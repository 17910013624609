import React, { Component, Fragment } from 'react';
import {
    TextField,
    Select,
    ExpansionPanel,
    ExpansionPanelSummary,
    Typography,
    ExpansionPanelDetails,
    withStyles,
    InputLabel,
    OutlinedInput,
    Divider,
    Checkbox,
    Dialog,
    DialogContent,
    DialogActions,
    Button,
    Tooltip,
    MenuItem,

} from '@material-ui/core';
import VisibilityIcon from '@material-ui/icons/Visibility';
import RefreshIcon from '@material-ui/icons/Refresh';
import { withTranslation } from "react-i18next";

import {
    ExpandMore,
    Delete,
    Edit,
    CheckBox,
    AddBox,
    Close
} from '@material-ui/icons';

import AsyncPaginate from "react-select-async-paginate";

import { config } from '../../configuration';
import { getOidcAccessToken } from '../../utils/oidcStorageUtils';

let server_url = config.url.server_url;
let count = 0;
let flag = false;
let minLor;
const AxisList = ["X", "Y", "Z"];
const DspList = [2, 7, 12, 25];
const DcsList = [{ value: 300, displayValue: "5 mins" }, { value: 1800, displayValue: "30 mins" }, { value: 3600, displayValue: "1 hr" }, { value: 7200, displayValue: "2 hr" }, { value: 21600, displayValue: "6 hr" }, { value: 86400, displayValue: "24 hr" }];
const RotatingSpeedType = [{ code: "FIXED", displayValue: "Fixed" }, { code: "VARIABLE", displayValue: "Variable" }]
const AlertPreferences = [{ code: "NOALRT", displayLabel: "No Alert" }, { code: "CAUTWARN", displayLabel: "Caution and Warning" }, { code: "WARN", displayLabel: "Warning Only" }];
const featureTypeList = [{ code: "VELBAND", displayLabel: "Velocity Band" }, { code: "PHASEDIFF", displayLabel: "Phase Difference" }]
const operatorList = ["+", "-", "*", "/", "--"];
const comparatorList = ["<", "<=", ">", ">=", "==", "!="];
const styles = theme => ({
    dropdownContent: {
        display: 'flex',
        justifyContent: 'space-between',
        minWidth: '410px'

    },
    dynamicDropdown: {
        width: '60% !important',
        marginLeft: '9px'
    },
    expansionPanelDetails: {
        display: 'block'
    },
    header2: {
        backgroundColor: "#f2f3f8",
        padding: 5,
        display: 'flex',
        justifyContent: 'space-between'
    },
    inputFieldDiv: {
        display: "inline-flex",
        justifyContent: "space-between",
        width: '50%',
        paddingTop: '10px',
        height: "90 !important",
        'align-items': 'baseline',
        backgroundColor: '#fff'
    },
    inputFieldDivColumn: {
        display: "inline-flex",
        justifyContent: "space-between",
        width: '33.33%',
        paddingTop: '10px',
        height: 90,
        'align-items': 'baseline',
        backgroundColor: '#fff'
    },
    inputFieldDivSpeed: {
        display: "inline-flex",
        justifyContent: "space-between",
        width: '15%',
        paddingTop: '10px',
        'align-items': 'baseline',
        backgroundColor: '#fff'
    },
    textField: {
        width: '60%',
        marginRight: 20
    },
    textFieldTable: {
        width: '100%',
        marginRight: 20,
    },
    rpmTextField: {
        width: '60%',
        marginRight: 29
    },
    textFieldLabel: {
        color: 'rgba(0, 0, 0, 0.4000000059604645)',
        'letter-spacing': 0.5,
        'font-weight': 500,
        fontSize: "14px",
        lineHeight: 1.5,
        width: '30%',
        marginLeft: 20,
    },
    outlinedInput: {
        padding: 14
    },
    requiredClass: {
        color: 'red'
    },
    expansionPanelSummary: {
        alignItems: 'center'
    },
    deleteIcon: {
        'font-size': 18,
        cursor: 'pointer',
        color: 'rgba(221, 44, 0, 0.5)',
        '&:hover': {
            color: 'rgba(221, 44, 0, 1.0)'
        },
        marginLeft: 10
    },
    editIcon: {
        'font-size': 18,
        cursor: 'pointer',
        color: 'rgba(0, 200, 83, 0.5)',
        '&:hover': {
            color: 'rgba(0, 200, 83, 1.0)'
        },
        marginLeft: 10
    },
    addIcon: {
        'font-size': 31,
        cursor: 'pointer',
        color: 'rgba(0, 200, 83, 0.5)',
        '&:hover': {
            color: 'rgba(0, 200, 83, 1.0)'
        },
        marginRight: 23,
        marginTop: "10px"
    },
    statecolor: {
        marginBottom: "10px",
        "color": "white",
        li: {
            marginRight: "20px",
            padding: " 4px 8px",
            fontSize: "12px",
            borderRadius: "2px"
        }
    },
    settingsModal: {
        'min-width': 630,
        'max-height': 589,
        'min-height': 250
    },
    dialogTitle: {
        display: 'flex',
        'justify-content': 'space-between',
        'align-items': 'center',
        padding: '10px 24px'
    },
    addPlantsButton: {
        float: 'right',
        color: '#42D784',
        marginLeft: 10
    },
    cloneButtonText: {
        padding: '0 8px'
    },
    icon: {
        color: "#9e9e9e",
        '& svg': {
            'font-size': 20,
            'margin-top': 5
        }
    },

})

class MonitorSpecs extends Component {

    state = {
        value: '',
        options: [],
        openFaultDialog: false,
        openAddFaultDialog: false,
        featureType: "",
        bearingTypeNumber: 0,
        openDeleteFaultDialog: false,
        openDeleteDiagnosticFaultDialog: false,
        openDeleteTriggerFaultDialog: false,
        dignosticIndex: "",
        triggerIndex: "",
        trigger: "",
        faultList: [],
        openRemoveFaultDialog: false,
        isExpandBasic: [],
        openFeatureTypeSelectionDialog: false,
        checkedArr: []
    }


    componentDidMount() {
        count = 0;
        flag = false;
        var x = document.getElementsByClassName("checkbox");
        for (let i = 0; i < x.length; i++) {
            x[i].checked = false;
        }
        if (this.props.entityName === "schedules") {
            this.setState({
                newShift: this.createDefaultShiftObject()
            })
        }

        if (this.props.metadata && this.props.metadata.metadata && this.props.metadata.metadata.dataSendPeriodDetails) {
            const lor = this.props.jsonData.spectral_monitoring.lines_of_resolution;
            const fmax = this.props.jsonData.spectral_monitoring.maximum_frequency;
            if (fmax && fmax !== "" && fmax !== 0 && lor && lor !== 0 && lor !== "") {
                var res = this.props.metadata.metadata.dataSendPeriodDetails.find(x => x.lor.toString() === lor.toString() && x.fmax.toString() === fmax.toString());
                if (res) {
                    let mindsp = res.dsp / 1000;
                    let newDspList = DspList.filter(option => !(mindsp !== 2 && option < mindsp));
                    if (!newDspList.includes(parseInt(this.props.jsonData.additional_attrs.dsp))) {
                        this.props.updateStateForSpectralMonitoring(newDspList[0].toString(), "dsp", "additional_attrs", "");
                    }
                }
            }
        }
    }

    openAddFaultDialog = (event) => {
        event.stopPropagation();
        fetch(`${server_url}/getFaultList/1`, {
            method: 'GET',
            headers: {
                "Authorization": `Bearer ${getOidcAccessToken()}`,
                "Accept-Language": localStorage.getItem("lang") || "en"
            }
        })
            .then(response => response.json())
            .then(data => {
                this.setState({
                    faultList: data,
                    openAddFaultDialog: true
                })
            });

    }

    closeAddFaultDialog = (event) => {
        this.setState({
            openAddFaultDialog: false
        })
    }

    openFeatureTypeSelectionDialog = (event) => {
        this.setState({
            openFeatureTypeSelectionDialog: true
        })

    }

    closeAdvancedFeatureDialog = (event) => {
        this.setState({
            openFeatureTypeSelectionDialog: false,
            featureType: ""
        })
    }


    updateStateForKey = (value, levelIndicator, forSave) => {
        this.props.updateStateForKey(value, levelIndicator, forSave)
    }

    updateStateForSpectralMonitoring(value, field, node) {
        let minValue = 1;
        let maxValue = 60000;
        let error = false;
        let errorMessage = "";
        if (field === "maximum_frequency") {
            minLor = value / 100;
            this.getG_range()

            const lor = this.props.jsonData.spectral_monitoring.lines_of_resolution;
            const fmax = value;
            if (fmax && fmax !== "" && fmax !== 0 && lor && lor !== 0 && lor !== "") {
                var res = this.props.metadata.metadata.dataSendPeriodDetails.find(x => x.lor.toString() === lor.toString() && x.fmax.toString() === fmax.toString());
                if (res) {
                    let mindsp = res.dsp / 1000;
                    let newDspList = DspList.filter(option => !(mindsp !== 2 && option < mindsp));
                    if (!newDspList.includes(parseInt(this.props.jsonData.additional_attrs.dsp))) {
                        this.props.updateStateForSpectralMonitoring(newDspList[0].toString(), "dsp", "additional_attrs", "");
                    }
                }
            }
        }
        if(field === "minimum_frequency"){
            this.props.updateStateForSpectralMonitoring(value, 'minimum_frequency', node, '');
        }
        if (field === "lines_of_resolution") {
            const lor = value;
            const fmax = this.props.jsonData.spectral_monitoring.maximum_frequency;
            if (fmax && fmax !== "" && fmax !== 0 && lor && lor !== 0 && lor !== "") {
                var res = this.props.metadata.metadata.dataSendPeriodDetails.find(x => x.lor.toString() === lor.toString() && x.fmax.toString() === fmax.toString());
                if (res) {
                    let mindsp = res.dsp / 1000;
                    let newDspList = DspList.filter(option => !(mindsp !== 2 && option < mindsp));
                    if (!newDspList.includes(parseInt(this.props.jsonData.additional_attrs.dsp))) {
                        this.props.updateStateForSpectralMonitoring(newDspList[0].toString(), "dsp", "additional_attrs", "");
                    }
                }
            }

            if (value === '100') {
                if (this.props.jsonData.spectral_monitoring.maximum_frequency !== '325' && this.props.jsonData.spectral_monitoring.maximum_frequency !== '648') {
                    this.props.updateStateForSpectralMonitoring('325', 'maximum_frequency', node, '');
                }
            }
        }
        if (field === "dsp" || field === "dcs") {
            value = parseFloat(value);
        }
        if (field === "dsp" && (isNaN(value) || value === "")) {
            error = true;
            // errorMessage = `${parameterDatatype}: ${minValue} - ${maxValue}`;
            errorMessage = this.props.t("Enter valid number.");
        }
        if (field === "rotating_speed" || field === "to" || field === "from") {
            let parameterDatatype = "double";
            if (isNaN(value)) {
                error = true;
                // errorMessage = `${parameterDatatype}: ${minValue} - ${maxValue}`;
                errorMessage = this.props.t("Enter actual RPM of the machine");
            } else if (value === "") {
                error = false;
            } else {
                if (parameterDatatype === "double") {
                    var valid = (value.match(/^-?\d*(\.\d+)?$/));
                    if (valid) {
                        if (parseFloat(value) >= minValue && parseFloat(value) <= maxValue) {
                            this.getG_range()
                            error = false;
                            errorMessage = "";
                        } else if (parseFloat(value) < minValue) {
                            error = true;
                            errorMessage = this.props.t("Enter actual RPM of the machine");
                        } else {
                            error = true;
                            errorMessage = `${this.props.t("Enter a value less than or equal to")} ${maxValue}`;
                        }
                    }
                    else {
                        error = true;
                        errorMessage = this.props.t("Enter actual RPM of the machine");
                    }
                }
            }

            if (!error && (field === "to" || field === "from")) {
                if (field === "to" && this.props.jsonData.additional_attrs.rotating_speed_range.from !== "" && value != "") {
                    errorMessage = parseFloat(this.props.jsonData.additional_attrs.rotating_speed_range.from) > parseFloat(value) ? "Value of To must be greater than value of From." : "";
                }
                if (field === "from" && this.props.jsonData.additional_attrs.rotating_speed_range.to !== "" && value != "") {
                    errorMessage = parseFloat(this.props.jsonData.additional_attrs.rotating_speed_range.to) < parseFloat(value) ? "Value of From must be less than value of To." : "";
                }
            }

        }

        this.props.updateStateForSpectralMonitoring(value, field, node, errorMessage);
    }
    getBearingTypeLabel = (bearingTypeNumber) => {
        if (bearingTypeNumber != "0" || bearingTypeNumber != 0) {
            fetch(`${server_url}/bearingtype/1/${bearingTypeNumber}`, {
                method: 'GET',
                headers: {
                    "Authorization": `Bearer ${getOidcAccessToken()}`,
                    "Accept-Language": localStorage.getItem("lang") || "en"
                }
            })
                .then(response => response.json())
                .then(data => {
                    let value = {
                        value: data.id,
                        label: data.type
                    }
                    this.setState({ value: value, bearingTypeNumber: data.id })
                });
        } else if (this.state.bearingTypeNumber != 0) {
            this.setState({ value: '', bearingTypeNumber: 0 })
        }
    }

    loadOptions = async (search, prevOptions, { page }) => {
        const options = [];
        const response = await fetch(`${server_url}/searchbearingtype/1/${page}/20?bearingTypeString=${search}`, {
            headers: {
                "Content-Type": 'application/json',
                "Authorization": `Bearer ${getOidcAccessToken()}`,
                "Accept-Language": localStorage.getItem("lang") || "en"
            }
        })
        const data = await response.json();
        data.content.forEach(el => {
            options.push({
                value: el.id,
                label: el.label
            });
        })

        let filteredOptions;
        if (!search) {
            filteredOptions = options;
        } else {
            const searchLower = search.toLowerCase();

            filteredOptions = options.filter(({ label }) =>
                label.toLowerCase().includes(searchLower)
            );
        }

        const hasMore = data.last === false ? true : false;

        return {
            options: filteredOptions,
            hasMore,
            additional: {
                page: page + 1,
            },
        };
    };

    getFaultSpecs = () => {
        this.setState({
            openFaultDialog: true,
        });
    }

    removeBearingType = () => {

        this.setState({
            openRemoveFaultDialog: true,
        });
        //  this.updateStateForSpectralMonitoring(0, "bearing_type", "additional_attrs")
    }
    removeBearingFault = () => {
        this.updateStateForSpectralMonitoring(0, "bearing_type", "additional_attrs")
        this.closeRemoveFaultDialog();
        this.setState({
            bearingTypeNumber: 0,
            value: ''
        })
    }

    closeRemoveFaultDialog = () => {
        this.setState({
            openRemoveFaultDialog: false
        });
    }

    handleOk = () => {
        if (this.state.bearingTypeNumber === "") {
            this.handleCancel();
            // alert('Please select Bearing type.')
            this.props.showError(this.props.t("Please select Bearing type."), false);
        } else {
            this.setState({
                openFaultDialog: false
            }, () => {
                this.props.updateSpecForFaultConifg(this.state.bearingTypeNumber);
            })
        }
        // var keys = Object.keys(this.props.jsonData.conditions);
        // if ((keys.length - 6) === this.state.checkedArr.length) {
        //     count=1;
        //     var x = document.getElementById("allCheckbox");
        // x.checked = true;
        // }
    }
    handleCancel = () => {
        if (flag) {
            count = 1;
            var x = document.getElementById("allCheckbox");
            x.checked = true;
        }
        this.setState({
            openFaultDialog: false
        })
        this.props.removeBearingTypeFaults();
        //  this.updateStateForSpectralMonitoring(0, "bearing_type", "additional_attrs")
        //  this.setState({ value: "", bearingTypeNumber: "" });


    }

    handleChange = (value, field, feature) => {
        if (flag) {
            count = 0;
            var x = document.getElementById("allCheckbox");
            x.checked = false;
        }
        //  let conditions = this.props.jsonData.find(condition => condition.key === 'conditions');
        //const featureType = 2;
        //const count = conditions.children.filter((obj) => obj.featureType === featureType).length;
        var keys = Object.keys(this.props.jsonData.conditions);
        if ((keys.length - 6) > 16) {
            this.props.showError(this.props.t("More than 16 features are configured hence cannot add bearing features."), false);
        } else {
            if (this.props.monitorCodeName == 'VELMONWITHFAULTS') {
                this.setState({
                    openFaultDialog: true,
                });
            }
        }
        this.updateStateForSpectralMonitoring(value.value, "bearing_type", "additional_attrs")
        this.setState({ value: value, bearingTypeNumber: value.value });
    }


    handleBasicExpand = (key, val) => {
        this.props.expanPanel(key, val, "basic");

    }

    handleDiagnosticsExpand = (key, val) => {
        this.props.expanPanel(key, val, "diagnostic");
    }

    allCheckboxSelect = () => {
        count++;
        let checkedArr = [];

        if (count % 2 !== 0) {
            flag = true;
            var x = document.getElementsByClassName("checkbox");
            for (let i = 0; i < x.length; i++) {
                x[i].checked = true;
            }
            var keys = Object.keys(this.props.jsonData.conditions);
            for (let j = 5; j < keys.length; j++) {
                if (!this.props.features.includes(keys[j])) {
                    let newArray = [];
                    checkedArr.push(keys[j]);
                }

            }

        } else {
            flag = false;

            var x = document.getElementsByClassName("checkbox");
            for (let i = 0; i < x.length; i++) {
                x[i].checked = false;
            }
        }
        this.setState({
            checkedArr: checkedArr
        })
    }

    checkedFaults = (feature, index) => {
        if (flag) {
            count = 0;
            var x = document.getElementById("allCheckbox");
            x.checked = false;
        }
        let newArray = [...this.state.checkedArr, feature];
        if (this.state.checkedArr.includes(feature)) {
            newArray = newArray.filter(day => day !== feature);

        }
        this.handleBasicExpand(feature, false)
        this.setState({ checkedArr: newArray }, () => {
            var keys = Object.keys(this.props.jsonData.conditions);
            if ((keys.length - 6) === this.state.checkedArr.length) {
                count = 1;
                var x = document.getElementById("allCheckbox");
                x.checked = true;
            }
        })

    }
    removeAdvancedFault = () => {
        if (this.state.checkedArr.length !== 0) {
            this.setState({
                openDeleteFaultDialog: true,
                deleteFault: this.state.checkedArr
            })
        }
    }

    removeDiagnosticFault = (index, index1, trigger) => {
        if (trigger === "feature") {
            this.setState({
                openDeleteDiagnosticFaultDialog: true,
                dignosticIndex: index,
                triggerIndex: index1,
                trigger: trigger
            })
        } else {
            this.setState({
                openDeleteTriggerFaultDialog: true,
                dignosticIndex: index,
                triggerIndex: index1,
                trigger: trigger
            })
        }

    }
    //Close delete fault Dialog
    closeDeleteFaultDialog = () => {
        this.setState({
            openDeleteFaultDialog: false
        })
    }

    openThresholdPopup = () => {
        this.props.openThresholdPopup();
    }

    closeDeleteDiagnosticFaultDialog = () => {
        this.setState({
            openDeleteDiagnosticFaultDialog: false
        })
    }
    closeDeleteTriggerFaultDialog() {
        this.setState({
            openDeleteTriggerFaultDialog: false,
            dignosticIndex: "",
            triggerIndex: "",
            trigger: ""
        })
    }
    removeDiagnostics() {
        if (this.state.trigger === "trigger") {
            this.closeDeleteTriggerFaultDialog();
        }
        else {
            this.closeDeleteDiagnosticFaultDialog();
        }
        this.props.removeDiagnostics(this.state.dignosticIndex, this.state.triggerIndex, this.state.trigger);
    }

    addDiagnostics(feature, type) {
        if (type === "trigger") {
            if (this.props.jsonData.diagnostics[feature].triggers.length >= 20) {
                this.props.showError(this.props.t("Maximum 20 triggers can be added per Dignostics in Monitoring Location."), false);
            } else {
                this.props.addDiagnostics(feature, type);
            }
        } else {
            if (this.props.jsonData.diagnostics.length >= 20) {
                this.props.showError(this.props.t("Maximum 20 Dignostics can be added per Monitoring Location."), false);
            } else {
                this.props.addDiagnostics(feature, type);
            }
        }

    }
    //Delete fault
    deleteFault = () => {
        //   let conditions = this.props.jsonData.find(condition => condition.key === 'conditions');
        // const featureType = 2;
        // const count = conditions.children.filter((obj) => obj.featureType === featureType).length;
        var keys = Object.keys(this.props.jsonData.conditions);
        if ((keys.length - 6) === this.state.checkedArr.length) {
            this.props.showError(this.props.t("Minimum 1 advanced feature is required per Monitoring Location."), false);
        } else {
            this.props.deleteAdvancedFeature(this.state.deleteFault);
            this.setState({ checkedArr: [] });
            var x = document.getElementsByClassName("checkbox");
            for (let i = 0; i < x.length; i++) {
                x[i].checked = false;
            }
        }
        this.closeDeleteFaultDialog();
    }

    updateFault = (value) => {
        this.setState({
            selectedFault: value
        })
    }

    updateFeature = (value) => {
        this.setState({
            featureType: value
        })
    }

    updateStateForDValues = (value, field, type, isRequired, feature, trigger) => {
        let minValue = 0;
        let maxValue = 60000;

        let error = false;
        let errorMessage = "";
        switch (type) {
            case "number":
                let parameterDatatype = "double";
                if (value === "" && isRequired) {
                    error = true;
                    // errorMessage = `${parameterDatatype}: ${minValue} - ${maxValue}`;
                    errorMessage = this.props.t("Enter valid number.");
                } else if (value === "" && !isRequired) {
                    error = false;
                    errorMessage = "";

                }
                else if (isNaN(value) && value !== "") {
                    error = true;
                    // errorMessage = `${parameterDatatype}: ${minValue} - ${maxValue}`;
                    errorMessage = this.props.t("Enter valid number.");
                }
                else {
                    if (parameterDatatype === "double") {
                        var valid = (value.match(/^-?\d*(\.\d+)?$/));
                        if (valid) {
                            if (parseFloat(value) >= minValue && parseFloat(value) <= maxValue) {
                                error = false;
                                errorMessage = "";
                            } else if (parseFloat(value) < minValue) {
                                error = true;
                                errorMessage = `${this.props.t("Enter a value greater than or equal to")} ${minValue}`;
                            } else {
                                error = true;
                                errorMessage = `${this.props.t("Enter a value less than or equal to")} ${maxValue}`;
                            }
                        }
                        else {
                            error = true;
                            errorMessage = this.props.t("Enter valid number.");
                        }
                    } else {
                        error = false;
                        errorMessage = "";
                    }
                }
                break;
            case "Textbox":
                if (field === "displayLabel") {
                    if (value.length > 75) {
                        error = true;
                        errorMessage = this.props.t("Diagnostic Name length should not be more than 75 character.");
                    }
                }
                if (field === "recommendation") {
                    if (value.length > 150) {
                        error = true;
                        errorMessage = this.props.t("Alarm Recommendation length should not be more than 150 character.");
                    }
                }
                if (field === "unit") {
                    var valid = (value.match(/^[a-zA-Z\d\-_\/\s]+$/));
                    if (value.length > 20) {
                        error = true;
                        errorMessage = this.props.t("Unit length should not be more than 20 character.");
                    } else if (valid) {
                        value = value;
                    } else {
                        error = true;
                        errorMessage = this.props.t("Enter valid unit.");
                    }
                }
                else {
                    value = value;
                }
                break;


        }

        this.props.updateStateForDValues(value, field, feature, trigger, errorMessage);
    }
    getMinimum_frequency=()=>{
        let arr = [0.5,1,5,10];
        let minFreq = [];
        arr.map(fbb =>
            minFreq.push(<option key={fbb} value={fbb}>{fbb}</option>)
        );
        return minFreq;
    }

    getG_range = () => {
        let arr = [];
        if (this.props.jsonData && this.props.jsonData.spectral_monitoring) {
            if (this.props.jsonData.spectral_monitoring.maximum_frequency <= 1300) {
                arr = [{ "key": 2, "value": 2 },
                { "key": 4, "value": 4 },
                { "key": 8, "value": 8 },
                { "key": 16, "value": 16 }
                ]
            } else {
                arr = [{ "key": 8, "value": 8 },
                { "key": 16, "value": 16 },
                { "key": 32, "value": 32 },
                { "key": 64, "value": 64 }
                ]
            }
        }

        let displayDriver = [];
        arr.map(fbb =>
            displayDriver.push(<option key={fbb.key} value={fbb.key}>{fbb.value}</option>)
        );


        return displayDriver;
    }

    updateStateForValues = (value, field, type, isRequired, feature) => {
        let minValue = 0;
        let maxValue = 60000;

        let error = false;
        let errorMessage = "";
        switch (type) {
            case "number":
                let parameterDatatype = "double";
                if (value === "" && isRequired) {
                    error = true;
                    // errorMessage = `${parameterDatatype}: ${minValue} - ${maxValue}`;
                    //errorMessage = this.props.t("Enter valid number.");
                } else if (value === "" && !isRequired) {
                    error = false;
                    errorMessage = "";

                }
                else if (isNaN(value) && value !== "") {
                    error = true;
                    // errorMessage = `${parameterDatatype}: ${minValue} - ${maxValue}`;
                    errorMessage = this.props.t("Enter valid number.");
                }
                else {
                    if (parameterDatatype === "double") {
                        var valid = (value.match(/^-?\d*(\.\d+)?$/));
                        if (valid) {
                            if (parseFloat(value) >= minValue && parseFloat(value) <= maxValue) {
                                error = false;
                                errorMessage = "";
                            } else if (parseFloat(value) < minValue) {
                                error = true;
                                errorMessage = `${this.props.t("Enter a value greater than or equal to")} ${minValue}`;
                            } else {
                                error = true;
                                errorMessage = `${this.props.t("Enter a value less than or equal to")} ${maxValue}`;
                            }
                        }
                        else {
                            error = true;
                           // errorMessage = this.props.t("Enter valid number.");
                        }
                    } else {
                        error = false;
                        errorMessage = "";
                    }
                }
                break;
            case "Textbox":
                if (field === "displayLabel") {
                    if (value.length > 75) {
                        error = true;
                        errorMessage = this.props.t("Feature Name length should not be more than 75 character.");
                    }
                }
                if (field === "recommendation") {
                    if (value.length > 150) {
                        error = true;
                        errorMessage = this.props.t("Alarm Recommendation length should not be more than 150 character.");
                    }
                }
                if (field === "unit") {
                    var valid = (value.match(/^[a-zA-Z\d\-_\/\s]+$/));
                    if (value.length > 20) {
                        error = true;
                        errorMessage = this.props.t("Unit length should not be more than 20 character.");
                    } else if (valid) {
                        value = value;
                    } 
                    else {
                        error = true;
                        errorMessage = this.props.t("Enter valid unit.");
                    }
                    if(value.length === 0){
                        error=true;
                        errorMessage=""
                    }
                }
                else {
                    value = value;
                }
                break;


        }

        this.props.updateStateForValues(value, field, feature, errorMessage);
    }


    updateStateForThreshold = (value, type, feature) => {
            this.props.updateStateForThreshold(value, type, feature);
    }

    handleAdvancedFeature = () => {
        if (flag) {
            count = 0;
            var x = document.getElementById("allCheckbox");
            x.checked = false;
        }
        var keys = Object.keys(this.props.jsonData.conditions);
        if (keys.length - 6 >= 20) {
            this.props.showError(this.props.t("Maximum 20 Advanced Features can be added per Monitoring Location."), false);
        } else {
            this.props.addNewAdvancedFeature(this.state.featureType);
        }
        this.closeAdvancedFeatureDialog();

    }
    getSpectralComponent() {

        return (
            this.props.jsonData && this.props.jsonData.spectral_monitoring ?
                (
                    <div key={"spectral_monitoring"}>
                        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                            <Typography className={this.props.classes.header} style={{ margin: '1%' }} variant={"h6"}>{this.props.t("Spectrum Fmax, LOR and g-range Settings")}</Typography>
                        </div>
                        <>

                            {
                                <div className={this.props.classes.inputFieldDiv} key={"lines_of_resolution"}>
                                    <div style={{ display: 'flex', flexDirection: 'column' }}>
                                        <div style={{ display: 'inline-flex' }}>
                                            <InputLabel className={this.props.classes.textFieldLabel} style={{ paddingTop: '8px' }}>{this.props.t("Lines Of Resolution")}</InputLabel>
                                            <Select
                                                //  native
                                                value={this.props.jsonData.spectral_monitoring.lines_of_resolution.toString()}
                                                onChange={(event) => this.updateStateForSpectralMonitoring(event.target.value, "lines_of_resolution", "spectral_monitoring")}
                                                style={{ left: '40px' }}
                                                error={this.props.errorModel["lor"] ? true : false}
                                                input={
                                                    <OutlinedInput
                                                        variant="outlined"
                                                        name="monitorTypeId"
                                                        id="language-native-simple"
                                                        labelWidth={0}
                                                        className={this.props.classes.textField}
                                                        classes={{ input: this.props.classes.outlinedInput }}
                                                    />
                                                }
                                            >
                                                {this.props.metadata.metadata && this.props.metadata.metadata.linesOfResolutions !== null ?
                                                    this.props.metadata.metadata.linesOfResolutions.map(option => (
                                                        <MenuItem key={option} value={option.toString()} hidden={option == 100 && this.props.jsonData.spectral_monitoring.maximum_frequency == 1300 || ((option === 100 || option === 200) && minLor === 25) || ((option === 100 || option === 200 || option === 400) && minLor === 50) || ((option === 100 || option === 200 || option === 400 || option === 800) && minLor === 100)}>
                                                            {option === '3200' || option === 3200 ? <div>{option}<span style={{ color: 'red', padding: 0, fontSize: '16px' }}>*</span></div> : <div>{option}</div>}
                                                        </MenuItem>))
                                                    : <MenuItem key={""} value={""}></MenuItem>
                                                }
                                            </Select>
                                        </div>
                                        <div style={{ marginLeft: '40%', paddingTop: '8px', fontSize: '0.75rem', wordWrap: 'break-word', color: 'red' }}>
                                            {this.props.errorModel["lor"] ? this.props.errorModel["lor"] : null}
                                        </div>
                                        <div style={{ marginLeft: 20, paddingTop: '10px', fontSize: '14px', wordWrap: 'break-word', color: 'black' }}>
                                            {this.props.t("Notes")}:<br></br>
                                            {this.props.t("1. Lines of Resolution values marked")} <span style={{ color: 'red' }}>*</span> {this.props.t("are only applicable for devices with firmware versions 3.0.0 and above.")} <br></br>
                                            {this.props.t("2. Maximum Frequency values marked")} <span style={{ color: 'red' }}>**</span> {this.props.t("are only applicable for V2.0 devices.")}<br></br>
                                            {this.props.t("3. Please find below the revised DSP table for your reference. Note that the combination of Fmax=10000 and LOR 1600/3200 is not applicable for firmwares 3.0.11 onwards.")}

                                        </div>

                                    </div>
                                </div>

                            }
                            {
                                <div className={this.props.classes.inputFieldDiv} key={"lines_of_resolution"}>
                                    <div style={{ display: 'flex', flexDirection: 'column', width: "100%" }}>
                                        <div style={{ display: 'inline-flex' }}>
                                            <InputLabel className={this.props.classes.textFieldLabel}>{this.props.t("Maximum Frequency(Hz)")}</InputLabel>
                                            <Select
                                                //  native
                                                value={this.props.jsonData.spectral_monitoring.maximum_frequency.toString()}
                                                onChange={(event) => this.updateStateForSpectralMonitoring(event.target.value, "maximum_frequency", "spectral_monitoring")}
                                                input={
                                                    <OutlinedInput
                                                        variant="outlined"
                                                        name="monitorTypeId"
                                                        id="language-native-simple"
                                                        labelWidth={0}
                                                        className={this.props.classes.textField}
                                                        classes={{ input: this.props.classes.outlinedInput }}
                                                    />
                                                }
                                            >
                                                {this.props.metadata.metadata && this.props.metadata.metadata.maximumFrequencies !== null ?
                                                    this.props.metadata.metadata.maximumFrequencies.map(option => (
                                                        <MenuItem key={option} value={option.toString()} hidden={this.props.jsonData.spectral_monitoring !== undefined && this.props.jsonData.spectral_monitoring.lines_of_resolution !== undefined && this.props.jsonData.spectral_monitoring.lines_of_resolution === '100' && option >= 1300}>
                                                            {option === '2500' || option === '5000' || option === '10000' || option === 2500 || option === 5000 || option === 10000 ? <div>{(option === '10000' || option === 10000) ? "8000" : option}<span style={{ color: 'red', padding: 0, fontSize: '16px' }}>**</span></div> : option}
                                                        </MenuItem>))
                                                    : <MenuItem key={""} value={""}></MenuItem>
                                                }
                                            </Select>

                                            {this.props.showErrorMessage && this.props.jsonData.spectral_monitoring.maximum_frequency ?
                                                (
                                                    <div style={{ marginLeft: 20, color: 'red' }}>
                                                        {this.props.t("The device associated with this monitor does not support configurable Lines of Resolution and Maximum Frequency.")}
                                                    </div>
                                                ) : null
                                            }
                                        </div>
                                        <div style={{ paddingTop: '20px', fontSize: '14px', wordWrap: 'break-word', color: 'black', display: "inline-flex" }}>
                                            <InputLabel className={this.props.classes.textFieldLabel}>{this.props.t('Minimum Frequency(Hz)')}</InputLabel>
                                            <Select
                                                native
                                                value={this.props.jsonData.spectral_monitoring.minimum_frequency ? this.props.jsonData.spectral_monitoring.minimum_frequency : 10}
                                                onChange={(event) => this.updateStateForSpectralMonitoring(event.target.value, "minimum_frequency", "spectral_monitoring")}
                                                input={
                                                    <OutlinedInput
                                                        variant="outlined"
                                                        name="displayPosition"
                                                        id="language-native-simple"
                                                        labelWidth={this.state.labelWidth}
                                                        className={this.props.classes.textField}
                                                        classes={{ input: this.props.classes.outlinedInput }}
                                                    />
                                                }
                                            >           {this.getMinimum_frequency()}


                                            </Select>


                                        </div>
                                        <div style={{ paddingTop: '20px', fontSize: '14px', wordWrap: 'break-word', color: 'black', display: "inline-flex" }}>
                                            <InputLabel className={this.props.classes.textFieldLabel}>{this.props.t('g-Range')}</InputLabel>
                                            <Select
                                                native
                                                value={this.props.jsonData.spectral_monitoring.g_range ? this.props.jsonData.spectral_monitoring.g_range : 4}
                                                onChange={(event) => this.updateStateForSpectralMonitoring(event.target.value, "g_range", "spectral_monitoring")}
                                                input={
                                                    <OutlinedInput
                                                        variant="outlined"
                                                        name="displayPosition"
                                                        id="language-native-simple"
                                                        labelWidth={this.state.labelWidth}
                                                        className={this.props.classes.textField}
                                                        classes={{ input: this.props.classes.outlinedInput }}
                                                    />
                                                }
                                            >           {this.getG_range()}


                                            </Select>


                                        </div>

                                    </div>
                                </div>

                            }

                        </>
                    </div >
                ) : null
        )

    }
    getAttributeComponent() {
        var dataSendPeriodDetails = [];
        var mindsp = 0;
        if (this.props.metadata && this.props.metadata.metadata && this.props.metadata.metadata.dataSendPeriodDetails) {
            const lor = this.props.jsonData.spectral_monitoring.lines_of_resolution;
            const fmax = this.props.jsonData.spectral_monitoring.maximum_frequency;
            if (fmax && fmax !== "" && fmax !== 0 && lor && lor !== 0 && lor !== "") {
                var res = this.props.metadata.metadata.dataSendPeriodDetails.find(x => x.lor.toString() === lor.toString() && x.fmax.toString() === fmax.toString());
                if (res) {
                    mindsp = res.dsp / 1000;
                    /* this.props.dspList.map((dsp) => {
                         if (dsp >= mindsp) {
                             dataSendPeriodDetails.push(dsp);
                         }
                     })*/
                }
            }
            //    else {
            //        dataSendPeriodDetails = [];
            //    }
        }

        if (this.props.jsonData.additional_attrs) {

            return (<div key={"additional_attributes"}>
                <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                    <Typography className={this.props.classes.header} style={{ margin: '1%', }} variant={"h6"}>{this.props.t("Additional Attributes")}</Typography>
                </div>
                {
                    <div className="row">
                        <div className={this.props.classes.inputFieldDiv} key={"bearing_type"}>
                            <InputLabel className={this.props.classes.textFieldLabel}>{this.props.t("Bearing Type")}</InputLabel>
                            {parseInt(this.props.jsonData.additional_attrs.bearing_type) !== this.state.value.value ? this.getBearingTypeLabel(this.props.jsonData.additional_attrs.bearing_type) : null}
                            <AsyncPaginate
                                className={this.props.classes.dynamicDropdown}
                                value={this.state.value}
                                loadOptions={this.loadOptions}
                                onChange={(value) => this.handleChange(value, "bearing_type")}
                                additional={{
                                    page: 0,
                                }}
                            />
                            <div>
                                {/* { this.props.monitorCodeName == 'VELMONWITHFAULTS'  ?
                            <Tooltip  title={<span style={{ fontSize: "12px" }}>Assign Bearing Faults</span>}>
                                <SearchIcon className={this.props.classes.icon} onClick={this.getFaultSpecs} /> 
                            </Tooltip>
                        : null} */}
                                {/* this.props.monitorCodeName == 'VELMONWITHFAULTS' && */}
                                {
                                    this.state.bearingTypeNumber !== 0 ?
                                        <Tooltip title={<span style={{ fontSize: "12px" }}>{this.props.t("Remove Bearing Faults")}</span>}>
                                            <span className={this.props.classes.icon} style={{ fontSize: "14px", fontWeight: 'bold', cursor: "pointer" }} onClick={() => this.removeBearingType()}>X</span>
                                        </Tooltip>
                                        : null
                                }
                            </div >
                        </div >

                        <div className={this.props.classes.inputFieldDiv} key={"dsp"}>
                            <div style={{ display: 'flex', flexDirection: 'column', width: "100%" }}>
                                <div style={{ display: 'inline-flex' }}>
                                    <InputLabel style={{ marginTop: "10px", marginRight: "10px" }} className={this.props.classes.textFieldLabel}> {this.props.t("Data Send Period (in secs)")} <span className={this.props.classes.requiredClass}>*</span></InputLabel>
                                    <Select
                                        native
                                        onChange={(event) => this.updateStateForSpectralMonitoring(event.target.value, "dsp", "additional_attrs")}
                                        value={this.props.jsonData.additional_attrs.dsp.toString()}
                                        error={this.props.errorModel["dsp"] ? true : false}
                                        style={{ marginLeft: "10px", height: 'fit-content' }}
                                        input={
                                            <OutlinedInput
                                                variant="outlined"
                                                name="monitorTypeId"
                                                id="language-native-simple"
                                                labelWidth={0}
                                                className={this.props.classes.textField}
                                                classes={{ input: this.props.classes.outlinedInput }}
                                            />
                                        }
                                    >
                                        <option key={""} value={""} disabled>{this.props.t("Select")}</option>
                                        {DspList !== null && DspList !== 'null' && DspList.length > 0 ?
                                            DspList.map(option =>
                                            (<option key={option} hidden={mindsp !== 2 && option < mindsp} value={option}>{option}
                                            </option>))
                                            : ""
                                        }
                                    </Select>

                                </div>

                                <div style={{ marginLeft: '35%', paddingTop: '8px', fontSize: '0.75rem', wordWrap: 'break-word', color: 'red' }}>
                                    {this.props.errorModel["dsp"] ? this.props.errorModel["dsp"] : null}
                                </div>
                            </div>
                        </div>
                    </div >
                }

                {
                    <div className="row">
                        <div className={this.props.classes.inputFieldDiv} key={"dcs"} style={{ marginTop: '10px' }}>
                            <div style={{ display: 'flex', flexDirection: 'column', width: "100%" }}>
                                <div style={{ display: 'inline-flex' }}>
                                    <InputLabel style={{ marginTop: "10px", marginRight: "10px" }} className={this.props.classes.textFieldLabel}> {this.props.t("Duty Cycle Selection (Battery Devices Only)")} </InputLabel>
                                    <Select
                                        native
                                        onChange={(event) => this.updateStateForSpectralMonitoring(event.target.value, "dcs", "additional_attrs")}
                                        value={this.props.jsonData.additional_attrs.dcs === undefined ? '' : this.props.jsonData.additional_attrs.dcs}
                                        style={{ marginLeft: "19px", height: 'fit-content' }}
                                        input={
                                            <OutlinedInput
                                                variant="outlined"
                                                name="monitorTypeId"
                                                id="language-native-simple"
                                                labelWidth={0}
                                                className={this.props.classes.textField}
                                                classes={{ input: this.props.classes.outlinedInput }}
                                            />
                                        }
                                    >
                                        <option key={""} value={""} disabled>{this.props.t("Select")}</option>
                                        {
                                            DcsList.map(option =>
                                            (<option key={option.value} value={option.value}>{option.displayValue}
                                            </option>))
                                        }
                                    </Select>
                                </div>
                            </div>
                        </div>
                    </div>
                }
            </div >
            )
        }
    }

    getBasicFeatureComponent() {
        return (
            <div key={"dignostics"}>
                <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                    <Typography className={this.props.classes.header} style={{ margin: '1%' }} variant={"h6"}>{this.props.t("Basic Features")}</Typography>
                    {
                        this.props.id && this.props.id !== "" ?
                            (
                                <>
                                    <Button
                                        style={{ marginTop: "12px", height: "30px", backgroundColor: "#029e02f2", fontSize: "10px", color: "white" }}
                                        onClick={() => (this.openThresholdPopup())}
                                    >
                                        {this.props.t("Threshold Assistant")}
                                        {
                                            this.props.isComputeThreshold ?
                                                <RefreshIcon style={{ marginLeft: "5px" }}></RefreshIcon>
                                                :
                                                this.props.lastThresholdAssistantDatetime && this.props.lastThresholdAssistantDatetime !== "" ?
                                                    <VisibilityIcon style={{ marginLeft: "5px" }}></VisibilityIcon>
                                                    : null
                                        }
                                    </Button>

                                </>

                            ) : null
                    }

                </div>
                <table className="tableCount">
                    <tr className="tableCounttd">
                        <th className="tableCounttd">{this.props.t("Feature Name")} </th>
                        <th className="tableCounttd wd">{this.props.t("Operational Value")} <span className={this.props.classes.requiredClass}>*</span></th>
                        <th className="tableCounttd wd">{this.props.t("Caution Value")} <span className={this.props.classes.requiredClass}>*</span></th>
                        <th className="tableCounttd wd">{this.props.t("Warning Value")} <span className={this.props.classes.requiredClass}>*</span></th>
                        <th className="tableCounttd">{this.props.t("Dashborad Dial")}</th>
                    </tr>
                    {
                        this.props.features.map((feature, index) => {
                            var item = this.props.jsonData.conditions[feature];
                            //  var keys = Object.keys(item);
                            return (<>
                                <tr>
                                    <td className="tableCounttd">{this.props.t(feature)}</td>
                                    <td className="tableCounttd wd"><TextField
                                        variant="outlined"
                                        type="text"
                                        className={this.props.classes.textFieldTable}
                                        onChange={(event) => this.updateStateForThreshold(event.target.value, "Operational", feature)}
                                        value={item.Operational.threshold_value}
                                        error={this.props.errorModel[feature + "Operational"] ? true : false}
                                        helperText={this.props.errorModel[feature + "Operational"] ? this.props.errorModel[feature + "Operational"] : null}
                                        InputProps={{
                                            classes: {
                                                input: this.props.classes.outlinedInput
                                            }
                                        }}
                                    /></td>
                                    <td className="tableCounttd wd"><TextField
                                        variant="outlined"
                                        type="text"
                                        className={this.props.classes.textFieldTable}
                                        onChange={(event) => this.updateStateForThreshold(event.target.value, "Caution", feature)}
                                        value={item.Caution.threshold_value}
                                        error={this.props.errorModel[feature + "Caution"] ? true : false}
                                        helperText={this.props.errorModel[feature + "Caution"] ? this.props.errorModel[feature + "Caution"] : null}
                                        InputProps={{
                                            classes: {
                                                input: this.props.classes.outlinedInput
                                            }
                                        }}
                                    /></td>
                                    <td className="tableCounttd wd"><TextField
                                        variant="outlined"
                                        type="text"
                                        className={this.props.classes.textFieldTable}
                                        onChange={(event) => this.updateStateForThreshold(event.target.value, "Warning", feature)}
                                        value={item.Warning.threshold_value}
                                        error={this.props.errorModel[feature + "Warning"] ? true : false}
                                        helperText={this.props.errorModel[feature + "Warning"] ? this.props.errorModel[feature + "Warning"] : null}
                                        InputProps={{
                                            classes: {
                                                input: this.props.classes.outlinedInput
                                            }
                                        }}
                                    /></td>
                                    <td className="tableCounttd"><Checkbox
                                        style={{ paddingTop: "5px" }}
                                        checked={item.dashboardDial}
                                        onChange={(event) => this.updateStateForValues(!item.dashboardDial, "dashboardDial", "boolean", true, feature)}
                                        color="secondary"
                                        classes={{
                                            root: this.props.classes.defaultCheckbox
                                        }}
                                    /></td>
                                </tr>

                            </>)
                        })
                    }
                </table>
            </div>
        )


    }

    getAdvanceFeatureComponent() {
        var keys = Object.keys(this.props.jsonData.conditions);
        let phaseDiff = [], velocBand = [];
        if (keys.length > 0) {
            keys.map((feature, index) => {
                var item = this.props.jsonData.conditions[feature];
                if (item.featureType === "PHASEDIFF") {
                    phaseDiff.push(item)
                } else {
                    if (item.featureType === "VELBAND") {
                        velocBand.push(item)
                    }
                }
            })
        }


        return (
            <div key={"advancedFeatures"} >
                <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                    <Typography style={{ margin: '1%' }} variant={"h6"}>{this.props.t("Advance Features")}</Typography>
                    <div>
                        <Tooltip title={<span style={{ fontSize: "12px" }}>{this.props.t("Delete Faults")}</span>}>
                            <Delete
                                style={{ fontSize: "24px", marginTop: "10px",pointerEvents:this.state.checkedArr.length===0 && 'none' }}
                                classes={{ root: this.props.classes.deleteIcon }}
                                onClick={(event) => (this.removeAdvancedFault())}
                                disabled={this.state.checkedArr.length !== 0}
                            //onClick={(event) => (this.openFeatureTypeSelectionDialog(event))}
                            />
                        </Tooltip>
                        <Tooltip title={<span style={{ marginTop: "10px", fontSize: "12px" }}>{this.props.t("Add Advance Features")}</span>}>
                            <AddBox
                                classes={{ root: this.props.classes.addIcon }}
                                onClick={(event) => (this.openFeatureTypeSelectionDialog(event))}
                            />
                        </Tooltip>
                    </div>
                </div>
                <div className="row" style={{ fontSize: "14px", marginLeft: "8px", marginBottom: "10px" }}>
                    <input type="checkbox" title={this.props.t("Select All")}
                        id="allCheckbox"
                        style={{
                            paddingTop: "5px", width: "40px",
                            height: "18px"
                        }}
                        onChange={(event) => this.allCheckboxSelect()}
                        color="secondary"
                        classes={{
                            root: this.props.classes.defaultCheckbox
                        }}
                    />

                    <span>{this.props.t("(Phase difference only applicable for V2.0 and onward devices)")}</span>
                </div>

                {/* velocity table */}

                {velocBand.length > 0 && <>
                    <span className="bg-Caution">{this.props.t("Velocity Band")}</span>
                    <br /><table className="tableCount" style={{ marginBottom: "15px" }}>
                        <tr className="tableCounttd">
                            <th className="tableCounttd"></th>
                            <th className="tableCounttd wd">{this.props.t("Feature Name")} <span className={this.props.classes.requiredClass}>*</span></th>
                            <th className="tableCounttd">{this.props.t("Alarm Recommendation")} </th>
                            <th className="tableCounttd">{this.props.t("Dashborad Dial")}</th>
                            <th className="tableCounttd">{this.props.t("Axis")} <span className={this.props.classes.requiredClass}>*</span></th>
                            <th className="tableCounttd">{this.props.t("Multiplier")} <span className={this.props.classes.requiredClass}>*</span></th>
                            <th className="tableCounttd">{this.props.t("Band %")} <span className={this.props.classes.requiredClass}>*</span></th>
                            <th className="tableCounttd">{this.props.t("Unit")} <span className={this.props.classes.requiredClass}>*</span></th>
                            <th className="tableCounttd">{this.props.t("Operational Value")} <span className={this.props.classes.requiredClass}>*</span></th>
                            <th className="tableCounttd">{this.props.t("Caution Value")} <span className={this.props.classes.requiredClass}>*</span></th>
                            <th className="tableCounttd">{this.props.t("Warning Value")} <span className={this.props.classes.requiredClass}>*</span></th>
                        </tr>
                        {
                            keys.length > 0 ?
                                (
                                    keys.map((feature, index) => {
                                        var item = this.props.jsonData.conditions[feature];
                                        //  var keys = Object.keys(item);
                                        return (
                                            this.props.features.includes(feature) ? "" : <>
                                                {item.featureType !== "PHASEDIFF" &&
                                                    <tr>
                                                        <td className="tableCounttd"><input type="checkbox"
                                                            className="checkbox"

                                                            style={{
                                                                paddingTop: "5px", width: "40px",
                                                                height: "18px"
                                                            }}
                                                            ref={'ref_' + index}
                                                            //checked={this.state.isSelectedCheckboxes[index]}
                                                            onChange={(event) => this.checkedFaults(feature, index)}
                                                            color="secondary"
                                                            classes={{
                                                                root: this.props.classes.defaultCheckbox
                                                            }}
                                                        /></td>
                                                        <td className="tableCounttd wd"><TextField
                                                            variant="outlined"
                                                            type="text"
                                                            placeholder={this.props.t("Enter feature name here")}
                                                            onChange={(event) => this.updateStateForValues(event.target.value, "displayLabel", "Textbox", true, feature)}
                                                            value={item.displayLabel}
                                                            error={this.props.errorModel[feature + "displayLabel"] ? true : false}
                                                            helperText={this.props.errorModel[feature + "displayLabel"] ? this.props.errorModel[feature + "displayLabel"] : null}
                                                            className={this.props.classes.textFieldTable}
                                                            InputProps={{
                                                                classes: {
                                                                    input: this.props.classes.outlinedInput
                                                                }
                                                            }}
                                                        /></td>
                                                        <td className="tableCounttd"><TextField
                                                            variant="outlined"
                                                            type="text"
                                                            className={this.props.classes.textFieldTable}
                                                            onChange={(event) => this.updateStateForValues(event.target.value, "recommendation", "Textbox", false, feature)}
                                                            value={item.recommendation}
                                                            error={this.props.errorModel[feature + "recommendation"] ? true : false}
                                                            helperText={this.props.errorModel[feature + "recommendation"] ? this.props.errorModel[feature + "recommendation"] : null}
                                                            InputProps={{
                                                                classes: {
                                                                    input: this.props.classes.outlinedInput
                                                                }
                                                            }}
                                                        /></td>
                                                        <td className="tableCounttd"><Checkbox
                                                            style={{ paddingTop: "5px" }}
                                                            checked={item.dashboardDial}
                                                            onChange={(event) => this.updateStateForValues(!item.dashboardDial, "dashboardDial", "boolean", true, feature)}
                                                            color="secondary"
                                                            classes={{
                                                                root: this.props.classes.defaultCheckbox
                                                            }}
                                                        /></td>
                                                        <td className="tableCounttd" style={{ width: "9%" }}> <Select
                                                            native
                                                            onChange={(event) => this.updateStateForValues(event.target.value, "axis", "Textbox", true, feature)}
                                                            value={item.spectral_features.axis}
                                                            error={this.props.errorModel[feature + "axis"] ? true : false}
                                                            input={
                                                                <OutlinedInput
                                                                    variant="outlined"
                                                                    name="axis"
                                                                    id="language-native-simple"
                                                                    labelWidth={0}
                                                                    className={this.props.classes.textFieldTable}
                                                                    classes={{ input: this.props.classes.outlinedInput }}
                                                                />
                                                            }
                                                        >
                                                            {<option key={""} value={""} disabled>Select</option>}
                                                            {AxisList !== null && AxisList !== 'null' && AxisList.length > 0 ?
                                                                AxisList.map(option => (<option key={option} value={option}>{option}
                                                                </option>))
                                                                : <option key={""} value={""}></option>
                                                            }
                                                        </Select>
                                                        <div style={{ marginLeft: 7, paddingTop: '8px', fontSize: '0.75rem', wordWrap: 'break-word', color: 'red' }}>
                                                                {this.props.errorModel[feature + "axis"] ? this.props.errorModel[feature + "axis"] : null}
                                                            </div></td>
                                                        <td className="tableCounttd"> <TextField
                                                            variant="outlined"
                                                            type="text"
                                                            className={this.props.classes.textFieldTable}
                                                            onChange={(event) => this.updateStateForValues(event.target.value, "multiplier", "number", true, feature)}
                                                            value={item.spectral_features.multiplier ? item.spectral_features.multiplier:""}
                                                            error={this.props.errorModel[feature + "multiplier"] ? true : false}
                                                            helperText={this.props.errorModel[feature + "multiplier"] ? this.props.errorModel[feature + "multiplier"] : null}
                                                            InputProps={{
                                                                classes: {
                                                                    input: this.props.classes.outlinedInput
                                                                }
                                                            }}
                                                        /></td>
                                                        <td className="tableCounttd">
                                                            <TextField
                                                                variant="outlined"
                                                                type="text"
                                                                className={this.props.classes.textFieldTable}
                                                                onChange={(event) => this.updateStateForValues(event.target.value, "band", "number", true, feature)}
                                                                value={item.spectral_features.band ?item.spectral_features.band :""}
                                                                error={this.props.errorModel[feature + "band"] ? true : false}
                                                                helperText={this.props.errorModel[feature + "band"] ? this.props.errorModel[feature + "band"] : null}
                                                                InputProps={{
                                                                    classes: {
                                                                        input: this.props.classes.outlinedInput
                                                                    }
                                                                }}
                                                            />
                                                        </td>
                                                        <td className="tableCounttd" style={{ width: "9%" }}><TextField
                                                            variant="outlined"
                                                            type="text"
                                                            className={this.props.classes.textFieldTable}
                                                            onChange={(event) => this.updateStateForValues(event.target.value, "unit", "Textbox", true, feature)}
                                                            value={item.spectral_features.unit ?item.spectral_features.unit :""}
                                                            error={this.props.errorModel[feature + "unit"] ? true : false}
                                                            helperText={this.props.errorModel[feature + "unit"] ? this.props.errorModel[feature + "unit"] : null}
                                                            InputProps={{
                                                                classes: {
                                                                    input: this.props.classes.outlinedInput
                                                                }
                                                            }}
                                                        /></td>
                                                        <td className="tableCounttd"><TextField
                                                            variant="outlined"
                                                            type="text"
                                                            className={this.props.classes.textFieldTable}
                                                            onChange={(event) => this.updateStateForThreshold(event.target.value, "Operational", feature)}
                                                            value={item.Operational.threshold_value ? item.Operational.threshold_value:""}
                                                            error={this.props.errorModel[feature + "Operational"] ? true : false}
                                                            helperText={this.props.errorModel[feature + "Operational"] ? this.props.errorModel[feature + "Operational"] : null}
                                                            InputProps={{
                                                                classes: {
                                                                    input: this.props.classes.outlinedInput
                                                                }
                                                            }}
                                                        /></td>
                                                        <td className="tableCounttd"><TextField
                                                            variant="outlined"
                                                            type="text"
                                                            className={this.props.classes.textFieldTable}
                                                            onChange={(event) => this.updateStateForThreshold(event.target.value, "Caution", feature)}
                                                            value={item.Caution.threshold_value ? item.Caution.threshold_value:""}
                                                            error={this.props.errorModel[feature + "Caution"] ? true : false}
                                                            helperText={this.props.errorModel[feature + "Caution"] ? this.props.errorModel[feature + "Caution"] : null}
                                                            InputProps={{
                                                                classes: {
                                                                    input: this.props.classes.outlinedInput
                                                                }
                                                            }}
                                                        /></td>
                                                        <td className="tableCounttd"><TextField
                                                            variant="outlined"
                                                            type="text"
                                                            className={this.props.classes.textFieldTable}
                                                            onChange={(event) => this.updateStateForThreshold(event.target.value, "Warning", feature)}
                                                            value={item.Warning.threshold_value ?item.Warning.threshold_value :""}
                                                            error={this.props.errorModel[feature + "Warning"] ? true : false}
                                                            helperText={this.props.errorModel[feature + "Warning"] ? this.props.errorModel[feature + "Warning"] : null}
                                                            InputProps={{
                                                                classes: {
                                                                    input: this.props.classes.outlinedInput
                                                                }
                                                            }}
                                                        /></td>
                                                    </tr>
                                                }
                                            </>)
                                    })) : ""
                        }
                    </table>
                </>
                }

                {/* phase table */}
                {phaseDiff.length > 0 && <>
                    <span className="bg-Operational">{this.props.t("Phase Difference")}</span><br />
                    <table className="tableCount">
                        <tr className="tableCounttd">
                            <th className="tableCounttd"></th>
                            <th className="tableCounttd wd">{this.props.t("Feature Name")} <span className={this.props.classes.requiredClass}>*</span></th>
                            <th className="tableCounttd">{this.props.t("Axis 1")} <span className={this.props.classes.requiredClass}>*</span></th>
                            <th className="tableCounttd">{this.props.t("Axis 2")} <span className={this.props.classes.requiredClass}>*</span></th>
                            <th className="tableCounttd">{this.props.t("Unit")} <span className={this.props.classes.requiredClass}>*</span></th>
                            <th className="tableCounttd" style={{ width: "10%" }}>{this.props.t("Dashborad Dial")}</th>
                        </tr>
                        {
                            keys.length > 0 ?
                                (
                                    keys.map((feature, index) => {
                                        var item = this.props.jsonData.conditions[feature];
                                        //  var keys = Object.keys(item);
                                        return (
                                            this.props.features.includes(feature) ? "" : <>
                                                {item.featureType === "PHASEDIFF" &&
                                                    <tr>
                                                        <td className="tableCounttd"><input type="checkbox"
                                                            className="checkbox"

                                                            style={{
                                                                paddingTop: "5px", width: "40px",
                                                                height: "18px"
                                                            }}
                                                            ref={'ref_' + index}
                                                            //checked={this.state.isSelectedCheckboxes[index]}
                                                            onChange={(event) => this.checkedFaults(feature, index)}
                                                            color="secondary"
                                                            classes={{
                                                                root: this.props.classes.defaultCheckbox
                                                            }}
                                                        /></td>
                                                        <td className="tableCounttd wd"><TextField
                                                            variant="outlined"
                                                            type="text"
                                                            placeholder={this.props.t("Enter feature name here")}
                                                            className={this.props.classes.textFieldTable}
                                                            onChange={(event) => this.updateStateForValues(event.target.value, "displayLabel", "Textbox", true, feature)}
                                                            value={item.displayLabel}
                                                            error={this.props.errorModel[feature + "displayLabel"] ? true : false}
                                                            helperText={this.props.errorModel[feature + "displayLabel"] ? this.props.errorModel[feature + "displayLabel"] : null}
                                                            InputProps={{
                                                                classes: {
                                                                    input: this.props.classes.outlinedInput
                                                                }
                                                            }}
                                                        /></td>
                                                        <td className="tableCounttd wd"><Select
                                                            native
                                                            onChange={(event) => this.updateStateForValues(event.target.value, "axis1", "Textbox", true, feature)}
                                                            value={item.spectral_features.axis1}
                                                            error={this.props.errorModel[feature + "axis1"] ? true : false}
                                                            input={
                                                                <OutlinedInput
                                                                    variant="outlined"
                                                                    name="axis"
                                                                    id="language-native-simple"
                                                                    labelWidth={0}
                                                                    className={this.props.classes.textFieldTable}
                                                                    classes={{ input: this.props.classes.outlinedInput }}
                                                                />
                                                            }
                                                        >
                                                            {<option key={""} value={""} disabled>Select</option>}
                                                            {AxisList !== null && AxisList !== 'null' && AxisList.length > 0 ?
                                                                AxisList.map(option => (<option key={option} value={option}>{option}
                                                                </option>))
                                                                : <option key={""} value={""}></option>
                                                            }
                                                        </Select><div style={{ marginLeft: 7, paddingTop: '8px', fontSize: '0.75rem', wordWrap: 'break-word', color: 'red' }}>
                                                                {this.props.errorModel[feature + "axis1"] ? this.props.errorModel[feature + "axis1"] : null}
                                                            </div></td>
                                                        <td className="tableCounttd wd"><Select
                                                            native
                                                            onChange={(event) => this.updateStateForValues(event.target.value, "axis2", "Textbox", true, feature)}
                                                            value={item.spectral_features.axis2}
                                                            error={this.props.errorModel[feature + "axis2"] ? true : false}
                                                            input={
                                                                <OutlinedInput
                                                                    variant="outlined"
                                                                    name="axis"
                                                                    id="language-native-simple"
                                                                    labelWidth={0}
                                                                    className={this.props.classes.textFieldTable}
                                                                    classes={{ input: this.props.classes.outlinedInput }}
                                                                />
                                                            }
                                                        >
                                                            {<option key={""} value={""} disabled>Select</option>}
                                                            {AxisList !== null && AxisList !== 'null' && AxisList.length > 0 ?
                                                                AxisList.map(option => (<option key={option} value={option}>{option}
                                                                </option>))
                                                                : <option key={""} value={""}></option>
                                                            }
                                                        </Select><div style={{ marginLeft: 7, paddingTop: '8px', fontSize: '0.75rem', wordWrap: 'break-word', color: 'red' }}>
                                                                {this.props.errorModel[feature + "axis2"] ? this.props.errorModel[feature + "axis2"] : null}
                                                            </div></td>
                                                        <td className="tableCounttd wd"><TextField
                                                            variant="outlined"
                                                            type="text"
                                                            className={this.props.classes.textFieldTable}
                                                            onChange={(event) => this.updateStateForValues(event.target.value, "unit", "Textbox", true, feature)}
                                                            value={item.spectral_features.unit}
                                                            error={this.props.errorModel[feature + "unit"] ? true : false}
                                                            helperText={this.props.errorModel[feature + "unit"] ? this.props.errorModel[feature + "unit"] : null}
                                                            InputProps={{
                                                                classes: {
                                                                    input: this.props.classes.outlinedInput
                                                                }
                                                            }}
                                                        /></td>
                                                        <td className="tableCounttd"><Checkbox
                                                            style={{ paddingTop: "5px" }}
                                                            checked={item.dashboardDial}
                                                            onChange={(event) => this.updateStateForValues(!item.dashboardDial, "dashboardDial", "boolean", true, feature)}
                                                            color="secondary"
                                                            classes={{
                                                                root: this.props.classes.defaultCheckbox
                                                            }}
                                                        /></td>
                                                    </tr>

                                                }</>)
                                    })) : ""
                        }
                    </table>
                </>
                }


            </div >
        )


    }


    getDiagnoticsComponent() {
        var keys = Object.keys(this.props.jsonData.conditions);
        return (
            <div key={"diagnostics"} >
                <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                    <Typography style={{ margin: '1%' }} variant={"h6"}>{this.props.t("Diagnostics")}</Typography>
                    <Tooltip title={<span style={{ fontSize: "12px" }}>{this.props.t("Add Diagnostics")}</span>}>
                        <AddBox
                            classes={{ root: this.props.classes.addIcon }}
                            onClick={(event) => (this.addDiagnostics(null, "feature"))}
                        />
                    </Tooltip>
                </div>
                <div className="row" style={{ marginBottom: "10px", fontSize: "14px", marginLeft: "15px" }}>
                    {this.props.t("(Only applicable for V2.0 and onward devices)")}
                </div>

                {
                    this.props.jsonData.diagnostics && this.props.jsonData.diagnostics.length > 0 ?
                        (
                            this.props.jsonData.diagnostics.map((item, index) => {
                                return (
                                    <ExpansionPanel key={index} expanded={item.expand ? item.expand : false} onChange={() => { this.handleDiagnosticsExpand(index, !item.expand) }}>
                                        <ExpansionPanelSummary
                                            expandIcon={<ExpandMore />}
                                            aria-controls="panel1a-content"
                                            id="panel1a-header"
                                            classes={{ content: this.props.classes.expansionPanelSummary }}
                                        >
                                            <Typography variant={"subtitle1"}
                                                style={{ width: '100%', display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                                <ul className="this.props.classes.statecolor">
                                                    <li> {item.displayLabel ? item.displayLabel : "New Diagnostic"}</li>
                                                </ul>
                                                <Tooltip title={<span style={{ fontSize: "12px" }}>{this.props.t("Delete Diagnostic")}</span>}>
                                                    <Delete
                                                        classes={{ root: this.props.classes.deleteIcon }}
                                                        onClick={(event) => (this.removeDiagnosticFault(index, null, "feature"))}
                                                    />
                                                </Tooltip>
                                            </Typography>
                                        </ExpansionPanelSummary>
                                        <ExpansionPanelDetails
                                            classes={{ root: this.props.classes.expansionPanelDetails }}
                                        >
                                            <>
                                                <div className="row">
                                                    <div className={this.props.classes.inputFieldDivColumn} key={"diagnotic" + index}>
                                                        <InputLabel className={this.props.classes.textFieldLabel}> {this.props.t("Diagnostic Name")} <span className={this.props.classes.requiredClass}>*</span></InputLabel>
                                                        <TextField
                                                            variant="outlined"
                                                            type="text"
                                                            className={this.props.classes.textField}
                                                            onChange={(event) => this.updateStateForDValues(event.target.value, "displayLabel", "Textbox", true, index, null)}
                                                            value={item.displayLabel}
                                                            error={this.props.errorModel["displayLabel" + index] ? true : false}
                                                            helperText={this.props.errorModel["displayLabel" + index] ? this.props.errorModel["displayLabel" + index] : null}
                                                            InputProps={{
                                                                classes: {
                                                                    input: this.props.classes.outlinedInput
                                                                }
                                                            }}
                                                        />
                                                    </div>

                                                    <div className={this.props.classes.inputFieldDivColumn} style={{ width: "66.66%" }} key={"recommendation" + index}>
                                                        <InputLabel className={this.props.classes.textFieldLabel} style={{ width: "25%" }}> {this.props.t("Alarm Recommendation")} </InputLabel>
                                                        <TextField
                                                            variant="outlined"
                                                            type="text"
                                                            style={{ width: "75%" }}
                                                            className={this.props.classes.textField}
                                                            onChange={(event) => this.updateStateForDValues(event.target.value, "recommendation", "Textbox", false, index, null)}
                                                            value={item.recommendation}
                                                            error={this.props.errorModel["recommendation" + index] ? true : false}
                                                            helperText={this.props.errorModel["recommendation" + index] ? this.props.errorModel["recommendation" + index] : null}
                                                            InputProps={{
                                                                classes: {
                                                                    input: this.props.classes.outlinedInput
                                                                }
                                                            }}
                                                        />
                                                    </div>

                                                    <div hidden className={this.props.classes.inputFieldDivColumn} key={"alertBuffer" + index}>
                                                        <InputLabel className={this.props.classes.textFieldLabel}> {this.props.t("Alert Buffer[in sec]")}</InputLabel>
                                                        <TextField
                                                            variant="outlined"
                                                            type="text"
                                                            className={this.props.classes.textField}
                                                            onChange={(event) => this.updateStateForDValues(event.target.value, "alertBuffer", "number", false, index, null)}
                                                            value={item.alertBuffer}
                                                            error={this.props.errorModel["alertBuffer" + index] ? true : false}
                                                            helperText={this.props.errorModel["alertBuffer" + index] ? this.props.errorModel["alertBuffer" + index] : null}
                                                            InputProps={{
                                                                classes: {
                                                                    input: this.props.classes.outlinedInput
                                                                }
                                                            }}
                                                        />
                                                    </div>
                                                    <div hidden className={this.props.classes.inputFieldDivColumn} key={"repeatFrequency" + index}>
                                                        <InputLabel className={this.props.classes.textFieldLabel}> {this.props.t("Repeat Alert[in min]")}</InputLabel>
                                                        <TextField
                                                            variant="outlined"
                                                            type="text"
                                                            className={this.props.classes.textField}
                                                            onChange={(event) => this.updateStateForDValues(event.target.value, "repeatFrequency", "number", false, index, null)}
                                                            value={item.repeatFrequency}
                                                            error={this.props.errorModel["repeatFrequency" + index] ? true : false}
                                                            helperText={this.props.errorModel["repeatFrequency" + index] ? this.props.errorModel["repeatFrequency" + index] : null}
                                                            InputProps={{
                                                                classes: {
                                                                    input: this.props.classes.outlinedInput
                                                                }
                                                            }}
                                                        />
                                                    </div>
                                                </div>
                                                <div>
                                                    {
                                                        item.triggers.map((trigger, index1) => {
                                                            return (
                                                                <div key={"trigger" + index + index1}>
                                                                    <Typography className={this.props.classes.header2} variant={"subtitle2"}>
                                                                        {
                                                                            trigger.displayLabel && trigger.displayLabel != "" ? trigger.displayLabel : this.props.t("New Trigger")
                                                                        }
                                                                        {
                                                                            index1 > 0 ?
                                                                                (<Tooltip title={<span style={{ fontSize: "12px" }}>{this.props.t("Delete Trigger")}</span>}>
                                                                                    <Delete
                                                                                        classes={{ root: this.props.classes.deleteIcon }}
                                                                                        onClick={(event) => (this.removeDiagnosticFault(index, index1, "trigger"))}
                                                                                    />
                                                                                </Tooltip>)
                                                                                : (
                                                                                    <Tooltip title={<span style={{ fontSize: "12px" }}>{this.props.t("Add Trigger")}</span>}>
                                                                                        <AddBox
                                                                                            classes={{ root: this.props.classes.addIcon }}
                                                                                            onClick={(event) => (this.addDiagnostics(index, "trigger"))}
                                                                                        />
                                                                                    </Tooltip>
                                                                                )
                                                                        }

                                                                    </Typography>

                                                                    <div className="row" style={{ marginBottom: "10px" }}>

                                                                        <div className={this.props.classes.inputFieldDivColumn} key={"Feature1" + index + index1}>
                                                                            <div style={{ display: 'flex', flexDirection: 'column', width: "100%" }}>
                                                                                <div style={{ display: 'inline-flex' }}>
                                                                                    <InputLabel style={{ marginTop: "10px" }} className={this.props.classes.textFieldLabel}>{this.props.t("Feature1")} <span className={this.props.classes.requiredClass}>*</span></InputLabel>
                                                                                    <Select
                                                                                        native
                                                                                        style={{ marginLeft: "10px" }}
                                                                                        value={trigger.feature1}
                                                                                        onChange={(event) => this.updateStateForDValues(event.target.value, "feature1", "Textbox", true, index, index1)}
                                                                                        input={
                                                                                            <OutlinedInput
                                                                                                variant="outlined"
                                                                                                name="monitorTypeId"
                                                                                                id="language-native-simple"
                                                                                                labelWidth={0}
                                                                                                className={this.props.classes.textField}
                                                                                                error={this.props.errorModel["feature1" + index + index1] ? true : false}
                                                                                                classes={{ input: this.props.classes.outlinedInput }}
                                                                                            />
                                                                                        }
                                                                                    >
                                                                                        <option key={""} value={""} disabled>Select</option>
                                                                                        {
                                                                                            Object.keys(this.props.jsonData.conditions).map(option => {
                                                                                                if (!this.props.jsonData.conditions[option].isNew) {
                                                                                                    return (<option key={option} value={option}>{this.props.jsonData.conditions[option].displayLabel ? this.props.jsonData.conditions[option].displayLabel : option}
                                                                                                    </option>)
                                                                                                }
                                                                                            })

                                                                                        }
                                                                                    </Select>
                                                                                </div>

                                                                                <div style={{ marginLeft: 170, paddingTop: '8px', fontSize: '0.75rem', wordWrap: 'break-word', color: 'red' }}>
                                                                                    {this.props.errorModel["feature1" + index + index1] ? this.props.errorModel["feature1" + index + index1] : null}
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        <div className={this.props.classes.inputFieldDivColumn} key={"Feature2" + index + index1}>
                                                                            <div style={{ display: 'flex', flexDirection: 'column', width: "100%" }}>
                                                                                <div style={{ display: 'inline-flex' }}>
                                                                                    <InputLabel style={{ marginTop: "10px" }} className={this.props.classes.textFieldLabel}>{this.props.t("Feature2")}</InputLabel>
                                                                                    <Select
                                                                                        native
                                                                                        style={{ marginLeft: "10px" }}
                                                                                        value={trigger.feature2}
                                                                                        onChange={(event) => this.updateStateForDValues(event.target.value, "feature2", "Textbox", true, index, index1)}
                                                                                        input={
                                                                                            <OutlinedInput
                                                                                                variant="outlined"
                                                                                                name="monitorTypeId"
                                                                                                id="language-native-simple"
                                                                                                labelWidth={0}
                                                                                                className={this.props.classes.textField}
                                                                                                error={this.props.errorModel["feature2" + index + index1] ? true : false}
                                                                                                classes={{ input: this.props.classes.outlinedInput }}
                                                                                            />
                                                                                        }
                                                                                    >
                                                                                        <option key={""} value={""} >Select</option>
                                                                                        {
                                                                                            Object.keys(this.props.jsonData.conditions).map(option => {
                                                                                                if (!this.props.jsonData.conditions[option].isNew) {
                                                                                                    return (<option key={option} value={option}>{this.props.jsonData.conditions[option].displayLabel ? this.props.jsonData.conditions[option].displayLabel : option}
                                                                                                    </option>)
                                                                                                }
                                                                                            })

                                                                                        }
                                                                                    </Select>
                                                                                </div>

                                                                                <div style={{ marginLeft: 170, paddingTop: '8px', fontSize: '0.75rem', wordWrap: 'break-word', color: 'red' }}>
                                                                                    {this.props.errorModel["feature2" + index + index1] ? this.props.errorModel["feature2" + index + index1] : null}
                                                                                </div>
                                                                            </div>

                                                                        </div>
                                                                        <div className={this.props.classes.inputFieldDivColumn} key={"operator" + index + index1}>
                                                                            <div style={{ display: 'flex', flexDirection: 'column', width: "100%" }}>
                                                                                <div style={{ display: 'inline-flex' }}>
                                                                                    <InputLabel style={{ marginTop: "10px", lineHeight: "1" }} className={this.props.classes.textFieldLabel}>{this.props.t("Feature Comparison Operator")} <span className={this.props.classes.requiredClass}>*</span></InputLabel>
                                                                                    <Select
                                                                                        native
                                                                                        style={{ marginLeft: "10px" }}
                                                                                        value={trigger.operator}
                                                                                        onChange={(event) => this.updateStateForDValues(event.target.value, "operator", "Textbox", true, index, index1)}
                                                                                        input={
                                                                                            <OutlinedInput
                                                                                                variant="outlined"
                                                                                                id="language-native-simple"
                                                                                                labelWidth={0}
                                                                                                className={this.props.classes.textField}
                                                                                                error={this.props.errorModel["operator" + index + index1] ? true : false}
                                                                                                classes={{ input: this.props.classes.outlinedInput }}
                                                                                            />
                                                                                        }
                                                                                    >
                                                                                        <option key={""} value={""} disabled>Select</option>
                                                                                        {
                                                                                            operatorList.map(option => (<option key={option} value={option}>{option}
                                                                                            </option>))

                                                                                        }
                                                                                    </Select>
                                                                                </div>

                                                                                <div style={{ marginLeft: 185, paddingTop: '8px', fontSize: '0.75rem', wordWrap: 'break-word', color: 'red' }}>
                                                                                    {this.props.errorModel["operator" + index + index1] ? this.props.errorModel["operator" + index + index1] : null}
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div className="row">
                                                                        <div className={this.props.classes.inputFieldDivColumn} key={"comparator" + index + index1}>
                                                                            <div style={{ display: 'flex', flexDirection: 'column', width: "100%" }}>
                                                                                <div style={{ display: 'inline-flex' }}>

                                                                                    <InputLabel style={{ marginTop: "10px" }} className={this.props.classes.textFieldLabel}> {this.props.t("Comparator")} <span className={this.props.classes.requiredClass}>*</span></InputLabel>
                                                                                    <Select
                                                                                        native
                                                                                        style={{ marginLeft: "10px" }}

                                                                                        value={trigger.comparator}
                                                                                        onChange={(event) => this.updateStateForDValues(event.target.value, "comparator", "Textbox", true, index, index1)}
                                                                                        input={
                                                                                            <OutlinedInput
                                                                                                variant="outlined"
                                                                                                name="monitorTypeId"
                                                                                                id="language-native-simple"
                                                                                                labelWidth={0}
                                                                                                className={this.props.classes.textField}
                                                                                                error={this.props.errorModel["comparator" + index + index1] ? true : false}
                                                                                                classes={{ input: this.props.classes.outlinedInput }}
                                                                                            />
                                                                                        }
                                                                                    >
                                                                                        <option key={""} value={""} disabled>{this.props.t("Select")}</option>
                                                                                        {
                                                                                            comparatorList.map(option => (<option key={option} value={option}>{option}
                                                                                            </option>))

                                                                                        }
                                                                                    </Select>
                                                                                </div>

                                                                                <div style={{ marginLeft: 185, paddingTop: '8px', fontSize: '0.75rem', wordWrap: 'break-word', color: 'red' }}>
                                                                                    {this.props.errorModel["comparator" + index + index1] ? this.props.errorModel["comparator" + index + index1] : null}
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        <div className={this.props.classes.inputFieldDivColumn} key={"threshold" + index + index1}>
                                                                            <InputLabel className={this.props.classes.textFieldLabel}> {this.props.t("Threshold")} <span className={this.props.classes.requiredClass}>*</span></InputLabel>
                                                                            <TextField
                                                                                variant="outlined"
                                                                                type="text"
                                                                                className={this.props.classes.textField}
                                                                                onChange={(event) => this.updateStateForDValues(event.target.value, "threshold", "number", true, index, index1)}
                                                                                value={trigger.threshold}
                                                                                error={this.props.errorModel["threshold" + index + index1] ? true : false}
                                                                                helperText={this.props.errorModel["threshold" + index + index1] ? this.props.errorModel["threshold" + index + index1] : null}
                                                                                InputProps={{
                                                                                    classes: {
                                                                                        input: this.props.classes.outlinedInput
                                                                                    }
                                                                                }}
                                                                            />
                                                                        </div>
                                                                        <div className={this.props.classes.inputFieldDivColumn} key={"istriggermandatory"}>
                                                                            <div style={{ display: 'flex', width: "100%", flexDirection: 'column' }}>
                                                                                <div style={{ display: 'inline-flex' }}>
                                                                                    <InputLabel className={this.props.classes.textFieldLabel} style={{ paddingTop: '8px' }}>{this.props.t("Trigger Is Mandatory")}</InputLabel>
                                                                                    <Checkbox
                                                                                        style={{ paddingTop: "0px" }}
                                                                                        checked={trigger.isMandatory}
                                                                                        onChange={(event) => this.updateStateForDValues(!trigger.isMandatory, "isMandatory", "boolean", true, index, index1)}
                                                                                        color="secondary"
                                                                                        classes={{
                                                                                            root: this.props.classes.defaultCheckbox
                                                                                        }}
                                                                                    />

                                                                                </div>

                                                                            </div>
                                                                        </div>

                                                                    </div>

                                                                </div>

                                                            )
                                                        })
                                                    }
                                                </div>

                                            </>

                                        </ExpansionPanelDetails>
                                    </ExpansionPanel>

                                )
                            })

                        ) : ""
                }

            </div >
        )


    }


    render() {
        const { t } = this.props;
        return (
            <Fragment>
                {/*this.getWidgets()*/}

                {
                    <div key={"spectral_monitoring"}>
                        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                            <Typography className={this.props.classes.header} style={{ margin: '1%' }} variant={"h6"}>{this.props.t("Equipment Speed")}</Typography>
                        </div>
                        <div className="row">
                            <div className={this.props.classes.inputFieldDiv} key={"rotating_speed_type"}>
                                <InputLabel style={{ marginTop: "10px" }} className={this.props.classes.textFieldLabel}>{this.props.t("Rotating Speed Type")}<span className={this.props.classes.requiredClass}>*</span></InputLabel>
                                <Select
                                    native
                                    onChange={(event) => this.updateStateForSpectralMonitoring(event.target.value, "rotating_speed_type", "additional_attrs")}
                                    value={this.props.jsonData.additional_attrs.rotating_speed_type}
                                    style={{ marginLeft: "10px" }}
                                    input={
                                        <OutlinedInput
                                            variant="outlined"
                                            name="monitorTypeId"
                                            id="language-native-simple"
                                            labelWidth={0}
                                            className={this.props.classes.textField}
                                            classes={{ input: this.props.classes.outlinedInput }}
                                        />
                                    }
                                >
                                    <option key={""} value={""} disabled>{this.props.t("Select")}</option>
                                    {RotatingSpeedType !== null && RotatingSpeedType !== 'null' && RotatingSpeedType.length > 0 ?
                                        RotatingSpeedType.map(option =>
                                        (<option key={option.code} value={option.code}>{this.props.t(option.displayValue)}
                                        </option>))
                                        : <option key={""} value={""}></option>
                                    }
                                </Select>
                            </div>
                            {
                                this.props.jsonData.additional_attrs.rotating_speed_type === "FIXED" ?
                                    (
                                        <div className={this.props.classes.inputFieldDiv} key={"rotating_speed"}>
                                            <InputLabel className={this.props.classes.textFieldLabel}>{this.props.t("Rotating Speed (RPM)")}<span className={this.props.classes.requiredClass}>*</span></InputLabel>
                                            <TextField
                                                id={"rotating_speed"}
                                                name={"rotating_speed"}
                                                variant="outlined"
                                                type="text"
                                                className={this.props.classes.rpmTextField}
                                                onChange={(event) => this.updateStateForSpectralMonitoring(event.target.value, "rotating_speed", "additional_attrs")}
                                                value={this.props.jsonData.additional_attrs.rotating_speed === 0 ? null : this.props.jsonData.additional_attrs.rotating_speed}
                                                //error={item.deleted}
                                                error={this.props.errorModel["rotating_speed"] ? true : false}
                                                helperText={this.props.errorModel["rotating_speed"]}//change
                                                InputProps={{
                                                    classes: {
                                                        input: this.props.classes.outlinedInput
                                                    }
                                                }}
                                            />
                                        </div>
                                    ) : (
                                        <>
                                            <div className={this.props.classes.inputFieldDivSpeed} style={{ width: "20%" }} key={"rotatingSpeed"}>
                                                <InputLabel style={{ width: "100%", marginTop: "15px" }} className={this.props.classes.textFieldLabel}>
                                                    {this.props.t("Rotating Speed Range (RPM)")}<span className={this.props.classes.requiredClass}>*</span>
                                                </InputLabel>
                                            </div>
                                            <div className={this.props.classes.inputFieldDivSpeed} key={"from"}>
                                                <InputLabel className={this.props.classes.textFieldLabel}>{this.props.t("From")}</InputLabel>
                                                <TextField
                                                    id={"from"}
                                                    name={"from"}
                                                    variant="outlined"
                                                    type="text"
                                                    className={this.props.classes.rpmTextField}
                                                    onChange={(event) => this.updateStateForSpectralMonitoring(event.target.value, "from", "additional_attrs")}
                                                    value={this.props.jsonData.additional_attrs.rotating_speed_range.from}
                                                    error={this.props.errorModel["from"] ? true : false}
                                                    helperText={this.props.errorModel["from"]}//change
                                                    InputProps={{
                                                        classes: {
                                                            input: this.props.classes.outlinedInput
                                                        }
                                                    }}
                                                />
                                            </div>
                                            <div className={this.props.classes.inputFieldDivSpeed} key={"To"}>
                                                <InputLabel className={this.props.classes.textFieldLabel}>{this.props.t("To")}</InputLabel>
                                                <TextField
                                                    id={"To"}
                                                    name={"To"}
                                                    variant="outlined"
                                                    type="text"
                                                    className={this.props.classes.textField}
                                                    onChange={(event) => this.updateStateForSpectralMonitoring(event.target.value, "to", "additional_attrs")}
                                                    value={this.props.jsonData.additional_attrs.rotating_speed_range.to}
                                                    error={this.props.errorModel["to"] ? true : false}
                                                    helperText={this.props.errorModel["to"]}//change
                                                    InputProps={{
                                                        classes: {
                                                            input: this.props.classes.outlinedInput
                                                        }
                                                    }}
                                                />
                                            </div>
                                        </>
                                    )
                            }
                        </div>
                    </div>
                }
                < div style={{ marginTop: "60px", marginBottom: "30px" }}>
                    <Divider style={{ background: "grey" }} />
                </div>
                {this.getSpectralComponent()}
                < div style={{ marginTop: "30px", marginBottom: "10px" }}>
                    <Divider style={{ background: "grey" }} />
                </div>
                {this.getAttributeComponent()}
                <div style={{ marginTop: "10px" }}>
                    <Divider style={{ background: "grey" }} />
                </div>
                {this.getBasicFeatureComponent()}
                {
                    this.props.monitorCodeName == 'VELMONWITHFAULTS' ?
                        (<div style={{ marginTop: "20px", marginBottom: "10px" }}>
                            <Divider style={{ background: "grey" }} />
                        </div>
                        ) : ""
                }
                {
                    this.props.monitorCodeName == 'VELMONWITHFAULTS' ?
                        this.getAdvanceFeatureComponent() : ""
                }
                {
                    this.props.monitorCodeName == 'VELMONWITHFAULTS' ?
                        (<div style={{ marginTop: "20px", marginBottom: "10px" }}>
                            <Divider style={{ background: "grey" }} />
                        </div>
                        ) : ""
                }
                {this.props.monitorCodeName == 'VELMONWITHFAULTS' ? this.getDiagnoticsComponent() : ""}

                <Dialog
                    open={this.state.openFeatureTypeSelectionDialog}
                    onClose={this.closeAdvancedFeatureDialog}
                    maxWidth={'sm'}
                    fullWidth={true}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <DialogContent className={this.props.classes.dropdownContent}>
                        <InputLabel className={this.props.classes.textFieldLabel} style={{ paddingTop: '8px' }}>{this.props.t("Select Feature Type")}</InputLabel>
                        <Select
                            //  native
                            value={this.state.featureType}
                            onChange={(event) => this.updateFeature(event.target.value)}
                            input={
                                <OutlinedInput
                                    variant="outlined"
                                    name="featureType"
                                    id="language-native-simple"
                                    labelWidth={0}
                                    className={this.props.classes.textField}
                                    // error={item.deleted || item.error || item.value === ""}
                                    classes={{ input: this.props.classes.outlinedInput }}
                                />
                            }
                        >
                            {featureTypeList !== null && featureTypeList !== 'null' && featureTypeList.length > 0 ?
                                featureTypeList.map(feature => (
                                    <MenuItem key={feature.displayLabel} value={feature.code}>
                                        {feature.displayLabel}
                                    </MenuItem>))
                                : <MenuItem key={""} value={""}></MenuItem>
                            }
                        </Select>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={(event) => this.handleAdvancedFeature()} disabled={this.state.featureType === "" || this.state.featureType.trim() === ""} color="primary" autoFocus>
                            {t("OK")}
                        </Button>
                        <Button onClick={(event) => this.closeAdvancedFeatureDialog()} color="primary" autoFocus>
                            {t("Cancel")}
                        </Button>
                    </DialogActions>
                </Dialog>

                <Dialog
                    open={this.state.openFaultDialog}
                    onClose={this.handleCancel}
                    maxWidth={'md'}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <DialogContent className={this.props.classes.content}>
                        {t("Would you like to assign the bearing faults associated with the selected bearing type?")}
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={(event) => this.handleOk()} color="primary" autoFocus>
                            {t("OK")}
                        </Button>
                        <Button onClick={(event) => this.handleCancel()} color="primary" autoFocus>
                            {t("Cancel")}
                        </Button>
                    </DialogActions>
                </Dialog>
                <Dialog
                    open={this.state.openDeleteDiagnosticFaultDialog}
                    onClose={this.closeDeleteDiagnosticFaultDialog}
                    maxWidth={'md'}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <DialogContent className={this.props.classes.content}>
                        {t("Are you sure, you want to remove this dignostic feature from this Monitoring Location?")}                    </DialogContent>
                    <DialogActions>
                        <Button onClick={(event) => this.removeDiagnostics()} color="primary" autoFocus>
                            {t("OK")}
                        </Button>
                        <Button onClick={(event) => this.closeDeleteDiagnosticFaultDialog()} color="primary" autoFocus>
                            {t("Cancel")}
                        </Button>
                    </DialogActions>
                </Dialog>
                <Dialog
                    open={this.state.openDeleteTriggerFaultDialog}
                    onClose={this.closeDeleteTriggerFaultDialog}
                    maxWidth={'md'}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <DialogContent className={this.props.classes.content}>
                        {t("Are you sure, you want to remove this trigger from this Monitoring Location?")}                    </DialogContent>
                    <DialogActions>
                        <Button onClick={(event) => this.removeDiagnostics()} color="primary" autoFocus>
                            {t("OK")}
                        </Button>
                        <Button onClick={(event) => this.closeDeleteTriggerFaultDialog()} color="primary" autoFocus>
                            {t("Cancel")}
                        </Button>
                    </DialogActions>
                </Dialog>

                <Dialog
                    open={this.state.openDeleteFaultDialog}
                    onClose={this.closeDeleteFaultDialog}
                    maxWidth={'md'}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <DialogContent className={this.props.classes.content}>
                        {t("Are you sure, you want to remove this advanced feature from this Monitoring Location?")}                    </DialogContent>
                    <DialogActions>
                        <Button onClick={(event) => this.deleteFault()} color="primary" autoFocus>
                            {t("OK")}
                        </Button>
                        <Button onClick={(event) => this.closeDeleteFaultDialog()} color="primary" autoFocus>
                            {t("Cancel")}
                        </Button>
                    </DialogActions>
                </Dialog>
                <Dialog
                    open={this.state.openRemoveFaultDialog}
                    onClose={this.closeRemoveFaultDialog}
                    maxWidth={'md'}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <DialogContent className={this.props.classes.content}>
                        {t("Do you want to remove the bearing faults associated with this bearing type?")}                    </DialogContent>
                    <DialogActions>
                        <Button onClick={(event) => this.removeBearingFault()} color="primary" autoFocus>
                            {t("OK")}
                        </Button>
                        <Button onClick={(event) => this.closeRemoveFaultDialog()} color="primary" autoFocus>
                            {t("Cancel")}
                        </Button>
                    </DialogActions>
                </Dialog>
            </Fragment >
        )
    }
}

export default withTranslation()((withStyles(styles)(MonitorSpecs)));