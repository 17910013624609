import React, { Component } from 'react'
import TimeKeeper from 'react-timekeeper';
import PickyDateTime from 'react-picky-date-time';
import ShiftSelector from './ShiftSelector';
import { withTranslation } from "react-i18next";

class DateRangeSelector extends Component {
    constructor(props){
        super(props);

        var today = new Date(),
        time = today.getHours() + ':' + today.getMinutes();

        this.state = {
            activeItem: { 1: true },
            activeView: { 1: true },
            startDate:'',
            startTime:'',
            endDate:'',
            endTime:'',
            startFlag: false,
            endFlag: false,
            time: time,
            timeNext: time,
            isCalendar: false,
            isCalendarNext: false,
            selectedDay: {year: today.getFullYear(), month: today.getMonth()+1, date: today.getDate()},
            selectedDayNext: {year: today.getFullYear(), month: today.getMonth()+1, date: today.getDate()},
            clocktime: undefined,
            clocktimeNext: undefined
        }
    }

    ValidateStartDate = (startDate,endDate) => {
        let today = new Date();
        if(new Date(startDate) > new Date(endDate)){
            alert("Start date should be less than end date ");
            this.setState({ selectedDay : {year: today.getFullYear(), month: today.getMonth()+1, date: today.getDate()}});
            this.setState({ selectedDayNext : {year: today.getFullYear(), month: today.getMonth()+1, date: today.getDate()}});
        }
    }
    
    componentDidUpdate(prevProps) {
        let startDate,endDate,startTime,endTime;   
        if (prevProps.range !== this.props.range) {
            startDate = new Date(this.props.range[1]);
            startDate.toTimeString();
            endDate = new Date(this.props.range[2]);
            endDate.toTimeString();
            this.setState({selectedDay: {year: startDate.getFullYear(), month: startDate.getMonth()+1, date: startDate.getDate() }})
            this.setState({selectedDayNext: {year: endDate.getFullYear(), month: endDate.getMonth()+1, date: endDate.getDate() }})

            let setCurrentTime,setCurrentTimeNext;
            
            if(startDate.getHours()===0 && startDate.getMinutes()===0){
                setCurrentTime = (startDate.getHours()+'0') + ':' + (startDate.getMinutes()+'0')
            }
            else if(startDate.getMinutes()===0 || startDate.getMinutes()<10 ){
                setCurrentTime = startDate.getHours() + ':' + (startDate.getMinutes()+'0')
            }
            else{
                setCurrentTime = startDate.getHours() + ':' + startDate.getMinutes()
            }

            if(endDate.getHours()===0 && endDate.getMinutes()===0){
                setCurrentTimeNext = (endDate.getHours()+'0') + ':' + (endDate.getMinutes()+'0')
            }
            else if(endDate.getMinutes()===0 || endDate.getMinutes()<10 ){
                setCurrentTimeNext = endDate.getHours() + ':' + (endDate.getMinutes()+'0')
            }
            else{
                setCurrentTimeNext = endDate.getHours() + ':' + endDate.getMinutes()
            }

            this.setState({time:  setCurrentTime, timeNext: setCurrentTimeNext,clocktime: setCurrentTime,clocktimeNext: setCurrentTimeNext})
            startTime = startDate.getHours() + ':' + startDate.getMinutes() + ':' + startDate.getSeconds();            
            startDate = startDate.getFullYear() +'/'+ (startDate.getMonth()+1) +'/'+ startDate.getDate();
            endTime = endDate.getHours() + ':' + endDate.getMinutes() + ':' + endDate.getSeconds();  
            endDate = endDate.getFullYear() +'/'+ (endDate.getMonth()+1) +'/'+ endDate.getDate();
      
            this.setState({ startDate : startDate, startTime : startTime, endDate : endDate, endTime : endTime },()=>{
                this.ValidateStartDate(this.state.startDate,this.state.endDate);
            })
            
        }
    }

    userSelectionFromCal = (e) => {
            const { time, timeNext, selectedDay, selectedDayNext, clocktime, clocktimeNext, startFlag, endFlag } = this.state
            let startingDate = !startFlag ? new Date() : new Date(`${selectedDay.year} ${selectedDay.month} ${selectedDay.date}`)
            let endingDate = !endFlag ? new Date(new Date().getTime() + (24 * 60 * 60 * 1000)) : new Date(`${selectedDayNext.year} ${selectedDayNext.month} ${selectedDayNext.date}`)
            let startingTime = clocktime ? clocktime : time
            let endingTime = clocktimeNext ? clocktimeNext : timeNext

            this.setState({ isCalendar: false, isCalendarNext: false });

            startingDate.setHours(startingTime.split(':')[0],startingTime.split(':')[1],0,0);
            endingDate.setHours(endingTime.split(':')[0],endingTime.split(':')[1],0,0);
            let dateRange = "userSelection";
            this.props.rangeSelect(e, dateRange, startingDate, endingDate)
    }

    toggleActiveItem = (e, listId, dateRange) => {
        this.setState({ activeItem: { [listId]: true } })
        this.props.rangeSelect(e, dateRange)
    }

    toggleActiveView = (e, listId) => {
        this.setState({ activeView: { [listId]: true } })
    }

    handleDayClick = (res) => {
        const { date, month, year } = res;
        this.setState({ startFlag: true, selectedDay: {year: year, month: month, date: date }});
    }

    handleDayClickNext = (res) => {
        const { date, month, year } = res;
        this.setState({ endFlag: true, selectedDayNext: {year: year, month: month, date: date }});
    }

    handleTimeChange = (newTime) => { 
        this.setState({ clocktime: newTime.formatted24, time: newTime});
    }

    handleTimeChangeNext = (newTime) => {
        this.setState({ clocktimeNext: newTime.formatted24, timeNext: newTime});
    }

    closeStart = (e) => {
        if (this.prodSearchNode != null && this.prodSearchCalendar != null) {
            if (this.prodSearchNode.contains(e.target) || this.prodSearchCalendar.contains(e.target)) {
                return;
            }
            this.searchClick("start");
        }
    }

    closeEnd = (e) => {
        if (this.prodSearchEndNode != null && this.prodSearchCalendarNext != null) {
            if (this.prodSearchEndNode.contains(e.target) || this.prodSearchCalendarNext.contains(e.target)) {
                return;
            }
            this.searchClick("end");
        }
    }

    searchClick = (data) => {
        if (data === "start") {
            if (!this.state.isCalendar) {
                document.addEventListener('click', this.closeStart, false);
                this.setState({isCalendar : true})
            }
            else {
                document.removeEventListener('click', this.closeStart, false);
                this.setState({isCalendar : false})
            }
        }
        else {
            if (!this.state.isCalendarNext) {
                document.addEventListener('click', this.closeEnd, false);
                this.setState({isCalendarNext : true})
            } else {
                document.removeEventListener('click', this.closeEnd, false);
                this.setState({isCalendarNext : false})
            }
        }
    }
    closeCal = () => {
        this.setState({isCalendar : false,isCalendarNext : false})
    }
    
    render() {
        const row = this.props.widgetsData.widgetData.production.DateRage;
        const {activeItem,activeView,startFlag,endFlag,startDate,endDate,startTime,endTime,isCalendar,isCalendarNext,timeNext} = this.state;
        const {selectedDay,selectedDayNext,clocktime,clocktimeNext} = this.state;
        const {shiftNumber,t} = this.props;
        return (  
            <div className="dashNavs">
                <div className="date-container">
                    <div className="heading">
                        <span className={activeView[1] ? 'text-bg-white' : ''} onClick={(e) => this.toggleActiveView(e, 1)}>{t("NORMAL VIEW")}</span>
                        <span className={activeView[2] ? 'text-bg-white' : ''} onClick={(e) => this.toggleActiveView(e, 2)}>{t("CLASSIC VIEW")}</span>
                        <span className='text-bg-white' style={{"float": "right","padding": "1px 12px"}}>{shiftNumber}</span>
                        
                    </div>

                    <ul>
                        {row && row.listItem.map((list, index) =>
                            <li key={index} >
                                <span className={activeItem[list.id] ? 'text-bg-white' : ''} onClick={(e) => this.toggleActiveItem(e, list.id, list.range)}>
                                {list.rangeValue}</span>
                            </li>
                        )}
                    </ul>  
                </div>
                
                

                <div className="customDate-container">
                    <h2 className="heading">{t("Custom Date Range")}</h2>
                    <div className="customDate-wrp">
                        <div className="customDate-start text-cursor" ref={prodSearchNode => { this.prodSearchNode = prodSearchNode; }} onClick={() => this.searchClick("start")}>                            
                            {!startFlag ?
                            <span>{startDate}</span> :
                            <span>{selectedDay.year}/{selectedDay.month}/{selectedDay.date}</span>
                            }
                            <span>  </span>
                            {clocktime ?
                                <span>{clocktime}:0</span> :
                                <span>{startTime}</span>
                            }
                        </div>
                        <p>to</p>
                        <div className="customDate-end text-cursor" ref={prodSearchEndNode => { this.prodSearchEndNode = prodSearchEndNode; }} onClick={() => this.searchClick("end")}>
                            {!endFlag ?                               
                                <span>{endDate}</span> :
                                <span>{selectedDayNext.year}/{selectedDayNext.month}/{selectedDayNext.date}</span>                                
                            }
                            <span>  </span>
                            {clocktimeNext ?
                                <span>{clocktimeNext}:0</span> :
                                <span>{endTime}</span>
                            }
                        </div>
                    </div>
                </div>

                {isCalendar ?
                    <div className="timeDate startCal widgets-shadow" ref={prodSearchCalendar => { this.prodSearchCalendar = prodSearchCalendar }}>
                        <PickyDateTime size="m" mode={0} defaultDate={`${selectedDay.month}/${selectedDay.date}/${selectedDay.year}`} locale="en-us" show={true} onDatePicked={res => this.handleDayClick(res)}/>                        
                        <TimeKeeper time={this.state.time} onChange={this.handleTimeChange} closeOnMinuteSelect={true} switchToMinuteOnHourSelect={true} />
                        <ShiftSelector plantShift={this.props.plantShift} rangeSelect={this.props.rangeSelect} closeCal={this.closeCal} userSelectionFromCal={this.userSelectionFromCal}/>
                    </div>
                    : null
                }
                {isCalendarNext ?
                    <div className="timeDate endCal widgets-shadow" ref={prodSearchCalendarNext => { this.prodSearchCalendarNext = prodSearchCalendarNext }}>
                        <PickyDateTime size="m" mode={0} defaultDate={`${selectedDayNext.month}/${selectedDayNext.date}/${selectedDayNext.year}`} locale="en-us" show={true} onDatePicked={res => this.handleDayClickNext(res)}/>                        
                        <TimeKeeper time={timeNext} onChange={this.handleTimeChangeNext} switchToMinuteOnHourSelect={true} />
                        <ShiftSelector plantShift={this.props.plantShift} rangeSelect={this.props.rangeSelect} closeCal={this.closeCal} userSelectionFromCal={this.userSelectionFromCal}/>
                    </div>
                    : null
                }
            </div>
        )
    }
}



export default withTranslation()(DateRangeSelector);