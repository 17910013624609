import React, { Component } from 'react'
import { withTranslation } from "react-i18next";

class UtilityConsumptionContainer extends Component {
    constructor(props){
        super(props);
    }  
    
    render() {
        const {t}=this.props;
        return (
            <div className="UtilityConsumption-cntr display-text"> 
                {t("UtilityConsumptionContainer")}
            </div>
        )
    }
}
  
export default withTranslation()(UtilityConsumptionContainer);