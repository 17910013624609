import React, { Component } from 'react'
import { withTranslation } from "react-i18next";

class AlarmsAnalysis extends Component {
    constructor(props){
        super(props);
    }  
    
    onSelectionChange = (e) => {
        this.props.onAlarmAnalysisChange(e.target.value);       
    }

    render() {
        const {t}=this.props;
        return (
            <div className="AlarmsAnalysis-wrp display-text"> 
                <div className="list-selection-wrp display-text">
                        <select className="list-selection form-control" id="AlarmsAnalysis" onChange={this.onSelectionChange}>
                            <option value="byMachine">{t("BY MACHINE")}</option>
                            <option value="byFrequency">{t("BY FREQUENCY")}</option>
                            <option value="byDate">{t("BY DATE")}</option>
                        </select>
                    </div>
            </div>
        )
    }
}
  
export default withTranslation()(AlarmsAnalysis);