import React, { Component } from 'react';
import * as actionCreators from '../../actions/actionCreators';
import { connect } from 'react-redux';
import { KeyboardBackspace } from '@material-ui/icons';
import {
    Card,
    CardContent,
    InputLabel,
    TextField,
    Select,
    withStyles,
    OutlinedInput,
    Button,
    Paper,
    Tabs,
    Tab
} from '@material-ui/core';
import { withTranslation } from "react-i18next";

import WidgetComponents from '../../components/WidgetComponents';

const styles = theme => ({
    cardContent: {
        height: '91%',
        "overflow-y": "auto"
    },
    cardContentRoot: {
        '&:last-child': {
            paddingBottom: 16
        }
    },
    card: {
        width: '100%',
        borderRadius: 0,
        height: '80vh'
    },
    textField: {
        width: '60%',
        marginRight: 20
    },
    textArea: {
        width: '80%',
        marginRight: 20
    },
    yamlTextArea: {
        width: '100%',
        marginTop: '1%',
        backgroundColor: '#f2f3f8'
    },
    outlinedInput: {
        padding: 14
    },
    saveButton: {
        fontSize: "14px",
        fontWeight: "500",
        textAlign: "center",
        color: "#ffffff",
        backgroundColor: "#42D784",
        '&:hover': {
            backgroundColor: '#42d784'
        },
        '&:disabled': {
            backgroundColor: '#f6f6f6'
        },
        borderRadius: "3px",
        boxShadow: "0 0 1px 0 rgba(0, 0, 0, 0.05)",
        width: "110px",
        height: "36px",
        marginRight: "24px"
    },
    inputFieldDiv: {
        display: "flex",
        justifyContent: "space-between",
        width: '50%',
        float: 'left',
        paddingTop: '1%',
        height: 80,
        'align-items': 'baseline'
    },
    textareaInputFieldDiv: {
        display: "flex",
        justifyContent: "space-between",
        width: '100%',
        float: 'left',
        paddingTop: '1%',
        height: 180,
        'align-items': 'baseline'
    },
    textFieldLabel: {
        color: 'rgba(0, 0, 0, 0.4000000059604645)',
        'letter-spacing': 0.5,
        'font-weight': 500,
        fontSize: "14px",
        lineHeight: 2.5,
        width: '30%',
        marginLeft: 20,
        //text-align': 'right'
    },
    textareaFieldLabel: {
        color: 'rgba(0, 0, 0, 0.4000000059604645)',
        'letter-spacing': 0.5,
        'font-weight': 500,
        fontSize: "14px",
        lineHeight: 2.5,
        width: '15%',
        marginLeft: 20,
        //'text-align': 'right'
    },
    requiredClass: {
        color: 'red'
    },
    actionButtonContainer: {
        display: 'flex',
        'justify-content': 'space-between',
        height: 50,
        'border-bottom': '1px solid rgba(207, 216, 220, 0.5)'
    },
    backButtonContainer: {
        display: 'flex',
        'align-items': 'center',
        paddingLeft: 21
    },
    arrowBack: {
        cursor: 'pointer'
    },
    entityTitle: {
        color: 'rgba(0, 0, 0, 0.8)',
        'font-family': 'Roboto',
        'font-size': 21,
        'font-weight': '400',
        'letter-spacing': 0.5,
        marginLeft: 10
    },
    paper: {
        borderRadius: 0,
        marginBottom: 1,
        boxShadow: '0 0 0 0',
        backgroundColor: '#f2f3f8',
        height: '10vh',
        clear: "both"
    },
    enabledTabs: {
        color: 'rgba(24, 32, 44, 0.8700000047683716)',
        letterSpacing: 0.1
    },
    selectedTab: {
        color: '#5ACBE5 !important'
    },
    tabIndicator: {
        backgroundColor: '#5ACBE5'
    }
})

class JobsEntity extends Component {

    state = {
        id: "",
        disableSaveButton: true,
        plantId: "",
        partName: "",
        partRef: "",
        jobLists: "",
        specs: "",
        labelWidth: 0,
        partRefError: false,
        specsError: false,
        partNameError: false,
        jobListError: false,
        selectedTab: 0
    }

    // handle widget/yaml view
    handleTabChange = (event, value) => {
        this.setState({
            selectedTab: value
        },
            () => {
                if (this.state.specs) {
                    this.getJobTypeSpecs();
                }
            })
    };

    // update the state for widget view
    updateStateForValue = (value, incomingLevelIndicator, error, errorMessage) => {
        let specs = [...this.state.specs];
        let currentObject = {};
        let levelIndicator = [];
        levelIndicator = incomingLevelIndicator.map(level => {
            return level.split(":")[0];
        })

        levelIndicator.forEach((key) => {
            if (((Object.keys(currentObject)).length) === 0) {
                currentObject = specs.find((item) => (item.key === key))
            }
            else {
                currentObject = currentObject.children.find((item) => (item.key === key));
            }
        })
        currentObject["value"] = value;
        currentObject["error"] = error;
        currentObject["errorMessage"] = errorMessage;

        this.setState({
            specs,
            disableSaveButton: false
        })
    }

    // display specs widget view
    getSpecView = () => {
        return (
            <div>
                {this.state.specs && typeof this.state.specs === "object" ?
                    <WidgetComponents
                        jsonData={this.state.specs}
                        updateStateForValue={(value, levelIndicator, error, errorMessage) => (this.updateStateForValue(value, levelIndicator, error, errorMessage))}
                        entityName={"jobs"}
                    />
                    : null}
            </div>
        )
    }

    // display specs yaml view
    getYamlView = () => {
        return (
            <div>
                {
                    typeof this.state.specs === "string" ?
                        <TextField
                            id="specification"
                            multiline
                            rows="60"
                            //helperText={(this.state.validationData.specs.validations && this.state.validationData.specs.validations.helpText) ? (this.state.validationData.specs.validations.helpText) : null}
                            value={this.state.specs}
                            variant="outlined"
                            onChange={(event) => this.inputChangeHandler(event, "specs")}
                            className={this.props.classes.yamlTextArea}
                            //error={this.state.specsError}
                            InputProps={{
                                classes: {
                                    multiline: this.props.classes.outlinedInput
                                }
                            }}
                        />
                        : null
                }
            </div>
        )
    }

    // create job-types dropdown list
    getJobTypeSpecs = (isNew) => {
        if (isNew) {
            if (this.state.selectedTab === 0) {
                let plantOrganizationId = "";
                let jobTypeObject = null;
                if (this.state.plantId) {
                    this.props.scopeSelector.subOrganizations.map((organization) => {
                        if (organization.plants && organization.plants.length > 0) {
                            organization.plants.map((plant) => {
                                if (plant.id === this.state.plantId) {
                                    plantOrganizationId = organization.id;
                                }
                            })
                        }
                    })
                }
                jobTypeObject = this.props.staticData.staticData[plantOrganizationId].jobTypes.find(item => {
                    if (item.id) {
                        return item;
                    }
                })
                if(jobTypeObject.specs.trim() !== "") {
                    this.props.getJsonFromYaml({ "entityType": "jobs", "specs": jobTypeObject.specs });
                }                
            }
            else if (this.state.selectedTab === 1) {
                let specs = JSON.parse(JSON.stringify(this.state.specs));
                specs.forEach((item) => {
                    this.normalizeJsonObject(item);
                })
                this.props.getYamlFromJson(specs);
            }
        }
        else {
            if (this.state.selectedTab === 0) {
                if (typeof this.state.specs === "string" && this.state.specs.trim() !== "") {
                    this.props.getJsonFromYaml({ "entityType": "jobs", "specs": this.state.specs });
                }
            }
            else if (this.state.selectedTab === 1) {
                if (typeof this.state.specs === "object") {
                    let specs = JSON.parse(JSON.stringify(this.state.specs));
                    specs.forEach((item) => {
                        this.normalizeJsonObject(item);
                    })
                    this.props.getYamlFromJson(specs);
                }
            }
        }
    }

    // cast number to string of specs json
    normalizeJsonObject = (object) => {
        if(object.value && typeof object.value === "string") {
            if(Number(object.value)) {
                object.value = Number(object.value);
            }
        }

        if(object.children !== null) {
            object.children.forEach((item) => {
                this.normalizeJsonObject(item);
            })
        }
    }

    // Handle changes to any user input field
    inputChangeHandler = (event, field) => {
        switch (field) {
            case "partName": this.setState({ partName: event.target.value, disableSaveButton: false });
                this.checkValidation("partName", event.target.value);
                break;
            case "specs": this.setState({ specs: event.target.value, disableSaveButton: false });
                this.checkValidation("specs", event.target.value);
                break;
            case "partRef": this.setState({ partRef: event.target.value, disableSaveButton: false });
                this.checkValidation("partRef", event.target.value);
                break;
            case "jobLists": this.setState({ jobLists: event.target.value, disableSaveButton: false });
                this.checkValidation("jobLists", event.target.value);
                break;
            default: console.log("default case");
        }
    }

    // Handle routing
    goToBackPage = () => {
        this.props.history.goBack();
    }

    // update jobs entity
    updateJobsData = () => {
        let { partName, id, partRef, jobLists, specs } = this.state;
        if (typeof specs !== "string") {
            specs = JSON.stringify(specs);
        }
        if (id && id != null && id !== "") {
            this.props.updateJobsEntity({ partName, id, partRef, jobLists, specs }, id);
        }
        else {
            this.props.updateJobsEntity({ partName, partRef, jobLists, specs }, id);
        }
    }

    // create job-lists dropdown list
    getJobListName = () => {
        let jobList = [];
        jobList.push(<option key={""} disabled={true} value={""}>{"Select"}</option>);
        if ((this.state.validationData.jobListsName.validations && !this.state.validationData.jobListsName.validations.required) || !(this.state.validationData.jobListsName.validations)) {
            jobList.push(<option key={""} value={""}>{"None"}</option>);
        }
        if (this.props.jobListData && this.props.jobListData.tableData && this.props.jobListData.tableData.length > 0) {
            this.props.jobListData.tableData.map((data) => {
                jobList.push(
                    <option key={data.id} value={data.id}>{data.name}</option>
                );
            })
        }
        return jobList;
    }

    componentDidMount() {
        if (this.props.data) {
            let entityNames = Object.keys(this.props.data)
            entityNames.map((data) => {
                if (this.props.data[data] == null) {
                    this.props.data[data] = ""
                }
            })

            this.setState({
                id: this.props.data.id,
                partName: this.props.data.partName,
                partRef: this.props.data.partRef,
                specs: this.props.data.specs,

            })
            if (this.props.data.jobLists && this.props.data.jobLists.id != "") {
                this.setState({ jobLists: this.props.data.jobLists.id })
            }

            if (this.props.data.specs && this.props.data.specs != "") {
                if (this.props.data.specs.props) {
                    this.setState({ specs: this.props.data.specs.props.children },
                        () => {
                            this.getJobTypeSpecs();
                        })
                }
                else {
                    this.setState({ specs: this.props.data.specs },
                        () => {
                            this.getJobTypeSpecs();
                        })
                }
            }
        }
        if (this.props.selectedScopeData || this.props.selectedScopeData == "") {
            this.setState({ plantId: this.props.selectedScopeData })
        }

        if (this.props.validationData && this.props.validationData.config && this.props.validationData.config.Jobs) {
            this.setState({ validationData: this.props.validationData.config.Jobs });
        }
    }

    componentDidUpdate(prevProps, prevState) {
        if (this.state.plantId && this.state.plantId !== prevState.plantId) {
            this.props.getTableData("JobListTable", this.state.plantId, true);
            if (!this.props.data) {
                this.getJobTypeSpecs(true);
            }
            else {
                this.getJobTypeSpecs();
            }
        }
        if (prevProps.jsonData !== this.props.jsonData) {
            this.props.jsonData.json ? this.setState({
                specs: this.props.jsonData.json
            })
                : this.setState({
                    selectedTab: 0
                })
        }
    }

    // disable save button based on validations
    disableSaveButton = () => {
        if (this.state.jobLists === "" && (this.state.validationData.jobListsName.validations && this.state.validationData.jobListsName.validations.required)) {
            return true;
        }
        else if (this.state.partName === "" && (this.state.validationData.partName.validations && this.state.validationData.partName.validations.required)) {
            return true;
        }
        else if (this.state.partRef === "" && (this.state.validationData.partRef.validations && this.state.validationData.partRef.validations.required)) {
            return true;
        }
        else if (this.state.specs === "" && (this.state.validationData.specs.validations && this.state.validationData.specs.validations.required)) {
            return true;
        }
        else if (this.state.partRefError || this.state.specsError || this.state.partNameError || this.state.jobListError) {
            return true;
        }
        else {
            return false;
        }
    }

    // check validations of the user input fields
    checkValidation = (field, value) => {
        switch (field) {
            case "jobLists":
                if (!this.state.jobListError && value === "" && (this.state.validationData.jobListsName.validations && this.state.validationData.jobListsName.validations.required)) {
                    this.setState({ jobListError: true });
                } else if (this.state.jobListError) {
                    if ((value === "" && (this.state.validationData.jobListsName.validations && this.state.validationData.jobListsName.validations.required))) {
                        //skip
                    }
                    else {
                        this.setState({ jobListError: false });
                    }
                };
                break;
            case "partName":
                if (!this.state.partNameError && value === "" && (this.state.validationData.partName.validations && this.state.validationData.partName.validations.required)) {
                    this.setState({ partNameError: true });
                } else if (!this.state.partNameError && (this.state.validationData.partName.validations) && (value.length > this.state.validationData.partName.validations.maxLength)) {
                    this.setState({ partNameError: true });
                } else if (this.state.partNameError) {
                    if ((value === "" && (this.state.validationData.partName.validations && this.state.validationData.partName.validations.required) || ((this.state.validationData.partName.validations) && (value.length > this.state.validationData.partName.validations.maxLength)))) {
                        //skip
                    }
                    else {
                        this.setState({ partNameError: false });
                    }
                };
                break;
            case "partRef":
                if (!this.state.partRefError && value === "" && (this.state.validationData.partRef.validations && this.state.validationData.partRef.validations.required)) {
                    this.setState({ partRefError: true });
                } else if (!this.state.partRefError && (this.state.validationData.partRef.validations) && (value.length > this.state.validationData.partRef.validations.maxLength)) {
                    this.setState({ partRefError: true });
                } else if (this.state.partRefError) {
                    if ((value === "" && (this.state.validationData.partRef.validations && this.state.validationData.partRef.validations.required) || ((this.state.validationData.partRef.validations) && (value.length > this.state.validationData.partRef.validations.maxLength)))) {
                        //skip
                    }
                    else {
                        this.setState({ partRefError: false });
                    }
                };
                break;
            case "specs":
                if (!this.state.specsError && value === "" && (this.state.validationData.specs.validations && this.state.validationData.specs.validations.required)) {
                    this.setState({ specsError: true });
                } else if (!this.state.specsError && (this.state.validationData.specs.validations) && (value.length > this.state.validationData.specs.validations.maxLength)) {
                    this.setState({ specsError: true });
                } else if (this.state.specsError) {
                    if ((value === "" && (this.state.validationData.specs.validations && this.state.validationData.specs.validations.required) || ((this.state.validationData.specs.validations) && (value.length > this.state.validationData.specs.validations.maxLength)))) {
                        //skip
                    }
                    else {
                        this.setState({ specsError: false });
                    }
                };
                break;
        }
    }

    // set card header
    getCardHeader = () => {
        if (this.props.data && this.props.data.partName && this.props.data.partName != "") {
            return this.props.data.partName;
        }
        else {
            return "Create New"
        }
    }

    render() {
        const {t}=this.props;
        return (
            <Card className={this.props.classes.card}>
                <div className={this.props.classes.actionButtonContainer}>
                    <div className={this.props.classes.backButtonContainer}>
                        <KeyboardBackspace onClick={this.goToBackPage} classes={{ root: this.props.classes.arrowBack }} />
                        <div className={this.props.classes.entityTitle}>{this.getCardHeader()}</div>
                    </div>
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                        <Button variant="contained"
                            className={this.props.classes.saveButton}
                            disabled={this.state.disableSaveButton || this.disableSaveButton()}
                            onClick={this.updateJobsData}>{t("Save")}</Button>
                    </div>
                </div>
                <CardContent className={this.props.classes.cardContent} classes={{ root: this.props.classes.cardContentRoot }}>
                    {this.state.validationData && this.state.validationData.partName && this.state.validationData.partName.page_visible ? (
                        <div className={this.props.classes.inputFieldDiv}>
                            <InputLabel className={this.props.classes.textFieldLabel}>
                                {this.state.validationData.partName.display_name}
                                {(this.state.validationData.partName.validations && this.state.validationData.partName.validations.required) ? (<span className={this.props.classes.requiredClass}>*</span>) : null}
                            </InputLabel>
                            <TextField
                                id="partName"
                                name="partName"
                                variant="outlined"
                                helperText={(this.state.validationData.partName.validations && this.state.validationData.partName.validations.helpText) ? (this.state.validationData.partName.validations.helpText) : null}
                                className={this.props.classes.textField}
                                onChange={(event) => this.inputChangeHandler(event, "partName")}
                                value={this.state.partName}
                                error={this.state.partNameError}
                                InputProps={{
                                    classes: {
                                        input: this.props.classes.outlinedInput
                                    }
                                }}
                            />
                        </div>
                    )
                        : null
                    }
                    {this.state.validationData && this.state.validationData.jobListsName && this.state.validationData.jobListsName.page_visible ? (
                        <div className={this.props.classes.inputFieldDiv}>
                            <InputLabel className={this.props.classes.textFieldLabel}>
                                {this.state.validationData.jobListsName.display_name}
                                {(this.state.validationData.jobListsName.validations && this.state.validationData.jobListsName.validations.required) ? (<span className={this.props.classes.requiredClass}>*</span>) : null}
                            </InputLabel>
                            <Select
                                native
                                value={this.state.jobLists}
                                onChange={(event) => this.inputChangeHandler(event, "jobLists")}
                                input={
                                    <OutlinedInput
                                        variant="outlined"
                                        name="jobLists"
                                        id="language-native-simple"
                                        labelWidth={this.state.labelWidth}
                                        className={this.props.classes.textField}
                                        error={this.state.jobListError}
                                        classes={{ input: this.props.classes.outlinedInput }}
                                    />
                                }
                            >
                                {this.getJobListName()}
                            </Select>
                        </div>
                    )
                        : null
                    }
                    {this.state.validationData && this.state.validationData.partRef && this.state.validationData.partRef.page_visible ? (
                        <div className={this.props.classes.inputFieldDiv}>
                            <InputLabel className={this.props.classes.textFieldLabel}>
                                {this.state.validationData.partRef.display_name}
                                {(this.state.validationData.partRef.validations && this.state.validationData.partRef.validations.required) ? (<span className={this.props.classes.requiredClass}>*</span>) : null}
                            </InputLabel>
                            <TextField
                                name="partRef"
                                id="partRef-disabled"
                                variant="outlined"
                                helperText={(this.state.validationData.partRef.validations && this.state.validationData.partRef.validations.helpText) ? (this.state.validationData.partRef.validations.helpText) : null}
                                onChange={(event) => this.inputChangeHandler(event, "partRef")}
                                className={this.props.classes.textField}
                                value={this.state.partRef}
                                error={this.state.partRefError}
                                InputProps={{
                                    classes: {
                                        input: this.props.classes.outlinedInput
                                    }
                                }}
                            />
                        </div>
                    )
                        : null
                    }
                    {/* {this.state.validationData && this.state.validationData.specs && this.state.validationData.specs.page_visible ? (
                        <div className={this.props.classes.textareaInputFieldDiv}>
                            <InputLabel className={this.props.classes.textareaFieldLabel}>
                                {this.state.validationData.specs.display_name}
                                {(this.state.validationData.specs.validations && this.state.validationData.specs.validations.required) ? (<span className={this.props.classes.requiredClass}>*</span>) : null}
                            </InputLabel><br />
                            <TextField
                                id="specification"
                                multiline
                                rows="6"
                                helperText={(this.state.validationData.specs.validations && this.state.validationData.specs.validations.helpText) ? (this.state.validationData.specs.validations.helpText) : null}
                                value={this.state.specs}
                                variant="outlined"
                                onChange={(event) => this.inputChangeHandler(event, "specs")}
                                className={this.props.classes.textArea}
                                error={this.state.specsError}
                                InputProps={{
                                    classes: {
                                        multiline: this.props.classes.outlinedInput
                                    }
                                }}
                            />
                        </div>
                    )
                        : null
                    } */}
                    {/* {
                        this.state.specs !== "" ?
                            
                            : null
                    } */}
                    <div style={{ clear: "both", minHeight: 200 }}>
                        <Paper>
                            <Tabs
                                value={this.state.selectedTab}
                                onChange={this.handleTabChange}
                                indicatorColor="primary"
                                textColor="primary"
                                variant="fullWidth"
                                TabIndicatorProps={{
                                    classes: {
                                        colorPrimary: this.props.classes.tabIndicator
                                    }
                                }}
                                style={{ marginTop: 185, marginBottom: 10 }}
                            >
                                <Tab label={this.props.t("SPECS")} key={"SPECS"} classes={{ selected: this.props.classes.selectedTab }} />
                                <Tab label={this.props.t("YAML")} key={"YAML"} classes={{ selected: this.props.classes.selectedTab }} />
                            </Tabs>
                        </Paper>
                        {this.state.selectedTab === 0 ? this.getSpecView() : this.getYamlView()}
                    </div>
                </CardContent>
            </Card >
        );
    }
}

const mapStateToProps = (state) => {
    return {
        staticData: state.staticDataReducer,
        jobListData: state.jobListDataReducer,
        validationData: state.viewDefinitionReducer,
        jsonData: state.constructJsonReducer
    }
}

const mapDispatchToProps = dispatch => {
    return ({
        getTableData: (selectedNavtab, currentScopeSelectionId, setToStore) => (dispatch(actionCreators.getTableData(selectedNavtab, currentScopeSelectionId, setToStore))),
        updateJobsEntity: (jobsEntityData, id) => (dispatch(actionCreators.updateJobsEntity(jobsEntityData, id))),
        updateSelectedTab: (selectedTab) => (dispatch(actionCreators.updateSelectedTab(selectedTab))),
        getJsonFromYaml: (yamlRequestObject) => (dispatch(actionCreators.getJsonFromYaml(yamlRequestObject))),
        getYamlFromJson: (jsonRequestObject) => (dispatch(actionCreators.getYamlFromJson(jsonRequestObject)))
    })
}

export default withTranslation()(connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(JobsEntity)));

