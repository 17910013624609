import * as actionTypes from '../actions/actionTypes';

export const loaderReducer = (state={load: false}, action) => {
    switch(action.type) {

        case actionTypes.LOADER_STATE:
        return Object.assign({}, state, {load: action.payload})

        default: return state
    }
}