import React, { Component, Children, cloneElement } from 'react';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TablePagination from '@material-ui/core/TablePagination';
import { withTranslation } from "react-i18next";

export const Column = ({ intent, label, value }) =>
    intent === 'header' ? <span>{label}</span> : <span>{value}</span>;

class RenderContextTable extends Component {
    constructor(props) {
        super(props);
        this.state = { rowsPerPage: 5, page: 0, emptyRows: false };
        //this.emptyRows = this.state.rowsPerPage - Math.min(this.state.rowsPerPage, this.props.data.length - this.state.page * this.state.rowsPerPage);

    }
    componentDidUpdate(prevProps) {
        if (prevProps != this.props) {
            (this.props.data.length) == 0 ? this.setState({ emptyRows: true }) : this.setState({ emptyRows: false });
        }
    }
    handleChangePage = (event, newPage) => {
        this.setState({ page: newPage });
    }
    handleChangeRowsPerPage = (event) => {
        this.setState({ rowsPerPage: parseInt(event.target.value, 10) });
    }
    render() {
        const { children, data, orderBy, direction, ...props } = this.props;
        const { page, rowsPerPage } = this.state;
        let currentLanguage = localStorage.getItem("lang");
        if (currentLanguage === "null" || currentLanguage === null) {
            currentLanguage = "en";
        }

        return (
            <div {...props}>
                <Table className="alarmTable">
                    <TableHead>
                        <TableRow>
                            {/* Dynamic generation of the header cells from the children */}
                            {Children.map(children, column => (
                                <TableCell className="card-th"
                                    key={column.props.name}
                                    sortDirection={
                                        orderBy === column.props.name ? direction : false
                                    }
                                >
                                    {/* Clone the child, adding an intent prop to the props it already has */}
                                    {cloneElement(column, { intent: 'header' })}
                                </TableCell>
                            ))}
                        </TableRow>
                    </TableHead>
                </Table>
                <Table className="alarmNext">
                    <TableBody>
                        {/* Dynamic generation of the cells from the children */}
                        {data.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map(item => (
                            <TableRow key={item.id}>
                                {Children.map(children, column => (
                                    <TableCell
                                        key={column.props.name}
                                        numeric={column.props.isNumeric}
                                    >
                                        {console.log(item)}
                                        {/* Clone the child, adding intent and value props to the props it already has */}
                                        {cloneElement(column, {
                                            intent: 'cell',
                                            value: item[column.props.name],
                                            id: item.id,
                                            status: item.status
                                        })}
                                    </TableCell>
                                ))}
                            </TableRow>
                        ))}
                        {this.state.emptyRows ?
                            <TableRow style={{ height: 10 * this.emptyRows }}>
                                <TableCell style={{ textAlign: 'center', width: '1%' }} colSpan={6} > {this.props.t("No records to display")} </TableCell>
                            </TableRow>
                            : null
                        }

                    </TableBody>

                    <TablePagination id="tablePagination"
                        rowsPerPageOptions={[5, 10, 25]}
                        component="div"
                        count={this.props.data.length}
                        rowsPerPage={this.state.rowsPerPage}
                        page={this.state.page}
                        backIconButtonProps={{
                            'aria-label': this.props.t('Previous Page'),
                        }}
                        nextIconButtonProps={{
                            'aria-label': this.props.t('Next Page'),
                        }}
                        labelRowsPerPage={this.props.t('Rows per Page')}
                        onChangePage={this.handleChangePage}
                        onChangeRowsPerPage={this.handleChangeRowsPerPage}
                    />
                </Table>
            </div>
        )
    }
}

export default withTranslation()(RenderContextTable);



