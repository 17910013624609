import React, { Component } from 'react';
import * as actionCreators from '../../actions/actionCreators';
import { connect } from 'react-redux';
import { KeyboardBackspace } from '@material-ui/icons';
import {
    Card,
    CardContent,
    InputLabel,
    TextField,
    Select,
    withStyles,
    OutlinedInput,
    Button,
    Paper,
    Tabs,
    Tab
} from '@material-ui/core';
import { withTranslation } from "react-i18next";

import WidgetComponents from '../../components/WidgetComponents';

const styles = theme => ({
    cardContent: {
        height: '91%',
        "overflow-y": "auto"
    },
    cardContentRoot: {
        '&:last-child': {
            paddingBottom: 16
        }
    },
    card: {
        width: '100%',
        borderRadius: 0,
        height: '80vh'
    },
    textField: {
        width: '60%',
        marginRight: 20
    },
    textArea: {
        width: '80%',
        marginRight: 20
    },
    yamlTextArea: {
        width: '100%',
        marginTop: '1%',
        backgroundColor: '#f2f3f8'
    },
    outlinedInput: {
        padding: 14
    },
    saveButton: {
        fontSize: "14px",
        fontWeight: "500",
        textAlign: "center",
        color: "#ffffff",
        backgroundColor: "#42D784",
        '&:hover': {
            backgroundColor: '#42d784'
        },
        '&:disabled': {
            backgroundColor: '#f6f6f6'
        },
        borderRadius: "3px",
        boxShadow: "0 0 1px 0 rgba(0, 0, 0, 0.05)",
        width: "110px",
        height: "36px",
        marginRight: "24px"
    },
    inputFieldDiv: {
        display: "flex",
        justifyContent: "space-between",
        width: '50%',
        float: 'left',
        paddingTop: '1%',
        height: 80,
        'align-items': 'baseline'
    },
    textareaInputFieldDiv: {
        display: "flex",
        justifyContent: "space-between",
        width: '100%',
        float: 'left',
        paddingTop: '1%',
        height: 180,
        'align-items': 'baseline'
    },
    textFieldLabel: {
        color: 'rgba(0, 0, 0, 0.4000000059604645)',
        'letter-spacing': 0.5,
        'font-weight': 500,
        fontSize: "14px",
        lineHeight: 2.5,
        width: '30%',
        marginLeft: 20,
        //text-align': 'right'
    },
    textareaFieldLabel: {
        color: 'rgba(0, 0, 0, 0.4000000059604645)',
        'letter-spacing': 0.5,
        'font-weight': 500,
        fontSize: "14px",
        lineHeight: 2.5,
        width: '15%',
        marginLeft: 20,
        //'text-align': 'right'
    },
    requiredClass: {
        color: 'red'
    },
    actionButtonContainer: {
        display: 'flex',
        'justify-content': 'space-between',
        height: 50,
        'border-bottom': '1px solid rgba(207, 216, 220, 0.5)'
    },
    backButtonContainer: {
        display: 'flex',
        'align-items': 'center',
        paddingLeft: 21
    },
    arrowBack: {
        cursor: 'pointer'
    },
    entityTitle: {
        color: 'rgba(0, 0, 0, 0.8)',
        'font-family': 'Roboto',
        'font-size': 21,
        'font-weight': '400',
        'letter-spacing': 0.5,
        marginLeft: 10
    },
    paper: {
        borderRadius: 0,
        marginBottom: 1,
        boxShadow: '0 0 0 0',
        backgroundColor: '#f2f3f8',
        height: '10vh',
        clear: "both"
    },
    enabledTabs: {
        color: 'rgba(24, 32, 44, 0.8700000047683716)',
        letterSpacing: 0.1
    },
    selectedTab: {
        color: '#5ACBE5 !important'
    },
    tabIndicator: {
        backgroundColor: '#5ACBE5'
    }
})

class SpectralFeaturesEntity extends Component {

    state = {
        id: "",
        disableSaveButton: true,
        plantId: "",
        deviceIdentifier: "",
        machineId: "",
        monitorTypeId: "",
        featureName: "",
        specs: "",
        notes: "",
        labelWidth: 0,
        labelError: false,
        specsError: false,
        notesError: false,
        monitorTypeError: false,
        machineError: false,
        deviceIdentifierError: false,
        selectedTab: 0,
        showErrorMessage: false
    }

    // handle widget/yaml view
    handleTabChange = (event, value) => {
        this.setState({
            selectedTab: value
        },
            () => {
                if (this.state.specs) {
                    this.getMonitorTypeSpecs();
                }
            })
    };

    // update the state for widget view
    updateStateForValue = (value, incomingLevelIndicator, error, errorMessage) => {
        let specs = [...this.state.specs];
        let currentObject = {};
        let levelIndicator = [];
        levelIndicator = incomingLevelIndicator.map(level => {
            return level.split(":")[0];
        })
        if (levelIndicator[2] === 'Operational' || levelIndicator[2] === 'Caution' || levelIndicator[2] === 'Warning' && levelIndicator[3] === 'threshold_value') {
            currentObject = specs.find((item) => (item.key === levelIndicator[0]));
            currentObject = currentObject.children.find((item) => (item.key === levelIndicator[1]));
            let operationValue = levelIndicator[2] === 'Operational' ? value : currentObject.children[0].children[0].value;
            let cautionValue = levelIndicator[2] === 'Caution' ? value : currentObject.children[1].children[0].value;
            let warningValue = levelIndicator[2] === 'Warning' ? value : currentObject.children[2].children[0].value;
            operationValue = parseFloat(operationValue);
            cautionValue = parseFloat(cautionValue);
            warningValue = parseFloat(warningValue);
            if ((value === '' && levelIndicator[2] === 'Operational') || (isNaN(value) && levelIndicator[2] === 'Operational') || operationValue === '' || isNaN(operationValue)) {
                levelIndicator[2] === 'Operational' ? currentObject.children[0].children[0].value = value : currentObject.children[0].children[0].value = currentObject.children[0].children[0].value;
                currentObject.children[0].children[0].error = true;
                currentObject.children[0].children[0].errorMessage = this.props.t("Please enter a valid number.");
            } else if (operationValue >= cautionValue || operationValue >= warningValue) {
                levelIndicator[2] === 'Operational' ? currentObject.children[0].children[0].value = value : currentObject.children[0].children[0].value = currentObject.children[0].children[0].value;
                currentObject.children[0].children[0].error = true;
                currentObject.children[0].children[0].errorMessage = 'Operational value can not be greater than or equal to Caution or Warning value';
            } else {
                // currentObject.children[0].children[0].value= value;
                levelIndicator[2] === 'Operational' ? currentObject.children[0].children[0].value = value : currentObject.children[0].children[0].value = currentObject.children[0].children[0].value;
                currentObject.children[0].children[0].error = false;
                currentObject.children[0].children[0].errorMessage = '';
            }
            if ((value === '' && levelIndicator[2] === 'Caution') || (isNaN(value) && levelIndicator[2] === 'Caution') || cautionValue === '' || isNaN(cautionValue)) {
                levelIndicator[2] === 'Caution' ? currentObject.children[1].children[0].value = value : currentObject.children[1].children[0].value = currentObject.children[1].children[0].value;
                currentObject.children[1].children[0].error = true;
                currentObject.children[1].children[0].errorMessage = this.props.t("Please enter a valid number.");
            } else if (cautionValue <= operationValue || cautionValue >= warningValue) {
                levelIndicator[2] === 'Caution' ? currentObject.children[1].children[0].value = value : currentObject.children[1].children[0].value = currentObject.children[1].children[0].value;
                currentObject.children[1].children[0].error = true;
                currentObject.children[1].children[0].errorMessage = 'Caution value can not be greater than or equal to Warning value or can not be lesser than or equal Operational value';
            } else {
                // currentObject.children[1].children[0].value= value;
                levelIndicator[2] === 'Caution' ? currentObject.children[1].children[0].value = value : currentObject.children[1].children[0].value = currentObject.children[1].children[0].value;
                currentObject.children[1].children[0].error = false;
                currentObject.children[1].children[0].errorMessage = '';
            }
            if ((value === '' && levelIndicator[2] === 'Warning') || (isNaN(value) && levelIndicator[2] === 'Warning') || warningValue === '' || isNaN(warningValue)) {
                levelIndicator[2] === 'Warning' ? currentObject.children[2].children[0].value = value : currentObject.children[2].children[0].value = currentObject.children[2].children[0].value;
                currentObject.children[2].children[0].error = true;
                currentObject.children[2].children[0].errorMessage = this.props.t("Please enter a valid number.");
            } else if (warningValue <= cautionValue || warningValue <= operationValue) {
                levelIndicator[2] === 'Warning' ? currentObject.children[2].children[0].value = value : currentObject.children[2].children[0].value = currentObject.children[2].children[0].value;
                currentObject.children[2].children[0].error = true;
                currentObject.children[2].children[0].errorMessage = 'Warning value can not be lesser than or equal Caution or Operational value';
            } else {
                // currentObject.children[2].children[0].value= value;
                levelIndicator[2] === 'Warning' ? currentObject.children[2].children[0].value = value : currentObject.children[2].children[0].value = currentObject.children[2].children[0].value;
                currentObject.children[2].children[0].error = false;
                currentObject.children[2].children[0].errorMessage = '';
            }
        } else {
            levelIndicator.forEach((key) => {
                if (((Object.keys(currentObject)).length) === 0) {
                    currentObject = specs.find((item) => (item.key === key))
                }
                else {
                    currentObject = currentObject.children.find((item) => (item.key === key));
                }
            })
            currentObject["value"] = value;
            currentObject["error"] = error;
            currentObject["errorMessage"] = errorMessage;
        }
        this.setState({
            specs,
            disableSaveButton: false
        })
    }

    // update key
    updateStateForKey = (value, incomingLevelIndicator, forSave) => {
        let specs = [...this.state.specs];
        let currentObject = {};
        let levelIndicator = [];
        levelIndicator = incomingLevelIndicator.map(level => {
            return level.split(":")[0];
        })

        levelIndicator.forEach((key) => {
            if (((Object.keys(currentObject)).length) === 0) {
                currentObject = specs.find((item) => (item.key === key))
            }
            else {
                currentObject = currentObject.children.find((item) => (item.key === key));
            }
        })
        if (forSave) {
            let newValueForKey = currentObject.label;
            currentObject.key = newValueForKey;
            currentObject.editing = false;
        }
        else {
            currentObject.label = value;
        }

        this.setState({
            specs,
            disableSaveButton: false
        })
    }

    // handle editing of a condition in widget view
    editCondition = (incomingLevelIndicator) => {
        let specs = [...this.state.specs];
        let currentObject = {};
        let levelIndicator = [];
        levelIndicator = incomingLevelIndicator.map(level => {
            return level.split(":")[0];
        })

        levelIndicator.forEach((key) => {
            if (((Object.keys(currentObject)).length) === 0) {
                currentObject = specs.find((item) => (item.key === key))
            }
            else {
                currentObject = currentObject.children.find((item) => (item.key === key));
            }
        })
        currentObject.editing = true;

        this.setState({
            specs,
            disableSaveButton: false
        })
    }

    // handle deletion of a condition from widget view
    deleteCondition = (levelIndicator) => {
        let specs = [...this.state.specs];
        let deleteItem = {};

        levelIndicator.forEach((level, index) => {
            let splittedArray = level.split(":");
            if (Object.keys(deleteItem).length === 0) {
                deleteItem = specs[splittedArray[1]];
            }
            else {
                if (index < levelIndicator.length - 1) {
                    deleteItem = deleteItem.children[splittedArray[1]];
                }
            }
        })

        deleteItem.children.splice((levelIndicator[(levelIndicator.length - 1)].split(":"))[1], 1);

        this.setState({
            specs,
            disableSaveButton: false
        })
    }

    // handle addition of a new condition in widget view
    addNewCondition = (value, incomingLevelIndicator) => {
        let levelIndicator = [];
        levelIndicator = incomingLevelIndicator.map(level => {
            return level.split(":")[0];
        })

        let specs = [...this.state.specs];

        let sourceObject = specs[incomingLevelIndicator[0].split(":")[1]].children.find((item) => item.key === incomingLevelIndicator[1]);

        let destinationObject = JSON.parse(JSON.stringify(sourceObject));

        for (let key in destinationObject) {
            if (key === "key") {
                destinationObject[key] = value;
            }
            else if (key === "label") {
                destinationObject[key] = value;
            }
            else if (key === "level") {
                destinationObject[key] = (destinationObject[key] + 1);
            }
            else if (key === "children") {
                destinationObject[key] = null;
            }
        }

        specs[incomingLevelIndicator[0].split(":")[1]].children.map((item) => {
            if (item.key === incomingLevelIndicator[1]) {
                if (item.children && item.children.length > 0) {
                    item.children.unshift(destinationObject);
                }
                else {
                    item.children = [];
                    item.children.push(destinationObject);
                }
            }
        })

        this.setState({
            specs,
            disableSaveButton: false
        },
            () => {
                this.props.addNewCondition({ "entityType": "monitors", "widgetConfig": this.state.specs })
            }
        )
    }

    // display specs widget view
    getSpecView = () => {
        return (
            <div>
                {this.state.specs && typeof this.state.specs === "object" ?
                    <WidgetComponents
                        jsonData={this.state.specs}
                        updateStateForValue={(value, levelIndicator, error, errorMessage) => (this.updateStateForValue(value, levelIndicator, error, errorMessage))}
                        updateStateForKey={(value, levelIndicator, forSave) => (this.updateStateForKey(value, levelIndicator, forSave))}
                        deleteCondition={(levelIndicator) => (this.deleteCondition(levelIndicator))}
                        editCondition={(levelIndicator) => (this.editCondition(levelIndicator))}
                        entityName={"monitors"}
                        addNewCondition={(value, levelIndicator) => (this.addNewCondition(value, levelIndicator))}
                        showErrorMessage={this.state.showErrorMessage}
                    />
                    : null}
            </div>
        )
    }

    // display specs yaml view
    getYamlView = () => {
        return (
            <div>
                {
                    typeof this.state.specs === "string" ?
                        <TextField
                            id="specification"
                            multiline
                            rows="60"
                            disabled='true'
                            //helperText={(this.state.validationData.specs.validations && this.state.validationData.specs.validations.helpText) ? (this.state.validationData.specs.validations.helpText) : null}
                            value={this.state.specs}
                            variant="outlined"
                            onChange={(event) => this.inputChangeHandler(event, "specs")}
                            className={this.props.classes.yamlTextArea}
                            //error={this.state.specsError}
                            InputProps={{
                                classes: {
                                    multiline: this.props.classes.outlinedInput
                                }
                            }}
                        />
                        : null
                }
            </div>
        )
    }

    // get monitor specs for selected monitor type
    getMonitorTypeSpecs = (id) => {
        if (id) {
            let plantOrganizationId = "";
            let monitorTypeObject = null;
            if (id) {
                if (this.state.plantId) {
                    this.props.scopeSelector.subOrganizations.map((organization) => {
                        if (organization.plants && organization.plants.length > 0) {
                            organization.plants.map((plant) => {
                                if (plant.id === this.state.plantId) {
                                    plantOrganizationId = organization.id;
                                }
                            })
                        }
                    })
                }
                monitorTypeObject = this.props.staticData.staticData[plantOrganizationId].monitortypes.find(item => {
                    if (item.id === parseInt(id)) {
                        return item;
                    }
                })
            }
            if (this.state.selectedTab === 0 && monitorTypeObject.specs.trim() !== "") {
                this.props.getJsonFromYaml({ "entityType": "monitors", "specs": monitorTypeObject.specs });
            }
            else if (this.state.selectedTab === 1) {
                this.setState({
                    specs: monitorTypeObject.specs
                })
            }
        }
        else {
            if (this.state.selectedTab === 0) {
                if (typeof this.state.specs === "string" && this.state.specs.trim() !== "") {
                    this.props.getJsonFromYaml({ "entityType": "spectralfeatures", "specs": this.state.specs });
                }
            }
            else if (this.state.selectedTab === 1) {
                if (typeof this.state.specs === "object") {
                    let specs = JSON.parse(JSON.stringify(this.state.specs));
                    specs.forEach((item) => {
                        this.normalizeJsonObject(item);
                    })
                    this.props.getYamlFromJson(specs);
                }
            }
        }
    }

    // cast number to string for specs
    normalizeJsonObject = (object) => {
        if (object.value && typeof object.value === "string") {
            if (Number(object.value)) {
                object.value = Number(object.value);
            }
        }

        if (object.children !== null) {
            object.children.forEach((item) => {
                this.normalizeJsonObject(item);
            })
        }
    }

    // Handle changes to any user input field
    inputChangeHandler = (event, field) => {
        switch (field) {
            case "featureName": this.setState({ featureName: event.target.value, disableSaveButton: false });
                this.checkValidation("featureName", event.target.value);
                break;
            case "notes": this.setState({ notes: event.target.value, disableSaveButton: false });
                this.checkValidation("notes", event.target.value);
                break;
            case "machineId": this.setState({ machineId: event.target.value, disableSaveButton: false });
                this.checkValidation("machineId", event.target.value);
                break;
            case "monitorTypeId":
                this.getMonitorTypeSpecs(event.target.value);
                this.setState({ monitorTypeId: event.target.value, disableSaveButton: false });
                this.checkValidation("monitorTypeId", event.target.value);
                break;
            case "label": this.setState({ label: event.target.value, disableSaveButton: false });
                this.checkValidation("label", event.target.value);
                break;
            default: console.log("default case");
        }
    }

    // Handle routing
    goToBackPage = () => {
        this.props.history.goBack();
    }

    // update monitors entity
    updateMonitorsData = () => {
        let { id, deviceIdentifier, notes, specs, machineId, monitorTypeId, label } = this.state;
        if (typeof specs !== "string") {
            specs = JSON.stringify(specs);
        }
        if (id && id != null && id !== "") {
            this.props.updateMonitorsEntity({ id, deviceIdentifier, notes, specs, machineId, monitorTypeId, label }, id);
        }
        else {
            this.props.updateMonitorsEntity({ deviceIdentifier, notes, specs, machineId, monitorTypeId, label }, id);
        }
    }

    // create machines dropdown list
    getMachinesName = () => {
        let machinesList = [];
        machinesList.push(<option key={""} disabled={true} value={""}>{"Select"}</option>);
        if ((this.state.validationData.machineName.validations && !this.state.validationData.machineName.validations.required) || !(this.state.validationData.machineName.validations)) {
            machinesList.push(<option key={""} value={""}>{"None"}</option>);
        }
        if (this.props.machinesData && this.props.machinesData.tableData && this.props.machinesData.tableData.length > 0) {
            this.props.machinesData.tableData.map((data) => {
                machinesList.push(
                    <option key={data.id} value={data.id}>{data.label}</option>
                );
            })
        }
        return machinesList;
    }

    // create devices dropdown list
    getDeviceMacAddress = () => {
        let deviceList = [];
        deviceList.push(<option key={""} disabled={true} value={""}>{"Select"}</option>);
        if ((this.state.validationData.monitorDeviceIdentifier.validations && !this.state.validationData.monitorDeviceIdentifier.validations.required) || !(this.state.validationData.monitorDeviceIdentifier.validations)) {
            deviceList.push(<option key={""} value={""}>{"None"}</option>);
        }
        if (this.props.devicesData && this.props.devicesData.tableData && this.props.devicesData.tableData.length > 0) {
            this.props.devicesData.tableData.map((data) => {
                deviceList.push(
                    <option key={data.deviceIdentifier} value={data.deviceIdentifier}>{data.deviceIdentifier}</option>
                );
            })
        }
        return deviceList;
    }

    // create monitor-types dropdown list
    getMonitorType = () => {
        let monitorTypeList = [];
        monitorTypeList.push(<option key={""} disabled={true} value={""}>{"Select"}</option>);
        if ((this.state.validationData.monitorTypeName.validations && !this.state.validationData.monitorTypeName.validations.required) || !(this.state.validationData.monitorTypeName.validations)) {
            monitorTypeList.push(<option key={""} value={""}>{"None"}</option>);
        }
        let found = false;
        let plantOrganization = "";

        if (this.state.plantId) {
            this.props.scopeSelector.subOrganizations.map((organization) => {
                if (organization.plants && organization.plants.length > 0) {
                    organization.plants.map((plant) => {
                        if (plant.id === this.state.plantId && !found) {
                            plantOrganization = organization.id;
                            found = true;
                        }
                    })
                }
            })
        }


        if (plantOrganization && this.props.staticData && this.props.staticData.staticData) {
            let plantOrganizationStaticData = this.props.staticData.staticData[plantOrganization]
            if (plantOrganizationStaticData && plantOrganizationStaticData.monitortypes) {
                plantOrganizationStaticData.monitortypes.map((data) => {
                    monitorTypeList.push(
                        <option key={data.id} value={data.id}>{data.name}</option>
                    );
                })
            }
        }
        return monitorTypeList;
    }

    componentDidMount() {
        if (this.props.data) {
            let entityNames = Object.keys(this.props.data)
            entityNames.map((data) => {
                if (this.props.data[data] == null) {
                    this.props.data[data] = ""
                }
            })
            this.setState({
                id: this.props.data.id,
                featureName: this.props.data.featureName,
                specs: JSON.stringify({
                    axis: this.props.data.axis,
                    multiplier: this.props.data.multiplier,
                    band: this.props.data.band
                })

            }, () => {
                this.getMonitorTypeSpecs();
            })
        } else {
            this.setState({
                id: '',
                featureName: '',
                specs: JSON.stringify({
                    axis: '',
                    multiplier: '',
                    band: ''
                })

            }, () => {
                this.getMonitorTypeSpecs();
            })
        }
        if (this.props.selectedScopeData) {
            this.setState({
                plantId: this.props.selectedScopeData,
            })
        }

        if (this.props.validationData && this.props.validationData.config && this.props.validationData.config.SpectralFeatures) {
            this.setState({ validationData: this.props.validationData.config.SpectralFeatures });
        }
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevState.plantId != this.state.plantId && this.state.plantId && this.state.plantId != "") {
            this.props.getTableData("DevicesTable", this.state.plantId, true, false, this.state.deviceIdentifier);
            this.props.getTableData("MachinesTable", this.state.plantId, true);
        }

        if (prevProps.jsonData !== this.props.jsonData) {
            this.props.jsonData.json ? this.setState({
                specs: this.props.jsonData.json
            })
                : this.setState({
                    selectedTab: 0
                })
        }
    }

    // disable save button based on validations
    disableSaveButton = () => {
        if (this.state.featureName === "" && (this.state.validationData.featureName.validations && this.state.validationData.featureName.validations.required)) {
            return true;
        }
        else {
            return false;
        }
    }

    // set card header
    getCardHeader = () => {
        if (this.props.data && this.props.data.featureName && this.props.data.featureName != "") {
            return this.props.data.featureName;
        }
        else {
            return "Create New"
        }
    }

    // check validations of the user input fields
    checkValidation = (field, value) => {
        switch (field) {
            case "deviceIdentifier":
                if (!this.state.deviceIdentifierError && value === "" && (this.state.validationData.deviceIdentifier.validations && this.state.validationData.deviceIdentifier.validations.required)) {
                    this.setState({ deviceIdentifierError: true });
                } else if (this.state.deviceIdentifierError) {
                    if ((value === "" && (this.state.validationData.deviceIdentifier.validations && this.state.validationData.deviceIdentifier.validations.required))) {
                        //skip
                    }
                    else {
                        this.setState({ deviceIdentifierError: false });
                    }
                };
                break;
            case "machineId":
                if (!this.state.machineError && value === "" && (this.state.validationData.machineName.validations && this.state.validationData.machineName.validations.required)) {
                    this.setState({ machineError: true });
                } else if (this.state.machineError) {
                    if ((value === "" && (this.state.validationData.machineName.validations && this.state.validationData.machineName.validations.required))) {
                        //skip
                    }
                    else {
                        this.setState({ machineError: false });
                    }
                };
                break;
            case "monitorTypeId":
                if (!this.state.monitorTypeError && value === "" && (this.state.validationData.monitorTypeName.validations && this.state.validationData.monitorTypeName.validations.required)) {
                    this.setState({ monitorTypeError: true });
                } else if (this.state.monitorTypeError) {
                    if ((value === "" && (this.state.validationData.monitorTypeName.validations && this.state.validationData.monitorTypeName.validations.required))) {
                        //skip
                    }
                    else {
                        this.setState({ monitorTypeError: false });
                    }
                };
                break;
            case "label":
                if (!this.state.labelError && value === "" && (this.state.validationData.label.validations && this.state.validationData.label.validations.required)) {
                    this.setState({ labelError: true });
                } else if (!this.state.labelError && (this.state.validationData.label.validations) && (value.length > this.state.validationData.label.validations.maxLength)) {
                    this.setState({ labelError: true });
                } else if (this.state.labelError) {
                    if ((value === "" && (this.state.validationData.label.validations && this.state.validationData.label.validations.required) || ((this.state.validationData.label.validations) && (value.length > this.state.validationData.label.validations.maxLength)))) {
                        //skip
                    }
                    else {
                        this.setState({ labelError: false });
                    }
                };
                break;
            case "notes":
                if (!this.state.notesError && value === "" && (this.state.validationData.notes.validations && this.state.validationData.notes.validations.required)) {
                    this.setState({ notesError: true });
                } else if (!this.state.notesError && (this.state.validationData.notes.validations) && (value.length > this.state.validationData.notes.validations.maxLength)) {
                    this.setState({ notesError: true });
                } else if (this.state.notesError) {
                    if ((value === "" && (this.state.validationData.notes.validations && this.state.validationData.notes.validations.required) || ((this.state.validationData.notes.validations) && (value.length > this.state.validationData.notes.validations.maxLength)))) {
                        //skip
                    }
                    else {
                        this.setState({ notesError: false });
                    }
                };
                break;
            case "specs":
                if (!this.state.specsError && value === "" && (this.state.validationData.specs.validations && this.state.validationData.specs.validations.required)) {
                    this.setState({ specsError: true });
                } else if (!this.state.specsError && (this.state.validationData.specs.validations) && (value.length > this.state.validationData.specs.validations.maxLength)) {
                    this.setState({ specsError: true });
                } else if (this.state.specsError) {
                    if ((value === "" && (this.state.validationData.specs.validations && this.state.validationData.specs.validations.required) || ((this.state.validationData.specs.validations) && (value.length > this.state.validationData.specs.validations.maxLength)))) {
                        //skip
                    }
                    else {
                        this.setState({ specsError: false });
                    }
                };
                break;
        }
    }

    render() {
        const {t}=this.props;
        return (
            <Card className={this.props.classes.card}>
                <div className={this.props.classes.actionButtonContainer}>
                    <div className={this.props.classes.backButtonContainer}>
                        <KeyboardBackspace onClick={this.goToBackPage} classes={{ root: this.props.classes.arrowBack }} />
                        <div className={this.props.classes.entityTitle}>{this.getCardHeader()}</div>
                    </div>
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                        <Button variant="contained"
                            className={this.props.classes.saveButton}
                            disabled={this.state.disableSaveButton || this.disableSaveButton()}
                            onClick={this.updateMonitorsData}>{this.props.t("Save")}</Button>
                    </div>
                </div>
                <CardContent className={this.props.classes.cardContent} classes={{ root: this.props.classes.cardContentRoot }}>
                    {this.state.validationData && this.state.validationData.featureName && this.state.validationData.featureName.page_visible ? (
                        <div className={this.props.classes.inputFieldDiv}>
                            <InputLabel className={this.props.classes.textFieldLabel}>
                                {this.state.validationData.featureName.display_name}
                                {(this.state.validationData.featureName.validations && this.state.validationData.featureName.validations.required) ? (<span className={this.props.classes.requiredClass}>*</span>) : null}
                            </InputLabel>
                            <TextField
                                id="label"
                                name="label"
                                variant="outlined"
                                className={this.props.classes.textField}
                                helperText={(this.state.validationData.featureName.validations && this.state.validationData.featureName.validations.helpText) ? (this.state.validationData.label.validations.helpText) : null}
                                onChange={(event) => this.inputChangeHandler(event, "featureName")}
                                value={this.state.featureName}
                                error={this.state.labelError}
                                InputProps={{
                                    classes: {
                                        input: this.props.classes.outlinedInput
                                    }
                                }}
                            />
                        </div>
                    )
                        : null
                    }
                    {this.state.validationData && this.state.validationData.monitorTypeName && this.state.validationData.monitorTypeName.page_visible ? (
                        <div className={this.props.classes.inputFieldDiv}>
                            <InputLabel className={this.props.classes.textFieldLabel}>
                                {this.state.validationData.monitorTypeName.display_name}
                                {(this.state.validationData.monitorTypeName.validations && this.state.validationData.monitorTypeName.validations.required) ? (<span className={this.props.classes.requiredClass}>*</span>) : null}
                            </InputLabel>
                            <Select
                                native
                                value={this.state.monitorTypeId}
                                onChange={(event) => this.inputChangeHandler(event, "monitorTypeId")}
                                input={
                                    <OutlinedInput
                                        variant="outlined"
                                        name="monitorTypeId"
                                        id="language-native-simple"
                                        labelWidth={this.state.labelWidth}
                                        className={this.props.classes.textField}
                                        error={this.state.monitorTypeError}
                                        classes={{ input: this.props.classes.outlinedInput }}
                                    />
                                }
                            >
                                {this.getMonitorType()}
                            </Select>
                        </div>
                    )
                        : null
                    }
                    {this.state.validationData && this.state.validationData.monitorDeviceIdentifier && this.state.validationData.monitorDeviceIdentifier.page_visible ? (
                        <div className={this.props.classes.inputFieldDiv}>
                            <InputLabel className={this.props.classes.textFieldLabel}>
                                {this.state.validationData.monitorDeviceIdentifier.display_name}
                                {(this.state.validationData.monitorDeviceIdentifier.validations && this.state.validationData.monitorDeviceIdentifier.validations.required) ? (<span className={this.props.classes.requiredClass}>*</span>) : null}
                            </InputLabel>
                            <Select
                                native
                                value={this.state.deviceIdentifier}
                                onChange={(event) => this.inputChangeHandler(event, "deviceIdentifier")}
                                input={
                                    <OutlinedInput
                                        variant="outlined"
                                        name="macAddeviceMacAddressdress"
                                        id="language-native-simple"
                                        labelWidth={this.state.labelWidth}
                                        className={this.props.classes.textField}
                                        error={this.state.deviceIdentifierError}
                                        classes={{ input: this.props.classes.outlinedInput }}
                                    />
                                }
                            >
                                {this.getDeviceMacAddress()}
                            </Select>
                        </div>
                    )
                        : null
                    }
                    {this.state.validationData && this.state.validationData.machineName && this.state.validationData.machineName.page_visible ? (
                        <div className={this.props.classes.inputFieldDiv}>
                            <InputLabel className={this.props.classes.textFieldLabel}>
                                {this.state.validationData.machineName.display_name}
                                {(this.state.validationData.machineName.validations && this.state.validationData.machineName.validations.required) ? (<span className={this.props.classes.requiredClass}>*</span>) : null}
                            </InputLabel>
                            <Select
                                native
                                value={this.state.machineId}
                                onChange={(event) => this.inputChangeHandler(event, "machineId")}
                                input={
                                    <OutlinedInput
                                        variant="outlined"
                                        name="machineId"
                                        id="language-native-simple"
                                        labelWidth={this.state.labelWidth}
                                        className={this.props.classes.textField}
                                        error={this.state.machineError}
                                        classes={{ input: this.props.classes.outlinedInput }}
                                    />
                                }
                            >
                                {this.getMachinesName()}
                            </Select>
                        </div>
                    )
                        : null
                    }
                    {this.state.validationData && this.state.validationData.notes && this.state.validationData.notes.page_visible ? (
                        <div className={this.props.classes.textareaInputFieldDiv}>
                            <InputLabel className={this.props.classes.textareaFieldLabel}>
                                {this.state.validationData.notes.display_name}
                                {(this.state.validationData.notes.validations && this.state.validationData.notes.validations.required) ? (<span className={this.props.classes.requiredClass}>*</span>) : null}
                            </InputLabel>
                            <TextField
                                id="note"
                                multiline
                                rows="6"
                                value={this.state.notes}
                                helperText={(this.state.validationData.notes.validations && this.state.validationData.notes.validations.helpText) ? (this.state.validationData.notes.validations.helpText) : null}
                                variant="outlined"
                                onChange={(event) => this.inputChangeHandler(event, "notes")}
                                className={this.props.classes.textArea}
                                error={this.state.notesError}
                                InputProps={{
                                    classes: {
                                        multiline: this.props.classes.outlinedInput
                                    }
                                }}
                            />
                        </div>
                    )
                        : null
                    }
                    {/* {this.state.validationData && this.state.validationData.specs && this.state.validationData.specs.page_visible ? (
                        <div className={this.props.classes.textareaInputFieldDiv}>
                            <InputLabel className={this.props.classes.textareaFieldLabel}>
                                {this.state.validationData.specs.display_name}
                                {(this.state.validationData.specs.validations && this.state.validationData.specs.validations.required) ? (<span className={this.props.classes.requiredClass}>*</span>) : null}
                            </InputLabel>
                            <TextField
                                id="specification"
                                multiline
                                rows="6"
                                helperText={(this.state.validationData.specs.validations && this.state.validationData.specs.validations.helpText) ? (this.state.validationData.specs.validations.helpText) : null}
                                value={this.state.specs}
                                variant="outlined"
                                onChange={(event) => this.inputChangeHandler(event, "specs")}
                                className={this.props.classes.textArea}
                                error={this.state.specsError}
                                InputProps={{
                                    classes: {
                                        multiline: this.props.classes.outlinedInput
                                    }
                                }}
                            />
                        </div>
                    )
                        : null
                    } */}
                    {/* <WidgetTabs
                        specs={"This is specs view"}
                        yaml={"This is yaml view"}
                    /> */}

                    {/* {
                        this.state.specs !== "" ?
                            
                            : null
                    } */}
                    <div style={{ clear: "both", minHeight: 200 }}>
                        <Paper>
                            <Tabs
                                value={this.state.selectedTab}
                                onChange={this.handleTabChange}
                                indicatorColor="primary"
                                textColor="primary"
                                variant="fullWidth"
                                TabIndicatorProps={{
                                    classes: {
                                        colorPrimary: this.props.classes.tabIndicator
                                    }
                                }}
                                style={{ marginTop: 10, marginBottom: 10 }}
                            >
                                <Tab label={this.props.t("SPECS")} key={"SPECS"} classes={{ selected: this.props.classes.selectedTab }} />
                                <Tab label={this.props.t("YAML")} key={"YAML"} classes={{ selected: this.props.classes.selectedTab }} />
                            </Tabs>
                        </Paper>
                        {this.state.selectedTab === 0 ? this.getSpecView() : this.getYamlView()}
                    </div>
                </CardContent>
            </Card >
        );
    }
}

const mapStateToProps = (state) => {
    return {
        staticData: state.staticDataReducer,
        devicesData: state.deviceDataReducer,
        machinesData: state.machinesDataReducer,
        validationData: state.viewDefinitionReducer,
        jsonData: state.constructJsonReducer
    }
}

const mapDispatchToProps = dispatch => {
    return ({
        getTableData: (selectedNavtab, currentScopeSelectionId, setToStore, showArchivedData, deviceIdentifier) => (dispatch(actionCreators.getTableData(selectedNavtab, currentScopeSelectionId, setToStore, showArchivedData, deviceIdentifier))),
        updateMonitorsEntity: (monitorsEntityData, id) => (dispatch(actionCreators.updateMonitorsEntity(monitorsEntityData, id))),
        updateSelectedTab: (selectedTab) => (dispatch(actionCreators.updateSelectedTab(selectedTab))),
        getJsonFromYaml: (yamlRequestObject) => (dispatch(actionCreators.getJsonFromYaml(yamlRequestObject))),
        getYamlFromJson: (jsonRequestObject) => (dispatch(actionCreators.getYamlFromJson(jsonRequestObject))),
        addNewCondition: (object) => (dispatch(actionCreators.addNewCondition(object)))
    })
}

export default withTranslation()(connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(SpectralFeaturesEntity)));

