import React, { Component, Fragment } from 'react';
import Picky from "react-picky";
import "react-picky/dist/picky.css";
import moment from "moment";
import { config } from '../../../configuration';
import { connect } from 'react-redux';
import * as actionCreators from '../../../actions/actionCreators';
import { getOidcAccessToken } from '../../../utils/oidcStorageUtils';
import { withTranslation } from "react-i18next";

let currentLanguage = localStorage.getItem("lang");
if (currentLanguage === "null" || currentLanguage === null) {
    currentLanguage = "en";
}

export class WaterfallDiagram extends Component {
    constructor(props) {
        super(props);
        this.axisList = [
            { "value": "X", "viewValue": this.props.t("Horizontal") },
            { "value": "Y", "viewValue": this.props.t("Vertical") },
            { "value": "Z", "viewValue": this.props.t("Axial") },
        ]
        this.state = {
            selectedAxis: { "value": "X", "viewValue": this.props.t("Horizontal") },
            axisOpen: false,
            isDisable: false,
            image: ""
        };

    }
   
    componentDidUpdate(prevProps) {
        if (prevProps.deviceId !== this.props.deviceId) {
            this.setState({image:''})
        }
        if (prevProps.range !== this.props.range) {
            let trendSearchStartDate = moment(this.props.range[1]);
            let trendSearchEndDate = moment(this.props.range[2]);
            let trendSearchDifferenceHours = trendSearchEndDate.diff(trendSearchStartDate, 'hours');
            if (trendSearchDifferenceHours >= 24) {
                this.setState({ isDisable: false })
            } else {
                this.setState({ isDisable: true })

            }
        }
        var elem = document.getElementById("myP");
        if (elem !== null) {
            document.getElementById('myP').addEventListener('fullscreenchange', (event) => {
                // document.fullscreenElement will point to the element that
                // is in fullscreen mode if there is one. If not, the value
                // of the property is null.
                if (document.fullscreenElement) {
                    //console.log(`Element: ${document.fullscreenElement.id} entered fullscreen mode.`);
                } else {
                    var element = document.getElementById("imageDiv");
                    element.classList.add("imageCss");
                    // console.log('Leaving full-screen mode.');
                }
            });
        }
        if ((prevProps.featureList !== (this.props && this.props.featureList)) ||(prevProps.dataLoggerFlag !==this.props.dataLoggerFlag)) {
            if((this.props && this.props.featureList) && this.props.dataLoggerFlag){
                this.props.featureList.map((item)=>{
                    if(item.viewValue===this.props.t("Horizontal Velocity")){
                        this.axisList = [
                            { "value": "Z", "viewValue": this.props.t("Horizontal") },
                        ]
                        this.setState({
                            selectedAxis: { "value": "Z", "viewValue": this.props.t("Horizontal") },
                        })
                    }
                    if(item.viewValue===this.props.t("Vertical Velocity")){
                        this.axisList = [
                            { "value": "Z", "viewValue": this.props.t("Vertical") },
                        ]
                        this.setState({
                            selectedAxis: { "value": "Z", "viewValue": this.props.t("Vertical") },
                        })
                    }
                    if(item.viewValue===this.props.t("Axial Velocity")){
                        this.axisList = [
                            { "value": "Z", "viewValue": this.props.t("Axial") },
                        ]
                        this.setState({
                            selectedAxis: { "value": "Z", "viewValue": this.props.t("Axial") },
                        })
                    }
                })
            }
            else{
                this.axisList = [
                    { "value": "X", "viewValue": this.props.t("Horizontal") },
                    { "value": "Y", "viewValue": this.props.t("Vertical") },
                    { "value": "Z", "viewValue": this.props.t("Axial") },
                ]
            }
        }
    }
    axisChange = (value) => {
        this.setState({ axisOpen: false, selectedAxis: value })
    }
    fetchAxisData = () => {
        this.props.props.setLoaderState({ "value": true });
        fetch(`${config.url.mindapp_server_url}/proxy/waterfall?from=${this.props.range[1]}&to=${this.props.range[2]}&monitorId=${this.props.monitorId}&axis=${this.state.selectedAxis.value}`,
            {
                headers: {
                    "Content-Type": 'application/json',
                    "Authorization": `Bearer ${getOidcAccessToken()}`,
                    "Accept-Language": localStorage.getItem("lang") || "en"
                }
            })
            .then(res => res.text())
            .then(
                (result,res) => {
                    if(result.includes("success")){
                        this.setState({
                            image: ''
                        }, () => {
                            this.props.props.setLoaderState({ "value": false });
                        });
                       
                    }else{
                        this.setState({
                            image: result
                        }, () => {
                            this.props.props.setLoaderState({ "value": false });
                        });
                    }
                },
                // Note: it's important to handle errors here
                // instead of a catch() block so that we don't swallow
                // exceptions from actual bugs in components.
                (error) => {
                    console.log(error);
                    this.setState({
                        image: ''
                    });
                    this.props.props.setLoaderState({ "value": false });

                }
            ).catch(function (error) {
                this.setState({
                    image: ''
                });
                this.props.props.setLoaderState({ "value": false });
            }
            );

    }
    openFullscreen() {
        var elem = document.getElementById("myP");
        var element = document.getElementById("imageDiv");
        element.classList.remove("imageCss");

        if (elem.requestFullscreen) {
            elem.requestFullscreen();
        } else if (elem.mozRequestFullScreen) { /* Firefox */
            elem.mozRequestFullScreen();
        } else if (elem.webkitRequestFullscreen) { /* Chrome, Safari & Opera */
            elem.webkitRequestFullscreen();
        } else if (elem.msRequestFullscreen) { /* IE/Edge */
            elem = window.top.document.body; //To break out of frame in IE
            elem.msRequestFullscreen();
        }
    }

    render() {
        const row = this.props.widgetsData.widgetData.waterFall;
        const { t } = this.props.props;
        
        return (
            <div className="realTime-panel display-text widgets-shadow">
                <div className="widgets-heading widgets-heading-info">
                    <h2 className="display-text">{row && this.props.t(row.header)}</h2>
                    <span className="float-right">{this.props.deviceName}</span>
                </div>
                <div className="widgets-container" style={{ display: 'flex' }}>
                    <div className="selector-Panel feature-range-wrp" style={{ width: '53%' }}>
                        <label>{row && this.props.t(row.feature)}</label>
                        <Picky className="feature-range"
                            value={this.state.selectedAxis}
                            options={this.axisList}
                            onChange={this.axisChange}
                            open={false}
                            keepOpen={this.state.axisOpen}
                            valueKey="value"
                            labelKey="viewValue"
                            multiple={false}
                            includeSelectAll={false}
                            includeFilter={false}
                            dropdownHeight={400}
                        />
                    </div>
                    <div className="selector-Panel get-btn-wrp">
                        <button style={{ opacity: this.state.isDisable ? 0.5 : 1,cursor: this.state.isDisable ? 'none' : 'pointer' }} disabled={this.state.isDisable} className="get-btn" onClick={() => this.fetchAxisData()}>{row && row.getBtn ? this.props.t(row.getBtn) : this.props.t("GET")}</button>
                    </div>
                    {this.state.image && <div style={{ cursor:'pointer', padding: '20px' }}>
                            <span onClick={() => this.openFullscreen()} style={{ fontSize: "10px",textDecoration:'underline',marginLeft:'30px' }}>{t("View in full screen")}</span>
                    </div>
                    }
                    <div className="clearfix"></div>

                </div>
                {this.state.image ?
                    <div className="highChart-waterfall" id="myP" onKeyPress={(e) => this.handleKeyPress(e)} >
                        <img id='imageDiv' className='imageCss' src={`data:image/png;base64,${this.state.image}`} />
                    </div>
                    :
                    <div className='higchart_waterfall_Nodata'>{t("No data to display")}</div>}

            </div>
        )
    }
}

const mapSatateToProps = state => {
    return {
        loaderState: state.loaderReducer,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        setLoaderState: (value) => (dispatch(actionCreators.setLoaderState(value))),

    }
}

// export default WaterfallDiagram
export default withTranslation()(connect(mapSatateToProps, mapDispatchToProps)(WaterfallDiagram));
