import * as actionTypes from "../actions/actionTypes";

const initialState = {
  selectedPlantId: null,
};

const applicationReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.SET_APPLICATION_SELECTED_PLANT_ID:
      return Object.assign({}, state, {
        selectedPlantId: action.payload,
      });
    default:
      return state;
  }
};

export default applicationReducer;
