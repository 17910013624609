import React, { Component } from 'react'
import { Chart } from "react-google-charts";
import { connect } from 'react-redux';
import moment from "moment";
import * as actionCreators from '../../../actions/actionCreators';
import { withTranslation } from "react-i18next";


class AlarmsAnalysisContainer extends Component {
    constructor(props) {
        super(props);
        this.state = {
            data: []
        }
    }

    componentDidMount() {
        let from = moment(this.props.range[1]).utc().format("YYYY-MM-DDTHH:mm:00") + "Z";
        let to = moment(this.props.range[2]).utc().format("YYYY-MM-DDTHH:mm:00") + "Z";
        this.props.getAlarmAnalysisData(1, this.props.plantId, from, to)
    }

    componentDidUpdate(prevProps) {
        if (prevProps.alarmsAnalysis !== this.props.alarmsAnalysis) {
            let from = moment(this.props.range[1]).utc().format("YYYY-MM-DDTHH:mm:00") + "Z";
            let to = moment(this.props.range[2]).utc().format("YYYY-MM-DDTHH:mm:00") + "Z";
            if (this.props.alarmsAnalysis === "byMachine") {
                this.props.getAlarmAnalysisData(1, this.props.plantId, from, to);
            }
            if (this.props.alarmsAnalysis === "byFrequency") {
                this.props.getAlarmAnalysisData(2, this.props.plantId, from, to);
            }
            if (this.props.alarmsAnalysis === "byDate") {
                this.props.getAlarmAnalysisData(3, this.props.plantId, from, to);
            }
        }

        if (prevProps.alarmsData.alarmData !== this.props.alarmsData.alarmData) {
            this.drawChart(this.props.alarmsData.alarmData);
        }
    }

    drawChart = (alarmsData) => {
        let data = [];
        if (this.props.alarmsAnalysis === "byDate") {
            data = [["Name", "Value0", { type: "string", role: "tooltip" }]];
            alarmsData.forEach(ele => {
                data.push([ele.name, ele.alarmCount, `Alarm Count : ${ele.alarmCount}`])
            })
        } else {
            data = [["Name", "Value0", { type: "string", role: "tooltip" }, "Cumulative Frequency", { type: "string", role: "tooltip" }]];
            let temp = 0;
            alarmsData.sort( function(a,b) {
                return b.alarmCount - a.alarmCount;
              });
            alarmsData.forEach(ele => {
                temp += ele.alarmCount;
                data.push([ele.name, ele.alarmCount, `Alarm Count : ${ele.alarmCount}`, temp, `Cumulative Alarm Count ${temp}`])
            })
        }
        this.setState({
            data: data
        })
    }

    render() {
        const {t}=this.props;
        return (
            <div className="QualityData-cntr display-text">
                <Chart
                    width={'500px'}
                    height={'300px'}
                    graph_id="ComboChart"
                    chartType="ComboChart"
                    loader={<div>{t("Loading Chart")}</div>}
                    data={this.state.data}
                    options={{
                        isStacked: true,
                        vAxis: { title: '' },
                        hAxis: { title: '' },
                        legend: 'none',
                        seriesType: 'bars',
                        series: [{},
                        { type: "line" }]
                    }}
                    rootProps={{ 'data-testid': '1' }}
                />
            </div>
        )
    }
}


const mapStateToProps = state => {
    return {
        alarmsData: state.alarmAnalysisDataReducer
    }
}

const mapDispatchToProps = dispatch => {
    return {
        getAlarmAnalysisData: (type, plantId, from, to) => (dispatch((actionCreators.getAlarmAnalysisData(type, plantId, from, to)))),
    }
}

export default withTranslation()(connect(mapStateToProps, mapDispatchToProps)(AlarmsAnalysisContainer));
