import React from 'react';
import { Button, Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions } from '@material-ui/core';
import { withTranslation } from "react-i18next";


const DeleteConfirmationModal = (props) => {
    return (
        <Dialog
            open={props.openPromptModal}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            disableBackdropClick={true}
        >
            <DialogTitle id="alert-dialog-title">{props.t("Delete Entity")}</DialogTitle>
            <DialogContent>
                <DialogContentText id="alert-dialog-description">
                    {props.message ? props.message : props.t("Continuing with the current action will result in deleting the entity and its dependencies.")}
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={() => props.handleDeletePromptModal("disagree")} color="primary">
                    {props.t("Cancel")}
                </Button>
                <Button onClick={() => props.handleDeletePromptModal("agree")} color="primary">
                    {props.t("Delete")}
                </Button>
            </DialogActions>
        </Dialog>
    )
}

export default withTranslation()(DeleteConfirmationModal);