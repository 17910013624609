import i18n from "i18next";
import XHR from "i18next-xhr-backend";
import { initReactI18next } from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import Backend from "i18next-http-backend";
let lang = localStorage.getItem("lang");
if (lang === null || lang === "null") {
  lang = "en";
}

i18n
  .use(Backend)
  .use(XHR)
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    lng: lang,
    backend: {
      loadPath: `https://idap-multilingual.s3.ap-south-1.amazonaws.com/{{lng}}.json`,
      // remove this and add `https://idap-multilingual.s3.ap-south-1.amazonaws.com/{{lng}}.json` , temp fix
    },
    fallbackLng: lang,
    ns: ["translations"],
    defaultNS: "translations",
    debug: false,
    keySeparator: ".",
    interpolation: {
      escapeValue: false,
      formatSeparator: ",",
    },
    react: {
      wait: true,
      bindI18n: "languageChanged loaded",
      bindStore: "added removed",
      nsMode: "default",
      useSuspense: false,
    },
  });

export default i18n;
