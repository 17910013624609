import * as React from "react";
import { Tooltip, Button, IconButton, Popover } from "@material-ui/core";
import { config, IDAP_SSO_DOMAIN_NAME } from "../../configuration";
import { useTranslation } from "react-i18next";

let ninePin = require("../../assets/images/9pin.png");

const SubscribeApps = (props) => {
  const { i18n } = useTranslation();
  
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  const openUrl = (item) => {
    const languageCookie = `idap-lang-${config.url.env}=${i18n.language}`;
    document.cookie = `${languageCookie}; domain=${IDAP_SSO_DOMAIN_NAME}; secure`;

    let plantId =
      localStorage.getItem("plantValue") ||
      localStorage.getItem("selectedScopeData");
    const dashboard = localStorage.getItem("dashboard");

    let rootUrl = item.rootUrl;

    if (rootUrl.includes("?")) {
      rootUrl += `&plantId=${plantId}`;
    } else {
      if (item.name === "DRS" && dashboard === "summaryPlant") {
        plantId = "all";
      }
      rootUrl += `?plantId=${plantId}`;
    }
    
    window.open(rootUrl);
    handleClose();
  };

  return (
    <div>
      <IconButton
        id="ninePinIconButton"
        style={componentStyles.ninePinBtn}
        onClick={handleClick}
      >
        <img alt="" src={ninePin} style={componentStyles.ninePinImg} />
      </IconButton>
      <Popover
        id={id}
        style={componentStyles.popoverStyle}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
      >
        <div style={componentStyles.mainContainer}>
          <div style={componentStyles.listContainer}>
            {props.apps.map((item, index) => {
              return (
                item.clientId !== "daas" && (
                  <ul
                    onClick={() => openUrl(item)}
                    key={index}
                    style={componentStyles.appListWrapper}
                  >
                    <li style={componentStyles.appListDisplay}>
                      <Tooltip
                        title={
                          <span style={componentStyles.appName}>
                            {item.name}
                          </span>
                        }
                      >
                        <IconButton id={item.name + "IconButton"}>
                          <img
                            alt=""
                            src={item.logo}
                            style={componentStyles.appImg}
                          />
                        </IconButton>
                      </Tooltip>
                      <p className="font-weight-normal mb-3">{item.name}</p>
                    </li>
                  </ul>
                )
              );
            })}
          </div>
          <Button
            variant="contained"
            onClick={() => {
              handleClose();
              window.open(props.plantOsUrl + "applications", "_self");
            }}
            style={componentStyles.suiteHomeLink}
          >
            {props.plantOSbuttonText}
          </Button>
        </div>
      </Popover>
    </div>
  );
};

const componentStyles = {
  mainContainer: {
    width: "560px",
  },
  listContainer: {
    display: "flex",
    flexWrap: "wrap",
    padding: "12px",
    paddingTop: "0.8rem",
  },
  suiteHomeLink: {
    flex: 1,
    width: "100%",
    paddingTop: "5px",
    marginBottom: "0px",
    background: "rgb(25, 118, 210)",
    color: "white",
    borderTop: "1px solid #cfcfcf",
    boxShadow: "none",
  },
  appListWrapper: {
    width: "33.3%",
    textAlign: "center",
    paddingInlineStart: "0px",
  },
  appListDisplay: {
    listStyle: "none",
    textAlign: "center",
    marginBottom: "5px",
  },
  ninePinBtn: {
    marginTop: "3px",
    padding: "5px",
    fontSize: "5px",
  },
  ninePinImg: {
    width: "40px",
    cursor: "pointer",
  },
  popoverStyle: {
    height: "calc(100vh - 10px)",
    width: "100%",
  },
  appImg: {
    width: "50px",
    height: "50px",
  },
  appName: {
    fontSize: "14px",
  },
};

export default SubscribeApps;