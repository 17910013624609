import React, { Fragment, Component } from 'react';
import { Drawer, List, ListItem, ListItemText, IconButton, withStyles, Grid, Divider } from '@material-ui/core';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { ArrowDropDown, Domain, PermDataSetting, People, Room } from '@material-ui/icons';
import { Button } from '@material-ui/core';
import { Close } from '@material-ui/icons';

import { Scrollbars } from 'react-custom-scrollbars';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';

import * as actionCreators from '../actions/actionCreators';
import { config } from '../configuration';
import ScopeSelector from './ScopeSelector';
import Collapse from '@material-ui/core/Collapse';
import moment from "moment";
import { withTranslation } from "react-i18next";

import PopoverTable from '../components/Monitoring/Panels/AlarmsPopover/PopoverTable';
import TextField from '@material-ui/core/TextField';

import ArrowRightIcon from '@material-ui/icons/ArrowRight';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import GroupWorkIcon from '@material-ui/icons/GroupWork';
import DashboardIcon from '@material-ui/icons/Dashboard';
import PinDropRoundedIcon from '@material-ui/icons/PinDropRounded';
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord';
import { getOidcAccessToken } from '../utils/oidcStorageUtils';

let server_url = config.url.server_url;
let count=0;

const styles = theme => ({
    total: {
        width: 'auto',
        paddingLeft: '4px',
        paddingRight: '4px',
        fontSize: '13px',
        height: '25px',
        borderRadius: '4px',
        background: '#2196f3',
        color: '#fff',
        textAlign: 'center'
    },
    listText: {
        color: '#2196f3',
        fontSize: '0.95rem',
        paddingLeft: '10px',
        paddingRight: '10px'
    },
    paper: {
        top: 50,
        width: '100%',
        position: 'relative',
        borderRight: 0,
        height: '93vh',
        backgroundColor: 'fff',
        overflow: 'hidden'
    },
    navigationLabel: {
        padding: 9,
        color: '#2196f3',
        'font-weight': 400,
        'font-size': '1rem',
        'margin-block-start': '0.8rem',
        'margin-block-end': '0.8rem'
    },
    logoClass: {
        'max-width': '150px',
        height: '34px',
        //padding: '10px 20.5px 13px 10px',
    },
    headerSection: {
        height: '40px',
        backgroundColor: '#ffc107',
        // padding: ' 0px 5px 0px 5px'
    },
    productHeader: {
        color: '#2196f3',
        fontSize: '14px',
        fontWeight: 500,
        lineHeight: '5',
    },
    'listItem': {
        '& > span': {
            color: '#2196f3',
            fontSize: '0.95rem'
        }
    },
    'link': {
        display: 'block',
        color: '#2196f3',
        'text-decoration': 'none !important',
        '&:hover': {
            color: '#2196f3'
        },
        '& svg': {
            'font-size': 18
        }
    },
    activeLink: {
        '&>a': {
            backgroundColor: 'rgba(0,0,0,.15)',
            // 'border-radius': '0 100px 100px 0',
            width: '90%',
            height: 'auto',
            color: '#2196f3 !important'
        }
    },
    listItemButtons: {
        'padding-left': 34
    },
    listItemHeader: {
        'padding-right': 10,
        color: 'rgba(255, 255, 255, 0.6000000238418579)'
    },
    listHeaderDropdown: {
        fill: '#fff'
    },
    imageContainer: {
        width: '100%',
        height: '9vh',
        display: 'flex',
        'justify-content': 'space-between',
        'align-items': 'center'
    },
    icon: {
        color: "#9e9e9e",
        marginRight: '3px',
        '& svg': {
            'font-size': 20
        }
    },

    browseBar: {
        width: '100%',
        display: 'flex',
        backgroundColor: '#ffc107!important',
        'justify-content': 'space-between',
        'align-items': 'center'
    },
    root: {
        display: 'flex',
        flexWrap: 'wrap',
    },
    textField: {
        // marginLeft: theme.spacing(1),
        // marginRight: theme.spacing(1),
        width: '90%'
    },
});

class Navigation extends Component {
    constructor(props) {
        super(props);
        this.state = {
            activeItem: {},
            open: [],
            tableDataArray: [],
            plantList: '',
            plantPermission: ['dashboard'],
            monitorList: {},
            copyMonitorList: []
        }
        this.tableDataArray = [];
        this.isAvailable = false;
        this.deviceAlarmData = [];
        this.timer = '';
        this.handleChange = this.handleChange.bind(this);
    }

    componentDidMount() {
        if (this.props.selectedTab === 5 && (this.props.selectedScopeData !== undefined && this.props.selectedScopeData !==null)) {
            this.setState({ plantId: this.props.selectedScopeData }, () => {
                this.fetchMonitorList();
                // this.props.getTableData('MonitorsTable', this.props.selectedScopeData , false);
                this.props.getMonitorList(this.props.selectedScopeData, (list) => {
                  //  console.log('MonitorList', list);
                })
            })
        }
        if (this.props.dashboardType.type === "summaryPlant") {
            this.props.getSummaryPlantData(this.props.t)
        }
        if (this.props.selectedTab === 4 && this.props.selectedScopeData) {
            //this.props.getTableData('MonitorsTable', this.props.selectedScopeData , false);
            this.props.scopeSelector.subOrganizations.map((organization) => {
                if (organization.plants.length > 0) {
                    organization.plants.map((plant) => {
                        if (this.state.plantPermission.indexOf(plant.permissions) !== -1) {
                            this.props.getTableData('MonitorsTable', plant.id, false);
                        }
                    })
                }
            })

        }       
    }

    // loadDataForNavBar = (data) => {
    //     let navData= [];
    //     data.forEach(ele=>{
    //         if(navData.length >0){
    //            let index = navData.findIndex(object=> object.machineGroups.id === ele.machines.machineGroups.id);
    //             if(index !==  -1){
    //                 let machineIndex = navData[index].machines.findIndex(element=> element.machines.id === ele.machines.id);
    //                 if(machineIndex !== -1){
    //                     navData[index].machines[machineIndex].monitor.push(ele)
    //                 }else{
    //                     navData[index].machines.push({machines:ele.machines, monitor: [ele], status: true})
    //                 }
    //             }else{
    //                 navData.push({ machineGroups : ele.machines.machineGroups, machines: [{machines:ele.machines, monitor: [ele], status: true}], status: true });
    //             }
    //         }else{
    //             navData.push({ machineGroups : ele.machines.machineGroups, machines: [{machines:ele.machines, monitor: [ele], status: true}] , status: true});
    //         }
    //     })
    //     this.tableDataArray = [...navData];
    //     this.setState({
    //         tableDataArray : this.tableDataArray
    //     }, ()=>{
    //         if(this.state.tableDataArray.length > 0 && this.state.tableDataArray[0].machines.length >0 && this.state.tableDataArray[0].machines[0].monitor.length >0 ){
    //             this.navItemSelection(this.state.tableDataArray[0].machines[0].monitor[0],this.state.tableDataArray[0].machines[0].monitor[0].id, this.state.tableDataArray[0].machines[0].monitor[0].deviceIdentifier);
    //             this.props.history.push("/dashboard/MonitoringTable");
    //         }else{
    //             this.props.setDeviceData({});
    //         }
    //     })
    // }

    fetchMonitorList = () => {
        //  this.closeDrawer();
        if (this.props.selectedScopeData !== undefined && this.props.selectedScopeData !==null) {
            count=0;
            this.props.getMonitorList(this.props.selectedScopeData, (list) => {
                list.responseTime = new Date();
                if(list.areaStatusResponseList === null){
                    this.props.setDeviceData()
                }
                this.setState({
                    monitorList: list,
                    copyMonitorList: list,
                    inputValue: ''
                })
            })
        }
    }
    componentDidUpdate(prevProps, prevState) {
        if (prevProps.drawerData.value !== this.props.drawerData.value) {
            if (this.props.selectedTab === 5 && this.props.drawerData.value === true) {
                this.fetchMonitorList();
                this.timer = setInterval(this.fetchMonitorList, 60000);
            } else {
                clearInterval(this.timer);
            }
        }
        if (prevProps.deviceData.deviceData !== this.props.deviceData.deviceData && this.props.deviceData.deviceData.deviceData) {
           this.setState({ activeItem: { [this.props.deviceData.deviceData.deviceData.id]: true } })
           

        }
        
        var elmnt = document.getElementById("activeLinkId");
        
        if(elmnt && this.props.drawerData.value && count < 4){
            count++;
            if(elmnt){
                elmnt.scrollIntoView({block: 'center'});
            }
        }

        if (prevProps.selectedTab !== this.props.selectedTab && this.props.selectedTab === 5 && (this.props.selectedScopeData !== undefined && this.props.selectedScopeData !==null)) {
            this.setState({
                plantId: this.props.selectedScopeData,
                inputValue: '',
                activeItem: {}
            }, () => {
                // this.props.getTableData('MonitorsTable', this.props.selectedScopeData , false);
                this.fetchMonitorList();

                // setInterval( this.fetchMonitorList , 60000);
            })
            if (this.props.dashboardType.type === "summary") {
                this.props.history.push("/dashboard/MonitoringTable");
            }
        }
        if (this.props.selectedTab === 5 && this.props.selectedScopeData && prevProps.selectedScopeData !== this.props.selectedScopeData) {
            this.setState({
                plantId: this.props.selectedScopeData,
                inputValue: '',
                activeItem: {}
            }, () => {
                // this.props.getTableData('MonitorsTable', this.props.selectedScopeData , false);
                this.fetchMonitorList();
                // setInterval( this.fetchMonitorList , 60000);
            })
            if (this.props.dashboardType.type === "summary") {
                this.props.history.push("/dashboard/MonitoringTable");
            }

        }
        // if (this.props.selectedTab !== prevProps.selectedTab){
        //     this.props.history.replace("/dashboard");
        // }
        // if (this.props.selectedTab === 4 && prevProps.selectedScopeData !== this.props.selectedScopeData) {
        //     this.props.getTableData('MonitorsTable', this.props.selectedScopeData , false);
        // }

        if (this.props.selectedTab === 4 && this.props.selectedScopeData && prevProps.selectedScopeData !== this.props.selectedScopeData) {
            // this.props.getTableData('MonitorsTable', this.props.selectedScopeData , false);
            this.props.scopeSelector.subOrganizations.map((organization) => {
                if (organization.plants.length > 0) {
                    organization.plants.map((plant) => {
                        if (this.state.plantPermission.indexOf(plant.permissions) !== -1) {
                            this.props.getTableData('MonitorsTable', plant.id, false);
                        }
                    })
                }
            })

        }

        // if(this.props.selectedTab === 5 && prevProps.tableData !== this.props.tableData) {
        //     if(this.props.selectedScopeData === this.state.plantId){
        //         this.loadDataForNavBar(this.props.tableData.tableData);
        //     }else{
        //         this.loadDataForNavBar([]);
        //     }
        // }

        if ((this.props.selectedTab === 5 && this.state.monitorList.areaStatusResponseList !== undefined && this.state.monitorList.areaStatusResponseList !== null && prevState.monitorList !== this.state.monitorList) || (prevProps.deviceData.deviceData !== this.props.deviceData.deviceData && this.props.deviceData.deviceData.deviceData)) {
            let Outerlist = this.state.monitorList;
           let abc= parseInt(this.props && this.props.deviceData && this.props.deviceData.deviceData && this.props.deviceData.deviceData.deviceData && this.props.deviceData.deviceData.deviceData.id)
           if(Outerlist.hasOwnProperty("areaStatusResponseList") && Outerlist.areaStatusResponseList.length>0){
            Outerlist.areaStatusResponseList.map((list)=>{
                list.expand = true;
            if (list.hasOwnProperty("machineGroupStatusResponses") && list.machineGroupStatusResponses.length > 0) {
                list.machineGroupStatusResponses.forEach(ele => {
                    ele.expand = true;
                    ele.machineStatusReponse.forEach(item => {
                        item.monitorStatusResponse.forEach(data => {
                            let devceID = this.props && this.props.deviceData && this.props.deviceData.deviceData && this.props.deviceData.deviceData.deviceData && this.props.deviceData.deviceData.deviceData.id;
                            if (devceID) {
                                if (data.id === abc) {
                                    item.expand = true;
                                }
                            } else {
                                item.expand = false;
                            }
                        })

                    })
                })
            }  })
        }else {
            Outerlist = [];
            }
            this.setState({
                monitorList: Outerlist
            }, () => {
                //data.name, data.id, ele.name
                
                if (this.state.monitorList.hasOwnProperty("areaStatusResponseList") && this.state.monitorList.areaStatusResponseList.length>0 && this.state.monitorList.areaStatusResponseList[0].hasOwnProperty("machineGroupStatusResponses") && this.state.monitorList.areaStatusResponseList[0].machineGroupStatusResponses.length > 0 && this.state.monitorList.areaStatusResponseList[0].machineGroupStatusResponses[0].machineStatusReponse.length > 0 && this.state.monitorList.areaStatusResponseList[0].machineGroupStatusResponses[0].machineStatusReponse[0].monitorStatusResponse.length > 0 && Object.keys(this.state.activeItem).length === 0) {
                    let deviceName = localStorage.getItem("deviceName");
                    let deviceId = localStorage.getItem("deviceId");
                    let machineName = localStorage.getItem("machineName");
                    let deviceIdentifier = localStorage.getItem("deviceIdentifier");
                    let currentLanguage = localStorage.getItem("lang");

                    if (currentLanguage === "null" || currentLanguage === null) {
                        currentLanguage = "en"
                    }
                    if (deviceName !== null) {
                        this.props.setWidgetLanguage(currentLanguage);
                        this.props.setDeviceData({ "deviceName": deviceName, "id": deviceId, "machineName": machineName, "deviceIdentifier": deviceIdentifier })

                    } else {
                        this.navItemSelection(this.state.monitorList.areaStatusResponseList[0].machineGroupStatusResponses[0].machineStatusReponse[0].monitorStatusResponse[0].name, this.state.monitorList.areaStatusResponseList[0].machineGroupStatusResponses[0].machineStatusReponse[0].monitorStatusResponse[0].id, this.state.monitorList.areaStatusResponseList[0].machineGroupStatusResponses[0].machineStatusReponse[0].name, this.state.monitorList.areaStatusResponseList[0].machineGroupStatusResponses[0].machineStatusReponse[0].monitorStatusResponse[0].deviceIdentifier);

                    }


                    this.props.history.push("/dashboard/MonitoringTable");
                }
            })
        }


        if (this.props.selectedTab === 4 && prevProps.tableData !== this.props.tableData) {
            this.tableDataArray = [];
            this.props.scopeSelector.subOrganizations.map((organization) => {
                if (organization.plants.length > 0) {
                    organization.plants.map((plant) => {
                        if (this.state.plantPermission.indexOf(plant.permissions) !== -1) {
                            let navData = [];
                            this.props.tableData.tableData.forEach(ele => {
                                if (navData.length > 0) {
                                    let index = navData.findIndex(object => object.machineGroups.id === ele.machines.machineGroups.id);
                                    if (index !== -1) {
                                        let machineIndex = navData[index].machines.findIndex(element => element.machines.id === ele.machines.id);
                                        if (machineIndex !== -1) {
                                            navData[index].machines[machineIndex].monitor.push(ele)
                                        } else {
                                            navData[index].machines.push({ machines: ele.machines, monitor: [ele], status: true })
                                        }
                                    } else {
                                        navData.push({ machineGroups: ele.machines.machineGroups, machines: [{ machines: ele.machines, monitor: [ele], status: true }], status: true });
                                    }
                                } else {
                                    navData.push({ machineGroups: ele.machines.machineGroups, machines: [{ machines: ele.machines, monitor: [ele], status: true }], status: true });
                                }
                            })
                            this.tableDataArray = [...navData];
                            this.setState({
                                tableDataArray: this.tableDataArray
                            })
                        }
                    })
                }
            })
        }

    }

    // get icons corresponding to entities in navigation bar
    getIcon = (item) => {
        let icon = null;

        if (item.key === "Organizations") {
            icon = <Domain />
        }
        else if (item.key === "Plants") {
            icon = <PermDataSetting />
        }
        else {
            icon = <People />
        }
        return icon;
    }

    // set active class for current selected navigation
    getActiveClass = (item, tab) => {
        let name = null;
        if (tab === 'monitoring') {
            return false;
        } else {
            if (tab === "administration") {
                name = `/dashboard/${item.key}Table`;
            }
            else if (tab === "configuration") {
                name = `/dashboard/${item.key.replace(/\s/g, '')}Table`;
            }

            let entityRoute = this.props.location.pathname.split("/")[3];
            let found = null;
            found = this.props.location.pathname === name;
            if (entityRoute) {
                found = entityRoute.includes(item.key.split(" ").join("")) ? true : false;
            }
            return [found, name];
        }
    }

    // create left navigation bar
    getNavigationTabs = () => {
        if (this.props.selectedTab && this.props.navigationTabs) {
            switch (this.props.selectedTab) {
                case 3:
                    let organizationType = null;
                    let rootOrganization = this.props.scopeSelector.subOrganizations.find((organization) => (
                        organization.root
                    ))

                    organizationType = rootOrganization.organizationType;
                    if (organizationType === "customer") {
                        return (
                            <List>
                                {
                                    this.props.navigationTabs.map(item => {
                                        if (item.key !== "Organizations") {
                                            let found = this.getActiveClass(item, "administration");
                                            return (
                                                <span className={found[0] ? this.props.classes.activeLink : null} key={item.key}>
                                                    <Link className={this.props.classes.link} to={found[1]}>
                                                        <ListItem button onClick={() => this.closeDrawer()}>
                                                            {this.getIcon(item)}
                                                            <ListItemText className={this.props.classes.listItem} primary={item.value} />
                                                        </ListItem>
                                                    </Link>
                                                </span>
                                            )
                                        }
                                    })
                                }
                            </List>
                        )
                    }
                    else {
                        return (
                            <List>
                                {
                                    this.props.navigationTabs.map(item => {
                                        let found = this.getActiveClass(item, "administration");
                                        return (
                                            <span className={found[0] ? this.props.classes.activeLink : null} key={item.key}>
                                                <Link className={this.props.classes.link} to={found[1]}>
                                                    <ListItem button onClick={() => this.closeDrawer()}>
                                                        {this.getIcon(item)}
                                                        <ListItemText className={this.props.classes.listItem} primary={item.value} />
                                                    </ListItem>
                                                </Link>
                                            </span>
                                        )
                                    })
                                }
                            </List>
                        )
                    }


                case 2:
                    const { t } = this.props;
                    let myArray = [];
                    var navData = [...this.props.navigationTabs];
                    navData.unshift({ key: "Quick Configuration", value: t("Quick Configuration"), data: null })
                    myArray = navData.map(item => {
                        if (item.data !== null) {
                            var itemData = [...item.data];
                            if (item.key === "System") {
                                itemData.push({ key: "Scheduled Reports", value: t("Scheduled Reports"), data: null })
                            }
                            return (<Fragment key={item.key}>
                                <ListItem classes={{ gutters: this.props.classes.listItemHeader }}>
                                    <ListItemText className={this.props.classes.listItem} primary={item.value} />
                                </ListItem>
                                {
                                    itemData.map(object => {
                                        let found = this.getActiveClass(object, "configuration");
                                        if (object.key === "Jobs" || object.key === "Loss Codes") {
                                            return null;
                                        }
                                        else {
                                            return (
                                                <span className={found[0] ? this.props.classes.activeLink : null} key={object.key}>
                                                    <Link className={this.props.classes.link}
                                                        to={found[1]}
                                                    >
                                                        <ListItem button classes={{ gutters: this.props.classes.listItemButtons }} onClick={() => this.closeDrawer()}>
                                                            <ListItemText primary={"- " + object.value} className={this.props.classes.listItem} />
                                                        </ListItem>
                                                    </Link>
                                                </span>
                                            )
                                        }
                                    })
                                }
                            </Fragment>
                            )
                        } else {
                            let found = this.getActiveClass(item, "configuration");
                            return (
                                <Fragment key={item.key}>
                                    {
                                        <span className={found[0] ? this.props.classes.activeLink : null} key={item.key}>
                                            <Link className={this.props.classes.link}
                                                to={found[1]}
                                            >
                                                <ListItem button classes={{ gutters: this.props.classes.listItemHeader }} onClick={() => this.closeDrawer()}>
                                                    <ListItemText primary={item.value} className={this.props.classes.listItem} />
                                                </ListItem>
                                            </Link>
                                        </span>
                                    }

                                </Fragment>
                            )
                        }
                    })
                    return myArray;
                case 5:
                    let myArray1 = [];
                    if(Array.isArray(this.state.monitorList.areaStatusResponseList)){
                        myArray1 = this.state.monitorList.areaStatusResponseList.map((areaGroup,indexArea)=>{
                                if (Array.isArray(areaGroup.machineGroupStatusResponses)) {
                            return <span style={{ marginTop: '20px' }} className={null} key={areaGroup.name}>
                                  <ListItem button onClick={() => this.handleClick3(indexArea)} classes={{ gutters: this.props.classes.listItemHeader }}>
                                        {this.getStatus3(indexArea) ? <ArrowDropDownIcon className={this.props.classes.icon} /> : <ArrowRightIcon className={this.props.classes.icon} />}
                                        <PinDropRoundedIcon className={this.getIconClass(areaGroup.status)} />  <div className={this.props.classes.listText}>{areaGroup.name}</div>
                                        {/* <ListItemText className={this.props.classes.listItem} primary={row.name} /> */}
                                        <div className={this.props.classes.total}>{areaGroup.totalOnlineMonitors}/{areaGroup.totalMonitors}</div>
                                        {/* {this.getStatus1(index) ? <ExpandLess /> : <ExpandMore />} */}
                                    </ListItem>
                                    <Collapse in={this.getStatus3(indexArea)} timeout="auto" unmountOnExit>
                                        <List component="div" disablePadding>
                                            {
               areaGroup.machineGroupStatusResponses.map((row, index) => {
                if (this.checkEmptyMonitor(row)) {
                    return <span className="customLabel-header" key={row.id}>
                        <ListItem button onClick={() => this.handleClick1(indexArea,index)} classes={{ gutters: this.props.classes.listItemHeader }} style={{ marginLeft: '26px', width: '82%', top: '-36px' }}>
                            {this.getStatus1(indexArea,index) ? <ArrowDropDownIcon className={this.props.classes.icon} /> : <ArrowRightIcon className={this.props.classes.icon} />}
                            <GroupWorkIcon className={this.getIconClass(row.status)} />  <div className={this.props.classes.listText}>{row.name}</div>
                            {/* <ListItemText className={this.props.classes.listItem} primary={row.name} /> */}
                            <div className={this.props.classes.total}>{row.totalOnlineMonitors}/{row.totalMonitors}</div>
                            {/* {this.getStatus1(index) ? <ExpandLess /> : <ExpandMore />} */}
                        </ListItem>
                        <Collapse in={this.getStatus1(indexArea,index)} timeout="auto" unmountOnExit>
                            <List component="div" disablePadding style={{marginTop: "-36px",marginLeft:"26px"}}>
                                {
                                    row.machineStatusReponse.map((ele, mindex) => {
                                        if (ele.monitorStatusResponse.length > 0) {
                                            return <span key={ele.id} className="customLabel-header">
                                                <ListItem id="9784" button onClick={() => this.handleClick2(indexArea,index, mindex)} classes={{ gutters: this.props.classes.listItemHeader }} style={{ marginLeft: '26px', width: '82%', top: '-36px' }}>
                                                    {this.getStatus2(indexArea,index, mindex) ? <ArrowDropDownIcon className={this.props.classes.icon} /> : <ArrowRightIcon className={this.props.classes.icon} />}
                                                    <DashboardIcon className={this.getIconClass(ele.status)} />
                                                    {/* <ListItemText className={this.props.classes.listItem} primary={ele.name} style={{ top: '0px', position: 'relative' }} /> */}
                                                    <div className={this.props.classes.listText}>{ele.name}</div>
                                                    <div className={this.props.classes.total}>{ele.totalOnlineMonitors}/{ele.totalMonitors}</div>
                                                    {/* {this.getStatus2(index, mindex) ? <ExpandLess /> : <ExpandMore />} */}
                                                </ListItem>
                                                <Collapse in={this.getStatus2(indexArea,index, mindex)} timeout="auto" unmountOnExit>
                                                    <List component="div" disablePadding style={{ top: '-28px', position: 'relative' }}>
                                                        {ele.monitorStatusResponse.map(data => {
                                                            return <span id={this.state.activeItem[data.id]?"activeLinkId":null} className={this.state.activeItem[data.id] ? this.props.classes.activeLink : null} key={data.id} onClick={() => this.navItemSelection(data.name, data.id, ele.name, data.deviceIdentifier,areaGroup.name,row.name)}>
                                                        <Link className={"customLabel " + this.props.classes.link}
                                                            to={`/dashboard/MonitoringTable`}
                                                        >
                                                            <ListItem button onClick={() => this.closeDrawer()}>
                                                                <span className={this.getStatusIcon(data.status)}></span>
                                                                <div className={this.props.classes.listText}>{data.name}</div>
                                                                {/* <ListItemText primary={data.name} className={this.props.classes.listItem} /> */}
                                                            </ListItem>
                                                        </Link>
                                                    </span>
                                                        })}
                                                </List>
                                            </Collapse>
                                            </span>
                                        }
                                    })
                                }
                        </List>
                    </Collapse>
                    </span >
                }
            })
                                            }
                                        </List>
                                </Collapse>
                                </span>
                    }
                    })
                }
                    // if(this.state.tableDataArray.length > 0){
                    //     myArray1 = this.state.tableDataArray.map((row, index)=>{
                    //         return <span  style={{marginTop:'20px'}} className={null} key={row.machineGroups.name}>
                    //             <ListItem button onClick={() => this.handleClick1(index)} classes={{ gutters: this.props.classes.listItemHeader }}>
                    //                     {this.getStatus1(index) ? <ArrowDropDownIcon className={this.props.classes.icon} /> : <ArrowRightIcon className={this.props.classes.icon} />}
                    //                     <GroupWorkIcon className={this.props.classes.icon} /><ListItemText className={this.props.classes.listItem} primary={row.machineGroups.name} />
                    //                     {this.getStatus1(index) ? <ExpandLess /> : <ExpandMore />}
                    //             </ListItem>
                    //             <Collapse in={this.getStatus1(index)} timeout="auto" unmountOnExit>
                    //             <List component="div" disablePadding>
                    //             {
                    //                 row.machines.map((ele, mindex)=>{
                    //                     return <span key={ele.machines.label} className="customLabel-header">
                    //                         <ListItem id="9784" button onClick={() => this.handleClick2(index,mindex)} classes={{ gutters: this.props.classes.listItemHeader }} style={{marginLeft: '26px',width: '82%',top: '-36px'}}>
                    //                         {this.getStatus2(index,mindex) ? <ArrowDropDownIcon className={this.props.classes.icon} /> : <ArrowRightIcon className={this.props.classes.icon} />}                                                    
                    //                         <DashboardIcon className={this.props.classes.icon} /><ListItemText className={this.props.classes.listItem} primary={ele.machines.label} style={{top: '0px', position: 'relative'}}/>
                    //                             {/* <div className="alertbox1">{ele.monitor.length}</div>{this.getTotalCount(ele.monitor) > 0 ? <div className="alertbox" ><span>{this.getTotalCount(ele.monitor)}</span><span className="icon">⚠</span></div>:null} */}
                    //                             {this.getStatus2(index,mindex) ? <ExpandLess /> : <ExpandMore />}
                    //                         </ListItem>
                    //                         <Collapse in={this.getStatus2(index,mindex)} timeout="auto" unmountOnExit>
                    //                         <List component="div" disablePadding style={{top: '-28px',position: 'relative'}}>
                    //                         {ele.monitor.map(data =>{
                    //                             return <span className={this.state.activeItem[data.id] ? this.props.classes.activeLink : null} key={data.id} onClick={() => this.navItemSelection(data,data.id)} /*style={{top: '-40px', position:'absolute'}}*/>
                    //                                 <Link className={"customLabel "+this.props.classes.link}
                    //                                     to={`/dashboard/MonitoringTable`}
                    //                                 >
                    //                                     <ListItem button onClick={()=> this.closeDrawer()} /*classes={{ gutters: this.props.classes.listItemButtons }}*/>
                    //                                     {/* <span className={'dot-'+data.state.toLocaleLowerCase()}></span> */}
                    //                                     {/* <FiberManualRecordIcon className={this.props.classes.icon1} /> */}
                    //                                     <ListItemText primary={data.label} className={this.props.classes.listItem} />
                    //                                     {/* Commented Alarm count for this release */}
                    //                                     {/* {data.alarmCount > 0 ? <div className="alertbox" onClick={()=> this.toggleDrawer('bottom', true, data.label)}><span>{data.alarmCount}</span><span className="icon">⚠</span></div>:null} */}
                    //                                     </ListItem>
                    //                                 </Link>
                    //                             </span>
                    //                      } )}
                    //                         </List>
                    //                         </Collapse>
                    //                     </span>
                    //                 })
                    //             }
                    //             </List>
                    //             </Collapse>
                    //         </span>
                    //     })
                    // }
                    return myArray1;
                case 4:
                    let prodArray1 = [];
                    if (this.state.tableDataArray.length > 0 && this.state.plantList > 0) {
                        prodArray1 = this.state.tableDataArray.map((row, index) => {
                            return <span className={null} key={row.machineGroups.name}>
                                <ListItem button onClick={() => this.handleClick1(index)} classes={{ gutters: this.props.classes.listItemHeader }}>
                                    <ListItemText className={this.props.classes.listItem} primary={row.machineGroups.name} />
                                    {this.getStatus1(index) ? <ExpandLess /> : <ExpandMore />}
                                </ListItem>
                                <Collapse in={this.getStatus1(index)} timeout="auto" unmountOnExit>
                                    <List component="div" disablePadding>
                                        {
                                            row.machines.map((ele, mindex) => {
                                                return <span key={ele.machines.label}>
                                                    <ListItem button onClick={() => this.handleClick2(index, mindex)} classes={{ gutters: this.props.classes.listItemHeader }}>
                                                        <ListItemText className={this.props.classes.listItem} primary={"--" + ele.machines.label} />
                                                        {this.getStatus2(index, mindex) ? <ExpandLess /> : <ExpandMore />}
                                                    </ListItem>
                                                    <Collapse in={this.getStatus2(index, mindex)} timeout="auto" unmountOnExit>
                                                        <List component="div" disablePadding>
                                                            {ele.monitor.map(data => {
                                                                return <span key={data.id} onClick={() => this.navItemSelection(data, data.id)}>
                                                                    <Link className={this.props.classes.link} onClick={() => this.closeDrawer()}
                                                                        to={`#`}
                                                                    >
                                                                        <ListItem button classes={{ gutters: this.props.classes.listItemButtons }}>
                                                                            <ListItemText primary={"- " + data.label} className={this.props.classes.listItem} />
                                                                        </ListItem>
                                                                    </Link>
                                                                </span>
                                                            })}
                                                        </List>
                                                    </Collapse>
                                                </span>
                                            })
                                        }
                                    </List>
                                </Collapse>
                            </span>
                        })
                    }
                    return prodArray1;
                default: return null;
            }
        }
    }

    checkEmptyMonitor = (row) => {
        let flag = false;
        row.machineStatusReponse.forEach(machine => {
            if (flag) { return; }
            if (machine.monitorStatusResponse.length > 0) {
                flag = true;
            } else {
                flag = false;
            }
        })
        return flag;
    }

    getStatusIcon = (status) => {
        let icon = '';
        //1 idle, 2 operational, 3 caution, 4 warning, 5 disconnected
        switch (status) {
            case 1: icon = 'dot-idle'
                break;
            case 2: icon = 'dot-operational'
                break;
            case 3: icon = 'dot-caution'
                break;
            case 4: icon = 'dot-warning'
                break;
            case 5: icon = 'dot-disconnected'
                break;
            case 6: icon = 'dot-faulty'
                break;
            default:
                console.log('Wrong status');
        }
        return icon;
    }

    getIconClass = (status) => {
        let icon = '';
        //1 idle, 2 operational, 3 caution, 4 warning, 5 disconnected
        switch (status) {
            case 1: icon = 'iconIdle'
                break;
            case 2: icon = 'iconOperational'
                break;
            case 3: icon = 'iconCaution'
                break;
            case 4: icon = 'iconWarning'
                break;
            case 5: icon = 'iconDisconnected'
                break;
            case 6: icon = 'iconFaulty'
                break;
            default:
                console.log('Wrong status');
        }
        return icon;
    }

    getStatus1 = (mainIndex,index) => {
        // return this.state.tableDataArray[index].status;
        //return this.state.monitorList.machineGroupStatusResponses[index].expand;
        return this.state.monitorList.areaStatusResponseList[mainIndex].machineGroupStatusResponses[index].expand;
    }
    getTotalCount(monitors) {
        // return monitors.map(item => item.alarmCount).reduce((prev, next) => prev + next);
        return 0;
    }
    handleClick1 = (indexArea,index) => {
        let outerList = this.state.monitorList;
        let list = outerList.areaStatusResponseList[indexArea];
        list.machineGroupStatusResponses[index].expand = !list.machineGroupStatusResponses[index].expand;
        // this.tableDataArray[index].status = !this.tableDataArray[index].status;
        this.setState({
            // tableDataArray: [...this.tableDataArray],
            monitorList: outerList
        })
    }

    handleClick3 = (indexArea) => {
        let outerList = this.state.monitorList;
        outerList.areaStatusResponseList[indexArea].expand = !outerList.areaStatusResponseList[indexArea].expand;
        // this.tableDataArray[index].status = !this.tableDataArray[index].status;
        this.setState({
            // tableDataArray: [...this.tableDataArray],
            monitorList: outerList
        })
    }
    getStatus2 = (indexArea,index, mindex) => {

        return this.state.monitorList.areaStatusResponseList[indexArea].machineGroupStatusResponses[index].machineStatusReponse[mindex].expand
    }

    getStatus3 = (index) => {
        // return this.state.tableDataArray[index].status;
        return this.state.monitorList.areaStatusResponseList[index].expand;
    }

    handleClick2 = (indexArea,index, mindex) => {
        // this.tableDataArray[index].machines[mindex].status = !this.tableDataArray[index].machines[mindex].status;
        let outerList = this.state.monitorList;
        let list = outerList.areaStatusResponseList[indexArea];
        list.machineGroupStatusResponses[index].machineStatusReponse[mindex].expand = !list.machineGroupStatusResponses[index].machineStatusReponse[mindex].expand;
        this.setState({
            monitorList: outerList,
            tableDataArray: [...this.tableDataArray]
        })
    }
    plantListCheck = (value) => {
        this.setState({ plantList: value.length })
    }
    getPlantNameNav = (value) => {
        this.props.getPlantName(value.name)
    }

    navItemSelection = (deviceName, deviceId, machineName, deviceIdentifier,areaName,machineGroupName) => {
        count=0;
        localStorage.setItem("deviceName", deviceName);
        localStorage.setItem("deviceId", deviceId)
        localStorage.setItem("machineName", machineName)
        localStorage.setItem("deviceIdentifier", deviceIdentifier)
        localStorage.setItem("areaName", areaName)
        localStorage.setItem("machineGroupName", machineGroupName)


        this.props.setDeviceData({ "deviceName": deviceName, "id": deviceId, "machineName": machineName, "deviceIdentifier": deviceIdentifier })
        this.setState({ activeItem: { [deviceId]: true } })
        //this.props.updateDrawer(false);

    }
    createData = (id, name, time, level, details, status, machineName) => {
        return { id, name, time, level, details, status, machineName };
    }

    toggleDrawer = (side, open, label) => {
        let arr = this.props.alarmsData.alarmsData;
        if (arr.length > 0) {
            arr.forEach(element => {
                element.time = new Date(element.time);
            })

            arr.sort(function (a, b) {
                return b.time.getTime() - a.time.getTime()
            });

            this.deviceAlarmData = [];
            let description = '';
            let time = '';
            arr.forEach(element => {
                description = element.description.replace(/{|"|}/g, '');
                time = moment(element.time).utc().local().format("YYYY-MM-DD HH:mm:ss");
                this.deviceAlarmData.push(this.createData(element.id, label, time, element.level, description, element.status, this.props.deviceData.deviceData.deviceData.machineName));
            })
        }
        this.setState({ [side]: open });
    }

    // close right side Drawer
    closeDrawer = () => {
        count=0;
        this.props.updateDrawer(false);
    }

    handleChange(e) {
        // let navData= [];
        // this.props.tableData.tableData.forEach(ele=>{
        //     if(navData.length >0){
        //        let index = navData.findIndex(object=> object.machineGroups.id === ele.machines.machineGroups.id);
        //         if(index !==  -1){
        //             let machineIndex = navData[index].machines.findIndex(element=> element.machines.id === ele.machines.id);
        //             if(machineIndex !== -1 ){
        //                 let label = ele.label.toLowerCase();
        //                 let str = e.target.value.toLowerCase()
        //                 if(label.includes(str)){
        //                     navData[index].machines[machineIndex].monitor.push(ele)
        //                  }
        //             }else{
        //                 let label = ele.label.toLowerCase();
        //                 let str = e.target.value.toLowerCase()
        //                 if(label.includes(str)){
        //                     navData[index].machines.push({machines:ele.machines, monitor: [ele], status: true})
        //                  }
        //             }
        //         }else{
        //                 let label = ele.label.toLowerCase();
        //                 let str = e.target.value.toLowerCase()
        //                 if(label.includes(str)){
        //                     navData.push({ machineGroups : ele.machines.machineGroups, machines: [{machines:ele.machines, monitor: [ele], status: true}], status: true });
        //                  }
        //         }
        //     }else{
        //         let label = ele.label.toLowerCase();
        //         let str = e.target.value.toLowerCase()
        //         if(label.includes(str)){
        //             navData.push({ machineGroups : ele.machines.machineGroups, machines: [{machines:ele.machines, monitor: [ele], status: true}] , status: true});
        //          }

        //     }
        // })
        // this.tableDataArray = [...navData];
        // this.setState({
        //     tableDataArray : this.tableDataArray,
        //     inputValue: e.target.value
        // },()=>console.log(this.state.tableDataArray))

        let OuternewList = JSON.parse(JSON.stringify(this.state.copyMonitorList));
        if(OuternewList.hasOwnProperty("areaStatusResponseList") && OuternewList.areaStatusResponseList.length>0){
            OuternewList.areaStatusResponseList.map((newList)=>{
                newList.expand = true;
        if (newList.hasOwnProperty("machineGroupStatusResponses") && newList.machineGroupStatusResponses.length > 0) {
            this.state.copyMonitorList.areaStatusResponseList.forEach((area,areaIndex)=>{
                area.expand = true;
                area.machineGroupStatusResponses.forEach((ele, mgIndex) => {
                ele.expand = true;
                ele.machineStatusReponse.forEach((item, mcIndex) => {
                    item.expand = true;
                    item.monitorStatusResponse.forEach((monitor, mindex) => {
                        let label = monitor.name.toLowerCase();
                        let str = e.target.value.toLowerCase()
                        if (label.includes(str)) {
                            let newIndex = OuternewList.areaStatusResponseList[areaIndex].machineGroupStatusResponses[mgIndex].machineStatusReponse[mcIndex].monitorStatusResponse.findIndex(mon => mon.id === monitor.id);
                            if (newIndex === -1) {
                                OuternewList.areaStatusResponseList[areaIndex].machineGroupStatusResponses[mgIndex].machineStatusReponse[mcIndex].monitorStatusResponse.push(monitor);
                            }
                        } else {
                            let newIndex = OuternewList.areaStatusResponseList[areaIndex].machineGroupStatusResponses[mgIndex].machineStatusReponse[mcIndex].monitorStatusResponse.findIndex(mon => mon.id === monitor.id);
                            if (newIndex !== -1) {
                                OuternewList.areaStatusResponseList[areaIndex].machineGroupStatusResponses[mgIndex].machineStatusReponse[mcIndex].monitorStatusResponse.splice(newIndex, 1);
                            }
                        }
                    })
                })
            })
        })
        }
    })}
        this.setState({
            monitorList: OuternewList,
            inputValue: e.target.value
        });
    }

    render() {
        const { t } = this.props;
        let prodTableLength = this.state.tableDataArray.length;
        return (
            <>
                <Drawer anchor="left" style={{ width: '30%' }}
                    open={this.props.drawerData.value} classes={{ paper: this.props.classes.paper }} >
                    {/* <Drawer variant="permanent" classes={{ paper: this.props.classes.paper }} className={this.props.selectedTab===5 ? 'mondash' : ''}> */}
                    <Grid className={"custom-nav " + this.props.classes.headerSection} container direction={"row"}>
                        <div className={this.props.classes.browseBar}>
                            {/* <img alt="IU logo" src={`${server_url}/getLogo/${this.props.userOrganization.id}/${new Date()}?access_token=${getOidcAccessToken()}`} className={this.props.classes.logoClass} /> */}
                            <div id="browse" style={{ color: 'white', marginLeft: '15px' }}>{t("Browse")}</div>

                            <IconButton className={this.props.classes.icon} onClick={this.closeDrawer} id="close_utton">
                                <Close style={{ fontSize: 22, color: 'black' }} />
                            </IconButton>
                        </div>

                    </Grid>
                    <div style={{ height: (this.props.selectedTab === 5 ? '20vh' : '10vh'), marginTop: '10px',marginBottom:"5px" }}>
                        <ScopeSelector selectedTab={this.props.selectedTab}
                            scopeSelector={this.props.scopeSelector}
                            history={this.props.history}
                            isAdmin={this.props.isAdmin}
                            linkClass={this.props.classes.link} />
                        {/* <Divider style={{ backgroundColor: '#404854', marginTop: 5 }} /> */}
                        {this.props.selectedTab === 4 && this.state.plantList > 0 ?
                            <p id="total_machine" className={"NavTotalMachine " + this.props.classes.link}>{t("Total Machines")} : {prodTableLength}</p> : null
                        }
                    </div>
                    {
                        this.props.dashboardType.type === "summary" && this.props.selectedTab === 5 || this.props.dashboardType.type === "summaryPlant" && this.props.selectedTab === 5 ? null :

                            <Scrollbars
                                autoHide
                                autoHideTimeout={1000}
                                autoHideDuration={200}
                                className="area"
                            >

                                <div >
                                    {this.props.selectedTab === 5 ?
                                        // <input type="text" className="monitorSearch" value={this.state.inputValue} onChange={this.handleChange} placeholder="Filter" /> 
                                        <TextField
                                        className="fullWidthFilter"
                                            // label="Filter"
                                            style={{ position:"sticky" }}
                                            placeholder={t("Filter")}
                                            id="filter"
                                            value={this.state.inputValue}
                                            onChange={this.handleChange}
                                            // helperText="Full width!"
                                            fullWidth
                                            //margin="normal"
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                        />
                                        : null

                                    }
                                    {this.getNavigationTabs()}
                                </div>

                            </Scrollbars>
                    }
                </Drawer>
                <Drawer className="drawer-panel" anchor="bottom" open={this.state.bottom} onClose={(e) => this.toggleDrawer('bottom', false)}>
                    <div className="drawer-header text-amber-bg">
                        <h2 className="display-text">{t("Alarms")}</h2>
                        <span className="float-right" onClick={(e) => this.toggleDrawer('bottom', false)}>x</span>
                    </div>
                    <PopoverTable className="drawer-content" alarmsData={this.deviceAlarmData} deviceId={'abc'} t={this.props.t} />
                </Drawer>
            </>
        )
    }
}

// export default (withStyles(styles)(Navigation));
const mapSatateToProps = state => {
    return {
        tableData: state.tableDataReducer,
        deviceData: state.dashboardDeviceReducer,
        userData: state.userDataReducer,
        alarmsData: state.alarmReportDataReducer,
        drawerData: state.drawerReducer,
        dashboardType: state.dashboardTypeReducer,
        widgetLanguage: state.setWidgetLanguageReducer
    }
}

const mapDispatchToProps = dispatch => {
    return {
        getTableData: (selectedNavtab, currentScopeSelectionId, setToStore) => (dispatch(actionCreators.getTableData(selectedNavtab, currentScopeSelectionId, setToStore, false, false, 2))),
        setWidgetLanguage: (language) => (dispatch(actionCreators.setWidgetLanguage(language))),
        getSummaryPlantData: (t) => (dispatch(actionCreators.getSummaryPlantData(t))),

        // getDeviceData: () => (dispatch((actionCreators.getDeviceData()))),
        setDeviceData: (deviceData) => (dispatch((actionCreators.setDeviceData(deviceData)))),
        updateDrawer: () => (dispatch(actionCreators.updateDrawer(false))),
        getMonitorList: (plantId, cb) => (dispatch(actionCreators.getMonitorList(plantId, cb)))
    }
}

// export default (withStyles(styles)(Navigation));
export default withTranslation()(connect(mapSatateToProps, mapDispatchToProps)(withStyles(styles)(Navigation)));
