import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withTranslation } from "react-i18next";


export class BatteryHealth extends Component {
    render() {
        return (
            <div className="widgets-shadow">
                <div className="widgets-heading widgets-heading-info">
                    <h2 className="display-text" style={{ paddingRight: "0px" }}>{this.props.t("Battery Health")} </h2>
                </div>
                {this.props && this.props.deviceDetails && this.props.deviceDetails.deviceData && this.props.deviceDetails.deviceData.deviceData && this.props.deviceDetails.deviceData.deviceData.batteryLevel ?
                    <div class="single-chart">
                        <div>
                        {/* <svg viewBox="0 0 40 40" class={this.props.deviceDetails.deviceData.deviceData.batteryLevel>=30 ?"circular-chart green":"circular-chart red"}> */}
                        <svg viewBox="0 0 40 40" class="circular-chart green">
                            <path class="circle-bg"
                                d="M18 2.0845
          a 15.9155 15.9155 0 0 1 0 31.831
          a 15.9155 15.9155 0 0 1 0 -31.831"
                            />
                            <path class="circle"
                                stroke-dasharray={`${this.props.deviceDetails.deviceData.deviceData.batteryLevel}, 100`}
                                d="M18 2.0845
          a 15.9155 15.9155 0 0 1 0 31.831
          a 15.9155 15.9155 0 0 1 0 -31.831"
                            />
                            <text x="18" y="20.35" class="percentage">{this.props.deviceDetails.deviceData.deviceData.batteryLevel}%</text>
                        </svg>
                        </div>
                        {/* <div style={{textAlign:"center",fontWeight:"bold"}}>{this.props.deviceDetails.deviceData.deviceData.batteryLevel>=70 ?this.props.t("Good"):this.props.deviceDetails.deviceData.deviceData.batteryLevel >=40 && this.props.deviceDetails.deviceData.deviceData.batteryLevel <= 70 ? this.props.t("Medium"):this.props.t("Bad")}</div> */}
                        {/* <div style={{textAlign:"center",fontWeight:"bold"}}>{this.props.deviceDetails.deviceData.deviceData.batteryLevel>=30 ?this.props.t("Good"):this.props.t("Bad")}</div> */}
                        {/* <div style={{textAlign:"center",fontWeight:"bold"}}>{this.props.t("Good")}</div> */}

                    </div> :
                    <div class="single-chart" style={{textAlign:"center"}}>NA</div>}
            </div>
        )
    }
}
const mapStateToProps = state => {
    return {
        alarmsData: state.alarmReportDataReducer
    }
}
export default withTranslation()(connect(mapStateToProps, null)(BatteryHealth));

