import React from "react";
import moment from "moment";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { hasAuthParams, withAuth } from "react-oidc-context";
import { withStyles, CircularProgress } from "@material-ui/core";
import { getOidcUser } from "../utils/oidcStorageUtils";
import {
  logout,
  isUserAuthenticated,
  updatedashboardType,
  updateDrawer,
  saveUserData,
} from "../actions/actionCreators";

import useGAEventsTracker from "../hooks/useGAEventsTracker";

const GAEventsTracker = useGAEventsTracker("usersession");

const styles = () => ({
  loaderContainer: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: "100vh",
    background: "#e6e8eb",
  },
  blueLoader: {
    color: "#0189d9",
  },
});

const removeOidcObjectFromLocalStorage = () => {
  const oidcStorageKey = `oidc.user:${process.env.REACT_APP_OIDC_AUTHORITY_URL}:${process.env.REACT_APP_OIDC_CLIENT_ID}`;

  if (localStorage.getItem(oidcStorageKey)) {
    localStorage.removeItem(oidcStorageKey);
  }
};

const removeLangPreferrenceFromLocalStorage = () => {
  if(localStorage.getItem("lang") !== "en") localStorage.removeItem("lang");
}

class AuthRedirector extends React.Component {
  componentDidMount() {
    const { auth } = this.props;

    auth.events.addAccessTokenExpired(this.handleAccessTokenExpired);
    auth.events.addUserSignedOut(this.handleUserSignedOut);

    if (!hasAuthParams()) {
      const { pathname, search, hash } = window.location;
      auth.signinRedirect({
        state: { pathname, search, hash },
        scope: "openid email roles",
      });
    }
    
    // TODO!: Remove this func call after some releases
    removeOidcObjectFromLocalStorage();
    // removeLangPreferrenceFromLocalStorage();
  }

  componentDidUpdate(prevProps) {
    const { auth: currAuth } = this.props;
    const { auth: prevAuth } = prevProps;

    if (this.hasAuthStatusChanged(prevAuth, currAuth)) {
      if (
        currAuth.isAuthenticated &&
        !currAuth.isLoading &&
        !currAuth.activeNavigator
      ) {
        this.postLoginUserActions();
      }
    }
  }

  hasAuthStatusChanged(prevAuth, currAuth) {
    return (
      currAuth.isAuthenticated !== prevAuth.isAuthenticated ||
      currAuth.activeNavigator !== prevAuth.activeNavigator ||
      currAuth.isLoading !== prevAuth.isLoading
    );
  }

  postLoginUserActions() {
    const user = getOidcUser();

    if (user) {
      const email = user.profile.email.replace("@", "$");
      const label = {
        username: email,
        name: email,
      };
      GAEventsTracker("login", label);
      localStorage.setItem("userNameWithdollar", email);
    }
    localStorage.setItem("loginTime", moment());
  }

  handleAccessTokenExpired = () => {
    this.props.logout();
  }

  handleUserSignedOut = () => {
    const { auth } = this.props;
    const user = getOidcUser();
    if(user) {
      auth.removeUser();
    }
    window.location.reload();
  }

  render() {
    const { auth, children, classes } = this.props;

    if (auth.isLoading) {
      return (
        <div className={classes.loaderContainer}>
          <CircularProgress className={classes.blueLoader} />
        </div>
      );
    }

    if (auth.error) {
        return (
        <div style={{ display: 'flex', flexDirection: "column", justifyContent: "center", alignItems: "center", height: "100vh" }}>
            <div>Something went wrong - {auth.error.message}</div>
            <div>Please click <a href="/">here to go back to home page</a></div>
        </div>
      );
    }

    if (
      !hasAuthParams() &&
      !auth.activeNavigator &&
      !auth.isLoading &&
      auth.isAuthenticated
    ) {
      return <>{children}</>;
    }

    // TODO: Can add loading here as well - Instead of a white screen loading maybe better
    return null;
  }
}

const mapStateToProps = (state) => {
  return {
    userData: state.userDataReducer,
  };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      isUserAuthenticated,
      updatedashboardType,
      updateDrawer,
      saveUserData,
      logout,
    },
    dispatch
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withAuth(withStyles(styles)(AuthRedirector)));
