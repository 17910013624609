export const computeRange = (data, selectDate, selectedTime) => {
    let currentDateTime = new Date();
    let startDate;
    let range = [];
    switch (data) {
        case 'today':
            startDate = new Date();
            startDate.setHours(0)
            startDate.setMinutes(0);
            startDate.setSeconds(0);
            startDate.setMilliseconds(0);
            range.push(data);
            range.push(startDate.toISOString());
            range.push(currentDateTime.toISOString());
            break;
        case 'thisMonth':
            startDate = new Date();
            startDate.setDate(1);
            startDate.setHours(0)
            startDate.setMinutes(0);
            startDate.setSeconds(0);
            startDate.setMilliseconds(0);
            range.push(data);
            range.push(startDate.toISOString());
            range.push(currentDateTime.toISOString());
            break;
        case '30Minutes':
            startDate = new Date(currentDateTime.getTime() - 30 * 1000 * 60);
            startDate.setHours(startDate.getHours());
            startDate.setMinutes(startDate.getMinutes());
            startDate.setSeconds(0);
            startDate.setMilliseconds(0);
            currentDateTime.setHours(currentDateTime.getHours());
            currentDateTime.setMinutes(currentDateTime.getMinutes());
            currentDateTime.setSeconds(0);
            currentDateTime.setMilliseconds(0);
            range.push(data);
            range.push(startDate.toISOString());
            range.push(currentDateTime.toISOString());
            break;
        case 'hour':
            startDate = new Date(currentDateTime.getTime() - 60 * 1000 * 60);
            startDate.setHours(startDate.getHours());
            startDate.setMinutes(startDate.getMinutes());
            startDate.setSeconds(0);
            startDate.setMilliseconds(0);
            currentDateTime.setHours(currentDateTime.getHours());
            currentDateTime.setMinutes(currentDateTime.getMinutes());
            currentDateTime.setSeconds(0);
            currentDateTime.setMilliseconds(0);
            range.push(data);
            range.push(startDate.toISOString());
            range.push(currentDateTime.toISOString());
            break;
        case '24hour':
            startDate = new Date(currentDateTime.getTime() - 24 * 60 * 1000 * 60);
            startDate.setHours(startDate.getHours());
            startDate.setMinutes(startDate.getMinutes());
            startDate.setSeconds(0);
            startDate.setMilliseconds(0);
            currentDateTime.setHours(currentDateTime.getHours());
            currentDateTime.setMinutes(currentDateTime.getMinutes());
            currentDateTime.setSeconds(0);
            currentDateTime.setMilliseconds(0);
            range.push(data);
            range.push(startDate.toISOString());
            range.push(currentDateTime.toISOString());
            break;
        case 'week':
            startDate = new Date(currentDateTime.getTime() - 7 * 24 * 60 * 1000 * 60);
            startDate.setHours(startDate.getHours());
            startDate.setMinutes(startDate.getMinutes());
            startDate.setSeconds(0);
            startDate.setMilliseconds(0);
            currentDateTime.setHours(currentDateTime.getHours());
            currentDateTime.setMinutes(currentDateTime.getMinutes());
            currentDateTime.setSeconds(0);
            currentDateTime.setMilliseconds(0);
            range.push(data);
            range.push(startDate.toISOString());
            range.push(currentDateTime.toISOString());
            break;
        case 'month':
            currentDateTime.setSeconds(0);
            currentDateTime.setMilliseconds(0);

            startDate = new Date(currentDateTime);
            startDate.setDate(startDate.getDate() - 30);
            
            range.push(data);
            range.push(startDate.toISOString());
            range.push(currentDateTime.toISOString());
            break;
        case 'year':
            startDate = new Date();
            let year = currentDateTime.getUTCFullYear();
            startDate.setUTCFullYear(year - 1);
            startDate.setHours(startDate.getHours());
            startDate.setMinutes(startDate.getMinutes());
            startDate.setSeconds(0);
            startDate.setMilliseconds(0);
            currentDateTime.setSeconds(0);
            currentDateTime.setMilliseconds(0);
            range.push(data);
            range.push(startDate.toISOString());
            range.push(currentDateTime.toISOString());
            break;
        case 'all':
            let start = new Date(2015, 7, 1, 0, 0, 0, 0);
            range.push(data);
            range.push(start.toISOString());
            range.push(currentDateTime.toISOString());
            break;
        case 'userSelection':

            const diffTime = Math.abs((selectedTime).getTime() - (selectDate).getTime());
            const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
            let diffMinutes = Math.ceil(diffTime / (1000 * 60));
            if (diffMinutes <= 30) {
                range.push('30Minutes');
            } else if (diffMinutes > 30 && diffMinutes <= 60) {
                range.push('hour');
            }
            else if (diffDays <= 1) {
                range.push('24hour');
            } else if (diffDays > 1 && diffDays <= 7) {
                range.push('week');
            } else if (diffDays >= 8 && diffDays <= 31) {
                range.push('month');
            } else {
                range.push('year');
            }
            range.push((selectDate).toISOString());

            range.push((selectedTime).toISOString());

            range.push("search");

            break;
    }
    return range;
}

export const getSynDisplayValue = (synValue) => {
    const synValMap = {
        0: "-",
        1: "Low Priority",
        2: "Medium Priority",
        3: "High Priority"
    }
    return synValMap[synValue];
}