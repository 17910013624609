import React, { Component } from 'react'
import { withRouter } from "react-router-dom";
import TrendHistory from './Panels/TrendHistory';
import InstataneousParameters from './Panels/InstataneousParameters';
import EquipmentHistory from './Panels/EquipmentHistory';
import RealTimePanel from './Panels/RealTimePanel';
import WaterfallDiagram from "./Panels/WaterfallDiagram";
import ReportPanel from './Panels/ReportPanel';
import MaintenancePanel from './Panels/MaintenancePanel';
import * as actionCreators from '../../actions/actionCreators';
import InternetSpeedPanel from '.././Production/WidgetPanels/InternetSpeedPanel';
import {
  withStyles
} from '@material-ui/core';
import { connect } from 'react-redux';
import { withTranslation } from "react-i18next";


const styles = theme => ({
    backdrop: {
      zIndex: theme.zIndex.drawer + 1,
      color: '#fff',
    },
    root: {
      display: 'flex',
      '& > * + *': {
        marginLeft: 2,
      },
    },
    loading: {
      position: "fixed",
      "z-index": 1400,
      height: "180vh",
      width: "120%",
      "background-color": "rgba(0,0,0,0.5)",
      display: 'flex',
      marginLeft: "-50px",
      marginTop: "-100px",
      marginBottom: "-50px",
      'justify-content': 'center',
      'align-items': 'center'
    },
    summaryLoading: {
      position: "fixed",
      "z-index": 1400,
      height: "120vh",
      width: "110%",
      "background-color": "rgba(0,0,0,0.5)",
      display: 'flex',
      marginLeft: "-50px",
      marginTop: "-100px",
      marginBottom: "-50px",
      'justify-content': 'center',
      'align-items': 'center'
    },
    closeButton: {
      position: 'absolute',
      right: "10px",
      top: "10px",
      color: theme.palette.grey[500],
    },
    loaderColor: {
      color: '#2abbe8'
    },
    select: {
      "&:before": {
        borderColor: "#3f51b5",
      },
      '&:after': {
        borderColor: "#3f51b5",
      },
      color: "#000000"
    },
    inputFieldDiv: {
      display: "flex",
      width: '40%',
      float: 'left',
      paddingTop: '1px',
      height: 36,
      'align-items': 'baseline'
    },
    textFieldLabel: {
      color: 'rgba(0, 0, 0)',
      'letter-spacing': 0.5,
      'font-weight': 500,
      fontSize: "14px",
      lineHeight: 2.5,
      marginLeft: 20,
      //text-align': 'right'
    },
    textField: {
      width: '50%',
      marginLeft: 10
    },
    outlinedInput: {
      padding: 8
    },
  });
  
  let thresholds = {
    "signalEnergy": {
      "Ideal": 10,
      "Operational": 60,
      "Caution": 100,
      "Warning": 80
    }
    ,
    "velocityX":
    {
      "Ideal": 5,
      "Operational": 20,
      "Caution": 22,
      "Warning": 40
    }
    ,
    "velocityY":
    {
      "Ideal": 5,
      "Operational": 20,
      "Caution": 22,
      "Warning": 40
    }
    ,
    "velocityZ":
    {
      "Ideal": 5,
      "Operational": 20,
      "Caution": 22,
      "Warning": 40
    }
    ,
    "temperature":
    {
      "Ideal": 30,
      "Operational": 60,
      "Caution": 80,
      "Warning": 100
    },
    "noise":
    {
      "Ideal": 30,
      "Operational": 60,
      "Caution": 80,
      "Warning": 100
    },
    "fId1":
    {
      "Ideal": 3,
      "Operational": 6,
      "Caution": 8,
      "Warning": 10
    },
    "fId2":
    {
      "Ideal": 3,
      "Operational": 6,
      "Caution": 8,
      "Warning": 10
    },
    "fId3":
    {
      "Ideal": 0.3,
      "Operational": 0.6,
      "Caution": 0.8,
      "Warning": 1
    }
  
  };
  class DefaultView extends Component {
    constructor(props) {
      super(props);
    }
    render() {
        let propsDetails=this.props.props;
        let stateDetails=this.props.state;

        return(
            <div className="widgets-Panel">
            <div className="trend-insta-wrp">
              <TrendHistory props={propsDetails} deviceName={propsDetails.dashboardDeviceData.deviceData.deviceData.deviceName} featureList={stateDetails.trendArr} thresholds={thresholds} widgetsData={propsDetails.widgetsData} deviceId={propsDetails.dashboardDeviceData.deviceData.deviceData.id} range={stateDetails.range} addFeature={this.props.addFeature} removeFeature={this.props.removeFeature} />
              <InstataneousParameters props={propsDetails} deviceName={propsDetails.dashboardDeviceData.deviceData.deviceData.deviceName} featureList={stateDetails.instateneousArray} thresholds={thresholds} widgetsData={propsDetails.widgetsData} monitorData={propsDetails.monitorData} deviceId={propsDetails.dashboardDeviceData.deviceData.deviceData.id} realTimeData={stateDetails.realTimeData} fingerprintRealTimeData={stateDetails.fingerprintRealTimeData} range={stateDetails.range}  batteryFlag={stateDetails.batteryFlag} />
              <InternetSpeedPanel props={propsDetails} />
            </div>
            <div className="widgets-info realTime-wrp">
              <EquipmentHistory reference={this.props.el1} onClick={() => this.props.scrollView(this.props.el2)} props={propsDetails} deviceName={propsDetails.dashboardDeviceData.deviceData.deviceData.deviceName} monitorId={propsDetails.dashboardDeviceData.deviceData.deviceData.id} widgetsData={propsDetails.widgetsData} monitorData={propsDetails.monitorData} featureList={stateDetails.featureArray} deviceId={propsDetails.dashboardDeviceData.deviceData.deviceData.id} range={stateDetails.range} onEquipmentDone={this.props.onEquipmentDone} batteryFlag={stateDetails.batteryFlag} dataLoggerFlag={stateDetails.dataLoggerFlag} isEquipmentDone={stateDetails.isEquipmentDone}/>
              <RealTimePanel reference={this.props.el2} props={propsDetails} deviceName={propsDetails.dashboardDeviceData.deviceData.deviceData.deviceName} macAddress={propsDetails.dashboardDeviceData.deviceData.deviceData.deviceIdentifier} widgetsData={propsDetails.widgetsData} featureList={stateDetails.featureArray} fftFeatureList={stateDetails.fffFeatureArray} thresholds={thresholds} deviceId={propsDetails.dashboardDeviceData.deviceData.deviceData.id} realTimeData={stateDetails.realTimeData} fingerprintRealTimeData={stateDetails.fingerprintRealTimeData} batteryFlag={stateDetails.batteryFlag} dataLoggerFlag={stateDetails.dataLoggerFlag}/>
              <WaterfallDiagram props={propsDetails} deviceName={propsDetails.dashboardDeviceData.deviceData.deviceData.deviceName} range={stateDetails.range} widgetsData={propsDetails.widgetsData} monitorId={propsDetails.dashboardDeviceData.deviceData.deviceData.id} deviceId={propsDetails.dashboardDeviceData.deviceData.deviceData.id}  dataLoggerFlag={stateDetails.dataLoggerFlag} featureList={stateDetails.featureArray}/>
              {/* <ReportPanel props={propsDetails} deviceName={propsDetails.dashboardDeviceData.deviceData.deviceData.deviceName} widgetsData={propsDetails.widgetsData} featureList={stateDetails.featureArray} thresholds={thresholds} range={stateDetails.range} deviceId={propsDetails.dashboardDeviceData.deviceData.deviceData.deviceName} machineName={propsDetails.dashboardDeviceData.deviceData.deviceData.machineName} /> */}
            </div>
            <MaintenancePanel updateSelectedTab={(value) => this.props.updateSelectedTab(value)} props={propsDetails} machineName={propsDetails.dashboardDeviceData.deviceData.deviceData.machineName} maintenanceData={propsDetails.maintenanceData.maintenanceData} alarmsData={propsDetails.alarmsData} deviceName={propsDetails.dashboardDeviceData.deviceData.deviceData.deviceName} widgetsData={propsDetails.widgetsData} deviceId={propsDetails.dashboardDeviceData.deviceData.deviceData.id} range={stateDetails.range} featureList={stateDetails.featureArray} deviceData={propsDetails.deviceData} onRefreshMaintainace={this.props.onRefreshMaintainace} onMaintainanceDone={this.props.onMaintainanceDone} batteryFlag={stateDetails.batteryFlag}/>
          </div>
        )
    }
}
const mapSatateToProps = state => {
    return {
      alarmsData: state.alarmReportDataReducer,
      maintenanceData: state.maintenanceDataReducer,
      featureListData: state.featureDataReducer,
      userData: state.userDataReducer,
      drawerData: state.drawerReducer,
      monitorData: state.getMonitorReducer,
      dashboardType: state.dashboardTypeReducer,
      summaryData: state.summaryDataReducer,
      deviceData: state.deviceDataforDashboardReducer,
      loaderState: state.loaderReducer,
      config: state.viewDefinitionReducer.config,
  
    }
  }
  
  const mapDispatchToProps = dispatch => {
    return {
      setLoaderState: (value) => (dispatch(actionCreators.setLoaderState(value))),
      setDeviceData: (deviceData) => (dispatch((actionCreators.setDeviceData(deviceData)))),
      updatedashboardType: (type) => (dispatch(actionCreators.updatedashboardType(type))),
       
    }
  }
  export default withRouter(withTranslation()(connect(mapSatateToProps, mapDispatchToProps)(withStyles(styles)(DefaultView))));
  