import React, { Component } from 'react'
import moment from "moment";
import { connect } from 'react-redux';
import * as actionCreators from '../../../actions/actionCreators';
import ProductionHistory from '../ListingPanels/ProductionHistory'
import ProductionHistoryContainer from '../ListingPanels/ProductionHistoryContainer'
import OEEByPartNumber from '../ListingPanels/OEEByPartNumber'
import OEEByPartNumberContainer from '../ListingPanels/OEEByPartNumberContainer'
import ProductivitySelection from '../ListingPanels/ProductivitySelection'
import ProductivitySelectionContainer from '../ListingPanels/ProductivitySelectionContainer'
import OeeSelection from '../ListingPanels/OeeSelection'
import OeeSelectionContainer from '../ListingPanels/OeeSelectionContainer'
import LossComponent from '../ListingPanels/LossComponent'
import LossComponentContainer from '../ListingPanels/LossComponentContainer'
import DowntimeAnalysis from '../ListingPanels/DowntimeAnalysis'
import DowntimeAnalysisContainer from '../ListingPanels/DowntimeAnalysisContainer'
import MaintenanceMetrics from '../ListingPanels/MaintenanceMetrics'
import MaintenanceMetricsContainer from '../ListingPanels/MaintenanceMetricsContainer'
import UtilityConsumption from '../ListingPanels/UtilityConsumption'
import UtilityConsumptionContainer from '../ListingPanels/UtilityConsumptionContainer'
import QualityData from '../ListingPanels/QualityData'
import QualityDataContainer from '../ListingPanels/QualityDataContainer'
import AlarmsAnalysis  from '../ListingPanels/AlarmsAnalysis'
import AlarmsAnalysisContainer from '../ListingPanels/AlarmsAnalysisContainer'
import CycleTimeComparison from '../ListingPanels/CycleTimeComparison'
import CycleTimeComparisonContainer from '../ListingPanels/CycleTimeComparisonContainer'
import TopMachineLoss from '../ListingPanels/TopMachineLoss'
import TopMachineLossContainer from '../ListingPanels/TopMachineLossContainer'
import OEEByOperatorContainer from '../ListingPanels/OEEByOperatorContainer';
import OEEByOperator from '../ListingPanels/OEEByOperator';
import { withTranslation } from "react-i18next";


class MachineProductivityListingPanel extends Component {
    constructor(props){
        super(props);
        this.state = {
            isProductionHistory :  {0: true},
            plantName : '',
            showBookedLoss: true,
            showPlannedDowntime :false,
            dataURL:'',
            lossOption: 'byDuration',
            hiddenLossValue: 'withHiddenLosses',
            selectedMachineOee : 0,
            lossOpen: false,
            selectedMachineProd : 0,
            downtimeOption : '',
            machineIdforLoss : 0,
            machineIdforCycleTime: 0,
            qualityValue: 1,
            alarmsAnalysis: 'byMachine'
        }        
    } 
    
    onSelectionChange = (e) =>{
        let eleValue = parseInt(e.target.value);
        let from = moment(this.props.range[1]).utc().format("YYYY-MM-DDTHH:mm:00") + "Z";
        let to = moment(this.props.range[2]).utc().format("YYYY-MM-DDTHH:mm:00") + "Z";
        if( eleValue || eleValue===0){
            this.setState({isProductionHistory: {[eleValue]: true}});
        }
        if( eleValue == 4 ) {
            this.setState({ downtimeOption : 'histogram'})
        }
    }

    onOeeSelectionChange = (e) =>{
        let eleValue = parseInt(e.target.value);
        
        if(eleValue || eleValue===0){
            this.setState({isProductionHistory: {[2]: true}, selectedMachineOee : eleValue});
        }
        
    }

    onProdSelectionChange = (e) =>{
        let eleValue = parseInt(e.target.value);
        
        if(eleValue || eleValue===0){
            this.setState({isProductionHistory: {[1]: true}, selectedMachineProd : eleValue});
        }
        
    }

    changeBookedLoss = (e) =>{
        let eleValue = parseInt(e.target.value);
        if(eleValue===2){
            this.setState({showBookedLoss: false});
        }else{
            this.setState({showBookedLoss: true});
        }
    }

    changePlannedDowntime = (e) =>{
        let eleValue = parseInt(e.target.value);
        if(eleValue===2){
            this.setState({showPlannedDowntime : false });
        }else{
            this.setState({showPlannedDowntime : true});
        }
    }

    componentDidMount(){
        this.setState({plantName: this.props.plantName })
    }

    componentDidUpdate(prevProps){
        if(prevProps.machineList !== this.props.machineList && this.props.machineList.length > 0 ){
            var machineIds = this.props.machineList.map(function(a) {return a.machineId;});
            let from = moment(this.props.range[1]).utc().format("YYYY-MM-DDTHH:mm:00") + "Z";
            let to = moment(this.props.range[2]).utc().format("YYYY-MM-DDTHH:mm:00") + "Z";
            this.props.getLossReportData(this.props.selectedPlantId , from, to, machineIds, 5);
            this.props.getCycleTimeComparison(this.props.selectedPlantId , from, to, machineIds[0]);
        
        }
        if(prevProps.plantName !== this.props.plantName) {
            this.setState({plantName: this.props.plantName })
        }
        if(prevProps.range !== this.props.range){
            // this.setState({isProductionHistory :  {0: true}})
            let ele_1 = document.getElementById('productivity_type');
            ele_1.value = '0'
            let event= {target: { value : 0}}
            this.onSelectionChange(event);
        }
    }

    parentFunChart = (text) => {
        this.setState({dataURL:text})
    }

    onLossSelectionChange = (value) =>{
        this.setState({
            lossOption : value
        })
    }
    onHiddenLossSelectionChange = (e) =>{
        this.setState({
            hiddenLossValue : e.target.value
        })
    }
    lossesModalClose = () => {
        this.setState({ lossOpen: false })
    };
    
    lossesModalOpen = () => {
       this.setState({ lossOpen: true })
    };

    onDowntimeChange = (value) => {
        this.setState({ downtimeOption: value })
    }
    
    onMachineSelectionChange = (e) => {
        this.setState({ machineIdforLoss : parseInt(e.target.value) })
    }

   
    onMachineChangeForCycletime = (e) => {
        let from = moment(this.props.range[1]).utc().format("YYYY-MM-DDTHH:mm:00") + "Z";
        let to = moment(this.props.range[2]).utc().format("YYYY-MM-DDTHH:mm:00") + "Z";
        this.setState({ machineIdforCycleTime : parseInt(e.target.value) })
        this.props.getCycleTimeComparison(this.props.selectedPlantId , from, to, e.target.value);
    }

    onQualityChange = (value) => {
        let from = moment(this.props.range[1]).utc().format("YYYY-MM-DDTHH:mm:00") + "Z";
        let to = moment(this.props.range[2]).utc().format("YYYY-MM-DDTHH:mm:00") + "Z";
        this.setState({ qualityValue: value })
        this.props.getQualitytData1(this.props.selectedPlantId , from, to, value);
    }

    onAlarmAnalysisChange =(value) => {
        this.setState({ alarmsAnalysis: value })
    }

    render() {
        const {t}=this.props;
        const { isProductionHistory, dataURL, plantName, selectedMachineOee, selectedMachineProd, downtimeOption, machineIdforLoss, machineIdforCycleTime} = this.state;
        const { selectedPlantId, range, productivitySelection, partCountList, oeeByPartNo, oeeSelection, machineList, lossList, cycleTimeData, qualityReport} = this.props
        return ( 
            <div className="productivity-listing-panel common-widget">
                <div className="listing-upper-panel">
                    <div className="list-selection-wrp display-text">
                        <div className="list-selection">
                            <select className="list-selection form-control" id="productivity_type" onChange={this.onSelectionChange}>
                                <option value="0">{t("Machine Production History")}</option>
                                <option value="1">{t("Productivity")}</option>
                                <option value="2" id="productivity_graph_oee_label">{t("OEE")}</option>
                                <option value="3">{t("Losses")}</option>
                                <option value="4">{t("Downtime Analysis")}</option>
                                <option value="5">{t("Maintenance Metrics")}</option>
                                <option value="6">{t("Utility Consumption")}</option>
                                <option value="7">{t("Quality Data")}</option>
                                <option value="8">{t("Alarms Analysis")}</option>
                                <option value="9">{t("OEE By Part Number")}</option>
                                <option value="10">{t("OEE By Operator")}</option>
                                <option value="11">{t("Cycle Time Comparison")}</option>
                                <option value="12">{t("Top 5 Machine Loss")}</option>
                                {/* <option value="13">Top 5 Plant Loss</option> */}
                            </select>
                        </div>
                        {
                            isProductionHistory[1] ? 
                            <div className="list-selection" style={{marginTop: "10px"}}>
                                   <select className="list-selection form-control" id="productivity_type" onChange={(e) => this.onProdSelectionChange(e)}>
                                   <option value="all">{t("All Machines")}</option>
                                   {this.props.machineList.map((list, index) =>
                                        <option value={list.machineId} key={index}>{list.machineName}</option>
                                   )
                                   }
                                   </select>
                            </div> : null 
                        }
                        {
                            isProductionHistory[2] ? 
                            <div className="list-selection" style={{marginTop: "10px"}}>
                                   <select className="list-selection form-control" id="productivity_type" onChange={(e) => this.onOeeSelectionChange(e)}>
                                   <option value="0">{t("All Machines")}</option>
                                   {this.props.machineList.map((list, index) =>
                                        <option value={list.machineId} key={index}>{list.machineName}</option>
                                   )
                                   }
                                   </select>
                            </div> : null 
                        }
                    </div>
                   
                        {
                            isProductionHistory[0] ? <ProductionHistory changeBookedLoss={this.changeBookedLoss} changePlannedDowntime={this.changePlannedDowntime} /> : null 
                        }
                        {
                            isProductionHistory[1] ? <ProductivitySelection dataURL={dataURL} plantName={plantName} selectedMachineProd={selectedMachineProd} reportData={productivitySelection.productivityData} range={range}/> : null 
                        }
                        {
                            isProductionHistory[2] ? <OeeSelection dataURL={dataURL} selectedMachineOee={selectedMachineOee} reportData={oeeSelection.reportData} plantName={plantName} range={range}/> : null 
                        }
                        {
                            isProductionHistory[3] ? <LossComponent onLossSelectionChange={this.onLossSelectionChange} 
                                                                    lossOption={this.state.lossOption} 
                                                                    hiddenLossValue={this.state.hiddenLossValue} 
                                                                    onHiddenLossSelectionChange={this.onHiddenLossSelectionChange} 
                                                                    lossesModalClose={this.lossesModalClose}
                                                                    lossesModalOpen={this.lossesModalOpen}
                                                                    range={range}
                                                                    partCountList={partCountList}
                                                                    lossList= {lossList}
                                                                    lossReportData={this.props.lossReportData.inputData}
                                                     /> : null 
                        }
                        {
                            isProductionHistory[4] ? <DowntimeAnalysis downtimeOption={downtimeOption} onDowntimeChange={this.onDowntimeChange}/> : null 
                        }
                        {
                            isProductionHistory[5] ? <MaintenanceMetrics maintenanceMetricsData={this.props.maintenanceMetricsData.maintenanceMetricsData}/> : null 
                        }
                        {
                            isProductionHistory[6] ? <UtilityConsumption/> : null
                        }
                        {
                            isProductionHistory[7] ? <QualityData onQualityChange={this.onQualityChange} range={range} plantName={plantName} qualityReport={qualityReport} qualityData1={this.props.qualityData1} qualityData={this.props.qualityData} /> : null
                        }
                        {
                            isProductionHistory[8] ? <AlarmsAnalysis onAlarmAnalysisChange={this.onAlarmAnalysisChange}/> : null
                        }
                        {
                            isProductionHistory[9] ? <OEEByPartNumber list={partCountList.list} selectedPlantId={selectedPlantId} range={range}/>: null 
                        }
                        {
                            isProductionHistory[10] ? <OEEByOperator list={partCountList.list} selectedPlantId={selectedPlantId} range={range}/>: null 
                        }
                        {
                            isProductionHistory[11] ? <CycleTimeComparison machineList={machineList} onMachineChangeForCycletime={this.onMachineChangeForCycletime}/> : null
                        }
                        {
                            isProductionHistory[12] ? <TopMachineLoss machineList={machineList} onMachineSelectionChange={this.onMachineSelectionChange}/> : null
                        }
                   
                </div> 
                <div className="listing-bottom-panel" style={{marginTop: "25px"}}>
                    {
                        isProductionHistory[0] ? <ProductionHistoryContainer lossList= {lossList} selectedPlantId={selectedPlantId} showBookedLoss={this.state.showBookedLoss} showPlannedDowntime={this.state.showPlannedDowntime} range={range} /> : null                         
                    }
                    {
                        isProductionHistory[1] ? <ProductivitySelectionContainer parentFunChart={this.parentFunChart} selectedMachineProd={selectedMachineProd} plantName={plantName} reportData={productivitySelection.productivityData}/> : null
                    }
                    {
                        isProductionHistory[2] ? <OeeSelectionContainer parentFunChart={this.parentFunChart} selectedMachineOee={selectedMachineOee} reportData={oeeSelection.reportData} plantName={plantName}/> : null 
                    }
                    {
                        isProductionHistory[3] ? <LossComponentContainer
                                                                        lossList = {lossList.inputData}
                                                                        lossOption={this.state.lossOption} 
                                                                        hiddenLossValue={this.state.hiddenLossValue} 
                                                                        lossesModalClose={this.lossesModalClose}
                                                                        open={this.state.lossOpen}
                                                                        range={range}
                                                                        plantId={selectedPlantId}
                                                /> : null 
                    }
                    {
                        isProductionHistory[4] ? <DowntimeAnalysisContainer downtimeOption={downtimeOption} downtimeDataList={this.props.downtimeDataList}/> : null 
                    }
                    {
                        isProductionHistory[5] ? <MaintenanceMetricsContainer maintenanceMetricsData={this.props.maintenanceMetricsData.maintenanceMetricsData} /> : null 
                    }
                    {
                        isProductionHistory[6] ? <UtilityConsumptionContainer/> : null
                    }
                     {
                        isProductionHistory[7] ? <QualityDataContainer qualityValue={this.state.qualityValue} qualityData1={this.props.qualityData1} qualityData={this.props.qualityData}/> : null
                    }
                    {
                        isProductionHistory[8] ? <AlarmsAnalysisContainer range={range}
                                                                          plantId={selectedPlantId} 
                                                                          alarmsAnalysis={this.state.alarmsAnalysis}/> : null
                    }
                    {
                        isProductionHistory[9] ? <OEEByPartNumberContainer  oeeByPartNo={oeeByPartNo}/>: null 
                    }
                    {
                        isProductionHistory[10] ? <OEEByOperatorContainer  oeeByPartNo={oeeByPartNo}/>: null  
                    }
                    {
                        isProductionHistory[11] ? <CycleTimeComparisonContainer machineIdforCycleTime={machineIdforCycleTime} cycleTimeData={cycleTimeData.count}/> : null
                    }
                    {
                        isProductionHistory[12] ? <TopMachineLossContainer machineIdforLoss={machineIdforLoss} range={range} selectedPlantId={selectedPlantId}/> : null
                    }


                </div>               
            </div>
        )
    }
}


const mapStateToProps = state => {
    return {
      productivitySelection : state.productivitySelectionReducer,
      partCountList : state.partCountListReducer,
      oeeSelection : state.oeeSelectionReducer,
      downtimeDataList : state.downtimeAnalysisReducer,
      maintenanceMetricsData : state.maintenanceMetricsReducer,
      lossList : state.lossDropDownListReducer,
      lossReportData : state.lossReportDataReducer,
      cycleTimeData : state.cycleTimeComparisonReducer,
      qualityData1: state.qualityDataReducer1,
      qualityReport: state.qualityReportReducer
    }
  }

 const mapDispatchToProps = dispatch => {
    return { 
        getLossReportData: (plantId , from, to, machineIdList, selection) => (dispatch((actionCreators.getLossReportData(plantId , from, to, machineIdList, selection)))),
        getCycleTimeComparison: (plantId , from, to, machineId) => (dispatch((actionCreators.getCycleTimeComparison(plantId , from, to, machineId)))),        
        getQualitytData1: (plantId , from, to, type) => (dispatch((actionCreators.getQualitytData1(plantId , from, to, type)))),
    
    }
}

export default withTranslation()(connect(mapStateToProps, mapDispatchToProps)(MachineProductivityListingPanel));