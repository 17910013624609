import React, { Component, Fragment } from 'react';
import {
    AppBar,
    Typography,
    withStyles,
    Grid,
    IconButton,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    Button,
    TextField,
    InputAdornment,
    Divider
} from '@material-ui/core';
import { connect } from 'react-redux';
import { Settings, Close, Visibility, VisibilityOff, ExitToApp, Edit } from '@material-ui/icons';
import { Drawer } from '@material-ui/core';
import MenuIcon from '@material-ui/icons/Menu';
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import ContactSupportIcon from '@material-ui/icons/ContactSupport';
import Tooltip from '@material-ui/core/Tooltip';
import FileCopyOutlinedIcon from '@material-ui/icons/FileCopyOutlined';
import AccessTimeOutlinedIcon from '@material-ui/icons/AccessTimeOutlined';
import AddCircleOutlineRoundedIcon from '@material-ui/icons/AddCircleOutlineRounded';
import LanguageDropdown from './LanguageDropdown';
import * as actionCreators from '../actions/actionCreators';
import MUIDataTable from "mui-datatables";
import { createMuiTheme, MuiThemeProvider } from '@material-ui/core/styles';
import { CloudDownload } from '@material-ui/icons';
import { withTranslation } from "react-i18next";
import moment from "moment";
import { SHOW_PLATFORM_UPTIME, support_email,config,IDAP_SSO_DOMAIN_NAME } from '../configuration';
import Brightness1Icon from '@material-ui/icons/Brightness1';
import { CSVLink } from "react-csv";
import { getOidcAccessToken } from '../utils/oidcStorageUtils';
import { isPossiblePhoneNumber, parsePhoneNumber }  from 'react-phone-number-input';
import SubscribeApps from "./SubscribeApps";
import DatePicker from "react-date-picker";
import 'react-date-picker/dist/DatePicker.css';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
let logoImg = require('../assets/images/IUlogo.png')

let server_url = config.url.server_url;
let currentLanguage = localStorage.getItem("lang");
let type = localStorage.getItem("parentOrganizationtype");

if (currentLanguage === "null" || currentLanguage === null) {
    currentLanguage = "en"
}

const styles = theme => ({
    title: {
        color: 'white',
    },
    paper1: {
        top: 50,
        width: '30%',
        // right:15,
        // borderRight: 0,
        height: '100vh',
        // backgroundColor: '#18202C',
        overflow: 'hidden'
    },
    paper2: {
        top: 53,
        width: '27%',
        height: 'auto',
        position: 'absolute',
        right: '15px',
        padding: '10px',
        background: '#fff',
        'z-index': '99',
        border: '1px solid #e9ecef',
        'box-shadow': '1px 0px 2px 1px #e9ecef',
        display: 'grid',
        gridTemplateColumns: '1fr 1fr 1fr',
        gridGap: '13px',
        borderRadius: '5px'

    },
    toolbar: {
        regular: {
            minHeight: 48
        }
    },
    userMessage: {
        fontSize: 14,
        textAlign: "center",
        color: "inherit",
        fontWeight: "400",
        padding: '0 10px'
    },
    icon: {
        color: "inherit",
        '& svg': {
            'font-size': 20
        }
    },
    containedHelperText: {
        margin: '3px 5px 0'
    },
    paper: {
        position: 'absolute',
        width: theme.spacing.unit * 50,
        backgroundColor: theme.palette.background.paper,
        boxShadow: theme.shadows[5],
        padding: theme.spacing.unit * 4,
        outline: 'none',
    },
    header: {
        height: '50px',
        backgroundColor: '#1565c0 !important',
    },
    headerleftText: {
        display: 'flex',
        'justify-content': 'flex-start',
        'align-items': 'center',
        'margin-left': 5
    },
    headerRightActions: {
        // width:"23%",
        display: 'flex',
        flexDirection: 'row-reverse',
        'align-items': 'center',
        color: '#fff'
    },
    headerLeftActions: {
        width: "100%",
        display: 'flex',
        flexDirection: 'row',
        'align-items': 'center',
        color: '#fff'
    },
    logoClass: {
        // width: "160px",
        height: "40px"
    },
    divContainer: {
        display: "flex",
        flexDirection: "row-reverse",
        height: '9vh'
    },
    dialogActions: {
        margin: 12,
        marginRight: 20
    },
    tabName: {
        color: "#232F3E",
        fontSize: "17px",
        fontWeight: 500
    },
    entityName: {
        color: "#232F3E",
        fontSize: "17px",
        fontWeight: 500
    },
    settingsModal: {
        'min-width': 525,
        'max-height': 589,
        'min-height': 460
    },
    platformModal: {
        'min-width': 525,
        'max-height': 500,
        'max-width': 700,
        height: "400px"
    },
    documentModal: {
        height: "600px",
        width:'660px'
    },
    headingP: {
        fontSize: '1.25rem',
        fontWeight: 700,
        textAlign: 'center',
        color: '#000',
        // marginBottom: '20px'
    },
    subheadP: {
        fontSize: '1rem',
        fontWeight: 700,
        textAlign: 'center',
    },
    dateP: {
        textAlign: 'center',
        color: "rgba(0, 0, 0, 0.54)",
        fontSize: "14px",
        fontWeight: 700,
        marginBottom: '20px'
    },
    dialogTitle: {
        display: 'flex',
        'justify-content': 'space-between',
        'align-items': 'center',
        padding: '10px 24px'
    },
    dialogContent: {
        padding: '0 24px'
    },
    elementContainer: {
        display: 'flex',
        'align-items': 'center',
        'justify-content': 'space-between'
    },
    inputLabel: {
        color: 'rgba(0, 0, 0, 0.4000000059604645)',
        'font-size': 14,
        'letter-spacing': 0.5,
        'font-weight': 500
    },
    textField: {
        width: 300,
        height: 50,
        '& input': {
            padding: 16
        }
    },
    saveButton: {
        width: 145,
        backgroundColor: '#42D784',
        '&:hover': {
            backgroundColor: '#42D784'
        }
    },
    visibilityIcon: {
        color: '#4c4c4c',
        cursor: 'pointer'
    },
    visibilityOffIcon: {
        color: '#afacac'
    },
    sup: {
        top: 0,
        color: 'red',
        fontSize: '100%'
    },
    outlinedInput: {
        height: 45,
        //color: '#FFFFFF',
        backgroundColor: 'transparent !important',
        '-webkit-appearance': 'none'
    },
    tabs: {
        color: 'white',
        cursor: 'pointer'
    },
    enableTab: {
        color: 'yellow',
        cursor: 'pointer'
    },
    content: {
        fontFamily: 'Roboto,-apple-system,Helvetica Neue,Helvetica,Arial,sans-serif',
        fontSize: '20px',
        fontWeight: 500,
        letterSpacing: '.02em',
        lineHeight: 1.12,
        color: 'rgba(0,0,0,.5)'
    },
    statusPlatform: {
        display: "flex",
        backgroundColor: "#f8f9fa",
        padding: "10px",
        justifyContent: "space-between"
    },
    border: {
        'box-shadow': '0px 0px 4px 2px #e9ecef',
    },
    greenStrip: {
        width: "5px",
        height: "30px",
        background: "#54b061",
        borderRadius: "5px",
        marginLeft: "1px",
        display: "inline-block"
    },
    greenStripDiv: {
        padding: "15px",
    },
    dateDiv: {
        padding: "10px",
        marginTop: "-25px"
    },
    devicePercentage: {
        float: "right",
        paddingRight: "10px",
        color: "#6fa0d8",
        fontWeight: 600,
        cursor: "pointer"
    },
    deviceModal: {
        'min-width': 1100,
    },
    datePicker: {
        marginRight: 20,
        padding: "5px",
        "& .react-date-picker__inputGroup": {
          padding: "5px !important",
        },
      },
});

class Header extends Component {

    state = {
        openDialog: false,
        saveButton: false,
        phoneNumber: '',
        email: '',
        fName: '',
        lName: '',
        selectedFile: null,
        logo: "",
        "validUsername": null,
        "validFirstname": null,
        "validLastname": null,
        "validPhoneNumber": null,
        "validEmailAddress": null,
        "somethingChanged": null,
        open: false,
        openSupportDialog: false,
        openDocumentDialog: false,
        openPlatformUptime: false,
        openDevUptime: false,
        openDetailFlag: false,
        logo: '',
        columns: [],
        data: null,
        columnsDevice: [],
        dataDevice: null,
        openNineDrawer: false,
        lang: "",
        scopeSeletorPlantsData: [],
        deviceUptimeArray: [],
        plantwiseUptimeArray: [],
        plantNameForDetailPage: '',
        plantIdForDownload:"",
        startDate: null,
        endDate: null
    }

    getMuiTheme = () => createMuiTheme({
        overrides: {
            MUIDataTableHeadCell: {
                fixedHeader: {
                    'z-index': 0
                },
                hintIconAlone: {
                    marginTop: '2px',
                    fontSize: '14px'
                },
                fixedHeaderXAxis:{
                    left:this.state.openDetailFlag?"70px":"0px"
                }
            },
            MUIDataTableFilter: {
                root: {
                    'width': '500px !important'
                }
            },
            typography: {
                useNextVariants: true,
            },
            MUIDataTable: {
                responsiveScroll: {
                    height: '280px',
                    'max-height': '300px !important'
                }
            },
            MuiTableCell: {
                root: {
                    "padding-right": "38px !important"
                },
                head: {
                    fontSize: 14,
                    fontWeight: 800,
                    letterSpacing: 0.25
                },
                body: {
                    fontSize: 14,
                    letterSpacing: 0.25
                }
            }
        }
    })
    getCookie(cname) {
        let name = cname + "=";
        let decodedCookie = decodeURIComponent(document.cookie);
        let ca = decodedCookie.split(';');
        for (let i = 0; i < ca.length; i++) {
            let c = ca[i];
            while (c.charAt(0) == ' ') {
                c = c.substring(1);
            }
            if (c.indexOf(name) == 0) {
                return c.substring(name.length, c.length);
            }
        }
        return "";
    }


    componentDidUpdate(prevProps, prevState) {
        if (prevProps.i18n.language !== prevState.lang) {
            this.setState({ lang: this.props.i18n.language }, () => {
                this.getTabs();
            })
        }
        if (prevProps.selectedScopeData === undefined && this.props.selectedScopeData && this.props.selectedTab !== 0 && this.props.selectedTab !== 4) {
            this.props.history.push(this.props.history.location.pathname);
            // this.props.isAdmin ? this.props.history.push("/dashboard/UsersTable") : this.props.history.push("/dashboard/MonitorsTable");
        }
    }

    getApiCallForUsingPlantId = (plantId, from, to) => {
        this.props.setLoaderState({ "value": true });
        var that = this;
        fetch(`${config.url.mindapp_server_url}/iu/md/api/device-overall/uptime-plants?plantId=${plantId}&fromDate=${from}&toDate=${to}`,
            {
                headers: {
                    "Authorization": `Bearer ${getOidcAccessToken()}`,
                    "Accept-Language": localStorage.getItem("lang") || "en"
                }
            })
            .then(res => res.json())
            .then(
                (result) => {
                    that.props.setLoaderState({ "value": false });
                    this.setState({ plantwiseUptimeArray: result },()=>{
                        this.tableConfigurationsDeviceUptime();
                    })
                },
                // Note: it's important to handle errors here
                // instead of a catch() block so that we don't swallow
                // exceptions from actual bugs in components.
                (error) => {
                    that.props.setLoaderState({ "value": false });

                    this.setState({
                        //  isLoaded: true,
                        error
                    });
                }
            )
            .catch(function (error) {
                that.props.setLoaderState({ "value": false });
            }
            );
    }
    getApiCallForUsingUaerId = (plantId, from, to) => {
        var that = this;
        that.props.setLoaderState({ "value": true });

        fetch(`${config.url.mindapp_server_url}/iu/md/api/device-overall/uptimeByUserId?userId=${plantId}&fromDate=${from}&toDate=${to}`,
            {
                headers: {
                    "Authorization": `Bearer ${getOidcAccessToken()}`,
                    "Accept-Language": localStorage.getItem("lang") || "en"
                }
            })
            .then(res => res.json())
            .then(
                (result) => {
                    that.props.setLoaderState({ "value": false });
                    this.setState({ deviceUptimeArray: result })
                },
                // Note: it's important to handle errors here
                // instead of a catch() block so that we don't swallow
                // exceptions from actual bugs in components.
                (error) => {
                    that.props.setLoaderState({ "value": false });
                    this.setState({
                        //  isLoaded: true,
                        error
                    });
                }
            )
            .catch(function (error) {
                that.props.setLoaderState({ "value": false });
            }
            );
    }

    componentDidMount() {
        this.getLogo();
        this.setState({ lang: this.props && this.props.i18n && this.props.i18n.language });
        this.props.getDocumentationData();
        window.addEventListener('click', (event) => {
            if (event.target.className !== "") {
                this.setState({ openNineDrawer: false })
            }
        });
        let scopeSeletorPlantsData = [];
       this.setState({ scopeSeletorPlantsData })

    }

    getLogo = () => {
        const accessToken = getOidcAccessToken();
        fetch(`${server_url}/getLogo/${this.props.userOrganization}/${new Date()}`, {
            headers: {
                "Authorization": `Bearer ${accessToken}`,
                "Accept-Language": localStorage.getItem("lang") || "en"
            }
        })
            .then(function (response) {
                if (response.status === 200) {
                    return response.blob();
                }
            })
            .then(images => {
                // Then create a local URL for that image and print it 
                let outside = URL && URL.createObjectURL(images)
                this.setState({
                    logo: outside
                }, () => {
                    //skip
                })
            })
    }

    handleChange = (value) => {
        let dashboard;
        let dash = localStorage.getItem("dashboard");
        localStorage.removeItem("navigateID");
        if (dash === null) {
            let plantView = localStorage.getItem("PlantView")
            let plantViewArray = JSON.parse(plantView);
            if (plantViewArray !== null) {
                if (plantViewArray.length > 1) {
                    dashboard = "summaryPlant";
                } else {
                    dashboard = "summary"
                }
            }
            else {
                dashboard = "summary"
            }
        } else {
            dashboard = dash;
        }
        localStorage.setItem("dashboard", dashboard);
        // localStorage.removeItem("deviceName");
        // localStorage.removeItem("deviceId");
        // localStorage.removeItem("machineName");
        // localStorage.removeItem("deviceIdentifier");

        this.props.updatedashboardType(dashboard);
        if (this.props.selectedTab !== value) {
            if (value === 3) {
                this.props.history.push("/dashboard/UsersTable")
                let PlantValue = localStorage.getItem("orgValue");
                if (PlantValue !== null && PlantValue !== undefined) {
                    let currentPlantName = localStorage.getItem("organizationtName");
                    this.props.updateScopeSelector(parseInt(PlantValue), 3, null, "OrganizationSelector", currentPlantName.name);

                }
            } else if (value === 0) {
                let PlantValue = localStorage.getItem("plantValue");
                if (PlantValue !== null && PlantValue !== undefined) {
                    let currentPlant = localStorage.getItem("plantPermisssion");
                    let currentPlantName = localStorage.getItem("plantName");
                    this.props.updateScopeSelector(parseInt(PlantValue), value, currentPlant.permission, "PlantSelector", currentPlantName.name);

                }
                if (value === 0) {
                    this.props.history.push("/dashboard/MonitoringTable");
                } else {
                    this.props.history.push("/dashboard/Monitoring");
                }
            } else if (value === 4) {
                let PlantValue = localStorage.getItem("plantValue");
                if (PlantValue !== null && PlantValue !== undefined) {
                    let currentPlant = localStorage.getItem("plantPermisssion");
                    let currentPlantName = localStorage.getItem("plantName");
                    this.props.updateScopeSelector(parseInt(PlantValue), value, currentPlant.permission, "PlantSelector", currentPlantName.name);

                }
                //  this.props.history.push("/dashboard/ProductionDashboard")
            } else {
                this.props.history.push("/dashboard/MonitorsTable");
                let PlantValue = localStorage.getItem("plantValue");
                if (PlantValue !== null && PlantValue !== undefined) {
                    let currentPlant = localStorage.getItem("plantPermisssion");
                    let currentPlantName = localStorage.getItem("plantName");
                    this.props.updateScopeSelector(parseInt(PlantValue), value, currentPlant.permission, "PlantSelector", currentPlantName.name);

                }
            }

            this.props.updateSelectedTab(value);
            if (value === 0 && dashboard === "monitoring") {
                this.props.updateDrawer(false);
            } else {
                this.props.updateDrawer(true);
            }
        }
    };


    getTabs = () => {
        let tabs = [];
        if (this.props.tabs) {
            tabs = <div style={{ width: '100%', display: 'flex', justifyContent: 'space-around' }} >{this.props.tabs.map((item, index) => {
                if ((item.key === 'Reporting') || (item.key === 'Configuration') || (item.key === "Administration")) {
                    return null;
                } else {
                    return <Typography variant="h6" key={item.key} className={index === this.props.selectedTab ? this.props.classes.enableTab : this.props.classes.tabs} onClick={() => this.handleChange(index)}>
                        {this.props.t(item.key).toUpperCase()}
                    </Typography>
                }
            })}</div>
        }
        return tabs;
    }



    logoUploadHandler = () => {
        this.props.uploadLogo(this.state.selectedFile)
    }

    // handle user logout
    handleLogout = () => {
        // actionCreator logOut is invoked
        this.props.logout();
    }

    toggleDrawer = (flag) => {
        this.setState({ open: flag });
    }
    ninePinDrawer = () => {
        this.setState({ openNineDrawer: !this.state.openNineDrawer });
    }

    openSupportDialog = () => {
        this.setState({
            openSupportDialog: !this.state.openSupportDialog,
        })
    }

    openDocumentDialog = () => {
        this.tableConfigurations();
        this.setState({
            openDocumentDialog: !this.state.openDocumentDialog,
        })
    }

    openPlatformUptime = () => {
        this.props.getPlatformUtimeLast30DaysData()
        this.setState({
            openPlatformUptime: !this.state.openPlatformUptime,
        })
    }
    openDeviceUptime = () => {
        let currentDateTime = new Date();
        let startDate = new Date(currentDateTime);
        let startDate1 = new Date(currentDateTime);
        
        startDate.setDate(currentDateTime.getDate() - 31);
        startDate1.setDate(currentDateTime.getDate() - 1);
        startDate.setHours(0,0,0,0);
        startDate1.setHours(0,0,0,0);

        this.getApiCallForUsingUaerId(this.props.userData.id,startDate.toISOString(), startDate1.toISOString())
        this.setState({
            openDevUptime: !this.state.openDevUptime,
            startDate,
            endDate: startDate1
        })
    }
    handleClose = () => {
        this.setState({
            openSupportDialog: false,
        })
    }
    //open detail Veiw
    openDetailView = (plant) => {
        this.closeDialog();
        this.getApiCallForUsingPlantId(plant.plantId,this.state.startDate.toISOString(), this.state.endDate.toISOString())
        this.setState({ openDetailFlag: !this.state.openDetailFlag, open: false, plantNameForDetailPage: this.props.t("Plant") + "-" + plant.plantName ,plantIdForDownload:plant.plantId})
    }
    
    handleDocumentClose = () => {
        this.setState({
            openDocumentDialog: false,
        })
    }

    // open settings modal
    openDialog = () => {
        this.setState({
            openDialog: !this.state.openDialog,
            email: this.props.userData.email,
            phoneNumber: this.props.userData.phoneNumber,
            fName: this.props.userData.firstName,
            lName: this.props.userData.lastName
        })
    }

    // close settings modal
    closeDialog = () => {
        this.setState({
            openDialog: false,
            saveButton: false,
            openPlatformUptime: false,
            openDevUptime: false,
            "validUsername": null,
            "validFirstname": null,
            "validLastname": null,
            "validPhoneNumber": null,
            "validEmailAddress": null,
            "somethingChanged": null
        })
    }
    closeDialogDetail = () => {
        this.setState({
            openDetailFlag: false
        })
    }

    goToDeviceUptimeDialog = () => {
        this.setState({
            openDetailFlag: false,
            openDevUptime: true
        })
    }

    // update logged-in user details
    updateUser = () => {

        let selectedNavTab = this.props.location.pathname.split("/")[2];
        let selectedtab = this.props.userData.selectedTab;
        let currentScopeSelectionId = this.getCurrentScopeSelectionId(selectedtab);

        const { phoneNumber, email: emailAddress, fName: firstName, lName: lastName } = this.state
        this.props.updateUser({
            phoneNumber,
            emailAddress,
            firstName,
            lastName
        }, selectedNavTab, currentScopeSelectionId);
        // if (this.props.userData.admin) {
        //     this.logoUploadHandler();
        // }
        this.setState({
            openDialog: false
        })
    }

    /*validateEmail = (email) => {
        const regEx = RegExp(/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/);
        if (email === "") {
            return true;
        } else {
            return regEx.test(email);
        }
    }*/

    // get helptext for user input fields in settings modal
    getHelpText = fieldName => {
        let helpText = null;
        switch (fieldName) {
            case 'userName':
                for (let key in this.props.config.Users) {
                    if (key === fieldName && this.props.config.Users[fieldName]["validations"] && this.props.config.Users[fieldName]["validations"]["helpText"]) {
                        helpText = this.props.config.Users[fieldName]["validations"]["helpText"];
                        break;
                    }
                }
                return helpText;

            case 'firstName':
                for (let key in this.props.config.Users) {
                    if (key === fieldName && this.props.config.Users[fieldName]["validations"] && this.props.config.Users[fieldName]["validations"]["helpText"]) {
                        helpText = this.props.config.Users[fieldName]["validations"]["helpText"];
                        break;
                    }
                }
                return helpText;

            case 'lastName':
                for (let key in this.props.config.Users) {
                    if (key === fieldName && this.props.config.Users[fieldName]["validations"] && this.props.config.Users[fieldName]["validations"]["helpText"]) {
                        helpText = this.props.config.Users[fieldName]["validations"]["helpText"];
                        break;
                    }
                }
                return helpText;
            case 'phoneNumber':
                for (let key in this.props.config.Users) {
                    if (key === fieldName && this.props.config.Users[fieldName]["validations"] && this.props.config.Users[fieldName]["validations"]["helpText"]) {
                        helpText = this.props.config.Users[fieldName]["validations"]["helpText"];
                        break;
                    }
                }
                return helpText;

            case 'emailAddress':
                for (let key in this.props.config.Users) {
                    if (key === fieldName && this.props.config.Users[fieldName]["validations"] && this.props.config.Users[fieldName]["validations"]["helpText"]) {
                        helpText = this.props.config.Users[fieldName]["validations"]["helpText"];
                        break;
                    }
                }
                return helpText;

            default: return null;
        }
    }

    // get mandatory fields in settings modal
    isRequiredField = fieldName => {
        let requiredNode = null;

        switch (fieldName) {
            case 'userName':
                for (let key in this.props.config.Users) {
                    if (key === fieldName && this.props.config.Users[fieldName]["validations"] && this.props.config.Users[fieldName]["validations"]["required"]) {
                        requiredNode = <sup className={this.props.classes.sup}>{'*'}</sup>
                        break;
                    }
                }
                return requiredNode;

            case 'firstName':
                for (let key in this.props.config.Users) {
                    if (key === fieldName && this.props.config.Users[fieldName]["validations"] && this.props.config.Users[fieldName]["validations"]["required"]) {
                        requiredNode = <sup className={this.props.classes.sup}>{'*'}</sup>
                        break;
                    }
                }
                return requiredNode;

            case 'lastName':
                for (let key in this.props.config.Users) {
                    if (key === fieldName && this.props.config.Users[fieldName]["validations"] && this.props.config.Users[fieldName]["validations"]["required"]) {
                        requiredNode = <sup className={this.props.classes.sup}>{'*'}</sup>
                        break;
                    }
                }
                return requiredNode;
            case 'phoneNumber':
                for (let key in this.props.config.Users) {
                    if (key === fieldName && this.props.config.Users[fieldName]["validations"] && this.props.config.Users[fieldName]["validations"]["required"]) {
                        requiredNode = <sup className={this.props.classes.sup}>{'*'}</sup>
                        break;
                    }
                }
                return requiredNode;

            case 'emailAddress':
                for (let key in this.props.config.Users) {
                    if (key === fieldName && this.props.config.Users[fieldName]["validations"] && this.props.config.Users[fieldName]["validations"]["required"]) {
                        requiredNode = <sup className={this.props.classes.sup}>{'*'}</sup>
                        break;
                    }
                }
                return requiredNode;

            default: return null;
        }
    }

    // validate user input fields in settings modal
    validate = (fieldName, value, rules) => {
        let valid = true;
        switch (fieldName) {
            case 'userName':
                if (rules) {
                    if (rules["minLength"]) {
                        valid = value.length >= rules["minLength"] && valid;
                    }
                    if (rules["maxLength"]) {
                        valid = value.length <= rules["maxLength"] && valid;
                    }
                    if (rules["required"]) {
                        valid = value.trim() !== '' && valid;
                    }
                }
                this.setState({
                    validUsername: valid
                })
                break;

            case 'firstName':
                if (rules) {
                    if (rules["minLength"]) {
                        valid = value.length >= rules["minLength"] && valid;
                    }
                    if (rules["maxLength"]) {
                        valid = value.length <= rules["maxLength"] && valid;
                    }
                    if (rules["required"]) {
                        valid = value.trim() !== '' && valid;
                    }
                }
                this.setState({
                    validFirstname: valid
                })
                break;

            case 'lastName':
                if (rules) {
                    if (rules["minLength"]) {
                        valid = value.length >= rules["minLength"] && valid;
                    }
                    if (rules["maxLength"]) {
                        valid = value.length <= rules["maxLength"] && valid;
                    }
                    if (rules["required"]) {
                        valid = value.trim() !== '' && valid;
                    }
                }
                this.setState({
                    validLastname: valid
                })
                break;
            case 'phoneNumber':
                try {
                    if (!value) {
                        this.setState({ validPhoneNumber: rules["required"] ? false : null })
                    } else {
                        const parsedNumber = parsePhoneNumber(value);
                        if(parsedNumber) {
                            this.setState({ validPhoneNumber: isPossiblePhoneNumber(value, parsedNumber.country) });
                        }
                    }
                } catch (error) {
                    this.setState({ validPhoneNumber: false });
                }
                break;

            case 'emailAddress':
                if (rules) {
                    if (rules["minLength"]) {
                        valid = value.length >= rules["minLength"] && valid;
                    }
                    if (rules["maxLength"]) {
                        valid = value.length <= rules["maxLength"] && valid;
                    }
                    if (rules["required"]) {
                        valid = value.trim() !== '' && valid;
                    }
                    const regEx = RegExp(/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/);

                    valid = regEx.test(value) && valid;

                }
                this.setState({
                    validEmailAddress: valid
                })
                break;

            default: return null;
        }
    }

    // disable save button of settings modal based on validations
    disableSaveButton = () => {
        let disableSaveButton = true;
        if (this.state.somethingChanged) {
            if (this.state.validUsername === false
                || this.state.validEmailAddress === false || this.state.validFirstname === false
                || this.state.validLastname === false || this.state.validPhoneNumber === false) {
                disableSaveButton = true;
            }
            else {
                disableSaveButton = false;
            }
        }
        return disableSaveButton;
    }

    // handle user input changes for settings modal
    inputChangeHandler = (event, field) => {
        switch (field) {
            case "fName":
                this.validate("firstName", event.target.value, this.props.config.Users["firstName"].validations);
                this.setState({ fName: event.target.value, somethingChanged: true });
                break;

            case "lName":
                this.validate("lastName", event.target.value, this.props.config.Users["lastName"].validations);
                this.setState({ lName: event.target.value, somethingChanged: true });
                break;

            case "phoneNumber":
                this.validate("phoneNumber", event.target.value, this.props.config.Users["phoneNumber"].validations);
                this.setState({ phoneNumber: event.target.value, somethingChanged: true });
                break;

            case "email":
                this.validate("emailAddress", event.target.value, this.props.config.Users["emailAddress"].validations);
                this.setState({ email: event.target.value, somethingChanged: true });
                break;

            default: console.log("default case");
        }
    }
    onDownload(index) {

    }
    tableConfigurations = () => {
        let columns = [
            {
                "name": "displayLabel",
                "label": this.props.t("Document Name"),
                "options": {
                    "display": true,
                    "filter": false,
                    "sort": false,
                    "searchable": false
                }
            },
            {
                "name": "edit",
                "label": this.props.t("Download"),
                "options": {
                    "hint": this.props.t("Download"),
                    "display": true,
                    "filter": false,
                    "sort": false,
                    "searchable": false,
                    setCellProps: () => ({ style: { textAlign: "center" } }),
                }
            }

        ];
        let data = [];

        data = this.props.documentationData.json.map((item, index) => {
            item.edit = <CloudDownload style={{ fontSize: "18", cursor: 'pointer', color: 'rgba(0, 200, 83, 0.5)' }} onClick={() => this.onDownload(item.downloadUrl, item.displayLabel)} />;
            return item;
        });
        this.setState({
            columns,
            data
        })

    }
    tableConfigurationsDeviceUptime = () => {
        let columns = [
            {
                "name": "monitorName",
                "label": this.props.t("Measurement Location"),
                "options": {
                    "display": true,
                    "filter": false,
                    "sort": false,
                    "searchable": true,
                    "customBodyRender": (value, tableMeta) => <div style={{ width:"300px",marginLeft:"70px" }}>{value}</div>,
                }
            },
            {
                "name": "uptimePercent",
                "label": `${this.props.t("Device Uptime")} (${this.state.startDate.toLocaleDateString("en-IN", { day: '2-digit', month: '2-digit', year: 'numeric' })} - ${this.state.endDate.toLocaleDateString("en-IN", { day: '2-digit', month: '2-digit', year: 'numeric' })})`,
                "options": {
                    "display": true,
                    "filter": false,
                    "sort": false,
                    "searchable": false,
                    "customBodyRender": (value, tableMeta) => <div style={{ fontSize: "18", marginLeft: '75px', color: '#5550ce', fontWeight: '600' }}>{value}</div>,
                }
            }

        ];
        let data = [];
        data = this.state && this.state.plantwiseUptimeArray && this.state.plantwiseUptimeArray.deviceUptime && this.state.plantwiseUptimeArray.deviceUptime.map((item,index)=>{
            item.uptimePercent=item.uptimePercent;
            item.monitorName=item.monitorName;

            return item
        })
        
        this.setState({
            columnsDevice: columns,
            dataDevice: data
        })

    }
    onDownload(url, filename) {
        let a = document.createElement('a');
        document.body.appendChild(a);
        a.href = url;
        a.target = "_blank"
        a.download = filename + "pdf";
        a.click();
        document.body.removeChild(a);

    }
    downloadPlantData = () => {
        this.props.setLoaderState({ "value": true });
        var that = this;
        const utcStartDate = this.state.startDate;
        utcStartDate.setHours(5,30,0,0)
        const utcEndDate = this.state.endDate;
        utcEndDate.setHours(5,30,0,0)
        fetch(`${config.url.mindapp_server_url}/iu/md/api/device-overall/getUptimeReport?plantId=${this.state.plantIdForDownload}&fromDate=${this.state.startDate.toISOString()}&toDate=${this.state.endDate.toISOString()}`,
            {
                headers: {
                    "Content-Type": 'application/octet-stream',
                    "Authorization": `Bearer ${getOidcAccessToken()}`,
                    "Accept-Language": localStorage.getItem("lang") || "en"
                }
            })
            .then(function (response) {
                if (response.status === 200) {
                    response.blob().then(blob => {
                        let url = window.URL.createObjectURL(blob);
                        let a = document.createElement('a');
                        document.body.appendChild(a);
                        a.href = url;
                        a.download = "UptimeReport.csv";
                        a.click();
                        document.body.removeChild(a);
                    });
                    that.props.setLoaderState({ "value": false });
                }
            })

            .catch(function (error) {
                console.log("error", error);
                that.props.setLoaderState({ "value": false });
            }
            );
    }
    // get organization's id selected in the scope-selector
    getCurrentScopeSelectionId = (selectedTab) => {
        let selectedScopeData = "";
        if (this.props.location.pathname.split("/").length === 3) {
            let currentEntity = this.props.location.pathname.split("/")[2];

            if (currentEntity === "OrganizationsTable" || currentEntity === "PlantsTable" || currentEntity === "UsersTable") {
                selectedScopeData = this.props.userData.selectedOrganization;
            }
            else if (currentEntity === "MachineGroupsTable" || currentEntity === "MachinesTable" || currentEntity === "SchedulesTable" ||
                currentEntity === "JobsTable" || currentEntity === "LossCodesTable" || currentEntity === "MonitorsTable" || currentEntity === "DevicesTable" ||
                currentEntity === "AlertsTable") {
                selectedScopeData = this.props.userData.selectedPlant;
            }
        }
        if (selectedScopeData) {
            return selectedScopeData;
        }
    }

    openDrawer = () => {
        this.props.updateDrawer(true);
    }

    handleStartDateChange = (date) => {
        const { endDate } = this.state;
    
        // Check if the selected start date is greater than the current end date
        if (date > endDate) {
          this.setState({
            endDate: date,
            startDate: date,
          });
        } else {
          this.setState({
            startDate: date,
          });
        }
      };
    
      handleEndDateChange = (date) => {
        this.setState({
          endDate: date,
        });
      };
    
    redirectUrl = (Url) => {
        document.cookie = `idap-lang-${config.url.env}=${this.props.i18n.language}` + "; domain=" + `${IDAP_SSO_DOMAIN_NAME}; secure`;
        let plantId = localStorage.getItem("plantValue");
        if (plantId === null) {
            plantId = localStorage.getItem("selectedScopeData")
        }
        let dashboard=localStorage.getItem("dashboard");
        if ((Url.redirectUrl).indexOf('?') != -1) {
            window.open(`${Url.redirectUrl}&plantId=${plantId}`)
        } else {
            if(Url.code ==="DRS" && dashboard==="summaryPlant"){
                plantId="all"
            }
            window.open(`${Url.redirectUrl}?plantId=${plantId}`)
        }

    }

    render() {
        let ninePin = require("./../assets/images/9pin.png");
       // let logoHeader = require("./../assets/images/thk.jpg");
       const { startDate, endDate } = this.state;
        const { t } = this.props;
        const options = {
            filter: false,
            search: this.state.openDetailFlag ? true : false,
            sort: false,
            print: false,
            download: false,
            viewColumns: false,
            elevation: 0,
            responsive: "scroll",
            selectableRows: "none",
            rowsPerPage: 5,
            rowsPerPageOptions: [5, 10, 50],
            textLabels: {
                body: {
                    noMatch: this.props.t("No matching records")
                },
                pagination: {
                    next: this.props.t("Next Page"),
                    previous: this.props.t("Previous Page"),
                    rowsPerPage: this.props.t("Rows per Page"),
                    displayRows: this.props.t("of")
                },
                toolbar: {
                    search: this.props.t("Search"),
                    download: this.props.t("Download"),
                    print: this.props.t("Print"),
                    viewColumns: this.props.t("View Columns"),
                    filterTable: this.props.t("Filter Table"),
                    upload: this.props.t("Upload"),
                    // download:currentLanguage==="en" ? "Download":"アップロード",
                    showArchivedData: this.props.t("Show Archieved Data"),
                    showActiveData: this.props.t("Show Active Data")


                },
                filter: {
                    title: this.props.t("FILTERS"),
                    reset: this.props.t("Selected")
                },
                viewColumns: {
                    title: this.props.t("Show Columns")
                },
                selectedRows: {
                    text: this.props.t("Selected"),
                    delete: this.props.t("Delete")
                }

            }
        }

        return (
            <div >
                <AppBar position="static" className={this.props.classes.header}>
                    <div >
                        <Dialog
                            onClose={this.closeDialog}
                            aria-labelledby="customized-dialog-title"
                            open={this.state.openDialog}
                            classes={{ paperWidthSm: this.props.classes.settingsModal }}
                        >
                            <div className={this.props.classes.dialogTitle}>
                                <Typography variant="h6">{this.props.t("Settings")}</Typography>
                                {/* <IconButton aria-label="Close" > */}
                                <Close onClick={this.closeDialog} style={{ cursor: 'pointer' }} />
                                {/* </IconButton> */}
                            </div>
                            <Divider />
                            <DialogContent classes={{ root: this.props.classes.dialogContent }}>
                                <div key={"Email"} className={this.props.classes.elementContainer}>
                                    <div className={this.props.classes.inputLabel}>{t("Email Address")}{this.isRequiredField("emailAddress")}</div>
                                    <TextField
                                        disabled
                                        id="email"
                                        className={this.props.classes.textField}
                                        type="email"
                                        name="email"
                                        value={this.state.email}
                                        autoComplete="email"
                                        margin="normal"
                                        variant="outlined"
                                        helperText={this.getHelpText("emailAddress")}
                                        error={this.state.validEmailAddress === false}
                                        onChange={(event) => this.inputChangeHandler(event, "email")}
                                        FormHelperTextProps={{
                                            classes: {
                                                contained: this.props.classes.containedHelperText
                                            }
                                        }}
                                        InputProps={{
                                            classes: {
                                                root: this.props.classes.outlinedInput
                                            }
                                        }}
                                    />
                                </div>
                                <div key={"First Name"} className={this.props.classes.elementContainer}>
                                    <div className={this.props.classes.inputLabel}>{t("First Name")}{this.isRequiredField("firstName")}</div>
                                    <TextField
                                        required
                                        id="fName"
                                        className={this.props.classes.textField}
                                        value={this.state.fName}
                                        margin="normal"
                                        variant="outlined"
                                        onChange={(event) => this.inputChangeHandler(event, "fName")}
                                        error={this.state.validFirstname === false}
                                        helperText={this.getHelpText("firstName")}
                                        FormHelperTextProps={{
                                            classes: {
                                                contained: this.props.classes.containedHelperText
                                            }
                                        }}
                                        InputProps={{
                                            classes: {
                                                root: this.props.classes.outlinedInput
                                            }
                                        }}
                                    />
                                </div>
                                <div key={"Last Name"} className={this.props.classes.elementContainer}>
                                    <div className={this.props.classes.inputLabel}>{t("Last Name")}{this.isRequiredField("lastName")}</div>
                                    <TextField
                                        required
                                        id="lName"
                                        className={this.props.classes.textField}
                                        value={this.state.lName}
                                        margin="normal"
                                        variant="outlined"
                                        helperText={this.getHelpText("lastName")}
                                        error={this.state.validLastname === false}
                                        onChange={(event) => this.inputChangeHandler(event, "lName")}
                                        FormHelperTextProps={{
                                            classes: {
                                                contained: this.props.classes.containedHelperText
                                            }
                                        }}
                                        InputProps={{
                                            classes: {
                                                root: this.props.classes.outlinedInput
                                            }
                                        }}
                                    />
                                </div>

                                <div key={"Phone Number"} className={this.props.classes.elementContainer}>
                                    <div className={this.props.classes.inputLabel}>{t("Phone Number")}{this.isRequiredField("phoneNumber")}</div>
                                    <TextField
                                        id="phoneNumber"
                                        className={this.props.classes.textField}
                                        name="phoneNumber"
                                        value={this.state.phoneNumber}
                                        margin="normal"
                                        variant="outlined"
                                        helperText={this.getHelpText("phoneNumber")}
                                        error={this.state.validPhoneNumber === false}
                                        onChange={(event) => this.inputChangeHandler(event, "phoneNumber")}
                                        FormHelperTextProps={{
                                            classes: {
                                                contained: this.props.classes.containedHelperText
                                            }
                                        }}
                                        InputProps={{
                                            classes: {
                                                root: this.props.classes.outlinedInput
                                            }
                                        }}
                                    />
                                </div>
                            </DialogContent>
                            <DialogActions classes={{ root: this.props.classes.dialogActions }}>
                                <Button
                                    variant="contained"
                                    color="primary"
                                    disabled={this.disableSaveButton()}
                                    onClick={this.updateUser}
                                    className={this.props.classes.saveButton}>

                                    {t("Save Changes")}
                                </Button>
                            </DialogActions>
                        </Dialog>
                    </div>
                    <Grid container className={this.props.classes.divContainer} >
                        <div >
                            {/* <Tooltip title={<span style={{ fontSize: "15px" }}>{this.props.userData.email}</span>}>
                            <IconButton className={this.props.classes.icon} onClick={()=>this.toggleDrawer(true)}>
                                <AccountCircleIcon style={{ fontSize: 22 }}/>
                            </IconButton>
                        </Tooltip> */}
                            {/* <IconButton className={this.props.classes.icon} onClick={this.handleLogout}>
                                <ExitToApp style={{ fontSize: 22 }} />
                            </IconButton>
                            <Typography variant="subtitle1" color="inherit" style={{ padding: 12 }}>
                                {this.props.userData.email}
                            </Typography>
                            <IconButton className={this.props.classes.icon} onClick={this.openDialog}>
                                <Settings />
                            </IconButton> */}
                            {/* {
                                this.props.languages.length > 0 ?
                                    <LanguageDropdown/>
                                    //     history={this.props.history}
                                    //     languages={this.props.languages}
                                    //     preferedLanguage={this.props.userData.language}
                                    //     userData={this.props.userData}
                                    // />
                                     : null
                            } */}
                        </div>
                        <div className={this.props.classes.headerLeftActions}>
                            <IconButton className={this.props.classes.icon} onClick={this.openDrawer}>
                                <MenuIcon style={{ fontSize: 22 }} />
                            </IconButton>
                            {/* <img alt="IU logo" src={`${server_url}/getLogo/${this.props.userOrganization}/${new Date()}?access_token=${getOidcAccessToken()}`} className={this.props.classes.logoClass} /> */}
                            <img alt="IU logo" src={logoImg} className={this.props.classes.logoClass} />
                            {this.getTabs()}
                            <LanguageDropdown className={this.props.classes.icon} />
                             {this.props &&
                              this.props.appsNameReducer &&
                                this.props.appsNameReducer.length !== 0 && (
                                    <SubscribeApps plantOSbuttonText={`PLANTOS ${t("app suite home").toUpperCase()}`} plantOsUrl={config.url.plant_os_url} apps={this.props.appsNameReducer.map((item) => {return {...item , name : t(item.name) }})} />
                             )}
                            <Tooltip title={<span style={{ fontSize: "13px", marginRight: "15px", wordBreak: "break-all" }}>{this.props.userData.email}</span>}>
                                <IconButton className={this.props.classes.icon} onClick={() => this.toggleDrawer(true)}>
                                    <AccountCircleIcon style={{ fontSize: 22 }} />
                                </IconButton>
                            </Tooltip>
                        </div>
                    </Grid>
                </AppBar>
                <Drawer anchor="right" open={this.state.open} classes={{ paper: this.props.classes.paper1 }} >
                    <div id="btnEmail" style={{ display: 'flex', background: '#ffc107', width: '100%' }}>
                        <Typography id="emailAddress" value={this.props && this.props.userData && this.props.userData.email} variant="subtitle1" color="inherit" style={{ padding: 12, width: '90%' }}>
                            {this.props.userData.email}
                        </Typography>
                        {/* <span className="float-right" onClick={(e) => this.toggleDrawer(false)}>x</span>  style={{ width: '13%' }}*/}
                        <IconButton id="closeDrawer" className={this.props.classes.icon} onClick={(e) => this.toggleDrawer(false)}>
                            <Close style={{ fontSize: 22 }} />
                        </IconButton>
                    </div>
                    <div id="btnLogout" style={{ display: 'flex', cursor: 'pointer' }} onClick={() => this.handleLogout()}>
                        <IconButton className={this.props.classes.icon} onClick={() => this.toggleDrawer(true)}>
                            <ArrowForwardIcon style={{ fontSize: 22 }} />
                        </IconButton>
                        <Typography variant="subtitle1" color="inherit" style={{ padding: 12 }}>
                            {t("Logout")}
                        </Typography>
                    </div>
                    <Divider style={{ backgroundColor: '#e0e0e0', marginTop: 5 }} />
                    <div id="btnSetting" style={{ display: 'flex', cursor: 'pointer' }} onClick={() => this.openDialog()}>
                        <IconButton className={this.props.classes.icon}>
                            <Settings />
                        </IconButton>
                        <Typography variant="subtitle1" color="inherit" style={{ padding: 12 }}>
                            {t("Settings")}
                        </Typography>
                    </div>
                    <Divider style={{ backgroundColor: '#e0e0e0', marginTop: 5 }} />
                    <div id="btnSupport" style={{ display: 'flex', cursor: 'pointer' }} onClick={() => this.openSupportDialog()}>
                        <IconButton className={this.props.classes.icon}>
                            <ContactSupportIcon />
                        </IconButton>
                        <Typography variant="subtitle1" color="inherit" style={{ padding: 12 }}>
                            {t("Support")}
                        </Typography>
                    </div>
                    <Divider style={{ backgroundColor: '#e0e0e0', marginTop: 5 }} />
                    <div id="btnDocumentation" style={{ display: 'flex', cursor: 'pointer' }} onClick={() => this.openDocumentDialog()}>
                        <IconButton className={this.props.classes.icon}>
                            <FileCopyOutlinedIcon />
                        </IconButton>
                        <Typography variant="subtitle1" color="inherit" style={{ padding: 12 }}>
                            {t("Documentation")}
                        </Typography>
                    </div>
                    {SHOW_PLATFORM_UPTIME && <><Divider style={{ backgroundColor: '#e0e0e0', marginTop: 5 }} />
                        <div id="btnPlatform" style={{ display: 'flex', cursor: 'pointer' }} onClick={() => this.openPlatformUptime()}>
                            <IconButton className={this.props.classes.icon}>
                                <AccessTimeOutlinedIcon />
                            </IconButton>
                            <Typography variant="subtitle1" color="inherit" style={{ padding: 12 }}>
                                {t("Platform Uptime")}
                            </Typography>
                        </div>
                    </>
                    }
                    <Divider style={{ backgroundColor: '#e0e0e0', marginTop: 5 }} />
                    <div id="btnDeviceUptime" style={{ display: 'flex', cursor: 'pointer' }} onClick={() => this.openDeviceUptime()}>
                        <IconButton className={this.props.classes.icon}>
                            <AddCircleOutlineRoundedIcon />
                        </IconButton>
                        <Typography variant="subtitle1" color="inherit" style={{ padding: 12 }}>
                            {t("Device Uptime")}
                        </Typography>
                    </div>
                </Drawer>
                <Dialog
                    open={this.state.openSupportDialog}
                    onClose={this.handleClose}
                    // className={this.props.classes.dialogbox}
                    maxWidth={'md'}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <DialogContent className={this.props.classes.content}>
                        {/* <DialogContentText id="alert-dialog-description"> */}
                        {t("For technical support, please get in touch with us via e-mail at") + " " + support_email}
                        {/* </DialogContentText> */}
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={(event) => this.handleClose()} color="primary" autoFocus>
                            {t("OK")}
                        </Button>
                    </DialogActions>
                </Dialog>
                <Dialog
                    open={this.state.openDocumentDialog}
                    onClose={this.handleDocumentClose}
                    classes={{ paperWidthSm: this.props.classes.documentModal }}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <DialogContent className={this.props.classes.content}>
                        <div>
                            <MuiThemeProvider theme={this.getMuiTheme()}>
                                {
                                    this.state.data !== null ? (
                                        <MUIDataTable
                                            title={t("Documentation")}
                                            data={this.state.data}
                                            columns={this.state.columns}
                                            options={options}
                                        />
                                    ) : <Fragment></Fragment>
                                }
                            </MuiThemeProvider>
                        </div>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={(event) => this.handleDocumentClose()} color="primary" autoFocus>
                            {this.props.t("Cancel")}
                        </Button>
                    </DialogActions>
                </Dialog>

                <Dialog
                    onClose={this.closeDialog}
                    aria-labelledby="customized-dialog-title"
                    open={this.state.openPlatformUptime}
                    classes={{ paperWidthSm: this.props.classes.platformModal }}
                >
                    <div className={this.props.classes.dialogTitle}>
                        <Typography variant="h6"></Typography>
                        {/* <IconButton aria-label="Close" > */}
                        <Close onClick={this.closeDialog} style={{ cursor: 'pointer' }} />
                        {/* </IconButton> */}
                    </div>
                    {/* <Divider /> */}
                    <DialogContent classes={{ root: this.props.classes.dialogContent }}>
                        <div className={this.props.classes.headingP}> {this.props.t("IDAP PLATFORM UPTIME")} </div>
                        <div className={this.props.classes.dateP}> {this.props.t("(in the last 30 days)")}</div>
                        <div className={this.props.classes.headingP} style={{ marginBottom: "15px" }}>
                            {this.props && this.props.platformUptimeReducer && this.props.platformUptimeReducer.uptime}
                            % </div>
                        {/* <div className={this.props.classes.dateP}>As of 29 Nov 2021 , 1:48 PM</div> */}
                        <div className={this.props.classes.border}>
                            <div className={this.props.classes.statusPlatform}>
                                <div style={{ fontSize: this.props.i18n.language === "en" ? "1rem" : "12px" }}>{this.props.t("Status  of IDAP Platform")}</div>
                                <div style={{ marginTop: "2px", fontSize: "12px" }}><span><Brightness1Icon style={{ fontSize: this.props.i18n.language === "en" ? "18" : "14", marginLeft: "2px", color: "green" }} /> {this.props.t("Uptime 100%")} </span><span><Brightness1Icon style={{ fontSize: this.props.i18n.language === "en" ? "18" : "14", marginLeft: "2px", color: "yellow" }} /> {this.props.t("Uptime 99% - 100%")} </span><span> <Brightness1Icon style={{ fontSize: this.props.i18n.language === "en" ? "18" : "14", marginLeft: "2px", color: "red" }} /> {this.props.t("Uptime less than 99%")}</span> </div>
                            </div>
                            <div className={this.props.classes.greenStripDiv}>
                                {this.props && this.props.platformUptimeReducer && this.props.platformUptimeReducer.daywiseUptime && this.props.platformUptimeReducer.daywiseUptime.map((item, key) => {
                                    if (item.uptime < 99) {
                                        return <> <span key={key} className={this.props.classes.greenStrip} style={{ background: "red", marginLeft: "-3px" }}></span><span key={key} className={this.props.classes.greenStrip} style={{ background: "red" }}></span><span key={key} className={this.props.classes.greenStrip} style={{ background: "red" }}></span></>
                                    }
                                    else if (item.uptime >= 99 && item.uptime !== 100) {
                                        return <> <span key={key} className={this.props.classes.greenStrip} style={{ background: "yellow", marginLeft: "-3px" }}></span><span key={key} className={this.props.classes.greenStrip} style={{ background: "yellow" }}></span><span key={key} className={this.props.classes.greenStrip} style={{ background: "yellow" }}></span></>
                                    } else {
                                        return <> <span key={key} className={this.props.classes.greenStrip} style={{ marginLeft: "-3px" }}></span><span key={key} className={this.props.classes.greenStrip}></span><span key={key} className={this.props.classes.greenStrip}></span></>
                                    }
                                })}
                            </div>
                            <div className={this.props.classes.dateDiv}>
                                <span>{moment(this.props && this.props.platformUptimeReducer && this.props.platformUptimeReducer.daywiseUptime && this.props.platformUptimeReducer.daywiseUptime[0].date).format("DD-MMM-YYYY")}</span>
                                <span style={{ float: "right" }}>{moment(this.props && this.props.platformUptimeReducer && this.props.platformUptimeReducer.daywiseUptime && this.props.platformUptimeReducer.daywiseUptime[29].date).format("DD-MMM-YYYY")}</span>
                            </div>
                        </div>
                    </DialogContent>
                    <DialogActions classes={{ root: this.props.classes.dialogActions }}>

                    </DialogActions>
                </Dialog>
                <Dialog
                    onClose={this.closeDialog}
                    aria-labelledby="customized-dialog-title"
                    open={this.state.openDevUptime}
                    classes={{ paperWidthSm: this.props.classes.platformModal }}
                >
                    <div className={this.props.classes.dialogTitle}>
                        <Typography variant="h6"></Typography>
                        {/* <IconButton aria-label="Close" > */}
                        <Close onClick={this.closeDialog} style={{ cursor: 'pointer' }} />
                        {/* </IconButton> */}
                    </div>
                    {/* <Divider /> */}
                    <DialogContent classes={{ root: this.props.classes.dialogContent }}>
                        <div className={this.props.classes.headingP}> {this.props.t("Device Overall Uptime")} </div>
                        <div>
                            <label>{t("Start Date")}: </label>
                            <DatePicker
                                selected={startDate}
                                value={startDate}
                                onChange={this.handleStartDateChange}
                                maxDate={endDate}
                                clearIcon={null}
                                className={this.props.classes.datePicker}
                                format='dd/MM/y'
                             />

                            <label>{t("End Date")}: </label>
                            <DatePicker
                                selected={endDate}
                                value={endDate}
                                onChange={this.handleEndDateChange}
                                minDate={startDate}
                                maxDate={new Date()}
                                clearIcon={null}
                                className={this.props.classes.datePicker}
                                format='dd/MM/y'
                            />
                            <Button variant="contained" style={{ color: "white", background:"#2196F3" }} onClick={()=> this.getApiCallForUsingUaerId(this.props.userData.id,startDate.toISOString(), endDate.toISOString())}>{this.props.t("GET")}</Button>
                        </div>
                        {this.state && this.state.deviceUptimeArray && this.state.deviceUptimeArray.map((plant, index) =>
                            <div className="" key={index}>
                                <div><span onClick={() => this.openDetailView(plant)} style={{ color: "#6fa0d8", cursor: "pointer" }}>{this.props.t("Plant")}: {plant.plantName}</span><span onClick={() => this.openDetailView(plant)} className={this.props.classes.devicePercentage}>{(plant.overAllUptime).toFixed(2) +"%"}</span></div>
                            </div>
                        )}
                    </DialogContent>

                </Dialog>
                <Dialog
                    onClose={this.closeDialogDetail}
                    aria-labelledby="customized-dialog-title"
                    open={this.state.openDetailFlag}
                    classes={{ paperWidthSm: this.props.classes.deviceModal }}
                >
                    <div className={this.props.classes.dialogTitle}>
                        <ArrowBackIcon onClick={this.goToDeviceUptimeDialog} style={{ cursor: 'pointer'}}/>
                        {/* <IconButton aria-label="Close" > */}
                        <Close onClick={this.closeDialogDetail} style={{ cursor: 'pointer' }} />
                        {/* </IconButton> */}
                    </div>
                    {/* <Divider /> */}
                    <DialogContent classes={{ root: this.props.classes.dialogContent }}>
                        {this.props.loaderState && this.props.loaderState.load && this.props.loaderState.load.value===false && <div><span style={{ marginRight: "50px", float: "right" }}><div style={{ fontSize: "12px", padding: '3px', cursor: this.state && this.state.dataDevice && this.state.dataDevice.length !== 0 && 'pointer', pointerEvents: this.state && this.state.dataDevice && this.state.dataDevice.length === 0 && 'none', color: this.state && this.state.dataDevice && this.state.dataDevice.length === 0 ? 'grey' : '#6fa0d8', fontWeight: "bold" }} id="csvdownloadFilterdDevice" disabled={this.state && this.state.dataDevice && this.state.dataDevice.length === 0} onClick={() => this.state && this.state.dataDevice && this.state.dataDevice.length !== 0 && this.downloadPlantData()}>{this.props.t("Download")}</div></span></div>}

                        <div className="">
                            <MuiThemeProvider theme={this.getMuiTheme()}>
                                {
                                    this.state.dataDevice !== null ? (
                                        <MUIDataTable
                                            title={this.state.plantNameForDetailPage}
                                            data={this.state.dataDevice}
                                            columns={this.state.columnsDevice}
                                            options={options}
                                        />
                                    ) : <Fragment></Fragment>
                                }
                            </MuiThemeProvider>
                            {/* {this.props.loaderState && this.props.loaderState.load && this.props.loaderState.load.value===false && <div style={{fontSize:'14px',textAlign:"center",marginBottom:'15px'}}>{this.props.t("noteMsgForMonitor")}</div>} */}
                        </div>
                    </DialogContent>

                </Dialog>
            </div >
        );
    }
}

const mapSatateToProps = state => {
    return {
        userData: state.userDataReducer,
        documentationData: state.documentationDataReducer,
        appsNameReducer: state.appsNameReducer,
        platformUptimeReducer: state.platformUptimeReducer,
        deviceUptimeReducer: state.deviceUptimeReducer,
        loaderState: state.loaderReducer,
    }
}

const mapDispatchToProps = dispatch => {
    return {
        logout: () => (dispatch(actionCreators.logout())),
        updateUser: (updatedUserData, selectedNavTab, currentScopeSelectionId) => (dispatch(actionCreators.updateLoggedInUser(updatedUserData, selectedNavTab, currentScopeSelectionId))),
        // updateDrawer : ()=> (dispatch(actionCreators.updateDrawer(true))),
        updateSelectedTab: (selectedTab) => (dispatch(actionCreators.updateSelectedTab(selectedTab))),
        updateDrawer: (value) => (dispatch(actionCreators.updateDrawer(value))),
        getDocumentationData: () => (dispatch(actionCreators.getDocumentationData())),
        updatedashboardType: (type) => (dispatch(actionCreators.updatedashboardType(type))),
        isUserAuthenticated: () => (dispatch(actionCreators.isUserAuthenticated())),
        updateScopeSelector: (selection, tab, permission, scope, plantName) => (dispatch(actionCreators.updateScopeSelector(selection, tab, permission, scope, plantName))),
        getPlatformUtimeLast30DaysData: () => (dispatch(actionCreators.getPlatformUtimeLast30DaysData())),
        setLoaderState: (value) => (dispatch(actionCreators.setLoaderState(value))),


    }
}

export default withTranslation()(connect(mapSatateToProps, mapDispatchToProps)(withStyles(styles)(Header)));
