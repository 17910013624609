import React, { Component } from 'react'
import { withTranslation } from "react-i18next";

class CycleTimeComparison extends Component {
    constructor(props){
        super(props);
    }  
    
    render() {
        const {t}=this.props;
        return (
            <div className="CycleTimeComparison-wrp display-text"> 
                <div className="list-selection-wrp display-text" style={{ width: '100%'}}>
                    { this.props.machineList.length !== 0 ?
                        <select className="list-selection form-control" onChange={(e) => this.props.onMachineChangeForCycletime(e)}>            
                            { this.props.machineList.map( (list, index) =>
                                <option value={ list.machineId } key={ index }>{list.machineName}</option>
                            )}
                        </select>
                    : null }    
                </div>
            </div>
        )
    }
}
  
export default  withTranslation()(CycleTimeComparison);