import React, { Component } from 'react';
import { connect } from 'react-redux';
import {
    withStyles,
    Dialog,
    DialogActions,
    Button,
    InputLabel,
    Select,
    OutlinedInput
} from '@material-ui/core';
import { withTranslation } from "react-i18next";

import * as actionCreators from '../../actions/actionCreators';

const styles = () => ({
    dialog: {
        padding: '12px 24px'
    },
    outlinedInput: {
        padding: 12
    },
    elementContainer: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        paddingTop: 24
    },
    textFeildLabel: {
        color: 'rgba(0, 0, 0, 0.4000000059604645)',
        'letter-spacing': 0.5,
        'font-weight': 500,
        fontSize: "14px",
        paddingLeft: "7px",
        lineHeight: 2.5
    },
    textField: {
        border: "1px solid #00000005",
        borderRadius: "6px",
        width: '315px',
        height: '45px',
    },
    textArea: {
        border: "1px solid #00000005",
        width: '315px',
    },
    multiline: {
        paddingTop: 7
    },
    saveButton: {
        width: 100,
        backgroundColor: '#42D784',
        '&:hover': {
            backgroundColor: '#42D784'
        }
    },
    dialogAction: {
        marginTop: 24
    }
})

class LossCodesModal extends Component {

    state = {
        lossList: "",
        description: "",
        subDescription: "",
        specs: "",
        lossLabel: "",
        lossSubLabel: "",
        lossCode: "",
        lossSubCode: "",
        changedLossLabel: false,
        changedLossSubLabel: false,
        changedLossCode: false,
        changedLossSubCode: false,
        changedLossList: false,
        changedDescription: false,
        changedSubDescription: false,
        changedSpecs: false
    }

    componentDidMount() {
        if (this.props.selectedPlant) {
            this.props.getTableData("LossListTable", this.props.selectedPlant, true);
        }
    }

    // handle updated user input values
    handleUpdate = () => {
        this.props.updateSelectedRecords(this.state);
        this.props.disableBulkEditingView();
    }

    // handle modal window close action
    handleClose = () => {
        this.props.disableBulkEditingView();
        this.setState({
            lossList: "",
            description: "",
            subDescription: "",
            specs: "",

            changedLossList: false,
            changedDescription: false,
            changedSubDescription: false,
            changedSpecs: false
        })
    }

    // handle user input changes
    onChangeHandler = (event, item) => {
        switch (item) {
            case "LossLabel":
                this.setState({
                    changedLossLabel: true,
                    lossLabel: event.target.value
                })
                break;
            case "LossSubLabel":
                this.setState({
                    changedLossSubLabel: true,
                    lossSubLabel: event.target.value
                })
                break;
            case "LossCode":
                this.setState({
                    changedLossCode: true,
                    lossCode: event.target.value
                })
                break;
            case "LossSubCode":
                this.setState({
                    changedLossSubCode: true,
                    lossSubCode: event.target.value
                })
                break;
            case "LossList":
                this.setState({
                    changedLossList: true,
                    lossList: event.target.value
                })
                break;
            case "Description":
                this.setState({
                    changedDescription: true,
                    description: event.target.value
                })
                break;
            case "SubDescription":
                this.setState({
                    changedSubDescription: true,
                    subDescription: event.target.value
                })
                break;
            case "Specs":
                this.setState({
                    changedSpecs: true,
                    specs: event.target.value
                })
                break;
            default: console.log("default case");
        }
    }

    // create job-lists dropdown list
    getLossListName = () => {
        let lossList = [];
        lossList.push(<option key={""} disabled={true} value={""}>{"Select"}</option>);
        if ((this.props.config.lossListsName.validations && !this.props.config.lossListsName.validations.required) || !(this.props.config.lossListsName.validations)) {
            lossList.push(<option key={""} value={" "}>{"None"}</option>);
        }
        if (this.props.lossListData && this.props.lossListData.tableData && this.props.lossListData.tableData.length > 0) {
            this.props.lossListData.tableData.map((data) => {
                lossList.push(
                    <option key={data.id} value={data.id}>{data.name}</option>
                );
            })
        }
        return lossList;
    }

    render() {
        const {t}=this.props;
        return (
            <Dialog
                open={this.props.enableBulkEditingView}
                onClose={this.handleClose}
                aria-labelledby="form-dialog-title"
                maxWidth="sm"
                fullWidth={true}
                classes={{ paper: this.props.classes.dialog }}
                disableBackdropClick={true}
            >
                {
                    this.props.config.lossLabel.page_visible ?
                        (
                            <div className={this.props.classes.elementContainer}>
                                <InputLabel className={this.props.classes.textFeildLabel} style={{ alignSelf: 'flex-start' }}>
                                    {this.props.config.lossLabel.display_name}
                                </InputLabel>
                                <OutlinedInput
                                    value={this.state.lossLabel}
                                    onChange={(event) => this.onChangeHandler(event, "LossLabel")}
                                    className={this.props.classes.textField}
                                    labelWidth={0}
                                />
                            </div>
                        )
                        : null
                }
                {
                    this.props.config.lossSublabel.page_visible ?
                        (
                            <div className={this.props.classes.elementContainer}>
                                <InputLabel className={this.props.classes.textFeildLabel} style={{ alignSelf: 'flex-start' }}>
                                    {this.props.config.lossSublabel.display_name}
                                </InputLabel>
                                <OutlinedInput
                                    value={this.state.lossSubLabel}
                                    onChange={(event) => this.onChangeHandler(event, "LossSubLabel")}
                                    className={this.props.classes.textField}
                                    labelWidth={0}
                                />
                            </div>
                        )
                        : null
                }
                {
                    this.props.config.lossCode.page_visible ?
                        (
                            <div className={this.props.classes.elementContainer}>
                                <InputLabel className={this.props.classes.textFeildLabel} style={{ alignSelf: 'flex-start' }}>
                                    {this.props.config.lossCode.display_name}
                                </InputLabel>
                                <OutlinedInput
                                    value={this.state.lossCode}
                                    onChange={(event) => this.onChangeHandler(event, "LossCode")}
                                    className={this.props.classes.textField}
                                    labelWidth={0}
                                />
                            </div>
                        )
                        : null
                }
                {
                    this.props.config.lossSubcode.page_visible ?
                        (
                            <div className={this.props.classes.elementContainer}>
                                <InputLabel className={this.props.classes.textFeildLabel} style={{ alignSelf: 'flex-start' }}>
                                    {this.props.config.lossSubcode.display_name}
                                </InputLabel>
                                <OutlinedInput
                                    value={this.state.lossSubCode}
                                    onChange={(event) => this.onChangeHandler(event, "LossSubCode")}
                                    className={this.props.classes.textField}
                                    labelWidth={0}
                                />
                            </div>
                        )
                        : null
                }
                {
                    this.props.config.description.page_visible ?
                        (
                            <div className={this.props.classes.elementContainer}>
                                <InputLabel className={this.props.classes.textFeildLabel} style={{ alignSelf: 'flex-start' }}>
                                    {this.props.config.description.display_name}
                                </InputLabel>
                                <OutlinedInput
                                    value={this.state.description}
                                    onChange={(event) => this.onChangeHandler(event, "Description")}
                                    className={this.props.classes.textField}
                                    labelWidth={0}
                                />
                            </div>
                        )
                        : null
                }
                {
                    this.props.config.subDescription.page_visible ?
                        (
                            <div className={this.props.classes.elementContainer}>
                                <InputLabel className={this.props.classes.textFeildLabel} style={{ alignSelf: 'flex-start' }}>
                                    {this.props.config.subDescription.display_name}
                                </InputLabel>
                                <OutlinedInput
                                    value={this.state.subDescription}
                                    onChange={(event) => this.onChangeHandler(event, "SubDescription")}
                                    className={this.props.classes.textField}
                                    labelWidth={0}
                                />
                            </div>
                        )
                        : null
                }
                {
                    this.props.config.lossListsName.page_visible ?
                        (
                            <div className={this.props.classes.elementContainer}>
                                <InputLabel className={this.props.classes.textFeildLabel}>
                                    {this.props.config.lossListsName.display_name}
                                </InputLabel>
                                <Select
                                    native
                                    value={this.state.lossList}
                                    onChange={(event) => this.onChangeHandler(event, "LossList")}
                                    input={
                                        <OutlinedInput
                                            variant="outlined"
                                            name="lossListId"
                                            id="language-native-simple"
                                            labelWidth={0}
                                            className={this.props.classes.textField}
                                            classes={{ input: this.props.classes.outlinedInput }}
                                        />
                                    }
                                >
                                    {this.getLossListName()}
                                </Select>
                            </div>
                        )
                        : null
                }
                {
                    this.props.config.specs.page_visible ?
                        (
                            <div className={this.props.classes.elementContainer}>
                                <InputLabel className={this.props.classes.textFeildLabel} style={{ alignSelf: 'flex-start' }}>
                                    {this.props.config.specs.display_name}
                                </InputLabel>
                                <OutlinedInput
                                    multiline
                                    rows="5"
                                    value={this.state.specs}
                                    onChange={(event) => this.onChangeHandler(event, "Specs")}
                                    className={this.props.classes.textArea}
                                    classes={{ multiline: this.props.classes.multiline }}
                                    labelWidth={0}
                                />
                            </div>
                        )
                        : null
                }
                <DialogActions classes={{ root: this.props.classes.dialogAction }}>
                    <Button onClick={this.handleClose} color="primary">
                        {t("Cancel")}
                    </Button>
                    <Button
                        variant="contained"
                        color="primary"
                        disabled={!(this.state.changedLossList || this.state.changedDescription || this.state.changedSubDescription || this.state.changedSpecs || this.state.changedLossLabel || this.state.changedLossSubLabel || this.state.changedLossCode || this.state.changedLossSubCode)}
                        className={this.props.classes.saveButton}
                        onClick={this.handleUpdate}
                    >
                        {t("Save")}
                        </Button>
                </DialogActions>
            </Dialog>
        )
    }
}

const mapStateToProps = state => {
    return {
        lossListData: state.lossListDataReducer,
    }
}

const mapDispatchToProps = dispatch => {
    return {
        getTableData: (selectedNavtab, currentScopeSelectionId, setToStore) => (dispatch(actionCreators.getTableData(selectedNavtab, currentScopeSelectionId, setToStore))),
    }
}

export default withTranslation()(connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(LossCodesModal)));