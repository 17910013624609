import React, { Component } from 'react';
import { connect } from 'react-redux';
import {
    withStyles,
    Dialog,
    DialogActions,
    Button,
    InputLabel,
    Select,
    OutlinedInput
} from '@material-ui/core';
import { withTranslation } from "react-i18next";

import * as actionCreators from '../../actions/actionCreators';

const styles = () => ({
    dialog: {
        padding: '12px 24px'
    },
    outlinedInput: {
        padding: 12
    },
    elementContainer: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        paddingTop: 24
    },
    textFeildLabel: {
        color: 'rgba(0, 0, 0, 0.4000000059604645)',
        'letter-spacing': 0.5,
        'font-weight': 500,
        fontSize: "14px",
        paddingLeft: "7px",
        lineHeight: 2.5
    },
    textField: {
        border: "1px solid #00000005",
        borderRadius: "6px",
        width: '315px',
        height: '45px',
    },
    textArea: {
        border: "1px solid #00000005",
        width: '315px',
    },
    multiline: {
        paddingTop: 7
    },
    saveButton: {
        width: 100,
        backgroundColor: '#42D784',
        '&:hover': {
            backgroundColor: '#42D784'
        }
    },
    dialogAction: {
        marginTop: 24
    }
})

class MonitorsModal extends Component {

    state = {
        monitorType: "",
        machine: "",
        macAddress: "",
        notes: "",
        specs: "",
        changedMacAddress: false,
        changedMonitorType: false,
        changedMachine: false,
        changedNotes: false,
        changedSpecs: false
    }

    componentDidMount() {
        if (this.props.selectedPlant) {
            this.props.getTableData("MachinesTable", this.props.selectedPlant, true);
            this.props.getTableData("DevicesTable", this.props.selectedPlant, true);
        }
    }

    // handle updated user input values
    handleUpdate = () => {
        this.props.updateSelectedRecords(this.state);
        this.props.disableBulkEditingView();
    }

    // handle modal window close action
    handleClose = () => {
        this.props.disableBulkEditingView();
        this.setState({
            monitorType: "",
            machine: "",
            macAddress: "",
            notes: "",
            specs: "",
            changedMacAddress: false,
            changedMonitorType: false,
            changedMachine: false,
            changedNotes: false,
            changedSpecs: false
        })
    }

    // handle user input changes
    onChangeHandler = (event, item) => {
        switch (item) {
            case "MonitorType":
                this.setState({
                    changedMonitorType: true,
                    monitorType: event.target.value
                })
                break;
            case "Machine":
                this.setState({
                    changedMachine: true,
                    machine: event.target.value
                })
                break;
            case "DeviceMacAddress":
                this.setState({
                    changedMacAddress: true,
                    macAddress: event.target.value
                })
                break;
            case "Notes":
                this.setState({
                    changedNotes: true,
                    notes: event.target.value
                })
                break;
            case "Specs":
                this.setState({
                    changedSpecs: true,
                    specs: event.target.value
                })
                break;
            default: console.log("default case");
        }
    }

    // create monitor-types dropdown list
    getMonitorType = () => {
        let monitorTypeList = [];
        monitorTypeList.push(<option key={""} disabled={true} value={""}>{"Select"}</option>);
        if ((this.props.config.monitorTypeName.validations && !this.props.config.monitorTypeName.validations.required) || !(this.props.config.monitorTypeName.validations)) {
            monitorTypeList.push(<option key={""} value={" "}>{"None"}</option>);
        }
        if (this.props.parentOrganizationId && this.props.staticData && this.props.staticData.staticData) {
            let plantOrganizationStaticData = this.props.staticData.staticData[this.props.parentOrganizationId]
            if (plantOrganizationStaticData && plantOrganizationStaticData.monitortypes) {
                plantOrganizationStaticData.monitortypes.map((data) => {
                    monitorTypeList.push(
                        <option key={data.id} value={data.id}>{data.name}</option>
                    );
                })
            }
        }
        return monitorTypeList;
    }

    // create machines dropdown list
    getMachinesName = () => {
        let machinesList = [];
        machinesList.push(<option key={""} disabled={true} value={""}>{"Select"}</option>);
        if ((this.props.config.machineName.validations && !this.props.config.machineName.validations.required) || !(this.props.config.machineName.validations)) {
            machinesList.push(<option key={""} value={" "}>{"None"}</option>);
        }
        if (this.props.machinesData && this.props.machinesData.tableData && this.props.machinesData.tableData.length > 0) {
            this.props.machinesData.tableData.map((data) => {
                machinesList.push(
                    <option key={data.id} value={data.id}>{data.label}</option>
                );
            })
        }
        return machinesList;
    }

    // create devices dropdown list
    getDeviceMacAddress = () => {
        let deviceList = [];
        deviceList.push(<option key={""} disabled={true} value={""}>{"Select"}</option>);
        if ((this.props.config.deviceMacAddress.validations && !this.props.config.deviceMacAddress.validations.required) || !(this.props.config.deviceMacAddress.validations)) {
            deviceList.push(<option key={""} value={" "}>{"None"}</option>);
        }
        if (this.props.devicesData && this.props.devicesData.tableData && this.props.devicesData.tableData.length > 0) {
            this.props.devicesData.tableData.map((data) => {
                deviceList.push(
                    <option key={data.macAddress} value={data.macAddress}>{data.macAddress}</option>
                );
            })
        }
        return deviceList;
    }

    render() {
        const {t}=this.props;
        return (
            <Dialog
                open={this.props.enableBulkEditingView}
                onClose={this.handleClose}
                aria-labelledby="form-dialog-title"
                maxWidth="sm"
                fullWidth={true}
                classes={{ paper: this.props.classes.dialog }}
                disableBackdropClick={true}
            >
                {
                    this.props.config.monitorTypeName.page_visible ?
                        (
                            <div className={this.props.classes.elementContainer}>
                                <InputLabel className={this.props.classes.textFeildLabel}>
                                    {this.props.config.monitorTypeName.display_name}
                                </InputLabel>
                                <Select
                                    native
                                    value={this.state.monitorType}
                                    onChange={(event) => this.onChangeHandler(event, "MonitorType")}
                                    input={
                                        <OutlinedInput
                                            variant="outlined"
                                            name="monitorType"
                                            id="language-native-simple"
                                            labelWidth={0}
                                            className={this.props.classes.textField}
                                            classes={{ input: this.props.classes.outlinedInput }}
                                        />
                                    }
                                >
                                    {this.getMonitorType()}
                                </Select>
                            </div>
                        )
                        : null
                }
                {
                    this.props.config.machineName.page_visible ?
                        (
                            <div className={this.props.classes.elementContainer}>
                                <InputLabel className={this.props.classes.textFeildLabel}>
                                    {this.props.config.machineName.display_name}
                                </InputLabel>
                                <Select
                                    native
                                    value={this.state.machine}
                                    onChange={(event) => this.onChangeHandler(event, "Machine")}
                                    input={
                                        <OutlinedInput
                                            variant="outlined"
                                            name="machine"
                                            id="language-native-simple"
                                            labelWidth={0}
                                            className={this.props.classes.textField}
                                            classes={{ input: this.props.classes.outlinedInput }}
                                        />
                                    }
                                >
                                    {this.getMachinesName()}
                                </Select>
                            </div>
                        )
                        : null
                }
                {/* {
                    this.props.config.deviceMacAddress.page_visible ?
                        (
                            <div className={this.props.classes.elementContainer}>
                                <InputLabel className={this.props.classes.textFeildLabel}>
                                    {this.props.config.deviceMacAddress.display_name}
                                </InputLabel>
                                <Select
                                    native
                                    value={this.state.macAddress}
                                    onChange={(event) => this.onChangeHandler(event, "DeviceMacAddress")}
                                    input={
                                        <OutlinedInput
                                            variant="outlined"
                                            name="macAddress"
                                            id="language-native-simple"
                                            labelWidth={0}
                                            className={this.props.classes.textField}
                                            classes={{ input: this.props.classes.outlinedInput }}
                                        />
                                    }
                                >
                                    {this.getDeviceMacAddress()}
                                </Select>
                            </div>
                        )
                        : null
                } */}
                {
                    this.props.config.notes.page_visible ?
                        (
                            <div className={this.props.classes.elementContainer}>
                                <InputLabel className={this.props.classes.textFeildLabel} style={{ alignSelf: 'flex-start' }}>
                                    {this.props.config.notes.display_name}
                                </InputLabel>
                                <OutlinedInput
                                    multiline
                                    rows="5"
                                    value={this.state.notes}
                                    onChange={(event) => this.onChangeHandler(event, "Notes")}
                                    className={this.props.classes.textArea}
                                    classes={{ multiline: this.props.classes.multiline }}
                                    labelWidth={0}
                                />
                            </div>
                        )
                        : null
                }
                {
                    this.props.config.specs.page_visible ?
                        (
                            <div className={this.props.classes.elementContainer}>
                                <InputLabel className={this.props.classes.textFeildLabel} style={{ alignSelf: 'flex-start' }}>
                                    {this.props.config.specs.display_name}
                                </InputLabel>
                                <OutlinedInput
                                    multiline
                                    rows="5"
                                    value={this.state.specs}
                                    onChange={(event) => this.onChangeHandler(event, "Specs")}
                                    className={this.props.classes.textArea}
                                    classes={{ multiline: this.props.classes.multiline }}
                                    labelWidth={0}
                                />
                            </div>
                        )
                        : null
                }
                <DialogActions classes={{ root: this.props.classes.dialogAction }}>
                    <Button onClick={this.handleClose} color="primary">
                        {t("Cancel")}
                    </Button>
                    <Button
                        variant="contained"
                        color="primary"
                        disabled={!(this.state.changedMacAddress || this.state.changedMonitorType || this.state.changedMachine || this.state.changedSpecs || this.state.changedNotes)}
                        className={this.props.classes.saveButton}
                        onClick={this.handleUpdate}
                    >
                        {t("Save")}
                        </Button>
                </DialogActions>
            </Dialog>
        )
    }
}

const mapStateToProps = state => {
    return {
        machinesData: state.machinesDataReducer,
        devicesData: state.deviceDataReducer,
    }
}

const mapDispatchToProps = dispatch => {
    return {
        getTableData: (selectedNavtab, currentScopeSelectionId, setToStore) => (dispatch(actionCreators.getTableData(selectedNavtab, currentScopeSelectionId, setToStore))),
    }
}

export default withTranslation()(connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(MonitorsModal)));