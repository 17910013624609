import React, { Component } from 'react';
import Timeline from 'react-visjs-timeline';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import { withStyles, Link, Checkbox } from '@material-ui/core';
import RefreshIcon from '@material-ui/icons/Refresh';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle'
import { Close } from '@material-ui/icons';
import { ThumbUp, ThumbDown, ChevronLeft, ChevronRight } from '@material-ui/icons'
import { connect } from 'react-redux';
import * as actionCreators from '../../../actions/actionCreators';
import moment from "moment";
import { withTranslation } from "react-i18next";

const imgsrc = "data:image/svg+xml;base64, PHN2ZyBhcmlhLWhpZGRlbj0idHJ1ZSIgZm9jdXNhYmxlPSJmYWxzZSIgZGF0YS1wcmVmaXg9ImZhcyIgZGF0YS1pY29uPSJ0b29scyIgY2xhc3M9InN2Zy1pbmxpbmUtLWZhIGZhLXRvb2xzIGZhLXctMTYiIHJvbGU9ImltZyIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB2aWV3Qm94PSIwIDAgNTEyIDUxMiI+PHBhdGggZmlsbD0iY3VycmVudENvbG9yIiBkPSJNNTAxLjEgMzk1LjdMMzg0IDI3OC42Yy0yMy4xLTIzLjEtNTcuNi0yNy42LTg1LjQtMTMuOUwxOTIgMTU4LjFWOTZMNjQgMCAwIDY0bDk2IDEyOGg2Mi4xbDEwNi42IDEwNi42Yy0xMy42IDI3LjgtOS4yIDYyLjMgMTMuOSA4NS40bDExNy4xIDExNy4xYzE0LjYgMTQuNiAzOC4yIDE0LjYgNTIuNyAwbDUyLjctNTIuN2MxNC41LTE0LjYgMTQuNS0zOC4yIDAtNTIuN3pNMzMxLjcgMjI1YzI4LjMgMCA1NC45IDExIDc0LjkgMzFsMTkuNCAxOS40YzE1LjgtNi45IDMwLjgtMTYuNSA0My44LTI5LjUgMzcuMS0zNy4xIDQ5LjctODkuMyAzNy45LTEzNi43LTIuMi05LTEzLjUtMTIuMS0yMC4xLTUuNWwtNzQuNCA3NC40LTY3LjktMTEuM0wzMzQgOTguOWw3NC40LTc0LjRjNi42LTYuNiAzLjQtMTcuOS01LjctMjAuMi00Ny40LTExLjctOTkuNi45LTEzNi42IDM3LjktMjguNSAyOC41LTQxLjkgNjYuMS00MS4yIDEwMy42bDgyLjEgODIuMWM4LjEtMS45IDE2LjUtMi45IDI0LjctMi45em0tMTAzLjkgODJsLTU2LjctNTYuN0wxOC43IDQwMi44Yy0yNSAyNS0yNSA2NS41IDAgOTAuNXM2NS41IDI1IDkwLjUgMGwxMjMuNi0xMjMuNmMtNy42LTE5LjktOS45LTQxLjYtNS02Mi43ek02NCA0NzJjLTEzLjIgMC0yNC0xMC44LTI0LTI0IDAtMTMuMyAxMC43LTI0IDI0LTI0czI0IDEwLjcgMjQgMjRjMCAxMy4yLTEwLjcgMjQtMjQgMjR6Ij48L3BhdGg+PC9zdmc+";

const styles = theme => ({
    odrFft: {
        backgroundColor: '#000000',
        color: 'green',
        fontSize: '10px',
        borderWidth: '2px',
        borderColor: 'green'
    }
});

export class EquipmentHistory extends Component {
    constructor(props) {
        super(props);
        this.state = {
            open: false,
            contentItem: null,
            isDisabled: {},
            dialogId: '',
            status: '',
            title: '',
            alarmListData: [],
            dateArray: [],
            isDialogOpen: false,
            odrFlag: true,
            fftFlag: true,
            fftTime: null,
            options: {
                orientation: {
                    axis: "bottom"
                },
                maxHeight: '400px',
                minHeight: '50px',

                width: '100%',
                height: '300px',
                stack: true,
                // zoomMin: 10000,
                // zoomMax: 100000000,
                showMajorLabels: true,
                showCurrentTime: true,
            },
            showForward: false,
            isShowFFT: false,
            isShowAlarm: false,
            showBackward: false,
            autoRefresh: false,
            fromRefresh: false,
            diagnosticDetails: {}
        }
        this.timeline = React.createRef();
        this.zoomIn = this.zoomIn.bind(this);
        this.alarmList = [];
    }

    zoomIn(start, end) {
        //this.timeline.current.$el.zoomIn(0) 
        // if(start && start._isAMomentObject){
        //     this.props.setLoaderState({ value: true })
        // }else{
        //     this.props.setLoaderState({ value: false })
        // }
        var range = this.timeline.current.$el.getWindow();
        // var interval = range.end - range.start;

        // this.timeline.current.$el.setWindow({
        //     start: range.start.valueOf() - interval * 1,
        //     end:   range.end.valueOf()   + interval * 1
        // });


        this.timeline.current.$el.setWindow({
            start: start,
            end: end
        });

    }

    componentDidMount() {
        let selectedOrg = this.props.props.userData && this.props.props.userData.selectedOrganization;
        if (selectedOrg == undefined) {
            let rootOrganization = this.props.props.userData.scopeSelector.subOrganizations.find((organization) => (
                organization.root
            ))
            selectedOrg = rootOrganization.id;
        }
        let odrFftFlag = this.props.props.userData && this.props.props.userData.scopeSelector.subOrganizations.find(ele => ele.id === selectedOrg);
        if (odrFftFlag !== undefined) {
            this.setState({ odrFlag: odrFftFlag.odrEnable, fftFlag: odrFftFlag.fftWaveformEnable })
        }
        if (this.props.range[0] === 'month' || this.props.range.length >= 3) {
            let range = [];
            this.setState({ fromRefresh: false });
            let startDate, currentDateTime, currentTime;
            if (this.props.range[0] === 'month') {
                currentDateTime = new Date();
                currentDateTime.setSeconds(0);
                currentDateTime.setMilliseconds(0);

                startDate = new Date(currentDateTime);
                startDate.setDate(startDate.getDate() - 30);
            
                currentTime = moment(currentDateTime)
                startDate = moment(startDate);
            } else {
                startDate = new Date(this.props.range[1]);
                currentDateTime = new Date(this.props.range[2]);
                currentTime = moment(currentDateTime);
            }

            var duration = moment.duration(currentTime.diff(startDate));
            var hours = duration.asHours();
            let arr = [];
            if (hours > 24) {
                let newDate = moment(Date.now()).subtract(24, 'hours')
                do {
                    let object = {
                        startTime: newDate,
                        endTime: currentTime
                    }
                    arr.push(object)
                    currentTime = newDate;
                    newDate = moment(newDate).subtract(24, 'hours')
                } while (startDate < newDate);
                arr.push({
                    startTime: startDate,
                    endTime: currentTime
                });
            } else {
                arr.push({
                    startTime: moment(startDate),
                    endTime: moment(currentTime)
                });
            }
            this.setState({
                dateArray: arr
            })
            range.push(startDate.toISOString());
            range.push(currentDateTime.toISOString());
            this.state.options.min = range[0];
            this.state.options.max = range[1];
            this.zoomIn(range[0], range[1]);
            this.setState({
                alarmListData: [],
                showForward: false,
                showBackward: false,
                autoRefresh: false,

            });
            if (this.state.dateArray.length > 0) {
                let from = this.state.dateArray[0].startTime.utc().format("YYYY-MM-DDTHH:mm:ss") + "Z";
                let to = this.state.dateArray[0].endTime.utc().format("YYYY-MM-DDTHH:mm:ss") + "Z";
                this.props.getAlarmsData(this.props.deviceId, from, to);
                if (this.state.odrFlag) {
                    this.props.getDiagnosticsAlarmsData(this.props.monitorId, from, to);
                }
                this.props.getFFTData(this.props.deviceId, from, to);
                this.props.getEquipmentData(this.props.deviceId, from, to, 2);
                this.state.options.min = from;
                this.state.options.max = to;
                this.zoomIn(from, to);
                this.setState({ dateArrayIndex: 0, from: from, to: to });

            }
            if (this.state.isShowAlarm) {
                let alarmList = [];
                this.props.diagnosticsAlarmsData.diagnosticsAlarmsData.forEach((element, index) => {
                    if (element.endTime && element.endTime !== "" && element.diagnosticDetails.healthScore > 0 && this.state.odrFlag) {
                        var item5 = document.createElement('div');
                        var img5 = document.createElement('img');
                        img5.src = imgsrc;
                        img5.style.width = '20px';
                        img5.style.height = '20px';
                        item5.appendChild(img5);
                        let object = {
                            id: "diagnostics-" + element.id,
                            content: item5,
                            title: moment(element.endTime).utc().local().format("YYYY-MM-DD HH:mm:ss"),
                            start: element.endTime,
                            className: 's_9',
                            diagnosticDetails: element.diagnosticDetails
                        };
                        if (this.state.odrFlag) {
                            let fftObject = {
                                id: `ODR-FFT${element.id}`,
                                content: `<div class="fft_odrCss">ODR<br/> FFT</div>`,
                                title: moment(element.fftTime).utc().local().format("YYYY-MM-DD HH:mm:ss"),
                                start: moment(element.fftTime).utc().format("YYYY-MM-DDTHH:mm:ss.SSS") + "Z",
                                className: `fft odrCss ${moment(element.fftTime).utc().local().format("YYYY-MM-DD HH:mm:ss")}`
                            }
                            alarmList.push(Object.assign({}, fftObject));
                        }
                        alarmList.push(Object.assign({}, object));
                    }
                })


                // this.props.alarmsData.alarmsData.forEach((element) => {
                //     if (element.level === 3) {
                //         let object = {
                //             id: element.id,
                //             content: `<div><span class="warn warningIcon"></span></div>`,
                //             title: moment(element.time).utc().local().format("YYYY-MM-DD HH:mm:ss"),
                //             start: element.time,
                //             className: 's_2'
                //         };
                //         alarmList.push(Object.assign({}, object));
                //     } else if (element.level === 4) {
                //         let object = {
                //             id: element.id,
                //             content: `<div><span class="warn warningIcon"></span></div>`,
                //             title: moment(element.time).utc().local().format("YYYY-MM-DD HH:mm:ss"),
                //             start: element.time,
                //             className: 's_3'
                //         };
                //         alarmList.push(Object.assign({}, object));
                //     }
                // })
                var result = alarmList.reduce((unique, o) => {
                    if (!unique.some(obj => obj.id === o.id)) {
                        unique.push(o);
                    }
                    return unique;
                }, []);
                var result1 = [...this.state.alarmListData, ...result].reduce((unique, o) => {
                    if (!unique.some(obj => obj.id === o.id)) {
                        unique.push(o);
                    }
                    return unique;
                }, []);
                this.setState({
                    alarmListData: [...result1]
                }, () => {
                    this.zoomIn(this.props.range[1], this.props.range[2]);
                })
            }
            if (this.state.isShowFFT) {
                let fftList = [];
                this.props.fftData.FFTData.forEach((element, index) => {
                    let find = this.state.alarmListData.find(ele => ele.start === element.time);
                    if (find) {
                        // console.log(find);
                    } else {
                        let object = {
                            id: `FFT${element.id}`, content: "<div>FFT</div>",
                            title: moment(element.time).utc().local().format("YYYY-MM-DD HH:mm:ss"),
                            start: element.time,
                            className: `fft ${moment(element.time).utc().local().format("YYYY-MM-DD HH:mm:ss")} ${element.idle === true ? "idleColorFFt" : ""}`
                        }
                        fftList.push(Object.assign({}, object));
                    }

                })
                this.setState({
                    alarmListData: [...this.state.alarmListData, ...fftList]
                }, () => {
                    this.zoomIn(this.props.range[1], this.props.range[2]);
                })
            }
            let equipmentList = [];
            let showBackward = false;
            let showForward = false;
            if (this.props.equipmentData && this.props.equipmentData.EquipmentData) {

                this.props.equipmentData.EquipmentData.forEach((element, index) => {
                    let endTime = '';
                    if (element.endTime === null) {
                        endTime = new Date();
                    } else {
                        endTime = element.endTime;
                    }
                    let object = {
                        start: element.startTime,
                        end: endTime,  // end is optional
                        content: ``,
                        // className: ,
                        type: 'background',
                    }
                    if (element.state === 4) {
                        object.className = 's_3';
                    } else if (element.state === 3) {
                        object.className = 's_2';
                    } else if (element.state === 1) {
                        object.className = 's_0';
                    } else if (element.state === 2) {
                        object.className = 's_1';
                    } else if (element.state === 6) {
                        object.className = 's_6';
                    }                     
                    else {
                        object.className = 's_-1';
                    }
                    if(this.props.batteryFlag){
                        object.className = 's_11';
                    }
                    equipmentList.push(Object.assign({}, object));
                })
            }
            if (equipmentList.length > 0) {
                let from = equipmentList[0].start;
                let to = equipmentList[equipmentList.length - 1].end;
                this.zoomIn(equipmentList[0].start, equipmentList[equipmentList.length - 1].end);
                let options = { ...this.state.options };
                options.min = moment(from);
                options.max = moment(to);
                this.setState({
                    alarmListData: [...this.state.alarmListData, ...equipmentList],
                    showBackward: showBackward,
                    showForward: showForward,
                    options: options
                }, () => {
                    if (equipmentList.length < 1100) {
                        this.zoomIn(this.state.from, equipmentList[equipmentList.length - 1].end);
                    } else {
                        this.zoomIn(equipmentList[0].start, equipmentList[equipmentList.length - 1].end);
                    }
                })
            } else {
                let showBackward = false;
                let showForward = false;

                let options = { ...this.state.options };
                options.min = moment(this.state.from);
                options.max = moment(this.state.to);
                this.setState({ showBackward: showBackward, showForward: showForward, alarmListData: [], options: options },
                    () => {
                        this.zoomIn(moment(this.state.from), moment(this.state.to));
                    });
            }


        }
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevProps.range[0] !== this.props.range[0] || (prevProps.range[0] == this.props.range[0] && prevProps.range[1] !== undefined && prevProps.range[2] !== undefined && (prevProps.range[1] != this.props.range[1] || prevProps.range[2] != this.props.range[2]))) {
            var duration = moment.duration(moment(this.props.range[2]).diff(moment(this.props.range[1])));
            var hours = duration.asHours();
            this.setState({ fromRefresh: false })
            let arr = [];
            if (hours > 24) {
                let currentTime = moment(this.props.range[2])
                let startDate = moment(this.props.range[1]);
                let newDate = moment(this.props.range[2]).subtract(24, 'hours')
                do {
                    let object = {
                        startTime: newDate,
                        endTime: currentTime
                    }
                    arr.push(object)
                    currentTime = newDate;
                    newDate = moment(newDate).subtract(24, 'hours')
                } while (startDate < newDate);
                arr.push({
                    startTime: startDate,
                    endTime: currentTime
                });
            } else {
                arr.push({
                    startTime: moment(this.props.range[1]),
                    endTime: moment(this.props.range[2])
                });
            }
            let autoRefresh = false;
            if (this.props.range[0] === 'today' || this.props.range[0] === 'thisMonth') {
                autoRefresh = true;
                // this.setState({autoRefresh: true});
            } else {
                autoRefresh = false;
                // this.setState({autoRefresh: false});
            }

            this.setState({
                // alarmListData: [],
                dateArray: arr,
                autoRefresh: autoRefresh,
                showForward: autoRefresh && this.props.range[0] === prevProps.range[0] ? this.state.showForward : false,
                showBackward: autoRefresh && this.props.range[0] === prevProps.range[0] ? this.state.showBackward : false,
            }, () => {
                // this.zoomIn(this.props.range[1], this.props.range[2]);
            })
        }
        if (prevProps.deviceId !== this.props.deviceId) {
            this.setState({ fromRefresh: false })
            if(this.state && this.state.dateArray[0]){

                let from = this.state && this.state.dateArray[0] && this.state && this.state.dateArray[0].startTime.utc().format("YYYY-MM-DDTHH:mm:ss") + "Z";
                let to = this.state && this.state.dateArray[0] && this.state && this.state.dateArray[0].endTime.utc().format("YYYY-MM-DDTHH:mm:ss") + "Z";
    
                this.setState({
                    alarmListData: [],
                    showForward: false,
                    showBackward: false,
                    autoRefresh: false,
                    isShowFFT: false,
                    isShowAlarm: false
    
                });
                this.props.getAlarmsData(this.props.deviceId, from, to);
    
                if (this.state.odrFlag) {
                    this.props.getDiagnosticsAlarmsData(this.props.monitorId, from, to);
                }
                this.props.getFFTData(this.props.deviceId, from, to);
                this.props.getEquipmentData(this.props.deviceId, from, to, 2);
            }

        }
        if (this.state.dateArray.length > 0 && prevState.dateArray !== this.state.dateArray && this.state.dateArray[0].startTime && this.state.dateArray[0].endTime) {
            var from = this.state.dateArray[0].startTime.utc().format("YYYY-MM-DDTHH:mm:ss") + "Z";
            var to = this.state.dateArray[0].endTime.utc().format("YYYY-MM-DDTHH:mm:ss") + "Z";
            if (!this.state.autoRefresh) {
                this.props.getAlarmsData(this.props.deviceId, from, to);
                if (this.state.odrFlag) {
                    this.props.getDiagnosticsAlarmsData(this.props.monitorId, from, to);
                }
                this.props.getFFTData(this.props.deviceId, from, to);
                this.props.getEquipmentData(this.props.deviceId, from, to, 2);
                this.state.options.min = from;
                this.state.options.max = to;
                this.zoomIn(from, to);
                this.setState({ dateArrayIndex: 0, from: from, to: to });
            } else if (this.state.autoRefresh && this.state.showForward === true) {
                // this.props.getAlarmsData(this.props.deviceId, from, to);
                // this.props.getFFTData(this.props.deviceId, from, to);
                // this.props.getEquipmentData(this.props.deviceId,from, to,2);
                let from = moment(this.state.from).utc().format("YYYY-MM-DDTHH:mm:ss") + "Z";
                let to = moment(this.state.to).utc().format("YYYY-MM-DDTHH:mm:ss") + "Z";
                // this.props.getAlarmsData(this.props.deviceId, from, to);
                // this.props.getFFTData(this.props.deviceId, from, to);
                // this.props.getEquipmentData(this.props.deviceId,from, to,2);
                // this.state.options.min = from;
                // this.state.options.max = to;
                // this.zoomIn(from, to);
                this.setState({ dateArrayIndex: 0, autoRefresh: false, from: from, to: to });

            } else {
                this.props.getAlarmsData(this.props.deviceId, from, to);
                if (this.state.odrFlag) {
                    this.props.getDiagnosticsAlarmsData(this.props.monitorId, from, to);
                }
                this.props.getFFTData(this.props.deviceId, from, to);
                this.props.getEquipmentData(this.props.deviceId, from, to, 2);
                this.setState({ dateArrayIndex: 0, autoRefresh: false, from: from, to: to });
            }
        }

        if (prevProps.diagnosticsAlarmsData.diagnosticsAlarmsData !== this.props.diagnosticsAlarmsData.diagnosticsAlarmsData) {
            let alarmList = [];
            this.props.diagnosticsAlarmsData.diagnosticsAlarmsData.forEach((element, index) => {
                if (element.endTime && element.endTime !== "" && element.diagnosticDetails.healthScore > 0 && this.state.odrFlag) {
                    let item5 = document.createElement('div');
                    let img5 = document.createElement('img');
                    img5.src = imgsrc;
                    img5.style.width = '20px';
                    img5.style.height = '20px';
                    item5.appendChild(img5);
                    let object = {
                        id: "diagnostics-" + element.id,
                        content: item5,
                        title: moment(element.endTime).utc().local().format("YYYY-MM-DD HH:mm:ss"),
                        start: element.endTime,
                        className: 's_9',
                        diagnosticDetails: element.diagnosticDetails
                    };
                    if (this.state.odrFlag) {
                        let fftObject = {
                            id: `ODR-FFT${element.id}`,
                            content: `<div class="fft_odrCss">ODR<br/> FFT</div>`,
                            title: moment(element.fftTime).utc().local().format("YYYY-MM-DD HH:mm:ss"),
                            start: moment(element.fftTime).utc().format("YYYY-MM-DDTHH:mm:ss.SSS") + "Z",
                            className: `fft odrCss ${moment(element.fftTime).utc().local().format("YYYY-MM-DD HH:mm:ss")}`
                        }
                        alarmList.push(Object.assign({}, fftObject));
                    }

                    alarmList.push(Object.assign({}, object));
                }
            })
            var result = alarmList.reduce((unique, o) => {
                if (!unique.some(obj => obj.id === o.id)) {
                    unique.push(o);
                }
                return unique;
            }, []);
            var result1 = [...this.state.alarmListData, ...result].reduce((unique, o) => {
                if (!unique.some(obj => obj.id === o.id)) {
                    unique.push(o);
                }
                return unique;
            }, []);
            if (this.state.isShowAlarm) {
                this.setState({
                    alarmListData: [...result1]
                }, () => {
                    this.zoomIn(this.props.range[1], this.props.range[2]);
                })
            }
        }
        if (prevProps.alarmsData.alarmsData !== this.props.alarmsData.alarmsData) {
            let alarmList = [];
            // this.props.alarmsData.alarmsData.forEach((element) => {
            //     if (element.level === 3) {
            //         let object = {
            //             id: element.id,
            //             content: `<div><span class="warn warningIcon"></span></div>`,
            //             title: moment(element.time).utc().local().format("YYYY-MM-DD HH:mm:ss"),
            //             start: element.time,
            //             className: 's_2'
            //         };
            //         alarmList.push(Object.assign({}, object));
            //     } else if (element.level === 4) {
            //         let object = {
            //             id: element.id,
            //             content: `<div><span class="warn warningIcon"></span></div>`,
            //             title: moment(element.time).utc().local().format("YYYY-MM-DD HH:mm:ss"),
            //             start: element.time,
            //             className: 's_3'
            //         };
            //         alarmList.push(Object.assign({}, object));
            //     }
            // })
            var result = alarmList.reduce((unique, o) => {
                if (!unique.some(obj => obj.id === o.id)) {
                    unique.push(o);
                }
                return unique;
            }, []);
            var result1 = [...this.state.alarmListData, ...result].reduce((unique, o) => {
                if (!unique.some(obj => obj.id === o.id)) {
                    unique.push(o);
                }
                return unique;
            }, []);
            if (this.state.isShowAlarm) {
                this.setState({
                    alarmListData: [...result1]
                }, () => {
                    this.zoomIn(this.props.range[1], this.props.range[2]);
                })
            }
        }
        if (prevProps.fftData.FFTData !== this.props.fftData.FFTData) {
            let fftList = [];
            this.props.fftData.FFTData.forEach((element, index) => {
                let find = this.state.alarmListData.find(ele => ele.start === element.time);
                if (find) {
                    // console.log(find);
                } else {
                    let object = {
                        id: `FFT${element.id}`, content: "<div>FFT</div>",
                        title: moment(element.time).utc().local().format("YYYY-MM-DD HH:mm:ss"),
                        start: element.time,
                        className: `fft ${moment(element.time).utc().local().format("YYYY-MM-DD HH:mm:ss")} ${element.idle === true ? "idleColorFFt" : ""}`
                    }
                    fftList.push(Object.assign({}, object));
                }

            })
            if (this.state.isShowFFT) {
                this.setState({
                    alarmListData: [...this.state.alarmListData, ...fftList]
                }, () => {
                    this.zoomIn(this.props.range[1], this.props.range[2]);
                })
            }
        }
        if ((prevProps.equipmentData !== this.props.equipmentData) ||(prevProps.batteryFlag !==this.props.batteryFlag) ||(this.props.isEquipmentDone) ||(prevProps.dataLoggerFlag !==this.props.dataLoggerFlag)) {
            this.props.onEquipmentDone();
            //this.props.setLoaderState({ value: true })
            let equipmentList = [];
            let showBackward = false;
            let showForward = false;
            this.props && this.props.equipmentData && this.props.equipmentData.EquipmentData && this.props.equipmentData.EquipmentData.forEach((element, index) => {
              
                let endTime = '';
                if (element.endTime === null) {
                    endTime = new Date();
                } else {
                    endTime = element.endTime;
                }
                let object = {
                    start: element.startTime,
                    end: endTime,  // end is optional
                    content: ``,
                    // className: ,
                    type: 'background',
                }
                if (element.state === 4) {
                    object.className = 's_3';
                } else if (element.state === 3) {
                    object.className = 's_2';
                } else if (element.state === 1) {
                    object.className = 's_0';
                } else if (element.state === 2) {
                    object.className = 's_1';
                } else if (element.state === 6) {
                    object.className = 's_6';
                } 
                else{
                    object.className = 's_-1';
                }
                if(this.props.batteryFlag){
                    object.className = 's_11';
                }
                equipmentList.push(Object.assign({}, object));
            })
            if (equipmentList.length > 0) {
                let from = equipmentList[0].start;
                let to = equipmentList[equipmentList.length - 1].end;
                this.zoomIn(equipmentList[0].start, equipmentList[equipmentList.length - 1].end);
                if (this.formatDate(equipmentList[0].start) === this.formatDate(this.props.range[1])) {
                    // alert('start is equal');
                    showBackward = false;
                } else if (equipmentList.length < 1100) { //&& this.state.dateArray.length <= 1
                    if (this.formatDate(from) !== this.formatDate(this.props.range[1])) {
                        showBackward = true;
                    } else {
                        showBackward = false;
                    }
                } else {
                    showBackward = true;
                }

                if (this.formatDate(equipmentList[equipmentList.length - 1].end) === this.formatDate(this.props.range[2]) || this.formatDate(equipmentList[equipmentList.length - 1].end) >= this.formatDate(this.props.range[2])) {
                    // alert('end is equal');
                    showForward = false;
                } else {
                    showForward = true;
                }
                let options = { ...this.state.options };
                options.min = moment(from);
                options.max = moment(to);
                this.setState({
                    alarmListData: [...this.state.alarmListData, ...equipmentList],
                    showBackward: showBackward,
                    showForward: showForward,
                    options: options
                }, () => {
                    if (equipmentList.length < 1100) {
                        this.zoomIn(equipmentList[0].start, equipmentList[equipmentList.length - 1].end);
                    } else {
                        this.zoomIn(equipmentList[0].start, equipmentList[equipmentList.length - 1].end);
                    }
                })
            } else {
                let showBackward = false;
                let showForward = false;
                if (this.formatDate(this.state.from) === this.formatDate(this.props.range[1])) {
                    showBackward = false;
                } else {
                    showBackward = true;
                }
                if (this.formatDate(this.state.to) === this.formatDate(this.props.range[2]) || this.formatDate(this.state.to) >= this.formatDate(this.props.range[2])) {
                    showForward = false;
                } else {
                    showForward = true;
                }
                let options = { ...this.state.options };
                options.min = moment(this.state.from);
                options.max = moment(this.state.to);
                this.setState({ showBackward: showBackward, showForward: showForward, alarmListData: [], options: options },
                    () => {
                        this.zoomIn(moment(this.state.from), moment(this.state.to));
                    });
            }
        }
        if (prevProps.updateFftData.data !== this.props.updateFftData.data) {
            if (this.props.updateFftData.data.time) {
                let fftData = [];
                let find = this.state.alarmListData.find(ele => ele.start === this.props.updateFftData.data.time)
                if (find) {
                    //skip
                } else {
                    let object = {
                        id: `FFT${this.props.updateFftData.data.id}`, content: "<div>FFT</div>",
                        title: moment(this.props.updateFftData.data.time).utc().local().format("YYYY-MM-DD HH:mm:ss"),
                        start: this.props.updateFftData.data.time,
                        className: `fft ${moment(this.props.updateFftData.data.time).utc().local().format("YYYY-MM-DD HH:mm:ss")} ${this.props.updateFftData.data.idle === true ? "idleColorFFt" : ""}`
                    }
                    fftData.push(Object.assign({}, object));
                }
                this.setState({
                    alarmListData: [...this.state.alarmListData, ...fftData]
                }, () => {
                    this.zoomIn(this.state.dateArray[0].startTime, this.props.updateFftData.data.time);
                })
            }
        }
    }

    formatDate = (date) => {
        return moment(date).format("YYYY-MM-DD HH:mm:ss");
    }

    clickHandler = (props) => {
        if (!props.event.target.innerText.includes('FFT')) {
            if (props && props.items[0] && props.items[0].toString().includes("diagnostics")) {
                let arr = props.items[0].replace("diagnostics-", "");
                this.handleDialogOpen(arr)
            } else {
                this.handleClickOpen(props.items[0]);
            }
        } else {
            let data = this.state.alarmListData.filter(ele => ele.id === props.items[0]);
            if (data.length > 0) {
                this.updateFftData(data[0].start);
                if (data[0].id.includes("ODR-FFT")) {
                    this.props.onClick()
                }
            }
        }
    }

    updateFftData = (data) => {
        this.props.setFftSelectedData(data);
    }

    handleDialogOpen = (item) => {
        if (item) {
            let data = this.props.diagnosticsAlarmsData.diagnosticsAlarmsData.find(ele => ele.id.toString() === item);
            let diagnosticDetails = data.diagnosticDetails;
            this.setState({
                diagnosticDetails: diagnosticDetails,
                isDialogOpen: true,
                fftTime: data.fftTime
            });
        }

    }

    handleDialogClose = () => {
        this.setState({ isDialogOpen: false, diagnosticDetails: {} })
    }


    handleClickOpen = (item) => {
        if (item) {
            let data = this.props.alarmsData.alarmsData.filter(ele => ele.id === item);
            // console.log(data);
            let description = "";
            // let title = `${data[0].level} - ${ moment(data[0].time).utc().local().format("YYYY-MM-DD HH:mm:ss")}`
            let title = `${data[0].level === 4 ? "Warning" : "Caution"} - ${new Date(data[0].time)}`
            let featureData = this.props.featureList.find(ele => ele.value === data[0].featureCode);
            if (featureData) {
                description = data[0].featureName + ": Value:" + data[0].value + ",Threshold:" + data[0].threshold;
            } else {
                description = data[0].description.replace(/{|"|}/g, '')
            }
            let x = description.split(":");
            let y = x[2].split(",");
            let newValue = this.props.t(x[0]) + ": " + this.props.t(x[1]) + ":" + y[0] + "," + this.props.t(y[1]) + ":" + x[3];
            let t=title.split("-");
            let newTittle=this.props.t(t[0])+"-"+t[1];
            this.setState({ contentItem: newValue, open: true, dialogId: data[0].id, status: data[0].status, title: newTittle });
        }
    }

    handleCloseOkay = () => {
        this.setState({ open: false, actNote: '' })
    }
    changeDuration(direction) {
        this.setState({ fromRefresh: false })

        if (this.props.equipmentData.EquipmentData.length !== 0) {
            if (direction === 'forward') {
                let from = this.props.equipmentData.EquipmentData[this.props.equipmentData.EquipmentData.length - 1].endTime;
                let to = "";
                if (moment(this.props.range[2]).diff(moment(this.props.equipmentData.EquipmentData[this.props.equipmentData.EquipmentData.length - 1].endTime).add(24, 'hours')) > 0) {
                    to = moment(this.props.equipmentData.EquipmentData[this.props.equipmentData.EquipmentData.length - 1].endTime).add(24, 'hours').utc().format("YYYY-MM-DDTHH:mm:ss") + "Z";
                } else {
                    to = moment(this.props.range[2]).utc().format("YYYY-MM-DDTHH:mm:ss") + "Z";
                }
                this.props.getAlarmsData(this.props.deviceId, from, to);
                if (this.state.odrFlag) {
                    this.props.getDiagnosticsAlarmsData(this.props.monitorId, from, to);
                }

                this.props.getFFTData(this.props.deviceId, from, to);
                this.props.getEquipmentData(this.props.deviceId, from, to, 1);
                this.setState({
                    alarmListData: [],
                    from: from,
                    to: to
                });
            } else {
                let to = this.props.equipmentData.EquipmentData[0].startTime ;

                // Handle a corner case were the api query parameter 'to' is same as api 
                // response's 0th 'startTime': 'to' should then be 24 hours before the 'from'
                // e.g. API: /equipment-history?to=2022-11-27T08:02:47Z
                // Response:[{startTime:2022-11-27T15:10:47Z}]
                
                if(moment(to).isSame(moment(this.state.to), 'day')){
                    to=this.state.from
                }

                let from = '';
                if (moment(this.props.range[1]).diff(moment(to).subtract(24, 'hours')) <= 0) {
                    from = moment(to).subtract(24, 'hours').utc().format("YYYY-MM-DDTHH:mm:ss") + "Z";
                } else {
                    from = moment(this.props.range[1]).utc().format("YYYY-MM-DDTHH:mm:ss") + "Z";
                }
                this.props.getAlarmsData(this.props.deviceId, from, to);
                if (this.state.odrFlag) {
                    this.props.getDiagnosticsAlarmsData(this.props.monitorId, from, to);
                }
                this.props.getFFTData(this.props.deviceId, from, to);
                this.props.getEquipmentData(this.props.deviceId, from, to, 2);

                this.setState({
                    alarmListData: [],
                    from: from,
                    to: to
                });
            }
        } else {
            let showBackward = false;
            let showForward = false;
            if (this.formatDate(this.state.from) === this.formatDate(this.props.range[1])) {
                showBackward = false;
            } else {
                showBackward = true;
            }
            if (this.formatDate(this.state.to) === this.formatDate(this.props.range[2])) {
                showForward = false;
            } else {
                showForward = true;
            }
            if (direction === 'forward') {
                let from = this.state.to;
                let to = "";
                if (moment(this.props.range[2]).diff(moment(from).add(24, 'hours')) > 0) {
                    to = moment(from).add(24, 'hours').utc().format("YYYY-MM-DDTHH:mm:ss") + "Z";
                } else {
                    to = moment(this.props.range[2]).utc().format("YYYY-MM-DDTHH:mm:ss") + "Z";
                }
                this.props.getAlarmsData(this.props.deviceId, from, to);
                if (this.state.odrFlag) {
                    this.props.getDiagnosticsAlarmsData(this.props.monitorId, from, to);
                }
                this.props.getFFTData(this.props.deviceId, from, to);
                this.props.getEquipmentData(this.props.deviceId, from, to, 1);
                this.setState({
                    alarmListData: [],
                    from: from,
                    to: to,
                    showBackward: showBackward,
                    showForward: showForward
                }, () => {
                    this.state.options.min = from;
                    this.state.options.max = to;
                    this.zoomIn(from, to);
                });

            } else {
                let to = this.state.from;
                let from = '';
                if (moment(this.props.range[1]).diff(moment(to).subtract(24, 'hours')) <= 0) {
                    from = moment(to).subtract(24, 'hours').utc().format("YYYY-MM-DDTHH:mm:ss") + "Z";
                } else {
                    from = moment(this.props.range[1]).utc().format("YYYY-MM-DDTHH:mm:ss") + "Z";
                }

                this.props.getAlarmsData(this.props.deviceId, from, to);
                if (this.state.odrFlag) {
                    this.props.getDiagnosticsAlarmsData(this.props.monitorId, from, to);
                }
                this.props.getFFTData(this.props.deviceId, from, to);
                this.props.getEquipmentData(this.props.deviceId, from, to, 2);

                this.setState({
                    alarmListData: [],
                    from: from,
                    to: to,
                    showBackward: showBackward,
                    showForward: showForward
                }, () => {
                    this.state.options.min = from;
                    this.state.options.max = to;
                    this.zoomIn(from, to);
                });
            }
        }

        this.setState({ alarmListData: [] });
    }
    refreshData() {

        this.setState({ fromRefresh: true });
        let from = this.state.dateArray[0].startTime.utc().format("YYYY-MM-DDTHH:mm:ss") + "Z";
        let to = this.state.dateArray[0].endTime.utc().format("YYYY-MM-DDTHH:mm:ss") + "Z";
        let currentDateTime = new Date();
        let startDate;
        switch (this.props.range[0]) {
            case 'today':
                startDate = new Date();
                startDate.setHours(0)
                startDate.setMinutes(0);
                startDate.setSeconds(0);
                startDate.setMilliseconds(0);
                from = startDate.toISOString();
                to = currentDateTime.toISOString();
                break;
            case 'thisMonth':
                to = currentDateTime.toISOString();
                from = moment(to).subtract(24, 'hours').toISOString()

                break;
        }
        if (this.props.range.length === 4 && this.props.range[3] === "search") {
            to = moment(this.props.range[2]).utc().format("YYYY-MM-DDTHH:mm:00") + "Z";
        }

        var result1 = [];
        this.setState({
            alarmListData: [...result1],

        });


        this.setState({ alarmListData: [] });
        this.props.getAlarmsData(this.props.deviceId, from, to);
        if (this.state.odrFlag) {
            this.props.getDiagnosticsAlarmsData(this.props.monitorId, from, to);
        }
        this.props.getFFTData(this.props.deviceId, from, to);
        this.props.getEquipmentData(this.props.deviceId, from, to, 2);
        this.state.options.min = from;
        this.state.options.max = to;
        this.zoomIn(from, to);
        this.setState({ dateArrayIndex: 0, from: from, to: to });


    }

    getFftAlarmData() {
        this.setState({ fromRefresh: true });
        var result1 = [];
        this.setState({
            alarmListData: [...result1],

        });
        this.setState({ alarmListData: [] });
        if (this.state.isShowAlarm) {
            let alarmList = [];
            this.props.diagnosticsAlarmsData.diagnosticsAlarmsData && this.props.diagnosticsAlarmsData.diagnosticsAlarmsData.forEach((element, index) => {
                if (element.endTime && element.endTime !== "" && element.diagnosticDetails.healthScore > 0 && this.state.odrFlag) {
                    var item5 = document.createElement('div');
                    var img5 = document.createElement('img');
                    img5.src = imgsrc;
                    img5.style.width = '20px';
                    img5.style.height = '20px';
                    item5.appendChild(img5);
                    let object = {
                        id: "diagnostics-" + element.id,
                        content: item5,
                        title: moment(element.endTime).utc().local().format("YYYY-MM-DD HH:mm:ss"),
                        start: element.endTime,
                        className: 's_9',
                        diagnosticDetails: element.diagnosticDetails
                    };
                    if (this.state.odrFlag) {
                        let fftObject = {
                            id: `ODR-FFT${element.id}`,
                            content: `<div class="fft_odrCss">ODR<br/> FFT</div>`,
                            title: moment(element.fftTime).utc().local().format("YYYY-MM-DD HH:mm:ss"),
                            start: moment(element.fftTime).utc().format("YYYY-MM-DDTHH:mm:ss.SSS") + "Z",
                            className: `fft odrCss ${moment(element.fftTime).utc().local().format("YYYY-MM-DD HH:mm:ss")}`
                        }
                        alarmList.push(Object.assign({}, fftObject));
                    }
                    alarmList.push(Object.assign({}, object));
                }
            })
            // this.props && this.props.alarmsData && this.props.alarmsData.alarmsData && this.props.alarmsData.alarmsData.forEach((element) => {
            //     if (element.level === 3) {
            //         let object = {
            //             id: element.id,
            //             content: `<div><span class="warn warningIcon"></span></div>`,
            //             title: moment(element.time).utc().local().format("YYYY-MM-DD HH:mm:ss"),
            //             start: element.time,
            //             className: 's_2'
            //         };
            //         alarmList.push(Object.assign({}, object));
            //     } else if (element.level === 4) {
            //         let object = {
            //             id: element.id,
            //             content: `<div><span class="warn warningIcon"></span></div>`,
            //             title: moment(element.time).utc().local().format("YYYY-MM-DD HH:mm:ss"),
            //             start: element.time,
            //             className: 's_3'
            //         };
            //         alarmList.push(Object.assign({}, object));
            //     }
            // })
            var result = alarmList.reduce((unique, o) => {
                if (!unique.some(obj => obj.id === o.id)) {
                    unique.push(o);
                }
                return unique;
            }, []);
            result1 = [...result].reduce((unique, o) => {
                if (!unique.some(obj => obj.id === o.id)) {
                    unique.push(o);
                }
                return unique;
            }, []);
            var result2 = [...this.state.alarmListData, ...result].reduce((unique, o) => {
                if (!unique.some(obj => obj.id === o.id)) {
                    unique.push(o);
                }
                return unique;
            }, []);
            this.setState({
                alarmListData: [...result2]
            }, () => {
                //this.zoomIn(this.props.range[1], currentDateTime.toISOString());
            })
        }
        if (this.state.isShowFFT) {
            let fftList = [];
            this.props.fftData.FFTData.forEach((element, index) => {
                let find = this.state.alarmListData.find(ele => ele.start === element.time);
                if (find) {
                    // console.log(find);
                } else {
                    let object = {
                        id: `FFT${element.id}`,
                        content: "<div>FFT</div>",
                        title: moment(element.time).utc().local().format("YYYY-MM-DD HH:mm:ss"),
                        start: element.time,
                        className: `fft ${moment(element.time).utc().local().format("YYYY-MM-DD HH:mm:ss")} ${element.idle === true ? "idleColorFFt" : ""}`
                    }
                    fftList.push(Object.assign({}, object));
                }

            })
            result1 = [...result1, ...fftList]
            this.setState({
                alarmListData: [...this.state.alarmListData, ...fftList]
            }, () => {
                //  this.zoomIn(this.props.range[1], currentDateTime.toISOString());
            })

        }
        let equipmentList = [];
        if (this.props.equipmentData && this.props.equipmentData.EquipmentData) {
            this.props.equipmentData.EquipmentData.forEach((element, index) => {
                let endTime = '';
                if (element.endTime === null) {
                    endTime = new Date();
                } else {
                    endTime = element.endTime;
                }
                let object = {
                    start: element.startTime,
                    end: endTime,  // end is optional
                    content: ``,
                    // className: ,
                    type: 'background',
                }
                if (element.state === 4) {
                    object.className = 's_3';
                } else if (element.state === 3) {
                    object.className = 's_2';
                } else if (element.state === 1) {
                    object.className = 's_0';
                } else if (element.state === 2) {
                    object.className = 's_1';
                } else if (element.state === 6) {
                    object.className = 's_6';
                } else {
                    object.className = 's_-1';
                }
                if(this.props.batteryFlag){
                    object.className = 's_11';
                }
                equipmentList.push(Object.assign({}, object));
            })
        }
        if (equipmentList.length > 0) {
            let from = equipmentList[0].start > this.state.from ? this.state.from : equipmentList[0].start;
            let to = equipmentList[equipmentList.length - 1].end;
            this.zoomIn(equipmentList[0].start, equipmentList[equipmentList.length - 1].end);
            let options = { ...this.state.options };
            options.min = moment(from);
            options.max = moment(to);
            result1 = [...result1, ...equipmentList]
            this.setState({
                alarmListData: [...result1],
                options: options
            }, () => {
                if (equipmentList.length < 1100) {
                    this.zoomIn(this.state.from, equipmentList[equipmentList.length - 1].end);
                } else {
                    this.zoomIn(equipmentList[0].start, equipmentList[equipmentList.length - 1].end);
                }
            })
        } else {
            let options = { ...this.state.options };
            options.min = moment(this.state.from);
            options.max = moment(this.state.to);
            this.setState({ alarmListData: [], options: options },
                () => {
                    this.zoomIn(moment(this.state.from), moment(this.state.to));
                });
        }
    }


    onClickCheckbox(label) {
        this.setState({ alarmListData: [] },
            function () {
                if (label === 'FFT') {
                    var showFFT = !this.state.isShowFFT;
                    this.setState({ isShowFFT: showFFT },
                        function () { this.getFftAlarmData(); });


                } else {
                    var showAlarm = !this.state.isShowAlarm;
                    this.setState({ isShowAlarm: showAlarm },
                        function () { this.getFftAlarmData(); });
                }
            });

    }

    handleODRFftLinkClick = () => {
        if (this.state.fftTime) {
            let fftTime = moment(this.state.fftTime).utc().format("YYYY-MM-DDTHH:mm:ss.SSS") + "Z";
            this.updateFftData(fftTime);
        }
        this.props.onClick();
        this.setState({ isDialogOpen: false, diagnosticDetails: {}, fftTime: null })
    }

    render() {
        const { t } = this.props;
        const row = this.props.widgetsData.widgetData.equipmentHistory;
        const deviveStateRow = this.props.widgetsData.widgetData.commonStateForDevice;
        const renderLink = () => {
            if (this.props.range[0] === 'today' || this.props.range[0] === 'thisMonth') {
                return <Link
                    component="button"
                    variant="body2"
                    title={this.props.t("Refresh Data")}
                    onClick={() => {
                        this.refreshData();
                    }}>
                    <RefreshIcon></RefreshIcon>
                </Link>

            }
        }
        return (
            <div className="equipment-panel display-text widgets-shadow" ref={this.props.reference}>

                <div className="widgets-heading widgets-heading-info">
                    <h2 className="display-text">{row && this.props.t(row.header)}</h2>
                    <span className="float-right">{this.props.deviceName} &nbsp;
                        {renderLink()}
                    </span>
                </div>
                <div>
                    <span>
                        <Checkbox
                            checked={this.state.isShowAlarm}
                            onChange={() => (this.onClickCheckbox("ALARM"))}
                            color="secondary"

                        />
                        {t("Show ODR Tags")}
                    </span>
                    {this.state.fftFlag && <span>
                        <Checkbox
                            checked={this.state.isShowFFT}
                            onChange={() => (this.onClickCheckbox("FFT"))}
                            color="secondary"

                        />
                        {t("Show FFT Tags")}
                    </span>}

                </div>
                <div className="equipment-container">
                    {this.props.batteryFlag===false && <ul className="equipment-statecolor">
                        {deviveStateRow && deviveStateRow.deviceState.map((list, index) =>
                            <li key={index} className={'bg-' + list.name}>{t(list.value)}</li>
                        )}
                    </ul>}
                    <div className="flex-container">
                        <div className={this.state.showBackward ? "iconClass leftIconFft" : 'hideIconClass'}><ChevronLeft className="icons leftIconFft" style={{ fill: 'red', fontSize: '35px' }} onClick={() => this.changeDuration('backward')} /></div>
                        <div style={{ width: '100%', height: '100%' }}> <Timeline
                            ref={this.timeline}
                            options={this.state.options}
                            selectHandler={this.clickHandler}
                            items={this.state.alarmListData} />
                        </div>
                        <div className={this.state.showForward ? "iconClass rightIconFft" : 'hideIconClass'}><ChevronRight className="icons rightIconFft" style={{ fill: 'red', fontSize: '35px' }} onClick={() => this.changeDuration('forward')} /></div>
                    </div>
                    <Dialog
                        open={this.state.open}
                        onClose={this.handleClose}
                        aria-labelledby="alert-dialog-title"
                        aria-describedby="alert-dialog-description"
                        className="warning-dialogBox"
                    >
                        <DialogTitle id="alert-dialog-title" className={this.state.title.includes("Warning") ? 'text-warning' : 'text-caution'}>{this.state.title}

                        </DialogTitle>
                        <DialogContent>
                            <DialogContentText id="alert-dialog-description">
                                {this.state.contentItem}
                                {/* <textarea value={this.state.actNote} placeholder={t("Action Note")} cols="50" rows="4" style={{ resize: 'none',fontSize:"16px" }} onChange={(e) => this.actionNotes(e)}></textarea>
                                {this.state.actNoteError && <div style={{color:'#f44336',fontSize:'0.75rem'}}>{t("Please enter maximum 100 characters")}</div>} */}
                            </DialogContentText>
                        </DialogContent>
                        <DialogActions>
                            <Button onClick={(event) => this.handleCloseOkay()} color="primary" autoFocus>
                                {t("OK")}
                            </Button>
                        </DialogActions>
                    </Dialog>

                    <Dialog
                        open={this.state.isDialogOpen}
                        onClose={this.handleDialogClose}
                        aria-labelledby="alert-dialog-title"
                        aria-describedby="alert-dialog-description"
                        className="warning-dialogBox"
                    >
                        <DialogTitle id="alert-dialog-title" >
                            {"Diagnostic & Recommendation"}
                            <Close
                                onClick={this.handleDialogClose}
                                className='close'
                                style={{ backgroundColor: '#ffc107', cursor: 'pointer' }}
                            />
                        </DialogTitle>
                        <DialogContent>
                            {/* <DialogContentText id="alert-dialog-description">
                                <span style={{ fontWeight: "500" }}>Observation:</span>{this.state.diagnosticDetails ? this.state.diagnosticDetails.observation : ""}
                            </DialogContentText> */}
                            <DialogContentText id="alert-dialog-description">
                                <span style={{ fontWeight: "500" }}>{t("Health Score")}:</span>{this.state.diagnosticDetails && this.state.diagnosticDetails.healthScore ? this.state.diagnosticDetails.healthScore + "%" : ""}
                            </DialogContentText>
                            <DialogContentText id="alert-dialog-description">
                                <span style={{ fontWeight: "500" }}>{t("Diagnostic")}:</span> {this.state.diagnosticDetails ? this.state.diagnosticDetails.diagnostic : ""}
                            </DialogContentText>
                            <DialogContentText id="alert-dialog-description">
                                <span style={{ fontWeight: "500" }}>{t("Recommendation")}:</span> {this.state.diagnosticDetails ? this.state.diagnosticDetails.recommendation : ""}
                            </DialogContentText>
                            <>
                                <a onClick={(event) => this.handleODRFftLinkClick()} style={{ cursor: 'pointer', color: '#444dcd' }}>{t("Click to see corresponding FFT used to derive this ODR")}</a>
                            </>
                        </DialogContent>
                    </Dialog>
                </div>
            </div>
        )
    }

}
const mapStateToProps = state => {
    return {
        alarmsData: state.alarmsDataReducer,
        fftData: state.fftDataReducer,
        equipmentData: state.equipmentDataReducer,
        updateFftData: state.fftUpdateReducer,
        diagnosticsAlarmsData: state.diagnosticsAlarmsDataReducer
    }
}

const mapDispatchToProps = dispatch => {
    return {
    setLoaderState: (value) => (dispatch(actionCreators.setLoaderState(value))),
        setFftSelectedData: (value) => (dispatch((actionCreators.setFftSelectedData(value)))),
        getDiagnosticsAlarmsData: (deviceId, from, to) => (dispatch(actionCreators.getDiagnosticsAlarmsData(deviceId, from, to))),
        getAlarmsData: (deviceId, from, to) => (dispatch(actionCreators.getAlarmsData(deviceId, from, to))),
        getFFTData: (deviceId, from, to) => (dispatch(actionCreators.getFFTData(deviceId, from, to))),
        getEquipmentData: (deviceId, from, to, direction) => (dispatch(actionCreators.getEquipmentData(deviceId, from, to, direction))),
    }
}

export default withTranslation()(connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(EquipmentHistory)));