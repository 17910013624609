import React, { Component } from 'react';
import { withTranslation } from "react-i18next";


class UtilityConsumption extends Component {
    constructor(props){
        super(props);
    }  
    
    render() {
        const {t}=this.props;
        return (
            <div className="UtilityConsumption-wrp display-text"> 
                <div className="list-selection-wrp display-text" >
                        <a onClick={this.generateReport} className="btn btn-primary" style={{color: "#333",backgroundColor: "#fff",borderColor: "#ccc", padding: "6px 8px"}}>{t("REPORT")}</a>
                  
                </div>
            </div>
        )
    }
}
  
export default withTranslation()(UtilityConsumption);