import React from 'react';
import { Button, Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions } from '@material-ui/core';
import { withTranslation } from "react-i18next";

const MonitorsWithStateCount = (props) => {
    return (
        <Dialog
            open={props.openPromptModal}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            disableBackdropClick={true}
        >
            <DialogTitle id="alert-dialog-title">{props.t("Monitors with")} {props.t(props.entityName)} {props.t("state")}</DialogTitle>
            <DialogContent>
                <DialogContentText id="alert-dialog-description" style={{ color: "grey" }}>
                    <table className="tableCount">
                        <tr>
                            <th className="tableCountth">{props.t("Measurement Location")}</th>
                            <th className="tableCountth">{props.t("Asset")}</th>
                        </tr>
                        {props.props.length !== 0 ? <>

                            {props.props.map((item, key) =>
                                <>
                                    {item && item.monitors.map((monitor, index) =>
                                        <tr key={index + key}>
                                            <td onClick={()=>props.loadMonitoringDashboard(monitor)} className="tableCounttd" style={{color: "#1664c0", cursor: "pointer" }}>
                                                {monitor.monitorName}
                                            </td>
                                            <td className="tableCounttd">
                                                {monitor.machineName}
                                            </td>
                                        </tr>
                                    )}
                                </>
                            )}
                        </> : <tr>
                            <td className="tableCounttd" colSpan="2">
                                {props.t("No records to display")}
                            </td>
                        </tr>}

                    </table>
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button style={{ background: '#42D784', color: "#fff" }} onClick={() => props.handlePromptModal()}>
                     {props.t("OK")}
                </Button>
            </DialogActions>
        </Dialog>
    )
}

export default withTranslation()(MonitorsWithStateCount);