import React, { Component, Fragment } from "react";
import { connect } from 'react-redux';
import { withStyles, IconButton, Tooltip, Button, Switch } from '@material-ui/core';
import MUIDataTable from "mui-datatables";
import { createMuiTheme, MuiThemeProvider } from '@material-ui/core/styles';
import { Edit, Launch, Archive, Unarchive, Delete } from '@material-ui/icons';

import * as actionCreators from '../../actions/actionCreators';
import { linux_deployment_platform } from '../../configuration';
import ScheduledReportsModal from './ScheduledReportsModal';
import CsvDownloadComponent from '../CsvDownloadComponent';
import CsvUploadComponent from '../CsvUploadComponent';
import ConfirmationModal from '../ConfirmationModal';
import DeleteConfirmationModal from "../DeleteConfirmation";
import { withTranslation } from "react-i18next";
let currentLanguage = localStorage.getItem("lang");
if (currentLanguage === "null" || currentLanguage === null) {
    currentLanguage = "en"
}

const styles = theme => ({
    addButton: {
        background: "#2abbe8",
        color: "#504a4a",
        fontSize: "12px",
        fontWeight: "500",
        "&:hover": {
            background: "#2abbe8a1"
        }
    },
    createNewButton: {
        fontWeight: '400',
        marginLeft: 12
    },
    editIcon: {
        'font-size': 18,
        cursor: 'pointer',
        marginLeft:"11px",
        color: 'rgba(0, 200, 83, 0.5)',
        '&:hover': {
            color: 'rgba(0, 200, 83, 1.0)'
        }
    },
    deleteIcon: {
        'font-size': 18,
        cursor: 'pointer',
        color: 'rgba(221, 44, 0, 0.5)',
        '&:hover': {
            color: 'rgba(221, 44, 0, 1.0)'
        }
    },
    removeIcon: {
        'font-size': 18,
        cursor: 'pointer',
        color: 'rgba(221, 44, 0, 0.5)',
        '&:hover': {
            color: 'rgba(221, 44, 0, 1.0)'
        }
    },
    unarchiveIcon: {
        'font-size': 18,
        cursor: 'pointer',
        color: 'rgba(0, 200, 83, 0.5)',
        '&:hover': {
            color: 'rgba(0, 200, 83, 1.0)'
        }
    },
    bulkEditArchiveIcon: {
        cursor: 'pointer',
        color: 'rgba(221, 44, 0, 0.5)',
        '&:hover': {
            color: 'rgba(221, 44, 0, 1.0)'
        }
    },
    bulkEditUnarchiveIcon: {
        cursor: 'pointer',
        color: 'rgba(0, 200, 83, 0.5)',
        '&:hover': {
            color: 'rgba(0, 200, 83, 1.0)'
        }
    },
    flexDiv: {
        display: "flex",
        flexDirection: "row-reverse",
        marginRight: "20px"
    },
    buttonContainer: {
        padding: '5px 10px 10px 0px',
        width: '100%'
    },
    saveButton: {
        backgroundColor: '#2abbe8',
        '&:hover': {
            backgroundColor: '#2abbe8',
        }
    },
    hideHeaderForActiveData: {
        '& thead tr th:nth-child(1)': {
            width: 10
        },
        '& tbody tr td:nth-of-type(1)': {
            padding: '4px 12px',
        },
        '& thead tr th:nth-child(2)': {
            width: 10,
            padding: '4px 15px'
        },
        '& tbody tr td:nth-of-type(2)': {
            padding: '4px 12px',
        },
        '& thead tr th:nth-child(3)': {
            width: 10,
            padding: '4px 30px'
        },
        '& tbody tr td:nth-of-type(3)': {
            padding: '4px 12px',
        },
        '& thead tr th:nth-child(4)': {
            width: 10,
            padding: '4px 30px'
        },
        '& tbody tr td:nth-of-type(4)': {
            padding: '4px 12px',
        },
    },
    hideHeaderForArchivedData: {
        '& thead tr th:nth-child(1)': {
            width: 10
        },
        '& tbody tr td:nth-of-type(1)': {
            padding: '4px 12px',
        },
        '& thead tr th:nth-child(2)': {
            width: 10,
            padding: '4px 15px'
        },
        '& tbody tr td:nth-of-type(2)': {
            padding: '4px 12px',
        },
        '& thead tr th:nth-child(3)': {
            width: 10,
            padding: '4px 30px'
        },
        '& tbody tr td:nth-of-type(3)': {
            padding: '4px 12px',
        }
    },
    buttonLabel: {
        height: 24
    },
    iconBotton: {
        '&:hover': {
            color: '#3f51b5'
        }
    }
});

class ScheduledReportsTable extends Component {

    state = {
        columns: [],
        data: null,
        displayCreateButton: null,
        selectedRows: null,
        enableBulkEditingView: false,
        showArchivedData: false,
        headerClass: "",
        noFlag:false,
        openPromptModal: false,
        openDeletePromptModal: false,
        rowsToBeArchived: [],
        lang:currentLanguage 
        
    }

    getMuiTheme = () => createMuiTheme({
        overrides: {
            MUIDataTableHeadCell: {
                fixedHeader: {
                    'z-index': 0
                },
                hintIconAlone: {
                    marginTop: '2px',
                    fontSize: '14px'
                }
            },
            typography: {
                useNextVariants: true,
            },
            MUIDataTable: {
                responsiveScroll: {
                    height: 'calc(85vh - 90px)',
                    'max-height': 'calc(85vh - 90px) !important',
                    'z-index':'0'
                }
            },
            MuiTableCell: {
                root: {
                    "padding-right": "38px !important",
                    whiteSpace: "pre-line",
                    verticalAlign: "top"
                },
                head: {
                    fontSize: 14,
                    fontWeight: 800,
                    letterSpacing: 0.25
                },
                body: {
                    fontSize: 14,
                    letterSpacing: 0.25,
                }
            }
        }
    })
    //------------------------------------------------------------------------------------
    // handle routing for entity view
    navigateToEntityView = () => {
        this.props.navigateToEntityView("", this.props.location.pathname.split("/")[2]);
    }

    // handle delete functionality of selected record
    onDelete = (rowId, multipleDeletion) => {
        let rowsToBeArchived = [];
        if (multipleDeletion) {
            this.state.selectedRows.forEach(item => {
                rowsToBeArchived.push(item.deviceIdentifier);
            })
        }
        else {
            rowsToBeArchived.push(rowId);
        }

        this.setState({
            rowsToBeArchived,
            openPromptModal: true
        })
    }

    // handle delete functionality of selected record
    onRemove = (rowId, multipleDeletion) => {
        let rowsToBeArchived = [];
        if (multipleDeletion) {
            this.state.selectedRows.forEach(item => {
                rowsToBeArchived.push(item.deviceIdentifier);
            })
        }
        else {
            rowsToBeArchived.push(rowId);
        }

        this.setState({
            rowsToBeArchived,
            openDeletePromptModal: true
        })
    }

    // handle user action for deleting
    handlePromptModal = (action) => {
        if (action === "agree") {
            let selectedtab = this.props.userData.selectedTab;
            let selectedNavTab = this.props.location.pathname.split("/")[2];
            let currentScopeSelectionId = this.getCurrentScopeSelectionId(selectedtab);
            if (this.state.rowsToBeArchived.length > 0) {
                this.props.deleteRow(selectedNavTab, currentScopeSelectionId, this.state.rowsToBeArchived);
            }
        }
        this.setState({
            openPromptModal: false,
            rowsToBeArchived: []
        })
    }

    handleDeletePromptModal = (action) => {
        if (action === "agree") {
            let selectedtab = this.props.userData.selectedTab;
            let selectedNavTab = this.props.location.pathname.split("/")[2];
            let currentScopeSelectionId = this.getCurrentScopeSelectionId(selectedtab);
            if (this.state.rowsToBeArchived.length > 0) {
                this.props.removeRow(selectedNavTab, currentScopeSelectionId, this.state.rowsToBeArchived, this.state.showArchivedData);
            }
        }
        this.setState({
            openDeletePromptModal: false,
            rowsToBeArchived: []
        })
    }

    // gets the id of current plant selected in scope selector
    getCurrentScopeSelectionId = (selectedTab) => {
        let selectedScopeData = "";
        if (this.props.location.pathname.split("/").length === 3) {
            let currentEntity = this.props.location.pathname.split("/")[2];

            if (currentEntity === "OrganizationsTable" || currentEntity === "PlantsTable" || currentEntity === "UsersTable") {
                selectedScopeData = this.props.userData.selectedOrganization;
            }
            else if (currentEntity === "MachineGroupsTable" || currentEntity === "MachinesTable" || currentEntity === "SchedulesTable" ||
                currentEntity === "JobsTable" || currentEntity === "LossCodesTable" || currentEntity === "MonitorsTable" || currentEntity === "DevicesTable" ||
                currentEntity === "AlertsTable" || currentEntity === "ScheduledReportsTable") {
                selectedScopeData = this.props.userData.selectedPlant;
            }
        }
        if (selectedScopeData) {
            return selectedScopeData;
        }
    }

    componentDidMount() {
        let selectedNavTab = this.props.location.pathname.split("/")[2];
        let selectedtab = this.props.userData.selectedTab;
        let currentScopeSelectionId = this.getCurrentScopeSelectionId(selectedtab);
        if (currentScopeSelectionId) {
            this.props.getTableData(selectedNavTab, currentScopeSelectionId, false, this.state.showArchivedData);
            this.displayCreateButton();
            this.setState({noFlag:false})
        }else{
            this.setState({noFlag:true})
        }
    }

    // initializes table component based on the view-definitions received from the api call
    tableConfigurations = () => {
        let columns = [
            {
                "name": "edit",
                "label": "E",
                "options": {
                    "hint": this.props.t("Edit"),
                    "display": true,
                    "filter": false,
                    "sort": false,
                    "searchable": false
                }
            },
            {
                "name": "remove",
                "label": "R",
                "options": {
                    "hint": this.props.t("Delete"),
                    "display": true,
                    "filter": false,
                    "sort": false,
                    "searchable": false
                }
            },
            /*   {
                   "name": "remove",
                   "label": "R",
                   "options": {
                       "hint": "Delete",
                       "display": true,
                       "filter": false,
                       "sort": false,
                       "searchable": false
                   }
               },*/
            {
                "name": "reportType",
                "label": this.props.t("Report Types"),
                "options": {
                    "display": true,
                    "filter": false,
                    "sort": false,
                    "searchable": false
                }
            },
            // {
            //     "name": "reportingType",
            //     "label": this.props.t("Reporting Type"),
            //     "options": {
            //         "display": true,
            //         "filter": false,
            //         "sort": false,
            //         "searchable": false
            //     }
            // },
            {
                "name": "reportingInterval",
                "label": this.props.t("Reporting Interval"),
                "options": {
                    "display": "true",
                    "filter": false,
                    "sort": false,
                    "searchable": false
                }
            },
            {
                "name": "timeZone",
                "label": this.props.t("Time Zone"),
                "options": {
                    "display": true,
                    "filter": false
                }
            },
            {
                "name": "entityType",
                "label": this.props.t("Entity Type"),
                "options": {
                    "display": "true",
                    "filter": false,
                    "sort": false,
                    "searchable": false
                }
            },
            {
                "name": "emailAddresses",
                "label": this.props.t("Email Addresses"),
                "options": {
                    "display": true,
                    "filter": false
                }
            },
            {
                "name": "entityNames",
                "label": this.props.t("Entity Value"),
                "options": {
                    "display": true,
                    "filter": false
                }
            },
            {
                "name": "entityIds",
                "label": this.props.t("Entity Ids"),
                "options": {
                    "display": false,
                    "filter": false
                }
            }
        ];
        let data = [];
        data = this.props.tableData.tableData.schedules.map((item, index) => {
            item.reportType = item.reportType.join('\n');
            item.emailAddresses = item.emailAddresses.join('\n');
            item.entityNames = item.entityNames.join('\n');
            item.edit = <Edit classes={{ root: this.props.classes.editIcon }} onClick={() => this.onEdit(index)} />;
            item.delete = this.state.showArchivedData ? <Unarchive classes={{ root: this.props.classes.unarchiveIcon }} onClick={() => this.onRestore(item.deviceIdentifier, false)} />
                : <Archive classes={{ root: this.props.classes.deleteIcon }} onClick={() => this.onDelete(item.deviceIdentifier, false)} />;
            item.remove = <Delete classes={{ root: this.props.classes.removeIcon }} onClick={() => this.onRemove(item.id, false)} />;
            return item;
        });
        this.setState({
            columns,
            data
        })

    }
    onEdit = (rowIndex) => {
        let object = this.props.tableData.tableData.schedules.find((item, index) => {
            return index === rowIndex
        })
        this.props.navigateToEntityView(object, "ScheduledReportsEntity");
    }
    // display create new button based on user permissions
    displayCreateButton = () => {
         let flag=false;
       let plantPermisssion= localStorage.getItem("plantPermisssion")
       if(this.props.userData.permission ==='configuration' || plantPermisssion === 'configuration'){
           flag=true;
       }
       else if(this.props.userData.permission ==="dashboard" && plantPermisssion === 'configuration' ){
           flag=false
       }
      flag === true ?
            this.setState({ displayCreateButton: true })
            :
            this.setState({ displayCreateButton: false })
    }

    componentDidUpdate(prevProps, prevState) {
        let selectedtab = this.props.userData.selectedTab;
        let selectedNavTab = this.props.location.pathname.split("/")[2];
        if ((prevProps.tableData !== this.props.tableData)) {
            let headerClass = "";
            if (this.props.userData.permission === "configuration") {
                headerClass = this.state.showArchivedData ? this.props.classes.hideHeaderForArchivedData : this.props.classes.hideHeaderForActiveData;
            }
            this.setState({
                headerClass
            })
            if ((prevProps.selectedScopeData !== this.props.selectedScopeData)) {
                let currentScopeSelectionId = this.getCurrentScopeSelectionId(selectedtab);
                this.props.getTableData(selectedNavTab, currentScopeSelectionId, false, this.state.showArchivedData);
                this.displayCreateButton();
            }
            else {
                this.tableConfigurations();
            }

        }
        else if ((prevProps.selectedScopeData !== this.props.selectedScopeData && prevProps.selectedTab === this.props.selectedTab) || prevState.showArchivedData !== this.state.showArchivedData) {
            let currentScopeSelectionId = this.getCurrentScopeSelectionId(selectedtab);
            if (currentScopeSelectionId) {
                this.props.getTableData(selectedNavTab, currentScopeSelectionId, false, this.state.showArchivedData);
                this.displayCreateButton();
            }
        }
        
    }

    // handle switch for displaying archived/active data
    handleSwitch = (event, checked) => {
        this.setState({
            showArchivedData: checked
        })
    }

    // purpose : create csv upload/download icons,user can create new Devices through csv by editing in csv file before uploading 
    //It uses  <CsvDownloadComponent/> and <CsvUploadComponent/>  which is imported from "../CsvUploadComponent" and "../CsvDownloadComponent"
    //This function also creates "Create NEW" button (for creating new Device through UI), which calls navigateToEntityView() function when clicked
    getCustomToolbar = () => {
        if (this.state.displayCreateButton) {
            return (
                <div style={{ display: 'inline-block' }}>
                    { /*
                        this.props.userData.admin && !this.state.showArchivedData ?
                            (
                                <Fragment>
                                    <IconButton className={this.props.classes.iconBotton}>
                                        <CsvDownloadComponent
                                            parentOrganizationId={this.props.userData.parentOrganizationId}
                                            url={"downloadCSVForDevice"}
                                            selectedPlant={this.props.userData.selectedPlant}
                                        />
                                    </IconButton>
                                    <IconButton classes={{ label: this.props.classes.buttonLabel }} className={this.props.classes.iconBotton}>
                                        <CsvUploadComponent
                                            selectedNavTab={"DevicesTable"}
                                            currentScopeSelectionId={this.props.userData.selectedPlant}
                                        />
                                    </IconButton>
                                </Fragment>
                            )
                            : null
                            */ }{
                        /*
                           
                    <Tooltip title={<span style={{ fontSize: "12px" }}>{this.state.showArchivedData ? this.props.t(" Show Active Data") : this.props.t("Show Archived Data")}</span>}>
                        <Switch
                            checked={this.state.showArchivedData}
                            color="primary"
                            disableRipple={false}
                            onChange={this.handleSwitch}
                        />
                    </Tooltip> 
                     */ }
                    <Button variant="outlined" className={this.props.classes.createNewButton} style={{ marginLeft: 12 }}
                        onClick={() => this.navigateToEntityView("", this.props.location.pathname.split("/")[2])}>
                        {this.props.t("Create New")}
                    </Button>
                </div>
            )
        }
        else {
            return null;
        }
    }

    // update selected records for bulk editing
    updateSelectedRecords = (updatedObject) => {
        for (let row of this.state.selectedRows) {
            if (updatedObject.changedDeviceType) {
                row.deviceTypesId = updatedObject.deviceType;
            }
            if (updatedObject.changedNotes) {
                row.notes = updatedObject.notes;
            }
            if (updatedObject.changedSpecs) {
                row.specs = updatedObject.specs;
            }
        }

        let selectedNavTab = this.props.location.pathname.split("/")[2];
        let selectedtab = this.props.userData.selectedTab;
        let currentScopeSelectionId = this.getCurrentScopeSelectionId(selectedtab);

        this.props.updateAll(selectedNavTab, currentScopeSelectionId, this.state.selectedRows);
    }

    // enable bulk editing view
    enableBulkEditingView = () => {
        this.setState({
            enableBulkEditingView: true
        })
    }

    // disable bulk editing view
    disableBulkEditingView = () => {
        this.setState({
            enableBulkEditingView: false
        })
    }

    // create custom toolbar for table view
    getCustomToolbarSelect = () => {
        return (
            <div>
                {/* <IconButton className="class-mk-find" className={this.state.showArchivedData ? 'custom-hideSvg' : null} onClick={this.state.showArchivedData ? () => { return false } : this.enableBulkEditingView}>
                    {this.state.showArchivedData ? null : <Launch />}
                </IconButton>
                <IconButton style={{ marginRight: 14 }} onClick={this.state.showArchivedData ?
                    () => this.onRestore(undefined, true)
                    : () => this.onDelete(undefined, true)
                }>

                    {
                        this.state.showArchivedData ?
                            <Unarchive classes={{ root: this.props.classes.bulkEditUnarchiveIcon }} />
                            : <Archive classes={{ root: this.props.classes.bulkEditArchiveIcon }} />
                    }
                </IconButton>
                */ }
                <IconButton style={{ marginRight: 14 }} onClick={() => this.onRemove(undefined, true)}>
                    <Delete classes={{ root: this.props.classes.deleteIcon }} />
                </IconButton>
            </div>
        )
    }

    // handle multiple record selection
    selectedRows = (currentRowsSelected, allRowsSelected) => {
        let selectedRows = [];
        for (let data of allRowsSelected) {
            let selectedIndex = data.dataIndex;
            let selectedObject = this.state.data[selectedIndex];

            let newObject = {};
            newObject.deviceTypesId = selectedObject.deviceTypes ? selectedObject.deviceTypes.id : null;
            newObject.deviceIdentifier = selectedObject.deviceIdentifier;
            newObject.notes = selectedObject.notes;
            newObject.specs = selectedObject.specs;
            newObject.plantId = this.props.userData.selectedPlant;

            selectedRows.push(newObject)
        }

        this.setState({
            selectedRows
        })
    }

    // validate multiple record selection based on user permissions
    isRowSelectable = () => {
        let isSelectable = linux_deployment_platform ? "none" : false;
        if (this.state.data && this.state.data.length > 0) {
            if (this.props.userData.permission === "configuration") {
                isSelectable = this.state.data.length === 0 ? (linux_deployment_platform ? "none" : false) : false;
            }
        }
        return isSelectable;
    }

    render() {
        const {t}=this.props;
        const options = {
            filter: true,
            sort: true,
            search: true,
            customToolbar: () => (this.getCustomToolbar()),
            print: false,
            elevation: 0,
            responsive: "scroll",
            download: false,
            filterType: 'dropdown',
            rowsPerPage: 20,
            rowsPerPageOptions: [20, 50, 100],
            selectableRows: this.isRowSelectable(),
            onRowsSelect: (currentRowsSelected, allRowsSelected) => this.selectedRows(currentRowsSelected, allRowsSelected),
            customToolbarSelect: () => (this.getCustomToolbarSelect()),
            textLabels: {
                body: {
                    noMatch: this.props.t("No matching records")
                },
                pagination: {
                    next: this.props.t("Next Page"),
                    previous: this.props.t("Previous Page"),
                    rowsPerPage: this.props.t("Rows per Page"),
                    displayRows: this.props.t("of")
                },
                toolbar: {
                    search: this.props.t("Search"),
                    download: this.props.t("Download"),
                    print: this.props.t("Print"),
                    viewColumns: this.props.t("View Columns"),
                    filterTable: this.props.t("Filter Table"),
                    upload: this.props.t("Upload"),
                    // download:currentLanguage==="en" ? "Download":"アップロード",
                    showArchivedData: this.props.t("Show Archieved Data"),
                    showActiveData: this.props.t("Show Active Data")


                },
                filter: {
                    title: this.props.t("FILTERS"),
                    reset: this.props.t("Reset")
                },
                viewColumns: {
                    title: this.props.t("Show Columns")
                },
                selectedRows: {
                    text: this.props.t("Reset"),
                    delete: this.props.t("Delete")
                }

            }
        }

        return (
            <Fragment>
                <ConfirmationModal
                props={this.props}
                    openPromptModal={this.state.openPromptModal}
                    handlePromptModal={(action) => this.handlePromptModal(action)}
                    entityName={t("Scheduled Report/s")}
                />
                <DeleteConfirmationModal
                props={this.props}
                    openPromptModal={this.state.openDeletePromptModal}
                    handleDeletePromptModal={(action) => this.handleDeletePromptModal(action)}
                    entityName={t("Scheduled Report/s")}
                    message={t("Are you sure, you want to delete the selected report schedule?")}
                />
                <div id="tableContainer" className={this.state.headerClass}>
                    <MuiThemeProvider theme={this.getMuiTheme()}>
                        {
                            this.state.data !== null ? (
                                <MUIDataTable
                                    title={t("Scheduled Reports")}
                                    data={this.state.data}
                                    columns={this.state.columns}
                                    options={options}
                                />
                            ) : <Fragment></Fragment>
                        }
                    </MuiThemeProvider>
                    {this.state.noFlag && (<div style={{marginTop:'10%',textAlign:"center",padding:"13px",boxShadow:"0 3px 5px rgb(0 0 0 / 15%)"}}>{this.props.t("Data is not available to display here, as you don't have assigned any plant.")}</div>)}

                </div>
                {/*
                    this.state.enableBulkEditingView ?
                        <DevicesModal
                            config={this.props.config.Devices}
                            staticData={this.props.staticData}
                            parentOrganizationId={this.props.userData.parentOrganizationId}
                            selectedPlant={this.props.userData.selectedPlant}
                            updateSelectedRecords={(state) => (this.updateSelectedRecords(state))}
                            disableBulkEditingView={this.disableBulkEditingView}
                            enableBulkEditingView={this.state.enableBulkEditingView} />
                        : null */
                }
            </Fragment >
        );
    }
}

const mapStateToProps = state => {
    return {
        userData: state.userDataReducer,
        tableData: state.tableDataReducer,
        staticData: state.staticDataReducer
    }
}

const mapDispatchToProps = dispatch => {
    return {
        getTableData: (selectedNavtab, currentScopeSelectionId, setToStore, showArchivedData) => (dispatch(actionCreators.getTableData(selectedNavtab, currentScopeSelectionId, setToStore, showArchivedData))),
        deleteRow: (selectedNavTab, currentScopeSelectionId, requestObject) => (dispatch(actionCreators.deleteRow(selectedNavTab, currentScopeSelectionId, requestObject))),
        removeRow: (selectedNavTab, currentScopeSelectionId, requestObject, showArchivedData) => (dispatch(actionCreators.removeRow(selectedNavTab, currentScopeSelectionId, requestObject, showArchivedData))),
        updateAll: (selectedNavTab, currentScopeSelectionId, allRecords) => (dispatch(actionCreators.updateAll(selectedNavTab, currentScopeSelectionId, allRecords))),
        restoreEntity: (entityName, rowsToBeUnarchived, selectedNavtab, currentScopeSelectionId, setToStore, showArchivedData) => (dispatch(actionCreators.restoreEntity(entityName, rowsToBeUnarchived, selectedNavtab, currentScopeSelectionId, setToStore, showArchivedData)))
    }
}

export default withTranslation()(connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(ScheduledReportsTable)));
