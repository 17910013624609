import React, { Component, Fragment } from 'react';
import Highcharts from 'highcharts';
import {
    OutlinedInput, InputLabel, Select,
    Link, Dialog, Typography, Tooltip, DialogContent, Divider, DialogActions, Button, TableRow, TableHead, TableCell, Table, TableBody, DialogTitle
} from '@material-ui/core';
import { Close, ChevronRight, ExpandMore } from '@material-ui/icons';
import RefreshIcon from '@material-ui/icons/Refresh';
import { withStyles } from '@material-ui/core';
import { createMuiTheme, MuiThemeProvider } from '@material-ui/core/styles';
import MUIDataTable, { TableBodyRow } from "mui-datatables";
import Brightness1Icon from '@material-ui/icons/Brightness1';
import { withTranslation } from "react-i18next";
import { config } from '../../../configuration';
import moment from "moment";
import { getSynDisplayValue } from '../../../utils';


const clonedeep = require('lodash.clonedeep')
const styles = theme => ({
    loaderColor: {
        color: '#2abbe8'
    },
    dialogTitle: {
        display: 'flex',
        'justify-content': 'space-between',
        'align-items': 'center',
        padding: '10px 24px'
    },
    dialogContent: {
        padding: '0 24px'
    },
    
});
const statusColors = {
    1: "#64dd17",
    2: "#ffc107",
    3: "#ff5722"
}


const colors = {
    1: "#31ccec",
    2: "#64dd17",
    3: "#ffc107",
    4: "#ff5722",
    0: "#9e9e9e",
    5: "#9e9e9e",
    6: "#800080"
}

export class SummaryPlantPanel extends Component {
    constructor(props) {
        super(props);
        this.state = {
            top: false,
            left: false,
            bottom: false,
            right: false,
            data: 0,
            columns: [],
            tabledata: null,
            isPieFilter: false,
            filter: null,
            options: {},
            openDialog: false,
            record: {},
            openFlag: {},
            odrEnable: true,
            fftEnable: true,
            mostHealthy: "",
            leastHealthy: "",
            series: [{
                name: this.props.t('Index'),
                data: [
                    {
                        name: this.props.t("Health Score > 80 %"),
                        y: 7,
                        color: '#64dd17',
                        bg: 'rgb(100, 221, 23,0.1)',
                        id: "operational"
                    },
                    {
                        name: this.props.t("Health Score > 50 % < 80%"),
                        y: 25,
                        color: '#ffc107',
                        bg: 'rgb(255, 193, 7,0.1)',
                        id: "caution"
                    },
                    {
                        name: this.props.t("Health Score < 50%"),
                        y: 17,
                        color: '#ff5722',
                        bg: 'rgb(255, 87, 34,0.1)',
                        id: "warning"
                    },
                    {
                        name: "",
                        y: 17,
                        color: '#fff',
                        bg: 'whitesmoke',
                        id: "disconnected",

                    }

                ]
            }],
            optionsC: {
                filter: false,
                search: true,
                sort: true,
                print: false,
                download: false,
                viewColumns: false,
                elevation: 0,
                responsive: "scroll",
                selectableRows: "none",
                rowsPerPage: 20,
                rowsPerPageOptions: [20, 50, 100],
                expandableRows: true,// Try Adding This
                rowsExpanded: [],
                onRowClick: this.onRowClick,
                renderExpandableRow: (rowData, rowMeta) => {
                    return (
                        <React.Fragment>
                            <tr>
                                <td colSpan={8}>
                                    {/* <TableContainer component={Paper}> */}
                                    <Table style={{ minWidth: "650" }} aria-label="simple table">
                                        {
                                            // ((rowData[8] && rowData[8].trim() !== "") || (rowData[9] && rowData[9].trim() !== "") || (rowData[10] && rowData[10].trim() !== ""))?

                                            (
                                                <TableHead>
                                                    <TableRow>
                                                        <TableCell>
                                                            {rowData[6] && rowData[6].trim() !== "" ? this.props.t("Observation") : <div style={{ textAlign: "center" }}> {this.props.t("Observation")}</div>}
                                                        </TableCell>
                                                        <TableCell>
                                                            {rowData[7] && rowData[7].trim() !== "" ? this.props.t("Diagnostic") : <div style={{ textAlign: "center" }}>{this.props.t("Diagnostic")}</div>}
                                                        </TableCell>
                                                        <TableCell>
                                                            {rowData[8] && rowData[8].trim() !== "" ? this.props.t("Recommendation") : <div style={{ textAlign: "center" }}>{this.props.t("Recommendation")}</div>}
                                                        </TableCell>
                                                    </TableRow>
                                                </TableHead>)
                                            //  : ""
                                        }
                                        <TableBody>
                                            {

                                                (<TableRow key={rowData[0]}>

                                                    <TableCell style={{ width: "33%",wordBreak:"break-word" }}>
                                                        {rowData[6] && rowData[6].trim() !== "" ? rowData[6] : <div style={{ textAlign: "center" }}>-</div>}
                                                    </TableCell >
                                                    <TableCell style={{ width: "33%",wordBreak:"break-word" }} >
                                                        {rowData[7] && rowData[7].trim() !== "" ? rowData[7] : <div style={{ textAlign: "center" }}>-</div>}
                                                    </TableCell>
                                                    <TableCell style={{ width: "33%" ,wordBreak:"break-word"}} >
                                                        {rowData[8] && rowData[8].trim() !== "" ? rowData[8] : <div style={{ textAlign: "center" }}>-</div>}
                                                    </TableCell>
                                                </TableRow>

                                                )
                                                // :
                                                // (
                                                //     <TableRow key={rowData[0]}>

                                                //         <TableCell align="center" style={{ width: "100%" }}>
                                                //             No Data To Display
                                                //         </TableCell >
                                                //     </TableRow>

                                                // )
                                            }

                                        </TableBody>
                                    </Table>
                                    {/* </TableContainer> */}
                                </td>
                            </tr>

                        </React.Fragment>
                    );
                },
                textLabels: {
                    body: {
                        noMatch: this.props.t("No matching records"),
                        toolTip: this.props.t("Sort"),
                    },
                    pagination: {
                        next: this.props.t("Next Page"),
                        previous: this.props.t("Previous Page"),
                        rowsPerPage: this.props.t("Rows per Page"),
                        displayRows: this.props.t("of")
                    },
                    toolbar: {
                        search: this.props.t("Search"),
                        download: this.props.t("Download"),
                        print: this.props.t("Print"),
                        viewColumns: this.props.t("View Columns"),
                        filterTable: this.props.t("Filter Table"),
                        upload: this.props.t("Upload"),
                        // download:currentLanguage==="en" ? "Download":"アップロード",
                        showArchivedData: this.props.t("Show Archieved Data"),
                        showActiveData: this.props.t("Show Active Data")
    
    
                    },
                    filter: {
                        title: this.props.t("FILTERS"),
                        reset: this.props.t("Reset")
                    },
                    viewColumns: {
                        title: this.props.t("Show Columns")
                    },
                    selectedRows: {
                        text: this.props.t("Reset"),
                        delete: this.props.t("Delete")
                    }
    
                }
            },
            lang:''
        }


    }

    getMuiTheme = () => createMuiTheme({
        overrides: {
            MUIDataTableHeadCell: {
                fixedHeader: {
                    'z-index': 0
                },
                hintIconAlone: {
                    marginTop: '2px',
                    fontSize: '14px'
                }
            },
            MUIDataTableFilter: {
                root: {
                    'width': '382px !important'
                }
            },

            MUIDataTableSelectCell: {
                "icon": {
                    // Soft hide the button.
                    color: '#1665c0'
                },
                fixedHeaderYAxis: {
                    width: '15px'
                },
            },

            typography: {
                useNextVariants: true,
            },
            MUIDataTable: {
                responsiveScroll: {
                    // width: '990px',
                    height: 'calc(85vh - 150px)',
                    'max-height': 'calc(85vh - 150px) !important',
                    'z-index': '0'
                }
            },
            MuiTableCell: {
                root: {
                    "padding-right": "0px !important"
                },
                head: {
                    fontSize: 14,
                    fontWeight: 800,
                    letterSpacing: 0.25
                },
                body: {
                    fontSize: 14,
                    letterSpacing: 0.25,
                    // width: 50,
                }
            }
        }
    })


    highChartsRender(series) {
        Highcharts.chart({
            chart: {
                type: 'pie',
                renderTo: 'piePanel',
                height: '350px'
            },
            title: {
                verticalAlign: 'middle',
                floating: true,
                text: '',
                style: {
                    fontSize: '10px',
                }
            },
            credits: {
                "enabled": false
            },
            exporting: {
                "enabled": false
            },
            plotOptions: {
                pie: {
                    borderColor: '#d0d0d0',
                    dataLabels: {
                        enabled: false
                    },
                    showInLegend: true,
                    size: '200',
                }
            },
            series: series
        });
    }

    subscription = (item) => {
        var today = new Date().getTime();
        var status = (today > item.subscriptionStart && today < item.subscriptionEnd && ((item.subscriptionEnd - today) / (1000 * 3600 * 24)) > 30) ? 1 :
            ((today > item.subscriptionStart && today < item.subscriptionEnd && ((item.subscriptionEnd - today) / (1000 * 3600 * 24)) < 30 ? 2 : 3))
        var subStart = item.subscriptionStart ? new Date(item.subscriptionStart).getDate() + "/" + (new Date(item.subscriptionStart).getMonth() + 1) + "/" + new Date(item.subscriptionStart).getFullYear() : null;
        var subEnd = item.subscriptionEnd ? new Date(item.subscriptionEnd).getDate() + "/" + (new Date(item.subscriptionEnd).getMonth() + 1) + "/" + new Date(item.subscriptionEnd).getFullYear() : null;
        var subscriptionRemain = status === 1 || status === 2 ? Math.ceil((item.subscriptionEnd - today) / (1000 * 3600 * 24)) : 0;
        item.subscriptionStatus = <Tooltip title={subStart && subEnd ? this.props.t("Subscription Start") + ": " + subStart + ' &' + this.props.t("Subscription End") + ': ' + subEnd : "-"} aria-label="status"><span>{(subscriptionRemain===0?this.props.t("0 Days") :subscriptionRemain+ " " + this.props.t("Days"))}<Brightness1Icon style={{ fontSize: "18", marginLeft: "2px", color: statusColors[status] }} /></span></Tooltip>
        return item.subscriptionStatus

    }
    
    componentDidMount() {
        let selectedOrg = this.props.props.userData && this.props.props.userData.selectedOrganization;
        if (selectedOrg == undefined) {
            let rootOrganization = this.props.props.userData.scopeSelector.subOrganizations.find((organization) => (
                organization.root
            ))
            selectedOrg = rootOrganization.id;
        }
        let odrFftFlag = this.props.props.userData && this.props.props.userData.scopeSelector.subOrganizations.find(ele => ele.id === selectedOrg);
        if (odrFftFlag !== undefined) {
            if(!odrFftFlag.odrEnable){
            this.setState({
                odrEnable: odrFftFlag.odrEnable,
               
            },()=>{
                this.optionsCall(odrFftFlag.odrEnable)
            })
        }
        }
        this.setState({lang:this.props && this.props.props.i18n.language},()=>{
            this.optionsCall(odrFftFlag.odrEnable)
        })
       }

    componentDidUpdate(prevProps,prevState) {
        if(prevProps.props.i18n.language !== prevState.lang){
            this.setState({lang:this.props.props.i18n.language},()=>{
                const operational = this.props.healthScoreData && this.props.healthScoreData.averagePlantHealthScoreDetails ? this.props.healthScoreData.averagePlantHealthScoreDetails.moreThan80 : [];
                const caution = this.props.healthScoreData && this.props.healthScoreData.averagePlantHealthScoreDetails ? this.props.healthScoreData.averagePlantHealthScoreDetails.between50And80 : [];
                const warning = this.props.healthScoreData && this.props.healthScoreData.averagePlantHealthScoreDetails ? this.props.healthScoreData.averagePlantHealthScoreDetails.lessThan50 : [];
                const disconnected = this.props.healthScoreData && this.props.healthScoreData.averagePlantHealthScoreDetails ? this.props.healthScoreData.averagePlantHealthScoreDetails.healthScoreUnavailable : [];
                let series = [{
                    name: this.props.t('Index'),
                    data: [
                        {
                            name: this.props.t("Health Score > 80 %"),
                            y: operational ? operational : 0,
                            color: '#64dd17',
                            bg: 'rgb(100, 221, 23,0.1)',
                            id: "operational"
                        },
                        {
                            name: this.props.t("Health Score > 50 % < 80%"),
                            y: caution ? caution : 0,
                            color: '#ffc107',
                            bg: 'rgb(255, 193, 7,0.1)',
                            id: "caution"
                        },
                        {
                            name: this.props.t("Health Score < 50%"),
                            y: warning ? warning : 0,
                            color: '#ff5722',
                            bg: 'rgb(255, 87, 34,0.1)',
                            id: "warning"
                        },
                        {
                            name: this.props.t("health Scores not available"),
                            y: disconnected ? disconnected : 0,
                            color: '#fff',
                            bg: '#fff',
                            id: "disconnected"
                        },
    
                    ]
                }];
    
                this.setState({ series: series })
                this.highChartsRender(series);
                this.optionsCall(this.state.odrEnable);
            });
            
            this.tableConfigurations();
        }

        if(prevProps.healthScoreData !== this.props.healthScoreData){
            this.setState({
                mostHealthy: this.props.healthScoreData && this.props.healthScoreData.mostHealthyPlant !== undefined || this.props.healthScoreData.mostHealthyPlant !== null ? this.props.healthScoreData.mostHealthyPlant : "",
                leastHealthy: this.props.healthScoreData && this.props.healthScoreData.leastHealthyPlant !== undefined || this.props.healthScoreData.leastHealthyPlant !== undefined ? this.props.healthScoreData.leastHealthyPlant : "",
            })

            const operational = this.props.healthScoreData && this.props.healthScoreData.averagePlantHealthScoreDetails ? this.props.healthScoreData.averagePlantHealthScoreDetails.moreThan80 : [];
            const caution = this.props.healthScoreData && this.props.healthScoreData.averagePlantHealthScoreDetails ? this.props.healthScoreData.averagePlantHealthScoreDetails.between50And80 : [];
            const warning = this.props.healthScoreData && this.props.healthScoreData.averagePlantHealthScoreDetails ? this.props.healthScoreData.averagePlantHealthScoreDetails.lessThan50 : [];
            const disconnected = this.props.healthScoreData && this.props.healthScoreData.averagePlantHealthScoreDetails ? this.props.healthScoreData.averagePlantHealthScoreDetails.healthScoreUnavailable : [];
            
            let series = [{
                name: this.props.t('Index'),
                data: [
                    {
                        name: this.props.t("Health Score > 80 %"),
                        y: operational ? operational : 0,
                        color: '#64dd17',
                        bg: 'rgb(100, 221, 23,0.1)',
                        id: "operational"
                    },
                    {
                        name: this.props.t("Health Score > 50 % < 80%"),
                        y: caution ? caution : 0,
                        color: '#ffc107',
                        bg: 'rgb(255, 193, 7,0.1)',
                        id: "caution"
                    },
                    {
                        name: this.props.t("Health Score < 50%"),
                        y: warning ? warning : 0,
                        color: '#ff5722',
                        bg: 'rgb(255, 87, 34,0.1)',
                        id: "warning"
                    },
                    {
                        name: this.props.t("health Scores not available"),
                        y: disconnected ? disconnected : 0,
                        color: '#fff',
                        bg: '#fff',
                        id: "disconnected"
                    },

                ]
            }];

            this.setState({ series: series })
            this.highChartsRender(series);
        }

        if ((prevProps.summaryData.summaryData !== this.props.summaryData.summaryData) ) {         
            this.setState({ options: clonedeep(this.state.optionsC) })

            if (this.props.summaryData && this.props.summaryData.summaryData && this.props.summaryData.summaryData.machines) 
                this.tableConfigurations()

            this.props.onSummaryDone();
        }
    }
    optionsCall=(flag)=>{
        let optionsC= {
            filter: false,
            search: true,
            sort: true,
            print: false,
            download: false,
            viewColumns: false,
            elevation: 0,
            responsive: "scroll",
            selectableRows: "none",
            rowsPerPage: 20,
            rowsPerPageOptions: [20, 50, 100],
            expandableRows: flag ,// Try Adding This
            rowsExpanded: [],
            onRowClick: this.onRowClick,
            renderExpandableRow: (rowData, rowMeta) => {
                return (
                    <React.Fragment>
                        <tr>
                            <td colSpan={8}>
                                {/* <TableContainer component={Paper}> */}
                                <Table style={{ minWidth: "650" }} aria-label="simple table">
                                    {
                                        // ((rowData[8] && rowData[8].trim() !== "") || (rowData[9] && rowData[9].trim() !== "") || (rowData[10] && rowData[10].trim() !== ""))?

                                        (
                                            <TableHead>
                                                    <TableRow>
                                                        <TableCell>
                                                            {rowData[6] && rowData[6].trim() !== "" ? this.props.t("Observation") : <div style={{ textAlign: "center" }}> {this.props.t("Observation")}</div>}
                                                        </TableCell>
                                                        <TableCell>
                                                            {rowData[7] && rowData[7].trim() !== "" ? this.props.t("Diagnostic") : <div style={{ textAlign: "center" }}>{this.props.t("Diagnostic")}</div>}
                                                        </TableCell>
                                                        <TableCell>
                                                            {rowData[8] && rowData[8].trim() !== "" ? this.props.t("Recommendation") : <div style={{ textAlign: "center" }}>{this.props.t("Recommendation")}</div>}
                                                        </TableCell>
                                                    </TableRow>
                                                </TableHead>)
                                        //  : ""
                                    }
                                    <TableBody>
                                        {

                                            (<TableRow key={rowData[0]}>

                                                <TableCell style={{ width: "33%" ,wordBreak:"break-word"}}>
                                                    {rowData[6] && rowData[6].trim() !== "" ? rowData[6] : <div style={{ textAlign: "center" }}>-</div>}
                                                </TableCell >
                                                <TableCell style={{ width: "33%",wordBreak:"break-word" }} >
                                                    {rowData[7] && rowData[7].trim() !== "" ? rowData[7] : <div style={{ textAlign: "center" }}>-</div>}
                                                </TableCell>
                                                <TableCell style={{ width: "33%" ,wordBreak:"break-word"}} >
                                                    {rowData[8] && rowData[8].trim() !== "" ? rowData[8] : <div style={{ textAlign: "center" }}>-</div>}
                                                </TableCell>
                                            </TableRow>

                                            )
                                            // :
                                            // (
                                            //     <TableRow key={rowData[0]}>

                                            //         <TableCell align="center" style={{ width: "100%" }}>
                                            //             No Data To Display
                                            //         </TableCell >
                                            //     </TableRow>

                                            // )
                                        }

                                    </TableBody>
                                </Table>
                                {/* </TableContainer> */}
                            </td>
                        </tr>

                    </React.Fragment>
                );
            },
            textLabels: {
                body: {
                    noMatch: this.props.t("No matching records"),
                    toolTip: this.props.t("Sort"),
                },
                pagination: {
                    next: this.props.t("Next Page"),
                    previous: this.props.t("Previous Page"),
                    rowsPerPage: this.props.t("Rows per Page"),
                    displayRows: this.props.t("of")
                },
                toolbar: {
                    search: this.props.t("Search"),
                    download: this.props.t("Download"),
                    print: this.props.t("Print"),
                    viewColumns: this.props.t("View Columns"),
                    filterTable: this.props.t("Filter Table"),
                    upload: this.props.t("Upload"),
                    // download:currentLanguage==="en" ? "Download":"アップロード",
                    showArchivedData: this.props.t("Show Archieved Data"),
                    showActiveData: this.props.t("Show Active Data")


                },
                filter: {
                    title: this.props.t("FILTERS"),
                    reset: this.props.t("Reset")
                },
                viewColumns: {
                    title: this.props.t("Show Columns")
                },
                selectedRows: {
                    text: this.props.t("Reset"),
                    delete: this.props.t("Delete")
                }

            }
        }
        this.setState({options: clonedeep(optionsC)})

    }
    compare = (a, b) => {
        var val1 = a.healthScore && a.healthScore !== 0 ? a.healthScore : 99999;
        var val2 = b.healthScore && b.healthScore !== 0 ? b.healthScore : 99999;
        if (val1 < val2) {
            return -1;
        }
        if (val1 > val2) {
            return 1;
        }
        return 0;
    }

    reoprtedDRS = (id) => {
        window.open(`${config.url.relible_url}reports-doc/${id}`)
    }
    tableConfigurations = () => {
        let columns = [
            {
                "name": "machineId",
                "label": "monitorId",
                "options": {
                    "display": false,
                    "filter": false,
                    "sort": false,
                    "searchable": false
                }
            },
            {
                "name": "plantName",
                "label": this.props.t("Plant Name"),
                "options": {
                    "display": true,
                    "filter": false,
                    "sort": true,
                    "searchable": true,
                    "customBodyRender": (value) => <a onClick={() => this.loadMonitoringDashboard(value)}><span style={{ color: "#1664c0", cursor: "pointer" }}>{value}</span></a>,
                    setCellProps: () => ({ style: { width: '200px',wordBreak:"break-word",display:"table-cell",padding:"15px" } }),


                }
            },
            {
                "name": "machineName",
                "label": this.props.t("Asset"),
                "options": {
                    "display": true,
                    "filter": false,
                    "sort": true,
                    "searchable": true,
                    "customBodyRender": (value, tableMeta) => <a onClick={() => this.loadSummaryDashboard(tableMeta.rowData[1], value)}><span style={{ color: "#1664c0", cursor: "pointer" }}>{value}</span></a>,
                    setCellProps: () => ({ style: { width:"200px",wordBreak:"break-word" } })
                }
            },
            {
                "name": "trend",
                "label": this.props.t("Vibration Trend"),
                "options": {
                    "display": true,
                    "filter": false,
                    "sort": true,
                    "searchable": false,
                    setCellProps: () => ({ style: {width: '200px' } }),
                    "customBodyRender": (value) => <div><span  style={{marginLeft:value > 0  ?"13px":"40px"}}>{value === 3 ? this.props.t("Significant rise") : value === 2 ? this.props.t("Minor rise") : value === 1 ? this.props.t("No rise") : value === null ? "-":"-"}</span></div>
                }
            },
            {
                "name": "synPriority",
                "label": this.props.t("Syndication Priority"),
                "options": {
                    "display": this.props.summaryData && this.props.summaryData.summaryData && this.props.summaryData.summaryData.showSynPriority,
                    "filter": false,
                    "sort": true,
                    "searchable": false,
                    setCellProps: () => ({ style: {
                        width: "180px", 
                        minWidth: "180px",
                        whiteSpace: "nowrap",
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                        textAlign: "center"
                } }),
                    "customBodyRender": (value) => <div>{getSynDisplayValue(value)}</div>,
                }
            },
            {
                "name": "healthScore",
                "label": this.props.t("Asset Health Score"),
                "options": {
                    "display": this.state.odrEnable === true ? true : false,
                    "filter": false,
                    "sort": true,
                    "searchable": false,
                    setCellProps: () => ({ style: { width: '200px' } }),
                    "customBodyRender": (value, tableMeta) => value && value >= 0 ? <div><span style={{ marginLeft: "30px" }}>{value === 101 ? "NA" : value + "%"}
                        <Tooltip title={tableMeta && tableMeta.rowData && tableMeta.rowData[15] ? this.props.t(tableMeta.rowData[15]) : null} placement='right'>
                            <sup style={{ marginLeft: "5px", color: "red" }}>
                                {tableMeta && tableMeta.rowData && tableMeta.rowData[14] ? tableMeta.rowData[14] : ""}
                            </sup>
                        </Tooltip>
                    </span>
                        {tableMeta.rowData[11] && tableMeta.rowData[11] !== "" && <div style={{ cursor: "pointer" }} onClick={() => this.reoprtedDRS(tableMeta.rowData[12])}><span style={{ background: "#468bd2", color: "#fff", fontSize: "11px", padding: "4px" }}>{tableMeta.rowData[11] && tableMeta.rowData[11] !== "COMPLETED" ? this.props.t("Reported on") + " " + tableMeta.rowData[10] : this.props.t("Completed on") + " " + tableMeta.rowData[13]}</span></div>}
                    </div> : <div> <span style={{ marginLeft: "30px" }}>{"NA"}</span></div>

                }
            },
            {
                "name": "observation",
                "label": this.props.t("observation"),
                "options": {
                    "display": false,
                    "filter": false,
                    "sort": false,
                    "searchable": false
                }
            }, {
                "name": "diagnostic",
                "label": this.props.t("diagnostic"),
                "options": {
                    "display": false,
                    "filter": false,
                    "sort": false,
                    "searchable": false
                }
            },
            {
                "name": "recommendation",
                "label": this.props.t("recommendation"),
                "options": {
                    "display": false,
                    "filter": false,
                    "sort": false,
                    "searchable": false
                }
            },
            // {
            //     "name": "subscriptionStatus",
            //     "label": this.props.t("Subscription Status"),
            //     "options": {
            //         "display": true,
            //         "filter": false,
            //         "sort": false,
            //         "searchable": false,
            //         setCellProps: () => ({ style: { textAlign: "center" } })
            //     }
            // }
            {
                "name": "healthTrend",
                "label": this.props.t("Health Trend"),
                "options": {
                    "display": true,
                    "filter": false,
                    "sort": true,
                    "searchable": false,
                    setCellProps: () => ({ style: { width: "250px" } }),
                    "customBodyRender": (value) => <div style={{width:"100px",marginLeft:value >0 ?"0px":"35px"}}><span>{value === 3 ? this.props.t("Deteriorating fast") : value === 2 ? this.props.t("Deteriorating gradually") : value === 1 ? this.props.t("Stable") : value === null ? "-":"-"}</span></div>
                }
            },
            
            {
                "name": "createdDate",
                "label": "createdDate",
                "options": {
                    "display": false,
                    "filter": false,
                    "sort": false,
                    "searchable": false
                }
            },
            {
                "name": "plantStatus",
                "label": "plantStatus",
                "options": {
                    "display": false,
                    "filter": false,
                    "sort": false,
                    "searchable": false
                }
            },
            {
                "name": "uniqueId",
                "label": "uniqueId",
                "options": {
                    "display": false,
                    "filter": false,
                    "sort": false,
                    "searchable": false
                }
            },
            {
                "name": "completionDate",
                "label": "completionDate",
                "options": {
                    "display": false,
                    "filter": false,
                    "sort": false,
                    "searchable": false
                }
            },
            {
                "name": "healthScoreIntelligenceSymbol",
                "label": "healthScoreIntelligenceSymbol",
                "options": {
                    "display": false,
                    "filter": false,
                    "sort": false,
                    "searchable": false
                }
            },
            {
                "name": "healthScoreIntelligenceHoverText",
                "label": "healthScoreIntelligenceHoverText",
                "options": {
                    "display": false,
                    "filter": false,
                    "sort": false,
                    "searchable": false
                }
            },
        ];

        /* if (this.props.summaryData && this.props.summaryData.summaryData && this.props.summaryData.summaryData.monitors && this.props.summaryData.summaryData.monitors.length <= 20) {
            columns.push({
                "name": "estimatedAlarmForecast",
                "label": "Estimated Alarm Forecast",
                "options": {
                    "display": true,
                    "filter": false,
                    "sort": false,
                    "searchable": false
                }
            })
        } */

        let tabledata = [];
        // let arr=[10,20,70,80,90]
        if (this.props.summaryData && this.props.summaryData.summaryData && this.props.summaryData.summaryData.machines) {
            var summarydata = [...this.props.summaryData.summaryData.machines]
            tabledata = summarydata.map((item, index) => {
                item.trend = item.trend !== null ? item.trend : 0;

                if (item && item.healthScore) {
                    item.healthScore=((item.healthScore !==null) && (item.healthScore !==0)) ?item.healthScore:101;
                } else { 
                    item.healthScore=101;
                }

                item.createdDate =  item.serviceRequestCreatedDate ? moment(item.serviceRequestCreatedDate).format("DD MMM YY") : "";
                item.plantStatus = item.serviceRequestStatus || "";
                item.uniqueId = item.serviceRequestId || "";
                item.completionDate =  item.serviceRequestCompletionDate ? moment(item.serviceRequestCompletionDate).format("DD MMM YY") : "";
                item.healthScoreIntelligenceSymbol = item.superscript;
                item.healthScoreIntelligenceHoverText = item.superscriptMessage;

                return item;
            });

        }
        

        this.setState({
            columns,
            tabledata

        },()=>{
            this.optionsCall(this.state.odrEnable)
        })
    }
    openDialog = (data) => {
        var record = this.state.tabledata.find(x => x.monitorId === data);
        this.setState({ record: record });
        this.setState({
            openDialog: true
        });
    }
    closeDialog = () => {
        this.setState({
            openDialog: false
        });
    }
    loadMonitoringDashboardLeast(value){
        let index=value.indexOf("(")
        let str=value.slice(0,(index-1));
        this.loadMonitoringDashboard(str);
    }

    loadMonitoringDashboard(value) {
        this.props.actionList(value)
        localStorage.setItem("dashboard", "summary");

    }
    loadSummaryDashboard(plantName ,value){
        this.props.searchText(plantName,value)
        localStorage.setItem("dashboard", "summary");
    }
    getEquipementHealthScore = () => {
        let arr = [
            { "key": "All", value: this.props.t("All") },
            { "key": "<=50", "value": "<=50" },
            { "key": "<=10", "value": "<=10" },
            { "key": "<20", "value": "<20" },
            { "key": "<30", "value": "<30" },
            { "key": "<40", "value": "<40" },
            // { "key": "<50", "value": "<50" },
            { "key": "<60", "value": "<60" },
            { "key": "<70", "value": "<70" },
            { "key": "<80", "value": "<80" },
            { "key": ">=80", "value": ">=80" }

        ]
        let equipmentHealth = [];
        equipmentHealth.push(<option key={""} disabled={true} value={""}>{this.props.t("Select")}</option>);
        arr.map(fbb =>
            equipmentHealth.push(<option key={fbb.key} value={fbb.key}>{fbb.value}</option>)
        );


        return equipmentHealth;
    }
    render() {
        const renderLink = () => {
            return <Link
                component="button"
                variant="body2"
                title={this.props.t("Refresh Data")}
                onClick={() => {
                    this.props.onRefreshSummaryPanel()
                }}>
                <RefreshIcon></RefreshIcon>
            </Link>
        }

        const { series } = this.state;
        const { t } = this.props;
        
        return (
            <div className="col-sm-12" style={{ paddingTop: "10px", backgroundColor: "white" }}>
                <div className="row">
                    <div className="col-sm-3" style={{ borderRight: "1px solid", borderColor: "grey" }}>
                        <div className="col-sm-12 row" style={{ marginTop: "20px" }}>
                            <div className="col-sm-11" title={this.props.summaryData && this.props.summaryData.summaryData ? this.props.summaryData.summaryData.plantName : ""} style={{
                                maxWidth: "99%", overflow: "auto", wordBreak: "break-word", alignContent: "center", fontSize: "14px", fontWeight: "500", color: "#1664c0"
                            }}>
                                <span>{t("User")}</span> : <span> {this.props.props && this.props.props.userData && this.props.props.userData.email}</span>
                            </div>
                            <div className="col-sm-1" style={{ padding: "0px" }}>
                                <div className="float-right">
                                    {renderLink()}
                                </div>
                            </div>
                        </div>                        
                        <div className="col-sm-12">
                            <div id="piePanel" style={{ marginRight: 'auto', marginLeft: 'auto' }}></div>
                        </div>
                        {this.props.healthScoreData && this.props.healthScoreData.averagePlantHealthScoreDetails && this.props.healthScoreData.averagePlantHealthScoreDetails.healthScoreUnavailable === 100 &&
                            <div className="col-sm-12" style={{  textAlign: "center", fontSize: "13px", fontWeight: "500", color: "red",top:"-112px" }}>
                                {t("Health score data is not available.")}
                            </div>

                        }
                        <div className="row col-sm-12" style={{ marginLeft: "16px" }}>
                            <div className="col-sm-12" style={{ fontSize: "14px", fontWeight: "bold" }}>{t("Most Healthy Plant")}:</div>
                            <div className="col-sm-12" style={{ fontSize: "14px", fontWeight: "bold", color: "rgb(22, 100, 192)" ,cursor:this.state.mostHealthy && 'pointer'}} onClick={()=>this.state.mostHealthy&& this.loadMonitoringDashboardLeast(this.state.mostHealthy)}>{this.state.mostHealthy === null ? "-" : this.state.mostHealthy}</div>
                            <div className="col-sm-12" style={{ fontSize: "14px", fontWeight: "bold" }}>{t("Least Healthy Plant")}:</div>
                            <div className="col-sm-12" style={{ fontSize: "14px", fontWeight: "bold", color: "rgb(22, 100, 192)" ,cursor:this.state.leastHealthy && 'pointer'}} onClick={()=>this.state.leastHealthy&& this.loadMonitoringDashboardLeast(this.state.leastHealthy)}>{this.state.leastHealthy === null ? "-" : this.state.leastHealthy}</div>
                        </div>
                    </div>
                    <div className="col-sm-9" style={{ paddingRight: "0px" }}>
                        <div className="row" style={{
                            textAlign: "center", fontSize: "15px", fontWeight: "500", color: "#1664c0", width: "100%"
                        }}>
                            <div className="col-sm-2" style={{ borderRight: "1px solid", borderColor: "grey" }}>
                                {t("Total Plants")}: <div>{this.props.healthScoreData && this.props.healthScoreData.totalPlants ? this.props.healthScoreData.totalPlants : 0}</div>
                            </div>
                            <div className="col-sm-2" style={{ borderRight: "1px solid", borderColor: "grey" }}>
                                {t("Total Areas")}: <div>{this.props.healthScoreData && this.props.healthScoreData.totalAreas ? this.props.healthScoreData.totalAreas : 0}</div>
                            </div>
                            <div className="col-sm-2" style={{ borderRight: "1px solid", borderColor: "grey" }}>
                                {t("Total Equipment")}: <div>{this.props.healthScoreData && this.props.healthScoreData.totalEquipment ? this.props.healthScoreData.totalEquipment : 0}</div>
                            </div>
                            <div className="col-sm-2" style={{ borderRight: "1px solid", borderColor: "grey" }}>
                                {t("Total Assets")}: <div>{this.props.healthScoreData && this.props.healthScoreData.totalAssets ? this.props.healthScoreData.totalAssets : 0}</div>
                            </div>
                            <div className="col-sm-4" style={{ textAlign: "center" }}>
                                {t("Total Measurement Locations")}: <div>{this.props.healthScoreData && this.props.healthScoreData.totalMeasurementLocations ? this.props.healthScoreData.totalMeasurementLocations : 0}</div>
                            </div>
                        </div>
                        <div className="col-sm-12" style={{ paddingRight: "0px" }}>
                            <MuiThemeProvider theme={this.getMuiTheme()}>
                                {
                                    this.state.tabledata !== null ? (
                                        <MUIDataTable
                                            style={{ minWidth: "100%" }}
                                            data={this.state.tabledata}
                                            columns={this.state.columns}
                                            options={{
                                                serverSide: true,
                                                count: this.props.summaryPlantPagination.total,
                                                page: this.props.summaryPlantPagination.pageNumber,
                                                onTableChange: this.props.onTableChange, 
                                                ...this.state.options, 
                                            }}
                                        />
                                    ) : <Fragment></Fragment>
                                }
                            </MuiThemeProvider>
                            <div style={{ zIndex: 1, position: "absolute", top: "16px", right: "77px", fontSize: "14px" }}>
                                <div className="inputFieldDivPlant">
                                    <InputLabel className="textFieldLabelPlant" style={{ padding: "10px" }}>
                                        {t("Equipment with Health Score")}
                                    </InputLabel>
                                    <Select
                                        native
                                        value={this.props.selectedHealthScoreVal}
                                        onChange={(event) => this.props.inputChangeHandler(event)}
                                        input={
                                            <OutlinedInput
                                                variant="outlined"
                                                name="displayPosition"
                                                id="language-native-simple"
                                                className="textFieldPlant"
                                                style={{ height: "30px" }}
                                            // classes={{ input: this.props.props.classes.outlinedInput }}
                                            />
                                        }
                                    >
                                        {this.getEquipementHealthScore()}
                                    </Select>
                                </div>
                            </div>
                        </div>
                        <Dialog
                            open={this.state.openDialog}
                            onClose={this.closeDialog}
                            maxWidth={'md'}
                            aria-labelledby="alert-dialog-title"
                            aria-describedby="alert-dialog-description"
                        >
                            <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', padding: '10px 24px' }}>
                                <Typography variant="h6">
                                    {t("Diagnostic & Recommendation")}
                                </Typography>
                                <Close onClick={(event) => this.closeDialog()} style={{ cursor: 'pointer' }} />
                            </div>
                            <Divider />

                            <DialogContent >
                                <div className="row" style={{ marginTop: "10px" }}>
                                    <div className="col-sm-12 row">
                                        <div className="col-sm-3" style={{ fontWeight: "500" }}>{t("Asset")}:</div>
                                        <div className="col-sm-9">{this.state.record.machineName}</div>
                                    </div>
                                    <div className="col-sm-12 row">
                                        <div className="col-sm-3" style={{ fontWeight: "500" }}>{t("Measurement Location")}:</div>
                                        <div className="col-sm-9">{this.state.record.monitorName}</div>
                                    </div>
                                    <div className="col-sm-12 row">
                                        <div className="col-sm-3" style={{ fontWeight: "500" }}>{t("Health Score")}:</div>
                                        <div className="col-sm-9">{this.state.record.healthScore + "%"}</div>
                                    </div>
                                    <div className="col-sm-12 row">
                                        <div className="col-sm-3" style={{ fontWeight: "500" }}>{t("Observation")}:</div>
                                        <div className="col-sm-9">{this.state.record.observation}</div>
                                    </div>
                                    <div className="col-sm-12 row">
                                        <div className="col-sm-3" style={{ fontWeight: "500" }}>{t("Diagnostic")}:</div>
                                        <div className="col-sm-9">{this.state.record.diagnostic}</div>
                                    </div>
                                    <div className="col-sm-12 row">
                                        <div className="col-sm-3" style={{ fontWeight: "500" }}>{t("Recommentation")}:</div>
                                        <div className="col-sm-9">{this.state.record.recommendation}</div>
                                    </div>
                                </div>
                            </DialogContent>
                            <DialogActions>

                                <Button onClick={(event) => this.closeDialog()} color="primary" autoFocus>
                                    {t("Ok")}
                                </Button>
                            </DialogActions>
                        </Dialog>
                    </div>
                </div>
            </div >
        )
    }
}

export default withTranslation()((withStyles(styles)(SummaryPlantPanel)));