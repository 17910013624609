import React, { Component } from 'react'
import { connect } from 'react-redux';
import ProductionHistoryGraph from './productionHistoryGraph';
import moment from "moment";
import * as actionCreators from '../../../actions/actionCreators';

class ProductionHistoryContainer extends Component {
    constructor(props){
        super(props);
        this.state={
            data : '',
            tickList: []
        };
        this.chartData = [];
    }  

    componentDidMount(){
        if(Object.entries(this.props.machineProductionHistory).length !== 0){
            this.chartData = [];
            let keys = Object.keys(this.props.machineProductionHistory.machineProductionHistory)
            keys.forEach(element => {
                this.createChartData(this.props.machineProductionHistory.machineProductionHistory[element].machineName, this.props.machineProductionHistory.machineProductionHistory[element].state,this.props.machineProductionHistory.machineProductionHistory[element].machineId);
            });
        }
        
    }
   
    componentDidUpdate(prevProps){
        if(prevProps.machineProductionHistory !== this.props.machineProductionHistory){
            this.chartData = [];
            let keys = Object.keys(this.props.machineProductionHistory.machineProductionHistory)
            keys.forEach(element => {
                this.createChartData(this.props.machineProductionHistory.machineProductionHistory[element].machineName, this.props.machineProductionHistory.machineProductionHistory[element].state,this.props.machineProductionHistory.machineProductionHistory[element].machineId);
            });
        }

        if(prevProps.showBookedLoss !== this.props.showBookedLoss || prevProps.showPlannedDowntime !== this.props.showPlannedDowntime){
            this.chartData = [];
            let keys = Object.keys(this.props.machineProductionHistory.machineProductionHistory)
            
            keys.forEach(element => {
                this.createChartData(this.props.machineProductionHistory.machineProductionHistory[element].machineName, this.props.machineProductionHistory.machineProductionHistory[element].state,this.props.machineProductionHistory.machineProductionHistory[element].machineId);
            });
        }
        
        if(prevProps.showPlannedDowntime !== this.props.showPlannedDowntime){
            let plannedDownTime = this.props.showPlannedDowntime ? 1 : 0;
            let from = moment(this.props.range[1]).utc().format("YYYY-MM-DDTHH:mm:00") + "Z";
            let to = moment(this.props.range[2]).utc().format("YYYY-MM-DDTHH:mm:00") + "Z";
            this.props.getMachineProductionHistory(this.props.selectedPlantId,from,to,plannedDownTime);
        }
    }

    createChartData(machineName, state, machineId){
        let data = [];
        let labels = [""];
        let values = [machineName];
        let totalDuration = 0;
        state.forEach(element => {
            if(element.state === 1){ //Idle
                totalDuration += element.duration;
                labels.push('Idle');
                labels.push({ role: 'style' });
                labels.push({ type: 'string', role: 'tooltip' });

                values.push(element.duration * 1000);
                values.push('color:#0070c0');
                values.push(`Idle :  ${moment(element.duration*1000).format('HH:mm:ss')}, ${moment(element.begin).utc().local().format("YYYY-MM-DD HH:mm:ss")} to ${moment(element.end).utc().local().format("YYYY-MM-DD HH:mm:ss")}`);
            }else if(element.state === 2){ //Production
                totalDuration += element.duration;
                labels.push('Production');
                labels.push({ role: 'style' });
                labels.push({ type: 'string', role: 'tooltip' });

                values.push(element.duration  * 1000);
                values.push('color:#109618');
                values.push(`Production :  ${moment(element.duration*1000).format('HH:mm:ss')}, ${moment(element.begin).utc().local().format("YYYY-MM-DD HH:mm:ss")} to ${moment(element.end).utc().local().format("YYYY-MM-DD HH:mm:ss")}`);
            }else if(element.state === 3){ //Disconnected
                totalDuration += element.duration;
                labels.push('Disconnected');
                labels.push({ role: 'style' });
                labels.push({ type: 'string', role: 'tooltip' });

                values.push(element.duration * 1000);
                values.push('color:#999999');
                values.push(`Disconnected : ${moment(element.duration*1000).format('HH:mm:ss')}, ${moment(element.begin).utc().local().format("YYYY-MM-DD HH:mm:ss")} to ${moment(element.end).utc().local().format("YYYY-MM-DD HH:mm:ss")}`);
            }else if(element.state === 4){ //Booked Loss
                if(this.props.showBookedLoss){
                    totalDuration += element.duration;
                    labels.push('Booked Loss');
                    labels.push({ role: 'style' });
                    labels.push({ type: 'string', role: 'tooltip' });

                    let lossName = this.props.lossList.hasOwnProperty('inputData') ? this.props.lossList.inputData.find(ele=> parseInt(ele.id) === parseInt(element.loss.lossId)) : '';
                    values.push(element.duration * 1000);
                    values.push('color:#ff0000');
                    values.push(`LossId: ${lossName.value ? lossName.value : '' } \n SubcategoryID: ${element.loss.subCategoryId} \n  Disconnected : ${moment(element.duration*1000).format('HH:mm:ss')}, ${moment(element.begin).utc().local().format("YYYY-MM-DD HH:mm:ss")} to ${moment(element.end).utc().local().format("YYYY-MM-DD HH:mm:ss")} \n Operator Name: ${element.loss.operatorName} Part Name:${element.loss.partNo}  Part Number: ${element.loss.partNo}`);
                }
            }else if(element.state === 5){ // Planned Downtime
                if(this.props.showPlannedDowntime){
                    totalDuration += element.duration;
                    labels.push('Planned Downtime');
                    labels.push({ role: 'style' });
                    labels.push({ type: 'string', role: 'tooltip' });


                    values.push(element.duration * 1000);
                    values.push('color:#cccc00');
                    // values.push(`Planned Downtime:${element.plannedDownTime} \n Disconnected : ${element.duration}, ${element.begin} to ${element.end}`);
                    values.push(`Planned Downtime`);
                }
            }
        });
        data.push(labels);
        data.push(values);
        this.chartData.push(data);
        let tickList = [];
        var starttime = parseInt(parseInt(moment(state[0].begin).format('X')) / 60);

        for (var k = 0; k < 4; k++) {
            var min = parseInt((totalDuration * 1000) / 3 * k);

            var time = moment((starttime) * 60 * 1000 + min).format("YYYY-MM-DD HH:mm:ss");
            tickList.push({v:min, f:time});
        }
        this.setState({data: [...this.chartData], tickList: tickList,machineName: machineId})
    }

    render() {
        return (
            <div className="productionHistory-cntr" style={{height: "340px",overflowY: "scroll",marginLeft:"8px"}}> 
                { this.state.data.length > 0 ?                         
                    this.state.data.map((item,index) =>
                        <ProductionHistoryGraph bookedLoss={this.props.showBookedLoss} plannedDowntime={this.props.showPlannedDowntime} range={this.props.range} lossList= {this.props.lossList} key={index} selectedPlantId={this.props.selectedPlantId} rawData={this.props.machineProductionHistory.machineProductionHistory} machineName={this.state.machineName} data={item} tickList={this.state.tickList}/>
                    )
                    : null
                }
            </div>
        )
    }
}
const mapStateToProps = state => {
    return {
        machineProductionHistory : state.machineProductionHistoryReducer
    }
  }
  const mapDispatchToProps = dispatch => {
    return {
        getMachineProductionHistory:(plantId , from, to , pd) => (dispatch((actionCreators.getMachineProductionHistory(plantId,from, to, pd)))),
    }
  }

// export default ProductionHistoryContainer;
export default connect(mapStateToProps, mapDispatchToProps )(ProductionHistoryContainer);