import React from 'react';
import { withStyles, Tooltip } from '@material-ui/core';

const styles = theme => ({
    scheduleDay: {
        color: 'rgba(0, 0, 0, 0.4000000059604645)',
        fontSize: 14,
        fontWeight: 500,
        lineHeight: 2.5,
        letterSpacing: 0.5
    }
})

class Timeline extends React.Component {

    state = {
        data: {}
    }

    shouldComponentUpdate(newProps) {
        if (newProps.scheduleData === this.state.data) {
            return false;
        }
        return true;
    }

    componentDidUpdate(prevProps) {
        if (prevProps.scheduleData !== this.props.scheduleData) {
            this.setState({
                data: {}
            },
                () => {
                    this.setState({
                        data: JSON.parse(JSON.stringify(this.props.scheduleData))
                    })
                }
            )
        }
    }

    componentDidMount() {
        this.setState({
            data: JSON.parse(JSON.stringify(this.props.scheduleData))
        })
    }
// handle view of breaks
    getBreaks = (breaks, shiftStartTime, shiftEndTime, scheduleArray) => {
        let breaksArray = [];
        for (let currentBreak in breaks) {

            let timeDiff = breaks[currentBreak].timeDiff.split(':');
            let timeInMinutes = parseInt(timeDiff[0] * 60) + parseInt(timeDiff[1]);
            let relativeTimeDifference = breaks[currentBreak].shiftRelativeTimeDiff.split(':');
            let relativeTimeDifferenceInMinutes = parseInt(relativeTimeDifference[0] * 60) + parseInt(relativeTimeDifference[1]);
            let startTime = breaks[currentBreak].startTime.split(':');
            let endTime = breaks[currentBreak].endTime.split(':');
            let newendtime = "";
            let startnext = "";
            if ((parseInt(shiftStartTime) > parseInt(shiftEndTime))) {

                if (parseInt(startTime[0]) > parseInt(endTime[0])) {
                    newendtime = "24:00:00";
                    timeInMinutes = (24 - parseInt(startTime[0])) * 60 - parseInt(startTime[1]);
                    startnext = "00:00:00";
                    let tooltip = currentBreak + ": " + breaks[currentBreak]["startTime"] + "to" + newendtime;
                    breaksArray.push(
                        <Tooltip key={currentBreak} title={tooltip}>
                            <div style={{ width: timeInMinutes * 0.5, height: 20, backgroundColor: '#F5A623', display: 'inline-block', boxSizing: 'border-box', position: 'absolute', left: relativeTimeDifferenceInMinutes * 0.5 }}>
                            </div>
                        </Tooltip>
                    )
                    timeInMinutes = parseInt(endTime[0]) * 60 + parseInt(endTime[1]);
                    relativeTimeDifferenceInMinutes = 0;

                }
                else if (parseInt(startTime[0]) >= 12 && parseInt(endTime[0]) <= 24) {
                    let tooltip = currentBreak + ": " + breaks[currentBreak]["startTime"] + "to " + breaks[currentBreak]["endTime"];
                    breaksArray.push(
                        <Tooltip key={currentBreak} title={tooltip}>
                            <div style={{ width: timeInMinutes * 0.5, height: 20, backgroundColor: '#F5A623', display: 'inline-block', boxSizing: 'border-box', position: 'absolute', left: relativeTimeDifferenceInMinutes * 0.5 }}>
                            </div>
                        </Tooltip>
                    )
                    timeInMinutes = 0;
                }
                else {
                    startnext = breaks[currentBreak]["startTime"];
                    relativeTimeDifferenceInMinutes = parseInt(startTime[0]) * 60 + parseInt(startTime[1]);

                }
                scheduleArray.carryOverBreak[0] = startnext;
                scheduleArray.carryOverBreak[1] = breaks[currentBreak]["endTime"];
                scheduleArray.carryOverBreak[2] = timeInMinutes;
                scheduleArray.carryOverBreak[3] = currentBreak;
                scheduleArray.carryOverBreak[4] = relativeTimeDifferenceInMinutes;
            }

            else {

                let tooltip = currentBreak + ": " + breaks[currentBreak]["startTime"] + " to " + breaks[currentBreak]["endTime"];
                breaksArray.push(
                    <Tooltip key={currentBreak} title={tooltip}>
                        <div style={{ width: timeInMinutes * 0.5, height: 20, backgroundColor: '#F5A623', display: 'inline-block', boxSizing: 'border-box', position: 'absolute', left: relativeTimeDifferenceInMinutes * 0.5 }}>
                        </div>
                    </Tooltip>
                )
            }
        }
        return breaksArray;
    }
// handle days view 
    getDay = (day, scheduleArray) => {
        let timelineArray = [];
        let timelineEnd = "";
        let data = JSON.parse(JSON.stringify(this.state.data));

        for (let shift in data[day].shifts) {

            let validCarryOver = false;

            if (scheduleArray.carryOverSchedule.length > 0) {
                switch (day) {
                    case 'Mon': if (scheduleArray.carryOverSchedule[3] === 'Sun') { validCarryOver = true; }
                        break;
                    case 'Tue': if (scheduleArray.carryOverSchedule[3] === 'Mon') { validCarryOver = true; }
                        break;
                    case 'Wed': if (scheduleArray.carryOverSchedule[3] === 'Tue') { validCarryOver = true; }
                        break;
                    case 'Thu': if (scheduleArray.carryOverSchedule[3] === 'Wed') { validCarryOver = true; }
                        break;
                    case 'Fri': if (scheduleArray.carryOverSchedule[3] === 'Thu') { validCarryOver = true; }
                        break;
                    case 'Sat': if (scheduleArray.carryOverSchedule[3] === 'Fri') { validCarryOver = true; }
                        break;
                    case 'Sun': if (scheduleArray.carryOverSchedule[3] === 'Sat') { validCarryOver = true; }
                        break;
                    default:
                        break;
                }
            }

            if (validCarryOver) {
                let tooltip = "previous day's shift:" + scheduleArray.carryOverSchedule[0] + " to " + scheduleArray.carryOverSchedule[1];
                let tooltip2 = "previous day's break:" + scheduleArray.carryOverBreak[0] + " to " + scheduleArray.carryOverBreak[1];

                timelineArray.push(
                    <Tooltip key={shift} title={tooltip}>
                        <div style={{ width: scheduleArray.carryOverSchedule[2] * 0.5, height: 20, backgroundColor: '#58BA57', display: 'inline-block', borderLeft: '1px solid black', borderRight: '1px solid black', boxSizing: 'border-box', position: 'relative' }}>
                            <Tooltip key={scheduleArray.carryOverBreak[3]} title={tooltip2}>
                                <div style={{ width: scheduleArray.carryOverBreak[2] * 0.5, height: 20, backgroundColor: '#F5A623', display: 'inline-block', boxSizing: 'border-box', position: 'absolute', left: scheduleArray.carryOverBreak[4] * 0.5 }}>
                                </div>
                            </Tooltip>
                        </div>
                    </Tooltip>
                )

                timelineEnd = scheduleArray.carryOverSchedule[1];
                scheduleArray.carryOverSchedule = [];
                scheduleArray.carryOverBreak = [];
            }

            let timeDiff = data[day].shifts[shift].timeDiff.split(':');
            let startTime = data[day].shifts[shift].startTime.split(':');
            let endTime = data[day].shifts[shift].endTime.split(':');
            let previousEndTime = timelineEnd.split(':');
            let freeTime = 0;

            if (timelineEnd === "") {
                freeTime = (parseInt(startTime[0]) * 60) + (parseInt(startTime[1]));
            }
            else {
                freeTime = ((parseInt(startTime[0]) - parseInt(previousEndTime[0])) * 60) + (parseInt(startTime[1]) - parseInt(previousEndTime[1]));
            }

            timelineArray.push(
                <Tooltip key={"Free Shift"} title={"No shifts scheduled"}>
                    <div style={{ width: freeTime * 0.5, height: 20, backgroundColor: '#FFFFFF', display: 'inline-block', position: 'relative' }}>
                    </div>
                </Tooltip>
            )


            let timeInMinutes = parseInt(timeDiff[0] * 60) + parseInt(timeDiff[1]);
            let newendtime = "";
            let startnext = "";
            if (parseInt(startTime[0]) > 12 && parseInt(endTime[0]) < 12) {
                newendtime = "24:00";
                timeInMinutes = (24 - parseInt(startTime[0])) * 60 - parseInt(startTime[1]);
                startnext = "00:00";
                scheduleArray.carryOverSchedule[0] = startnext;
                scheduleArray.carryOverSchedule[1] = data[day].shifts[shift]["endTime"];
                scheduleArray.carryOverSchedule[2] = parseInt(endTime[0]) * 60 + parseInt(endTime[1]);
                scheduleArray.carryOverSchedule[3] = day;

                let tooltip = shift + ": " + data[day].shifts[shift]["startTime"] + " to " + newendtime;
                timelineArray.push(
                    <Tooltip key={shift} title={tooltip}>
                        <div style={{ width: timeInMinutes * 0.5, height: 20, backgroundColor: '#58BA57', display: 'inline-block', borderLeft: '1px solid black', borderRight: '1px solid black', boxSizing: 'boredr-box', position: 'relative' }}>
                            {this.getBreaks(data[day].shifts[shift]["breaks"], startTime[0], endTime[0], scheduleArray)}
                        </div>
                    </Tooltip>
                )
            }
            else {
                let tooltip = shift + ": " + data[day].shifts[shift]["startTime"] + " to " + data[day].shifts[shift]["endTime"];
                timelineArray.push(
                    <Tooltip key={shift} title={tooltip}>
                        <div style={{ width: timeInMinutes * 0.5, height: 20, backgroundColor: '#58BA57', display: 'inline-block', borderLeft: '1px solid black', borderRight: '1px solid black', boxSizing: 'boredr-box', position: 'relative' }}>
                            {this.getBreaks(data[day].shifts[shift]["breaks"], startTime[0], endTime[0], scheduleArray)}
                        </div>
                    </Tooltip>
                )
                timelineEnd = data[day].shifts[shift]["endTime"];
            }
        }
        scheduleArray.timelineData = timelineArray;
        return scheduleArray;
    }
// handle timeline
    getTimeline = () => {
        let timelineArrayForWidget = [];
        let scheduleArray = {};

        scheduleArray['carryOverSchedule'] = [];
        scheduleArray['timelineData'] = [];
        scheduleArray['carryOverBreak'] = [];

        let data = JSON.parse(JSON.stringify(this.state.data));

        for (let day in data) {
            timelineArrayForWidget.push(
                <div style={{ display: 'flex', alignItems: 'center' }}>
                    <span style={{ display: 'inline-block', width: 40, marginLeft: '-47px' }} className={this.props.classes.scheduleDay}>{day}</span><span style={{ marginLeft: '0px' }}>{this.getDay(day, scheduleArray).timelineData}</span>
                </div>
            )
        }
        timelineArrayForWidget.splice(0, 1);
        for (let day in data) {
            timelineArrayForWidget.unshift(
                <div style={{ display: 'flex', alignItems: 'center' }}>
                    <span style={{ display: 'inline-block', width: 40, marginLeft: '-47px' }} className={this.props.classes.scheduleDay}>{day}</span><span>{this.getDay(day, scheduleArray).timelineData}</span>
                </div>
            )
            break;
        }
        return timelineArrayForWidget;
    }
// handle time interval
    getTimeInterval = () => {
        let timeIntervalArray = [];
        for (let i = 0; i <= 9; i++) {
            timeIntervalArray.push(
                <span style={{ fontSize: '12px', textAlign: 'left', position: 'relative', borderBottom: '1px solid #000', lineHeight: '0.1em' }} >
                    <span style={{
                        background: '#fff',
                        paddingRight: '23px'
                    }}>
                        {i}
                    </span>
                </span>
            )
        }
        for (let i = 10; i <= 24; i++) {
            timeIntervalArray.push(
                <span style={{ fontSize: '12px', textAlign: 'left', position: 'relative', borderBottom: '1px solid #000', lineHeight: '0.1em' }} >
                    <span style={{
                        background: '#fff',
                        paddingRight: '17px'
                    }}>
                        {i}
                    </span>
                </span>
            )
        }
        return timeIntervalArray;
    }

    render() {
        return (
            <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-end', width: '81%', marginLeft: '19%' }}>
                <div style={{ position: 'relative', width: 722, marginBottom: 5 }} id="scheduleScale">
                    {this.getTimeInterval()}
                </div>
                <div style={{
                    position: 'relative',
                    width: '722px',
                    height: '260px',
                    border: '0.02em solid #D3D3D3',
                    backgroundImage: ' repeating-linear-gradient(to right,#D3D3D3,#D3D3D3 0.3%,transparent 0.3%, transparent 4.147%)',
                }}>
                    <div style={{ position: 'absolute', top: '10px', left: '8px', width: '100%', }}>
                        {this.getTimeline()}
                    </div>
                </div>
            </div>
        )
    }
}

export default withStyles(styles)(Timeline);