import React, { Component } from 'react';
import * as actionCreators from '../../actions/actionCreators';
import { connect } from 'react-redux';
import { KeyboardBackspace } from '@material-ui/icons';
import {
    Card,
    CardContent,
    InputLabel,
    TextField,
    withStyles,
    Button,
    Grid,
    Paper,
    Tabs,
    Tab
} from '@material-ui/core';
import Checkbox from '@material-ui/core/Checkbox'
import { withTranslation } from "react-i18next";

import Timeline from '../TimelineWidget';
import WidgetComponents from '../../components/WidgetComponents';

const styles = theme => ({
    cardContent: {
        height: '91%',
        "overflow-y": "auto"
    },
    cardContentRoot: {
        '&:last-child': {
            paddingBottom: 16
        }
    },
    card: {
        width: '100%',
        borderRadius: 0,
        height: '80vh'
    },
    textField: {
        width: '60%',
        marginRight: 20
    },
    textArea: {
        width: '80%',
        marginRight: 20
    },
    yamlTextArea: {
        width: '100%',
        marginTop: '1%',
        backgroundColor: '#f2f3f8'
    },
    outlinedInput: {
        padding: 14
    },
    saveButton: {
        fontSize: "14px",
        fontWeight: "500",
        textAlign: "center",
        color: "#ffffff",
        backgroundColor: "#42D784",
        '&:hover': {
            backgroundColor: '#42d784'
        },
        '&:disabled': {
            backgroundColor: '#f6f6f6'
        },
        borderRadius: "3px",
        boxShadow: "0 0 1px 0 rgba(0, 0, 0, 0.05)",
        width: "110px",
        height: "36px",
        marginRight: "24px"
    },
    addPlantsButton: {
        float: 'right',
        color: '#42D784',
        marginLeft: 10
    },
    inputFieldDiv: {
        display: "flex",
        justifyContent: "space-between",
        width: '50%',
        float: 'left',
        paddingTop: '1%',
        height: 80,
        'align-items': 'baseline'
    },
    textareaInputFieldDiv: {
        display: "flex",
        justifyContent: "space-between",
        width: '100%',
        float: 'left',
        paddingTop: '1%',
        height: 180,
        'align-items': 'baseline'
    },
    textFieldLabel: {
        color: 'rgba(0, 0, 0, 0.4000000059604645)',
        'letter-spacing': 0.5,
        'font-weight': 500,
        fontSize: "14px",
        lineHeight: 2.5,
        width: '30%',
        marginLeft: 20,
        //text-align': 'right'
    },
    textareaFieldLabel: {
        color: 'rgba(0, 0, 0, 0.4000000059604645)',
        'letter-spacing': 0.5,
        'font-weight': 500,
        fontSize: "14px",
        lineHeight: 2.5,
        width: '15%',
        marginLeft: 20,
        //'text-align': 'right'
    },
    requiredClass: {
        color: 'red'
    },
    actionButtonContainer: {
        display: 'flex',
        'justify-content': 'space-between',
        height: 50,
        'border-bottom': '1px solid rgba(207, 216, 220, 0.5)'
    },
    backButtonContainer: {
        display: 'flex',
        'align-items': 'center',
        paddingLeft: 21
    },
    arrowBack: {
        cursor: 'pointer'
    },
    entityTitle: {
        color: 'rgba(0, 0, 0, 0.8)',
        'font-family': 'Roboto',
        'font-size': 21,
        'font-weight': '400',
        'letter-spacing': 0.5,
        marginLeft: 10
    },
    paper: {
        borderRadius: 0,
        marginBottom: 1,
        boxShadow: '0 0 0 0',
        backgroundColor: '#f2f3f8',
        height: '10vh',
        clear: "both"
    },
    enabledTabs: {
        color: 'rgba(24, 32, 44, 0.8700000047683716)',
        letterSpacing: 0.1
    },
    selectedTab: {
        color: '#5ACBE5 !important'
    },
    tabIndicator: {
        backgroundColor: '#5ACBE5'
    },
    settingsModal: {
        'min-width': 630,
        'max-height': 589,
        'min-height': 565
    },
    dialogTitle: {
        display: 'flex',
        'justify-content': 'space-between',
        'align-items': 'center',
        padding: '10px 24px'
    },
})

class SchedulesEntity extends Component {

    state = {
        id: "",
        plantsId: "",
        disableSaveButton: true,
        plantName: "",
        notes: "",
        name: "",
        specs: "",
        validationData: "",
        NameError: false,
        SpecsError: false,
        NotesError: false,
        labelWidth: 0,
        defaultSchedule: false,
        selectedTab: 0
    }

    //function to setstate of checkbox
    toggleCheckbox = () => {
        this.setState({
            defaultSchedule: !this.state.defaultSchedule,
            disableSaveButton: false,
        });

    }

    // handle widget/yaml view
    handleTabChange = (event, value) => {
        this.setState({
            selectedTab: value
        },
            () => {
                if (this.state.specs) {
                    this.getScheduleTypeSpecs();
                }
            })
    };

    // handle addition of new shift/break in widget view
    addNewShiftOrBreak = (day, shiftOrBreak, data) => {
        let specs = JSON.parse(JSON.stringify(this.state.specs));

        outer: for(let dayObject of specs[0].children) {
            if(dayObject["key"] === day) {
                for(let shiftObject of dayObject.children) {
                    if(shiftObject["key"] === shiftOrBreak) {
                        if(shiftObject.children === null) {
                            shiftObject.children = [];
                        }
                        shiftObject.children.push(data);
                        break outer;
                    }
                }
            }
        }

        this.setState({
            specs,
            disableSaveButton: false
        })
    }

    // handle deletion of a shift/break 
    deleteCondition = (levelIndicator) => {
        let specs = [...this.state.specs];
        let deleteItem = {};

        levelIndicator.forEach((level, index) => {
            let splittedArray = level.split(":");
            if (Object.keys(deleteItem).length === 0) {
                deleteItem = specs[splittedArray[1]];
            }
            else {
                if (index < levelIndicator.length - 1) {
                    deleteItem = deleteItem.children[splittedArray[1]];
                }
            }
        })

        deleteItem.children.splice((levelIndicator[(levelIndicator.length - 1)].split(":"))[1], 1);

        this.setState({
            specs,
            disableSaveButton: false
        })
    }

    // update the state for widget view
    updateStateForValue = (value, incomingLevelIndicator, error, errorMessage) => {
        let specs = [...this.state.specs];
        let currentObject = {};
        let levelIndicator = [];
        levelIndicator = incomingLevelIndicator.map(level => {
            return level.split(":")[0];
        })

        levelIndicator.forEach((key) => {
            if (((Object.keys(currentObject)).length) === 0) {
                currentObject = specs.find((item) => (item.key === key))
            }
            else {
                currentObject = currentObject.children.find((item) => (item.key === key));
            }
        })
        currentObject["value"] = value;
        currentObject["error"] = error;
        currentObject["errorMessage"] = errorMessage;

        this.setState({
            specs,
            disableSaveButton: false
        })
    }

    // display specs widget view
    getSpecView = () => {
        return (
            <div>
                {this.state.specs && typeof this.state.specs === "object" ?
                    <WidgetComponents
                        jsonData={this.state.specs}
                        updateStateForValue={(value, levelIndicator, error, errorMessage) => (this.updateStateForValue(value, levelIndicator, error, errorMessage))}
                        entityName={"schedules"}
                        cloneSchedule={(specs) => (this.cloneSchedule(specs))}
                        deleteCondition={(levelIndicator) => (this.deleteCondition(levelIndicator))}
                        addNewShiftOrBreak={(day, shiftOrBreak, data) => (this.addNewShiftOrBreak(day, shiftOrBreak, data))}
                    />
                    : null}
            </div>
        )
    }

    // clone selected day's schedule for other days as specified by the user
    cloneSchedule = (specs) => {
        this.setState({
            specs,
            disableSaveButton: false
        })
    }

    // display specs yaml view
    getYamlView = () => {
        return (
            <div>
                {
                    typeof this.state.specs === "string" ?
                        <TextField
                            id="specification"
                            multiline
                            rows="60"
                            //helperText={(this.state.validationData.specs.validations && this.state.validationData.specs.validations.helpText) ? (this.state.validationData.specs.validations.helpText) : null}
                            value={this.state.specs}
                            variant="outlined"
                            onChange={(event) => this.inputChangeHandler(event, "specs")}
                            className={this.props.classes.yamlTextArea}
                            //error={this.state.specsError}
                            InputProps={{
                                classes: {
                                    multiline: this.props.classes.outlinedInput
                                }
                            }}
                        />
                        : null
                }
            </div>
        )
    }

    // get schedule specs for selected schedule type 
    getScheduleTypeSpecs = (isNew) => {
        if (isNew) {
            if (this.state.selectedTab === 0) {
                let plantOrganizationId = "";
                let scheduleTypeObject = null;
                if (this.state.plantsId) {
                    this.props.scopeSelector.subOrganizations.map((organization) => {
                        if (organization.plants && organization.plants.length > 0) {
                            organization.plants.map((plant) => {
                                if (plant.id === this.state.plantsId) {
                                    plantOrganizationId = organization.id;
                                }
                            })
                        }
                    })
                }
                scheduleTypeObject = this.props.staticData.staticData[plantOrganizationId].scheduleTypes.find(item => {
                    if (item.id) {
                        return item;
                    }
                })
                if (scheduleTypeObject.specs.trim() !== "") {
                    this.props.getJsonFromYaml({ "entityType": "schedules", "specs": scheduleTypeObject.specs }, "schedules");
                }
            }
            else if (this.state.selectedTab === 1) {
                this.props.getYamlFromJson(this.state.specs, "schedules");
            }
        }
        else {
            if (this.state.selectedTab === 0) {
                if (typeof this.state.specs === "string" && this.state.specs.trim() !== "") {
                    this.props.getJsonFromYaml({ "entityType": "schedules", "specs": this.state.specs }, "schedules");
                }
            }
            else if (this.state.selectedTab === 1) {
                if (typeof this.state.specs === "object") {
                    this.props.getYamlFromJson(this.state.specs, "schedules");
                }
            }
        }
    }

    // Handle changes to any user input field
    inputChangeHandler = (event, field) => {
        switch (field) {
            case "scheduleName": this.setState({ name: event.target.value, disableSaveButton: false });
                this.checkValidation("scheduleName", event.target.value);
                break;
            case "specs": this.setState({ specs: event.target.value, disableSaveButton: false });
                this.checkValidation("specs", event.target.value);
                break;
            case "notes": this.setState({ notes: event.target.value, disableSaveButton: false });
                this.checkValidation("notes", event.target.value);
                break;
            default: console.log("default case");
        }
    }

    // Handle routing
    goToBackPage = () => {
        this.props.history.goBack();
    }

    // update schedules entity
    updateSchedulesData = () => {
        let { plantsId, id, name, notes, specs, defaultSchedule } = this.state;
        if (typeof specs !== "string") {
            specs = JSON.stringify(specs);
        }
        if (id && id != null && id !== "") {
            this.props.updateSchedulesEntity({ plantsId, id, name, notes, specs, defaultSchedule }, id);
        }
        else {
            this.props.updateSchedulesEntity({ plantsId, name, notes, specs, defaultSchedule }, id);
        }
    }

    // set selected plant's organization id in the state
    getPlantNamefromOrganization = () => {
        if (this.props.selectedScopeData || this.props.selectedScopeData == "") {
            let tempdata = "";
            let found = false;
            this.props.scopeSelector.subOrganizations.map((organization) => {
                if (organization.plants && organization.plants.length > 0) {
                    organization.plants.map((plant) => {
                        if (plant.id === this.props.selectedScopeData && !found) {
                            tempdata = plant.name;
                            found = true;
                        }
                    })
                }
            })
            this.setState({
                plantsId: this.props.selectedScopeData,
                plantName: tempdata,
            })
        }
    }

    componentDidMount() {
        if (this.props.data) {
            let entityNames = Object.keys(this.props.data)
            entityNames.map((data) => {
                if (this.props.data[data] == null) {
                    this.props.data[data] = ""
                }
            })
            this.setState({
                id: this.props.data.id,
                name: this.props.data.name

            })

            if (this.props.data.specs && this.props.data.specs != "") {
                if (this.props.data.specs.props) {
                    this.setState({ specs: this.props.data.specs.props.children },
                        () => {
                            this.getScheduleTypeSpecs();
                        })
                }
                else {
                    this.setState({ specs: this.props.data.specs },
                        () => {
                            this.getScheduleTypeSpecs();
                        })
                }
            }

            if (this.props.data.defaultSchedule) {
                this.setState({
                    defaultSchedule: this.props.data.defaultSchedule
                })
            }

            if (this.props.data.notes && this.props.data.notes != "") {
                if (this.props.data.notes.props) {
                    this.setState({ notes: this.props.data.notes.props.children })
                }
                else {
                    this.setState({ notes: this.props.data.notes })
                }
            }

            if (this.props.data.id && this.props.data.id != "") {
                if (this.props.data.plants && this.props.data.plants.id) {
                    this.setState({
                        plantsId: this.props.data.plants.id,
                        plantName: this.props.data.plants.name
                    })
                }
                else {
                    this.getPlantNamefromOrganization();
                }
            }
            else if (!this.props.data.id || this.props.data.id == "") {
                if (this.props.selectedScopeData || this.props.selectedScopeData == "") {
                    let tempdata = "";
                    let found = false;
                    this.props.scopeSelector.subOrganizations.map((organization) => {
                        if (organization.plants && organization.plants.length > 0) {
                            organization.plants.map((plant) => {
                                if (plant.id === this.props.selectedScopeData && !found) {
                                    tempdata = plant.name;
                                    found = true;
                                }
                            })
                        }
                    })
                    this.setState({
                        plantId: this.props.selectedScopeData,
                        plantName: tempdata
                    })
                }
            }

            if (Object.keys(this.props.data).length > 0 && this.props.data.specificationsResponse) {
                if (this.props.data.specificationsResponse.validSpecs) {
                    this.setState({
                        validSpecificationResponse: this.props.data.specificationsResponse.scheduleSpecifications
                    })
                }
            }
        }
        else {
            this.getPlantNamefromOrganization();
        }

        if (this.props.validationData && this.props.validationData.config && this.props.validationData.config.Schedules) {
            this.setState({ validationData: this.props.validationData.config.Schedules });
        }
    }

    componentDidUpdate(prevProps, prevState) {
        if (this.state.plantsId && prevState.plantsId !== this.state.plantsId && !this.props.data) {
            this.getScheduleTypeSpecs(true);
        }
        if (prevProps.jsonData !== this.props.jsonData) {
            this.props.jsonData.json ? this.setState({
                specs: this.props.jsonData.json
            })
                : this.setState({
                    selectedTab: 0
                })
        }
        if (this.props.currentScheduleReducer && prevProps.currentScheduleReducer !== this.props.currentScheduleReducer) {
            if (this.props.currentScheduleReducer.json && this.props.currentScheduleReducer.json.validSpecs) {
                this.setState({
                    validSpecificationResponse: this.props.currentScheduleReducer.json.scheduleSpecifications
                })
            }
        }
    }

    // check validations of the user input fields
    checkValidation = (field, value) => {
        switch (field) {
            case "scheduleName":
                if (!this.state.NameError && value === "" && (this.state.validationData.name.validations && this.state.validationData.name.validations.required)) {
                    this.setState({ NameError: true });
                } else if (!this.state.NameError && (this.state.validationData.name.validations) && (value.length > this.state.validationData.name.validations.maxLength)) {
                    this.setState({ NameError: true });
                } else if (this.state.NameError) {
                    if ((value === "" && (this.state.validationData.name.validations && this.state.validationData.name.validations.required) || ((this.state.validationData.name.validations) && (value.length > this.state.validationData.name.validations.maxLength)))) {
                        //skip
                    }
                    else {
                        this.setState({ NameError: false });
                    }
                };
                break;
            case "specs":
                if (!this.state.SpecsError && value === "" && (this.state.validationData.specs.validations && this.state.validationData.specs.validations.required)) {
                    this.setState({ SpecsError: true });
                } else if (!this.state.SpecsError && (this.state.validationData.specs.validations) && (value.length > this.state.validationData.specs.validations.maxLength)) {
                    this.setState({ SpecsError: true });
                } else if (this.state.SpecsError) {
                    if ((value === "" && (this.state.validationData.specs.validations && this.state.validationData.specs.validations.required) || ((this.state.validationData.specs.validations) && (value.length > this.state.validationData.specs.validations.maxLength)))) {
                        //skip
                    }
                    else {
                        this.setState({ SpecsError: false });
                    }
                };
                break;
            case "notes":
                if (!this.state.NotesError && value === "" && (this.state.validationData.notes.validations && this.state.validationData.notes.validations.required)) {
                    this.setState({ NotesError: true });
                } else if (!this.state.NotesError && (this.state.validationData.notes.validations) && (value.length > this.state.validationData.notes.validations.maxLength)) {
                    this.setState({ NotesError: true });
                } else if (this.state.NotesError) {
                    if ((value === "" && (this.state.validationData.notes.validations && this.state.validationData.notes.validations.required) || ((this.state.validationData.notes.validations) && (value.length > this.state.validationData.notes.validations.maxLength)))) {
                        //skip
                    }
                    else {
                        this.setState({ NotesError: false });
                    }
                };
                break;
        }
    }

    // disable save button based on validations
    disableSaveButton = () => {
        if (this.state.name === "" && (this.state.validationData.name.validations && this.state.validationData.name.validations.required)) {
            return true;
        }
        else if (this.state.notes === "" && (this.state.validationData.notes.validations && this.state.validationData.notes.validations.required)) {
            return true;
        }
        else if (this.state.specs === "" && (this.state.validationData.specs.validations && this.state.validationData.specs.validations.required)) {
            return true;
        }
        else if (this.state.NameError || this.state.NotesError || this.state.SpecsError) {
            return true;
        }
        //else if (this.state.defaultSchedule === false)
        // return true;
        else {
            return false;
        }
    }

    // set card header
    getCardHeader = () => {
        if (this.props.data && this.props.data.name && this.props.data.name != "") {
            return this.props.data.name;
        }
        else {
            return "Create New"
        }
    }

    // create timeline widget
    getTimeline = () => {
        let result = false;
        if (Object.keys(this.props.data).length > 0 && this.props.data.specificationsResponse) {
            result = this.props.data.specificationsResponse.validSpecs;
        }
        if (this.state.validSpecificationResponse && Object.keys(this.state.validSpecificationResponse).length > 0 && this.props.currentScheduleReducer.json && this.props.currentScheduleReducer.json.validSpecs) {
            result = true;
        }
        return result;
    }

    // handle check schedule button
    checkSchedule = () => {
        let currentSpecifications = typeof this.state.specs !== "string" ? JSON.stringify(this.state.specs) : this.state.specs;
        if (typeof this.state.specs !== "string") {
            this.props.checkSchedule(currentSpecifications, this.state.selectedTab === 0 ? "json" : "yaml");
        }
        else {
            this.props.checkSchedule({ "entityType": "schedules", "specs": currentSpecifications }, this.state.selectedTab === 0 ? "json" : "yaml");
        }
    }

    // disable check schedule button if specs are invalid
    disableCheckScheduleButton = () => {
        let disableCheckScheduleButton = false;
        if (this.state.selectedTab === 0) {
            if (this.state.specs === "" || typeof this.state.specs === "string") {
                disableCheckScheduleButton = true;
            }
        }
        else {
            if (typeof this.state.specs === "string" && this.state.specs === "") {
                disableCheckScheduleButton = true;
            }
        }
        return disableCheckScheduleButton;
    }

    render() {
        return (
            <Card className={this.props.classes.card}>
                <div className={this.props.classes.actionButtonContainer}>
                    <div className={this.props.classes.backButtonContainer}>
                        <KeyboardBackspace onClick={this.goToBackPage} classes={{ root: this.props.classes.arrowBack }} />
                        <div className={this.props.classes.entityTitle}>{this.getCardHeader()}</div>
                    </div>
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                        <div style={{ marginRight: 24 }}>
                            <Checkbox
                                checked={this.state.defaultSchedule}
                                onChange={this.toggleCheckbox}
                                color="secondary"
                            />
                            <span className={this.props.classes.textFieldLabel} style={{ marginLeft: 0 }}>{"Set as plant default"}</span>
                        </div>
                        <Button variant="contained"
                            className={this.props.classes.saveButton}
                            disabled={this.state.disableSaveButton || this.disableSaveButton()}
                            onClick={this.updateSchedulesData}>{this.props.t("Save")}</Button>
                    </div>
                </div>
                <CardContent className={this.props.classes.cardContent} classes={{ root: this.props.classes.cardContentRoot }}>
                    {this.state.validationData && this.state.validationData.name && this.state.validationData.name.page_visible ? (
                        <div className={this.props.classes.inputFieldDiv}>
                            <InputLabel className={this.props.classes.textFieldLabel}>
                                {this.state.validationData.name.display_name}
                                {(this.state.validationData.name.validations && this.state.validationData.name.validations.required) ? (<span className={this.props.classes.requiredClass}>*</span>) : null}
                            </InputLabel>
                            <TextField
                                required={(this.state.validationData.name.validations && this.state.validationData.name.validations.required)}
                                id="scheduleName"
                                name="scheduleName"
                                variant="outlined"
                                helperText={(this.state.validationData.name.validations && this.state.validationData.name.validations.helpText) ? (this.state.validationData.name.validations.helpText) : null}
                                className={this.props.classes.textField}
                                onChange={(event) => this.inputChangeHandler(event, "scheduleName")}
                                value={this.state.name}
                                error={this.state.NameError}
                                InputProps={{
                                    classes: {
                                        input: this.props.classes.outlinedInput
                                    }
                                }}
                            />
                        </div>
                    )
                        : null
                    }
                    {this.state.validationData && this.state.validationData.plantName && this.state.validationData.plantName.page_visible ? (
                        <div className={this.props.classes.inputFieldDiv}>
                            <InputLabel className={this.props.classes.textFieldLabel}>
                                {this.state.validationData.plantName.display_name}
                                {(this.state.validationData.plantName.validations && this.state.validationData.plantName.validations.required) ? (<span className={this.props.classes.requiredClass}>*</span>) : null}
                            </InputLabel>
                            <TextField
                                name="plantName"
                                id="plantName-disabled"
                                disabled
                                variant="outlined"
                                className={this.props.classes.textField}
                                value={this.state.plantName}
                                InputProps={{
                                    classes: {
                                        input: this.props.classes.outlinedInput
                                    }
                                }}
                            />
                        </div>
                    )
                        : null
                    }
                    {this.state.validationData && this.state.validationData.notes && this.state.validationData.notes.page_visible ? (
                        <div className={this.props.classes.textareaInputFieldDiv}>
                            <InputLabel className={this.props.classes.textareaFieldLabel}>
                                {this.state.validationData.notes.display_name}
                                {(this.state.validationData.notes.validations && this.state.validationData.notes.validations.required) ? (<span className={this.props.classes.requiredClass}>*</span>) : null}
                            </InputLabel>
                            <TextField
                                required={(this.state.validationData.notes.validations && this.state.validationData.notes.validations.required)}
                                id="note"
                                multiline
                                rows="6"
                                helperText={(this.state.validationData.notes.validations && this.state.validationData.notes.validations.helpText) ? (this.state.validationData.notes.validations.helpText) : null}
                                value={this.state.notes}
                                variant="outlined"
                                onChange={(event) => this.inputChangeHandler(event, "notes")}
                                className={this.props.classes.textArea}
                                error={this.state.NotesError}
                                InputProps={{
                                    classes: {
                                        multiline: this.props.classes.outlinedInput
                                    }
                                }}
                            />
                        </div>
                    )
                        : null
                    }
                    {this.state.validationData && this.state.validationData.scheduleResult && this.state.validationData.scheduleResult.page_visible ? (
                        <div className={this.props.classes.inputFeildDiv + " " + this.props.classes.inputFeildDivLeft}>
                            {
                                this.getTimeline()
                                    ?
                                    <div style={{ width: '100%', height: 350, paddingRight: 85 }}>
                                        <Grid container>
                                            <div style={{ width: '100%', display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                                <div style={{ width: '50%' }}>
                                                    <InputLabel className={this.props.classes.textFieldLabel} style={{ width: '50%' }}>
                                                        {this.state.validationData.scheduleResult.display_name}
                                                        {(this.state.validationData.scheduleResult.validations && this.state.validationData.scheduleResult.validations.required) ? (<span className={this.props.classes.requiredClass}>*</span>) : null}
                                                    </InputLabel>
                                                </div>
                                                <div style={{ width: '45%', display: 'flex', justifyContent: 'flex-end' }}>
                                                    <div style={{ paddingLeft: 16 }}>
                                                        <span style={{ height: 15, width: 15, backgroundColor: '#58BA57', display: 'inline-block', marginRight: 5, verticalAlign: 'middle' }}>
                                                        </span>
                                                        <span>
                                                            {"Uptime"}
                                                        </span>
                                                    </div>
                                                    <div style={{ paddingLeft: 16 }}>
                                                        <span style={{ height: 15, width: 15, backgroundColor: '#000000', display: 'inline-block', marginRight: 5, verticalAlign: 'middle' }}>
                                                        </span>
                                                        <span>
                                                            {"Shift End"}
                                                        </span>
                                                    </div>
                                                    <div style={{ paddingLeft: 16 }}>
                                                        <span style={{ height: 15, width: 15, backgroundColor: '#F5A623', display: 'inline-block', marginRight: 5, verticalAlign: 'middle' }}>
                                                        </span>
                                                        <span>
                                                            {"Break"}
                                                        </span>
                                                    </div>
                                                    <div style={{ paddingLeft: 16 }}>
                                                        <span style={{ height: 15, width: 15, backgroundColor: '#fff', display: 'inline-block', marginRight: 5, verticalAlign: 'middle', border: '1px solid black' }}>
                                                        </span>
                                                        <span>
                                                            {"No Shift"}
                                                        </span>
                                                    </div>
                                                </div>
                                            </div>
                                        </ Grid>
                                        <div style={{ height: 350, paddingTop: 10 }}>
                                            <Timeline scheduleData={this.state.validSpecificationResponse} />
                                        </div>
                                    </div>
                                    : null
                            }
                        </div>
                    )
                        : null
                    }
                    <div style={{ clear: "both", minHeight: 200 }}>
                        <Paper>
                            <Tabs
                                value={this.state.selectedTab}
                                onChange={this.handleTabChange}
                                indicatorColor="primary"
                                textColor="primary"
                                variant="fullWidth"
                                TabIndicatorProps={{
                                    classes: {
                                        colorPrimary: this.props.classes.tabIndicator
                                    }
                                }}
                                style={{ marginTop: 300, marginBottom: 10 }}
                            >
                                <Tab label={this.props.t("SPECS")} key={"SPECS"} classes={{ selected: this.props.classes.selectedTab }} />
                                <Tab label={"YAML"} key={"YAML"} classes={{ selected: this.props.classes.selectedTab }} />
                            </Tabs>
                        </Paper>
                        <Button
                            variant="text"
                            className={this.props.classes.addPlantsButton}
                            //color="primary"
                            disabled={this.disableCheckScheduleButton()}
                            onClick={this.checkSchedule}
                        >
                            {"Check Schedule"}
                        </Button>
                        {this.state.selectedTab === 0 ? this.getSpecView() : this.getYamlView()}
                    </div>
                </CardContent>
            </Card >
        );
    }
}

const mapStateToProps = state => {
    return {
        validationData: state.viewDefinitionReducer,
        staticData: state.staticDataReducer,
        jsonData: state.constructJsonReducer,
        currentScheduleReducer: state.currentScheduleReducer
    }
}

const mapDispatchToProps = dispatch => {
    return ({
        updateSchedulesEntity: (schedulesEntityData, id) => (dispatch(actionCreators.updateSchedulesEntity(schedulesEntityData, id))),
        updateSelectedTab: (selectedTab) => (dispatch(actionCreators.updateSelectedTab(selectedTab))),
        getJsonFromYaml: (yamlRequestObject, entity) => (dispatch(actionCreators.getJsonFromYaml(yamlRequestObject, entity))),
        getYamlFromJson: (jsonRequestObject, entity) => (dispatch(actionCreators.getYamlFromJson(jsonRequestObject, entity))),
        checkSchedule: (specs, format) => (dispatch(actionCreators.checkSchedule(specs, format)))
    })
}

export default withTranslation()(connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(SchedulesEntity)));

