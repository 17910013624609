import * as actionTypes from "../actions/actionTypes";
import { cloneDeep } from "lodash";

const initialState = {
  trendHistorySelectedFeatures: [],
  trendHistoryChartLoading: false,
};

const trendHistoryReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.SET_TREND_HISTORY_SELECTED_FEATURES:
      return Object.assign({}, state, {
        trendHistorySelectedFeatures: cloneDeep(action.payload),
      });
    case actionTypes.SET_TREND_HISTORY_CHART_LOADING:
      return Object.assign({}, state, {
        trendHistoryChartLoading: action.payload,
      });
    default:
      return state;
  }
};

export default trendHistoryReducer;
