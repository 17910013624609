import React, { Component } from 'react';
import * as actionCreators from '../../actions/actionCreators';
import { connect } from 'react-redux';
import { KeyboardBackspace } from '@material-ui/icons';
import { Scrollbars } from 'react-custom-scrollbars';
import {
    Card, CardContent, InputLabel, TextField, Select, withStyles, OutlinedInput, Button, Typography, Grid, Divider
} from '@material-ui/core';
import { withTranslation } from "react-i18next";
let currentLanguage = localStorage.getItem("lang");
        
if(currentLanguage === "null"  || currentLanguage === null) {
    currentLanguage="en"
}

const styles = theme => ({
    cardContent: {
        height: '91%',
        "overflow-y": "auto"
    },
    cardContentRoot: {
        '&:last-child': {
            paddingBottom: 16
        }
    },
    card: {
        width: '100%',
        borderRadius: 0,
        height: '80vh'
    }, textField: {
        width: '60%',
        marginRight: 20
    },
    textArea: {
        width: '80%',
        marginRight: 20
    },
    outlinedInput: {
        padding: 14
    },
    saveButton: {
        fontSize: "14px",
        fontWeight: "500",
        textAlign: "center",
        color: "#ffffff",
        backgroundColor: "#42D784",
        '&:hover': {
            backgroundColor: '#42d784'
        },
        '&:disabled': {
            backgroundColor: '#f6f6f6'
        },
        borderRadius: "3px",
        boxShadow: "0 0 1px 0 rgba(0, 0, 0, 0.05)",
        width: "110px",
        height: "36px",
        marginRight: "24px"
    },
    inputFieldDiv: {
        display: "flex",
        justifyContent: "space-between",
        width: '50%',
        float: 'left',
        paddingTop: '1%',
        height: 80,
        'align-items': 'baseline'
    },
    textareaInputFieldDiv: {
        display: "flex",
        justifyContent: "space-between",
        width: '100%',
        float: 'left',
        paddingTop: '1%',
        height: 180,
        'align-items': 'baseline'
    },
    textFieldLabel: {
        color: 'rgba(0, 0, 0, 0.4000000059604645)',
        'letter-spacing': 0.5,
        'font-weight': 500,
        fontSize: "14px",
        lineHeight: 2.5,
        width: '30%',
        marginLeft: 20,
        //text-align': 'right'
    },
    textareaFieldLabel: {
        color: 'rgba(0, 0, 0, 0.4000000059604645)',
        'letter-spacing': 0.5,
        'font-weight': 500,
        fontSize: "14px",
        lineHeight: 2.5,
        width: '15%',
        marginLeft: 20,
        //'text-align': 'right'
    },
    requiredClass: {
        color: '#d32f2f'
    },
    actionButtonContainer: {
        display: 'flex',
        'justify-content': 'space-between',
        height: 50,
        'border-bottom': '1px solid rgba(207, 216, 220, 0.5)'
    },
    backButtonContainer: {
        display: 'flex',
        'align-items': 'center',
        paddingLeft: 21
    },
    arrowBack: {
        cursor: 'pointer'
    },
    entityTitle: {
        color: 'rgba(0, 0, 0, 0.8)',
        'font-family': 'Roboto',
        'font-size': 21,
        'font-weight': '400',
        'letter-spacing': 0.5,
        marginLeft: 10
    },
    gridItem: {
        'padding': "10px"
    }
})
const initialState = {
    addNew: true,
    disableSaveButton: true,
    machineGroupsId: "",
    newMachineGroup: "",
    machine: "",
    newMachine: "",
    machineType: "",
    rotatingSpeed: "",
    monitoringLocation: "",
    rotatingSpeedMonitor: "",
    labelWidth: 0,
    machineGroupError: false,
    newMachineGroupError: false,
    machineError: false,
    newMachineError: false,
    machineTypeError: false,
    rotatingSpeedError: false,
    isSaveCalled: false,
    rotatingSpeedErrorMessage: "",
    monitoringLocationError: false,
    inputBoxErrorMsg: currentLanguage=== "en" ? "Max 45 characters" :"最大45文字",
    newMachineGroupMsg: currentLanguage=== "en" ? "Max 45 characters" :"最大45文字",
    newMachineMsg: currentLanguage=== "en" ? "Max 45 characters" :"最大45文字",
    rotatingSpeedMonitorError: false,
    rotatingSpeedMonitorErrorMessage: ""
}
class QuickConfigTableEntity extends Component {

    state = {
        addNew: true,
        disableSaveButton: true,
        plantId: "",
        machineGroupsId: "",
        newMachineGroup: "",
        machine: "",
        newMachine: "",
        machineType: "",
        rotatingSpeed: "",
        monitoringLocation: "",
        rotatingSpeedMonitor: "",
        labelWidth: 0,
        machineGroupError: false,
        newMachineGroupError: false,
        machineError: false,
        newMachineError: false,
        machineTypeError: false,
        isSaveCalled: false,
        rotatingSpeedError: false,
        rotatingSpeedErrorMessage: "",
        inputBoxErrorMsg: "Max 45 characters",
        newMachineGroupMsg: "Max 45 characters",
        newMachineMsg: "Max 45 characters",
        monitoringLocationError: false,
        rotatingSpeedMonitorError: false,
        rotatingSpeedMonitorErrorMessage: ""
    }

    // Handle changes to any user input field
    inputChangeHandler = (event, field) => {

        switch (field) {
            case "machineGroupsId": this.setState({ machineGroupsId: event.target.value, disableSaveButton: false });
                this.checkValidation("machineGroupsId", event.target.value);
                if (event.target.value !== "0") {
                    this.setState({ newMachineError: false, newMachine: "", machineType: "", machineTypeError: false, rotatingSpeed: "", rotatingSpeedError: false, newMachineGroupError: false, newMachineGroup: "", machine: "", machineError: false });

                } else {
                    this.setState({ newMachineError: false, newMachine: "", machineType: "", machineTypeError: false, rotatingSpeed: "", rotatingSpeedError: false, machine: "0", machineError: false });
                }
                break;
            case "newMachineGroup": this.setState({ newMachineGroup: event.target.value, disableSaveButton: false });
                this.checkValidation("newMachineGroup", event.target.value);
                break;
            case "machine": this.setState({ machine: event.target.value, disableSaveButton: false });
                this.checkValidation("machine", event.target.value);
                if (event.target.value !== "0") {
                    this.setState({ newMachineError: false, newMachine: "", machineType: "", machineTypeError: false, rotatingSpeed: "", rotatingSpeedError: false });
                }
                break;
            case "newMachine": this.setState({ newMachine: event.target.value, disableSaveButton: false });
                this.checkValidation("newMachine", event.target.value);
                break;
            case "machineType": this.setState({ machineType: event.target.value, disableSaveButton: false });
                this.checkValidation("machineType", event.target.value);
                break;
            case "rotatingSpeed": this.setState({ rotatingSpeed: event.target.value, disableSaveButton: false });
                this.checkValidation("rotatingSpeed", event.target.value);
                break;
            case "monitoringLocation": this.setState({ monitoringLocation: event.target.value, disableSaveButton: false });
                this.checkValidation("monitoringLocation", event.target.value);
                break;
            case "rotatingSpeedMonitor": this.setState({ rotatingSpeedMonitor: event.target.value, disableSaveButton: false });
                this.checkValidation("rotatingSpeedMonitor", event.target.value);
                break;

            default: console.log("default case");
        }
    }


    // update devices entity
    quickConfig = () => {
        const configData = { plantId: this.state.plantId, machineGroupsId: parseInt(this.state.machineGroupsId), machineId: parseInt(this.state.machine), machineTypeId: parseInt(this.state.machineType), machineRpm: (this.state.rotatingSpeed != "" ? parseFloat(this.state.rotatingSpeed) : ""), monitorRpm: parseFloat(this.state.rotatingSpeedMonitor), machineName: this.state.newMachine, machineGroupName: this.state.newMachineGroup, monitorName: this.state.monitoringLocation, emailId: this.props.userData.email };
        this.props.quickConfig(configData);
        this.setState({ isSaveCalled: true });
    }

    // create machines dropdown list
    getMachinesName = () => {
        let machinesList = [];
        machinesList.push(<option key={""} disabled={true} value={""}>{this.props.t("Select")}</option>);
        if (this.state.machineGroupsId !== '0') {
            if (this.props.machinesData && this.props.machinesData.tableData && this.props.machinesData.tableData.length > 0) {
                var dataList = this.props.machinesData.tableData.filter(x => x.machineGroups.id === parseInt(this.state.machineGroupsId))
                dataList.map((data) => {
                    machinesList.push(
                        <option key={data.id} value={data.id}>{data.label}</option>
                    );
                })
            }
            machinesList.push(<option key={"0"} value={"0"} >{this.props.t("Create New")}</option>);
        } else {
            machinesList.push(<option key={"0"} value={"0"} disabled={true}>{this.props.t("Create New")}</option>);
        }
        return machinesList;
    }


    // create machine-types dropdown list
    getMachineTypes = () => {
        let machineTypeList = [];
        machineTypeList.push(<option key={""} disabled={true} value={""}>{this.props.t("Select")}</option>);

        let found = false;
        let plantOrganization = "";
        //let tempPlantId = this.props.selectedScopeData;
        let tempPlantId = this.state.plantId;
        if (tempPlantId) {
            this.props.scopeSelector.subOrganizations.map((organization) => {
                if (organization.plants && organization.plants.length > 0) {
                    organization.plants.map((plant) => {
                        if (plant.id === tempPlantId && !found) {
                            plantOrganization = organization.id;
                            found = true;
                        }
                    })
                }
            })
        }
        if (plantOrganization && this.props.staticData && this.props.staticData.staticData) {
            let plantOrganizationStaticData = this.props.staticData.staticData[plantOrganization]
            if (plantOrganizationStaticData && plantOrganizationStaticData.machinetypes) {
                plantOrganizationStaticData.machinetypes.map((data) => {
                    machineTypeList.push(
                        <option key={data.id} value={data.id}>{data.name}</option>
                    );
                })
            }
        }
        return machineTypeList;
    }

    getMachineGroups = () => {
        let machineGroupList = [];
        machineGroupList.push(<option key={""} disabled={true} value={""}>{this.props.t("Select")}</option>);
        if (this.props.machineGroupData && this.props.machineGroupData.tableData && this.props.machineGroupData.tableData.length > 0) {
            this.props.machineGroupData.tableData.map((data) => {
                machineGroupList.push(
                    <option key={data.id} value={data.id}>{data.name}</option>
                );
            })
        }
        machineGroupList.push(<option key={"0"} value={"0"}>{this.props.t("Create New")}</option>);

        return machineGroupList;
    }
    componentDidMount() {
        if (this.props.selectedScopeData) {
            this.setState({
                plantId: this.props.selectedScopeData,
            })
        }
    }

    componentDidUpdate(prevProps, prevState) {
        if (this.state.plantId && this.state.plantId !== "" && prevState.plantId !== this.state.plantId) {
            this.props.getTableData("MachineGroupsTable", this.state.plantId, true);
            this.props.getTableData("MachinesTable", this.state.plantId, true);

        }
        let selectedtab = this.props.userData.selectedTab;
        let selectedNavTab = this.props.location.pathname.split("/")[2];
        if ((prevProps.selectedScopeData !== this.props.selectedScopeData && prevProps.selectedTab === this.props.selectedTab)) {
            this.setState({
                ...initialState,
                plantId: this.props.selectedScopeData
            }, () => {
                let currentScopeSelectionId = this.getCurrentScopeSelectionId(selectedtab);

            })
        }
        if (this.state.isSaveCalled) {
            if (this.props.alertMessage.message !== "" && this.props.alertMessage.message !== undefined) {
                if (this.props.alertMessage.message.successful) {
                    this.setState({
                        ...initialState,
                        plantId: this.props.selectedScopeData
                    })
                    this.props.getTableData("MachineGroupsTable", this.state.plantId, true);
                    this.props.getTableData("MachinesTable", this.state.plantId, true);

                }
            }
        }

    }

    // gets the id of current plant selected in scope selector
    getCurrentScopeSelectionId = (selectedTab) => {
        let selectedScopeData = "";
        if (this.props.location.pathname.split("/").length === 3) {
            let currentEntity = this.props.location.pathname.split("/")[2];

            if (currentEntity === "OrganizationsTable" || currentEntity === "PlantsTable" || currentEntity === "UsersTable") {
                selectedScopeData = this.props.userData.selectedOrganization;
            }
            else if (currentEntity === "MachineGroupsTable" || currentEntity === "MachinesTable" || currentEntity === "SchedulesTable" ||
                currentEntity === "JobsTable" || currentEntity === "LossCodesTable" || currentEntity === "MonitorsTable" || currentEntity === "DevicesTable" ||
                currentEntity === "AlertsTable" || currentEntity === "QuickConfigurationTable") {
                selectedScopeData = this.props.userData.selectedPlant;
            }
        }
        if (selectedScopeData) {
            return selectedScopeData;
        }
    }


    // disable save button based on validations
    disableSaveButton = () => {
        if (this.state.machineGroupsId === "") {
            return true;
        }
        else if (this.state.machineGroupsId === "0" && this.state.newMachineGroup === "") {
            return true;
        }
        else if (this.state.machine === "") {
            return true;
        }
        else if (this.state.machine === "0" && this.state.newMachine === "") {
            return true;
        }
        else if (this.state.machine === "0" && this.state.machineType === "") {
            return true;
        }
        else if (this.state.rotatingSpeedMonitor === "") {
            return true;
        }
        else if (this.state.monitoringLocation === "") {
            return true;
        }
        else if (this.state.machineGroupError || this.state.newMachineError || this.state.machineError || this.state.newMachineGroupError || this.state.machineTypeError || this.state.rotatingSpeedMonitorError || this.state.rotatingSpeedError || this.state.monitoringLocationError) {
            return true;
        }
        else {
            return false;
        }
    }

    // check validations of the user input fields
    checkValidation = (field, value) => {
        switch (field) {
            case "machineGroupsId":

                if (!this.state.machineGroupError && value === "") {
                    this.setState({ machineGroupError: true });
                } else if (this.state.machineGroupError) {
                    if (value === "") {
                        this.setState({ machineGroupError: true });
                    }
                    else {
                        this.setState({ machineGroupError: false });
                    }
                };
                break;
            case "machine":
                if (!this.state.machineError && value === "") {
                    this.setState({ machineError: true });
                } else if (this.state.machineError) {
                    if (value === "") {
                        this.setState({ machineError: true });
                    }
                    else {
                        this.setState({ machineError: false });
                    }
                };
                break;
            case "machineType":
                if (!this.state.machineTypeError && value === "") {
                    this.setState({ machineTypeError: true });
                } else if (this.state.machineTypeError) {
                    if (value === "") {
                        this.setState({ machineTypeError: true });
                    }
                    else {
                        this.setState({ machineTypeError: false });
                    }
                };
                break;
            case "monitoringLocation":
                if (!this.state.monitoringLocationError && value === "") {
                    this.setState({ monitoringLocationError: true });
                } else if (!this.state.monitoringLocationError && (value !== "" && (value.trim() === "" || value.length > 45))) {
                    this.setState({ monitoringLocationError: true });
                } else if (this.state.monitoringLocationError) {
                    if (value === "" || value.trim() === "" || value.length > 45) {
                        this.setState({ monitoringLocationError: true });
                    }
                    else {
                        this.setState({ monitoringLocationError: false });
                    }
                };
                break;
            case "newMachineGroup":
                if (!this.state.newMachineGroupError && value === "") {
                    this.setState({ newMachineGroupError: false });
                } else if (!this.state.newMachineGroupError && (value !== "" && (value.trim() === "" || value.length > 45))) {
                    this.setState({ newMachineGroupError: true, newMachineGroupMsg: "Max 45 characters" });
                } else if (this.state.newMachineGroupError) {
                    if (value === "") {
                        this.setState({ newMachineGroupError: false, newMachineGroupMsg: "" });
                    } else if (value.trim() === "" || value.length > 45) {
                        this.setState({ newMachineGroupError: true, newMachineGroupMsg: "Max 45 characters" });
                    }
                    else {
                        this.setState({ newMachineGroupError: false, newMachineGroupMsg: "" });
                    }
                };
                break;
            case "newMachine":
                if (!this.state.newMachineError && value === "") {
                    this.setState({ newMachineError: false, newMachineMsg: "" });
                } else if (!this.state.newMachineError && (value !== "" && (value.trim() === "" || value.length > 45))) {
                    this.setState({ newMachineError: true, newMachineMsg: "Max 45 characters" });
                } else if (this.state.newMachineError) {
                    if (value === "") {
                        this.setState({ newMachineError: false, newMachineMsg: "" });
                    } else if (value.trim() === "" || value.length > 45) {
                        this.setState({ newMachineError: true, newMachineMsg: "Max 45 characters" });
                    }
                    else {
                        this.setState({ newMachineError: false, newMachineMsg: "" });
                    }
                };
                break;
            case "rotatingSpeed":
                if (value === "" && isNaN(value)) {
                    this.setState({ rotatingSpeedError: false, rotatingSpeedErrorMessage: "" })
                } else if (value !== "" && isNaN(value)) {
                    this.setState({ rotatingSpeedError: true, rotatingSpeedErrorMessage: this.props.t("Enter actual RPM of the machine") }); 
                } else if (value < 0 || value > 60000) {
                    this.setState({ rotatingSpeedError: true, rotatingSpeedErrorMessage: this.props.t("Enter a value less than or equal to")+" 60000" })

                } 
                else if(value ==="0"){
                    this.setState({ rotatingSpeedError: true, rotatingSpeedErrorMessage: this.props.t("Enter actual RPM of the machine") }); 
                }
                else {
                    this.setState({ rotatingSpeedError: false, rotatingSpeedErrorMessage: "" })
                }
                break;
            case "rotatingSpeedMonitor":
                if (value !== "" && isNaN(value)) {
                    this.setState({ rotatingSpeedMonitorError: true, rotatingSpeedMonitorErrorMessage: this.props.t("Enter actual RPM of the machine") }); 
                } else if (value < 0 || value > 60000) {
                    this.setState({ rotatingSpeedMonitorError: true, rotatingSpeedMonitorErrorMessage: this.props.t("Enter a value less than or equal to")+" 60000" })

                }
                else if( value === "0"){
                    this.setState({ rotatingSpeedMonitorError: true, rotatingSpeedMonitorErrorMessage: this.props.t("Enter actual RPM of the machine") }); 

                } else {
                    this.setState({ rotatingSpeedMonitorError: false, rotatingSpeedMonitorErrorMessage: "" })
                }
                break;
        }
    }

    // set card header
    getCardHeader = () => {
        const {t}=this.props;
        if (this.props.data && this.props.data.deviceIdentifier && this.props.data.deviceIdentifier !== "") {
            return this.props.data.deviceIdentifier;
        }
        else {
            return t("Quick Configuration");
        }
    }

    render() {
        const {t}=this.props;
        return (
            <Card className={this.props.classes.card}>
                <div className={this.props.classes.actionButtonContainer}>
                    {<div className={this.props.classes.backButtonContainer}>
                        <div className={this.props.classes.entityTitle}>{this.getCardHeader()}</div>
                    </div>}
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                        <Button variant="contained"
                            className={this.props.classes.saveButton}
                            disabled={this.state.disableSaveButton || this.disableSaveButton()}
                            onClick={this.quickConfig}>{t("Save")}</Button>
                    </div>
                </div>
                <CardContent className={this.props.classes.cardContent} classes={{ root: this.props.classes.cardContentRoot }}>
                    <Grid container className={this.props.classes.gridClass}>
                        <Grid item sm={6} style={{ marginTop: "20px", marginBottom: "50px" }}>
                            <div>
                                <InputLabel className={this.props.classes.textFieldLabel}>
                                    {t("Equipment")}
                                <span className={this.props.classes.requiredClass}>*</span>
                                </InputLabel>
                                <Select
                                    native
                                    value={this.state.machineGroupsId}
                                    onChange={(event) => this.inputChangeHandler(event, "machineGroupsId")}
                                    input={
                                        <OutlinedInput
                                            variant="outlined"
                                            name="machineGroupsId"
                                            id="language-native-simple"
                                            labelWidth={this.state.labelWidth}
                                            className={this.props.classes.textField}
                                            error={this.state.machineGroupError}
                                            classes={{ input: this.props.classes.outlinedInput }}
                                        />
                                    }>
                                    {this.getMachineGroups()}
                                </Select>
                            </div>
                        </Grid>
                        <Grid item sm={6} style={{ marginTop: "20px", marginBottom: "50px" }}>
                            {this.state.machineGroupsId === '0' ? (
                                <div>
                                    <InputLabel className={this.props.classes.textFieldLabel}>
                                    </InputLabel>
                                    <TextField
                                        id="newMachineGroup"
                                        name="newMachineGroup"
                                        onChange={(event) => this.inputChangeHandler(event, "newMachineGroup")}
                                        variant="outlined"
                                        className={this.props.classes.textField}
                                        value={this.state.newMachineGroup}
                                        error={this.state.newMachineGroupError}
                                        helperText={this.state.newMachineGroupError ? this.state.newMachineGroupMsg : ""}
                                        InputProps={{
                                            classes: {
                                                input: this.props.classes.outlinedInput
                                            }
                                        }}
                                    />
                                </div>
                            ) : null
                            }
                        </Grid>
                        <Grid item sm={12}>
                            <Divider />
                        </Grid>
                        <Grid item sm={6} style={{ marginTop: "50px", marginBottom: "50px" }}>
                            <div >
                                <InputLabel className={this.props.classes.textFieldLabel}>
                                    {t("Asset")}
                                   <span className={this.props.classes.requiredClass}>*</span>
                                </InputLabel>
                                <Select
                                    native
                                    value={this.state.machine}
                                    onChange={(event) => this.inputChangeHandler(event, "machine")}
                                    input={
                                        <OutlinedInput
                                            variant="outlined"
                                            name="machine"
                                            id="language-native-simple"
                                            labelWidth={this.state.labelWidth}
                                            className={this.props.classes.textField}
                                            error={this.state.machineError}
                                            classes={{ input: this.props.classes.outlinedInput }}
                                        />
                                    }>
                                    {this.getMachinesName()}
                                </Select>
                            </div>
                        </Grid>
                        <Grid item sm={6} style={{ marginTop: "50px", marginBottom: "50px" }}>
                            {this.state.machine === '0' ? (
                                <div >
                                    <InputLabel className={this.props.classes.textFieldLabel}>

                                    </InputLabel>
                                    <TextField
                                        id="newMachine"
                                        name="newMachine"
                                        onChange={(event) => this.inputChangeHandler(event, "newMachine")}
                                        variant="outlined"
                                        className={this.props.classes.textField}
                                        value={this.state.newMachine}
                                        error={this.state.newMachineError}
                                        helperText={this.state.newMachineError ? this.state.newMachineMsg : ""}
                                        InputProps={{
                                            classes: {
                                                input: this.props.classes.outlinedInput
                                            }
                                        }}
                                    />
                                </div>
                            ) : null
                            }
                        </Grid>
                        <Grid item sm={6}>
                            {this.state.machine === '0' ? (
                                <div>
                                    <InputLabel className={this.props.classes.textFieldLabel}>
                                        {t("Asset Type")}
                                 <span className={this.props.classes.requiredClass}>*</span>
                                    </InputLabel>
                                    <Select
                                        native
                                        value={this.state.machineType}
                                        onChange={(event) => this.inputChangeHandler(event, "machineType")}
                                        input={
                                            <OutlinedInput
                                                variant="outlined"
                                                name="machineType"
                                                id="language-native-simple"
                                                labelWidth={this.state.labelWidth}
                                                className={this.props.classes.textField}
                                                error={this.state.machineTypeError}
                                                classes={{ input: this.props.classes.outlinedInput }}
                                            />
                                        }
                                    >
                                        {this.getMachineTypes()}
                                    </Select>
                                </div>) : null
                            }
                        </Grid>
                        <Grid item sm={6} style={{ marginBottom: "50px" }}>
                            {this.state.machine === '0' ? (

                                <div>
                                    <InputLabel className={this.props.classes.textFieldLabel}>
                                        {t("Rotating Speed (RPM)")}
                                </InputLabel>
                                    <TextField
                                        id="rotatingSpeed"
                                        name="rotatingSpeed"
                                        onChange={(event) => this.inputChangeHandler(event, "rotatingSpeed")}
                                        variant="outlined"
                                        className={this.props.classes.textField}
                                        value={this.state.rotatingSpeed}
                                        error={this.state.rotatingSpeedError}
                                        helperText={this.state.rotatingSpeedError ? this.state.rotatingSpeedErrorMessage : ""}
                                        InputProps={{
                                            classes: {
                                                input: this.props.classes.outlinedInput
                                            }
                                        }}
                                    />
                                </div>) : null
                            }
                        </Grid>
                        <Grid item sm={12}>
                            <Divider />
                        </Grid>
                        <Grid item sm={6} style={{ marginTop: "50px", marginBottom: "50px" }}>
                            <div >
                                <InputLabel className={this.props.classes.textFieldLabel}>
                                    {t("Monitoring Location")}
                                   <span className={this.props.classes.requiredClass}>*</span>
                                </InputLabel>
                                <TextField
                                    id="monitoringLocation"
                                    name="monitoringLocation"
                                    onChange={(event) => this.inputChangeHandler(event, "monitoringLocation")}
                                    variant="outlined"
                                    className={this.props.classes.textField}
                                    value={this.state.monitoringLocation}
                                    error={this.state.monitoringLocationError}
                                    helperText={this.state.monitoringLocationError ? this.state.inputBoxErrorMsg : ""}
                                    InputProps={{
                                        classes: {
                                            input: this.props.classes.outlinedInput
                                        }
                                    }}
                                />
                            </div>
                        </Grid>
                        <Grid item sm={6} style={{ marginTop: "50px", marginBottom: "50px" }}>
                            <div>
                                <InputLabel className={this.props.classes.textFieldLabel}>
                                    {t("Rotating Speed (RPM)")}
                                   <span className={this.props.classes.requiredClass}>*</span>
                                </InputLabel>
                                <TextField
                                    id="rotatingSpeedMonitor"
                                    name="rotatingSpeedMonitor"
                                    onChange={(event) => this.inputChangeHandler(event, "rotatingSpeedMonitor")}
                                    variant="outlined"
                                    className={this.props.classes.textField}
                                    value={this.state.rotatingSpeedMonitor}
                                    error={this.state.rotatingSpeedMonitorError}
                                    helperText={this.state.rotatingSpeedMonitorError ? this.state.rotatingSpeedMonitorErrorMessage : ""}
                                    InputProps={{
                                        classes: {
                                            input: this.props.classes.outlinedInput
                                        }
                                    }}
                                />
                            </div>
                        </Grid>

                    </Grid>
                </CardContent>
            </Card >
        );
    }
}

const mapStateToProps = (state) => {
    return {
        staticData: state.staticDataReducer,
        machineGroupData: state.machineGroupReducer,
        machinesData: state.machinesDataReducer,
        staticData: state.staticDataReducer,
        alertMessage: state.alertMessageReducer,

    }
}

const mapDispatchToProps = dispatch => {
    return ({
        getAlertMessage: () => (dispatch(actionCreators.getAlertMessage())),
        getTableData: (selectedNavtab, currentScopeSelectionId, setToStore) => (dispatch(actionCreators.getTableData(selectedNavtab, currentScopeSelectionId, setToStore))),
        quickConfig: (devicesEntityData) => (dispatch(actionCreators.quickConfig(devicesEntityData))),
        updateSelectedTab: (selectedTab) => (dispatch(actionCreators.updateSelectedTab(selectedTab)))
    })
}

export default withTranslation()(connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(QuickConfigTableEntity)));

