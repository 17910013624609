import * as actionTypes from '../actions/actionTypes';

export const alertMessageReducer = (state={message: ""}, action) => {
    switch(action.type) {

        case actionTypes.GET_ALERT_MESSAGE:
        return Object.assign({}, state, {message: action.payload});

        default: return state
    }
}