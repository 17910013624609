import React, { Component } from 'react';
import {
    withStyles,
    Dialog,
    DialogActions,
    Button,
    InputLabel,
    OutlinedInput
} from '@material-ui/core';
import { withTranslation } from "react-i18next";

const styles = () => ({
    dialog: {
        padding: '12px 24px'
    },
    elementContainer: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        paddingTop: 24
    },
    textFeildLabel: {
        color: 'rgba(0, 0, 0, 0.4000000059604645)',
        'letter-spacing': 0.5,
        'font-weight': 500,
        fontSize: "14px",
        paddingLeft: "7px",
        lineHeight: 2.5
    },
    textField: {
        border: "1px solid #00000005",
        borderRadius: "6px",
        width: '315px',
        height: '45px',
    },
    textArea: {
        border: "1px solid #00000005",
        width: '315px',
    },
    multiline: {
        paddingTop: 7
    },
    saveButton: {
        width: 100,
        backgroundColor: '#42D784',
        '&:hover': {
            backgroundColor: '#42D784'
        }
    },
    dialogAction: {
        marginTop: 24
    }
})

class SchedulesModal extends Component {

    state = {
        specs: "",
        notes: "",
        changedSpecs: false,
        changedNotes: false
    }

    // handle updated user input values
    handleUpdate = () => {
        this.props.updateSelectedRecords(this.state);
        this.props.disableBulkEditingView();
    }

    // handle modal window close action
    handleClose = () => {
        this.props.disableBulkEditingView();
        this.setState({
            specs: "",
            notes: "",
            changedNotes: false,
            changedSpecs: false
        })
    }

    // handle user input changes
    onChangeHandler = (event, item) => {
        switch (item) {
            case "Notes":
                this.setState({
                    changedNotes: true,
                    notes: event.target.value
                })
                break;
            case "Specs":
                this.setState({
                    changedSpecs: true,
                    specs: event.target.value
                })
                break;
            default: console.log("default case");
        }
    }

    render() {
        const {t}=this.props;
        return (
            <Dialog
                open={this.props.enableBulkEditingView}
                onClose={this.handleClose}
                aria-labelledby="form-dialog-title"
                maxWidth="sm"
                fullWidth={true}
                classes={{ paper: this.props.classes.dialog }}
                disableBackdropClick={true}
            >
                {
                    this.props.config.notes.page_visible ?
                        (
                            <div className={this.props.classes.elementContainer}>
                                <InputLabel className={this.props.classes.textFeildLabel} style={{ alignSelf: 'flex-start' }}>
                                    {this.props.config.notes.display_name}
                                </InputLabel>
                                <OutlinedInput
                                    multiline
                                    rows="5"
                                    value={this.state.notes}
                                    onChange={(event) => this.onChangeHandler(event, "Notes")}
                                    className={this.props.classes.textArea}
                                    classes={{ multiline: this.props.classes.multiline }}
                                    labelWidth={0}
                                />
                            </div>
                        )
                        : null
                }
                {
                    this.props.config.specs.page_visible ?
                        (
                            <div className={this.props.classes.elementContainer}>
                                <InputLabel className={this.props.classes.textFeildLabel} style={{ alignSelf: 'flex-start' }}>
                                    {this.props.config.specs.display_name}
                                </InputLabel>
                                <OutlinedInput
                                    multiline
                                    rows="5"
                                    value={this.state.specs}
                                    onChange={(event) => this.onChangeHandler(event, "Specs")}
                                    className={this.props.classes.textArea}
                                    classes={{ multiline: this.props.classes.multiline }}
                                    labelWidth={0}
                                />
                            </div>
                        )
                        : null
                }
                <DialogActions classes={{ root: this.props.classes.dialogAction }}>
                    <Button onClick={this.handleClose} color="primary">
                        {t("Cancel")}
                    </Button>
                    <Button
                        variant="contained"
                        color="primary"
                        disabled={!(this.state.changedNotes || this.state.changedSpecs)}
                        className={this.props.classes.saveButton}
                        onClick={this.handleUpdate}
                    >
                        {t("Save")}
                        </Button>
                </DialogActions>
            </Dialog>
        )
    }
}

export default withTranslation()((withStyles(styles)(SchedulesModal)));