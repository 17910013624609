import React, { Component } from 'react';
import * as actionCreators from '../../actions/actionCreators';
import { connect } from 'react-redux';
import { KeyboardBackspace } from '@material-ui/icons';
import { Scrollbars } from 'react-custom-scrollbars';
import {
    Card, CardContent, InputLabel, TextField, Select, withStyles, OutlinedInput, Button, Typography, Grid
} from '@material-ui/core';
import DatePicker from 'react-date-picker';
import { withTranslation } from "react-i18next";

const styles = theme => ({
    cardContent: {
        height: '91%',
        "overflow-y": "auto"
    },
    cardContentRoot: {
        '&:last-child': {
            paddingBottom: 16
        }
    },
    card: {
        width: '100%',
        borderRadius: 0,
        height: '80vh'
    },
    textField: {
        width: '60%',
        marginRight: 20
    },
    calenderField: {
        width: '60%',
        marginRight: 20,
        height: '50px !important',

    },
    textArea: {
        width: '80%',
        marginRight: 20
    },
    outlinedInput: {
        padding: 14
    },
    saveButton: {
        fontSize: "14px",
        fontWeight: "500",
        textAlign: "center",
        color: "#ffffff",
        backgroundColor: "#42D784",
        '&:hover': {
            backgroundColor: '#42d784'
        },
        '&:disabled': {
            backgroundColor: '#f6f6f6'
        },
        borderRadius: "3px",
        boxShadow: "0 0 1px 0 rgba(0, 0, 0, 0.05)",
        width: "110px",
        height: "36px",
        marginRight: "24px"
    },
    inputFieldDiv: {
        display: "flex",
        justifyContent: "space-between",
        width: '50%',
        float: 'left',
        paddingTop: '1%',
        height: 80,
        'align-items': 'baseline'
    },
    textareaInputFieldDiv: {
        display: "flex",
        justifyContent: "space-between",
        width: '100%',
        float: 'left',
        paddingTop: '1%',
        height: 180,
        'align-items': 'baseline'
    },
    textFieldLabel: {
        color: 'rgba(0, 0, 0, 0.4000000059604645)',
        'letter-spacing': 0.5,
        'font-weight': 500,
        fontSize: "14px",
        lineHeight: 2.5,
        width: '30%',
        marginLeft: 20,
        //text-align': 'right'
    },
    textareaFieldLabel: {
        color: 'rgba(0, 0, 0, 0.4000000059604645)',
        'letter-spacing': 0.5,
        'font-weight': 500,
        fontSize: "14px",
        lineHeight: 2.5,
        width: '15%',
        marginLeft: 20,
        //'text-align': 'right'
    },
    requiredClass: {
        color: '#d32f2f'
    },
    actionButtonContainer: {
        display: 'flex',
        'justify-content': 'space-between',
        height: 50,
        'border-bottom': '1px solid rgba(207, 216, 220, 0.5)'
    },
    backButtonContainer: {
        display: 'flex',
        'align-items': 'center',
        paddingLeft: 21
    },
    arrowBack: {
        cursor: 'pointer'
    },
    entityTitle: {
        color: 'rgba(0, 0, 0, 0.8)',
        'font-family': 'Roboto',
        'font-size': 21,
        'font-weight': '400',
        'letter-spacing': 0.5,
        marginLeft: 10
    }
})
// const subscriptionMandatory = this.props.t("Please enter both Subscription Start and Subscription End.")
// const validSubscription = this.props.t("Subscription End should be greater than or equal to Subscription Start.")

class DevicesEntity extends Component {

    state = {
        addNew: true,
        disableSaveButton: true,
        plantId: "",
        deviceTypesId: "",
        deviceIdentifier: "",
        specs: "",
        notes: "",
        labelWidth: 0,
        deviceIdentifierError: false,
        specsError: false,
        notesError: false,
        deviceTypeError: false,
        isAdmin: false,
        ssid:"",
        wifiMacId: "",
        wifiMacIdError: false,
        subscriptionEnd: null,
        subscriptionEndError: false,
        subscriptionStart: null,
        subscriptionStartError: false,
        subscriptionId: "",
        subscriptionError: "Subscription start date can not be greater than the subscription end date."
    }

    // Handle changes to any user input field
    inputChangeHandler = (event, field) => {

        switch (field) {
            case "specs": this.setState({ specs: event.target.value, disableSaveButton: false });
                this.checkValidation("specs", event.target.value);
                break;
            case "notes": this.setState({ notes: event.target.value, disableSaveButton: false });
                this.checkValidation("notes", event.target.value);
                break;
            case "deviceTypesId": this.setState({ deviceTypesId: event.target.value, disableSaveButton: false });
                this.checkValidation("deviceTypesId", event.target.value);
                break;
            case "deviceIdentifier":
                const input = event.target;
                const start = input.selectionStart;
                const end = input.selectionEnd;
                this.setState({
                    deviceIdentifier: input.value.toUpperCase(),
                    disableSaveButton: false
                },
                    () => input.setSelectionRange(start, end));
                this.checkValidation("deviceIdentifier", input.value.toUpperCase());
                break;
            case "wifiMacId": this.setState({ wifiMacId: event.target.value, disableSaveButton: false })
                this.checkValidation("wifiMacId", event.target.value);
                break;
            case "subscriptionEnd": this.setState({ subscriptionEnd: event.target.value, disableSaveButton: false })
                break;
            default: console.log("default case");
        }
    }

    // Handle routing
    goToBackPage = () => {
        this.props.history.goBack();
    }

    // update devices entity
    updateDevicesData = () => {
        const { specs, notes, deviceTypesId, deviceIdentifier, addNew, plantId, wifiMacId, subscriptionId } = this.state
        var subscriptionEnd = this.state.subscriptionEnd ? (this.state.subscriptionEnd.getFullYear()).toString() + '-' + (this.state.subscriptionEnd.getMonth() + 1).toString().padStart(2, '0') + '-' + (this.state.subscriptionEnd.getDate()).toString().padStart(2, '0') : null;
        var subscriptionStart = this.state.subscriptionStart ? (this.state.subscriptionStart.getFullYear()).toString() + '-' + (this.state.subscriptionStart.getMonth() + 1).toString().padStart(2, '0') + '-' + (this.state.subscriptionStart.getDate()).toString().padStart(2, '0') : null;
        this.props.updateDevicesEntity({ specs, notes, deviceTypesId, deviceIdentifier, plantId, wifiMacId, subscriptionEnd, subscriptionStart, subscriptionId }, addNew);
    }

    // create device-types dropdown list
    getDeviceType = () => {
        let deviceTypeList = [];
        deviceTypeList.push(<option key={""} disabled={true} value={""}>{this.props.t("Select")}</option>);
        if ((this.state.validationData.deviceTypeName.validations && !this.state.validationData.deviceTypeName.validations.required) || !(this.state.validationData.deviceTypeName.validations)) {
            deviceTypeList.push(<option key={""} value={""}>{this.props.t("None")}</option>);
        }
        let found = false;
        let plantOrganization = "";

        if (this.state.plantId && this.state.plantId != "") {
            this.props.scopeSelector.subOrganizations.map((organization) => {
                if (organization.plants && organization.plants.length > 0) {
                    organization.plants.map((plant) => {
                        if (plant.id === this.state.plantId && !found) {
                            plantOrganization = organization.id;
                            found = true;
                        }
                    })
                }
            })
        }

        if (plantOrganization && this.props.staticData && this.props.staticData.staticData) {
            let plantOrganizationStaticData = this.props.staticData.staticData[plantOrganization]
            if (plantOrganizationStaticData && plantOrganizationStaticData.devicetypes) {
                plantOrganizationStaticData.devicetypes.map((data) => {
                    deviceTypeList.push(
                        <option key={data.id} value={data.id}>{this.props.t(data.name)}</option>
                    );
                })
            }
        }
        return deviceTypeList;
    }

    componentDidMount() {
        const datePickers = document.getElementsByClassName(
            "react-date-picker__inputGroup__input"
        );
        for (let i = 0; i < datePickers.length; i++) {
            datePickers[i].setAttribute("readonly", true);
        }
        if (this.props.data) {
            let entityNames = Object.keys(this.props.data)
            entityNames.map((data) => {
                if (this.props.data[data] == null) {
                    this.props.data[data] = ""
                }
            })
            this.setState({
                deviceIdentifier: this.props.data.deviceIdentifier,
                firmwareVersion: this.props.data.firmwareVersion,
                ssid:this.props.data.ssid,
                wifiMacId: this.props.data.wifiMacId,
                addNew: false,
                subscriptionStart: this.props.data.subscriptionStart ? new Date(this.props.data.subscriptionStart) : null,
                subscriptionEnd: this.props.data.subscriptionEnd ? new Date(this.props.data.subscriptionEnd) : null

            })

            if (this.props.data.specs && this.props.data.specs != "") {
                if (this.props.data.specs.props) {
                    this.setState({ specs: this.props.data.specs.props.children })
                }
                else {
                    this.setState({ specs: this.props.data.specs })
                }
            }
            if (this.props.data.notes && this.props.data.notes != "") {
                if (this.props.data.notes.props) {
                    this.setState({ notes: this.props.data.notes.props.children })
                }
                else {
                    this.setState({ notes: this.props.data.notes })
                }
            }
            if (this.props.data.deviceTypes && this.props.data.deviceTypes.id) {
                this.setState({ deviceTypesId: this.props.data.deviceTypes.id });
            }
        }
        if (!this.props.data) {
            this.setState({ addNew: true })
        }

        if (this.props.selectedScopeData) {
            this.setState({
                plantId: this.props.selectedScopeData,
            })
        }

        if (this.props.validationData && this.props.validationData.config && this.props.validationData.config.Devices) {
            this.setState({ validationData: this.props.validationData.config.Devices });
        }
    }

    componentDidUpdate(prevProps, prevState) {
        if (this.props.userData !== prevProps.userData) {
            var isAdmin = this.state.isAdmin;
            isAdmin = (this.props.userData.admin
                && (this.props.userData.parentOrganizationType === "publisher"))
                ? false : true;
            this.setState({ isAdmin: isAdmin });
        }
    }

    // disable save button based on validations
    disableSaveButton = () => {
        if (this.state.deviceIdentifier === "" && (this.state.validationData && this.state.validationData.deviceIdentifier && this.state.validationData.deviceIdentifier.validations && this.state.validationData.deviceIdentifier.validations.required)) {
            return true;
        }
        else if (this.state.notes === "" && (this.state.validationData && this.state.validationData.notes && this.state.validationData.notes.validations && this.state.validationData.notes.validations.required)) {
            return true;
        }
        else if (this.state.specs === "" && (this.state.validationData && this.state.validationData.specs &&  this.state.validationData.specs.validations && this.state.validationData.specs.validations.required)) {
            return true;
        }
        else if (this.state.deviceTypesId === "" && (this.state.validationData &&  this.state.validationData.deviceTypeName && this.state.validationData.deviceTypeName.validations && this.state.validationData.deviceTypeName.validations.required)) {
            return true;
        }
        else if (this.state.wifiMacId === "" && (this.state.validationData && this.state.validationData.wifiMacId && this.state.validationData.wifiMacId.validations && this.state.validationData.wifiMacId.validations.required)) {
            return true;
        }
        else if (this.state.deviceIdentifierError || this.state.specsError || this.state.notesError || this.deviceTypeError || this.state.wifiMacIdError || this.state.subscriptionEndError) {
            return true;
        }
        else {
            return false;
        }
    }

    // check validations of the user input fields
    checkValidation = (field, value) => {
        switch (field) {
            case "deviceTypesId":
                if (!this.state.deviceTypeError && value === "" && (this.state.validationData.deviceTypeName.validations && this.state.validationData.deviceTypeName.validations.required)) {
                    this.setState({ deviceTypeError: true });
                } else if (this.state.deviceTypeError) {
                    if ((value === "" && (this.state.validationData.deviceTypeName.validations && this.state.validationData.deviceTypeName.validations.required))) {
                        //skip
                    }
                    else {
                        this.setState({ deviceTypeError: false });
                    }
                };
                break;
            case "deviceIdentifier":
                const regEx = RegExp(/^(((\d|([a-f]|[A-F])){2}:){5}(\d|([a-f]|[A-F])){2})$|^(((\d|([a-f]|[A-F])){2}-){5}(\d|([a-f]|[A-F])){2})$|^$/);
                this.setState({ deviceIdentifierError: !regEx.test(value) });

                if (regEx.test(value)) {
                    if (!this.state.deviceIdentifierError && value === "" && (this.state.validationData.deviceIdentifier.validations && this.state.validationData.deviceIdentifier.validations.required)) {
                        this.setState({ deviceIdentifierError: true });
                    } else if (!this.state.deviceIdentifierError && (this.state.validationData.deviceIdentifier.validations) && (value.length > this.state.validationData.deviceIdentifier.validations.maxLength)) {
                        this.setState({ deviceIdentifierError: true });
                    } else if (this.state.deviceIdentifierError) {
                        if ((value === "" && (this.state.validationData.deviceIdentifier.validations && this.state.validationData.deviceIdentifier.validations.required) || ((this.state.validationData.deviceIdentifier.validations) && (value.length > this.state.validationData.deviceIdentifier.validations.maxLength)))) {
                            //skip
                        }
                        else {
                            this.setState({ deviceIdentifierError: false });
                        }
                    };
                }
                break;
            case "notes":
                if (!this.state.notesError && value === "" && (this.state.validationData.notes.validations && this.state.validationData.notes.validations.required)) {
                    this.setState({ notesError: true });
                } else if (!this.state.notesError && (this.state.validationData.notes.validations) && (value.length > this.state.validationData.notes.validations.maxLength)) {
                    this.setState({ notesError: true });
                } else if (this.state.notesError) {
                    if ((value === "" && (this.state.validationData.notes.validations && this.state.validationData.notes.validations.required) || ((this.state.validationData.notes.validations) && (value.length > this.state.validationData.notes.validations.maxLength)))) {
                        //skip
                    }
                    else {
                        this.setState({ notesError: false });
                    }
                };
                break;
            case "specs":
                if (!this.state.specsError && value === "" && (this.state.validationData.specs.validations && this.state.validationData.specs.validations.required)) {
                    this.setState({ specsError: true });
                } else if (!this.state.specsError && (this.state.validationData.specs.validations) && (value.length > this.state.validationData.specs.validations.maxLength)) {
                    this.setState({ specsError: true });
                } else if (this.state.specsError) {
                    if ((value === "" && (this.state.validationData.specs.validations && this.state.validationData.specs.validations.required) || ((this.state.validationData.specs.validations) && (value.length > this.state.validationData.specs.validations.maxLength)))) {
                        //skip
                    }
                    else {
                        this.setState({ specsError: false });
                    }
                };
                break;
            case "wifiMacId":
                if (!this.state.wifiMacIdError && value === "" && (this.state.validationData.wifiMacId.validations && this.state.validationData.wifiMacId.validations.required)) {
                    this.setState({ wifiMacIdError: true });
                } else if (!this.state.wifiMacIdError && (this.state.validationData.wifiMacId.validations) && (value.length > this.state.validationData.wifiMacId.validations.maxLength)) {
                    this.setState({ wifiMacIdError: true });
                } else if (this.state.wifiMacIdError) {
                    if ((value === "" && (this.state.validationData.wifiMacId.validations && this.state.validationData.wifiMacId.validations.required) || ((this.state.validationData.wifiMacId.validations) && (value.length > this.state.validationData.wifiMacId.validations.maxLength)))) {
                        //skip
                    }
                    else {
                        this.setState({ wifiMacIdError: false });
                    }
                };
                break;
        }
    }

    // set card header
    getCardHeader = () => {
        if (this.props.data && this.props.data.deviceIdentifier && this.props.data.deviceIdentifier != "") {
            return this.props.data.deviceIdentifier;
        }
        else {
            return this.props.t("Register New");
        }
    }

    handleDateChange = (date, type) => {
        if (type === "start") {
            this.setState({ subscriptionStart: date, changedSubscriptionStart: true }, () => {
                if (this.state.subscriptionStart !== null && this.state.subscriptionEnd !== null) {
                    if (this.state.subscriptionEnd < this.state.subscriptionStart) {
                        this.setState({ subscriptionEndError: true, subscriptionError: this.props.t("Subscription End should be greater than or equal to Subscription Start.") })
                    } else {
                        this.setState({ subscriptionEndError: false, disableSaveButton: false })
                    }
                } else if ((this.state.subscriptionEnd === null && this.state.subscriptionStart !== null) || (this.state.subscriptionEnd !== null && this.state.subscriptionStart === null)) {
                    this.setState({ subscriptionEndError: true, subscriptionError: this.props.t("Please enter both Subscription Start and Subscription End.") })
                } else {
                    this.setState({ subscriptionEndError: false, disableSaveButton: false })
                }
            })
        } else {
            this.setState({ subscriptionEnd: date, changedSubscriptionEnd: true }, () => {
                if (this.state.subscriptionStart !== null && this.state.subscriptionEnd !== null) {
                    if (this.state.subscriptionEnd < this.state.subscriptionStart) {
                        this.setState({ subscriptionEndError: true, subscriptionError: this.props.t("Subscription End should be greater than or equal to Subscription Start.") })
                    } else {
                        this.setState({ subscriptionEndError: false, disableSaveButton: false })
                    }
                } else if ((this.state.subscriptionEnd === null && this.state.subscriptionStart !== null) || (this.state.subscriptionEnd !== null && this.state.subscriptionStart === null)) {
                    this.setState({ subscriptionEndError: true, subscriptionError: this.props.t("Please enter both Subscription Start and Subscription End.") })
                } else {
                    this.setState({ subscriptionEndError: false, disableSaveButton: false })
                }
            })
        }

    };
    // get helptext for user input fields
    getHelpText = fieldName => {
        switch (fieldName) {
            case 'wifiMacId':
                return this.props.t(`Max ${this.state.validationData.wifiMacId.validations.maxLength} characters`);
        }
    }

    render() {
        const { t } = this.props;
        return (
            <Card className={this.props.classes.card}>
                <div className={this.props.classes.actionButtonContainer}>
                    <div className={this.props.classes.backButtonContainer}>
                        <KeyboardBackspace onClick={this.goToBackPage} classes={{ root: this.props.classes.arrowBack }} />
                        <div className={this.props.classes.entityTitle}>{this.getCardHeader()}</div>
                    </div>
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                        <Button variant="contained"
                            className={this.props.classes.saveButton}
                            disabled={this.state.disableSaveButton || this.disableSaveButton()}
                            onClick={this.updateDevicesData}>{t("Save")}</Button>
                    </div>
                </div>
                <CardContent className={this.props.classes.cardContent} classes={{ root: this.props.classes.cardContentRoot }}>
                    {this.state.validationData && this.state.validationData.deviceIdentifier && this.state.validationData.deviceIdentifier.page_visible ? (
                        <div className={this.props.classes.inputFieldDiv}>
                            <InputLabel className={this.props.classes.textFieldLabel}>
                                {this.state.validationData.deviceIdentifier.display_name}
                                {(this.state.validationData.deviceIdentifier.validations && this.state.validationData.deviceIdentifier.validations.required) ? (<span className={this.props.classes.requiredClass}>*</span>) : null}
                            </InputLabel>
                            <TextField
                                id="deviceIdentifier"
                                disabled={!this.state.addNew}
                                helperText={(this.state.validationData.deviceIdentifier.validations && this.state.validationData.deviceIdentifier.validations.helpText) ? (this.state.validationData.deviceIdentifier.validations.helpText) : null}
                                name="deviceIdentifier"
                                variant="outlined"
                                className={this.props.classes.textField}
                                onChange={(event) => this.inputChangeHandler(event, "deviceIdentifier")}
                                value={this.state.deviceIdentifier}
                                error={this.state.deviceIdentifierError}
                                InputProps={{
                                    classes: {
                                        input: this.props.classes.outlinedInput
                                    }
                                }}
                            />
                        </div>
                    )
                        : null
                    }
                    {this.state.validationData && this.state.validationData.wifiMacId && this.state.validationData.wifiMacId.page_visible ? (
                        <div className={this.props.classes.inputFieldDiv}>
                            <InputLabel className={this.props.classes.textFieldLabel}>
                                {this.state.validationData.wifiMacId.display_name}
                            </InputLabel>
                            <TextField
                                id="wifiMacId"
                                name="wifiMacId"
                                variant="outlined"
                                className={this.props.classes.textField}
                                helperText={this.getHelpText("wifiMacId")}
                                onChange={(event) => this.inputChangeHandler(event, "wifiMacId")}
                                value={this.state.wifiMacId}
                                error={this.state.wifiMacIdError}
                                InputProps={{
                                    classes: {
                                        input: this.props.classes.outlinedInput
                                    }
                                }}
                            />
                        </div>
                    )
                        : null
                    }
                    {this.state.validationData && this.state.validationData.deviceTypeName && this.state.validationData.deviceTypeName.page_visible ? (
                        <div className={this.props.classes.inputFieldDiv}>
                            <InputLabel className={this.props.classes.textFieldLabel}>
                                {this.state.validationData.deviceTypeName.display_name}
                                {(this.state.validationData.deviceTypeName.validations && this.state.validationData.deviceTypeName.validations.required) ? (<span className={this.props.classes.requiredClass}>*</span>) : null}
                            </InputLabel>
                            <Select
                                native
                                value={this.state.deviceTypesId}
                                onChange={(event) => this.inputChangeHandler(event, "deviceTypesId")}
                                input={
                                    <OutlinedInput
                                        variant="outlined"
                                        name="deviceTypesId"
                                        id="language-native-simple"
                                        labelWidth={this.state.labelWidth}
                                        className={this.props.classes.textField}
                                        error={this.state.deviceTypeError}
                                        classes={{ input: this.props.classes.outlinedInput }}
                                    />
                                }
                            >
                                {this.getDeviceType()}
                            </Select>
                        </div>
                    )
                        : null
                    }
                    {!this.state.addNew && this.state.firmwareVersion !== "" && this.state.validationData && this.state.validationData.firmwareVersion && this.state.validationData.firmwareVersion.page_visible ? (
                        <div className={this.props.classes.inputFieldDiv}>
                            <InputLabel className={this.props.classes.textFieldLabel}>
                                {this.state.validationData.firmwareVersion.display_name}
                                {(this.state.validationData.firmwareVersion.validations && this.state.validationData.firmwareVersion.validations.required) ? (<span className={this.props.classes.requiredClass}>*</span>) : null}
                            </InputLabel>
                            <TextField
                                id="firmwareVersion"
                                disabled={true}
                                name="firmwareVersion"
                                variant="outlined"
                                className={this.props.classes.textField}
                                value={this.state.firmwareVersion}
                                InputProps={{
                                    classes: {
                                        input: this.props.classes.outlinedInput
                                    }
                                }}
                            />
                        </div>
                    )
                        : null
                    }
                    <div className="row col-sm-12" style={{ margin: "0px", padding: "0px" }}>
                        <div className={this.props.classes.inputFieldDiv}>
                            <InputLabel className={this.props.classes.textFieldLabel}>
                                {t("Subscription Start")}
                            </InputLabel>
                            <DatePicker
                                disabled={this.state.isAdmin}
                                onChange={date => this.handleDateChange(date, "start")}
                                value={this.state.subscriptionStart}
                                className={this.props.classes.calenderField}
                                format="dd-MM-y"
                            />

                            {/* <TextField
                            id="subscriptionEnd"
                            name="subscriptionEnd"
                            variant="outlined"
                            type="date"
                            className={this.props.classes.textField}
                            onChange={(event) => this.inputChangeHandler(event, "subscriptionEnd")}
                            value={this.state.subscriptionEnd}
                            error={this.state.subscriptionEndError}
                            InputProps={{
                                classes: {
                                    input: this.props.classes.outlinedInput
                                }
                            }}
                        /> */}
                        </div>
                        <div className={this.props.classes.inputFieldDiv}>
                            <InputLabel className={this.props.classes.textFieldLabel}>
                                {t("Subscription End")}
                            </InputLabel>
                            <DatePicker
                                disabled={this.state.isAdmin}
                                onChange={date => this.handleDateChange(date, "end")}
                                value={this.state.subscriptionEnd}
                                className={this.props.classes.calenderField}
                                format="dd-MM-y"
                            />

                            {/* <TextField
                            id="subscriptionEnd"
                            name="subscriptionEnd"
                            variant="outlined"
                            type="date"
                            className={this.props.classes.textField}
                            onChange={(event) => this.inputChangeHandler(event, "subscriptionEnd")}
                            value={this.state.subscriptionEnd}
                            error={this.state.subscriptionEndError}
                            InputProps={{
                                classes: {
                                    input: this.props.classes.outlinedInput
                                }
                            }}
                        /> */}
                        </div>

                        {this.state.subscriptionEndError ?
                            <div style={{ marginLeft: "68%", marginTop: "0px", fontSize: "12px", color: "red" }}> {this.state.subscriptionError}</div>
                            : ""}
                    </div>
                    {!this.state.addNew && <>
                        <div className={this.props.classes.inputFieldDiv}>
                            <InputLabel className={this.props.classes.textFieldLabel}>
                                {t("Wi-Fi SSID")}</InputLabel>
                            <TextField
                                id="deviceIdentifier"
                                disabled
                                name="deviceIdentifier"
                                variant="outlined"
                                helperText={t("Last Know Value")}
                                className={this.props.classes.textField}
                                value={this.state.ssid}
                                InputProps={{
                                    classes: {
                                        input: this.props.classes.outlinedInput
                                    }
                                }}
                            />
                        </div>
                        <div className={this.props.classes.inputFieldDiv}>
                            <InputLabel className={this.props.classes.textFieldLabel}>
                                {t("Wi-Fi router IP address")}</InputLabel>
                            <TextField
                                id="deviceIdentifier"
                                disabled
                                name="deviceIdentifier"
                                variant="outlined"
                                helperText={t("Last Know Value")}
                                className={this.props.classes.textField}
                                InputProps={{
                                    classes: {
                                        input: this.props.classes.outlinedInput
                                    }
                                }}
                            />
                        </div>
                        {/* <div className={this.props.classes.inputFieldDiv}>
                            <InputLabel className={this.props.classes.textFieldLabel}>
                                {t("Wi-Fi router Internet Speed")}</InputLabel>
                            <TextField
                                id="deviceIdentifier"
                                disabled
                                name="deviceIdentifier"
                                variant="outlined"
                                className={this.props.classes.textField}
                                // onChange={(event) => this.inputChangeHandler(event, "deviceIdentifier")}
                                // value={this.state.deviceIdentifier}
                                InputProps={{
                                    classes: {
                                        input: this.props.classes.outlinedInput
                                    }
                                }}
                            />
                        </div>
                    */}
                    </>}
                    {this.state.validationData && this.state.validationData.notes && this.state.validationData.notes.page_visible ? (
                        <div className={this.props.classes.textareaInputFieldDiv}>
                            <InputLabel className={this.props.classes.textareaFieldLabel}>
                                {this.state.validationData.notes.display_name}
                                {(this.state.validationData.notes.validations && this.state.validationData.notes.validations.required) ? (<span className={this.props.classes.requiredClass}>*</span>) : null}</InputLabel>
                            <TextField
                                id="note"
                                multiline
                                rows="6"
                                helperText={(this.state.validationData.notes.validations && this.state.validationData.notes.validations.helpText) ? (this.state.validationData.notes.validations.helpText) : null}
                                value={this.state.notes}
                                variant="outlined"
                                onChange={(event) => this.inputChangeHandler(event, "notes")}
                                className={this.props.classes.textArea}
                                error={this.state.notesError}
                                InputProps={{
                                    classes: {
                                        multiline: this.props.classes.outlinedInput
                                    }
                                }}
                            />
                        </div>
                    )
                        : null
                    }



                    {this.state.validationData && this.state.validationData.specs && this.state.validationData.specs.page_visible ? (
                        <div className={this.props.classes.textareaInputFieldDiv}>
                            <InputLabel className={this.props.classes.textareaFieldLabel}>
                                {this.state.validationData.specs.display_name}
                                {(this.state.validationData.specs.validations && this.state.validationData.specs.validations.required) ? (<span className={this.props.classes.requiredClass}>*</span>) : null}
                            </InputLabel>
                            <TextField
                                id="specification"
                                multiline
                                rows="6"
                                helperText={(this.state.validationData.specs.validations && this.state.validationData.specs.validations.helpText) ? (this.state.validationData.specs.validations.helpText) : null}
                                value={this.state.specs}
                                variant="outlined"
                                onChange={(event) => this.inputChangeHandler(event, "specs")}
                                className={this.props.classes.textArea}
                                error={this.state.specsError}
                                InputProps={{
                                    classes: {
                                        multiline: this.props.classes.outlinedInput
                                    }
                                }}
                            />
                        </div>
                    )
                        : null
                    }
               {/* {!this.state.addNew && <div style={{padding: "20px",color: "green",fontSize: '14px',fontWeight: '600'}}>{t("Note:Incase the device is Disconnected from the wifi last fetched value should display.")}</div>} */}
                </CardContent>
            </Card >
        );
    }
}

const mapStateToProps = (state) => {
    return {
        staticData: state.staticDataReducer,
        validationData: state.viewDefinitionReducer,
        userData: state.userDataReducer,

    }
}

const mapDispatchToProps = dispatch => {
    return ({
        getTableData: (selectedNavtab, currentScopeSelectionId, setToStore) => (dispatch(actionCreators.getTableData(selectedNavtab, currentScopeSelectionId, setToStore))),
        updateDevicesEntity: (devicesEntityData, addnew) => (dispatch(actionCreators.updateDevicesEntity(devicesEntityData, addnew))),
        updateSelectedTab: (selectedTab) => (dispatch(actionCreators.updateSelectedTab(selectedTab)))
    })
}

export default withTranslation()(connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(DevicesEntity)));

