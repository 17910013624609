import React, { Component } from 'react'
import { withTranslation } from "react-i18next";

class ProductionHistory extends Component {
    constructor(props){
        super(props);
        this.state={
            showBookedLoss : false,
            showPlannedDowntime: false
        }
    }  

    render() {
        const {t}=this.props;
        return (
            <div className="productionHistory-wrp display-text"> 
                <div className="header-wrp">                
                    <div className="color-header display-text">
                        <div style={{ width:"24px", height:"12px", background:"#0070c0",display:"inline-block"}}></div>
                        <div style={{ fontSize:"14px" , display:"inline", padding: " 0 4px"}}>{t("Idle")}</div>
                        <div style={{ width:"24px", height:"12px", background:"#109618", display:"inline-block"}}></div>
                        <div style={{ fontSize:"14px", display:"inline", padding: "0 4px"}}>{t("Production")}</div>
                        <div style={{ width: "24px", height:"12px", background: "#999999", display:"inline-block"}}></div>
                        <div style={{ fontSize: "14px", display: "inline", padding: "0 4px"}}>{t("Disconnected")}</div>
                    </div>
                    <div className="header-dropDown display-text">
                        <select className="form-control" onChange={this.props.changeBookedLoss} id="sel_booked_losses">
                            <option value="1">{t("SHOW BOOKED LOSSES")}</option>
                            <option value="2">{t("HIDE BOOKED LOSSES")}</option>
                        </select>
                    </div>
                </div>
                <div className="header-wrp"> 
                    <div className="color-header display-text">                        
                        <div style={{ width:"24px", height:"12px", background:"#cccc00",display:"inline-block"}}></div>
                        <div style={{ fontSize:"14px" , display:"inline", padding: "0 4px"}}>{t("Planned Downtime")}</div>
                        <div style={{ width: "24px", height:"12px", background: "#ff0000", display:"inline-block"}}></div>
                        <div style={{ fontSize:"14px" , display:"inline", padding: "0 4px"}}>{t("Booked Losses")}</div>
                    </div>
                    <div className=" header-dropDown display-text">
                        <select className="form-control" onChange={this.props.changePlannedDowntime} id="sel_planned_downtime">
                            <option value="2">{t("HIDE PLANNED DOWNTIME")}</option>
                            <option value="1">{t("SHOW PLANNED DOWNTIME")}</option>
                        </select>
                    </div>
                </div>
            </div>
        )
    }
}

export default withTranslation()(ProductionHistory);