import React, { Component } from 'react'
import TotalAlarmWidget from './TotalAlarmWidget'
import ProductivityChart from './ProductivityChart'
import { connect } from 'react-redux';
import { withTranslation } from "react-i18next";

class ProductivityPanel extends Component {
    constructor(props){
        super(props);   
        this.state = {
            alarmData : this.props.alarmsDataProd.alarmData ? this.props.alarmsDataProd.alarmData : 0
        }    
    }

    componentDidUpdate(prevProps) {
        if(prevProps.alarmsDataProd.alarmData !== this.props.alarmsDataProd.alarmData){
            this.setState({ alarmData :  this.props.alarmsDataProd.alarmData })
        }
    }
        
    render() {
        const { alarmData } = this.state;
        const{t}=this.props;
        return ( 
            <div className="productivity-panel-wrp widget-col">
                <div className="productivity-panel common-widget">
                    <h1 className="bg-Idle panel-heading">{t("PRODUCTIVITY INDEX")}</h1>
                    <ProductivityChart/>
                </div>
                <div className="table-gap"></div>
                <div className="total-widget-wrp">
                    <div className="totalPart-panel common-widget">
                        <h1 className="bg-Warning panel-heading">{t("TOTAL PART COUNT")}</h1>
                        <div className="totalPart text-total">
                            <h3>{this.props.plantPartCountProd.plantPartCount ? this.props.plantPartCountProd.plantPartCount.actualPartCount : 0}</h3>
                            <div className="text-target">
                                {/* <div className="symbol"></div> */}
                                {t("Target")}, {this.props.plantPartCountProd.plantPartCount ? this.props.plantPartCountProd.plantPartCount.targetPartCount : 0}
                            </div>
                            <div className="clearfix"></div>
                        </div>
                    </div>
                <TotalAlarmWidget alarmData={alarmData}/>
               </div>
            </div>
        )
    }
}

const mapStateToProps = state => {
    return {
      alarmsDataProd : state.saveAlarmDataReducer,
      plantPartCountProd : state.plantPartCountReducer
    }
  }

export default withTranslation()(connect(mapStateToProps, null)(ProductivityPanel));