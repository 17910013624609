import React from "react";
import moment from "moment";
import { withAuth, hasAuthParams } from "react-oidc-context";
import { CircularProgress, withStyles } from "@material-ui/core";
import { withTranslation } from "react-i18next";
import { connect } from "react-redux";

import useGAEventsTracker from "../hooks/useGAEventsTracker";
import * as actionCreators from "../actions/actionCreators";
import { config } from "../configuration";
import { getOidcUser } from "../utils/oidcStorageUtils";

let maintenanceUrl = config.url.maintenance_message_url;

const styles = () => ({
  loaderContainer: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: "100vh",
    background: "#e6e8eb",
  },
  blueLoader: {
    color: "#0189d9",
  },
});

class Login extends React.Component {
  state = {
    maintenanceMessage: "",
  };

  GAEventsTracker = useGAEventsTracker("usersession");

  componentDidMount() {
    const user = getOidcUser();
    const { auth, userData } = this.props;
    this.props.isUserAuthenticated();

    if (userData.loggedIn) {
      this.handleRedirectToDashboard();
    } else if (auth.isAuthenticated && user) {
      this.postLoginUserActions();
    }
  }

  componentDidUpdate(prevProps) {
    const { auth: currAuth, userData: currUserData } = this.props;
    const { auth: prevAuth, userData: prevUserData } = prevProps;

    const user = getOidcUser();
    const { isAuthenticated } = currAuth;

    if (this.hasLoggedInStatusChangedToTrue(prevUserData, currUserData)) {
      this.handleRedirectToDashboard();
    }

    if (this.hasAuthStatusChanged(prevAuth, currAuth)) {
      if (isAuthenticated && user) {
        this.postLoginUserActions();
      }
    }
  }

  hasLoggedInStatusChangedToTrue(prevUserData, currUserData) {
    return !prevUserData.loggedIn && currUserData.loggedIn;
  }

  hasAuthStatusChanged(prevAuth, currAuth) {
    return (
      currAuth.isAuthenticated !== prevAuth.isAuthenticated ||
      currAuth.activeNavigator !== prevAuth.activeNavigator ||
      currAuth.isLoading !== prevAuth.isLoading
    );
  }

  handleRedirectToDashboard() {
    let str = "";
    if (window.location.href.indexOf("?") !== -1) {
      this.props.updatedashboardType("summary");
      localStorage.setItem("dashboard", "summary");
      let pathArray = window.location.href.split("?plantId=");
      str = pathArray[1];
      this.props.updateDrawer(true);
    }
    this.props.history.push({
      pathname: "/dashboard/MonitoringTable",
      plantId: str,
    });
  }

  getMaintenanceMessage = () => {
    const headers = {
      "Content-Type": "application/json",
      "access-control-allow-origin": "*",
      "Cache-Control": "no-cache",
      "Accept-Language": localStorage.getItem("lang") || "en"
    };

    fetch(`${maintenanceUrl}`, {
      method: "GET",
      headers: headers,
    })
      .then((res) => res.json())
      .then((result) => {
        this.setState({ maintenanceMessage: result.maintenanceMessage });
      })
      .catch((_) => {
        this.setState({ maintenanceMessage: "" });
      });
  };

  postLoginUserActions() {
    const user = getOidcUser();
    const userData = {
      message: "User logged in",
      selectedTab: 0,
    };

    this.props.updateDrawer(true);

    if (user) {
      const email = user.profile.email.replace("@", "$");
      const label = {
        username: email,
        name: email,
      };
      this.GAEventsTracker("login", label);
      localStorage.setItem("userNameWithdollar", email);
    }
    localStorage.setItem("loginTime", moment());
    this.props.saveUserData(userData);
  }

  render() {
    const { classes } = this.props;
    return (
      <div className={classes.loaderContainer}>
        <CircularProgress className={classes.blueLoader} />
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    userData: state.userDataReducer,
    dashboardType: state.dashboardTypeReducer,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    isUserAuthenticated: () => dispatch(actionCreators.isUserAuthenticated()),
    updatedashboardType: (type) =>
      dispatch(actionCreators.updatedashboardType(type)),
    updateDrawer: (value) => dispatch(actionCreators.updateDrawer(value)),
    saveUserData: (value) => dispatch(actionCreators.saveUserData(value)),
  };
};

export default withTranslation()(
  withStyles(styles)(
    connect(mapStateToProps, mapDispatchToProps)(withAuth(Login))
  )
);
