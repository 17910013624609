import React, { Component } from 'react';
import { withTranslation } from "react-i18next";


class TotalAlarmWidget extends Component {
    constructor(props){
        super(props);
    }  
    
    render() {
        const alarmData = this.props.alarmData;
        const {t}=this.props;
        return ( 
            <div className="totalAlarm-panel common-widget">
                <h1 className="bg-Operational panel-heading">{t("TOTAL ALARMS")}</h1>
                <div className="totalAlarm text-total">
                    <h3 className="display-text">{alarmData}</h3>
                    {/* <div className="display-text text-target">
                        <div className="symbol"></div>
                        <div>Target</div>
                        <div>100</div>
                    </div> */}
                    <div className="clearfix"></div>
                </div>
            </div>
        )
    }
}

export default withTranslation()(TotalAlarmWidget);