import * as actionTypes from '../actions/actionTypes';

export const jobListDataReducer = (state={}, action) => {
    switch(action.type) {

        case actionTypes.JOB_LIST_DATA:
        return Object.assign({}, state, action.payload)

        default: return state
    }
}