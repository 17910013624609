import * as actionTypes from '../actions/actionTypes';

export const alarmReportDataReducer = (state = {
    alarmsDataLength: 0
}, action) => {
    switch (action.type) {

        case actionTypes.REPORT_SAVE_ALARMS_DATA: {
            let newState = {
                alarmsDataLength: action.payload.alarmsData.length,
                alarmsData: action.payload.alarmsData
            }

            return Object.assign({}, state, newState)
        }

        default: return state
    }
}