import React, { Component } from 'react'
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import moment from "moment";
import Picky from "react-picky";
import { Link } from '@material-ui/core';
import RefreshIcon from '@material-ui/icons/Refresh';
import "react-picky/dist/picky.css";
import { NoDataToDisplay } from "react-highcharts-no-data-to-display";
import * as actionCreators from '../../../actions/actionCreators';
import { connect } from 'react-redux';
import { config } from '../../../configuration';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import Button from '@material-ui/core/Button';
import { withTranslation } from "react-i18next";
import { computeRange } from '../../../utils';
import { getOidcAccessToken } from '../../../utils/oidcStorageUtils';

import {
  isEqual,
  get,
  set,
  isEmpty as lodashIsEmpty,
  mapValues,
  forEach,
  keys,
  toNumber,
  unionBy,
  cloneDeep,
} from "lodash";

let currentLanguage = localStorage.getItem("lang");
if (currentLanguage === "null" || currentLanguage === null) {
    currentLanguage = "en";
}


require("highcharts/modules/exporting")(Highcharts);

NoDataToDisplay(Highcharts);

Highcharts.setOptions({
    global: {
        useUTC: false
    },
});

const clonedeep = require('lodash.clonedeep')
class TrendHistory extends Component {
    constructor(props) {
        super(props);
        this.allintervals = {
            "userSelection": [
                { "intervalUnit": "minute", "intervalValue": 1, "intervalName": this.props.t("1 Minute") },
                { "intervalUnit": "minute", "intervalValue": 30, "intervalName": this.props.t("30 Minute") },
                { "intervalUnit": "hour", "intervalValue": 6, "intervalName": this.props.t("6 Hour") }
            ],
            "today": [
                { "intervalUnit": "minute", "intervalValue": 30, "intervalName": this.props.t("30 Minute") },
                { "intervalUnit": "minute", "intervalValue": 1, "intervalName": this.props.t("1 Minute") },
                { "intervalUnit": "hour", "intervalValue": 6, "intervalName": this.props.t("6 Hour") }
            ],
            "thisMonth": [
                { "intervalUnit": "hour", "intervalValue": 6, "intervalName": this.props.t("6 Hour") },
                { "intervalUnit": "day", "intervalValue": 1, "intervalName": this.props.t("1 Day") },
                { "intervalUnit": "day", "intervalValue": 7, "intervalName": this.props.t("7 Day") }
        
            ],
            "30Minutes": [
                { "intervalUnit": "seconds", "intervalValue": 1, "intervalName": this.props.t("All") },
                { "intervalUnit": "seconds", "intervalValue": 10, "intervalName": this.props.t("10 Seconds") },
                { "intervalUnit": "minute", "intervalValue": 1, "intervalName": this.props.t("1 Minute") }
            ],
            "hour": [
                { "intervalUnit": "seconds", "intervalValue": 10, "intervalName": this.props.t("10 Seconds") },
                { "intervalUnit": "minute", "intervalValue": 1, "intervalName": this.props.t("1 Minute") },
                // { "intervalUnit": "minute", "intervalValue": 30, "intervalName": "30 Minute" }
            ],
            "24hour": [
                { "intervalUnit": "minute", "intervalValue": 30, "intervalName": this.props.t("30 Minute") },
                { "intervalUnit": "minute", "intervalValue": 1, "intervalName": this.props.t("1 Minute") },
                { "intervalUnit": "hour", "intervalValue": 6, "intervalName": this.props.t("6 Hour") }
            ],
            "week": [
                { "intervalUnit": "minute", "intervalValue": 30, "intervalName": this.props.t("30 Minute") },
                { "intervalUnit": "hour", "intervalValue": 6, "intervalName": this.props.t("6 Hour") },
                { "intervalUnit": "day", "intervalValue": 1, "intervalName": this.props.t("1 Day") }
            ],
            "month": [
                { "intervalUnit": "hour", "intervalValue": 6, "intervalName": this.props.t("6 Hour") },
                { "intervalUnit": "day", "intervalValue": 1, "intervalName": this.props.t("1 Day") },
                { "intervalUnit": "day", "intervalValue": 7, "intervalName": this.props.t("7 Day") }
            ],
            "year": [
                { "intervalUnit": "day", "intervalValue": 1, "intervalName": this.props.t("1 Day") },
                { "intervalUnit": "day", "intervalValue": 7, "intervalName": this.props.t("7 Day") },
                { "intervalUnit": "day", "intervalValue": 30, "intervalName": this.props.t("1 Month") }
            ],
            "all": [
                { "intervalUnit": "day", "intervalValue": 7, "intervalName": this.props.t("7 Day") },
                { "intervalUnit": "day", "intervalValue": 30, "intervalName": this.props.t("1 Month") }
            ]
        }
        this.functions = [
            { "value": "minvalue", "viewValue": this.props.t("Min") },
            { "value": "maxvalue", "viewValue": this.props.t("Max") },
            { "value": "average", "viewValue": this.props.t("Avg") }
        ]
        this.chartOptions = {
            "chart": {
                "type": "spline",
                "zoomType": 'x',
                "resetZoomButton": {
                    "position": {
                        // align: 'right', // by default
                        // verticalAlign: 'top', // by default
                        x: -20,
                        y: 40
                    }
                }
            },
            "colors": [
                "#8f004c", "#11d92b", "#4221ca", "#fff943", "#d53bef", "#a9ff75", "#ee00be", "#45ffab", "#ff6bfe",
                "#019f33", "#4c007a", "#d1c500", "#7c7eff", "#68a400", "#003aa0", "#f9a000", "#00235a", "#c4ff8f",
                "#c90080", "#86ffb5", "#f40014", "#00efcc", "#d22d00", "#01e8f6", "#880007", "#8effee", "#af003a",
                "#01a65f", "#ff6db8", "#478700", "#ad96ff", "#6e6e00", "#200037", "#fffbaa", "#300016", "#ffca73",
                "#005b84", "#ff6b44", "#009189", "#ff5f6d", "#006b3a", "#d8bdff", "#1d2600", "#ffd1e9", "#411e00",
                "#ffded3", "#005657", "#ffa46c", "#6e4700", "#ff9ab1"
            ],
            "title": {
                "text": ""
            },
            "subtitle": {
            },
            xAxis: {
                type: 'datetime',
                dateTimeLabelFormats: {
                    millisecond: '%H:%M', 
                    second: '%H:%M',      
                },
            },           
            "yAxis": {
                "title": {
                    "text": ""
                },
                showLastLabel: true,
                showFirstLabel: true,
                startOnTick: false,
                endOnTick: false,
                plotBands: [],
                max: null,
                min: null,
            },
            "tooltip": {
                "crosshairs": true,
                "shared": true,
            },
            "plotOptions": {
                series: {
                    color: ''
                }
            },
            "credits": {
                "enabled": false
            },
            "series": [],
            exporting: {
                menuItemDefinitions: {
                    // Custom definition
                    viewFullscreen: {
                        text: this.props.t('View in full screen')
                    },
                    printChart: {
                        text: this.props.t('Print chart')
                    },
                    downloadPNG: {
                        text: this.props.t('Download PNG image')
                    },
                    downloadJPEG: {
                        text: this.props.t('Download JPEG image')
                    },
                    downloadPDF: {
                        text: this.props.t('Download PDF document')
                    },
                    downloadSVG: {
                        text: this.props.t('Download SVG vector image')
                    },
                },
                buttons: {
                    contextButton: {
                        menuItems: ["viewFullscreen", "printChart", "separator", "downloadPNG", "downloadJPEG", "downloadPDF", "downloadSVG"]
                    }
                }
            },
            "lang": {
                "noData": ''
            },
            "noData": {
                "position": {
                    "x": 0,
                    "y": 0,
                    "align": "center",
                    "verticalAlign": "middle",
                    "color": "red"
                }
            }, navigation: {
                buttonOptions: {
                    verticalAlign: 'top',
                    y: 10,
                    x: -20
                },
                menuStyle: {
                    background: 'white',
                    border: '1px solid #ccc',
                    padding: '8px'
                },
                menuItemStyle: {
                    padding: '4px',
                    margin: '0',
                    display: 'block',
                    whiteSpace: 'nowrap'
                }
            }
        }
        this.state = {
            deviceId: null,
            interval: [],
            value: { "intervalUnit": "hour", "intervalValue": 6, "intervalName": this.props.t("6 Hour") },
            from: '',
            to: '',
            intervalUnit: '',
            intervalValue: '',
            timeSeriesData: {},
            data: {},
            functionValue: { "value": "average", "viewValue": this.props.t("Avg") },
            options: this.chartOptions,
            arrayValue: [],
            isLoaded: false,
            intervalOpen: false,
            functionOpen: false,
            defaultFeature: [],
            minimumData: {},
            maximumData: {},
            forecastData: {},
            diagnosticRecommendationData: [],
            enableFuncAndInterval: false,
            showHealthScore: false,
            odrFlag: true,
            computedData: []
        }

        this.chart = React.createRef();
    }

    componentDidMount() {
        this.intervalChange(this.state.value);
        let selectedOrg = this.props.props.userData && this.props.props.userData.selectedOrganization;
        if (selectedOrg == undefined) {
            let rootOrganization = this.props.props.userData.scopeSelector.subOrganizations.find((organization) => (
                organization.root
            ))
            selectedOrg = rootOrganization.id;
        }
        let odrFftFlag = this.props.props.userData && this.props.props.userData.scopeSelector.subOrganizations.find(ele => ele.id === selectedOrg);
        if (odrFftFlag !== undefined) {
            this.setState({
                odrFlag: odrFftFlag.odrEnable
            })
        }

        const container = this.chart.current.container.current;

        container.style.height = "100%";
        container.style.width = "100%";
        this.chart.current.chart.reflow();
        
        let currentDateTime = new Date();
        currentDateTime.setSeconds(0);
        currentDateTime.setMilliseconds(0);

        let startDate = new Date(currentDateTime);
        let range = [];
       
        startDate.setDate(startDate.getDate() - 30);
        
        range.push(startDate.toISOString());
        range.push(currentDateTime.toISOString());
        let from = moment(range[0]).utc().format("YYYY-MM-DDTHH:mm:00") + "Z";
        let to = moment(range[1]).utc().format("YYYY-MM-DDTHH:mm:00") + "Z";
        let intervalLoacalValue = localStorage.getItem("intervalSet")
        let intervalLoacalValueArray = JSON.parse(intervalLoacalValue);

        let featureSelection, featureSelectionArr = [];
        const featureOpts = this.getFeatureListOptions()
        if (intervalLoacalValueArray && intervalLoacalValueArray.length !== 0) {
            if (Array.isArray(intervalLoacalValueArray)) {
                featureSelection = intervalLoacalValueArray
            } else {
                featureSelection = JSON.parse(intervalLoacalValueArray)
            }

            featureSelection && featureSelection.map((item, index) => {
                let check;
                if (item && item.value === 'healthScore') {
                    featureSelectionArr.push(item);
                } else {
                    if (item && item.viewValue) {
                        // TODO@!: DO NOT REMOVE - Kept for future multilingual reference
                        // let name = this.props.t(item.viewValue)
                        // let name = item.viewValue
                        check = featureOpts && featureOpts.filter(function (e) { return e.value === item.value });
                        if (check && check.length && check.length !== 0) {
                            featureSelectionArr.push(check[0]);
                        }
                    }
                }

            })
        } else {
            featureSelectionArr = this.props.reportFeature.reportFeature.length > 0 ? this.props.reportFeature.reportFeature.value : []
        }
        this.setState({
            deviceId: this.props.deviceId,
            interval: this.allintervals[this.props.range[0]],
            from: from,
            to: to,
            showHealthScore: false,
            range: this.props.range,
            intervalUnit: this.allintervals[this.props.range[0]][0].intervalUnit,
            intervalValue: this.allintervals[this.props.range[0]][0].intervalValue,
            arrayValue: featureSelectionArr,
            value : this.allintervals[this.props.range[0]][0]
        }, () => {
            let chartObj = this.chart.current.chart;
            let scope = this.state.arrayValue.map((view, key) => {
                // TODO@!: DO NOT REMOVE - Kept for future multilingual reference
                view.viewValue = this.props.t(view.viewValue)
                return view

            })
            this.setState({ arrayValue: scope })
        });
    }

    fetchExternalTrendHistoryData = () => {
        const { externalFeaturesGroupIds } = this.props;
        const { intervalUnit, intervalValue } = this.state;

        const dateRange = this.props.range[0];
        
        let [_dateRange, from, to] = computeRange(dateRange);

        if (this.props.range.length === 4 && this.props.range[3] === "search") {
          from =
            moment(this.props.range[1])
              .utc()
              .format("YYYY-MM-DDTHH:mm:00") + "Z";
          to =
            moment(this.props.range[2])
              .utc()
              .format("YYYY-MM-DDTHH:mm:00") + "Z";
        }

        if (
          from &&
          to &&
          intervalValue &&
          intervalUnit &&
          !lodashIsEmpty(externalFeaturesGroupIds)
        ) {
          this.props.getExternalFeaturesTrendHistory(
            from,
            to,
            intervalValue,
            intervalUnit,
            externalFeaturesGroupIds
          );
        }
    }

    componentWillUnmount() {
        this.props.setTrendHistorySelectedFeatures([]);
    }

    componentDidUpdate(prevProps, prevState) {
        let { range, deviceId } = this.props;

        const { timeSeriesData, arrayValue, functionValue } = this.state;

        const {
          externalFeaturesTrendHistoryData: prevExternalFeaturesTrendHistoryData,
          externalFeaturesGroupIds: prevExternalFeaturesGroupIds,
          trendHistoryChartLoading: prevTrendHistoryChartLoading
        } = prevProps;
        const {
          externalFeaturesTrendHistoryData,
          externalFeaturesGroupIds,
          trendHistorySelectedFeatures,
          externalFeatures,
          trendHistoryChartLoading
        } = this.props;
        
        if(!isEqual(externalFeatures, prevProps.externalFeatures)){
            let healthScoreFeature = { "value": "healthScore", "viewValue": this.props.t("Health Score") };
            const featureOpts = this.getFeatureListOptions()
            let intervalLoacalValue = localStorage.getItem("intervalSet")
            let intervalLoacalValueArray = JSON.parse(intervalLoacalValue);
            let featureSelection, featureSelectionArr = [];
            console.log("externalFeatures changed", featureOpts);
            if (intervalLoacalValueArray && intervalLoacalValueArray.length !== 0) {
                if (Array.isArray(intervalLoacalValueArray)) {
                    featureSelection = intervalLoacalValueArray
                } else {
                    featureSelection = JSON.parse(intervalLoacalValueArray)
                }


                featureSelection && featureSelection.map((item, index) => {
                    let check;
                    if (item && item.value === 'healthScore') {
                        featureSelectionArr.push(item);
                    } else {
                        if (item && item.viewValue) {
                            check = featureOpts && featureOpts.filter(function (e) { return e.value === item.value });
                            if (check && check.length && check.length !== 0) {
                                featureSelectionArr.push(check[0]);
                            }
                        }
                    }

                })
            } else {
                featureSelectionArr = [healthScoreFeature]
            }
            if (featureSelectionArr.length === 0) {
                featureSelectionArr = [this.props.featureList[0]]
                
            }

            this.setState({
                arrayValue: healthScoreFeature ? featureSelectionArr : [this.props.featureList[0]]
            }, () => {
                this.transformInputData(this.state.timeSeriesData);
                this.mapDatatoChart();
                let scope = this.state.arrayValue.map((view, key) => {
                    // TODO@!: DO NOT REMOVE - Kept for future multilingual reference
                    view.viewValue = this.props.t(view.viewValue)
                    return view

                })
                this.setState({ arrayValue: scope })
            })
        }

        if(!isEqual(trendHistoryChartLoading, prevTrendHistoryChartLoading)) {
            const chart = get(this.chart, "current.chart");
            if (chart && trendHistoryChartLoading) {
                chart.showLoading(this.props.t("Fetching Data..."))
            }
        }

        if (
          prevState.forecastData !== this.state.forecastData ||
          prevExternalFeaturesTrendHistoryData !==
            externalFeaturesTrendHistoryData
        ) {
          this.props.setTrendHistoryChartLoading(true);
          this.mapDatatoChart();
        }

        if (prevProps.deviceId !== deviceId) {
            let currentDateTime = new Date();
            let startDate = new Date();
            range = [];

            let rangeObj = localStorage.getItem("dateRange")
            rangeObj = rangeObj && rangeObj !== "null" ? rangeObj : "month"
            if (rangeObj === "30Minutes") {
                startDate = new Date(currentDateTime.getTime() - 30 * 1000 * 60);
                this.setState({
                    value: { "intervalUnit": "seconds", "intervalValue": 1, "intervalName": this.props.t("All") },
                })
            }
            if (rangeObj === "hour") {
                startDate = new Date(currentDateTime.getTime() - 60 * 1000 * 60);
                this.setState({
                    value: { "intervalUnit": "seconds", "intervalValue": 10, "intervalName": this.props.t("10 Seconds") },
                })
            }
            if (rangeObj === "24hour") {
                startDate = new Date(currentDateTime.getTime() - 24 * 60 * 1000 * 60);
                this.setState({
                    value: { "intervalUnit": "minute", "intervalValue": 30, "intervalName": this.props.t("30 Minute") },
                })
            }
            if (rangeObj === "week") {
                startDate = new Date(currentDateTime.getTime() - 7 * 24 * 60 * 1000 * 60);
                this.setState({
                    value: { "intervalUnit": "minute", "intervalValue": 30, "intervalName": this.props.t("30 Minute") },
                })
            }
            if (rangeObj === "month") {
                startDate = new Date(currentDateTime);
                startDate.setDate(startDate.getDate() - 30);
                this.setState({
                    value: { "intervalUnit": "hour", "intervalValue": 6, "intervalName": this.props.t("6 Hour") },
                })
            }
            if (rangeObj === "today") {
                startDate = new Date();
                startDate.setHours(0)
                startDate.setMinutes(0);
                startDate.setSeconds(0);
                startDate.setMilliseconds(0);
                this.setState({
                    value: { "intervalUnit": "minute", "intervalValue": 30, "intervalName": this.props.t("30 Minute") },
                })
            }
            if (rangeObj === "thisMonth") {
                startDate = new Date();
                startDate.setDate(1);
                startDate.setHours(0)
                startDate.setMinutes(0);
                startDate.setSeconds(0);
                startDate.setMilliseconds(0);
                this.setState({
                    value: { "intervalUnit": "hour", "intervalValue": 6, "intervalName": this.props.t("6 Hour") },
                })
            }
            if (rangeObj === "year") {
                startDate = new Date();
                let year = currentDateTime.getUTCFullYear();
                startDate.setUTCFullYear(year - 1);
                this.setState({
                    value: { "intervalUnit": "day", "intervalValue": 1, "intervalName": this.props.t("1 Day") },
                })
            }
            if (rangeObj === "all") {
                startDate = new Date(2015, 7, 1, 0, 0, 0, 0);

                this.setState({
                    value: { "intervalUnit": "day", "intervalValue": 7, "intervalName": this.props.t("7 Day") },
                })
            }
            currentDateTime.setHours(currentDateTime.getHours());
            currentDateTime.setMinutes(currentDateTime.getMinutes());
            currentDateTime.setSeconds(0);
            currentDateTime.setMilliseconds(0);
            range.push(rangeObj);
            range.push(startDate.toISOString());
            range.push(currentDateTime.toISOString());
            this.functionChange(functionValue);
            this.setState({
                deviceId: deviceId,
                data: [],
            }, () => {
                let chartObj = this.chart.current.chart;
                chartObj.hideNoData();
                this.fetchData();
                this.fetchExternalTrendHistoryData();
            })
        }

        if (prevProps.featureList !== this.props.featureList) {
            this.props.setTrendHistoryChartLoading(true);
            this.props && this.props.featureList && this.props.featureList.map((view, key) => {
                // TODO@!: DO NOT REMOVE - Kept for future multilingual reference
                view.viewValue = this.props.t(view.viewValue)
                return view

            });
            let initialFeature = this.props.featureList.find(ele => ele.value === '0004')
            if (this.state.odrFlag) {
                let trendSearchStartDate = moment(range[1]);
                let trendSearchEndDate = moment(range[2]);
                let trendSearchDifferenceHours = trendSearchEndDate.diff(trendSearchStartDate, 'hours');
                // TODO@!: DO NOT REMOVE - Kept for future multilingual reference
                let healthScoreFeature = { "value": "healthScore", "viewValue": this.props.t("Health Score") };
                // let healthScoreFeature = { "value": "healthScore", "viewValue": "Health Score" };
                if (trendSearchDifferenceHours >= 24) {
                    if (this.props.featureList.filter(function (e) { return e.value === 'healthScore'; }).length == 0 && this.state.odrFlag) {
                        this.props.addFeature(healthScoreFeature);
                    }

                    if (this.state.showHealthScore !== true) {
                        this.setState({
                            showHealthScore: true
                        })
                    }
                    let intervalLoacalValue = localStorage.getItem("intervalSet")
                    let intervalLoacalValueArray = JSON.parse(intervalLoacalValue);
                    let featureSelection, featureSelectionArr = [];
                    const featureOpts = this.getFeatureListOptions()
                    if (intervalLoacalValueArray && intervalLoacalValueArray.length !== 0) {
                        if (Array.isArray(intervalLoacalValueArray)) {
                            featureSelection = intervalLoacalValueArray
                        } else {
                            featureSelection = JSON.parse(intervalLoacalValueArray)
                        }


                        featureSelection && featureSelection.map((item, index) => {
                            let check;
                            if (item && item.value === 'healthScore') {
                                featureSelectionArr.push(item);
                            } else {
                                if (item && item.viewValue) {
                                    // TODO@!: DO NOT REMOVE - Kept for future multilingual reference
                                    // let name = this.props.t(item.viewValue)
                                    // let name = item.viewValue
                                    check = featureOpts && featureOpts.filter(function (e) { return e.value === item.value });
                                    if (check && check.length && check.length !== 0) {
                                        featureSelectionArr.push(check[0]);
                                    }
                                }
                            }

                        })
                    } else {
                        featureSelectionArr = [healthScoreFeature]
                    }
                    if (featureSelectionArr.length === 0) {
                        featureSelectionArr = [this.props.featureList[0]]
                    }
                    //let intervalset = localStorage.setItem("intervalSet", JSON.stringify(featureSelectionArr));

                    this.setState({
                        arrayValue: healthScoreFeature ? featureSelectionArr : [this.props.featureList[0]]
                    }, () => {
                        this.transformInputData(this.state.timeSeriesData);
                        this.mapDatatoChart();
                        let scope = this.state.arrayValue.map((view, key) => {
                            // TODO@!: DO NOT REMOVE - Kept for future multilingual reference
                            view.viewValue = this.props.t(view.viewValue)
                            return view

                        })
                        this.setState({ arrayValue: scope })
                    })
                }
                else {
                    let intervalLoacalValue = localStorage.getItem("intervalSet")
                    let intervalLoacalValueArray = JSON.parse(intervalLoacalValue);
                    if (intervalLoacalValueArray && intervalLoacalValueArray.length !== 0) {
                        if (!(Array.isArray(intervalLoacalValueArray))) {
                            intervalLoacalValueArray = JSON.parse(intervalLoacalValueArray)
                        }
                        intervalLoacalValueArray.map((item, index) => {
                            if (item && item.value === "healthScore") {
                                intervalLoacalValueArray.splice(index, 1);
                            }
                        })
                        localStorage.setItem("intervalSet", JSON.stringify(intervalLoacalValueArray));
                    }
                    if (this.props.featureList.filter(function (e) { return e.value === 'healthScore'; }).length > 0) {
                        this.props.removeFeature(healthScoreFeature);

                    }

                    if (this.state.showHealthScore !== false) {
                        this.setState({
                            showHealthScore: false
                        })
                    }

                    if (this.state.enableFuncAndInterval !== false) {
                        this.setState({
                            enableFuncAndInterval: false
                        })
                    }

                    let featureSelection, featureSelectionArr = [];
                    const featureOpts = this.getFeatureListOptions()
                    if (intervalLoacalValueArray && intervalLoacalValueArray.length !== 0) {
                        if (Array.isArray(intervalLoacalValueArray)) {
                            featureSelection = intervalLoacalValueArray
                        } else {
                            featureSelection = JSON.parse(intervalLoacalValueArray)
                        }

                        featureSelection && featureSelection.map((item, index) => {
                            let check;
                            if (item && item.value === 'healthScore') {
                                featureSelectionArr.push(item);
                            } else {
                                if (item && item.viewValue) {
                                    // TODO@!: DO NOT REMOVE - Kept for future multilingual reference
                                    // let name = this.props.t(item.viewValue)
                                    // let name = item.viewValue
                                    check = featureOpts && featureOpts.filter(function (e) { return e.value === item.value });
                                    if (check && check.length && check.length !== 0) {
                                        featureSelectionArr.push(check[0]);
                                    }
                                }
                            }


                        })
                    } else {
                        featureSelectionArr = [initialFeature]
                    }
                    if (featureSelectionArr.length === 0) {
                        featureSelectionArr = [this.props.featureList[0]]
                    }
                    //let intervalset = localStorage.setItem("intervalSet", JSON.stringify(featureSelectionArr));

                    this.setState({
                        arrayValue: initialFeature ? featureSelectionArr : [this.props.featureList[0]]
                    }, () => {
                        this.transformInputData(this.state.timeSeriesData);
                        this.mapDatatoChart();
                        let scope = this.state.arrayValue.map((view, key) => {
                            // TODO@!: DO NOT REMOVE - Kept for future multilingual reference
                            view.viewValue = this.props.t(view.viewValue)
                            return view

                        })
                        this.setState({ arrayValue: scope })
                    })
                }
            } else {
                let intervalLoacalValue = localStorage.getItem("intervalSet")
                let intervalLoacalValueArray = JSON.parse(intervalLoacalValue);
                let featureSelection, featureSelectionArr = [];
                const featureOpts = this.getFeatureListOptions()
                if (intervalLoacalValueArray && intervalLoacalValueArray.length !== 0) {
                    if (Array.isArray(intervalLoacalValueArray)) {
                        featureSelection = intervalLoacalValueArray
                    } else {
                        featureSelection = JSON.parse(intervalLoacalValueArray)
                    }

                    featureSelection && featureSelection.map((item, index) => {
                        let check;
                        if (item && item.value === 'healthScore') {
                            featureSelectionArr.push(item);
                        } else {
                            if (item && item.viewValue) {
                                // TODO@!: DO NOT REMOVE - Kept for future multilingual reference
                                // let name = this.props.t(item.viewValue)
                                // let name = item.viewValue
                                check = featureOpts && featureOpts.filter(function (e) { return e.value === item.value });
                                if (check && check.length && check.length !== 0) {
                                    featureSelectionArr.push(check[0]);
                                }
                            }
                        }
                    })
                } else {
                    featureSelectionArr = [initialFeature]
                }
                if (featureSelectionArr.length === 0) {
                    featureSelectionArr = [this.props.featureList[0]]
                }
                let intervalset = localStorage.setItem("intervalSet", JSON.stringify(featureSelectionArr));

                this.setState({
                    arrayValue: initialFeature ? featureSelectionArr : [this.props.featureList[0]]
                }, () => {
                    this.transformInputData(this.state.timeSeriesData);
                    this.mapDatatoChart();
                    let scope = this.state.arrayValue.map((view, key) => {
                        // TODO@!: DO NOT REMOVE - Kept for future multilingual reference
                        view.viewValue = this.props.t(view.viewValue)
                        return view

                    })
                    this.setState({ arrayValue: scope })
                })
            }
            this.props.featureList.map((view, key) => {
                // TODO@!: DO NOT REMOVE - Kept for future multilingual reference
                view.viewValue = this.props.t(view.viewValue)
                return view

            });

        }

        let trendSearchStartDate = moment(range[1]);
        let trendSearchEndDate = moment(range[2]);
        let trendSearchDifference = trendSearchEndDate.diff(trendSearchStartDate, 'days') + 1;
        let monthDays = trendSearchStartDate.format('M') == 2 ? 28 : 30;
        if (trendSearchDifference >= monthDays && arrayValue.length === 1 && arrayValue[0].value !== '0001' && arrayValue[0].value !== '0005' && arrayValue[0].value !== '0006') {
            // MINDAPP-1875 temporarily disable Forecast from dropdown
            // if (functions.filter(function (e) { return e.value === 'forecast'; }).length == 0) {
            //     functions.push({ "value": "forecast", "viewValue": this.props.t("Forecast") });
            // }
        } else {
            if (this.functions.filter(function (e) { return e.value === 'forecast'; }).length > 0) {
                this.functions.pop();
            }
            if (functionValue.value === 'forecast') {
                this.setState({
                    functionValue: { "value": "average", "viewValue": "Avg" }
                }, () => {
                    this.transformInputData(this.state.timeSeriesData);
                });
            }
        }

        let trendSearchDifferenceHours = trendSearchEndDate.diff(trendSearchStartDate, 'hours');
        let healthScoreFeature = { "value": "healthScore", "viewValue": "Health Score" };
        if (trendSearchDifferenceHours >= 24) {
            if (this.props.featureList.filter(function (e) { return e.value === 'healthScore'; }).length == 0 && this.state.odrFlag) {
                this.props.addFeature(healthScoreFeature);
            }

            if (this.state.showHealthScore !== true) {
                this.setState({
                    showHealthScore: true
                })
            }
            let computedFlag = false, otherFlag = false;

            arrayValue.map((item) => {
                if ((item.value.includes("healthScore")) || (item.value.includes("GRMS")) || (item.value.includes("PPAC")) || (item.value.includes("CRESTACC")) || (item.value.includes("KURTACC")) || (item.value.includes("PPSW")) || (item.value.includes("RPM")) || (item.value.includes("SWRMS"))) {
                    computedFlag = true;
                } else {
                    otherFlag = true;
                }

            })
            if (computedFlag && !(otherFlag)) {
                if (this.state.enableFuncAndInterval !== true) {
                    this.setState({
                        enableFuncAndInterval: true
                    })
                }
            } else {
                if (this.state.enableFuncAndInterval !== false) {
                    this.setState({
                        enableFuncAndInterval: false
                    })
                }
            }
        } else {
            if (this.props.featureList.filter(function (e) { return e.value === 'healthScore'; }).length > 0) {
                this.props.removeFeature(healthScoreFeature);
            }

            if (this.state.showHealthScore !== false) {
                this.setState({
                    showHealthScore: false
                })
            }

            let computedFlag = false, otherFlag = false;

            arrayValue.map((item) => {
                if ((item.value.includes("healthScore")) || (item.value.includes("GRMS")) || (item.value.includes("PPAC")) || (item.value.includes("CRESTACC")) || (item.value.includes("KURTACC")) || (item.value.includes("PPSW")) || (item.value.includes("RPM")) || (item.value.includes("SWRMS"))) {
                    computedFlag = true;
                } else {
                    otherFlag = true;
                }

            })
            if (computedFlag && !(otherFlag)) {
                if (this.state.enableFuncAndInterval !== true) {
                    this.setState({
                        enableFuncAndInterval: true
                    })
                }
            } else {
                if (this.state.enableFuncAndInterval !== false) {
                    this.setState({
                        enableFuncAndInterval: false
                    })
                }
            }
        }

        if (prevProps.range[0] !== range[0] || prevProps.range[1] !== range[1] || prevProps.range[2] !== range[2]) {
            let from = moment(range[1]).utc().format("YYYY-MM-DDTHH:mm:00") + "Z";
            let to = moment(range[2]).utc().format("YYYY-MM-DDTHH:mm:00") + "Z";
            let intervalUnit = this.allintervals[range[0]][0].intervalUnit;
            let intervalValue = this.allintervals[range[0]][0].intervalValue;
            let value = this.allintervals[range[0]][0];
            if (range[0] == 'hour' && prevProps.deviceId !== deviceId) {
                intervalUnit = 'minute';
                intervalValue = 1;
                value = { "intervalUnit": "seconds", "intervalValue": 10, "intervalName": "10 Seconds" };
            }
            if (prevProps.range[0] !== range[0]) {
                this.setState({
                    interval: this.allintervals[range[0]],
                    from: from,
                    to: to,
                    intervalValue: intervalValue,
                    intervalUnit: intervalUnit,
                    value: value
                }, () => {

                    let chartObj = this.chart.current.chart;
                    chartObj.hideNoData();
                    this.fetchData();
                    this.fetchExternalTrendHistoryData();
                });
            } else if (prevProps.range[0] === range[0] && (prevProps.range[1] !== undefined || prevProps.range[2] !== undefined)) {
                this.setState({
                    interval: this.allintervals[range[0]],
                    from: from,
                    to: to,
                    intervalValue: intervalValue,
                    intervalUnit: intervalUnit,
                    value: value
                }, () => {

                    let chartObj = this.chart.current.chart;
                    chartObj.hideNoData();
                    this.fetchData();
                    this.fetchExternalTrendHistoryData();
                });
            } else {
                this.setState({
                    interval: this.allintervals[range[0]],
                    from: from,
                    to: to,
                    intervalValue: intervalValue,
                    intervalUnit: intervalUnit,
                    value: this.allintervals[range[0]][0]
                }, () => {

                    let chartObj = this.chart.current.chart;
                    chartObj.hideNoData();
                    this.fetchData();
                    this.fetchExternalTrendHistoryData();
                });
            }
        }

        if (!isEqual(prevExternalFeaturesGroupIds, externalFeaturesGroupIds)) {
            this.fetchExternalTrendHistoryData();
        }

        if (
          !isEqual(
            prevExternalFeaturesTrendHistoryData,
            externalFeaturesTrendHistoryData
          )
        ) {
          this.props.setTrendHistoryChartLoading(true);

          const filteredExternalFeatures = (
            trendHistorySelectedFeatures || []
          ).filter(
            (item) =>
              get(item, "type") === "EXTERNAL_FEATURE" &&
              (externalFeatures || []).some(
                (extFeature) =>
                  get(extFeature, "featureCode") === get(item, "value")
              )
          );

          this.setState(
            {
              arrayValue: unionBy(
                arrayValue,
                filteredExternalFeatures,
                "value"
              ),
            },
            () => {
              this.mapDatatoChart();
            }
          );
        }
    }

    transformInputData(timeSeriesData) {
        this.props.setTrendHistoryChartLoading(true);

        let rawdata = {}
        let minimumData = {}
        let maximumData = {}
        if (timeSeriesData.hasOwnProperty('basic_features')) {
            timeSeriesData['basic_features'].forEach(element => {
                this.props.featureList.forEach(key => {


                    let averageData = JSON.parse(element.jsonavg);
                    if (averageData.hasOwnProperty(key.value)) {
                        if (!rawdata.hasOwnProperty(key.value)) {
                            let object = {};
                            let feature = key.value;
                            object[feature] = [];

                            let dataobject;
                            dataobject = {};
                            dataobject.X = element.time;
                            dataobject.Y = averageData[key.value];
                            object[feature].push(dataobject);
                            rawdata = { ...rawdata, ...object }
                        } else {
                            let dataobject;
                            dataobject = {};
                            dataobject.X = element.time;
                            dataobject.Y = averageData[key.value];
                            rawdata[key.value].push(dataobject);
                        }
                    }
                    let minimumRawData = JSON.parse(element.jsonmin);
                    if (minimumRawData.hasOwnProperty(key.value)) {
                        if (!minimumData.hasOwnProperty(key.value)) {
                            let object = {};
                            let feature = key.value;
                            object[feature] = [];

                            let dataobject;
                            dataobject = {};
                            dataobject.X = element.time;
                            dataobject.Y = minimumRawData[key.value];
                            object[feature].push(dataobject);
                            minimumData = { ...minimumData, ...object }
                        } else {
                            let dataobject;
                            dataobject = {};
                            dataobject.X = element.time;
                            dataobject.Y = minimumRawData[key.value];
                            minimumData[key.value].push(dataobject);
                        }
                    }
                    let maximumRawData = JSON.parse(element.jsonmax);
                    if (maximumRawData.hasOwnProperty(key.value)) {
                        if (!maximumData.hasOwnProperty(key.value)) {
                            let object = {};
                            let feature = key.value;
                            object[feature] = [];

                            let dataobject;
                            dataobject = {};
                            dataobject.X = element.time;
                            dataobject.Y = maximumRawData[key.value];
                            object[feature].push(dataobject);
                            maximumData = { ...maximumData, ...object }
                        } else {
                            let dataobject;
                            dataobject = {};
                            dataobject.X = element.time;
                            dataobject.Y = maximumRawData[key.value];
                            maximumData[key.value].push(dataobject);
                        }
                    }
                })
            });
        }

        if (timeSeriesData.hasOwnProperty('fingerprint_data')) {
            timeSeriesData['fingerprint_data'].forEach(element => {
                this.props.featureList.forEach(key => {
                    let averageData = JSON.parse(element.jsonavg);
                    if (averageData.hasOwnProperty(key.value)) {
                        if (!rawdata.hasOwnProperty(key.value)) {
                            let object = {};
                            let feature = key.value;
                            object[feature] = [];

                            let dataobject;
                            dataobject = {};
                            dataobject.X = element.time;
                            dataobject.Y = averageData[key.value];
                            object[feature].push(dataobject);
                            rawdata = { ...rawdata, ...object }
                        } else {
                            let dataobject;
                            dataobject = {};
                            dataobject.X = element.time;
                            dataobject.Y = averageData[key.value];
                            rawdata[key.value].push(dataobject);
                        }
                    }
                    let minimumRawData = JSON.parse(element.jsonmin);
                    if (minimumRawData.hasOwnProperty(key.value)) {
                        if (!minimumData.hasOwnProperty(key.value)) {
                            let object = {};
                            let feature = key.value;
                            object[feature] = [];

                            let dataobject;
                            dataobject = {};
                            dataobject.X = element.time;
                            dataobject.Y = minimumRawData[key.value];
                            object[feature].push(dataobject);
                            minimumData = { ...minimumData, ...object }
                        } else {
                            let dataobject;
                            dataobject = {};
                            dataobject.X = element.time;
                            dataobject.Y = minimumRawData[key.value];
                            minimumData[key.value].push(dataobject);
                        }
                    }
                    let maximumRawData = JSON.parse(element.jsonmax);
                    if (maximumRawData.hasOwnProperty(key.value)) {
                        if (!maximumData.hasOwnProperty(key.value)) {
                            let object = {};
                            let feature = key.value;
                            object[feature] = [];

                            let dataobject;
                            dataobject = {};
                            dataobject.X = element.time;
                            dataobject.Y = maximumRawData[key.value];
                            object[feature].push(dataobject);
                            maximumData = { ...maximumData, ...object }
                        } else {
                            let dataobject;
                            dataobject = {};
                            dataobject.X = element.time;
                            dataobject.Y = maximumRawData[key.value];
                            maximumData[key.value].push(dataobject);
                        }
                    }
                })
            });
        }

        this.props.setFeatureData([rawdata, maximumData, minimumData, this.state.intervalUnit, this.state.intervalValue]);
        this.setState({
            data: rawdata,
            minimumData: minimumData,
            maximumData: maximumData,
        }, () => {
            let chartObj = this.chart.current.chart;
            chartObj.hideNoData();
            this.mapDatatoChart();
        })  
    }

    generateSeriesDataFromReadings = (readings, featureCode) => {
        if(lodashIsEmpty(readings) || lodashIsEmpty(featureCode)) return [];

        const { functionValue } = this.state;
        const functionParameter = get(functionValue, "value");

        const data = [];

        (readings || []).forEach((reading) => {
            const timeStampInMilliseconds = moment(get(reading, "time", "")).valueOf();
            let dataPointValue = undefined;

            if(functionParameter === "average") {
                dataPointValue = toNumber(get(reading, `jsonavg[${featureCode}]`));
            } else if (functionParameter === "maxvalue") {
                dataPointValue = toNumber(get(reading, `jsonmax[${featureCode}]`));
            } else {
                dataPointValue = toNumber(get(reading, `jsonmin[${featureCode}]`));
            }
            
            if(isFinite(timeStampInMilliseconds) && isFinite(dataPointValue)) {
                data.push([timeStampInMilliseconds, dataPointValue]);
            }
        });

        return data;
    }

    generateChartSeriesForExternalFeature = (featureCode, featureName) => {
        if(lodashIsEmpty(featureCode)) return [];

        const chartSeries = [];
        
        const rawData = get(this.props, "externalFeaturesTrendHistoryData.monitors", []);
        const jsonParsedData = mapValues(rawData, (monitor) => {
            return {
                ...monitor,
                readings: get(monitor, "readings", []).map((reading) => {
                    return {
                        ...reading,
                        jsonavg: JSON.parse(reading.jsonavg),
                        jsonmin: JSON.parse(reading.jsonmin),
                        jsonmax: JSON.parse(reading.jsonmax)
                    }
                }),
            };
        });

        forEach(jsonParsedData, (monitor) => {
            const firstReadingObject = get(monitor, "readings[0]", {});
            const firstReadingObjectKeys = keys(get(firstReadingObject, "jsonavg", {}));
            
            if(firstReadingObjectKeys.includes(featureCode)) {
                const seriesName = `${featureName}`;
                const seriesData = this.generateSeriesDataFromReadings(get(monitor, "readings", []), featureCode);

                chartSeries.push({
                  name: seriesName,
                  data: seriesData,
                });
            }
        });

        return chartSeries;
    }

    mapDatatoChart() {
        const { data, functionValue, arrayValue, forecastData, diagnosticRecommendationData, computedData } = this.state;
        let seriesData = [];
        let computedFlag = false, otherFlag = false;

        arrayValue.map((item) => {
            if ((item.value.includes("GRMS")) || (item.value.includes("PPAC")) || (item.value.includes("CRESTACC")) || (item.value.includes("KURTACC")) || (item.value.includes("PPSW")) || (item.value.includes("RPM") || (item.value.includes("SWRMS")))) {
                computedFlag = true;
            } else {
                otherFlag = true;
            }

        })

        let data2 = {};
        if (functionValue.value === 'minvalue') {
            data2 = clonedeep(this.state.minimumData);
        } else if (functionValue.value === 'maxvalue') {
            data2 = clonedeep(this.state.maximumData);
        } else {
            data2 = clonedeep(this.state.data);
        }

        if (arrayValue.length >= 1) {
            let options1 = clonedeep(this.chartOptions);
            let maxval = 0;
            let plotOptionsSeriesColor = 'white';
            if (!isEmpty(data)) {
                maxval = arrayValue[0].caution + (arrayValue[0].caution * (20 / 100));
                var maxim = 0;
                arrayValue.map((item) => {
                    if (data2[item.value]) {
                        var max = data2[item.value].reduce(function (prev, current) {
                            return (prev.Y > current.Y) ? prev : current
                        });
                        maxim = maxim && maxim.Y < max.Y ? maxim : max;
                    } else {
                        maxim = max;
                    }
                })

                var minimum = 0;
                arrayValue.map((item) => {
                    if (data2[item.value]) {
                        var min = data2[item.value].reduce(function (prev, current) {
                            return (prev.Y < current.Y) ? prev : current
                        });
                        minimum = minimum && minimum.Y < min.Y ? minimum : min;
                    }
                })

                let minDataValue = minimum.Y < 0 ? (minimum.Y - 5) : 0;

                let maxDataValue = maxim ? maxim.Y + (maxim.Y * (20 / 100)) : maxval;
                if (arrayValue.length === 1) {
                    let plotbands;
                    if (arrayValue[0].type === "VELBAND") {
                        plotbands = [{
                            color: "#31ccec",
                            from: minDataValue,
                            to: arrayValue[0].idle,
                        }, {
                            color: "#64dd17",
                            from: arrayValue[0].idle,
                            to: arrayValue[0].operational,
                            label: {
                                text: arrayValue[0].idle,
                                verticalAlign: 'bottom',
                                align: 'left',
                                y: 0,
                                x: 10,
                                style: {
                                    color: '#000000',
                                    fontSize: 14,
                                    fontWeight: 400
                                }
                            }
                        },
                        {
                            color: "#ffc107",
                            from: arrayValue[0].operational,
                            to: arrayValue[0].caution,
                            label: {
                                text: arrayValue[0].operational,
                                verticalAlign: 'bottom',
                                align: 'left',
                                y: 0,
                                x: 160,
                                style: {
                                    color: '#000000',
                                    fontSize: 14,
                                    fontWeight: 400
                                }
                            }
                        },
                        {
                            color: "#ff5722",
                            from: arrayValue[0].caution,
                            to: maxval < maxDataValue ? maxDataValue : maxval,
                            label: {
                                verticalAlign: 'bottom',
                                align: 'left',
                                y: 0,
                                x: 240,
                                text: arrayValue[0].caution,
                                style: {
                                    color: '#000000',
                                    fontSize: 14,
                                    fontWeight: 400
                                }
                            }
                        },
                        ]
                        plotOptionsSeriesColor = 'white';

                    } else {
                        plotbands = [{
                            color: "white",
                            from: 0,
                            to: maxval,

                        }];
                        plotOptionsSeriesColor = 'black';

                    }
                    options1.yAxis.plotBands = plotbands;
                    options1.yAxis.max = maxval < maxDataValue ? maxDataValue : maxval;
                    options1.yAxis.min = minDataValue;
                    let check = (arrayValue[0] && arrayValue[0].unit) !== undefined ? ((arrayValue[0] && arrayValue[0].unit).includes("m/s") && arrayValue[0].unit + " (rms)") : arrayValue[0].unit;
                    options1.yAxis.title.text = check === false ? arrayValue[0].unit : check

                } else {
                    options1.yAxis.plotBands = [];
                    options1.yAxis.max = null;
                    options1.yAxis.min = minDataValue;
                    var valueArr = arrayValue.map(function (item) { return item.unit });
                    var unitEqual = valueArr.every((val, i, arr) => val === arr[0]);
                    if (unitEqual) {
                        options1.yAxis.title.text = (valueArr[0]).includes("m/s") ? valueArr[0] + " (rms)" : valueArr[0];
                    } else {
                        options1.yAxis.title.text = "";
                    }
                }

                options1.series = [];
                let data1 = {};
                if (functionValue.value === 'minvalue') {
                    data1 = clonedeep(this.state.minimumData);
                } else if (functionValue.value === 'maxvalue') {
                    data1 = clonedeep(this.state.maximumData);
                } else {
                    data1 = clonedeep(this.state.data);
                }

                arrayValue.forEach(element => {
                    seriesData = [];
                    if (
                      element.value.includes("GRMS") ||
                      element.value.includes("PPAC") ||
                      element.value.includes("CRESTACC") ||
                      element.value.includes("KURTACC") ||
                      element.value.includes("PPSW") ||
                      element.value.includes("RPM") ||
                      element.value.includes("SWRMS") ||
                      get(element, "type") === "EXTERNAL_FEATURE"
                    ) {
                    } else {
                      if (data1[element.value]) {
                            data1[element.value].forEach(element => {
                          var dateMoment = moment(element.X);
                          seriesData.push([dateMoment.valueOf(), element.Y]);
                        });
                      } else {
                        options1.yAxis.plotBands = [];
                      }
                      options1.series.push({
                        name: element.viewValue,
                        data: seriesData,
                      });
                    }
                });

                if (functionValue.value === 'forecast' && !isEmpty(forecastData) && forecastData.confidence
                    && arrayValue.length === 1 && arrayValue[0].value !== '0001' && arrayValue[0].value !== '0005' && arrayValue[0].value !== '0006') {

                    var forecastTimeSeriesData = [];
                    var subSamplesSeriesData = [];
                    let trendA = forecastData.trendA;
                    let trendB = forecastData.trendB;
                    let subSamples = forecastData.subSamples;

                    let title = this.props.t('Already Alarming');
                    if (forecastData.daysToWarning >= 0) {
                        title = this.props.t("Trend projects") + forecastData.daysToWarning + this.props.t("days to WARNING")
                    }

                    options1.title = {
                        useHTML: true,
                        text: title,
                        floating: true,
                        align: 'left',
                        x: 70,
                        y: 41,
                        style: {
                            fontSize: '9px',
                            backgroundColor: '#ffffff',
                            padding: '9px'
                        }
                    };

                    for (var index = 0; index < subSamples.length; index++) {
                        let subSample = subSamples[index];
                        subSamplesSeriesData.push([subSample[0], subSample[1]]);

                        // if (index == subSamples.length - 1) {
                        //     forecastTimeSeriesData.push({
                        //         x: subSample[0],
                        //         y: subSample[1]
                        //     });
                        // }
                    }

                    forecastTimeSeriesData.push({
                        x: trendA[0],
                        y: trendA[1]
                    });

                    let markerColor = '#100166';
                    forecastTimeSeriesData.push({
                        x: trendB[0],
                        y: trendB[1],
                        color: markerColor,
                        marker: {
                            enabled: true,
                            radius: 4,
                            fillColor: markerColor,
                            symbol: 'circle'
                        }
                    });

                    var forecastSeriesData = {
                        name: "Forecast Data",
                        data: forecastTimeSeriesData,
                        color: '#0070c0'
                    }

                    const forecastSeriesDataIndex = options1.series.findIndex(item => item.name === 'Forecast Data');
                    if (forecastSeriesDataIndex > -1) {
                        options1.series[forecastSeriesDataIndex] = forecastSeriesData
                    } else {
                        options1.series.push(forecastSeriesData);
                    }

                    var regressionSeriesData = {
                        name: "Regression Data",
                        data: subSamplesSeriesData,
                        type: 'scatter',
                        color: '#0070c0',
                        marker: {
                            radius: 2,
                            enabled: true,
                            symbol: 'circle'
                        },
                        tooltip: {
                            useHTML: true,
                            headerFormat: '<span style="font-size:10px">{point.x:%A, %b %e, %H:%M:%S}</span><br>',
                            pointFormatter: function () {
                                var point = this;
                                return '<span style="color:' + point.color + '">\u25CF</span> ' + arrayValue[0].viewValue + ': <b>' + point.y + '</b><br/>';
                            }
                        }
                    }

                    const regressionSeriesDataIndex = options1.series.findIndex(item => item.name === 'Regression Data');
                    if (regressionSeriesDataIndex > -1) {
                        options1.series[regressionSeriesDataIndex] = regressionSeriesData
                    } else {
                        options1.series.push(regressionSeriesData);
                    }

                    options1.yAxis.max = maxval < trendB[1] ? trendB[1] : maxval;
                } else if (functionValue.value === 'forecast' && !isEmpty(forecastData) && !forecastData.confidence
                    && arrayValue.length === 1 && arrayValue[0].value !== '0001' && arrayValue[0].value !== '0005' && arrayValue[0].value !== '0006') {

                    this.handleOpenModal();
                } else if (functionValue.value === 'forecast' && isEmpty(forecastData)
                    && arrayValue.length === 1 && arrayValue[0].value !== '0001' && arrayValue[0].value !== '0005' && arrayValue[0].value !== '0006') {

                    options1.title = {
                        useHTML: true,
                        text: this.props.t("Forecast data could not be fetched, please try again later"),
                        floating: true,
                        align: 'left',
                        x: 70,
                        y: 41,
                        style: {
                            fontSize: '9px',
                            backgroundColor: '#ffffff',
                            padding: '9px',
                            color: 'red'
                        }
                    }
                } else {
                    options1.title = {
                        text: ``,
                        style: {
                            backgroundColor: 'transparent',
                        }
                    }
                }
            }

            var isHealthScoreSelected = arrayValue && arrayValue.filter(function (e) { return e && e.value === 'healthScore'; }).length != 0;
            if (this.state.showHealthScore && isHealthScoreSelected && !isEmpty(diagnosticRecommendationData)) {
                var diagnosticRecommendationSeriesData = [];
                var maxHealthScore = 0;
                for (var index = 0; index < diagnosticRecommendationData.length; index++) {
                    let diagnosticRecommendation = diagnosticRecommendationData[index];
                    if (diagnosticRecommendation.diagnosticDetails.healthScore !== null
                        && diagnosticRecommendation.diagnosticDetails.healthScore > 0) {
                        if (maxHealthScore < diagnosticRecommendation.diagnosticDetails.healthScore) {
                            maxHealthScore = diagnosticRecommendation.diagnosticDetails.healthScore;
                        }
                        
                        const momentDateWithoutSeconds = moment(diagnosticRecommendation.createdOn).startOf('minute',);
                        const timestampWithoutSeconds = momentDateWithoutSeconds.valueOf();

                        diagnosticRecommendationSeriesData.push([
                            timestampWithoutSeconds,
                            diagnosticRecommendation.diagnosticDetails.healthScore
                        ]);
                    }
                }

                if (!isEmpty(diagnosticRecommendationSeriesData)) {
                    var healthScoreSeriesData = {
                // TODO@!: DO NOT REMOVE - Kept for future multilingual reference
                        name: this.props.t("Health Score"),
                        // name: "Health Score",
                        data: diagnosticRecommendationSeriesData
                    }
                // TODO@!: DO NOT REMOVE - Kept for future multilingual reference
                const healthScoreSeriesDataIndex = options1.series.findIndex(item => item.name === this.props.t('Health Score'));
                    // const healthScoreSeriesDataIndex = options1.series.findIndex(item => item.name === 'Health Score');
                    if (healthScoreSeriesDataIndex > -1) {
                        options1.series[healthScoreSeriesDataIndex] = healthScoreSeriesData;
                    } else {
                        options1.series.push(healthScoreSeriesData);
                    }

                    if (maxval) {
                        maxval = maxval < maxHealthScore ? maxHealthScore : maxval;
                    } else {
                        maxval = maxHealthScore
                    }

                    maxval += 5;

                    if (arrayValue.length == 1) {
                        var plotbands = [{
                            color: "#ff5722",
                            from: 0,
                            to: 50,
                        }, {
                            color: "#ffc107",
                            from: 50,
                            to: 85
                        },
                        {
                            color: "#64dd17",
                            from: 85,
                            to: 105
                        }]

                        plotOptionsSeriesColor = '#FFFFFF';
                        options1.yAxis.plotBands = plotbands;
                        options1.yAxis.min = 0;
                        options1.yAxis.max = maxval;
                        options1.yAxis.title.text = '%';
                    } else {
                        options1.yAxis.max = maxval;
                    }
                } else {
                    options1.title = {
                        useHTML: true,
                        text: this.props.t("Health score data could not be fetched, please try again later"),
                        floating: true,
                        align: 'left',
                        x: 70,
                        y: 51,
                        style: {
                            fontSize: '9px',
                            backgroundColor: '#ffffff',
                            padding: '9px',
                            color: 'red'
                        }
                    }
                }
            } else if (this.state.showHealthScore && isHealthScoreSelected && isEmpty(diagnosticRecommendationData)) {
                options1.title = {
                    useHTML: true,
                    text: this.props.t("Health score data could not be fetched, please try again later"),
                    floating: true,
                    align: 'left',
                    x: 70,
                    y: 51,
                    style: {
                        fontSize: '9px',
                        backgroundColor: '#ffffff',
                        padding: '9px',
                        color: 'red'
                    }
                }
            } else {
                options1.title = {
                    text: ``,
                    style: {
                        backgroundColor: 'transparent',
                    }
                }
            }
            if ((isEmpty(data) && isEmpty(diagnosticRecommendationData))) {
                // TODO: Remove this after verification
                // options1.series.length = 0;
                // options1.yAxis.plotBands = [];
                // options1.yAxis.gridLineWidth = 0;
                options1.yAxis.title = {
                    text: ``,
                    style: {
                        backgroundColor: 'transparent',
                    }
                };
                // options1.yAxis.max = '';
                // options1.yAxis.min = 0;
            }
            if (!computedFlag && isEmpty(data) && (otherFlag) && !isHealthScoreSelected) {
                options1.title = {
                    useHTML: true,
                    text: this.props.t("No data to display"),
                    floating: true,
                    align: 'left',
                    x: 210,
                    y: 120,
                    style: {
                        fontSize: '12px',
                        fontWeight: 'bold',
                        color: '#60606a',
                        fill: '#60606a',
                    }
                }
            }
            if (computedFlag && isEmpty(computedData) && (!otherFlag)) {
                options1.title = {
                    useHTML: true,
                    text: this.props.t("No data to display"),
                    floating: true,
                    align: 'left',
                    x: 210,
                    y: 120,
                    style: {
                        fontSize: '12px',
                        fontWeight: 'bold',
                        color: '#60606a',
                        fill: '#60606a',
                    }
                }
            }
            if (computedFlag && isEmpty(computedData) && isEmpty(data) && (otherFlag) && (!isEmpty(diagnosticRecommendationData))) {
                options1.title = {
                    useHTML: true,
                    text: this.props.t("No data to display"),
                    floating: true,
                    align: 'left',
                    x: 210,
                    y: 120,
                    style: {
                        fontSize: '12px',
                        fontWeight: 'bold',
                        color: '#60606a',
                        fill: '#60606a',
                    }
                }
            }
            options1.plotOptions.series = {
                color: plotOptionsSeriesColor,
                shadow: false,
                borderWidth: 0,
                marker: {
                    enabled: false
                }
            }
            let total = [];
            if (computedFlag && !isEmpty(computedData)) {
                var computedSeriesData = [];
                var maxHealthScore = 0;
                arrayValue.map((item) => {
                    if ((item.value.includes("GRMS")) || (item.value.includes("PPAC")) || (item.value.includes("CRESTACC")) || (item.value.includes("KURTACC")) || (item.value.includes("PPSW")) || (item.value.includes("RPM")) || (item.value.includes("SWRMS"))) {
                        for (var index = 0; index < computedData.length; index++) {
                            let computedDataforSeries = computedData[index];
                            let val = item.value;
                            if (computedDataforSeries !== null) {
                                computedSeriesData.push([
                                    computedDataforSeries.timestamp,
                                    computedData[index][val] || 0
                                ]);
                                total.push(computedData[index][val] || 0);
                            }
                        }
                        var computedSeriesDataValue = {
                            // TODO@!: DO NOT REMOVE - Kept for future multilingual reference
                            name: this.props.t(item.viewValue),
                            // name: item.viewValue,
                            data: computedSeriesData
                        }
                        options1.series.push(computedSeriesDataValue);
                        computedSeriesData=[];
                    }
                })
                    
                    if (computedFlag && !otherFlag) {
                            let maxValue = Math.max(...total);
                            let minValue = Math.min(...total);
                            maxValue += 0.2;
                            options1.yAxis.max =  maxValue;
                            options1.yAxis.min =  minValue;
                    }
            }


            options1.plotOptions.series = {
              color:
                arrayValue.length == 1 &&
                options1 &&
                  options1.yAxis &&
                  options1.yAxis.plotBands &&
                  options1.yAxis.plotBands.length
                  ? plotOptionsSeriesColor
                  : undefined,
              shadow: false,
              borderWidth: 0,
              marker: {
                enabled: false,
              },
            };
            const obj = options1.series.filter((ele, ind, arr) => {
                return ind === arr.findIndex(elem => elem.name === ele.name) &&
                    arrayValue.some(featureItem => featureItem.viewValue === ele.name);
            });
            
            options1.series = obj;
            
            const externalFeaturesSeries = [];
            const selectedExternalFeatures = arrayValue.filter((feature) => get(feature, "type") === "EXTERNAL_FEATURE");
            
            selectedExternalFeatures.forEach((feature) => {
                const featureSpecificSeries = this.generateChartSeriesForExternalFeature(get(feature, "value"), get(feature, "viewValue")); 
                
                if(!lodashIsEmpty(featureSpecificSeries)) {
                    externalFeaturesSeries.push(...featureSpecificSeries);
                }
            });
            
            options1.series = [...options1.series, ...externalFeaturesSeries];

            if(options1.series && options1.series.length > 0) {
                options1.title.text = "";
                const areAllOptionsExternalFeatures = arrayValue.every((feature) => get(feature, "type") === "EXTERNAL_FEATURE");
                
                if(areAllOptionsExternalFeatures) {
                    set(options1, "yAxis.max", null);
                    set(options1, "yAxis.min", null);
                }
            }

            if(!lodashIsEmpty(arrayValue) && arrayValue.length === 1) {
                set(options1, "yAxis.title.text", get(arrayValue, "[0].unit", ""));
            }

            const _isDataPresentForAnySeries = get(options1, "series", []).some((series) => !lodashIsEmpty(get(series, "data", [])));
            
            if(!_isDataPresentForAnySeries) {
                options1 = cloneDeep(this.chartOptions);
            }

            this.setState({
                options: options1
            }, () => {
                let chartObj = this.chart.current.chart;
                chartObj.zoomOut();
                chartObj.hideNoData();
                if(options1.title.text !==this.props.t("No data to display")){
                    if (
                      (isEmpty(data) &&
                        isEmpty(diagnosticRecommendationData) &&
                        isEmpty(computedData)) ||
                      (options1.series && options1.series.length === 0)
                    ) {
                      chartObj.showNoData(this.props.t("No data to display"));
                    }

                    const isDataPresentForAnySeries = get(options1, "series", []).some((series) => !lodashIsEmpty(get(series, "data", [])));
                    
                    if (isDataPresentForAnySeries) {
                        chartObj.hideNoData();
                    }
                }
            });
        }

        setTimeout(() => {
            this.props.setTrendHistoryChartLoading(false);
            const chart = get(this.chart, "current.chart");
            if (chart) {
                chart.hideLoading();
            }
        }, 1000);
    }

    fetchData() {
        this.props.setTrendHistoryChartLoading(true);

        const { intervalUnit, intervalValue, deviceId, arrayValue } = this.state;
        const dateRange = this.props.range[0];
        const accessToken = getOidcAccessToken();
        let [_dateRange,from,to] = computeRange(dateRange);
        if (this.props.range.length === 4 && this.props.range[3] === "search") {
            from = moment(this.props.range[1]).utc().format("YYYY-MM-DDTHH:mm:00") + "Z";
            to = moment(this.props.range[2]).utc().format("YYYY-MM-DDTHH:mm:00") + "Z";
        }

        if (deviceId == null || deviceId == 'null') {
            this.setState({
                timeSeriesData: {
                    'basic_features': [],
                    'fingerprint_data': []
                },
                forecastData: {}
            });
        } else {
            this.props.setLoaderState({ "value": true });
            var that = this;
            fetch(`${config.url.mindapp_server_url}/iu/md/api/trend-history?from=${from}&to=${to}&intervalUnit=${intervalUnit}&intervalValue=${intervalValue}&monitorId=${deviceId}`,
                {
                    headers: {
                        "Authorization": `Bearer ${accessToken}`,
                        "Accept-Language": localStorage.getItem("lang") || "en"
                    }
                })
                .then(res => res.json())
                .then(
                    (result) => {

                        this.props.setFeatureRawData([result, intervalUnit, intervalValue]);
                        this.setState({
                            timeSeriesData: result
                        }, () => {
                            this.transformInputData(this.state.timeSeriesData);
                            this.props.setLoaderState({ "value": false });
                        });
                    },
                    // Note: it's important to handle errors here
                    // instead of a catch() block so that we don't swallow
                    // exceptions from actual bugs in components.
                    (error) => {
                        this.setState({
                            isLoaded: true,
                            error
                        });
                    }
                )
                .catch(function (error) {
                    that.props.setLoaderState({ "value": false });
                }
                );
            fetch(`${config.url.mindapp_server_url}/trend/computed-features?from=${this.props.range[1]}&to=${to}&monitorId=${deviceId}`,
                {
                    headers: {
                        "Authorization": `Bearer ${accessToken}`,
                        "Accept-Language": localStorage.getItem("lang") || "en"
                    }
                })
                .then(res => res.json())
                .then(
                    (result) => {
                        this.setState({
                            computedData: result.data

                        }, () => {
                            this.mapDatatoChart();
                            this.props.setLoaderState({ "value": false });
                        });
                    },
                    // Note: it's important to handle errors here
                    // instead of a catch() block so that we don't swallow
                    // exceptions from actual bugs in components.
                    (error) => {
                        this.setState({
                            computedData: [],
                            isLoaded: true,
                            error
                        });
                    }
                )
                .catch(function (error) {
                    that.props.setLoaderState({ "value": false });
                }
                );

            let trendSearchStartDate = moment(this.props.range[1]);
            let trendSearchEndDate = moment(this.props.range[2]);
            let trendSearchDifference = trendSearchEndDate.diff(trendSearchStartDate, 'days') + 1;
            let monthDays = trendSearchStartDate.format('M') == 2 ? 28 : 30;
            if (trendSearchDifference >= monthDays && arrayValue.length === 1) {
                this.props.setLoaderState({ "value": true });
                var startTime = trendSearchStartDate.format('YYYY-MM-DD HH:mm:ss');
                var endTime = trendSearchEndDate.format('YYYY-MM-DD HH:mm:ss');
                var features = arrayValue.map(function (e) { return e.value });
                // RUL getting issue at md backend - dirty commenting
                /* fetch(`${config.url.mindapp_server_url}/rul/forecast?startTime=${startTime}&endTime=${endTime}&summaryUnit=${intervalUnit}&summaryInterval=${intervalValue}&monitorId=${deviceId}&featureCodes=${features.join(',')}`,
                    {
                        headers: {
                            "Authorization": `Bearer ${accessToken}`
                        }
                    })
                    .then(res => res.json())
                    .then((result) => {
                        this.setState({
                            forecastData: result
                        }, () => {
                            this.props.setLoaderState({ "value": false });
                        });
                    },
                        (error) => {
                            this.setState({
                                forecastData: {}
                            }, () => {
                                this.props.setLoaderState({ "value": false });
                            });
                        })
                    .catch(function (error) {
                        this.setState({
                            forecastData: {}
                        }, () => {
                            this.props.setLoaderState({ "value": false });
                        });
                    }
                    );
                */ 
            } else {
                if (this.state.functionValue.value === 'forecast') {
                    this.setState({
                        functionValue: { "value": "average", "viewValue": this.props.t("Avg") }
                    });
                }
            }

            let trendSearchDifferenceHours = trendSearchEndDate.diff(trendSearchStartDate, 'hours');
            if (trendSearchDifferenceHours >= 24 && this.state.odrFlag) {
                this.props.setLoaderState({ "value": true });
                fetch(`${config.url.mindapp_server_url}/diagnostic-recommendation/bymonitor?from=${this.props.range[1]}&to=${to}&monitorId=${deviceId}`,
                    {
                        headers: {
                            "Authorization": `Bearer ${accessToken}`,
                            "Accept-Language": localStorage.getItem("lang") || "en"
                        }
                    })
                    .then(res => res.json())
                    .then((result) => {
                        this.setState({
                            diagnosticRecommendationData: result
                        }, () => {
                            this.props.setLoaderState({ "value": false });
                            this.mapDatatoChart();
                        });
                    },
                        (error) => {
                            this.setState({
                                diagnosticRecommendationData: []
                            }, () => {
                                this.props.setLoaderState({ "value": false });
                                this.mapDatatoChart();
                            });
                        })
                    .catch(function (error) {
                        this.setState({
                            diagnosticRecommendationData: []
                        }, () => {
                            this.props.setLoaderState({ "value": false });
                            this.mapDatatoChart();
                        });
                    }
                    );
            } else {
                this.mapDatatoChart();
            }
        }
    }

    intervalChange = (value) => {
        this.setState({
            intervalValue: value.intervalValue,
            intervalUnit: value.intervalUnit,
            value: value,
            intervalOpen: false
        }, () => {
            let chartObj = this.chart.current.chart;
            chartObj.hideNoData();
            this.fetchData();
            this.fetchExternalTrendHistoryData();
        });
    }

    functionChange = (value) => {
        this.props.setTrendHistoryChartLoading(true);
        this.setState({ functionValue: value, functionOpen: false }, () => {
            this.mapDatatoChart();
        });
    }

    featureChange = (value) => {
        this.props.setTrendHistoryChartLoading(true);
        this.props.setTrendHistorySelectedFeatures(value);
        if (value.length === 0) {
            this.setState({
                arrayValue: this.props.reportFeature.reportFeature.value
            }, () => {
                this.mapDatatoChart();
            })
        } else {
            this.setState({
                arrayValue: value
            }, () => {
                if (value.length === 1 && value[0].value !== '0001' && value[0].value !== '0005' && value[0].value !== '0006' && value[0].value != 'healthScore') {
                    this.fetchData();
                    this.fetchExternalTrendHistoryData();
                }
                let intervalset = localStorage.setItem("intervalSet", JSON.stringify(this.state.arrayValue));
                this.mapDatatoChart();
            })
        }
    }

    handleOpenModal = () => {
        this.setState({ showModal: true });
    }

    handleCloseModal = () => {
        this.setState({ showModal: false });
    }

    getFeatureListOptions = () => {
        const externalFeatureOptions = get(this.props, "externalFeatures", []).map((extFeature) => ({
            viewValue: `${this.props.t(get(extFeature, "displayName", ""))}`,
            value: get(extFeature, "featureCode", ""),
            unit: get(extFeature, "unitAbbreviation", ""),
            type: "EXTERNAL_FEATURE"
        })); 
        
        return [...get(this.props, "featureList", []), ...externalFeatureOptions];
    }

    render() {
        const { t } = this.props;
        const row = this.props.widgetsData.widgetData.trendHistory;
        const renderLink = () => {
            if (this.props.range[0] === 'today' || this.props.range[0] === 'thisMonth') {
                return <Link
                    component="button"
                    variant="body2"
                    title={this.props.t("Refresh Data")}
                    onClick={() => {
                        this.fetchData();
                        this.fetchExternalTrendHistoryData();
                    }}>
                    <RefreshIcon></RefreshIcon>
                </Link>
            }
        }


        return (
            <div className="trendHistory-panel widgets-info widgets-shadow">

                <div className="widgets-heading widgets-heading-info">
                    <h2 className="display-text">{row && this.props.t(row.header)}</h2>
                    <span className="float-right">{this.props.deviceName} &nbsp;
                        {renderLink()}
                    </span>
                </div>

                <div className="clearfix"></div>

                <div className="trend-history-filters">
                    <div className="selector-Panel feature-range-wrp">
                        <label>{row && this.props.t(row.feature)}</label>
                        <Picky
                            className="feature-range"
                            value={this.state.arrayValue}
                            options={this.getFeatureListOptions()}
                            onChange={this.featureChange}
                            open={false}
                            valueKey="value"
                            labelKey="viewValue"
                            multiple={true}
                            includeSelectAll={true}
                            includeFilter={false}
                            dropdownHeight={600}
                        />
                    </div>
                    <div className="selector-Panel function-range-wrp" style={{fontSize:this.props.i18n.language==="ja"&& '13px'}}>
                        <label>{row && this.props.t(row.function)}</label>
                        <Picky
                            className="function-range"
                            value={this.state.functionValue}
                            options={this.functions}
                            onChange={this.functionChange}
                            open={false}
                            keepOpen={this.state.functionOpen}
                            valueKey="value"
                            labelKey="viewValue"
                            multiple={false}
                            includeSelectAll={false}
                            includeFilter={false}
                            dropdownHeight={300}
                            disabled={this.state.enableFuncAndInterval}
                        />
                    </div>
                    <div className="selector-Panel function-range-wrp">
                        <label>{row && this.props.t(row.interval)}</label>
                        <Picky
                            className="function-range"
                            value={this.state.value}
                            options={this.state.interval}
                            onChange={this.intervalChange}
                            open={false}
                            keepOpen={this.state.intervalOpen}
                            valueKey="intervalValue"
                            labelKey="intervalName"
                            multiple={false}
                            includeSelectAll={false}
                            includeFilter={false}
                            dropdownHeight={300}
                            disabled={this.state.enableFuncAndInterval}
                        />
                    </div>
                </div>
                <div className="highChart-wrp">
                    <HighchartsReact
                        ref={this.chart}
                        highcharts={Highcharts}
                        constructorType="chart"
                        options={this.state.options}
                    />
                </div>
                {
                    this.state.showModal ?
                        <Dialog
                            open={this.handleOpenModal}
                            onClose={this.handleCloseModal}
                        >
                            <DialogContent>
                                <DialogContentText>
                                    {t("Due to non-incremental trend data, the forecast cannot be determined.")}
                                </DialogContentText>
                            </DialogContent>
                            <DialogActions>
                                <Button onClick={this.handleCloseModal} color="primary" autoFocus>
                                    {t("Close")}
                                </Button>
                            </DialogActions>
                        </Dialog>
                        : null
                }
            </div>
        )
    }
}

// export default TrendHistory
const mapSatateToProps = state => {
    return {
        // deviceData: state.dashboardDeviceReducer,
        deviceData: state.dashboardDeviceReducer,
        reportFeature: state.reportFeatureReducer,
        externalFeaturesGroupIds: state.externalFeaturesReducer.externalFeaturesGroupIds,
        externalFeatures: state.externalFeaturesReducer.externalFeatures,
        externalFeaturesTrendHistoryData: state.externalFeaturesReducer.externalFeaturesTrendHistoryData,
        trendHistorySelectedFeatures: state.trendHistoryReducer.trendHistorySelectedFeatures,
        trendHistoryChartLoading: state.trendHistoryReducer.trendHistoryChartLoading,
    }
}

const mapDispatchToProps = dispatch => {
    return {
      setFeatureData: (data) => dispatch(actionCreators.setFeatureData(data)),
      setTrendHistoryChartLoading: (isLoading) => dispatch(actionCreators.setTrendHistoryChartLoading(isLoading)),
      setTrendHistorySelectedFeatures: (list) => dispatch(actionCreators.setTrendHistorySelectedFeatures(list)),
      setFeatureRawData: (data) =>
        dispatch(actionCreators.setFeatureRawData(data)),
      setChartImage: (image) => dispatch(actionCreators.setChartImage(image)),
      setReportFeature: (value) =>
        dispatch(actionCreators.setReportFeature(value)),
      setLoaderState: (value) => dispatch(actionCreators.setLoaderState(value)),
      getExternalFeaturesTrendHistory: (
        startDateTime,
        endDateTime,
        intervalValue,
        intervalUnit,
        externalDeviceParameterGroupIds
      ) =>
        dispatch(
          actionCreators.getExternalFeaturesTrendHistory(
            startDateTime,
            endDateTime,
            intervalValue,
            intervalUnit,
            externalDeviceParameterGroupIds
          )
        ),
    };
}

// export default (withStyles(styles)(Navigation));
export default withTranslation()(connect(mapSatateToProps, mapDispatchToProps)(TrendHistory));

function isEmpty(obj) {
    for (var key in obj) {
        if (obj.hasOwnProperty(key))
            return false;
    }
    return true;
}
