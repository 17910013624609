export const SAVE_APP_DATA = 'SAVE_APP_DATA';
export const SAVE_MACHINEGROUP_COUNT = 'SAVE_MACHINEGROUP_COUNT';
export const SAVE_MONITOR_COUNT = 'SAVE_MONITOR_COUNT';
export const SAVE_MACHINE_COUNT = 'SAVE_MACHINE_COUNT';
export const SAVE_USER_AUTHENTICATION = 'SAVE_USER_AUTHENTICATION';
export const UNAUTHORIZED_USER = 'UNAUTHORIZED_USER';
export const GET_USER_DATA = 'GET_USER_DATA';
export const SAVE_USER_DATA = 'SAVE_USER_DATA';
export const SAVE_DIAGNOSTICS_ALARMS_DATA = 'SAVE_DIAGNOSTICS_ALARMS_DATA';
export const USER_LOGOUT = 'USER_LOGOUT';
export const SAVE_LOGO = 'SAVE_LOGO';
export const SAVE_MACHINE_IMAGE = "SAVE_MACHINE_IMAGE";
export const UPDATE_SELECTED_TAB = 'UPDATE_SELECTED_TAB';
export const UPDATE_USER = 'UPDATE_USER';
export const UPDATE_SCOPE_SELECTOR = 'UPDATE_SCOPE_SELECTOR';
export const SAVE_PARENT_ORGANIZATION = 'SAVE_PARENT_ORGANIZATION';
export const SAVE_CURRENT_DELETED_ORGANIZATION = 'SAVE_CURRENT_DELETED_ORGANIZATION';
export const REMOVE_CURRENT_DELETED_ORGANIZATION = 'REMOVE_CURRENT_DELETED_ORGANIZATION';
export const SAVE_SUMMARY_DATA = 'SAVE_SUMMARY_DATA';
export const SAVE_SUMMARY_HEALTH_SCORE = 'SAVE_SUMMARY_HEALTH_SCORE';
export const SAVE_DEVICE_DATA = 'SAVE_DEVICE_DATA';
export const SAVE_TABLE_DATA = 'SAVE_TABLE_DATA';
export const SCHEDULE_LIST_DATA = 'SCHEDULE_LIST_DATA';
export const JOB_LIST_DATA = 'JOB_LIST_DATA';
export const LOSS_TABLE_DATA = 'LOSS_TABLE_DATA';
export const JOB_TABLE_DATA = 'JOB_TABLE_DATA';
export const LOSS_LIST_DATA = 'LOSS_LIST_DATA';
export const GET_STATIC_DATA = 'GET_STATIC_DATA';
export const GET_METADATA = 'GET_METADATA';
export const MACHINE_GROUP_DATA = 'MACHINE_GROUP_DATA';
export const DEVICE_DATA = "DEVICE_DATA";
export const MACHINES_DATA = "MACHINES_DATA";
export const USER_TABLE_DATA = "USER_TABLE_DATA";
export const MONITOR_DATA = 'MONITOR_DATA';
export const SAVE_THRESHOLDS = 'SAVE_THRESHOLDS';
export const LOADER_STATE = "LOADER_STATE";

export const SAVE_NEW_USER = 'SAVE_NEW_USER';
export const UPDATE_EXISTING_USER = 'UPDATE_EXISTING_USER';

export const GET_ALERT_MESSAGE = 'GET_ALERT_MESSAGE';

export const SAVE_USER_PLANTS = 'SAVE_USER_PLANTS';

export const SAVE_VIEW_DEFINITION = 'SAVE_VIEW_DEFINITION';

export const SAVE_TIMEZONES = 'SAVE_TIMEZONES';

export const SAVE_ALERT_MEDIA = 'SAVE_ALERT_MEDIA';

export const SAVE_JSON = 'SAVE_JSON';

export const SAVE_CURRENT_SCHEDULE = 'SAVE_CURRENT_SCHEDULE';

export const SET_DEVICE_DATA = 'SET_DEVICE_DATA';

export const UPDATE_DASHBOARD_DATA = 'UPDATE_DASHBOARD_DATA';

export const SET_FEATURE_DATA = 'SET_FEATURE_DATA';

export const GET_DEVICE_DATA = 'GET_DEVICE_DATA';

export const SET_CHART_IMAGE = 'SET_CHART_IMAGE';

export const SET_FEATURE_RAW_DATA = 'SET_FEATURE_RAW_DATA';

export const SET_REPORT_FEATURE_DATA = 'SET_REPORT_FEATURE_DATA';

export const SET_WIDGET_LANGUAGE = 'SET_WIDGET_LANGUAGE';

export const SAVE_ALARMS_DATA = 'SAVE_ALARMS_DATA';

export const SAVE_FFT_DATA = 'SAVE_FFT_DATA';

export const SAVE_SHOCKWAVE_DATA = 'SAVE_SHOCKWAVE_DATA';

export const SAVE_EQUIPMENT_DATA = 'SAVE_EQUIPMENT_DATA';

export const UPDATE_ALARM_STATUS = 'UPDATE_ALARM_STATUS';

export const SAVE_MAINTENANCE_DATA = 'SAVE_MAINTENANCE_DATA';

export const SAVE_FEATURE_DATA = 'SAVE_FEATURE_DATA';

export const SAVE_FAULT_LIST = 'SAVE_FAULT_LIST';

export const SAVE_BEARING_DATA = 'SAVE_BEARING_DATA';

export const SET_SELECTED_FFT_DATA = 'SET_SELECTED_FFT_DATA';

export const SAVE_ALARM_DATA = 'SAVE_ALARM_DATA';

export const SAVE_DOCUMENTATION_DATA = 'SAVE_DOCUMENTATION_DATA';

export const GET_MONITOR = 'GET_MONITOR';

export const SAVE_MACHINE_DATA = 'SAVE_MACHINE_DATA';

export const SAVE_PRODUCTIVITY_INDEX_DATA = 'SAVE_PRODUCTIVITY_INDEX_DATA';

export const SAVE_PLANT_PART_COUNT = 'SAVE_PLANT_PART_COUNT';

export const SAVE_TIME_SERIES_DATA = 'SAVE_TIME_SERIES_DATA';

export const SAVE_PLANT_SHIFT = 'SAVE_PLANT_SHIFT';

export const SAVE_OEE_BY_PART_NUMBER = 'SAVE_OEE_BY_PART_NUMBER';

export const SAVE_PRODUCTIVITY_SELECTION = 'SAVE_PRODUCTIVITY_SELECTION';

export const SAVE_PART_NUMBER_LIST = 'SAVE_PART_NUMBER_LIST';

export const SAVE_MACHINE_PRODUCTION_HISTORY = 'SAVE_MACHINE_PRODUCTION_HISTORY';

export const SAVE_OEE_SELECTION = 'SAVE_OEE_SELECTION';

export const SAVE_INPUT_DIALOG = 'SAVE_INPUT_DIALOG';

export const SAVE_DOWNTIME_ANALYSIS = 'SAVE_DOWNTIME_ANALYSIS';

export const SAVE_MAINTENANCE_METRICS_DATA = 'SAVE_MAINTENANCE_METRICS_DATA';

export const SAVE_LOSS_DROPDOWN_LIST = 'SAVE_LOSS_DROPDOWN_LIST';

export const SAVE_LOSS_REPORT_DATA = 'SAVE_LOSS_REPORT_DATA';

export const SAVE_LOSS_DATA = "SAVE_LOSS_DATA";

export const SAVE_TOP_PLANT_LOSS = 'SAVE_TOP_PLANT_LOSS';

export const SAVE_TOP_MACHINE_LOSS = 'SAVE_TOP_MACHINE_LOSS';

export const SAVE_ALARM_ANALYSIS_DATA = 'SAVE_ALARM_ANALYSIS_DATA';

export const SAVE_ALARM_REPORT_DATA = 'SAVE_ALARM_REPORT_DATA';

export const SAVE_QUALITY_DATA = 'SAVE_QUALITY_DATA';

export const SAVE_CYCLE_TIME_REPORT_DATA = 'SAVE_CYCLE_TIME_REPORT_DATA';

export const SAVE_CYCLE_TIME_COMPARISON = 'SAVE_CYCLE_TIME_COMPARISON';

export const SAVE_QUALITY_DATA1 = 'SAVE_QUALITY_DATA1';

export const SAVE_QUALITY_REPORT = 'SAVE_QUALITY_REPORT';

export const UPDATE_DRAWER = 'UPDATE_DRAWER';

export const UPDATE_FFT_DATA = 'UPDATE_FFT_DATA';

export const REPORT_SAVE_ALARMS_DATA = 'REPORT_SAVE_ALARMS_DATA';

export const REPORT_UPDATE_ALARM_STATUS = 'REPORT_UPDATE_ALARM_STATUS';

export const REPORT_SAVE_EQUIPMENT_DATA = 'REPORT_SAVE_EQUIPMENT_DATA';

export const SPECTRAL_FEATURE_TABLE_DATA = 'SPECTRAL_FEATURE_TABLE_DATA';

export const ALARM_WATCHLIST_DATA = 'ALARM_WATCHLIST_DATA';

export const SHOW_APP_LIST = 'SHOW_APP_LIST';

export const UPDATE_PASSWORD = 'UPDATE_PASSWORD';
 
export const SHOW_PLATFORM_UPTIME_30_DAYS_DATA='SHOW_PLATFORM_UPTIME_30_DAYS_DATA'

export const SAVE_LANGUAGES_LIST = 'SAVE_LANGUAGES_LIST';

export const SET_EXTERNAL_FEATURES = 'SET_EXTERNAL_FEATURES';
export const SET_APPLICATION_SELECTED_PLANT_ID = 'SET_APPLICATION_SELECTED_PLANT_ID';
export const SET_EXTERNAL_FEATURES_GROUP_IDS = 'SET_EXTERNAL_FEATURES_GROUP_IDS';
export const SET_EXTERNAL_FEATURES_TREND_HISTORY_DATA = 'SET_EXTERNAL_FEATURES_TREND_HISTORY_DATA';
export const SET_IS_LOADING_EXTERNAL_FEATURES_TREND_HISTORY_DATA = 'SET_IS_LOADING_EXTERNAL_FEATURES_TREND_HISTORY_DATA';
export const SET_TREND_HISTORY_SELECTED_FEATURES = 'SET_TREND_HISTORY_SELECTED_FEATURES';
export const SET_TREND_HISTORY_CHART_LOADING = 'SET_TREND_HISTORY_CHART_LOADING';