import React, { Component } from 'react';
import * as actionCreators from '../../actions/actionCreators';
import { connect } from 'react-redux';
import { KeyboardBackspace } from '@material-ui/icons';
import { withTranslation } from "react-i18next";

import { Scrollbars } from 'react-custom-scrollbars';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Radio from '@material-ui/core/Radio';
import {
    Card, CardContent, InputLabel, TextField, Select, withStyles, OutlinedInput, Button, Typography, Grid
} from '@material-ui/core';

const styles = theme => ({
    cardContent: {
        height: '91%',
        "overflow-y": "auto"
    },
    cardContentRoot: {
        '&:last-child': {
            paddingBottom: 16
        }
    },
    card: {
        width: '100%',
        borderRadius: 0,
        height: '80vh'
    },
    textField: {
        width: '60%',
        marginRight: 20
    },
    textArea: {
        width: '80%',
        marginRight: 20
    },    
    outlinedInput: {
        padding: 14
    },
    saveButton: {
        fontSize: "14px",
        fontWeight: "500",
        textAlign: "center",
        color: "#ffffff",
        backgroundColor: "#42D784",
        '&:hover': {
            backgroundColor: '#42d784'
        },
        '&:disabled': {
            backgroundColor: '#f6f6f6'
        },
        borderRadius: "3px",
        boxShadow: "0 0 1px 0 rgba(0, 0, 0, 0.05)",
        width: "110px",
        height: "36px",
        marginRight: "24px"
    },
    inputFieldDiv: {
        display: "flex",
        justifyContent: "space-between",
        width: '50%',
        float: 'left',
        paddingTop: '1%',
        height: 80,
        'align-items': 'baseline'
    },
    textareaInputFieldDiv: {
        display: "flex",
        justifyContent: "space-between",
        width: '100%',
        float: 'left',
        paddingTop: '1%',
        height: 180,
        'align-items': 'baseline'
    },
    textFieldLabel: {
        color: 'rgba(0, 0, 0, 0.4000000059604645)',
        'letter-spacing': 0.5,
        'font-weight': 500,
        fontSize: "14px",
        lineHeight: 2.5,
        width: '30%',
        marginLeft: 20,
        //text-align': 'right'
    },
    textareaFieldLabel: {
        color: 'rgba(0, 0, 0, 0.4000000059604645)',
        'letter-spacing': 0.5,
        'font-weight': 500,
        fontSize: "14px",
        lineHeight: 2.5,
        width: '15%',
        marginLeft: 20,
        //'text-align': 'right'
    },
    requiredClass: {
        color: 'red'
    },
    actionButtonContainer: {
        display: 'flex',
        'justify-content': 'space-between',
        height: '9%',
        'border-bottom': '1px solid rgba(207, 216, 220, 0.5)'
    },
    backButtonContainer: {
        display: 'flex',
        'align-items': 'center',
        paddingLeft: 21
    },
    arrowBack: {
        cursor: 'pointer'
    },
    entityTitle: {
        color: 'rgba(0, 0, 0, 0.8)',
        'font-family': 'Roboto',
        'font-size': 21,
        'font-weight': '400',
        'letter-spacing': 0.5,
        marginLeft: 10
    }
})

class PlantEntity extends Component {

    state = {
        id: "",
        disableSaveButton: true,
        organizationName: "",
        language: "",
        name: "",
        notes: "",
        timezone: "(GMT+5:30) IST",
        specs: "",
        orgId: "",
        schedule: "",
        lossList: "",
        jobList: "",
        labelWidth: 0,
        nameError: false,
        specsError: false,
        notesError: false,
        languageError: false,
        timeZoneError: false,
        scheduleError: false,
        lossListError: false,
        jobListError: false,
        fftOnAlarmError: false,
        fftOnAlarm: 'No',
		industryType: '',
        industryTypeError: false
    }

    // Handle changes to any user input field
    inputChangeHandler = (event, field) => {

        switch (field) {
            case "fftOnAlarm":
                event.target.value === "Yes" ?
                    this.setState({
                        fftOnAlarm: "Yes",
                        disableSaveButton: false 
                    })
                    :
                    this.setState({
                        fftOnAlarm: 'No',
                        disableSaveButton: false 
                    })
                break;
            case "plantName": this.setState({ name: event.target.value, disableSaveButton: false });
                this.checkValidation("plantName", event.target.value);
                break;
            case "language": this.setState({ language: event.target.value, disableSaveButton: false });
                this.checkValidation("language", event.target.value);
                break;
            case "timezone": this.setState({ timezone: event.target.value, disableSaveButton: false });
                this.checkValidation("timezone", event.target.value);
                break;
            case "industryType": this.setState({ industryType: event.target.value, disableSaveButton: false });
                this.checkValidation("industryType", event.target.value);
                break;
				
				
            case "specs": this.setState({ specs: event.target.value, disableSaveButton: false });
                this.checkValidation("specs", event.target.value);
                break;
            case "notes": this.setState({ notes: event.target.value, disableSaveButton: false });
                this.checkValidation("notes", event.target.value);
                break;
            case "schedule": this.setState({ schedule: event.target.value, disableSaveButton: false });
                this.checkValidation("schedule", event.target.value);
                break;
            case "lossList": this.setState({ lossList: event.target.value, disableSaveButton: false });
                this.checkValidation("lossList", event.target.value);
                break;
            case "jobList": this.setState({ jobList: event.target.value, disableSaveButton: false });
                this.checkValidation("jobList", event.target.value);
                break;
            // case "organizationName": this.setState({ organizationName: event.target.value, disableSaveButton: false }); break;
            default: console.log("default case");
        }
    }

    // Handle routing
    goToBackPage = () => {
        this.props.history.goBack();
    }

    getLossListName = () => {
        let lossList = [];
        lossList.push(<option key={""} disabled={true} value={""}>{this.props.t("Select")}</option>);
        if (this.props.lossCodes && this.props.lossCodes.tableData && this.props.lossCodes.tableData.length > 0) {
            this.props.lossCodes.tableData.map((data) => {
                lossList.push(
                    <option key={data.id} value={data.id}>{data.name}</option>
                );
            })
        }
        return lossList;
    }

    getJobListName = () => {
        let jobList = [];
        jobList.push(<option key={""} disabled={true} value={""}>{this.props.t("Select")}</option>);
        if (this.props.jobsData && this.props.jobsData.tableData && this.props.jobsData.tableData.length > 0) {
            this.props.jobsData.tableData.map((data) => {
                jobList.push(
                    <option key={data.id} value={data.id}>{data.name}</option>
                );
            })
        }
        return jobList;
    }

    getScheduleName = () => {
        let scheduleList = [];
        scheduleList.push(<option key={""} disabled={true} value={""}>{this.props.t("Select")}</option>);
        if (this.props.schedulesData && this.props.schedulesData.tableData && this.props.schedulesData.tableData.length > 0) {
            this.props.schedulesData.tableData.map((data) => {
                scheduleList.push(
                    <option key={data.id} value={data.id}>{data.name}</option>
                );
            })
        }
        return scheduleList;
    }

    // update plants entity
    updatePlantData = () => {
        const { organizationName, timezone, language, id, name, notes, specs, schedule, lossList, jobList,industryType } = this.state
        let fftOnAlarm = (this.state.fftOnAlarm === 'Yes') ? true : false;
        if (id && id != null && id !== "") {
            this.props.updatePlantEntity({ organizationName, timezone, language, id, name, notes, specs, schedule, lossList, jobList,fftOnAlarm ,industryType}, id);
        }
        else {
            this.props.updatePlantEntity({ organizationName, timezone, language, name, notes, specs, schedule, lossList, jobList, fftOnAlarm,industryType}, id);
        }
    }

    // set selected organization's id in the state
    getOrganizationName = () => {
        if (this.props.selectedScopeData && this.props.selectedScopeData !== "") {
            let tempdata = "";
            let found = false;
            this.props.scopeSelector.subOrganizations.map((organization) => {
                if (organization.id === this.props.selectedScopeData && !found) {
                    tempdata = organization.name;
                    found = true;
                }
            })
            this.setState({ organizationName: tempdata })
        }
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevProps.selectedTab !== this.props.selectedTab) {
            this.props.history.replace("/dashboard");
        }
        if (this.state.orgId && this.state.orgId != "" && prevState.orgId != this.state.orgId) {
            this.props.getTableData("OrgSchedulesTable", this.state.orgId, true);
            this.props.getTableData("OrgJobsTable", this.state.orgId, true);
            this.props.getTableData("OrgLossCodesTable", this.state.orgId, true);
        }
    }

    componentDidMount() {
        if (this.props.data) {
            let entityNames = Object.keys(this.props.data)
            entityNames.map((data) => {
                if (this.props.data[data] == null) {
                    this.props.data[data] = ""
                }
            })
            this.setState({
                id: this.props.data.id,
                language: this.props.data.language,
                name: this.props.data.name,
                timezone: this.props.data.timezone,
                fftOnAlarm: this.props.data.fftOnAlarm,
				industryType: this.props.data.industryType,
            })
            if (this.props.data.organizationName) {
                // if (this.props.data.organizationName.props) {
                //     this.setState({ organizationName: this.props.data.organizationName.props.children })
                // }
                if (this.props.data.organizationName) {
                    this.setState({ organizationName: this.props.data.organizationName })
                }
                else {
                    this.getOrganizationName();
                }
            }
            if (this.props.data.specs && this.props.data.specs != "") {
                if (this.props.data.specs.props) {
                    this.setState({ specs: this.props.data.specs.props.children })
                }
                else {
                    this.setState({ specs: this.props.data.specs })
                }
            }
            if (this.props.data.schedules && this.props.data.schedules.id) {
                this.setState({ schedule: this.props.data.schedules.id })
            }
            if (this.props.data.jobLists && this.props.data.jobLists.id) {
                this.setState({ jobList: this.props.data.jobLists.id })
            }
            if (this.props.data.lossLists && this.props.data.lossLists.id) {
                this.setState({ lossList: this.props.data.lossLists.id })
            }
            if (this.props.data.notes && this.props.data.notes != "") {
                if (this.props.data.notes.props) {
                    this.setState({ notes: this.props.data.notes.props.children })
                }
                else {
                    this.setState({ notes: this.props.data.notes })
                }
            }
        }
        else {
            this.getOrganizationName();
        }

        if (this.props.validationData && this.props.validationData.config && this.props.validationData.config.Plants) {
            this.setState({ validationData: this.props.validationData.config.Plants });
        }

        if (this.props.selectedScopeData && this.props.selectedScopeData !== "") {
            this.setState({ orgId: this.props.selectedScopeData });
        }
    }

    // disable save button based on validations
    disableSaveButton = () => {
        if (this.state.name === "" && (this.state.validationData.name.validations && this.state.validationData.name.validations.required)) {
            return true;
        }
        else if (this.state.language === "" && (this.state.validationData.language.validations && this.state.validationData.language.validations.required)) {
            return true;
        }
        else if (this.state.notes === "" && (this.state.validationData.notes.validations && this.state.validationData.notes.validations.required)) {
            return true;
        }
        else if (this.state.specs === "" && (this.state.validationData.specs.validations && this.state.validationData.specs.validations.required)) {
            return true;
        }
        else if (this.state.organizationName === "" && (this.state.validationData.organizationName.validations && this.state.validationData.organizationName.validations.required)) {
            return true;
        }
        else if (this.state.timezone === "" && (this.state.validationData.timezone.validations && this.state.validationData.timezone.validations.required)) {
            return true;
        }
        else if (this.state.industryType === "" && (this.props.userData.parentOrganizationType ==='publisher' && this.props.userData.admin)) {
            return true;
        }

        else if (this.state.jobList === "" && (this.state.validationData.jobListsName.validations && this.state.validationData.jobListsName.validations.required)) {
            return true;
        }
        else if (this.state.lossList === "" && (this.state.validationData.lossListsName.validations && this.state.validationData.lossListsName.validations.required)) {
            return true;
        }
        else if (this.state.schedule === "" && (this.state.validationData.schedulesName.validations && this.state.validationData.schedulesName.validations.required)) {
            return true;
        }
        else if (this.state.nameError || this.state.specsError || this.state.notesError || this.state.languageError|| this.state.industryTypeError|| this.state.timeZoneError || this.state.scheduleError || this.state.lossListError || this.state.jobListError) {
            return true;
        }
        else {
            return false;
        }
    }

    // set card header
    getCardHeader = () => {
        if (this.props.data && this.props.data.name && this.props.data.name != "") {
            return this.props.data.name;
        }
        else {
            return this.props.t("Create New")
        }
    }
	
	    // create indu-type dropdown list
	// create indu-type dropdown list
	getIndustryTypeDropDownList = () => {
        let arr = [
			{ "key": "Automotive", "value": this.props.t("Automotive") },
            { "key": "Cement", "value": this.props.t("Cement") },
            { "key": "Chemical", "value": this.props.t("Chemical") },
            { "key": "Ferrous", "value": this.props.t("Ferrous") },
            { "key": "Fertilizers", "value": this.props.t("Fertilizers") },
            { "key": "FMCG", "value": this.props.t("FMCG") },
            { "key": "F&B", "value": this.props.t("F&B") },
            { "key": "Glass", "value": this.props.t("Glass") },
            { "key": "Manufacturing", "value": this.props.t("Manufacturing") },
            { "key": "Non Ferrous", "value": this.props.t("Non Ferrous") },	
			{ "key": "Oil and Gas", "value": this.props.t("Oil and Gas") },			
			{ "key": "Paper", "value": this.props.t("Paper") },
			{ "key": "Pharma", "value": this.props.t("Pharma") },			
			{ "key": "Plastic", "value": this.props.t("Plastic") },
			{ "key": "Port", "value": this.props.t("Port") },
			{ "key": "Power", "value": this.props.t("Power") },		
			{ "key": "Textile", "value": this.props.t("Textile") },
			{ "key": "Tyre", "value": this.props.t("Tyre") },
		]
        let displayIndustryType = [];
        displayIndustryType.push(<option key={""} disabled={true} value={""}>{this.props.t("Select")}</option>);
        arr.map(fbb =>
            displayIndustryType.push(<option key={fbb.key} value={fbb.key}>{fbb.value}</option>)
        );
        return displayIndustryType;
    }

    // check validations of the user input fields
    checkValidation = (field, value) => {
        switch (field) {
            case "language":
                if (!this.state.languageError && value === "" && (this.state.validationData.language.validations && this.state.validationData.language.validations.required)) {
                    this.setState({ languageError: true });
                } else if (this.state.languageError) {
                    if ((value === "" && (this.state.validationData.language.validations && this.state.validationData.language.validations.required))) {
                        //skip
                    }
                    else {
                        this.setState({ languageError: false });
                    }
                };
                break;
            case "plantName":
                if (!this.state.nameError && value === "" && (this.state.validationData.name.validations && this.state.validationData.name.validations.required)) {
                    this.setState({ nameError: true });
                } else if (!this.state.nameError && (this.state.validationData.name.validations) && (value.length > this.state.validationData.name.validations.maxLength)) {
                    this.setState({ nameError: true });
                } else if (this.state.nameError) {
                    if ((value === "" && (this.state.validationData.name.validations && this.state.validationData.name.validations.required) || ((this.state.validationData.name.validations) && (value.length > this.state.validationData.name.validations.maxLength)))) {
                        //skip
                    }
                    else {
                        this.setState({ nameError: false });
                    }
                };
                break;
            case "timezone":
                if (!this.state.timeZoneError && value === "" && (this.state.validationData.timezone.validations && this.state.validationData.timezone.validations.required)) {
                    this.setState({ timeZoneError: true });
                } else if (!this.state.timeZoneError && (this.state.validationData.timezone.validations) && (value.length > this.state.validationData.timezone.validations.maxLength)) {
                    this.setState({ timeZoneError: true });
                } else if (this.state.timeZoneError) {
                    if ((value === "" && (this.state.validationData.timezone.validations && this.state.validationData.timezone.validations.required) || ((this.state.validationData.timezone.validations) && (value.length > this.state.validationData.timezone.validations.maxLength)))) {
                        //skip
                    }
                    else {
                        this.setState({ timeZoneError: false });
                    }
                };
                break;
				
            case "industryType":
                if (!this.state.industryTypeError && value === "" && (this.state.validationData.industryType.validations && this.state.validationData.industryType.validations.required)) {
                    this.setState({ industryTypeError: true });
                } else if (!this.state.industryTypeError && (this.state.validationData.industryType.validations) && (value.length > this.state.validationData.industryType.validations.maxLength)) {
                    this.setState({ industryTypeError: true });
                } else if (this.state.industryTypeError) {
                    if ((value === "" && (this.state.validationData.industryType.validations && this.state.validationData.industryType.validations.required) || ((this.state.validationData.industryType.validations) && (value.length > this.state.validationData.industryType.validations.maxLength)))) {
                        //skip
                    }
                    else {
                        this.setState({ industryTypeError: false });
                    }
                };
                break;				
				
            case "jobList":
                if (!this.state.jobListError && value === "" && (this.state.validationData.jobListsName.validations && this.state.validationData.jobListsName.validations.required)) {
                    this.setState({ jobListError: true });
                } else if (this.state.jobListError) {
                    if ((value === "" && (this.state.validationData.jobListsName.validations && this.state.validationData.jobListsName.validations.required))) {
                        //skip
                    }
                    else {
                        this.setState({ jobListError: false });
                    }
                };
                break;
            case "lossList":
                if (!this.state.lossListError && value === "" && (this.state.validationData.lossListsName.validations && this.state.validationData.lossListsName.validations.required)) {
                    this.setState({ lossListError: true });
                } else if (this.state.lossListError) {
                    if ((value === "" && (this.state.validationData.lossListsName.validations && this.state.validationData.lossListsName.validations.required))) {
                        //skip
                    }
                    else {
                        this.setState({ lossListError: false });
                    }
                };
                break;
            case "schedule":
                if (!this.state.scheduleError && value === "" && (this.state.validationData.schedulesName.validations && this.state.validationData.schedulesName.validations.required)) {
                    this.setState({ scheduleError: true });
                } else if (this.state.scheduleError) {
                    if ((value === "" && (this.state.validationData.schedulesName.validations && this.state.validationData.schedulesName.validations.required))) {
                        //skip
                    }
                    else {
                        this.setState({ scheduleError: false });
                    }
                };
                break;
            case "specs":
                if (!this.state.specsError && value === "" && (this.state.validationData.specs.validations && this.state.validationData.specs.validations.required)) {
                    this.setState({ specsError: true });
                } else if (!this.state.specsError && (this.state.validationData.specs.validations) && (value.length > this.state.validationData.specs.validations.maxLength)) {
                    this.setState({ specsError: true });
                } else if (this.state.specsError) {
                    if ((value === "" && (this.state.validationData.specs.validations && this.state.validationData.specs.validations.required) || ((this.state.validationData.specs.validations) && (value.length > this.state.validationData.specs.validations.maxLength)))) {
                        //skip
                    }
                    else {
                        this.setState({ specsError: false });
                    }
                };
                break;
            case "notes":
                if (!this.state.notesError && value === "" && (this.state.validationData.notes.validations && this.state.validationData.notes.validations.required)) {
                    this.setState({ notesError: true });
                } else if (!this.state.notesError && (this.state.validationData.notes.validations) && (value.length > this.state.validationData.notes.validations.maxLength)) {
                    this.setState({ notesError: true });
                } else if (this.state.notesError) {
                    if ((value === "" && (this.state.validationData.notes.validations && this.state.validationData.notes.validations.required) || ((this.state.validationData.notes.validations) && (value.length > this.state.validationData.notes.validations.maxLength)))) {
                        //skip
                    }
                    else {
                        this.setState({ notesError: false });
                    }
                };
                break;
        }
    }

    render() {
        return (
            <Card className={this.props.classes.card}>
                <div className={this.props.classes.actionButtonContainer}>
                    <div className={this.props.classes.backButtonContainer}>
                        <KeyboardBackspace onClick={this.goToBackPage} classes={{ root: this.props.classes.arrowBack }} />
                        <div className={this.props.classes.entityTitle}>{this.getCardHeader()}</div>
                    </div>
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                        <Button variant="contained"
                            className={this.props.classes.saveButton}
                            disabled={this.state.disableSaveButton || this.disableSaveButton()}
                            onClick={this.updatePlantData}>{this.props.t("Save")}</Button>
                    </div>
                </div>
                <CardContent className={this.props.classes.cardContent} classes={{ root: this.props.classes.cardContentRoot }}>
                    {this.state.validationData && this.state.validationData.name && this.state.validationData.name.page_visible ? (
                        <div className={this.props.classes.inputFieldDiv}>
                            <InputLabel className={this.props.classes.textFieldLabel}>
                                {this.state.validationData.name.display_name}
                                {(this.state.validationData.name.validations && this.state.validationData.name.validations.required) ? (<span className={this.props.classes.requiredClass}>*</span>) : null}
                            </InputLabel>
                            <TextField
                                id="plantName"
                                name="plantName"
                                variant="outlined"
                                helperText={(this.state.validationData.name.validations && this.state.validationData.name.validations.helpText) ? (this.state.validationData.name.validations.helpText) : null}
                                className={this.props.classes.textField}
                                onChange={(event) => this.inputChangeHandler(event, "plantName")}
                                value={this.state.name}
                                error={this.state.nameError}
                                InputProps={{
                                    classes: {
                                        input: this.props.classes.outlinedInput
                                    }
                                }}
                            />
                        </div>
                    )
                        : null
                    }
                    {this.state.validationData && this.state.validationData.organizationName && this.state.validationData.organizationName.page_visible ? (
                        <div className={this.props.classes.inputFieldDiv}>
                            <InputLabel className={this.props.classes.textFieldLabel}>
                                {this.state.validationData.organizationName.display_name}
                                {(this.state.validationData.organizationName.validations && this.state.validationData.organizationName.validations.required) ? (<span className={this.props.classes.requiredClass}>*</span>) : null}
                            </InputLabel>
                            <TextField
                                name="organizationName"
                                disabled
                                variant="outlined"
                                id="organizationName-disabled"
                                className={this.props.classes.textField}
                                placeholder="organization Name"
                                value={this.state.organizationName}
                                InputProps={{
                                    classes: {
                                        input: this.props.classes.outlinedInput
                                    }
                                }}
                            />
                        </div>
                    )
                        : null
                    }
                    {/* <Grid item sm={6}>
                                    {this.state.validationData && this.state.validationData.schedulesName && this.state.validationData.schedulesName.page_visible ? (
                                        <div className={this.props.classes.inputFeildDiv + " " + this.props.classes.inputFeildDivLeft}>
                                            <InputLabel className={this.props.classes.textFeildLabel}>
                                                {this.state.validationData.schedulesName.display_name}
                                                {(this.state.validationData.schedulesName.validations && this.state.validationData.schedulesName.validations.required) ? (<span className={this.props.classes.requiredClass}>*</span>) : null}
                                            </InputLabel>
                                            <Select
                                                native
                                                value={this.state.schedule}
                                                onChange={(event) => this.inputChangeHandler(event, "schedule")}
                                                input={
                                                    <OutlinedInput
                                                        variant="outlined"
                                                        name="schedule"
                                                        id="language-native-simple"
                                                        labelWidth={this.state.labelWidth}
                                                        className={this.props.classes.textField}
                                                        error={this.state.scheduleError}
                                                    />
                                                }
                                            >
                                                {this.getScheduleName()}
                                            </Select>
                                        </div>
                                    )
                                        : null
                                    }
                                </Grid> */}
                    {/* <Grid item sm={6}>
                                    {this.state.validationData && this.state.validationData.lossListsName && this.state.validationData.lossListsName.page_visible ? (
                                        <div className={this.props.classes.inputFeildDiv + " " + this.props.classes.inputFeildDivRight}>
                                            <InputLabel className={this.props.classes.textFeildLabel}>
                                                {this.state.validationData.lossListsName.display_name}
                                                {(this.state.validationData.lossListsName.validations && this.state.validationData.lossListsName.validations.required) ? (<span className={this.props.classes.requiredClass}>*</span>) : null}
                                            </InputLabel>
                                            <Select
                                                native
                                                value={this.state.lossList}
                                                onChange={(event) => this.inputChangeHandler(event, "lossList")}
                                                input={
                                                    <OutlinedInput
                                                        variant="outlined"
                                                        name="lossList"
                                                        id="language-native-simple"
                                                        labelWidth={this.state.labelWidth}
                                                        className={this.props.classes.textField}
                                                        error={this.state.lossListError}
                                                    />
                                                }
                                            >
                                                {this.getLossListName()}
                                            </Select>
                                        </div>
                                    )
                                        : null
                                    }
                                </Grid> */}
                    {/* <Grid item sm={6}>
                                    {this.state.validationData && this.state.validationData.jobListsName && this.state.validationData.jobListsName.page_visible ? (
                                        <div className={this.props.classes.inputFeildDiv + " " + this.props.classes.inputFeildDivLeft}>
                                            <InputLabel className={this.props.classes.textFeildLabel}>
                                                {this.state.validationData.jobListsName.display_name}
                                                {(this.state.validationData.jobListsName.validations && this.state.validationData.jobListsName.validations.required) ? (<span className={this.props.classes.requiredClass}>*</span>) : null}
                                            </InputLabel>
                                            <Select
                                                native
                                                value={this.state.jobList}
                                                onChange={(event) => this.inputChangeHandler(event, "jobList")}
                                                input={
                                                    <OutlinedInput
                                                        variant="outlined"
                                                        name="jobList"
                                                        id="language-native-simple"
                                                        labelWidth={this.state.labelWidth}
                                                        className={this.props.classes.textField}
                                                        error={this.state.jobListError}
                                                    />
                                                }
                                            >
                                                {this.getJobListName()}
                                            </Select>
                                        </div>
                                    )
                                        : null
                                    }
                                </Grid> */}
								
					{this.props && this.props.userData && this.props.userData.parentOrganizationType ==='publisher' && this.props && this.props.userData.admin ? (
                        <div className={this.props.classes.inputFieldDiv}>
                            <InputLabel className={this.props.classes.textFieldLabel}>
                                {this.state.validationData && this.state.validationData.industryType &&  this.state.validationData.industryType.display_name}
                                {(this.state.validationData && this.state.validationData.industryType && this.state.validationData.industryType.validations && this.state.validationData.industryType.validations.required) ? (<span className={this.props.classes.requiredClass}>*</span>) : null}
                            </InputLabel>
                            <Select
                                native
                                value={this.state.industryType}
                                onChange={(event) => this.inputChangeHandler(event, "industryType")}
                                input={
                                    <OutlinedInput
                                        variant="outlined"
                                        name="industryType"
                                        id="industryType-native-simple"
                                        labelWidth={this.state.labelWidth}
                                        className={this.props.classes.textField}
                                        error={this.state.industryTypeError}
                                        value={this.state.industryType}
                                        classes={{ input: this.props.classes.outlinedInput }}
                                    />
                                }
                            >
                               
							   {this.getIndustryTypeDropDownList()}
                            </Select>
                        </div>
                    )
                        : null
                    }								
								
                    
                    {this.state.validationData && this.state.validationData.timezone && this.state.validationData.timezone.page_visible ? (
                        <div className={this.props.classes.inputFieldDiv}>
                            <InputLabel className={this.props.classes.textFieldLabel}>
                                {this.state.validationData.timezone.display_name}
                                {(this.state.validationData.timezone.validations && this.state.validationData.timezone.validations.required) ? (<span className={this.props.classes.requiredClass}>*</span>) : null}
                            </InputLabel>
                            <Select
                                native
                                value={this.state.timezone}
                                onChange={(event) => this.inputChangeHandler(event, "timezone")}
                                input={
                                    <OutlinedInput
                                        variant="outlined"
                                        name="timezone"
                                        id="timezone-native-simple"
                                        labelWidth={this.state.labelWidth}
                                        className={this.props.classes.textField}
                                        error={this.state.timeZoneError}
                                        value={this.state.timezone}
                                        classes={{ input: this.props.classes.outlinedInput }}
                                    />
                                }
                            >
                                <option key={""} disabled={true} value={""}>{this.props.t("Select")}</option>
                                {
                                    this.props.timezones ? this.props.timezones.map((item) => (<option value={item} key={item}>{item}</option>)) : this.props.history.replace("/dashboard")
                                }
                            </Select>
                        </div>
                    )
                        : null
                    }
                    {this.state.validationData && this.state.validationData.fftOnAlarm && this.state.validationData.fftOnAlarm.page_visible ? (
                        <div className={this.props.classes.inputFieldDiv}>
                        <InputLabel className={this.props.classes.textFieldLabel}>
                            {this.state.validationData.fftOnAlarm.display_name}
                            {(this.state.validationData.fftOnAlarm.validations && this.state.validationData.fftOnAlarm.validations.required) ? (<span className={this.props.classes.requiredClass}>*</span>) : null}
                        </InputLabel>
                        <Select
                            native
                            value={this.state.fftOnAlarm}
                            onChange={(event) => this.inputChangeHandler(event, "fftOnAlarm")}
                            input={
                                <OutlinedInput
                                    variant="outlined"
                                    name="fftOnAlarm"
                                    id="fftOnAlarm-native-simple"
                                    labelWidth={this.state.labelWidth}
                                    className={this.props.classes.textField}
                                    error={this.state.fftOnAlarmError}
                                    value={this.state.fftOnAlarm}
                                    classes={{ input: this.props.classes.outlinedInput }}
                                />
                            }
                        >
                            <option key={"Yes"}  value={"Yes"}>{this.props.t("Yes")}</option>
                            <option key={"No"}  value={"No"}>{this.props.t("No")}</option>
                        </Select>
                    </div>
                    )
                        : null
                    }
					
					{this.state.validationData && this.state.validationData.language && this.state.validationData.language.page_visible ? (
                        <div className={this.props.classes.inputFieldDiv}>
                            <InputLabel className={this.props.classes.textFieldLabel}>
                                {this.state.validationData.language.display_name}
                                {(this.state.validationData.language.validations && this.state.validationData.language.validations.required) ? (<span className={this.props.classes.requiredClass}>*</span>) : null}
                            </InputLabel>
                            <Select
                                native
                                value={this.state.language}
                                onChange={(event) => this.inputChangeHandler(event, "language")}
                                input={
                                    <OutlinedInput
                                        variant="outlined"
                                        name="language"
                                        id="language-native-simple"
                                        labelWidth={this.state.labelWidth}
                                        className={this.props.classes.textField}
                                        error={this.state.languageError}
                                        classes={{ input: this.props.classes.outlinedInput }}
                                    />
                                }
                            >
                                <option disabled={true} key={""} value="">{this.props.t("Select")}</option>
                                <option value="English">English</option>
                                {/* {
                                    this.props.languages.map((item) => (<option value={item} key={item}>{item}</option>))
                                } */}
                            </Select>
                        </div>
                    )
                        : null
                    }
					
					
                    {this.state.validationData && this.state.validationData.notes && this.state.validationData.notes.page_visible ? (
                        <div className={this.props.classes.textareaInputFieldDiv}>
                            <InputLabel className={this.props.classes.textareaFieldLabel}>
                                {this.state.validationData.notes.display_name}
                                {(this.state.validationData.notes.validations && this.state.validationData.notes.validations.required) ? (<span className={this.props.classes.requiredClass}>*</span>) : null}
                            </InputLabel>
                            <TextField
                                id="note"
                                multiline
                                rows="6"
                                helperText={(this.state.validationData.notes.validations && this.state.validationData.notes.validations.helpText) ? (this.state.validationData.notes.validations.helpText) : null}
                                value={this.state.notes}
                                variant="outlined"
                                onChange={(event) => this.inputChangeHandler(event, "notes")}
                                className={this.props.classes.textArea}
                                error={this.state.notesError}
                                InputProps={{
                                    classes: {
                                        multiline: this.props.classes.outlinedInput
                                    }
                                }}
                            />
                        </div>
                    )
                        : null
                    }
                    {this.state.validationData && this.state.validationData.specs && this.state.validationData.specs.page_visible ? (
                        <div className={this.props.classes.textareaInputFieldDiv}>
                            <InputLabel className={this.props.classes.textareaFieldLabel}>
                                {this.state.validationData.specs.display_name}
                                {(this.state.validationData.specs.validations && this.state.validationData.specs.validations.required) ? (<span className={this.props.classes.requiredClass}>*</span>) : null}
                            </InputLabel>
                            <TextField
                                id="specification"
                                multiline
                                rows="6"
                                helperText={(this.state.validationData.specs.validations && this.state.validationData.specs.validations.helpText) ? (this.state.validationData.specs.validations.helpText) : null}
                                value={this.state.specs}
                                variant="outlined"
                                onChange={(event) => this.inputChangeHandler(event, "specs")}
                                className={this.props.classes.textArea}
                                error={this.state.specsError}
                                InputProps={{
                                    classes: {
                                        multiline: this.props.classes.outlinedInput
                                    }
                                }}
                            />
                        </div>
                    )
                        : null
                    }
                </CardContent>
            </Card >
        );
    }
}

const mapStateToProps = (state) => {
    return {
        validationData: state.viewDefinitionReducer,
        schedulesData: state.schedulesDataReducer,
        jobsData: state.jobListDataReducer,
        lossCodes: state.lossListDataReducer,
        userData: state.userDataReducer,
    }
}

const mapDispatchToProps = dispatch => {
    return ({
        getTableData: (selectedNavtab, currentScopeSelectionId, setToStore) => (dispatch(actionCreators.getTableData(selectedNavtab, currentScopeSelectionId, setToStore))),
        updatePlantEntity: (plantEntityData, id) => (dispatch(actionCreators.updatePlantEntity(plantEntityData, id))),
        updateSelectedTab: (selectedTab) => (dispatch(actionCreators.updateSelectedTab(selectedTab)))
    })
}

export default withTranslation()(connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(PlantEntity)));

