import * as actionTypes from '../actions/actionTypes';

export const reportEquipmentDataReducer = (state={}, action) => {
    switch(action.type) {

        case actionTypes.REPORT_SAVE_EQUIPMENT_DATA:{ 
            return Object.assign({}, state, action.payload)
        }
        
        default: return state
    }
}