import React, { Component } from 'react'
import moment from "moment";
import { connect } from 'react-redux';
import * as actionCreators from '../../../actions/actionCreators';
import { withTranslation } from "react-i18next";

class OEEByOperator extends Component {
    constructor(props){
        super(props);
    }  

    onSelectionChange = (e) =>{
        let eleValue = Number.isInteger(e.target.value) ? parseInt(e.target.value) : e.target.value;
        let startDate = moment(this.props.range[1]).utc().format("YYYY-MM-DDTHH:mm:00") + "Z";
        let endDate = moment(this.props.range[2]).utc().format("YYYY-MM-DDTHH:mm:00") + "Z";
        if(eleValue || eleValue===0){
            this.props.getOEEByPartNumber(this.props.selectedPlantId,startDate,endDate,eleValue)
        }
    }
    
    render() {
        const {t}=this.props;
        return (
            <div className="oeeByPartNo-cntr display-text"> 
                <div className="list-selection-wrp display-text">
                    {t("Shoot")}
                        <select className="list-selection form-control" id="productivity_type" onChange={this.onSelectionChange}>
                            {this.props.list.map((list, index) =>
                               <option value={list.partName} key={index}>{list.partName}</option>
                            )}
                        </select>
                    </div>
            </div>
        )
    }
}

const mapDispatchToProps = dispatch => {
    return {        
        getOEEByPartNumber: (plantId , from, to, partNumber) => (dispatch((actionCreators.getOEEByPartNumber(plantId , from, to,partNumber)))),
    }
  }
  
export default withTranslation()(connect(null,mapDispatchToProps )(OEEByOperator));