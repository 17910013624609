import * as actionTypes from '../actions/actionTypes';

export const qualityReportReducer = (state={}, action) => {
    switch(action.type) {

        case actionTypes.SAVE_QUALITY_REPORT:
        return Object.assign({}, state, action.payload)

        default: return state
    }
}