import * as actionTypes from '../actions/actionTypes';

export const dashboardDeviceReducer = (state={
    deviceData : {},
    featureData: {},
    imageData: '',
    rawData: {}
}, action) => {
    switch(action.type) {

        case actionTypes.SET_DEVICE_DATA:
                return {
                    ...state,
                    deviceData : action.payload
                }

        case actionTypes.SET_FEATURE_DATA:
                return {
                    ...state,
                    featureData : action.payload
                }

        case actionTypes.SET_CHART_IMAGE:
                return {
                    ...state,
                    imageData : action.payload
            }

        case actionTypes.SET_FEATURE_RAW_DATA:
            return {
                ...state,
                rawData: action.payload
            }

        default: return state
    }
}