import React, { Component } from 'react'
import { withRouter } from "react-router-dom";
import { config } from '../../configuration';
import {deviceConfig} from "./DeviceConfig";

import DashNavs from './Panels/DashNavs';
import * as actionCreators from '../../actions/actionCreators';
import { EventSourcePolyfill } from 'event-source-polyfill';

import MuiDialogTitle from '@material-ui/core/DialogTitle';
import moment from "moment";
import CircularProgress from '@material-ui/core/CircularProgress';

import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import {
  withStyles,
  Dialog,
  DialogActions,
  OutlinedInput,
  DialogContent,
  Typography,
  Button,
  InputLabel,
  Select,
} from '@material-ui/core';
import { connect } from 'react-redux';
import { SummaryPanel } from './Panels/SummaryPanel';
import { SummaryPlantPanel } from "./Panels/SummaryPlantPanel"
import { withTranslation } from "react-i18next";
import { support_email } from '../../configuration';
import DefaultView from './DefaultView';
import DataLoggerView from './DataLoggerView';
import BatteryView from './BatteryView';
import { computeRange } from '../../utils';
import { getOidcAccessToken } from '../../utils/oidcStorageUtils';
import { debounce, isEqual, get, isEmpty } from 'lodash';
import useGAEventsTracker from '../../hooks/useGAEventsTracker';

let realtime_server_url = config.url.realtime_server_url;
let currentLanguage = localStorage.getItem("lang");

const sortOrder = {
  "ascending": "ASC",
  "descending": "DESC"
}

const operatorMap = {
  ">" : "GREATER_THAN",
  ">=" : "GREATER_THAN_OR_EQUAL_TO",
  "<" : "LESS_THAN",
  "<=" : "LESS_THAN_OR_EQUAL_TO",
}

const styles = theme => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff',
  },
  root: {
    display: 'flex',
    '& > * + *': {
      marginLeft: 2,
    },
  },
  loading: {
    position: "fixed",
    "z-index": 1400,
    height: "180vh",
    width: "120%",
    "background-color": "rgba(0,0,0,0.5)",
    display: 'flex',
    marginLeft: "-50px",
    marginTop: "-100px",
    marginBottom: "-50px",
    'justify-content': 'center',
    'align-items': 'center'
  },
  summaryLoading: {
    position: "fixed",
    "z-index": 1400,
    height: "120vh",
    width: "110%",
    "background-color": "rgba(0,0,0,0.5)",
    display: 'flex',
    marginLeft: "-50px",
    marginTop: "-100px",
    marginBottom: "-50px",
    'justify-content': 'center',
    'align-items': 'center'
  },
  closeButton: {
    position: 'absolute',
    right: "10px",
    top: "10px",
    color: theme.palette.grey[500],
  },
  loaderColor: {
    color: '#2abbe8'
  },
  select: {
    "&:before": {
      borderColor: "#3f51b5",
    },
    '&:after': {
      borderColor: "#3f51b5",
    },
    color: "#000000"
  },
  inputFieldDiv: {
    display: "flex",
    width: '40%',
    float: 'left',
    paddingTop: '1px',
    height: 36,
    'align-items': 'baseline'
  },
  textFieldLabel: {
    color: 'rgba(0, 0, 0)',
    'letter-spacing': 0.5,
    'font-weight': 500,
    fontSize: "14px",
    lineHeight: 2.5,
    marginLeft: 20,
    //text-align': 'right'
  },
  textFieldAreaLabel: {
    color: "#FFFFFF",
    'letter-spacing': 0.5,
    'font-weight': 500,
    fontSize: "14px",
    lineHeight: 2.5,
    marginTop:"0px",
    marginBottom:"0px"
  },
  textField: {
    width: '50%',
    marginLeft: 10
  },
  outlinedInput: {
    padding: 8
  },
});

let thresholds = {
  "signalEnergy": {
    "Ideal": 10,
    "Operational": 60,
    "Caution": 100,
    "Warning": 80
  }
  ,
  "velocityX":
  {
    "Ideal": 5,
    "Operational": 20,
    "Caution": 22,
    "Warning": 40
  }
  ,
  "velocityY":
  {
    "Ideal": 5,
    "Operational": 20,
    "Caution": 22,
    "Warning": 40
  }
  ,
  "velocityZ":
  {
    "Ideal": 5,
    "Operational": 20,
    "Caution": 22,
    "Warning": 40
  }
  ,
  "temperature":
  {
    "Ideal": 30,
    "Operational": 60,
    "Caution": 80,
    "Warning": 100
  },
  "noise":
  {
    "Ideal": 30,
    "Operational": 60,
    "Caution": 80,
    "Warning": 100
  },
  "fId1":
  {
    "Ideal": 3,
    "Operational": 6,
    "Caution": 8,
    "Warning": 10
  },
  "fId2":
  {
    "Ideal": 3,
    "Operational": 6,
    "Caution": 8,
    "Warning": 10
  },
  "fId3":
  {
    "Ideal": 0.3,
    "Operational": 0.6,
    "Caution": 0.8,
    "Warning": 1
  }

};

class MonitoringDashboard extends Component {
  constructor(props) {
    super(props);
    this.el1 = React.createRef();
    this.el2 = React.createRef();
    this.state = {
      range: ["month"],
      realTimeData: [],
      diagnosticData: [],
      fingerprintRealTimeData: [],
      featureArray: [],
      instateneousArray: [],
      trendArr: [],
      isFull: false,
      noFlag: false,
      isEquipmentDone: false,
      isSummaryDone: false,
      isSummaryDoneForPlant: false,
      isMaintainanceDone: false,
      isGetFeatureDone: false,
      subscriptionRemain: "",
      isShowPopup: false,
      searchTexValue: "",
      machineList: [],
      monitorList: [],
      areaList: [],
      machineGroupList: [],
      selectedMonitor: "",
      selectedMonitorId: null,
      selectedMachine: "",
      selectedMachineId: null,
      selectedArea: "",
      selectedMachineGroup: "",
      selectedMachineGroupId: null,
      selectedAreaId: null,
      selectedMonitorGroup: "",
      emptyFlag: false,
      batteryFlag: false,
      dataLoggerFlag: false,
      selectedHealthScoreVal: "<=50",
      summaryPlantPagination: {
        pageNumber: 0,
        pageSize: 20,
        orderBy: {
          field: "healthScore",
          direction: "ASC",
        },
        keyword: "",
        total: 0,
        healthScoreFilter: {
          operator: "LESS_THAN_OR_EQUAL_TO",
          value: 50,
        },
      },
      lang: currentLanguage,
  };
    this.source = {};
    this.source1 = {};
    this.source2 = {};
    this.rangeSelector = this.rangeSelector.bind(this);
    this.timer = '';
  }

  GAEventsTracker = useGAEventsTracker('usersession');

  componentDidMount() {
    if (this.props.dashboardType.type === "monitoring") {
      let currentDateTime = new Date();
      let startDate;
      let range = [];
      let rangeObj = localStorage.getItem("dateRange")
      rangeObj = rangeObj && rangeObj !== "null" ? rangeObj : "month"
      if (rangeObj === "30Minutes") {
        startDate = new Date(currentDateTime.getTime() - 30 * 1000 * 60);
      }
      if (rangeObj === "hour") {
        startDate = new Date(currentDateTime.getTime() - 60 * 1000 * 60);
      }
      if (rangeObj === "24hour") {
        startDate = new Date(currentDateTime.getTime() - 24 * 60 * 1000 * 60);
      }
      if (rangeObj === "week") {
        startDate = new Date(currentDateTime.getTime() - 7 * 24 * 60 * 1000 * 60);
      }
      if (rangeObj === "month") {
        startDate = new Date(currentDateTime);
        startDate.setDate(startDate.getDate() - 30);
      }
      if (rangeObj === "today") {
        startDate = new Date();
        startDate.setHours(0)
        startDate.setMinutes(0);
        startDate.setSeconds(0);
        startDate.setMilliseconds(0);
      }
      if (rangeObj === "thisMonth") {
        startDate = new Date();
        startDate.setDate(1);
        startDate.setHours(0)
        startDate.setMinutes(0);
        startDate.setSeconds(0);
        startDate.setMilliseconds(0);
      }
      if (rangeObj === "year") {
        startDate = new Date();
        let year = currentDateTime.getUTCFullYear();
        startDate.setUTCFullYear(year - 1);
      }
      if (rangeObj === "all") {
        startDate = new Date(2015, 7, 1, 0, 0, 0, 0);
      }

      if (rangeObj !== "thisMonth" && rangeObj !== "today" && rangeObj !== "all") {
        startDate.setHours(startDate.getHours());
        startDate.setMinutes(startDate.getMinutes());
        startDate.setSeconds(0);
        startDate.setMilliseconds(0);
      }
      currentDateTime.setHours(currentDateTime.getHours());
      currentDateTime.setMinutes(currentDateTime.getMinutes());
      currentDateTime.setSeconds(0);
      currentDateTime.setMilliseconds(0);
      range.push(rangeObj);
      range.push(startDate.toISOString());
      range.push(currentDateTime.toISOString());
      this.setState({ range: range });
      let from = moment(range[1]).utc().format("YYYY-MM-DDTHH:mm:ss") + "Z";
      let to = moment(range[2]).utc().format("YYYY-MM-DDTHH:mm:ss") + "Z";
      if (this.props.dashboardDeviceData && this.props.dashboardDeviceData.deviceData && this.props.dashboardDeviceData.deviceData.deviceData) {
        this.fetchRealtimeData();
        this.fetchDiagnosticsData();
        this.setState({ isEquipmentDone: true, isMaintainanceDone: true, isGetFeatureDone: true });
        if (this.props.dashboardDeviceData && this.props.dashboardDeviceData.deviceData && this.props.dashboardDeviceData.deviceData.deviceData && this.props.dashboardDeviceData.deviceData.deviceData.id) {
          this.props.getMonitorByIdForDashboard(this.props.dashboardDeviceData.deviceData.deviceData.id);
          this.props.getDeviceData(this.props.dashboardDeviceData.deviceData.deviceData.deviceIdentifier);
          this.props.getAlarmsData(this.props.dashboardDeviceData.deviceData.deviceData.id, from, to);
          // this.props.getFFTData(this.props.dashboardDeviceData.deviceData.deviceData.id, from, to);
          // this.props.getEquipmentData(this.props.dashboardDeviceData.deviceData.deviceData.id, from, to);
          this.props.getMaintenanceData(this.props.dashboardDeviceData.deviceData.deviceData.id, from, to);
          this.props.getFeatureListData(this.props.dashboardDeviceData.deviceData.deviceData.id);
        }
        this.setState({ noFlag: false })
        let selectedScopeData = localStorage.getItem("selectedScopeData")
        let machineList = [], monitorList = []; let machineGroupList = []; let areaList = [];
        this.props.getMonitorList(parseInt(selectedScopeData), (list) => {
          list.responseTime = new Date();
          list.areaStatusResponseList.map(areaGroup =>{
            areaGroup.machineGroupStatusResponses.map((item, key) => {
            item.machineStatusReponse.map((machine, index) => {
              machine.monitorStatusResponse.map((monitor, i) => {
                monitorList.push({
                  value: monitor.name,
                  id: machine.name,
                  monitorId: monitor.id,
                  monitorName: monitor.name,
                  deviceId: machine.id,
                  deviceIdentifier: monitor.deviceIdentifier,
                  area: areaGroup.name,
                  machineGroup: item.name,
                  machineGroupId: item.id,
                  areaId: areaGroup.id,
                  machineName: machine.name,
                  machineId: machine.id,
                });
              })

              machineList.push({
                name: machine.name,
                machineId: machine.id,
                area: areaGroup.name,
                machineGroup: item.name,
                machineGroupId: item.id,
                areaId: areaGroup.id,
              });
            })

            machineGroupList.push({ name: item.name, area: areaGroup.name });
          })

          areaList.push(areaGroup.name);
        })
          let machineName = localStorage.getItem("machineName");
          if (machineName) {
            let deviceId = localStorage.getItem("deviceId");
            let obj = monitorList.filter(d => d.monitorId === parseInt(deviceId));
            if (obj.length !== 0) {
              localStorage.setItem("deviceName", obj[0].value);
              localStorage.setItem("deviceId", obj[0].monitorId)
              localStorage.setItem("areaName",obj[0].area);
              localStorage.setItem("machineGroupName",obj[0].machineGroup);
              localStorage.setItem("machineName", obj[0].id)
              localStorage.setItem("deviceIdentifier", obj[0].deviceIdentifier)
              this.props.setDeviceData({ "deviceName": obj[0].value, "id": obj[0].monitorId, "machineName": obj[0].id, "deviceIdentifier": obj[0].deviceIdentifier })
              
              this.setState(
                {
                  areaList,
                  machineGroupList,
                  machineList,
                  selectedMachine: machineName,
                  selectedMachineId: get(obj, "[0].machineId", null),
                  selectedMonitor: obj[0].value,
                  selectedArea: obj[0].area,
                  selectedMachineGroup: obj[0].machineGroup,
                  selectedMachineGroupId: get(obj, "[0].machineGroupId", null),
                  selectedAreaId: get(obj, "[0].areaId", null),
                  monitorList,
                  selectedMonitorGroup: obj[0],
                },
                () => {
                  this.getMachineGroups();
                  this.getMachineName();
                  this.getMonitorName();
                }
              );
            }
          } else {
            localStorage.setItem("deviceName", monitorList[0].value);
            localStorage.setItem("deviceId", monitorList[0].monitorId)
            localStorage.setItem("machineName", monitorList[0].id)
            localStorage.setItem("deviceIdentifier", monitorList[0].deviceIdentifier)
            localStorage.setItem("areaName",monitorList[0].area);
            localStorage.setItem("machineGroupName",monitorList[0].machineGroup);
            this.props.setDeviceData({ "deviceName": monitorList[0].value, "id": monitorList[0].monitorId, "machineName": monitorList[0].id, "deviceIdentifier": monitorList[0].deviceIdentifier })
            this.setState({
              areaList,
              machineGroupList,
              machineList,
              selectedMachine: machineList[0],
              selectedMachineId: get(machineList, "[0].machineId", null),
              selectedMonitor: monitorList[0].value,
              selectedArea: monitorList[0].area,
              selectedMachineGroup: monitorList[0].machineGroup,
              selectedMachineGroupId: get(monitorList, "[0].machineGroupId", null),
              selectedAreaId: get(monitorList, "[0].areaId", null),
              monitorList,
              selectedMonitorGroup: monitorList[0],
            });
          }
        })

      }
      else {
        this.setState({ noFlag: true })
      }
      // setInterval(() => {
      //   // 
      //   // 
      //   let currentDateTime = new Date();
      //   range = [];
      //   range.push(this.state.range[0]);
      //   range.push(this.state.range[1]);
      //   range.push(currentDateTime.toISOString());
      //   if(this.state.range[0] === 'today' || this.state.range[0]=== 'thisMonth'){
      //     this.setState({
      //       range : range
      //     }, ()=>{
      //       // 
      //     })
      //   }

      //  }, 60000);
    } else if (this.props.dashboardType.type === "summary") {
      if (this.props.selectedScopeData) {
        this.setState({ noFlag: false });
        this.onRefreshSummary()
      } else {
        this.setState({ noFlag: true })
      }
    }
    else {
      this.onRefreshSummaryPanel();
    }
  }

  componentWillUnmount() {
    if (this.props.dashboardType.type === "monitoring") {
      var currrentDeviceId = this.props.dashboardDeviceData && this.props.dashboardDeviceData.deviceData && this.props.dashboardDeviceData.deviceData.deviceData ? this.props.dashboardDeviceData.deviceData.deviceData.id : null;
      if (currrentDeviceId) {
        this.source.close();
        this.source1.close();
        this.source2.close();
      }

    }
  }
  componentDidUpdate(prevProps, prevState) {
    const {
      selectedAreaId: prevSelectedAreaId,
      selectedMachineGroupId: prevSelectedMachineGroupId,
      selectedMachineId: prevSelectedMachineId,
      selectedMonitorId: prevSelectedMonitorId,
    } = prevState;
    
    const {
      selectedAreaId,
      selectedMachineGroupId,
      selectedMachineId,
      selectedMonitorId,
    } = this.state;

    if (
      !isEqual(selectedAreaId, prevSelectedAreaId) ||
      !isEqual(selectedMachineGroupId, prevSelectedMachineGroupId) ||
      !isEqual(selectedMachineId, prevSelectedMachineId) ||
      !isEqual(selectedMonitorId, prevSelectedMonitorId)
    ) {
      let plantIdToUse = this.props.selectedApplicationPlantId;

      if (isEmpty(plantIdToUse)) {
        const storedSelectedPlantId = localStorage.getItem("selectedPlantID");
        const storedPlantId = Number(storedSelectedPlantId)
          ? Number(storedSelectedPlantId)
          : null;
        plantIdToUse = storedPlantId;

        this.props.setApplicationSelectedPlantId(storedPlantId);
      }

      if (
        plantIdToUse &&
        selectedAreaId &&
        selectedMachineGroupId &&
        selectedMachineId &&
        selectedMonitorId
      ) {
        this.props.getExternalFeatures(
          plantIdToUse,
          selectedAreaId,
          selectedMachineGroupId,
          selectedMachineId,
          selectedMonitorId
        );
      }
    }

    if (prevProps.summaryData !== this.props.summaryData) {
      if (this.props.summaryData && this.props.summaryData.summaryData) {
        if (Object.keys(this.props.summaryData.summaryData).length === 0) {
          this.setState({ isSummaryDoneForPlant: false, emptyFlag: true });
        } else {
          if (this.props.dashboardType.type === "summaryPlant") {
            const {
              totalElements,
            } = this.props.summaryData.summaryData.pageDetailResponse;
            this.setState({
              emptyFlag: false,
              summaryPlantPagination: {
                ...this.state.summaryPlantPagination,
                total: totalElements,
              },
            });
          } else
            this.setState({
              emptyFlag: false,
            });
        }
      }
    }

    if (this.props.i18n.language !== prevState.lang) {
      if (this.props.dashboardType.type === "summaryPlant") 
        this.onRefreshSummaryPanel();
      else if(this.props.dashboardType.type === "summary") {
        this.onRefreshSummary()
      }          
      this.setState({ lang: this.props.i18n.language });
    }

    // Refetch Data when Pagination state changes
    if (
      !isEqual(
        prevState.summaryPlantPagination.orderBy,
        this.state.summaryPlantPagination.orderBy
      ) ||
      !isEqual(
        prevState.summaryPlantPagination.healthScoreFilter,
        this.state.summaryPlantPagination.healthScoreFilter
      ) ||
      prevState.summaryPlantPagination.keyword !==
        this.state.summaryPlantPagination.keyword ||
      prevState.summaryPlantPagination.pageNumber !==
        this.state.summaryPlantPagination.pageNumber ||
      prevState.summaryPlantPagination.pageSize !==
        this.state.summaryPlantPagination.pageSize
    ) {
      this.props.getSummaryPlantData(this.state.summaryPlantPagination);
    }
    if ((prevProps.deviceData && prevProps.deviceData.deviceData && prevProps.deviceData.deviceData.deviceTypes) !== (this.props.deviceData && this.props.deviceData.deviceData && this.props.deviceData.deviceData.deviceTypes)) {
      this.setState({ dataLoggerFlag: false })
      if (this.props.deviceData && this.props.deviceData.deviceData && this.props.deviceData.deviceData.deviceTypes && this.props.deviceData.deviceData.deviceTypes.name) {
        if (deviceConfig && deviceConfig[0].battery[0] ===this.props.deviceData.deviceData.deviceTypes.name) {
          this.setState({ batteryFlag: true, dataLoggerFlag: false })
        }
        else if (deviceConfig && deviceConfig[1].dataLogger[0]===this.props.deviceData.deviceData.deviceTypes.name ||
          deviceConfig && deviceConfig[1].dataLogger[1]===this.props.deviceData.deviceData.deviceTypes.name ||
          deviceConfig && deviceConfig[1].dataLogger[2]===this.props.deviceData.deviceData.deviceTypes.name ||
          deviceConfig && deviceConfig[1].dataLogger[3]===this.props.deviceData.deviceData.deviceTypes.name
        ) {
          this.setState({ dataLoggerFlag: true, batteryFlag: false })
        }
        else {
          this.setState({ batteryFlag: false, dataLoggerFlag: false })
        }
      }
    }
    if (this.props.dashboardType.type === "monitoring") {
      if (prevProps.dashboardType.type !== this.props.dashboardType.type && this.props.dashboardType.type === "monitoring") {
        let currentDateTime = new Date();
        let startDate;
        let range = [];
        let rangeObj = localStorage.getItem("dateRange")
        rangeObj = rangeObj && rangeObj !== "null" ? rangeObj : "month"
        if (rangeObj === "30Minutes") {
          startDate = new Date(currentDateTime.getTime() - 30 * 1000 * 60);
        }
        if (rangeObj === "hour") {
          startDate = new Date(currentDateTime.getTime() - 60 * 1000 * 60);
        }
        if (rangeObj === "24hour") {
          startDate = new Date(currentDateTime.getTime() - 24 * 60 * 1000 * 60);
        }
        if (rangeObj === "week") {
          startDate = new Date(currentDateTime.getTime() - 7 * 24 * 60 * 1000 * 60);
        }
        if (rangeObj === "month") {
          startDate = new Date(currentDateTime);
          startDate.setDate(startDate.getDate() - 30);
        }
        if (rangeObj === "today") {
          startDate = new Date();
          startDate.setHours(0)
          startDate.setMinutes(0);
          startDate.setSeconds(0);
          startDate.setMilliseconds(0);
        }
        if (rangeObj === "thisMonth") {
          startDate = new Date();
          startDate.setDate(1);
          startDate.setHours(0)
          startDate.setMinutes(0);
          startDate.setSeconds(0);
          startDate.setMilliseconds(0);
        }
        if (rangeObj === "year") {
          startDate = new Date();
          let year = currentDateTime.getUTCFullYear();
          startDate.setUTCFullYear(year - 1);
        }
        if (rangeObj === "all") {
          startDate = new Date(2015, 7, 1, 0, 0, 0, 0);
        }

        if (rangeObj !== "thisMonth" && rangeObj !== "today" && rangeObj !== "all") {
          startDate.setHours(startDate.getHours());
          startDate.setMinutes(startDate.getMinutes());
          startDate.setSeconds(0);
          startDate.setMilliseconds(0);
        }
        currentDateTime.setHours(currentDateTime.getHours());
        currentDateTime.setMinutes(currentDateTime.getMinutes());
        currentDateTime.setSeconds(0);
        currentDateTime.setMilliseconds(0);
        range.push(rangeObj);
        range.push(startDate.toISOString());
        range.push(currentDateTime.toISOString());
        this.setState({ range: range });
        let from = moment(range[1]).utc().format("YYYY-MM-DDTHH:mm:ss") + "Z";
        let to = moment(range[2]).utc().format("YYYY-MM-DDTHH:mm:ss") + "Z";
        if ((this.props.dashboardDeviceData && this.props.dashboardDeviceData.deviceData && this.props.dashboardDeviceData.deviceData.deviceData)) {
          this.fetchRealtimeData();
          this.fetchDiagnosticsData();
          this.setState({ isEquipmentDone: true, isMaintainanceDone: true, isGetFeatureDone: true });
          if (this.props.dashboardDeviceData && this.props.dashboardDeviceData.deviceData && this.props.dashboardDeviceData.deviceData.deviceData && this.props.dashboardDeviceData.deviceData.deviceData.id) {
            this.props.getMonitorByIdForDashboard(this.props.dashboardDeviceData.deviceData.deviceData.id);
            this.props.getDeviceData(this.props.dashboardDeviceData.deviceData.deviceData.deviceIdentifier);
            this.props.getAlarmsData(this.props.dashboardDeviceData.deviceData.deviceData.id, from, to);
            // this.props.getFFTData(this.props.dashboardDeviceData.deviceData.deviceData.id, from, to);
            // this.props.getEquipmentData(this.props.dashboardDeviceData.deviceData.deviceData.id, from, to);
            this.props.getMaintenanceData(this.props.dashboardDeviceData.deviceData.deviceData.id, from, to);
            this.props.getFeatureListData(this.props.dashboardDeviceData.deviceData.deviceData.id);
          }
        }
      }
      if (this.props.deviceData != prevProps.deviceData) {
        var today = new Date().getTime();
        var subscriptionStart = null;
        var subscriptionEnd = null;
        if (this.props.deviceData && this.props.deviceData.deviceData) {
          subscriptionStart = this.props.deviceData.deviceData.subscriptionStart;
          subscriptionEnd = this.props.deviceData.deviceData.subscriptionEnd;
        }
        if (subscriptionStart && subscriptionEnd) {
          var subscriptionRemain = Math.ceil((subscriptionEnd - today) / (1000 * 3600 * 24));
          var isShowPopup = subscriptionRemain >= 0 && subscriptionRemain <= 30 && subscriptionRemain % 5 === 0;
          this.setState({ subscriptionRemain: subscriptionRemain, isShowPopup: isShowPopup });
        }
      }
      var prevDeviceId = prevProps.dashboardDeviceData && prevProps.dashboardDeviceData.deviceData && prevProps.dashboardDeviceData.deviceData.deviceData ? prevProps.dashboardDeviceData.deviceData.deviceData.id : null;
      var currrentDeviceId = this.props.dashboardDeviceData && this.props.dashboardDeviceData.deviceData && this.props.dashboardDeviceData.deviceData.deviceData ? this.props.dashboardDeviceData.deviceData.deviceData.id : null;
      if (prevDeviceId !== currrentDeviceId) {
        if (prevDeviceId) {
          this.source.close();
          this.source1.close();
          this.source2.close();
        }
        if (currrentDeviceId) {
          this.props.getMonitorByIdForDashboard(this.props.dashboardDeviceData.deviceData.deviceData.id);
          const fetchingDeviceData = this.props.getDeviceData(this.props.dashboardDeviceData.deviceData.deviceData.deviceIdentifier);
          this.setState({ isEquipmentDone: true, isMaintainanceDone: true, isGetFeatureDone: true });
          this.fetchRealtimeData();
          this.fetchDiagnosticsData();
          // this.props.getAlarmsData(this.props.dashboardDeviceData.deviceData.deviceData.id, this.state.range[1], this.state.range[2]);
          //Commented for first call after login on 11.12.2020
          setTimeout(() => {
            if (this.props.dashboardDeviceData && this.props.dashboardDeviceData.deviceData && this.props.dashboardDeviceData.deviceData.deviceData && this.props.dashboardDeviceData.deviceData.deviceData.id) {
              this.props.getMaintenanceData(this.props.dashboardDeviceData.deviceData.deviceData.id, this.state.range[1], this.state.range[2]);
            }
          }, 10);
          // let startDate = moment(this.state.range[1]).utc().format("YYYY-MM-DDTHH:mm:ss") + "Z";
          // let endDate = moment(this.state.range[2]).utc().format("YYYY-MM-DDTHH:mm:ss") + "Z";
          // // this.props.getFFTData(this.props.dashboardDeviceData.deviceData.deviceData.id, startDate, endDate);
          // this.props.getEquipmentData(this.props.dashboardDeviceData.deviceData.deviceData.id, startDate, endDate);

          // Wait for deviceData to be available before getting feature list
          // Need to know whether device is dataLogger or Battery or Default
          fetchingDeviceData.then(() => {
            this.props.getFeatureListData(this.props.dashboardDeviceData.deviceData.deviceData.id);
          });

          let currentDateTime = new Date();
          let startDate;
          let range = [];
          let rangeObj = localStorage.getItem("dateRange")
          rangeObj = rangeObj && rangeObj !== "null" ? rangeObj : "month"
          if (rangeObj === "30Minutes") {
            startDate = new Date(currentDateTime.getTime() - 30 * 1000 * 60);
          }
          if (rangeObj === "hour") {
            startDate = new Date(currentDateTime.getTime() - 60 * 1000 * 60);
          }
          if (rangeObj === "24hour") {
            startDate = new Date(currentDateTime.getTime() - 24 * 60 * 1000 * 60);
          }
          if (rangeObj === "week") {
            startDate = new Date(currentDateTime.getTime() - 7 * 24 * 60 * 1000 * 60);
          }
          if (rangeObj === "month") {
            startDate = new Date(currentDateTime);
            startDate.setDate(startDate.getDate() - 30);
          }
          if (rangeObj === "today") {
            startDate = new Date();
            startDate.setHours(0)
            startDate.setMinutes(0);
            startDate.setSeconds(0);
            startDate.setMilliseconds(0);
          }
          if (rangeObj === "thisMonth") {
            startDate = new Date();
            startDate.setDate(1);
            startDate.setHours(0)
            startDate.setMinutes(0);
            startDate.setSeconds(0);
            startDate.setMilliseconds(0);
          }
          if (rangeObj === "year") {
            startDate = new Date();
            let year = currentDateTime.getUTCFullYear();
            startDate.setUTCFullYear(year - 1);
          }
          if (rangeObj === "all") {
            startDate = new Date(2015, 7, 1, 0, 0, 0, 0);
          }

          if (rangeObj !== "thisMonth" && rangeObj !== "today" && rangeObj !== "all") {
            startDate.setHours(startDate.getHours());
            startDate.setMinutes(startDate.getMinutes());
            startDate.setSeconds(0);
            startDate.setMilliseconds(0);
          }
          currentDateTime.setHours(currentDateTime.getHours());
          currentDateTime.setMinutes(currentDateTime.getMinutes());
          currentDateTime.setSeconds(0);
          currentDateTime.setMilliseconds(0);
          range.push(rangeObj);
          range.push(startDate.toISOString());
          range.push(currentDateTime.toISOString());
          this.setState({ range: range });
          // let from = moment(range[1]).utc().format("YYYY-MM-DDTHH:mm:ss") + "Z";
          // let to = moment(range[2]).utc().format("YYYY-MM-DDTHH:mm:ss") + "Z";
        }
      }
      if (prevProps.featureListData != this.props.featureListData) {
        let object = {};
        this.setState({ isGetFeatureDone: false });
        let featureArray = [];
        let fffFeatureArray = [];
        if (this.props.featureListData.featureData && this.props.featureListData.featureData.length > 0) {
          this.props.featureListData.featureData.forEach(element => {
            object.monitorId = element.monitorId;
            object.viewValue = element.featureId.displayName;
            object.value = element.featureId.featureCode;
            object.unit = element.featureId.unitAbbreviation != null ? element.featureId.unitAbbreviation : '';
            if (element.caution !== undefined && element.operational !== undefined && element.idle !== undefined) {
              object.caution = element.caution;
              object.idle = element.idle;
              object.operational = element.operational;
              object.warning = element.warning;
              object.type = "VELBAND"
            } else {
              object.type = "PHASEDIFF"
            }
            if (element.monitorId === 0) {
              fffFeatureArray.push(Object.assign({}, object));
            } else {
              featureArray.push(Object.assign({}, object));
            }
          });
        }
        // 
        this.props.setReportFeature([featureArray[0]]);
        let featureArrayForBattery = featureArray;
        let trendArr = featureArray;
        if (this.state.batteryFlag) {
          featureArrayForBattery = featureArray.filter((item) => item.unit === "")
        }
        if (this.state.dataLoggerFlag) {
          let arr = [];
          arr = featureArray.filter((item) => (!(item && item.viewValue.includes("NA"))))
          trendArr = arr.filter((item) => ((item.viewValue !== "Temperature") && (item.viewValue !== "Audio")))
        }
        this.setState({
          featureArray: this.state.dataLoggerFlag ? trendArr : featureArrayForBattery,
          instateneousArray: this.state.dataLoggerFlag ? trendArr : featureArray,
          trendArr
        })
        this.setState({
          fffFeatureArray: fffFeatureArray
        })
      }
      if (prevState.range[0] != this.state.range[0] || (prevState.range[0] == this.state.range[0] && prevState.range[1] !== undefined && prevState.range[2] !== undefined && (prevState.range[1] != this.state.range[1] || prevState.range[2] != this.state.range[2]))) {
        // 
        if (this.props.dashboardDeviceData && this.props.dashboardDeviceData.deviceData && this.props.dashboardDeviceData.deviceData.deviceData) {
          if (this.props.dashboardDeviceData && this.props.dashboardDeviceData.deviceData && this.props.dashboardDeviceData.deviceData.deviceData && this.props.dashboardDeviceData.deviceData.deviceData.id) {
            this.props.getAlarmsData(this.props.dashboardDeviceData.deviceData.deviceData.id, this.state.range[1], this.state.range[2]);
            let startDate = moment(this.state.range[1]).utc().format("YYYY-MM-DDTHH:mm:ss") + "Z";
            let endDate = moment(this.state.range[2]).utc().format("YYYY-MM-DDTHH:mm:ss") + "Z";
            // this.props.getFFTData(this.props.dashboardDeviceData.deviceData.deviceData.id, startDate, endDate);
            // this.props.getEquipmentData(this.props.dashboardDeviceData.deviceData.deviceData.id, startDate, endDate);
            this.props.getMaintenanceData(this.props.dashboardDeviceData.deviceData.deviceData.id, startDate, endDate);
          }
        }
      }


      if (prevProps.drawerData.value != this.props.drawerData.value) {
        if (this.props.drawerData.value === false) {

          this.fetchAllData();
          this.timer = setInterval(this.fetchAllData, 60000);
        } else {

          clearInterval(this.timer);
        }
      }
    } else if (this.props.dashboardType.type === "summary") {
      var currrentDeviceId = this.props.dashboardDeviceData && this.props.dashboardDeviceData.deviceData && this.props.dashboardDeviceData.deviceData.deviceData ? this.props.dashboardDeviceData.deviceData.deviceData.id : null;
      if (prevProps.dashboardType.type === "monitoring" && currrentDeviceId) {
        this.source.close();
        this.source1.close();
        this.source2.close();
      }
      if (prevProps.selectedScopeData !== this.props.selectedScopeData) {
        this.setState({ searchTexValue: '' })
        this.onRefreshSummary()
      }

      if (prevProps.selectedTab !== this.props.selectedTab && this.props.selectedTab === 5) {
        this.onRefreshSummary()
      }
      if (prevProps.dashboardType.type !== this.props.dashboardType.type && this.props.dashboardType.type === "summary") {
        this.onRefreshSummary()
      }
      if (prevProps.dashboardType.type !== this.props.dashboardType.type && this.props.dashboardType.type === "summaryPlant") {
        localStorage.setItem("selectedScopeData", this.props.selectedScopeData)
        this.onRefreshSummaryPanel();
      }

    }
    else {
      var currrentDeviceId = this.props.dashboardDeviceData && this.props.dashboardDeviceData.deviceData && this.props.dashboardDeviceData.deviceData.deviceData ? this.props.dashboardDeviceData.deviceData.deviceData.id : null;
      if (prevProps.dashboardType.type === "monitoring" && currrentDeviceId) {
        this.source.close();
        this.source1.close();
        this.source2.close();
      }
      if (prevProps.selectedScopeData !== this.props.selectedScopeData && this.props.dashboardType.type == "summary") {
        this.onRefreshSummary()
      }

      if (prevProps.selectedTab !== this.props.selectedTab && this.props.selectedTab === 5) {
        this.onRefreshSummary()
      }
      if (prevProps.dashboardType.type !== this.props.dashboardType.type && this.props.dashboardType.type === "summary") {
        this.onRefreshSummary()
      }
      if (prevProps.dashboardType.type !== this.props.dashboardType.type && this.props.dashboardType.type === "summaryPlant") {
        this.setState({ isSummaryDoneForPlant: true })
        localStorage.setItem("selectedScopeData", this.props.selectedScopeData)
        this.onRefreshSummaryPanel();
      }

    }

    if (((this.props && this.props.monitorData && this.props.monitorData.data) != (prevProps && prevProps.monitorData && prevProps.monitorData.data)) && (this.props.dashboardType.type === "monitoring") && this.props.location.state) {
      this.props.setDeviceData({ "deviceName": this.props.monitorData.data.label, "id": this.props.monitorData.data.id, "machineName": this.props.monitorData.data.machineName, "deviceIdentifier": this.props.monitorData.data && this.props.monitorData.data.devices && this.props.monitorData.data.devices.deviceIdentifier ? this.props.monitorData.data.devices.deviceIdentifier : null })
      this.props.getDeviceData(this.props.monitorData.data && this.props.monitorData.data.devices && this.props.monitorData.data.devices.deviceIdentifier ? this.props.monitorData.data.devices.deviceIdentifier : null);

    }

    let selectedScopeData = localStorage.getItem("selectedScopeData");
    if ((prevProps.dashboardDeviceData && prevProps.dashboardDeviceData.deviceData && prevProps.dashboardDeviceData.deviceData.deviceData) !== (this.props.dashboardDeviceData && this.props.dashboardDeviceData.deviceData && this.props.dashboardDeviceData.deviceData.deviceData)) {
      let machineList = [], monitorList = []; let machineGroupList = []; let areaList = [];
      if (selectedScopeData) {
        this.props.getMonitorList(parseInt(selectedScopeData), (list) => {
          list.responseTime = new Date();
          if (list.areaStatusResponseList !== null) {
            list.areaStatusResponseList.map(areaGroup=>{
              areaGroup.machineGroupStatusResponses.map((item, key) => {
              item.machineStatusReponse.map((machine, index) => {
                machine.monitorStatusResponse.map((monitor, i) => {
                  monitorList.push({
                    value: monitor.name,
                    id: machine.name,
                    monitorId: monitor.id,
                    monitorName: monitor.name,
                    deviceId: machine.id,
                    deviceIdentifier: monitor.deviceIdentifier,
                    area: areaGroup.name,
                    machineGroup: item.name,
                    machineGroupId: item.id,
                    areaId: areaGroup.id,
                    machineName: machine.name,
                    machineId: machine.id,
                  });
                })

                machineList.push({
                  name: machine.name,
                  machineId: machine.id,
                  area: areaGroup.name,
                  machineGroup: item.name,
                  machineGroupId: item.id,
                  areaId: areaGroup.id,
                });
              })
              
              machineGroupList.push({name:item.name,area: areaGroup.name});
            })
            areaList.push(areaGroup.name);
          });

            let machineName = localStorage.getItem("machineName");
            if (machineName) {
              let deviceName = localStorage.getItem("deviceName");
              let obj = monitorList.filter((d) => d.value === deviceName);
              if (obj.length !== 0) {
                this.GAEventsTracker("machine_selection", {
                  "machine_selection": machineName
                });
                if (obj.length > 1) {
                  obj = obj.filter((val) => val.id === machineName);
                }
                localStorage.setItem("setMachineName", machineName);
                localStorage.setItem("setMonitorName", obj[0].value);
                localStorage.setItem("areaName", obj[0].area);
                localStorage.setItem("machineGroupName", obj[0].machineGroup);

                this.setState(
                  {
                    areaList,
                    machineGroupList,
                    machineList,
                    selectedMachine: machineName,
                    selectedMachineId: get(obj, "[0].machineId", null),
                    selectedMonitor: obj[0].value,
                    selectedMonitorId: get(obj, "[0].monitorId", null),
                    selectedArea: obj[0].area,
                    selectedMachineGroup: obj[0].machineGroup,
                    selectedMachineGroupId: get(obj, "[0].machineGroupId", null),
                    selectedAreaId: get(obj, "[0].areaId", null),
                    monitorList,
                    selectedMonitorGroup: obj[0],
                  },
                  () => {
                    this.getMachineGroups();
                    this.getMachineName();
                    this.getMonitorName();
                  }
                );
              } else {
                localStorage.setItem("setMachineName", machineList[0].name);
                localStorage.setItem("setMonitorName", monitorList[0].value);
                localStorage.setItem("areaName", monitorList[0].area);
                localStorage.setItem(
                  "machineGroupName",
                  monitorList[0].machineGroup
                );

                localStorage.setItem("deviceName", monitorList[0].value);
                localStorage.setItem("deviceId", monitorList[0].monitorId);
                localStorage.setItem("machineName", monitorList[0].id);
                localStorage.setItem(
                  "deviceIdentifier",
                  monitorList[0].deviceIdentifier
                );

                this.props.setDeviceData({
                  deviceName: monitorList[0].value,
                  id: monitorList[0].monitorId,
                  machineName: monitorList[0].id,
                  deviceIdentifier: monitorList[0].deviceIdentifier,
                });
                
                this.setState(
                  {
                    areaList,
                    machineGroupList,
                    machineList,
                    monitorList,
                    selectedMachine: machineList[0].name,
                    selectedMachineId: get(machineList, "[0].machineId", null),
                    selectedMonitor: monitorList[0].value,
                    selectedMonitorId: get(monitorList, "[0].monitorId", null),
                    selectedArea: monitorList[0].area,
                    selectedMachineGroup: monitorList[0].machineGroup,
                    selectedMachineGroupId: get(monitorList, "[0].machineGroupId", null),
                    selectedAreaId: get(monitorList, "[0].areaId", null),
                    selectedMonitorGroup: monitorList[0],
                  },
                  () => {
                    this.getMachineGroups();
                    this.getMachineName();
                    this.getMonitorName();
                  }
                );
              }
            } else {
              this.setState({
                areaList,
                machineGroupList,
                machineList,
                selectedMachine: machineList[0],
                selectedMachineId: get(machineList, "[0].machineId", null),
                selectedMonitor: monitorList[0].value,
                selectedMonitorId: get(monitorList, "[0].monitorId", null),
                selectedArea: monitorList[0].area,
                selectedMachineGroup: monitorList[0].machineGroup,
                selectedMachineGroupId: get(monitorList, "[0].machineGroupId", null),
                selectedAreaId: get(monitorList, "[0].areaId", null),
                monitorList,
                selectedMonitorGroup: monitorList[0],
              });
            }
          }
        })
      }
      let from = moment(this.state.range[1]).utc().format("YYYY-MM-DDTHH:mm:ss") + "Z";
      let to = moment(this.state.range[2]).utc().format("YYYY-MM-DDTHH:mm:ss") + "Z";
      this.props.dashboardDeviceData.deviceData.deviceData && this.props.getAlarmsData(this.props.dashboardDeviceData.deviceData.deviceData.id, from, to);
    }
    if ((prevProps.dashboardType.type === "summary") && (this.props.dashboardType.type !== "summary")) {
      this.setState({ searchTexValue: "" });
      let setMachinename = localStorage.getItem("setMachineName");
      let setMonitorName = localStorage.getItem("setMonitorName");
      if (setMachinename && setMachinename !== "null" || setMonitorName && setMonitorName !== "null") {
        this.GAEventsTracker("machine_selection", {
          "machine_selection": setMachinename,
        });
      }

    }
  }

  fetchAllData = () => {
    let currentDateTime = new Date();
    let range = [];
    range.push(this.state.range[0]);
    range.push(this.state.range[1]);
    range.push(currentDateTime.toISOString());
    /* if (this.state.range[0] === 'today' || this.state.range[0] === 'thisMonth') {
       this.setState({
         range: range
       })
     }*/
  }

  fetchRealtimeData = () => {
    const accessToken = getOidcAccessToken();
    if (this.props.dashboardDeviceData && this.props.dashboardDeviceData.deviceData && this.props.dashboardDeviceData.deviceData.deviceData) {
      this.source = new EventSourcePolyfill(`${realtime_server_url}/motstd/${this.props.dashboardDeviceData.deviceData.deviceData.id}`, {
        headers: {
          "Authorization": `Bearer ${accessToken}`
        }
      });

      this.source.addEventListener('message', message => {
        let resp = JSON.parse(message.data);
        // 
        if (resp.hasOwnProperty('data')) {
          let data = { ...JSON.parse(resp.data), _time: resp.time }
          this.setState({
            realTimeData: data
          })
        }
      })
      this.source1 = new EventSourcePolyfill(`${realtime_server_url}/spectral/${this.props.dashboardDeviceData.deviceData.deviceData.id}`,
        {
          headers: {
            "Authorization": `Bearer ${accessToken}`
          }
        });
      this.source1.addEventListener('message', message => {
        let resp = JSON.parse(message.data);
        if (resp.hasOwnProperty('data')) {
          let data = { ...resp.data, _time: resp.time }
          this.setState({
            fingerprintRealTimeData: data
          })
        }
      })
    }
  }


  fetchDiagnosticsData = () => {
    if (this.props.dashboardDeviceData && this.props.dashboardDeviceData.deviceData && this.props.dashboardDeviceData.deviceData.deviceData) {

      this.source2 = new EventSourcePolyfill(`${realtime_server_url}/diagnostics/${this.props.dashboardDeviceData.deviceData.deviceData.id}`, {
        headers: {
          "Authorization": `Bearer ${getOidcAccessToken()}`
        }
      });
      this.source2.addEventListener('message', message => {
        let resp = JSON.parse(message.data);
        // 
        this.setState({
          diagnosticData: resp
        })

      })
    }
  }

  rangeSelector(e, data, selectDate, selectedTime) {
    const range = computeRange(data, selectDate, selectedTime);
    this.setState({
      range: range
    })
  }

  onRefreshSummary = () => {
    this.setState({ isSummaryDone: true })
    if (this.props.selectedScopeData === undefined) {
      let selectedScopeData = localStorage.getItem("selectedScopeData")
      if (selectedScopeData != null && selectedScopeData != undefined) {
        this.props.getSummaryData(selectedScopeData)


      }
    } else {
      localStorage.setItem("selectedScopeData", this.props.selectedScopeData)
      this.props.getSummaryData(this.props.selectedScopeData)


    }
  }
  onRefreshSummaryPanel = () => {
    this.setState({ isSummaryDoneForPlant: true })
    this.props.getSummaryPlantData(this.state.summaryPlantPagination);
    this.props.getSummaryPlantHealthScore()
  }

  onRefreshMaintainace = () => {
    let currentDateTime = new Date();

    currentDateTime.setHours(currentDateTime.getHours());
    currentDateTime.setMinutes(currentDateTime.getMinutes());
    currentDateTime.setSeconds(0);
    currentDateTime.setMilliseconds(0);
    let startDate = moment(this.state.range[1]).utc().format("YYYY-MM-DDTHH:mm:ss") + "Z";
    let endDate = moment(currentDateTime).utc().format("YYYY-MM-DDTHH:mm:ss") + "Z";
    if (this.props.dashboardDeviceData && this.props.dashboardDeviceData.deviceData && this.props.dashboardDeviceData.deviceData.deviceData && this.props.dashboardDeviceData.deviceData.deviceData.id) {
      this.props.getMaintenanceData(this.props.dashboardDeviceData.deviceData.deviceData.id, startDate, endDate);
    }

  }

  onEquipmentDone = () => {

    this.setState({ isEquipmentDone: false });
  }
  onSummaryDone = () => {
    this.setState({ isSummaryDone: false })
    if (this.props.summaryData && this.props.summaryData.summaryData && this.props.summaryData.summaryData.averagePlantHealthScoreDetails !== undefined) {
      this.setState({ isSummaryDoneForPlant: false })

    }
  }
  onMaintainanceDone = () => {
    this.setState({ isMaintainanceDone: false });
  }
  closeLoading = () => {
    this.props.setLoaderState({ value: false })
  }

  goFull = () => {
    this.setState({ isFull: true });
  }

  loadMonitoringDashboard = (monitor) => {
    localStorage.setItem("dashboard", "monitoring");

    this.props.setDeviceData({ "deviceName": monitor.monitorName, "id": monitor.monitorId, "machineName": monitor.machineName, "deviceIdentifier": monitor.deviceIdentifier })
    this.props.updatedashboardType("monitoring");
  }
  searchText = (name, searchTexValue) => {
    this.actionList(name)
    this.setState({ searchTexValue })
  }
  actionList = (name) => {
    this.setState({ searchTexValue: '' })
    this.props.updatedashboardType("summary");
    let plantView = localStorage.getItem("PlantView")
    let plantViewArray = JSON.parse(plantView);
    let summaryViewArray, scopeSeletorPlantsData = [];
    if (plantViewArray !== null) {
      summaryViewArray = plantViewArray.filter(function (e) { return e.name === name });
    } else {
      this.props.userData.scopeSelector.subOrganizations.map((organization) => {
        if (organization.plants.length > 0) {
          organization.plants.map((plant) => {
            if (plant.permissions !== null) {
              scopeSeletorPlantsData.push({
                id: plant.id,
                name: plant.name,
                permission: plant.permissions
              })
            }
          })
        }
      })
      summaryViewArray = scopeSeletorPlantsData.filter(function (e) { return e.name === name });


    }
    this.props.updateScopeSelector(summaryViewArray[0].id, 0, summaryViewArray[0].permission, "Monitoring", summaryViewArray[0].name);
    this.props.updatedashboardType("summary");
    this.props.getSummaryData(summaryViewArray[0].id);
    localStorage.setItem("selectedScopeData", summaryViewArray[0].id);
    localStorage.setItem("plantValue", summaryViewArray[0].id);
    localStorage.setItem("plantPermisssion", summaryViewArray[0].permission);
    localStorage.setItem("plantName", summaryViewArray[0].name)
  }
  loadSummaryDashboard = (monitor) => {
    this.props.updatedashboardType("summary");
  }

  handleHidePopup = () => {
    this.setState({ isShowPopup: false })
  }

  addFeature = (feature) => {
    let featureArray = [...this.state.featureArray]
    let trendArr = [...this.state.trendArr]
    featureArray.unshift(feature)
    trendArr.unshift(feature)
    this.setState({
      featureArray: featureArray, trendArr: trendArr
    });
  }

  removeFeature = (feature) => {
    let newtrendArr = this.state.trendArr.filter(function (e) { return e.value !== feature.value; });
    let newFeatureArray = this.state.featureArray.filter(function (e) { return e.value !== feature.value; });
    this.setState({
      featureArray: newFeatureArray,
      trendArr: newtrendArr
    });
  }
  scrollView = (ref) => {
    window.scrollTo(0, ref.current.offsetTop)
  }
  updateSelectedTab = (value) => {
    document.cookie = `idap-lang-${config.url.env}=null` + "; max-age=0; domain=infinite-uptime.com; secure";
    let scope = localStorage.getItem("selectedScopeData")
    window.open(`${config.url.admin_service_url}/login` + "?device=" + value + "&scope=" + scope + "&lang=" + this.props.i18n.language);
  }

  getMachineName = () => {
    const scopeData = [];
    this.state.machineList.map((data) => {
      if(this.state.selectedMachineGroup === data.machineGroup)
      {
        scopeData.push(
          <option key={data.name} value={data.name}>{data.name}</option>
        );
      }
    })
    return scopeData;
  }
  getAreaName = () => {
    const scopeData = [];
    this.state.areaList.forEach((data) => {
      scopeData.push(
        <option key={data} value={data}>{data}</option>
      );
    })
    return scopeData;
  }
  getMachineGroups = () => {
    const scopeData = [];
    this.state.machineGroupList.forEach((data) => {
      if(this.state.selectedArea === data.area){
      scopeData.push(
        <option key={data.name} value={data.name}>{data.name}</option>
      );
      }
    })
    return scopeData;
  }
  getMonitorName = () => {
    const { t } = this.props;

    const scopeData = [];
    this.state.monitorList.map((data) => {
      if (this.state.selectedMachine === data.id) {
        scopeData.push(
          <option key={data.monitorId} value={data.value} >{data.value}</option>
        );
      }
    })
    return scopeData;
  }
  changeMachineName = (e) => {
    this.setState({ selectedMachine: e.target.value })
    this.getMonitorName();
    let monitorArr = [];
    this.state.monitorList.map((data) => {
      if (e.target.value === data.id) {
        monitorArr.push(data);
      }
    })
    this.setState({
      selectedMonitorGroup: monitorArr[0],
      selectedMonitor: monitorArr[0].value,
      selectedMonitorId: get(monitorArr, "[0].monitorId", null),
      selectedMachineId: get(monitorArr, "[0].machineId", null),
      selectedAreaId: get(monitorArr, "[0].areaId", null),
    });

    localStorage.setItem("deviceName", monitorArr[0].value);
    localStorage.setItem("deviceId", monitorArr[0].monitorId)
    localStorage.setItem("machineName", monitorArr[0].id)
    localStorage.setItem("deviceIdentifier", monitorArr[0].deviceIdentifier)
    this.props.setDeviceData({ "deviceName": monitorArr[0].value, "id": monitorArr[0].monitorId, "machineName": monitorArr[0].id, "deviceIdentifier": monitorArr[0].deviceIdentifier })


  }

  changeAreaName = (e) => {
    this.setState({ selectedArea: e.target.value })
    this.getMonitorName();
    this.getMachineName();
    this.getMachineGroups();
    let monitorArr = [];
    this.state.monitorList.map((data) => {
      if (e.target.value === data.area) {
        monitorArr.push(data);
      }
    })

    this.setState({
      selectedMonitorGroup: monitorArr[0],
      selectedMonitor: monitorArr[0].value,
      selectedMonitorId: get(monitorArr, "[0].monitorId", null),
      selectedMachineGroup: monitorArr[0].machineGroup,
      selectedMachineGroupId: get(monitorArr, "[0].machineGroupId", null),
      selectedAreaId: get(monitorArr, "[0].areaId", null),
    });
    
    localStorage.setItem("deviceName", monitorArr[0].value);
    localStorage.setItem("deviceId", monitorArr[0].monitorId)
    localStorage.setItem("machineName", monitorArr[0].id)
    localStorage.setItem("areaName", monitorArr[0].area)
    localStorage.setItem("machineGroupName", monitorArr[0].machineGroup)
    localStorage.setItem("deviceIdentifier", monitorArr[0].deviceIdentifier)
    this.props.setDeviceData({ "deviceName": monitorArr[0].value, "id": monitorArr[0].monitorId, "machineName": monitorArr[0].id, "deviceIdentifier": monitorArr[0].deviceIdentifier })


  }

  changeMachineGroupName = (e) => {
    this.setState({ selectedMachineGroup: e.target.value })
    this.getMonitorName();
    this.getMachineName();
    let monitorArr = [];
    this.state.monitorList.map((data) => {
      if (e.target.value === data.machineGroup) {
        monitorArr.push(data);
      }
    })

    this.setState({
      selectedMonitorGroup: monitorArr[0],
      selectedMachineGroupId: get(monitorArr, "[0].machineGroupId", null),
      selectedMonitor: monitorArr[0].value,
      selectedMonitorId: get(monitorArr, "[0].monitorId", null),
      selectedMachine: monitorArr[0].id,
      selectedMachineId: get(monitorArr, "[0].machineId", null),
      selectedAreaId: get(monitorArr, "[0].areaId", null),
    });
    
    localStorage.setItem("deviceName", monitorArr[0].value);
    localStorage.setItem("deviceId", monitorArr[0].monitorId)
    localStorage.setItem("machineName", monitorArr[0].id)
    localStorage.setItem("machineGroupName",monitorArr[0].machineGroup);
    localStorage.setItem("deviceIdentifier", monitorArr[0].deviceIdentifier)
    this.props.setDeviceData({ "deviceName": monitorArr[0].value, "id": monitorArr[0].monitorId, "machineName": monitorArr[0].id, "deviceIdentifier": monitorArr[0].deviceIdentifier })

  }

  changeMonitorName = (e) => {
    let obj = this.state.monitorList.filter(d => ((d.value === e.target.value) && (d.id === this.state.selectedMachine)))
    this.setState({
      selectedMonitorGroup: obj[0],
      selectedMonitor: obj[0].value,
      selectedMonitorId: get(obj, "[0].monitorId", null),
      selectedAreaId: get(obj, "[0].areaId", null),
    });
    localStorage.setItem("deviceName", e.target.value);
    localStorage.setItem("deviceId", obj[0].monitorId)
    localStorage.setItem("machineName", obj[0].id)
    localStorage.setItem("deviceIdentifier", obj[0].deviceIdentifier)
    this.props.setDeviceData({ "deviceName": e.target.value, "id": obj[0].monitorId, "machineName": obj[0].id, "deviceIdentifier": obj[0].deviceIdentifier })

  }

  handleSearchChange = (searchText) => {
    this.debouncedSearchChange(searchText);
  }

  debouncedSearchChange = debounce((searchText) => {
      const { summaryPlantPagination} = this.state;
      const trimmedSearchText = searchText ? searchText.trim() : "";
      const isSearchTextUnchanged = isEqual(searchText, summaryPlantPagination.keyword);
      const newPaginationParams = isSearchTextUnchanged
        ? summaryPlantPagination
        : { ...summaryPlantPagination,keyword: trimmedSearchText, pageNumber: 0, total: 0 };

      this.setState({
        searchParam: trimmedSearchText,
        summaryPlantPagination: newPaginationParams,
      });

  }, 800);

  handlePageChange = (page) => {
      this.setState({
        summaryPlantPagination: {
          ...this.state.summaryPlantPagination,
          pageNumber: page,
        },
      });
  }

  handleRowsPerPageChange(newRowsPerPage) {
      this.setState({
        summaryPlantPagination: {
          ...this.state.summaryPlantPagination,
          pageSize: newRowsPerPage,
          pageNumber: 0
        },
      });
  }

  handleSort = (sortColumn, sortDirection) => {
      if (sortColumn && sortDirection) {
        this.setState({
          summaryPlantPagination: {
            ...this.state.summaryPlantPagination,
            orderBy: {
              field: sortColumn,
              direction: sortOrder[sortDirection],
            },
        },
        });
      }
  }

  handleTableChange = (action, tableState) => {
    window._tableState = tableState;
    if (action === "changePage") {
      const page = tableState.page;
      this.handlePageChange(page);
    } 
    else if (action === "changeRowsPerPage") {
      const newRowsPerPage = tableState.rowsPerPage;
      tableState.page = 0;
      this.handleRowsPerPageChange(newRowsPerPage);
    } else if (action === "sort") {
      const sortColumn =
        tableState.columns[tableState.activeColumn].name;
      const sortDirection = tableState.announceText.includes(
        "ascending"
      )
        ? "ascending"
        : "descending";
      this.handleSort(sortColumn, sortDirection);
    } 
    else if (action === "search") {
      this.handleSearchChange(tableState.searchText);
      tableState.page = 0;
    }
  }

  handleHealthScoreDropdownChange = (event) => {
    const val = event.target.value;

    if(val !== "All"){
      var num = val.match(/\d+/g)[0] || "50"; // Extract numeric values using regular expression
      var operator = val.match(/[^\d]+/g)[0] || "<="; // Extract non-numeric values using regular expression
    }

    window._tableState.page = 0
    this.setState({
      selectedHealthScoreVal: event.target.value,
      summaryPlantPagination: {
        ...this.state.summaryPlantPagination,
        pageNumber: 0,
        healthScoreFilter:
          val === "All"
            ? {}
            : {
                operator: operatorMap[operator],
                value: parseInt(num),
              },
      },
    });
  }

  render() {
    const { t } = this.props;
    const DialogTitle = withStyles(styles)((props) => {
    const { children, classes, onClose, ...other } = props;
      
      return (
        <MuiDialogTitle disableTypography className={classes.root} {...other}>
          <Typography variant="h6">{children}</Typography>
          {onClose ? (
            <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
              <CloseIcon />
            </IconButton>
          ) : null}
        </MuiDialogTitle>
      );
    });

    if (this.props.dashboardType.type === "monitoring") {

      if (this.props && this.props.dashboardDeviceData && this.props.dashboardDeviceData.deviceData && this.props.dashboardDeviceData.deviceData.deviceData && this.props.dashboardDeviceData.deviceData.deviceData.hasOwnProperty('id')) {
        return (
          <div>
            {
              (this.props.dashboardType.type !== undefined && this.props.dashboardType.type !== "summary" && (this.state.isEquipmentDone || this.state.isMaintainanceDone || this.state.isGetFeatureDone)) ?
                (

                  <div className={this.props.classes.loading}>
                    <CircularProgress classes={{ colorPrimary: this.props.classes.loaderColor }} variant="indeterminate" />
                  </div>

                ) : null

            }
            <Dialog
              open={this.state.isShowPopup}
              onClose={this.handleHidePopup}
              maxWidth={'md'}
              aria-labelledby="alert-dialog-title"
              aria-describedby="alert-dialog-description"
            >
              <DialogTitle id="customized-dialog-title" onClose={this.handleHidePopup}>

              </DialogTitle>
              {/* <Divider></Divider> */}

              <DialogContent className={this.props.classes.content} style={{ marginTop: "15px" }}>
                <span style={{ fontWeight: "500" }}>
                  {t("Subscription for this device to get over in") + " " + this.state.subscriptionRemain + " " + t("Days") + "."}
                  <br /><br />
                  {t("Contact Us For Renewal @")} <span style={{ color: "#1665c0" }}>{support_email}</span>
                </span>
              </DialogContent>
              <DialogActions>
                <Button onClick={(event) => this.loadSummaryDashboard()} color="primary" autoFocus>
                  {t('Click To See List')}
                </Button>
              </DialogActions>
            </Dialog>

            <div className={this.state.isFull ? 'text-scroll' : null}>
              {/* <Fullscreen enabled={this.state.isFull} onChange={isFull => this.setState({isFull})}>     */}
              <div className="full-screenable-node">
                <DashNavs props={this.props} deviceId={this.props.dashboardDeviceData.deviceData.deviceData.id} plantName={this.props.plantName} widgetsData={this.props.widgetsData} range={this.state.range} rangeSelect={this.rangeSelector} getAreaName={this.getAreaName} changeAreaName={this.changeAreaName} selectedArea={this.state.selectedArea} labelWidth={this.state.labelWidth} goFull={this.goFull} className="DashNavs" />
                <div style={{ display: "flex" }}>
                <div className={this.props.classes.inputFieldDiv}>
                    <InputLabel className={this.props.classes.textFieldLabel}>
                      {this.props.t("Equipment")}
                    </InputLabel>
                    <Select
                      native
                      value={this.state.selectedMachineGroup}
                      onChange={(event) => this.changeMachineGroupName(event)}
                      input={
                        <OutlinedInput
                          variant="outlined"
                          name="machineGroupName"
                          id="language-native-simple"
                          labelWidth={this.state.labelWidth}
                          className={this.props.classes.textField}
                          classes={{ input: this.props.classes.outlinedInput }}
                        />
                      }
                    >
                      {this.getMachineGroups()}
                    </Select>
                  </div>

                  <div className={this.props.classes.inputFieldDiv}>
                    <InputLabel className={this.props.classes.textFieldLabel}>
                      {this.props.t("Asset")}
                    </InputLabel>
                    <Select
                      native
                      value={this.state.selectedMachine}
                      onChange={(event) => this.changeMachineName(event)}
                      input={
                        <OutlinedInput
                          variant="outlined"
                          name="monitorTypeId"
                          id="language-native-simple"
                          labelWidth={this.state.labelWidth}
                          className={this.props.classes.textField}
                          classes={{ input: this.props.classes.outlinedInput }}
                        />
                      }
                    >
                      {this.getMachineName()}
                    </Select>
                  </div>

                  <div className={this.props.classes.inputFieldDiv}>
                    <InputLabel className={this.props.classes.textFieldLabel}>
                      {this.props.t("Measurement Location")}
                    </InputLabel>
                    <Select
                      native
                      value={this.state.selectedMonitor}
                      onChange={(event) => this.changeMonitorName(event)}
                      input={
                        <OutlinedInput
                          variant="outlined"
                          name="monitorTypeId"
                          id="language-native-simple"
                          labelWidth={this.state.labelWidth}
                          className={this.props.classes.textField}
                          classes={{ input: this.props.classes.outlinedInput }}
                        />
                      }
                    >
                      {this.getMonitorName()}
                    </Select>
                  </div>
                </div>
                {(this.state.batteryFlag === false && this.state.dataLoggerFlag === false) && <DefaultView props={this.props} state={this.state} onEquipmentDone={this.onEquipmentDone}
                  addFeature={this.addFeature} removeFeature={this.removeFeature} updateSelectedTab={this.updateSelectedTab}
                  onMaintainanceDone={this.onMaintainanceDone} onRefreshMaintainace={this.onRefreshMaintainace}
                  scrollView={this.scrollView} el1={this.el1} el2={this.el2}/>
                }
                {this.state.dataLoggerFlag && <DataLoggerView props={this.props} state={this.state} onEquipmentDone={this.onEquipmentDone}
                  addFeature={this.addFeature} removeFeature={this.removeFeature} updateSelectedTab={this.updateSelectedTab} el1={this.el1} el2={this.el2}
                  onMaintainanceDone={this.onMaintainanceDone} onRefreshMaintainace={this.onRefreshMaintainace} scrollView={this.scrollView} />
                }
                {this.state.batteryFlag && <BatteryView props={this.props} state={this.state} onEquipmentDone={this.onEquipmentDone}
                  addFeature={this.addFeature} removeFeature={this.removeFeature} updateSelectedTab={this.updateSelectedTab} el1={this.el1} el2={this.el2}
                  onMaintainanceDone={this.onMaintainanceDone} onRefreshMaintainace={this.onRefreshMaintainace} scrollView={this.scrollView}  />
                }
              </div>

              {/* </Fullscreen>    */}
            </div>
          </div>
        )
      } else {
        let plantView = localStorage.getItem("PlantView")
        let plantViewArray = JSON.parse(plantView);
        if ((this.props && this.props.selectedScopeData === undefined) && (plantViewArray === null)) {
          return (<div><div style={{ textAlign: "center", padding: "13px", boxShadow: "0 3px 5px rgb(0 0 0 / 15%)" }}>{this.props.t("Data is not available to display here, as you don't have assigned any plant.")}</div></div>);
        }
        else { return (<div><div style={{ textAlign: "center", padding: "13px", boxShadow: "0 3px 5px rgb(0 0 0 / 15%)" }}>{this.props.t("Please Add a Monitoring location/s to display data here.")}</div></div>); }
      }
    } else if (this.props.dashboardType.type === "summary") {
      return (
        <div>
          {/* {
            (this.props.dashboardType.type === "summary" && this.state.isSummaryDone) ?
              (
                <div className={this.props.classes.summaryLoading}>
                  <CircularProgress classes={{ colorPrimary: this.props.classes.loaderColor }} variant="indeterminate" />
                </div>

              ) : null

          } */}

          <div className="full-screenable-node">
            <SummaryPanel searchText={this.state.searchTexValue} props={this.props} t={this.props.t} summaryData={this.props.summaryData} selectedScopeData={this.props.selectedScopeData} loadMonitoringDashboard={this.loadMonitoringDashboard} onRefreshSummary={this.onRefreshSummary} onSummaryDone={this.onSummaryDone} />
            {/* <InternetSpeedPanel /> */}
          </div>
          {/* {(this.state.noFlag && !this.state.isSummaryDone) ? null :(<div style={{backgroundColor:'#fff',textAlign:"center",padding:"13px"}}>{this.props.t("Data is not available to display here, as you don't have assigned any plant.")}</div>)} */}
        </div>)
    } else {
      return (
        <div>
          {this.props.dashboardType.type === "summaryPlant" &&
          !this.state.isSummaryDoneForPlant ? (
            <div className={this.props.classes.summaryLoading}>
              <CircularProgress
                classes={{ colorPrimary: this.props.classes.loaderColor }}
                variant="indeterminate"
              />
            </div>
          ) : null}

          {this.state.emptyFlag ? (
            <div className="full-screenable-node">
              <div style={{ textAlign: "center", padding: "13px" }}>
                {this.props.t(
                  "Please Add a Monitoring location/s to display data here."
                )}
              </div>
            </div>
          ) : (
            <div className="full-screenable-node">
              <SummaryPlantPanel
                props={this.props}
                t={this.props.t}
                summaryData={this.props.summaryData}
                selectedScopeData={this.props.selectedScopeData}
                loadMonitoringDashboard={this.loadMonitoringDashboard}
                onRefreshSummaryPanel={this.onRefreshSummaryPanel}
                onSummaryDone={this.onSummaryDone}
                actionList={this.actionList}
                searchText={this.searchText}
                summaryPlantPagination={this.state.summaryPlantPagination}
                healthScoreData = {this.props.summaryHealthScore && this.props.summaryHealthScore.healthData && this.props.summaryHealthScore.healthData.data ? this.props.summaryHealthScore.healthData.data : {}}
                onTableChange = {this.handleTableChange}
                inputChangeHandler = {this.handleHealthScoreDropdownChange}
                selectedHealthScoreVal = {this.state.selectedHealthScoreVal}
              />
              {/* <InternetSpeedPanel /> */}
            </div>
          )}
        </div>
      );

    }

  }

}
const mapSatateToProps = state => {
  return {
    alarmsData: state.alarmReportDataReducer,
    maintenanceData: state.maintenanceDataReducer,
    featureListData: state.featureDataReducer,
    userData: state.userDataReducer,
    drawerData: state.drawerReducer,
    monitorData: state.getMonitorReducer,
    dashboardType: state.dashboardTypeReducer,
    summaryData: state.summaryDataReducer,
    summaryHealthScore: state.summaryPlantHealthScoreReducer,
    deviceData: state.deviceDataforDashboardReducer,
    loaderState: state.loaderReducer,
    config: state.viewDefinitionReducer.config,
    selectedApplicationPlantId: state.applicationReducer.selectedPlantId,
  }
}

const mapDispatchToProps = dispatch => {
  return {
    setLoaderState: (value) => dispatch(actionCreators.setLoaderState(value)),
    setDeviceData: (deviceData) =>
      dispatch(actionCreators.setDeviceData(deviceData)),
    updatedashboardType: (type) =>
      dispatch(actionCreators.updatedashboardType(type)),
    getAlarmsData: (deviceId, from, to) =>
      dispatch(actionCreators.getAlarmsReportData(deviceId, from, to)),
    getFFTData: (deviceId, from, to) =>
      dispatch(actionCreators.getFFTData(deviceId, from, to)),
    getEquipmentData: (deviceId, from, to) =>
      dispatch(actionCreators.getEquipmentReportData(deviceId, from, to)),
    getMaintenanceData: (deviceId, from, to) =>
      dispatch(actionCreators.getMaintenanceData(deviceId, from, to)),
    getFeatureListData: (deviceId) =>
      dispatch(actionCreators.getFeatureListData(deviceId)),
    getExternalFeatures: (
      plantId,
      areaId,
      equipmentId,
      assetId,
      measurementLocationId
    ) =>
      dispatch(
        actionCreators.getExternalFeatures(
          plantId,
          areaId,
          equipmentId,
          assetId,
          measurementLocationId
        )
      ),
    setReportFeature: (value) =>
      dispatch(actionCreators.setReportFeature(value)),
    getSummaryPlantData: (requestBody) =>
      dispatch(actionCreators.getSummaryPlantData(requestBody)),
    getSummaryPlantHealthScore: () =>
      dispatch(actionCreators.getSummaryPlantHealthScore()),
    getSummaryData: (plantId) =>
      dispatch(actionCreators.getSummaryData(plantId)),
    getMonitorByIdForDashboard: (id) =>
      dispatch(actionCreators.getMonitorByIdForDashboard(id)),
    getDeviceData: (deviceIdentifier) =>
      dispatch(actionCreators.getDeviceData(deviceIdentifier)),
    updateScopeSelector: (selection, tab, permission, scope, plantName) =>
      dispatch(
        actionCreators.updateScopeSelector(
          selection,
          tab,
          permission,
          scope,
          plantName
        )
      ),
    updateSelectedTab: (selectedTab) =>
      dispatch(actionCreators.updateSelectedTab(selectedTab)),
    getMonitorList: (plantId, cb) =>
      dispatch(actionCreators.getMonitorList(plantId, cb)),
    setApplicationSelectedPlantId: (plantId) =>
      dispatch(actionCreators.setApplicationSelectedPlantId(plantId)),
  };
                              }
export default withRouter(withTranslation()(connect(mapSatateToProps, mapDispatchToProps)(withStyles(styles)(MonitoringDashboard))));

//export default connect(mapSatateToProps, mapDispatchToProps)(MonitoringDashboard);