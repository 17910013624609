import * as actionTypes from '../actions/actionTypes';

export const bearingDataReducer = (state = {}, action) => {
    switch (action.type) {

        case actionTypes.SAVE_BEARING_DATA: {
            return Object.assign({}, state, action.payload)
        }

        default: return state
    }
}