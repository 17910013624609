import * as actionTypes from '../actions/actionTypes';

export const fftSelectedDataReducer = (state={time: ''}, action) => {
    switch(action.type) {

        case actionTypes.SET_SELECTED_FFT_DATA:
        return Object.assign({}, state, {time: action.payload});

        default: return state
    }
}