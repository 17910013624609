import React, { Component } from 'react'
import { Chart } from "react-google-charts";
import { withTranslation } from "react-i18next";

class AvailabilityGraph extends Component {
  constructor(props) {
    super(props);    
  }

  render() {
    const {t}=this.props;
    return (
      <div className="chart-body">

        <Chart
          width={'100%'}
          height={'92px'}
          chartType="BarChart"
          loader={<div>{t("Loading Chart")}</div>}
          data={this.props.data}
          options={{
            height: 92,
            legend: 'none',
            seriesType: 'bars',
            bar: { groupWidth: '75%' },
            explorer: {
              actions: ['dragToZoom', 'rightClickToReset'],
              maxZoomOut: 1,
              maxZoomIn: 32,
              keepInBounds: true
            },
            isStacked: true,
            hAxis: { ticks: this.props.ticksList}
          }}

          // chartEvents={[
          //   {
          //     eventName: 'select',
          //     callback: ({ chartWrapper }) => {
          //       const chart = chartWrapper.getChart()
          //       const selection = chart.getSelection()
          //       if (selection.length === 1) {
          //         const [selectedItem] = selection
          //         const dataTable = chartWrapper.getDataTable()
          //         const { row, column } = selectedItem
          //         console.log(dataTable.getValue(row, column))
          //         alert(
          //           'You selected : ' +
          //           JSON.stringify({
          //             row,
          //             column,
          //             value: dataTable.getValue(row, column),
          //           }),
          //           null,
          //           2,
          //         )
          //       }
          //       console.log(selection)
          //     },
          //   },
          // ]}
          // For tests
          rootProps={{ 'data-testid': '1' }}
        />
      </div>
    )
  }
}


export default withTranslation()(AvailabilityGraph);