import React, { Component } from 'react';
import styled from 'styled-components'
import { useTable, useExpanded , useTableState} from 'react-table'
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import Button from '@material-ui/core/Button';
import Picky from "react-picky";
import { connect } from 'react-redux';
import Modal from 'react-responsive-modal';
import Dropdown from 'muicss/lib/react/dropdown';
import DropdownItem from 'muicss/lib/react/dropdown-item';
import moment from "moment";
import { withTranslation } from "react-i18next";

import * as actionCreators from '../../../actions/actionCreators';

const Styles = styled.div`
height: 350px;
overflow-y: scroll;

  table {
    border-spacing: 0;
    border: 0px solid black;
    width: 150%;
    
    thead{
        tr{
            background-image: linear-gradient(white, #e1e3e6);
        }
    }

    tbody{       
        tr:nth-child(even) {background: #e1e3e8}
        tr:nth-child(odd) {background: #FFF}
        overflow-y : scroll;
    }

    th,
    td {
      margin: 0;
      padding: 0.5rem;
      border : none
      color: #333;

      
    }
    th {
        padding: 0.3rem;
        font-size: 18.25px;
        font-weight: 100;
        text-transform: uppercase;
        border-right: 3px solid white;
    }
    th:nth-child(2){
        border: none;
    }
  }
`


function Table({ columns: userColumns, data, handleClick, handleAlarmClick, sortByAlarmCount, sortByColumnName, sortByMachineName  }) {
    const state = useTableState({ expanded: {} });

    const {
        getTableProps,
        headerGroups,
        rows,
        prepareRow,
        // state: [{ expanded }],
    } = useTable(
        {
            columns: userColumns,
            data,
            state
        },
        useExpanded
    )
    state[0].expanded = {};
    return (
        <>
            <table {...getTableProps()}>
                <thead>
                    {headerGroups.map((headerGroup,index) => (
                        <tr key={index} {...headerGroup.getHeaderGroupProps()}>
                            {headerGroup.headers.map(column => {
                                if (column.Header === 'PART COUNT'|| column.Header === 'Parts/Hour' || column.Header === 'OEE' || column.Header === 'Strokes/min' || column.Header === 'CYCLE TIME') {
                                    return (
                                        <th key={column.Header} {...column.getHeaderProps()} onClick={() => handleClick(column)}>{column.render('Header')}
                                             <span {...column.getHeaderProps()} onClick={(e) => sortByColumnName(e,column)}><br></br><span className="sortImage"></span> </span>
                                        </th>
                                    )
                                } else if (column.Header === 'ALARMS') {
                                    return (
                                        <th key={column.Header}  {...column.getHeaderProps()} onClick={() => sortByAlarmCount()}>{column.render('Header')}
                                            <br></br><span className="sortImage"></span>
                                        </th>
                                    )
                                }
                                else if (column.Header === 'MACHINE') {
                                    return (
                                        <th key={column.Header}  {...column.getHeaderProps()} onClick={() => sortByMachineName()}>{column.render('Header')}
                                        <br></br><span className="sortImage"></span>
                                        </th>
                                    )
                                }
                                else {
                                    return (
                                        <th key={column.Header}  {...column.getHeaderProps()} onClick={(e) => sortByColumnName(e,column)} >{column.render('Header')} 
                                        {column.id !== 'expander' ? <><br></br><span className="sortImage"></span></> : null}
                                        </th>
                                    )
                                }
                            })}
                        </tr>
                    ))}
                </thead>
                <tbody>
                    {rows.map(
                        (row, i) =>
                            prepareRow(row) || (
                                <tr key={i} {...row.getRowProps()}>
                                    {row && row.cells.map(cell => {
                                        if (cell.column.Header === 'ALARMS' && cell.value != null) {
                                            return (
                                                // <td {...cell.getCellProps()}>{cell.render('Cell')}</td>
                                                <td key={'td-'+cell.value}>
                                                        <div className="info-tooltip">
                                                        {cell.row.original.sortedAlerts ?<div>
                                                            {cell.row.original.alertsCount ===0?
                                                                null : null}
                                                            {cell.row.original.alertsCount ===1? <div>
                                                               <div className='alertImage'></div>
                                                            </div> : null}
                                                            {cell.row.original.alertsCount ===2? <div>
                                                                <div className='alertImage'></div>
                                                                <div className='alertImage'></div>
                                                            </div> : null}
                                                            {cell.row.original.alertsCount > 2? <div>
                                                                <div className='alertImage'></div>
                                                                <div className='alertImage'></div>
                                                                 <span className="alarmText">+{cell.row.original.alertsCount-2}</span>
                                                            </div> : null}
                                                        </div> :
                                                        <div>
                                                            {cell.value.length ===1? <div>
                                                               <div className='alertImage'></div>
                                                            </div> : null}
                                                            {cell.value.length ===2? <div>
                                                                <div className='alertImage'></div>
                                                                <div className='alertImage'></div>
                                                            </div> : null}
                                                            {cell.value.length > 2? <div>
                                                                <div className='alertImage'></div>
                                                                <div className='alertImage'></div>
                                                                 <span className="alarmText">+{cell.value.length-2}</span>
                                                            </div> : null} 
                                                        </div> }
                                                            {/* */}
                                                            
                                                        <div className="info-tooltip-text" style={{'width': 500, 'height': 200, 'overflowY': 'scroll'}}>
                                                            { cell.row.original.sortedAlerts ? 
                                                                   cell.row.original.sortedAlerts.map((keyName, keyIndex)=> 
                                                                    <div className="info-tooltip-text-msg" key={keyIndex+'span'}>
                                                                    <div className="alarm-header">{keyName.alerts.length > 0 ? keyName.machineName : null}</div>
                                                                    
                                                                    {keyName.alerts.map((ele, i)=>
                                                                    <p> <span>{i + 1 }.&nbsp;</span>
                                                                    <span>{moment(ele.timestamp).utc().local().format("YYYY-MM-DD HH:mm:ss")}&nbsp;&nbsp;: Over the last {ele.observationDuration} seconds, the feature {ele.featureName} exceeded its medium threshold {ele.mediumThresholdValue} for {ele.mediumThresholdDuration} seconds, and its high threshold {ele.highThresholdValue} for {ele.highThresholdDuration} seconds. The feature reached a minimum value of {ele.minimumValue} and a maximum value of {ele.highValue}.</span>
                                                                    <span>
                                                                        <Dropdown
                                                                            className={ele.state.toLowerCase()=="warning"? 'text-Warning' : 'text-Caution'}
                                                                            label={ele.status && ele.status.toLowerCase()!='open'? ele.status : 'Tag'}
                                                                            disabled={ele.status.toLowerCase() !== 'open'? true : false}
                                                                            onClick={function () {}}
                                                                            onSelect={(val) => handleAlarmClick(val,ele)}
                                                                        >
                                                                            <DropdownItem className={ele.state.toLowerCase()=="warning"? 'bg-Warning' : 'bg-Caution'} value="False" style={{'color':'black'}}>False</DropdownItem>
                                                                            <DropdownItem className={ele.state.toLowerCase()=="warning"? 'bg-Warning' : 'bg-Caution'} value="Solved" style={{'color':'black'}}>Solved</DropdownItem>
                                                                        </Dropdown>
                                                                    </span></p>)}
                                                                </div>
                                                                )
                                                            : <>
                                                            {cell.value.map((ele,i)=> 
                                                                <div className="info-tooltip-text-msg" key={i+'span'}>
                                                                    {/* <div className="alarm-header">{ele.deviceName}</div> */}
                                                                    <span>{i+1}.&nbsp;</span>
                                                                    <span>{moment(ele.timestamp).utc().local().format("YYYY-MM-DD HH:mm:ss")}&nbsp;: Over the last {ele.observationDuration} seconds, the feature {ele.featureName} exceeded its medium threshold {ele.mediumThresholdValue} for {ele.mediumThresholdDuration} seconds, and its high threshold {ele.highThresholdValue} for {ele.highThresholdDuration} seconds. The feature reached a minimum value of {ele.minimumValue} and a maximum value of {ele.highValue}.</span>
                                                                    <span>
                                                                        <Dropdown
                                                                            className={ele.state.toLowerCase()=="warning"? 'text-Warning' : 'text-Caution'}
                                                                            label={ele.status && ele.status.toLowerCase()!='open'? ele.status : 'Tag'}
                                                                            disabled={ele.status.toLowerCase() !== 'open' ? true : false}
                                                                            onClick={function () {}}
                                                                            onSelect={(val) => handleAlarmClick(val,ele)}
                                                                        >
                                                                            <DropdownItem className={ele.state.toLowerCase()=="warning"? 'bg-Warning' : 'bg-Caution'} value="False" style={{'color':'black'}}>False</DropdownItem>
                                                                            <DropdownItem className={ele.state.toLowerCase()=="warning"? 'bg-Warning' : 'bg-Caution'} value="Solved" style={{'color':'black'}}>Solved</DropdownItem>
                                                                        </Dropdown>
                                                                    </span>
                                                                </div>
                                                            )}</>}
                                                            {/* {cell.value.map((ele,i)=> 
                                                                <div className="info-tooltip-text-msg" key={i+'span'}>
                                                                    <div className="alarm-header">{ele.deviceName}</div>
                                                                    <span>{i+1}.&nbsp;</span>
                                                                    <span>{ele.timestamp.split("T")[0]}&nbsp;{ele.timestamp.split("T")[1].slice(0, 8)}&nbsp;: Over the last {ele.observationDuration} seconds, the feature {ele.featureName} exceeded its medium threshold {ele.mediumThresholdValue} for {ele.mediumThresholdDuration} seconds, and its high threshold {ele.highThresholdValue} for {ele.highThresholdDuration} seconds. The feature reached a minimum value of {ele.minimumValue} and a maximum value of {ele.highValue}.</span>
                                                                    <span>
                                                                        <Dropdown
                                                                            className={ele.state=="Warning"? 'text-Warning' : 'text-Caution'}
                                                                            label={ele.status && ele.status!='Open'? ele.status : 'Tag'}
                                                                            disabled={ele.status !== 'Open'? true : false}
                                                                            onClick={function () {}}
                                                                            onSelect={(val) => handleAlarmClick(val,ele)}
                                                                        >
                                                                            <DropdownItem className={ele.state=="Warning"? 'bg-Warning' : 'bg-Caution'} value="False" style={{'color':'black'}}>False</DropdownItem>
                                                                            <DropdownItem className={ele.state=="Warning"? 'bg-Warning' : 'bg-Caution'} value="Solved" style={{'color':'black'}}>Solved</DropdownItem>
                                                                        </Dropdown>
                                                                    </span>
                                                                </div>
                                                            )} */}
                                                            </div>
                                                        </div>
                                                    </td>
                                            )
                                        } else if (cell.column.Header === 'STATUS') {
                                            if (cell.value === 1) {
                                                return (
                                                    <td key={cell.value} style={{textAlign: 'center'}} >
                                                        <div className="dot info-tooltip" style={{ 'backgroundColor': '#2ab6ea', textAlign:"center" }}>
                                                            <span className="info-tooltip-text">IDLE</span>
                                                        </div>
                                                    </td>
                                                )
                                            } else if (cell.value === 2) {
                                                return (
                                                    <td key={cell.value} style={{textAlign: 'center'}}>
                                                        <div className="dot info-tooltip" style={{ 'backgroundColor': '#39b54a' }}>
                                                            <span className="info-tooltip-text">OPERATIONAL</span>
                                                        </div>
                                                    </td>
                                                )
                                            } else if (cell.value === 3){
                                                return (
                                                    <td key={cell.value} style={{textAlign: 'center'}}>
                                                        <div className="dot info-tooltip" style={{ 'backgroundColor': '#ff0000' }}>
                                                            <span className="info-tooltip-text">BOOKED LOSS</span>
                                                        </div>
                                                    </td>
                                                )
                                            }else {
                                                return (
                                                    <td key={cell.value} style={{textAlign: 'center'}}>
                                                        <div className="dot info-tooltip" style={{ 'backgroundColor': '#999999' }}>
                                                            <span className="info-tooltip-text">DISCONNECTED</span>
                                                        </div>
                                                    </td>
                                                )
                                            }

                                        } else if(cell.column.Header === 'MACHINE') {
                                            return (
                                                <td {...cell.getCellProps()} style={{textAlign: 'left'}}>{cell.render('Cell')}</td>
                                            )
                                        } else {
                                            return (
                                                <td {...cell.getCellProps()} style={{textAlign: 'right'}}>{cell.render('Cell')}</td>
                                            )
                                        }

                                    })}
                                </tr>
                            )
                    )}
                </tbody>
            </table>
        </>
    )
}

export class MachineList extends Component {
    constructor(props) {
        super(props);
        this.state = {
            selected: ['oEE'],
            sortByPartNumberAsc: true,
            sortBySTDPartCountAsc: true,
            sortByStatusAsc: true,
            open: false,
            alarmOpen: false,
            operator: '',
            selectedAlarm: {},
            alarmStatus: '',
            action: '',
            value: 'Part-Count',
            data: [],
            options: [{ value: 'oEE', name: 'OEE', label: 'OEE' },
            { value: 'partsPerHour', name: 'Parts/Hour', label: 'Parts/Hour' },
            { value: 'partCount', name: 'PART COUNT', label: 'PART COUNT' },
            { value: 'strokesPerMinute', name: 'Strokes/min', label: 'Strokes/min' },
            { value: 'cycleTime', name: 'CYCLE TIME', label: 'CYCLE TIME' }
        ],

            // columnOptions: [{ value: 'oEE', name: 'OEE', label: 'OEE' },
            // { value: 'partsPerHour', name: 'Parts/Hour', label: 'Parts/Hour' },
            // { value: 'partCount', name: 'PART COUNT', label: 'PART COUNT' },
            // { value: 'strokesPerMinute', name: 'Strokes/min', label: 'Strokes/min' },
            // { value: 'machineName', name: 'MACHINE', label: 'MACHINE' },
            // { value: 'status', name: 'STATUS', label: 'STATUS' },
            // { value: 'stdPartCount', name: 'STD. PART COUNT', label: 'STD. PART COUNT' },
            // { value: 'alerts', name: 'ALARMS', label: 'ALARMS' },
            // ],
            columns: [
                {
                    Header: 'ALARMS',
                    accessor: 'alerts'
                },

                {
                    Header: () => null, // No header, please
                    id: 'expander', // Make sure it has an ID
                    Cell: ({ row }) =>
                        row.canExpand ? (
                            <span
                                {...row.getExpandedToggleProps({
                                    style: {
                                        paddingLeft: `${row.depth * 2}rem`,
                                    },
                                })}
                            >
                                {row.isExpanded ? <ExpandLess /> : <ExpandMore />}
                            </span>
                        ) : null,
                },
                {
                    Header: 'MACHINE',
                    accessor: 'machineName'
                },
                {
                    Header: 'PART NUMBER',
                    accessor: 'partNumber'
                },
                {
                    Header: 'STATUS',
                    accessor: 'status'
                },
                {
                    Header: 'PART COUNT',
                    accessor: 'partCount',
                },
                {
                    Header: 'STD. PART COUNT',
                    accessor: 'stdPartCount'
                }
            ]
        }
        this.onOptionChange = this.onOptionChange.bind(this);
        this.handleOperatorChange = this.handleOperatorChange.bind(this);
        this.handleActionChange = this.handleActionChange.bind(this);
    }

    handleClick(column) {
        this.setState({ open: true });
    }
    handleAlarmClick() {
        this.setState({ alarmOpen: true });
    }

    handleAlarmClick(value, row) {
        this.setState({
            alarmOpen: true,
            selectedAlarm : row,
            alarmStatus: value
        });
    }

    handleClose = () => {
        this.setState({ open: false })
    };
    handleAlarmClose = () => {
        this.setState({ alarmOpen: false,
                        action: '',
                        operator: '' })
    };
    onOptionChange(value) {
        this.setState({ value: value });
    };

    handleAlarmFormSubmit = (formAlarmSubmitEvent) => {
        formAlarmSubmitEvent.preventDefault();
        let from = moment(this.props.range[1]).utc().format("YYYY-MM-DDTHH:mm:00") + "Z";
        let to = moment(this.props.range[2]).utc().format("YYYY-MM-DDTHH:mm:00") + "Z";
        this.props.updateMachineStatus(this.state.operator, this.state.action,this.state.selectedAlarm.id, this.state.selectedAlarm.deviceName,this.state.alarmStatus,from,to,this.props.selectedPlantId);
        this.setState({
            alarmOpen :false,
            action: '',
            operator: ''
        })
    }

    handleFormSubmit = (formSubmitEvent) => {
        formSubmitEvent.preventDefault();
        let machineData = [...this.props.machineList.machineData];
        machineData.forEach((ele, i) => {
            // let sortedAlerts = this.groupAlertsByMachineId(ele.alerts);
            machineData[i].sortedAlerts = ele.alerts;
        })
        this.setState({
            open:false,
            data: [...machineData],
            columns: [
                {
                    Header: 'ALARMS',
                    accessor: 'alerts'
                },

                {
                    Header: () => null, // No header, please
                    id: 'expander', // Make sure it has an ID
                    Cell: ({ row }) =>
                        row.canExpand ? (
                            <span
                                {...row.getExpandedToggleProps({
                                    style: {
                                        paddingLeft: `${row.depth * 2}rem`,
                                    },
                                })}
                            >
                                {row.isExpanded ? <ExpandLess /> : <ExpandMore />}
                            </span>
                        ) : null,
                },
                {
                    Header: 'MACHINE',
                    accessor: 'machineName'
                },
                {
                    Header: 'PART NUMBER',
                    accessor: 'partNumber'
                },
                {
                    Header: 'STATUS',
                    accessor: 'status'
                },
                {
                    Header: this.state.value.name, 
                    accessor: this.state.value.value,
                },
                {
                    Header: this.state.value.name === 'CYCLE TIME' ? 'STD.CYCLE TIME'  : 'STD.PART COUNT' ,
                    accessor: this.state.value.value === 'cycleTime' ? 'stndCycleTime' : 'stdPartCount',
                }
            ]
        }
        )
    };
    componentDidUpdate(prevProps){
        if(prevProps.machineList.machineData !== this.props.machineList.machineData) {
            let machineData = [...this.props.machineList.machineData];
            machineData.forEach((ele, i) => {
                // let sortedAlerts = this.groupAlertsByMachineId(ele.alerts);
                let length = 0;
                ele.alerts.forEach(element => {
                    length += element.alerts.length;
                });
                machineData[i].sortedAlerts = ele.alerts;
                machineData[i].alertsCount = length;
            })
            this.setState({
                data: [...machineData]
            })
        }
    }

    groupAlertsByMachineId(alerts){
       let myresult = alerts.reduce((h, alert) => Object.assign(h, { [alert.deviceId]:( h[alert.deviceId] || [] ).concat({...alert}) }), {})
        return myresult;
    }

    handleOperatorChange(event) {
        this.setState({operator: event.target.value});
        }
    
    handleActionChange(event) {
        this.setState({action: event.target.value});
    }
   /* onChange = selected => {
        let columns = [{
            Header: () => null, // No header, please
            id: 'expander', // Make sure it has an ID
            Cell: ({ row }) =>
                row.canExpand ? (
                    <span
                        {...row.getExpandedToggleProps({
                            style: {
                                paddingLeft: `${row.depth * 2}rem`,
                            },
                        })}
                    >
                        {row.isExpanded ? <ExpandLess /> : <ExpandMore />}
                    </span>
                ) : null,
        }];
        selected.forEach(element => {
          let ele = this.state.columnOptions.find(e => e.value === element ) 
          let obj =  {
            Header: ele.name,
            accessor: ele.value
        }
         columns.push(Object.assign({},obj) )
        });
        this.setState({ 
            selected: selected,
            columns: columns, 
            data: [...this.props.machineList] 
        });
      };
      */
      sortByAlarmCount() {
        let tempData = [...this.state.data];
            if(this.state.sortByPartNumberAsc === true){
                tempData.sort(function(a, b){
                    return a.alertsCount-b.alertsCount;
                })
            }else{
                tempData.sort(function(a, b){
                    return b.alertsCount-a.alertsCount;
                })
            }
            
            this.setState({
                data: tempData,
                sortByPartNumberAsc: !this.state.sortByPartNumberAsc,
            })
      }

      sortByColumnName(e,columns){
        e.stopPropagation();
            let tempData = [...this.props.machineList.machineData];
            if(this.state.sortByPartNumberAsc === true){
                tempData.sort(function(a, b){
                    return a[columns.id]- b[columns.id];
                })
            }else{
                tempData.sort(function(a, b){
                    return b[columns.id]- a[columns.id];
                })
            }
            
            this.setState({
                data: tempData,
                sortByPartNumberAsc: !this.state.sortByPartNumberAsc,
            })
      }

      sortByMachineName(){
            let tempData = [...this.props.machineList.machineData];
            if(this.state.sortByPartNumberAsc === true){
                tempData.sort(function(a,b) {return (a.machineName > b.machineName) ? 1 : ((b.machineName > a.machineName) ? -1 : 0);} );
            }else{
                tempData.sort(function(a,b) {return (b.machineName > a.machineName) ? 1 : ((a.machineName > b.machineName) ? -1 : 0);} );
            }
            
            this.setState({
                data: tempData,
                sortByPartNumberAsc: !this.state.sortByPartNumberAsc,
            })
      }

      

    render() {
        const { columns, data, open, alarmOpen } = this.state;
        const  {t}=this.props;
        return (
            <div className="mk">
            <div className="common-widget" /*style={{"overflowY": "scroll"}}*/>              
            {/* <DualListBox
                options={this.state.columnOptions}
                selected={this.state.selected}
                onChange={this.onChange}
                preserveSelectOrder
                showOrderButtons={true}
            /> */}
                 <Styles>
                    <Table columns={columns} data={data} overflowY
                           handleClick={this.handleClick.bind(this)} 
                           handleAlarmClick={this.handleAlarmClick.bind(this)} 
                           sortByAlarmCount={this.sortByAlarmCount.bind(this)} 
                           sortByColumnName={this.sortByColumnName.bind(this)}
                           sortByMachineName={this.sortByMachineName.bind(this)}
                    />
                </Styles>

                <Modal open={open} onClose={this.handleClose} center>
                    <div className="machineList-dialog-wrp">
                        <h2>{t("Select Productivity Option")}</h2>
                        <form onSubmit={this.handleFormSubmit}>                                
                            <Picky
                                className="machineList-dialog-range"
                                value={this.state.value}
                                options={this.state.options}
                                onChange={this.onOptionChange}
                                open={false}
                                keepOpen={false}
                                valueKey="value"
                                labelKey="name"
                                multiple={false}
                                includeSelectAll={false}
                                includeFilter={false}
                                dropdownHeight={300}
                                placeholder={this.state.value}
                                autoBlur={true}
                            />
                            <div className="dialog-btn-wrp">
                                <Button className="dialog-btn select-btn" type="submit" color="primary" autoFocus>Select</Button>
                                <Button className="dialog-btn cancel-btn" onClick={() => this.handleClose()} color="primary" autoFocus>Cancel</Button>
                            </div>
                        </form>
                    </div>
                </Modal>
                
                <Modal open={alarmOpen} onClose={this.handleAlarmClose} center>
                    <div className="machineList-dialog-wrp">
                        <h2>{t("Operator & Action")}</h2>
                        <form  onSubmit={this.handleAlarmFormSubmit}>  
                        <div className="action-dialog">   
                            <div>                           
                                <label>{t("Operator")}</label><input type='text' value={this.state.operator} onChange={this.handleOperatorChange}></input>
                            </div>
                            <div>
                                <label>{t("Action")}</label><input type='text' value={this.state.action} onChange={this.handleActionChange}></input>
                            </div>
                            <div className="clearfix"></div>
                        </div>
                        <div className="dialog-btn-wrp">
                            <Button className="dialog-btn select-btn" type="submit" color="primary" autoFocus>{t("Add/Change")}</Button>
                            <Button className="dialog-btn cancel-btn" onClick={() => this.handleAlarmClose()} color="primary" autoFocus>{t("Cancel")}</Button>
                        </div>
                        </form>
                    </div>
                </Modal>
            
            </div>
            </div>

        )
    }
}

const mapStateToProps = state => {
    return {
      machineList : state.machineDataReducer,
    }
  }

const mapDispatchToProps = dispatch => {
    return {
        updateMachineStatus: (operator, action, id, deviceId, status , from, to, plantId) => (dispatch((actionCreators.updateMachineStatus(operator, action, id, deviceId, status, from, to, plantId)))),
    }
  }

export default withTranslation()(connect(mapStateToProps, mapDispatchToProps)(MachineList));
