import * as actionTypes from '../actions/actionTypes';

export const reportFeatureReducer = (state={
    reportFeature : [ ]
}, action) => {
    switch(action.type) {

        case actionTypes.SET_REPORT_FEATURE_DATA:
            return {
                ...state,
                reportFeature: action.payload
            }

        default: return state
    }
}