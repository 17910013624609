import React, { Component } from 'react';
import * as actionCreators from '../../actions/actionCreators';
import { connect } from 'react-redux';
import { KeyboardBackspace, CloudUpload } from '@material-ui/icons';
import {
    Card,
    CardContent,
    InputLabel,
    TextField,
    Select,
    withStyles,
    OutlinedInput,
    Button,
    InputAdornment
} from '@material-ui/core';
import { withTranslation } from "react-i18next";

const styles = theme => ({
    cardContent: {
        height: '91%',
        "overflow-y": "auto"
    },
    cardContentRoot: {
        '&:last-child': {
            paddingBottom: 16
        }
    },
    card: {
        width: '100%',
        borderRadius: 0,
        height: '80vh'
    },
    textField: {
        width: '60%',
        marginRight: 20
    },
    fileUploadDiv: {
        width: '60%',
        '&>div': {
            width: '100%'
        },
        marginRight: 20
    },
    textArea: {
        width: '80%',
        marginRight: 20
    },
    outlinedInput: {
        padding: 14
    },
    inputLogo: {
        display: "none"
    },
    cloudUploadIcon: {
        color: '#4c4c4c',
        cursor: 'pointer'
    },
    saveButton: {
        fontSize: "14px",
        fontWeight: "500",
        textAlign: "center",
        color: "#ffffff",
        backgroundColor: "#42D784",
        '&:hover': {
            backgroundColor: '#42d784'
        },
        '&:disabled': {
            backgroundColor: '#f6f6f6'
        },
        borderRadius: "3px",
        boxShadow: "0 0 1px 0 rgba(0, 0, 0, 0.05)",
        width: "110px",
        height: "36px",
        marginRight: "24px"
    },
    inputFieldDiv: {
        display: "flex",
        justifyContent: "space-between",
        width: '50%',
        float: 'left',
        paddingTop: '1%',
        height: 80,
        'align-items': 'baseline'
    },
    textareaInputFieldDiv: {
        display: "flex",
        justifyContent: "space-between",
        width: '100%',
        float: 'left',
        paddingTop: '3%',
        height: 180,
        'align-items': 'baseline'
    },
    textFieldLabel: {
        color: 'rgba(0, 0, 0, 0.4000000059604645)',
        'letter-spacing': 0.5,
        'font-weight': 500,
        fontSize: "14px",
        lineHeight: 2.5,
        width: '30%',
        marginLeft: 20,
        //'text-align': 'right'
    },
    textareaFieldLabel: {
        color: 'rgba(0, 0, 0, 0.4000000059604645)',
        'letter-spacing': 0.5,
        'font-weight': 500,
        fontSize: "14px",
        lineHeight: 2.5,
        width: '15%',
        marginLeft: 20,
        //'text-align': 'right'
    },
    requiredClass: {
        color: 'red'
    },
    actionButtonContainer: {
        display: 'flex',
        'justify-content': 'space-between',
        height: '9%',
        'border-bottom': '1px solid rgba(207, 216, 220, 0.5)'
    },
    backButtonContainer: {
        display: 'flex',
        'align-items': 'center',
        paddingLeft: 21
    },
    arrowBack: {
        cursor: 'pointer'
    },
    entityTitle: {
        color: 'rgba(0, 0, 0, 0.8)',
        'font-family': 'Roboto',
        'font-size': 21,
        'font-weight': '400',
        'letter-spacing': 0.5,
        marginLeft: 10
    },
})

class OrganizationsEntity extends Component {

    state = {
        id: "",
        disable: false,
        disableSaveButton: true,
        disablePuvName: false,
        disableOrgType: false,
        defaultLanguage: "",
        parentOrganizationtype: "",
        parentOrganizationName: "",
        selectedOrganizationType: "",
        name: "",
        notes: "",
        organizationType: "",
        puveyorName: "",
        specs: "",
        labelWidth: 0,
        purveyorDropDownData: "",
        selectedFile: "",
        nameError: false,
        specsError: false,
        notesError: false,
        languageError: false,
        orgTypeError: false,
        puvNameError: false,
        selectedFileSizeError: false,
        fftWaveformEnable: "Enabled",
        odrEnable: "Enabled",
        fftAnnotationEnable: "Enabled"
    }

    // Handle changes to any user input field
    inputChangeHandler = (event, field) => {
        switch (field) {
            case "organisationName": this.setState({ name: event.target.value, disableSaveButton: false });
                this.checkValidation("organisationName", event.target.value);
                break;
            case "defaultLanguage": this.setState({ defaultLanguage: event.target.value, disableSaveButton: false });
                this.checkValidation("defaultLanguage", event.target.value);
                break;
            case "organizationType": this.setState({ organizationType: event.target.value, disableSaveButton: false });
                this.checkValidation("organizationType", event.target.value);
                break;
            case "puveyorName": this.setState({ puveyorName: event.target.value, disableSaveButton: false });
                this.checkValidation("puveyorName", event.target.value);
                break;
            case "specs": this.setState({ specs: event.target.value, disableSaveButton: false });
                this.checkValidation("specs", event.target.value);
                break;
            case "notes": this.setState({ notes: event.target.value, disableSaveButton: false });
                this.checkValidation("notes", event.target.value);
                break;
            case "logoUpload":
                if (event.target.files.length > 0) {
                    this.checkValidation("logoUpload", event.target.files[0]);
                    this.setState({ selectedFile: event.target.files[0] }); break;
                }
                break;
            case "logoRemove":
                this.checkValidation("logoUpload", {});
                this.setState({ selectedFile: { name: '' } });
                break;
            case "fftWaveformEnable":
                this.setState({ fftWaveformEnable: event.target.value, disableSaveButton: false });
                break;
            case "odrEnable":
                this.setState({ odrEnable: event.target.value, disableSaveButton: false });
                break;
            case "fftAnnotationEnable":
                this.setState({ fftAnnotationEnable: event.target.value, disableSaveButton: false });
                break;
            default: console.log("default case");
        }
    }

    // Handle routing
    goToBackPage = () => {
        this.props.history.goBack();
    }

    getPurveyourDropDownData = (dataObject) => {
        if (dataObject) {
            let itemList = [];
            dataObject.map((data) => {
                if (data.organizationType !== "customer") {
                    itemList.push(data.name);
                }
            })
            return itemList;
        } else {
            return null;
        }
    }

    getPurveyourDropDownList = () => {
        let list = [];
        list.push(<option key={""} value={""}>{this.props.t("Select")}</option>);
        if (this.state.purveyorDropDownData) {
            this.state.purveyorDropDownData.map((data) => {
                list.push(<option key={data} value={data}>{data}</option>);
            })
        }
        return list;
    }

    // update organizations entity
    updateOrganisationData = () => {
        const defaultLanguage = this.state.defaultLanguage
        const id = this.state.id
        const name = this.state.name
        const notes = this.state.notes
        const organizationType = this.state.organizationType
        const puveyorName = this.state.puveyorName
        const specs = this.state.specs
        const fftAnnotationEnable = this.state.fftAnnotationEnable === "Enabled" ? true : false
        const selectedFile = this.state.selectedFile
        const fftWaveformEnable = this.state.fftWaveformEnable === "Enabled" ? true : false
        const odrEnable = this.state.odrEnable === "Enabled" ? true : false
        this.props.updateOrganisationEntity(defaultLanguage, name, notes, organizationType, puveyorName, specs, selectedFile, id, fftWaveformEnable, odrEnable,fftAnnotationEnable);
    }


    componentDidMount() {
        if (this.props.data) {
            let entityNames = Object.keys(this.props.data)
            entityNames.map((data) => {
                if (this.props.data[data] == null) {
                    this.props.data[data] = ""
                }
            })
            if (this.props.data.organizationType && this.props.data.organizationType.toUpperCase() === "PUBLISHER") {
                this.setState({ disable: true, organizationType: this.props.data.organizationType })
            }
            if (this.props.data.id && (this.props.data.id !== null) && (this.props.data.id !== "")) {
                this.setState({ disableOrgType: true })
            }
            this.setState({
                id: this.props.data.id,
                defaultLanguage: this.props.data.defaultLanguage,
                name: this.props.data.name,
                organizationType: this.props.data.organizationType,
                fftWaveformEnable: (this.props.data.fftWaveformEnable === "無効") || (this.props.data.fftWaveformEnable === "Disabled")  ?"Disabled":"Enabled",
                fftAnnotationEnable: (this.props.data.fftAnnotationEnable === "無効") || (this.props.data.fftAnnotationEnable === "Disabled")  ?"Disabled":"Enabled",
                odrEnable: (this.props.data.odrEnable ==="無効") || ( this.props.data.odrEnable === "Disabled") ?"Disabled":"Enabled"
            
            })
            if (this.props.data.organizationType !== "" && this.props.data.organizationType === "reseller") {
                this.setState({ disablePuvName: true })
            }
            if (this.props.data.organizationType !== "") {
                if (this.props.data.puveyorName) {
                    this.setState({ puveyorName: this.props.data.puveyorName })
                }
            }
            if (this.props.data.specs && this.props.data.specs !== "") {
                if (this.props.data.specs.props) {
                    this.setState({ specs: this.props.data.specs.props.children })
                }
                else {
                    this.setState({ specs: this.props.data.specs })
                }
            }
            if (this.props.data.notes && this.props.data.notes !== "") {
                if (this.props.data.notes.props) {
                    this.setState({ notes: this.props.data.notes.props.children })
                }
                else {
                    this.setState({ notes: this.props.data.notes })
                }
            }
        }
        if (this.props.scopeSelector) {
            this.setState({ purveyorDropDownData: this.getPurveyourDropDownData(this.props.scopeSelector.subOrganizations) })
            const parentOrganization = this.props.scopeSelector.subOrganizations.find((organization) => (
                organization.root
            ));
            this.setState({
                parentOrganizationtype: parentOrganization.organizationType,
                parentOrganizationName: parentOrganization.name
            });
            const selectedOrganization = this.props.scopeSelector.subOrganizations.find((organization) => (
                organization.id === this.props.selectedScopeData
            ));

            if (selectedOrganization) {
                this.setState({
                    selectedOrganizationType: selectedOrganization.organizationType
                });
            }

            if (selectedOrganization && selectedOrganization.organizationType === "reseller") {
                this.setState({
                    disablePuvName: true,
                    disableOrgType: true,

                })
                if (this.props.data === "") {
                    this.setState({ puveyorName: selectedOrganization.name, organizationType: "customer" })
                }
                else {
                    this.setState({ puveyorName: this.props.data.puveyorName, organizationType: this.props.data.organizationType })
                }
            }
        }

        if (this.props.validationData && this.props.validationData.config && this.props.validationData.config.Organizations) {
            this.setState({ validationData: this.props.validationData.config.Organizations });
        }
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevProps.selectedTab !== this.props.selectedTab) {
            this.props.history.replace("/dashboard");
        }

        if (this.state.selectedOrganizationType) {
            if (prevState.organizationType !== this.state.organizationType && this.state.parentOrganizationtype === "publisher" && !(this.state.id && this.state.id != null && this.state.id !== "")) {
                if (this.state.organizationType && this.state.organizationType !== "" && this.state.organizationType === "reseller") {
                    this.setState({ disablePuvName: true, puveyorName: this.state.parentOrganizationName })
                }
                else if (this.state.selectedOrganizationType !== "reseller") {
                    this.setState({ disablePuvName: false, puveyorName: "" })
                }
            }
        }
    }

    // disable save button based on validations
    disableSaveButton = () => {
        if (this.state.name === "" && (this.state.validationData.name.validations && this.state.validationData.name.validations.required)) {
            return true;
        }
        else if (this.state.defaultLanguage === "" && (this.state.validationData.defaultLanguage.validations && this.state.validationData.defaultLanguage.validations.required)) {
            return true;
        }
        else if (this.state.notes === "" && (this.state.validationData.notes.validations && this.state.validationData.notes.validations.required)) {
            return true;
        }
        else if (this.state.specs === "" && (this.state.validationData.specs.validations && this.state.validationData.specs.validations.required)) {
            return true;
        }
        else if (this.state.organizationType === "" && (this.state.validationData.organizationType.validations && this.state.validationData.organizationType.validations.required)) {
            return true;
        }
        else if (this.state.puveyorName === "" && (this.state.validationData.puveyorName.validations && this.state.validationData.puveyorName.validations.required)) {
            return true;
        }
        else if (this.state.nameError || this.state.specsError || this.state.notesError || this.state.languageError || this.state.orgTypeError || this.state.puvNameError) {
            return true;
        }
        else if (this.state.nameError || this.state.specsError || this.state.notesError || this.state.languageError || this.state.orgTypeError || this.state.puvNameError || this.state.selectedFileSizeError) {
            return true;
        }
        else {
            return false;
        }
    }

    // set card header
    getCardHeader = () => {
        if (this.props.data && this.props.data.name && this.props.data.name !== "") {
            return this.props.data.name;
        }
        else {
            return this.props.t("Create New")
        }
    }

    // check validations of the user input fields
    checkValidation = (field, value) => {
        switch (field) {
            case "defaultLanguage":
                if (!this.state.languageError && value === "" && (this.state.validationData.defaultLanguage.validations && this.state.validationData.defaultLanguage.validations.required)) {
                    this.setState({ languageError: true });
                } else if (this.state.languageError) {
                    if ((value === "" && (this.state.validationData.defaultLanguage.validations && this.state.validationData.defaultLanguage.validations.required))) {
                        //skip
                    }
                    else {
                        this.setState({ languageError: false });
                    }
                };
                break;
            case "organizationType":
                if (!this.state.orgTypeError && value === "" && (this.state.validationData.organizationType.validations && this.state.validationData.organizationType.validations.required)) {
                    this.setState({ orgTypeError: true });
                } else if (this.state.orgTypeError) {
                    if ((value === "" && (this.state.validationData.organizationType.validations && this.state.validationData.organizationType.validations.required))) {
                        //skip
                    }
                    else {
                        this.setState({ orgTypeError: false });
                    }
                };
                break;

            case "puveyorName":
                if (!this.state.puvNameError && value === "" && (this.state.validationData.puveyorName.validations && this.state.validationData.puveyorName.validations.required)) {
                    this.setState({ puvNameError: true });
                } else if (this.state.puvNameError) {
                    if ((value === "" && (this.state.validationData.puveyorName.validations && this.state.validationData.puveyorName.validations.required))) {
                        //skip
                    }
                    else {
                        this.setState({ puvNameError: false });
                    }
                };
                break;
            case "logoUpload":
                value.size > 64000 ? this.setState({ selectedFileSizeError: true, disableSaveButton: true })
                    : this.setState({ selectedFileSizeError: false, disableSaveButton: false })
                break;
            case "organisationName":
                if (!this.state.nameError && value === "" && (this.state.validationData.name.validations && this.state.validationData.name.validations.required)) {
                    this.setState({ nameError: true });
                } else if (!this.state.nameError && (this.state.validationData.name.validations) && (value.length > this.state.validationData.name.validations.maxLength)) {
                    this.setState({ nameError: true });
                } else if (this.state.nameError) {
                    if ((value === "" && (this.state.validationData.name.validations && this.state.validationData.name.validations.required) || ((this.state.validationData.name.validations) && (value.length > this.state.validationData.name.validations.maxLength)))) {
                        //skip
                    }
                    else {
                        this.setState({ nameError: false });
                    }
                };
                break;
            case "notes":
                if (!this.state.notesError && value === "" && (this.state.validationData.notes.validations && this.state.validationData.notes.validations.required)) {
                    this.setState({ notesError: true });
                } else if (!this.state.notesError && (this.state.validationData.notes.validations) && (value.length > this.state.validationData.notes.validations.maxLength)) {
                    this.setState({ notesError: true });
                } else if (this.state.notesError) {
                    if ((value === "" && (this.state.validationData.notes.validations && this.state.validationData.notes.validations.required) || ((this.state.validationData.notes.validations) && (value.length > this.state.validationData.notes.validations.maxLength)))) {
                        //skip
                    }
                    else {
                        this.setState({ notesError: false });
                    }
                };
                break;
            case "specs":
                if (!this.state.specsError && value === "" && (this.state.validationData.specs.validations && this.state.validationData.specs.validations.required)) {
                    this.setState({ specsError: true });
                } else if (!this.state.specsError && (this.state.validationData.specs.validations) && (value.length > this.state.validationData.specs.validations.maxLength)) {
                    this.setState({ specsError: true });
                } else if (this.state.specsError) {
                    if ((value === "" && (this.state.validationData.specs.validations && this.state.validationData.specs.validations.required) || ((this.state.validationData.specs.validations) && (value.length > this.state.validationData.specs.validations.maxLength)))) {
                        //skip
                    }
                    else {
                        this.setState({ specsError: false });
                    }
                };
                break;
        }
    }

    // create organization-type dropdown list
    getOrganizationTypeDropDownList = () => {
        let orglist = [];
        orglist.push(<option key={""} disabled={true} value={""}>{this.props.t("Select")}</option>);
        const parentOrganization = this.props.scopeSelector.subOrganizations.find((organization) => (
            organization.root
        ));
        if (parentOrganization.organizationType === "publisher") {
            orglist.push(<option key={"reseller"} value={"reseller"}>{this.props.t("Reseller")}</option>);
        }
        orglist.push(<option key={"customer"} value={"customer"}>{this.props.t("Customer")}</option>);
        return orglist;
    }

    render() {
        const { t } = this.props;
        return (
            <Card className={this.props.classes.card}>
                <div className={this.props.classes.actionButtonContainer}>
                    <div className={this.props.classes.backButtonContainer}>
                        <KeyboardBackspace id="arrowBackOrg" onClick={this.goToBackPage} classes={{ root: this.props.classes.arrowBack }} />
                        <div id="entityTitleLabel" className={this.props.classes.entityTitle}>{this.getCardHeader()}</div>
                    </div>
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                        <Button variant="contained"
                            id="saveBtnOrg"
                            className={this.props.classes.saveButton}
                            disabled={this.state.disableSaveButton || this.disableSaveButton()}
                            onClick={this.updateOrganisationData}>
                            {t("Save")}
                        </Button>
                    </div>
                </div>
                <CardContent className={this.props.classes.cardContent} classes={{ root: this.props.classes.cardContentRoot }}>
                    {this.state.validationData && this.state.validationData.name && this.state.validationData.name.page_visible ? (
                        <div className={this.props.classes.inputFieldDiv}>
                            <InputLabel className={this.props.classes.textFieldLabel} id="organisationNameLabel">
                                {this.state.validationData.name.display_name}
                                {(this.state.validationData.name.validations && this.state.validationData.name.validations.required) ? (<span className={this.props.classes.requiredClass}>*</span>) : null}
                            </InputLabel>
                            <TextField
                                id="organisationName"
                                name="organisationName"
                                variant="outlined"
                                helperText={(this.state.validationData.name.validations && this.state.validationData.name.validations.helpText) ? (this.state.validationData.name.validations.helpText) : null}
                                className={this.props.classes.textField}
                                onChange={(event) => this.inputChangeHandler(event, "organisationName")}
                                value={this.state.name}
                                error={this.state.nameError}
                                InputProps={{
                                    classes: {
                                        input: this.props.classes.outlinedInput
                                    }
                                }}
                            />
                        </div>
                    )
                        : null
                    }
                    {this.state.validationData && this.state.validationData.defaultLanguage && this.state.validationData.defaultLanguage.page_visible ? (
                        <div className={this.props.classes.inputFieldDiv}>
                            <InputLabel className={this.props.classes.textFieldLabel} id="defaultLanguageLabel">
                                {this.state.validationData.defaultLanguage.display_name}
                                {(this.state.validationData.defaultLanguage.validations && this.state.validationData.defaultLanguage.validations.required) ? (<span className={this.props.classes.requiredClass}>*</span>) : null}
                            </InputLabel>
                            <Select
                                native
                                value={this.state.defaultLanguage}
                                id="defaultLanguage"
                                onChange={(event) => this.inputChangeHandler(event, "defaultLanguage")}
                                input={
                                    <OutlinedInput
                                        variant="outlined"
                                        name="defaultLanguage"
                                        id="language-native-simple"
                                        labelWidth={this.state.labelWidth}
                                        className={this.props.classes.textField}
                                        error={this.state.languageError}
                                        classes={{ input: this.props.classes.outlinedInput }}
                                    />
                                }
                            >
                                <option disabled={true} key={""} value="">{this.props.t("Select")}</option>
                                <option value="English">English</option>
                                {/* {
                                    this.props.languages.map((item) => (<option value={item} key={item}>{item}</option>))
                                } */}
                            </Select>
                        </div>
                    )
                        : null
                    }
                    {this.state.validationData && this.state.validationData.organizationType && this.state.validationData.organizationType.page_visible ? (
                        <div className={this.props.classes.inputFieldDiv}>
                            <InputLabel className={this.props.classes.textFieldLabel} id="organizationTypeLabel">
                                {this.state.validationData.organizationType.display_name}
                                {(this.state.validationData.organizationType.validations && this.state.validationData.organizationType.validations.required) ? (<span className={this.props.classes.requiredClass}>*</span>) : null}
                            </InputLabel>
                            {
                                (this.state.disable || this.state.disableOrgType) ? (
                                    <TextField
                                        name="organizationType"
                                        id="organizationType-disabled"
                                        disabled
                                        variant="outlined"
                                        className={this.props.classes.textField}
                                        value={this.state.organizationType}
                                        InputProps={{
                                            classes: {
                                                input: this.props.classes.outlinedInput
                                            }
                                        }}
                                    />
                                ) : (
                                    <Select
                                        native
                                        value={this.state.organizationType}
                                        onChange={(event) => this.inputChangeHandler(event, "organizationType")}
                                        input={
                                            <OutlinedInput
                                                id="organizationType"
                                                variant="outlined"
                                                name="organizationType"
                                                labelWidth={this.state.labelWidth}
                                                className={this.props.classes.textField}
                                                error={this.state.orgTypeError}
                                                classes={{ input: this.props.classes.outlinedInput }}
                                            />
                                        }
                                    >
                                        {this.getOrganizationTypeDropDownList()}
                                    </Select>
                                )
                            }
                        </div>
                    )
                        : null
                    }
                    {this.state.validationData && this.state.validationData.puveyorName && this.state.validationData.puveyorName.page_visible ? (
                        <div className={this.props.classes.inputFieldDiv}>
                            <InputLabel className={this.props.classes.textFieldLabel} id="purveyourNameLabel">
                                {this.state.validationData.puveyorName.display_name}
                                {(this.state.validationData.puveyorName.validations && this.state.validationData.puveyorName.validations.required) ? (<span className={this.props.classes.requiredClass}>*</span>) : null}
                            </InputLabel>
                            {
                                (this.state.disable || this.state.disablePuvName || this.state.disableOrgType) ? (
                                    <TextField
                                        name="purveyourName"
                                        disabled
                                        variant="outlined"
                                        id="purveyourName-disabled"
                                        className={this.props.classes.textField}
                                        placeholder="Purveyor"
                                        value={this.state.puveyorName}
                                        InputProps={{
                                            classes: {
                                                input: this.props.classes.outlinedInput
                                            }
                                        }}
                                    />
                                ) : (
                                    <Select
                                        native
                                        value={this.state.puveyorName}
                                        onChange={(event) => this.inputChangeHandler(event, "puveyorName")}
                                        variant="outlined"
                                        input={
                                            <OutlinedInput
                                                name="purveyourName"
                                                id="purvyor-native-simple"
                                                labelWidth={this.state.labelWidth}
                                                className={this.props.classes.textField}
                                                error={this.state.puvNameError}
                                                classes={{ input: this.props.classes.outlinedInput }}
                                            />
                                        }
                                    >
                                        {this.getPurveyourDropDownList()}
                                    </Select>
                                )
                            }
                        </div>
                    )
                        : null
                    }
                    {this.state.validationData && this.state.validationData.uploadLogo && this.state.validationData.uploadLogo.page_visible ? (
                        <div style={{ width: '53%' }} className={this.props.classes.inputFieldDiv}>
                            <InputLabel className={this.props.classes.textFieldLabel} id="logoUploadLabel">
                                {this.state.validationData.uploadLogo.display_name}
                                {(this.state.validationData.uploadLogo.validations && this.state.validationData.uploadLogo.validations.required) ? (<span className={this.props.classes.requiredClass}>*</span>) : null}
                            </InputLabel>
                            <label style={{ width: '58%', marginRight: '0' }} htmlFor="contained-button-file" className={this.props.classes.fileUploadDiv}>
                                {/*  upload logo of organization  */}
                                <TextField
                                    name="logoUpload"
                                    variant="outlined"
                                    id="logoUpload-disabled"
                                    helperText={(this.state.validationData.uploadLogo.validations && this.state.validationData.uploadLogo.validations.helpText) ? (this.state.validationData.uploadLogo.validations.helpText) : null}
                                    placeholder="Choose File.."
                                    value={this.state.selectedFile.name}
                                    InputProps={{
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                <CloudUpload className={this.props.classes.cloudUploadIcon}></CloudUpload>

                                            </InputAdornment>

                                        ),
                                        classes: {
                                            input: this.props.classes.outlinedInput
                                        }
                                    }}
                                    error={this.state.selectedFileSizeError}
                                />

                            </label>
                            <span id="logoRemove" style={{ display: 'inline-block', paddingRight: '35px', fontSize: '18px' }} onClick={(event) => this.inputChangeHandler(event, "logoRemove")}>x</span>

                            <input
                                variant="outlined"
                                accept="image/*"
                                className={this.props.classes.inputLogo}
                                id="contained-button-file"
                                multiple
                                type="file"
                                onChange={(event) => this.inputChangeHandler(event, "logoUpload")}
                            />
                        </div>
                    )
                        : null
                    }

                    <div className={this.props.classes.inputFieldDiv}>
                        <InputLabel className={this.props.classes.textFieldLabel} id="fftwaveformLabel">
                            {t("FFT/Waveform Data")}
                        </InputLabel>
                        <Select
                            native
                            value={this.state.fftWaveformEnable}
                            onChange={(event) => this.inputChangeHandler(event, "fftWaveformEnable")}
                            input={
                                <OutlinedInput
                                    variant="outlined"
                                    name="fftWaveformEnable"
                                    id="fftWaveformEnable"
                                    labelWidth={this.state.labelWidth}
                                    className={this.props.classes.textField}
                                    // error={this.state.languageError}
                                    classes={{ input: this.props.classes.outlinedInput }}
                                />
                            }
                        >
                            <option disabled={true} key={""} value="">{t(this.props.t("Select"))}</option>
                            <option value="Enabled">{t("Enabled")}</option>
                            <option value="Disabled">{t("Disabled")}</option>
                        </Select>
                    </div>
                    <div className={this.props.classes.inputFieldDiv}>
                        <InputLabel className={this.props.classes.textFieldLabel} id="odrDataLabel">
                            {t("ODR Data")}</InputLabel>
                        <Select
                            native
                            value={this.state.odrEnable}
                            onChange={(event) => this.inputChangeHandler(event, "odrEnable")}
                            input={
                                <OutlinedInput
                                    variant="outlined"
                                    name="odrEnable"
                                    id="odrEnable"
                                    labelWidth={this.state.labelWidth}
                                    className={this.props.classes.textField}
                                    // error={this.state.languageError}
                                    classes={{ input: this.props.classes.outlinedInput }}
                                />
                            }
                        >
                            <option disabled={true} key={""} value="">{t(this.props.t("Select"))}</option>
                            <option value="Enabled">{t("Enabled")}</option>
                            <option value="Disabled">{t("Disabled")}</option>
                            {/* {
                                    this.props.languages.map((item) => (<option value={item} key={item}>{item}</option>))
                                } */}
                        </Select>
                    </div>
                    <div className={this.props.classes.inputFieldDiv}>
                        <InputLabel className={this.props.classes.textFieldLabel} id="annotationSwitch">
                            {t("Annotation")}</InputLabel>
                        <Select
                            native
                            value={this.state.fftAnnotationEnable}
                            onChange={(event) => this.inputChangeHandler(event, "fftAnnotationEnable")}
                            input={
                                <OutlinedInput
                                    variant="outlined"
                                    name="fftAnnotationEnable"
                                    id="fftAnnotationEnable"
                                    labelWidth={this.state.labelWidth}
                                    className={this.props.classes.textField}
                                    // error={this.state.languageError}
                                    classes={{ input: this.props.classes.outlinedInput }}
                                />
                            }
                        >
                            <option disabled={true} key={""} value="">{t(this.props.t("Select"))}</option>
                            <option value="Enabled">{t("Enabled")}</option>
                            <option value="Disabled">{t("Disabled")}</option>
                            {/* {
                                    this.props.languages.map((item) => (<option value={item} key={item}>{item}</option>))
                                } */}
                        </Select>
                    </div>

                    {this.state.validationData && this.state.validationData.notes && this.state.validationData.notes.page_visible ? (
                        <div className={this.props.classes.textareaInputFieldDiv}>
                            <InputLabel className={this.props.classes.textareaFieldLabel} id="noteLabel">
                                {this.state.validationData.notes.display_name}
                                {(this.state.validationData.notes.validations && this.state.validationData.notes.validations.required) ? (<span className={this.props.classes.requiredClass}>*</span>) : null}
                            </InputLabel>
                            <TextField
                                id="note"
                                multiline
                                rows="6"
                                helperText={(this.state.validationData.notes.validations && this.state.validationData.notes.validations.helpText) ? (this.state.validationData.notes.validations.helpText) : null}
                                value={this.state.notes}
                                variant="outlined"
                                onChange={(event) => this.inputChangeHandler(event, "notes")}
                                className={this.props.classes.textArea}
                                error={this.state.notesError}
                                InputProps={{
                                    classes: {
                                        multiline: this.props.classes.outlinedInput
                                    }
                                }}
                            />
                        </div>
                    )
                        : null
                    }
                    {this.state.validationData && this.state.validationData.specs && this.state.validationData.specs.page_visible ? (
                        <div className={this.props.classes.textareaInputFieldDiv}>
                            <InputLabel className={this.props.classes.textareaFieldLabel} id="specificationLabel">
                                {this.state.validationData.specs.display_name}
                                {(this.state.validationData.specs.validations && this.state.validationData.specs.validations.required) ? (<span className={this.props.classes.requiredClass}>*</span>) : null}</InputLabel>
                            <TextField
                                id="specification"
                                multiline
                                rows="6"
                                helperText={(this.state.validationData.specs.validations && this.state.validationData.specs.validations.helpText) ? (this.state.validationData.specs.validations.helpText) : null}
                                value={this.state.specs}
                                variant="outlined"
                                onChange={(event) => this.inputChangeHandler(event, "specs")}
                                className={this.props.classes.textArea}
                                error={this.state.specsError}
                                InputProps={{
                                    classes: {
                                        multiline: this.props.classes.outlinedInput
                                    }
                                }}
                            />
                        </div>
                    )
                        : null
                    }
                </CardContent>
            </Card >
        );
    }
}

const mapStateToProps = (state) => {
    return {
        validationData: state.viewDefinitionReducer
    }
}

const mapDispatchToProps = dispatch => {
    return ({
        updateOrganisationEntity: (defaultLanguage, name, notes, organizationType, puveyorName, specs, selectedFile, id, fftWaveformEnable, odrEnable,fftAnnotationEnable) => (dispatch(actionCreators.updateOrganisationEntity(defaultLanguage, name, notes, organizationType, puveyorName, specs, selectedFile, id, fftWaveformEnable, odrEnable,fftAnnotationEnable))),
        updateSelectedTab: (selectedTab) => (dispatch(actionCreators.updateSelectedTab(selectedTab)))
    })
}

export default withTranslation()(connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(OrganizationsEntity)));

