import React, { Component } from 'react'
import ReactEcharts from 'echarts-for-react';
import { Tooltip } from '@material-ui/core';
import moment from "moment";
import { withTranslation } from "react-i18next";


//import { colors } from 'material-ui/styles';
const clonedeep = require('lodash.clonedeep')

export class InstataneousParameters extends Component {
  constructor(props) {
    super(props);
    this.state = {
      hover: false,
      displayGauges: {},
      features: ["Total Acceleration", "X-axis Velocity", "Y-axis Velocity", "Z-axis Velocity", "Temperature", "Audio"],
      featureData: {
        "Total Acceleration": "0001", "X-axis Velocity": "0002", "Y-axis Velocity": "0003", "Z-axis Velocity": "0004", "Temperature": "0005", "Audio": "0006"
      },
      option: {
        series: [{
          min: 0,
          max: 100,
          splitNumber: 6,
          title: { // All component : static
            offsetCenter: [0, '125%'],
            textStyle: {
              fontWeight: 'bolder',
              fontSize: 12,
              color: '#027be3',

            }
          },
          splitLine: {
            length: 10
          },
          axisLine: {
            lineStyle: {
              color: [[0.2, '#54CCEE'], [0.4, '#74DD00'], [0.8, '#F9C000'], [1, '#F5550E']],
              width: 5,
              shadowColor: '#fff',
              shadowBlur: 10
            }
          },
          axisLabel: {
            show: true,
            textStyle: {
              fontSize: 12
            },
            formatter: function (v) {
              // return Math.floor(v);
              return Math.round(v * 10) / 10;
            }
          },
          type: 'gauge',
          detail: {
            formatter: '{value}',// +gaugeData.unit,
            offsetCenter: ['0%', 50],
            textStyle: {
              color: 'auto',
              fontSize: 18,
              fontWeight: '200',
            }
          },
          data: []
        }]
      },
      guageList: [],
      dateTime:''
    };
  }
  componentDidUpdate(prevProps,prevState) {
    const { realTimeData, fingerprintRealTimeData, deviceId, monitorData } = this.props;
    if (prevProps.featureList !== this.props.featureList) {
      let gauges = [];
      this.props.featureList.forEach((ele) => {
        if (ele.type === "VELBAND") {
          let option1 = clonedeep(this.state.option)
          option1.series[0].data = [];
          // TODO@!: DO NOT REMOVE - Kept for future multilingual reference
          // let name = ele.viewValue + ele.unit;
          let name = this.props.t(ele.viewValue) + ele.unit;
          // TODO@!: DO NOT REMOVE - Kept for future multilingual reference
          // var nameArray = ele.viewValue.split(" ");
          var nameArray = this.props.t(ele.viewValue).split(" ");
          var featurename = "";
          nameArray.map((item) => {
            featurename = featurename.length > 18 && (featurename.length - featurename.lastIndexOf('\n')) > 18 ? featurename + "\n " + item : featurename + " " + item;
          })
          let length = [...name].length;
          option1.series[0].data.push({ value: 0, name: length > 15 ? `${featurename} \n ${ele.unit} ` : `${featurename}\n ${ele.unit} ` });
          // let color = [[0.2, '#54CCEE'],[0.4, '#74DD00'],[0.8, '#F9C000'],[1, '#F5550E']];
          // let threshold = this.props.thresholds[ele.value];
          let max = ele.caution + (ele.caution * (20 / 100));
          //   let min = parseFloat(ele.value).toFixed(2) < 0 ? parseFloat(ele.value).toFixed(2) - 5 : 0;
          option1.series[0].max = max;
          // option1.series[0].min = parseFloat(ele.value).toFixed(2) < 0 ? parseFloat(ele.value).toFixed(2) - 5 : 0;
          let colors = [];
          let colorplot = [];
          colorplot.push(ele.idle / max);
          colorplot.push('#54CCEE');
          colors.push(colorplot);
          colorplot = [];
          colorplot.push(ele.operational / max);
          colorplot.push('#74DD00');
          colors.push(colorplot);
          colorplot = [];
          colorplot.push(ele.caution / max);
          colorplot.push('#F9C000');
          colors.push(colorplot);
          colorplot = [];
          colorplot.push(max / max);
          colorplot.push('#F5550E');
          colors.push(colorplot);
          option1.series[0].axisLine.lineStyle.color = colors;
          option1.series[0].data[0].name=(option1.series[0].data[0].name).toLowerCase().includes("m/s") ? option1.series[0].data[0].name +"(rms)": option1.series[0].data[0].name
          gauges.push({ id: ele.value, type: ele.type, option: option1 });
        } else {
          let option1 = clonedeep(this.state.option)
          option1.series[0].data = [];
          // TODO@!: DO NOT REMOVE - Kept for future multilingual reference
          // let name = ele.viewValue + ele.unit;
          let name = this.props.t(ele.viewValue) + ele.unit;
          // TODO@!: DO NOT REMOVE - Kept for future multilingual reference
          // var nameArray = ele.viewValue.split(" ");
          var nameArray = this.props.t(ele.viewValue).split(" ");
          var featurename = "";
          nameArray.map((item, index) => {
            featurename = featurename.length > 18 && (featurename.length - featurename.lastIndexOf('\n')) > 18 && index < nameArray.length - 1 ? featurename + "\n " + item : featurename + " " + item;
          })
          let length = [...name].length;
          option1.series[0].data.push({ value: 0, name: length > 15 ? `${featurename} \n ${ele.unit} ` : `${featurename} \n ${ele.unit} ` });
          let max = 10;
          //  option1.series[0].min = parseFloat(ele.value).toFixed(2) < 0 ? parseFloat(ele.value).toFixed(2) - 5 : 0;
          option1.series[0].max = max;
          let colors = [];
          let colorplot = [];
          colorplot.push(10);
          colorplot.push('#000');
          colors.push(colorplot);
          option1.series[0].axisLine.lineStyle.color = colors;
          gauges.push({ id: ele.value, type: ele.type, option: option1 });
        }
      });
      this.setState({
        guageList: gauges
      });
    }
    if (prevProps.deviceId !== deviceId) {
      let gauges = [];
      this.props.featureList.forEach((ele) => {
        if (ele.type === "VELBAND") {
          let option1 = clonedeep(this.state.option)
          option1.series[0].data = [];
          // TODO@!: DO NOT REMOVE - Kept for future multilingual reference
          // let name = ele.viewValue + ele.unit;
          let name = this.props.t(ele.viewValue) + ele.unit;
          // TODO@!: DO NOT REMOVE - Kept for future multilingual reference
          // var nameArray = ele.viewValue.split(" ");
          var nameArray = this.props.t(ele.viewValue).split(" ");
          var featurename = "";
          nameArray.map((item, index) => {
            featurename = featurename.length > 18 && (featurename.length - featurename.lastIndexOf('\n')) > 18 && index < nameArray.length - 1 ? featurename + "\n " + item : featurename + " " + item;
          })
          let length = [...name].length;
          option1.series[0].data.push({ value: 0, name: length > 15 ? `${featurename} \n ${ele.unit} ` : `${featurename} \n ${ele.unit} ` });
          // let color = [[0.2, '#54CCEE'],[0.4, '#74DD00'],[0.8, '#F9C000'],[1, '#F5550E']];
          // let threshold = this.props.thresholds[ele.value];
          let max = ele.caution + (ele.caution * (20 / 100));
          let min = parseFloat(ele.value).toFixed(2) < 0 ? parseFloat(ele.value).toFixed(2) - 5 : 0;
          option1.series[0].min = parseFloat(ele.value).toFixed(2) < 0 ? parseFloat(ele.value).toFixed(2) - 5 : 0;
          option1.series[0].max = max;
          let colors = [];
          let colorplot = [];
          colorplot.push((ele.idle - min) / max);
          colorplot.push('#54CCEE');
          colors.push(colorplot);
          colorplot = [];
          colorplot.push(ele.operational / max);
          colorplot.push('#74DD00');
          colors.push(colorplot);
          colorplot = [];
          colorplot.push(ele.caution / max);
          colorplot.push('#F9C000');
          colors.push(colorplot);
          colorplot = [];
          colorplot.push(max / max);
          colorplot.push('#F5550E');
          colors.push(colorplot);
          option1.series[0].axisLine.lineStyle.color = colors;
          gauges.push({ id: ele.value, type: ele.type, option: option1 });

        } else {
          let option1 = clonedeep(this.state.option)
          option1.series[0].data = [];
          // TODO@!: DO NOT REMOVE - Kept for future multilingual reference
          // let name = ele.viewValue + ele.unit;
          let name = this.props.t(ele.viewValue) + ele.unit;
          let length = [...name].length;
          // TODO@!: DO NOT REMOVE - Kept for future multilingual reference
          // var nameArray = ele.viewValue.split(" ");
          var nameArray = this.props.t(ele.viewValue).split(" ");
          var featurename = "";
          nameArray.map((item) => {
            featurename = featurename.length > 18 && (featurename.length - featurename.lastIndexOf('\n')) > 18 ? featurename + "\n " + item : featurename + " " + item;
          })
          option1.series[0].data.push({ value: 0, name: length > 15 ? `${featurename} \n ${ele.unit} ` : `${featurename} \n ${ele.unit} ` });
          let max = 10;
          option1.series[0].max = max;
          option1.series[0].min = parseFloat(ele.value).toFixed(2) < 0 ? parseFloat(ele.value).toFixed(2) - 5 : 0;
          let colors = [];
          let colorplot = [];
          colorplot.push(max);
          colorplot.push('#000');
          colors.push(colorplot);
          option1.series[0].axisLine.lineStyle.color = colors;
          gauges.push({ id: ele.value, type: ele.type, option: option1 });
        }
      });
      this.setState({
        guageList: gauges
      });
    }
    if (this.state.guageList.length > 0 && prevProps.realTimeData !== realTimeData) {
      // The above check on guageList length is to ensure that guagelist array has atleast one element before real time data is displayed on the instantenuous parameter guages
      let gaugeData = clonedeep(this.state.guageList);
      let gauges = [];
      gaugeData.forEach((element) => {
        if (realTimeData.hasOwnProperty(element.id)) {
          element.option.series[0].data[0].value = parseFloat(realTimeData[element.id]).toFixed(2);
          var ele = this.props.featureList.find(x => x.value === element.id);
          let min = parseFloat(realTimeData[element.id]).toFixed(2) < 0 ? parseFloat(realTimeData[element.id]).toFixed(2) - 5 : 0;
          element.option.series[0].min = min;
          if (ele) {
            let max = ele.caution + (ele.caution * (20 / 100));
            let colors = [];
            let colorplot = [];
            colorplot.push((ele.idle - min) / (max - min));
            colorplot.push('#54CCEE');
            colors.push(colorplot);
            colorplot = [];
            colorplot.push((ele.operational - min) / (max - min));
            colorplot.push('#74DD00');
            colors.push(colorplot);
            colorplot = [];
            colorplot.push((ele.caution - min) / (max - min));
            colorplot.push('#F9C000');
            colors.push(colorplot);
            colorplot = [];
            colorplot.push(max / max);
            colorplot.push('#F5550E');
            colors.push(colorplot);
            element.option.series[0].axisLine.lineStyle.color = colors;
          }
          element.timestamp = realTimeData._time;
          gauges.push(element);
        } else {
          gauges.push(element);
        }
      })
      this.setState({
        guageList: gauges
      })
    }
    if (this.state.guageList.length > 0 && prevProps.fingerprintRealTimeData !== fingerprintRealTimeData) {
      // The above check on guageList length is to ensure that guagelist array has atleast one element before fingerprint real time data is displayed on the instantenuous parameter guages
      let gaugeData = clonedeep(this.state.guageList);
      let gauges = [];
      gaugeData.forEach((element) => {
        if (fingerprintRealTimeData.hasOwnProperty(element.id)) {
          element.option.series[0].min = parseFloat(fingerprintRealTimeData[element.id]).toFixed(2) < 0 ? parseFloat(fingerprintRealTimeData[element.id]).toFixed(2) - 5 : 0;
          element.option.series[0].data[0].value = parseFloat(fingerprintRealTimeData[element.id]).toFixed(6);
          element.timestamp = fingerprintRealTimeData._time;
          if (element.type === "PHASEDIFF") {
            element.option.series[0].max = parseFloat(fingerprintRealTimeData[element.id]).toFixed(6) <= 0 ? 5 : parseFloat(fingerprintRealTimeData[element.id]).toFixed(6) + 5;
            element.option.series[0].axisLine.lineStyle.color[0][0] = parseFloat(fingerprintRealTimeData[element.id]).toFixed(6) <= 0 ? 5 : parseFloat(fingerprintRealTimeData[element.id]).toFixed(6) + 5;
          } else {
            var ele = this.props.featureList.find(x => x.value === element.id);
            if (ele) {
              let min = parseFloat(realTimeData[element.id]).toFixed(2) < 0 ? parseFloat(realTimeData[element.id]).toFixed(2) - 5 : 0;
              let max = ele.caution + (ele.caution * (20 / 100));
              element.option.series[0].min = min;
              let colors = [];
              let colorplot = [];
              colorplot.push((ele.idle - min) / (max - min));
              colorplot.push('#54CCEE');
              colors.push(colorplot);
              colorplot = [];
              colorplot.push((ele.operational - min) / (max - min));
              colorplot.push('#74DD00');
              colors.push(colorplot);
              colorplot = [];
              colorplot.push((ele.caution - min) / (max - min));
              colorplot.push('#F9C000');
              colors.push(colorplot);
              colorplot = [];
              colorplot.push(max / max);
              colorplot.push('#F5550E');
              colors.push(colorplot);
              element.option.series[0].axisLine.lineStyle.color = colors;
            }
          }
          gauges.push(element);
        } else {
          gauges.push(element);
        }
      })
      this.setState({
        guageList: gauges
      })

    }
    if ((prevProps.monitorData !== monitorData) ||(prevProps.featureList !== this.props.featureList)) {

      const specs = monitorData && monitorData.data && monitorData.data.specsJson ? JSON.parse(monitorData.data.specsJson) : {};
      const data = {};
      if (specs.conditions) {
        var keys = Object.keys(specs.conditions);
        keys.map((item) => {
          if (this.state.features.includes(item)) {
            var code = this.state.featureData[item];
            var feature = this.props.featureList.find(x => x.value === code);
            if (feature) {
              data[feature.value] = specs.conditions[item].dashboardDial !== undefined && specs.conditions[item].dashboardDial !== null ? specs.conditions[item].dashboardDial : true;
            } else {
              data[code] = specs.conditions[item].dashboardDial !== undefined && specs.conditions[item].dashboardDial !== null ? specs.conditions[item].dashboardDial : true;
            }
          } else {
            data[item] = specs.conditions[item].dashboardDial !== undefined && specs.conditions[item].dashboardDial !== null ? specs.conditions[item].dashboardDial : true;
          }
        })
      }
      this.setState({
        displayGauges: data
      })

    }
    if(prevState.guageList !== this.state.guageList){
      this.state.guageList.map((element, index) =>{
        if(element && element.timestamp && element.id==="0001"){
          let time=moment(new Date(element.timestamp.replace(" ", 'T') + 'Z').toString()).format("DD MMM YYYY HH:mm a");
          if(prevState.dateTime !== time){
            this.setState({dateTime:time}) 
          }
        }

      })
    }
  }
  render() {  
    const row = this.props.widgetsData.widgetData.instaParameters;
    return (
      <>
      <div className="instataneous-parameters widgets-info widgets-shadow" >
      {/* style={{opacity:this.props.batteryFlag && "0.2",pointerEvents:this.props.batteryFlag && "none"}} */}
        <div className="widgets-heading widgets-heading-info">
          <h2 className="display-text">{row && this.props.t(row.header)}</h2>
          <span className="float-right">{this.props.deviceName}</span>
        </div>
        <div className="clearfix"></div>
        {this.state.guageList.length > 0 ?
          <span>{this.state.guageList.map((element, index) =>
            this.state.displayGauges[element.id] ?
              (<Tooltip key={index} title={element.timestamp ? moment(element.timestamp.replace(" ", 'T') + 'Z').local().toString() : ""}>
                <div className='reactgauge' style={{ display: 'inline-block' }} >
                  <ReactEcharts className="gauge" key={index} option={element.option} />
                </div>
              </Tooltip>)
              : ""
              )}</span> :
              <div className="nodata">{("No data to display")}</div>
            }
            {this.props.batteryFlag && this.state.dateTime!=='' &&<div style={{textAlign:'center',color:"#838383",fontWeight:'bold'}}>{this.props.t("Updated on")}: {this.state.dateTime}</div>}
      </div>
        {/* {this.props.batteryFlag && <div className='batteryMessage'>{this.props.t("Battery Device -No Instataneous Values")}</div>} */}
      </>
    )
  }
}

export default withTranslation()(InstataneousParameters);
