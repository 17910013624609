import * as actionTypes from '../actions/actionTypes';

export const lossDataReducer = (state={}, action) => {
    switch(action.type) {

        case actionTypes.SAVE_LOSS_DATA:
        return Object.assign({}, state, action.payload)

        default: return state
    }
}