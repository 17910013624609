import React, { Component, Fragment } from "react";
import { connect } from 'react-redux';
import { withStyles, IconButton, Tooltip, Button, Switch } from '@material-ui/core';
import MUIDataTable from "mui-datatables";
import { createMuiTheme, MuiThemeProvider } from '@material-ui/core/styles';
import { Edit, Launch, Unarchive, Delete } from '@material-ui/icons';

import * as actionCreators from '../../actions/actionCreators';
import { linux_deployment_platform } from '../../configuration';
import AlertsModal from './AlertsModal';
import CsvDownloadComponent from '../CsvDownloadComponent';
import CsvUploadComponent from '../CsvUploadComponent';
import ConfirmationModal from '../ConfirmationModal';
import { withTranslation } from "react-i18next";
let currentLanguage = localStorage.getItem("lang");
if (currentLanguage === "null" || currentLanguage === null) {
    currentLanguage = "en"
}

const styles = theme => ({
    addButton: {
        background: "#2abbe8",
        color: "#504a4a",
        fontSize: "12px",
        fontWeight: "500",
        "&:hover": {
            background: "#2abbe8a1"
        }
    },
    createNewButton: {
        fontWeight: '400',
        marginLeft: 12
    },
    editIcon: {
        'font-size': 18,
        cursor: 'pointer',
        color: 'rgba(0, 200, 83, 0.5)',
        '&:hover': {
            color: 'rgba(0, 200, 83, 1.0)'
        }
    },
    deleteIcon: {
        'font-size': 18,
        cursor: 'pointer',
        color: 'rgba(221, 44, 0, 0.5)',
        '&:hover': {
            color: 'rgba(221, 44, 0, 1.0)'
        }
    },
    unarchiveIcon: {
        'font-size': 18,
        cursor: 'pointer',
        color: 'rgba(0, 200, 83, 0.5)',
        '&:hover': {
            color: 'rgba(0, 200, 83, 1.0)'
        }
    },
    bulkEditArchiveIcon: {
        cursor: 'pointer',
        color: 'rgba(221, 44, 0, 0.5)',
        '&:hover': {
            color: 'rgba(221, 44, 0, 1.0)'
        }
    },
    bulkEditUnarchiveIcon: {
        cursor: 'pointer',
        color: 'rgba(0, 200, 83, 0.5)',
        '&:hover': {
            color: 'rgba(0, 200, 83, 1.0)'
        }
    },
    flexDiv: {
        display: "flex",
        flexDirection: "row-reverse",
        marginRight: "20px"
    },
    buttonContainer: {
        padding: '5px 10px 10px 0px',
        width: '100%'
    },
    saveButton: {
        backgroundColor: '#2abbe8',
        '&:hover': {
            backgroundColor: '#2abbe8',
        }
    },
    hideHeaderForActiveData: {
        '& thead tr th:nth-child(1)': {
            width: 10
        },
        '& tbody tr td:nth-of-type(1)': {
            padding: '4px 12px',
        },
        '& thead tr th:nth-child(2)': {
            width: 10,
            padding: '4px 15px'
        },
        '& tbody tr td:nth-of-type(2)': {
            padding: '4px 12px',
        },
        '& thead tr th:nth-child(3)': {
            width: 10,
            padding: '4px 30px'
        },
        '& tbody tr td:nth-of-type(3)': {
            padding: '4px 12px',
        }
    },
    hideHeaderForArchivedData: {
        '& thead tr th:nth-child(1)': {
            width: 10,
            padding: '4px 12px',
            color: '#fff'
        },
        '& tbody tr td:nth-of-type(1)': {
            padding: '4px 12px'
        },
        '& thead tr th:nth-child(2)': {
            width: 10,
            padding: '4px 12px',
            color: '#fff'
        },
        '& tbody tr td:nth-of-type(2)': {
            padding: '4px 12px'
        }
    },
    buttonLabel: {
        height: 24
    },
    iconBotton: {
        '&:hover': {
            color: '#3f51b5'
        }
    }
});

class AlertsTable extends Component {

    state = {
        columns: [],
        data: null,
        displayCreateButton: null,
        selectedRows: null,
        enableBulkEditingView: false,
        showArchivedData: false,
        headerClass: "",
        openPromptModal: false,
        rowsToBeArchived: [],
        lang: currentLanguage

    }

    getMuiTheme = () => createMuiTheme({
        overrides: {
            MUIDataTableHeadCell: {
                fixedHeader: {
                    'z-index': 0
                }
            },
            typography: {
                useNextVariants: true,
            },
            MUIDataTable: {
                responsiveScroll: {
                    height: 'calc(85vh - 150px)',
                    'max-height': 'calc(85vh - 150px)'
                }
            },
            MuiTableCell: {
                root: {
                    "padding-right": "38px !important"
                },
                head: {
                    fontSize: 14,
                    letterSpacing: 0.25
                },
                body: {
                    fontSize: 14,
                    letterSpacing: 0.25
                }
            }
        }
    })

    // handle routing for entity view
    navigateToEntityView = () => {
        this.props.navigateToEntityView("", this.props.location.pathname.split("/")[2]);
    }

    // handle delete functionality of selected record
    onDelete = (rowId, multipleDeletion) => {
        let rowsToBeArchived = [];
        if (multipleDeletion) {
            this.state.selectedRows.forEach(item => {
                rowsToBeArchived.push(item.id);
            })
        }
        else {
            rowsToBeArchived.push(rowId);
        }

        this.setState({
            rowsToBeArchived,
            openPromptModal: true
        })
    }

    // handle user action for deleting
    handlePromptModal = (action) => {
        if (action === "agree") {
            let selectedtab = this.props.userData.selectedTab;
            let selectedNavTab = this.props.location.pathname.split("/")[2];
            let currentScopeSelectionId = this.getCurrentScopeSelectionId(selectedtab);
            if (this.state.rowsToBeArchived.length > 0) {
                this.props.deleteRow(selectedNavTab, currentScopeSelectionId, this.state.rowsToBeArchived);
            }
        }
        this.setState({
            openPromptModal: false,
            rowsToBeArchived: []
        })
    }

    // handle unarchive functionality of selected records
    onRestore = (rowIndex, multipleRestore) => {
        let rowsToBeUnarchived = [];
        let selectedNavTab = this.props.location.pathname.split("/")[2];
        let selectedtab = this.props.userData.selectedTab;
        let currentScopeSelectionId = this.getCurrentScopeSelectionId(selectedtab);
        if (multipleRestore) {
            this.state.selectedRows.forEach(item => {
                rowsToBeUnarchived.push(item.id);
            })
        }
        else {
            rowsToBeUnarchived.push(rowIndex);
        }
        this.props.restoreEntity("AlertsTable", rowsToBeUnarchived, selectedNavTab, currentScopeSelectionId, false, this.state.showArchivedData);
    }

    // handle edit functionality of selected record
    onEdit = (rowIndex) => {
        let object = this.props.tableData.tableData.find((item, index) => {
            return index === rowIndex
        })
        this.props.navigateToEntityView(object, "AlertsEntity");
    }

    // handle routing for foreign keys in the table
    handleForeignKey = (rowIndex, entityKey) => {
        let object = this.props.tableData.tableData.find(item => {
            return item.id === rowIndex
        });
        let selectedNavTab = this.props.location.pathname.split("/")[2];

        if (entityKey === "userName") {
            object.user.admin = object.user.admin ? "true" : "false";
            this.props.navigateToEntityView(object.user, "UsersEntity", selectedNavTab);
        }
        else if (entityKey === "plantName") {
            this.props.navigateToEntityView(object.plants, "PlantsEntity", selectedNavTab);
        }
        else if (entityKey === "machineGroupName") {
            this.props.navigateToEntityView(object.machineGroups, "MachineGroupsEntity", selectedNavTab);
        }
        else if (entityKey === "machineName") {
            this.props.navigateToEntityView(object.machines, "MachinesEntity", selectedNavTab);
        }
        else if (entityKey === "assets") {
            if (object.assetsEntityName === "plantName") {
                this.props.navigateToEntityView(object.plants, "PlantsEntity", selectedNavTab);
            }
            else if (object.assetsEntityName === "machineGroupName") {
                this.props.navigateToEntityView(object.machineGroups, "MachineGroupsEntity", selectedNavTab);
            }
            else if (object.assetsEntityName === "machineName") {
                this.props.navigateToEntityView(object.machines, "MachinesEntity", selectedNavTab);
            }
        }
    }

    // gets the id of current plant selected in scope selector
    getCurrentScopeSelectionId = (selectedTab) => {
        let selectedScopeData = "";
        if (this.props.location.pathname.split("/").length === 3) {
            let currentEntity = this.props.location.pathname.split("/")[2];

            if (currentEntity === "OrganizationsTable" || currentEntity === "PlantsTable" || currentEntity === "UsersTable") {
                selectedScopeData = this.props.userData.selectedOrganization;
            }
            else if (currentEntity === "MachineGroupsTable" || currentEntity === "MachinesTable" || currentEntity === "SchedulesTable" ||
                currentEntity === "JobsTable" || currentEntity === "LossCodesTable" || currentEntity === "MonitorsTable" || currentEntity === "DevicesTable" ||
                currentEntity === "AlertsTable") {
                selectedScopeData = this.props.userData.selectedPlant;
            }
        }
        if (selectedScopeData) {
            return selectedScopeData;
        }
    }

    componentDidMount() {
        let selectedNavTab = this.props.location.pathname.split("/")[2];
        let selectedtab = this.props.userData.selectedTab;
        let currentScopeSelectionId = this.getCurrentScopeSelectionId(selectedtab);
        if (currentScopeSelectionId) {
            this.props.getTableData(selectedNavTab, currentScopeSelectionId, false, this.state.showArchivedData);
            this.displayCreateButton();
        }
    }

    // initializes table component based on the view-definitions received from the api call
    tableConfigurations = () => {
        let columns = [];
        let data = [];
        let currentColumnObject = {};

        if (this.props.tableData.tableData.length > 0 && Object.keys(this.props.config).length > 0) {

            if (this.props.userData.permission === 'configuration') {
                if (!this.state.showArchivedData) {
                    if (this.props.config.AlertExclusions.edit.table_visible) {
                        columns.push({
                            "name": "edit",
                            "label": this.props.config.AlertExclusions.edit.display_name,
                            "options": {
                                "display": true,
                                "filter": this.props.config.AlertExclusions.edit.filter_visible,
                                "sort": false,
                                "searchable": false
                            }
                        })
                    }
                    if (this.props.config.AlertExclusions.delete.table_visible) {
                        columns.push({
                            "name": "delete",
                            "label": this.props.config.AlertExclusions.delete.display_name,
                            "options": {
                                "display": true,
                                "filter": this.props.config.AlertExclusions.delete.filter_visible,
                                "sort": false,
                                "searchable": false
                            }
                        })
                    }
                }
                else {
                    if (this.props.config.AlertExclusions.delete.table_visible) {
                        columns.push({
                            "name": "delete",
                            "label": this.props.config.AlertExclusions.delete.display_name,
                            "options": {
                                "display": true,
                                "filter": this.props.config.AlertExclusions.delete.filter_visible,
                                "sort": false,
                                "searchable": false
                            }
                        })
                    }
                }
            }

            for (let key in this.props.tableData.tableData[0]) {
                let currentConfigObject = this.props.config["AlertExclusions"][key];
                if (currentConfigObject !== undefined) {
                    currentColumnObject.name = currentConfigObject["key"];
                    currentColumnObject.label = currentConfigObject["display_name"];
                    currentColumnObject.options = {};

                    if (currentConfigObject["table_visible"]) {
                        currentColumnObject.options.display = true;
                        if (currentConfigObject.name !== "assets") {
                            currentColumnObject.options.filter = currentConfigObject["filter_visible"];
                        }

                        if (currentConfigObject["ellipsis"]) {
                            currentColumnObject.options.customBodyRender = (value) => <div style={{ maxWidth: 150, overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}>{value}</div>
                            currentColumnObject.options.sort = false;
                            currentColumnObject.options.searchable = false;
                        }

                        if (currentConfigObject["table_hyperlink"] && !this.state.showArchivedData) {
                            currentColumnObject.options.customBodyRender = (value, tableMeta) => {
                                if (this.props.userData.permission === "configuration" && this.props.userData.admin) {
                                    if (currentConfigObject.key === "assets") {
                                        return (<a onClick={() => this.handleForeignKey(tableMeta.rowData[2], currentConfigObject["key"])}>{value.split("~*!")[0]}</a>);
                                    }
                                    else if (currentConfigObject.key === "machineGroupName") {
                                        for (let alert of this.props.tableData.tableData) {
                                            if (alert.machineGroups && alert.machineGroups.name === value) {
                                                if (alert.machineGroups.isActive) {
                                                    return (<a onClick={() => this.handleForeignKey(tableMeta.rowData[2], currentConfigObject["key"])}>{value}</a>);
                                                }
                                                else {
                                                    return (<span style={{ color: 'rgba(0, 0, 0, 0.38)' }}>{value}</span>)
                                                }
                                            }
                                        }
                                    }
                                    else if (currentConfigObject.key === "machineName") {
                                        for (let alert of this.props.tableData.tableData) {
                                            if (alert.machines && alert.machines.label === value) {
                                                if (alert.machines.isActive) {
                                                    return (<a onClick={() => this.handleForeignKey(tableMeta.rowData[2], currentConfigObject["key"])}>{value}</a>);
                                                }
                                                else {
                                                    return (<span style={{ color: 'rgba(0, 0, 0, 0.38)' }}>{value}</span>)
                                                }
                                            }
                                        }
                                    }
                                    else {
                                        return (<a onClick={() => this.handleForeignKey(tableMeta.rowData[2], currentConfigObject["key"])}>{value}</a>);
                                    }
                                }
                                else if (this.props.userData.permission === "configuration" && this.props.userData.admin === false) {
                                    if (currentConfigObject.key === "userName") {
                                        return value;
                                    }
                                    else if (currentConfigObject.key === "assets") {
                                        //console.log(value)
                                        if (value.split("~*!").length > 1) {
                                            return value.split("~*!")[0];
                                        }
                                        else {
                                            return (<a onClick={() => this.handleForeignKey(tableMeta.rowData[2], currentConfigObject["key"])}>{value.split("~*!")[0]}</a>);
                                        }
                                    }
                                    else if (currentConfigObject.key === "machineGroupName") {
                                        for (let alert of this.props.tableData.tableData) {
                                            if (alert.machineGroups && alert.machineGroups.name === value) {
                                                if (alert.machineGroups.isActive) {
                                                    return (<a onClick={() => this.handleForeignKey(tableMeta.rowData[2], currentConfigObject["key"])}>{value}</a>);
                                                }
                                                else {
                                                    return (<span style={{ color: 'rgba(0, 0, 0, 0.38)' }}>{value}</span>)
                                                }
                                            }
                                        }
                                    }
                                    else if (currentConfigObject.key === "machineName") {
                                        for (let alert of this.props.tableData.tableData) {
                                            if (alert.machines && alert.machines.label === value) {
                                                if (alert.machines.isActive) {
                                                    return (<a onClick={() => this.handleForeignKey(tableMeta.rowData[2], currentConfigObject["key"])}>{value}</a>);
                                                }
                                                else {
                                                    return (<span style={{ color: 'rgba(0, 0, 0, 0.38)' }}>{value}</span>)
                                                }
                                            }
                                        }
                                    }
                                    else {
                                        return (<a onClick={() => this.handleForeignKey(tableMeta.rowData[2], currentConfigObject["key"])}>{value}</a>);
                                    }
                                }
                                else {
                                    if (currentConfigObject.key === "assets") {
                                        return value.split("~*!")[0];
                                    }
                                    return value;
                                }
                            }
                        }
                    }
                    else {
                        currentColumnObject.options.display = "excluded";
                        currentColumnObject.options.filter = false;
                        currentColumnObject.options.sort = false;
                        currentColumnObject.options.searchable = false;
                    }

                    columns.push(currentColumnObject)
                    currentColumnObject = {};
                }
            }
        }

        data = this.props.tableData.tableData.map((item, index) => {

            item.edit = <Edit classes={{ root: this.props.classes.editIcon }} onClick={() => this.onEdit(index)} />;
            item.delete = this.state.showArchivedData ? <Unarchive classes={{ root: this.props.classes.unarchiveIcon }} onClick={() => this.onRestore(item.id, false)} />
                : <Delete classes={{ root: this.props.classes.deleteIcon }} onClick={() => this.onDelete(item.id, false)} />;

            return item;
        });


        if (this.state.showArchivedData) {
            data.forEach(item => {
                item.assets = item.assets.split("~*!")[0];
            })
        }

        this.setState({
            columns,
            data
        })

    }

    // display create new button based on user permissions
    displayCreateButton = () => {
        let flag = false;
        let plantPermisssion = localStorage.getItem("plantPermisssion")
        if (this.props.userData.permission === 'configuration' || plantPermisssion === 'configuration') {
            flag = true;
        }
        else if (this.props.userData.permission === "dashboard" && plantPermisssion === 'configuration') {
            flag = false
        }
        flag === true ?
            this.setState({ displayCreateButton: true })
            :
            this.setState({ displayCreateButton: false })
    }

    componentDidUpdate(prevProps, prevState) {
        let selectedtab = this.props.userData.selectedTab;
        let selectedNavTab = this.props.location.pathname.split("/")[2];
        if ((prevProps.tableData !== this.props.tableData)) {
            let headerClass = "";
            if (this.props.userData.permission === "configuration") {
                headerClass = this.state.showArchivedData ? this.props.classes.hideHeaderForArchivedData : this.props.classes.hideHeaderForActiveData;
            }
            this.setState({
                headerClass
            })
            if ((prevProps.selectedScopeData !== this.props.selectedScopeData)) {
                let currentScopeSelectionId = this.getCurrentScopeSelectionId(selectedtab);
                this.props.getTableData(selectedNavTab, currentScopeSelectionId, false, this.state.showArchivedData);
                this.displayCreateButton();
            }
            else {
                this.tableConfigurations();
            }
        }
        else if ((prevProps.selectedScopeData !== this.props.selectedScopeData && prevProps.selectedTab === this.props.selectedTab) || prevState.showArchivedData !== this.state.showArchivedData) {
            let currentScopeSelectionId = this.getCurrentScopeSelectionId(selectedtab);
            if (currentScopeSelectionId) {
                this.props.getTableData(selectedNavTab, currentScopeSelectionId, false, this.state.showArchivedData);
                this.displayCreateButton();
            }
        }

    }

    // handle switch for displaying archived/active data
    handleSwitch = (event, checked) => {
        this.setState({
            showArchivedData: checked
        })
    }

    // purpose : create csv upload/download icons,user can create new Alertss through csv by editing in csv file before uploading 
    //It uses  <CsvDownloadComponent/> and <CsvUploadComponent/>  which is imported from "../CsvUploadComponent" and "../CsvDownloadComponent"
    //This function also creates "Create NEW" button (for creating new Alerts through UI), which calls navigateToEntityView() function when clicked
    getCustomToolbar = () => {
        if (this.state.displayCreateButton) {
            return (
                <div style={{ display: 'inline-block' }}>
                    {
                        this.props.userData.admin && !this.state.showArchivedData ?
                            (
                                <Fragment>
                                    <IconButton className={this.props.classes.iconBotton}>
                                        <CsvDownloadComponent
                                            parentOrganizationId={this.props.userData.parentOrganizationId}
                                            url={"downloadCSVForAlert"}
                                            parentComponent={"AlertsTable"}
                                            selectedPlant={this.props.userData.selectedPlant}
                                        />
                                    </IconButton>
                                    <IconButton classes={{ label: this.props.classes.buttonLabel }} className={this.props.classes.iconBotton}>
                                        <CsvUploadComponent
                                            selectedNavTab={"AlertsTable"}
                                            currentScopeSelectionId={this.props.userData.selectedPlant}
                                        />
                                    </IconButton>
                                </Fragment>
                            )
                            : null
                    }
                    {/* <Tooltip title={this.state.showArchivedData ? this.props.t(" Show Active Data") : this.props.t("Show Archived Data")}>
                        <Switch
                            checked={this.state.showArchivedData}
                            color="primary"
                            disableRipple={false}
                            onChange={this.handleSwitch}
                        />
                    </Tooltip> */}
                    <Button variant="outlined" className={this.props.classes.createNewButton} style={{ marginLeft: 12 }}
                        onClick={() => this.navigateToEntityView("", this.props.location.pathname.split("/")[2])}>
                        {this.props.t("Create New")}
                    </Button>
                </div>
            )
        }
        else {
            return null;
        }
    }

    // update selected records for bulk editing
    updateSelectedRecords = (updatedObject) => {
        for (let row of this.state.selectedRows) {
            if (updatedObject.changedAlertTypes) {
                row.alertTypes = updatedObject.alertTypes;
            }
            if (updatedObject.changedUserName) {
                row.user = updatedObject.userName;
            }
            if (updatedObject.changedSeverity) {
                row.severity = updatedObject.severity;
            }
            /*if (updatedObject.changedPlant) {
                row.plants = updatedObject.plants;
            }*/
            if (updatedObject.changedPlant || updatedObject.changedMachine || updatedObject.changedMachineGroup) {
                row.machines = updatedObject.machine;
                row.machineGroups = updatedObject.machineGroup;
                row.plants = updatedObject.plants;
            }
            /*if (updatedObject.changedMachineGroup) {
                row.machineGroups = updatedObject.machineGroup;
            }*/
            if (updatedObject.changedNotes) {
                row.notes = updatedObject.notes;
            }
        }

        let selectedNavTab = this.props.location.pathname.split("/")[2];
        let selectedtab = this.props.userData.selectedTab;
        let currentScopeSelectionId = this.getCurrentScopeSelectionId(selectedtab);

        this.props.updateAll(selectedNavTab, currentScopeSelectionId, this.state.selectedRows);
    }

    // enable bulk editing view
    enableBulkEditingView = () => {
        this.setState({
            enableBulkEditingView: true
        })
    }

    // disable bulk editing view
    disableBulkEditingView = () => {
        this.setState({
            enableBulkEditingView: false
        })
    }

    // create custom toolbar for table view
    getCustomToolbarSelect = () => {
        return (
            <div>
                <IconButton className="class-mk-find" className={this.state.showArchivedData ? 'custom-hideSvg' : null} onClick={this.state.showArchivedData ? () => { return false } : this.enableBulkEditingView}>
                    {this.state.showArchivedData ? null : <Launch />}
                </IconButton>
                <IconButton style={{ marginRight: 14 }} onClick={this.state.showArchivedData ?
                    () => this.onRestore(undefined, true)
                    : () => this.onDelete(undefined, true)
                }>
                    {
                        this.state.showArchivedData ?
                            <Unarchive classes={{ root: this.props.classes.bulkEditUnarchiveIcon }} />
                            : <Delete classes={{ root: this.props.classes.bulkEditArchiveIcon }} />
                    }
                </IconButton>
            </div>
        )
    }

    // handle multiple record selection
    selectedRows = (currentRowsSelected, allRowsSelected) => {
        let selectedRows = [];
        for (let data of allRowsSelected) {
            let selectedIndex = data.dataIndex;
            let selectedObject = this.state.data[selectedIndex];

            let newObject = {};
            newObject.alertTypes = selectedObject.alertTypes ? selectedObject.alertTypes.id : null;
            newObject.id = selectedObject.id;
            newObject.machineGroups = selectedObject.machineGroups ? selectedObject.machineGroups.id : null;
            newObject.machines = selectedObject.machines ? selectedObject.machines.id : null;
            newObject.plants = selectedObject.plants ? selectedObject.plants.id : null;
            newObject.user = selectedObject.user ? selectedObject.user.id : null;
            newObject.notes = selectedObject.notes;
            newObject.severity = selectedObject.severity;

            selectedRows.push(newObject)
        }

        this.setState({
            selectedRows
        })
    }

    // validate multiple record selection based on user permissions
    isRowSelectable = () => {
        let isSelectable = linux_deployment_platform ? "none" : false;
        if (this.state.data && this.state.data.length > 0) {
            if (this.props.userData.permission === "configuration") {
                isSelectable = this.state.data.length === 0 ? (linux_deployment_platform ? "none" : false) : "multiple";
            }
        }
        return isSelectable;
    }

    render() {
        const options = {
            filter: true,
            sort: true,
            search: true,
            customToolbar: () => (this.getCustomToolbar()),
            print: false,
            elevation: 0,
            responsive: "scroll",
            download: false,
            filterType: 'dropdown',
            rowsPerPage: 20,
            rowsPerPageOptions: [20, 50, 100],
            selectableRows: this.isRowSelectable(),
            onRowsSelect: (currentRowsSelected, allRowsSelected) => this.selectedRows(currentRowsSelected, allRowsSelected),
            customToolbarSelect: () => (this.getCustomToolbarSelect()),
            textLabels: {
                body: {
                    noMatch: this.props.t("No matching records")
                },
                pagination: {
                    next: this.props.t("Next Page"),
                    previous: this.props.t("Previous Page"),
                    rowsPerPage: this.props.t("Rows per Page"),
                    displayRows: this.props.t("of")
                },
                toolbar: {
                    search: this.props.t("Search"),
                    download: this.props.t("Download"),
                    print: this.props.t("Print"),
                    viewColumns: this.props.t("View Columns"),
                    filterTable: this.props.t("Filter Table"),
                    upload: this.props.t("Upload"),
                    // download:currentLanguage==="en" ? "Download":"アップロード",
                    showArchivedData: this.props.t("Show Archieved Data"),
                    showActiveData: this.props.t("Show Active Data")


                },
                filter: {
                    title: this.props.t("FILTERS"),
                    reset: this.props.t("Reset")
                },
                viewColumns: {
                    title: this.props.t("Show Columns")
                },
                selectedRows: {
                    text: this.props.t("Reset"),
                    delete: this.props.t("Delete")
                }

            }
        }

        return (
            <Fragment>
                <ConfirmationModal
                    props={this.props}
                    openPromptModal={this.state.openPromptModal}
                    handlePromptModal={(action) => this.handlePromptModal(action)}
                    delete={true}
                />
                <div id="tableContainer" className={this.state.headerClass}>
                    <MuiThemeProvider theme={this.getMuiTheme()}>
                        {
                            this.state.data !== null ? (
                                <MUIDataTable
                                    title={this.props.t("Alert Exclusions")}
                                    data={this.state.data}
                                    columns={this.state.columns}
                                    options={options}
                                />
                            ) : <Fragment></Fragment>
                        }
                    </MuiThemeProvider>
                </div>
                {
                    this.state.enableBulkEditingView ?
                        <AlertsModal
                            config={this.props.config.AlertExclusions}
                            selectedPlant={this.props.userData.selectedPlant}
                            updateSelectedRecords={(state) => (this.updateSelectedRecords(state))}
                            disableBulkEditingView={this.disableBulkEditingView}
                            enableBulkEditingView={this.state.enableBulkEditingView} />
                        : null
                }
            </Fragment >
        );
    }
}

const mapStateToProps = state => {
    return {
        userData: state.userDataReducer,
        tableData: state.tableDataReducer
    }
}

const mapDispatchToProps = dispatch => {
    return {
        getTableData: (selectedNavtab, currentScopeSelectionId, setToStore, showArchivedData) => (dispatch(actionCreators.getTableData(selectedNavtab, currentScopeSelectionId, setToStore, showArchivedData))),
        deleteRow: (selectedNavTab, currentScopeSelectionId, requestObject) => (dispatch(actionCreators.deleteRow(selectedNavTab, currentScopeSelectionId, requestObject))),
        updateAll: (selectedNavTab, currentScopeSelectionId, allRecords) => (dispatch(actionCreators.updateAll(selectedNavTab, currentScopeSelectionId, allRecords))),
        restoreEntity: (entityName, rowsToBeUnarchived, selectedNavtab, currentScopeSelectionId, setToStore, showArchivedData) => (dispatch(actionCreators.restoreEntity(entityName, rowsToBeUnarchived, selectedNavtab, currentScopeSelectionId, setToStore, showArchivedData)))
    }
}

export default withTranslation()(connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(AlertsTable)));
