import React, { Component } from 'react';
import * as actionCreators from '../../actions/actionCreators';
import { connect } from 'react-redux';
import { KeyboardBackspace } from '@material-ui/icons';
import {
    Card,
    CardContent,
    InputLabel,
    TextField,
    Select,
    withStyles,
    OutlinedInput,
    Button,
    Grid,
    Radio
} from '@material-ui/core';
import { withTranslation } from "react-i18next";


const styles = theme => ({
    cardContent: {
        height: '91%',
        "overflow-y": "auto"
    },
    cardContentRoot: {
        '&:last-child': {
            paddingBottom: 16
        }
    },
    card: {
        width: '100%',
        borderRadius: 0,
        height: '80vh'
    },
    textField: {
        width: '60%',
        marginRight: 20
    },
    textArea: {
        width: '80%',
        marginRight: 20
    },
    outlinedInput: {
        padding: 14
    },
    saveButton: {
        fontSize: "14px",
        fontWeight: "500",
        textAlign: "center",
        color: "#ffffff",
        backgroundColor: "#42D784",
        '&:hover': {
            backgroundColor: '#42d784'
        },
        '&:disabled': {
            backgroundColor: '#f6f6f6'
        },
        borderRadius: "3px",
        boxShadow: "0 0 1px 0 rgba(0, 0, 0, 0.05)",
        width: "110px",
        height: "36px",
        marginRight: "24px"
    },
    inputFieldDiv: {
        display: "flex",
        justifyContent: "space-between",
        width: '50%',
        float: 'left',
        paddingTop: '1%',
        height: 80,
        'align-items': 'baseline'
    },
    textareaInputFieldDiv: {
        display: "flex",
        justifyContent: "space-between",
        width: '100%',
        float: 'left',
        paddingTop: '1%',
        height: 180,
        'align-items': 'baseline'
    },
    textFieldLabel: {
        color: 'rgba(0, 0, 0, 0.4000000059604645)',
        'letter-spacing': 0.5,
        'font-weight': 500,
        fontSize: "14px",
        lineHeight: 2.5,
        width: '30%',
        marginLeft: 20,
        //text-align': 'right'
    },
    textareaFieldLabel: {
        color: 'rgba(0, 0, 0, 0.4000000059604645)',
        'letter-spacing': 0.5,
        'font-weight': 500,
        fontSize: "14px",
        lineHeight: 2.5,
        width: '15%',
        marginLeft: 20,
        //'text-align': 'right'
    },
    requiredClass: {
        color: '#d32f2f'
    },
    actionButtonContainer: {
        display: 'flex',
        'justify-content': 'space-between',
        height: 50,
        'border-bottom': '1px solid rgba(207, 216, 220, 0.5)'
    },
    backButtonContainer: {
        display: 'flex',
        'align-items': 'center',
        paddingLeft: 21
    },
    arrowBack: {
        cursor: 'pointer'
    },
    entityTitle: {
        color: 'rgba(0, 0, 0, 0.8)',
        'font-family': 'Roboto',
        'font-size': 21,
        'font-weight': '400',
        'letter-spacing': 0.5,
        marginLeft: 10
    }
})

class AlertsEntity extends Component {

    state = {
        id: "",
        plants: "",
        plantName: "",
        disableSaveButton: true,
        isUserAdmin: false,
        machineGroups: "",
        machines: "",
        notes: "",
        alertTypes: "",
        addNew: true,
        organizationId: "",
        severity: "",
        user: "",
        userName: "",
        labelWidth: 0,
        notesError: false,
        machineGroupError: false,
        machinesError: false,
        alertTypeError: false,
        severityError: false,
        userError: false,
        selectedPlantsRadioButton: false,
        selectedMachineGroupsRadioButton: false,
        selectedMachinesRadioButton: false
    }

    // Handle changes to any user input field
    inputChangeHandler = (event, field) => {

        switch (field) {
            case "machineGroups": this.setState({ machineGroups: event.target.value, disableSaveButton: false });
                this.checkValidation("machineGroups", event.target.value);
                break;
            case "machines": this.setState({ machines: event.target.value, disableSaveButton: false });
                this.checkValidation("machines", event.target.value);
                break;
            case "notes": this.setState({ notes: event.target.value, disableSaveButton: false });
                this.checkValidation("notes", event.target.value);
                break;
            case "alertTypes": this.setState({ alertTypes: event.target.value, disableSaveButton: false });
                this.checkValidation("alertTypes", event.target.value);
                break;
            case "severity": this.setState({ severity: event.target.value, disableSaveButton: false });
                this.checkValidation("severity", event.target.value);
                break;
            case "user": this.setState({ user: event.target.value, disableSaveButton: false });
                this.checkValidation("user", event.target.value);
                break;
            default: console.log("default case");
        }
    }

    // Handle assets inputs
    handleRadioButton = (event, checked) => {
        switch (event.target.name) {
            case "plants":
                this.setState({
                    selectedPlantsRadioButton: checked,
                    selectedMachineGroupsRadioButton: false,
                    machineGroups: "",
                    selectedMachinesRadioButton: false,
                    machines: "",
                    disableSaveButton: false
                });
                break;
            case "machineGroups":
                this.setState({
                    selectedPlantsRadioButton: false,
                    selectedMachineGroupsRadioButton: checked,
                    selectedMachinesRadioButton: false,
                    machines: "",
                    disableSaveButton: false
                });
                break;
            case "machines":
                this.setState({
                    selectedPlantsRadioButton: false,
                    selectedMachineGroupsRadioButton: false,
                    machineGroups: "",
                    selectedMachinesRadioButton: checked,
                    disableSaveButton: false
                });
                break;
            default: console.log("default case reached");
        }
    }

    // Handle routing
    goToBackPage = () => {
        this.props.history.goBack();
    }

    // update alerts entity
    updateAlertsData = () => {
        let { id, plants, machineGroups, machines, notes, name, alertTypes, severity, user } = this.state;
        machineGroups = machineGroups === "" ? null : machineGroups;
        machines = machines === "" ? null : machines;
        if (id) {
            this.props.updateAlertsEntity({ id, plants, machineGroups, machines, notes, name, alertTypes, severity, user }, id);
        } else {
            this.props.updateAlertsEntity({ plants, machineGroups, machines, notes, name, alertTypes, severity, user });
        }

    }

    // create machine-groups dropdown list
    getMachineGroups = () => {
        let machineGroupList = [];
        machineGroupList.push(<option disabled={true} key={""} value={""}>{"Select"}</option>);
        if ((this.state.validationData.assets.validations && !this.state.validationData.assets.validations.required) || !(this.state.validationData.assets.validations)) {
            machineGroupList.push(<option key={""} value={""}>{"None"}</option>);
        }
        if (this.props.machineGroupData && this.props.machineGroupData.tableData && this.props.machineGroupData.tableData.length > 0) {
            this.props.machineGroupData.tableData.map((data) => {
                machineGroupList.push(
                    <option key={data.id} value={data.id}>{data.name}</option>
                );
            })
        }
        return machineGroupList;
    }

    // create machines dropdown list
    getMachines = () => {
        let machinesList = [];
        machinesList.push(<option disabled={true} key={""} value={""}>{"Select"}</option>);
        if ((this.state.validationData.assets.validations && !this.state.validationData.assets.validations.required) || !(this.state.validationData.assets.validations)) {
            machinesList.push(<option key={""} value={""}>{"None"}</option>);
        }
        if (this.props.machinesData && this.props.machinesData.tableData && this.props.machinesData.tableData.length > 0) {
            this.props.machinesData.tableData.map((data) => {
                machinesList.push(
                    <option key={data.id} value={data.id}>{data.label}</option>
                );
            })
        }
        return machinesList;
    }

    // create users dropdown list
    getUsers = () => {
        let usersList = [];
        usersList.push(<option disabled={true} key={""} value={""}>{"Select"}</option>);
        if ((this.state.validationData.userName.validations && !this.state.validationData.userName.validations.required) || !(this.state.validationData.userName.validations)) {
            usersList.push(<option key={""} value={""}>{"None"}</option>);
        }
        if (this.props.usersData && this.props.usersData.tableData && this.props.usersData.tableData.length > 0) {
            this.props.usersData.tableData.map((data) => {
                usersList.push(
                    <option key={data.id} value={data.id}>{data.emailAddress}</option>
                );
            })
        }
        return usersList;
    }

    // create alert-types dropdown list
    getAlertTypes = () => {
        let alertTypeList = [];
        alertTypeList.push(<option key={""} disabled={true} value={""}>{"Select"}</option>);
        if ((this.state.validationData.alertTypeName.validations && !this.state.validationData.alertTypeName.validations.required) || !(this.state.validationData.alertTypeName.validations)) {
            alertTypeList.push(<option key={""} value={""}>{"None"}</option>);
        }
        if (this.state.organizationId && this.state.organizationId != "" && this.props.staticData && this.props.staticData.staticData) {
            let plantOrganizationStaticData = this.props.staticData.staticData[this.props.userData.parentOrganizationId]
            if (plantOrganizationStaticData && plantOrganizationStaticData.alerttypes) {
                plantOrganizationStaticData.alerttypes.map((data) => {
                    alertTypeList.push(
                        <option key={data.id} value={data.id}>{data.name}</option>
                    );
                })
            }
        }
        return alertTypeList;
    }

    // set selected plant's organization id in the state
    getOrganizationFromPlantId = () => {
        let found = false;
        let plantOrganization = "";
        let plantId = "";
        if (this.state.plants && this.state.plants != "") {
            plantId = this.state.plants
        }
        else {
            plantId = this.props.selectedScopeData;
        }
        if (plantId && plantId != "") {
            this.props.scopeSelector.subOrganizations.map((organization) => {
                if (organization.plants && organization.plants.length > 0) {
                    organization.plants.map((plant) => {
                        if (plant.id === plantId && !found) {
                            plantOrganization = organization.id;
                            found = true;
                        }
                    })
                }
            })
        }
        this.setState({ organizationId: plantOrganization })
    }

    // get selected plant's name from organization data
    getPlantNamefromOrganization = () => {
        if (this.props.selectedScopeData || this.props.selectedScopeData == "") {
            let tempdata = "";
            let found = false;
            this.props.scopeSelector.subOrganizations.map((organization) => {
                if (organization.plants && organization.plants.length > 0) {
                    organization.plants.map((plant) => {
                        if (plant.id === this.props.selectedScopeData && !found) {
                            tempdata = plant.name;
                            found = true;
                        }
                    })
                }
            })
            this.setState({
                plants: this.props.selectedScopeData,
                plantName: tempdata,
            })
        }
    }

    componentDidMount() {
        if (this.props.data) {
            let entityNames = Object.keys(this.props.data)
            entityNames.map((data) => {
                if (this.props.data[data] == null) {
                    this.props.data[data] = ""
                }
            })
            this.setState({
                severity: this.props.data.severity,
                addNew: false

            })

            if (this.props.data.id) {
                this.setState({ id: this.props.data.id })
            }

            if (this.props.data.specs && this.props.data.specs != "") {
                if (this.props.data.specs.props) {
                    this.setState({ specs: this.props.data.specs.props.children })
                }
                else {
                    this.setState({ specs: this.props.data.specs })
                }
            }
            if (this.props.data.notes && this.props.data.notes != "") {
                if (this.props.data.notes.props) {
                    this.setState({ notes: this.props.data.notes.props.children })
                }
                else {
                    this.setState({ notes: this.props.data.notes })
                }
            }

            if (this.props.data.plants && this.props.data.plants.id) {
                this.setState({
                    plants: this.props.data.plants.id,
                    plantName: this.props.data.plants.name
                })

                if (this.props.data.machineGroups && this.props.data.machineGroups.id) {
                    this.setState({
                        machineGroups: this.props.data.machineGroups.id,
                        selectedMachineGroupsRadioButton: true
                    })
                }
                else if (this.props.data.machines && this.props.data.machines.id) {
                    this.setState({
                        machines: this.props.data.machines.id,
                        selectedMachinesRadioButton: true
                    })
                }
                else {
                    this.setState({
                        selectedPlantsRadioButton: true
                    })
                }
            }

            if (this.props.data.user && this.props.data.user.id) {
                this.setState({
                    user: this.props.data.user.id,
                    userName: this.props.data.user.userName
                })
                if (this.props.data.user) {
                    this.setState({ isUserAdmin: this.props.userData.admin })
                }
            }
            if (this.props.data.alertTypes && this.props.data.alertTypes.id) {
                this.setState({ alertTypes: this.props.data.alertTypes.id })
                if (this.props.data.alertTypes.organization && this.props.data.alertTypes.organization.id) {
                    this.setState({ organizationId: this.props.data.alertTypes.organization.id })
                }
                else {
                    this.getOrganizationFromPlantId()
                }
            }
        }
        else {
            this.getPlantNamefromOrganization();
            // if (this.props.userData && this.props.userData.id && this.props.userData.userName) {
            //     this.setState({
            //         user: this.props.userData.id,
            //         userName: this.props.userData.userName,
            //         isUserAdmin: this.props.userData.admin
            //     })
            // }
            this.getOrganizationFromPlantId()
        }
        if (!this.props.data) {
            this.setState({ addNew: true })
        }

        if (this.props.config && this.props.config.AlertExclusions) {
            this.setState({ validationData: this.props.config.AlertExclusions });
        }
    }

    componentDidUpdate(prevProps, prevState) {
        if (this.state.plants && (prevState.plants !== this.state.plants)) {
            this.props.getTableData("MachineGroupsTable", this.state.plants, true);
            this.props.getTableData("MachinesTable", this.state.plants, true);
            this.props.getTableData("AlertsUsersTable", this.state.plants, true);

        }
    }

    // disable save button based on validations
    disableSaveButton = () => {
        if (this.state.user === "" && (this.state.validationData.userName.validations && this.state.validationData.userName.validations.required)) {
            return true;
        }
        if (this.state.machineGroups === "" && (this.state.validationData.machineGroupName.validations && this.state.validationData.machineGroupName.validations.required)) {
            return true;
        }
        else if (this.state.alertTypes === "" && (this.state.validationData.alertTypeName.validations && this.state.validationData.alertTypeName.validations.required)) {
            return true;
        }
        else if (this.state.machines === "" && (this.state.validationData.machineName.validations && this.state.validationData.machineName.validations.required)) {
            return true;
        }
        else if (this.state.severity === "" && (this.state.validationData.severity.validations && this.state.validationData.severity.validations.required)) {
            return true;
        }
        else if (this.state.notes === "" && (this.state.validationData.notes.validations && this.state.validationData.notes.validations.required)) {
            return true;
        }
        else if (this.state.notesError || this.state.machineGroupError || this.state.machinesError || this.state.alertTypeError || this.state.severityError || this.state.userError) {
            return true;
        }
        else if (!this.state.selectedPlantsRadioButton && !this.state.selectedMachineGroupsRadioButton && !this.state.selectedMachinesRadioButton) {
            return true;
        }
        else if (this.state.selectedPlantsRadioButton && !this.state.plants) {
            return true;
        }
        else if (this.state.selectedMachineGroupsRadioButton && !this.state.machineGroups) {
            return true;
        }
        else if (this.state.selectedMachinesRadioButton && !this.state.machines) {
            return true;
        }
        else {
            return false;
        }
    }

    // set card header
    getCardHeader = () => {
        return "Alert Exclusions"
    }

    // check validations of the user input fields
    checkValidation = (field, value) => {
        switch (field) {
            case "machineGroups":
                if (!this.state.machineGroupError && value === "" && (this.state.validationData.machineGroupName.validations && this.state.validationData.machineGroupName.validations.required)) {
                    this.setState({ machineGroupError: true });
                } else if (this.state.machineGroupError) {
                    if ((value === "" && (this.state.validationData.machineGroupName.validations && this.state.validationData.machineGroupName.validations.required))) {
                        //skip
                    }
                    else {
                        this.setState({ machineGroupError: false });
                    }
                };
                break;
            case "machines":
                if (!this.state.machinesError && value === "" && (this.state.validationData.machineName.validations && this.state.validationData.machineName.validations.required)) {
                    this.setState({ machinesError: true });
                } else if (this.state.machinesError) {
                    if ((value === "" && (this.state.validationData.machineName.validations && this.state.validationData.machineName.validations.required))) {
                        //skip
                    }
                    else {
                        this.setState({ machinesError: false });
                    }
                };
                break;
            case "alertTypes":
                if (!this.state.alertTypeError && value === "" && (this.state.validationData.alertTypeName.validations && this.state.validationData.alertTypeName.validations.required)) {
                    this.setState({ alertTypeError: true });
                } else if (this.state.alertTypeError) {
                    if ((value === "" && (this.state.validationData.alertTypeName.validations && this.state.validationData.alertTypeName.validations.required))) {
                        //skip
                    }
                    else {
                        this.setState({ alertTypeError: false });
                    }
                };
                break;
            case "severity":
                if (!this.state.severityError && value === "" && (this.state.validationData.severity.validations && this.state.validationData.severity.validations.required)) {
                    this.setState({ severityError: true });
                } else if (this.state.severityError) {
                    if ((value === "" && (this.state.validationData.severity.validations && this.state.validationData.severity.validations.required))) {
                        //skip
                    }
                    else {
                        this.setState({ severityError: false });
                    }
                };
                break;
            case "user":
                if (this.state.isUserAdmin) {
                    if (!this.state.userError && value === "" && (this.state.validationData.userName.validations && this.state.validationData.userName.validations.required)) {
                        this.setState({ userError: true });
                    } else if (this.state.userError) {
                        if ((value === "" && (this.state.validationData.userName.validations && this.state.validationData.userName.validations.required))) {
                            //skip
                        }
                        else {
                            this.setState({ userError: false });
                        }
                    };
                }
                break;
            case "notes":
                if (!this.state.notesError && value === "" && (this.state.validationData.notes.validations && this.state.validationData.notes.validations.required)) {
                    this.setState({ notesError: true });
                } else if (!this.state.notesError && (this.state.validationData.notes.validations) && (value.length > this.state.validationData.notes.validations.maxLength)) {
                    this.setState({ notesError: true });
                } else if (this.state.notesError) {
                    if ((value === "" && (this.state.validationData.notes.validations && this.state.validationData.notes.validations.required) || ((this.state.validationData.notes.validations) && (value.length > this.state.validationData.notes.validations.maxLength)))) {
                        //skip
                    }
                    else {
                        this.setState({ notesError: false });
                    }
                };
                break;
        }
    }

    render() {
        return (
            <Card className={this.props.classes.card}>
                <div className={this.props.classes.actionButtonContainer}>
                    <div className={this.props.classes.backButtonContainer}>
                        <KeyboardBackspace onClick={this.goToBackPage} classes={{ root: this.props.classes.arrowBack }} />
                        <div className={this.props.classes.entityTitle}>{this.getCardHeader()}</div>
                    </div>
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                        <Button variant="contained"
                            className={this.props.classes.saveButton}
                            disabled={this.state.disableSaveButton || this.disableSaveButton()}
                            onClick={this.updateAlertsData}>{this.props.t("Save")}</Button>
                    </div>
                </div>
                <CardContent className={this.props.classes.cardContent} classes={{ root: this.props.classes.cardContentRoot }}>
                    {this.state.validationData && this.state.validationData.alertTypeName && this.state.validationData.alertTypeName.page_visible ? (
                        <div className={this.props.classes.inputFieldDiv}>
                            <InputLabel className={this.props.classes.textFieldLabel}>
                                {this.state.validationData.alertTypeName.display_name}
                                {(this.state.validationData.alertTypeName.validations && this.state.validationData.alertTypeName.validations.required) ? (<span className={this.props.classes.requiredClass}>*</span>) : null}
                            </InputLabel>
                            <Select
                                native
                                value={this.state.alertTypes}
                                onChange={(event) => this.inputChangeHandler(event, "alertTypes")}
                                input={
                                    <OutlinedInput
                                        variant="outlined"
                                        name="alertTypes"
                                        id="language-native-simple"
                                        labelWidth={this.state.labelWidth}
                                        className={this.props.classes.textField}
                                        error={this.state.alertTypeError}
                                        classes={{ input: this.props.classes.outlinedInput }}
                                    />
                                }
                            >
                                {this.getAlertTypes()}
                            </Select>
                        </div>
                    )
                        : null
                    }
                    {this.state.validationData && this.state.validationData.userName && this.state.validationData.userName.page_visible ? (
                        <div className={this.props.classes.inputFieldDiv}>
                            <InputLabel className={this.props.classes.textFieldLabel}>
                                {this.state.validationData.userName.display_name}
                                {(this.state.validationData.userName.validations && this.state.validationData.userName.validations.required) ? (<span className={this.props.classes.requiredClass}>*</span>) : null}
                            </InputLabel>
                            <Select
                                native
                                value={this.state.user}
                                onChange={(event) => this.inputChangeHandler(event, "user")}
                                input={
                                    <OutlinedInput
                                        variant="outlined"
                                        name="user-select"
                                        id="user-native-simple"
                                        labelWidth={this.state.labelWidth}
                                        className={this.props.classes.textField}
                                        error={this.state.userError}
                                        //disabled={!this.state.isUserAdmin}
                                        classes={{ input: this.props.classes.outlinedInput }}
                                    />
                                }
                            >
                                {this.getUsers()}
                            </Select>
                        </div>
                    )
                        : null
                    }
                    {this.state.validationData && this.state.validationData.severity && this.state.validationData.severity.page_visible ? (
                        <div className={this.props.classes.inputFieldDiv}>
                            <InputLabel className={this.props.classes.textFieldLabel}>
                                {this.state.validationData.severity.display_name}
                                {(this.state.validationData.severity.validations && this.state.validationData.severity.validations.required) ? (<span className={this.props.classes.requiredClass}>*</span>) : null}
                            </InputLabel>
                            <Select
                                native
                                value={this.state.severity}
                                onChange={(event) => this.inputChangeHandler(event, "severity")}
                                input={
                                    <OutlinedInput
                                        variant="outlined"
                                        name="severity"
                                        id="language-native-simple"
                                        labelWidth={this.state.labelWidth}
                                        className={this.props.classes.textField}
                                        error={this.state.severityError}
                                        classes={{ input: this.props.classes.outlinedInput }}
                                    />
                                }
                            >
                                <option key={""} disabled={true} value={""}>{"Select"}</option>
                                <option key={"info"} value={"info"}>{"Info"}</option>
                                <option key={"warning"} value={"warning"}>{"Warning"}</option>
                                <option key={"critical"} value={"critical"}>{"Critical"}</option>
                            </Select>
                        </div>
                    )
                        : null
                    }
                    {this.state.validationData && this.state.validationData.assets && this.state.validationData.assets.page_visible ? (
                        <div className={this.props.classes.textareaInputFieldDiv} style={{ height: 250, alignItems: 'flex-start' }}>
                            <InputLabel className={this.props.classes.textareaFieldLabel}>
                                {this.state.validationData.assets.display_name}
                                {(this.state.validationData.assets.validations && this.state.validationData.assets.validations.required) ? (<span className={this.props.classes.requiredClass}>*</span>) : null}
                            </InputLabel>
                            <div className={this.props.classes.textArea}>
                                <div style={{ display: 'flex', width: '100%', float: 'left', alignItems: 'flex-start' }}>
                                    <Radio
                                        checked={this.state.selectedPlantsRadioButton}
                                        onChange={this.handleRadioButton}
                                        name="plants"
                                        disableRipple={true}
                                        style={{ width: '12%', paddingTop: '2%' }}
                                    />
                                    {this.state.validationData && this.state.validationData.alertTypeName && this.state.validationData.plantName.page_visible ? (
                                        <div className={this.props.classes.inputFieldDiv} style={{ width: '88%', alignItems: 'flex-start' }}>
                                            <InputLabel className={this.props.classes.textFieldLabel} style={{ marginLeft: 0, width: '80%' }}>
                                                {this.state.validationData.plantName.display_name}
                                                {(this.state.validationData.plantName.validations && this.state.validationData.plantName.validations.required) ? (<span className={this.props.classes.requiredClass}>*</span>) : null}
                                            </InputLabel>
                                            <TextField
                                                name="plantName"
                                                id="plantName-disabled"
                                                disabled
                                                variant="outlined"
                                                className={this.props.classes.textField}
                                                value={this.state.plantName}
                                                InputProps={{
                                                    classes: {
                                                        input: this.props.classes.outlinedInput
                                                    }
                                                }}
                                                style={{ marginRight: 0 }}
                                            />
                                        </div>
                                    )
                                        : null
                                    }
                                </div>
                                <div style={{ display: 'flex', width: '100%', float: 'left', alignItems: 'flex-start' }}>
                                    <Radio
                                        checked={this.state.selectedMachineGroupsRadioButton}
                                        onChange={this.handleRadioButton}
                                        name="machineGroups"
                                        disableRipple={true}
                                        style={{ width: '12%', paddingTop: '2%' }}
                                    />
                                    {this.state.validationData && this.state.validationData.machineGroupName && this.state.validationData.machineGroupName.page_visible ? (
                                        <div className={this.props.classes.inputFieldDiv} style={{ width: '88%', alignItems: 'flex-start' }}>
                                            <InputLabel className={this.props.classes.textFieldLabel} style={{ marginLeft: 0, width: '80%' }}>
                                                {this.state.validationData.machineGroupName.display_name}
                                                {(this.state.validationData.machineGroupName.validations && this.state.validationData.machineGroupName.validations.required) ? (<span className={this.props.classes.requiredClass}>*</span>) : null}
                                            </InputLabel>
                                            <Select
                                                native
                                                disabled={!this.state.selectedMachineGroupsRadioButton}
                                                value={this.state.machineGroups}
                                                onChange={(event) => this.inputChangeHandler(event, "machineGroups")}
                                                input={
                                                    <OutlinedInput
                                                        variant="outlined"
                                                        name="machineGroups"
                                                        id="language-native-simple"
                                                        labelWidth={this.state.labelWidth}
                                                        className={this.props.classes.textField}
                                                        style={{ marginRight: 0 }}
                                                        error={this.state.machineGroupError}
                                                        classes={{ input: this.props.classes.outlinedInput }}
                                                    />
                                                }
                                            >
                                                {this.getMachineGroups()}
                                            </Select>
                                        </div>
                                    )
                                        : null
                                    }
                                </div>
                                <div style={{ display: 'flex', width: '100%', float: 'left', alignItems: 'flex-start' }}>
                                    <Radio
                                        checked={this.state.selectedMachinesRadioButton}
                                        onChange={this.handleRadioButton}
                                        name="machines"
                                        disableRipple={true}
                                        style={{ width: '12%', paddingTop: '2%' }}
                                    />
                                    {this.state.validationData && this.state.validationData.machineName && this.state.validationData.machineName.page_visible ? (
                                        <div className={this.props.classes.inputFieldDiv} style={{ width: '88%', alignItems: 'flex-start' }}>
                                            <InputLabel className={this.props.classes.textFieldLabel} style={{ marginLeft: 0, width: '80%' }}>
                                                {this.state.validationData.machineName.display_name}
                                                {(this.state.validationData.machineName.validations && this.state.validationData.machineName.validations.required) ? (<span className={this.props.classes.requiredClass}>*</span>) : null}
                                            </InputLabel>
                                            <Select
                                                native
                                                disabled={!this.state.selectedMachinesRadioButton}
                                                value={this.state.machines}
                                                onChange={(event) => this.inputChangeHandler(event, "machines")}
                                                input={
                                                    <OutlinedInput
                                                        variant="outlined"
                                                        name="machines"
                                                        id="language-native-simple"
                                                        labelWidth={this.state.labelWidth}
                                                        className={this.props.classes.textField}
                                                        style={{ marginRight: 0 }}
                                                        error={this.state.machinesError}
                                                        classes={{ input: this.props.classes.outlinedInput }}
                                                    />
                                                }
                                            >
                                                {this.getMachines()}
                                            </Select>
                                        </div>
                                    )
                                        : null
                                    }
                                </div>
                            </div>
                        </div>
                    )
                        : null
                    }
                    {this.state.validationData && this.state.validationData.notes && this.state.validationData.notes.page_visible ? (
                        <div className={this.props.classes.textareaInputFieldDiv}>
                            <InputLabel className={this.props.classes.textareaFieldLabel}>
                                {this.state.validationData.notes.display_name}
                                {(this.state.validationData.notes.validations && this.state.validationData.notes.validations.required) ? (<span className={this.props.classes.requiredClass}>*</span>) : null}
                            </InputLabel>
                            <TextField
                                id="note"
                                required={(this.state.validationData.notes.validations && this.state.validationData.notes.validations.required)}
                                multiline
                                rows="6"
                                value={this.state.notes}
                                helperText={(this.state.validationData.notes.validations && this.state.validationData.notes.validations.helpText) ? (this.state.validationData.notes.validations.helpText) : null}
                                variant="outlined"
                                onChange={(event) => this.inputChangeHandler(event, "notes")}
                                className={this.props.classes.textArea}
                                error={this.state.notesError}
                                InputProps={{
                                    classes: {
                                        multiline: this.props.classes.outlinedInput
                                    }
                                }}
                            />
                        </div>
                    )
                        : null
                    }
                </CardContent >
            </Card >
        );
    }
}

const mapStateToProps = (state) => {
    return {
        machineGroupData: state.machineGroupReducer,
        machinesData: state.machinesDataReducer,
        staticData: state.staticDataReducer,
        usersData: state.userTableDataReducer
    }
}

const mapDispatchToProps = dispatch => {
    return ({
        getTableData: (selectedNavtab, currentScopeSelectionId, setToStore) => (dispatch(actionCreators.getTableData(selectedNavtab, currentScopeSelectionId, setToStore))),
        updateAlertsEntity: (alertsEntityData, id) => (dispatch(actionCreators.updateAlertsEntity(alertsEntityData, id))),
        updateSelectedTab: (selectedTab) => (dispatch(actionCreators.updateSelectedTab(selectedTab)))
    })
}

export default  withTranslation()(connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(AlertsEntity)));

