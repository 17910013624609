import React, { Component } from 'react'
import { Chart } from "react-google-charts";
import Button from '@material-ui/core/Button';
import Modal from 'react-responsive-modal';
import Picky from "react-picky";
import { connect } from 'react-redux';
import TimeKeeper from 'react-timekeeper';
import moment from "moment";
import PickyDateTime from 'react-picky-date-time';
import * as actionCreators from '../../../actions/actionCreators';
import { withTranslation } from "react-i18next";

const subCategoryList = [
  {
    key: 'miscellaneous', value: 'Miscellaneous'
  }
];

const styles = {
  customModal : {
      modalStyle: "modalStyle"
  }
};


class ProductionHistoryGraph extends Component {
  constructor(props) {
    super(props);
    var today = new Date(),
    time = today.getHours() + ':' + today.getMinutes();
    this.state = {
      formOpen: false,
      partName: '',
      operatorName: '',
      downTimeReason: 'M',
      partNumber: this.props.partCountList.hasOwnProperty('list') ? this.props.partCountList.list[0].partRef : null,
      subCategory: subCategoryList[0].value,
      loss: this.props.lossList.hasOwnProperty('inputData')?this.props.lossList.inputData[0].id: '',
      isCalendar : false,
      isCalendarNext : false,
      selectedDay: {year: today.getFullYear(), month: today.getMonth()+1, date: today.getDate()},
      selectedDayNext: {year: today.getFullYear(), month: today.getMonth()+1, date: today.getDate()},
      startTime: '',
      endTime: '',
      time : time,
      timeNext : time,
      machineId : ''
    }

  }

  chartEvents = [
    {
      eventName: 'select',
      callback: ({ chartWrapper, google }) => {
        const chart = chartWrapper.getChart()
        const selection = chart.getSelection()
        if (selection.length === 1) {
          const [selectedItem] = selection
          const dataTable = chartWrapper.getDataTable()
          const { row, column } = selectedItem
          // var selection = chart.getSelection();
          var message = '';
          for (var i = 0; i < selection.length; i++) {
            var item = selection[i];
            if (item.row != null && item.column != null) {
              let x = google.visualization.arrayToDataTable([
                this.props.data[0],
                this.props.data[1]
              ])
              var str = x.getFormattedValue(item.row, item.column);
              var category = x.getValue(chart.getSelection()[0].row, 0);
            }
          }
          var trendIndex = (item.column - 1) / 3;
            let keys = Object.keys(this.props.rawData)
            let id = '';
            keys.forEach(element => {
                if(this.props.rawData[element].machineName === category){
                  id = element;
                }          
            });
            

          let stateList = this.props.rawData[id].state;
          if(!this.props.bookedLoss){
            stateList = this.props.rawData[id].state.filter(ele=> ele.state !== 4);

          }
          if(!this.props.plannedDowntime){
            stateList = stateList.filter(ele=> ele.state !== 5);
          }
          let p = stateList[trendIndex];
          if(p.state === 1 || p.state === 3) {
            let startDate = moment(p.begin+':00Z').utc().local();
            let endDate = moment(p.end+':00Z').utc().local();
            this.setState({ formOpen: true,
                            startTime : startDate.format('YYYY-MM-DD'),
                            endTime: endDate.format('YYYY-MM-DD') ,
                            time : startDate.format('HH:mm'),
                            timeNext : endDate.format('HH:mm'),
                            selectedDay: {year: startDate.year(), month: startDate.month()+1, date: startDate.day()},
                            selectedDayNext: {year: endDate.year(), month: endDate.month()+1, date: endDate.day()},
                            operatorName : this.props.rawData[id].currentOperator + " " + this.props.rawData[id].currentOperatorTime,
                            machineId : p.machineId,
                          });
          } else {
            
          }

        }

      },
    },
  ];


  onSubCategoryChange = (value) => {
    this.setState({ subCategory: value });
  };

  onLossChange = (value) => {
    this.setState({ loss: value.target.value });
  };

  onSelectionChange = (e) =>{
    this.setState({  partNumber : e.target.value })
  }

  modalClose = () => {
    this.setState({ formOpen: false })
  } 

  handlePartNameChange = (event) => {
    this.setState({ partName: event.target.value });
  }

  handleOperatorNameChange = (event) => {
    this.setState({ operatorName: event.target.value });
  }

  setDowntimeReason(event) {
    this.setState({  downTimeReason: event.target.value  })
  }

  startTimeClicked = () => {
      this.setState({ isCalendar : true, isCalendarNext : false  })
  }

  endTimeClicked = () => {
      this.setState({  isCalendarNext: true, isCalendar : false  })
  }

  handleDayClick = (res) => {
      const { date, month, year } = res;
      this.setState({ startFlag: true, selectedDay: { year: year, month: month, date: date } });
  }

  handleDayClickNext = (res) => {
      const { date, month, year } = res;
      this.setState({ endFlag: true, selectedDayNext: { year: year, month: month, date: date } });
  }

  handleTimeChange = (newTime) => { 
    const { time, timeNext } = this.state
    let startHour = time.split(":")[0];
    let startMinute = time.split(':')[1]
    let endHour = timeNext.split(":")[0];
    let endMinute = timeNext.split(':')[1]

    if(startHour > newTime.hour24 ||  startMinute > newTime.minute){
      alert(this.props.t("Start-time is not correct"))
      this.setState({ isCalendar: false, isCalendarNext: false, formOpen: true }); 
      return
    }

    if(endHour< newTime.hour24 ||  endMinute < newTime.minute){
      alert(this.props.t("Start-time cannot be greater than End-time"))
      this.setState({ isCalendar: false, isCalendarNext: false, formOpen: true }); 
      return
    }
    this.setState({ time: newTime.formatted24});
  }

  handleTimeChangeNext = (newTime) => {
    const { time, timeNext } = this.state
    let startHour = time.split(":")[0];
    let startMinute = time.split(':')[1]
    let endHour = timeNext.split(":")[0];
    let endMinute = timeNext.split(':')[1]

    if(startHour < newTime.hour24 ){
      alert(this.props.t("End-time is not correct"))
      this.setState({ isCalendar: false, isCalendarNext: false, formOpen: true }); 
      return
    }
    else {
      if(endMinute == newTime.minute){

      }
      else if(startMinute < newTime.minute){
        alert(this.props.t("End-time is not correct"))
        this.setState({ isCalendar: false, isCalendarNext: false, formOpen: true }); 
        return
      }
    }

    if(endHour > newTime.hour24 ||  endMinute > newTime.minute){
      alert(this.props.t("End-time cannot be less than Start-time"))
      this.setState({ isCalendar: false, isCalendarNext: false, formOpen: true }); 
      return
    }
      this.setState({ timeNext: newTime.formatted24});
  }

  userSelectionFromCal = (e) => {
      this.setState({ isCalendar: false, isCalendarNext: false, formOpen: true });   
  }

  handleFormSubmit = (formSubmitEvent) => {
    formSubmitEvent.preventDefault();

    const { startTime, endTime, time, timeNext } = this.state;

    let startingDate = new Date(startTime)
    startingDate.setHours(time.split(':')[0],time.split(':')[1],0,0);

    let endingDate = new Date(endTime)
    endingDate.setHours(timeNext.split(':')[0],timeNext.split(':')[1],0,0);
    
    
    let startTime1 = moment(startingDate).utc().format("YYYY-MM-DDTHH:mm:00") + "Z";
    let endTime1 = moment(endingDate).utc().format("YYYY-MM-DDTHH:mm:00") + "Z";

    let from = moment(this.props.range[1]).utc().format("YYYY-MM-DDTHH:mm:00") + "Z";
    let to = moment(this.props.range[2]).utc().format("YYYY-MM-DDTHH:mm:00") + "Z";

    this.setState({ formOpen: false  })

    let pd = this.props.plannedDowntime ? 1 : 0;

    this.props.sendAddLossData(this.state.machineId, this.props.selectedPlantId, this.state.partNumber , this.state.downTimeReason, this.state.operatorName, this.state.partName, this.state.loss, this.state.subCategory,startTime1,endTime1, from, to, pd);
  }


  render() {
    const {t}=this.props;
    const { selectedDay, selectedDayNext, startTime, endTime, time, timeNext} = this.state;
    return (
      <div className="chart-body">
        <Chart
          width={'100%'}
          height={'110px'}
          chartType="BarChart"
          loader={<div>{t("Loading Chart")}</div>}
          data={this.props.data}
          options={{
            height: 92,
            legend: 'none',
            seriesType: 'bars',
            bar: { groupWidth: '75%' },
            explorer: {
              actions: ['dragToZoom', 'rightClickToReset'],
              maxZoomOut: 1,
              maxZoomIn: 32,
              keepInBounds: true
            },
            isStacked: true,
            hAxis: { ticks: this.props.tickList }
          }}
          chartEvents={this.chartEvents}
        />
        {this.props.rawData.hasOwnProperty(this.props.machineName)
        ? 
        <div className="graph-info-wrp">
          <div id="equipment_data_1" className="display-text" style={{ width: "50%" }}>
            <div>Current Status:
              <div className="display-text">
                <span style={{ textTransform: "uppercase" }}>&nbsp;{this.props.rawData[this.props.machineName] ?<span> {this.props.rawData[this.props.machineName].currentState === 1? "IDLE" : null}
                                                                    {this.props.rawData[this.props.machineName].currentState === 2? "PRODUCTION" : null}
                                                                    {this.props.rawData[this.props.machineName].currentState === 3? "DISCONNECTED" : null}
                                                                    {this.props.rawData[this.props.machineName].currentState === 4? "BOOKED LOSS" : null}
                                                                    {this.props.rawData[this.props.machineName].currentState === 5? "PLANNED DOWNTIME" : null}
                                                                     </span>: null}</span>
                <span> (last {this.props.rawData[this.props.machineName] ? this.props.rawData[this.props.machineName].productionHrs : null} hrs)</span>
                <div className={"btn-circle operation-" + this.props.rawData[this.props.machineName].currentState}></div>
              </div>
            </div>
            <div>{t("Current Loss")}: <span>{t("None")}</span></div>
            <div>{t("Current Operator")}: <span>{this.props.rawData[this.props.machineName].currentOperator}</span> - <span>{this.props.rawData[this.props.machineName].currentOperatorTime.replace("T", " ")}</span></div>
            <div>{t("Current Part")}: <span>{this.props.rawData[this.props.machineName].currentPartNumber}</span></div>
          </div>
          <div className="display-text" style={{ width: "50%", verticalAlign: "top" }}>
            <div>{t("Duration selected")}: <span>{this.props.rawData[this.props.machineName].durationSelectedHrs}</span></div>
            <div>
              <div style={{ width: "24px", height: "12px", background: "#0070c0", display: "inline-block" }}></div>
              <div style={{ paddingLeft: "4px", width: "87%", verticalAlign: "top", display: "inline-block" }}>Hidden Losses: <span>{this.props.rawData[this.props.machineName].hiddenLossesCount}</span><span> ({this.props.rawData[this.props.machineName].hiddenLossesPercent.toFixed(1)}%, {this.props.rawData[this.props.machineName].hiddenLossesHrs} hours)</span></div>
            </div>
            <div>
              <div style={{ width: "24px", height: "12px", background: "#109618", display: "inline-block" }}></div>
              <div style={{ paddingLeft: "4px", width: "87%", verticalAlign: "top", display: "inline-block" }}>Production: <span>{this.props.rawData[this.props.machineName].productionPercent.toFixed(1)}</span>,<span> {this.props.rawData[this.props.machineName].productionHrs} hours</span></div>
            </div>
            <div>
              <div style={{ width: "24px", height: "12px", background: "#ff0000", display: "inline-block" }}></div>
              <div style={{ paddingLeft: "4px", width: "87%", verticalAlign: "top", display: "inline-block" }}>Booked Losses: <span>{this.props.rawData[this.props.machineName].bookedLossesCount}</span><span> ({this.props.rawData[this.props.machineName].bookedLossesPercent.toFixed(1)}%, {this.props.rawData[this.props.machineName].bookedLossesHrs} hours)</span></div>
            </div>
          </div>
        </div>: null}
        
        <Modal open={this.state.formOpen} onClose={this.modalClose} center classNames={{modal: styles.customModal}}>
            <div className="machineList-dialog-wrp">
              <h2>{t("Add Losses")}</h2>
                <form onSubmit={this.handleFormSubmit}>
                  <div className="qualityInput-dialog addLosses-dialog">
                      <div className="item-wrp">
                        <label>{t("Asset")}</label>
                        <span className="macName">{this.props.machineName}</span>                            
                      </div>

                      <div className="item-wrp">
                        <label>{t("Part Name")}</label>
                        <input className="macName" type='text' value={this.state.partName} required onChange={this.handlePartNameChange}></input>   
                      </div>  

                      <div className="item-wrp">
                        <label>{t("Part Number")}</label>
                        {this.props.partCountList.list
                          ? <select className="list-selection form-control dropdowns macName" id="" onChange={this.onSelectionChange}>
                            {this.props.partCountList.list.map((list, index) =>
                              <option value={list.partName} key={index}>{list.partName}</option>
                            )}
                        </select> : null}
                      </div>

                      <div className="item-wrp">
                        <label>{t("Operator Name")}</label>
                        <input className="macName" type='text' value={this.state.operatorName} required onChange={this.handleOperatorNameChange}></input>
                      </div>

                      <div className="item-wrp">
                        <label >{t("Loss")}</label>
                        <select className="list-selection form-control dropdowns macName" id="" onChange={this.onLossChange}>
                        {this.props.lossList.hasOwnProperty('inputData') ? this.props.lossList.inputData.map((list) =>
                          <option value={list.id} key={list.id}>{list.value}</option>
                        )
                          : null}
                        </select>                        
                    </div>
                    <div className="item-wrp">
                      <label>{t("Subcategory")}</label>
                      <select className="list-selection form-control dropdowns macName" id="" onChange={this.onSubCategoryChange}>
                          {subCategoryList.map((list, index) =>
                            <option value={list.value} key={index}>{list.value}</option>
                          )}
                        </select> 
                    </div>
                    <div className="item-wrp">
                      <label>{t("Downtime Reason")}</label>
                      <div className="radioButtons macName" onChange={event => this.setDowntimeReason(event)}>
                        <input className="radio" type="radio" defaultChecked value="M" name="downTimeReason" /><span>M</span>
                        <input className="radio" type="radio" value="H" name="downTimeReason" /><span>H</span>
                        <input className="radio" type="radio" value="E" name="downTimeReason" /><span>E</span>
                        <input className="radio" type="radio" value="N" name="downTimeReason" /><span>N</span>
                      </div>
                    </div>

                      <div className="item-wrp">
                          <label>{t("Start Time")}</label>
                          <span style={{ background: '#aaa', color: '#eee', fontSize: '20px', padding: "4px 8px" }} className="list-selection" onClick={this.startTimeClicked} >
                               {startTime} {time}
                          </span>
                          {   this.state.isCalendar ?
                                  <div className="timeDate startCal widgets-shadow" ref={prodSearchCalendar => { this.prodSearchCalendar = prodSearchCalendar }}>
                                      <PickyDateTime size="m" mode={0} defaultDate={`${selectedDay.month}/${selectedDay.date}/${selectedDay.year}`} locale="en-us" show={true} onDatePicked={res => this.handleDayClick(res)} />
                                      <TimeKeeper time={time} onChange={this.handleTimeChange} closeOnMinuteSelect={true} switchToMinuteOnHourSelect={true} />
                                      <div className="shiftSelector-action" style={{ background: "white", textAlign: "right", padding: "10px"}}>
                                          <button className="btn btn-primary" style={{ padding: "4px 12px" }} onClick={(e) => this.userSelectionFromCal(e)}>Ok</button>
                                      </div>
                                  </div>
                              : null
                          }
                      </div>

                      <div className="item-wrp">
                          <label>{t("End Time")}</label>
                          <span style={{ background: '#aaa', color: '#eee', fontSize: '20px', padding: "4px 8px" }} className="list-selection" onClick={this.endTimeClicked} >
                               {endTime} {timeNext}
                          </span>
                          { this.state.isCalendarNext ?
                              <div className="timeDate endCal widgets-shadow" ref={prodSearchCalendarNext => { this.prodSearchCalendarNext = prodSearchCalendarNext }}>
                                  <PickyDateTime size="m" mode={0} defaultDate={`${selectedDayNext.month}/${selectedDayNext.date}/${selectedDayNext.year}`} locale="en-us" show={true} onDatePicked={res => this.handleDayClickNext(res)} />
                                  <TimeKeeper time={timeNext} onChange={this.handleTimeChangeNext} switchToMinuteOnHourSelect={true} />
                                  <div className="shiftSelector-action" style={{ background: "white", textAlign: "right", padding: "10px"}}>
                                      <button className="btn btn-primary" style={{ padding: "4px 12px" }} onClick={(e) => this.userSelectionFromCal(e)}>Ok</button>
                                  </div>
                              </div>
                              : null
                          }
                      </div>
                      <div className="clearfix"></div>
                    </div>     
                       
                    <div className="dialog-btn-wrp">
                        <Button className="dialog-btn select-btn" type="submit" color="primary" autoFocus>{t("OK")}</Button>
                        <Button className="dialog-btn cancel-btn" onClick={() => this.modalClose()} color="primary" autoFocus>{t("Cancel")}</Button>
                    </div>
                    </form>
                </div>
            </Modal>
        <div>
        </div>
      </div>
    )
  }
}

const mapDispatchToProps = dispatch => {
  return {
    sendAddLossData: (machineId, plantId, partNumber, downTimeReason, operatorName, partName, loss, subCategory, startTime, endTime, from , to, pd) => (dispatch((actionCreators.sendAddLossData(machineId, plantId, partNumber, downTimeReason, operatorName, partName, loss, subCategory, startTime, endTime, from, to, pd)))),        
  }
}


const mapStateToProps = state => {
  return {
    partCountList : state.partCountListReducer
  }
}
export default withTranslation()(connect(mapStateToProps, mapDispatchToProps)(ProductionHistoryGraph));
// export default ProductionHistoryGraph;