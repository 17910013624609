import { InputLabel, OutlinedInput, Select } from '@material-ui/core';
import React, { Component } from 'react'
import TimeKeeper from 'react-timekeeper';


export default class DashNavs extends Component {
    constructor(props) {
        super(props);

        var today = new Date(),
            time = today.getHours() + ':' + today.getMinutes(),
            selectedMeridiem = (today.getHours() > 11 ? 'pm' : 'am');

        this.state = {
            time: time,
            timeNext: time,
            isCalendar: false,
            isCalendarNext: false,
            clocktime: undefined,
            clocktimeNext: undefined,
            activeItem: { 5: true },
            selectedMeridiem: selectedMeridiem,
            startTime: '',
            endDate: '',
            endTime: '',
            colorSelection: true,
            datetime: '',
            endDateTime: ''

        };
        this.count = 0;
        this.countNext = 0;
        this.finalCount = 2;
        this.finalCountNext = 2;
    }

   
    
    handleTimeChange = (newTime) => {
        if (this.count >= this.finalCount) {
            this.count = 0;
        }
        if (this.state.selectedMeridiem != newTime.meridiem) {
            this.finalCount = 3;
        }
        else {
            this.finalCount = 2;
        }

        this.count = this.count + 1;

        this.setState({ clocktime: newTime.formatted24 })

        if (this.count == this.finalCount) {
            this.setState(prevState => ({ isCalendar: !prevState.isCalendar, selectedMeridiem: newTime.meridiem }));
        }
        this.setState({ time: newTime });
    }
    handleTimeChangeNext = (newTime) => {
        if (this.countNext >= this.finalCountNext) {
            this.countNext = 0;
        }

        if (this.state.selectedMeridiem != newTime.meridiem) {
            this.finalCountNext = 3;
        }
        else {
            this.finalCountNext = 2;
        }

        this.countNext = this.countNext + 1;

        this.setState({ clocktimeNext: newTime.formatted24 })
        if (this.countNext == this.finalCountNext) {
            this.setState(prevState => ({ isCalendarNext: !prevState.isCalendarNext, selectedMeridiem: newTime.meridiem }));
        }
        this.setState({ timeNext: newTime });
    }
    onRangeClick = (e, dateRange) => {
        e.preventDefault()
        this.props.rangeSelect(e, dateRange)
    }

    toggleActiveItem = (e, listId, dateRange) => {
        let intervalListId = localStorage.setItem("intervalListId", listId);
        let dateRangeFor = localStorage.setItem("dateRange", dateRange);
        this.setState({ activeItem: { [listId]: true }, colorSelection: true })
        this.props.rangeSelect(e, dateRange)
    }

    closeStart = (e) => {
        if (this.searchNode != null && this.searchNodeCalendar != null) {
            if (this.searchNode.contains(e.target) || this.searchNodeCalendar.contains(e.target)) {
                return;
            }
            this.searchClick("start");
        }
    }
    closeEnd = (e) => {
        if (this.searchEndNode != null && this.searchNodeCalendarNext != null) {
            if (this.searchEndNode.contains(e.target) || this.searchNodeCalendarNext.contains(e.target)) {
                return;
            }
            this.searchClick("end");
        }
    }
   
    searchClick = (data) => {
        if (data == "start") {
            if (!this.state.isCalendar) {
                document.addEventListener('click', this.closeStart, false);
            }
            else {
                document.removeEventListener('click', this.closeStart, false);
            }
            this.setState(prevState => ({ isCalendar: !prevState.isCalendar }));
        }
        else {
            if (!this.state.isCalendarNext) {
                document.addEventListener('click', this.closeEnd, false);
            } else {
                document.removeEventListener('click', this.closeEnd, false);
            }
            this.setState(prevState => ({ isCalendarNext: !prevState.isCalendarNext }));
        }
    }
    componentDidMount() {
        let intervalListId = localStorage.getItem("intervalListId");
        let range = localStorage.getItem("dateRange");
        let idOfInterval = intervalListId && intervalListId !== "NaN" ? [parseInt(intervalListId)] : [5];
        this.toggleActiveItem(null, idOfInterval, range)
    }
    componentDidUpdate(prevProps) {
        let startDate, endDate, startTime, endTime;
        if (prevProps.deviceId !== this.props.deviceId) {
            let intervalListId = localStorage.getItem("intervalListId");
            let range = localStorage.getItem("dateRange");
            let idOfInterval = intervalListId && intervalListId !== "NaN" ? [parseInt(intervalListId)] : [7];
            this.toggleActiveItem(null, idOfInterval, range)
        }
        if (prevProps.range != this.props.range) {
            startDate = new Date(this.props.range[1]);
            startDate.toTimeString();
            endDate = new Date(this.props.range[2]);
            endDate.toTimeString();


            let setCurrentTime, setCurrentTimeNext;

            if (startDate.getHours() === 0 && startDate.getMinutes() === 0) {
                setCurrentTime = (startDate.getHours() + '0') + ':' + (startDate.getMinutes() + '0')
            }
            else if (startDate.getMinutes() === 0 || startDate.getMinutes() < 10) {
                setCurrentTime = startDate.getHours() + ':' + ('0' + startDate.getMinutes())
            }
            else {
                setCurrentTime = startDate.getHours() + ':' + startDate.getMinutes()
            }

            if (endDate.getHours() === 0 && endDate.getMinutes() === 0) {
                setCurrentTimeNext = (endDate.getHours() + '0') + ':' + (endDate.getMinutes() + '0')
            }
            else if (endDate.getMinutes() === 0 || endDate.getMinutes() < 10) {
                setCurrentTimeNext = endDate.getHours() + ':' + ('0' + endDate.getMinutes())
            }
            else {
                setCurrentTimeNext = endDate.getHours() + ':' + endDate.getMinutes()
            }

            this.setState({ time: setCurrentTime, timeNext: setCurrentTimeNext, clocktime: setCurrentTime, clocktimeNext: setCurrentTimeNext })
            this.setState({
                datetime: `${new Date(this.props.range[1]).getFullYear()}-${`${new Date(this.props.range[1]).getMonth() +
                    1}`.padStart(2, 0)}-${`${new Date(this.props.range[1]).getDate()}`.padStart(
                        2,
                        0
                    )}`, endDateTime: `${new Date(this.props.range[2]).getFullYear()}-${`${new Date(this.props.range[2]).getMonth() +
                        1}`.padStart(2, 0)}-${`${new Date(this.props.range[2]).getDate()}`.padStart(
                            2,
                            0
                        )}`
            })
        }
    }
    userSelection = (e) => {
        const { time, timeNext,  clocktime, clocktimeNext,  datetime, endDateTime } = this.state
        this.setState({ isCalendar: false, isCalendarNext: false })
        if (datetime === "" || endDateTime === "") {
            let msg = this.props.props.t("Please enter valid date");
            alert(msg);
        } else {
            let startingDate = new Date(`${datetime}`)
            let endingDate = new Date(`${endDateTime}`)
            let startingTime = clocktime ? clocktime : time
            let endingTime = clocktimeNext ? clocktimeNext : timeNext

            this.setState({ isCalendar: false, isCalendarNext: false, colorSelection: false });

            startingDate.setHours(startingTime.split(':')[0])
            startingDate.setMinutes(startingTime.split(':')[1]);
            startingDate.setSeconds(0);
            startingDate.setMilliseconds(0);
            endingDate.setHours(endingTime.split(':')[0])
            endingDate.setMinutes(endingTime.split(':')[1]);
            endingDate.setSeconds(0);
            endingDate.setMilliseconds(0);
            let dateRange = "userSelection";

            if (endingDate > startingDate) {
                var CurrentDate = new Date();
                if (endingDate > CurrentDate) {
                    let msg = this.props.props.t("End date can not be greater than the current date.")
                    alert(msg);
                } else {
                    localStorage.setItem("dateRange", "month")
                    localStorage.setItem("intervalListId", "5")
                    this.props.rangeSelect(e, dateRange, startingDate, endingDate)
                }
            } else {
                let msg = this.props.props.t("Start date can not be greater than the end date.")
                alert(msg);
            }
        }

    }
    handleChange = (field, e) => {
        if(e.target.value.length === 10){
            this.setState({ [field]: e.target.value, isCalendar: false, isCalendarNext: false });
        }
    };
    handleClicktime = () => {
        this.setState({ isCalendar: false, isCalendarNext: false })
    }
    //{row.header}
    render() {
        const row = this.props.widgetsData.widgetData.dashboard;
        const { time, timeNext, isCalendar, isCalendarNext, } = this.state
        return (
            <div className="dashNavs">
                <div style={{display:"flex",justifyContent:"flex-start",flexDirection:"column"}}>
                <div className="heading plantfont" onClick={() => this.props.goFull()} title={this.props.plantName}><span> {this.props.plantName}</span></div>
                <div style={{width:"17rem",marginTop:"-.5rem",marginBottom:"-.5rem"}}>
                    <InputLabel className={this.props.props.classes.textFieldAreaLabel} >
                      {this.props.props.t("Area Name")}
                    </InputLabel>
                    <Select
                      native
                      value={this.props.selectedArea}
                      onChange={(event) => this.props.changeAreaName(event)}
                      style={{backgroundColor:"white",height:"1.75rem"}}
                      input={
                        <OutlinedInput
                          variant="outlined"
                          name="areaName"
                          id="language-native-simple"
                          className={this.props.props.classes.textField}
                          classes={{ input: this.props.props.classes.outlinedInput }}
                        />
                      }
                    >
                      {this.props.getAreaName()}
                    </Select>
                  </div>
                  </div>
                <div className="durationTabs">
                    <ul>
                        {row && row.listItem.map((list, index) =>
                            <li key={index} ><span
                                className={this.state.colorSelection ? this.state.activeItem[list.id] ? 'text-amber-bg' : '' : ''}
                                onClick={(e) => this.toggleActiveItem(e, list.id, list.range)}
                            >{this.props.props.t(list.rangeValue)}</span></li>
                        )}
                    </ul>
                </div>

                <div className="searchDate display-text">
                    <div className="display-text" ref={searchNode => { this.searchNode = searchNode; }}>
                        <p>{row && this.props.props.t(row.searchStart)}</p>
                        <p>
                            <input
                                type="date"
                                value={this.state.datetime}
                                maxlength="4"
                                pattern="[1-9][0-9]{3}"
                                max={ "9999-12-31" }
                                onChange={e => this.handleChange('datetime', e)}
                                onClick={() => this.handleClicktime()}
                            />
                            <span onClick={() => this.searchClick("start")}>
                                {this.state.clocktime ?
                                    <span>{this.state.clocktime}</span> :
                                    <span>{this.state.startTime}</span>
                                }
                                <span className="calendar"></span></span></p>
                    </div>
                    <div className="display-text" ref={searchEndNode => { this.searchEndNode = searchEndNode; }}>
                        <p>{row && this.props.props.t(row.searchEnd)}</p>
                        <p>
                            <input
                                type="date"
                                maxlength="4"
                                pattern="[1-9][0-9]{3}"
                                max={ "9999-12-31" }
                                size="4"
                                value={this.state.endDateTime}
                                onChange={e => this.handleChange('endDateTime', e)}
                                onClick={() => this.handleClicktime()}

                            />
                            <span onClick={() => this.searchClick("end")}>
                                {this.state.clocktimeNext ?
                                    <span>{this.state.clocktimeNext}</span> :
                                    <span>{this.state.endTime}</span>
                                }
                                <span className="calendar" ></span></span></p>
                    </div>
                    <div className="searchLogo display-text text-amber-bg" style={{height:"fit-content"}} id="searchLogo" onClick={(e) => this.userSelection(e)}><i className="display-text"></i></div>
                </div>

                {isCalendar ?
                    <div style={{ right: '250px' }} className="timeDate startCal" ref={searchNodeCalendar => { this.searchNodeCalendar = searchNodeCalendar }}>
                        <TimeKeeper time={this.state.time} onChange={this.handleTimeChange} closeOnMinuteSelect={true} switchToMinuteOnHourSelect={true} />
                    </div>
                    : null
                }

                {isCalendarNext ?
                    <div className="timeDate endCal" ref={searchNodeCalendarNext => { this.searchNodeCalendarNext = searchNodeCalendarNext }}>
                        <TimeKeeper time={timeNext} onChange={this.handleTimeChangeNext} switchToMinuteOnHourSelect={true} />
                    </div>
                    : null
                }
            </div>
        )
    }
}