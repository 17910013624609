import * as actionTypes from '../actions/actionTypes';

export const alertMediaReducer = (state = {}, action) => {
    switch(action.type) {

        case actionTypes.SAVE_ALERT_MEDIA:
        return Object.assign({}, state, action.payload)

        default: return state
    }
}