// SIT ENV
//export const server_url = "http://13.235.103.225:8080";
//export const mindapp_server_url = "http://13.235.103.225:8002";
// export const realtime_server_url = "http://13.235.103.225:8100";
// UAT ENV
//  export const server_url = "http://15.206.142.104:8080";
//  export const mindapp_server_url = "http://15.206.142.104:8002";
//  export const realtime_server_url = "http://15.206.142.104:8100";


//PROD
//export const server_url = "https://admin-backend.infinite-uptime.com";
//export const mindapp_server_url = "https://md-backend.infinite-uptime.com";
//export const realtime_server_url = "https://data-link.infinite-uptime.com";


//others
export const prod_server_url = "http://0.0.0.0:8080";
export const linux_deployment_platform = true;
export const SHOW_PLATFORM_UPTIME = process.env.REACT_APP_SHOW_PLATFORM_UPTIME === "true";
export const support_email = process.env.REACT_APP_SUPPORT_EMAIL;
export const IDAP_SSO_COOKIENAME = process.env.REACT_APP_IDAP_SSO_COOKIENAME;
export const IDAP_SSO_DOMAIN_NAME = process.env.REACT_APP_IDAP_SSO_DOMAIN_NAME;

export const config = {
    url: {
        server_url: process.env.REACT_APP_SERVER_URL,
        mindapp_server_url: process.env.REACT_APP_MINDAPP_SERVER_URL,
        realtime_server_url: process.env.REACT_APP_REALTIME_SERVER_URL,
        maintainance_message_url: process.env.REACT_APP_MAINTENANCE_MESSAGE_URL,
        relible_url: process.env.REACT_APP_RELIBLE_URL,
        support_url: process.env.REACT_APP_SUPPORT_URL,
        admin_service_url: process.env.REACT_APP_ADMIN_SERVICE_URL,
        pms_url: process.env.REACT_APP_PMS_URL,
        env: process.env.REACT_APP_ENV,
        plant_os_api_url: process.env.REACT_APP_PLANT_OS_PMS_URL,
        plant_os_url: process.env.REACT_APP_PLANT_OS_URL,
        pms_url_sandbox: process.env.REACT_APP_PMS_URL_SANDBOX,
        multilingual_base_url: process.env.REACT_APP_MULTILINGUAL_BASE_URL,
    },
    ga_tracking_code: process.env.REACT_APP_GA_TRACKING_CODE     

};
