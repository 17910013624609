import { User } from "oidc-client-ts";
import { getState } from "../oidcStateManager";

export const getOidcUser = () => {
  const oidcStorageKey = `oidc.user:${process.env.REACT_APP_OIDC_AUTHORITY_URL}:${process.env.REACT_APP_OIDC_CLIENT_ID}`;
  const oidcStorage = getState(oidcStorageKey);
  return oidcStorage ? User.fromStorageString(oidcStorage) : null;
};

export const getOidcAccessToken = () => {
  const user = getOidcUser();
  return user ? user.access_token : null;
};
