import * as actionTypes from '../actions/actionTypes';

export const alarmsDataReducer = (state = {
    alarmsDataLength: 0
}, action) => {
    switch (action.type) {

        case actionTypes.SAVE_ALARMS_DATA: {
            let newState = {
                alarmsDataLength: action.payload.alarmsData.length,
                alarmsData: action.payload.alarmsData
            }

            return Object.assign({}, state, newState)
        }
        case actionTypes.UPDATE_ALARM_STATUS: {
            let index = state.alarmsData.findIndex(obj => obj.id == action.payload.id);
            if (index !== -1) {
                state.alarmsData[index].status = action.payload.status;
                state.alarmsDataLength = state.alarmsData.length;
                // state.alarmsData[index].actionNote = action.payload.actionNote
            }

            return Object.assign({}, state, state.alarmsData)
        }

        default: return state
    }
}