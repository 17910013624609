import React, { Component } from 'react';
import { connect } from 'react-redux';
import {
    withStyles,
    Dialog,
    DialogActions,
    Button,
    InputLabel,
    Select,
    OutlinedInput,
    Radio,
    TextField
} from '@material-ui/core';
import { withTranslation } from "react-i18next";

import * as actionCreators from '../../actions/actionCreators';

const styles = () => ({
    dialog: {
        padding: '12px 24px'
    },
    outlinedInput: {
        padding: 12
    },
    elementContainer: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        paddingTop: 24
    },
    textFeildLabel: {
        color: 'rgba(0, 0, 0, 0.4000000059604645)',
        'letter-spacing': 0.5,
        'font-weight': 500,
        fontSize: "14px",
        paddingLeft: "7px",
        lineHeight: 2.5
    },
    textField: {
        border: "1px solid #00000005",
        borderRadius: "6px",
        width: '315px',
        height: '45px',
    },
    textArea: {
        border: "1px solid #00000005",
        width: '315px',
    },
    multiline: {
        paddingTop: 7
    },
    saveButton: {
        width: 100,
        backgroundColor: '#42D784',
        '&:hover': {
            backgroundColor: '#42D784'
        }
    },
    dialogAction: {
        marginTop: 24
    }
})

class AlertsModal extends Component {

    state = {
        alertTypes: "",
        userName: "",
        severity: "",
        plantName: "",
        machineGroup: "",
        machine: "",
        plants: "",
        notes: "",
        changedAlertTypes: false,
        changedMachineGroup: false,
        changedMachine: false,
        changedNotes: false,
        changedUserName: false,
        changedSeverity: false,
        selectedPlantsRadioButton: false,
        selectedMachineGroupsRadioButton: false,
        selectedMachinesRadioButton: false,
        disableSaveButton: true
    }

    componentDidMount() {
        if (this.props.selectedPlant) {
            this.getPlantNamefromOrganization();
            this.props.getTableData("MachineGroupsTable", this.props.selectedPlant, true);
            this.props.getTableData("MachinesTable", this.props.selectedPlant, true);
            this.props.getTableData("AlertsUsersTable", this.props.selectedPlant, true);
        }
    }

    // handle updated user input values
    handleUpdate = () => {
        this.props.updateSelectedRecords(this.state);
        this.props.disableBulkEditingView();
    }

    // handle modal window close action
    handleClose = () => {
        this.props.disableBulkEditingView();
        this.setState({
            alertTypes: "",
            userName: "",
            machineGroup: "",
            machine: "",
            notes: "",
            severity: "",
            plantName: "",
            plants: "",
            changedAlertTypes: false,
            changedMachineGroup: false,
            changedMachine: false,
            changedNotes: false,
            changedUserName: false,
            changedSeverity: false,
            changedPlant: false,
            selectedPlantsRadioButton: false,
            selectedMachineGroupsRadioButton: false,
            selectedMachinesRadioButton: false,
            disableSaveButton: false
        })
    }

    // handles modal window radio buttons 
    handleRadioButton = (event, checked) => {
        switch (event.target.name) {
            case "plants":
                this.setState({
                    selectedPlantsRadioButton: checked,
                    selectedMachineGroupsRadioButton: false,
                    machineGroup: "",
                    selectedMachinesRadioButton: false,
                    machine: "",
                    changedPlant: true,
                });
                break;
            case "machineGroups":
                this.setState({
                    selectedPlantsRadioButton: false,
                    selectedMachineGroupsRadioButton: checked,
                    selectedMachinesRadioButton: false,
                    machine: "",
                });
                break;
            case "machines":
                this.setState({
                    selectedPlantsRadioButton: false,
                    selectedMachineGroupsRadioButton: false,
                    machineGroup: "",
                    selectedMachinesRadioButton: checked,
                });
                break;
            default: console.log("default case reached");
        }
    }

    // handle user input changes
    onChangeHandler = (event, item) => {
        switch (item) {
            case "alertTypes":
                this.setState({
                    changedAlertTypes: true,
                    disableSaveButton: false,
                    alertTypes: event.target.value
                })
                break;
            case "user":
                this.setState({
                    changedUserName: true,
                    disableSaveButton: false,
                    userName: event.target.value
                })
                break;
            case "severity":
                this.setState({
                    changedSeverity: true,
                    disableSaveButton: false,
                    severity: event.target.value
                })
                break;
            case "machineGroups":
                this.setState({
                    changedMachineGroup: true,
                    machineGroup: event.target.value
                })
                break;
            case "machines":
                this.setState({
                    changedMachine: true,
                    machine: event.target.value
                })
                break;
            case "Notes":
                this.setState({
                    changedNotes: true,
                    disableSaveButton: false,
                    notes: event.target.value
                })
                break;
            default: console.log("default case");
        }
    }

    // disable save button based on validations
    disableSaveButton = () => {

        if (!this.state.selectedPlantsRadioButton && !this.state.selectedMachineGroupsRadioButton && !this.state.selectedMachinesRadioButton) {
            return true;
        }
        else if (this.state.selectedPlantsRadioButton) {
            return false;
        }
        else if (this.state.selectedMachineGroupsRadioButton && !this.state.machineGroup) {
            return true;
        }
        else if (this.state.selectedMachinesRadioButton && !this.state.machine) {
            return true;
        }
        else {
            return false;
        }

    }

    // get selected plant's name from organization data
    getPlantNamefromOrganization = () => {
        if (this.props.userData && this.props.userData.scopeSelector && this.props.userData.scopeSelector.subOrganizations && this.props.userData.scopeSelector.subOrganizations.length > 0) {
            let tempdata = "";
            let found = false;
            this.props.userData.scopeSelector.subOrganizations.map((organization) => {
                if (organization.plants && organization.plants.length > 0) {
                    organization.plants.map((plant) => {
                        if (plant.id === this.props.selectedPlant && !found) {
                            tempdata = plant.name;
                            found = true;
                        }
                    })
                }
            })
            this.setState({
                plants: this.props.selectedPlant,
                plantName: tempdata,
            })
        }
    }

    // create alert-types dropdown list
    getAlertTypes = () => {
        let alertTypeList = [];
        alertTypeList.push(<option key={""} disabled={true} value={""}>{"Select"}</option>);
        if ((this.props.config.alertTypeName.validations && !this.props.config.alertTypeName.validations.required) || !(this.props.config.alertTypeName.validations)) {
            alertTypeList.push(<option key={""} value={" "}>{"None"}</option>);
        }
        if (this.props.staticData && this.props.staticData.staticData) {
            let plantOrganizationStaticData = this.props.staticData.staticData[this.props.userData.parentOrganizationId]
            if (plantOrganizationStaticData && plantOrganizationStaticData.alerttypes) {
                plantOrganizationStaticData.alerttypes.map((data) => {
                    alertTypeList.push(
                        <option key={data.id} value={data.id}>{data.name}</option>
                    );
                })
            }
        }
        return alertTypeList;
    }

    // create users dropdown list
    getUsers = () => {
        let usersList = [];
        usersList.push(<option disabled={true} key={""} value={""}>{"Select"}</option>);
        if ((this.props.config.userName.validations && !this.props.config.userName.validations.required) || !(this.props.config.userName.validations)) {
            usersList.push(<option key={""} value={" "}>{"None"}</option>);
        }
        if (this.props.usersData && this.props.usersData.tableData && this.props.usersData.tableData.length > 0) {
            this.props.usersData.tableData.map((data) => {
                usersList.push(
                    <option key={data.id} value={data.id}>{data.emailAddress}</option>
                );
            })
        }
        return usersList;
    }

    // create machine-groups dropdown list
    getMachineGroups = () => {
        let machineGroupList = [];
        machineGroupList.push(<option key={""} disabled={true} value={""}>{"Select"}</option>);
        if ((this.props.config.assets.validations && !this.props.config.assets.validations.required) || !(this.props.config.assets.validations)) {
            machineGroupList.push(<option key={""} value={" "}>{"None"}</option>);
        }
        if (this.props.machineGroupData && this.props.machineGroupData.tableData && this.props.machineGroupData.tableData.length > 0) {
            this.props.machineGroupData.tableData.map((data) => {
                machineGroupList.push(
                    <option key={data.id} value={data.id}>{data.name}</option>
                );
            })
        }
        return machineGroupList;
    }

    // create machines dropdown list
    getMachines = () => {
        let machinesList = [];
        machinesList.push(<option disabled={true} key={""} value={""}>{"Select"}</option>);
        if ((this.props.config.assets.validations && !this.props.config.assets.validations.required) || !(this.props.config.assets.validations)) {
            machinesList.push(<option key={""} value={" "}>{"None"}</option>);
        }
        if (this.props.machinesData && this.props.machinesData.tableData && this.props.machinesData.tableData.length > 0) {
            this.props.machinesData.tableData.map((data) => {
                machinesList.push(
                    <option key={data.id} value={data.id}>{data.label}</option>
                );
            })
        }
        return machinesList;
    }

    render() {
        const {t} = this.props;
        return (
            <Dialog
                open={this.props.enableBulkEditingView}
                onClose={this.handleClose}
                aria-labelledby="form-dialog-title"
                maxWidth="sm"
                fullWidth={true}
                classes={{ paper: this.props.classes.dialog }}
                disableBackdropClick={true}
            >
                {
                    this.props.config.alertTypeName.page_visible ?
                        (
                            <div className={this.props.classes.elementContainer}>
                                <InputLabel className={this.props.classes.textFeildLabel}>
                                    {this.props.config.alertTypeName.display_name}
                                </InputLabel>
                                <Select
                                    native
                                    value={this.state.alertTypes}
                                    onChange={(event) => this.onChangeHandler(event, "alertTypes")}
                                    input={
                                        <OutlinedInput
                                            variant="outlined"
                                            name="alertTypes"
                                            id="language-native-simple"
                                            labelWidth={0}
                                            className={this.props.classes.textField}
                                            classes={{ input: this.props.classes.outlinedInput }}
                                        />
                                    }
                                >
                                    {this.getAlertTypes()}
                                </Select>
                            </div>
                        )
                        : null
                }
                {
                    this.props.config.userName.page_visible ?
                        (
                            <div className={this.props.classes.elementContainer}>
                                <InputLabel className={this.props.classes.textFeildLabel}>
                                    {this.props.config.userName.display_name}
                                </InputLabel>
                                <Select
                                    native
                                    value={this.state.userName}
                                    onChange={(event) => this.onChangeHandler(event, "user")}
                                    input={
                                        <OutlinedInput
                                            variant="outlined"
                                            name="user-select"
                                            id="user-native-simple"
                                            labelWidth={0}
                                            className={this.props.classes.textField}
                                            classes={{ input: this.props.classes.outlinedInput }}
                                        />
                                    }
                                >
                                    {this.getUsers()}
                                </Select>
                            </div>
                        )
                        : null
                }
                {
                    this.props.config.severity.page_visible ?
                        (
                            <div className={this.props.classes.elementContainer}>
                                <InputLabel className={this.props.classes.textFeildLabel}>
                                    {this.props.config.severity.display_name}
                                </InputLabel>
                                <Select
                                    native
                                    value={this.state.severity}
                                    onChange={(event) => this.onChangeHandler(event, "severity")}
                                    input={
                                        <OutlinedInput
                                            variant="outlined"
                                            name="severity"
                                            id="language-native-simple"
                                            labelWidth={0}
                                            className={this.props.classes.textField}
                                            classes={{ input: this.props.classes.outlinedInput }}
                                        />
                                    }
                                >
                                    <option key={""} disabled={true} value={""}>{t("Select")}</option>
                                    <option key={"info"} value={"info"}>{t("Info")}</option>
                                    <option key={"warning"} value={"warning"}>{t("Warning")}</option>
                                    <option key={"critical"} value={"critical"}>{t("Critical")}</option>
                                </Select>
                            </div>
                        )
                        : null
                }
                {
                    this.props.config.assets.page_visible ?
                        (
                            <div className={this.props.classes.elementContainer}>
                                <InputLabel className={this.props.classes.textFeildLabel}>
                                    {this.props.config.assets.display_name}
                                </InputLabel>
                                <div className={this.props.classes.textArea}>
                                    <div style={{ display: 'flex', width: '100%', float: 'left', alignItems: 'flex-start' }}>

                                        {this.props.config && this.props.config.plantName && this.props.config.plantName.page_visible ? (
                                            <div>
                                                <div>
                                                    <Radio
                                                        checked={this.state.selectedPlantsRadioButton}
                                                        onChange={this.handleRadioButton}
                                                        name="plants"
                                                        disableRipple={true}
                                                        style={{ width: '12%', paddingTop: '2%' }}
                                                    />
                                                    <InputLabel className={this.props.classes.textFieldLabel} style={{ marginLeft: 0, width: '80%' }}>
                                                        {this.props.config.plantName.display_name}
                                                    </InputLabel>
                                                </div>
                                                <div className={this.props.classes.inputFieldDiv} style={{ width: '88%', alignItems: 'flex-start' }}>
                                                    <TextField
                                                        name="plantName"
                                                        id="plantName-disabled"
                                                        disabled
                                                        variant="outlined"
                                                        className={this.props.classes.textField}
                                                        value={this.state.plantName}
                                                        InputProps={{
                                                            classes: {
                                                                input: this.props.classes.outlinedInput
                                                            }
                                                        }}
                                                        style={{ marginRight: 0 }}
                                                    />
                                                </div>
                                            </div>
                                        )
                                            : null
                                        }
                                    </div>
                                    <div style={{ display: 'flex', width: '100%', float: 'left', alignItems: 'flex-start' }}>

                                        {this.props.config && this.props.config.machineGroupName && this.props.config.machineGroupName.page_visible ? (
                                            <div style={{ marginTop: '1%' }}>
                                                <div>
                                                    <Radio
                                                        checked={this.state.selectedMachineGroupsRadioButton}
                                                        onChange={this.handleRadioButton}
                                                        name="machineGroups"
                                                        disableRipple={true}
                                                        style={{ width: '12%', paddingTop: '2%' }}
                                                    />
                                                    <InputLabel className={this.props.classes.textFieldLabel} style={{ marginLeft: 0, width: '80%' }}>
                                                        {this.props.config.machineGroupName.display_name}
                                                    </InputLabel>
                                                </div>
                                                <div className={this.props.classes.inputFieldDiv} style={{ width: '88%', alignItems: 'flex-start' }}>
                                                    <Select
                                                        native
                                                        disabled={!this.state.selectedMachineGroupsRadioButton}
                                                        value={this.state.machineGroup}
                                                        onChange={(event) => this.onChangeHandler(event, "machineGroups")}
                                                        input={
                                                            <OutlinedInput
                                                                variant="outlined"
                                                                name="machineGroups"
                                                                id="language-native-simple"
                                                                labelWidth={this.state.labelWidth}
                                                                className={this.props.classes.textField}
                                                                style={{ marginRight: 0 }}
                                                                error={this.state.machineGroupError}
                                                                classes={{ input: this.props.classes.outlinedInput }}
                                                            />
                                                        }
                                                    >
                                                        {this.getMachineGroups()}
                                                    </Select>
                                                </div>
                                            </div>
                                        )
                                            : null
                                        }
                                    </div>
                                    <div style={{ display: 'flex', width: '100%', float: 'left', alignItems: 'flex-start' }}>

                                        {this.props.config && this.props.config.machineName && this.props.config.machineName.page_visible ? (
                                            <div style={{ marginTop: '1%' }}>
                                                <div>
                                                    <Radio
                                                        checked={this.state.selectedMachinesRadioButton}
                                                        onChange={this.handleRadioButton}
                                                        name="machines"
                                                        disableRipple={true}
                                                        style={{ width: '12%', paddingTop: '2%' }}
                                                    />
                                                    <InputLabel className={this.props.classes.textFieldLabel} style={{ marginLeft: 0, width: '80%' }}>
                                                        {this.props.config.machineName.display_name}
                                                    </InputLabel>
                                                </div>
                                                <div className={this.props.classes.inputFieldDiv} style={{ width: '88%', alignItems: 'flex-start' }}>
                                                    <Select
                                                        native
                                                        disabled={!this.state.selectedMachinesRadioButton}
                                                        value={this.state.machine}
                                                        onChange={(event) => this.onChangeHandler(event, "machines")}
                                                        input={
                                                            <OutlinedInput
                                                                variant="outlined"
                                                                name="machines"
                                                                id="language-native-simple"
                                                                labelWidth={this.state.labelWidth}
                                                                className={this.props.classes.textField}
                                                                style={{ marginRight: 0 }}
                                                                error={this.state.machinesError}
                                                                classes={{ input: this.props.classes.outlinedInput }}
                                                            />
                                                        }
                                                    >
                                                        {this.getMachines()}
                                                    </Select>
                                                </div>
                                            </div>
                                        )
                                            : null
                                        }
                                    </div>
                                </div>
                            </div>
                        )
                        : null
                }

                {
                    this.props.config.notes.page_visible ?
                        (
                            <div className={this.props.classes.elementContainer}>
                                <InputLabel className={this.props.classes.textFeildLabel} style={{ alignSelf: 'flex-start' }}>
                                    {this.props.config.notes.display_name}
                                </InputLabel>
                                <OutlinedInput
                                    multiline
                                    rows="5"
                                    value={this.state.notes}
                                    onChange={(event) => this.onChangeHandler(event, "Notes")}
                                    className={this.props.classes.textArea}
                                    classes={{ multiline: this.props.classes.multiline }}
                                    labelWidth={0}
                                />
                            </div>
                        )
                        : null
                }
                <DialogActions classes={{ root: this.props.classes.dialogAction }}>
                    <Button onClick={this.handleClose} color="primary">
                        Cancel
                    </Button>
                    <Button
                        variant="contained"
                        color="primary"
                        //disabled={!(this.state.changedAlertTypes || this.state.changedUserName || this.state.changedSeverity || this.state.changedPlant || this.state.changedMachineGroup || this.state.changedMachine || this.state.changedNotes) || this.disableSaveButton() || this.state.disableSaveButton}
                        disabled={this.disableSaveButton() && this.state.disableSaveButton}
                        className={this.props.classes.saveButton}
                        onClick={this.handleUpdate}
                    >
                        Save
                        </Button>
                </DialogActions>
            </Dialog>
        )
    }
}

const mapStateToProps = state => {
    return {
        staticData: state.staticDataReducer,
        machineGroupData: state.machineGroupReducer,
        machinesData: state.machinesDataReducer,
        usersData: state.userTableDataReducer,
        userData: state.userDataReducer
    }
}

const mapDispatchToProps = dispatch => {
    return {
        getTableData: (selectedNavtab, currentScopeSelectionId, setToStore) => (dispatch(actionCreators.getTableData(selectedNavtab, currentScopeSelectionId, setToStore))),
    }
}

export default withTranslation()(connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(AlertsModal)));