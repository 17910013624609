import * as actionTypes from '../actions/actionTypes';

export const viewDefinitionReducer = (state = {
    config: {}
}, action) => {
    switch(action.type) {

        case actionTypes.SAVE_VIEW_DEFINITION:
        return {
            ...state,
            config: {...action.payload}
        }

        default: return state
    }
}