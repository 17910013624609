import React, { Component, Fragment } from 'react';
import {
    withStyles,
    Tooltip,
    Dialog,
    DialogContent,
    DialogActions,
    Button
} from '@material-ui/core';
import { CloudUpload } from '@material-ui/icons';
import { connect } from 'react-redux';
import { withTranslation } from "react-i18next";

import * as actionCreators from '../actions/actionCreators';

const styles = theme => ({
    paper: {
        minWidth: 150
    }
})
//handles the csv upload functionality
class CsvUploadComponent extends Component {

    state = {
        openDialog: false
    }

    inputChangeHandler = (event) => {
        if (event.target.files.length > 0) {
            if (event.target.files[0].name.split(".")[1] === "csv") {
                this.props.uploadCsvFile(event.target.files[0], this.props.selectedNavTab, this.props.currentScopeSelectionId);
            }
            else {
                alert(this.props.t("Please provide a valid CSV file"));
            }
        }
        event.target.value = null
    }

    clicked = (e) => {
        e.preventDefault();
        if (this.props.selectedNavTab !== 'MonitorsTable') {
            this.refs.fileUploader.click();
        } else {
            this.setState({
                openDialog: true
            })
        }
    }
    handleOk = () => {
        this.setState({
            openDialog: false,
        }, () => {
            this.refs.fileUploader.click();
        })
    }
    handleCancel = () => {
        this.setState({
            openDialog: false
        })
    }

    render() {
        const {t}=this.props;
        return (
            <Fragment>
                <label htmlFor="contained-button-file">
                    <Tooltip title={<span style={{ fontSize: "12px" }}>{t("Upload")}</span>}>
                        <CloudUpload style={{ cursor: 'pointer' }} onClick={(e) => this.clicked(e)} />
                    </Tooltip >
                </label>
                <input
                    ref="fileUploader"
                    variant="outlined"
                    //accept="image/*"
                    style={{ display: 'none' }}
                    id="contained-button-file"
                    multiple
                    type="file"
                    onChange={(event) => {
                        this.inputChangeHandler(event)

                    }}

                />
                <Dialog
                    open={this.state.openDialog}
                    onClose={this.handleCancel}
                    maxWidth={'md'}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <DialogContent className={this.props.classes.content}>
                        {t("Please note that only Monitoring Locations with Monitoring Location Type 'Without Diagnostic Faults' will be created, do you want to continue?")}</DialogContent>
                    <DialogActions>
                        <Button onClick={(event) => this.handleOk()} color="primary" autoFocus>
                            {t("OK")}
                                            </Button>
                        <Button onClick={(event) => this.handleCancel()} color="primary" autoFocus>
                            {t("Cancel")}
                                            </Button>
                    </DialogActions>
                </Dialog>
            </Fragment >
        );
    }
}

const mapDispatchToProps = dispatch => {
    return {
        uploadCsvFile: (file, selectedNanTab, currentScopeSelectionId) => (dispatch(actionCreators.uploadCsvFile(file, selectedNanTab, currentScopeSelectionId)))
    }
}

export default withTranslation()(connect(null, mapDispatchToProps)(withStyles(styles)(CsvUploadComponent)));