import React, { Component } from "react";
import { InputLabel, Radio, MenuItem, Divider, FormControl, Select, withStyles } from "@material-ui/core";
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { withTranslation } from "react-i18next";
import ReactGA from "react-ga4";
import { config ,IDAP_SSO_DOMAIN_NAME} from '../configuration';

import * as actionCreators from '../actions/actionCreators';
import moment from "moment";
import useGAEventsTracker from "../hooks/useGAEventsTracker";
const styles = theme => ({
    root: {
        display: 'grid',
        marginLeft: 10,
        marginRight: 10,
    },
    label: {
        color: '#000000'
    },
    select: {
        "&:before": {
            borderColor: "#3f51b5",
        },
        '&:after': {
            borderColor: "#3f51b5",
        },
        color: "#000000"
    },
    selectMenu: {
        display: 'flex',
        justifyContent: 'space-between'
    },
    menuItem: {
        display: 'flex',
        justifyContent: 'space-between'
    },
    icon: {
        fill: "#000000",
    },
    underline: {
        '&:hover': {
            '&:before': {
                borderBottom: '2px solid #3f51b5 !important'
            }
        }
    },
    focused: {
        color: "#000000 !important"
    },
    permissionIcon: {
        fontSize: 14
    }
});

class ScopeSelector extends Component {

    state = {
        open: false,
        openView: false,
        selectedOrganizationValue: "",
        selectedPlantValue: "",
        scopeSeletorPlantsData: [],
        plantPermission: ['dashboard'],
    };

    GAEventsTracker = useGAEventsTracker('usersession');
    
    GAEventsTracker = useGAEventsTracker('usersession');

    componentDidUpdate=(prevProps, prevState) => {
        if(prevState.selectedPlantValue !== this.state.selectedPlantValue) {
            this.props.setApplicationSelectedPlantId(this.state.selectedPlantValue);
        }

        if (prevProps.selectedTab !== this.props.selectedTab) {
            switch (this.props.selectedTab) {
                case 3:
                    const parentOrganization = this.props.scopeSelector.subOrganizations.find((organization) => (
                        organization.root
                    ));
                    if (this.state.selectedOrganizationValue === "" && !this.props.userData.selectedOrganization) {
                        this.setState({
                            selectedOrganizationValue: parentOrganization.id
                        }, () => {
                        })
                        this.props.updateScopeSelector(parentOrganization.id, this.props.selectedTab, null, "OrganizationSelector", parentOrganization.name);
                        this.props.saveParentOrganization(parentOrganization.id, parentOrganization.organizationType);
                        localStorage.setItem("parentOrganizationtype", parentOrganization.organizationType);
                    } else {
                        this.setState({
                            selectedOrganizationValue: this.props.userData.selectedOrganization
                        }, () => {
                        })
                    }

                    break;
                case 2:
                    if (this.state.selectedPlantValue === "" && this.props.scopeSelector.subOrganizations.length > 0) {
                        let defaultPlantName = null;
                        let scopeSeletorPlantsData = [];
                        let defaultPlant = {};
                        const parentOrganization = this.props.scopeSelector.subOrganizations.find((organization) => (
                            organization.root
                        ));

                        this.props.scopeSelector.subOrganizations.map((organization) => {
                            if (organization.plants.length > 0) {
                                organization.plants.map((plant) => {
                                    if (plant.permissions !== null) {
                                        scopeSeletorPlantsData.push({
                                            id: plant.id,
                                            name: plant.name,
                                            permission: plant.permissions
                                        })
                                    }
                                })
                            }
                        })

                        scopeSeletorPlantsData.sort(function (plant1, plant2) {
                            return plant1.name.trim().toLowerCase().localeCompare(plant2.name.trim().toLowerCase());
                        });

                        if (scopeSeletorPlantsData.length > 0) {
                            defaultPlant = { ...scopeSeletorPlantsData[0] };
                            defaultPlantName = defaultPlant.name;
                        }

                        if (defaultPlant && scopeSeletorPlantsData.length > 0) {
                            this.setState({
                                scopeSeletorPlantsData: [...scopeSeletorPlantsData],
                                selectedPlantValue: defaultPlant.id
                            })
                            this.props.updateScopeSelector(defaultPlant.id, this.props.selectedTab, defaultPlant.permission, "PlantSelector", defaultPlant.name);
                            this.props.saveParentOrganization(parentOrganization.id, parentOrganization.organizationType);
                            localStorage.setItem("parentOrganizationtype", parentOrganization.organizationType);
                        }
                    }
                    break;
                default: return null;
            }
        }
        /*   if ((this.props.userData.selectedPlant && (this.props.scopeSelector.subOrganizations.length > 0)) && prevProps.scopeSelector.subOrganizations !== this.props.scopeSelector.subOrganizations) {
               let defaultPlantName = null;
               let scopeSeletorPlantsData = [];
               let defaultPlant = {};
   
               const parentOrganization = this.props.scopeSelector.subOrganizations.find((organization) => (
                   organization.root
               ));
               this.props.scopeSelector.subOrganizations.map((organization) => {
                   if (organization.plants.length > 0) {
                       organization.plants.map((plant) => {
                           if (plant.permissions !== null) {
                               scopeSeletorPlantsData.push({
                                   id: plant.id,
                                   name: plant.name,
                                   permission: plant.permissions
                               })
                           }
                       })
                   }
               })
   
               if (this.props.userData.selectedPlant) {
                   if (scopeSeletorPlantsData.length > 0) {
                       defaultPlant = scopeSeletorPlantsData.find(item => item.id === this.props.userData.selectedPlant);
                       if (defaultPlant) {
                           defaultPlantName = defaultPlant.name;
                       }
                       else {
                           defaultPlant = { ...scopeSeletorPlantsData[0] };
                           defaultPlantName = defaultPlant.name;
                       }
                   }
               }
               else {
                   if (scopeSeletorPlantsData.length > 0) {
                       defaultPlant = { ...scopeSeletorPlantsData[0] };
                       defaultPlantName = defaultPlant.name;
                   }
               }
   
               if (defaultPlant) {
                   this.setState({
                       scopeSeletorPlantsData: [...scopeSeletorPlantsData],
                       selectedPlantValue: defaultPlant.id
                   })
                   this.props.updateScopeSelector(defaultPlant.id, this.props.selectedTab, defaultPlant.permission, "PlantSelector", defaultPlant.name);
               }
           }
   
           if (this.props.scopeSelector && !this.props.userData.selectedPlant) {
               if (this.props.scopeSelector.subOrganizations.length > 0) {
                   let defaultPlantName = null;
                   let scopeSeletorPlantsData = [];
                   let defaultPlant = {};
   
                   const parentOrganization = this.props.scopeSelector.subOrganizations.find((organization) => (
                       organization.root
                   ));
                   this.props.scopeSelector.subOrganizations.map((organization) => {
                       if (organization.plants.length > 0) {
                           organization.plants.map((plant) => {
                               if (plant.permissions !== null) {
                                   scopeSeletorPlantsData.push({
                                       id: plant.id,
                                       name: plant.name,
                                       permission: plant.permissions
                                   })
                               }
                           })
                       }
                   })
   
                   if (scopeSeletorPlantsData.length > 0) {
                       defaultPlant = { ...scopeSeletorPlantsData[0] };
                       defaultPlantName = defaultPlant.name;
                   }
   
                   if (defaultPlant && scopeSeletorPlantsData.length > 0) {
                       this.setState({
                           scopeSeletorPlantsData: [...scopeSeletorPlantsData],
                           selectedPlantValue: defaultPlant.id
                       })
                       this.props.updateScopeSelector(defaultPlant.id, this.props.selectedTab, defaultPlant.permission, "PlantSelector", defaultPlant.name);
                   }
               }
           }
   
           if (this.props.userData.currentDeletedOrganization && prevProps.userData.currentDeletedOrganization !== this.props.userData.currentDeletedOrganization) {
               
               for (let id of this.props.userData.currentDeletedOrganization) {
                   if (id === this.props.userData.selectedOrganization) {
                       const parentOrganization = {
                           id: this.props.userData.parentOrganizationId,
                           organizationType: this.props.userData.parentOrganizationType
                       };
                       this.setState({
                           selectedOrganizationValue: parentOrganization.id
                       })
   
                       this.props.updateScopeSelector(parentOrganization.id, this.props.selectedTab, null, "OrganizationSelector", parentOrganization.name);
                       this.props.history.replace("/dashboard");
                       break;
                   }
               }
   
   
           }*/

    }

    componentDidMount() {
        if (this.props.scopeSelector) {
            switch (5) {
                case 3:
                    const parentOrganization = this.props.scopeSelector.subOrganizations.find((organization) => (
                        organization.root
                    ));
                    if (this.state.selectedOrganizationValue === "" && !this.props.userData.selectedOrganization) {
                        this.setState({
                            selectedOrganizationValue: parentOrganization.id
                        }, () => {
                        })
                        this.props.updateScopeSelector(parentOrganization.id, this.props.selectedTab, null, "OrganizationSelector", parentOrganization.name);
                        this.props.saveParentOrganization(parentOrganization.id, parentOrganization.organizationType);
                        localStorage.setItem("parentOrganizationtype", parentOrganization.organizationType);
                    } else {
                        let organization = this.props.scopeSelector.subOrganizations.find(ele => ele.id === this.props.userData.selectedOrganization);
                        this.setState({
                            selectedOrganizationValue: this.props.userData.selectedOrganization
                        }, () => {
                            this.props.updateScopeSelector(organization.id, this.props.selectedTab, null, "OrganizationSelector", organization.name);
                            // this.props.saveParentOrganization(parentOrganization.id, parentOrganization.organizationType);
                        })
                    }

                    break;
                    
                case 2:    
                case 5:
                    if (this.props.userData.selectedPlant) {
                        let scopeSeletorPlantsData = [];
                        this.props.scopeSelector.subOrganizations.map((organization) => {
                            if (organization.plants.length > 0) {
                                organization.plants.map((plant) => {
                                    if (plant.permissions !== null) {
                                        scopeSeletorPlantsData.push({
                                            id: plant.id,
                                            name: plant.name,
                                            permission: plant.permissions
                                        })
                                    }
                                })
                            }
                        })

                        scopeSeletorPlantsData.sort(function (plant1, plant2) {
                            return plant1.name.trim().toLowerCase().localeCompare(plant2.name.trim().toLowerCase());
                        });
                        localStorage.setItem("PlantView", JSON.stringify(scopeSeletorPlantsData))

                        this.setState({
                            selectedPlantValue: this.props.userData.selectedPlant,
                            scopeSeletorPlantsData: [...scopeSeletorPlantsData],
                        })
                        let plant = scopeSeletorPlantsData.find(ele => ele.id === this.props.userData.selectedPlant);
                        if (plant) {
                            this.props.updateScopeSelector(this.props.userData.selectedPlant, this.props.selectedTab, plant.permission, "Monitoring", plant.name);
                        } else{
                            plant=scopeSeletorPlantsData[0];
                            this.props.updateScopeSelector(plant.id, this.props.selectedTab, plant.permission, "Monitoring", plant.name);

                        }
                        this.props.updatedashboardType(this.props.dashboardType.type);
                        localStorage.setItem("selectedPlantID",plant && plant.id && plant.id);
                        localStorage.setItem("plantValue",plant.id);
                        localStorage.setItem("plantName",plant.name);
                        localStorage.setItem("selectedScopeData",plant.id);
                        
                        this.setState({selectedPlantValue:plant.id})
                        this.props.updatedashboardType(this.props.dashboardType.type);
                        localStorage.setItem("selectedPlantID",plant.id);
                        let selectedView;
                        if (this.props.dashboardType.type === "summary") {
                            selectedView = "summarydashboardview";
                        }
                        if (this.props.dashboardType.type === "summaryPlant") {
                            selectedView = "multiplantview";
                        }
                        if (this.props.dashboardType.type === "monitoring") {
                            selectedView = "detaileddashboardview";
                        }
                        this.setAnaylyticsView(selectedView, plant.name, plant.id)
                        // this.props.saveParentOrganization(parentOrganization.id, parentOrganization.organizationType);
                    } else {
                        let selectedView, selectedPlantOrg;
                        if (this.state.selectedPlantValue === "" && this.props.scopeSelector.subOrganizations.length > 0) {
                            let defaultPlantName = null;
                            let scopeSeletorPlantsData = [];
                            let defaultPlant = {};
                            const parentOrganization = this.props.scopeSelector.subOrganizations.find((organization) => (
                                organization.root
                            ));

                            this.props.scopeSelector.subOrganizations.map((organization) => {
                                if (organization.plants.length > 0) {
                                    organization.plants.map((plant) => {
                                        if (plant.permissions !== null) {
                                            scopeSeletorPlantsData.push({
                                                id: plant.id,
                                                name: plant.name,
                                                permission: plant.permissions
                                            })
                                        }
                                    })
                                }
                            })

                            scopeSeletorPlantsData.sort(function (plant1, plant2) {
                                return plant1.name.trim().toLowerCase().localeCompare(plant2.name.trim().toLowerCase());
                            });
                            let plantIdFromDRS = this.props.history.location.plantId;
                            if ((this.props && this.props.history && this.props.history.location && this.props.history.location.plantId) && scopeSeletorPlantsData) {
                                if (scopeSeletorPlantsData.length > 0) {
                                    let newArr = scopeSeletorPlantsData.filter(function (e) { return e.id === parseInt(plantIdFromDRS) });
                                    if (newArr.length > 0) {
                                        defaultPlant = { ...newArr[0] };
                                        defaultPlantName = defaultPlant.name;
                                    } else {
                                        defaultPlant = { ...scopeSeletorPlantsData[0] };
                                        defaultPlantName = defaultPlant.name;
                                    }

                                }
                            } else {
                                if (scopeSeletorPlantsData && scopeSeletorPlantsData.length > 0) {
                                    defaultPlant = { ...scopeSeletorPlantsData[0] };
                                    defaultPlantName = defaultPlant.name;
                                }
                            }
                            if (defaultPlant && scopeSeletorPlantsData.length > 0) {
                                
                                    const parentOrganization = this.props.scopeSelector.subOrganizations.find((organization) => (
                                        organization.root
                                    ));

                                   
                                    selectedPlantOrg = this.props.scopeSelector.subOrganizations.find((organization) => {
                                        return organization.plants && organization.plants.some((item) => {
                                          return item.id === parseInt(defaultPlant.id);
                                        });
                                      });
                                    localStorage.setItem("organizationtName", parentOrganization.name);

                                    localStorage.setItem("plantName", defaultPlant.name);
                                    localStorage.setItem("selectedPlantID",defaultPlant.id);
                                    if(selectedPlantOrg && selectedPlantOrg.name && selectedPlantOrg.name !=="null"){
                                        this.GAEventsTracker("plant_selection", {
                                            "plantName": defaultPlant.name,
                                            "plant_selection": defaultPlant.name, // This is the event parameter for the custom event which shows in the report
                                            "value": defaultPlant.name,
                                        });
                                    }

                                

                                this.setState({
                                    scopeSeletorPlantsData: [...scopeSeletorPlantsData],
                                    selectedPlantValue: defaultPlant.id
                                })
                                this.props.updateScopeSelector(defaultPlant.id, this.props.selectedTab, defaultPlant.permission, "Monitoring", defaultPlant.name);
                                let dashboard = localStorage.getItem("dashboard");
                                localStorage.setItem("PlantView", JSON.stringify(scopeSeletorPlantsData))
                                if ((dashboard === "summaryPlant" || dashboard === null || dashboard === undefined) && scopeSeletorPlantsData.length > 1) {
                                    dashboard = "summaryPlant"
                                    selectedView = "multiplantview";
                                }
                                else {
                                    if((scopeSeletorPlantsData.length ===1 || scopeSeletorPlantsData.length===0) && (dashboard !=="monitoring")){
                                        dashboard = "summary"
                                        selectedView = "summarydashboardview";
                                    }
                                    if(dashboard ==="monitoring"){
                                        dashboard ="monitoring";
                                        selectedView = "detaileddashboardview";
                                    }
                                }

                                this.props.updatedashboardType(dashboard);
                                this.props.saveParentOrganization(parentOrganization.id, parentOrganization.organizationType);
                                localStorage.setItem("parentOrganizationtype", parentOrganization.organizationType);
                            }
                            let dashboard = localStorage.getItem("dashboard");
                            if ((scopeSeletorPlantsData.length ===1 || scopeSeletorPlantsData.length===0) && (dashboard !=="monitoring")) {
                                    this.props.updatedashboardType("summary");
                                    localStorage.setItem("dashboard", "summary");
                                    selectedView = "summarydashboardview";
                            }
                        }
                        let name = localStorage.getItem("userNameWithdollar");
                        if(selectedPlantOrg && selectedPlantOrg.name !=="null"){
                            let plantName = localStorage.getItem("plantName")
                            this.GAEventsTracker("plant_selection", {
                                "plantName": plantName,
                                "plant_selection": plantName, // This is the event parameter for the custom event
                                "value": plantName,
                            });
                            ReactGA.send({ hitType: "pageview", page: selectedView, title: `${name} visited ${selectedView} for Plant: ${selectedPlantOrg}` });
                        }

                    }

                    break;
                case 4:
                    if (this.state.selectedPlantValue === "" && this.props.scopeSelector.subOrganizations.length > 0) {
                        let defaultPlantName = null;
                        let scopeSeletorPlantsData = [];
                        let defaultPlant = {};
                        const parentOrganization = this.props.scopeSelector.subOrganizations.find((organization) => (
                            organization.root
                        ));

                        this.props.scopeSelector.subOrganizations.map((organization) => {
                            if (organization.plants.length > 0) {
                                organization.plants.map((plant) => {

                                    if (this.state.plantPermission.indexOf(plant.permissions) != -1) {
                                        scopeSeletorPlantsData.push({
                                            id: plant.id,
                                            name: plant.name,
                                            permission: plant.permissions
                                        })
                                    }
                                })
                            }
                        })

                        scopeSeletorPlantsData.sort(function (plant1, plant2) {
                            return plant1.name.trim().toLowerCase().localeCompare(plant2.name.trim().toLowerCase());
                        });

                        if (scopeSeletorPlantsData.length > 0) {
                            defaultPlant = { ...scopeSeletorPlantsData[0] };
                            defaultPlantName = defaultPlant.name;
                        }

                        if (defaultPlant && scopeSeletorPlantsData.length > 0) {
                            this.setState({
                                scopeSeletorPlantsData: [...scopeSeletorPlantsData],
                                selectedPlantValue: defaultPlant.id
                            })
                            this.props.updateScopeSelector(defaultPlant.id, this.props.selectedTab, defaultPlant.permission, "Production");
                            this.props.saveParentOrganization(parentOrganization.id, parentOrganization.organizationType);
                            localStorage.setItem("parentOrganizationtype", parentOrganization.organizationType);
                        }
                    }
                    // this.props.getPlantNameNav(this.state.scopeSeletorPlantsData)
                    break;
                default: return null;
            }
        }
    }
    setAnaylyticsView = (selectedView, plantName,id) => {
        let selectedPlantOrg = this.props && this.props.scopeSelector && this.props.scopeSelector.subOrganizations && this.props.scopeSelector.subOrganizations.find((organization) => {
            return organization.plants && organization.plants.some((item) => {
                if(id && id !=="null"){
                    return item.id === parseInt(id);
                }
            });
          });
          if(selectedPlantOrg && selectedPlantOrg.name && selectedPlantOrg.name !=="null"){
            this.GAEventsTracker("plant_selection", {
                "plantName": plantName,
                "plant_selection": plantName, // This is the event parameter for the custom event which shows in the report
                "value": plantName,
            });
            ReactGA.send({ hitType: "pageview", page: selectedView, title: `Visited ${selectedView} for Plant: ${plantName}` });
        }
    }

    handleChange = (event, selectedTab) => {
        localStorage.removeItem("deviceName");
        localStorage.removeItem("deviceId");
        localStorage.removeItem("machineName");
        localStorage.removeItem("deviceIdentifier");
        let dashboard = localStorage.getItem("dashboard");
        let selectedView;
        document.cookie = `idap-lang-${config.url.env}=null` + "; domain=" + `${IDAP_SSO_DOMAIN_NAME}; secure`;
        document.cookie = `idap-machineName-${config.url.env}=null` + "; domain=" + `${IDAP_SSO_DOMAIN_NAME}; secure`;

        if (dashboard === "summary") {
            selectedView = "summarydashboardview";
        }
        if (dashboard === "summaryPlant") {
            selectedView = "multiplantview";
        }
        if (dashboard === "monitoring") {
            selectedView = "detaileddashboardview";
        }
        switch (selectedTab) {
            case 3: this.setState({ selectedOrganizationValue: event.target.value });
                localStorage.setItem("orgValue", event.target.value)
                let organization = this.props.scopeSelector.subOrganizations.find(ele => ele.id === event.target.value);
                localStorage.setItem("organizationtName", organization.name)

                this.props.updateScopeSelector(event.target.value, this.props.selectedTab, null, "OrganizationSelector", organization.name);
                break;
            case 2: this.setState({ selectedPlantValue: event.target.value });
                localStorage.setItem("plantValue", event.target.value)
                let currentPlant = null;
                currentPlant = this.state.scopeSeletorPlantsData.find((plant) => plant.id === event.target.value);
                this.props.updateScopeSelector(event.target.value, this.props.selectedTab, currentPlant.permission, "PlantSelector", currentPlant.name);
                localStorage.setItem("plantPermisssion", currentPlant.permission);
                localStorage.setItem("plantName", currentPlant.name);
                let orgName = localStorage.getItem("organizationtName");
                break;
            case 5: this.setState({ selectedPlantValue: event.target.value });
                localStorage.setItem("plantValue", event.target.value)
                localStorage.setItem("selectedScopeData", event.target.value)
                currentPlant = null;
                currentPlant = this.state.scopeSeletorPlantsData.find((plant) => plant.id === event.target.value);
                localStorage.setItem("plantPermisssion", currentPlant.permission);
                localStorage.setItem("plantName", currentPlant.name)
                localStorage.setItem("selectedPlantID",currentPlant.id);
                this.setAnaylyticsView(selectedView, currentPlant.name,currentPlant.id)
                this.props.updateScopeSelector(event.target.value, this.props.selectedTab, currentPlant.permission, "Monitoring", currentPlant.name);
                break;
            case 4:
                this.setState({ selectedPlantValue: event.target.value });
                localStorage.setItem("plantValue", event.target.value)
                currentPlant = null;
                currentPlant = this.state.scopeSeletorPlantsData.find((plant) => plant.id === event.target.value);
                this.props.updateScopeSelector(event.target.value, this.props.selectedTab, currentPlant.permission, "Production");
                this.props.getPlantNameNav(currentPlant)
                localStorage.setItem("plantPermisssion", currentPlant.permission);
                localStorage.setItem("plantName", currentPlant.name)
                let orgNameForga1 = localStorage.getItem("organizationtName");
                break;
            default: return null;
        }
    };

    handleClose = () => {
        this.setState({ open: false });
    };

    handleOpen = () => {
        this.setState({ open: true });
    };

    handleCloseView = () => {
        this.setState({ openView: false });
    };

    handleOpenView = () => {
        this.setState({ openView: true });
    };

    handleDashboard = (selectedTab) => {
        document.cookie = `idap-machineName-${config.url.env}=null` + "; domain=" + `${IDAP_SSO_DOMAIN_NAME}; secure`;
        document.cookie = `idap-lang-${config.url.env}=null` + "; domain=" + `${IDAP_SSO_DOMAIN_NAME}; secure`;

        let selectedView;
        localStorage.setItem("dashboard", selectedTab);
        if (selectedTab === "summary") {
            selectedView = "summarydashboardview";
            localStorage.removeItem("deviceName");
            localStorage.removeItem("deviceId");
            localStorage.removeItem("machineName");
            localStorage.removeItem("deviceIdentifier");
        }
        if (selectedTab === "summaryPlant") {
            selectedView = "multiplantview";
        }
        if (selectedTab === "monitoring") {
            selectedView = "detaileddashboardview";

        }
        this.props.updatedashboardType(selectedTab);
        this.props.updateDrawer(false);
        let selectedPlantID = localStorage.getItem("selectedPlantID");
        let plantName = localStorage.getItem("plantName");
        this.setAnaylyticsView(selectedView, plantName,selectedPlantID)
    };
    getViewData = () => {
        const { t } = this.props;
        var views;
        if (this.state.scopeSeletorPlantsData.length > 1) {
            views = [{ key: "summaryPlant", value: "Multi Plant View" }, { key: "summary", value: "Summary" }, { key: "monitoring", value: "Detailed Dashboard" }];
        } else {
            views = [{ key: "summary", value: "Summary" }, { key: "monitoring", value: "Detailed Dashboard" }];

        }
        const scopeData = [];
        views.map((item) => {
            scopeData.push(
                <MenuItem id={item.key} key={item.key} value={item.key}>
                    <label style={{
                        display: 'flex', justifyContent: 'flex-start', marginRight: 11, width: '100%',
                        whiteSpace: 'nowrap',
                        textOverflow: 'ellipsis',
                        overflow: 'hidden'
                    }}
                        title={t(item.value)}
                    >
                        {t(item.value)}
                    </label>
                </MenuItem>
            );
        })
        return scopeData;
    }
    getScopeData = () => {
        const scopeData = [];
        if (this.props.selectedTab && this.props.scopeSelector && this.props.scopeSelector.subOrganizations.length > 0) {
            switch (5) {
                case 3: this.props.scopeSelector.subOrganizations.map((orgName) => {
                    scopeData.push(
                        <MenuItem key={orgName.id} value={orgName.id}> <label style={{
                            display: 'flex', justifyContent: 'flex-start', marginRight: 11, width: '100%',
                            whiteSpace: 'nowrap',
                            textOverflow: 'ellipsis',
                            overflow: 'hidden'
                        }}
                            title={orgName.name}
                        >
                            {orgName.name}
                        </label>
                        </MenuItem>
                    );
                })
                    break;
                case 2:
                    if (this.state.scopeSeletorPlantsData.length > 0) {
                        this.state.scopeSeletorPlantsData.map((plant) => {
                            scopeData.push(
                                <MenuItem
                                    classes={{ root: this.props.classes.menuItem }}
                                    key={plant.id} value={plant.id}
                                    permission={plant.permission}
                                >
                                    <label style={{
                                        display: 'flex', justifyContent: 'flex-start', marginRight: 11, width: '100%',
                                        whiteSpace: 'nowrap',
                                        textOverflow: 'ellipsis',
                                        overflow: 'hidden'
                                    }}
                                        title={plant.name}
                                    >
                                        {plant.name}
                                    </label>
                                    {/* <span>
                                        {
                                            plant.permission === "configuration" ?
                                                <Tooltip title="View and Modify">
                                                    <Create classes={{ root: this.props.classes.permissionIcon }} />
                                                </Tooltip>
                                                :
                                                <Tooltip title="View Only">
                                                    <Visibility classes={{ root: this.props.classes.permissionIcon }} />
                                                </Tooltip>
                                        }
                                    </span> */}
                                </MenuItem>
                            );
                        })
                    }
                    break;
                case 5:
                    if (this.state.scopeSeletorPlantsData.length > 0) {
                        this.state.scopeSeletorPlantsData.map((plant) => {
                            scopeData.push(
                                <MenuItem
                                    classes={{ root: this.props.classes.menuItem }}
                                    key={plant.id} value={plant.id}
                                    permission={plant.permission}
                                >
                                    <label style={{
                                        display: 'flex', justifyContent: 'flex-start', marginRight: 11, width: '100%',
                                        whiteSpace: 'pre-wrap',
                                        textOverflow: 'ellipsis',
                                        overflow: 'hidden',
                                        overflowWrap: "anywhere"
                                    }}
                                        title={plant.name}
                                    >{plant.name}</label>
                                    {/* <span>
                                            {
                                                plant.permission === "configuration" ?
                                                    <Tooltip title="View and Modify">
                                                        <Create classes={{ root: this.props.classes.permissionIcon }} />
                                                    </Tooltip>
                                                    :
                                                    <Tooltip title="View Only">
                                                        <Visibility classes={{ root: this.props.classes.permissionIcon }} />
                                                    </Tooltip>
                                            }
                                        </span> */}
                                </MenuItem>
                            );
                            localStorage.setItem("PlantView", JSON.stringify(this.state.scopeSeletorPlantsData))
                        })
                    }

                    break;
                case 4:
                    if (this.state.scopeSeletorPlantsData.length > 0) {
                        this.state.scopeSeletorPlantsData.map((plant) => {
                            if (this.state.plantPermission.indexOf(plant.permission) != -1) {
                                scopeData.push(
                                    <MenuItem
                                        classes={{ root: this.props.classes.menuItem }}
                                        key={plant.id} value={plant.id}
                                        permission={plant.permission}
                                        className="plant-list"
                                    >
                                        <Link to={`/dashboard/ProductionDashboard`} className={"text-link " + this.props.linkClass}>
                                            <label style={{
                                                display: 'flex', justifyContent: 'flex-start', marginRight: 5, width: '100%',
                                                whiteSpace: 'nowrap',
                                                textOverflow: 'ellipsis',
                                                overflow: 'hidden'
                                            }}
                                                title={plant.name}
                                            >{plant.name}</label></Link>
                                        {/* <span>
                                        {
                                            plant.permission === "configuration" ?
                                                <Tooltip title="View and Modify">
                                                    <Create classes={{ root: this.props.classes.permissionIcon }} />
                                                </Tooltip>
                                                :
                                                <Tooltip title="View Only">
                                                    <Visibility classes={{ root: this.props.classes.permissionIcon }} />
                                                </Tooltip>
                                        }
                                    </span> */}
                                    </MenuItem>
                                );
                            }
                        })
                    }
                    this.props.plantListCheck(scopeData)
                    break;
                default: return null;
            }
        }

        return scopeData;
    }

    render() {
        const { t } = this.props;
        let tab=5;
        const inputLabel = tab === 3 ? t("Organization") : t("Plant");
        const selectedValue = tab === 3 ? this.state.selectedOrganizationValue : this.state.selectedPlantValue;
        return (
            <>
                <form autoComplete="off">
                    {
                        tab === 5 || tab === 0 ?

                            <FormControl className={this.props.classes.root}>
                                <InputLabel id="view" htmlFor="age-simple" className={this.props.classes.label} classes={{ focused: this.props.classes.focused }}>{t("View")}</InputLabel>
                                <Select
                                    className={this.props.classes.select + " " + this.props.classes.underline}
                                    open={this.state.openView}
                                    onClose={this.handleCloseView}
                                    onOpen={this.handleOpenView}
                                    id="selected_view"
                                    value={this.props.dashboardType && this.props.dashboardType.type ? this.props.dashboardType.type : ""}
                                    onChange={(event) => this.handleDashboard(event.target.value)}
                                    inputProps={{
                                        classes: {
                                            icon: this.props.classes.icon
                                        }
                                    }}
                                    classes={{ selectMenu: this.props.classes.selectMenu }}
                                >
                                    {this.getViewData()}
                                </Select>
                            </FormControl>
                            : ""
                    }
                    {tab === 5 && this.props.dashboardType && this.props.dashboardType.type === "summaryPlant" ? <FormControl className={this.props.classes.root} style={{ visibility: "hidden" }}>
                        <InputLabel id="plant" htmlFor="age-simple" className={this.props.classes.label} classes={{ focused: this.props.classes.focused }}>{t(inputLabel)}</InputLabel>
                        <Select
                            className={this.props.classes.select + " " + this.props.classes.underline}
                            open={this.state.open}
                            onClose={this.handleClose}
                            onOpen={this.handleOpen}
                            value={selectedValue ? selectedValue : ""}
                            onChange={(event) => this.handleChange(event, tab)}
                            inputProps={{
                                classes: {
                                    icon: this.props.classes.icon
                                }
                            }}
                            id="selected_plant"
                            classes={{ selectMenu: this.props.classes.selectMenu }}

                        >
                            {this.getScopeData()}
                        </Select>
                    </FormControl> : <FormControl className={this.props.classes.root}>
                        <InputLabel id="plant" htmlFor="age-simple" className={this.props.classes.label} classes={{ focused: this.props.classes.focused }}>{t(inputLabel)}</InputLabel>
                        <Select
                            className={this.props.classes.select + " " + this.props.classes.underline}
                            open={this.state.open}
                            onClose={this.handleClose}
                            onOpen={this.handleOpen}
                            id="selected_plant"
                            value={selectedValue ? selectedValue : ""}
                            onChange={(event) => this.handleChange(event, tab)}
                            inputProps={{
                                classes: {
                                    icon: this.props.classes.icon
                                }
                            }}
                            classes={{ selectMenu: this.props.classes.selectMenu }}

                        >
                            {this.getScopeData()}
                        </Select>
                    </FormControl>}

                </form>

            </>
        );
    }
}

const mapStateToProps = state => {
    return {
        userData: state.userDataReducer,
        dashboardType: state.dashboardTypeReducer

    }
}

const mapDispatchToProps = dispatch => {
    return {
        updateDrawer: () => (dispatch(actionCreators.updateDrawer(false))),
        updatedashboardType: (type) => (dispatch(actionCreators.updatedashboardType(type))),
        updateScopeSelector: (selection, tab, permission, scope, plantName) => (dispatch(actionCreators.updateScopeSelector(selection, tab, permission, scope, plantName))),
        saveParentOrganization: (id, type) => (dispatch(actionCreators.saveParentOrganization(id, type))),
        setApplicationSelectedPlantId: (plantId) => (dispatch(actionCreators.setApplicationSelectedPlantId(plantId)))
    }
}

export default withTranslation()(connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(ScopeSelector)));