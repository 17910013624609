import React, { Component, Fragment } from "react";
import { connect } from 'react-redux';
import { withStyles, Button } from '@material-ui/core';
import MUIDataTable from "mui-datatables";
import { createMuiTheme, MuiThemeProvider } from '@material-ui/core/styles';
import { Edit } from '@material-ui/icons';

import * as actionCreators from '../../actions/actionCreators';
import { withTranslation } from "react-i18next";

import ConfirmationModal from '../ConfirmationModal';

let currentLanguage = localStorage.getItem("lang");

if (currentLanguage === "null" || currentLanguage === null) {
    currentLanguage = "en"
}
const styles = theme => ({
    addButton: {
        background: "#2abbe8",
        color: "#504a4a",
        fontSize: "12px",
        fontWeight: "500",
        "&:hover": {
            background: "#2abbe8a1"
        }
    },
    createNewButton: {
        fontWeight: '400',
        marginLeft: 12
    },
    editIcon: {
        'font-size': 18,
        cursor: 'pointer',
        color: 'rgba(0, 200, 83, 0.5)',
        '&:hover': {
            color: 'rgba(0, 200, 83, 1.0)'
        }
    },
    deleteIcon: {
        'font-size': 18,
        cursor: 'pointer',
        color: 'rgba(221, 44, 0, 0.5)',
        '&:hover': {
            color: 'rgba(221, 44, 0, 1.0)'
        }
    },
    unarchiveIcon: {
        'font-size': 18,
        cursor: 'pointer',
        color: 'rgba(0, 200, 83, 0.5)',
        '&:hover': {
            color: 'rgba(0, 200, 83, 1.0)'
        }
    },
    bulkEditArchiveIcon: {
        cursor: 'pointer',
        color: 'rgba(221, 44, 0, 0.5)',
        '&:hover': {
            color: 'rgba(221, 44, 0, 1.0)'
        }
    },
    bulkEditUnarchiveIcon: {
        cursor: 'pointer',
        color: 'rgba(0, 200, 83, 0.5)',
        '&:hover': {
            color: 'rgba(0, 200, 83, 1.0)'
        }
    },
    flexDiv: {
        display: "flex",
        flexDirection: "row-reverse",
        marginRight: "20px"
    },
    buttonContainer: {
        padding: '5px 10px 10px 0px',
        width: '100%'
    },
    saveButton: {
        backgroundColor: '#2abbe8',
        '&:hover': {
            backgroundColor: '#2abbe8',
        }
    },
    hideHeaderForActiveData: {
        '& thead tr th:nth-child(1)': {
            width: 10
        },
        '& tbody tr td:nth-of-type(1)': {
            padding: '4px 12px',
        },
    },
    hideHeaderForArchivedData: {
        '& thead tr th:nth-child(1)': {
            width: 10,
            padding: '4px 12px',
            // color: '#fff'
        },
        '& tbody tr td:nth-of-type(1)': {
            padding: '4px 12px'
        },
        '& thead tr th:nth-child(2)': {
            width: 10,
            // padding: '4px 12px',
            // color: '#fff'
        },
        '& tbody tr td:nth-of-type(2)': {
            padding: '4px 12px'
        }
    },
    buttonLabel: {
        height: 24
    },
    iconBotton: {
        '&:hover': {
            color: '#3f51b5'
        }
    }
});

class SpectralFeaturesTable extends Component {

    state = {
        columns: [],
        data: null,
        displayCreateButton: null,
        selectedRows: null,
        enableBulkEditingView: false,
        showArchivedData: false,
        headerClass: "",
        openPromptModal: false,
        rowsToBeArchived: [],
        lang:currentLanguage 

    }

    getMuiTheme = () => createMuiTheme({
        overrides: {
            MUIDataTableHeadCell: {
                fixedHeader: {
                    'z-index': 0
                },
                hintIconAlone: {
                    marginTop: '2px',
                    fontSize: '14px'
                }
            },
            MUIDataTableFilter: {
                root: {
                    'width': '382px !important'
                }
            },
            typography: {
                useNextVariants: true,
            },
            MUIDataTable: {
                responsiveScroll: {
                    height: 'calc(85vh - 90px)',
                    'max-height': 'calc(85vh - 90px) !important',
                    'z-index':'0'
                }
            },
            MuiTableCell: {
                root: {
                    "padding-right": "38px !important"
                },
                head: {
                    fontSize: 14,
                    fontWeight: 800,
                    letterSpacing: 0.25
                },
                body: {
                    fontSize: 14,
                    letterSpacing: 0.25
                }
            }
        }
    })

    // handle routing for entity view
    navigateToEntityView = () => {
        this.props.navigateToEntityView("", this.props.location.pathname.split("/")[2]);
    }


    // handle edit functionality of selected record
    onEdit = (rowIndex) => {
        let object = this.props.tableData.tableData.find((item, index) => {
            return index === rowIndex
        })
        this.props.navigateToEntityView(object, "SpectalFeaturesEntity");
    }

    // handle routing for foreign keys in the table
    handleForeignKey = (rowIndex, entityKey) => {
        let object = this.props.tableData.tableData.find(item => {
            return item.id === rowIndex
        });
        let selectedNavTab = this.props.location.pathname.split("/")[2];

        if (entityKey === "plantsName") {
            this.props.navigateToEntityView(object.plants, "PlantsEntity", selectedNavTab);
        }
        else if (entityKey === "schedulesName") {
            this.props.navigateToEntityView(object.schedules, "SchedulesEntity", selectedNavTab);
        }
        else if (entityKey === "joblistsName") {
            let selectedtab = this.props.userData.selectedTab;
            let currentScopeSelectionId = this.getCurrentScopeSelectionId(selectedtab);
            this.props.navigateToEntityView(object, "JobsTable", selectedNavTab, currentScopeSelectionId, "MachineGroupsTable");
        }
        else if (entityKey === "lossListsName") {
            let selectedtab = this.props.userData.selectedTab;
            let selectedNavTab = this.props.location.pathname.split("/")[2];
            let currentScopeSelectionId = this.getCurrentScopeSelectionId(selectedtab);

            this.props.navigateToEntityView(object, "LossCodesTable", selectedNavTab, currentScopeSelectionId, "MachineGroupsTable");
        }
    }

    // gets the id of current plant selected in scope selector
    getCurrentScopeSelectionId = (selectedTab) => {
        let selectedScopeData = "";
        if (this.props.location.pathname.split("/").length === 3) {
            let currentEntity = this.props.location.pathname.split("/")[2];

            if (currentEntity === "OrganizationsTable" || currentEntity === "PlantsTable" || currentEntity === "UsersTable") {
                selectedScopeData = this.props.userData.selectedOrganization;
            }
            else if (currentEntity === "MachineGroupsTable" || currentEntity === "MachinesTable" || currentEntity === "SchedulesTable" ||
                currentEntity === "JobsTable" || currentEntity === "LossCodesTable" || currentEntity === "MonitorsTable" || currentEntity === "DevicesTable" ||
                currentEntity === "AlertsTable" || currentEntity === "SpectralFeaturesTable") {
                selectedScopeData = this.props.userData.selectedPlant;
            }
        }
        if (selectedScopeData) {
            return selectedScopeData;
        }
    }

    componentDidMount() {
        let selectedNavTab = this.props.location.pathname.split("/")[2];
        let selectedtab = this.props.userData.selectedTab;
        let currentScopeSelectionId = this.getCurrentScopeSelectionId(selectedtab);
        if (currentScopeSelectionId) {
            this.props.getTableData(selectedNavTab, currentScopeSelectionId, false, this.state.showArchivedData);
            this.displayCreateButton();
        }
    }

    // initializes table component based on the view-definitions received from the api call
    tableConfigurations = () => {
        let columns = [];
        let data = [];
        let currentColumnObject = {};

        if (this.props.tableData.tableData.length > 0 && Object.keys(this.props.config).length > 0) {

            if (this.props.userData.permission === 'configuration') {
                if (this.props.config.SpectralFeatures.edit.table_visible) {
                    columns.push({
                        "name": "edit",
                        "label": this.props.config.SpectralFeatures.edit.display_name,
                        "options": {
                            hint: this.props.t('Edit'),
                            "display": true,
                            "filter": this.props.config.SpectralFeatures.edit.filter_visible,
                            "sort": false,
                            "searchable": false
                        }
                    })
                }
            }


            for (let key in this.props.tableData.tableData[0]) {
                let currentConfigObject = this.props.config["SpectralFeatures"][key];
                if (currentConfigObject !== undefined) {
                    currentColumnObject.name = currentConfigObject["key"];
                    currentColumnObject.label = currentConfigObject["display_name"];
                    currentColumnObject.options = {};

                    if (currentConfigObject["table_visible"]) {
                        currentColumnObject.options.display = true;
                        currentColumnObject.options.filter = currentConfigObject["filter_visible"];

                        if (currentConfigObject["ellipsis"]) {
                            currentColumnObject.options.customBodyRender = (value) => <div style={{ maxWidth: 150, overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}>{value}</div>
                            currentColumnObject.options.sort = false;
                            currentColumnObject.options.searchable = false;
                        }

                        if (currentConfigObject["table_hyperlink"] && !this.state.showArchivedData) {
                            currentColumnObject.options.customBodyRender = (value, tableMeta) => (
                                this.props.userData.permission === "configuration" ?
                                    <a onClick={() => this.handleForeignKey(tableMeta.rowData[2], currentConfigObject["key"])}>{value}</a>
                                    : value
                            )
                        }
                    }
                    else {
                        currentColumnObject.options.display = "excluded";
                        currentColumnObject.options.filter = false;
                        currentColumnObject.options.sort = false;
                        currentColumnObject.options.searchable = false;
                    }

                    columns.push(currentColumnObject)
                    currentColumnObject = {};
                }
            }
        }

        data = this.props.tableData.tableData.map((item, index) => {

            if (item.type === 2) {
                item.edit = <Edit classes={{ root: this.props.classes.editIcon }} onClick={() => this.onEdit(index)} />;
            } else {
                item.edit = null;
            }
            return item;
        });

        this.setState({
            columns,
            data
        })

    }

    // display create new button based on user permissions
    displayCreateButton = () => {
         let flag=false;
       let plantPermisssion= localStorage.getItem("plantPermisssion")
       if(this.props.userData.permission ==='configuration' || plantPermisssion === 'configuration'){
           flag=true;
       }
       else if(this.props.userData.permission ==="dashboard" && plantPermisssion === 'configuration' ){
           flag=false
       }
      flag === true ?
            this.setState({ displayCreateButton: true })
            :
            this.setState({ displayCreateButton: false })
    }

    componentDidUpdate(prevProps, prevState) {
        let selectedtab = this.props.userData.selectedTab;
        let selectedNavTab = this.props.location.pathname.split("/")[2];
        if ((prevProps.tableData !== this.props.tableData)) {
            let headerClass = "";
            if (this.props.userData.permission === "configuration") {
                headerClass = this.state.showArchivedData ? this.props.classes.hideHeaderForArchivedData : this.props.classes.hideHeaderForActiveData;
            }
            this.setState({
                headerClass
            })

            if ((prevProps.selectedScopeData !== this.props.selectedScopeData)) {
                let currentScopeSelectionId = this.getCurrentScopeSelectionId(selectedtab);
                this.props.getTableData(selectedNavTab, currentScopeSelectionId, false, this.state.showArchivedData);
                this.displayCreateButton();
            }
            else {
                this.tableConfigurations();
            }
        }
        else if ((prevProps.selectedScopeData !== this.props.selectedScopeData && prevProps.selectedTab === this.props.selectedTab) || prevState.showArchivedData !== this.state.showArchivedData) {
            let currentScopeSelectionId = this.getCurrentScopeSelectionId(selectedtab);
            if (currentScopeSelectionId) {
                this.props.getTableData(selectedNavTab, currentScopeSelectionId, false, this.state.showArchivedData);
                this.displayCreateButton();
            }
        }
        
    }

    // purpose : create csv upload/download icons,user can create new MachineGroups through csv by editing in csv file before uploading 
    //It uses  <CsvDownloadComponent/> and <CsvUploadComponent/>  which is imported from "../CsvUploadComponent" and "../CsvDownloadComponent"
    //This function also creates "Create NEW" button (for creating new MachineGroup through UI), which calls navigateToEntityView() function when clicked
    getCustomToolbar = () => {
        if (this.state.displayCreateButton) {
            return (
                <div style={{ display: 'inline-block' }}>
                    <Button variant="outlined" className={this.props.classes.createNewButton} style={{ marginLeft: 12 }}
                        onClick={() => this.navigateToEntityView("", this.props.location.pathname.split("/")[2])}>
                        {this.props.t("Create New")}
                    </Button>
                </div>
            )
        }
        else {
            return null;
        }
    }

    render() {
        const {t}=this.props;
        const options = {
            selectableRows: false,
            filter: false,
            sort: true,
            search: true,
            customToolbar: () => (this.getCustomToolbar()),
            print: false,
            elevation: 0,
            responsive: "scroll",
            download: false,
            filterType: 'dropdown',
            rowsPerPage: 20,
            rowsPerPageOptions: [20, 50, 100],
            textLabels: {
                body: {
                    noMatch: this.props.t("No matching records")
                },
                pagination: {
                    next: this.props.t("Next Page"),
                    previous: this.props.t("Previous Page"),
                    rowsPerPage: this.props.t("Rows per Page"),
                    displayRows: this.props.t("of")
                },
                toolbar: {
                    search: this.props.t("Search"),
                    download: this.props.t("Download"),
                    print: this.props.t("Print"),
                    viewColumns: this.props.t("View Columns"),
                    filterTable: this.props.t("Filter Table"),
                    upload: this.props.t("Upload"),
                    // download:currentLanguage==="en" ? "Download":"アップロード",
                    showArchivedData: this.props.t("Show Archieved Data"),
                    showActiveData: this.props.t("Show Active Data")


                },
                filter: {
                    title: this.props.t("FILTERS"),
                    reset: this.props.t("Reset")
                },
                viewColumns: {
                    title: this.props.t("Show Columns")
                },
                selectedRows: {
                    text: this.props.t("Reset"),
                    delete: this.props.t("Delete")
                }

            }
        }

        return (
            <Fragment>
                <ConfirmationModal
                props={this.props}
                    openPromptModal={this.state.openPromptModal}
                    handlePromptModal={(action) => this.handlePromptModal(action)}
                />
                <div id="tableContainer" className={this.state.headerClass}>
                    <MuiThemeProvider theme={this.getMuiTheme()}>
                        {
                            this.state.data !== null ? (
                                <MUIDataTable
                                    title={t("Spectral Features")}
                                    data={this.state.data}
                                    columns={this.state.columns}
                                    options={options}
                                />
                            ) : <Fragment></Fragment>
                        }
                    </MuiThemeProvider>
                </div>
            </Fragment >
        );
    }
}

const mapStateToProps = state => {
    return {
        userData: state.userDataReducer,
        tableData: state.tableDataReducer,
        staticData: state.staticDataReducer
    }
}

const mapDispatchToProps = dispatch => {
    return {
        getTableData: (selectedNavtab, currentScopeSelectionId, setToStore, showArchivedData) => (dispatch(actionCreators.getTableData(selectedNavtab, currentScopeSelectionId, setToStore, showArchivedData)))
    }
}

export default withTranslation()(connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(SpectralFeaturesTable)));
