import moment from "moment";
import ReactGA from "react-ga4";

const useGAEventsTracker = (category = "IDAP") => {
  const trackEvent = (action = "action", options = {}) => {
    let name = localStorage.getItem("userNameWithdollar");

    ReactGA.event(action, {
      category,
      userName: name,
      user_name: name,
      date: moment().format("YYYY-MM-DD"),
      ...options,
    });
  };

  return trackEvent;
};

export default useGAEventsTracker;
