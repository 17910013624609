import React, { Component } from 'react'
import { useTable, useExpanded , useTableState} from 'react-table'
import styled from 'styled-components'

const Styles = styled.div`
  height: 390px;

  table {
    border-spacing: 0;
    border: 0px solid black;
    overflow: auto;
    width: 100%;   

    tbody{       
        tr:nth-child(odd) {background: #e1e3e8}
        tr:nth-child(even) {background: #FFF}        
    }
    th:nth-child(4) { width: 12%}
    th,
    td {
      margin: 0;
      padding: 0.5rem;
      border : none
      color: #333;
      text-align : center;
    }
    th {
        padding: 8px;
        font-size: 16px;
        background: #bdbdbd;    
        font-weight: normal;           
    }
  }
`
function Table({ columns: userColumns, data}) {
    const {
        getTableProps,
        headerGroups,
        rows,
        prepareRow
    } = useTable(
        {
            columns: userColumns,
            data
        },
    )
    return (
        <>
            <pre>
                {/* <code>{JSON.stringify({ expanded }, null, 2)}</code> */}
            </pre>
            <table {...getTableProps()}>
                <thead>
                    {headerGroups.map(headerGroup => (
                        <tr {...headerGroup.getHeaderGroupProps()}>
                            {headerGroup.headers.map(column => {                                
                                return (
                                    <th {...column.getHeaderProps()}>{column.render('Header')}</th>
                                )
                                })
                            }
                        </tr>
                    ))}
                </thead>
                <tbody>
                    {
                        (data.length===0)? <tr>
                            <td colSpan="5">NO DATA</td>
                        </tr>
                        :
                        rows.map(
                        (row, i) =>
                            prepareRow(row) || (                               
                                <tr {...row.getRowProps()}>
                                    {row && row.cells.map(cell => {
                                        if (cell.column.Header === 'OEE' && Number.isInteger(cell.value) !== 0) {
                                            return (
                                                <td {...cell.getCellProps()}>{cell.value.toFixed(2)}</td>
                                            )
                                        }
                                        else {
                                            return (
                                                <td {...cell.getCellProps()}>{cell.render('Cell')}</td>
                                            )
                                        }                                            
                                    })}
                                </tr>
                            )
                    )}
                </tbody>
            </table>
        </>
    )
}

class OEEByPartNumberContainer extends Component {
    constructor(props){
        super(props);
        this.state = {
            data: this.props.oeeByPartNo.rows,           
            columns: [                
                {
                    Header: 'Machine Name',
                    accessor: 'machineName'
                },
                {
                    Header: 'Start Time',
                    accessor: 'startTime'
                },
                {
                    Header: 'End Time',
                    accessor: 'endTime'
                },
                {
                    Header: 'Part Count',
                    accessor: 'partCount',
                },
                {
                    Header: 'OEE',
                    accessor: 'oee'
                }
            ]
        }
    } 
    render() {
        const {columns,data} = this.state;
        return (
            <div className="oeeByPartNo-cntr" style={{"overflowY": "scroll"}}>
                 <Styles><Table columns={columns} data={data}/></Styles>
            </div>
        )
    }
}

export default OEEByPartNumberContainer;