import * as actionTypes from '../actions/actionTypes';

// const japaneaseData = {
//     "dashboard": {
//         "header": "仪表板",
//         "searchStart": "開始日時",
//         "searchEnd": "終了日時",
//         "listItem": [
//             { id: 1, range: "30Minutes", rangeValue: "30分" },
//             { id: 2, range: "hour", rangeValue: "1時間" },
//             { id: 3, range: "24hour", rangeValue: "1日" },
//             { id: 4, range: "week", rangeValue: "1週間" },
//             { id: 5, range: "month", rangeValue: "1か月" },
//             { id: 6, range: "year", rangeValue: "1年" },
//             { id: 7, range: "all", rangeValue: "全期間" }
//         ]
//     },
//     "trendHistory": {
//         "header": "傾向のグラフ",
//         "feature": "データ種別",
//         "function": "関数",
//         "interval": "間隔"
//     },
//     "equipmentHistory": {
//         "header": "診断の履歴"        
//     },
//     "commonStateForDevice":{
//         "deviceState": [
//             { name: 1 , value: "停止中"},
//             { name: 2, value: "稼働中" },
//             { name: 3, value: "注意" },
//             { name: 4, value: "警告" },
//             { name: 5, value: "切断" },
//             { name: 6, value: "欠陥のある" }
//         ]
//     },
//     "maintainenceIndex": {
//         "header": "稼働状態",
//         "alarmList": [
//             { name: " 合計アラーム数", value: "totalAlarms",  listValue: "67", color: '#ff9800' },
//             { name: "確認済みアラーム数", value: "alarmsClosed", listValue: "0", color: '#4caf50', data: '' },
//             { name: "誤検出アラーム数",  value: "falseAlarm", listValue: "0", color: '#2196f3', data: '' },
//             { name: "アラーム予測", value: "alarmForecast", color: '#ffc107', data: '' }

//         ]
//         // "stateColor" :[Idle,Operational,Caution ]
//     },
//     "realTime": {
//         "header": "現在値のグラフ / FFT解析",
//         "last": "最近参数",
//         "feature": "データ種別",
//         "getBtn" : "取得"
//     },
//     "waterFall":{
//         "header": "ウォーターフォール図",
//         "last": "最近参数",
//         "feature": "軸",
//         "getBtn" : "取得"
//     },
//     "instaParameters": {
//         "header": "各パラメータの現在値"
//     },
//     "reports": {
//         "header": "CSV / PDF 出力",
//         "reportList": [
//             { value: "Parameter" , name: "各パラメータの傾向値", id: "1" },
//             { value: "Equipment", name: "診断の履歴", id: "2" },
//             { value: "Alarms", name: "アラーム", id: "3" }
//         ]
//     }
// }

const englishData = {
    "production":{
        "DateRage": {
            "header": "TRAILING DATE RANGE",
            "listItem": [
                { id: 1, range: "today", rangeValue: "Today" },
                { id: 2, range: "shift", rangeValue: "This Shift" },
                { id: 3, range: "thisMonth", rangeValue: "This Month" },
                { id: 4, range: "30Minutes", rangeValue: "30min" },
                { id: 5, range: "hour", rangeValue: "1hr" },
                { id: 6, range: "24hour", rangeValue: "1d" },
                { id: 7, range: "week", rangeValue: "1wk" },
                { id: 8, range: "month", rangeValue: "1mo" },
                { id: 9, range: "year", rangeValue: "1yr" },
                { id: 10, range: "all", rangeValue: "All" }
    
            ]
        }
    },
    "dashboard": {
        "header": "DASHBOARD",
        "searchStart": "Search Start",
        "searchEnd": "Search End",
        "listItem": [
            { id: 1, range: "30Minutes", rangeValue: "30M" },
            { id: 2, range: "hour", rangeValue: "1H" },
            { id: 3, range: "24hour", rangeValue: "1D" },
            { id: 4, range: "week", rangeValue: "1W" },
            { id: 5, range: "month", rangeValue: "1M" },
            { id: 6, range: "year", rangeValue: "1Y" },
            { id: 7, range: "all", rangeValue: "All" }
        ]
    },
    "trendHistory": {
        "header": "Trend History",
        "feature": "Feature",
        "function": "Function",
        "interval": "Interval"
    },
    "equipmentHistory": {
        "header": "ML Status History"
    },
    "commonStateForDevice": {
        "deviceState": [
            { name: 1, value: "Idle" },
            { name: 2, value: "Operational" },
            { name: 3 , value: "Caution"},
            { name: 4, value: "Warning" },
            { name: 5, value: "Disconnected" },
            { name: 6, value: "Sensor Fault" }
        ]
    },
    "maintainenceIndex": {
        "header": "Maintenance Index",
        "alarmList": [
            { name: "Total Alarms", value: "totalAlarms", listValue: "67", color: '#ff9800' },
            { name: "Alarms Closed", value: "alarmsClosed", listValue: "0", color: '#4caf50', data: '' },
            { name: "False Alarms Set", value: "falseAlarm", listValue: "0", color: '#2196f3', data: '' },
            { name: "Alarm Forecast", value: "alarmForecast", color: '#ffc107', data: '' }
        ]
    },
    "realTime": {
        "header": "Realtime Data Stream and Frequency Spectrum Visualization",
        "last": "Last",
        "feature": "Feature",
        "getBtn" : "GET"
    },
    "waterFall":{
        "header": "Waterfall Diagram",
        "last": "Last",
        "feature": "Axis",
        "getBtn" : "GET"
    },
    "instaParameters": {
        "header": "Instantaneous Parameters"
    },
    "reports": {
        "header": "Report Generator",
        "reportList": [
            { value: "Parameter" ,name: "Parameter", id: "1" },
            { value: "Equipment", name: "Equipment", id: "2" },
            { value: "Alarms",name: "Alarms", id: "3" }
        ]
    }
}
// export const setWidgetLanguageReducer = (state={
//    x: [  { "trendHistory": "趋势历史"},
//             { "equipmentHistory": "设备历史"},
//             { "maintainenceIndex": "维护指标"},
//             { "realTime": "实时数据流及频谱可视化"},
//             { "instaParameters": "瞬时参数"},
//             { "reports": "生成报告"}
//             ]
// }, action) => {
//     switch(action.type) {

//         case actionTypes.SET_WIDGET_LANGUAGE:
//             if(action.payload=="Chinese")
//             return Object.assign({}, state,x)

//         default: return state
//     }
// }

// import * as actionTypes from '../actions/actionTypes';

export const setWidgetLanguageReducer = (state = {
    widgetData: {},
}, action) => {
    switch (action.type) {

        case actionTypes.SET_WIDGET_LANGUAGE:
            return {
                ...state,
                widgetData: englishData
            }
             

        default: return state
    }
}