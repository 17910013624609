import React, { Component } from "react";
import { MenuItem, Select, withStyles } from '@material-ui/core';
import * as actionCreators from '../actions/actionCreators';
import { connect } from 'react-redux';
import { withTranslation, useTranslation } from "react-i18next";
import { Link, withRouter } from "react-router-dom";
import { config ,IDAP_SSO_DOMAIN_NAME} from '../configuration';
import { getOidcAccessToken } from "../utils/oidcStorageUtils";


const styles = theme => ({
    selectClasses:{
        // background: "#f4f4f4",
        // borderRadius: '50px',
        height: '30px',
        width: '122px',
        marginTop: '10px',
        // color: '#bebebc',
        marginRight:'18px',
        display: "flex",
        justifyContent : "center"

    },
    select: {
       
        
        "&:before": {
            border: 'none',
        },
        '&:after': {
            border: 'none',
        },
        '& > div': {
            '& > div': {
            display:"flex",

                '&:focus': {
                    backgroundColor: 'transparent'
                }
            }
        },
        color: "inherit",
        'font-size': '1rem',
    },
   
    icon: {
        fill: "#bebebc",
        top:' 3px',
    right:' 3px',
    },
    underline: {
        '&:hover': {
            '&:before': {
                border: 'none !important'
            }
        }
    },
    menu: {
      maxHeight: '303px !important', 
  }
});

class LanguageDropdown extends Component {
  state = {
    scope: "",
    open: false,
    value: "en",
    lang: this.props.i18n.language,
    preferredLang : "",
  };
  getCookie(cname) {
    let name = cname + "=";
    let decodedCookie = decodeURIComponent(document.cookie);
    let ca = decodedCookie.split(";");
    for (let i = 0; i < ca.length; i++) {
      let c = ca[i];
      while (c.charAt(0) === " ") {
        c = c.substring(1);
      }
      if (c.indexOf(name) === 0) {
        return c.substring(name.length, c.length);
      }
    }
    return "";
  }
  handleChange = (event) => {
    document.cookie =
      `idap-lang-${config.url.env}=null` +
      "; domain=" +
      `${IDAP_SSO_DOMAIN_NAME}; secure`;

      this.setState({ value: event.target.value });
      this.props.setWidgetLanguage(event.target.value);
      this.props.i18n.changeLanguage(event.target.value);
      localStorage.setItem("lang", event.target.value);
    let url_string = window.location.href;
    let string = url_string.split("&");
    if (string[1]) {
      let lang = string[1].split("lang=");
      if (lang[1] && lang[1] !== "null") {
        window.location.replace(string[0]);
      }
    }
    // this.props.history.replace("/dashboard");
  };

  handleClose = () => {
    this.setState({ open: false });
  };

  handleOpen = () => {
    this.setState({ open: true });
  };

  getPreferredLanguage = async () => {
    fetch(config.url.plant_os_api_url+`/user/primary-data`,
    {
      method : "GET",
      headers : {
        Authorization : `Bearer ${getOidcAccessToken()}`,
        "Accept-Language": localStorage.getItem("lang") || "en"
      }
    }).then(res => res.json())
    .then(res => this.setState({preferredLang : res.data.language}))
  }

  componentDidMount() {
    this.getPreferredLanguage();
   this.props.getLanguageList();
    let currentLanguage = localStorage.getItem("lang");

    if (currentLanguage === "null" || currentLanguage === null) {
      currentLanguage = "en";
    }
    let url_string = window.location.href;
    let string = url_string.split("&");
    if (string[1]) {
      let lang = string[1].split("lang=");
      if (lang[1] && lang[1] !== "null") {
        currentLanguage = lang[1];
      }
    }
    // let langSet = this.getCookie(`idap-lang-${config.url.env}`);
    // if (langSet && langSet !== "null" && langSet !== "") {
    //   currentLanguage = langSet;
    // }
    // using local storage now

    this.props.setWidgetLanguage(currentLanguage);
    this.props.i18n.changeLanguage(currentLanguage);
    this.setState({value: currentLanguage});
  }
  componentDidUpdate(prevProps, prevState) {
    let dashboard = localStorage.getItem("dashboard");
    let url_string = window.location.href;
    let string = url_string.split("&");
    if (string[1]) {
    } else {
      if (prevProps.i18n.language !== prevState.lang) {
        if (dashboard === "monitoring") {
          document.cookie =
            `idap-lang-${config.url.env}=null` +
            "; domain=" +
            `${IDAP_SSO_DOMAIN_NAME}; secure`;
          window.location.reload();
        }
          window.location.reload();
      }
      if(prevState.preferredLang!== this.state.preferredLang || prevProps.languagesListData !== this.props.languagesListData) {
        if(this.props.languagesListData && this.props.languagesListData.langList && this.props.languagesListData.langList.length > 0 && this.state.preferredLang !== "") {
          let currentLanguage = localStorage.getItem("lang");
          if (currentLanguage === "null" || currentLanguage === null || currentLanguage === "undefined" || !currentLanguage) {
            const preferredLangSetting = this.props.languagesListData.langList.find(lang => lang.languageName === this.state.preferredLang.toLocaleLowerCase());
            const preferredLangByUser = preferredLangSetting ? preferredLangSetting.languageCode : "en";
            this.setState({value: preferredLangByUser});
            localStorage.setItem("lang", preferredLangByUser);
            this.props.i18n.changeLanguage(preferredLangByUser);
          }
        }
      }
    }
  }

  getLanguageData = () => {
    return this.props.languagesListData.langList.map(lang => {
        const capitalizedLanguageName = lang.languageName.charAt(0).toUpperCase() + lang.languageName.slice(1);
        return (
          <MenuItem key={lang.languageCode} value={lang.languageCode} style={{
            color: "#000",
            fontSize: "14px",
            width:"100%"
          }}
          disabled={lang.url === null}>
            <img style={{marginRight:"7px", width:"22px"}} alt={lang.languageCode} src={lang.countryFlagUrl}/>{capitalizedLanguageName}
          </MenuItem>
        );
      });
  };

  render() {
    return (
      <div  sx={{}} className={this.props.classes.selectClasses} id="languageChange">
        {this.props.languagesListData && this.props.languagesListData.langList && 
        <Select
           
        sx={{
          color: 'white',
          boxShadow: 'none',
          '.MuiOutlinedInput-notchedOutline': { border: 0 },
          '&.MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline': {
            border: 0,
          },
          '& .MuiSelect-icon': {
            color: 'white',
            top: '2px',
          },
          '&.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': { border: 0 },
        }}
            className={
              this.props.classes.select + " " + this.props.classes.underline
            }
            open={this.state.open}
            onClose={this.handleClose}
            onOpen={this.handleOpen}
            value={this.state.value}
            onChange={this.handleChange}
            id="selectedLanguage"
            inputProps={{
              classes: {
                icon: this.props.classes.icon,
              },
            }}
            MenuProps={{
              classes: {
                  paper: this.props.classes.menu,
              }
          }}
          >
            {this.getLanguageData()}
          </Select>}
        </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
      languagesListData: state.languageListReducer,
  }
}

const mapDispatchToProps = dispatch => {
    return {
        // changeLanguage: (language) => (dispatch(actionCreators.changeLanguage(language))),
        setWidgetLanguage: (language) => (dispatch(actionCreators.setWidgetLanguage(language))),
        getLanguageList: () => (dispatch(actionCreators.getLanguageList())),
    }
}

export default withRouter(withTranslation()(connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(LanguageDropdown))));