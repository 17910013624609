import "react-app-polyfill/ie11";
import "react-app-polyfill/stable";
import { NativeEventSource, EventSourcePolyfill } from "event-source-polyfill";

import React from "react";
import ReactDOM from "react-dom";
import thunk from "redux-thunk";
import ReactGA from "react-ga4";
import { HashRouter, Route, Switch } from "react-router-dom";
import { createStore, applyMiddleware, compose } from "redux";
import { Provider } from "react-redux";
import { I18nextProvider } from "react-i18next";
import { AuthProvider } from "react-oidc-context";

import App from "./App";
import Dashboard from "./components/Dashboard";
import Login from "./components/Login";
import AuthRedirector from "./components/AuthRedirector";
import ResetPassword from "./../src/components/ResetPasswordView";
import i18n from "./i18n";
import { authProvider } from "./oidcConfig";
import * as serviceWorker from "./serviceWorker";
import { rootReducer } from "./reducers/rootReducer";
import { config } from "./configuration";

import "./index.css";

global.EventSource = NativeEventSource || EventSourcePolyfill;

// redux dev-tools config
const composeEnhancer = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
window.__MUI_USE_NEXT_TYPOGRAPHY_VARIANTS__ = true;

ReactGA.initialize(config.ga_tracking_code, {
  gaOptions: {
    cookieFlags: "secure;",
  },
  gtagOptions: {
    cookie_flags: "secure;",
  },
});

let name = localStorage.getItem("userNameWithdollar");

ReactGA.set({ user_properties: { user_id: name, name } });

const store = createStore(
  rootReducer(),
  composeEnhancer(applyMiddleware(thunk))
);

ReactDOM.render(
  <AuthProvider {...authProvider.oidcConfig}>
    <I18nextProvider i18n={i18n}>
      <Provider store={store}>
        <HashRouter>
          <Switch>
            <Route path="/resetPassword" component={ResetPassword} />
            <AuthRedirector>
              <Switch>
                <Route path="/" exact component={App} />
                <Route path="/login" component={Login} />
                <Route path="/dashboard" component={Dashboard} />
                <Route component={Login} />
              </Switch>
            </AuthRedirector>
          </Switch>
        </HashRouter>
      </Provider>
    </I18nextProvider>
  </AuthProvider>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
