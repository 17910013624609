import React, { Component } from 'react'
import { withTranslation } from "react-i18next";

class DowntimeAnalysis extends Component {
    constructor(props){
        super(props);
    }  

    onSelectionChange = (e) => {
        this.props.onDowntimeChange(e.target.value);       
    }
    
    render() {
        const{t}=this.props;
        return (
            <div className="downtimeAnalysis-wrp display-text"> 
                <div className="list-selection-wrp display-text">
                        <select className="list-selection form-control" id="downtime_analysis_type" onChange={this.onSelectionChange}>
                            <option value="histogram">{t("DOWNTIME HISTOGRAM")}</option>
                            <option value="pareto">{t("DOWNTIME PARETO")}</option>
                        </select>
                    </div>
            </div>
        )
    }
}
  
export default withTranslation()(DowntimeAnalysis);