import React, { Component } from 'react'
import { useTable } from 'react-table'
import styled from 'styled-components'
import * as actionCreators from '../../../actions/actionCreators';
import { connect } from 'react-redux';
import moment from "moment";
import { withTranslation } from "react-i18next";


const Styles = styled.div`
  height: 390px;

  table {
    border-spacing: 0;
    border: 0px solid black;
    overflow: auto;
    width: 100%;   

    tbody{       
        tr:nth-child(odd) {background: #e1e3e8}
        tr:nth-child(even) {background: #FFF}        
    }
    th:nth-child(4) { width: 12%}
    th,
    td {
      margin: 0;
      padding: 0.5rem;
      border : none
      color: #333;
      text-align : center;
    }
    th {
        padding: 8px;
        font-size: 16px;
        background: #bdbdbd;    
        font-weight: normal;   
        width : 10%;        
    }
  }
`
function Table({ columns: userColumns, data}) {
    const {
        getTableProps,
        headerGroups,
        rows,
        prepareRow
    } = useTable(
        {
            columns: userColumns,
            data
        },
    )
    return (
        <>
            <pre>
                {/* <code>{JSON.stringify({ expanded }, null, 2)}</code> */}
            </pre>
            <table {...getTableProps()}>
                <thead>
                    {headerGroups.map(headerGroup => (
                        <tr {...headerGroup.getHeaderGroupProps()}>
                            {headerGroup.headers.map(column => {                                
                                return (
                                    <th {...column.getHeaderProps()}>{column.render('Header')}</th>
                                )
                                })
                            }
                        </tr>
                    ))}
                </thead>
                <tbody>
                    {
                        (data.length===0)? <tr>
                            <td colSpan="5">NO DATA</td>
                        </tr>
                        :
                        rows.map(
                        (row, i) =>
                            prepareRow(row) || (                               
                                <tr {...row.getRowProps()}>
                                    {row && row.cells.map(cell => {
                                        if (cell.column.Header === 'Sr No.') {
                                            return (
                                                <td>{i+1}</td>
                                            )
                                        }
                                        else{
                                            return (
                                                <td {...cell.getCellProps()}>{cell.render('Cell')}</td>
                                            )     
                                        }                               
                                    })}
                                </tr>
                            )
                    )}
                </tbody>
            </table>
        </>
    )
}


class TopMachineLossContainer extends Component {
    constructor(props){
        super(props);
        this.state = {
            data: [],           
            columns: [                
                {
                    Header: 'Sr No.',
                    accessor: 'SrNo'
                },
                {
                    Header: 'Loss Hour',
                    accessor: 'stateDuration'
                },
                {
                    Header: 'LossId',
                    accessor: 'lossId'
                },
                {
                    Header: 'Operator Name',
                    accessor: 'operatorName'
                }
            ],
            columnsPlant: [                
                {
                    Header: 'Sr No.',
                    accessor: 'SrNo'
                },
                {
                    Header: 'Loss Hour',
                    accessor: 'stateDuration'
                },
                {
                    Header: 'LossId',
                    accessor: 'lossId'
                },
                {
                    Header: 'Machine Name',
                    accessor: 'machineName'
                }
            ]
        }
    }  

    componentDidMount(){       
        this.setState({ data: this.props.plantLoss.loss })
    }

    componentDidUpdate(prevProps){
        let from = moment(this.props.range[1]).utc().format("YYYY-MM-DDTHH:mm:00") + "Z";
        let to = moment(this.props.range[2]).utc().format("YYYY-MM-DDTHH:mm:00") + "Z";
       
        if( prevProps.machineIdforLoss !== this.props.machineIdforLoss && this.props.machineIdforLoss ==0){           
            this.setState({ data: this.props.plantLoss.loss })
        }
        if( prevProps.machineIdforLoss !== this.props.machineIdforLoss && this.props.machineIdforLoss !=0){
            this.props.getTopMachineLoss(this.props.machineIdforLoss,from,to, this.props.selectedPlantId)            
        }
        if( prevProps.machineLoss.loss !== this.props.machineLoss.loss ){           
            this.setState({ data: this.props.machineLoss.loss })
        }
    }
    
    render() {
        const {t}=this.props;
        const { columns, columnsPlant, data } = this.state;
        return (
            <div className="TopMachineLoss-cntr display-text" style={{overflowY: "scroll", width : "100%"}}> 
            <p> {t("current selected machine id")} : {this.props.machineIdforLoss} </p>
            {this.props.machineIdforLoss == 0  ?
                <Styles><Table columns={columnsPlant} data={data}/></Styles>
                : <Styles><Table columns={columns} data={data}/></Styles>}
            </div>
        )
    }
}

const mapStateToProps = state => {
    return {
      plantLoss : state.topPlantLossReducer,
      machineLoss : state.topMachineLossReducer
    }
  }

const mapDispatchToProps = dispatch => {
    return {        
        getTopPlantLoss: (plantId , from, to) => (dispatch((actionCreators.getTopPlantLoss(plantId , from, to)))),
        getTopMachineLoss: (machineId , from, to, plantId) => (dispatch((actionCreators.getTopMachineLoss(machineId , from, to, plantId)))),
    }
}
export default withTranslation()(connect(mapStateToProps, mapDispatchToProps)(TopMachineLossContainer));