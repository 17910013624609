import * as actionTypes from '../actions/actionTypes';

export const metadataReducer = (state = {}, action) => {
    switch (action.type) {

        case actionTypes.GET_METADATA:
            return Object.assign({}, state, action.payload)

        default: return state
    }
}