import * as actionTypes from '../actions/actionTypes';

export const saveAlarmDataReducer = (state={}, action) => {
    switch(action.type) {

        case actionTypes.SAVE_ALARM_DATA:{ 
            return Object.assign({}, state, action.payload)
        }
        
        default: return state
    }
}