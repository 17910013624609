import React, { Component } from 'react';
import { connect } from 'react-redux';
import {
    withStyles,
    Dialog,
    DialogActions,
    Button,
    InputLabel,
    Select,
    OutlinedInput,
    Divider
} from '@material-ui/core';
import DatePicker from 'react-date-picker';
import { withTranslation } from "react-i18next";

import * as actionCreators from '../../actions/actionCreators';
import { TramRounded } from '@material-ui/icons';

const styles = () => ({
    dialog: {
        padding: '12px 24px'
    },
    outlinedInput: {
        padding: 12
    },
    calenderField: {
        width: '315px',
        height: '45px !important',

    },
    elementContainer: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        paddingTop: 24
    },
    textFeildLabel: {
        color: 'rgba(0, 0, 0, 0.4000000059604645)',
        'letter-spacing': 0.5,
        'font-weight': 500,
        fontSize: "14px",
        paddingLeft: "7px",
        lineHeight: 2.5
    },
    textField: {
        border: "1px solid #00000005",
        borderRadius: "6px",
        width: '315px',
        height: '45px',
    },
    textArea: {
        border: "1px solid #00000005",
        width: '315px',
    },
    multiline: {
        paddingTop: 7
    },
    saveButton: {
        width: 100,
        backgroundColor: '#42D784',
        '&:hover': {
            backgroundColor: '#42D784'
        }
    },
    dialogAction: {
        marginTop: 24
    }
})
// const subscriptionMandatory = this.props.t("Please enter both Subscription Start and Subscription End.")
// const validSubscription = this.props.t("Subscription End should be greater than or equal to Subscription Start.")

class DevicesModal extends Component {

    state = {
        deviceType: "",
        notes: "",
        specs: "",
        changedDeviceType: false,
        changedNotes: false,
        changedSpecs: false,
        subscriptionStart: null,
        subscriptionEnd: null,
        changedSubscriptionStart: false,
        changedSubscriptionEnd: false,
        subscriptionEndError: false,
        subscriptionError: ""

    }

    componentDidMount() {

        setTimeout(() => {
            const datePickers = document.getElementsByClassName(
                "react-date-picker__inputGroup__input"
            );
            for (let i = 0; i < datePickers.length; i++) {
                datePickers[i].setAttribute("readonly", true);
            }
        }, 200);


    }

    // handle updated user input values
    handleUpdate = () => {
        this.props.updateSelectedRecords(this.state);
        this.props.disableBulkEditingView();
    }

    // handle modal window close action
    handleClose = () => {
        this.props.disableBulkEditingView();
        this.setState({
            deviceType: "",
            notes: "",
            specs: "",
            changedDeviceType: false,
            changedNotes: false,
            changedSpecs: false,
            subscriptionStart: null,
            subscriptionEnd: null,
            changedSubscriptionStart: false,
            changedSubscriptionEnd: false,
            subscriptionEndError: false,
            subscriptionError: ""
        })
    }

    // handle user input changes
    onChangeHandler = (event, item) => {
        switch (item) {
            case "DeviceType":
                this.setState({
                    deviceType: event.target.value,
                    changedDeviceType: true
                })
                break;
            case "Notes":
                this.setState({
                    changedNotes: true,
                    notes: event.target.value
                })
                break;
            case "Specs":
                this.setState({
                    changedSpecs: true,
                    specs: event.target.value
                })
                break;
            default: console.log("default case");
        }
    }
    handleDateChange = (date, type) => {
        if (type === "start") {
            this.setState({ subscriptionStart: date, changedSubscriptionStart: true }, () => {
                if (this.state.subscriptionStart !== null && this.state.subscriptionEnd !== null) {
                    if (this.state.subscriptionEnd < this.state.subscriptionStart) {
                        this.setState({ subscriptionEndError: true, subscriptionError: this.props.t("Subscription End should be greater than or equal to Subscription Start.") })
                    } else {
                        this.setState({ subscriptionEndError: false })
                    }
                } else if ((this.state.subscriptionEnd === null && this.state.subscriptionStart !== null) || (this.state.subscriptionEnd !== null && this.state.subscriptionStart === null)) {
                    this.setState({ subscriptionEndError: true, subscriptionError: this.props.t("Please enter both Subscription Start and Subscription End.") })
                } else {
                    this.setState({ subscriptionEndError: false })
                }
            })
        } else {
            this.setState({ subscriptionEnd: date, changedSubscriptionEnd: true }, () => {
                if (this.state.subscriptionStart !== null && this.state.subscriptionEnd !== null) {
                    if (this.state.subscriptionEnd < this.state.subscriptionStart) {
                        this.setState({ subscriptionEndError: true, subscriptionError: this.props.t("Subscription End should be greater than or equal to Subscription Start.") })
                    } else {
                        this.setState({ subscriptionEndError: false })
                    }
                } else if ((this.state.subscriptionEnd === null && this.state.subscriptionStart !== null) || (this.state.subscriptionEnd !== null && this.state.subscriptionStart === null)) {
                    this.setState({ subscriptionEndError: true, subscriptionError: this.props.t("Please enter both Subscription Start and Subscription End.") })
                } else {
                    this.setState({ subscriptionEndError: false })
                }
            })
        }

    };

    // create device-types dropdown list
    getDeviceType = () => {
        let deviceTypeList = [];
        deviceTypeList.push(<option key={""} value={""}>{"Select"}</option>);
        if ((this.props.config.deviceTypeName.validations && !this.props.config.deviceTypeName.validations.required) || !(this.props.config.deviceTypeName.validations)) {
            deviceTypeList.push(<option key={""} value={" "}>{"None"}</option>);
        }
        if (this.props.parentOrganizationId && this.props.staticData && this.props.staticData.staticData) {
            let plantOrganizationStaticData = this.props.staticData.staticData[this.props.parentOrganizationId]
            if (plantOrganizationStaticData && plantOrganizationStaticData.devicetypes) {
                plantOrganizationStaticData.devicetypes.map((data) => {
                    deviceTypeList.push(
                        <option key={data.id} value={data.id}>{data.name}</option>
                    );
                })
            }
        }
        return deviceTypeList;
    }
    
    disableSaveButton = () => {
        if (this.state.changedDeviceType || this.state.changedSpecs || this.state.changedNotes || this.state.changedSubscriptionEnd || this.state.changedSubscriptionStart) {
            if (this.state.subscriptionEndError) {
                return true;
            } else {
                if ((this.state.subscriptionEnd === null && this.state.subscriptionStart !== null) || (this.state.subscriptionEnd !== null && this.state.subscriptionStart === null)) {
                    return true;
                }
                else {
                    return false;
                }
            }
        } else {
            return true;
        }
    }

    render() {
        const {t} =this.props;
        return (
            <Dialog
                open={this.props.enableBulkEditingView}
                onClose={this.handleClose}
                aria-labelledby="form-dialog-title"
                maxWidth="sm"
                fullWidth={true}
                classes={{ paper: this.props.classes.dialog }}
                disableBackdropClick={true}
            >
                {
                    this.props.userData && this.props.userData.admin && (this.props.userData.parentOrganizationType === "publisher") ?
                        <>
                            <div className={this.props.classes.elementContainer}>
                                <InputLabel className={this.props.classes.textFeildLabel}>
                                    {t("Subscription Start")}
                        </InputLabel>
                                <DatePicker
                                    onChange={date => { this.handleDateChange(date, "start") }}
                                    value={this.state.subscriptionStart}
                                    format="dd-MM-y"
                                    className={this.props.classes.calenderField}
                                />
                            </div>
                            <div className={this.props.classes.elementContainer}>
                                <InputLabel className={this.props.classes.textFeildLabel}>
                                    {t("Subscription End")}
                        </InputLabel>
                                <DatePicker
                                    onChange={date => { this.handleDateChange(date, "end") }}
                                    value={this.state.subscriptionEnd}
                                    className={this.props.classes.calenderField}
                                    format="dd-MM-y"
                                />
                            </div>
                            {this.state.subscriptionEndError ?
                                <div style={{ marginTop: "10px", fontSize: "12px", color: "red" }}> {this.state.subscriptionError}</div>
                                : ""}
                            <Divider style={{ marginTop: "10px" }}></Divider>

                        </>
                        : null}

                {
                    this.props.config.deviceTypeName.page_visible ?
                        (
                            <div className={this.props.classes.elementContainer}>
                                <InputLabel className={this.props.classes.textFeildLabel}>
                                    {this.props.config.deviceTypeName.display_name}
                                </InputLabel>
                                <Select
                                    native
                                    value={this.state.deviceType}
                                    onChange={(event) => this.onChangeHandler(event, "DeviceType")}
                                    input={
                                        <OutlinedInput
                                            variant="outlined"
                                            name="deviceType"
                                            id="language-native-simple"
                                            labelWidth={0}
                                            className={this.props.classes.textField}
                                            classes={{ input: this.props.classes.outlinedInput }}
                                        />
                                    }
                                >
                                    {this.getDeviceType()}
                                </Select>
                            </div>
                        )
                        : null
                }
                {
                    this.props.config.notes.page_visible ?
                        (
                            <div className={this.props.classes.elementContainer}>
                                <InputLabel className={this.props.classes.textFeildLabel} style={{ alignSelf: 'flex-start' }}>
                                    {this.props.config.notes.display_name}
                                </InputLabel>
                                <OutlinedInput
                                    multiline
                                    rows="5"
                                    value={this.state.notes}
                                    onChange={(event) => this.onChangeHandler(event, "Notes")}
                                    className={this.props.classes.textArea}
                                    classes={{ multiline: this.props.classes.multiline }}
                                    labelWidth={0}
                                />
                            </div>
                        )
                        : null
                }
                {
                    this.props.config.specs.page_visible ?
                        (
                            <div className={this.props.classes.elementContainer}>
                                <InputLabel className={this.props.classes.textFeildLabel} style={{ alignSelf: 'flex-start' }}>
                                    {this.props.config.specs.display_name}
                                </InputLabel>
                                <OutlinedInput
                                    multiline
                                    rows="5"
                                    value={this.state.specs}
                                    onChange={(event) => this.onChangeHandler(event, "Specs")}
                                    className={this.props.classes.textArea}
                                    classes={{ multiline: this.props.classes.multiline }}
                                    labelWidth={0}
                                />
                            </div>
                        )
                        : null
                }
                <DialogActions classes={{ root: this.props.classes.dialogAction }}>
                    <Button onClick={this.handleClose} color="primary">
                        {t("Cancel")}
                    </Button>
                    <Button
                        variant="contained"
                        color="primary"
                        disabled={this.disableSaveButton()}
                        className={this.props.classes.saveButton}
                        onClick={this.handleUpdate}
                    >
                        {t("Save")}
                        </Button>
                </DialogActions>
            </Dialog>
        )
    }
}

const mapDispatchToProps = dispatch => {
    return {
        getTableData: (selectedNavtab, currentScopeSelectionId, setToStore) => (dispatch(actionCreators.getTableData(selectedNavtab, currentScopeSelectionId, setToStore))),
    }
}

export default withTranslation()(connect(null, mapDispatchToProps)(withStyles(styles)(DevicesModal)));