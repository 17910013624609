import React, { Component, Fragment } from 'react';
import ReactDOM from 'react-dom';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import  RenderContextTable  from './renderContextTable';
import { ExpandLess, ExpandMore, ThumbUp, ThumbDown } from '@material-ui/icons'
import SchoolIcon from '@material-ui/icons/School'
import { connect } from 'react-redux';
import { withTranslation } from "react-i18next";
import * as actionCreators from '../../../../actions/actionCreators';

const HeaderWithSorting = ({
    children,
    isCurrentOrderBy,
    onClick,
    orderDirection,
}) => (
    <TableSortLabel
        active={isCurrentOrderBy}
        direction={orderDirection}
        onClick={onClick}
    >
        {children}
    </TableSortLabel>
);

const ColumnWithSorting = ({
    intent,
    label,
    name,
    value,
    onSort,
    orderBy,
    orderDirection,
}) =>
    intent === 'header' ? (
        <HeaderWithSorting
            onClick={() => onSort(name)}
            isCurrentOrderBy={orderBy === name}
            orderDirection={orderDirection}
        >
            {label}
        </HeaderWithSorting>
    ) : (
        <span>{value}</span>
    );
// const ColumnWithNoteSorting = ({
//     intent,
//     label,
//     name,
//     value,
//     onSort,
//     orderBy,
//     orderDirection,
//     id,
//     status,
//     action,
//     actionNote,
//     actionNotes,
//     actNoteError,
//     actID,

// }) =>
//     intent === 'header' ? (
//         <HeaderWithSorting
//             onClick={() => onSort(name)}
//             isCurrentOrderBy={orderBy === name}
//             orderDirection={orderDirection}
//         >
//             {label}
//         </HeaderWithSorting>
//     ) : (
//         <div style={{ marginLeft: "-71px" }}>
//             <textarea placeholder="Action Note" rows="2" value={value} style={{ resize: 'none', fontSize: "14px" }} onChange={(e) => actionNotes(e, 'btn', id)}></textarea>
//             {actNoteError && (actID === id) && <div style={{ color: '#f44336', fontSize: '9px' }}>Please enter maximum 100 characters</div>}
//             <span style={{ display: "flex" }}>
//                 <button className="action-disable" style={{ border: 'none', background: 'green', color: "#fff" }} id={'btn' + id} onClick={(event) => action('btn', id, status, actionNote)}  >
//                     Save
//                 </button>
//             </span></div>
//     );

const ChipColumnWithSorting = ({
    intent,
    label,
    name,
    value,
    onSort,
    orderBy,
    orderDirection,
}) =>
    intent === 'header' ? (
        <HeaderWithSorting
            onClick={() => onSort(name)}
            isCurrentOrderBy={orderBy === name}
            orderDirection={orderDirection}
        >
            {label}
        </HeaderWithSorting>
    ) : (
        <span>{value}</span>
    );

const LevelColumnWithSorting = ({
    intent,
    label,
    name,
    value,
    onSort,
    orderBy,
    orderDirection,
    t
}) =>
    intent === 'header' ? (
        <HeaderWithSorting
            onClick={() => onSort(name)}
            isCurrentOrderBy={orderBy === name}
            orderDirection={orderDirection}
        >
            {label}
        </HeaderWithSorting>
    ) : (
        <div>
            {/* {value.toLowerCase() === 'danger' ? (
                    <div class="bg-Danger">{value}</div>
                ) : null
                } */}
            {value === 4 ? (
                <div class="bg-Warning">{t("Warning")}</div>
            ) : null
            }
            {value === 3 ? (
                <div class="bg-Caution">{t("Caution")}</div>
            ) : null
            }
        </div>
    );


const DetailsColumnWithSorting = ({
    intent,
    label,
    name,
    value,
    onSort,
    orderBy,
    orderDirection,
    t,
    labelChange
}) =>
    intent === 'header' ? (
        <HeaderWithSorting
            onClick={() => onSort(name)}
            isCurrentOrderBy={orderBy === name}
            orderDirection={orderDirection}
        >
            {t(label)}
        </HeaderWithSorting>
    ) : (
        <div>
            {labelChange(value)}
        </div>
    );


class PopoverTable extends Component {
    constructor(props) {
        super(props);
        this.state = {
            alarmsData: [], orderBy: '', orderDirection: 'asc'
        };
    }



    componentDidMount() {
       
        this.setState({
            alarmsData: this.props.alarmsData
        })
        this.handleSort('name');

    }
    // actionNotes = (e, data, id) => {
    //     let element = document.getElementById(data + id);
    //     let index = this.state.alarmsData.findIndex(ele => ele.id === id);
    //     let alarmsData = this.state.alarmsData;

    //     if (e.target.value.length <= 100 && e.target.value.length !== 0) {
    //         element.classList.remove("action-disable");
    //         alarmsData[index].actionNote = e.target.value;
    //         this.setState({ alarmsData, actNoteError: false, actID: id })
    //     } else {
    //         element.classList.add("action-disable");
    //         this.setState({ actNoteError: true, actID: id })
    //     }
    // }

    handleSort = orderBy => {
        let orderDirection = this.state.orderDirection;

        if (orderBy === this.state.orderBy) {
            orderDirection = orderDirection === 'asc' ? 'desc' : 'asc';
        } else {
            orderDirection = 'asc';
        }

        // this.setState({
        //     orderDirection,
        //     orderBy,
        //     alarmsData: this.state.alarmsData.sort((item1, item2) => {
        //         if (item1[orderBy] < item2[orderBy])
        //             return orderDirection === 'asc' ? -1 : 1;
        //         if (item2[orderBy] < item1[orderBy])
        //             return orderDirection === 'asc' ? 1 : -1;
        //         return 0;
        //     }),
        // });
    };


    handleSearchClick = event => {
        event.stopPropagation();
    };
    labelChange=(val)=>{
     let x=val.split(":");
     let y=x[2].split(",")
     let newValue=this.props.t(x[0])+": "+this.props.t(x[1])+":"+y[0]+","+this.props.t(y[1])+":"+x[3];

     return newValue

    }
    render() {
        const { alarmsData, orderBy, orderDirection } = this.state;

        return (
            <Fragment>
                <Card className="drawer-content">
                    <CardHeader title="" className="card-header" />
                    <RenderContextTable
                        data={alarmsData}
                        orderBy="calories"
                        order="asc"
                    >
                        <ColumnWithSorting
                            label={this.props.t("Asset")}
                            name="machineName"
                            onSort={this.handleSort}
                            orderBy={orderBy}
                            orderDirection={orderDirection}
                        />
                        <ColumnWithSorting
                            label={this.props.t("Measurement Location")}
                            name="name"
                            onSort={this.handleSort}
                            orderBy={orderBy}
                            orderDirection={orderDirection}
                        />
                        <ChipColumnWithSorting
                            label={this.props.t("Time")}
                            name="time"
                            onSort={this.handleSort}
                            orderBy={orderBy}
                            orderDirection={orderDirection}
                        />
                        <LevelColumnWithSorting
                            label={this.props.t("Level")}
                            name="level"
                            onSort={this.handleSort}
                            orderBy={orderBy}
                            orderDirection={orderDirection}
                            t={this.props.t}
                        />
                        <DetailsColumnWithSorting
                            label={this.props.t("Details")}
                            name="details"
                            onSort={this.handleSort}
                            orderBy={orderBy}
                            orderDirection={orderDirection}
                            t={this.props.t}
                            labelChange={this.labelChange}
                        />
                        {/* <ColumnWithNoteSorting
                            label="Action Note"
                            name="actionNote"
                            onSort={this.handleSort}
                            orderBy={orderBy}
                            id={alarmsData.id}
                            status={alarmsData.status}
                            action={this.action}
                            orderDirection={orderDirection}
                            actionNote={alarmsData.actionNote}
                            actionNotes={this.actionNotes}
                            actNoteError={this.state.actNoteError}
                            actID={this.state.actID}
                        /> */}
                    </RenderContextTable>
                </Card>
            </Fragment>
        );
    }
}



export default withTranslation ()(connect(null, null)(PopoverTable));
