import React, { Component } from 'react';
import {
    Grid, Button, FormControl, TextField,
    Paper, Typography, withStyles, InputAdornment,
    AppBar, CircularProgress
} from '@material-ui/core';
import { Visibility, VisibilityOff, AccountCircle } from '@material-ui/icons';
import { withTranslation } from "react-i18next";
import { config } from './../configuration';
import { connect } from 'react-redux';
import * as actionCreators from '../actions/actionCreators';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import { CardHeader } from '@material-ui/core';
import { Link } from 'react-router-dom';
import queryString from 'query-string';
import LanguageDropdown from "./LanguageDropdown.js"


let server_url = config.url.server_url;

const styles = theme => ({
    gridContainer: {
    },
    gridItem: {
        display: 'flex',
        'justify-content': 'center',
        'align-items': 'center',
        'flex-basis': '100vw'
    },
    main: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    logoClass: {
        margin: '5px',
        width: "140px",
        height: "40px"
    },
    logoClass1: {
        width: "200px",
        margin: "5px",
        height: "98px",
        paddingTop: "10px",
        display: 'block',
        marginLeft: 'auto',
        marginRight: 'auto',
    },
    paper: {
        display: 'flex',
        borderRadius: 0,
        marginTop: '5rem',
        width: '446px',
        backgroundColor: '#FFFFFF'
    },
    form: {
        width: '100%',
    },
    outlinedInput: {
        height: 50,
        color: '#ffc107 !important',
        backgroundColor: 'transparent !important',
        '-webkit-appearance': 'none'
    },
    notchedOutline: {
        borderColor: '#ffc107!important !important'
    },
    labelRoot: {
        color: '#979797 !important'
    },
    focusedInput: {
        color: '#1565c0 !important'
    },
    accountIcon: {
        color: '#1565c0',
    },
    loading: {
        position: "fixed",
        "z-index": 1400,
        height: "100vh",
        width: "100%",
        "background-color": "transparent",
        display: 'flex',
        'justify-content': 'center',
        'align-items': 'center'
    },
    loaderColor: {
        color: '#2abbe8'
    },
    submit: {
        marginTop: theme.spacing.unit * 3,
        color: "#FFFFFF",
        borderRadius: 3,
        width: '100%',
        height: 36,
        backgroundColor: "#31ccec",
        "&:hover": {
            backgroundColor: "#31ccec",
        },
        marginTop: 8
    },
    disabledButton: {
        backgroundColor: '#D8D8D8 !important'
    },
    header: {
        height: '50px',
        backgroundColor: '#1565c0 !important',
    },
    header1: {
        height: '50px',
        backgroundColor: '#FFFFFF !important',
        boxShadow: 'none'
    },
    loginCardHeader: {
        height: '30px',
        backgroundColor: '#2196f3',
        textAlign: 'center',
        color: '#FFFFFF',
        padding:"25px"
    },
    title: {
        color: '#FFFFFF',
        fontSize: "22px"

    },
    loginButton: {
        marginTop: 10,
        width: '446px'
    },
    hideElement: {
        display: 'none'
    }
});

class ResetPasswordView extends Component {
    state = {
        title: "Reset Password",
        username: "",
        isPasswordExpired: false,
        oldPassword: '',
        newPassword: '',
        password: '',
        confirmPassword: '',
        newPasswordError:'',
        expNewPasswordError:'',
        passwordMsg: '',
        passwordConfirm:"",
        token: '',
        domainName: '',
        disableResetPasswordButton: true,
        displayPassword: false,
        confirmPasswordError: false,
        invalidLink: false
    }

    componentDidMount() {
        let host = document.location.hostname;
        this.setState({
            domainName: host
        })

        if (document.location.hostname.includes("rss")) {
            this.changeFavicon("/reitz.png")
        }
        if (((document.location.hostname).toLowerCase().includes("japan")) || ((document.location.hostname).toLowerCase().includes("omniedge-r.thk"))) {
            this.changeFavicon("/fav.ico")

        }
        if (this.props && this.props.location && this.props.location.state && this.props.location.state.passwordExpired) {
            this.setState({ isPasswordExpired: this.props.location.state.passwordExpired, username: this.props.location.state.email })
        }

        let params = queryString.parse(this.props.location.search)
        if (params !== undefined && params.token !== undefined && params.token !== '') {
            this.setState({
                token: params.token,
            })

            this.fetchResetPasswordDetails(params.token);
        }
        if (params !== undefined && params.generate !== undefined && params.generate !== '') {
            this.setState({ title: params.generate === "true" ? "Generate Password" : "Reset Password" })
        }
    }
    changeFavicon = link => {
        let $favicon = document.querySelector('link[rel="shortcut icon"]')
        if ($favicon !== null) {
            $favicon.href = link
        } else {
            $favicon = document.createElement("link")
            $favicon.rel = "icon"
            $favicon.href = link
            document.head.appendChild($favicon)
        }
    }

    fetchResetPasswordDetails = (token) => {
        const that = this;
        fetch(`${server_url}/users/getResetPasswordDetails?token=${token}`, {
            method: 'GET',
            headers:{
                "Accept-Language": localStorage.getItem("lang") || "en"
            }
        })
            .then(function (response) {
                if (response.status === 200) {
                    return response.json();
                } else if (response.status === 400) {
                    that.setState({
                        invalidLink: true
                    })

                    return;
                } else {
                    that.setState({
                        invalidLink: true
                    })

                    return;
                }
            }).then(function (data) {
                if (data) {
                    that.setState({
                        username: data.username,
                        invalidLink: false
                    })
                }
            })
            .catch(function (error) {
                console.log(error)
                that.setState({
                    invalidLink: true
                })
            });
    }

    inputChangeHandler = event => {
        if (event.target.id === "password") {
            this.setState({
                password: event.target.value
            }, () => {
                this.passwordValidation("password")
            })
        } else if (event.target.id === "confirm-password") {
            this.setState({
                confirmPassword: event.target.value
            }, () => {
                this.passwordValidation("confirm-password")
            })

        }
        if (event.target.id === "oldPassword") {
            this.setState({ oldPassword: event.target.value }, () => {
                this.passwordValidation("oldPassword")
            })
        } else if (event.target.id === "newPassword") {
            this.setState({
                newPassword: event.target.value
            }, () => {
                this.passwordValidation("newPassword")
            })
        }

    }

    passwordValidation = (id) => {
        const re = RegExp("^(?=.*[a-z])(?=.*[A-Z])(?=.*\\d)(?=.*[\\W_])[^<>]{8,}$");

        if (id === "oldPassword" || id === "newPassword") {
            if(id === "newPassword"){
            if (this.state.newPassword !== '' && this.state.newPassword !== this.state.oldPassword) {
                if (re.test(this.state.newPassword)) {
                    this.setState({
                        disableResetPasswordButton: false,
                        confirmPasswordError: false
                    })
                } else {
                    this.setState({
                        disableResetPasswordButton: true,
                        confirmPasswordError: true,
                        passwordMsg: 'Password must has at least 8 characters that include at least 1 lowercase character, 1 uppercase characters, 1 number, and 1 special character.'
                    })
                }
            } else {
                if(this.state.newPassword ===''){
                    this.setState({confirmPasswordError:false,disableResetPasswordButton: true})
                }else{
                    this.setState({
                        disableResetPasswordButton: true,
                        confirmPasswordError: true,
                        passwordMsg:'U163'
                    })
                }
            }
        }
        }
        if (id === "password" || id === "confirm-password") {
            if(id === "confirm-password"){
                if (re.test(this.state.confirmPassword)) {
                    this.setState({
                        disableResetPasswordButton: false,
                        confirmPasswordError: false
                    })
                } else {
                    this.setState({
                        disableResetPasswordButton: true,
                        confirmPasswordError: true,
                        passwordConfirm: 'Password must has at least 8 characters that include at least 1 lowercase character, 1 uppercase characters, 1 number, and 1 special character.'
                    })
                }
                if(this.state.confirmPassword ===''){
                    this.setState({confirmPasswordError:false,disableResetPasswordButton: true})
                }
            }else{
                if (re.test(this.state.password)) {
                    this.setState({
                        disableResetPasswordButton: false,
                        newPasswordError: false
                    })
                } else {
                    this.setState({
                        disableResetPasswordButton: true,
                        newPasswordError: true,
                        passwordMsg: 'Password must has at least 8 characters that include at least 1 lowercase character, 1 uppercase characters, 1 number, and 1 special character.'
                    })
                }
                if(this.state.password ===''){
                    this.setState({newPasswordError:false,disableResetPasswordButton: true})
                }
            }
            if ((this.state.confirmPassword !== this.state.password) && (re.test(this.state.password) && this.state.confirmPassword !== null)) {
                    this.setState({
                        disableResetPasswordButton: true,
                        confirmPasswordError: true,
                        passwordConfirm: 'Password and Confirm Password does not match'
                    })
                }
                if ((this.state.confirmPassword === this.state.password) && (re.test(this.state.password) && re.test(this.state.confirmPassword))) {
                    this.setState({
                        disableResetPasswordButton: false,
                        confirmPasswordError: false,
                        passwordConfirm: ''
                    })
                }
        }
    }
    handleResetPassword = () => {
        let updatePasswordRequest;
        if (this.state.isPasswordExpired) {
            updatePasswordRequest = {
                username: this.state.username,
                password: this.state.newPassword,
                confirmPassword: this.state.newPassword,
                oldPassword: this.state.oldPassword,
            }
        } else {

            updatePasswordRequest = {
                username: this.state.username,
                password: this.state.password,
                confirmPassword: this.state.confirmPassword,
                token: this.state.token
            }
        }
        this.props.updatePassword(updatePasswordRequest, this.state.isPasswordExpired);
    }

    // show password
    displayPassword = () => {
        this.setState({ displayPassword: true })
    }

    // hide password
    hidePassword = () => {
        this.setState({ displayPassword: false })
    }

    render() {
        const { t } = this.props;
        return (
            <>
                <AppBar position="static" className={this.state.domainName.includes("rss") ? this.props.classes.header1 : this.props.classes.header}>
                    <img alt="IU logo" src={`${server_url}/getLogo?domainName=${this.state.domainName}`} className={this.state.domainName.includes("rss") ? this.props.classes.logoClass1 : this.props.classes.logoClass} />
                </AppBar>
                <div style={{ float: "right", marginRight: "25px" }}>
                    <LanguageDropdown />
                </div>
                <Grid container classes={{ container: this.props.classes.gridContainer }}>
                    <Grid item classes={{ item: this.props.classes.gridItem }}>
                        <main className={this.props.classes.main}>
                            {/* <img alt="IU logo" src={`${server_url}/getLogo`} className={this.props.classes.logoClass} /> */}
                            <Paper className={this.props.classes.paper}>
                                <Card className={this.props.classes.root} variant="outlined">
                                    <CardHeader title={t(this.state.title)}
                                        className={this.props.classes.loginCardHeader}
                                        classes={{
                                            title: this.props.classes.title,
                                        }}
                                    >
                                    </CardHeader>
                                    <CardContent>
                                        <div className={this.state.invalidLink ? this.props.classes.loginButton : this.props.classes.hideElement} fullWidth>
                                            <p fullWidth>{t("Your reset password link is invalid.")}</p>
                                            <Link style={{ cursor: 'pointer', textDecoration: 'none', color: "#1b7bf3" }} to="/forgetPassword">{t("Try again")}</Link>
                                        </div>
                                        <form className={this.state.invalidLink ? this.props.classes.hideElement : this.props.classes.form} onKeyPress={this.handleKeyPress} id="loginForm" autoComplete="off">
                                            {!this.props.isPasswordUpdated.updatePassword.value ?
                                                <>
                                                    <FormControl required fullWidth>
                                                        <TextField
                                                            id="email"
                                                            variant="standard"
                                                            label={t("Username")}
                                                            type="email"
                                                            value={this.state.username}
                                                            classes={{ focused: this.props.classes.focused }}
                                                            disabled={true}
                                                            InputProps={{
                                                                classes: {
                                                                    root: this.props.classes.outlinedInput,
                                                                    notchedOutline: this.props.classes.notchedOutline
                                                                },
                                                                endAdornment: (
                                                                    <InputAdornment position="end">
                                                                        <AccountCircle className={this.props.classes.accountIcon} />
                                                                    </InputAdornment>
                                                                ),
                                                            }}
                                                            InputLabelProps={{
                                                                FormLabelClasses: {
                                                                    root: this.props.classes.labelRoot,
                                                                    focused: this.props.classes.focusedInput
                                                                }
                                                            }}
                                                        />
                                                    </FormControl>
                                                    {this.state.isPasswordExpired ?
                                                        <>
                                                            <FormControl margin="normal" required fullWidth>
                                                                <TextField
                                                                    id="oldPassword"
                                                                    variant="standard"
                                                                    required={true}
                                                                    autoComplete="new-password"
                                                                    type={'password'}
                                                                    label={t("Old Password")}
                                                                    value={this.state.oldPassword}
                                                                    onChange={this.inputChangeHandler}
                                                                    InputProps={{
                                                                        classes: {
                                                                            root: this.props.classes.outlinedInput,
                                                                            notchedOutline: this.props.classes.notchedOutline
                                                                        }
                                                                    }}
                                                                    InputLabelProps={{
                                                                        FormLabelClasses: {
                                                                            root: this.props.classes.labelRoot,
                                                                            focused: this.props.classes.focusedInput
                                                                        }
                                                                    }}
                                                                />
                                                            </FormControl>
                                                            <FormControl margin="normal" required fullWidth>
                                                                    <TextField
                                                                        id="newPassword"
                                                                        variant="standard"
                                                                        required={true}
                                                                        autoComplete="new-password"
                                                                        type={'password'}
                                                                        label={t("New Password")}
                                                                        value={this.state.newPassword}
                                                                        error={this.state.confirmPasswordError}
                                                                        onChange={this.inputChangeHandler}
                                                                        InputProps={{
                                                                            classes: {
                                                                                root: this.props.classes.outlinedInput,
                                                                                notchedOutline: this.props.classes.notchedOutline
                                                                            }
                                                                        }}
                                                                        InputLabelProps={{
                                                                            FormLabelClasses: {
                                                                                root: this.props.classes.labelRoot,
                                                                                focused: this.props.classes.focusedInput
                                                                            }
                                                                        }}
                                                                    />
                                                                    {this.state.confirmPasswordError && <div style={{color:'red',fontSize:'11px'}}>{t(this.state.passwordMsg)}</div>}
                                                            </FormControl>
                                                        </> : <>

                                                            <FormControl margin="normal" required fullWidth>
                                                                    <TextField
                                                                        id="password"
                                                                        variant="standard"
                                                                        required={true}
                                                                        autoComplete="new-password"
                                                                        type={'password'}
                                                                        label={t("Password")}
                                                                        value={this.state.password}
                                                                        error={this.state.newPasswordError}
                                                                        onChange={this.inputChangeHandler}
                                                                        InputProps={{
                                                                            classes: {
                                                                                root: this.props.classes.outlinedInput,
                                                                                notchedOutline: this.props.classes.notchedOutline
                                                                            }
                                                                        }}
                                                                        InputLabelProps={{
                                                                            FormLabelClasses: {
                                                                                root: this.props.classes.labelRoot,
                                                                                focused: this.props.classes.focusedInput
                                                                            }
                                                                        }}
                                                                    />
                                                                     {this.state.newPasswordError && <div style={{color:'red',fontSize:'11px'}}>{t(this.state.passwordMsg)}</div>}
                                                            </FormControl>
                                                            <FormControl required fullWidth>
                                                                    <TextField
                                                                        id="confirm-password"
                                                                        variant="standard"
                                                                        required={true}
                                                                        autoComplete="new-password"
                                                                        type={this.state.displayPassword ? 'text' : 'password'}
                                                                        label={t("Re-enter Password")}
                                                                        value={this.state.confirmPassword}
                                                                        onChange={this.inputChangeHandler}
                                                                        error={this.state.confirmPasswordError}
                                                                        InputProps={{
                                                                            classes: {
                                                                                root: this.props.classes.outlinedInput,
                                                                                notchedOutline: this.props.classes.notchedOutline
                                                                            },
                                                                            endAdornment: (
                                                                                <InputAdornment position="end">
                                                                                    {!this.state.password ?
                                                                                        <VisibilityOff
                                                                                            className={this.props.classes.visibilityOffIcon}
                                                                                        />
                                                                                        :
                                                                                        <Visibility
                                                                                            className={this.props.classes.visibilityIcon}
                                                                                            onMouseDown={this.displayPassword}
                                                                                            onMouseUp={this.hidePassword} />}
                                                                                </InputAdornment>
                                                                            ),
                                                                        }}
                                                                        InputLabelProps={{
                                                                            FormLabelClasses: {
                                                                                root: this.props.classes.labelRoot,
                                                                                focused: this.props.classes.focusedInput
                                                                            }
                                                                        }}
                                                                    />
                                                                {this.state.confirmPasswordError && <div style={{color:'red',fontSize:'11px'}}>{t(this.state.passwordConfirm)}</div>}
                                                            </FormControl>
                                                        </>}
                                                    <Button
                                                        fullWidth
                                                        variant="contained"
                                                        color="primary"
                                                        className={this.props.classes.submit}
                                                        classes={{ disabled: this.props.classes.disabledButton }}
                                                        onClick={this.handleResetPassword}
                                                        disabled={this.state.disableResetPasswordButton}>
                                                        {t("Update Password")}
                                                    </Button>
                                                </> :
                                                <div className={this.props.classes.loginButton} fullWidth>
                                                    <p fullWidth>{t("Your password is updated succesfully")}</p>
                                                    <div style={{ cursor: 'pointer', textDecoration: 'none', color: "#1b7bf3" }} onClick={() => this.props.history.replace("/login")}>{t("Click here to go to login page")}</div>
                                                </div>}
                                            {/* <div style={{ color: 'black', textAlign: 'right', marginTop: '10px', fontSize: '7px' }}>{t("Version")} : {packageJson.appversion}</div> */}
                                            {/* <div style={{ color: 'white',textAlign: 'center',marginTop: '8px'}}>Build Version : {packageJson.version}</div> */}
                                        </form>
                                    </CardContent>
                                </Card>
                            </Paper>
                        </main>
                    </Grid>
                </Grid>
            </>)
    }
}

const mapStateToProps = (state) => {
    return {
        isPasswordUpdated: state.updatePasswordReducer,

    }
}

const mapDispatchToProps = dispatch => {
    return ({
        updatePassword: (updatePasswordRequest, flag) => (dispatch(actionCreators.updatePassword(updatePasswordRequest, flag)))
    })
}

export default withTranslation()(connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(ResetPasswordView)));
