import React, { Component, Fragment } from 'react';
import Picky from "react-picky";
import "react-picky/dist/picky.css";
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import { Checkbox, IconButton } from '@material-ui/core';
import { Close } from '@material-ui/icons';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import ArrowBack from '@material-ui/icons/ArrowBack';
import moment from "moment";
import { config } from '../../../configuration';
import { connect } from 'react-redux';
import * as actionCreators from '../../../actions/actionCreators';
import { withTranslation } from "react-i18next";
import { getOidcAccessToken } from '../../../utils/oidcStorageUtils';

let currentLanguage = localStorage.getItem("lang");
if (currentLanguage === "null" || currentLanguage === null) {
    currentLanguage = "en";
}
let timerCall, leftfFlag = false, rightFlag = false, odrFftIco = false, fftC = 0;
const clonedeep = require('lodash.clonedeep')
Highcharts.setOptions({
    global: {
        useUTC: false
    },
    lang:{
        resetZoom: "Reset zoom"
    }
});



const units = [
    { id: 1, unit: "CPM" },
    { id: 2, unit: "Hz" }
];

/*
const fftFeatures = [
    { "value": "accelerationTimeWaveformX", "viewValue": "Acceleration Time Waveform X", "unit": "m/s²" },
    { "value": "accelerationTimeWaveformY", "viewValue": "Acceleration Time Waveform Y", "unit": "m/s²" },
    { "value": "accelerationTimeWaveformZ", "viewValue": "Acceleration Time Waveform Z", "unit": "m/s²" },
    { "value": "fftVelocityX", "viewValue": "FFT Velocity in X", "unit": "mm/s" },
    { "value": "fftVelocityY", "viewValue": "FFT Velocity in Y", "unit": "mm/s" },
    { "value": "fftVelocityZ", "viewValue": "FFT Velocity in Z", "unit": "mm/s" },
    { "value": "fftAccelerationX", "viewValue": "FFT Acceleration in X", "unit": "m/s²" },
    { "value": "fftAccelerationY", "viewValue": "FFT Acceleration in Y", "unit": "m/s²" },
    { "value": "fftAccelerationZ", "viewValue": "FFT Acceleration in Z", "unit": "m/s²" }
];
*/
const ranges = [
    { "value": "10", "viewValue": "10" },
    { "value": "25", "viewValue": "25" },
    { "value": "50", "viewValue": "50" },
    { "value": "100", "viewValue": "100" }
];

export class RealTimePanel extends Component {
    constructor(props) {
        super(props);
        this.chartOptions = {

            "chart": {
                "type": "line",
                "zoomType": 'x',
                "animation": false,
                "resetZoomButton": {
                    "position": {
                        // align: 'right', // by default
                        // verticalAlign: 'top', // by default
                        x: -20,
                        y: 40
                    }
                }
            },
            "title": {
                "text": ""
            },
            "navigation": {
                "buttonOptions": {
                    "enabled": true
                }
            },
            "subtitle": {
                "text": ""
            },
            "xAxis": {
                "title": {
                    "text": "",
                    "style":{
                    "fontSize":"13px",
                    "fontWeight":"bold",
                    "color":"black",
                    }
                },
                "labels": {
                    "style":{
                    "fontSize":"13px",
                    "fontWeight":"bold",
                    "color":"black",
                    }
                }
            },
            "yAxis": {
                "title": {
                    "text": "",
                    "style":{
                    "fontSize":"13px",
                    "fontWeight":"bold",
                    "color":"black",
                    }
                    
                },
                "labels": {
                    "style":{
                    "fontSize":"13px",
                    "fontWeight":"bold",
                    "color":"black",
                    }
                },		
                gridLineColor: 'transparent',
                showLastLabel: true,
                showFirstLabel: true,
                startOnTick: false,
                endOnTick: false,
            },
            tooltip: {
                shared: true,
                useHTML: true,
                headerFormat: '<small  style="font-size:12px">{point.key:%A,%b %e,%Y}</small><table>',
                pointFormat: '<tr><td style="font-size:12px">{series.name}: </td>' +
                    '<td style="text-align: right;font-size:12px"><b>{point.y} </b></td></tr>',
                footerFormat: '</table>',
                valueDecimals: 2
            },
            "plotOptions": {
                "series": {
                    "lineWidth": 0.75,
                    "color": '#FFFFFF',
                    "states": {
                        "hover": {
                            "enabled": false,
                            "lineWidth": 0.75
                        }
                    },
                },
        
                "spline": {
                    "marker": {
                        "enabled": true,
                        "radius": 4,
                        "lineColor": "#666666",
                        "lineWidth": 0.75
                    }
                }
            },
            navigation: {
                buttonOptions: {
                    verticalAlign: 'top',
                    y: 10,
                    x: -20
                },
                menuStyle: {
                    background: 'white',
                    border: '1px solid #ccc',
                    padding: '8px'
                },
                menuItemStyle: {
                    padding: '4px',
                    margin: '0',
                    display: 'block',
                    whiteSpace: 'nowrap'
                }
            },
            "credits": {
                "enabled": false
            },
            "series": [],
        
            exporting: {
                menuItemDefinitions: {
                    // Custom definition
                    viewFullscreen: {
                        text: this.props.t('View in full screen')
                    },
                    printChart: {
                        text: this.props.t('Print chart')
                    },
                    downloadPNG: {
                        text: this.props.t('Download PNG image')
                    },
                    downloadJPEG: {
                        text: this.props.t('Download JPEG image')
                    },
                    downloadPDF: {
                        text: this.props.t('Download PDF document')
                    },
                    downloadSVG: {
                        text: this.props.t('Download SVG vector image')
                    },
                },
                buttons: {
                    contextButton: {
                        menuItems: ["viewFullscreen", "printChart", "separator", "downloadPNG", "downloadJPEG", "downloadPDF", "downloadSVG"]
                    }
                }
            },
        
        
            noData: {
                style: {
                    fontWeight: 'bold',
                    fontSize: '12px',
                    color: 'red',
                }
            }
        }
        this.state = {
            backArr: true,
            frontArr: true,
            isNoDataFlag: false,
            timeSeriesData: [],
            data: [],
            range: { "value": "50", "viewValue": "50" },
            feature: [],
            retry: null,
            isRetry: false,
            loaderClose: false,
            selectedFeature: [],
            forntArrowFlag: true,
            rightArrow: false,
            leftArrow: false,
            fftHighlightedData: [],
            addFFTData: [],
            odrfftIconShow: false,
            options: {
                "chart": {
                    "type": "spline",
                    "zoomType": 'x',
                    "resetZoomButton": {
                        "position": {
                            // align: 'right', // by default
                            // verticalAlign: 'top', // by default
                            x: 0,
                            y: 30
                        }
                    }
                },
                "title": {
                    "text": ""
                },
                "subtitle": {
                }, "credits": {
                    "enabled": false
                },
                tooltip: {
                    shared: true,
                    useHTML: true,
                    headerFormat: '<small style="font-size:12px">{point.key:.3f}</small><table>',
                    pointFormat: '<tr><td style="color: {series.color} font-size:12px">{series.name}: </td>' +
                        '<td style="text-align: right;font-size:12px"><b>{point.y} </b></td></tr>',
                    footerFormat: '</table>',
                    valueDecimals: 2
                },
                "lang": {
                    // "noData": this.props.t("No data received, please check the device's network connectivity. (Note- the min. requirement to get data reliably is 'upload speed: 250 Kbps & WiFi signal strength: -60 dBm' )")
                    "noData": this.props.t("No data received, please check the device's network connectivity. (Note- the min. requirement to get data reliably is 'upload speed 250 Kbps & WiFi signal strength -60 dBm' )")
                },
                noData: {
                    style: {
                        color: 'black',
                        //textOverflow: 'ellipsis',
                        whiteSpace: 'wrap',
                        width: 350,
                        color: 'red'
                    }
                }
            },
            lastOpen: false,
            featureOpen: false,
            fftFeature: false,
            showWaveFeature: false,
            showSpecFeature: false,
            isShowAnnotation: false,
            fftAnnotationEnable: true,
            rawDataStatistics: {},
            trendlines: [],
            activeItem: { 1: true },
            unit: 'CPM',
            ARMS: 0,
            VRMS: 0,
            RPM: "",
            RMS: "",
            PKplus: "",
            PKminus: "",
            crest: "",
            fftData: {},
            acceFeature: false,
            fftVelFeature: false,
            fftAccefeature: false,
            isFeatureSelected: false,
            time: '',
            fftTime: '',
            annotable: false,
            fftFlag: true,
            sendCommand: true
        };

        this.chart = React.createRef();
        this.lastRange = React.createRef()
    }

    componentDidMount() {
        let selectedOrg = this.props.props.userData && this.props.props.userData.selectedOrganization;
        this.unitStoredinCache()
        if (selectedOrg == undefined) {
            let rootOrganization = this.props.props.userData.scopeSelector.subOrganizations.find((organization) => (
                organization.root
            ))
            selectedOrg = rootOrganization.id;
        }
        let odrFftFlag = this.props.props.userData && this.props.props.userData.scopeSelector.subOrganizations.find(ele => ele.id === selectedOrg);
        if (odrFftFlag !== undefined) {
            this.setState({
                fftFlag: odrFftFlag.fftWaveformEnable,
                isShowAnnotation: odrFftFlag.fftAnnotationEnable,
                fftAnnotationEnable: odrFftFlag.fftAnnotationEnable
            })
        }
        this.chartRef = React.createRef();
        const container = this.chart.current.container.current;
        container.style.height = "100%";
        container.style.width = "100%";
        this.chart.current.chart.reflow();
        let initialFeature = this.props.featureList.find(ele => ele.value === '0004');
        if (initialFeature) {
            let realTimeFeatureList = this.state.fftFlag ? [...this.props.featureList, ...this.props.fftFeatureList] : this.props.featureList;
            realTimeFeatureList = realTimeFeatureList.filter(function (e) { return e.value !== 'healthScore' });
            this.setState({
                featureList: realTimeFeatureList,
                feature: initialFeature ? initialFeature : this.props.featureList[0],
                selectedFeature: initialFeature ? initialFeature : this.props.featureList[0]
            })
        }

        document.body.addEventListener('click', function (evt) {
            if ((evt.target.parentNode.className === 'iconClass leftIconFft') || (evt.target.parentNode.className === 'leftIconFft') || (evt.target.parentNode && evt.target.parentNode.className && evt.target.parentNode.classList.contains("leftIconFft"))) {
                leftfFlag = true
            }
            if ((evt.target.parentNode.className === 'iconClass rightIconFft') || (evt.target.parentNode.className === 'rightIconFft') || (evt.target.parentNode && evt.target.parentNode.className && evt.target.parentNode.classList.contains("rightIconFft"))) {
                rightFlag = true
            }
            if (evt.target.className === "fft_odrCss" || (evt.target.parentNode.className === "vis-item vis-box fft odrCss vis-selected vis-readonly")) {
                odrFftIco = true
            }
            if (evt.target.parentNode.className === "vis-item-content" && (evt.target.className !== "fft_odrCss")) {
                odrFftIco = false;
                fftC = 0;
            }
        }, false);

        Highcharts.setOptions({
            lang:{
                resetZoom: this.props.t("Reset zoom")
            }
        });
        
    }

    componentDidUpdate(prevProps, prevState) {
        const { timeSeriesData, options, feature, range, fftFeature, fftData, selectedFeature, isShowAnnotation, unit } = this.state;
        if (leftfFlag) {
            leftfFlag = false;
            if ((this.state.fftFeature || this.state.acceFeature || this.state.fftVelFeature || this.state.fftAccefeature || this.state.showWaveFeature || this.state.showSpecFeature)) {
                this.setState({ backArr: true, frontArr: false, forntArrowFlag: true, rightArrow: false, leftArrow: true, odrfftIconShow: false })
            }
        }
        if (rightFlag) {
            rightFlag = false;
            if ((this.state.fftFeature || this.state.acceFeature || this.state.fftVelFeature || this.state.fftAccefeature || this.state.showWaveFeature || this.state.showSpecFeature)) {
                this.setState({ backArr: false, frontArr: true, rightArrow: true, leftArrow: false, odrfftIconShow: false })
            }
        }
        if (odrFftIco) {
            odrFftIco = false;
            this.setState({ odrfftIconShow: true })
        }
        if (!odrFftIco && fftC < 2) {
            fftC++;
            this.setState({ odrfftIconShow: false })
        }
        if (((prevProps.fftSelectedData.time && prevProps.fftSelectedData.time.value) !== (this.props.fftSelectedData.time && this.props.fftSelectedData.time.value))) {

            if (this.props.fftSelectedData.time.value !== '' && prevProps.fftSelectedData.time !== this.props.fftSelectedData.time) {
                let feature = this.props.fftFeatureList.filter(function (e) { return e.value === selectedFeature.value; })
                if (feature.length > 0) {
                    this.featureChange(feature[0]);
                    this.setState({ selectedFeature: feature[0] }, () => {
                        this.fetchData(this.props.fftSelectedData.time);
                    })
                } else {
                    this.featureChange(this.props.dataLoggerFlag ?this.state.featureList[3]:this.props.fftFeatureList[3]);
                    this.setState({ selectedFeature: this.props.dataLoggerFlag ? this.state.featureList[3]:this.props.fftFeatureList[3] }, () => {
                        this.fetchData(this.props.fftSelectedData.time);
                    })
                }
            }
            // if(selectedFeature && this.props.fftFeatureList.filter(function(e) { return e.value === selectedFeature.value; }).length > 0 && this.props.fftSelectedData.time.value!= ''){
            //     this.setState({fftTime: this.props.fftSelectedData.time.value,
            //     },()=>{
            //         this.fetchData(this.props.fftSelectedData.time);
            //     })
            // }else if(this.props.fftSelectedData.time.value!=='' && prevProps.fftSelectedData.time !== this.props.fftSelectedData.time){
            //     this.featureChange(this.props.fftFeatureList[3]);
            // }
        }

        if ((prevProps.fftFeatureList !== this.props.fftFeatureList) && this.props.fftFeatureList !== undefined) {
            let initialFeature = this.props.featureList.find(ele => ele.value === '0004')
            let realTimeFeatureList = this.state.fftFlag ? [...this.props.featureList, ...this.props.fftFeatureList] : this.props.featureList;
            realTimeFeatureList = realTimeFeatureList.filter(function (e) { return e.value !== 'healthScore' });
            realTimeFeatureList = realTimeFeatureList.filter(function (e) {
                // return !((e.viewValue.includes("RMS")) || (e.viewValue.includes("p-p")) || (e.viewValue.includes("Crest")) || (e.viewValue.includes("Kurtosis")) || (e.viewValue.includes("pk-pk")))
                return !((e.value.includes("RMS")) || (e.value.includes("PPACCL")) || (e.value.includes("CREST")) || (e.value.includes("KURT")) || (e.value.includes("PPSW")))
            });
            if(this.props.dataLoggerFlag){
                realTimeFeatureList = realTimeFeatureList.filter(function (e) {
                    return (!(e && e.viewValue.includes("NA")))
                });
                realTimeFeatureList = realTimeFeatureList.filter(function (e) {
                    return ((e.viewValue !=="Temperature") && (e.viewValue !=="Audio"))
                });
            }
            realTimeFeatureList.map((view, key) => {
                // TODO@!: DO NOT REMOVE - Kept for future multilingual reference
                view.viewValue = this.props.props.t(view.viewValue)
                return view

            })
            if(this.props.batteryFlag){
                initialFeature=realTimeFeatureList[0]
            }
            this.setState({
                featureList: realTimeFeatureList,
                feature: initialFeature ? initialFeature : this.props.featureList[0],
                selectedFeature: initialFeature ? initialFeature : this.props.featureList[0],
                acceFeature: false,
                fftVelFeature: false,
                fftAccefeature: false,
            }, () => {
                if(!this.props.batteryFlag){
                this.fetchData();
                }
            })
        }

        if ((prevProps.deviceId != this.props.deviceId)) {
            this.unitStoredinCache()
            this.setState({ isNoDataFlag: false }, () => {
                let chartObj = this.chart && this.chart.current && this.chart.current.chart;
                if (chartObj !== null) {
                    chartObj.hideNoData();
                    chartObj.showLoading(this.props.props.t("Fetching Data... Usually it takes 60 sec to collect the data"));
                    this.props.setFftSelectedData('');
                    if (this.props.featureList.length !== 0) {
                        let initialFeature = this.props.featureList.find(ele => ele.value === '0004')
                        let realTimeFeatureList = this.state.fftFlag ? [...this.props && this.props.featureList, ...this.props && this.props.fftFeatureList] : this.props && this.props.featureList;
                        realTimeFeatureList = realTimeFeatureList.filter(function (e) { return e.value !== 'healthScore' });
                        this.setState({
                            featureList: realTimeFeatureList,
                            selectedFeature: initialFeature ? initialFeature : realTimeFeatureList[0],
                            fftTime: ''
                        }, () => {
                            this.fetchData();
                        })
                    }
                }
                // else{
                //     this.fetchData();
                //    this.setState({ loaderClose: false })
                //                         this.props.setLoaderState({ "value": false });
                // }
            })

        }

        const { realTimeData, fingerprintRealTimeData } = this.props;
        if (prevProps.realTimeData !== realTimeData && !fftFeature && prevProps.realTimeData._time !== realTimeData._time) {
            if (options.series && options.series.length > 0 && options.series[0].data.length >= 0) {
                let data = clonedeep(options.series[0].data);
                if (range.value < data.length) {
                    data.shift();
                }
                let minimumVal = 0;
                minimumVal = Object.values(data.reduce((a, c) => {
                    if (c.length) {
                        var [first, second] = c;
                        if (second < a.s) a.s = second;
                    }
                    return a;
                }, { s: Number.MAX_VALUE }));
                let minDataValue = minimumVal[0] < 0 ? minimumVal[0] - 5 : 0;

                var result = Object.values(data.reduce((a, c) => {
                    if (c.length) {
                        var [first, second] = c;
                        if (second > a.s) a.s = second;
                    }
                    return a;
                }, { s: Number.MIN_VALUE }));
                let max = 0;
                let maxDataValue = 0;
                let plotbands = [];

                max = feature.caution + (feature.caution * (20 / 100));
                maxDataValue = result[0] + (result[0] * (20 / 100));
                plotbands = [{
                    color: "#31ccec",
                    from: minDataValue,
                    to: feature.idle,
                }, {
                    color: "#64dd17",
                    from: feature.idle,
                    to: feature.operational,
                    label: {
                        text: feature.idle,
                        verticalAlign: 'bottom',
                        align: 'left',
                        y: 0,
                        x: 10,
                        style: {
                            color: '#000000',
                            fontSize: 14,
                            fontWeight: 400
                        }
                    }
                },
                {
                    color: "#ffc107",
                    from: feature.operational,
                    to: feature.caution,
                    label: {
                        text: feature.operational,
                        verticalAlign: 'bottom',
                        align: 'left',
                        y: 0,
                        x: 80,
                        style: {
                            color: '#000000',
                            fontSize: 14,
                            fontWeight: 400
                        }
                    }
                },
                {
                    color: "#ff5722",
                    from: feature.caution,
                    to: max < maxDataValue ? maxDataValue : max,
                    label: {
                        text: feature.caution,
                        verticalAlign: 'bottom',
                        align: 'left',
                        y: 0,
                        x: 160,
                        style: {
                            color: '#000000',
                            fontSize: 14,
                            fontWeight: 400
                        }
                    }
                },
                ];
                // chartOptions1.yAxis.plotBands = [...plotbands];

                if (realTimeData[feature.value]) {
                    let time = realTimeData._time.replace(' ', 'T') + 'Z';
                    var dateMoment = moment(time).local();
                    data.push([dateMoment.valueOf(), parseFloat(realTimeData[feature.value])]);
                    let chartOptions1 = clonedeep(this.chartOptions);
                    chartOptions1.yAxis.title.text = (feature.unit).includes("m/s") ? feature.unit + " (rms)" : feature.unit;
                    chartOptions1.yAxis.min = minDataValue;
                    chartOptions1.yAxis.max = max < maxDataValue ? maxDataValue : max;
                    chartOptions1.yAxis.plotBands = [...plotbands];
                    chartOptions1.series.push({ name: feature.viewValue, data: data });
                    this.setState({
                        options: chartOptions1
                    });
                }
            } else {
                let keys = Object.keys(realTimeData);
                let obj = {};
                let time = realTimeData._time.replace(' ', 'T') + 'Z';
                keys.forEach(key => obj[key] = [{ 'Y': realTimeData[key], 'X': time }]);
                this.setState({ data: obj }, () => {
                    this.mapDatatoChart();
                })
            }
        }

        if (prevProps.fingerprintRealTimeData !== fingerprintRealTimeData && prevProps.fingerprintRealTimeData._time !== fingerprintRealTimeData._time) {
            if (options.series && options.series.length > 0 && options.series[0].data.length >= 0) {
                let data = clonedeep(options.series[0].data);
                if (range.value < data.length) {
                    data.shift();
                }

                let minimumVal = 0;
                minimumVal = Object.values(data.reduce((a, c) => {
                    if (c.length) {
                        var [first, second] = c;
                        if (second < a.s) a.s = second;
                    }
                    return a;
                }, { s: Number.MAX_VALUE }));
                let minDataValue = minimumVal[0] < 0 ? minimumVal[0] - 5 : 0;


                var result = Object.values(data.reduce((a, c) => {
                    if (c.length) {
                        var [first, second] = c;
                        if (second > a.s) a.s = second;
                    }

                    return a;
                }, { s: Number.MIN_VALUE }));
                let max = 0;
                let maxDataValue = 0;
                let plotbands = [];
                let plotOptionsSeriesColor = "white";
                if (feature.type === "VELBAND") {
                    max = feature.caution + (feature.caution * (20 / 100));
                    maxDataValue = result[0] + (result[0] * (20 / 100));
                    plotbands = [{
                        color: "#31ccec",
                        from: minDataValue,
                        to: feature.idle,
                    }, {
                        color: "#64dd17",
                        from: feature.idle,
                        to: feature.operational,
                        label: {
                            text: feature.idle,
                            verticalAlign: 'bottom',
                            align: 'left',
                            y: 0,
                            x: 10,
                            style: {
                                color: '#000000',
                                fontSize: 14,
                                fontWeight: 400
                            }
                        }
                    },
                    {
                        color: "#ffc107",
                        from: feature.operational,
                        to: feature.caution,
                        label: {
                            text: feature.operational,
                            verticalAlign: 'bottom',
                            align: 'left',
                            y: 0,
                            x: 80,
                            style: {
                                color: '#000000',
                                fontSize: 14,
                                fontWeight: 400
                            }
                        }
                    },
                    {
                        color: "#ff5722",
                        from: feature.caution,
                        to: max < maxDataValue ? maxDataValue : max,
                        label: {
                            text: feature.caution,
                            verticalAlign: 'bottom',
                            align: 'left',
                            y: 0,
                            x: 160,
                            style: {
                                color: '#000000',
                                fontSize: 14,
                                fontWeight: 400
                            }
                        }
                    },
                    ];
                } else {
                    max = result[0] + (result[0] * (50 / 100))
                    maxDataValue = result[0] + (result[0] * (20 / 100));
                    plotbands = [{
                        color: "white",
                        from: 0,
                        to: max,

                    }];
                    plotOptionsSeriesColor = "black";
                }

                // chartOptions1.yAxis.plotBands = [...plotbands];

                if (fingerprintRealTimeData[feature.value]) {
                    let time = fingerprintRealTimeData._time.replace(' ', 'T') + 'Z';
                    var dateMoment = moment(time).local();
                    data.push([dateMoment.valueOf(), parseFloat(fingerprintRealTimeData[feature.value])]);
                    let chartOptions1 = clonedeep(this.chartOptions);
                    chartOptions1.yAxis.title.text = (feature.unit).includes("m/s") ? feature.unit + " (rms)" : feature.unit;
                    chartOptions1.yAxis.min = minDataValue;
                    chartOptions1.yAxis.max = max < maxDataValue ? maxDataValue : max;
                    chartOptions1.yAxis.plotBands = [...plotbands];
                    chartOptions1.series.push({
                        name: feature.viewValue, color: plotOptionsSeriesColor,
                        data: data
                    });

                    this.setState({
                        options: chartOptions1
                    });
                }
            } else {
                // let keys = Object.keys(fingerprintRealTimeData);
                var keys = [];
                for (var k in fingerprintRealTimeData) keys.push(k);
                let obj = {};
                let time = fingerprintRealTimeData._time.replace(' ', 'T') + 'Z';
                keys.forEach(key => obj[key] = [{ 'Y': parseFloat(fingerprintRealTimeData[key]), 'X': time }]);
                this.setState({ data: obj }, () => {
                    this.mapDatatoChart();
                })
            }
        }

        if (prevState.timeSeriesData !== timeSeriesData) {
            let rawdata = {}
            timeSeriesData['basic_features'].forEach(element => {
                this.props.featureList.forEach(key => {
                    let responseRawData = JSON.parse(element.data);
                    if (responseRawData.hasOwnProperty(key.value)) {
                        if (!rawdata.hasOwnProperty(key.value)) {
                            let object = {};
                            let feature = key.value;
                            object[feature] = [];

                            let dataobject;
                            dataobject = {};
                            dataobject.X = element.time;
                            dataobject.Y = responseRawData[key.value];
                            object[feature].push(dataobject);
                            rawdata = { ...rawdata, ...object }
                        } else {
                            let dataobject;
                            dataobject = {};
                            dataobject.X = element.time;
                            dataobject.Y = responseRawData[key.value];
                            rawdata[key.value].push(dataobject);
                        }
                    }

                    // if (element.hasOwnProperty(key.value)) {
                    //     if (!rawdata.hasOwnProperty(key.value)) {
                    //         let object = {};
                    //         let feature = key.value;
                    //         object[feature] = [];

                    //         let dataobject;
                    //         dataobject = {};
                    //         dataobject.X = element._time;
                    //         dataobject.Y = element[key.value];
                    //         object[feature].push(dataobject);
                    //         rawdata = { ...rawdata, ...object }
                    //     } else {
                    //         let dataobject;
                    //         dataobject = {};
                    //         dataobject.X = element._time;
                    //         dataobject.Y = element[key.value];
                    //         rawdata[key.value].push(dataobject);
                    //     }
                    // }
                })
            })

            timeSeriesData['fingerprint_data'].forEach(element => {
                this.props.featureList.forEach(key => {
                    let responseRawData = JSON.parse(element.data);
                    if (responseRawData.hasOwnProperty(key.value)) {
                        if (!rawdata.hasOwnProperty(key.value)) {
                            let object = {};
                            let feature = key.value;
                            object[feature] = [];

                            let dataobject;
                            dataobject = {};
                            dataobject.X = element.time;
                            dataobject.Y = responseRawData[key.value];
                            object[feature].push(dataobject);
                            rawdata = { ...rawdata, ...object }
                        } else {
                            let dataobject;
                            dataobject = {};
                            dataobject.X = element.time;
                            dataobject.Y = responseRawData[key.value];
                            rawdata[key.value].push(dataobject);
                        }
                    }

                    // if (element.hasOwnProperty(key.value)) {
                    //     if (!rawdata.hasOwnProperty(key.value)) {
                    //         let object = {};
                    //         let feature = key.value;
                    //         object[feature] = [];

                    //         let dataobject;
                    //         dataobject = {};
                    //         dataobject.X = element._time;
                    //         dataobject.Y = element[key.value];
                    //         object[feature].push(dataobject);
                    //         rawdata = { ...rawdata, ...object }
                    //     } else {
                    //         let dataobject;
                    //         dataobject = {};
                    //         dataobject.X = element._time;
                    //         dataobject.Y = element[key.value];
                    //         rawdata[key.value].push(dataobject);
                    //     }
                    // }
                })
            })

            if (!isEmpty(rawdata)) {
                this.setState({
                    data: rawdata
                }, () => {
                    let chartObj = this.chart && this.chart.current && this.chart.current.chart;
                    if (chartObj !== null) {
                        chartObj.hideNoData();
                        chartObj.showLoading(this.props.props.t("Fetching Data... Usually it takes 60 sec to collect the data"));
                        this.mapDatatoChart();
                    }
                })
            } else {
                let chartObj = this.chart && this.chart.current && this.chart.current.chart;
                if (chartObj !== null) {
                    let options2 = clonedeep(this.chartOptions);
                    options2.yAxis.plotBands = [];
                    chartObj.showNoData(this.props.props.t("No data to display"));
                    options2.series.length = 0;
                    const container = this.chart && this.chart.current && this.chart.current.container.current;
                    options2.yAxis.min = 0;
                    options2.yAxis.max = '';
                    container.style.height = "100%";
                    container.style.width = "100%";
                    this.chart.current.chart.reflow();
                    this.setState({
                        options: options2
                    }, () => {
                        chartObj.hideLoading();
                        chartObj.zoomOut();
                        chartObj.showNoData(this.props.props.t("No data to display"));
                        const container = this.chart.current.container.current;

                        container.style.height = "100%";
                        container.style.width = "100%";
                        this.chart.current.chart.reflow();
                    })
                }
            }
        }
        if (prevState.fftData !== fftData && fftFeature) {
            if (feature.value === 'accelerationTimeWaveformX' || feature.value === 'accelerationTimeWaveformY' || feature.value === 'accelerationTimeWaveformZ') {
                this.loadFFTData(feature);
            } else if (feature.value === 'fftVelocityX' || feature.value === 'fftVelocityY' || feature.value === 'fftVelocityZ') {
                this.loadFFTVelocityData(feature);
            } else if (feature.value === 'fftAccelerationX' || feature.value === 'fftAccelerationY' || feature.value === 'fftAccelerationZ') {
                this.loadFFTAccelerationData(feature);
            }
            else if (feature.value === "shockwaveWaveformX" || feature.value === "shockwaveWaveformY" || feature.value === "shockwaveWaveformZ") {
                this.loadShockWaveData(feature);
            }
            else if (feature.value === "shockwaveAutocorrelationX" || feature.value === "shockwaveAutocorrelationY" || feature.value === "shockwaveAutocorrelationZ") {
                this.loadShockWaveAutocorrelationData(feature);
            }
            else if (feature.value === "shockwaveSpectrumX" || feature.value === "shockwaveSpectrumY" || feature.value === "shockwaveSpectrumZ") {
                this.loadShockWaveSpectrumData(feature);
            }
        }

        if (prevState.isShowAnnotation != isShowAnnotation) {
            if (feature.value === 'fftVelocityX' || feature.value === 'fftVelocityY' || feature.value === 'fftVelocityZ') {
                if (fftData && fftData.velocityFFTAnnotations && fftData.velocityFFTAnnotations.quality !== 'UnAnnotable') {
                    if (isShowAnnotation) {
                        this.configurePlotLines(fftData, feature.value,prevState.unit);
                    }

                    let fftChartOptions = clonedeep(this.state.options);
                    fftChartOptions.xAxis.plotLines = isShowAnnotation ? this.state.trendlines : [];
                    fftChartOptions.xAxis.plotLines.forEach((item) => {
                        if (unit !== "Hz") {
                            item.value = item.value * 60;
                            item.label.text = item.label.label + " : " + item.value.toFixed(3) + " " + unit;
                        }
                    })
                    fftChartOptions.tooltip = {
                        shared: true,
                        useHTML: true,
                        headerFormat: '<small style="font-size:12px">{point.key:.3f}</small><table>',
                        pointFormat: '<tr><td style="color: {series.color} ; font-size:12px">{series.name}: </td>' +
                            '<td style="text-align: right;font-size:12px"><b>{point.y} </b></td></tr>',
                        footerFormat: '</table>',
                        valueDecimals: 2
                    };
                    this.setState({
                        options: fftChartOptions,
                        trendlines: [],
                        annotable: true
                    }, () => {
                        let chartObj = this.chart && this.chart.current && this.chart.current.chart;
                        if (chartObj !== null) {
                            chartObj.hideLoading();
                            chartObj.zoomOut();
                        }
                    });
                } else {
                    this.setState({ annotable: false })
                }
            } else if (feature.value === 'fftAccelerationX' || feature.value === 'fftAccelerationY' || feature.value === 'fftAccelerationZ') {
                if (fftData && fftData.accelerationFFTAnnotations && fftData.accelerationFFTAnnotations.quality && fftData.accelerationFFTAnnotations.quality !== 'UnAnnotable') {
                    if (isShowAnnotation) {
                        this.configurePlotLines(fftData, feature.value,prevState.unit);
                    }

                    let fftChartOptions = clonedeep(this.state.options);
                    fftChartOptions.xAxis.plotLines = isShowAnnotation ? this.state.trendlines : [];
                    fftChartOptions.xAxis.plotLines.forEach((item) => {
                        if (unit !== "Hz") {
                            item.value = item.value * 60;
                            item.label.text = item.label.label + " : " + item.value + " " + unit;
                        }
                    })
                    fftChartOptions.tooltip = {
                        shared: true,
                        useHTML: true,
                        headerFormat: '<small style="font-size:12px">{point.key:.3f}</small><table>',
                        pointFormat: '<tr><td style="color: {series.color};font-size:12px">{series.name}: </td>' +
                            '<td style="text-align: right;font-size:12px"><b>{point.y} </b></td></tr>',
                        footerFormat: '</table>',
                        valueDecimals: 2
                    };
                    this.setState({
                        options: fftChartOptions,
                        trendlines: []
                    }, () => {
                        let chartObj = this.chart && this.chart.current && this.chart.current.chart;
                        if (chartObj !== null) {
                            chartObj.hideLoading();
                            chartObj.zoomOut();
                        }
                    });
                }
            }
        }
        let fftColr = document.getElementsByClassName(`vis-item vis-box fft ${moment(this.state.time).utc().local().format("YYYY-MM-DD HH:mm:ss")} vis-readonly`);
        if (((fftColr && fftColr.length !== 0) && (this.state.time !== prevState.time))) {
            let x = document.getElementsByClassName("mystyle");
            // If it exists, remove it.
            if (x.length > 0) {
                let size = x.length;
                for (let i = 0; i < size; i++) {
                    x[i].classList.remove("mystyle");
                }
            }
            fftColr[0].classList.add("mystyle");
            let odrColor = document.getElementsByClassName(`vis-item vis-box fft odrCss ${moment(this.state.time).utc().local().format("YYYY-MM-DD HH:mm:ss")} vis-readonly`);
            if (((odrColor && odrColor.length !== 0))) {
                this.setState({ odrfftIconShow: true })
            }
        }


        if ((fftColr && fftColr.length !== 0)) {
            fftColr[0].classList.add("mystyle");
        }
        if (prevProps.fftData.FFTData !== this.props.fftData.FFTData) {
            let data = this.props.fftData && this.props.fftData.FFTData.sort((a, b) => (new Date(a.time) - new Date(b.time)))
            let index = data.findIndex(x => x.time === this.state.time);
            if ((index !== 0 && index !== -1) && this.state.rightArrow && this.props.fftData.FFTData.length !== 0) {
                this.setState({ frontArr: true, backArr: true })
            }

            if ((index !== 0 && index !== -1) && this.state.leftArrow && this.props.fftData.FFTData.length !== 0) {
                this.setState({ frontArr: true, backArr: true })
            }
            if (this.state.rightArrow && this.props.fftData.FFTData.length === 0) {
                this.setState({ frontArr: false })
            }
            if (this.state.rightArrow && this.props.fftData.FFTData.length !== 0) {
                this.setState({ frontArr: true })
            }
            if (this.state.leftArrow && this.props.fftData.FFTData.length === 0) {
                this.setState({ backArr: false })
            }
            if (this.state.leftArrow && this.props.fftData.FFTData.length !== 0) {
                this.setState({ backArr: true })
            }
            if (index === 0 && this.props.fftData.FFTData.length !== 0) {
                this.setState({ backArr: false })
            }
            if (index === this.props.fftData.FFTData.length && this.props.fftData.FFTData.length !== 0) {
                this.setState({ frontArr: false })
            }
            if (index === this.props.fftData.FFTData.length && index === 0 && this.props.fftData.FFTData.length !== 0) {
                this.setState({ frontArr: false, backArr: false })
            }
        }
        if (this.state.addFFTData.length !== 0) {
            let data = this.props.fftData && this.props.fftData.FFTData.sort((a, b) => (new Date(a.time) - new Date(b.time)))
            let index = data.findIndex(x => x.time === this.state.addFFTData[0].time);
            if (index !== -1) {
                this.setState({ addFFTData: [] })
            }
        }
        Highcharts.setOptions({
            lang:{
                resetZoom: this.props.t("Reset zoom")
            }
        });
    }


    mapDatatoChart() {
        const { data, feature } = this.state;
        if (feature && feature.value) {
            let chartData = data[feature.value];
            let chartOptions1 = clonedeep(this.chartOptions);
            if (chartData) {
                let plotbands;
                chartOptions1.series = [];
                let max;
                let maxim, minimum;
                let maxDataValue, minDataValue;
                let plotOptionsSeriesColor = 'white';
                if (feature.type === "VELBAND") {
                    max = feature.caution + (feature.caution * (20 / 100));
                    maxim = chartData.reduce(function (prev, current) {
                        return (prev.Y > current.Y) ? prev : current
                    });
                    minimum = chartData.reduce(function (prev, current) {
                        return (prev.Y < current.Y) ? prev : current
                    });
                    minDataValue = minimum.Y < 0 ? (minimum.Y - 5) : 0;
                    maxDataValue = maxim.Y + (maxim.Y * (20 / 100));
                    // chartOptions1.series[0].showInLegend = false;
                    plotbands = [{
                        color: "#31ccec",
                        from: minDataValue,
                        to: feature.idle,
                    }, {
                        color: "#64dd17",
                        from: feature.idle,
                        to: feature.operational,
                        label: {
                            text: feature.idle,
                            verticalAlign: 'bottom',
                            align: 'left',
                            y: 0,
                            x: 10,
                            style: {
                                color: '#000000',
                                fontSize: 14,
                                fontWeight: 400
                            }
                        }
                    },
                    {
                        color: "#ffc107",
                        from: feature.operational,
                        to: feature.caution,
                        label: {
                            text: feature.operational,
                            verticalAlign: 'bottom',
                            align: 'left',
                            y: 0,
                            x: 80,
                            style: {
                                color: '#000000',
                                fontSize: 14,
                                fontWeight: 400
                            }
                        }
                    },
                    {
                        color: "#ff5722",
                        from: feature.caution,
                        to: max < maxDataValue ? maxDataValue : max,
                        label: {
                            text: feature.caution,
                            verticalAlign: 'bottom',
                            align: 'left',
                            y: 0,
                            x: 160,
                            style: {
                                color: '#000000',
                                fontSize: 14,
                                fontWeight: 400
                            }
                        }
                    },
                    ];
                } else {
                    maxim = chartData.reduce(function (prev, current) {
                        return (prev.Y > current.Y) ? prev : current
                    });
                    max = maxim.Y + (maxim.Y * (20 / 100));
                    maxDataValue = maxim.Y + (maxim.Y * (20 / 100));
                    minimum = chartData.reduce(function (prev, current) {
                        return (prev.Y < current.Y) ? prev : current
                    });
                    minDataValue = minimum.Y < 0 ? minimum.Y - 5 : 0;
                    plotbands = [{
                        color: "white",
                        from: minDataValue,
                        to: max,

                    }];
                    plotOptionsSeriesColor = 'black';
                }

                chartOptions1.yAxis.plotBands = [...plotbands];
                chartOptions1.xAxis.type = 'datetime';
                chartOptions1.xAxis.plotLines = [];
                chartOptions1.yAxis.title.text = (feature.unit).includes("m/s") ? feature.unit + " (rms)" : feature.unit;
                chartOptions1.yAxis.min = minDataValue;
                chartOptions1.xAxis.title.text = '';
                chartOptions1.yAxis.max = max < maxDataValue ? maxDataValue : max;
                let seriesData = [];
                chartOptions1.tooltip = {
                    shared: true,
                    useHTML: true,
                    headerFormat: '<small style="fontSize:12px">{point.key:%A,%b %e,%Y}</small><table>',
                    pointFormat: '<tr><td style="fontSize:12px">{series.name}: </td>' +
                        '<td style="text-align: right;fontSize:12px"><b>{point.y} </b></td></tr>',
                    footerFormat: '</table>',
                    valueDecimals: 2
                };

                chartData.forEach(element => {
                    var dateMoment = moment(element.X);
                    seriesData.push([dateMoment.valueOf(), element.Y]);
                });

                chartOptions1.series.push({
                    color: plotOptionsSeriesColor,
                    showInLegend: false,
                    name: feature.viewValue, data: seriesData
                })
            }

            this.setState({
                options: chartOptions1,
                acceFeature: false,
                fftVelFeature: false,
                fftAccefeature: false
            }, () => {
                let chartObj = this.chart && this.chart.current && this.chart.current.chart;
                if (chartObj !== null) {
                    chartObj.hideLoading();
                    chartObj.zoomOut();
                }
            });
        }
    }

    featureChange = (value) => {
        // let parnetEle = document.getElementById('selector-Panel');
        if ((value.value.indexOf('acc') != -1) || (value.value.indexOf('fft') != -1)) {
            // parnetEle.lastElementChild.firstElementChild.setAttribute('disabled', true)
            this.setState({ isFeatureSelected: true })
        }
        else {
            // parnetEle.lastElementChild.firstElementChild.removeAttribute("disabled");
            this.setState({ isFeatureSelected: false })
        }

        this.setState({
            feature:value,
            selectedFeature: value,
            featureOpen: false
        }, () => {
            if ((this.props.fftSelectedData.time && this.props.fftSelectedData.time.value !== "") || (this.props.fftSelectedData.time && this.props.fftSelectedData.time.value === "" && this.state.time)) {
                this.fetchData(new Date((this.props.fftSelectedData.time && this.props.fftSelectedData.time.value) || this.state.time))
            } else {
                this.fetchData();
            }
        });

        // if(this.props.fftFeatureList.filter(function(e) { return e.value === value.value; }).length > 0 ){
        //     this.setState({
        //         feature: value,
        //         fftFeature : true,
        //         featureOpen: false
        //     }, () => {
        //         this.fetchFFtData();
        //     });
        // }else{
        //     this.setState({ feature: value,
        //                     featureOpen: false }, () => {
        //         this.mapDatatoChart();
        //     });
        // }
    }

    loadFFTData(value) {
        var fftData = this.state.fftData;
        if (fftData) {
            let fftChartOptions = clonedeep(this.chartOptions);
            // fftChartOptions.yAxis.min = -50;
            fftChartOptions.xAxis = {
                // min : -50,
                ordinal: false,
                title: { text: this.props.props.t('Time (seconds)') },
                label: {
                    enabled: false
                },
                type: ''
            }

            // chartOptions1.xAxis.type = 'datetime';
            fftChartOptions.yAxis.title.text = this.props.props.t("Acceleration in")+" G-s"//this.state.feature.unit;
            let rawData = [];

            if (fftData.cleanRawData && fftData.time_series) {
                for (let s = 0; s < fftData.cleanRawData.length; s++) {
                    if (fftData.time_series[s]) {
                        rawData.push([fftData.time_series[s], fftData.cleanRawData[s]]);
                    }
                }
            }

            fftChartOptions.tooltip = {
                shared: true,
                useHTML: true,
                headerFormat: '<small style="color: {series.color};font-size:12px">{point.key:.3f}' + 's, ' + '{point.y}' + ' G</small>',
                pointFormat: '',
                footerFormat: '',
                valueDecimals: 2
            };
            // var result = Object.values(rawData.reduce((a, c) => {
            //     if (c.length) {
            //       var [first, second] = c;
            //       if (second > a.s) a.s = second;
            //     }
            //     return a;
            //   }, { s: Number.MIN_VALUE}));
            let max = Math.max.apply(null, fftData.cleanRawData || []); //11.08
            let min = Math.min.apply(null, fftData.cleanRawData || []); // 9.39

            fftChartOptions.series.push({
                showInLegend: false,
                color: 'black', data: rawData,
                name: this.state.feature.viewValue
            });
            fftChartOptions.yAxis.plotBands = [];
            fftChartOptions.xAxis.plotLines = [];

            // fftChartOptions.yAxis.max = (max * (20 / 100) < 0) ? max + (-(max * (20 / 100))) : max + (max * (20 / 100));
            fftChartOptions.yAxis.max = max;
            fftChartOptions.yAxis.min = min;
            // fftChartOptions.yAxis.min = 10;
            this.setState({
                options: fftChartOptions,
                feature: value,
                rawDataStatistics: fftData && fftData.rawDataStatistics,
                fftFeature: true,
                acceFeature: true,
                fftAccefeature: false,
                fftVelFeature: false,
                showWaveFeature: false,
                showSpecFeature: false
            }, () => {
                let chartObj = this.chart && this.chart.current && this.chart.current.chart;
                if (chartObj !== null) {
                    chartObj.hideLoading();
                    chartObj.zoomOut();
                }
            });
        } else {
            this.setState({
                options: this.chartOptions,
            }, () => {
                let chartObj = this.chart && this.chart.current && this.chart.current.chart;
                if (chartObj !== null) {
                    chartObj.hideLoading();
                    chartObj.zoomOut();
                }
            });
        }
    }
    loadShockWaveData(value) {
        let chartObj = this.chart && this.chart.current && this.chart.current.chart;
        if (chartObj !== null) {
            chartObj.xAxis[0].setExtremes(null, null);
            var fftData = this.state.fftData;
            if (fftData) {
                let fftChartOptions = clonedeep(this.chartOptions);
                this.setState({ annotable: false })

                fftChartOptions.yAxis.min = 0;
                fftChartOptions.xAxis = {
                    title: {
                        text: this.props.t("Seconds"),
                    },
                    label: {
                        enabled: false
                    },
                    type: ''
                }
                fftChartOptions.tooltip = {
                    shared: true,
                    useHTML: true,
                    headerFormat: '<small style="font-size:12px">{point.key:.3f}</small><table>',
                    pointFormat: '<tr><td style="color: {series.color};font-size:12px">{series.name}: </td>' +
                        '<td style="text-align: right;font-size:12px"><b>{point.y} </b></td></tr>',
                    footerFormat: '</table>',
                    valueDecimals: 2
                };
                fftChartOptions.yAxis.title.text = (this.state.feature.unit).includes("m/s") ? this.props.t(this.state.feature.unit) + " (rms)" : this.props.t(this.state.feature.unit);
                let velocityData = [];
                if (fftData.hasOwnProperty("sw_output") && !fftData.sw_output.hasOwnProperty("time")) {
                    fftData.sw_output.time = [];
                }
                
                if (fftData.sw_output && fftData.sw_output.time && fftData.sw_output.wave) {
                    for (let s = 0; s < fftData.sw_output.time.length; s++) {
                        if (fftData.sw_output.time[s]) {
                            velocityData.push([fftData.sw_output.time[s], fftData.sw_output.wave[s]]);
                        }
                    }
                }

                var result = Object.values(velocityData.reduce((a, c) => {
                    if (c.length) {
                        var [first, second] = c;
                        if (second > a.s) a.s = second;
                    }
                    return a;
                }, { s: Number.MIN_VALUE }));

                fftChartOptions.series.push({
                    showInLegend: false,
                    color: 'black', data: velocityData,
                    name: this.state.feature.viewValue
                });
                fftChartOptions.yAxis.plotBands = [];
                fftChartOptions.yAxis.max = result[0] + (result[0] * (20 / 100));
                fftChartOptions.xAxis.plotLines = this.state.trendlines;

                this.setState({
                    options: fftChartOptions,
                    feature: value,
                    RMS: fftData.sw_output && fftData.sw_output.sw_rms,
                    PKplus: fftData.sw_output && fftData.sw_output.sw_pk_max,
                    PKminus: fftData.sw_output && fftData.sw_output.sw_pk_min,
                    crest: fftData.sw_output && fftData.sw_output.sw_crest,
                    fftVelFeature: false,
                    trendlines: [],
                    fftAccefeature: false,
                    acceFeature: false,
                    showWaveFeature: true,
                    showSpecFeature: false
                }, () => {
                    let chartObj = this.chart && this.chart.current && this.chart.current.chart;
                    if (chartObj !== null) {
                        chartObj.hideLoading();
                        chartObj.zoomOut();
                    }
                });
            } else {
                this.setState({
                    options: this.chartOptions,
                }, () => {
                    let chartObj = this.chart && this.chart.current && this.chart.current.chart;
                    if (chartObj !== null) {
                        chartObj.hideLoading();
                        chartObj.zoomOut();
                    }
                });
            }
        }
    }
    loadFFTVelocityData(value) {
        let chartObj = this.chart && this.chart.current && this.chart.current.chart;
        if (chartObj !== null) {
            chartObj.xAxis[0].setExtremes(null, null);
            // var fftData = eval("(" + jsonData.result.FFTResult[0] + ")");
            var fftData = this.state.fftData;
            if (fftData) {
                let fftChartOptions = clonedeep(this.chartOptions);
                if (fftData.velocityFFTAnnotations && fftData.velocityFFTAnnotations.quality && fftData.velocityFFTAnnotations.quality !== 'UnAnnotable') {
                    this.configurePlotLines(fftData, value.value);
                    this.setState({ annotable: true });
                } else {
                    this.setState({ annotable: false });
                }

                fftChartOptions.yAxis.min = 0;
                fftChartOptions.xAxis = {
                    title: {
                        text: `${this.props.t('Frequency')} (${this.state.unit})`,
                    },
                    label: {
                        enabled: false
                    },
                    type: ''
                }
                fftChartOptions.tooltip = {
                    shared: true,
                    useHTML: true,
                    headerFormat: '<small style="font-size:12px">{point.key:.3f}</small><table>',
                    pointFormat: '<tr><td style="color: {series.color};font-size:12px">{series.name}: </td>' +
                        '<td style="text-align: right;font-size:12px"><b>{point.y} </b></td></tr>',
                    footerFormat: '</table>',
                    valueDecimals: 2
                };
                fftChartOptions.yAxis.title.text = this.state.feature.unit;
                let unitValue = (this.state.unit === "CPM" ? 60 : 1)

                let velocityData = [];
                let fftMinY, fftMaxY, lastX;
                if (fftData.velocityFFTData && fftData.frequencyVector) {
                    for (let s = 0; s < fftData.velocityFFTData.length; s++) {
                        var yVal = fftData.velocityFFTData[s];
                        fftMinY = fftMinY > yVal ? yVal : fftMinY;
                        fftMaxY = fftMaxY < yVal ? yVal : fftMaxY;
                        lastX = fftData.frequencyVector[s] * unitValue;
                        velocityData.push([lastX, yVal]);
                    }
                }
                
                var result = Object.values(velocityData.reduce((a, c) => {
                    if (c.length) {
                        var [first, second] = c;
                        if (second > a.s) a.s = second;
                    }
                    return a;
                }, { s: Number.MIN_VALUE }));

                fftChartOptions.series.push({
                    showInLegend: false,
                    color: 'black', data: velocityData,
                    name: this.state.feature.viewValue
                });
                fftChartOptions.yAxis.plotBands = [];
                fftChartOptions.yAxis.max = result[0] + (result[0] * (20 / 100));
                fftChartOptions.xAxis.plotLines = this.state.trendlines;

                this.setState({
                    options: fftChartOptions,
                    feature: value,
                    rawDataStatistics: fftData && fftData.RawDataStatistics,
                    VRMS: fftData && fftData.velocityFFTAnnotations && fftData.velocityFFTAnnotations.VRMS,
                    fftVelFeature: true,
                    trendlines: [],
                    fftAccefeature: false,
                    acceFeature: false,
                    fftVelFeature: true,
                    showWaveFeature: false,
                    showSpecFeature: false
                }, () => {
                    let chartObj = this.chart && this.chart.current && this.chart.current.chart;
                    if (chartObj !== null) {
                        chartObj.hideLoading();
                        chartObj.zoomOut();
                    }
                });
            } else {
                this.setState({
                    options: this.chartOptions,
                }, () => {
                    let chartObj = this.chart && this.chart.current && this.chart.current.chart;
                    if (chartObj !== null) {
                        chartObj.hideLoading();
                        chartObj.zoomOut();
                    }
                });
            }
        }
    }

    configurePlotLines(fftData, feature, unit) {
        if (
            fftData &&
            feature.toUpperCase().startsWith("FFTVEL") &&
            fftData.velocityFFTAnnotations &&
            fftData.velocityFFTAnnotations.quality &&
            fftData.velocityFFTAnnotations.quality == "Annotable" &&
            this.state.isShowAnnotation
        ) {
            this.createTrendLine(
                [fftData.basicInfo.fundamentalFrequency],
                '1X',
                '#2196f3',
                unit
            );
            this.createTrendLines(
                fftData.velocityFFTAnnotations.subharmonics,
                'Subharmonics',
                '#fff600',
                unit
            );
            this.createTrendLines(
                fftData.velocityFFTAnnotations.harmonics,
                'Harmonics',
                '#ff9800',
                unit
            );
            this.createTrendLines(
                fftData.velocityFFTAnnotations.interharmonics,
                'Interharmonics',
                '#4caf50',
                unit
            );
        } else if (
            fftData &&
            fftData.accelerationFFTAnnotations &&
            fftData.accelerationFFTAnnotations.quality &&
            fftData.accelerationFFTAnnotations.quality == "Annotable" &&
            this.state.isShowAnnotation &&
            unit
        ) {
            this.createTrendLine(
                fftData.basicInfo.fundamentalFrequency,
                '1X',
                '#2196f3',
                unit
            );
            this.createTrendLines(
                fftData.accelerationFFTAnnotations.subharmonics,
                'Subharmonics',
                '#fff600',
                unit
            );
            this.createTrendLines(
                fftData.accelerationFFTAnnotations.harmonics,
                'Harmonics',
                '#ff9800',
                unit
            );
            this.createTrendLines(
                fftData.accelerationFFTAnnotations.interharmonics,
                'Interharmonics',
                '#4caf50',
                unit
            );
        }
    }
    

    createTrendLines(xValues, label, color,unit) {
        if (xValues) {
            for (let x = 0; x < xValues.length; x++) {
                var xTup = xValues[x];
                this.createTrendLine(xTup[1], xTup[0] + "X", color,unit);
            }
        }
    }

    createTrendLine(xValue, label, color,unit) {
        var trendline = this.createFFTAnnotation(xValue, label, color,unit);
        this.state.trendlines.push(trendline);
    }

    createFFTAnnotation(val, lbl, color,unit) {
        if(unit !==this.state.unit){
            if(this.state.unit ==='CPM'){
                if (Array.isArray(val)) {
                    var value = [...val];
                    val = value.map((item) => {
                        item=item*60;
                        return item.toFixed(3);
                    })
                }else{
                    val=val*60;
                    val=val.toFixed(3);
                }
            }
        }
        var newVal = val;
        
        if (Array.isArray(val)) {
            var value = [...val];
            newVal = value.map((item) => {
                return item;
            })
        }

        return {
            value: val,
            width: 3,
            zIndex: 3,
            color: color,
            label: {
                label: lbl,
                text: lbl + " : " + newVal + " " + this.state.unit,
                style: {
                    color: 'black'
                }
            }
        };
    }
    loadShockWaveAutocorrelationData(value) {
        let chartObj = this.chart && this.chart.current && this.chart.current.chart;
        if (chartObj !== null) {
            chartObj.xAxis[0].setExtremes(null, null);
            // var fftData = eval("(" + jsonData.result.FFTResult[0] + ")");
            var fftData = this.state.fftData;
            if (fftData) {
                let fftChartOptions = clonedeep(this.chartOptions);
                this.setState({ annotable: false })
                fftChartOptions.yAxis.min = 0;
                fftChartOptions.xAxis = {
                    title: {
                        text: this.props.t("Seconds"),
                    },
                    label: {
                        enabled: false
                    },
                    type: ''
                }
                fftChartOptions.tooltip = {
                    shared: true,
                    useHTML: true,
                    headerFormat: '<small style="font-size:12px">{point.key:.3f}</small><table>',
                    pointFormat: '<tr><td style="color: {series.color};font-size:12px">{series.name}: </td>' +
                        '<td style="text-align: right;font-size:12px"><b>{point.y} </b></td></tr>',
                    footerFormat: '</table>',
                    valueDecimals: 2
                };
                fftChartOptions.yAxis.title.text = (this.state.feature.unit).includes("m/s") ? this.props.t(this.state.feature.unit) + " (rms)" : this.props.t(this.state.feature.unit);
                let velocityData = [];
                if (fftData.sw_output && !fftData.sw_output.hasOwnProperty("time_auto_corr")) {
                    fftData.sw_output.time_auto_corr = []; // MINDAPP-1900 added to prevent detailed dash from breaking when shockwave time_auto_corr property is missing from res
                }

                if (fftData.sw_output && fftData.sw_output.time_auto_corr && fftData.sw_output.auto_corr) {
                    for (let s = 0; s < fftData.sw_output.time_auto_corr.length; s++) {
                        if (fftData.sw_output.time_auto_corr[s] && fftData.sw_output.auto_corr[s]) {
                            velocityData.push([fftData.sw_output.time_auto_corr[s], fftData.sw_output.auto_corr[s]]);
                        }
                    }
                }

                var result = Object.values(velocityData.reduce((a, c) => {
                    if (c.length) {
                        var [first, second] = c;
                        if (second > a.s) a.s = second;
                    }
                    return a;
                }, { s: Number.MIN_VALUE }));

                fftChartOptions.series.push({
                    showInLegend: false,
                    color: 'black', data: velocityData,
                    name: this.state.feature.viewValue
                });
                fftChartOptions.yAxis.plotBands = [];
                fftChartOptions.yAxis.max = result[0] + (result[0] * (20 / 100));
                fftChartOptions.xAxis.plotLines = this.state.trendlines;

                this.setState({
                    options: fftChartOptions,
                    feature: value,
                    RMS: fftData.sw_output && fftData.sw_output.sw_rms,
                    PKplus: fftData.sw_output && fftData.sw_output.sw_pk_max,
                    PKminus: fftData.sw_output && fftData.sw_output.sw_pk_min,
                    crest: "",
                    fftVelFeature: false,
                    trendlines: [],
                    fftAccefeature: false,
                    acceFeature: false,
                    showWaveFeature: true,
                    fftFeature: false,
                    showSpecFeature: false
                }, () => {
                    let chartObj = this.chart && this.chart.current && this.chart.current.chart;
                    if (chartObj !== null) {
                        chartObj.hideLoading();
                        chartObj.zoomOut();
                    }
                });
            } else {
                this.setState({
                    options: this.chartOptions,
                }, () => {
                    let chartObj = this.chart && this.chart.current && this.chart.current.chart;
                    if (chartObj !== null) {
                        chartObj.hideLoading();
                        chartObj.zoomOut();
                    }
                });
            }
        }

    }
    loadShockWaveSpectrumData(value) {
        let chartObj = this.chart && this.chart.current && this.chart.current.chart;
        if (chartObj !== null) {
            chartObj.xAxis[0].setExtremes(null, null);

            // var fftData = eval("(" + jsonData.result.FFTResult[0] + ")");
            var fftData = this.state.fftData;
            if (fftData) {
                let fftChartOptions = clonedeep(this.chartOptions);
                // if (fftData.velocityFFTAnnotations.quality !== 'UnAnnotable') {
                //     this.configurePlotLines(fftData, value.value);
                //     this.setState({ annotable: true })
                // } else {
                this.setState({ annotable: false })
                //}

                fftChartOptions.yAxis.min = 0;
                fftChartOptions.xAxis = {
                    title: {
                        text: "Hz",
                    },
                    label: {
                        enabled: false
                    },
                    type: ''
                }
                fftChartOptions.tooltip = {
                    shared: true,
                    useHTML: true,
                    headerFormat: '<small style="font-size:12px">{point.key:.3f}</small><table>',
                    pointFormat: '<tr><td style="color: {series.color};font-size:12px">{series.name}: </td>' +
                        '<td style="text-align: right;font-size:12px"><b>{point.y} </b></td></tr>',
                    footerFormat: '</table>',
                    valueDecimals: 2
                };
                fftChartOptions.yAxis.title.text = (this.state.feature.unit).includes("m/s") ? this.props.t(this.state.feature.unit) + " (rms)" : this.props.t(this.state.feature.unit);
                let velocityData = [];
                
                if (fftData.sw_output && !fftData.sw_output.hasOwnProperty("freq")) {
                    fftData.sw_output.freq = [];
                }
                
                if (fftData.sw_output && fftData.sw_output.freq && fftData.sw_output.fft) {
                    for (let s = 0; s < fftData.sw_output.freq.length; s++) {
                        if (fftData.sw_output.freq[s] && fftData.sw_output.fft[s]) {
                            velocityData.push([fftData.sw_output.freq[s], fftData.sw_output.fft[s]]);
                        }
                    }
                }
                
                var result = Object.values(velocityData.reduce((a, c) => {
                    if (c.length) {
                        var [first, second] = c;
                        if (second > a.s) a.s = second;
                    }
                    return a;
                }, { s: Number.MIN_VALUE }));

                fftChartOptions.series.push({
                    showInLegend: false,
                    color: 'black', data: velocityData,
                    name: this.state.feature.viewValue
                });
                fftChartOptions.yAxis.plotBands = [];
                fftChartOptions.yAxis.max = result[0] + (result[0] * (20 / 100));
                fftChartOptions.xAxis.plotLines = this.state.trendlines;

                this.setState({
                    options: fftChartOptions,
                    feature: value,
                    //rawDataStatistics: fftData.RawDataStatistics,
                    //VRMS: fftData.velocityFFTAnnotations.VRMS,
                    fftVelFeature: false,
                    trendlines: [],
                    fftAccefeature: false,
                    acceFeature: false,
                    showWaveFeature: false,
                    fftFeature: false,
                    showSpecFeature: true
                }, () => {
                    let chartObj = this.chart && this.chart.current && this.chart.current.chart;
                    if (chartObj !== null) {
                        chartObj.hideLoading();
                        chartObj.zoomOut();
                    }
                });
            } else {
                this.setState({
                    options: this.chartOptions,
                }, () => {
                    let chartObj = this.chart && this.chart.current && this.chart.current.chart;
                    if (chartObj !== null) {
                        chartObj.hideLoading();
                        chartObj.zoomOut();
                    }
                });
            }
        }


    }
    loadFFTAccelerationData(value) {
        let chartObj = this.chart && this.chart.current && this.chart.current.chart;
        if (chartObj !== null) {
            chartObj.xAxis[0].setExtremes(null, null);
            // var fftData = eval("(" + jsonData.result.FFTResult[0] + ")");
            var fftData = this.state.fftData;
            if (fftData) {
                let fftChartOptions = clonedeep(this.chartOptions);
                fftChartOptions.yAxis.min = 0;
                if (fftData.accelerationFFTAnnotations && fftData.accelerationFFTAnnotations.quality && fftData.accelerationFFTAnnotations.quality !== 'UnAnnotable') {
                    this.configurePlotLines(fftData, value.value);
                }

                fftChartOptions.xAxis = {
                    title: {
                        text: `${this.props.t('Frequency')} (${this.state.unit})`,
                    },
                    label: {
                        enabled: false
                    },
                    type: ''
                }
                fftChartOptions.tooltip = {
                    shared: true,
                    useHTML: true,
                    headerFormat: '<small style="font-size:12px">{point.key:.3f}</small><table>',
                    pointFormat: '<tr><td style="color: {series.color};font-size:12px">{series.name}: </td>' +
                        '<td style="text-align: right;font-size:12px"><b>{point.y} </b></td></tr>',
                    footerFormat: '</table>',
                    valueDecimals: 2
                };
                fftChartOptions.yAxis.title.text = (this.state.feature.unit).includes("m/s") ? this.state.feature.unit + " (rms)" : this.state.feature.unit;
                let accelerationData = [];
                let fftMinY, fftMaxY, lastX;
                let unitValue = (this.state.unit === "CPM" ? 60 : 1)

                if (fftData && fftData.accelerationFFTData && fftData.frequencyVector) {
                    for (let s = 0; s < fftData.accelerationFFTData.length; s++) {
                        var yVal = fftData.accelerationFFTData[s];
                        fftMinY = fftMinY > yVal ? yVal : fftMinY;
                        fftMaxY = fftMaxY < yVal ? yVal : fftMaxY;
                        lastX = fftData.frequencyVector[s] * unitValue;
                        accelerationData.push([lastX, yVal]);
                    }
                }
                
                var result = Object.values(accelerationData.reduce((a, c) => {
                    if (c.length) {
                        var [first, second] = c;
                        if (second > a.s) a.s = second;
                    }

                    return a;
                }, { s: Number.MIN_VALUE }));

                fftChartOptions.series.push({
                    showInLegend: false,
                    color: 'black', data: accelerationData,
                    name: this.state.feature.viewValue

                });

                fftChartOptions.yAxis.plotBands = [];
                fftChartOptions.xAxis.plotLines = this.state.trendlines;
                fftChartOptions.yAxis.max = result[0] + (result[0] * (20 / 100));

                this.setState({
                    options: fftChartOptions,
                    feature: value,
                    ARMS: fftData && fftData.accelerationFFTAnnotations && fftData.accelerationFFTAnnotations.ARMS,
                    rawDataStatistics: fftData && fftData.RawDataStatistics,
                    fftFeature: true,
                    trendlines: [],
                    fftAccefeature: true,
                    acceFeature: false,
                    fftVelFeature: false,
                    showWaveFeature: false,
                    showSpecFeature: false
                }, () => {
                    let chartObj = this.chart && this.chart.current && this.chart.current.chart;
                    if (chartObj !== null) {
                        chartObj.hideLoading();
                        chartObj.zoomOut();
                    }
                });
            } else {
                this.setState({
                    options: this.chartOptions,
                }, () => {
                    let chartObj = this.chart && this.chart.current && this.chart.current.chart;
                    if (chartObj !== null) {
                        chartObj.hideLoading();
                        chartObj.zoomOut();
                    }
                });
            }
        }
    }

    rangeChange = (value) => {
        this.setState({
            range: value,
            lastOpen: false
        }, () => {
        });
    }

    isIsoDate(str) {
        if (!/\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}.\d{3}Z/.test(str)) return false;
        var d = new Date(str);
        return d.toISOString() === str;
    }
    fetchData = (time) => {
        this.setState({ isNoDataFlag: false }, () => {
            const { range, feature, selectedFeature } = this.state;
            let chartObj = this.chart && this.chart.current && this.chart.current.chart;
            if (chartObj !== null) {
                chartObj.hideNoData();
                chartObj.showLoading(this.props.props.t("Fetching Data... Usually it takes 60 sec to collect the data"));
                // let currentDateTime = new Date();
                let from = ''
                let check = this.isIsoDate(time)
                if (check) {
                    from = time
                }
                else {
                    if (time && !(time.hasOwnProperty('value'))) {
                        let date = new Date(time);
                        let dateIso = date.toISOString();
                        from = dateIso
                    }

                }
                if (time && time.hasOwnProperty('value')) {
                    from = time.value;
                }
                else {
                    if (time === "") {
                        from = '';
                    }
                }
                if (this.props.fftFeatureList !== undefined && selectedFeature && this.props.fftFeatureList.filter(function (e) { return e.value === selectedFeature.value; }).length > 0) {
                    this.unitStoredinCache()
                    this.setState({
                        fftFeature: true,
                        feature: selectedFeature,
                    }, () => {
                        this.fetchFFtData(from);

                    });
                } else {
                    this.setState({
                        feature: selectedFeature,
                        fftFeature: false,
                        acceFeature: false,
                        fftVelFeature: false,
                        showWaveFeature: false,
                        fftAccefeature: false,
                        showSpecFeature: false
                    }, () => {
                        this.fetchBasicFeatureData(range);
                    });
                }
            }
        });
    }

    fetchBasicFeatureData(range) {
        if (this.props.deviceId == null || this.props.deviceId == 'null' || this.props.fftFeatureList === undefined) {
            this.setState({
                timeSeriesData: {
                    'basic_features': [],
                    'fingerprint_data': []
                }
            });
        } else {
            this.setState({
                timeSeriesData: {
                    'basic_features': [],
                    'fingerprint_data': []
                }
            });
            // fetch(`${config.url.mindapp_server_url}/iu/md/api/realtime-data?limit=${range.value}&monitorId=${this.props.deviceId}`)
            //     // fetch(`http://192.168.1.47:8002/iu/md/api/realtime-data?monitorId=25&limit=2`)
            //     .then(res => res.json())
            //     .then(
            //         (result) => {
            //             this.setState({
            //                 timeSeriesData: result
            //             });
            //         },
            //         // Note: it's important to handle errors here
            //         // instead of a catch() block so that we don't swallow
            //         // exceptions from actual bugs in components.
            //         (error) => {
            //             this.setState({
            //                 isLoaded: true,
            //                 error
            //             });
            //         }
            //     )
        }
    }

    // ..........................FFT Data Code...................
    fetchFFtData =  (time) => {
        const { feature, sendCommand } = this.state;
        let from = '';
        let axisType = feature.value[feature.value.length - 1];
        let fftColr = document.getElementsByClassName(`vis-item vis-box fft ${moment(this.state.time).utc().local().format("YYYY-MM-DD HH:mm:ss")} vis-readonly`);
        let fftHighlightedData = this.props && this.props.fftData && this.props.fftData.FFTData && this.props.fftData.FFTData.sort((a, b) => (new Date(a.time) - new Date(b.time)))
        const accessToken = getOidcAccessToken();
        if (time === "") {
            this.setState({ backArr: true, frontArr: false, fftHighlightedData, forntArrowFlag: true })
        } else {
            this.setState({ frontArr: true })
        }
        if (((fftColr && fftColr.length !== 0) && (this.state.time))) {
            fftColr[0].classList.add("mystyle");
        }
        //this.shaockWaveData(from);
        if (feature.value.indexOf('shockwave') == -1) {
            if (this.props.deviceId) {
                let macAddress = '';
                if (this.props.macAddress && this.props.macAddress !== 'null' && this.props.macAddress !== null) {
                    macAddress = this.props.macAddress;
                }
                this.setState({ loaderClose: true })

                this.props.setLoaderState({ "value": true });

                fetch(`${config.url.mindapp_server_url}/iu/md/api/fft-data?monitorId=${this.props.deviceId}&macId=${macAddress}&selectAxis=${axisType}&to=${time}&sendCommand=${sendCommand}`,
                    {
                        headers: {
                            "Authorization": `Bearer ${accessToken}`,
                            "Accept-Language": localStorage.getItem("lang") || "en"
                        }
                    })
                    .then(res => res.json())
                    .then(
                        (result) => {
                            let parseResult = eval("(" + result.data + ")");
                            if (parseResult) {
                                clearTimeout(timerCall)
                                this.setState({
                                    sendCommand: true,
                                    fftData: parseResult,
                                    time: result.time,
                                    showWaveFeature: false,
                                    showSpecFeature: false,
                                    RPM: parseResult.rpm ? parseResult.rpm + ' (' + (parseResult.rpm / 60).toFixed(2) + 'Hz)' : undefined
                                }, () => {
                                    if (time === '') {
                                        this.setState({ addFFTData: [{ time: result.time, id: result.time }] })
                                        this.props.updateFftData({ time: result.time, id: result.time });
                                    }
                                    this.props.setFftSelectedData('');
                                    if (this.state.time == '') {
                                        this.setState({ time: new Date() })
                                    }
                                    let check = this.isIsoDate(this.state.time)
                                    if (!check) {
                                        time = (this.state.time).toISOString;
                                    }
                                    let data = this.props.fftData && this.props.fftData.FFTData.sort((a, b) => (new Date(a.time) - new Date(b.time)))
                                    let index = data.findIndex(x => x.time === time);
                                    if (index === 0) {
                                        this.setState({ backArr: false, frontArr: true })
                                    }
                                    if (index !== 0 && data.length !== 0) {
                                        this.setState({ backArr: true })
                                    }
                                    if (index === -1 && data.length === 0) {
                                        this.setState({ backArr: false, frontArr: false })

                                    }
                                    if (index === -1 && this.state.addFFTData.length != 0) {
                                        let ind = this.state.addFFTData.findIndex(x => x.time === this.state.time)
                                        if (ind !== -1) {
                                            this.setState({ frontArr: false, backArr: true })
                                        }
                                        if (data.length === 0) {
                                            this.setState({ backArr: false })
                                        }
                                    }
                                    if ((index === (data.length - 1)) && (data.length !== 0) && (this.state.addFFTData.length === 0)) {
                                        this.setState({ backArr: true, frontArr: false, forntArrowFlag: false })
                                    }
                                    if ((index === (data.length - 1)) && this.state.forntArrowFlag && (data.length !== 0) && (this.state.addFFTData.length === 0)) {
                                        this.setState({ backArr: true, frontArr: false, forntArrowFlag: false })
                                    }
                                    if ((index === (data.length - 1)) && index === 0 && (data.length !== 0) && (this.state.addFFTData.length === 0)) {
                                        this.setState({ backArr: false, frontArr: false, forntArrowFlag: false })
                                    }
                                    if (feature.value === 'accelerationTimeWaveformX' || feature.value === 'accelerationTimeWaveformY' || feature.value === 'accelerationTimeWaveformZ') {
                                        this.loadFFTData(feature);
                                    } else if (feature.value === 'fftVelocityX' || feature.value === 'fftVelocityY' || feature.value === 'fftVelocityZ') {
                                        this.loadFFTVelocityData(feature);
                                    } else if (feature.value === 'fftAccelerationX' || feature.value === 'fftAccelerationY' || feature.value === 'fftAccelerationZ') {
                                        this.loadFFTAccelerationData(feature);
                                    }


                                });
                                this.setState({
                                    loaderClose: false
                                })
                                this.props.setLoaderState({ "value": false });
                            } else {
                                if (time) {
                                    this.props.setLoaderState({ "value": false });
                                    this.setState({
                                        loaderClose: false,
                                        isNoDataFlag: true,
                                        time: '',
                                        acceFeature: false,
                                        fftVelFeature: false,
                                        fftAccefeature: false,
                                        showWaveFeature: false,
                                        showSpecFeature: false
                                    })
                                } else {
                                    if (this.state.retry != 9) {
                                        var retry = this.state.retry ? (this.state.retry + 1) : 1;
                                        this.setState({ isRetry: true, retry: retry, sendCommand: false })
                                        timerCall = setTimeout(() => {
                                            this.fetchData(time);
                                        }, 10000);
                                    } else {
                                        this.setState({ loaderClose: false })
                                        clearInterval(timerCall)
                                        this.props.setLoaderState({ "value": false });
                                        this.setState({ isRetry: false, retry: null, sendCommand: true });
                                        this.setState({
                                            isNoDataFlag: true,
                                            time: '',
                                            acceFeature: false,
                                            fftVelFeature: false,
                                            fftAccefeature: false
                                        })
                                    }
                                }
                            }

                        },
                        // Note: it's important to handle errors here
                        // instead of a catch() block so that we don't swallow
                        // exceptions from actual bugs in components.
                        (error) => {
                            this.props.setLoaderState({ "value": false });
                            this.setState({
                                loaderClose: false
                            })
                            this.setState({
                                isLoaded: true,
                                error
                            });
                        }
                    )
                    .catch(function (error) {
                        // this.props.setLoaderState({ "value": false });
                    });
            } else if (this.props.deviceId) {
                let macAddress = '';
                if (this.props.macAddress) {
                    macAddress = this.props.macAddress;
                }
                this.setState({ loaderClose: true })
                this.props.setLoaderState({ "value": true });
                fetch(`${config.url.mindapp_server_url}/iu/md/api/fft-data?monitorId=${this.props.deviceId}&macId=${macAddress}&selectAxis=${axisType}&to=${time}`,
                    {
                        headers: {
                            "Authorization": `Bearer ${accessToken}`,
                            "Accept-Language": localStorage.getItem("lang") || "en"
                        }
                    })
                    .then(res => res.json())
                    .then(
                        (result) => {
                            if (Object.keys(result).length > 0) {
                                let parseResult = eval("(" + result.data + ")");
                                this.setState({
                                    sendCommand: true,
                                    fftData: parseResult,
                                    time: result.time,
                                    showWaveFeature: false,
                                    showSpecFeature: false
                                }, () => {

                                    if (time === '') {
                                        this.setState({ addFFTData: [{ time: result.time, id: 999 }] })
                                        this.props.updateFftData({ time: result.time, id: 999 });
                                    }
                                    this.props.setFftSelectedData('');
                                    if (this.state.time == '') {
                                        this.setState({ time: new Date() })
                                    }
                                    let check = this.isIsoDate(this.state.time)
                                    if (!check) {
                                        time = (this.state.time).toISOString;
                                    }
                                    let data = this.props.fftData && this.props.fftData.FFTData.sort((a, b) => (new Date(a.time) - new Date(b.time)))
                                    let index = data.findIndex(x => x.time === time);
                                    if (index === 0) {
                                        this.setState({ backArr: false, frontArr: true })
                                    }
                                    if (index !== 0 && data.length !== 0) {
                                        this.setState({ backArr: true })
                                    }
                                    if (index === -1 && data.length === 0) {
                                        this.setState({ backArr: false, frontArr: false })

                                    }
                                    if (index === -1 && this.state.addFFTData.length != 0) {
                                        let ind = this.state.addFFTData.findIndex(x => x.time === this.state.time)
                                        if (ind !== -1) {
                                            this.setState({ frontArr: false, backArr: true })
                                        }
                                        if (data.length === 0) {
                                            this.setState({ backArr: false })
                                        }
                                    }
                                    if ((index === (data.length - 1)) && (data.length !== 0) && (this.state.addFFTData.length === 0)) {
                                        this.setState({ backArr: true, frontArr: false, forntArrowFlag: false })
                                    }
                                    if ((index === (data.length - 1)) && this.state.forntArrowFlag && (data.length !== 0) && (this.state.addFFTData.length === 0)) {
                                        this.setState({ backArr: true, frontArr: false, forntArrowFlag: false })
                                    }
                                    if ((index === (data.length - 1)) && index === 0 && (data.length !== 0) && (this.state.addFFTData.length === 0)) {
                                        this.setState({ backArr: false, frontArr: false, forntArrowFlag: false })
                                    }

                                    if (feature.value === 'accelerationTimeWaveformX' || feature.value === 'accelerationTimeWaveformY' || feature.value === 'accelerationTimeWaveformZ') {
                                        this.loadFFTData(feature);
                                    } else if (feature.value === 'fftVelocityX' || feature.value === 'fftVelocityY' || feature.value === 'fftVelocityZ') {
                                        this.loadFFTVelocityData(feature);
                                    } else if (feature.value === 'fftAccelerationX' || feature.value === 'fftAccelerationY' || feature.value === 'fftAccelerationZ') {
                                        this.loadFFTAccelerationData(feature);
                                    }

                                });
                            } else {
                                this.setState({
                                    isNoDataFlag: true,
                                    time: '',
                                    acceFeature: false,
                                    fftVelFeature: false,
                                    fftAccefeature: false
                                })
                            }
                            this.setState({
                                loaderClose: false
                            })
                            this.props.setLoaderState({ "value": false });
                        },
                        // Note: it's important to handle errors here
                        // instead of a catch() block so that we don't swallow
                        // exceptions from actual bugs in components.
                        (error) => {
                            this.setState({
                                isLoaded: true,
                                error
                            });
                        }
                    ).catch(function (error) {
                        this.props.setLoaderState({ "value": false });
                        this.setState({
                            loaderClose: false
                        })
                    });
            } else {
                this.setState({
                    isNoDataFlag: true,
                    time: '',
                    acceFeature: false,
                    fftVelFeature: false,
                    fftAccefeature: false
                })
                //     this.setState({ options : this.chartOptions ,options : { lang : { noData :"No data received, please check the device's network connectivity. (Note- the min. requirement to get data reliably is 'upload speed: 50 Kbps & WiFi signal strength: -60 dBm' )"
                // } }})
            }
        } else {
            this.shaockWaveData(time);
            this.setState({ sendCommand: true })
        }
    }

    shaockWaveData = (time) => {
        const { feature } = this.state;
        let from = '';
        const accessToken = getOidcAccessToken();
        let axisType = feature.value[feature.value.length - 1];
        if (this.props.deviceId) {
            let macAddress = '';
            if (this.props.macAddress && this.props.macAddress !== 'null' && this.props.macAddress !== null) {
                macAddress = this.props.macAddress;
            }
            this.setState({ loaderClose: true })

            this.props.setLoaderState({ "value": true });

            fetch(`${config.url.mindapp_server_url}/iu/md/api/shockwave-data?monitorId=${this.props.deviceId}&macId=${macAddress}&selectAxis=${axisType}&to=${time}`,
                {
                    headers: {
                        "Authorization": `Bearer ${accessToken}`,
                        "Accept-Language": localStorage.getItem("lang") || "en"
                    }
                }
            )
                .then(res => res.json())
                .then(
                    (result) => {
                        let parseResult = eval("(" + result.data + ")");
                        if (parseResult) {
                            this.setState({
                                fftData: parseResult,
                                time: result.time,
                                //RPM: result.rpm ? result.rpm + ' (' + (result.rpm / 60).toFixed(2) + 'Hz)' : undefined
                            }, () => {
                                if (time === '') {
                                    this.setState({ addFFTData: [{ time: result.time, id: result.time }] })
                                    this.props.updateFftData({ time: result.time, id: result.time });
                                }
                                this.props.setFftSelectedData('');
                                if (this.state.time == '') {
                                    this.setState({ time: new Date() })
                                }
                                let check = this.isIsoDate(this.state.time)
                                if (!check) {
                                    time = (this.state.time).toISOString;
                                }
                                let data = this.props.fftData && this.props.fftData.FFTData.sort((a, b) => (new Date(a.time) - new Date(b.time)))
                                let index = data.findIndex(x => x.time === time);
                                if (index === 0) {
                                    this.setState({ backArr: false, frontArr: true })
                                }
                                if (index !== 0 && data.length !== 0) {
                                    this.setState({ backArr: true })
                                }
                                if (index === -1 && data.length === 0) {
                                    this.setState({ backArr: false, frontArr: false })

                                }
                                if (index === -1 && this.state.addFFTData.length != 0) {
                                    let ind = this.state.addFFTData.findIndex(x => x.time === this.state.time)
                                    if (ind !== -1) {
                                        this.setState({ frontArr: false, backArr: true })
                                    }
                                    if (data.length === 0) {
                                        this.setState({ backArr: false })
                                    }
                                }
                                if ((index === (data.length - 1)) && (data.length !== 0) && (this.state.addFFTData.length === 0)) {
                                    this.setState({ backArr: true, frontArr: false, forntArrowFlag: false })
                                }
                                if ((index === (data.length - 1)) && this.state.forntArrowFlag && (data.length !== 0) && (this.state.addFFTData.length === 0)) {
                                    this.setState({ backArr: true, frontArr: false, forntArrowFlag: false })
                                }
                                if ((index === (data.length - 1)) && index === 0 && (data.length !== 0) && (this.state.addFFTData.length === 0)) {
                                    this.setState({ backArr: false, frontArr: false, forntArrowFlag: false })
                                }


                                if (feature.value === "shockwaveWaveformX" || feature.value === "shockwaveWaveformY" || feature.value === "shockwaveWaveformZ") {
                                    this.loadShockWaveData(feature);
                                }
                                else if (feature.value === "shockwaveAutocorrelationX" || feature.value === "shockwaveAutocorrelationY" || feature.value === "shockwaveAutocorrelationZ") {
                                    this.loadShockWaveAutocorrelationData(feature);
                                }
                                else if (feature.value === "shockwaveSpectrumX" || feature.value === "shockwaveSpectrumY" || feature.value === "shockwaveSpectrumZ") {
                                    this.loadShockWaveSpectrumData(feature);
                                }
                            });
                            this.setState({
                                loaderClose: false
                            })
                            this.props.setLoaderState({ "value": false });
                        } else {
                            if (time) {
                                this.props.setLoaderState({ "value": false });
                                this.setState({
                                    isNoDataFlag: true,
                                    time: '',
                                    acceFeature: false,
                                    fftVelFeature: false,
                                    fftAccefeature: false,
                                    showWaveFeature: false,
                                    showSpecFeature: false
                                })
                            } else {
                                if (this.state.retry != 9) {
                                    var retry = this.state.retry ? (this.state.retry + 1) : 1;
                                    timerCall = this.setState({ isRetry: true, retry: retry, sendCommand: false })
                                    setTimeout(() => {
                                        this.fetchData(time);
                                    }, 10000);
                                } else {
                                    this.setState({ loaderClose: false })

                                    this.props.setLoaderState({ "value": false });
                                    this.setState({ isRetry: false, retry: null, sendCommand: true });
                                    this.setState({
                                        isNoDataFlag: true,
                                        time: '',
                                        acceFeature: false,
                                        fftVelFeature: false,
                                        fftAccefeature: false,
                                        showWaveFeature: false,
                                        showSpecFeature: false
                                    })
                                }
                            }
                        }

                    },
                    // Note: it's important to handle errors here
                    // instead of a catch() block so that we don't swallow
                    // exceptions from actual bugs in components.
                    (error) => {
                        this.props.setLoaderState({ "value": false });
                        this.setState({
                            loaderClose: false
                        })
                        this.setState({
                            isLoaded: true,
                            error
                        });
                    }
                )
                .catch(function (error) {
                    // this.props.setLoaderState({ "value": false });
                });
        } else if (this.props.deviceId) {
            let macAddress = '';
            if (this.props.macAddress) {
                macAddress = this.props.macAddress;
            }
            this.setState({ loaderClose: true })

            this.props.setLoaderState({ "value": true });
            fetch(`${config.url.mindapp_server_url}/iu/md/api/shockwave-data?monitorId=${this.props.deviceId}&macId=${macAddress}&selectAxis=${axisType}&to=${time}`,
                {
                    headers: {
                        "Authorization": `Bearer ${accessToken}`,
                        "Accept-Language": localStorage.getItem("lang") || "en"
                    }
                })
                .then(res => res.json())
                .then(
                    (result) => {
                        if (Object.keys(result).length > 0) {
                            let parseResult = eval("(" + result.data + ")");
                            this.setState({
                                fftData: parseResult,
                                time: result.time
                            }, () => {

                                if (time === '') {
                                    this.setState({ addFFTData: [{ time: result.time, id: 999 }] })
                                    this.props.updateFftData({ time: result.time, id: 999 });
                                }
                                this.props.setFftSelectedData('');
                                if (this.state.time == '') {
                                    this.setState({ time: new Date() })
                                }
                                let check = this.isIsoDate(this.state.time)
                                if (!check) {
                                    time = (this.state.time).toISOString;
                                }
                                let data = this.props.fftData && this.props.fftData.FFTData.sort((a, b) => (new Date(a.time) - new Date(b.time)))
                                let index = data.findIndex(x => x.time === time);
                                if (index === 0) {
                                    this.setState({ backArr: false, frontArr: true })
                                }
                                if (index !== 0 && data.length !== 0) {
                                    this.setState({ backArr: true })
                                }
                                if (index === -1 && data.length === 0) {
                                    this.setState({ backArr: false, frontArr: false })

                                }
                                if (index === -1 && this.state.addFFTData.length != 0) {
                                    let ind = this.state.addFFTData.findIndex(x => x.time === this.state.time)
                                    if (ind !== -1) {
                                        this.setState({ frontArr: false, backArr: true })
                                    }
                                    if (data.length === 0) {
                                        this.setState({ backArr: false })
                                    }
                                }
                                if ((index === (data.length - 1)) && (data.length !== 0) && (this.state.addFFTData.length === 0)) {
                                    this.setState({ backArr: true, frontArr: false, forntArrowFlag: false })
                                }
                                if ((index === (data.length - 1)) && this.state.forntArrowFlag && (data.length !== 0) && (this.state.addFFTData.length === 0)) {
                                    this.setState({ backArr: true, frontArr: false, forntArrowFlag: false })
                                }
                                if ((index === (data.length - 1)) && index === 0 && (data.length !== 0) && (this.state.addFFTData.length === 0)) {
                                    this.setState({ backArr: false, frontArr: false, forntArrowFlag: false })
                                }

                                if (feature.value === "shockwaveWaveformX" || feature.value === "shockwaveWaveformY" || feature.value === "shockwaveWaveformZ") {
                                    this.loadShockWaveData(feature);
                                }
                                else if (feature.value === "shockwaveAutocorrelationX" || feature.value === "shockwaveAutocorrelationY" || feature.value === "shockwaveAutocorrelationZ") {
                                    this.loadShockWaveAutocorrelationData(feature);
                                }
                                else if (feature.value === "shockwaveSpectrumX" || feature.value === "shockwaveSpectrumY" || feature.value === "shockwaveSpectrumZ") {
                                    this.loadShockWaveSpectrumData(feature);
                                }
                            });
                        } else {
                            this.setState({
                                isNoDataFlag: true,
                                time: '',
                                acceFeature: false,
                                fftVelFeature: false,
                                fftAccefeature: false,
                                showWaveFeature: false,
                                showSpecFeature: false
                            })
                        }
                        this.setState({
                            loaderClose: false
                        })
                        this.props.setLoaderState({ "value": false });
                    },
                    // Note: it's important to handle errors here
                    // instead of a catch() block so that we don't swallow
                    // exceptions from actual bugs in components.
                    (error) => {
                        this.setState({
                            isLoaded: true,
                            error
                        });
                    }
                ).catch(function (error) {
                    this.props.setLoaderState({ "value": false });
                    this.setState({
                        loaderClose: false
                    })
                });
        } else {
            this.setState({
                isNoDataFlag: true,
                time: '',
                acceFeature: false,
                fftVelFeature: false,
                fftAccefeature: false,
                showWaveFeature: false,
                showSpecFeature: false
            })
            //     this.setState({ options : this.chartOptions ,options : { lang : { noData :"No data received, please check the device's network connectivity. (Note- the min. requirement to get data reliably is 'upload speed: 50 Kbps & WiFi signal strength: -60 dBm' )"
            // } }})
        }
    }

    toggleUnit = (e, id, unit) => {
        localStorage.setItem("unit",unit)
        if (unit != this.state.unit) {

            this.setState({
                activeItem: { [id]: true },
                //options: options1,
                unit: unit,
                loading: false
            }, () => {
                if (this.state.feature.value === 'fftVelocityX' || this.state.feature.value === 'fftVelocityY' || this.state.feature.value === 'fftVelocityZ') {
                    this.loadFFTVelocityData(this.state.feature);
                }
                if (this.state.feature.value === 'fftAccelerationX' || this.state.feature.value === 'fftAccelerationY' || this.state.feature.value === 'fftAccelerationZ') {
                    this.loadFFTAccelerationData(this.state.feature);
                }
            })
        }
    }

    countDecimals = function (value) {
        if (Math.floor(value) === value) {
            return 0;
        }

        return value.toString().split(".")[1].length || 0;
    }

    lastClick = () => {
        let parnetEle = document.getElementById('selector-Panel');
        if (this.state.isFeatureSelected) {
            parnetEle.lastElementChild.lastElementChild.style.visibility = "hidden";
        } else {
            parnetEle.lastElementChild.lastElementChild.style.visibility = "visible";
        }
    }

    onClickCheckbox() {
        this.setState({
            isShowAnnotation: !this.state.isShowAnnotation
        })
    }
    backArrowClick = () => {
        this.setState({ odrfftIconShow: false })
        let x = document.getElementsByClassName("mystyle");
        // If it exists, remove it.
        if (x.length > 0) { x[0].classList.remove("mystyle"); }
        let line = document.getElementsByClassName(`vis-item vis-line fft ${moment(this.state.time).utc().local().format("YYYY-MM-DD HH:mm:ss")} vis-selected vis-readonly`);
        if (line.length > 0) { line[0].classList.remove("vis-item"); }
        let time = this.state.time;
        let check = this.isIsoDate(this.state.time)
        if (!check) {
            time = (this.state.time).toISOString;
        }
        let data = this.props.fftData && this.props.fftData.FFTData.sort((a, b) => (new Date(a.time) - new Date(b.time)))
        let index = data.findIndex(x => x.time === time);
        if ((index === 0 || index === -1) && this.state.fftHighlightedData.length !== 0) {
            let len = this.state.fftHighlightedData.length - 1
            this.setState({ time: new Date(this.state.fftHighlightedData[len].time) }, () => {
                this.fetchData(this.state.fftHighlightedData[len].time)
            })
        }
        if (index !== -1 && index !== 0) {
            if (data[index - 1] && data[index - 1].time) {
                this.setState({ time: new Date(data[index - 1].time) }, () => {
                    this.fetchData(data[index - 1].time)
                })
            }
        }
        if (index - 1 === 0) {
            this.setState({ backArr: false, frontArr: true })
        }
        else {
            if (index === -1 && this.props.fftData && this.props.fftData.FFTData.length !== 0) {
                let len = this.props.fftData.FFTData.length - 1;
                this.setState({ time: new Date(data[len].time), backArr: true, frontArr: false }, () => {
                    this.fetchData(data[len].time)
                })
            } else {
                this.setState({ backArr: true, frontArr: true })
            }
        }
    }
    frontArrowClick = () => {
        this.setState({ odrfftIconShow: false })
        let x = document.getElementsByClassName("mystyle");
        // If it exists, remove it.
        if (x.length > 0) { x[0].classList.remove("mystyle"); }
        let line = document.getElementsByClassName(`vis-item vis-line fft ${moment(this.state.time).utc().local().format("YYYY-MM-DD HH:mm:ss")} vis-selected vis-readonly`);
        if (line.length > 0) { line[0].classList.remove("vis-item"); }
        let time = this.state.time;
        let check = this.isIsoDate(this.state.time)
        if (!check) {
            time = (this.state.time).toISOString;
        }
        let data = this.props.fftData && this.props.fftData.FFTData.sort((a, b) => (new Date(a.time) - new Date(b.time)))
        let index = data.findIndex(x => x.time === time);
        if ((index === 0 || index === -1) && this.state.fftHighlightedData.length !== 0) {
            let len = this.state.fftHighlightedData.length - 1
            this.setState({ time: new Date(this.state.fftHighlightedData[len].time) }, () => {
                this.fetchData(this.state.fftHighlightedData[len].time)
            })
        }
        if ((index !== -1) && ((index + 1) !== (data.length - 1))) {
            if (data[index + 1] && data[index + 1].time) {
                this.setState({ time: new Date(data[index + 1].time) }, () => {
                    this.fetchData(data[index + 1].time)
                })
            }
        }
        if (((index + 1) === (data.length)) && this.state.addFFTData.length !== 0) {
            if (this.state.addFFTData[0] && this.state.addFFTData[0].time) {
                this.setState({ time: new Date(this.state.addFFTData[0].time), frontArr: false, backArr: true, forntArrowFlag: true }, () => {
                    this.fetchData(this.state.addFFTData[0].time)
                })
            }
        }
        if ((index + 1) === (data.length - 1) && this.state.addFFTData.length === 0) {
            if (data[data.length - 1] && data[data.length - 1].time) {
                this.setState({ time: new Date(data[data.length - 1].time), frontArr: false, backArr: true, forntArrowFlag: true }, () => {
                    this.fetchData(data[data.length - 1].time)
                })
            }

        }
        if ((index + 1) === (data.length - 1) && this.state.addFFTData.length !== 0) {
            if (data[data.length - 1] && data[data.length - 1].time) {
                this.setState({ time: new Date(data[data.length - 1].time), frontArr: true, backArr: true, forntArrowFlag: true }, () => {
                    this.fetchData(data[data.length - 1].time)
                })
            }

        } else {
            if (index === -1 && this.props.fftData && this.props.fftData.FFTData.length !== 0) {
                let len = 0;
                this.setState({ time: new Date(data[len].time), backArr: false, frontArr: true }, () => {
                    this.fetchData(data[len].time)
                })
            }
            this.setState({ frontArr: true, backArr: true })
        }
    }
    closeLoader = () => {
        clearTimeout(timerCall)
        this.chart = React.createRef();
        this.props.setLoaderState({ "value": false });
        this.setState({ isRetry: false, retry: null, sendCommand: true, loaderClose: false });
        this.setState({
            isNoDataFlag: true,
            time: '',
            acceFeature: false,
            fftVelFeature: false,
            fftAccefeature: false
        })
    }
    unitStoredinCache=()=>{
        let storageUnit=localStorage.getItem("unit");
        if(storageUnit=== null || storageUnit=== undefined || storageUnit=== "null"){
            storageUnit='CPM'
        }
        let unique;
        if(storageUnit ==='CPM'){
            unique={1:true};
        }else{
            unique={2:true};
        }
        this.setState({unit:storageUnit,activeItem:unique})
    }

     getFormatedDateTime(dateStr) {
        
        const locale = localStorage.getItem("lang") || "en";
        
        const date = new Date(dateStr);
        var options = {
            weekday: 'short',
            year: 'numeric',
            month: 'short',
            day: 'numeric',
            hour: '2-digit',
            minute: '2-digit',
            second: '2-digit',
            hour12: false,
            timeZone: 'Asia/Kolkata',
            timeZoneName: 'short'
        };
        const formattedDate = date.toLocaleString(locale, options);
        return `${formattedDate} (${this.props.t("India Standard Time")})`; 
    }
    render() {
        const row = this.props.widgetsData.widgetData.realTime;
        const { t } = this.props.props;
        return (
            <div className="realTime-panel display-text widgets-shadow" ref={this.props.reference}>
                <div className="widgets-heading widgets-heading-info">
                    <h2 className="display-text">{row && this.props.t(row.header)}</h2>
                    {this.state.loaderClose && <IconButton style={{ zIndex: 1500, alignItems: "center", position: "fixed", top: "41%", left: "51%" }} onClick={() => this.closeLoader()}>
                        <Close style={{ fontSize: 18 }} />
                    </IconButton>}
                    <span className="float-right">{this.props.deviceName}</span>
                </div>
                <div className="widgets-container">
                    {/* <div id="selector-Panel" className="selector-Panel last-range-wrp">
                        <label>{row.last}</label>
                        <Picky className="last-range"
                            value={this.state.range}
                            options={ranges}
                            onOpen={this.lastClick}
                            onChange={this.rangeChange}
                            open={this.state.lastOpen}
                            keepOpen={this.state.lastOpen}
                            valueKey="value"
                            labelKey="viewValue"
                            multiple={false}
                            includeSelectAll={false}
                            includeFilter={false}
                            dropdownHeight={400}
                            id="lastRange"
                        />
                    </div> */}
                    <div className="selector-Panel feature-range-wrp" style={{ width: '53%' }}>
                        <label>{row && this.props.t(row.feature)}</label>
                        <Picky className="feature-range"
                            value={this.state.selectedFeature}
                            options={this.state.featureList}
                            onChange={this.featureChange}
                            open={false}
                            keepOpen={this.state.featureOpen}
                            valueKey="value"
                            labelKey="viewValue"
                            multiple={false}
                            includeSelectAll={false}
                            includeFilter={false}
                            dropdownHeight={400}
                        />
                    </div>
                   {this.props && this.props.batteryFlag === false && <div className="selector-Panel get-btn-wrp">
                        <button className="get-btn" onClick={() => this.fetchData()}>{row && row.getBtn ? this.props.t(row.getBtn) : this.props.t("GET")}</button>
                    </div>
                    }
                    <div className="clearfix"></div>
                    <div className={'legends'}>

                        {this.state.fftAnnotationEnable && (this.state.fftFeature && this.state.acceFeature || this.state.fftVelFeature || this.state.fftAccefeature) ? <div>
                            <span>
                                <Checkbox
                                    checked={this.state.isShowAnnotation}
                                    onChange={() => (this.onClickCheckbox())}
                                    color="secondary"
                                />
                                {t("Show Annotation")}
                            </span>
                        </div> : null}

                        {this.state.fftFeature && this.state.acceFeature || this.state.fftVelFeature || this.state.fftAccefeature ? <div>
                            {t("Last FFT Data Time")}: {this.state.time !== '' ? this.getFormatedDateTime(this.state.time) : ''}
                        </div> : null}
                        {this.state.fftFeature && this.state.acceFeature ? <div>
                            <ul className="display-text">
                                <li><span >{t("RMS")}: {this.state.rawDataStatistics.RMS}</span></li>
                                <li><span >{t("Min")}: {this.state.rawDataStatistics.min}</span></li>
                                <li><span >{t("Max")}: {this.state.rawDataStatistics.max}</span></li>
                                <li><span >{t("Median")}: {this.state.rawDataStatistics.median}</span></li>
                                <li><span >{t("Mode")}: {this.state.rawDataStatistics.mode}</span></li>
                                <li><span >{t("Std Dev")}: {this.state.rawDataStatistics.stddev}</span></li>
                                <li><span >{t("Mean")}: {this.state.rawDataStatistics.mean}</span></li>
                            </ul>
                        </div> : null}
                        {this.state.showWaveFeature &&
                            <ul className="display-text">
                                <li><span >{t("RMS")}: {this.state.RMS}</span></li>
                                <li><span >{t("PK+")}: {this.state.PKplus}</span></li>
                                <li><span >{t("PK-")}: {this.state.PKminus}</span></li>
                                {this.state.crest != "" ? <li><span >{t("Crest")}: {this.state.crest}</span></li> : null}

                            </ul>}

                        {this.state.fftFeature && this.state.fftVelFeature || this.state.fftAccefeature ? <div>
                            <ul className="display-text">
                                {units.map((item, index) =>
                                    <li key={index} className="toggle"><span key={index}
                                        className={this.state.activeItem[item.id] ? 'text-blue-bg' : 'text-grey-bg'}
                                        onClick={(e) => this.toggleUnit(e, item.id, item.unit)}
                                    >{item.unit}</span></li>
                                )}
                                {this.state.fftFeature && this.state.fftVelFeature ?
                                    <span>

                                        <li><span className="text-vrms-bg">{t("VRMS")}: {this.state.VRMS}</span></li>
                                        {
                                            this.state.RPM ?
                                                <li><span className="text-freq-bg">{t("RPM")}: {this.state.RPM}</span></li>
                                                : null
                                        }
                                        {this.state.odrfftIconShow && <li><span className="tex-freq-grey-bg">{t("ODR FFT")}</span></li>}

                                        {this.state.annotable && <span><li><span className="text-fft-bg">{t("FFT")}</span></li>
                                            <li><span className="text-subharm-bg">{t("Subharmonics")}</span></li>
                                            <li><span className="text-harm-bg">{t("Harmonics")}</span></li>
                                            <li><span className="text-interharm-bg">{t("Interharmonics")}</span></li>
                                            <li><span className="text-freq-bg">{t("Fundamental Frequency")}</span></li>
                                        </span>
                                        }
                                    </span> :
                                    null
                                }
                                {this.state.fftFeature && this.state.fftAccefeature ?
                                    <> <li><span className="text-vrms-bg">{t("ARMS")}: {this.state.ARMS}</span></li>
                                        {/* <li><span className="tex-freq-red-bg">{t("Overall")}:{(this.state.ARMS / 0.707).toFixed(1)}</span></li> */}

                                    </>
                                    :
                                    null
                                }
                            </ul>

                        </div> : null}
                        {(this.state.fftFeature || this.state.acceFeature || this.state.fftVelFeature || this.state.fftAccefeature || this.state.showWaveFeature || this.state.showSpecFeature) && <div>
                            {this.state.backArr && <span style={{ cursor: "pointer" }} onClick={() => this.backArrowClick()}><ArrowBack style={{ fontSize: 22 }} /></span>}
                            {this.state.frontArr && <span style={{ cursor: "pointer", float: "right" }} onClick={() => this.frontArrowClick()}><ArrowForwardIcon style={{ fontSize: 22 }} /></span>}
                        </div>
                        }
                    </div>
                </div>
                {!this.state.isNoDataFlag ?
                    <div className="highChart-wrp1">
                        <HighchartsReact
                            style={{ height: '100%' }}
                            ref={this.chart}
                            highcharts={Highcharts}
                            constructorType={"chart"}
                            options={this.state.options}
                        />
                    </div> :
                    <div style={{ padding: '33px', color: 'red', margin: '102px 70px', fontWeight: "bold", fontSize: "12px" }}>
                        {/* TODO!: Remove this when multilingua is reverted */}
                        {this.props.t("No data received, please check the device's network connectivity. (Note- the min. requirement to get data reliably is 'upload speed 250 Kbps & WiFi signal strength -60 dBm' )")}
                        {/* {`No data received, please check the device's network connectivity. (Note- the min. requirement to get data reliably is 'upload speed: 250 Kbps & WiFi signal strength: -60 dBm' )`} */}
                    </div>
                }
            </div>
        )
    }
}

const mapSatateToProps = state => {
    return {
        fftSelectedData: state.fftSelectedDataReducer,
        fftData: state.fftDataReducer,
        updateFftData: state.fftUpdateReducer
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        setLoaderState: (value) => (dispatch(actionCreators.setLoaderState(value))),
        updateFftData: (value) => (dispatch(actionCreators.updateFftData(value))),
        setFftSelectedData: (value) => (dispatch((actionCreators.setFftSelectedData(value)))),
    }
}

// export default RealTimePanel
export default withTranslation()(connect(mapSatateToProps, mapDispatchToProps)(RealTimePanel));

function isEmpty(obj) {
    for (var key in obj) {
        if (obj.hasOwnProperty(key))
            return false;
    }

    return true;
}