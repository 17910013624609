import * as actionTypes from '../actions/actionTypes';

export const userDataReducer = (state = {}, action) => {
    switch (action.type) {
        case actionTypes.SAVE_USER_DATA:
            return Object.assign({}, { ...state }, action.payload)

        case actionTypes.SAVE_USER_AUTHENTICATION:
            let properties = Object.getOwnPropertyNames(state);
            let selectedTab = properties.find(element => element === "selectedTab");
            if (!selectedTab) {
                action.payload.admin ? action.payload.selectedTab = 0 : action.payload.selectedTab = 2;
            }
            return Object.assign({}, state, action.payload)

        case actionTypes.SAVE_LOGO:
            const logo = { "logo": action.payload }
            return Object.assign({}, state, logo)

        case actionTypes.UNAUTHORIZED_USER:
            return Object.assign({}, action.payload)

        case actionTypes.USER_LOGOUT:
            return Object.assign({}, action.payload)

        case actionTypes.UPDATE_SELECTED_TAB:
            return Object.assign({}, state, action.payload)

        case actionTypes.SAVE_PARENT_ORGANIZATION:
            return Object.assign({}, state, action.payload)

        case actionTypes.SAVE_CURRENT_DELETED_ORGANIZATION:
            return Object.assign({}, state, action.payload)

        case actionTypes.REMOVE_CURRENT_DELETED_ORGANIZATION:
            return Object.assign({}, state, { currentDeletedOrganization: null });

        case actionTypes.UPDATE_SCOPE_SELECTOR:
            if (action.payload.scope === "OrganizationSelector") {
                return Object.assign({}, state, { selectedOrganization: action.payload.selection, plantName : action.payload.plantName })
            }
            else if (action.payload.scope === "PlantSelector") {
                return Object.assign({}, state, { selectedPlant: action.payload.selection, permission: action.payload.permission, plantName : action.payload.plantName })
            }
            else if (action.payload.scope === "Production") {
                return Object.assign({}, state, { selectedPlant: action.payload.selection })
            }
            
            else if (action.payload.scope === "Monitoring") {
                return Object.assign({}, state, { selectedPlant: action.payload.selection, permission: action.payload.permission, plantName : action.payload.plantName })
            }
            break;

        default: return state
    }
}