import * as actionTypes from '../actions/actionTypes';
 
const initialState = {
    endTime: "",
    monitorId: 0,
    startTime: "",
    summaryInterval: 0,
    summaryUnit: '',
    alarmInfo: []
}

export const alarmWatchlistReducer = (state={}, action) => {
    switch(action.type) {
        case actionTypes.ALARM_WATCHLIST_DATA:{
            if (isEmpty(action.payload)) {
                return Object.assign({}, state, initialState)  
            }

            return Object.assign({}, state, action.payload)  
        }

        default: return state
    }
}

function isEmpty(obj) {
    for (var key in obj) {
        if (obj.hasOwnProperty(key))
            return false;
    }
    return true;
}