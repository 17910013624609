import { cloneDeep } from "lodash";
import * as actionTypes from "../actions/actionTypes";

const initialState = {
  externalFeatures: [],
  externalFeaturesGroupIds: [],
  externalFeaturesTrendHistoryData: {},
  isLoadingExternalFeaturesTrendHistoryData: false,
};

const externalFeaturesReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.SET_EXTERNAL_FEATURES:
      return Object.assign({}, state, {
        externalFeatures: cloneDeep(action.payload),
      });
    case actionTypes.SET_EXTERNAL_FEATURES_GROUP_IDS:
      return Object.assign({}, state, {
        externalFeaturesGroupIds: cloneDeep(action.payload),
      });
    case actionTypes.SET_EXTERNAL_FEATURES_TREND_HISTORY_DATA:
      return Object.assign({}, state, {
        externalFeaturesTrendHistoryData: cloneDeep(action.payload),
      });
    case actionTypes.SET_IS_LOADING_EXTERNAL_FEATURES_TREND_HISTORY_DATA:
      return Object.assign({}, state, {
        isLoadingExternalFeaturesTrendHistoryData: action.payload,
      });
    default:
      return state;
  }
};

export default externalFeaturesReducer;