import React, { Component } from 'react'
import { Chart } from "react-google-charts";
import { withTranslation } from "react-i18next";

class QualityDataContainer extends Component {
    constructor(props){
        super(props);
        this.state={
            data :[],
            isNoData : false
        }
    }  

    componentDidMount(){
        this.drawChartForByReason(this.props.qualityData1.qualityData.byReasonGraphData);
    }
    componentDidUpdate(prevProps){
        if(prevProps.qualityValue !== this.props.qualityValue || prevProps.qualityData !== this.props.qualityData || prevProps.qualityData1 !== this.props.qualityData1){
            if(parseInt(this.props.qualityValue) == 1){
                this.drawChartForByReason(this.props.qualityData1.qualityData.byReasonGraphData);
               // this.drawChartByReason(this.props.qualityData);
            }
            if(parseInt(this.props.qualityValue) === 2){
                this.drawChartByNumber(this.props.qualityData);
            }
            if(parseInt(this.props.qualityValue) === 3){
                this.drawChartForByDate(this.props.qualityData1.qualityData.byDateRangeGraphData);
            }
        }
    }

    drawChartForByReason = (qualityData) => {
        if(qualityData.length > 0 ){
            this.setState({ isNoData : false })
            qualityData.sort(function (a, b) {
                return b.count - a.count;
            });
        } else{
            this.setState({ isNoData : true })
            return
        }
        
        let data = [["Name", "Value0",{type: "string", role: "tooltip"}, "Cumulative Frequency", {type: "string", role: "tooltip"}]];   
        let temp= 0;

        if(qualityData.length > 0 ){
            qualityData.forEach(ele=>{
                temp += ele.count;
                data.push([ele.reason, ele.count, `${ele.reason} : ${ele.count}`, temp, `Cumulative Part Count ${temp}`])
            })
        }
        
        
        this.setState({
            data: data
        })
    }

    drawChartForByDate = (qualityData) => {
        if(qualityData.length > 0 ){
            this.setState({ isNoData : false })
            qualityData.sort(function (a, b) {
                return b.count - a.count;
            });
        } else{
            this.setState({ isNoData : true })
            return
        }
       
        let data = [["Name", "Value0",{type: "string", role: "tooltip"}]];
        let temp= 0;

        if(qualityData.length > 0 ){
            qualityData.forEach(ele=>{
                temp += ele.count;
                let ele_date = ele.date.split("T")[0];
                data.push([ele_date, ele.count,`Rejected Part Count ${temp}`])
            })
        }
        
        this.setState({
            data: data
        })
    }

    groupBy = (list, keyGetter) => {
        const map = new Map();
        list.forEach((item) => {
             const key = keyGetter(item);
             const collection = map.get(key);
             if (!collection) {
                 map.set(key, [item]);
             } else {
                 collection.push(item);
             }
        });
        return map;
    }

    drawChartByReason = (qualityData) => {
        const grouped = this.groupBy(qualityData, ele => ele.reasonForRejection);
        
        let data = [["Name", "Value0",{type: "string", role: "tooltip"}, "Cumulative Frequency", {type: "string", role: "tooltip"}]];
        let arr= [];
        for (const [key, value] of grouped.entries()) {
            let total = 0;
            value.forEach(ele=>{
                total += parseFloat(ele.numberOfPartsRejected);
            })
            arr.push({key,total});
        }
        let temp= 0;
        arr.forEach(ele=>{
            temp += ele.total;
            data.push([ele.key, ele.total, `${ele.key} : ${ele.total}`, temp, `Cumulative Part Count ${temp}`])
        })
        
        this.setState({
            data: data
        })
    }

    drawChartByNumber = (qualityData) => {
        const grouped = this.groupBy(qualityData, ele => ele.numberOfPartsRejected);
        
        let data = [["Name", "Value0",{type: "string", role: "tooltip"}, "Cumulative Frequency", {type: "string", role: "tooltip"}]];
        let arr= [];
        for (const [key, value] of grouped.entries()) {
            let total = value.length;
            arr.push({key,total});
        }
        let temp= 0;
        arr.forEach(ele=>{
            temp += ele.total;
            data.push([ele.key, ele.total, `${ele.key} : ${ele.total}`, temp, `Cumulative Count ${temp}`])
        })
        
        this.setState({
            data: data
        })
    }

    drawChartByDate = (qualityData) => {
        const grouped = this.groupBy(qualityData, ele => ele.startTime);
        
        let data = [["Name", "Value0",{type: "string", role: "tooltip"}]];
        let arr= [];
        for (const [key, value] of grouped.entries()) {
            let total = 0;
            value.forEach(ele=>{
                total += parseFloat(ele.numberOfPartsRejected);
            })
            arr.push({key,total});
        }
        arr.forEach(ele=>{
            data.push([ele.key, ele.total, `Rejected Part Count : ${ele.total}`])
        })
        
        this.setState({
            data: data
        })
    }
    
    render() {
        const {t}=this.props;
        return (
            <div className="QualityData-cntr display-text"> 
            {
                this.state.isNoData ? <p>{t("Chart cannot be drawn, as there's no data")}</p> : null
            }
               <Chart
                    width={'500px'}
                    height={'300px'}
                    graph_id="ComboChart"
                    chartType="ComboChart"
                    loader={<div>{t("Loading Chart")}</div>}
                    data={this.state.data}
                    options={{
                        isStacked: true,
                        // vAxis: {title: '', ticks: vticks},
                        vAxis: {title: ''},
                        hAxis: {title: ''},
                        legend: 'none',
                        seriesType: 'bars',
                        series: [ {},
                            {type: "line"}]
                      }}
                    rootProps={{ 'data-testid': '1' }}
                />
            </div>
        )
    }
}
  
export default withTranslation()(QualityDataContainer);