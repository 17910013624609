import React from 'react';
import { Button, Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions } from '@material-ui/core';
import { withTranslation } from "react-i18next";

const DeleteUserConfirmationModal = (props) => {
    return (
        <Dialog
            open={props.openPromptModal}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            disableBackdropClick={true}
        >
            <DialogTitle id="alert-dialog-title">{`${props.t("Delete User/s?")}`}</DialogTitle>
            <DialogContent>                
                <DialogContentText id="alert-dialog-description" style={{ color: "red" }}>
                    {props.t("The selected entity/s will be deleted, and mapping with all other entities will be deleted. Do you want to continue?")}
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={() => props.handlePromptModal("disagree")} color="primary">
                    {props.t("Cancel")}
                </Button>
                <Button onClick={() => props.handlePromptModal("agree")} color="primary">
                    {`${props.t("Delete")}`}
                </Button>
            </DialogActions>
        </Dialog>
    )
}

export default withTranslation()(DeleteUserConfirmationModal);