import React, { Component, Fragment } from 'react';
import {
    TextField,
    Select,
    ExpansionPanel,
    ExpansionPanelSummary,
    Typography,
    ExpansionPanelDetails,
    withStyles,
    InputLabel,
    OutlinedInput,
    Divider,
    Checkbox,
    Dialog,
    DialogContent,
    DialogActions,
    Button,
    Tooltip,
    MenuItem
} from '@material-ui/core';
import {
    ExpandMore,
    Delete,
    Edit,
    CheckBox,
    AddBox,
    Close
} from '@material-ui/icons';

import AsyncPaginate from "react-select-async-paginate";

import { config } from '../configuration';
import { withTranslation } from "react-i18next";
import { getOidcAccessToken } from '../utils/oidcStorageUtils';

let server_url = config.url.server_url;

const styles = theme => ({
    dropdownContent:{
        display:'flex',
        justifyContent:'space-between',
        minWidth: '410px'

    },
    dynamicDropdown:{
        width: '60% !important',
        left: '12px'
    },
    expansionPanelDetails: {
        display: 'block'
    },
    header2: {
        backgroundColor: "#f2f3f8",
        padding: 5,
        display: 'flex',
        justifyContent: 'space-between'
    },
    inputFieldDiv: {
        display: "inline-flex",
        justifyContent: "space-between",
        width: '50%',
        paddingTop: '10px',
        height: 90,
        'align-items': 'baseline',
        backgroundColor: '#fff'
    },
    textField: {
        width: '60%',
        marginRight: 20
    },
    textFieldLabel: {
        color: 'rgba(0, 0, 0, 0.4000000059604645)',
        'letter-spacing': 0.5,
        'font-weight': 500,
        fontSize: "14px",
        lineHeight: 1.5,
        width: '30%',
        marginLeft: 20,
    },
    outlinedInput: {
        padding: 14
    },
    expansionPanelSummary: {
        alignItems: 'center'
    },
    deleteIcon: {
        'font-size': 18,
        cursor: 'pointer',
        color: 'rgba(221, 44, 0, 0.5)',
        '&:hover': {
            color: 'rgba(221, 44, 0, 1.0)'
        },
        marginLeft: 10
    },
    editIcon: {
        'font-size': 18,
        cursor: 'pointer',
        color: 'rgba(0, 200, 83, 0.5)',
        '&:hover': {
            color: 'rgba(0, 200, 83, 1.0)'
        },
        marginLeft: 10
    },
    addIcon: {
        'font-size': 31,
        cursor: 'pointer',
        color: 'rgba(0, 200, 83, 0.5)',
        '&:hover': {
            color: 'rgba(0, 200, 83, 1.0)'
        },
        marginRight: 23
    },
    settingsModal: {
        'min-width': 630,
        'max-height': 589,
        'min-height': 250
    },
    dialogTitle: {
        display: 'flex',
        'justify-content': 'space-between',
        'align-items': 'center',
        padding: '10px 24px'
    },
    addPlantsButton: {
        float: 'right',
        color: '#42D784',
        marginLeft: 10
    },
    cloneButtonText: {
        padding: '0 8px'
    },
    icon: {
        color: "#9e9e9e",
        '& svg': {
            'font-size': 20,
             'margin-top': 5
        }
    }, 
})

class WidgetComponents extends Component {

    state = {
        sourceDay: "",
        daysToCloneFor: [],
        openModal: false,
        newCondition: "",
        levelIndicator: [],
        openCloningModal: false,
        disableApplyButton: true,
        openNewScheduleModal: false,
        newShift: null,
        addNewShiftFor: "",
        addNewShiftOrBreak: "",
        disableNewShiftApplyButton: true,
        value: '',
        options : [],
        openFaultDialog: false,
        openAddFaultDialog: false,
        bearingTypeNumber : 0,
        openDeleteFaultDialog: false,
        faultList: [],
        openRemoveFaultDialog : false
    }

    addNewShiftOrBreak = () => {
        let { addNewShiftFor: day, addNewShiftOrBreak: shiftOrBreak, newShift: data } = this.state;
        this.props.addNewShiftOrBreak(day, shiftOrBreak, data);

        this.setState({
            addNewShiftFor: "",
            addNewShiftOrBreak: "",
            openNewScheduleModal: false,
            newShift: this.createDefaultShiftObject()
        })
    }

    openNewScheduleModal = (day, shiftOrBreak) => {                
        this.setState({
            openNewScheduleModal: true,
            addNewShiftFor: day.split(":")[0],
            addNewShiftOrBreak: shiftOrBreak
        })
    }

    createDefaultShiftObject = () => {
        let defaultObject = {
            "deletable": false,
            "deleted": false,
            "disabled": false,
            "editable": false,
            "editing": false,
            "error": false,
            "errorMessage": null,
            "key": "",
            "label": "",
            "level": 4,
            "maxValue": 0,
            "minValue": 0,
            "options": null,
            "parameterDatatype": "integer",
            "stepValue": 0,
            "type": "Double Number Scroll Box Heading",
            "value": null,
            bearingTypeNumber: null
        };

        let shiftObject = {};
        let startTimeObject = {};
        let endTimeObject = {};
        let hoursObject = {};
        let minutesObject = {};

        shiftObject = {
            ...defaultObject,
            "children": []
        };
        startTimeObject = {
            ...defaultObject,
            "key": "StartTime",
            "label": "Start Time",
            "level": 5,
            "type": "Double Number Scroll Box",
            "children": []
        };
        endTimeObject = {
            ...defaultObject,
            "key": "EndTime",
            "label": "End Time",
            "level": 5,
            "type": "Double Number Scroll Box",
            "children": []
        };
        hoursObject = {
            ...defaultObject,
            "key": "Hours",
            "label": "Hours",
            "level": 6,
            "type": "Double Number Scroll Box Helper",
            "children": null,
            "minValue": 0,
            "maxValue": 23,
            "stepValue": 1,
            "value": 0
        };
        minutesObject = {
            ...defaultObject,
            "key": "Minutes",
            "label": "Minutes",
            "level": 6,
            "type": "Double Number Scroll Box Helper",
            "children": null,
            "minValue": 0,
            "maxValue": 59,
            "stepValue": 5,
            "value": 0
        };

        startTimeObject["children"].push(hoursObject);
        startTimeObject["children"].push(minutesObject);
        endTimeObject["children"].push(hoursObject);
        endTimeObject["children"].push(minutesObject);
        shiftObject["children"].push(startTimeObject);
        shiftObject["children"].push(endTimeObject);

        return shiftObject;
    }

    closeNewScheduleModal = () => {
        this.setState({
            openNewScheduleModal: false,
            newShift: this.createDefaultShiftObject()
        })
    }

    componentDidMount() {
        if (this.props.entityName === "schedules") {
            this.setState({
                newShift: this.createDefaultShiftObject()
            })
        }
    }
//handles edit in widget
    handleEdit = (item) => {
        let levelIndicator = [];
        for (let key in item) {
            if (key.startsWith("level_")) {
                levelIndicator.push(item[key])
            }
        }
        this.props.editCondition(levelIndicator);
    }
// handle delete in widget
    handleDelete = (item) => {
        let levelIndicator = [];
        for (let key in item) {
            if (key.startsWith("level_")) {
                levelIndicator.push(item[key])
            }
        }
        this.props.deleteCondition(levelIndicator);
    }
// handle open model 
    openModal = (event, levelIndicator) => {
        event.stopPropagation();
        this.setState({
            openModal: true,
            levelIndicator
        })
    }

    openAddFaultDialog = (event) => {
        event.stopPropagation();
        fetch(`${server_url}/getFaultList/1`, {
            method: 'GET',
            headers: {
                "Authorization": `Bearer ${getOidcAccessToken()}`,
                "Accept-Language": localStorage.getItem("lang") || "en"
            }
        })
            .then(response => response.json())
            .then(data => {
                this.setState({
                    faultList: data,
                    openAddFaultDialog: true
                })
            });

    }

    closeAddFaultDialog = (event) => {
        this.setState({
            openAddFaultDialog: false
        })
    }

    closeModal = (event) => {
        event.stopPropagation();
        this.setState({
            openModal: false,
            newCondition: ""
        })
    }

    addNewCondition = () => {
        if (this.state.newCondition) {
            this.props.addNewCondition(this.state.newCondition, this.state.levelIndicator);
        }

        this.setState({
            levelIndicator: [],
            openModal: false,
            newCondition: ""
        })
    }

    updateStateForKey = (value, levelIndicator, forSave) => {
        this.props.updateStateForKey(value, levelIndicator, forSave)
    }
// handle the update the value in Textbox,Number Box,Number scroll box ,Double Number Scroll Box Helper
    updateStateForValue = (value, levelIndicator, item) => {
        let { minValue, maxValue, parameterDatatype } = item;
        let error = false;
        let errorMessage = "";

        switch (item.type) {
            case "Textbox":
                if(item.key === "unit"){
                    var valid = (value.match(/^[a-zA-Z\d\-_\/\s]+$/));
                    if(value.length > 20){
                        error = true;
                        errorMessage = this.props.t("Unit length should not be more than 20 character.");
                    }else if(valid){
                        value = value;
                    }else{
                        error = true;
                        errorMessage = this.props.t("Enter valid unit.");
                    }
                }
                else{
                    value = value;
                }

                break;
            case "Double Number Box":
                if (isNaN(value) || value === "") {
                    error = true;
                    // errorMessage = `${parameterDatatype}: ${minValue} - ${maxValue}`;
                    errorMessage = this.props.t("Enter valid number.");
                }
                else {
                        if (parameterDatatype === "double") {
                            var valid = (value.match(/^-?\d*(\.\d+)?$/));
                            if (valid) {
                                if (parseFloat(value) >= minValue && parseFloat(value) <= maxValue) {
                                    error = false;
                                    errorMessage = "";
                                }else if(parseFloat(value) < minValue){
                                    error = true;
                                    errorMessage = `${this.props.t("Enter a value greater than or equal to")} ${minValue}`;
                                }else{
                                    error = true;
                                    errorMessage = `${this.props.t("Enter a value less than or equal to")} ${maxValue}`;
                                }
                            }
                            else {
                                error = true;
                                errorMessage = this.props.t("Enter valid number.");
                            }
                        }
                }
                break;
            case "Number Box":
                // if (isNaN(value) || value === "" || value < minValue || value > maxValue) {
                //     error = true;
                //     // errorMessage = `${parameterDatatype}: ${minValue} - ${maxValue}`;
                //     errorMessage = this.props.t("Enter integer value.");
                // }
                // else {
                //     if (value >= minValue && value <= maxValue) {
                //         if (parameterDatatype === "integer") {
                //             if (Number.isInteger(Number(value))) {
                //                 error = false;
                //                 errorMessage = "";
                //             }
                //             else {
                //                 error = true;
                //                 errorMessage = this.props.t("Enter integer value.");
                //             }
                //         }
                //     }
                // }
                let isInteger = Number.isInteger(Number(value));
                if (isNaN(value) || value === "" || !isInteger) {
                    error = true;
                    // errorMessage = `${parameterDatatype}: ${minValue} - ${maxValue}`;
                    errorMessage = this.props.t("Enter valid number.");
                }
                else {
                    if (parameterDatatype === "integer") {
                        var valid = (value.match(/^-?\d*(\.\d+)?$/));
                        if (valid) {
                            if (parseInt(value) >= minValue && parseInt(value) <= maxValue) {
                                error = false;
                                errorMessage = "";
                            } else if (parseInt(value) < minValue) {
                                error = true;
                                errorMessage = `${this.props.t("Enter a value greater than or equal to")} ${minValue}`;
                            } else {
                                error = true;
                                errorMessage = `${this.props.t("Enter a value less than or equal to")} ${maxValue}`;
                            }
                        }
                        else {
                            error = true;
                            errorMessage = this.props.t("Enter valid number.");
                        }
                    }
                }
                break;
            case "Number Scroll Box":
                if (isNaN(value) || value === "" || value < minValue || value > maxValue) {
                    error = true;
                    errorMessage = this.props.t("Enter integer value.");
                }
                else {
                    if (value >= minValue && value <= maxValue) {
                        if (parameterDatatype === "integer") {
                            if (Number.isInteger(Number(value))) {
                                error = false;
                                errorMessage = "";
                            }
                            else {
                                error = true;
                                errorMessage = this.props.t("Enter integer value.");
                            }
                        }
                    }
                }
                break;
            case "Double Number Scroll Box Helper":
                if (isNaN(value) || value === "" || value < minValue || value > maxValue) {
                    error = true;
                    errorMessage = `${this.props.t("Enter integer b/w")} ${minValue} - ${maxValue}`;
                }
                else {
                    if (value >= minValue && value <= maxValue) {
                        if (parameterDatatype === "integer") {
                            if (Number.isInteger(Number(value))) {
                                error = false;
                                errorMessage = "";
                            }
                            else {
                                error = true;
                                errorMessage = `${this.props.t("Enter integer b/w")} ${minValue} - ${maxValue}`;
                            }
                        }
                    }
                }
                break;
            default: console.log("dafault case");
        }
        this.props.updateStateForValue(value, levelIndicator, error, errorMessage);
    }
// handle the mouse over event
    handleMouseOver = (key) => {
        this.setState({
            sourceDay: key
        })
    }
// handle the clone model view
    openCloningModal = (event) => {
        event.stopPropagation();
        this.setState({
            openCloningModal: true
        })
    }
// handle the closing of clone model
    closeCloningModal = () => {
        let daysToCloneFor = this.state.daysToCloneFor.map(item => {
            return ({
                "day": item.day,
                "checked": false
            })
        })

        this.setState({
            daysToCloneFor,
            sourceDay: "",
            openCloningModal: false,
            disableApplyButton: true
        })
    }
// handle the days in clone model
    getDaysToCloneFor = () => {
        let { sourceDay } = this.state;
        let disabledFontColor = "#c1c1c1";

        return (
            this.state.daysToCloneFor.map(item => {
                return (
                    <div key={item.day}>
                        <div style={{ padding: '10px 0' }}>
                            <div style={{ display: 'flex', alignItems: 'center', padding: '0 16px' }}>
                                <Checkbox
                                    checked={item.checked}
                                    onChange={(event, checked) => this.daysToCloneFor(event, checked)}
                                    value={item.day}
                                    inputProps={{
                                        'aria-label': 'primary checkbox',
                                    }}
                                    disabled={item.day === sourceDay}
                                />
                                <Typography variant={"subtitle1"} style={{ paddingLeft: 8, color: item.day === sourceDay ? disabledFontColor : 'inherit' }}>{item.day}</Typography>
                            </div>
                        </div>
                        <Divider />
                    </div>
                )
            })
        )
    }

    daysToCloneFor = (event, checked) => {
        let daysToCloneFor = this.state.daysToCloneFor.map(item => {
            if (item.day === event.target.value) {
                return ({
                    "day": item.day,
                    "checked": checked
                })
            }
            else {
                return item;
            }
        })

        this.setState({
            daysToCloneFor,
            disableApplyButton: false
        })
    }
// handle clone of schedule
    cloneSchedule = () => {
        let specs = JSON.parse(JSON.stringify(this.props.jsonData));
        let { sourceDay } = this.state;
        if (sourceDay) {
            let sourceDaySchedule = specs[0].children.find(item => item.key === sourceDay);
            this.state.daysToCloneFor.map(item => {
                if (item.checked) {
                    specs[0].children.find(item1 => {
                        if (item1.key === item.day) {
                            item1.children = JSON.parse(JSON.stringify(sourceDaySchedule.children));
                        }
                    });
                }
            })
            this.props.cloneSchedule(specs);

            let daysToCloneFor = this.state.daysToCloneFor.map(item => {
                return ({
                    "day": item.day,
                    "checked": false
                })
            })

            this.setState({
                daysToCloneFor,
                openCloningModal: false,
                sourceDay: ""
            })
        }
    }
// provide the DoubleNumberScrollBox in UI
    getDoubleNumberScrollBox = (item) => {
        let levelIndicator = [];
        for (let key in item) {
            if (key.startsWith("level_")) {
                levelIndicator.push(item[key])
            }
        }
        levelIndicator.push(item.key);

        return (
            <div className={this.props.classes.inputFieldDiv} key={item.key} style={{ width: '40%' }}>
                <InputLabel className={this.props.classes.textFieldLabel}>{item.key}</InputLabel>
                {
                    item.children.map(nextItem => {
                        return (
                            <TextField
                                key={nextItem.key}
                                id={nextItem.key}
                                name={nextItem.key}
                                variant="outlined"
                                type="number"
                                className={this.props.classes.textField}
                                style={{ width: '25%' }}
                                onChange={(event) => this.updateStateForValue(event.target.value, levelIndicator.concat([nextItem.key]), nextItem)}
                                value={nextItem.value}
                                //error={nextItem.deleted}
                                //helperText={nextItem.key}
                                error={nextItem.error || nextItem.deleted}
                                helperText={nextItem.errorMessage ? nextItem.errorMessage : nextItem.key}
                                InputProps={{
                                    classes: {
                                        input: this.props.classes.outlinedInput
                                    }
                                }}
                                inputProps={{
                                    min: nextItem.minValue,
                                    max: nextItem.maxValue,
                                    step: nextItem.stepValue
                                }}
                            />
                        )
                    })
                }
            </div>
        )
    }
//provide the dropdown view in UI
    getDropdown = (item) => {
        let levelIndicator = [];
        for (let key in item) {
            if (key.startsWith("level_")) {
                levelIndicator.push(item[key])
            }
        }
        levelIndicator.push(item.key);
        let errorMessage = '';
        if(this.props.showErrorMessage && item.key === 'maximum_frequency'){
                errorMessage =  <div style={{marginLeft:20, color: 'red'}}>
                 {this.props.t("The device associated with this monitor does not support configurable Lines of Resolution and Maximum Frequency.")}
                 </div>
        }
        if(item.key === 'lines_of_resolution'){
            errorMessage =  <div style={{marginLeft:20, paddingTop: '10px',fontSize: '14px',wordWrap: 'break-word', color: 'black'}}>
             {this.props.t("Note: Lines of Resolution values marked")} <span style={{color: 'red'}}>*</span> {this.props.t("are only applicable for devices with firmware versions 3.0.0 and above.")}
             </div>
        }
        if(item.key == 'lines_of_resolution' && errorMessage != ''){
        return (
            <>
            <div className={this.props.classes.inputFieldDiv} key={item.key}>
                <div style={{display:'flex', flexDirection:'column'}}>
                <div style={{display:'inline-flex'}}>
                <InputLabel className={this.props.classes.textFieldLabel} style={{paddingTop : '8px'}}>{item.label}</InputLabel>
                <Select
                //  native
                 value={item.value.toString()}
                 onChange={(event) => this.updateStateForValue(event.target.value, levelIndicator, item)}
                 style={{left: '40px'}}
                 input={
                    <OutlinedInput
                        variant="outlined"
                        name="monitorTypeId"
                        id="language-native-simple"
                        labelWidth={0}
                        className={this.props.classes.textField}
                        error={item.deleted || item.error || item.value === ""}
                        classes={{ input: this.props.classes.outlinedInput }}
                    />
                 }
                >
                 {item.options !== null && item.options !== 'null' && item.options.length > 0 ?
                  item.options.map(option => (
                  <MenuItem key={option} value={option.toString()}>
                      {option === '3200' ? <div>{option}<span style={{color:'red', padding:0,fontSize: '16px'}}>*</span></div>: option}
                       </MenuItem>))
                       : <MenuItem key={""} value={""}></MenuItem>
                  }
                </Select>
                </div>
                <div>
                    {errorMessage !== '' ? errorMessage : null}
                </div>
                </div>
            </div>
            </>
        )
        }else if(item.key !== 'bearing_type'){
            return (<>
            <div className={this.props.classes.inputFieldDiv} key={item.key}>
                <InputLabel className={this.props.classes.textFieldLabel}>{item.label}</InputLabel>
                <Select
                    native
                    value={item.value.toString()}
                    onChange={(event) => this.updateStateForValue(event.target.value, levelIndicator, item)}
                    input={
                        <OutlinedInput
                            variant="outlined"
                            name="monitorTypeId"
                            id="language-native-simple"
                            labelWidth={0}
                            className={this.props.classes.textField}
                            error={item.deleted || item.error || item.value === ""}
                            classes={{ input: this.props.classes.outlinedInput }}
                        />
                    }
                >
                    {/* <option key={""} value={""}></option> */}
                    {item.options !== null && item.options !== 'null' && item.options.length> 0 ?
                        item.options.map(option => (<option key={option} value={option.toString()}>{option}
                        </option>))
                        : <option key={""} value={""}></option>
                    }
                </Select>
                </div>       
                 {errorMessage !== '' ? errorMessage : null}
                 </>)

        }else if(item.key === 'bearing_type'){
            return (
                <><div className={this.props.classes.inputFieldDiv} key={item.key}>
                    <InputLabel className={this.props.classes.textFieldLabel}>{item.label}</InputLabel>
                    {parseInt(item.value) !== this.state.value.value ? this.getBearingTypeLabel(item.value) : null}
                    <AsyncPaginate
                        className={this.props.classes.dynamicDropdown}
                        value={this.state.value}
                        loadOptions={this.loadOptions}
                        onChange={(value) => this.handleChange(value, levelIndicator, item)}
                        additional={{
                            page: 0,
                        }}
                    />
                    <div>
                        {/* { this.props.monitorCodeName == 'VELMONWITHFAULTS'  ?
                            <Tooltip  title={<span style={{ fontSize: "12px" }}>Assign Bearing Faults</span>}>
                                <SearchIcon className={this.props.classes.icon} onClick={this.getFaultSpecs} /> 
                            </Tooltip>
                        : null} */}
                         { this.props.monitorCodeName == 'VELMONWITHFAULTS' && this.state.bearingTypeNumber !== 0 ?
                            <Tooltip  title={<span style={{ fontSize: "12px" }}>Remove Bearing Faults</span>}>
                                <span className={this.props.classes.icon} style={{ fontSize: "14px", fontWeight: 'bold' }} onClick={() => this.removeBearingType(levelIndicator, item)}>X</span> 
                            </Tooltip>
                        : null} 
                    </div>
                </div>

                    {errorMessage !== '' ? errorMessage : null}

                </>
            )
        }
    }

    getBearingTypeLabel = (bearingTypeNumber) => {
        if (bearingTypeNumber != "0" || bearingTypeNumber!= 0) {
            fetch(`${server_url}/bearingtype/1/${bearingTypeNumber}`, {
                method: 'GET',
                headers: {
                    "Authorization": `Bearer ${getOidcAccessToken()}`,
                    "Accept-Language": localStorage.getItem("lang") || "en"
                }
            })
                .then(response => response.json())
                .then(data => {
                    let value = {value: data.id,
                    label: data.type}
                    this.setState({ value : value, bearingTypeNumber : data.id})
                });
        }else if(this.state.bearingTypeNumber != 0){
            this.setState({ value : '', bearingTypeNumber : 0})
        }
    }

    loadOptions = async (search, prevOptions,{page}) => {
        const options  = [];
        const response = await fetch(
          `${server_url}/searchbearingtype/1/${page}/20?bearingTypeString=${search}`,
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${getOidcAccessToken()}`,
              "Accept-Language": localStorage.getItem("lang") || "en",
            },
          }
        );
        const data = await response.json();
        data.content.forEach(el=>{
          options.push({
                value: el.id,
                label: el.label
              });
        })
        let filteredOptions;
        if (!search) {
          filteredOptions = options;
        } else {
          const searchLower = search.toLowerCase();
      
          filteredOptions = options.filter(({ label }) =>
            label.toLowerCase().includes(searchLower)
          );
        }
      
        const hasMore = data.last === false ? true : false; 
      
        return {
          options: filteredOptions,
          hasMore,
          additional: {
            page: page + 1,
          },
        };
      };

    getFaultSpecs = () =>{
        this.setState({
            openFaultDialog: true,
        });
    }

    removeBearingType = (levelIndicator,item) =>{
        this.setState({
            openRemoveFaultDialog :true,
            bearingTypeNumber: 0,
            value : ''
        });
        this.updateStateForValue(0, levelIndicator, item);
    }
    removeBearingFault = () => {
        this.props.updateSpecForFaultConifg(0);
        this.closeRemoveFaultDialog();
    }
    
    closeRemoveFaultDialog = ()=> {
        this.setState({
            openRemoveFaultDialog : false
        });
    } 

    handleOk =() =>{
        if(this.state.bearingTypeNumber === ""){
            this.handleCancel();
            // alert('Please select Bearing type.')
            this.props.showError(this.props.t("Please select Bearing type."), false);
        }else{
            this.setState({
                openFaultDialog: false
            },()=>{
                this.props.updateSpecForFaultConifg(this.state.bearingTypeNumber);
            })
        }
    }
    handleCancel =() =>{
        this.setState({
            openFaultDialog: false
        })
    }

    handleChange = (value, levelIndicator,item ) => {
        let conditions = this.props.jsonData.find(condition => condition.key === 'conditions');
        const featureType = 2;
        const count = conditions.children.filter((obj) => obj.featureType === featureType).length;
        if(count > 9){
            this.props.showError(this.props.t("More than 9 faults are configured hence cannot add bearing faults."), false);
        }else{
            if(this.props.monitorCodeName == 'VELMONWITHFAULTS'){
                this.setState({
                    openFaultDialog: true,
                });
            }
        }
        
        this.updateStateForValue(value.value, levelIndicator, item);
        this.setState({ value : value, bearingTypeNumber: value.value });
    }

// provide NumberScrollBox in UI
    getNumberScrollBox = (item) => {
        let levelIndicator = [];
        for (let key in item) {
            if (key.startsWith("level_")) {
                levelIndicator.push(item[key])
            }
        }
        levelIndicator.push(item.key);

        return (
            <div className={this.props.classes.inputFieldDiv} key={item.key}>
                <InputLabel className={this.props.classes.textFieldLabel}>{item.label}</InputLabel>
                <TextField
                    id={item.key}
                    name={item.key}
                    variant="outlined"
                    type="number"
                    className={this.props.classes.textField}
                    onChange={(event) => this.updateStateForValue(event.target.value, levelIndicator, item)}
                    value={item.value}
                    //error={item.deleted}
                    error={item.error || item.deleted}
                    helperText={item.errorMessage ? item.errorMessage : null}
                    InputProps={{
                        classes: {
                            input: this.props.classes.outlinedInput
                        }
                    }}
                    inputProps={{
                        min: item.minValue,
                        max: item.maxValue,
                        step: item.stepValue
                    }}
                />
            </div>
        )
    }
// provide NumberBox view in UI
    getNumberBox = (item) => {
        let levelIndicator = [];
        for (let key in item) {
            if (key.startsWith("level_")) {
                levelIndicator.push(item[key])
            }
        }
        levelIndicator.push(item.key);

        return (
            <div className={this.props.classes.inputFieldDiv} key={item.key}>
                <InputLabel className={this.props.classes.textFieldLabel}>{item.label}</InputLabel>
                <TextField
                    id={item.key}
                    name={item.key}
                    variant="outlined"
                    type="text"
                    className={this.props.classes.textField}
                    onChange={(event) => this.updateStateForValue(event.target.value, levelIndicator, item)}
                    value={item.value}
                    //error={item.deleted}
                    error={item.error || item.deleted}
                   // helperText={item.errorMessage ? item.errorMessage : null}
                   helperText={item.errorMessage ? item.errorMessage : item.helpText}//change
                    InputProps={{
                        classes: {
                            input: this.props.classes.outlinedInput
                        }
                    }}
                />
            </div>
        )
    }
    getDoubleNumberBox = (item) => {
        let levelIndicator = [];
        for (let key in item) {
            if (key.startsWith("level_")) {
                levelIndicator.push(item[key])
            }
        }
        levelIndicator.push(item.key);

        return (
            <div className={this.props.classes.inputFieldDiv} key={item.key}>
                <InputLabel className={this.props.classes.textFieldLabel}>{item.label}</InputLabel>
                <TextField
                    id={item.key}
                    name={item.key}
                    variant="outlined"
                    type="text"
                    className={this.props.classes.textField}
                    onChange={(event) => this.updateStateForValue(event.target.value, levelIndicator, item)}
                    value={item.value}
                    //error={item.deleted}
                    error={item.error || item.deleted}
                   // helperText={item.errorMessage ? item.errorMessage : null}
                   helperText={item.errorMessage ? item.errorMessage : item.helpText}//change
                    InputProps={{
                        classes: {
                            input: this.props.classes.outlinedInput
                        }
                    }}
                />
            </div>
        )
    }
// handle TextBox view in UI
    getTextbox = (item) => {
        let levelIndicator = [];
        for (let key in item) {
            if (key.startsWith("level_")) {
                levelIndicator.push(item[key])
            }
        }
        levelIndicator.push(item.key);

        return (
            <div className={this.props.classes.inputFieldDiv} key={item.key}>
                <InputLabel className={this.props.classes.textFieldLabel}>{item.label}</InputLabel>
                <TextField
                    id={item.key}
                    name={item.key}
                    variant="outlined"
                    type="text"
                    className={this.props.classes.textField}
                    onChange={(event) => this.updateStateForValue(event.target.value, levelIndicator, item)}
                    value={item.value}
                    error={item.deleted || item.error}
                    helperText={item.errorMessage ? item.errorMessage : null}
                    InputProps={{
                        classes: {
                            input: this.props.classes.outlinedInput
                        }
                    }}
                />
            </div>
        )
    }
// provide view for DoubleNumberScrollBoxHeading in UI
    getDoubleNumberScrollBoxHeading = (item, incomingIndex) => {
        let object = {};

        for (let key in item) {
            if (key.startsWith("level_")) {
                object[key] = item[key];
            }
        }
        object = JSON.parse(JSON.stringify(object));

        return (
            <div key={item.key}>
                <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', minHeight: 110, padding: 12 }}>
                    <Delete classes={{ root: this.props.classes.deleteIcon }}
                        style={{ marginBottom: 12 }}
                        onClick={() => (this.handleDelete(object))}
                    />
                    <Typography variant={"body1"} style={{ marginBottom: 12 }}>{item.key}</Typography>
                    {
                        (item.children && item.children.length > 0) ?
                            item.children.map((nextItem, index) => {
                                if (!nextItem.disabled) {
                                    object = JSON.parse(JSON.stringify(object));
                                    nextItem = JSON.parse(JSON.stringify(nextItem));
                                    object["level_" + item.level + "_parent"] = item.key + ":" + incomingIndex;
                                    switch (nextItem.type) {
                                        case "Heading":
                                            return this.getHeader(Object.assign(nextItem, object), index);
                                        case "Accordion":
                                            return this.getAccordion(Object.assign(nextItem, object), index);
                                        case "Subheading":
                                            return this.getHeader2(Object.assign(nextItem, object), index);
                                        case "Textbox":
                                            return this.getTextbox(Object.assign(nextItem, object), index);
                                        case "Number Box":
                                            return this.getNumberBox(Object.assign(nextItem, object), index);
                                        case "Double Number Box":
                                            return this.getDoubleNumberBox(Object.assign(nextItem, object), index);
                                        case "Number Scroll Box":
                                            return this.getNumberScrollBox(Object.assign(nextItem, object), index);
                                        case "Dropdown":
                                            return this.getDropdown(Object.assign(nextItem, object), index);
                                        case "Double Number Scroll Box Heading":
                                            return this.getDoubleNumberScrollBoxHeading(Object.assign(nextItem, object), index);
                                        case "Double Number Scroll Box":
                                            return this.getDoubleNumberScrollBox(Object.assign(nextItem, object), index);
                                    }
                                }
                            })
                            : null
                    }
                </div>
                <Divider />
            </div>
        )
    }

    getHeader2 = (item, incomingIndex) => {
        let object = {};
        let levelIndicator = [];

        for (let key in item) {
            if (key.startsWith("level_")) {
                object[key] = item[key];
                levelIndicator.push(item[key])
            }
        }
        levelIndicator.push(item.key);

        return (
            <div key={item.key}>
                <Typography className={this.props.classes.header2} variant={"subtitle2"}>
                    {
                        item.editing ?
                            <TextField
                                id="standard-bare"
                                value={item.label}
                                inputProps={{ 'aria-label': 'bare' }}
                                onChange={(event) => this.updateStateForKey(event.target.value, levelIndicator)}
                            />
                            : item.label
                    }
                    <div>
                        {/* {
                            this.props.entityName === "monitors" && item.editable ?
                                (item.editing ?
                                    <CheckBox
                                        classes={{ root: this.props.classes.editIcon }}
                                        onClick={(event) => this.updateStateForKey(null, levelIndicator, true)}
                                    />
                                    : <Edit classes={{ root: this.props.classes.editIcon }}
                                        onClick={() => (this.handleEdit(object))}
                                    />
                                )
                                : null
                        }
                        {
                            this.props.entityName === "monitors" && item.deletable ?
                                <Delete classes={{ root: this.props.classes.deleteIcon }}
                                    onClick={() => (this.handleDelete(object))}
                                />
                                : null
                        } */}
                        {
                            this.props.entityName === "schedules" ?
                                <AddBox classes={{ root: this.props.classes.editIcon }}
                                    onClick={(event) => (this.openNewScheduleModal(object.level_2_parent, item.key))}
                                />
                                : null
                        }
                    </div>
                </Typography>
                {
                    (item.children && item.children.length > 0) ?
                        item.children.map((nextItem, index) => {
                            if (!nextItem.disabled) {
                                object = JSON.parse(JSON.stringify(object));
                                nextItem = JSON.parse(JSON.stringify(nextItem));
                                object["level_" + item.level + "_parent"] = item.key + ":" + incomingIndex;
                                switch (nextItem.type) {
                                    case "Heading":
                                        return this.getHeader(Object.assign(nextItem, object), index);
                                    case "Accordion":
                                        return this.getAccordion(Object.assign(nextItem, object), index);
                                    case "Subheading":
                                        return this.getHeader2(Object.assign(nextItem, object), index);
                                    case "Textbox":
                                        return this.getTextbox(Object.assign(nextItem, object), index);
                                    case "Number Box":
                                        return this.getNumberBox(Object.assign(nextItem, object), index);
                                    case "Double Number Box":
                                        return this.getDoubleNumberBox(Object.assign(nextItem, object), index);
                                    case "Number Scroll Box":
                                        return this.getNumberScrollBox(Object.assign(nextItem, object), index);
                                    case "Dropdown":
                                        return this.getDropdown(Object.assign(nextItem, object), index);
                                    case "Double Number Scroll Box Heading":
                                        return this.getDoubleNumberScrollBoxHeading(Object.assign(nextItem, object), index);
                                    case "Double Number Scroll Box":
                                        return this.getDoubleNumberScrollBox(Object.assign(nextItem, object), index);
                                }
                            }
                        })
                        : null
                }
            </div>
        )
    }
// handle view of Accordion in UI
    getAccordion = (item, incomingIndex) => {
        let object = {};
        let levelIndicator = [];

        for (let key in item) {
            if (key.startsWith("level_")) {
                object[key] = item[key];
                levelIndicator.push(item[key]);
            }
        }
        levelIndicator.push(item.key);

        if (this.props.entityName === "schedules") {
            let currentObject = null;

            if (this.state.daysToCloneFor.length > 0) {
                currentObject = this.state.daysToCloneFor.find(newitem => newitem.day === item.key);
            }
            if (!currentObject) {
                this.state.daysToCloneFor.push({
                    "day": item.key,
                    "checked": false
                })
            }
        }

        return (
            <ExpansionPanel key={item.key} expanded={item.expand ? item.expand : false}  onChange={this.handleExpandChange(item.key, levelIndicator)}>
                <ExpansionPanelSummary
                    expandIcon={<ExpandMore />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                    classes={{ content: this.props.classes.expansionPanelSummary }}
                    onMouseOver={this.props.entityName === "schedules" ? () => this.handleMouseOver(item.key) : null}
                >
                    <Typography variant={"subtitle1"}
                        style={{ width: '100%', display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                        {item.label}
                        {
                            this.props.entityName === "monitors" && item.deletable ?
                            <Tooltip title={<span style={{ fontSize: "12px" }}>Delete Fault</span>}>
                                <Delete
                                    classes={{ root: this.props.classes.deleteIcon }}
                                    onClick={(event) => (this.removeFault(event, item, levelIndicator))}
                                />
                            </Tooltip>
                                : null
                        }
                        {/* {
                            this.props.entityName === "monitors" ?
                                <AddBox
                                    classes={{ root: this.props.classes.editIcon }}
                                    onClick={(event) => (this.openModal(event, levelIndicator))}
                                />
                                : null
                        } */}
                        {
                            this.props.entityName === "schedules" ?
                                <Button
                                    variant="text"
                                    className={this.props.classes.addPlantsButton}
                                    onClick={this.openCloningModal}
                                    hidden={item.key !== this.state.sourceDay}
                                    classes={{ text: this.props.classes.cloneButtonText }}
                                >
                                    {"Clone"}
                                </Button>
                                : null
                        }
                    </Typography>
                </ExpansionPanelSummary>
                <ExpansionPanelDetails
                    classes={{ root: this.props.classes.expansionPanelDetails }}
                >
                    {
                        (item.children && item.children.length > 0) ?
                            item.children.map((nextItem, index) => {
                                if (!nextItem.disabled) {
                                    object = JSON.parse(JSON.stringify(object));
                                    nextItem = JSON.parse(JSON.stringify(nextItem));
                                    object["level_" + item.level + "_parent"] = item.key + ":" + incomingIndex;
                                    switch (nextItem.type) {
                                        case "Heading":
                                            return this.getHeader(Object.assign(nextItem, object), index);
                                        case "Accordion":
                                            return this.getAccordion(Object.assign(nextItem, object), index);
                                        case "Subheading":
                                            return this.getHeader2(Object.assign(nextItem, object), index);
                                        case "Textbox":
                                            return this.getTextbox(Object.assign(nextItem, object), index);
                                        case "Number Box":
                                            return this.getNumberBox(Object.assign(nextItem, object), index);
                                        case "Double Number Box":
                                            return this.getDoubleNumberBox(Object.assign(nextItem, object), index);
                                        case "Number Scroll Box":
                                            return this.getNumberScrollBox(Object.assign(nextItem, object), index);
                                        case "Dropdown":
                                            return this.getDropdown(Object.assign(nextItem, object), index);
                                        case "Double Number Scroll Box Heading":
                                            return this.getDoubleNumberScrollBoxHeading(Object.assign(nextItem, object), index);
                                        case "Double Number Scroll Box":
                                            return this.getDoubleNumberScrollBox(Object.assign(nextItem, object), index);
                                    }
                                }
                            })
                            : null
                    }
                </ExpansionPanelDetails>
            </ExpansionPanel>
        )
    }

    handleExpandChange = (panel, levelIndicator) => (event, isExpanded) => {
        this.props.expanPanel(panel, isExpanded, levelIndicator);
    };

    //remove faults from specs
    removeFault = (e, item, levelIndicator) => {
        e.stopPropagation();
        this.setState({
            openDeleteFaultDialog: true,
            deleteFault: item
        })
    }

    //Close delete fault Dialog
    closeDeleteFaultDialog = () =>{
        this.setState({
            openDeleteFaultDialog: false
        })
    }

    //Delete fault
    deleteFault = () => {
        let conditions = this.props.jsonData.find(condition => condition.key === 'conditions');
        const featureType = 2;
        const count = conditions.children.filter((obj) => obj.featureType === featureType).length;
        if(count === 1){
            this.props.showError(this.props.t("Minimum 1 fault is required per Monitoring Location."), false);
        }else{
            this.props.updateFaults('Delete', this.state.deleteFault.key, this.state.bearingTypeNumber);
        }
        this.closeDeleteFaultDialog();
    }

    updateFault = (value) =>{
        this.setState({
            selectedFault:value
        })
    }

    handleAddFault = () => {
        let conditions = this.props.jsonData.find(condition => condition.key === 'conditions');
        let find = conditions.children.find(child => child.key === this.state.selectedFault.faultCode);
        if(find){
            this.props.showError(this.props.t("The selected fault is already configured for this Monitoring Location."), false);
        }else{
            const featureType = 2;
            const count = conditions.children.filter((obj) => obj.featureType === featureType).length;
            if(count >= 13){
                this.props.showError(this.props.t("Maximum 13 faults can be added per Monitoring Location."), false);
            }else{
                this.props.updateFaults('Add', this.state.selectedFault.faultCode, this.state.bearingTypeNumber);
            }
            this.closeAddFaultDialog();
        }
    }

    getHeader = (item, incomingIndex) => {
        let object = {};

        for (let key in item) {
            if (key.startsWith("level_")) {
                object[key] = item[key];
            }
        }

        return (
            <div key={item.key}>
                <div style={{display:'flex', justifyContent:'space-between'}}>
                <Typography style={{ margin: '1%' }} variant={"h6"}>{item.label}</Typography>
                {
                            this.props.entityName === "monitors" && item.key === 'conditions' && this.props.monitorCodeName == 'VELMONWITHFAULTS' ?
                            <Tooltip title={<span style={{ fontSize: "12px" }}>Add Fault</span>}>
                                <AddBox
                                    classes={{ root: this.props.classes.addIcon }}
                                    onClick={(event) => (this.openAddFaultDialog(event))}
                                />
                            </Tooltip>
                                : null
                }
                </div>
                {
                    (item.children && item.children.length > 0) ?
                        item.children.map((nextItem, index) => {
                            if (!nextItem.disabled) {
                                object = JSON.parse(JSON.stringify(object));
                                nextItem = JSON.parse(JSON.stringify(nextItem));
                                object["level_" + item.level + "_parent"] = item.key + ":" + incomingIndex;
                                switch (nextItem.type) {
                                    case "Heading":
                                        return this.getHeader(Object.assign(nextItem, object), index);
                                    case "Accordion":
                                        return this.getAccordion(Object.assign(nextItem, object), index);
                                    case "Subheading":
                                        return this.getHeader2(Object.assign(nextItem, object), index);
                                    case "Textbox":
                                        return this.getTextbox(Object.assign(nextItem, object), index);
                                    case "Number Box":
                                        return this.getNumberBox(Object.assign(nextItem, object), index);
                                    case "Double Number Box":
                                        return this.getDoubleNumberBox(Object.assign(nextItem, object), index);
                                    case "Number Scroll Box":
                                        return this.getNumberScrollBox(Object.assign(nextItem, object), index);
                                    case "Dropdown":
                                        return this.getDropdown(Object.assign(nextItem, object), index);
                                    case "Double Number Scroll Box Heading":
                                        return this.getDoubleNumberScrollBoxHeading(Object.assign(nextItem, object), index);
                                    case "Double Number Scroll Box":
                                        return this.getDoubleNumberScrollBox(Object.assign(nextItem, object), index);
                                }
                            }
                        })
                        : null
                }
            </div>
        )
    }
// handle view of widget
    getWidgets = () => {
        return this.props.jsonData.map((item, index) => {
            if (!item.disabled) {
                switch (item.type) {
                    case "Heading":
                        return this.getHeader(item, index);
                    case "Accordion":
                        return this.getAccordion(item, index);
                    case "Subheading":
                        return this.getHeader2(item, index);
                    case "Textbox":
                        return this.getTextbox(item, index);
                    case "Number Box":
                        return this.getNumberBox(item, index);
                    case "Double Number Box":
                        return this.getNumberBox(item, index);
                    case "Number Scroll Box":
                        return this.getNumberScrollBox(item, index);
                    case "Dropdown":
                        return this.getDropdown(item, index);
                    case "Double Number Scroll Box Heading":
                        return this.getDoubleNumberScrollBoxHeading(item, index);
                    case "Double Number Scroll Box":
                        return this.getDoubleNumberScrollBox(item, index);
                }
            }
        })
    }
// validate new schedule input 
    validateNewScheduleInput = (inputType, value, parameterDatatype) => {
        let error = false;
        let errorMessage = "";
        let disableNewShiftApplyButton = false;
        let minValue = null;
        let maxValue = null;

        if (inputType === "Hours") {
            minValue = 0;
            maxValue = 23;
        }
        else {
            minValue = 0;
            maxValue = 59;
        }

        if (isNaN(value) || value === "" || value < minValue || value > maxValue) {
            error = true;
            errorMessage = `${this.props.t("Enter integer b/w")} ${minValue} - ${maxValue}`;
            disableNewShiftApplyButton = true;
        }
        else {
            if (value >= minValue && value <= maxValue) {
                if (parameterDatatype === "integer") {
                    if (Number.isInteger(Number(value))) {
                        error = false;
                        errorMessage = "";
                    }
                    else {
                        error = true;
                        errorMessage = `${this.props.t("Enter integer b/w")} ${minValue} - ${maxValue}`;
                        disableNewShiftApplyButton = true;
                    }
                }
            }
        }

        return [error, errorMessage, disableNewShiftApplyButton];
    }
// handle update of new schedule
    updateNewSchedule = (event) => {
        let { id, name, value } = event.target;
        let newShift = JSON.parse(JSON.stringify(this.state.newShift));
        let errorData = [];
        let error = false;
        let errorMessage = "";
        let disableNewShiftApplyButton = false;
        switch (id) {
            case "ShiftName":
                if (value.trim() === "") {
                    error = true;
                    errorMessage = this.props.t("Name cannot be empty");
                    disableNewShiftApplyButton = true;
                }
                newShift.key = value;
                newShift.label = value;
                newShift.error = error;
                newShift.errorMessage = errorMessage;
                break;
            case "StartTime":
                if (name === "Hours") {
                    newShift.children[0].children[0].value = value;
                    errorData = this.validateNewScheduleInput(name, value, newShift.children[0].children[0].parameterDatatype);
                    newShift.children[0].children[0].error = errorData[0];
                    newShift.children[0].children[0].errorMessage = errorData[1];
                    disableNewShiftApplyButton = errorData[2];
                }
                else {
                    newShift.children[0].children[1].value = value;
                    errorData = this.validateNewScheduleInput(name, value, newShift.children[0].children[1].parameterDatatype);
                    newShift.children[0].children[1].error = errorData[0];
                    newShift.children[0].children[1].errorMessage = errorData[1];
                    disableNewShiftApplyButton = errorData[2];
                }
                break;
            case "EndTime":
                if (event.target.name === "Hours") {
                    newShift.children[1].children[0].value = value;
                    errorData = this.validateNewScheduleInput(name, value, newShift.children[1].children[0].parameterDatatype);
                    newShift.children[1].children[0].error = errorData[0];
                    newShift.children[1].children[0].errorMessage = errorData[1];
                    disableNewShiftApplyButton = errorData[2];
                }
                else {
                    newShift.children[1].children[1].value = value;
                    errorData = this.validateNewScheduleInput(name, value, newShift.children[1].children[1].parameterDatatype);
                    newShift.children[1].children[1].error = errorData[0];
                    newShift.children[1].children[1].errorMessage = errorData[1];
                    disableNewShiftApplyButton = errorData[2];
                }
                break;
            default: console.log("default case reached")
        }

        this.setState({
            newShift,
            disableNewShiftApplyButton
        })
    }

    render() {
        const {t}=this.props;
        return (
            <Fragment>
                {this.getWidgets()}
                <Dialog
                    open={this.state.openModal}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                    disableBackdropClick={true}
                    classes={{ paperWidthSm: this.props.classes.settingsModal }}
                >
                    <div className={this.props.classes.dialogTitle}>
                        <Typography variant="h6">{"Create"}</Typography>
                        <Close onClick={this.closeModal} style={{ cursor: 'pointer' }} />
                    </div>
                    <Divider />
                    <DialogContent>
                        <div>
                            <div style={{ padding: '10px 0' }}>
                                <Typography variant="subtitle1" style={{ backgroundColor: '#f2f3f8', padding: '0 5px' }}>
                                    {t("Create new conditions")}
                                </Typography>
                            </div>
                            <Divider />
                        </div>
                        <div className={this.props.classes.inputFieldDiv} style={{ width: '100%', height: 55, marginTop: 18 }}>
                            <InputLabel className={this.props.classes.textFieldLabel}>{"Condition Name"}</InputLabel>
                            <TextField
                                variant="outlined"
                                type="text"
                                className={this.props.classes.textField}
                                onChange={(event) => { this.setState({ newCondition: event.target.value }) }}
                                value={this.state.newCondition}
                                InputProps={{
                                    classes: {
                                        input: this.props.classes.outlinedInput
                                    }
                                }}
                            />
                        </div>
                    </DialogContent>
                    <DialogActions>
                        <Button
                            color="primary"
                            style={{ marginRight: 24 }}
                            onClick={this.addNewCondition}
                        >
                            {t("Create")}
                        </Button>
                    </DialogActions>
                </Dialog>
                <Dialog
                    open={this.state.openCloningModal}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                    disableBackdropClick={true}
                    classes={{ paperWidthSm: this.props.classes.settingsModal }}
                >
                    <div className={this.props.classes.dialogTitle}>
                        <Typography variant="h6">{"Replicate Schedule"}</Typography>
                        <Close onClick={this.closeCloningModal} style={{ cursor: 'pointer' }} />
                    </div>
                    <Divider />
                    <DialogContent>
                        <div>
                            <div style={{ padding: '10px 0' }}>
                                <Typography variant="subtitle1" style={{ backgroundColor: '#f2f3f8', padding: '0 5px' }}>
                                    {"Apply same schedule for..."}
                                </Typography>
                            </div>
                            <Divider />
                        </div>
                        {
                            this.state.daysToCloneFor && this.state.daysToCloneFor.length && this.state.openCloningModal > 0 ?
                                this.getDaysToCloneFor()
                                : null
                        }
                    </DialogContent>
                    <DialogActions>
                        <Button
                            color="primary"
                            style={{ marginRight: 24 }}
                            onClick={this.cloneSchedule}
                            disabled={this.state.disableApplyButton}
                        >
                            {t("Apply")}
                        </Button>
                    </DialogActions>
                </Dialog>
                <Dialog
                    open={this.state.openNewScheduleModal}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                    disableBackdropClick={true}
                    classes={{ paperWidthSm: this.props.classes.settingsModal }}
                >
                    <div className={this.props.classes.dialogTitle}>
                        <Typography variant="h6">{"Create New"}</Typography>
                        <Close onClick={this.closeNewScheduleModal} style={{ cursor: 'pointer' }} />
                    </div>
                    <Divider />
                    <DialogContent>
                        <div>
                            <div style={{ padding: '10px 0' }}>
                                <Typography variant="subtitle1" style={{ backgroundColor: '#f2f3f8', padding: '0 5px' }}>
                                    {`Add new ${this.state.addNewShiftOrBreak === "shifts" ? "shift" : "break"}`}
                                </Typography>
                            </div>
                            <Divider />
                        </div>
                        {
                            this.state.newShift ?
                                <Fragment>
                                    <div className={this.props.classes.inputFieldDiv} style={{ width: '100%', height: 67, marginTop: 18 }}>
                                        <InputLabel className={this.props.classes.textFieldLabel} style={{ width: '15%' }}>{`${this.state.addNewShiftOrBreak === "shifts" ? "Shift Name" : "Break Name"}`}</InputLabel>
                                        <TextField
                                            id={"ShiftName"}
                                            variant="outlined"
                                            type="text"
                                            className={this.props.classes.textField}
                                            onChange={this.updateNewSchedule}
                                            value={this.state.newShift.key}
                                            error={this.state.newShift.error}
                                            helperText={this.state.newShift.error ? this.state.newShift.errorMessage : null}
                                            InputProps={{
                                                classes: {
                                                    input: this.props.classes.outlinedInput
                                                }
                                            }}
                                            style={{ width: '60%', marginRight: 0 }}
                                        />
                                    </div>
                                    <div style={{ display: 'flex', marginTop: 18, width: '100%', justifyContent: 'space-between', alignItems: 'baseline' }}>
                                        <InputLabel className={this.props.classes.textFieldLabel} style={{ width: '15%' }}>{"Start Time"}</InputLabel>
                                        <div style={{ display: 'flex', width: '60%', justifyContent: 'space-between' }}>
                                            <TextField
                                                id={this.state.newShift.children[0].key}
                                                name={this.state.newShift.children[0].children[0].key}
                                                variant="outlined"
                                                type="number"
                                                className={this.props.classes.textField}
                                                style={{ width: '45%', marginRight: 0 }}
                                                onChange={this.updateNewSchedule}
                                                value={this.state.newShift.children[0].children[0].value}
                                                //helperText={"Hours"}
                                                error={this.state.newShift.children[0].children[0].error}
                                                helperText={this.state.newShift.children[0].children[0].error ? this.state.newShift.children[0].children[0].errorMessage : "Hours"}
                                                InputProps={{
                                                    classes: {
                                                        input: this.props.classes.outlinedInput
                                                    }
                                                }}
                                                inputProps={{
                                                    min: 0,
                                                    max: 23,
                                                    step: 1
                                                }}
                                            />
                                            <TextField
                                                id={this.state.newShift.children[0].key}
                                                name={this.state.newShift.children[0].children[1].key}
                                                variant="outlined"
                                                type="number"
                                                className={this.props.classes.textField}
                                                style={{ width: '45%', marginRight: 0 }}
                                                onChange={this.updateNewSchedule}
                                                value={this.state.newShift.children[0].children[1].value}
                                                //helperText={"Minutes"}
                                                error={this.state.newShift.children[0].children[1].error}
                                                helperText={this.state.newShift.children[0].children[1].error ? this.state.newShift.children[0].children[1].errorMessage : "Minutes"}
                                                InputProps={{
                                                    classes: {
                                                        input: this.props.classes.outlinedInput
                                                    }
                                                }}
                                                inputProps={{
                                                    min: 0,
                                                    max: 59,
                                                    step: 5
                                                }}
                                            />
                                        </div>
                                    </div>
                                    <div style={{ display: 'flex', marginTop: 18, width: '100%', justifyContent: 'space-between', alignItems: 'baseline' }}>
                                        <InputLabel className={this.props.classes.textFieldLabel} style={{ width: '15%' }}>{"End Time"}</InputLabel>
                                        <div style={{ display: 'flex', width: '60%', justifyContent: 'space-between' }}>
                                            <TextField
                                                id={this.state.newShift.children[1].key}
                                                name={this.state.newShift.children[1].children[0].key}
                                                variant="outlined"
                                                type="number"
                                                className={this.props.classes.textField}
                                                style={{ width: '45%', marginRight: 0 }}
                                                onChange={this.updateNewSchedule}
                                                value={this.state.newShift.children[1].children[0].value}
                                                //helperText={"Hours"}
                                                error={this.state.newShift.children[1].children[0].error}
                                                helperText={this.state.newShift.children[1].children[0].error ? this.state.newShift.children[1].children[0].errorMessage : "Hours"}
                                                InputProps={{
                                                    classes: {
                                                        input: this.props.classes.outlinedInput
                                                    }
                                                }}
                                                inputProps={{
                                                    min: 0,
                                                    max: 23,
                                                    step: 1
                                                }}
                                            />
                                            <TextField
                                                id={this.state.newShift.children[1].key}
                                                name={this.state.newShift.children[1].children[1].key}
                                                variant="outlined"
                                                type="number"
                                                className={this.props.classes.textField}
                                                style={{ width: '45%', marginRight: 0 }}
                                                onChange={this.updateNewSchedule}
                                                value={this.state.newShift.children[1].children[1].value}
                                                //helperText={"Minutes"}
                                                error={this.state.newShift.children[1].children[1].error}
                                                helperText={this.state.newShift.children[1].children[1].error ? this.state.newShift.children[1].children[1].errorMessage : "Minutes"}
                                                InputProps={{
                                                    classes: {
                                                        input: this.props.classes.outlinedInput
                                                    }
                                                }}
                                                inputProps={{
                                                    min: 0,
                                                    max: 59,
                                                    step: 5
                                                }}
                                            />
                                        </div>
                                    </div>
                                </Fragment>
                                : null
                        }
                    </DialogContent>
                    <DialogActions>
                        <Button
                            color="primary"
                            style={{ marginRight: 24 }}
                            onClick={this.addNewShiftOrBreak}
                            disabled={this.state.disableNewShiftApplyButton || !this.state.newShift.key}
                        >
                            {t("Apply")}
                        </Button>
                    </DialogActions>
                </Dialog>
                <Dialog
                    open={this.state.openFaultDialog}
                    onClose={this.handleCancel}
                    maxWidth={'md'}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <DialogContent className={this.props.classes.content}>
                        {t("Would you like to assign the bearing faults associated with the selected bearing type?")}
                                        </DialogContent>
                    <DialogActions>
                        <Button onClick={(event) => this.handleOk()} color="primary" autoFocus>
                            {t("OK")}
                                            </Button>
                        <Button onClick={(event) => this.handleCancel()} color="primary" autoFocus>
                            {t("Cancel")}
                                            </Button>
                    </DialogActions>
                </Dialog>
                <Dialog
                    open={this.state.openAddFaultDialog}
                    onClose={this.closeAddFaultDialog}
                    maxWidth={'md'}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <DialogContent className={this.props.classes.dropdownContent}>
                        <InputLabel className={this.props.classes.textFieldLabel} style={{ paddingTop: '8px' }}>Select Fault</InputLabel>
                        <Select
                            //  native
                            value={this.state.selectedFault}
                            onChange={(event) => this.updateFault(event.target.value)}
                            input={
                                <OutlinedInput
                                    variant="outlined"
                                    name="monitorTypeId"
                                    id="language-native-simple"
                                    labelWidth={0}
                                    className={this.props.classes.textField}
                                    // error={item.deleted || item.error || item.value === ""}
                                    classes={{ input: this.props.classes.outlinedInput }}
                                />
                            }
                        >
                            {this.state.faultList !== null && this.state.faultList !== 'null' && this.state.faultList.length > 0 ?
                                this.state.faultList.map(fault => (
                                    <MenuItem key={fault.faultCode} value={fault}>
                                            {fault.faultName}
                                    </MenuItem>))
                                : <MenuItem key={""} value={""}></MenuItem>
                            }
                        </Select>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={(event) => this.handleAddFault()} color="primary" autoFocus>
                            {t("OK")}
                                            </Button>
                        <Button onClick={(event) => this.closeAddFaultDialog()} color="primary" autoFocus>
                            {t("Cancel")}
                                            </Button>
                    </DialogActions>
                </Dialog>
                <Dialog
                    open={this.state.openDeleteFaultDialog}
                    onClose={this.closeDeleteFaultDialog}
                    maxWidth={'md'}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <DialogContent className={this.props.classes.content}>
                        {t("Are you sure, you want to remove this fault from this Monitoring Location?")}                    </DialogContent>
                    <DialogActions>
                        <Button onClick={(event) => this.deleteFault()} color="primary" autoFocus>
                            {t("OK")}
                                            </Button>
                        <Button onClick={(event) => this.closeDeleteFaultDialog()} color="primary" autoFocus>
                            {t("Cancel")}
                                            </Button>
                    </DialogActions>
                </Dialog>
                <Dialog openRemoveFaultDialog closeRemoveFaultDialog removeBearingFault
                    open={this.state.openRemoveFaultDialog}
                    onClose={this.closeRemoveFaultDialog}
                    maxWidth={'md'}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <DialogContent className={this.props.classes.content}>
                        {t("Do you want to remove the bearing faults associated with this bearing type?")}                    </DialogContent>
                    <DialogActions>
                        <Button onClick={(event) => this.removeBearingFault()} color="primary" autoFocus>
                            {t("OK")}
                                            </Button>
                        <Button onClick={(event) => this.closeRemoveFaultDialog()} color="primary" autoFocus>
                            {t("Cancel")}
                                            </Button>
                    </DialogActions>
                </Dialog>
            </Fragment>
        )
    }
}

export default withTranslation()((withStyles(styles)(WidgetComponents)));