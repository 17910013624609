import React, { Component, Fragment } from 'react';
import {
    Grid,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    Typography,
    TextField,
    Divider,
    withStyles,
    Snackbar,
    IconButton,
    CircularProgress,
    Checkbox
} from '@material-ui/core';
import { connect } from 'react-redux';
import { Route, Switch } from 'react-router-dom';
import CloseIcon from '@material-ui/icons/Close';
import { Close } from '@material-ui/icons';

import * as actionCreators from '../actions/actionCreators';
import Header from './Header';
import FunctionTabs from './FunctionTabs';
import Navigation from './Navigation';
import OrganizationsTable from './Organizations/OrganizationsTable';
import OrganizationsEntity from './Organizations/OrganizationsEntity';
import PlantsTable from './Plants/PlantsTable';
import PlantsEntity from './Plants/PlantsEntity';
import UsersTable from './Users/UsersTable';
import UsersEntity from './Users/UsersEntity';
import MachineGroupsTable from './MachineGroups/MachineGroupsTable';
import MachineGroupsEntity from './MachineGroups/MachineGroupsEntity';
import QuickConfigTableEntity from './QuickConfig/QuickConfigTableEntity';
import MachinesTable from './Machines/MachinesTable';
import MachinesEntity from './Machines/MachinesEntity';
import SchedulesTable from './Schedules/SchedulesTable';
import SchedulesEntity from './Schedules/SchedulesEntity';
import JobsTable from './Jobs/JobsTable';
import JobsEntity from './Jobs/JobsEntity';
import LossCodesTable from './LossCodes/LossCodesTable';
import LossCodesEntity from './LossCodes/LossCodesEntity';
import MonitorsTable from './Monitors/MonitorsTable';
import MonitorsEntity from './Monitors/MonitorsEntity';
import DevicesTable from './Devices/DevicesTable';
import DevicesEntity from './Devices/DevicesEntity';
import AlertsTable from './Alerts/AlertsTable';
import AlertsEntity from './Alerts/AlertsEntity';
import ScheduledReportsEntity from './ScheduledReports/ScheduledReportsEntity';
import ScheduledReportsModal from './ScheduledReports/ScheduledReportsModal';
import ScheduledReportsTable from './ScheduledReports/ScheduledReportsTable';

import SpectralFeaturesTable from './SpectalFeatures/SpectralFeaturesTable';
import SpectalFeaturesEntity from './SpectalFeatures/SpectralFeaturesEntity';

import MonitoringDashboard from './Monitoring/MonitoringDashboard';
import ProductionDashboard from './Production/ProductionDashboard';

import moment from "moment";
import { config, IDAP_SSO_COOKIENAME, IDAP_SSO_DOMAIN_NAME } from '../configuration';
import { withTranslation } from "react-i18next";
import useGAEventsTracker from '../hooks/useGAEventsTracker'

let realtime_server_url = config.url.realtime_server_url, count = 0, countLang = 0, countMachine = 0, countPlant = 0;

const styles = theme => ({
    mainAreaBackground: {
        backgroundColor: "#F2F3F8",
    },
    settingsAlignment: {
        maxWidth: '100%',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        margin: '0px 10px 0px 10px',
        // height: '80vh'
    },
    loading: {
        position: "fixed",
        "z-index": 1400,
        height: "100vh",
        width: "100%",
        "background-color": "transparent",
        display: 'flex',
        'justify-content': 'center',
        'align-items': 'center'
    },
    loaderColor: {
        color: '#2abbe8'
    },
    close: {
        padding: theme.spacing.unit / 2,
    },
    snackbarClasses: {
        backgroundColor: '#43a047'
    },
    snackbarErrorClasses: {
        backgroundColor: '#d32f2f',
        flexWrap: 'nowrap'
    },
    settingsModal: {
        'min-width': 525,
        'max-height': 589,
        'min-height': 310
    },
    dialogTitle: {
        display: 'flex',
        'justify-content': 'space-between',
        'align-items': 'center',
        padding: '10px 24px'
    },
    dialogContent: {
        padding: '0 24px'
    },
    defaultCheckbox: {
        fontSize: 20
    },
    elementContainer: {
        display: 'flex',
        'align-items': 'center',
        'justify-content': 'space-between'
    },
    inputLabel: {
        color: 'rgba(0, 0, 0, 0.4000000059604645)',
        'font-size': 14,
        'letter-spacing': 0.5,
        'font-weight': 500
    },
    textField: {
        width: 300,
        height: 50,
        '& input': {
            padding: 16
        }
    },
    dialogSaveButton: {
        width: 145,
        backgroundColor: '#42D784',
        '&:hover': {
            backgroundColor: '#42D784'
        }
    },
});

class Dashboard extends Component {

    state = {
        data: {},
        open: false,
        error: false,
        name: "",
        id: "",
        loading: true,
        notes: "",
        openDialog: false,
        plantId: "",
        plantName: "",
        diableSaveButton: false,
        entityName: "",
        selectedNavTab: "",
        currentScopeSelectionId: "",
        defaultJobList: false,
        defaultLossList: false,
        errorMessage: '',
        widgetsData: ""
    }

    GAEventsTracker = useGAEventsTracker('usersession');

    handleClickOpen = () => {
        this.setState({ open: true });
    }

    handleClose = (event) => {
        this.setState({ open: false });
        this.props.getAlertMessage("");
    }

    handleErrorClose = (event) => {
        this.setState({ errorOpen: false });
    }

    getPopUpMessage = () => {
        let message = "";
        if (this.props.alertMessage && this.props.alertMessage.message && this.props.alertMessage.message.message && (typeof (this.props.alertMessage.message.message) === "string")) {
            message = this.props.alertMessage.message.message;
            return message;
        } else if (this.props.alertMessage && this.props.alertMessage.message && (typeof (this.props.alertMessage.message) === "object")) {
            if (this.props.alertMessage.message.message && this.props.alertMessage.message.message.messageCode) {
                message = this.props.alertMessage.message.message.messageCode;
                return this.props.t(message);
            }
            if (this.props.alertMessage.message.message && this.props.alertMessage.message.message.message && (typeof (this.props.alertMessage.message.message.message) === "string")) {
                message = this.props.alertMessage.message.message.message;
                return message;
            } else if (this.props.alertMessage.message.message && this.props.alertMessage.message.message && Array.isArray(this.props.alertMessage.message.message)) {
                this.props.alertMessage.message.message.map((item, index) => {
                    message = index === 0 ? (message + this.props.t(item)) : (message + " \n" + this.props.t(item));
                })
            } else {
                let keys = Object.keys(this.props.alertMessage.message);
                keys.map((key) => {
                    message = message + this.props.alertMessage.message[key] + ". ";
                })
            }
        }
        return message;
    }

    componentDidMount() {
        let currentLanguage = localStorage.getItem("lang");
        if (currentLanguage === "null" || currentLanguage === null) {
            currentLanguage = "en"
        }
        this.props.isUserAuthenticated();
        this.props.getAppsName()
        this.props.getAppData(currentLanguage);
        this.props.getViewDefinition(currentLanguage);
        this.props.getTimezones();
        this.props.getAlertMediaTypes();
		this.props.getStaticData();
        setTimeout(() => {
            this.setState({ loading: false })
        }, 1500);

        // this.source = new EventSource(`${realtime_server_url}/kafka_status`);
        // this.source.addEventListener('message', message => {
        //     let resp = JSON.parse(message.data);
        //     if (resp.hasOwnProperty('errorCode')) {
        //         this.setState({ errorMessage: resp.errorCode, errorOpen: true })
        //     }
        // })
        // this.setState({errorMessage: 'There might be a delay in updating the data on the dashboard due to delay in processing. Sorry for the inconvenience caused.', errorOpen: true })
        // this.props.setWidgetLanguage("English");
        if (window.performance) {
            if (performance.navigation.type == 1) {
                document.cookie = `idap-machineName-${config.url.env}=null` + "; domain=" + `${IDAP_SSO_DOMAIN_NAME}; secure`;
                document.cookie = `idap-plantIdFromAdmin-${config.url.env}=null` + "; domain=" + `${IDAP_SSO_DOMAIN_NAME}; secure`;
                console.log("page Reload")
            } else {
                console.log("This page is not reloaded");
            }
        }
    }
    componentWillUnmount() {
        if (this.source) {
            this.source.close();
        }
    }
    getCookie(cname) {
        let name = cname + "=";
        let decodedCookie = decodeURIComponent(document.cookie);
        let ca = decodedCookie.split(';');
        for (let i = 0; i < ca.length; i++) {
            let c = ca[i];
            while (c.charAt(0) == ' ') {
                c = c.substring(1);
            }
            if (c.indexOf(name) == 0) {
                return c.substring(name.length, c.length);
            }
        }
        return "";
    }
    getviewCall = (plantIDFromIU, dashboard) => {
        //this.props.updateDrawer(true);
        localStorage.setItem("dashboard", dashboard);

        localStorage.setItem("selectedPlantID", plantIDFromIU);
        let plantView = localStorage.getItem("PlantView")
        let plantViewArray = JSON.parse(plantView);
        let summaryViewArray, scopeSeletorPlantsData = [];
        if (plantViewArray !== null) {
            summaryViewArray = plantViewArray.filter(function (e) { return e.id === parseInt(plantIDFromIU) });
        } else {
            if (this.props && this.props.userData && this.props.userData.scopeSelector && this.props.userData.scopeSelector.subOrganizations) {
                this.props.userData.scopeSelector.subOrganizations.map((organization) => {
                    if (organization.plants.length > 0) {
                        organization.plants.map((plant) => {
                            if (plant.permissions !== null) {
                                scopeSeletorPlantsData.push({
                                    id: plant.id,
                                    name: plant.name,
                                    permission: plant.permissions
                                })
                            }
                        })
                    }
                })
                summaryViewArray = scopeSeletorPlantsData.filter(function (e) { return e.id === parseInt(plantIDFromIU) });
            }

        }
        if (dashboard && dashboard === "null") {
            if (scopeSeletorPlantsData.length > 1) {
                localStorage.setItem("dashboard", "summaryPlant")
            } else {
                localStorage.setItem("dashboard", "summary")
            }
        }
        this.props.updatedashboardType(dashboard)
        localStorage.setItem("selectedScopeData", plantIDFromIU);
        localStorage.setItem("plantValue", plantIDFromIU);
        
        // MINDAPP-2078 fix: Check summaryViewArray for required properties.
        if (
          summaryViewArray &&
          summaryViewArray.length > 0 &&
          summaryViewArray[0] &&
          summaryViewArray[0].id &&
          summaryViewArray[0].permission &&
          summaryViewArray[0].name
        ) {
          localStorage.setItem("plantName", summaryViewArray[0].name);
          this.props.updateScopeSelector(
            summaryViewArray[0].id,
            dashboard === "monitoring" ? 5 : 0,
            summaryViewArray[0].permission,
            "Monitoring",
            summaryViewArray[0].name
          );
        }
        
        if ((plantIDFromIU && plantIDFromIU !== "null" && plantIDFromIU !== undefined) && dashboard === "summary") {

            this.props.getSummaryData(plantIDFromIU);
            this.props.getMonitorList(plantIDFromIU, (list) => {
                // console.log('MonitorList........', list);
            })
        }


    }
    componentDidUpdate(prevProps, prevState) {
        let langSet = this.getCookie(`idap-lang-${config.url.env}`);
        //let dash = this.getCookie(`idap-dashboard-${config.url.env}`);
        let dash = localStorage.getItem("dashboard");
        let machineNameFromIU = this.getCookie(`idap-machineName-${config.url.env}`);
        let plantIDFromIU = this.getCookie(`idap-plantId-${config.url.env}`);
        let plantIdFromAdmin = this.getCookie(`idap-plantIdFromAdmin-${config.url.env}`);
        if (this.props.userData.loggedIn === false) {
            if(prevProps.userData.loggedIn === true) {
                // When a logged in User has been logged out either intentionally or unintentionally
    
                // Capture current localstorage variable values before it is cleared
                let lang = localStorage.getItem("lang");
                let loginTime = localStorage.getItem('loginTime');
                let intervalLoacalValue = localStorage.getItem("intervalSet")
                let intervaluArr = JSON.parse(intervalLoacalValue);
                let intervalListId = localStorage.getItem("intervalListId");
                let range = localStorage.getItem("dateRange");
                let storageUnit = localStorage.getItem("unit");
                let dashboard = localStorage.getItem("dashboard");
        
                // Clear localStorate
                localStorage.clear();
    
                // Clear Cookie
                document.cookie = `${IDAP_SSO_COOKIENAME}=null` + "; max-age=0; domain=" + `${IDAP_SSO_DOMAIN_NAME}; secure`;
    
                // Setting localstorage variable values (and lang) from the above captured variables
                this.props.i18n.changeLanguage(lang);
                localStorage.setItem("lang", lang);
                localStorage.setItem("intervalListId", intervalListId);
                localStorage.setItem("dateRange", range);
                localStorage.setItem("intervalSet", JSON.stringify(intervaluArr));
                localStorage.setItem("unit", storageUnit);
                localStorage.setItem("dashboard", dashboard)
    
                // Update Google Analytics about User and User's session duration
                let logoutTime = moment();
                var duration = logoutTime.diff(moment(loginTime), 'seconds');
                let username = prevProps.userData.email;
                let label = {
                    username: username.replace('@', '$'),
                    duration
                };
                this.GAEventsTracker('logout', label);
            }

            if (((document.location.hostname).toLowerCase().includes("japan")) || ((document.location.hostname).toLowerCase().includes("omniedge-r.thk"))) {
                this.props.history.replace("/login");
            } else {
                this.props.history.replace("/login");
            }
        }

        if (langSet && langSet !== "null" && langSet !== "" && countLang < 3) {
            countLang++;
            this.props.updateDrawer(true);

        }
        if (machineNameFromIU && machineNameFromIU !== "null" && machineNameFromIU !== "" && countMachine < 3) {
            countMachine++;
            this.getviewCall(plantIDFromIU, "summary")
        }
        if (plantIdFromAdmin && plantIdFromAdmin !== "null" && plantIdFromAdmin !== "" && countPlant < 3) {
            countPlant++;
            this.getviewCall(plantIdFromAdmin, dash)
        }
        if (prevProps.i18n.language !== this.props.i18n.language) {
            this.props.getViewDefinition(this.props.i18n.language);
        }
        if ((this.props.loaderState.load.value) && count < 4) {
            count++;
            this.setState({ loading: false })
        }
        if (prevProps.alertMessage !== this.props.alertMessage) {
            if (this.props.alertMessage && this.props.alertMessage.message && this.props.alertMessage.message !== "") {
                if (this.props.alertMessage && this.props.alertMessage.message && this.props.alertMessage.message.successful) {
                    if (this.props.alertMessage.message.loggedIn === false) {
                        this.props.isUserAuthenticated();
                    }
                    if (this.props.history.location.pathname.includes("Entity")) {
                        let link = this.props.location.pathname.split("/")[2];
                        if (link === "OrganizationsTable" || link === "PlantsTable" || link === "UsersTable") {
                            this.props.updateSelectedTab(3);
                        }
                        else if (link === "MachineGroupsTable" || link === "MachinesTable" || link === "SchedulesTable" ||
                            link === "JobsTable" || link === "LossCodesTable" || link === "MonitorsTable" || link === "DevicesTable" ||
                            link === "AlertsTable" || link === 'SpectralFeaturesTable' | link === 'QuickConfigurationTable' || link === 'ScheduledReportsTable'
                        ) {
                            this.props.updateSelectedTab(2);
                        }
                        let navigateId = localStorage.getItem("navigateID");
                        if (navigateId) {
                            localStorage.removeItem("navigateID");
                            this.props.history.push({ pathname: "/dashboard/MonitoringTable", state: true })
                        } else {
                            this.props.history.goBack();
                        }
                    }
                }
                if (this.props.alertMessage && this.props.alertMessage.message && (this.props.alertMessage.message.successful || this.props.alertMessage.message.loggedIn)) {
                    this.setState({ error: false });
                }
                else {
                    this.setState({ error: true });
                }
                this.handleClickOpen();
            }
        }
        if (this.props && this.props.userData && this.props.userData.selectedTab === undefined) {
            let tab = 0;
            if ((this.props.history.location.pathname.includes("Table") && this.props.history.location.pathname.includes("Monitoring"))) {
                let deviceName = localStorage.getItem("deviceName");
                let deviceId = localStorage.getItem("deviceId");
                let machineName = localStorage.getItem("machineName");
                let deviceIdentifier = localStorage.getItem("deviceIdentifier");
                let currentLanguage = localStorage.getItem("lang");

                if (currentLanguage === "null" || currentLanguage === null) {
                    currentLanguage = "en"
                }
                if (deviceName !== null) {
                    this.props.setWidgetLanguage(currentLanguage);
                    this.props.setDeviceData({ "deviceName": deviceName, "id": deviceId, "machineName": machineName, "deviceIdentifier": deviceIdentifier })

                }
                this.setState({ widgetsData: this.props.dashboardDeviceData })
                let dashboard = localStorage.getItem("dashboard");
                this.props.history.push("/dashboard/MonitoringTable");
                let plantView = localStorage.getItem("PlantView")
                let plantViewArray = JSON.parse(plantView);
                document.cookie = `idap-lang-${config.url.env}=null` + "; domain=" + `${IDAP_SSO_DOMAIN_NAME}; secure`;
                // document.cookie = `idap-machineName=null` + "; domain=" + `${IDAP_SSO_DOMAIN_NAME}`;
                if (plantViewArray !== null && plantViewArray.length > 1 && (dashboard && dashboard === "null" || dashboard === undefined)) {
                    dashboard = "summaryPlant";
                    this.props.updatedashboardType("summaryPlant");
                } else if (plantViewArray !== null && plantViewArray.length == 1 && (dashboard && dashboard === "null" || dashboard === undefined || dashboard === "summaryPlant")) {
                    dashboard = "summary";
                    this.props.updatedashboardType("summary");
                }
                if (dashboard === "summaryPlant") {
                    tab = 0;
                    this.props.updatedashboardType("summaryPlant");
                    let selectedScopeData = localStorage.getItem("selectedScopeData")
                    if (selectedScopeData !== null && selectedScopeData !== undefined) {
                        this.props.getMonitorList(selectedScopeData, (list) => {
                            // console.log('MonitorList........', list);
                        })
                        this.props.getSummaryPlantData(this.props.t);
                    }

                    this.props.updateDrawer(false);

                } else if (dashboard === "monitoring") {
                    tab = 5;
                    this.props.updatedashboardType("monitoring");
                    this.props.updateDrawer(false);

                }
                else {
                    localStorage.setItem("dashboard", "summary")
                    this.props.updatedashboardType("summary");
                    let selectedScopeData = localStorage.getItem("selectedPlantID");
                    if (selectedScopeData && selectedScopeData !== null && selectedScopeData !== undefined) {
                        this.props.getSummaryData(selectedScopeData);
                        this.props.getMonitorList(selectedScopeData, (list) => {
                            // console.log('MonitorList........', list);
                        })
                    }


                    this.props.updateDrawer(false);
                }

                this.props.updateSelectedTab(tab);
                let PlantValue = localStorage.getItem("plantValue");
                if (PlantValue !== null && PlantValue !== undefined) {
                    let currentPlant = localStorage.getItem("plantPermisssion");
                    let currentPlantName = localStorage.getItem("plantName")
                    this.props.updateScopeSelector(parseInt(PlantValue), tab, currentPlant, "Monitoring", currentPlantName);
                    // this.props.updatedashboardType(tab === 0 ? "summaryPlant" : "monitoring");

                }



            }
            else if ((this.props.history.location.pathname.includes("Table") && this.props.history.location.pathname.includes("Monitors"))) {
                this.props.history.push("/dashboard/MonitorsTable");
                this.props.updateSelectedTab(2);
                let PlantValue = localStorage.getItem("plantValue");

                if (PlantValue !== null && PlantValue !== undefined) {
                    let currentPlant = localStorage.getItem("plantPermisssion");
                    let currentPlantName = localStorage.getItem("plantName");

                    this.props.updateScopeSelector(parseInt(PlantValue), 2, currentPlant, "PlantSelector", currentPlantName);

                }
                this.props.updateDrawer(true);

            }
            else if ((this.props.history.location.pathname.includes("Table") && this.props.history.location.pathname.includes("Users"))) {
                this.props.history.push("/dashboard/UsersTable");
                this.props.updateSelectedTab(3);
                let PlantValue = localStorage.getItem("orgValue");
                if (PlantValue !== null && PlantValue !== undefined) {
                    let currentPlantName = localStorage.getItem("organizationtName");
                    this.props.updateScopeSelector(parseInt(PlantValue), 3, null, "OrganizationSelector", currentPlantName);

                }
                this.props.updateDrawer(true);


            }
            else if ((this.props.history.location.pathname.includes("Table") && this.props.history.location.pathname.includes("Users"))) {
                this.props.history.push("/dashboard/UsersTable");
                this.props.updateSelectedTab(3)
                this.props.updateDrawer(true);

            }
            else if ((this.props.history.location.pathname.includes("Table") && !(this.props.history.location.pathname.includes("Users") || this.props.history.location.pathname.includes("Monitors") || this.props.history.location.pathname.includes("Monitoring")))) {
                let currentEntity = this.props.location.pathname.split("/")[2];
                if (currentEntity === "OrganizationsTable" || currentEntity === "PlantsTable" || currentEntity === "UsersTable") {
                    this.props.updateSelectedTab(3);
                    this.props.history.push(`/dashboard/${currentEntity}`);
                    let PlantValue = localStorage.getItem("orgValue");
                    if (PlantValue !== null && PlantValue !== undefined) {
                        let currentPlantName = localStorage.getItem("organizationtName");
                        this.props.updateScopeSelector(parseInt(PlantValue), 3, null, "OrganizationSelector", currentPlantName);

                    }
                    this.props.updateDrawer(true);

                }
                else if (currentEntity === "MachineGroupsTable" || currentEntity === "MachinesTable" || currentEntity === "SchedulesTable" ||
                    currentEntity === "JobsTable" || currentEntity === "LossCodesTable" || currentEntity === "MonitorsTable" || currentEntity === "DevicesTable" ||
                    currentEntity === "AlertsTable" || currentEntity === 'SpectralFeaturesTable' || currentEntity === 'QuickConfigurationTable' || currentEntity === 'ScheduledReportsTable') {
                    this.props.updateSelectedTab(2);
                    this.props.history.push(`/dashboard/${currentEntity}`);
                    let PlantValue = localStorage.getItem("plantValue");
                    if (PlantValue !== null && PlantValue !== undefined) {
                        let currentPlant = localStorage.getItem("plantPermisssion");
                        let currentPlantName = localStorage.getItem("plantName");
                        this.props.updateScopeSelector(parseInt(PlantValue), 2, currentPlant, "PlantSelector", currentPlantName);

                    }
                    this.props.updateDrawer(true);

                }

            }
        }

        if (prevProps.userData.loggedIn === undefined && this.props.userData.loggedIn === true) {
            let tab = 0;
            if ((this.props.history.location.pathname.includes("Table") && this.props.history.location.pathname.includes("Monitoring"))) {
                let dashboard = localStorage.getItem("dashboard");
                if (dashboard !== null) {
                    this.props.history.push("/dashboard/MonitoringTable");
                    // if(dashboard==="summary"){
                    //     tab=0
                    // }else{
                    //     tab=5
                    // }
                    this.props.updateSelectedTab(tab);
                    let PlantValue = localStorage.getItem("plantValue");
                    if (PlantValue !== null && PlantValue !== undefined) {
                        let currentPlant = localStorage.getItem("plantPermisssion");
                        let currentPlantName = localStorage.getItem("plantName");

                        this.props.updateScopeSelector(parseInt(PlantValue), tab, currentPlant, "Monitoring", currentPlantName);
                        this.props.updatedashboardType(dashboard);
                        this.props.updatedashboardType(dashboard);
                    }

                }

            }
            else if ((this.props.history.location.pathname.includes("Table") && this.props.history.location.pathname.includes("Monitors"))) {
                this.props.history.push("/dashboard/MonitorsTable");
                this.props.updateSelectedTab(2);
                let PlantValue = localStorage.getItem("plantValue");
                if (PlantValue !== null && PlantValue !== undefined) {
                    let currentPlant = localStorage.getItem("plantPermisssion");
                    let currentPlantName = localStorage.getItem("plantName");
                    this.props.updateScopeSelector(parseInt(PlantValue), 2, currentPlant, "PlantSelector", currentPlantName);

                }
                this.props.updateDrawer(true);

            }
            else if ((this.props.history.location.pathname.includes("Table") && this.props.history.location.pathname.includes("Users"))) {
                this.props.history.push("/dashboard/UsersTable");
                this.props.updateSelectedTab(3);
                let PlantValue = localStorage.getItem("orgValue");
                if (PlantValue !== null && PlantValue !== undefined) {
                    let currentPlantName = localStorage.getItem("organizationtName");
                    this.props.updateScopeSelector(parseInt(PlantValue), 3, null, "OrganizationSelector", currentPlantName);

                }
                this.props.updateDrawer(true);

            }
            else if ((this.props.history.location.pathname.includes("Table") && this.props.history.location.pathname.includes("Users"))) {
                this.props.history.push("/dashboard/UsersTable");
                this.props.updateSelectedTab(3)
                this.props.updateDrawer(true);

            }
            else if ((this.props.history.location.pathname.includes("Table") && !(this.props.history.location.pathname.includes("Users") || this.props.history.location.pathname.includes("Monitors") || this.props.history.location.pathname.includes("Monitoring")))) {
                let currentEntity = this.props.location.pathname.split("/")[2];
                if (currentEntity === "OrganizationsTable" || currentEntity === "PlantsTable" || currentEntity === "UsersTable") {
                    this.props.updateSelectedTab(3);
                    this.props.history.push(`/dashboard/${currentEntity}`);
                    let PlantValue = localStorage.getItem("orgValue");
                    if (PlantValue !== null && PlantValue !== undefined) {
                        let currentPlantName = localStorage.getItem("organizationtName");
                        this.props.updateScopeSelector(parseInt(PlantValue), 3, null, "OrganizationSelector", currentPlantName);

                    }
                    this.props.updateDrawer(true);

                }
                else if (currentEntity === "MachineGroupsTable" || currentEntity === "MachinesTable" || currentEntity === "SchedulesTable" ||
                    currentEntity === "JobsTable" || currentEntity === "LossCodesTable" || currentEntity === "MonitorsTable" || currentEntity === "DevicesTable" ||
                    currentEntity === "AlertsTable" || currentEntity === 'SpectralFeaturesTable' || currentEntity === 'QuickConfigurationTable' || currentEntity === 'ScheduledReportsTable') {
                    this.props.updateSelectedTab(2);
                    this.props.history.push(`/dashboard/${currentEntity}`);
                    let PlantValue = localStorage.getItem("plantValue");
                    if (PlantValue !== null && PlantValue !== undefined) {
                        let currentPlant = localStorage.getItem("plantPermisssion");
                        let currentPlantName = localStorage.getItem("plantName");
                        this.props.updateScopeSelector(parseInt(PlantValue), 2, currentPlant, "PlantSelector", currentPlantName);

                    }

                }
                this.props.updateDrawer(true);


            }

        }
        if (((this.props.userData.selectedTab === 2) && (prevProps.userData.selectedPlant !== this.props.userData.selectedPlant)) || ((this.props.userData.selectedTab === 3) && (prevProps.userData.selectedOrganization !== this.props.userData.selectedOrganization))) {
            if (this.props.history.location.pathname.includes("Entity")) {
                this.props.history.goBack();
            }
        }
        let dashboard = localStorage.getItem("dashboard");
        if (((dashboard === "monitoring") && (prevProps.userData.selectedPlant !== this.props.userData.selectedPlant))) {
            let currentPlant = localStorage.getItem("plantPermisssion");
            let plnatVlue = localStorage.getItem("selectedScopeData");
            let currentPlantName = localStorage.getItem("plantName")

            this.props.updateScopeSelector(parseInt(plnatVlue), 5, currentPlant, "Monitoring", currentPlantName);
            this.props.updatedashboardType("monitoring");

        }

        if (prevProps.location.pathname !== this.props.location.pathname) {
            let arr = this.props.location.pathname.split("/");
            // console.log('arr here', arr);
            if (this.props.location.pathname.split("/").length === 2) {
                this.props.updateSelectedTab(3);
                this.props.history.replace("/dashboard/UsersTable");
                this.props.updateDrawer(true);
            }
            else if (this.props.location.pathname.split("/").length === 4) {
                let currentEntity = this.props.location.pathname.split("/")[3];
                if (currentEntity === "OrganizationsEntity" || currentEntity === "PlantsEntity" || currentEntity === "UsersEntity") {
                    this.props.updateSelectedTab(3);
                }
                else if (currentEntity === "MachineGroupsEntity" || currentEntity === "MachinesEntity" || currentEntity === "SchedulesEntity" ||
                    currentEntity === "JobsEntity" || currentEntity === "LossCodesEntity" || currentEntity === "MonitorsEntity" || currentEntity === "DevicesEntity" ||
                    currentEntity === "AlertsEntity" || currentEntity === "SpectalFeaturesEntity" || currentEntity === "ScheduledReportsEntity") {
                    this.props.updateSelectedTab(2);
                } else if (arr[2] === 'MonitoringTable' || arr[3] === 'MonitoringTable') {
                    this.props.updateSelectedTab(0);
                }
            }
            else if (this.props.location.pathname.split("/").length === 3) {
                let currentEntity = this.props.location.pathname.split("/")[2];
                if (currentEntity === "OrganizationsTable" || currentEntity === "PlantsTable" || currentEntity === "UsersTable") {
                    this.props.updateSelectedTab(3);
                }
                else if (currentEntity === "MachineGroupsTable" || currentEntity === "MachinesTable" || currentEntity === "SchedulesTable" ||
                    currentEntity === "JobsTable" || currentEntity === "LossCodesTable" || currentEntity === "MonitorsTable" || currentEntity === "DevicesTable" ||
                    currentEntity === "AlertsTable" || currentEntity === 'SpectralFeaturesTable' || currentEntity === 'QuickConfigurationTable' || currentEntity === 'ScheduledReportsTable') {
                    this.props.updateSelectedTab(2);
                } else if (arr[2] === 'MonitoringTable' || arr[3] === 'MonitoringTable') {
                    this.props.updateSelectedTab(0);
                }
            }
        }

        if ((this.props && this.props.userData && this.props.userData.scopeSelector) !== (prevProps && prevProps.userData && prevProps.userData.scopeSelector)) {
            let scopeSeletorPlantsData = [];
            this.props.userData && this.props.userData.scopeSelector && this.props.userData.scopeSelector.subOrganizations && this.props.userData.scopeSelector.subOrganizations.map((organization) => {
                if (organization.plants.length > 0) {
                    organization.plants.map((plant) => {
                        if (plant.permissions !== null) {
                            scopeSeletorPlantsData.push({
                                id: plant.id,
                                name: plant.name,
                                permission: plant.permissions
                            })
                        }
                    })
                }
            })

            scopeSeletorPlantsData.sort(function (plant1, plant2) {
                return plant1.name.trim().toLowerCase().localeCompare(plant2.name.trim().toLowerCase());
            });
            localStorage.setItem("PlantView", JSON.stringify(scopeSeletorPlantsData))
            let plant = scopeSeletorPlantsData.find(ele => ele.id === this.props.userData.selectedPlant);
            if (plant) {
                this.props.updateScopeSelector(this.props.userData.selectedPlant ? this.props.userData.selectedPlant:scopeSeletorPlantsData[0].id, this.props.selectedTab, plant.permission, "Monitoring", plant.name);
            } else {
                plant = scopeSeletorPlantsData[0];
                if (plant && plant) {
                localStorage.removeItem("deviceName");
                localStorage.removeItem("deviceId");
                localStorage.removeItem("machineName");
                localStorage.removeItem("deviceIdentifier");
                this.props.updateScopeSelector(plant.id, this.props.selectedTab, plant.permission, "Monitoring", plant.name);
                
                }

            }
            let dashboard = localStorage.getItem("dashboard");
            if ((dashboard === "summaryPlant" || dashboard === null || dashboard === "null" || dashboard === undefined) && (scopeSeletorPlantsData.length === 1 || scopeSeletorPlantsData.length === 0)) {
                dashboard = "summary"
            }
            if ((dashboard === "summaryPlant" || dashboard === null || dashboard === undefined || dashboard === "null") && (scopeSeletorPlantsData.length > 1)) {
                dashboard = "summaryPlant"
            }
            this.props.updatedashboardType(dashboard)
            if(plant && plant.id){
                localStorage.setItem("dashboard",dashboard)
                localStorage.setItem("selectedPlantID", plant && plant.id && plant.id);
                localStorage.setItem("plantValue", plant.id);
                localStorage.setItem("plantName", plant.name);
                localStorage.setItem("selectedScopeData", plant.id);
            }

        }
    }

    navigateToEntityView = (data, entity, selectedNavTab, currentScopeSelectionId, navigatedFrom) => {
        let pathname = this.props.location.pathname;
        this.setState(() => ({
            data,
            selectedNavTab,
            currentScopeSelectionId
        }))

        if (entity === "OrganizationsEntity" || entity === "PlantsEntity" || entity === "UsersEntity") {
            this.props.updateSelectedTab(3);
        }
        else if (entity === "MachineGroupsEntity" || entity === "MachinesEntity" ||
            entity === "SchedulesEntity" || entity === "JobsEntity" || entity === "LossCodesEntity" ||
            entity === "MonitorsEntity" || entity === "DevicesEntity" ||  entity === "AlertsEntity" ||
            entity === "SpectalFeaturesEntity" || entity === "ScheduledReportsEntity"
        ) {
            this.props.updateSelectedTab(2);
        }

        switch (entity) {

            case "OrganizationsTable":
            case "OrganizationsEntity": this.props.history.push(pathname + "/OrganizationsEntity"); break;
            case "PlantsTable":
            case "PlantsEntity": this.props.history.push(pathname + "/PlantsEntity"); break;
            case "UsersTable":
            case "UsersEntity": this.props.history.push(pathname + "/UsersEntity"); break;
            case "MachineGroupsTable":
            case "MachineGroupsEntity": this.props.history.push(pathname + "/MachineGroupsEntity"); break;
            case "MachinesTable":
            case "MachinesEntity": this.props.history.push(pathname + "/MachinesEntity"); break;
            case "SchedulesTable":
            case "SchedulesEntity": this.props.history.push(pathname + "/SchedulesEntity"); break;
            case "JobsTable":
            case "QuickConfigurationTable":
            case "JobsEntity": navigatedFrom !== undefined ?
                this.openDialog(data, "Job List", navigatedFrom)
                : this.props.history.push(pathname + "/JobsEntity"); break;
            case "LossCodesTable":
            case "LossCodesEntity": navigatedFrom !== undefined ?
                this.openDialog(data, "Loss List", navigatedFrom)
                : this.props.history.push(pathname + "/LossCodesEntity"); break;
            case "MonitorsTable":
            case "MonitorsEntity": this.props.history.push(pathname + "/MonitorsEntity"); break;
            case "DevicesTable":
            case "DevicesEntity": this.props.history.push(pathname + "/DevicesEntity"); break;
            case "AlertsTable":
            case "AlertsEntity": this.props.history.push(pathname + "/AlertsEntity"); break;
            case "SpectralFeaturesTable":
            case "SpectalFeaturesEntity": this.props.history.push(pathname + "/SpectalFeaturesEntity"); break;
            case "ScheduledReportsTable":
            case "ScheduledReportsEntity": this.props.history.push(pathname + "/ScheduledReportsEntity"); break;
            default: console.log("default case");
        }
    }

    getSelectedTab = () => {
        let navigationTabs = null;
        if (Object.keys(this.props.appData).length > 0 && this.props.appData.navbarTabs) {
            if (this.props.userData.selectedTab === 3) {
                navigationTabs = [];
                navigationTabs = [...this.props.appData.navbarTabs.administration];
            }
            else if (this.props.userData.selectedTab === 2) {
                navigationTabs = [];
                navigationTabs = [...this.props.appData.navbarTabs.configurations]

            }
            else if (this.props.userData.selectedTab === 0) {
                navigationTabs = [];
                // navigationTabs = [...this.props.appData.navbarTabs.configurations]
            }
            else if (this.props.userData.selectedTab === 4) {
                navigationTabs = [];
                // navigationTabs = [...this.props.appData.navbarTabs.configurations]
            }
        }
        return navigationTabs;
    }

    openDialog = (data, entity, navigatedFrom) => {
        if (entity === "Job List") {
            if (navigatedFrom === "PlantsTable") {
                this.setState({
                    name: data.jobLists.name,
                    notes: data.jobLists.notes,
                    id: data.jobLists.id,
                    openDialog: !this.state.openDialog,
                    diableSaveButton: false,
                    entityName: entity,
                    plantId: data.id,
                    defaultJobList: data.jobLists.defaultJobList
                })
            }
            else if (navigatedFrom === "MachineGroupsTable") {
                this.setState({
                    name: data.jobLists.name,
                    notes: data.jobLists.notes,
                    id: data.jobLists.id,
                    openDialog: !this.state.openDialog,
                    diableSaveButton: false,
                    entityName: entity,
                    plantId: data.plants.id,
                    defaultJobList: data.jobLists.defaultJobList
                })
            }
            else if (navigatedFrom === "MachinesTable") {
                this.setState({
                    name: data.jobLists.name,
                    notes: data.jobLists.notes,
                    id: data.jobLists.id,
                    openDialog: !this.state.openDialog,
                    diableSaveButton: false,
                    entityName: entity,
                    plantId: data.jobLists.plants.id,
                    defaultJobList: data.jobLists.defaultJobList
                })
            }
        }
        else if (entity === "Loss List") {
            if (navigatedFrom === "PlantsTable") {
                this.setState({
                    name: data.lossLists.name,
                    notes: data.lossLists.notes,
                    id: data.lossLists.id,
                    openDialog: !this.state.openDialog,
                    diableSaveButton: false,
                    entityName: entity,
                    plantId: data.id,
                    defaultLossList: data.lossLists.defaultLossList
                })
            }
            else if (navigatedFrom === "MachineGroupsTable") {
                this.setState({
                    name: data.lossLists.name,
                    notes: data.lossLists.notes,
                    id: data.lossLists.id,
                    openDialog: !this.state.openDialog,
                    diableSaveButton: false,
                    entityName: entity,
                    plantId: data.plants.id,
                    defaultLossList: data.lossLists.defaultLossList
                })
            }
            else if (navigatedFrom === "MachinesTable") {
                this.setState({
                    name: data.lossLists.name,
                    notes: data.lossLists.notes,
                    id: data.lossLists.id,
                    openDialog: !this.state.openDialog,
                    diableSaveButton: false,
                    entityName: entity,
                    plantId: data.lossLists.plants.id,
                    defaultLossList: data.lossLists.defaultLossList
                })
            }
        }
    }

    closeDialog = () => {
        this.setState({
            openDialog: false,
            saveButton: false
        })
    }

    inputChangeHandler = (event, field) => {

        switch (field) {
            case "name": this.setState({ name: event.target.value, diableSaveButton: true }); break;
            case "notes": this.setState({ notes: event.target.value, diableSaveButton: true }); break;
            case "defaultList":
                if (this.state.entityName === "Job List") {
                    this.setState({ defaultJobList: event.target.checked, diableSaveButton: true }); break;
                }
                else {
                    this.setState({ defaultLossList: event.target.checked, diableSaveButton: true }); break;
                }
            default: console.log("default case");
        }
    }

    saveJobList = () => {
        const { name, notes, plantId, id } = this.state;
        let defaultJobList = this.state.defaultJobList;
        let defaultLossList = this.state.defaultLossList;

        if (this.state.entityName === "Job List") {
            this.props.updateJobListsEntity({ name, notes, plantId, id, defaultJobList }, id, this.state.selectedNavTab, this.state.currentScopeSelectionId);
        }
        else if (this.state.entityName === "Loss List") {
            this.props.updateLossListsEntity({ name, notes, plantId, id, defaultLossList }, id, this.state.selectedNavTab, this.state.currentScopeSelectionId);
        }
        this.closeDialog();
    }
    getPlantName = (plantName) => {
        this.setState({ plantName: plantName })
    }
    render() {
        let userOrganization = null;
        if (this.props.userData.scopeSelector) {
            userOrganization = this.props.userData.scopeSelector.subOrganizations.find((organization) => (
                organization.root
            ));
        }
        let tab = 0;
        let selectedScopeData = tab === 2 ? this.props.userData.selectedPlant : this.props.userData.selectedOrganization;
        selectedScopeData = (this.props.userData.selectedTab === 0 || this.props.userData.selectedTab === 4) ? this.props.userData.selectedPlant : this.props.userData.selectedOrganization;

        const component = (Object.keys(this.props.appData).length > 0 && Object.keys(this.props.userData).length > 1 && this.props.userData.loggedIn === true && Object.keys(this.props.config).length > 0) ?
            (
                <Grid container>
                    {/* Comemnted code for left drawer */}
                    <Grid item className="">
                        {/* <Grid item sm={2} > */}
                        {tab ?
                            //  this.props.userData.selectedTab ==4 ?
                            //     <Navigation
                            //     selectedTab={this.props.userData.selectedTab}
                            //     scopeSelector={this.props.userData.scopeSelector}
                            //     navigationTabs={this.getSelectedTab()}
                            //     isAdmin={this.props.userData.admin}
                            //     logo={this.props.userData.logo}
                            //     userOrganization={userOrganization}
                            //     selectedScopeData={selectedScopeData}
                            //     selectedOrganization={this.props.userData.selectedOrganization} 
                            //     getPlantName={this.getPlantName}
                            //     />
                            //  :
                            < Route path="/dashboard" render={(props) => {
                                return (<Navigation {...props}
                                    selectedTab={tab}
                                    scopeSelector={this.props.userData.scopeSelector}
                                    navigationTabs={this.getSelectedTab()}
                                    isAdmin={this.props.userData.admin}
                                    logo={this.props.userData.logo}
                                    userOrganization={userOrganization}
                                    selectedScopeData={selectedScopeData}
                                    getPlantName={this.getPlantName}
                                //selectedOrganization={this.props.userData.selectedOrganization}
                                />)
                            }} />
                            :
                            < Route path="/dashboard" render={(props) => {
                                return (<Navigation {...props}
                                    selectedTab={tab + 5}
                                    scopeSelector={this.props.userData.scopeSelector}
                                    navigationTabs={this.getSelectedTab()}
                                    isAdmin={this.props.userData.admin}
                                    logo={this.props.userData.logo}
                                    userOrganization={userOrganization}
                                    selectedScopeData={selectedScopeData}
                                    selectedOrganization={this.props.userData.selectedOrganization}
                                />)
                            }} />

                        }
                    </Grid>
                    <Grid item sm={12}>
                        <Route path="/dashboard" render={(props) => {
                            return (<Header {...props}
                                userData={this.props.userData}
                                languages={this.props.appData.languages}
                                config={this.props.config}
                                userOrganization={userOrganization.id}
                                tabs={this.props.appData.tabs}
                                isAdmin={this.props.userData.admin}
                                selectedTab={tab}
                                selectedScopeData={selectedScopeData}
                            />)
                        }} />
                    </Grid>
                    <Grid item sm={12} className={this.props.classes.mainAreaBackground}>
                        {/* <Grid item sm={12}>
                            <Route path="/dashboard" render={(props) => {
                                return (<Header {...props}
                                    userData={this.props.userData}
                                    languages={this.props.appData.languages}
                                    config={this.props.config}
                                    userOrganization={userOrganization.id}
                                />)
                            }} />
                        </Grid> */}
                        <Grid item sm={12} >
                            <Grid item sm={12}>
                                {/* <Route path="/dashboard" render={(props) => {
                                    return (<FunctionTabs {...props}
                                        tabs={this.props.appData.tabs}
                                        isAdmin={this.props.userData.admin}
                                        selectedTab={this.props.userData.selectedTab}
                                        selectedScopeData={selectedScopeData}
                                    />)
                                }} /> */}
                            </Grid>
                            <Grid item sm={12} id="monitoringTable">
                                <Switch>
                                    <Route path="/dashboard/MonitoringTable" exact render={(props) => {
                                        return (<MonitoringDashboard dashboardDeviceData={this.props.dashboardDeviceData} selectedTab={this.props.userData.selectedTab} widgetsData={this.props.widgetLanguage} plantName={this.props.userData.plantName} selectedScopeData={this.props.userData.selectedPlant}
                                        />)
                                    }}
                                    />
                                </Switch>
                            </Grid>
                            <Grid item sm={12} id="ProductionDashboard">
                                <Switch>
                                    <Route path="/dashboard/ProductionDashboard" exact render={(props) => {
                                        return (<ProductionDashboard plantName={this.state.plantName} selectedPlantId={this.props.userData.selectedPlant} userData={this.props.userData} selectedOrganization={this.props.userData.selectedOrganization} plantInfo={this.props.userData.scopeSelector.subOrganizations} selectedTab={this.props.userData.selectedTab} dashboardDeviceData={this.props.dashboardDeviceData} widgetsData={this.props.widgetLanguage} />)
                                    }}
                                    />
                                </Switch>
                            </Grid>
                            <Grid item sm={12} className={this.props.classes.settingsAlignment}>
                                <Switch>
                                    <Route path="/dashboard/SpectralFeaturesTable" exact render={(props) => {
                                        return (<SpectralFeaturesTable {...props}
                                            selectedTab={this.props.userData.selectedTab}
                                            selectedScopeData={this.props.userData.selectedTab === 3 ?
                                                this.props.userData.selectedOrganization : this.props.userData.selectedPlant}
                                            navigateToEntityView={(pathname, data, selectedNavTab, currentScopeSelectionId, navigatedFrom) => this.navigateToEntityView(pathname, data, selectedNavTab, currentScopeSelectionId, navigatedFrom)}
                                            config={this.props.config}
                                        />)
                                    }} />
                                    <Route path="/dashboard/OrganizationsTable" exact render={(props) => {
                                        return (<OrganizationsTable {...props}
                                            selectedTab={this.props.userData.selectedTab}
                                            selectedScopeData={this.props.userData.selectedTab === 3 ?
                                                this.props.userData.selectedOrganization : this.props.userData.selectedPlant}
                                            navigateToEntityView={(pathname, data, selectedNavTab) => this.navigateToEntityView(pathname, data, selectedNavTab)}
                                            config={this.props.config}
                                            subOrganizations={this.props.userData.scopeSelector.subOrganizations}
                                        />)
                                    }} />
                                    <Route path="/dashboard/PlantsTable" exact render={(props) => {
                                        return (<PlantsTable {...props}
                                            selectedTab={this.props.userData.selectedTab}
                                            selectedScopeData={this.props.userData.selectedTab === 3 ?
                                                this.props.userData.selectedOrganization : this.props.userData.selectedPlant}
                                            navigateToEntityView={(pathname, data, selectedNavTab, currentScopeSelectionId, navigatedFrom) => this.navigateToEntityView(pathname, data, selectedNavTab, currentScopeSelectionId, navigatedFrom)}
                                            config={this.props.config}
                                            timezones={this.props.timezones}
                                        />)
                                    }} />
                                    <Route path="/dashboard/UsersTable" exact render={(props) => {
                                        return (<UsersTable {...props}
                                            selectedTab={this.props.userData.selectedTab}
                                            alertMediaTypes={this.props.alertMediaTypes}
                                            selectedScopeData={this.props.userData.selectedTab === 3 ?
                                                this.props.userData.selectedOrganization : this.props.userData.selectedPlant}
                                            navigateToEntityView={(pathname, data, selectedNavTab) => this.navigateToEntityView(pathname, data, selectedNavTab)}
                                            config={this.props.config}
                                            timezones={this.props.timezones}
                                        />)
                                    }} />
                                    <Route path="/dashboard/MachineGroupsTable" exact render={(props) => {
                                        return (<MachineGroupsTable {...props}
                                            selectedTab={this.props.userData.selectedTab}
                                            selectedScopeData={this.props.userData.selectedTab === 3 ?
                                                this.props.userData.selectedOrganization : this.props.userData.selectedPlant}
                                            navigateToEntityView={(pathname, data, selectedNavTab, currentScopeSelectionId, navigatedFrom) => this.navigateToEntityView(pathname, data, selectedNavTab, currentScopeSelectionId, navigatedFrom)}
                                            config={this.props.config}
                                        />)
                                    }} />
                                    <Route path="/dashboard/MachinesTable" exact render={(props) => {
                                        return (<MachinesTable {...props}
                                            selectedTab={this.props.userData.selectedTab}
                                            selectedScopeData={this.props.userData.selectedTab === 3 ?
                                                this.props.userData.selectedOrganization : this.props.userData.selectedPlant}
                                            navigateToEntityView={(pathname, data, selectedNavTab, currentScopeSelectionId, navigatedFrom) => this.navigateToEntityView(pathname, data, selectedNavTab, currentScopeSelectionId, navigatedFrom)}
                                            config={this.props.config}
                                        />)
                                    }} />
                                    <Route path="/dashboard/SchedulesTable" exact render={(props) => {
                                        return (<SchedulesTable {...props}
                                            selectedTab={this.props.userData.selectedTab}
                                            selectedScopeData={this.props.userData.selectedTab === 3 ?
                                                this.props.userData.selectedOrganization : this.props.userData.selectedPlant}
                                            navigateToEntityView={(pathname, data, selectedNavTab) => this.navigateToEntityView(pathname, data, selectedNavTab)}
                                            config={this.props.config}
                                        />)
                                    }} />
                                    <Route path="/dashboard/JobsTable" exact render={(props) => {
                                        return (<JobsTable {...props}
                                            data={this.state.data}
                                            selectedTab={this.props.userData.selectedTab}
                                            scopeSelector={this.props.userData.scopeSelector}
                                            selectedScopeData={this.props.userData.selectedTab === 3 ?
                                                this.props.userData.selectedOrganization : this.props.userData.selectedPlant}
                                            navigateToEntityView={(pathname, data, selectedNavTab) => this.navigateToEntityView(pathname, data, selectedNavTab)}
                                            config={this.props.config}
                                        />)
                                    }} />
                                    <Route path="/dashboard/LossCodesTable" exact render={(props) => {
                                        return (<LossCodesTable {...props}
                                            selectedTab={this.props.userData.selectedTab}
                                            scopeSelector={this.props.userData.scopeSelector}
                                            selectedScopeData={this.props.userData.selectedTab === 3 ?
                                                this.props.userData.selectedOrganization : this.props.userData.selectedPlant}
                                            navigateToEntityView={(pathname, data, selectedNavTab) => this.navigateToEntityView(pathname, data, selectedNavTab)}
                                            config={this.props.config}
                                        />)
                                    }} />
                                    <Route path="/dashboard/MonitorsTable" exact render={(props) => {
                                        return (<MonitorsTable {...props}
                                            selectedTab={this.props.userData.selectedTab}
                                            selectedScopeData={this.props.userData.selectedTab === 3 ?
                                                this.props.userData.selectedOrganization : this.props.userData.selectedPlant}
                                            navigateToEntityView={(pathname, data, selectedNavTab) => this.navigateToEntityView(pathname, data, selectedNavTab)}
                                            config={this.props.config}
                                        />)
                                    }} />
                                    <Route path="/dashboard/DevicesTable" exact render={(props) => {
                                        return (<DevicesTable {...props}
                                            selectedTab={this.props.userData.selectedTab}
                                            selectedScopeData={this.props.userData.selectedTab === 3 ?
                                                this.props.userData.selectedOrganization : this.props.userData.selectedPlant}
                                            navigateToEntityView={(pathname, data, selectedNavTab) => this.navigateToEntityView(pathname, data, selectedNavTab)}
                                            config={this.props.config}
                                        />)
                                    }} />
                                    <Route path="/dashboard/ScheduledReportsTable" exact render={(props) => {
                                        return (<ScheduledReportsTable {...props}
                                            selectedTab={this.props.userData.selectedTab}
                                            selectedScopeData={this.props.userData.selectedTab === 3 ?
                                                this.props.userData.selectedOrganization : this.props.userData.selectedPlant}
                                            navigateToEntityView={(pathname, data, selectedNavTab) => this.navigateToEntityView(pathname, data, selectedNavTab)}
                                            config={this.props.config}
                                        />)
                                    }} />
                                    <Route path="/dashboard/AlertsTable" exact render={(props) => {
                                        return (<AlertsTable {...props}
                                            selectedTab={this.props.userData.selectedTab}
                                            selectedScopeData={this.props.userData.selectedTab === 3 ?
                                                this.props.userData.selectedOrganization : this.props.userData.selectedPlant}
                                            navigateToEntityView={(pathname, data, selectedNavTab) => this.navigateToEntityView(pathname, data, selectedNavTab)}
                                            config={this.props.config}
                                        />)
                                    }} />
                                    <Route path="/dashboard/:link/OrganizationsEntity" render={(props) => {
                                        return (<OrganizationsEntity {...props}
                                            selectedTab={this.props.userData.selectedTab}
                                            data={this.state.data}
                                            scopeSelector={this.props.userData.scopeSelector}
                                            selectedScopeData={this.props.userData.selectedTab === 3 ?
                                                this.props.userData.selectedOrganization : ""}
                                            subOrganizations={this.props.userData.scopeSelector.subOrganizations}
                                            languages={this.props.appData.languages}
                                            userData={this.props.userData}
                                            config={this.props.config} />)
                                    }} />
                                    <Route path="/dashboard/:link/PlantsEntity" render={(props) => {
                                        return <PlantsEntity {...props}
                                            data={this.state.data}
                                            scopeSelector={this.props.userData.scopeSelector}
                                            selectedScopeData={this.props.userData.selectedTab === 3 ?
                                                this.props.userData.selectedOrganization : ""}
                                            timezones={this.props.timezones}
                                            subOrganizations={this.props.userData.scopeSelector.subOrganizations}
                                            languages={this.props.appData.languages}
                                            config={this.props.config} />
                                    }} />
                                    <Route path="/dashboard/:link/UsersEntity" render={(props) => {
                                        return <UsersEntity {...props}
                                            languages={this.props.appData.languages}
                                            selectedTab={this.props.userData.selectedTab}
                                            data={this.state.data}
                                            scopeSelector={this.props.userData.scopeSelector}
                                            subOrganizations={this.props.userData.scopeSelector.subOrganizations}
                                            config={this.props.config}
                                            timezones={this.props.timezones}
                                            alertMediaTypes={this.props.alertMediaTypes}
                                            selectedScopeData={this.props.userData.selectedTab === 3 ?
                                                this.props.userData.selectedOrganization : this.props.userData.selectedPlant} />
                                    }} />
                                    <Route path="/dashboard/:link/MachineGroupsEntity" render={(props) => {
                                        return <MachineGroupsEntity {...props}
                                            data={this.state.data}
                                            scopeSelector={this.props.userData.scopeSelector}
                                            subOrganizations={this.props.userData.scopeSelector.subOrganizations}
                                            selectedScopeData={this.props.userData.selectedTab === 2 ?
                                                this.props.userData.selectedPlant : ""}
                                            config={this.props.config} />
                                    }} />
                                    <Route path="/dashboard/:link/MachinesEntity" render={(props) => {
                                        return <MachinesEntity {...props}
                                            data={this.state.data}
                                            scopeSelector={this.props.userData.scopeSelector}
                                            selectedScopeData={this.props.userData.selectedTab === 2 ?
                                                this.props.userData.selectedPlant : ""}
                                            config={this.props.config} />
                                    }} />

                                    <Route path="/dashboard/:link/SchedulesEntity" render={(props) => {
                                        return <SchedulesEntity {...props}
                                            data={this.state.data}
                                            scopeSelector={this.props.userData.scopeSelector}
                                            selectedScopeData={this.props.userData.selectedTab === 2 ?
                                                this.props.userData.selectedPlant : ""}
                                            config={this.props.config} />
                                    }} />
                                    <Route path="/dashboard/:link/JobsEntity" render={(props) => {
                                        return <JobsEntity {...props}
                                            data={this.state.data}
                                            scopeSelector={this.props.userData.scopeSelector}
                                            selectedScopeData={this.props.userData.selectedTab === 2 ?
                                                this.props.userData.selectedPlant : ""}
                                            config={this.props.config} />
                                    }} />
                                    <Route path="/dashboard/:link/LossCodesEntity" render={(props) => {
                                        return <LossCodesEntity {...props}
                                            data={this.state.data}
                                            scopeSelector={this.props.userData.scopeSelector}
                                            selectedScopeData={this.props.userData.selectedTab === 2 ?
                                                this.props.userData.selectedPlant : ""}
                                            config={this.props.config} />
                                    }} />
                                    <Route path="/dashboard/:link/SpectalFeaturesEntity" render={(props) => {
                                        return <SpectalFeaturesEntity {...props}
                                            data={this.state.data}
                                            scopeSelector={this.props.userData.scopeSelector}
                                            selectedScopeData={this.props.userData.selectedTab === 2 ?
                                                this.props.userData.selectedPlant : ""}
                                            config={this.props.config} />
                                    }} />
                                    <Route path="/dashboard/:link/MonitorsEntity" render={(props) => {
                                        return <MonitorsEntity {...props}
                                            data={this.state.data}
                                            scopeSelector={this.props.userData.scopeSelector}
                                            selectedScopeData={this.props.userData.selectedTab === 2 ?
                                                this.props.userData.selectedPlant : ""}
                                            config={this.props.config} />
                                    }} />
                                    <Route path="/dashboard/:link/DevicesEntity" render={(props) => {
                                        return <DevicesEntity {...props}
                                            data={this.state.data}
                                            scopeSelector={this.props.userData.scopeSelector}
                                            selectedScopeData={this.props.userData.selectedTab === 2 ?
                                                this.props.userData.selectedPlant : ""}
                                            config={this.props.config} />
                                    }} />
                                    <Route path="/dashboard/:link/AlertsEntity" render={(props) => {
                                        return <AlertsEntity {...props}
                                            data={this.state.data}
                                            scopeSelector={this.props.userData.scopeSelector}
                                            selectedScopeData={this.props.userData.selectedTab === 2 ?
                                                this.props.userData.selectedPlant : ""}
                                            userData={this.props.userData}
                                            config={this.props.config} />
                                    }} />
                                    <Route path="/dashboard/QuickConfigurationTable" exact render={(props) => {
                                        return (<QuickConfigTableEntity {...props}
                                            selectedTab={this.props.userData.selectedTab}
                                            scopeSelector={this.props.userData.scopeSelector}
                                            selectedScopeData={this.props.userData.selectedTab === 3 ?
                                                this.props.userData.selectedOrganization : this.props.userData.selectedPlant}
                                            navigateToEntityView={(pathname, data, selectedNavTab, currentScopeSelectionId, navigatedFrom) => this.navigateToEntityView(pathname, data, selectedNavTab, currentScopeSelectionId, navigatedFrom)}
                                            config={this.props.config}
                                            userData={this.props.userData}
                                        />)
                                    }} />
                                    <Route path="/dashboard/:link/ScheduledReportsEntity" render={(props) => {
                                        return <ScheduledReportsEntity {...props}
                                            data={this.state.data}
                                            scopeSelector={this.props.userData.scopeSelector}
                                            selectedScopeData={this.props.userData.selectedTab === 2 ?
                                                this.props.userData.selectedPlant : ""}
                                            timezones={this.props.timezones}
                                            config={this.props.config} />
                                    }} />

                                </Switch>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            ) : null;
        return (
            <Fragment>
                {
                    (this.props.loaderState.load.value || this.state.loading) ? (
                        <div className={this.props.classes.loading}>
                            <CircularProgress classes={{ colorPrimary: this.props.classes.loaderColor }} variant="indeterminate" />
                        </div>
                    ) : null
                }
                <div>
                    {component}
                    <Snackbar
                        key="messageInfo"
                        anchorOrigin={{
                            vertical: 'top',
                            horizontal: 'center',
                        }}
                        open={this.state.open}
                        autoHideDuration={5000}
                        onClose={this.handleClose}
                        ContentProps={{
                            'aria-describedby': 'message-id',
                            classes: {
                                root: this.state.error ? this.props.classes.snackbarErrorClasses : this.props.classes.snackbarClasses
                            }
                        }}
                        message={<span id="message-id">{this.getPopUpMessage()}</span>}
                        action={[
                            <IconButton
                                key="close"
                                aria-label="Close"
                                color="inherit"
                                className={this.props.classes.close}
                                onClick={this.handleClose}
                            >
                                <CloseIcon style={{ fontSize: 18 }} />
                            </IconButton>,
                        ]}
                    />
                    {this.state.errorMessage && this.props.userData.selectedTab === 0 ?
                        <Snackbar
                            key="messageInfo"
                            anchorOrigin={{
                                vertical: 'top',
                                horizontal: 'center',
                            }}
                            open={this.state.errorOpen}
                            autoHideDuration={60000}
                            onClose={this.handleErrorClose}
                            ContentProps={{
                                'aria-describedby': 'message-id',
                                classes: {
                                    root: this.props.classes.snackbarErrorClasses
                                }
                            }}
                            message={<span id="message-id">{this.state.errorMessage}</span>}
                            action={[
                                <IconButton
                                    key="close"
                                    aria-label="Close"
                                    color="inherit"
                                    className={this.props.classes.close}
                                    onClick={this.handleErrorClose}
                                >
                                    <CloseIcon style={{ fontSize: 18 }} />
                                </IconButton>,
                            ]}
                        /> : null}
                </div>
                <div>
                    <Dialog
                        onClose={this.closeDialog}
                        aria-labelledby="customized-dialog-title"
                        open={this.state.openDialog}
                        classes={{ paperWidthSm: this.props.classes.settingsModal }}
                    >
                        <div className={this.props.classes.dialogTitle}>
                            <Typography variant="h6">{this.state.entityName}</Typography>
                            <div style={{ display: 'flex', alignItems: 'center' }}>
                                <div style={{ marginRight: 24 }}>
                                    <Checkbox
                                        checked={this.state.entityName === "Job List" ? this.state.defaultJobList : this.state.defaultLossList}
                                        onChange={(event) => (this.inputChangeHandler(event, "defaultList"))}
                                        color="secondary"
                                        classes={{
                                            root: this.props.classes.defaultCheckbox
                                        }}
                                    />
                                    <span className={this.props.classes.inputLabel} style={{ marginLeft: 0 }}>{"Set as plant default"}</span>
                                </div>
                                <span style={{ display: 'inline-block', marginLeft: 15 }}>
                                    <Close onClick={this.closeDialog} style={{ cursor: 'pointer' }} />
                                </span>
                            </div>
                        </div>
                        <Divider />
                        <DialogContent classes={{ root: this.props.classes.dialogContent }}>
                            <div key={"Job List Name"} className={this.props.classes.elementContainer}>
                                <div className={this.props.classes.inputLabel}>{"Job List Name"}</div>
                                <TextField
                                    required
                                    id="name"
                                    className={this.props.classes.textField}
                                    value={this.state.name}
                                    margin="normal"
                                    variant="outlined"
                                    onChange={(event) => this.inputChangeHandler(event, "name")}
                                />
                            </div>
                            {/* <div key={"Plant Name"} className={this.props.classes.elementContainer}>
                                <div className={this.props.classes.inputLabel}>{"Plant Name"}</div>
                                <TextField
                                    required
                                    id="plantName"
                                    disabled
                                    className={this.props.classes.textField}
                                    value={this.state.plantName}
                                    margin="normal"
                                    variant="outlined"
                                />
                            </div> */}
                            <div key={"Notes"} className={this.props.classes.elementContainer}>
                                <div className={this.props.classes.inputLabel}>{"Notes"}</div>
                                <TextField
                                    required
                                    id="notes"
                                    multiline
                                    rows="3"
                                    className={this.props.classes.textField}
                                    value={this.state.notes}
                                    margin="normal"
                                    variant="outlined"
                                    onChange={(event) => this.inputChangeHandler(event, "notes")}
                                />
                            </div>
                        </DialogContent>
                        <DialogActions classes={{ root: this.props.classes.dialogActions }}>
                            <Button
                                variant="contained"
                                color="primary"
                                disabled={!this.state.diableSaveButton}
                                onClick={this.saveJobList}
                                className={this.props.classes.dialogSaveButton}
                            >
                                {this.props.t("Save Changes")}
                            </Button>
                        </DialogActions>
                    </Dialog>
                </div>
            </Fragment>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        appData: state.appReducer,
        userData: state.userDataReducer,
        alertMessage: state.alertMessageReducer,
        loaderState: state.loaderReducer,
        config: state.viewDefinitionReducer.config,
        timezones: state.timezoneReducer.timezones,
        alertMediaTypes: state.alertMediaReducer.alertMediaTypes,
        dashboardDeviceData: state.dashboardDeviceReducer,
        widgetLanguage: state.setWidgetLanguageReducer,
        dashboardType: state.dashboardTypeReducer
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        getAppData: (language) => (dispatch(actionCreators.getAppData(language))),
        isUserAuthenticated: () => (dispatch(actionCreators.isUserAuthenticated())),
        getAlertMessage: () => (dispatch(actionCreators.getAlertMessage())),
        setLoaderState: () => (dispatch(actionCreators.setLoaderState())),
        getViewDefinition: (language) => (dispatch(actionCreators.getViewDefinition(language))),
        updateJobListsEntity: (jobListEntityData, id, selectedNavTab, currentScopeSelectionId) => (dispatch(actionCreators.updateJobListsEntity(jobListEntityData, id, selectedNavTab, currentScopeSelectionId))),
        updateLossListsEntity: (lossListEntityData, id, selectedNavTab, currentScopeSelectionId) => (dispatch(actionCreators.updateLossListsEntity(lossListEntityData, id, selectedNavTab, currentScopeSelectionId))),
        getTimezones: () => (dispatch(actionCreators.getTimezones())),
        getAlertMediaTypes: () => (dispatch(actionCreators.getAlertMediaTypes())),
		getStaticData: () => (dispatch(actionCreators.getStaticData())),
        updateSelectedTab: (selectedTab) => (dispatch(actionCreators.updateSelectedTab(selectedTab))),
        setWidgetLanguage: (language) => (dispatch(actionCreators.setWidgetLanguage(language))),
        updateDrawer: (value) => (dispatch(actionCreators.updateDrawer(value))),
        updatedashboardType: (type) => (dispatch(actionCreators.updatedashboardType(type))),
        setDeviceData: (deviceData) => (dispatch((actionCreators.setDeviceData(deviceData)))),
        getSummaryData: (plantId) => (dispatch((actionCreators.getSummaryData(plantId)))),
        getSummaryPlantData: (t) => (dispatch((actionCreators.getSummaryPlantData(t)))),
        getMonitorList: (plantId, cb) => (dispatch(actionCreators.getMonitorList(plantId, cb))),
        getAppsName: () => (dispatch(actionCreators.getAppsName())),
        updateScopeSelector: (selection, tab, permission, scope, plantName) => (dispatch(actionCreators.updateScopeSelector(selection, tab, permission, scope, plantName))),


    }
}

export default withTranslation()(connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(Dashboard)));
