import * as actionTypes from '../actions/actionTypes';

export const oeeByPartNumberReducer = (state={}, action) => {
    switch(action.type) {

        case actionTypes.SAVE_OEE_BY_PART_NUMBER:{ 
            return Object.assign({}, state, action.payload)
        }
        
        default: return state
    }
}