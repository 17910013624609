import React, { Component } from 'react'
import Chart from "react-google-charts";
import moment from "moment";
import { withTranslation } from "react-i18next";


class MaintenanceMetricsContainer extends Component {
    constructor(props){
        super(props);
        this.state = {
            data: [],
            vtiks: []
        }
    }  
    componentDidMount(){
        let data = [["Maintainence Metric", "Duration",{type: "string", role: "tooltip"}]];
        var maxValue = 0;
        this.props.maintenanceMetricsData.breakdown.forEach(element => {
            if (maxValue < element.duration)
            maxValue = element.duration;
            data.push([element.timestamp,parseInt(element.duration/1000), `Equipment Loss ${element.duration/1000} secs ( ${moment.utc(element.duration).format('HH:mm:ss')} 
             hrs), Percent:   ${parseFloat(element.percent).toFixed(2)} % ` ])
        });

        if (maxValue % 4 != 0){
            maxValue = parseInt(((maxValue/1000) / 4) + 1) * 4;
        }

        var valueStep = (maxValue/1000) / 4;
        var vticks = [{v:0, f:'0 hr'}];

        for (var i = 0; i < 4; i++) {
            vticks.push({v:valueStep*(i+1), f:parseFloat(valueStep*(i+1) / 3600).toFixed(2) + ' hrs'});
        }

        var percentArc = this.props.maintenanceMetricsData.breakdownLosses * (2 * Math.PI) / 100 - 0.5 * Math.PI;

        var c = document.getElementById("maintainenceMetrics");
        var ctx = c.getContext("2d");

        ctx.beginPath();
        ctx.arc(90, 90, 75, 0, 2 * Math.PI);
        ctx.lineWidth = 30;
        ctx.strokeStyle = '#cccccc';
        ctx.stroke();

        ctx.beginPath();
        ctx.arc(90, 90, 75, 0, 2 * Math.PI);
        ctx.lineWidth = 28;
        ctx.strokeStyle = '#fcfcfc';
        ctx.stroke();

        ctx.beginPath();
        ctx.arc(90, 90, 75, -0.5 * Math.PI, percentArc);
        ctx.lineWidth = 22;
        ctx.strokeStyle = '#39b54a';
        ctx.stroke();

        this.setState({
            data: data, vticks: vticks
        })
    }
    
    render() {
        const {t}=this.props;
        return (
            <div className="MaintenanceMetrics-cntr display-text" > 
                <Chart
                width="100%"
                height="260px"
                graph_id={`ComboChart`}
                chartType="ComboChart"
                loader={<div>{t("Loading Chart")}</div>}
                data={this.state.data}
                options={{
                  isStacked: true,
                  vAxis: {title: '', ticks: this.state.vticks},
                  hAxis: { title: '' },
                  legend: 'none',
                  seriesType: 'bars',
                }}
                rootProps={{ 'data-testid': '1' }}
              />
              <div className="productivity-chart-wrp display-text" style={{width:"50%"}}>
              <div className="graph-area">
                  <div className="graph">
                    <canvas id="maintainenceMetrics" width="200" height="200" >
                    </canvas></div>
                  <div className="text">
                    <div className="text-area">
                      <h6><span className="value">{this.props.maintenanceMetricsData.breakdownLosses ? this.props.maintenanceMetricsData.breakdownLosses.toFixed(2) : 0.00}</span><span className="symbol">%</span></h6>
                      <p id="plant_oee_label">{t("BREAKDOWN LOSSES")}</p>
                    </div>
                  </div>
                </div>
            </div>
            <div className='display-text' style={{width:"23%",verticalAlign: "top",marginTop: "50px", fontSize: '24px' }}>
                <p style={{color:"#39b54a"}}>{this.props.maintenanceMetricsData.mttr ? this.props.maintenanceMetricsData.mttr : 0.00} Days</p>
                <p style={{marginLeft:"18px"}}>{t("MTTR")}</p>
            </div>
            <div className='display-text' style={{width:"23%",verticalAlign: "top",marginTop: "50px", fontSize: '24px'}}>
                <p style={{color:"#39b54a"}}>{this.props.maintenanceMetricsData.mtbf ? this.props.maintenanceMetricsData.mtbf : 0.00} Days</p>
                <p style={{marginLeft:"18px"}}>{t("MTBF")}</p>
            </div>
              
            </div>
        )
    }
}
  
export default withTranslation()(MaintenanceMetricsContainer);