import React, { Component } from 'react'
import moment from "moment";
import { Chart } from "react-google-charts";
import { withTranslation } from "react-i18next";

class OeeSelectionContainer extends Component {
    constructor(props){
        super(props);
        this.state={
            data : [],
            options: '',
            chartImageURI: '',
            chartShowTitle: this.props.plantName
        }
        this.chartData = [];
    }  
    componentDidMount(){
        let reportData= this.props.reportData.oeeMachineList;
        let header = [];
        let data = [];
        
        var tooltipName = 'OEE / Hour';    
        header.push(['date', 'Hour', { type: 'string', role: 'tooltip' }])

        let outputMachine = reportData.filter(ele=>ele.machineId==this.props.selectedMachineOee)

        outputMachine[0].subRows.forEach((element,index)=>{ 
            var tooltipContent = element.timeStamp + "\n" + tooltipName + ": " + index + "\n" + " OEE / Hour: " + parseFloat(element.actualOEE).toFixed(2) + "%" + "%\n" + "Availability: " + parseFloat(element.availability).toFixed(2) + "%  " +
            "Productivity: " + parseFloat(element.productivity).toFixed(2) + "%  " + "Quality: " + parseFloat(element.quality).toFixed(2);
            
            header.push([moment(element.timeStamp).toDate(), element.actualOEE, tooltipContent]);
        })
            data.push(header);
            this.setState({data: data})       
    }

    componentDidUpdate(prevProps) {
        if(prevProps.selectedMachineOee !== this.props.selectedMachineOee && this.props.selectedMachineOee<3 ){
            let reportData= this.props.reportData.oeeMachineList;
            let header = [];
            let data = [];

            let outputMachine = reportData.filter(ele=>ele.machineId==this.props.selectedMachineOee)
            
            var tooltipName = 'OEE / Hour';    
            header.push(['date', 'Hour', { type: 'string', role: 'tooltip' }])
            
            outputMachine[0].subRows.forEach((element,index)=>{ 
                var tooltipContent = element.timeStamp + "\n" + tooltipName + ": " + index + "\n" + " OEE / Hour: " + parseFloat(element.actualOEE).toFixed(2) + "%" + "%\n" + "Availability: " + parseFloat(element.availability).toFixed(2) + "%  " +
                "Productivity: " + parseFloat(element.productivity).toFixed(2) + "%  " + "Quality: " + parseFloat(element.quality).toFixed(2);
                
                header.push([moment(element.timeStamp).toDate(), element.actualOEE, tooltipContent]);
            })
                data.push(header);
                let chartShowTitle = this.props.selectedMachineOee == 0 ? this.props.plantName : outputMachine[0].machineName;
                this.setState({ data: data, chartShowTitle : chartShowTitle, chartImageURI:''})       
        }
    }

    chart_events = [
        {
            eventName : 'ready',
            callback  : (ChartRef) => {
                // Returns Chart so you can access props and  the ChartWrapper object from chart.wrapper
                if(this.state.chartImageURI==''){          
                this.setState({
                  chartImageURI: ChartRef.chartWrapper.getChart().getImageURI(),
                },()=>this.props.parentFunChart(this.state.chartImageURI));
              }
            }
        }
      ];

    render() {
        const {t}=this.props;
        return (
            <div className="oeeSelection-cntr display-text" style={{width: "100%"}}> 
                { this.state.data.length>0 ?
                     <div className="chart-body" id="OeeSelectionGraph">
                        <Chart graphID="OeeGoogleChart"
                        width={'100%'}
                        height={'300px'}
                        chartType="LineChart"
                        loader={<div>{t("Loading Chart")}</div>}
                        data={this.state.data[0]}
                        
                        options={{
                            legend: 'none',           
                            title:  `OEE for ${this.state.chartShowTitle}`,
                            hAxis: {
                            title: 'TimeStamp',
                            },
                            vAxis: {
                            title: 'OEE',
                            },
                            series: {0: {color: '#2089fd'}},
                            explorer: {
                                actions: ['dragToZoom', 'rightClickToReset'],
                                maxZoomOut: 1,
                                maxZoomIn: 1024,
                                keepInBounds: true
                            },
                            interpolateNulls: true,
                        }}
                        chartEvents = {this.chart_events}
                
                        />        
                   </div>
                  : null 
                 } 
            </div>
        )
    }
}

export default withTranslation()(OeeSelectionContainer);