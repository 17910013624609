import React, { Component } from 'react'
import { Chart } from "react-google-charts";
import Modal from 'react-responsive-modal';
import Button from '@material-ui/core/Button';
import { connect } from 'react-redux';
import moment from "moment";
import * as actionCreators from '../../../actions/actionCreators';
import { withTranslation } from "react-i18next";

const styles = {
    customModal : {
        modalStyle: "modalStyle"
    }
  };
const clonedeep = require('lodash.clonedeep')


const colorList = ["#FF0000", "#00FF00", "#800000", "#FFFF00", "#808000", "#008000", "#00FFFF", "#008080", "#0000FF"];
const pieOptions = {
        title: "",
        pieHole: 0.6,
        slices: colorList,
        legend: {
          position: "bottom",
          alignment: "center",
          textStyle: {
            color: "233238",
            fontSize: 14
          }
        },
        tooltip: {
          showColorCode: true
        },
        chartArea: {
          left: 0,
          top: 0,
          width: "100%",
          height: "80%"
        },
        fontName: "Roboto"
      };

      const seriesInfo = [ {},
        {type: "line"}]

        const vticks =[ {v: 0, f: "0 hr"},
        {v: 1000, f: "61.54 hrs"},
        {v: 2000, f: "123.07 hrs"},
        {v: 3000, f: "184.61 hrs"},
        {v: 4000, f: "246.15 hrs"}]

      const comboGraphOptions = {
        isStacked: true,
        // vAxis: {title: '', ticks: vticks},
        vAxis: {title: ''},
        // hAxis:{"title":"","maxValue":10},
        height:430,
        chartArea: {
        	height:300,
          top:10,
        },
        hAxis: {
          title: '',
          textStyle:{color: 'black'},
          slantedText: true,  /* Enable slantedText for horizontal axis */
        slantedTextAngle: 45,
        showTextEvery:1
      },
        legend: 'none',
        seriesType: 'bars',
        series: [ {},
          {type: "line"}]
      };

class LossComponentContainer extends Component {
    constructor(props){
        super(props);
        this.state ={ 
          options: {},
          open:false,
          selectedLosses: this.props.lossList ? [0,...this.props.lossList.map(({ id }) => id)] : []
        }  
    }

    componentDidMount() {
      let from = moment(this.props.range[1]).utc().format("YYYY-MM-DDTHH:mm:00") + "Z";
      let to = moment(this.props.range[2]).utc().format("YYYY-MM-DDTHH:mm:00") + "Z";

      let stateFlag = this.props.hiddenLossValue === 'withHiddenLosses'? true : false;
      if(this.props.lossOption === 'byDuration'){
        this.props.getLossData(1,this.props.plantId,from, to , stateFlag);
      }
    }
    
    componentDidUpdate(prevProps, prevState){
      if(prevProps.lossOption !== this.props.lossOption || prevProps.range !== this.props.range){
        let from = moment(this.props.range[1]).utc().format("YYYY-MM-DDTHH:mm:00") + "Z";
        let to = moment(this.props.range[2]).utc().format("YYYY-MM-DDTHH:mm:00") + "Z";

        let stateFlag = this.props.hiddenLossValue === 'withHiddenLosses'? true : false;

        if(this.props.lossOption === 'byDate'){
          this.props.getLossData(4,this.props.plantId,from, to , stateFlag);
        }
        if(this.props.lossOption === 'byFrequency'){
          this.props.getLossData(2,this.props.plantId,from, to , stateFlag);
        }
        if(this.props.lossOption === 'byDuration'){
          this.props.getLossData(1,this.props.plantId,from, to , stateFlag);
        }
        if(this.props.lossOption === 'pieChart'){
          this.props.getLossData(2,this.props.plantId,from, to , stateFlag);
        }
        if(this.props.lossOption === 'byLossType'){
          this.props.getLossData(4,this.props.plantId,from, to , stateFlag, this.state.selectedLosses,);
        }
      }
        if( this.props.lossList && this.props.lossData.lossData && this.props.lossData.lossData !== prevProps.lossData.lossData  || prevProps.hiddenLossValue !== this.props.hiddenLossValue){
          let lossData = this.props.lossData.lossData;
          if(this.props.lossOption === 'byDate'){
          let byDateData = [];
           let arr = [];
           let headers=[];
            headers.push("Date")
            for(var i=0; i<= this.props.lossList.length; i++){
              headers.push("Losses Duration",{type: "string", role: "tooltip"});
            }
            let maxValue= 0;
            lossData.forEach(ele=>{
                byDateData = [];
                      this.props.lossList.forEach(loss=>{
                        let found = ele.dataList.find(e => parseInt(e.lossType) === parseInt(loss.id));
                      if(found){
                        maxValue+= found.duration/1000;
                         byDateData.push(parseInt(found.duration/1000));
                         byDateData.push(`${loss.value} ${found.duration/1000} secs ${moment.utc(found.duration).format('HH:mm:ss')}`);
                        }else{
                          byDateData.push(0,"");
                        }
                      })
                      let hiddenLoss = ele.dataList.find(e => parseInt(e.lossType) === 0);
                          if(hiddenLoss && this.props.hiddenLossValue === 'withHiddenLosses'){
                            maxValue+= hiddenLoss.duration/1000;
                            byDateData.unshift(`Hidden Loss ${hiddenLoss.duration/1000} secs ${moment.utc(hiddenLoss.duration).format('HH:mm:ss')}`);
                            byDateData.unshift(parseInt(hiddenLoss.duration/1000));
                          }else{
                            byDateData.push(0,"");
                          }
                          byDateData.unshift(moment(ele.timestamp).utc().local().format('YYYY-MM-DD HH:mm:ss'));
                arr.push(byDateData);
            })

            if (maxValue % 4 != 0){
                maxValue = parseInt(((maxValue/3) / 4) + 1) * 4;
            }

            var valueStep = maxValue / 4;
            var vticks = [{v:0, f:'0 hrs'}];

          for (var i = 0; i < 4; i++) {
              vticks.push({v:valueStep*(i+1), f:parseFloat(valueStep*(i+1) / 3600).toFixed(2) + ' hrs'});
           }
            let options = {
              isStacked: true,
              vAxis: {title: '', ticks: vticks},
              hAxis: {
                title: '',
                textStyle:{color: 'black'},
                slantedText: true,  /* Enable slantedText for horizontal axis */
              slantedTextAngle: 45,
              showTextEvery:1
            },
              legend: 'none',
              seriesType: 'bars',
            }

            // let options = clonedeep(graphOptions);
            this.setState({
              data : [headers,...arr],
              options: options
            });
        }
        if(this.props.lossOption === 'byDuration'){
          let header = ["LossID", "Value0",{type: "string", role: "tooltip"}, "Cumulative Frequency", {type: "string", role: "tooltip"}];
          let data = [];
          let total = 0;
          var sum = 0;
          for(let row=0; row<lossData.length; row++) {
            sum += lossData[row].duration/1000;
          }
          lossData.sort( function(a,b) {
            return b.duration - a.duration;
          });
          lossData.forEach(ele=>{
            if(this.props.hiddenLossValue  === 'withoutHiddenLosses' && ele.lossType === 'HIDDENLOSS'){
            }else{
            total += ele.duration/1000;
            let loss = {};
            if(ele.lossType === 'HIDDENLOSS'){
              loss.value = 'HIDDENLOSS';
            }else{
              loss = this.props.lossList.find(el=> el.id === parseInt(ele.lossType));
            }
            data.push([loss.value, parseInt(ele.duration/1000),  `${loss.value} : ${ele.duration/1000} secs ${moment.utc(ele.duration).format('HH:mm:ss')} `, total, `${loss.value}\nCumulative Percent: ${((total/sum)*100).toFixed(2)}%\nCumulative Time: ${parseFloat(total).toFixed(2)} secs( ${moment.utc(total*1000).format('HH:mm:ss')})`])
            }
          })

          let maxValue = sum;
          if (maxValue % 4 != 0)
                maxValue = parseInt((maxValue / 4) + 1) * 4;

            var valueStep = maxValue / 4;
            var vticks = [{v:0, f:'0 hr'}];

          for (var i = 0; i < 4; i++) {
              vticks.push({v:valueStep*(i+1), f:parseFloat(valueStep*(i+1) / 3600).toFixed(2) + ' hrs'});
           }
          let options = {
            isStacked: true,
            vAxis: {title: '', ticks: vticks},
            // hAxis: {title: ''},
            height:430,
            chartArea: {
              height:300,
              top:10,
            },
            hAxis: {
              title: '',
              textStyle:{color: 'black'},
              slantedText: true,  /* Enable slantedText for horizontal axis */
            slantedTextAngle: 45,
            showTextEvery:1
          },
            legend: 'none',
            seriesType: 'bars',
            series: [ {},
              {type: "line"}]
          };
          this.setState({
            data: [header, ...data],
            options: options
          })
        }
        if(this.props.lossOption === 'byFrequency'){
          let header = ["LossID", "Value0",{type: "string", role: "tooltip"}, "Cumulative Frequency", {type: "string", role: "tooltip"}];
          let data = [];
          let total = 0;
          var sum = 0;
          for(let row=0; row<lossData.length; row++) {
            sum += lossData[row].duration;
          }
          lossData.sort( function(a,b) {
            return b.duration - a.duration;
          });
          lossData.forEach(ele=>{
            if(this.props.hiddenLossValue  === 'withoutHiddenLosses' && ele.lossType === 'HIDDENLOSS'){
            }else{
            total += parseInt(ele.duration);
            let loss = {};
            if(ele.lossType === 'HIDDENLOSS'){
              loss.value = 'HIDDENLOSS';
            }else{
              loss = this.props.lossList.find(el=> el.id === parseInt(ele.lossType));
            }
            data.push([loss.value, parseInt(ele.duration),  `${loss.value} : ${ele.duration}`, total, `${loss.value}\nCumulative Percent: ${((total/sum)*100).toFixed(2)}%\nCumulative Frequency: ${total}`])
            }
          })
          let options = clonedeep(comboGraphOptions);
          this.setState({
            data: [header, ...data],
            options: options
          })
        }
        if(this.props.lossOption === 'pieChart'){
          let header = ["LossID", "Value0",{type: "string", role: "tooltip"}];
          let data = [];
          lossData.forEach(ele=>{
            if(this.props.hiddenLossValue  === 'withoutHiddenLosses' && ele.lossType === 'HIDDENLOSS'){
            }else{
              let loss = {};
              if(ele.lossType === 'HIDDENLOSS'){
                loss.value = 'HIDDENLOSS';
                data.unshift([loss.value, parseInt(ele.duration),  `${loss.value} : ${ele.duration}`])
              }else{
                loss = this.props.lossList.find(el=> el.id === parseInt(ele.lossType));
                data.push([loss.value, parseInt(ele.duration),  `${loss.value} : ${ele.duration}`])
              }
              
            }
          })
          let options = clonedeep(pieOptions);
          this.setState({
            data: [header, ...data],
            options: options
          })
        }
        if(this.props.lossOption === 'byLossType'){
          let lossData = this.props.lossData.lossData;
          this.drawByLossTypeGraph(lossData);
        }
      }
      if(this.state.selectedLosses !== prevState.selectedLosses ){
        let lossData = this.props.lossData.lossData;
        this.drawByLossTypeGraph(lossData);
      }        
    }

    drawByLossTypeGraph=(lossData)=>{
      let byDateData = [];
       let arr = [];
       let headers=[];
        headers.push("Date")
        for(var i=0; i< this.state.selectedLosses.length; i++){
          headers.push("Losses Duration",{type: "string", role: "tooltip"});
        }
        let maxValue = 0;
        lossData.forEach(ele=>{
            byDateData = [];
            
                  this.state.selectedLosses.forEach(loss=>{
                    let l = this.props.lossList.find(el=> parseInt(el.id) === parseInt(loss));
                    let found = ele.dataList.find(e => parseInt(e.lossType) === parseInt(loss));
                    if(!l && loss === 0){
                      l ={value : 'Hidden Loss'};
                      if(found){
                      maxValue += found.duration/1000;
                      byDateData.unshift(`${l.value} ${found.duration/1000} secs ${moment.utc(found.duration).format('HH:mm:ss')}`);
                      byDateData.unshift(found.duration/1000);
                      }else{
                        byDateData.push(0,"");

                      }
                    }else{
                    if(found){
                    maxValue += found.duration/1000;
                     byDateData.push(found.duration/1000);
                     byDateData.push(`${l.value} ${found.duration/1000} secs ${moment.utc(found.duration).format('HH:mm:ss')}`);
                    }else{
                      byDateData.push(0,"");
                    }
                  }
                  })
                  byDateData.unshift(moment(ele.timestamp).utc().local().format('YYYY-MM-DD HH:mm:ss'));
            arr.push(byDateData);
        })

        if (maxValue % 4 != 0){
          maxValue = parseInt(((maxValue/3) / 4) + 1) * 4;
        }

        var valueStep = maxValue / 4;
        var vticks = [{ v: 0, f: '0 hr' }];

        for (var i = 0; i < 4; i++) {
          vticks.push({ v: valueStep * (i + 1), f: parseFloat(valueStep * (i + 1) / 3600).toFixed(2) + ' hrs' });
        }
        let options = {
          isStacked: true,
          vAxis: { title: '', ticks: vticks },
          hAxis: {
            title: '',
            textStyle:{color: 'black'},
            slantedText: true,  /* Enable slantedText for horizontal axis */
          slantedTextAngle: 45,
          showTextEvery:1
        },
          legend: 'none',
          seriesType: 'bars',
        }
        // let options = clonedeep(graphOptions);
        this.setState({
          data : [headers,...arr],
          options: options
        });
    }


    handleLossesFormSubmit = (formSubmitEvent) => {
      formSubmitEvent.preventDefault();
      this.setState({drawByLossTypeGraph : true})
      this.props.lossesModalClose();
    }
    
    onLossChange = (input,id) => { 
      let { value, checked } = input.target;
      if( input.target.checked ){
        this.setState({selectedLosses: [...this.state.selectedLosses, id]})
      }else{
        let losses = this.state.selectedLosses.filter(function(val) { return val != id; });
        this.setState({selectedLosses: losses})
      }
    }

    checkChecked(id){
      let found = this.state.selectedLosses.find(e => e === id);
      if(found){
        return true
      }else if(found === 0){
        return true
      }else{
        return false;
      }

    }

    render() {
      const {t}=this.props;
      let lossList = [{id:0,value:'Hidden Loss'},...this.props.lossList];
        return (
            <div className="lossComponent-cntr"> 
                {(this.props.lossOption != 'pieChart') ? 
                    <Chart
                    width={'500px'}
                    height={'400px'}
                    graph_id="ComboChart"
                    chartType="ComboChart"
                    loader={<div>{t("Loading Chart")}</div>}
                    data={this.state.data}
                    options={this.state.options}
                    rootProps={{ 'data-testid': '1' }}
                /> :
                    null
               }
                {(this.props.lossOption == 'pieChart') ? <Chart
                        chartType="PieChart"
                        data={this.state.data}
                        options={pieOptions}
                        loader={<div>{t("Loading Chart")}</div>}
                        graph_id="PieChart"
                        width={"100%"}
                        height={"300px"}
                        legend_toggle
                    /> : null
                }
                <Modal open={this.props.open} onClose={this.props.lossesModalClose} center classNames={{modal: styles.customModal}}>
                    <div className="machineList-dialog-wrp">
                        <h2>{t("Select Losses")}</h2>
                        <form  onSubmit={this.handleLossesFormSubmit}>  
                        <div className="report-dialog">   
                                <div style={{height: "170px",overflowY: "scroll"}}>     
                                    {lossList.map((list, index) =>
                                        <div key={index}  style={ index==0 ? {padding: "4px"} : {padding: "4px",borderTop: "1px solid #ddd"}}>
                                            <input type="checkbox" name="alarm_report_machine" className="machine_input" checked={true === this.checkChecked(list.id) ? true  : false} onChange={(input) => this.onLossChange(input,list.id)}></input>
                                            <label value={list.id}>{list.value}</label>
                                        </div>
                                    )}                      
                                </div>
                        </div>
                        <div className="dialog-btn-wrp">
                            <Button className="dialog-btn select-btn" type="submit" color="primary" autoFocus>{t("Select Losses")}</Button>
                            <Button className="dialog-btn cancel-btn" onClick={() => this.props.lossesModalClose()} color="primary" autoFocus>{t("Cancel")}</Button>
                        </div>
                        </form>
                    </div>
                </Modal>
            </div>
        )
    }
}


const mapStateToProps = state => {
  return {
    lossData : state.lossDataReducer
  }
}

const mapDispatchToProps = dispatch => {
  return {
    getLossData: (type, plantId, from, to, lossIds, stateFlag) => (dispatch((actionCreators.getLossData(type, plantId, from, to, lossIds, stateFlag)))),        
  }
}

// export default LossComponentContainer;
export default withTranslation()(connect(mapStateToProps, mapDispatchToProps)(LossComponentContainer));