import React, { Component } from 'react';
import * as actionCreators from '../../actions/actionCreators';
import { connect } from 'react-redux';
import { makeStyles, useTheme } from '@material-ui/core/styles';
// import { Theme } from '@material-ui/core/styles/createMuiTheme';
import { KeyboardBackspace } from '@material-ui/icons';
import CloseIcon from '@material-ui/icons/Close';
import { Close } from '@material-ui/icons';
import { Scrollbars } from 'react-custom-scrollbars';
import {
    Card, CardContent, MenuItem, Snackbar, IconButton, Input, Chip, Checkbox, ListItemText, InputLabel, TextField, Select, withStyles, OutlinedInput, Button
} from '@material-ui/core';
import { Delete, Add } from '@material-ui/icons';

import { withTranslation } from "react-i18next";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250,
        },
    },

};

const styles = (theme) => ({
    formControl: {
        margin: 1,
        minWidth: 120,
        maxWidth: 300,
    },
    chips: {
        display: 'flex',
        flexWrap: 'wrap',
    },
    chip: {
        backgroundColor: "#ef6e37 !important",
        margin: 2,
    },
    selected: {
        backgroundColor: "#f97942e6 !important",
        color: "white",
    },
    snackbarClasses: {
        backgroundColor: '#43a047'
    },
    snackbarErrorClasses: {
        backgroundColor: '#d32f2f',
        flexWrap: 'nowrap'
    },
    close: {
        padding: theme.spacing.unit / 2,
    },
    noLabel: {
        marginTop: 3,
    },
    cardContent: {
        height: '91%',
        "overflow-y": "auto"
    },
    cardContentRoot: {
        '&:last-child': {
            paddingBottom: 16
        }
    },
    card: {
        width: '100%',
        borderRadius: 0,
        height: '80vh'
    }, textField: {
        width: '60%',
        marginRight: 20
    },
    textArea: {
        width: '80%',
        marginRight: 20
    },
    outlinedInput: {
        padding: 14
    },

    saveButton: {
        fontSize: "14px",
        fontWeight: "500",
        textAlign: "center",
        color: "#ffffff",
        backgroundColor: "#42D784",
        '&:hover': {
            backgroundColor: '#42d784'
        },
        '&:disabled': {
            backgroundColor: '#f6f6f6'
        },
        borderRadius: "3px",
        boxShadow: "0 0 1px 0 rgba(0, 0, 0, 0.05)",
        width: "110px",
        height: "36px",
        marginRight: "24px"
    },
    inputFieldDiv: {
        display: "flex",
        justifyContent: "space-between",
        width: '50%',
        float: 'left',
        paddingTop: '1%',
        height: 80,
        'align-items': 'baseline'
    },
    textareaInputFieldDiv: {
        display: "flex",
        justifyContent: "space-between",
        width: '100%',
        float: 'left',
        paddingTop: '1%',
        height: 180,
        'align-items': 'baseline'
    },
    textFieldLabel: {
        color: 'rgba(0, 0, 0, 0.4000000059604645)',
        'letter-spacing': 0.5,
        'font-weight': 500,
        fontSize: "14px",
        lineHeight: 2.5,
        width: '30%',
        marginLeft: 20,
        //text-align': 'right'
    },
    textareaFieldLabel: {
        color: 'rgba(0, 0, 0, 0.4000000059604645)',
        'letter-spacing': 0.5,
        'font-weight': 500,
        fontSize: "14px",
        lineHeight: 2.5,
        width: '15%',
        marginLeft: 20,
        //'text-align': 'right'
    },
    requiredClass: {
        color: '#d32f2f'
    },
    actionButtonContainer: {
        display: 'flex',
        'justify-content': 'space-between',
        height: 50,
        'border-bottom': '1px solid rgba(207, 216, 220, 0.5)'
    },
    backButtonContainer: {
        display: 'flex',
        'align-items': 'center',
        paddingLeft: 21
    },
    arrowBack: {
        cursor: 'pointer'
    },
    entityTitle: {
        color: 'rgba(0, 0, 0, 0.8)',
        'font-family': 'Roboto',
        'font-size': 21,
        'font-weight': '400',
        'letter-spacing': 0.5,
        marginLeft: 10
    },
    deleteIcon: {
        'font-size': 18,
        cursor: 'pointer',
        color: 'rgba(221, 44, 0, 0.5)',
        '&:hover': {
            color: 'rgba(221, 44, 0, 1.0)'
        }
    },
    addIcon: {
        'font-size': 18,
        cursor: 'pointer',
        color: 'green',
        '&:hover': {
            color: 'lightgreen'
        }
    },
})


class ScheduledReportsEntity extends Component {

    state = {
        addNew: true,
        disableSaveButton: true,
        plantId: "",
        reportTypes: [],
        entityType: "Machine",
        entityValue: [],
        reportingInterval: "",
        timeZone: "",
        emailIds: "",
        labelWidth: 0,
        email1: "",
        reportingType: "Current Plant",
        reportTypeError: false,
        entityTypeError: false,
        entityValueError: false,
        reportingintervalError: false,
        rendorValue: "",
        timeZoneError: false,
        email1Error: false,
        open: false,
        error: true,
        emailMsg: this.props.t("Please enter valid email"),
        reportTypeList: ["Parameter Report", "Equipment Report", "Alarm Report"],
        entityValueList: [],
        disableEntityDropdown: false,
        disableEntityTypeDropdown: false,
        documents: [],
        documentsError: []
    }

    // Handle changes to any user input field
    inputChangeHandler = (event, field) => {

        switch (field) {
            case "reportingInterval": this.setState({ reportingInterval: event.target.value, disableSaveButton: false });
                this.checkValidation("reportingInterval", event.target.value);
                break;
            case "timeZone": this.setState({ timeZone: event.target.value, disableSaveButton: false });
                this.checkValidation("timeZone", event.target.value);
                break;
            case "entityType": this.setState({ entityType: event.target.value, disableSaveButton: false },
                () => {
                    this.setState({ entityValue: [] })
                    this.getEntityValues();
                });
                this.checkValidation("entityType", event.target.value);
                break;
            case "email1": this.setState({ email1: event.target.value, disableSaveButton: false });
                this.checkValidation("emailAddress", event.target.value, "email1Error");
                break;

            case "reportingType": this.setState({ reportingType: event.target.value, disableSaveButton: false });
                break;
            default: console.log("default case");
        }
    }

    reportTypeChange = (event) => {
        this.setState({ reportTypes: event.target.value, disableSaveButton: false })
    }

    entityValueChange = (value) => {
        var arr = [];
        // arr.push(this.state.entityValueList[0]);
        if (value.length === 0) {
            this.setState({
                entityValue: arr
            }, () => {
                this.checkValidation("entityValue", this.state.entityType)
            });
        } else {
            this.setState({ entityValue: value }, () => {
                this.checkValidation("entityValue", this.state.entityType)
            });
        }

    }
    // Handle routing
    goToBackPage = () => {
        this.props.history.goBack();
    }

    // update devices entity
    updateDevicesData = () => {
        /* var reportType = [];
         this.state.reportTypes.map((type) => {
             reportType.push(type.value);
         }); 
        var entityValues = [];
        this.state.entityValue.map((entityval) => {
            entityValues.push(entityval.key);
        });*/

        var emails = [];
        if (this.state.email1 && this.state.email1 !== "") { emails.push(this.state.email1) }
        this.state.documents && this.state.documents.map((item, index) => {
            if (item !== "" && ((this.state.documentsError[index] !== true) || (this.state.documentsError[index] !== "true"))) {
                emails.push(item)
            }

        })
        let entityIds=[],array2=this.state.entityValueList,array1=this.state.entityValue;
        array2 = array2.filter(function(item) {
            return array1.includes(item.value);
          });
          array2 && array2.map((item)=>{
            entityIds.push(item.key)
          })
        var reportObj = {};
        if (this.state.reportTypes.length == 1 && this.state.reportTypes[0] === "Summary Report") {
            reportObj = { plantId: this.state.plantId, reportType: this.state.reportTypes, reportingInterval: this.state.reportingInterval, timeZone: this.state.timeZone, entityType: "", entityIds: [], emailAddresses: emails, addNew: this.state.addNew}
        } else {
            reportObj = { plantId: this.state.plantId, reportType: this.state.reportTypes, reportingInterval: this.state.reportingInterval, timeZone: this.state.timeZone, entityType: this.state.entityType, entityIds, emailAddresses: emails, addNew: this.state.addNew}
        }
        if (!this.state.addNew) {
            if (this.state.reportTypes.length == 1 && this.state.reportTypes[0] === "Summary Report") {
                reportObj = { id: this.props && this.props.data && this.props.data.id, plantId: this.state.plantId, reportType: this.state.reportTypes, reportingInterval: this.state.reportingInterval, timeZone: this.state.timeZone, entityType: "", entityIds: [], emailAddresses: emails, addNew: this.state.addNew}
            } else {
                reportObj = { id: this.props && this.props.data && this.props.data.id, plantId: this.state.plantId, reportType: this.state.reportTypes, reportingInterval: this.state.reportingInterval, timeZone: this.state.timeZone, entityType: this.state.entityType, entityIds, emailAddresses: emails, addNew: this.state.addNew}
            }
        }
        this.props.updateScheduledReportsEntity(reportObj, this.state.addNew, this.props && this.props.data && this.props.data.id);
    }

    // create reporting intervals dropdown list
    getReportingIntervals = () => {
        let reportingList = [];
        reportingList.push(<option key={""} disabled={true} value={""}>{this.props.t("Select")}</option>);
        reportingList.push(<option key="Daily" value="Daily">{this.props.t("Daily")}</option>);
        // reportingList.push(<option key="Weekly" value="Weekly">Weekly</option>);
        return reportingList;
    }
    //TimeZone

    getTimezones = () => {
        let timeZoneList = [];
        timeZoneList.push(<option key={""} disabled={true} value={""}>{this.props.t("Select")}</option>);
        this.props.timezones.map((item) => (
            timeZoneList.push(<option value={item} key={item}>{item}</option>)
        ))
        return timeZoneList;
    }
    //Entity Types

    getEntityTypes = () => {
        let EntityTypeList = [];
        EntityTypeList.push(<option key={""} disabled={true} value={""}>{this.props.t("Select")}</option>);
        EntityTypeList.push(<option key="machine" value="Machine">{this.props.t("Asset")}</option>);
        EntityTypeList.push(<option key="monitor" value="Monitoring Location">{this.props.t("Measurement Location")}</option>);
        return EntityTypeList;
    }
    //Entity Values
    getEntityValues = () => {
        var machinesList = [];
        if (this.state.entityType === "Machine") {
            if (this.props.machinesData && this.props.machinesData.tableData) {
                this.props.machinesData.tableData.map((data) => {
                    machinesList.push(
                        { key: data.id, value: data.label }
                    );
                })
            }

        } else {
            if (this.props.monitorsData && this.props.monitorsData.tableData) {
                this.props.monitorsData.tableData.map((data) => {
                    machinesList.push(
                        { key: data.id, value: data.label }
                    );
                })
            }

        }
        this.setState({ entityValueList: machinesList });
    }

    componentDidMount = () => {
        if (this.props.data) {
            let arrObj = this.props.data.reportType;
            let reportType;
            if(arrObj.includes('\n')){
                reportType = arrObj.split("\n");
            }else{
                reportType=[arrObj]
            }
            let emails = this.props.data.emailAddresses;
            let arrEmailObj = emails;
            if (emails.includes('\n')) {
                arrEmailObj = emails.split("\n");
            }
            let check = Array.isArray(arrEmailObj);
            let documents = [];
            let documentsError = []
            if (check) {
                arrEmailObj.map((item, index) => {
                    if (index === 0) {
                        this.setState({ email1: item })
                    }
                    documents[index] = item;
                    documentsError[index] = "";
                    this.setState(prevState => ({ documents: [...prevState.documents, documents[index]], documentsError: [...prevState.documentsError, documentsError[index]] }))

                })
            } else {
                this.setState({ email1: this.props.data.emailAddresses })
            }

            let entityObj = this.props.data.entityNames;
            let entityValue = entityObj.split("\n");
            if(this.props.data.entityIds.length ===0){
                entityValue=[];
            }
            let entityValueList = [];
            if (Array.isArray(entityValue)) {
                entityValue.map((item, index) => {
                    if(item){
                        entityValueList.push({ "key": this.props.data.entityIds[index], "value": item })
                    }
                })
            }
            this.setState({
                //reportTypes: arr,
                reportingInterval: this.props.data.reportingInterval,
                reportingType: this.props.data.reportingType,
                timeZone: this.props.data.timeZone,
                entityIds: this.props.data.entityIds,
                entityValue: entityValue,
                reportTypes: reportType,
                entityValueList,
                entityType: this.props.data.entityType
            }, () => {
                let documents = [...this.state.documents];
                documents.shift();
                let documentsError = [... this.state.documentsError];
                documentsError.shift();
                this.setState({ documents, documentsError })
            })
            let entityNames = Object.keys(this.props.data.entityNames)
            entityNames.map((data) => {
                if (this.props.data[data] === null) {
                    this.props.data[data] = ""
                }
            })
            this.setState({
                deviceIdentifier: this.props.data.deviceIdentifier,
                firmwareVersion: this.props.data.firmwareVersion,
                addNew: false

            })

            if (this.props.data.specs && this.props.data.specs != "") {
                if (this.props.data.specs.props) {
                    this.setState({ specs: this.props.data.specs.props.children })
                }
                else {
                    this.setState({ specs: this.props.data.specs })
                }
            }
            if (this.props.data.notes && this.props.data.notes != "") {
                if (this.props.data.notes.props) {
                    this.setState({ notes: this.props.data.notes.props.children })
                }
                else {
                    this.setState({ notes: this.props.data.notes })
                }
            }
            if (this.props.data.deviceTypes && this.props.data.deviceTypes.id) {
                this.setState({ deviceTypesId: this.props.data.deviceTypes.id });
            }
        }

        if (!this.props.data) {
            this.setState({ addNew: true })
        }

        if (this.props.selectedScopeData) {
            this.setState({
                plantId: this.props.selectedScopeData,

            }, () => {
                this.props.getTableData("MachinesTable", this.state.plantId, true);
                this.props.getTableData("MonitorsTable", this.state.plantId, true);
            })

        }
    }

    componentDidUpdate = (prevProps, prevState) => {
        if (this.state.plantId && this.state.plantId != "" && prevState.plantId != this.state.plantId) {
            var arr = [];
            // arr.push(this.state.reportTypeList[0]);
            this.setState({
                //reportTypes: arr,
                reportingInterval: "Daily",
                timeZone: "(GMT+5:30) IST",
            });
            this.getEntityValues();
        }

        if ((prevProps.machinesData != this.props.machinesData) ||(prevProps.monitorsData != this.props.monitorsData)) {
            this.getEntityValues();

        }
    }

    // disable save button based on validations
    disableSaveButton = () => {
        let flag = false;
        this.state.documentsError.map((item) => {
            if (item === "true" || item === true) {
                flag = true;
            }
        })
        if (this.state.reportTypes.length === 0) {
            return true;
        }
        else if (this.state.reportingInterval === "") {
            return true;
        }
        else if (this.state.timeZone === "") {
            return true;
        }
        else if (this.state.entityType === "" && !(this.state.reportTypes.length == 1 && this.state.reportTypes[0] === "Summary Report")) {
            return true;
        }
        else if (this.state.entityValue.length === 0 && !(this.state.reportTypes.length == 1 && this.state.reportTypes[0] === "Summary Report")) {
            return true;
        }
        else if (this.state.email1 === "") {
            return true;
        }
        else if (this.state.reportTypeError || this.state.reportingintervalError || this.state.timeZoneError || this.state.entityTypeError || this.state.entityValueError || this.state.email1Error || flag) {
            return true;
        }

        else {
            return false;
        }
    }

    disableEntityDropdown = () => {
        if (this.state.reportTypes.length == 1 && this.state.reportTypes[0] === "Summary Report") {
            return true;
        } else {
            return false;
        }
    }

    disableEntityTypeDropdown = () => {
        if (this.state.reportTypes.length == 1 && this.state.reportTypes[0] === "Summary Report") {
            return true;
        } else {
            return false;
        }
    }

    // check validations of the user input fields
    checkValidation = (field, value, errorName) => {
        var valid = true;
        switch (field) {
            case 'emailAddress':
                valid = value.length >= 6 && valid;
                valid = value.length <= 45 && valid;
                valid = value.trim() !== "" && valid;
                const regEx = RegExp(/^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/);
                valid = regEx.test(value) && valid;
                if (errorName === "email1Error") {
                    if ((this.state.email1Error && value === "") || (!this.state.email1Error && value === "")) {
                        this.setState({
                            email1Error: false
                        })
                    } else if ((this.state.email1Error && value != "" && valid) || (!this.state.email1Error && value != "" && valid)) {
                        this.setState({
                            email1Error: false
                        })
                    } else {
                        this.setState({
                            email1Error: true
                        })
                    }

                }
                break;
            case 'entityValue':
                valid = value.length > 0 && valid;
                this.setState({ entityValueError: !valid })
                break;
        }
    }

    // set card header
    getCardHeader = () => {
        if (this.state.addNew) {
            return this.props.t("Create New");
        } else {
            return this.props.t("Update Report");

        }

    }

    handleChange = (event) => {
        if (event.target.value.length <= 20) {
            this.setState({ entityValue:event.target.value,disableSaveButton: false })
        } else {
            // alert("You can select maximum 15 values");
            this.setState({ open: true });

        }
    };


    handleClose = () => {
        this.setState({ open: false });
    }

    add = () => {
        this.setState(prevState => ({ documents: [...prevState.documents, ''], documentsError: [...prevState.documentsError, ''] }));
    }

    removeClick = (i) => {
        let documents = [...this.state.documents];
        documents.splice(i, 1);
        let documentsError = [... this.state.documentsError];
        documentsError.splice(i, 1)
        this.setState({ documents, documentsError, disableSaveButton: false });
    }

    handleChangeEmail = (i, event) => {
        let documents = [...this.state.documents];
        let documentsError = [...this.state.documentsError]
        documents[i] = event.target.value;
        var valid = true;
        valid = documents[i].length >= 6 && valid;
        valid = documents[i].length <= 45 && valid;
        valid = documents[i].trim() !== "" && valid;
        const regEx = RegExp(/^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/);
        valid = regEx.test(documents[i]) && valid;
        if ((documents[i] && documents[i] === "") || (!documents[i] && documents[i] === "")) {
            documentsError[i] = false;
            this.setState({
                documentsError
            })
        } else if ((documents[i] && documents[i] != "" && valid) || (!documents[i] && documents[i] != "" && valid)) {
            documentsError[i] = false
            this.setState({
                documentsError
            })
        } else {
            documentsError[i] = true;
            this.setState({
                documentsError
            })
        }
        this.setState({ documents, disableSaveButton: false });
    }


    render() {
        const { t } = this.props;
        return (
            <Card className={this.props.classes.card}>
                <div className={this.props.classes.actionButtonContainer}>
                    <div className={this.props.classes.backButtonContainer}>
                        <KeyboardBackspace onClick={this.goToBackPage} classes={{ root: this.props.classes.arrowBack }} />
                        <div className={this.props.classes.entityTitle}>{this.getCardHeader()}</div>
                    </div>
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                        <Button variant="contained"
                            className={this.props.classes.saveButton}
                            disabled={this.state.disableSaveButton || this.disableSaveButton()}
                            onClick={this.updateDevicesData}>{this.props.t("Save")}</Button>
                    </div>
                </div>
                <Snackbar
                    key="messageInfo"
                    anchorOrigin={{
                        vertical: 'top',
                        horizontal: 'center',
                    }}
                    open={this.state.open}
                    autoHideDuration={5000}
                    onClose={this.handleClose}
                    ContentProps={{
                        'aria-describedby': 'message-id',
                        classes: {
                            root: this.state.error ? this.props.classes.snackbarErrorClasses : this.props.classes.snackbarClasses
                        }
                    }}
                    message={<span id="message-id">You can select maximum 20 entity values.</span>}
                    action={[
                        <IconButton
                            key="close"
                            aria-label="Close"
                            color="inherit"
                            className={this.props.classes.close}
                            onClick={this.handleClose}
                        >
                            <CloseIcon style={{ fontSize: 18 }} />
                        </IconButton>,
                    ]}
                />


                <CardContent className={this.props.classes.cardContent} classes={{ root: this.props.classes.cardContentRoot }}>
                    <div className="col-sm-12 col-md-6 col-lg-6">
                        <div className="row">
                            <div className="col-sm-12 col-md-12 col-lg-12" style={{ marginBottom: "30px" }}>
                                <div className={this.props.classes.inputFieldDiv} style={{ width: "100%" }}>
                                    <InputLabel className={this.props.classes.textFieldLabel}>
                                        {t("Report Type")}
                                        <span className={this.props.classes.requiredClass}>*</span>
                                    </InputLabel>
                                    {/*  <Picky
                                        className={{ input: this.props.classes.outlinedInput }}
                                        value={this.state.reportTypes}
                                        options={this.state.reportTypeList}
                                        onChange={this.reportTypeChange}
                                        open={false}
                                        valueKey="key"
                                        labelKey="value"
                                        multiple={true}
                                        includeSelectAll={true}
                                        includeFilter={false}
                                        dropdownHeight={700}
                                    />*/}
                                    <Select
                                        id="reportType"
                                        multiple
                                        value={this.state.reportTypes}
                                        onChange={this.reportTypeChange}
                                        style={{ maxHeight: "70px" }}
                                        input={
                                            <OutlinedInput
                                                variant="outlined"
                                                name="reportType"
                                                id="language-native-simple"
                                                labelWidth={this.state.labelWidth}
                                                className={this.props.classes.textField}
                                                error={this.state.entityTypeError}
                                            />
                                        }
                                        renderValue={(selected) => (
                                            <div style={{ display: 'flex', flexWrap: 'wrap', maxHeight: "55px", overflowY: "auto" }}>
                                                {selected.map((value) => (
                                                    <Chip key={value} label={value} style={{ marginBottom: "2px", marginRight: "2px" }} />
                                                ))}
                                            </div>
                                        )}
                                        MenuProps={MenuProps}
                                    >

                                        {this.state.reportTypeList.map((item) => (
                                            <MenuItem key={item} value={item} classes={{ selected: this.props.classes.selected }}>
                                                <Checkbox checked={this.state.reportTypes.indexOf(item) > - 1} inputProps={{ 'aria-label': 'primary checkbox' }} />
                                                <ListItemText primary={item} />
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </div>
                            </div>
                            {/* <div className="col-sm-12 col-md-12 col-lg-12">
                                <div className={this.props.classes.inputFieldDiv} style={{ width: "100%" }}>
                                    <InputLabel className={this.props.classes.textFieldLabel}>
                                        {t("Reporting Type")}
                                        <span className={this.props.classes.requiredClass}>*</span>
                                    </InputLabel>
                                    <Select
                                        native
                                        value={this.state.reportingType}
                                        onChange={(event) => this.inputChangeHandler(event, "reportingType")}
                                        input={
                                            <OutlinedInput
                                                variant="outlined"
                                                name="reportingType"
                                                id="language-native-simple"
                                                labelWidth={this.state.labelWidth}
                                                className={this.props.classes.textField}
                                                error={this.state.reportingintervalError}
                                                classes={{ input: this.props.classes.outlinedInput }}
                                            />
                                        }
                                    >
                                        <option key="AllPlant" value="All Plants">{this.props.t("All Plants")}</option>
                                        <option key="CurrentPlant" value="Current Plant">{this.props.t("Current Plant")}</option>
                                    </Select>
                                </div>
                            </div> */}
                            <div className="col-sm-12 col-md-12 col-lg-12">
                                <div className={this.props.classes.inputFieldDiv} style={{ width: "100%" }}>
                                    <InputLabel className={this.props.classes.textFieldLabel}>
                                        {t("Reporting Interval")}
                                        <span className={this.props.classes.requiredClass}>*</span>
                                    </InputLabel>
                                    <Select
                                        native
                                        value={this.state.reportingInterval}
                                        onChange={(event) => this.inputChangeHandler(event, "reportingInterval")}
                                        input={
                                            <OutlinedInput
                                                variant="outlined"
                                                name="reportingInterval"
                                                id="language-native-simple"
                                                labelWidth={this.state.labelWidth}
                                                className={this.props.classes.textField}
                                                error={this.state.reportingintervalError}
                                                classes={{ input: this.props.classes.outlinedInput }}
                                            />
                                        }
                                    >
                                        {this.getReportingIntervals()}
                                    </Select>
                                </div>
                            </div>
                            <div className="col-sm-12 col-md-12 col-lg-12">
                                <div className={this.props.classes.inputFieldDiv} style={{ width: "100%" }}>
                                    <InputLabel className={this.props.classes.textFieldLabel}>
                                        {t("Time Zone")}
                                        <span className={this.props.classes.requiredClass}>*</span>
                                    </InputLabel>
                                    <Select
                                        native
                                        value={this.state.timeZone}
                                        onChange={(event) => this.inputChangeHandler(event, "timeZone")}
                                        input={
                                            <OutlinedInput
                                                variant="outlined"
                                                name="timeZone"
                                                id="language-native-simple"
                                                labelWidth={this.state.labelWidth}
                                                className={this.props.classes.textField}
                                                error={this.state.timeZoneError}
                                                classes={{ input: this.props.classes.outlinedInput }}
                                            />
                                        }
                                    >
                                        {this.getTimezones()}
                                    </Select>

                                </div>
                            </div>
                            <div className="col-sm-12 col-md-12 col-lg-12">
                                <div className={this.props.classes.inputFieldDiv} style={{ width: "100%" }}>
                                    <InputLabel className={this.props.classes.textFieldLabel}>
                                        {t("Entity Type")}
                                        <span className={this.props.classes.requiredClass}>*</span>
                                    </InputLabel>
                                    <Select
                                        native
                                        value={this.state.entityType}
                                        onChange={(event) => this.inputChangeHandler(event, "entityType")}
                                        disabled={this.state.disableEntityTypeDropdown || this.disableEntityTypeDropdown()}
                                        input={
                                            <OutlinedInput
                                                variant="outlined"
                                                name="entityType"
                                                id="language-native-simple"
                                                labelWidth={this.state.labelWidth}
                                                className={this.props.classes.textField}
                                                error={this.state.entityTypeError}
                                                classes={{ input: this.props.classes.outlinedInput }}
                                            />
                                        }
                                    >
                                        {this.getEntityTypes()}
                                    </Select>

                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-sm-12 col-md-6 col-lg-6">
                        <div className="row">
                            <div className="col-sm-12 col-md-12 col-lg-12" style={{ marginBottom: "60px" }}>
                                <div className={this.props.classes.inputFieldDiv} style={{ width: "100%" }}>
                                    <InputLabel className={this.props.classes.textFieldLabel}>
                                        {t("Entity Value")}
                                        <span className={this.props.classes.requiredClass}>*</span>
                                    </InputLabel>
                                    {/* <Picky
                                        className={this.props.classes.outlinedInput}
                                        value={this.state.entityValue}
                                        options={this.state.entityValueList}
                                        numberDisplayed={15}
                                        onChange={this.entityValueChange}
                                        open={false}
                                        valueKey="key"
                                        labelKey="value"
                                        error={this.state.entityValueError}
                                        helperText={this.state.entityValueError ? "Please select value" : ""}
                                        multiple={true}
                                        includeSelectAll={true}
                                        includeFilter={false}
                                        dropdownHeight={600}
                                    /> */}
                                    <Select
                                        id="demo-mutiple-checkbox"
                                        multiple
                                        value={this.state.entityValue}
                                        onChange={(e) => this.handleChange(e)}
                                        style={{ maxHeight: "100px" }}
                                        disabled={this.state.disableEntityDropdown || this.disableEntityDropdown()}
                                        input={
                                            <OutlinedInput
                                                variant="outlined"
                                                name="entityType"
                                                id="language-native-simple"
                                                labelWidth={this.state.labelWidth}
                                                className={this.props.classes.textField}
                                                error={this.state.entityTypeError}
                                            />
                                        }
                                        renderValue={(selected) => (
                                            <div style={{ display: 'flex', flexWrap: 'wrap', maxHeight: "55px", overflowY: "auto" }}>
                                                {selected.map((value) => (
                                                    <>
                                                        <Chip key={value} label={value} style={{ marginBottom: "2px", marginRight: "2px" }} />
                                                    </>
                                                ))}
                                            </div>
                                        )}
                                        MenuProps={MenuProps}
                                    >
                                        {this.state.entityValueList.map((item) => (
                                            <MenuItem key={item.key} value={item.value} classes={{ selected: this.props.classes.selected }}>
                                                <Checkbox checked={this.state.entityValue.indexOf(item.value) > - 1} inputProps={{ 'aria-label': 'primary checkbox' }} />
                                                <ListItemText primary={item.value} />
                                            </MenuItem>
                                        ))}

                                    </Select>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-sm-12 col-md-6 col-lg-6">
                        <div className="row">
                            <div className="col-sm-12 col-md-12 col-lg-12" >
                                <div className={this.props.classes.inputFieldDiv} style={{ width: "100%" }}>
                                    <InputLabel className={this.props.classes.textFieldLabel}>
                                        {t("Email Ids")}
                                        <span className={this.props.classes.requiredClass}>*</span>
                                    </InputLabel>
                                    <TextField
                                        className={this.props.classes.textField}
                                        variant="outlined"
                                        value={this.state.email1}
                                        //disabled={this.state.addNew ? false : true}
                                        type="email"
                                        onChange={(event) => this.inputChangeHandler(event, "email1")}
                                        error={this.state.email1Error}
                                        helperText={this.state.email1Error ? this.state.emailMsg : ""}
                                        InputProps={{
                                            classes: {
                                                input: this.props.classes.outlinedInput
                                            }
                                        }}
                                    />
                                    <IconButton disabled={this.state.documents.length <=18 ? false:true} style={{ marginRight: "-75px", background: this.state.documents.length <=18 ? 'green':'rgba(0, 0, 0, 0.08)' }} onClick={() =>this.state.documents.length <=18 && this.add()}>
                                        <Add style={{color:this.state.documents.length <=18 ? "#fff":"green"}} classes={{ root: this.props.classes.addIcon }} />
                                    </IconButton>
                                </div>
                            </div>

                            {this.state.documents.map((Element, index) => {
                                return (<div className="col-sm-12 col-md-12 col-lg-12" key={index}>

                                    <div className={this.props.classes.inputFieldDiv} style={{ width: "100%" }}>
                                        <InputLabel className={this.props.classes.textFieldLabel}>
                                        </InputLabel>

                                        <TextField
                                            className={this.props.classes.textField}
                                            variant="outlined"
                                            value={Element || ''}
                                            type="email"
                                            onChange={(event) => this.handleChangeEmail(index, event)}
                                            error={this.state.documentsError[index]}
                                            helperText={this.state.documentsError[index] ? this.state.emailMsg : ""}
                                            InputProps={{
                                                classes: {
                                                    input: this.props.classes.outlinedInput
                                                }
                                            }}
                                        />
                                        <IconButton style={{ marginRight: "-75px", background: 'rgba(0, 0, 0, 0.08)' }} onClick={() => this.removeClick(index)}>
                                            <Delete classes={{ root: this.props.classes.deleteIcon }} />
                                        </IconButton>
                                    </div>
                                </div>)
                            })}
                        </div>
                    </div>
                </CardContent >
            </Card >
        );
    }
}

const mapStateToProps = (state) => {
    return {
        machinesData: state.machinesDataReducer,
        monitorsData: state.monitorDataReducer
    }
}

const mapDispatchToProps = dispatch => {
    return ({
        getTableData: (selectedNavtab, currentScopeSelectionId, setToStore) => (dispatch(actionCreators.getTableData(selectedNavtab, currentScopeSelectionId, setToStore))),
        updateScheduledReportsEntity: (reportsEntityData, addnew) => (dispatch(actionCreators.updateScheduledReportsEntity(reportsEntityData, addnew))),
        updateSelectedTab: (selectedTab) => (dispatch(actionCreators.updateSelectedTab(selectedTab)))
    })
}

export default withTranslation()(connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(ScheduledReportsEntity)));
