import React, { Component } from 'react'
import moment from "moment";
import { Chart } from "react-google-charts";
import { withTranslation } from "react-i18next";

class ProductivitySelectionContainer extends Component {
    constructor(props){
        super(props);
        this.state = {
            data : [],
            options: '',
            chartImageURI: '',
            chartShowTitle: this.props.plantName
        }
        this.chartData = [];
    }  
    
    componentDidMount(){
       let reportData= this.props.reportData.productivityMachineList;
       let header = [];
       let data = [],value=[];
      
       var tooltipName = 'Parts / Hour';    
       header.push(['date', 'Parts / Hour', { type: 'string', role: 'tooltip' }, 'Planned Parts / Hour'])
       
       let outputMachine = reportData.filter(ele=>ele.machineId==this.props.selectedMachineProd)

       outputMachine[0].data.forEach((element,index)=>{ 
        var partNumber = "\nPart Number: " + element.partCount;
        var tooltipContent = element.timeStamp + "\n" + tooltipName + ": " + index + "\n" + "Availability: " + parseFloat(element.availability).toFixed(2) + "%  " +
        "Productivity: " + parseFloat(element.productivity).toFixed(2) + "%  " + "Quality: " + parseFloat(element.quality).toFixed(2) + "%\n" +
        " OEE : " + parseFloat(element.oee).toFixed(2) + "%" + partNumber;
        header.push([moment(element.timeStamp+ ' ' + element.from).toDate(), element.partCount, tooltipContent, outputMachine[0].plannedParts]);
       })
        data.push(header);
        this.setState({data: data})       
    }

    componentDidUpdate(prevProps){
        if(prevProps.selectedMachineProd !== this.props.selectedMachineProd && this.props.selectedMachineProd<3 ){            
            let reportData= this.props.reportData.productivityMachineList;
            let header = [];
            let data = [],value=[];
        
            var tooltipName = 'Parts / Hour';    
            header.push(['date', 'Parts / Hour', { type: 'string', role: 'tooltip' }, 'Planned Parts / Hour'])
            
            let outputMachine = reportData.filter(ele=>ele.machineId==this.props.selectedMachineProd)
    
            outputMachine[0].data.forEach((element,index)=>{ 
                var partNumber = "\nPart Number: " + element.partCount;
                var tooltipContent = element.timeStamp + "\n" + tooltipName + ": " + index + "\n" + "Availability: " + parseFloat(element.availability).toFixed(2) + "%  " +
                "Productivity: " + parseFloat(element.productivity).toFixed(2) + "%  " + "Quality: " + parseFloat(element.quality).toFixed(2) + "%\n" +
                " OEE : " + parseFloat(element.oee).toFixed(2) + "%" + partNumber;
                header.push([moment(element.timeStamp+ ' ' + element.from).toDate(), element.partCount, tooltipContent, outputMachine[0].plannedParts]);
            })
            data.push(header);
            let chartShowTitle = this.props.selectedMachineOee == 0 ? this.props.plantName : outputMachine[0].machineName;
            this.setState({ data: data, chartShowTitle : chartShowTitle, chartImageURI:''})           
        }
    }
    

    chart_events = [
        {
            eventName : 'ready',
            callback  : (ChartRef) => {
                // Returns Chart so you can access props and  the ChartWrapper object from chart.wrapper
                if(this.state.chartImageURI==''){          
                this.setState({
                  chartImageURI: ChartRef.chartWrapper.getChart().getImageURI(),
                },()=>this.props.parentFunChart(this.state.chartImageURI));
              }
            }
        }
      ];
    
    render() {
      const {t} =this.props;

        return (
            <div className="ProductivitySelection-cntr display-text" style={{width: "100%"}}>                
               {this.state.data.length>0 ?
                <div className="chart-body" id="productivitySelectionGraph">
                <Chart graphID="GoogleChart"
                  width={'100%'}
                  height={'300px'}
                  chartType="LineChart"
                  loader={<div>{t("Loading Chart")}</div>}
                  data={this.state.data[0]}
                  
                  options={{
                    legend: 'none',           
                    title:  `Productivity of ${this.state.chartShowTitle}`,
                    hAxis: {
                      title: 'TimeStamp',
                    },
                    vAxis: {
                      title: 'Productivity',
                    },
                    series: {0: {color: '#2089fd'}},
                    explorer: {
                        actions: ['dragToZoom', 'rightClickToReset'],
                        maxZoomOut: 1,
                        maxZoomIn: 1024,
                        keepInBounds: true
                    },
                    interpolateNulls: true,
                  }}
                  chartEvents = {this.chart_events}
        
                />        
              </div>
                : null 
                 } 
                
            </div>
        )
    }
}

export default withTranslation()(ProductivitySelectionContainer);