import React, { Component } from 'react';
import * as actionCreators from '../../actions/actionCreators';
import { connect } from 'react-redux';
import { KeyboardBackspace } from '@material-ui/icons';
import { Scrollbars } from 'react-custom-scrollbars';
import {
    Card, CardContent, InputLabel, TextField, Select, withStyles, OutlinedInput, Button, Typography, Grid
} from '@material-ui/core';
import { withTranslation } from "react-i18next";


const styles = theme => ({
    cardContent: {
        height: '91%',
        "overflow-y": "auto"
    },
    cardContentRoot: {
        '&:last-child': {
            paddingBottom: 16
        }
    },
    card: {
        width: '100%',
        borderRadius: 0,
        height: '80vh'
    },
    textField: {
        width: '60%',
        marginRight: 20
    },
    textArea: {
        width: '80%',
        marginRight: 20
    },
    outlinedInput: {
        padding: 14
    },
    saveButton: {
        fontSize: "14px",
        fontWeight: "500",
        textAlign: "center",
        color: "#ffffff",
        backgroundColor: "#42D784",
        '&:hover': {
            backgroundColor: '#42d784'
        },
        '&:disabled': {
            backgroundColor: '#f6f6f6'
        },
        borderRadius: "3px",
        boxShadow: "0 0 1px 0 rgba(0, 0, 0, 0.05)",
        width: "110px",
        height: "36px",
        marginRight: "24px"
    },
    inputFieldDiv: {
        display: "flex",
        justifyContent: "space-between",
        width: '50%',
        float: 'left',
        paddingTop: '1%',
        height: 80,
        'align-items': 'baseline'
    },
    textareaInputFieldDiv: {
        display: "flex",
        justifyContent: "space-between",
        width: '100%',
        float: 'left',
        paddingTop: '1%',
        height: 180,
        'align-items': 'baseline'
    },
    textFieldLabel: {
        color: 'rgba(0, 0, 0, 0.4000000059604645)',
        'letter-spacing': 0.5,
        'font-weight': 500,
        fontSize: "14px",
        lineHeight: 1.5,
        width: '30%',
        marginLeft: 20,
        //text-align': 'right'
    },
    textareaFieldLabel: {
        color: 'rgba(0, 0, 0, 0.4000000059604645)',
        'letter-spacing': 0.5,
        'font-weight': 500,
        fontSize: "14px",
        lineHeight: 2.5,
        width: '15%',
        marginLeft: 20,
        //'text-align': 'right'
    },
    requiredClass: {
        color: 'red'
    },
    requiredClass: {
        color: 'red'
    },
    actionButtonContainer: {
        display: 'flex',
        'justify-content': 'space-between',
        height: '9%',
        'border-bottom': '1px solid rgba(207, 216, 220, 0.5)'
    },
    backButtonContainer: {
        display: 'flex',
        'align-items': 'center',
        paddingLeft: 21
    },
    arrowBack: {
        cursor: 'pointer'
    },
    entityTitle: {
        color: 'rgba(0, 0, 0, 0.8)',
        'font-family': 'Roboto',
        'font-size': 21,
        'font-weight': '400',
        'letter-spacing': 0.5,
        marginLeft: 10
    }
})

class MachineGroupsEntity extends Component {

    state = {
        id: "",
        disableSaveButton: true,
        plantId: "",
        plantName: "",
        scheduleId: "",
        lossListId: "",
        machineGroupTypeId: "",
        jobListId: "",
        displayPosition: "",
        displayPositionError: false,
        name: "",
        specs: "",
        labelWidth: 0,
        nameError: false,
        specsError: false,
        notesError: false,
        machineGroupTypeError: false,
        scheduleError: false,
        lossListError: false,
        jobListError: false,
        driver: "AC MOTOR",
        driven1: "GEAR BOX",
        driven2: "PUMP",
        driven1Coupling: "DIRECT",
        driven2Coupling: "DIRECT",
        driven1CouplingSpeedRatio: "",
        driven1SpeedRatio: "",
        driven2CouplingSpeedRatio: "",
        driven2SpeedRatio: "",
        driven1CouplingSpeedRatioError: false,
        driven1SpeedRatioError: false,
        driven2CouplingSpeedRatioError: false,
        driven2SpeedRatioError: false,
        errorMessage: "",
        errorMessage1: "",
        errorMessage2: "",
        errorMessage3: ""
    }

    // Handle changes to any user input field
    inputChangeHandler = (event, field) => {

        switch (field) {
            case "machineGroupName": this.setState({ name: event.target.value, disableSaveButton: false });
                this.checkValidation("machineGroupName", event.target.value);
                break;
            case "specs": this.setState({ specs: event.target.value, disableSaveButton: false });
                this.checkValidation("specs", event.target.value);
                break;
            case "notes": this.setState({ notes: event.target.value, disableSaveButton: false });
                this.checkValidation("notes", event.target.value);
                break;
            case "machineGroupTypeId": this.setState({ machineGroupTypeId: event.target.value, disableSaveButton: false });
                this.checkValidation("machineGroupTypeId", event.target.value);
                break;
            case "scheduleId": this.setState({ scheduleId: event.target.value, disableSaveButton: false });
                this.checkValidation("scheduleId", event.target.value);
                break;
            case "lossListId": this.setState({ lossListId: event.target.value, disableSaveButton: false });
                this.checkValidation("lossListId", event.target.value);
                break;
            case "jobListId": this.setState({ jobListId: event.target.value, disableSaveButton: false });
                this.checkValidation("jobListId", event.target.value);
                break;
            case "displayPosition": this.setState({ displayPosition: event.target.value, disableSaveButton: false });
                break;
            case "driver": this.setState({ driver: event.target.value, disableSaveButton: false });
                break;
            case "driven1": this.setState({ driven1: event.target.value,disableSaveButton: false });
                if (event.target.value !== "PUMP" || event.target.value !== "BLOWER" || event.target.value !== "COMPRESSOR" || event.target.value !== "GEAR BOX") {
                    this.setState({ driven1SpeedRatio: "", driven1SpeedRatioError: false })
                }
                break;
            case "driven2": this.setState({ driven2: event.target.value,disableSaveButton: false });
                if (event.target.value !== "PUMP" || event.target.value !== "BLOWER" || event.target.value !== "COMPRESSOR") {
                    this.setState({ driven2SpeedRatio: "", driven2SpeedRatioError: false })
                }
                break;
            case "driven1Coupling": this.setState({ driven1Coupling: event.target.value, disableSaveButton: false });
                if (event.target.value !== "CHAIN" || event.target.value !== "GEAR" || event.target.value !== "BELT DRIVEN") {
                    this.setState({ driven1CouplingSpeedRatio: "", driven1CouplingSpeedRatioError: false })
                }
                break;
            case "driven2Coupling": this.setState({ driven2Coupling: event.target.value,disableSaveButton: false });
                if (event.target.value !== "CHAIN" || event.target.value !== "GEAR" || event.target.value !== "BELT DRIVEN") {
                    this.setState({ driven2CouplingSpeedRatio: "", driven2CouplingSpeedRatioError: false })
                }
                break;
            case "driven1CouplingSpeedRatio": this.checkValidation("driven1CouplingSpeedRatio", event.target.value);
                this.setState({ driven1CouplingSpeedRatio: event.target.value,disableSaveButton: false });
                break;
            case "driven1SpeedRatio": this.checkValidation("driven1SpeedRatio", event.target.value);
                this.setState({ driven1SpeedRatio: event.target.value,disableSaveButton: false });
                break;
            case "driven2CouplingSpeedRatio": this.checkValidation("driven2CouplingSpeedRatio", event.target.value);
                this.setState({ driven2CouplingSpeedRatio: event.target.value,disableSaveButton: false });
                break;
            case "driven2SpeedRatio": this.checkValidation("driven2SpeedRatio", event.target.value);
                this.setState({ driven2SpeedRatio: event.target.value,disableSaveButton: false });
                break;

            default: console.log("default case");
        }
    }

    // Handle routing
    goToBackPage = () => {
        this.props.history.goBack();
    }

    // update machine-groups entity
    updateMachineGroupsData = () => {
        const { plantId, id, name, notes, specs, scheduleId, lossListId, jobListId,  driver, driven1, driven2, driven1Coupling, driven2Coupling, driven1CouplingSpeedRatio, driven1SpeedRatio, driven2CouplingSpeedRatio, driven2SpeedRatio } = this.state
        var displayPosition = this.state.displayPosition ? parseInt(this.state.displayPosition) : null;
        let machineGroupTypeId=this.state.machineGroupTypeId === " "?null:this.state.machineGroupTypeId;
        if (id && id != null && id !== "") {
            this.props.updateMachineGroupsEntity({ plantId, id, name, notes, specs, scheduleId, lossListId, jobListId, machineGroupTypeId, displayPosition, driver, driven1, driven2, driven1Coupling, driven2Coupling, driven1CouplingSpeedRatio, driven1SpeedRatio, driven2CouplingSpeedRatio, driven2SpeedRatio }, id);
        }
        else {
            this.props.updateMachineGroupsEntity({ plantId, name, notes, specs, scheduleId, lossListId, jobListId, machineGroupTypeId, displayPosition, driver, driven1, driven2, driven1Coupling, driven2Coupling, driven1CouplingSpeedRatio, driven1SpeedRatio, driven2CouplingSpeedRatio, driven2SpeedRatio }, id);
        }
    }

    // create loss-lists dropdown list
    getLossListName = () => {
        let lossList = [];
        lossList.push(<option key={""} disabled={true} value={""}>{this.props.t("Select")}</option>);
        if ((this.state.validationData.lossListsName.validations && !this.state.validationData.lossListsName.validations.required) || !(this.state.validationData.lossListsName.validations)) {
            lossList.push(<option key={""} value={""}>{this.props.t("None")}</option>);
        }
        if (this.props.lossCodes && this.props.lossCodes.tableData && this.props.lossCodes.tableData.length > 0) {
            this.props.lossCodes.tableData.map((data) => {
                lossList.push(
                    <option key={data.id} value={data.id}>{data.name}</option>
                );
            })
        }
        return lossList;
    }

    // create job-lists dropdown list
    getJobListName = () => {
        let jobList = [];
        jobList.push(<option key={""} disabled={true} value={""}>{this.props.t("Select")}</option>);
        if ((this.state.validationData.joblistsName.validations && !this.state.validationData.joblistsName.validations.required) || !(this.state.validationData.joblistsName.validations)) {
            jobList.push(<option key={""} value={""}>{this.props.t("None")}</option>);
        }
        if (this.props.jobsData && this.props.jobsData.tableData && this.props.jobsData.tableData.length > 0) {
            this.props.jobsData.tableData.map((data) => {
                jobList.push(
                    <option key={data.id} value={data.id}>{data.name}</option>
                );
            })
        }
        return jobList;
    }

    // create schedules dropdown list
    getScheduleName = () => {
        let scheduleList = [];
        scheduleList.push(<option key={""} disabled={true} value={""}>{this.props.t("Select")}</option>);
        if ((this.state.validationData.schedulesName.validations && !this.state.validationData.schedulesName.validations.required) || !(this.state.validationData.schedulesName.validations)) {
            scheduleList.push(<option key={""} value={""}>{this.props.t("None")}</option>);
        }
        if (this.props.schedulesData && this.props.schedulesData.tableData && this.props.schedulesData.tableData.length > 0) {
            this.props.schedulesData.tableData.map((data) => {
                scheduleList.push(
                    <option key={data.id} value={data.id}>{data.name}</option>
                );
            })
        }
        return scheduleList;
    }

    // create display position dropdown list
    getDisplayPosition = () => {
        let displayPositionList = [];
        displayPositionList.push(<option key={""} disabled={true} value={""}>{this.props.t("Select")}</option>);
        if (this.props.machineGroupCount && this.props.machineGroupCount.machineGroupCount && this.props.machineGroupCount.machineGroupCount > 0) {
            for (var i = 1; i <= this.props.machineGroupCount.machineGroupCount; i++) {
                displayPositionList.push(
                    <option key={i} value={i}>{i}</option>
                );
                if (i === this.props.machineGroupCount.machineGroupCount) {
                    if (this.state.id === null || this.state.id === "") {
                        displayPositionList.push(
                            <option key={this.props.machineGroupCount.machineGroupCount + 1} value={this.props.machineGroupCount.machineGroupCount + 1}>{this.props.t("Last")}</option>
                        );
                    }
                }
            }

        } else {
            if (this.state.id === null || this.state.id === "") {
                displayPositionList.push(
                    <option key={1} value={1}>{this.props.t("Last")}</option>
                );
            }
        }
        return displayPositionList;
    }

    // create machine-groups-types dropdown list
    getMachineGroupType = () => {
        let machineGroupTypeList = [];
        machineGroupTypeList.push(<option key={""} disabled={true} value={""}>{this.props.t("Select")}</option>);
        if ((this.state.validationData.machineGroupTypeName.validations && !this.state.validationData.machineGroupTypeName.validations.required) || !(this.state.validationData.machineGroupTypeName.validations)) {
            machineGroupTypeList.push(<option key={""} value={" "}>{this.props.t("None")}</option>);
        }
        let found = false;
        let plantOrganization = "";
        if (this.state.plantId) {
            this.props.scopeSelector.subOrganizations.map((organization) => {
                if (organization.plants && organization.plants.length > 0) {
                    organization.plants.map((plant) => {
                        if (plant.id === this.state.plantId && !found) {
                            plantOrganization = organization.id;
                            found = true;
                        }
                    })
                }
            })
        }

        if (plantOrganization && this.props.staticData && this.props.staticData.staticData) {
            let plantOrganizationStaticData = this.props.staticData.staticData[plantOrganization]
            if (plantOrganizationStaticData && plantOrganizationStaticData.machinegrouptypes) {
                plantOrganizationStaticData.machinegrouptypes.map((data) => {
                    machineGroupTypeList.push(
                        <option key={data.id} value={data.id}>{this.props.t(data.name)}</option>
                    );
                })
            }
        }
        return machineGroupTypeList;
    }

    // set selected plant's organization id in the state
    getPlantNamefromOrganization = () => {
        if (this.props.selectedScopeData || this.props.selectedScopeData == "") {
            let tempdata = "";
            let found = false;
            this.props.scopeSelector.subOrganizations.map((organization) => {
                if (organization.plants && organization.plants.length > 0) {
                    organization.plants.map((plant) => {
                        if (plant.id === this.props.selectedScopeData && !found) {
                            tempdata = plant.name;
                            found = true;
                        }
                    })
                }
            })
            this.setState({
                plantId: this.props.selectedScopeData,
                plantName: tempdata,
            }, () => {
                this.props.getMachineGroupCountByPlantId(this.state.plantId);
            })
        }
    }

    //driver dropdown list
    getDriver = () => {
        let arr = [{ "key": "AC MOTOR", "value": this.props.t("AC MOTOR") },
        { "key": "DC MOTOR", "value": this.props.t("DC MOTOR") },
        { "key": "TURBINE", "value": this.props.t("TURBINE") },
        { "key": "IC ENGINE", "value": this.props.t("IC ENGINE") }
        ]
        let displayDriver = [];
        displayDriver.push(<option key={""} disabled={true} value={""}>{this.props.t("Select")}</option>);
        arr.map(fbb =>
            displayDriver.push(<option key={fbb.key} value={fbb.key}>{fbb.value}</option>)
        );


        return displayDriver;
    }
    getDrivenOne = () => {
        let arr = [{ "key": "GENERATOR", "value": this.props.t("GENERATOR") },
        { "key": "PUMP", "value": this.props.t("PUMP") },
        { "key": "BLOWER", "value": this.props.t("BLOWER") },
        { "key": "COMPRESSOR", "value": this.props.t("COMPRESSOR") },
        { "key": "CALENDER ROLL", "value": this.props.t("CALENDER ROLL") },
        { "key": "CRUSHER", "value": this.props.t("CRUSHER") },
        { "key": "PRESS MACHINE", "value": this.props.t("PRESS MACHINE") },
        { "key": "GEAR BOX", "value": this.props.t("GEAR BOX") },
        { "key": "PLUMMER BLOCK", "value": this.props.t("PLUMMER BLOCK") },
        { "key": "EXTRUDER", "value": this.props.t("EXTRUDER") },
        { "key": "DRAWBENCH", "value": this.props.t("DRAWBENCH") }

        ]
        let displayDriver = [];
        displayDriver.push(<option key={""} disabled={true} value={""}>{this.props.t("Select")}</option>);
        arr.map(fbb =>
            displayDriver.push(<option key={fbb.key} value={fbb.key}>{fbb.value}</option>)
        );


        return displayDriver;
    }

    getDrivenTwo = () => {
        let arr = [{ "key": "GENERATOR", "value": this.props.t("GENERATOR") },
        { "key": "PUMP", "value": this.props.t("PUMP") },
        { "key": "BLOWER", "value": this.props.t("BLOWER") },
        { "key": "COMPRESSOR", "value": this.props.t("COMPRESSOR") },
        { "key": "CALENDER ROLL", "value": this.props.t("CALENDER ROLL") },
        { "key": "CRUSHER", "value": this.props.t("CRUSHER") },
        { "key": "PRESS MACHINE", "value": this.props.t("PRESS MACHINE") }


        ]
        let displayDriver = [];
        displayDriver.push(<option key={""} disabled={true} value={""}>{this.props.t("Select")}</option>);
        arr.map(fbb =>
            displayDriver.push(<option key={fbb.key} value={fbb.key}>{fbb.value}</option>)
        );


        return displayDriver;

    }

    getDriven1Coupling = () => {
        let arr = [{ "key": "DIRECT", "value": this.props.t("DIRECT") },
        { "key": "BELT DRIVEN", "value": this.props.t("BELT DRIVEN") },
        { "key": "CHAIN", "value": this.props.t("CHAIN") },
        { "key": "FLEXIBLE", "value": this.props.t("FLEXIBLE") },
        { "key": "SPLINE", "value": this.props.t("SPLINE") },
        { "key": "FLUID", "value": this.props.t("FLUID") },
        { "key": "MAGNETIC", "value": this.props.t("MAGNETIC") },
        { "key": "GEAR", "value": this.props.t("GEAR") },

        ]
        let displayDriver = [];
        displayDriver.push(<option key={""} disabled={true} value={""}>{this.props.t("Select")}</option>);
        arr.map(fbb =>
            displayDriver.push(<option key={fbb.key} value={fbb.key}>{fbb.value}</option>)
        );


        return displayDriver;
    }

    componentDidMount() {
        if (this.props.data) {
            let entityNames = Object.keys(this.props.data)
            entityNames.map((data) => {
                if (this.props.data[data] == null) {
                    this.props.data[data] = "";
                }
            })
            this.setState({
                id: this.props.data.id,
                name: this.props.data.name,
                driven1: this.props.data.driven1,
                driven2: this.props.data.driven2,
                driver: this.props.data.driver,
                driven1Coupling: this.props.data.driven1Coupling,
                driven2Coupling: this.props.data.driven2Coupling,
                driven1SpeedRatio: this.props.data.driven1SpeedRatio,
                driven2SpeedRatio: this.props.data.driven2SpeedRatio,
                driven1CouplingSpeedRatio: this.props.data.driven1CouplingSpeedRatio,
                driven2CouplingSpeedRatio: this.props.data.driven2CouplingSpeedRatio

            })

            if (this.props.data.specs && this.props.data.specs != "") {
                if (this.props.data.specs.props) {
                    this.setState({ specs: this.props.data.specs.props.children })
                }
                else {
                    this.setState({ specs: this.props.data.specs })
                }
            }
            if (this.props.data.notes && this.props.data.notes != "") {
                if (this.props.data.notes.props) {
                    this.setState({ notes: this.props.data.notes.props.children })
                }
                else {
                    this.setState({ notes: this.props.data.notes })
                }
            }
            if (this.props.data.plants && this.props.data.plants.id) {
                if (this.props.data.id && this.props.data.id != "") {
                    this.setState({
                        plantId: this.props.data.plants.id,
                        plantName: this.props.data.plants.name
                    }, () => {
                        this.props.getMachineGroupCountByPlantId(this.state.plantId);
                    })
                }
                else {
                    this.getPlantNamefromOrganization();
                }
            }

            if (this.props.data.schedules && this.props.data.schedules.id) {
                this.setState({ scheduleId: this.props.data.schedules.id })
            }

            if (this.props.data.displayPosition) {
                this.setState({ displayPosition: this.props.data.displayPosition })
            }

            if (this.props.data.lossLists && this.props.data.lossLists.id) {
                this.setState({ lossListId: this.props.data.lossLists.id })
            }
            if (this.props.data.jobLists && this.props.data.jobLists.id) {
                this.setState({ jobListId: this.props.data.jobLists.id })
            }
            if (this.props.data.machineGroupTypes && this.props.data.machineGroupTypes.id) {
                this.setState({ machineGroupTypeId: this.props.data.machineGroupTypes.id })
            }
        } else {
            this.getPlantNamefromOrganization();
        }

        if (this.props.validationData && this.props.validationData.config && this.props.validationData.config.MachineGroups) {
            this.setState({ validationData: this.props.validationData.config.MachineGroups });
        }
    }

    componentDidUpdate(prevProps, prevState) {
        if (this.state.plantId && this.state.plantId != "" && prevState.plantId != this.state.plantId) {
            this.props.getTableData("SchedulesTable", this.state.plantId, true);
            this.props.getTableData("JobListTable", this.state.plantId, true);
            this.props.getTableData("LossListTable", this.state.plantId, true);
        }
    }

    // disable save button based on validations
    disableSaveButton = () => {
        if (this.state.name === "" && (this.state.validationData.name.validations && this.state.validationData.name.validations.required)) {
            return true;
        }
        else if (this.state.notes === "" && (this.state.validationData.notes.validations && this.state.validationData.notes.validations.required)) {
            return true;
        }
        else if (this.state.specs === "" && (this.state.validationData.specs.validations && this.state.validationData.specs.validations.required)) {
            return true;
        }
        else if (this.state.jobListId === "" && (this.state.validationData.joblistsName.validations && this.state.validationData.joblistsName.validations.required)) {
            return true;
        }
        else if (this.state.lossListId === "" && (this.state.validationData.lossListsName.validations && this.state.validationData.lossListsName.validations.required)) {
            return true;
        }
        else if (this.state.machineGroupTypeId === "" && (this.state.validationData.machineGroupTypeName.validations && this.state.validationData.machineGroupTypeName.validations.required)) {
            return true;
        }
        else if (this.state.scheduleId === "" && (this.state.validationData.schedulesName.validations && this.state.validationData.schedulesName.validations.required)) {
            return true;
        }
        else if (this.state.nameError || this.state.specsError || this.state.notesError || this.state.machineGroupTypeError || this.state.scheduleError || this.state.lossListError || this.state.jobListError || this.state.displayPositionError) {
            return true;
        }
        else if (this.state.driver === "" || this.state.driven1Coupling === "" ) {
            return true;
        }
        else {
            return false;
        }
    }

    // check validations of the user input fields
    checkValidation = (field, value) => {
        const rx_live = /^[+-]?\d*(?:[.,]\d*)?$/;
        let minValue = 0;
        let maxValue = 60000;
        switch (field) {
            case "jobListId":
                if (!this.state.jobListError && value === "" && (this.state.validationData.joblistsName.validations && this.state.validationData.joblistsName.validations.required)) {
                    this.setState({ jobListError: true });
                } else if (this.state.jobListError) {
                    if ((value === "" && (this.state.validationData.joblistsName.validations && this.state.validationData.joblistsName.validations.required))) {
                        //skip
                    }
                    else {
                        this.setState({ jobListError: false });
                    }
                };
                break;
            case "lossListId":
                if (!this.state.lossListError && value === "" && (this.state.validationData.lossListsName.validations && this.state.validationData.lossListsName.validations.required)) {
                    this.setState({ lossListError: true });
                } else if (this.state.lossListError) {
                    if ((value === "" && (this.state.validationData.lossListsName.validations && this.state.validationData.lossListsName.validations.required))) {
                        //skip
                    }
                    else {
                        this.setState({ lossListError: false });
                    }
                };
                break;
            case "scheduleId":
                if (!this.state.scheduleError && value === "" && (this.state.validationData.schedulesName.validations && this.state.validationData.schedulesName.validations.required)) {
                    this.setState({ scheduleError: true });
                } else if (this.state.scheduleError) {
                    if ((value === "" && (this.state.validationData.schedulesName.validations && this.state.validationData.schedulesName.validations.required))) {
                        //skip
                    }
                    else {
                        this.setState({ scheduleError: false });
                    }
                };
                break;
            case "machineGroupTypeId":
                if (!this.state.machineGroupTypeError && value === "" && (this.state.validationData.machineGroupTypeName.validations && this.state.validationData.machineGroupTypeName.validations.required)) {
                    this.setState({ machineGroupTypeError: true });
                } else if (this.state.machineGroupTypeError) {
                    if ((value === "" && (this.state.validationData.machineGroupTypeName.validations && this.state.validationData.machineGroupTypeName.validations.required))) {
                        //skip
                    }
                    else {
                        this.setState({ machineGroupTypeError: false });
                    }
                };
                break;
            case "machineGroupName":
                if (!this.state.nameError && value === "" && (this.state.validationData.name.validations && this.state.validationData.name.validations.required)) {
                    this.setState({ nameError: true });
                } else if (!this.state.nameError && (this.state.validationData.name.validations) && (value.length > this.state.validationData.name.validations.maxLength)) {
                    this.setState({ nameError: true });
                } else if (this.state.nameError) {
                    if ((value === "" && (this.state.validationData.name.validations && this.state.validationData.name.validations.required) || ((this.state.validationData.name.validations) && (value.length > this.state.validationData.name.validations.maxLength)))) {
                        //skip
                    }
                    else {
                        this.setState({ nameError: false });
                    }
                };
                break;
            case "notes":
                if (!this.state.notesError && value === "" && (this.state.validationData.notes.validations && this.state.validationData.notes.validations.required)) {
                    this.setState({ notesError: true });
                } else if (!this.state.notesError && (this.state.validationData.notes.validations) && (value.length > this.state.validationData.notes.validations.maxLength)) {
                    this.setState({ notesError: true });
                } else if (this.state.notesError) {
                    if ((value === "" && (this.state.validationData.notes.validations && this.state.validationData.notes.validations.required) || ((this.state.validationData.notes.validations) && (value.length > this.state.validationData.notes.validations.maxLength)))) {
                        //skip
                    }
                    else {
                        this.setState({ notesError: false });
                    }
                };
                break;
            case "specs":
                if (!this.state.specsError && value === "" && (this.state.validationData.specs.validations && this.state.validationData.specs.validations.required)) {
                    this.setState({ specsError: true });
                } else if (!this.state.specsError && (this.state.validationData.specs.validations) && (value.length > this.state.validationData.specs.validations.maxLength)) {
                    this.setState({ specsError: true });
                } else if (this.state.specsError) {
                    if ((value === "" && (this.state.validationData.specs.validations && this.state.validationData.specs.validations.required) || ((this.state.validationData.specs.validations) && (value.length > this.state.validationData.specs.validations.maxLength)))) {
                        //skip
                    }
                    else {
                        this.setState({ specsError: false });
                    }
                };
                break;
            case "driven1CouplingSpeedRatio":
                if (rx_live.test(value) && value !== "") {
                    if (parseFloat(value) >= minValue && parseFloat(value) <= maxValue) {
                        this.setState({ driven1CouplingSpeedRatioError: false, disableSaveButton: false })
                    } else if (parseFloat(value) < minValue) {
                        this.setState({
                            driven1CouplingSpeedRatioError: true, disableSaveButton: true,
                            errorMessage: `${this.props.t("Enter a value greater than or equal to")} ${minValue}`
                        })
                    } else {
                        this.setState({
                            driven1CouplingSpeedRatioError: true, disableSaveButton: true,
                            errorMessage: `${this.props.t("Enter a value less than or equal to")} ${maxValue}`
                        })
                    }

                }
                else {
                    this.setState({
                        driven1CouplingSpeedRatioError: true, disableSaveButton: true,
                        errorMessage: `${this.props.t("Please enter a valid value")}`
                    })
                }
                break;
            case "driven1SpeedRatio":
                if (rx_live.test(value) && value !== "") {
                    if (parseFloat(value) >= minValue && parseFloat(value) <= maxValue) {
                        this.setState({ driven1SpeedRatioError: false, disableSaveButton: false })
                    } else if (parseFloat(value) < minValue) {
                        this.setState({
                            driven1SpeedRatioError: true, disableSaveButton: true,
                            errorMessage1: `${this.props.t("Enter a value greater than or equal to")} ${minValue}`
                        })
                    } else {
                        this.setState({
                            driven1SpeedRatioError: true, disableSaveButton: true,
                            errorMessage1: `${this.props.t("Enter a value less than or equal to")} ${maxValue}`
                        })
                    }

                }
                else {
                    this.setState({
                        driven1SpeedRatioError: true, disableSaveButton: true,
                        errorMessage1: `${this.props.t("Please enter a valid value")}`
                    })
                }
                break;
            case "driven2CouplingSpeedRatio":
                if (rx_live.test(value) && value !== "") {
                    if (parseFloat(value) >= minValue && parseFloat(value) <= maxValue) {
                        this.setState({ driven2CouplingSpeedRatioError: false, disableSaveButton: false })
                    } else if (parseFloat(value) < minValue) {
                        this.setState({
                            driven2CouplingSpeedRatioError: true, disableSaveButton: true,
                            errorMessage2: `${this.props.t("Enter a value greater than or equal to")} ${minValue}`
                        })
                    } else {
                        this.setState({
                            driven2CouplingSpeedRatioError: true, disableSaveButton: true,
                            errorMessage2: `${this.props.t("Enter a value less than or equal to")} ${maxValue}`
                        })
                    }

                }
                else {
                    this.setState({
                        driven2CouplingSpeedRatioError: true, disableSaveButton: true,
                        errorMessage2: `${this.props.t("Please enter a valid value")}`
                    })
                }
                break;
            case "driven2SpeedRatio":
                if (rx_live.test(value) && value !== "") {
                    if (parseFloat(value) >= minValue && parseFloat(value) <= maxValue) {
                        this.setState({ driven2SpeedRatioError: false, disableSaveButton: false })
                    } else if (parseFloat(value) < minValue) {
                        this.setState({
                            driven2SpeedRatioError: true, disableSaveButton: true,
                            errorMessage3: `${this.props.t("Enter a value greater than or equal to")} ${minValue}`
                        })
                    } else {
                        this.setState({
                            driven2SpeedRatioError: true, disableSaveButton: true,
                            errorMessage3: `${this.props.t("Enter a value less than or equal to")} ${maxValue}`
                        })
                    }

                }
                else {
                    this.setState({
                        driven2SpeedRatioError: true, disableSaveButton: true,
                        errorMessage3: `${this.props.t("Please enter a valid value")}`
                    })
                }
                break;
        }
    }

    // set card header
    getCardHeader = () => {
        if (this.props.data && this.props.data.name && this.props.data.name != "") {
            return this.props.data.name;
        }
        else {
            return this.props.t("Create New")
        }
    }

    render() {
        const { t } = this.props;
        return (
            <Card className={this.props.classes.card}>
                <div className={this.props.classes.actionButtonContainer}>
                    <div className={this.props.classes.backButtonContainer}>
                        <KeyboardBackspace onClick={this.goToBackPage} classes={{ root: this.props.classes.arrowBack }} />
                        <div className={this.props.classes.entityTitle}>{this.getCardHeader()}</div>
                    </div>
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                        <Button variant="contained"
                            className={this.props.classes.saveButton}
                            disabled={this.state.disableSaveButton || this.disableSaveButton()}
                            onClick={this.updateMachineGroupsData}>{t("Save")}</Button>
                    </div>
                </div>
                <CardContent className={this.props.classes.cardContent} classes={{ root: this.props.classes.cardContentRoot }}>
                    {this.state.validationData && this.state.validationData.name && this.state.validationData.name.page_visible ? (
                        <div className={this.props.classes.inputFieldDiv}>
                            <InputLabel className={this.props.classes.textFieldLabel}>
                                {this.state.validationData.name.display_name}
                                {(this.state.validationData.name.validations && this.state.validationData.name.validations.required) ? (<span className={this.props.classes.requiredClass}>*</span>) : null}
                            </InputLabel>
                            <TextField
                                id="machineGroupName"
                                name="machineGroupName"
                                helperText={(this.state.validationData.name.validations && this.state.validationData.name.validations.helpText) ? (this.state.validationData.name.validations.helpText) : null}
                                variant="outlined"
                                className={this.props.classes.textField}
                                onChange={(event) => this.inputChangeHandler(event, "machineGroupName")}
                                value={this.state.name}
                                error={this.state.nameError}
                                InputProps={{
                                    classes: {
                                        input: this.props.classes.outlinedInput
                                    }
                                }}
                            />
                        </div>
                    )
                        : null
                    }
                    {this.state.validationData && this.state.validationData.plantName && this.state.validationData.plantName.page_visible ? (
                        <div className={this.props.classes.inputFieldDiv}>
                            <InputLabel className={this.props.classes.textFieldLabel}>
                                {this.state.validationData.plantName.display_name}
                                {(this.state.validationData.plantName.validations && this.state.validationData.plantName.validations.required) ? (<span className={this.props.classes.requiredClass}>*</span>) : null}
                            </InputLabel>
                            <TextField
                                name="plantName"
                                id="plantName-disabled"
                                disabled
                                variant="outlined"
                                className={this.props.classes.textField}
                                value={this.state.plantName}
                                InputProps={{
                                    classes: {
                                        input: this.props.classes.outlinedInput
                                    }
                                }}
                            />
                        </div>
                    )
                        : null
                    }
                    {this.state.validationData && this.state.validationData.machineGroupTypeName && this.state.validationData.machineGroupTypeName.page_visible ? (
                        <div className={this.props.classes.inputFieldDiv}>
                            <InputLabel className={this.props.classes.textFieldLabel}>
                                {this.state.validationData.machineGroupTypeName.display_name}
                                {(this.state.validationData.machineGroupTypeName.validations && this.state.validationData.machineGroupTypeName.validations.required) ? (<span className={this.props.classes.requiredClass}>*</span>) : null}
                            </InputLabel>
                            <Select
                                native
                                value={this.state.machineGroupTypeId}
                                onChange={(event) => this.inputChangeHandler(event, "machineGroupTypeId")}
                                input={
                                    <OutlinedInput
                                        variant="outlined"
                                        name="machineGroupTypeId"
                                        id="language-native-simple"
                                        labelWidth={this.state.labelWidth}
                                        className={this.props.classes.textField}
                                        error={this.state.machineGroupTypeError}
                                        classes={{ input: this.props.classes.outlinedInput }}
                                    />
                                }
                            >
                                {this.getMachineGroupType()}
                            </Select>
                        </div>
                    )
                        : null
                    }
                    {this.state.validationData && this.state.validationData.schedulesName && this.state.validationData.schedulesName.page_visible ? (
                        <div className={this.props.classes.inputFieldDiv}>
                            <InputLabel className={this.props.classes.textFieldLabel}>
                                {this.state.validationData.schedulesName.display_name}
                                {(this.state.validationData.schedulesName.validations && this.state.validationData.schedulesName.validations.required) ? (<span className={this.props.classes.requiredClass}>*</span>) : null}
                            </InputLabel>
                            <Select
                                native
                                value={this.state.scheduleId}
                                onChange={(event) => this.inputChangeHandler(event, "scheduleId")}
                                input={
                                    <OutlinedInput
                                        variant="outlined"
                                        name="scheduleId"
                                        id="language-native-simple"
                                        labelWidth={this.state.labelWidth}
                                        className={this.props.classes.textField}
                                        error={this.state.scheduleError}
                                        classes={{ input: this.props.classes.outlinedInput }}
                                    />
                                }
                            >
                                {this.getScheduleName()}
                            </Select>
                        </div>
                    )
                        : null
                    }
                    {this.state.validationData && this.state.validationData.lossListsName && this.state.validationData.lossListsName.page_visible ? (
                        <div className={this.props.classes.inputFieldDiv}>
                            <InputLabel className={this.props.classes.textFieldLabel}>
                                {this.state.validationData.lossListsName.display_name}
                                {(this.state.validationData.lossListsName.validations && this.state.validationData.lossListsName.validations.required) ? (<span className={this.props.classes.requiredClass}>*</span>) : null}
                            </InputLabel>
                            <Select
                                native
                                value={this.state.lossListId}
                                onChange={(event) => this.inputChangeHandler(event, "lossListId")}
                                input={
                                    <OutlinedInput
                                        variant="outlined"
                                        name="lossListId"
                                        id="language-native-simple"
                                        labelWidth={this.state.labelWidth}
                                        className={this.props.classes.textField}
                                        error={this.state.lossListError}
                                        classes={{ input: this.props.classes.outlinedInput }}
                                    />
                                }
                            >
                                {this.getLossListName()}
                            </Select>
                        </div>
                    )
                        : null
                    }
                    {this.state.validationData && this.state.validationData.joblistsName && this.state.validationData.joblistsName.page_visible ? (
                        <div className={this.props.classes.inputFieldDiv}>
                            <InputLabel className={this.props.classes.textFieldLabel}>
                                {this.state.validationData.joblistsName.display_name}
                                {(this.state.validationData.joblistsName.validations && this.state.validationData.joblistsName.validations.required) ? (<span className={this.props.classes.requiredClass}>*</span>) : null}
                            </InputLabel>
                            <Select
                                native
                                value={this.state.jobListId}
                                onChange={(event) => this.inputChangeHandler(event, "jobListId")}
                                input={
                                    <OutlinedInput
                                        variant="outlined"
                                        name="jobListId"
                                        id="language-native-simple"
                                        labelWidth={this.state.labelWidth}
                                        className={this.props.classes.textField}
                                        error={this.state.jobListError}
                                        classes={{ input: this.props.classes.outlinedInput }}
                                    />
                                }
                            >
                                {this.getJobListName()}
                            </Select>
                        </div>
                    )
                        : null
                    }

                    <div style={{ padding: "0px", margin: "0px" }}>
                        <div className={this.props.classes.inputFieldDiv}>
                            <InputLabel className={this.props.classes.textFieldLabel}>
                                {t("Display Position")}
                            </InputLabel>
                            <Select
                                native
                                value={this.state.displayPosition}
                                onChange={(event) => this.inputChangeHandler(event, "displayPosition")}
                                input={
                                    <OutlinedInput
                                        variant="outlined"
                                        name="displayPosition"
                                        id="language-native-simple"
                                        labelWidth={this.state.labelWidth}
                                        className={this.props.classes.textField}
                                        error={this.state.displayPositionError}
                                        classes={{ input: this.props.classes.outlinedInput }}
                                    />
                                }
                            >
                                {this.getDisplayPosition()}
                            </Select>
                        </div>
                    </div>

                    {/* driver & driven dropdown */}
                    <div style={{ padding: "0px", margin: "0px" }}>
                        <div className={this.props.classes.inputFieldDiv}>
                            <InputLabel className={this.props.classes.textFieldLabel}>
                                {t("Driver")} <span className={this.props.classes.requiredClass}>*</span>
                            </InputLabel>
                            <Select
                                native
                                value={this.state.driver}
                                onChange={(event) => this.inputChangeHandler(event, "driver")}
                                input={
                                    <OutlinedInput
                                        variant="outlined"
                                        name="displayPosition"
                                        id="language-native-simple"
                                        labelWidth={this.state.labelWidth}
                                        className={this.props.classes.textField}
                                        classes={{ input: this.props.classes.outlinedInput }}
                                    />
                                }
                            >
                                {this.getDriver()}
                            </Select>
                        </div>
                    </div>




                    <div style={{ padding: "0px", margin: "0px" }}>
                        <div className={this.props.classes.inputFieldDiv}>
                            <InputLabel className={this.props.classes.textFieldLabel}>
                                 {t("Coupling 1")} <span className={this.props.classes.requiredClass}>*</span>
                            </InputLabel>
                            <Select
                                native
                                value={this.state.driven1Coupling}
                                onChange={(event) => this.inputChangeHandler(event, "driven1Coupling")}
                                input={
                                    <OutlinedInput
                                        variant="outlined"
                                        name="displayPosition"
                                        id="language-native-simple"
                                        labelWidth={this.state.labelWidth}
                                        className={this.props.classes.textField}
                                        classes={{ input: this.props.classes.outlinedInput }}
                                    />
                                }
                            >
                                {this.getDriven1Coupling()}
                            </Select>
                        </div>
                    </div>
                    <div style={{ padding: "0px", margin: "0px" }}>
                        <div className={this.props.classes.inputFieldDiv}>
                            <InputLabel className={this.props.classes.textFieldLabel}>
                                {t("DRIVEN1  COUPLING SPEED RATIO")}
                              </InputLabel>
                            <TextField
                                style={{ backgroundColor: (this.state.driven1Coupling === "BELT DRIVEN" || this.state.driven1Coupling === "CHAIN" || this.state.driven1Coupling === "GEAR") ? "#fff" : "#f6f6f6" }}
                                id="driven1CouplingSpeedRatio"
                                name="driven1CouplingSpeedRatio"
                                disabled={this.state.driven1Coupling !== "BELT DRIVEN" && this.state.driven1Coupling !== "CHAIN" && this.state.driven1Coupling !== "GEAR"}
                                variant="outlined"
                                className={this.props.classes.textField}
                                onChange={(event) => this.inputChangeHandler(event, "driven1CouplingSpeedRatio")}
                                value={this.state.driven1CouplingSpeedRatio}
                                helperText={this.state.driven1CouplingSpeedRatioError ? this.state.errorMessage : null}
                                error={this.state.driven1CouplingSpeedRatioError}
                                InputProps={{
                                    classes: {
                                        input: this.props.classes.outlinedInput
                                    }
                                }}
                            />

                        </div>
                    </div>


                    <div style={{ padding: "0px", margin: "0px" }}>
                        <div className={this.props.classes.inputFieldDiv}>
                            <InputLabel className={this.props.classes.textFieldLabel}>
                                {t("Driven1")} 
                            </InputLabel>
                            <Select
                                native
                                value={this.state.driven1}
                                onChange={(event) => this.inputChangeHandler(event, "driven1")}
                                input={
                                    <OutlinedInput
                                        variant="outlined"
                                        name="displayPosition"
                                        id="language-native-simple"
                                        labelWidth={this.state.labelWidth}
                                        className={this.props.classes.textField}
                                        classes={{ input: this.props.classes.outlinedInput }}
                                    />
                                }
                            >
                                {this.getDrivenOne()}
                            </Select>
                        </div>
                    </div>
                    <div style={{ padding: "0px", margin: "0px" }}>
                        <div className={this.props.classes.inputFieldDiv}>
                            <InputLabel className={this.props.classes.textFieldLabel}>
                                {t("DRIVEN1 SPEED RATIO")}                                
                            </InputLabel>
                            <TextField
                                style={{ backgroundColor: (this.state.driven1 === "PUMP" || this.state.driven1 === "BLOWER" || this.state.driven1 === "COMPRESSOR" || this.state.driven1 === "GEAR BOX") ? "#fff" : "#f6f6f6" }}

                                id="driven1SpeedRatio"
                                name="driven1SpeedRatio"
                                disabled={this.state.driven1 !== "PUMP" && this.state.driven1 !== "BLOWER" && this.state.driven1 !== "COMPRESSOR" && this.state.driven1 !== "GEAR BOX"}
                                variant="outlined"
                                className={this.props.classes.textField}
                                helperText={this.state.driven1SpeedRatioError ? this.state.errorMessage1 : null}
                                onChange={(event) => this.inputChangeHandler(event, "driven1SpeedRatio")}
                                value={this.state.driven1SpeedRatio}
                                error={this.state.driven1SpeedRatioError}
                                InputProps={{
                                    classes: {
                                        input: this.props.classes.outlinedInput
                                    }
                                }}
                            />
                        </div>
                    </div>

                    <div style={{ padding: "0px", margin: "0px" }}>
                        <div className={this.props.classes.inputFieldDiv}>
                            <InputLabel className={this.props.classes.textFieldLabel}>
                                 {t("Coupling 2")} 
                            </InputLabel>
                            <Select
                                native
                                value={this.state.driven2Coupling}
                                onChange={(event) => this.inputChangeHandler(event, "driven2Coupling")}
                                input={
                                    <OutlinedInput
                                        variant="outlined"
                                        name="displayPosition"
                                        id="language-native-simple"
                                        labelWidth={this.state.labelWidth}
                                        className={this.props.classes.textField}
                                        classes={{ input: this.props.classes.outlinedInput }}
                                    />
                                }
                            >
                                {this.getDriven1Coupling()}
                            </Select>
                        </div>
                    </div>
                    <div style={{ padding: "0px", margin: "0px" }}>
                        <div className={this.props.classes.inputFieldDiv}>
                            <InputLabel className={this.props.classes.textFieldLabel}>
                                {t("DRIVEN2  COUPLING SPEED RATIO")}
                             </InputLabel>
                            <TextField
                                style={{ backgroundColor: (this.state.driven2Coupling === "BELT DRIVEN" || this.state.driven2Coupling === "CHAIN" || this.state.driven2Coupling === "GEAR") ? "#fff" : "#f6f6f6" }}

                                id="driven2CouplingSpeedRatio"
                                name="driven2CouplingSpeedRatio"
                                disabled={this.state.driven2Coupling !== "BELT DRIVEN" && this.state.driven2Coupling !== "CHAIN" && this.state.driven2Coupling !== "GEAR"}
                                variant="outlined"
                                className={this.props.classes.textField}
                                onChange={(event) => this.inputChangeHandler(event, "driven2CouplingSpeedRatio")}
                                value={this.state.driven2CouplingSpeedRatio}
                                helperText={this.state.driven2CouplingSpeedRatioError ? this.state.errorMessage2 : null}
                                error={this.state.driven2CouplingSpeedRatioError}
                                InputProps={{
                                    classes: {
                                        input: this.props.classes.outlinedInput
                                    }
                                }}
                            />
                        </div>
                    </div>


                    <div style={{ padding: "0px", margin: "0px" }}>
                        <div className={this.props.classes.inputFieldDiv}>
                            <InputLabel className={this.props.classes.textFieldLabel}>
                                {t("Driven2")} 
                            </InputLabel>
                            <Select
                                native
                                value={this.state.driven2}
                                onChange={(event) => this.inputChangeHandler(event, "driven2")}
                                input={
                                    <OutlinedInput
                                        variant="outlined"
                                        name="displayPosition"
                                        id="language-native-simple"
                                        labelWidth={this.state.labelWidth}
                                        className={this.props.classes.textField}
                                        classes={{ input: this.props.classes.outlinedInput }}
                                    />
                                }
                            >
                                {this.getDrivenTwo()}
                            </Select>
                        </div>
                    </div>
                    <div style={{ padding: "0px", margin: "0px" }}>
                        <div className={this.props.classes.inputFieldDiv}>
                            <InputLabel className={this.props.classes.textFieldLabel}>
                                {t("DRIVEN2  SPEED RATIO")}
                            </InputLabel>
                            <TextField
                                style={{ backgroundColor: (this.state.driven2 === "PUMP" || this.state.driven2 === "BLOWER" || this.state.driven2 === "COMPRESSOR") ? "#fff" : "#f6f6f6" }}
                                id="driven2SpeedRatio"
                                name="driven2SpeedRatio"
                                disabled={this.state.driven2 !== "PUMP" && this.state.driven2 !== "BLOWER" && this.state.driven2 !== "COMPRESSOR"}
                                variant="outlined"
                                className={this.props.classes.textField}
                                onChange={(event) => this.inputChangeHandler(event, "driven2SpeedRatio")}
                                value={this.state.driven2SpeedRatio}
                                helperText={this.state.driven2SpeedRatioError ? this.state.errorMessage3 : null}
                                error={this.state.driven2SpeedRatioError}
                                InputProps={{
                                    classes: {
                                        input: this.props.classes.outlinedInput
                                    }
                                }}
                            />
                        </div>
                    </div>

                    {this.state.validationData && this.state.validationData.notes && this.state.validationData.notes.page_visible ? (
                        <div className={this.props.classes.textareaInputFieldDiv}>
                            <InputLabel className={this.props.classes.textareaFieldLabel}>
                                {this.state.validationData.notes.display_name}
                                {(this.state.validationData.notes.validations && this.state.validationData.notes.validations.required) ? (<span className={this.props.classes.requiredClass}>*</span>) : null}
                            </InputLabel>
                            <TextField
                                id="note"
                                multiline
                                rows="6"
                                helperText={(this.state.validationData.notes.validations && this.state.validationData.notes.validations.helpText) ? (this.state.validationData.notes.validations.helpText) : null}
                                value={this.state.notes}
                                variant="outlined"
                                onChange={(event) => this.inputChangeHandler(event, "notes")}
                                className={this.props.classes.textArea}
                                error={this.state.notesError}
                                InputProps={{
                                    classes: {
                                        multiline: this.props.classes.outlinedInput
                                    }
                                }}
                            />
                        </div>
                    )
                        : null
                    }
                    {this.state.validationData && this.state.validationData.specs && this.state.validationData.specs.page_visible ? (
                        <div className={this.props.classes.textareaInputFieldDiv}>
                            <InputLabel className={this.props.classes.textareaFieldLabel}>
                                {this.state.validationData.specs.display_name}
                                {(this.state.validationData.specs.validations && this.state.validationData.specs.validations.required) ? (<span className={this.props.classes.requiredClass}>*</span>) : null}
                            </InputLabel>
                            <TextField
                                id="specification"
                                multiline
                                rows="6"
                                helperText={(this.state.validationData.specs.validations && this.state.validationData.specs.validations.helpText) ? (this.state.validationData.specs.validations.helpText) : null}
                                value={this.state.specs}
                                variant="outlined"
                                onChange={(event) => this.inputChangeHandler(event, "specs")}
                                className={this.props.classes.textArea}
                                error={this.state.specsError}
                                InputProps={{
                                    classes: {
                                        multiline: this.props.classes.outlinedInput
                                    }
                                }}
                            />
                        </div>
                    )
                        : null
                    }
                </CardContent>
            </Card >
        );
    }
}

const mapStateToProps = (state) => {
    return {
        schedulesData: state.schedulesDataReducer,
        jobsData: state.jobListDataReducer,
        lossCodes: state.lossListDataReducer,
        staticData: state.staticDataReducer,
        validationData: state.viewDefinitionReducer,
        machineGroupCount: state.machineGroupCountReducer
    }
}

const mapDispatchToProps = dispatch => {
    return ({
        getTableData: (selectedNavtab, currentScopeSelectionId, setToStore) => (dispatch(actionCreators.getTableData(selectedNavtab, currentScopeSelectionId, setToStore))),
        updateMachineGroupsEntity: (machineGroupsEntityData, id) => (dispatch(actionCreators.updateMachineGroupsEntity(machineGroupsEntityData, id))),
        updateSelectedTab: (selectedTab) => (dispatch(actionCreators.updateSelectedTab(selectedTab))),
        getMachineGroupCountByPlantId: (plantId) => (dispatch(actionCreators.getMachineGroupCountByPlantId(plantId)))
    })
}

export default withTranslation()(connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(MachineGroupsEntity)));

