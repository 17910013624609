import React from "react";
import { Redirect } from "react-router-dom";
import { connect } from "react-redux";
import { withAuth } from "react-oidc-context";

import * as actionCreators from "./actions/actionCreators";

import "./scss/style.scss";

class App extends React.Component {

  componentWillMount() {
    if(this.props.userData.loggedIn === undefined) {
        this.props.isUserAuthenticated();
      }
  }

  redirectToTarget = () => {
    const { auth } = this.props;
    const { hash = "" } = auth.user.state;
    
    let targetUrl = hash.replace("#", "") || "/dashboard/MonitoringTable";
    
    if(targetUrl === "/") {
      if (this.props.userData.loggedIn) {
        this.props.updateSelectedTab(0);
        this.props.updateDrawer(true);
        return <Redirect to="/dashboard/MonitoringTable" />;
      } else {
        return <Redirect to="/login" />;
      }
    }
    return <Redirect to={targetUrl} />
  };

  render() {
    return <>{this.redirectToTarget()}</>;
  }
}

const mapStateToProps = (state) => ({
  userData: state.userDataReducer,
});

const mapDispatchToProps = (dispatch) => {
  return {
    isUserAuthenticated: () => (dispatch(actionCreators.isUserAuthenticated())),
    updateDrawer: (value) => dispatch(actionCreators.updateDrawer(value)),
    updateSelectedTab: (selectedTab) =>
      dispatch(actionCreators.updateSelectedTab(selectedTab)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(withAuth(App));
