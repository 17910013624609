import React, { Component } from 'react'
import { withTranslation } from "react-i18next";

class ShiftSelector extends Component {
    constructor(props){
        super(props);
    } 

    shiftSelector = (e) => {
        this.props.closeCal();
        this.props.rangeSelect(e, "userShiftSelection")
    }

    closeSelector = (e) => {
        this.props.closeCal();
    }

    render() {   
        const {t} =this.props;
        return (          
            <div className="shiftSelector-container">
                <div className="shiftSelector-wrp">
                    {                         
                        this.props.plantShift.map((list, index) =>
                        <span key={index} onClick={(e) => this.shiftSelector(e)} data-value={index}>{list.shift}</span>
                        )
                    }
                    
                </div>
                <div className="shiftSelector-action">
                    <span onClick={(e) => this.props.userSelectionFromCal(e)}>{t("Select")}</span>
                    <span onClick={(e) => this.closeSelector(e)}>{t("Cancel")}</span>
                </div>
            </div>

        )
    }
}

export default withTranslation()(ShiftSelector);