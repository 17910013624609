import React, { Component } from 'react'
import { withTranslation } from "react-i18next";

class MaintenanceMetrics extends Component {
    constructor(props){
        super(props);
    }  
    
    render() {
        const {t}=this.props;
        return (
            <div className="MaintenanceMetrics-wrp display-text"> 
                <div className="list-selection-wrp display-text" style={{ fontSize: "18px", paddingLeft: "32px", width: "100%", verticalAlign: "text-top"}}>
                    {t("Number of Breakdowns")}:{this.props.maintenanceMetricsData.breakdownCount} <span></span>
                </div>
            </div>
        )
    }
}
  
export default withTranslation()(MaintenanceMetrics);