import React, { Component } from 'react';
import { connect } from 'react-redux';
import moment from "moment";
import {
    withStyles,
    Dialog
} from '@material-ui/core';
import { Close } from '@material-ui/icons';
import { withTranslation } from "react-i18next";

import * as actionCreators from '../../../../actions/actionCreators';

const styles = theme => ({
    alarmWatchlistModalHeading: {
        backgroundColor: "#bfbfbf",
        color: "#fdfdfe",
        textAlign: "center",
        padding:"3px",
        fontSize:"24px"
    },
    table: {
        borderCollapse: "collapse",
        backgroundColor: "#f9f9f9",
        width: "100%",
        '& th': {
            textAlign: "left",
            padding: "6px",
            textDecoration: "underline",
            fontWeight: "normal",
            fontSize: "15px",
        },
        '& td': {
            textAlign: "left",
            padding: "6px",
            fontSize: "16px"
        }
    }
});

class AlarmWatchlistModal extends Component {
    handleClose = () => {
        this.props.closeAlarmWatchlistModal();
    }

    componentDidMount() {
        let startTime = moment(this.props.from).format('YYYY-MM-DD HH:mm:ss');
        let endTime = moment(this.props.to).format('YYYY-MM-DD HH:mm:ss');
        this.props.getAlarmWatchlistData(
            this.props.deviceId, startTime, endTime, this.props.intervalUnit, this.props.intervalValue);
    }

    renderTableRows() {
        if (this.props.alarmWatchlistData.alarmInfo !== undefined && this.props.alarmWatchlistData.alarmInfo.length > 0) {
            let featureList = this.props.featureList;
            return this.props.alarmWatchlistData.alarmInfo.map((info, index) => {
                const { featureCode, confidencePercent, daysToCaution, daysToWarning } = info;
                let featureData = featureList.find(ele => ele.value === featureCode);

                let cautionDays = this.props.t('No trend to failure');
                let cautionDaysStyle = {};
                if (daysToCaution !== null) {
                    if (daysToCaution < 0) {
                        cautionDays = 'Already alarming';
                        cautionDaysStyle = {
                            color: 'red'
                        }
                    } else {
                        cautionDays = daysToCaution + this.props.t(' Days');
                    }
                }

                let warningDays = this.props.t('No trend to failure');
                let warningDaysStyle = {};
                if (daysToWarning !== null) {
                    if (daysToWarning < 0) {
                        warningDays = 'Already alarming';
                        warningDaysStyle = {
                            color: 'red'
                        }

                        cautionDays = 'Already alarming';
                        cautionDaysStyle = {
                            color: 'red'
                        }
                    } else {
                        warningDays = daysToWarning + this.props.t(' Days');
                    }
                }
                
                let confidencePercentage  = (confidencePercent === 0 || confidencePercent == undefined) 
                    ? '-' : confidencePercent + '%';
                
                return (
                    <tr key={featureCode}>
                        <td>{featureData === undefined ? featureCode : featureData.viewValue}</td>
                        <td style = {cautionDaysStyle}>{cautionDays}</td>
                        <td style = {warningDaysStyle}>{warningDays}</td>
                        <td>{confidencePercentage}</td>
                    </tr>
                )
            })
        }

        if (!this.props.loaderState.load.value) {
            return (
                <tr>
                    <td colspan="4" style={{marginTop:'10px', color:'red'}}>
                       {this.props.t(" Data could not be fetched, please try again later.")}
                    </td>
                </tr>
            )
        }

        return null;
    }

    render() {
        const {t}=this.props;
        return (
            <Dialog
                open={this.props.viewAlarmWatchlist}
                onClose={this.handleClose}
                aria-labelledby="alart-watchlist-dialog-title"
                maxWidth="sm"
                fullWidth={true}
            >
                <div>
                    <div className={this.props.classes.alarmWatchlistModalHeading}>
                        {t("Alarm Forecast")}
                        <Close 
                            onClick={this.handleClose} 
                            className='close' 
                            style={{marginTop:'6px', marginRight:'6px', cursor: 'pointer'}}
                        />
                    </div>
                    <table className={this.props.classes.table}>
                    <tr>
                        <th>{t("Feature")}</th>
                        <th>{t("Time to Caution")}</th>
                        <th>{t("Time to Warning")}</th>
                        <th>{t("Confidence")}</th>
                    </tr>
                    {this.renderTableRows()}
                    </table>
                </div>
            </Dialog>
        )
    }
}

const mapStateToProps = state => {
    return {
        alarmWatchlistData: state.alarmWatchlistReducer,
        loaderState: state.loaderReducer,
    }
}

const mapDispatchToProps = dispatch => {
    return {
        getAlarmWatchlistData: (deviceId, from, to, intervalUnit, intervalValue) => 
        (dispatch(actionCreators.getAlarmWathlistData(deviceId, from, to, intervalUnit, intervalValue)))
    }
}

export default withTranslation()(connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(AlarmWatchlistModal)));