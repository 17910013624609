import React, { Component, Fragment } from "react";
import { connect } from 'react-redux';
import { withStyles, Button, IconButton, Switch, Tooltip, Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions } from '@material-ui/core';
import MUIDataTable from "mui-datatables";
import { createMuiTheme, MuiThemeProvider } from '@material-ui/core/styles';
import { Edit, Delete, Launch, Close } from '@material-ui/icons';

import * as actionCreators from '../../actions/actionCreators';
import { linux_deployment_platform } from '../../configuration';
import UsersModal from './UsersModal';
import CsvDownloadComponent from '../CsvDownloadComponent';
import CsvUploadComponent from '../CsvUploadComponent';
import DeleteUserConfirmationModal from './DeleteUserConfirmationModal';
import { withTranslation } from "react-i18next";
let currentLanguage = localStorage.getItem("lang");
let type=localStorage.getItem("parentOrganizationtype")

if (currentLanguage === "null" || currentLanguage === null) {
    currentLanguage = "en"
}
const styles = theme => ({
    addButton: {
        background: "#2abbe8",
        color: "#504a4a",
        fontSize: "12px",
        fontWeight: "500",
        "&:hover": {
            background: "#2abbe8a1"
        }
    },
    createNewButton: {
        fontWeight: '400',
        marginLeft: 12
    },
    editIcon: {
        'font-size': 18,
        cursor: 'pointer',
        color: 'rgba(0, 200, 83, 0.5)',
        '&:hover': {
            color: 'rgba(0, 200, 83, 1.0)'
        }
    },
    deleteIcon: {
        'font-size': 18,
        cursor: 'pointer',
        color: 'rgba(221, 44, 0, 0.5)',
        '&:hover': {
            color: 'rgba(221, 44, 0, 1.0)'
        }
    },
    unarchiveIcon: {
        'font-size': 18,
        cursor: 'pointer',
        color: 'rgba(0, 200, 83, 0.5)',
        '&:hover': {
            color: 'rgba(0, 200, 83, 1.0)'
        }
    },
    bulkEditArchiveIcon: {
        cursor: 'pointer',
        color: 'rgba(221, 44, 0, 0.5)',
        '&:hover': {
            color: 'rgba(221, 44, 0, 1.0)'
        }
    },
    bulkEditUnarchiveIcon: {
        cursor: 'pointer',
        color: 'rgba(0, 200, 83, 0.5)',
        '&:hover': {
            color: 'rgba(0, 200, 83, 1.0)'
        }
    },
    flexDiv: {
        display: "flex",
        flexDirection: "row-reverse",
        marginRight: "20px"
    },
    buttonContainer: {
        padding: '5px 10px 10px 0px',
        width: '100%'
    },
    saveButton: {
        backgroundColor: '#2abbe8',
        '&:hover': {
            backgroundColor: '#2abbe8',
        }
    },
    hideHeaderForActiveData: {
        '& thead tr th:nth-child(1)': {
            width: 10
        },
        '& tbody tr td:nth-of-type(1)': {
            padding: '4px 12px',
        },
        '& thead tr th:nth-child(2)': {
            width: 10,
            padding: '4px 15px'
        },
        '& tbody tr td:nth-of-type(2)': {
            padding: '4px 12px',
        },
        '& thead tr th:nth-child(3)': {
            width: 10,
            padding: '4px 30px'
        },
        '& tbody tr td:nth-of-type(3)': {
            padding: '4px 12px',
        }
    },
    hideHeaderForArchivedData: {
        '& thead tr th:nth-child(1)': {
            width: 10,
            padding: '4px 12px',
            // color: '#fff'
        },
        '& tbody tr td:nth-of-type(1)': {
            padding: '4px 12px'
        },
        '& thead tr th:nth-child(2)': {
            width: 10,
            // padding: '4px 12px',
            // color: '#fff'
        },
        '& tbody tr td:nth-of-type(2)': {
            padding: '4px 12px'
        }
    },
    buttonLabel: {
        height: 24
    },
    iconBotton: {
        '&:hover': {
            color: '#3f51b5'
        }
    }
});

class UsersTable extends Component {

    state = {
        columns: [],
        data: null,
        selectedRows: null,
        enableBulkEditingView: false,
        showArchivedData: false,
        headerClass: "",
        openPromptModal: false,
        rowsToBeArchived: [],
        lang:currentLanguage 

    }

    getMuiTheme = () => createMuiTheme({
        overrides: {
            MUIDataTableHeadCell: {
                fixedHeader: {
                    'z-index': 0
                },
                hintIconAlone: {
                    marginTop: '2px',
                    fontSize: '14px'
                }
            },
            typography: {
                useNextVariants: true,
            },
            MUIDataTable: {
                responsiveScroll: {
                    height: 'calc(85vh - 90px)',
                    'max-height': 'calc(85vh - 90px) !important',
                    'z-index':'0'
                }
            },
            MuiTableCell: {
                root: {
                    "padding-right": "38px !important"
                },
                head: {
                    fontSize: 14,
                    fontWeight: 800,
                    letterSpacing: 0.25
                },
                body: {
                    fontSize: 14,
                    letterSpacing: 0.25
                }
            }
        }
    })

    // handle routing for entity view
    navigateToEntityView = () => {
        this.props.navigateToEntityView("", this.props.location.pathname.split("/")[2]);
    }

    // handle delete functionality of selected record
    onDelete = (rowId, multipleDeletion) => {
        let rowsToBeArchived = [];
        if (multipleDeletion) {
            this.state.selectedRows.forEach(item => {
                rowsToBeArchived.push(item.emailAddress);
            })
        }
        else {
            let obj = this.state.data.find(d => d.id === rowId);
            rowsToBeArchived.push(obj.emailAddress);
        }

        this.setState({
            rowsToBeArchived,
            openPromptModal: true
        })
    }

    // handle user action for deleting
    handlePromptModal = (action) => {
        if (action === "agree") {
            let selectedtab = this.props.userData.selectedTab;
            let selectedNavTab = this.props.location.pathname.split("/")[2];
            let currentScopeSelectionId = this.getCurrentScopeSelectionId(selectedtab);
            if (this.state.rowsToBeArchived.length > 0) {
                this.props.deleteRow(selectedNavTab, currentScopeSelectionId, this.state.rowsToBeArchived);
            }
        }
        this.setState({
            openPromptModal: false,
            rowsToBeArchived: []
        })
    }

    // handle unarchive functionality of selected records
    onRestore = (rowIndex, multipleRestore) => {
        let rowsToBeUnarchived = [];
        let selectedNavTab = this.props.location.pathname.split("/")[2];
        let selectedtab = this.props.userData.selectedTab;
        let currentScopeSelectionId = this.getCurrentScopeSelectionId(selectedtab);
        if (multipleRestore) {
            this.state.selectedRows.forEach(item => {
                rowsToBeUnarchived.push(item.id);
            })
        }
        else {
            rowsToBeUnarchived.push(rowIndex);
        }
        this.props.restoreEntity("UsersTable", rowsToBeUnarchived, selectedNavTab, currentScopeSelectionId, false, this.state.showArchivedData);
    }

    // handle edit functionality of selected record
    onEdit = (rowIndex) => {
        let object = this.props.tableData.tableData.find((item, index) => {
            return index === rowIndex
        })
        this.props.navigateToEntityView(object, "UsersEntity");
    }

    // handle routing for foreign keys in the table
    handleForeignKey = (rowIndex) => {
        let object = this.props.tableData.tableData.find(item => {
            return item.id === rowIndex
        });
        let selectedNavTab = this.props.location.pathname.split("/")[2];

        this.props.navigateToEntityView(object.organization, "OrganizationsEntity", selectedNavTab);
    }

    // gets the id of current plant selected in scope selector
    getCurrentScopeSelectionId = (selectedTab) => {
        let selectedScopeData = "";
        if (this.props.location.pathname.split("/").length === 3) {
            let currentEntity = this.props.location.pathname.split("/")[2];

            if (currentEntity === "OrganizationsTable" || currentEntity === "PlantsTable" || currentEntity === "UsersTable") {
                selectedScopeData = this.props.userData.selectedOrganization;
            }
            else if (currentEntity === "MachineGroupsTable" || currentEntity === "MachinesTable" || currentEntity === "SchedulesTable" ||
                currentEntity === "JobsTable" || currentEntity === "LossCodesTable" || currentEntity === "MonitorsTable" || currentEntity === "DevicesTable" ||
                currentEntity === "AlertsTable") {
                selectedScopeData = this.props.userData.selectedPlant;
            }
        }
        if (selectedScopeData) {
            return selectedScopeData;
        }
    }

    componentDidMount() {
        let selectedNavTab = this.props.location.pathname.split("/")[2];
        let selectedtab = this.props.userData.selectedTab;
        let currentScopeSelectionId = this.getCurrentScopeSelectionId(selectedtab);
        if (currentScopeSelectionId) {
            this.props.getTableData(selectedNavTab, currentScopeSelectionId, false, this.state.showArchivedData);
        }
    }

    // initializes table component based on the view-definitions received from the api call
    tableConfigurations = () => {
        let columns = [];
        let data = [];
        let currentColumnObject = {};

        if (this.props.tableData.tableData.length > 0 && Object.keys(this.props.config).length > 0) {
            if (this.props.config.Users.edit.table_visible) {
                columns.push({
                    "name": "edit",
                    "label": this.props.config.Users.edit.display_name,
                    "options": {
                        hint: this.props.t('Edit'),
                        "display": true,
                        "filter": this.props.config.Users.edit.filter_visible,
                        "sort": false,
                        "searchable": false
                    }
                })
            }
            if (this.props.config.Users.delete.table_visible && (this.props.userData.parentOrganizationType === "publisher" || type === "publisher" )) {
                columns.push({
                    "name": "delete",
                    "label": this.props.config.Users.delete.display_name,
                    "options": {
                        hint: this.props.t('Delete'),
                        "display": this.props.userData.parentOrganizationType === "publisher" || type === "publisher"  ? true : false,
                        "filter": this.props.config.Users.delete.filter_visible,
                        "sort": false,
                        "searchable": false
                    }
                })
            }

            for (let key in this.props.tableData.tableData[0]) {
                let currentConfigObject = this.props.config["Users"][key];
                if (currentConfigObject !== undefined) {
                    currentColumnObject.name = currentConfigObject["key"];
                    currentColumnObject.label = currentConfigObject["display_name"];
                    currentColumnObject.options = {};
                    currentColumnObject.options.setCellProps= () => ({ style: { width:'100px' } });
                    currentColumnObject.options.customBodyRender = (value) => <div style={{ width: "100px",wordBreak:"break-all" }}>{value}</div>
                    
                    if (currentConfigObject["table_visible"]) {
                        currentColumnObject.options.display = true;
                        currentColumnObject.options.filter = currentConfigObject["filter_visible"];

                        if (currentConfigObject["ellipsis"]) {
                            currentColumnObject.options.customBodyRender = (value) => <div style={{ maxWidth: 150, overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}>{value}</div>
                            currentColumnObject.options.sort = false;
                            currentColumnObject.options.searchable = false;
                        }

                        if (currentConfigObject["table_hyperlink"] && !this.state.showArchivedData) {
                            currentColumnObject.options.customBodyRender = (value, tableMeta) => {
                                return this.props.userData.parentOrganizationType === "customer" ?
                                    value
                                    : <a onClick={() => this.handleForeignKey(tableMeta.rowData[2], currentConfigObject["key"])}>{value}</a>
                            }
                        }
                    }
                    else {
                        currentColumnObject.options.display = "excluded";
                        currentColumnObject.options.filter = false;
                        currentColumnObject.options.sort = false;
                        currentColumnObject.options.searchable = false;
                    }

                    columns.push(currentColumnObject)
                    currentColumnObject = {};
                }
            }
        }

        data = this.props.tableData.tableData.map((item, index) => {
            item.admin = item.admin ? "Yes" : "No";
            item.edit = <Edit classes={{ root: this.props.classes.editIcon }} onClick={() => this.onEdit(index)} />;
            item.delete = <Delete classes={{ root: this.props.classes.deleteIcon }} onClick={() => this.onDelete(item.id, false)} />;
            return item;
        });

        this.setState({
            columns,
            data
        })

    }

    componentDidUpdate(prevProps, prevState) {
        let selectedtab = this.props.userData.selectedTab;
        let selectedNavTab = this.props.location.pathname.split("/")[2];
        if ((prevProps.tableData !== this.props.tableData)) {
            let headerClass = this.state.showArchivedData ? this.props.classes.hideHeaderForArchivedData : this.props.classes.hideHeaderForActiveData;
            this.setState({
                headerClass
            })
            if ((prevProps.selectedScopeData !== this.props.selectedScopeData)) {
                let currentScopeSelectionId = this.getCurrentScopeSelectionId(selectedtab);
                this.props.getTableData(selectedNavTab, currentScopeSelectionId, false, this.state.showArchivedData);
            }
            else {
                this.tableConfigurations();
            }
        }
        else if ((prevProps.selectedScopeData !== this.props.selectedScopeData && prevProps.selectedTab === this.props.selectedTab) || prevState.showArchivedData !== this.state.showArchivedData) {
            let currentScopeSelectionId = this.getCurrentScopeSelectionId(selectedtab);
            if (currentScopeSelectionId) {
                this.props.getTableData(selectedNavTab, currentScopeSelectionId, false, this.state.showArchivedData);
            }
        }
        
    }

    // update selected records for bulk editing
    updateSelectedRecords = (updatedObject) => {
        for (let row of this.state.selectedRows) {
            row.admin = row.admin === 'Yes' ? true : false;
            if (updatedObject.changedLanguage) {
                row.language = updatedObject.language;
            }
            if (updatedObject.changedNotes) {
                row.notes = updatedObject.notes;
            }
            if (updatedObject.changedAdmin) {
                row.admin = updatedObject.admin;
            }
            if (updatedObject.changedTimezone) {
                row.timezone = updatedObject.timezone;
            }
            if (updatedObject.changedAlertMedia) {
                row.alertMedia = updatedObject.alertMedia;
            }
            if (updatedObject.changedAlertType) {
                row.alertTypes = updatedObject.alertTypes;
            }
        }

        let selectedNavTab = this.props.location.pathname.split("/")[2];
        let selectedtab = this.props.userData.selectedTab;
        let currentScopeSelectionId = this.getCurrentScopeSelectionId(selectedtab);

        this.props.updateAll(selectedNavTab, currentScopeSelectionId, this.state.selectedRows);
    }

    // enable bulk editing view
    enableBulkEditingView = () => {
        this.setState({
            enableBulkEditingView: true
        })
    }

    // disable bulk editing view
    disableBulkEditingView = () => {
        this.setState({
            enableBulkEditingView: false
        })
    }   
    
    // create custom toolbar for table view
    getCustomToolbarSelect = () => {
        return (
            <div>
                <IconButton className="class-mk-find" className={this.state.showArchivedData ? 'custom-hideSvg' : null} onClick={this.state.showArchivedData ? () => { return false } : this.enableBulkEditingView}>
                    {this.state.showArchivedData ? null : <Launch />}
                </IconButton>
                {this.props.userData.parentOrganizationType === "publisher" || type === "publisher"  ?
                <IconButton style={{ marginRight: 14 }} onClick={() => this.onDelete(undefined, true)}>
                    {
                        <Delete classes={{ root: this.props.classes.bulkEditArchiveIcon }} />
                    }
                </IconButton>
                :null}
            </div>
        )
    }

    // handle multiple record selection
    selectedRows = (currentRowsSelected, allRowsSelected) => {
        let selectedRows = [];
        for (let data of allRowsSelected) {
            let selectedIndex = data.dataIndex;
            let selectedObject = this.state.data[selectedIndex];

            let newObject = {};
            newObject.admin = selectedObject.admin;
            newObject.alertMedia = selectedObject.alertMedia;
            newObject.emailAddress = selectedObject.emailAddress;
            newObject.password = null;
            newObject.firstName = selectedObject.firstName;
            newObject.id = selectedObject.id;
            newObject.language = selectedObject.language;
            newObject.lastName = selectedObject.lastName;
            newObject.notes = selectedObject.notes;
            newObject.organizationName = selectedObject.organizationName;
            newObject.phoneNumber = selectedObject.phoneNumber;
            newObject.timezone = selectedObject.timezone;
            newObject.username = selectedObject.userName;

            let plants = [];

            if (selectedObject.plantPermissions.length > 0) {
                for (let permissionData of selectedObject.plantPermissions) {
                    let plant = {};
                    plant.id = permissionData.plants.id;
                    plant.permission = permissionData.permission;
                    plant.name = permissionData.plants.name;
                    plant.selected = permissionData.plants.isActive;

                    plants.push(plant);
                }
            }

            newObject.plants = plants;

            selectedRows.push(newObject)
        }

        this.setState({
            selectedRows
        })
    }

    // purpose : create csv upload/download icons,user can create new Users through csv by editing in csv file before uploading 
    //It uses  <CsvDownloadComponent/> and <CsvUploadComponent/>  which is imported from "../CsvUploadComponent" and "../CsvDownloadComponent"
    //This function also creates "Create NEW" button (for creating new User through UI), which calls navigateToEntityView() function when clicked
    getCustomToolbar = () => {
        return (
            <div style={{ display: 'inline-block' }}>
                {
                    this.state.showArchivedData ? null
                        :
                        (
                            <Fragment>
                                <IconButton className={"class-mk " + this.props.classes.iconBotton}>
                                    <CsvDownloadComponent
                                        parentOrganizationId={this.props.userData.parentOrganizationId}
                                        url={"downloadCSVForUser"}
                                    />
                                </IconButton>
                                <IconButton classes={{ label: this.props.classes.buttonLabel }} className={this.props.classes.iconBotton}>
                                    <CsvUploadComponent
                                        selectedNavTab={"UsersTable"}
                                        currentScopeSelectionId={this.props.userData.selectedOrganization}
                                    />
                                </IconButton>
                            </Fragment>
                        )
                }
               
                <Button variant="outlined" className={this.props.classes.createNewButton}
                    onClick={() => this.navigateToEntityView("", this.props.location.pathname.split("/")[2])}>
                    {this.props.t("Create New")}
                </Button>
            </div>
        )
    }

    // validate multiple record selection based on user permissions
    isRowSelectable = () => {
        let isSelectable = linux_deployment_platform ? "none" : false;
        if (this.state.data && this.state.data.length > 0) {
            isSelectable = this.state.data.length === 0 ? (linux_deployment_platform ? "none" : false) : "multiple";
        }
        return isSelectable;
    }

    render() {
        const {t}=this.props;
        const options = {
            filter: true,
            sort: true,
            search: true,
            customToolbar: () => (this.getCustomToolbar()),
            print: false,
            elevation: 0,
            responsive: "scroll",
            download: false,
            filterType: 'dropdown',
            rowsPerPage: 20,
            rowsPerPageOptions: [20, 50, 100],
            selectableRows: this.isRowSelectable(),
            onRowsSelect: (currentRowsSelected, allRowsSelected) => this.selectedRows(currentRowsSelected, allRowsSelected),
            customToolbarSelect: () => (this.getCustomToolbarSelect()),
            textLabels: {
                body: {
                    noMatch: this.props.t("No matching records")
                },
                pagination: {
                    next: this.props.t("Next Page"),
                    previous: this.props.t("Previous Page"),
                    rowsPerPage: this.props.t("Rows per Page"),
                    displayRows: this.props.t("of")
                },
                toolbar: {
                    search: this.props.t("Search"),
                    download: this.props.t("Download"),
                    print: this.props.t("Print"),
                    viewColumns: this.props.t("View Columns"),
                    filterTable: this.props.t("Filter Table"),
                    upload: this.props.t("Upload"),
                    // download:currentLanguage==="en" ? "Download":"アップロード",
                    showArchivedData: this.props.t("Show Archieved Data"),
                    showActiveData: this.props.t("Show Active Data")


                },
                filter: {
                    title: this.props.t("FILTERS"),
                    reset: this.props.t("Reset")
                },
                viewColumns: {
                    title: this.props.t("Show Columns")
                },
                selectedRows: {
                    text: this.props.t("Reset"),
                    delete: this.props.t("Delete")
                }

            }
        }

        return (
            <Fragment>
                <DeleteUserConfirmationModal
                props={this.props}
                    openPromptModal={this.state.openPromptModal}
                    handlePromptModal={(action) => this.handlePromptModal(action)}
                    entityName={t("User/s")}
                />
                <div id="tableContainer" className={this.state.headerClass}>
                    <MuiThemeProvider theme={this.getMuiTheme()}>
                        {
                            this.state.data !== null ? (
                                <MUIDataTable
                                    title={t("Users")}
                                    data={this.state.data}
                                    columns={this.state.columns}
                                    options={options}
                                />
                            ) : <Fragment></Fragment>
                        }
                    </MuiThemeProvider>
                </div>
                {
                    this.state.enableBulkEditingView ?
                        <UsersModal
                            config={this.props.config.Users}
                            updateSelectedRecords={(state) => (this.updateSelectedRecords(state))}
                            alertMediaTypes={this.props.alertMediaTypes}
                            languages={this.props.appData.languages}
                            timezones={this.props.timezones}
                            disableBulkEditingView={this.disableBulkEditingView}
                            enableBulkEditingView={this.state.enableBulkEditingView} />
                        : null
                }
            </Fragment >
        );
    }
}

const mapStateToProps = state => {
    return {
        userData: state.userDataReducer,
        tableData: state.tableDataReducer,
        appData: state.appReducer
    }
}

const mapDispatchToProps = dispatch => {
    return {
        getTableData: (selectedNavtab, currentScopeSelectionId, setToStore, showArchivedData) => (dispatch(actionCreators.getTableData(selectedNavtab, currentScopeSelectionId, setToStore, showArchivedData))),
        deleteRow: (selectedNavTab, currentScopeSelectionId, requestObject) => (dispatch(actionCreators.deleteRow(selectedNavTab, currentScopeSelectionId, requestObject))),
        updateAll: (selectedNavTab, currentScopeSelectionId, allRecords) => (dispatch(actionCreators.updateAll(selectedNavTab, currentScopeSelectionId, allRecords))),
        restoreEntity: (entityName, rowsToBeUnarchived, selectedNavtab, currentScopeSelectionId, setToStore, showArchivedData) => (dispatch(actionCreators.restoreEntity(entityName, rowsToBeUnarchived, selectedNavtab, currentScopeSelectionId, setToStore, showArchivedData)))
    }
}

export default withTranslation()(connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(UsersTable)));
