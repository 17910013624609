import React, { Component } from 'react'
import { Progress } from 'reactstrap';
import { connect } from 'react-redux';
import { withTranslation } from "react-i18next";


class ProductivityChart extends Component {
    constructor(props){
        super(props);
        this.state = {
            data: 0,
            series: [{
              name: this.props.t('Index'),
              data: [
                {
                  name: this.props.t("Availability"),
                  y: 0,
                  color: '#39b54a',
                  id: "Availability"
                },
                {
                  name: this.props.t("Productivity"),
                  y: 0,
                  color: '#2ab6ea',
                  id: "Productivity"
                },
                {
                  name: this.props.t("Quality"),
                  y: 0,
                  color: '#f4be53',
                  id: "Quality"
                },
                {
                  name: this.props.t("Data"),
                  y: 0,
                  color: '#ef6761',
                  id: "Data"
                }
              ]
            }]
          }     
    } 
        
    componentDidUpdate (prevProps) {
      if(prevProps.productivityIndexData.productivityIndexData !== this.props.productivityIndexData.productivityIndexData){
        let series = [{
          name: this.props.t('Index'),
          data: [
            {
              name: this.props.t("Availability"),
              y: this.props.productivityIndexData.productivityIndexData.availability,
              color: '#39b54a',
              id: "Availability"
            },
            {
              name: this.props.t("Productivity"),
              y: this.props.productivityIndexData.productivityIndexData.productivity,
              color: '#2ab6ea',
              id: "Productivity"
            },
            {
              name: this.props.t("Quality"),
              y: this.props.productivityIndexData.productivityIndexData.quality,
              color: '#f4be53',
              id: "Quality"
            },
            {
              name: this.props.t("Data"),
              y: this.props.productivityIndexData.productivityIndexData.data,
              color: '#ef6761',
              id: "Data"
            }
          ]
        }];
        this.setState({
          series : [...series]
        })
        let canvas = document.getElementById("canvas");
        let ctx = canvas.getContext("2d");
        var total = 100;
            var availability = parseFloat(this.props.productivityIndexData.productivityIndexData.availability);
            var availabilityArc = availability * (2 * Math.PI) / 100 - 0.5 * Math.PI;
            var productivity = parseFloat(this.props.productivityIndexData.productivityIndexData.availability) * parseFloat(this.props.productivityIndexData.productivityIndexData.productivity) / 100;
            var productivityArc = productivity * (2 * Math.PI) / 100 - 0.5 * Math.PI;
            var quality = parseFloat(this.props.productivityIndexData.productivityIndexData.availability) * parseFloat(this.props.productivityIndexData.productivityIndexData.productivity) * parseFloat(this.props.productivityIndexData.productivityIndexData.quality) / 100 / 100;
            var qualityArc = quality * (2 * Math.PI) / 100 - 0.5 * Math.PI;


            ctx.beginPath();
            ctx.arc(90, 90, 75, 0, 2 * Math.PI);
            ctx.lineWidth = 30;
            ctx.strokeStyle = '#cccccc';
            ctx.stroke();

            ctx.beginPath();
            ctx.arc(90, 90, 75, 0, 2 * Math.PI);
            ctx.lineWidth = 28;
            ctx.strokeStyle = '#fcfcfc';
            ctx.stroke();

            ctx.beginPath();
            ctx.arc(90, 90, 75, -0.5 * Math.PI, availabilityArc);
            ctx.lineWidth = 22;
            ctx.strokeStyle = '#39b54a';
            ctx.stroke();

            ctx.beginPath();
            ctx.arc(90, 90, 75, -0.5 * Math.PI, productivityArc);
            ctx.lineWidth = 16;
            ctx.strokeStyle = '#2ab6ea';
            ctx.stroke();

            ctx.beginPath();
            ctx.arc(90, 90, 75, -0.5 * Math.PI, qualityArc);
            ctx.lineWidth = 10;
            ctx.strokeStyle = '#f4be53';
            ctx.stroke();
      }
    }
    render() {
        const { series } = this.state;
        const {t}=this.props;
        let progressItem = series[0].data;
        return ( 
            <div className="productivity-chart-wrp">
                <div className="graph-area">
                  <div className="graph">
                    <canvas id="canvas" width="200" height="200" >
                    </canvas></div>
                  <div className="text">
                    <div className="text-area">
                      <h6 ><span className="value">{this.props.productivityIndexData.hasOwnProperty('productivityIndexData') ? this.props.productivityIndexData.productivityIndexData.oee.toFixed(1) : 0.00}</span><span className="symbol">%</span></h6>
                      <p id="plant_oee_label" style={{fontSize : '16px'}}>{t("Plant OEE")}</p>
                    </div>
                  </div>
                </div>
                <div>
                {progressItem.map((item, index) => (
                    <div className="progress-wrp" key={item.name} >
                    <h3 className="display-text">{item.name}</h3>
                    <span className="float-right">{Math.round(item.y)} %</span>
                    <Progress value={Math.round(item.y)} style={{ backgroundColor: 'white' , border: ' 2px solid '+item.color }} color={item.id} />
                    </div>
                ))}
                </div>
            </div>
        )
    }
}

const mapStateToProps = state => {
  return {
    productivityIndexData : state.productivityIndexDataReducer,
  }
}

export default withTranslation()(connect(mapStateToProps, null)(ProductivityChart));