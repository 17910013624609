import React, { Component } from 'react';
import * as actionCreators from '../../actions/actionCreators';
import { connect } from 'react-redux';
import { KeyboardBackspace } from '@material-ui/icons';
import { Scrollbars } from 'react-custom-scrollbars';
import {
    Card, CardContent, InputLabel, TextField, Select, withStyles, OutlinedInput, Button, Grid, Typography
} from '@material-ui/core';
import { withTranslation } from "react-i18next";


const styles = theme => ({
    cardContent: {
        height: '91%',
        "overflow-y": "auto"
    },
    cardContentRoot: {
        '&:last-child': {
            paddingBottom: 16
        }
    },
    card: {
        width: '100%',
        borderRadius: 0,
        height: '80vh'
    },
    textField: {
        width: '60%',
        marginRight: 20
    },
    textArea: {
        width: '80%',
        marginRight: 20
    },
    outlinedInput: {
        padding: 14
    },
    uploadButton: {
        boxShadow: "none",
        backgroundColor: "#ffffff",
        color: "#2195b9",
        minWidth: '100px'
    },
    saveButton: {
        fontSize: "14px",
        fontWeight: "500",
        textAlign: "center",
        color: "#ffffff",
        backgroundColor: "#42D784",
        '&:hover': {
            backgroundColor: '#42d784'
        },
        '&:disabled': {
            backgroundColor: '#f6f6f6'
        },
        borderRadius: "3px",
        boxShadow: "0 0 1px 0 rgba(0, 0, 0, 0.05)",
        width: "110px",
        height: "36px",
        marginRight: "24px"
    },
    inputFieldDiv: {
        display: "flex",
        justifyContent: "space-between",
        width: '50%',
        float: 'left',
        paddingTop: '1%',
        height: 80,
        'align-items': 'baseline'
    },
    textareaInputFieldDiv: {
        display: "flex",
        justifyContent: "space-between",
        width: '100%',
        float: 'left',
        paddingTop: '1%',
        height: 180,
        'align-items': 'baseline'
    },
    textFieldLabel: {
        color: 'rgba(0, 0, 0, 0.4000000059604645)',
        'letter-spacing': 0.5,
        'font-weight': 500,
        fontSize: "14px",
        lineHeight: 2.5,
        width: '30%',
        marginLeft: 20,
        //text-align': 'right'
    },
    textareaFieldLabel: {
        color: 'rgba(0, 0, 0, 0.4000000059604645)',
        'letter-spacing': 0.5,
        'font-weight': 500,
        fontSize: "14px",
        lineHeight: 2.5,
        width: '15%',
        marginLeft: 20,
        //'text-align': 'right'
    },
    requiredClass: {
        color: 'red'
    },
    actionButtonContainer: {
        display: 'flex',
        'justify-content': 'space-between',
        height: 50,
        'border-bottom': '1px solid rgba(207, 216, 220, 0.5)'
    },
    backButtonContainer: {
        display: 'flex',
        'align-items': 'center',
        paddingLeft: 21
    },
    arrowBack: {
        cursor: 'pointer'
    },
    entityTitle: {
        color: 'rgba(0, 0, 0, 0.8)',
        'font-family': 'Roboto',
        'font-size': 21,
        'font-weight': '400',
        'letter-spacing': 0.5,
        marginLeft: 10
    }
})
class LossCodesEntity extends Component {

    state = {
        id: "",
        plantId: "",
        lossLists: "",
        specs: "",
        description: "",
        lossCode: "",
        lossLabel: "",
        lossSubcode: "",
        lossSublabel: "",
        subDescription: "",
        disableSaveButton: true,
        labelWidth: 0,
        specsError: false,
        lossListError: false,
        descriptionError: false,
        lossCodeError: false,
        lossLabelError: false,
        lossSubcodeError: false,
        lossSublabelError: false,
        subDescriptionError: false
    }

    // Handle changes to any user input field
    inputChangeHandler = (event, field) => {

        switch (field) {
            case "lossLists": this.setState({ lossLists: event.target.value, disableSaveButton: false });
                this.checkValidation("lossLists", event.target.value);
                break;
            case "specs": this.setState({ specs: event.target.value, disableSaveButton: false });
                this.checkValidation("specs", event.target.value);
                break;
            case "description": this.setState({ description: event.target.value, disableSaveButton: false });
                this.checkValidation("description", event.target.value);
                break;
            case "lossCode": this.setState({ lossCode: event.target.value, disableSaveButton: false });
                this.checkValidation("lossCode", event.target.value);
                break;
            case "lossLabel": this.setState({ lossLabel: event.target.value, disableSaveButton: false });
                this.checkValidation("lossLabel", event.target.value);
                break;
            case "lossSubcode": this.setState({ lossSubcode: event.target.value, disableSaveButton: false });
                this.checkValidation("lossSubcode", event.target.value);
                break;
            case "lossSublabel": this.setState({ lossSublabel: event.target.value, disableSaveButton: false });
                this.checkValidation("lossSublabel", event.target.value);
                break;
            case "subDescription": this.setState({ subDescription: event.target.value, disableSaveButton: false });
                this.checkValidation("subDescription", event.target.value); break;
            default: console.log("default case");
        }
    }

    // Handle routing
    goToBackPage = () => {
        this.props.history.goBack();
    }

    // update losses entity
    updateLossesData = () => {
        const { lossLists, id, description, lossCode, specs, lossLabel, lossSubcode, lossSublabel, subDescription } = this.state
        if (id && id != null && id !== "") {
            this.props.updateLossesEntity({ lossLists, id, description, lossCode, specs, lossLabel, lossSubcode, lossSublabel, subDescription }, id);
        }
        else {
            this.props.updateLossesEntity({ lossLists, description, lossCode, specs, lossLabel, lossSubcode, lossSublabel, subDescription }, id);
        }
    }

    // create loss-list dropdown list
    getLossListName = () => {
        let lossList = [];
        lossList.push(<option key={""} disabled={true} value={""}>{"Select"}</option>);
        if ((this.state.validationData.lossListsName.validations && !this.state.validationData.lossListsName.validations.required) || !(this.state.validationData.lossListsName.validations)) {
            lossList.push(<option key={""} value={""}>{"None"}</option>);
        }
        if (this.props.lossListData && this.props.lossListData.tableData && this.props.lossListData.tableData.length > 0) {
            this.props.lossListData.tableData.map((data) => {
                lossList.push(
                    <option key={data.id} value={data.id}>{data.name}</option>
                );
            })
        }
        return lossList;
    }

    componentDidMount() {
        if (this.props.data) {
            let entityNames = Object.keys(this.props.data)
            entityNames.map((data) => {
                if (this.props.data[data] == null) {
                    this.props.data[data] = ""
                }
            })
            this.setState({
                id: this.props.data.id,
                description: this.props.data.description,
                lossCode: this.props.data.lossCode,
                lossLabel: this.props.data.lossLabel,
                lossSubcode: this.props.data.lossSubcode,
                lossSublabel: this.props.data.lossSublabel,
                subDescription: this.props.data.subDescription,
            })
            if (this.props.data.lossLists && this.props.data.lossLists.id != "") {
                this.setState({ lossLists: this.props.data.lossLists.id })
            }

            if (this.props.data.specs && this.props.data.specs != "") {
                if (this.props.data.specs.props) {
                    this.setState({ specs: this.props.data.specs.props.children })
                }
                else {
                    this.setState({ specs: this.props.data.specs })
                }
            }
        }
        if (this.props.selectedScopeData || this.props.selectedScopeData == "") {
            this.setState({ plantId: this.props.selectedScopeData })
        }

        if (this.props.validationData && this.props.validationData.config && this.props.validationData.config.Losses) {
            this.setState({ validationData: this.props.validationData.config.Losses });
        }
    }

    componentDidUpdate(prevProps, prevState) {
        if (this.state.plantId && this.state.plantId != "" && prevState.plantId != this.state.plantId) {
            this.props.getTableData("LossListTable", this.state.plantId, true);
        }
    }

    // disable save button based on validations
    disableSaveButton = () => {
        if (this.state.lossLists === "" && (this.state.validationData.lossListsName.validations && this.state.validationData.lossListsName.validations.required)) {
            return true;
        }
        else if (this.state.description === "" && (this.state.validationData.description.validations && this.state.validationData.description.validations.required)) {
            return true;
        }
        else if (this.state.lossCode === "" && (this.state.validationData.lossCode.validations && this.state.validationData.lossCode.validations.required)) {
            return true;
        }
        else if (this.state.lossLabel === "" && (this.state.validationData.lossLabel.validations && this.state.validationData.lossLabel.validations.required)) {
            return true;
        }
        else if (this.state.lossSubcode === "" && (this.state.validationData.lossSubcode.validations && this.state.validationData.lossSubcode.validations.required)) {
            return true;
        }
        else if (this.state.lossSublabel === "" && (this.state.validationData.lossSublabel.validations && this.state.validationData.lossSublabel.validations.required)) {
            return true;
        }
        else if (this.state.subDescription === "" && (this.state.validationData.subDescription.validations && this.state.validationData.subDescription.validations.required)) {
            return true;
        }
        else if (this.state.specs === "" && (this.state.validationData.specs.validations && this.state.validationData.specs.validations.required)) {
            return true;
        }
        else if (this.state.specsError || this.state.lossListError || this.state.descriptionError || this.state.lossCodeError || this.state.lossLabelError || this.state.lossSubcodeError || this.state.lossSublabelError || this.state.subDescriptionError) {
            return true;
        }
        else {
            return false;
        }
    }

    // check validations of the user input fields
    checkValidation = (field, value) => {
        switch (field) {
            case "lossLists":
                if (!this.state.lossListError && value === "" && (this.state.validationData.lossListsName.validations && this.state.validationData.lossListsName.validations.required)) {
                    this.setState({ lossListError: true });
                } else if (this.state.lossListError) {
                    if ((value === "" && (this.state.validationData.lossListsName.validations && this.state.validationData.lossListsName.validations.required))) {
                        //skip
                    }
                    else {
                        this.setState({ lossListError: false });
                    }
                };
                break;
            case "description":
                if (!this.state.descriptionError && value === "" && (this.state.validationData.description.validations && this.state.validationData.description.validations.required)) {
                    this.setState({ descriptionError: true });
                } else if (!this.state.descriptionError && (this.state.validationData.description.validations) && (value.length > this.state.validationData.description.validations.maxLength)) {
                    this.setState({ descriptionError: true });
                } else if (this.state.descriptionError) {
                    if ((value === "" && (this.state.validationData.description.validations && this.state.validationData.description.validations.required) || ((this.state.validationData.description.validations) && (value.length > this.state.validationData.description.validations.maxLength)))) {
                        //skip
                    }
                    else {
                        this.setState({ descriptionError: false });
                    }
                };
                break;
            case "subDescription":
                if (!this.state.subDescriptionError && value === "" && (this.state.validationData.subDescription.validations && this.state.validationData.subDescription.validations.required)) {
                    this.setState({ subDescriptionError: true });
                } else if (!this.state.subDescriptionError && (this.state.validationData.subDescription.validations) && (value.length > this.state.validationData.subDescription.validations.maxLength)) {
                    this.setState({ subDescriptionError: true });
                } else if (this.state.subDescriptionError) {
                    if ((value === "" && (this.state.validationData.subDescription.validations && this.state.validationData.subDescription.validations.required) || ((this.state.validationData.subDescription.validations) && (value.length > this.state.validationData.subDescription.validations.maxLength)))) {
                        //skip
                    }
                    else {
                        this.setState({ subDescriptionError: false });
                    }
                };
                break;
            case "lossCode":
                if (!this.state.lossCodeError && value === "" && (this.state.validationData.lossCode.validations && this.state.validationData.lossCode.validations.required)) {
                    this.setState({ lossCodeError: true });
                } else if (!this.state.lossCodeError && (this.state.validationData.lossCode.validations) && (value.length > this.state.validationData.lossCode.validations.maxLength)) {
                    this.setState({ lossCodeError: true });
                } else if (this.state.lossCodeError) {
                    if ((value === "" && (this.state.validationData.lossCode.validations && this.state.validationData.lossCode.validations.required) || ((this.state.validationData.lossCode.validations) && (value.length > this.state.validationData.lossCode.validations.maxLength)))) {
                        //skip
                    }
                    else {
                        this.setState({ lossCodeError: false });
                    }
                };
                break;
            case "lossLabel":
                if (!this.state.lossLabelError && value === "" && (this.state.validationData.lossLabel.validations && this.state.validationData.lossLabel.validations.required)) {
                    this.setState({ lossLabelError: true });
                } else if (!this.state.lossLabelError && (this.state.validationData.lossLabel.validations) && (value.length > this.state.validationData.lossLabel.validations.maxLength)) {
                    this.setState({ lossLabelError: true });
                } else if (this.state.lossLabelError) {
                    if ((value === "" && (this.state.validationData.lossLabel.validations && this.state.validationData.lossLabel.validations.required) || ((this.state.validationData.lossLabel.validations) && (value.length > this.state.validationData.lossLabel.validations.maxLength)))) {
                        //skip
                    }
                    else {
                        this.setState({ lossLabelError: false });
                    }
                };
                break;
            case "lossSubcode":
                if (!this.state.lossSubcodeError && value === "" && (this.state.validationData.lossSubcode.validations && this.state.validationData.lossSubcode.validations.required)) {
                    this.setState({ lossSubcodeError: true });
                } else if (!this.state.lossSubcodeError && (this.state.validationData.lossSubcode.validations) && (value.length > this.state.validationData.lossSubcode.validations.maxLength)) {
                    this.setState({ lossSubcodeError: true });
                } else if (this.state.lossSubcodeError) {
                    if ((value === "" && (this.state.validationData.lossSubcode.validations && this.state.validationData.lossSubcode.validations.required) || ((this.state.validationData.lossSubcode.validations) && (value.length > this.state.validationData.lossSubcode.validations.maxLength)))) {
                        //skip
                    }
                    else {
                        this.setState({ lossSubcodeError: false });
                    }
                };
                break;
            case "lossSublabel":
                if (!this.state.lossSublabelError && value === "" && (this.state.validationData.lossSublabel.validations && this.state.validationData.lossSublabel.validations.required)) {
                    this.setState({ lossSublabelError: true });
                } else if (!this.state.lossSublabelError && (this.state.validationData.lossSublabel.validations) && (value.length > this.state.validationData.lossSublabel.validations.maxLength)) {
                    this.setState({ lossSublabelError: true });
                } else if (this.state.lossSublabelError) {
                    if ((value === "" && (this.state.validationData.lossSublabel.validations && this.state.validationData.lossSublabel.validations.required) || ((this.state.validationData.lossSublabel.validations) && (value.length > this.state.validationData.lossSublabel.validations.maxLength)))) {
                        //skip
                    }
                    else {
                        this.setState({ lossSublabelError: false });
                    }
                };
                break;
            case "specs":
                if (!this.state.specsError && value === "" && (this.state.validationData.specs.validations && this.state.validationData.specs.validations.required)) {
                    this.setState({ specsError: true });
                } else if (!this.state.specsError && (this.state.validationData.specs.validations) && (value.length > this.state.validationData.specs.validations.maxLength)) {
                    this.setState({ specsError: true });
                } else if (this.state.specsError) {
                    if ((value === "" && (this.state.validationData.specs.validations && this.state.validationData.specs.validations.required) || ((this.state.validationData.specs.validations) && (value.length > this.state.validationData.specs.validations.maxLength)))) {
                        //skip
                    }
                    else {
                        this.setState({ specsError: false });
                    }
                };
                break;
        }
    }

    // set card header
    getCardHeader = () => {
        if (this.props.data && this.props.data.lossLabel && this.props.data.lossLabel != "") {
            return this.props.data.lossLabel;
        }
        else {
            return "Create New"
        }
    }

    render() {
        return (
            <Card className={this.props.classes.card} >
                <div className={this.props.classes.actionButtonContainer}>
                    <div className={this.props.classes.backButtonContainer}>
                        <KeyboardBackspace onClick={this.goToBackPage} classes={{ root: this.props.classes.arrowBack }} />
                        <div className={this.props.classes.entityTitle}>{this.getCardHeader()}</div>
                    </div>
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                        <Button variant="contained"
                            className={this.props.classes.saveButton}
                            disabled={this.state.disableSaveButton || this.disableSaveButton()}
                            onClick={this.updateLossesData}>{this.props.t("Save")}</Button>
                    </div>
                </div>
                <CardContent className={this.props.classes.cardContent} classes={{ root: this.props.classes.cardContentRoot }}>
                    {this.state.validationData && this.state.validationData.lossLabel && this.state.validationData.lossLabel.page_visible ? (
                        <div className={this.props.classes.inputFieldDiv}>
                            <InputLabel className={this.props.classes.textFieldLabel}>
                                {this.state.validationData.lossLabel.display_name}
                                {(this.state.validationData.lossLabel.validations && this.state.validationData.lossLabel.validations.required) ? (<span className={this.props.classes.requiredClass}>*</span>) : null}
                            </InputLabel>
                            <TextField
                                id="lossLabel"
                                name="lossLabel"
                                variant="outlined"
                                helperText={(this.state.validationData.lossLabel.validations && this.state.validationData.lossLabel.validations.helpText) ? (this.state.validationData.lossLabel.validations.helpText) : null}
                                className={this.props.classes.textField}
                                onChange={(event) => this.inputChangeHandler(event, "lossLabel")}
                                value={this.state.lossLabel}
                                error={this.state.lossLabelError}
                                InputProps={{
                                    classes: {
                                        input: this.props.classes.outlinedInput
                                    }
                                }}
                            />
                        </div>
                    )
                        : null
                    }
                    {this.state.validationData && this.state.validationData.lossSublabel && this.state.validationData.lossSublabel.page_visible ? (
                        <div className={this.props.classes.inputFieldDiv}>
                            <InputLabel className={this.props.classes.textFieldLabel}>
                                {this.state.validationData.lossSublabel.display_name}
                                {(this.state.validationData.lossSublabel.validations && this.state.validationData.lossSublabel.validations.required) ? (<span className={this.props.classes.requiredClass}>*</span>) : null}
                            </InputLabel>
                            <TextField
                                id="lossSublabel"
                                name="lossSublabel"
                                variant="outlined"
                                helperText={(this.state.validationData.lossSublabel.validations && this.state.validationData.lossSublabel.validations.helpText) ? (this.state.validationData.lossSublabel.validations.helpText) : null}
                                className={this.props.classes.textField}
                                onChange={(event) => this.inputChangeHandler(event, "lossSublabel")}
                                value={this.state.lossSublabel}
                                error={this.state.lossSublabelError}
                                InputProps={{
                                    classes: {
                                        input: this.props.classes.outlinedInput
                                    }
                                }}
                            />
                        </div>
                    )
                        : null
                    }
                    {this.state.validationData && this.state.validationData.lossCode && this.state.validationData.lossCode.page_visible ? (
                        <div className={this.props.classes.inputFieldDiv}>
                            <InputLabel className={this.props.classes.textFieldLabel}>
                                {this.state.validationData.lossCode.display_name}
                                {(this.state.validationData.lossCode.validations && this.state.validationData.lossCode.validations.required) ? (<span className={this.props.classes.requiredClass}>*</span>) : null}
                            </InputLabel>
                            <TextField
                                id="lossCode"
                                name="lossCode"
                                variant="outlined"
                                className={this.props.classes.textField}
                                helperText={(this.state.validationData.lossCode.validations && this.state.validationData.lossCode.validations.helpText) ? (this.state.validationData.lossCode.validations.helpText) : null}
                                onChange={(event) => this.inputChangeHandler(event, "lossCode")}
                                value={this.state.lossCode}
                                error={this.state.lossCodeError}
                                InputProps={{
                                    classes: {
                                        input: this.props.classes.outlinedInput
                                    }
                                }}
                            />
                        </div>
                    )
                        : null
                    }
                    {this.state.validationData && this.state.validationData.lossSubcode && this.state.validationData.lossSubcode.page_visible ? (
                        <div className={this.props.classes.inputFieldDiv}>
                            <InputLabel className={this.props.classes.textFieldLabel}>
                                {this.state.validationData.lossSubcode.display_name}
                                {(this.state.validationData.lossSubcode.validations && this.state.validationData.lossSubcode.validations.required) ? (<span className={this.props.classes.requiredClass}>*</span>) : null}
                            </InputLabel>
                            <TextField
                                id="lossSubcode"
                                name="lossSubcode"
                                variant="outlined"
                                className={this.props.classes.textField}
                                helperText={(this.state.validationData.lossSubcode.validations && this.state.validationData.lossSubcode.validations.helpText) ? (this.state.validationData.lossSubcode.validations.helpText) : null}
                                onChange={(event) => this.inputChangeHandler(event, "lossSubcode")}
                                value={this.state.lossSubcode}
                                error={this.state.lossSubcodeError}
                                InputProps={{
                                    classes: {
                                        input: this.props.classes.outlinedInput
                                    }
                                }}
                            />
                        </div>
                    )
                        : null
                    }
                    {this.state.validationData && this.state.validationData.lossListsName && this.state.validationData.lossListsName.page_visible ? (
                        <div className={this.props.classes.inputFieldDiv}>
                            <InputLabel className={this.props.classes.textFieldLabel}>
                                {this.state.validationData.lossListsName.display_name}
                                {(this.state.validationData.lossListsName.validations && this.state.validationData.lossListsName.validations.required) ? (<span className={this.props.classes.requiredClass}>*</span>) : null}
                            </InputLabel>
                            <Select
                                native
                                value={this.state.lossLists}
                                onChange={(event) => this.inputChangeHandler(event, "lossLists")}
                                input={
                                    <OutlinedInput
                                        variant="outlined"
                                        name="lossLists"
                                        id="language-native-simple"
                                        labelWidth={this.state.labelWidth}
                                        className={this.props.classes.textField}
                                        error={this.state.lossListError}
                                        classes={{ input: this.props.classes.outlinedInput }}
                                    />
                                }
                            >
                                {this.getLossListName()}
                            </Select>
                        </div>
                    )
                        : null
                    }
                    {this.state.validationData && this.state.validationData.description && this.state.validationData.description.page_visible ? (
                        <div className={this.props.classes.textareaInputFieldDiv}>
                            <InputLabel className={this.props.classes.textareaFieldLabel}>
                                {this.state.validationData.description.display_name}
                                {(this.state.validationData.description.validations && this.state.validationData.description.validations.required) ? (<span className={this.props.classes.requiredClass}>*</span>) : null}
                            </InputLabel>
                            <TextField
                                id="description"
                                multiline
                                rows="6"
                                value={this.state.description}
                                helperText={(this.state.validationData.description.validations && this.state.validationData.description.validations.helpText) ? (this.state.validationData.description.validations.helpText) : null}
                                variant="outlined"
                                onChange={(event) => this.inputChangeHandler(event, "description")}
                                className={this.props.classes.textArea}
                                error={this.state.descriptionError}
                                InputProps={{
                                    classes: {
                                        multiline: this.props.classes.outlinedInput
                                    }
                                }}
                            />
                        </div>
                    )
                        : null
                    }
                    {this.state.validationData && this.state.validationData.subDescription && this.state.validationData.subDescription.page_visible ? (
                        <div className={this.props.classes.textareaInputFieldDiv}>
                            <InputLabel className={this.props.classes.textareaFieldLabel}>
                                {this.state.validationData.subDescription.display_name}
                                {(this.state.validationData.subDescription.validations && this.state.validationData.subDescription.validations.required) ? (<span className={this.props.classes.requiredClass}>*</span>) : null}
                            </InputLabel>
                            <TextField
                                id="subDescription"
                                multiline
                                rows="6"
                                value={this.state.subDescription}
                                helperText={(this.state.validationData.subDescription.validations && this.state.validationData.subDescription.validations.helpText) ? (this.state.validationData.subDescription.validations.helpText) : null}
                                variant="outlined"
                                onChange={(event) => this.inputChangeHandler(event, "subDescription")}
                                className={this.props.classes.textArea}
                                error={this.state.subDescriptionError}
                                InputProps={{
                                    classes: {
                                        multiline: this.props.classes.outlinedInput
                                    }
                                }}
                            />
                        </div>
                    )
                        : null
                    }
                    {this.state.validationData && this.state.validationData.specs && this.state.validationData.specs.page_visible ? (
                        <div className={this.props.classes.textareaInputFieldDiv}>
                            <InputLabel className={this.props.classes.textareaFieldLabel}>
                                {this.state.validationData.specs.display_name}
                                {(this.state.validationData.specs.validations && this.state.validationData.specs.validations.required) ? (<span className={this.props.classes.requiredClass}>*</span>) : null}
                            </InputLabel><br />
                            <TextField
                                id="specification"
                                multiline
                                rows="6"
                                value={this.state.specs}
                                helperText={(this.state.validationData.specs.validations && this.state.validationData.specs.validations.helpText) ? (this.state.validationData.specs.validations.helpText) : null}
                                variant="outlined"
                                onChange={(event) => this.inputChangeHandler(event, "specs")}
                                className={this.props.classes.textArea}
                                error={this.state.specsError}
                                InputProps={{
                                    classes: {
                                        multiline: this.props.classes.outlinedInput
                                    }
                                }}
                            />
                        </div>
                    )
                        : null
                    }
                </CardContent>
            </Card >
        );
    }
}

const mapStateToProps = (state) => {
    return {
        lossListData: state.lossListDataReducer,
        validationData: state.viewDefinitionReducer
    }
}

const mapDispatchToProps = dispatch => {
    return ({
        getTableData: (selectedNavtab, currentScopeSelectionId, setToStore) => (dispatch(actionCreators.getTableData(selectedNavtab, currentScopeSelectionId, setToStore))),
        updateLossesEntity: (lossesEntityData, id) => (dispatch(actionCreators.updateLossesEntity(lossesEntityData, id))),
        updateSelectedTab: (selectedTab) => (dispatch(actionCreators.updateSelectedTab(selectedTab)))
    })
}

export default withTranslation()(connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(LossCodesEntity)));

