import React, { Fragment } from 'react';
import { withStyles, Popover, List, ListItem, ListItemText, Tooltip } from '@material-ui/core';
import { CloudDownload } from '@material-ui/icons';
import { connect } from 'react-redux';
import { withTranslation } from "react-i18next";

import * as actionCreators from '../actions/actionCreators';

const styles = theme => ({
    paper: {
        minWidth: 150
    }
})
//handles the csv download functionality
const CsvDownloadComponent = (props) => {
    const {t}=props;

    const downloadCsvFile = () => {
        props.downloadCsvFile(props.url, props.parentOrganizationId, props.selectedPlant);
    }

    return (
        <Tooltip title={<span style={{ fontSize: "12px" }}>{t("Download")}</span>}>
            <CloudDownload onClick={downloadCsvFile} />
        </Tooltip>
    );
}

const mapDispatchToProps = dispatch => {
    return {
        downloadCsvFile: (url, parentOrganizationId, selectedPlant) => (dispatch(actionCreators.downloadCsvFile(url, parentOrganizationId, selectedPlant)))
    }
}

export default withTranslation()(connect(null, mapDispatchToProps)(withStyles(styles)(CsvDownloadComponent)));