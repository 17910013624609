import React, { Component, Fragment } from 'react';
import * as actionCreators from '../../actions/actionCreators';
import { connect } from 'react-redux';
import WarningIcon from '@material-ui/icons/Warning';
import { KeyboardBackspace } from '@material-ui/icons';
import {
    Card,
    CardContent,
    InputLabel,
    TextField,
    Divider,
    Select,
    withStyles,
    OutlinedInput,
    Button,
    Paper,
    Tabs,
    Tab,
    Dialog,
    DialogContent,
    DialogActions,
    DialogTitle
} from '@material-ui/core';
import moment from 'moment'
import MUIDataTable from "mui-datatables";
import { createMuiTheme, MuiThemeProvider } from '@material-ui/core/styles';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Typography from '@material-ui/core/Typography';
import { withTranslation } from "react-i18next";

import MonitorSpec from "./monitorSpec";
import { dateFormat } from 'highcharts';

import { config } from '../../configuration';

let server_url = config.url.server_url;
let currentLanguage = localStorage.getItem("lang");
if (currentLanguage === "null" || currentLanguage === null) {
    currentLanguage = "en"
}

const styles = theme => ({
    cardContent: {
        height: '91%',
        "overflow-y": "auto"
    },
    cardContentRoot: {
        '&:last-child': {
            paddingBottom: 16
        }
    },
    card: {
        width: '100%',
        borderRadius: 0,
        height: '80vh'
    },
    textField: {
        width: '60%',
        marginRight: 20
    },
    textArea: {
        width: '80%',
        marginRight: 20
    },
    yamlTextArea: {
        width: '100%',
        marginTop: '1%',
        backgroundColor: '#f2f3f8'
    },
    outlinedInput: {
        padding: 14
    },
    closeButton: {
        position: 'absolute',
        right: "10px",
        top: "10px",
        color: theme.palette.grey[500],
    },
    saveButton: {
        fontSize: "14px",
        fontWeight: "500",
        textAlign: "center",
        color: "#ffffff",
        backgroundColor: "#42D784",
        '&:hover': {
            backgroundColor: '#42d784'
        },
        '&:disabled': {
            backgroundColor: '#f6f6f6'
        },
        borderRadius: "3px",
        boxShadow: "0 0 1px 0 rgba(0, 0, 0, 0.05)",
        width: "110px",
        height: "36px",
        marginRight: "24px"
    },
    inputFieldDiv: {
        display: "flex",
        justifyContent: "space-between",
        width: '50%',
        float: 'left',
        paddingTop: '1%',
        height: 80,
        'align-items': 'baseline'
    },
    textareaInputFieldDiv: {
        display: "flex",
        justifyContent: "space-between",
        width: '100%',
        float: 'left',
        paddingTop: '1%',
        height: 180,
        'align-items': 'baseline'
    },
    textFieldLabel: {
        color: 'rgba(0, 0, 0, 0.4000000059604645)',
        'letter-spacing': 0.5,
        'font-weight': 500,
        fontSize: "14px",
        lineHeight: 2.5,
        width: '30%',
        marginLeft: 20,
        //text-align': 'right'
    },
    textareaFieldLabel: {
        color: 'rgba(0, 0, 0, 0.4000000059604645)',
        'letter-spacing': 0.5,
        'font-weight': 500,
        fontSize: "14px",
        lineHeight: 2.5,
        width: '15%',
        marginLeft: 20,
        //'text-align': 'right'
    },
    requiredClass: {
        color: 'red'
    },
    actionButtonContainer: {
        display: 'flex',
        'justify-content': 'space-between',
        height: 50,
        'border-bottom': '1px solid rgba(207, 216, 220, 0.5)'
    },
    backButtonContainer: {
        display: 'flex',
        'align-items': 'center',
        paddingLeft: 21
    },
    arrowBack: {
        cursor: 'pointer'
    },
    entityTitle: {
        color: 'rgba(0, 0, 0, 0.8)',
        'font-family': 'Roboto',
        'font-size': 21,
        'font-weight': '400',
        'letter-spacing': 0.5,
        marginLeft: 10
    },
    paper: {
        borderRadius: 0,
        marginBottom: 1,
        boxShadow: '0 0 0 0',
        backgroundColor: '#f2f3f8',
        height: '10vh',
        clear: "both"
    },
    enabledTabs: {
        color: 'rgba(24, 32, 44, 0.8700000047683716)',
        letterSpacing: 0.1
    },
    selectedTab: {
        color: '#5ACBE5 !important'
    },
    tabIndicator: {
        backgroundColor: '#5ACBE5'
    },
    thresholdButton: {
        '&:disabled': {
            backgroundColor: '#f6f6f6',
            fontColor: "black"
        },
        '&:hover': {
            backgroundColor: '#42d784'
        },
        marginTop: "12px",
        height: "30px",
        backgroundColor: "#029e02f2",
        fontSize: "10px",
        color: "white"
    },
    computeButton: {
        marginTop: "12px",
        height: "30px",
        fontSize: "10px",
        color: "#1565c0",
        '&:disabled': {
            backgroundColor: '#f6f6f6'
        },
    },
    periodDiv: {
        color: "black",
        marginBottom: "15px !important",
        fontSize: "15px",
        padding: "0"
    },
    InputValidation: {
        position: "relative",
        top: "60px",
        left: "-31.5%",
        color: "red",
    }
})
const bearingFeatures = ["BSF", "BPFO", "FTF", "BPFI"];
const features = ["Total Acceleration", "X-axis Velocity", "Y-axis Velocity", "Z-axis Velocity", "Temperature", "Audio"];
const featureCodes = ["0001", "0002", "0003", "0004", "0005", "0006"];

class MonitorsEntity extends Component {

    state = {
        id: "",
        disableSaveButton: true,
        plantId: "",
        deviceIdentifier: "",
        machineId: "",
        monitorTypeId: "",
        label: "",
        specs: "",
        notes: "",
        orientationCode: "",
        orientationError: true,
        orientationErrorMessage: "",
        labelWidth: 0,
        labelError: false,
        specsError: false,
        notesError: false,
        monitorTypeError: false,
        machineError: false,
        deviceIdentifierError: false,
        selectedTab: 0,
        showErrorMessage: false,
        openDialog: false,
        tempMonitorId: "",
        monitorCodeName: "",
        tempMonitorCodeName: "",
        monitorType: '',
        tempMonitorType: '',
        errorModel: {},
        newAdvancedFeatures: 0,
        displayPosition: "",
        displayPositionError: false,
        columns: [],
        data: null,
        dspList: [],
        openThresholdsDialog: false,
        openThresholdNote: false,
        lastThresholdAssistantDatetime: "",
        lastThresholdAssistant: "",
        isComputeThreshold: false,
        openConfirmDialog: false,
        lang: currentLanguage

    }
    getMuiTheme = () => createMuiTheme({
        overrides: {
            MUIDataTableHeadCell: {
                fixedHeader: {
                    'z-index': 0
                },
                hintIconAlone: {
                    marginTop: '2px',
                    fontSize: '14px'
                }
            },
            MUIDataTableFilter: {
                root: {
                    'width': '500px !important'
                }
            },
            typography: {
                useNextVariants: true,
            },
            MUIDataTable: {
                responsiveScroll: {
                    height: '280px',
                    'max-height': '300px !important'
                }
            },
            MuiTableCell: {
                root: {
                    "padding-right": "38px !important"
                },
                head: {
                    fontSize: 14,
                    fontWeight: 800,
                    letterSpacing: 0.25
                },
                body: {
                    fontSize: 14,
                    letterSpacing: 0.25
                }
            }
        }
    })


    handleOk = () => {
        this.setState({
            openDialog: false,
            monitorTypeId: this.state.tempMonitorId,
            monitorCodeName: this.state.tempMonitorCodeName,
            monitorType: this.state.tempMonitorType,
            disableSaveButton: false
        }, () => {
            this.getMonitorTypeSpecs(this.state.monitorTypeId);
        })
    }
    handleCancel = () => {
        this.setState({
            openDialog: false
        })
    }
    handleThresholdsClose = () => {
        this.setState({
            openThresholdsDialog: false
        })
    }
    // handle widget/yaml view
    handleTabChange = (event, value) => {
        this.setState({
            selectedTab: value
        },
            () => {
                if (this.state.specs) {
                    this.getMonitorTypeSpecs();
                }
            })
    };

    validateNull = () => {
        let flag = true;
        if (typeof this.state.specs === "object") {
            let specs = this.state.specs;
            var errors = this.state.errorModel;
            if (specs.additional_attrs.dsp === "" || isNaN(specs.additional_attrs.dsp)) {
                errors["dsp"] = this.props.t("Please enter a valid value");
                flag = false;

            } else {
                if (errors["dsp"]) {
                    flag = false;
                } else {
                    var mindsp = 0;
                    if (this.props.metadata && this.props.metadata.metadata && this.props.metadata.metadata.dataSendPeriodDetails) {
                        const lor = specs.spectral_monitoring.lines_of_resolution;
                        const fmax = specs.spectral_monitoring.maximum_frequency;
                        if (fmax && fmax !== "" && fmax !== 0 && lor && lor !== 0 && lor !== "") {
                            var res = this.props.metadata.metadata.dataSendPeriodDetails.find(x => x.lor.toString() === lor.toString() && x.fmax.toString() === fmax.toString());
                            if (res) {
                                mindsp = res.dsp / 1000;
                            }
                        }
                        let lorInt = parseInt(lor)
                        if ((fmax === "2500" && (lorInt === 100 || lorInt === 200)) || (fmax === '5000' && (lorInt === 100 || lorInt === 200 || lorInt === 400)) || (fmax === '10000' && (lorInt === 100 || lorInt === 200 || lorInt === 400 || lorInt === 800))) {
                            errors['lor'] = this.props.t("Please select valid LOR value.")
                            flag = false;
                        }
                    }
                    if (specs.additional_attrs.dsp && specs.additional_attrs.dsp !== "" && (mindsp !== 2 && parseFloat(specs.additional_attrs.dsp) < mindsp)) {
                        errors['dsp'] = this.props.t("Please select valid DSP value.");
                        flag = false;

                    }
                }
            }
            if (specs.additional_attrs.rotating_speed_type === "FIXED") {
                if (specs.additional_attrs.rotating_speed === "" || isNaN(specs.additional_attrs.rotating_speed) || specs.additional_attrs.rotating_speed === 0) {
                    errors["rotating_speed"] = this.props.t("Enter actual RPM of the machine");
                    flag = false;

                } else if (errors["rotating_speed"]) {
                    flag = false;
                }
            } else {
                if (specs.additional_attrs.rotating_speed_range.from === "" || isNaN(specs.additional_attrs.rotating_speed_range.from)) {
                    errors["from"] = this.props.t("Enter actual RPM of the machine");
                    flag = false;

                } else if (errors["from"]) {
                    errors["from"] = this.props.t("Enter actual RPM of the machine");
                    flag = false;
                }
                if (specs.additional_attrs.rotating_speed_range.to === "" || isNaN(specs.additional_attrs.rotating_speed_range.to)) {
                    errors["to"] = this.props.t("Enter actual RPM of the machine");
                    flag = false;

                } else if (errors["to"]) {
                    errors["to"] = this.props.t("Enter actual RPM of the machine");
                    flag = false;
                }


                var maxSpeed = parseFloat(specs.additional_attrs.rotating_speed_range.to);
                var minSpeed = parseFloat(specs.additional_attrs.rotating_speed_range.from);
                var fMax = parseFloat(specs.spectral_monitoring.maximum_frequency);
                var lor = parseInt(specs.spectral_monitoring.lines_of_resolution);

                if (!(isNaN(maxSpeed) || isNaN(minSpeed))) {
                    var invalid = ((maxSpeed - minSpeed) / 60) < (fMax / lor)
                    if (invalid) {
                        flag = false;
                        errors["to"] = this.props.t("Speed Range lower than Resolution of Spectrum");
                    } else {
                        delete errors.to;
                        flag = true;
                    }
                }
            }
            var keys = Object.keys(specs.conditions);
            keys.map((feature, index) => {
                var obj = specs.conditions[feature];
                var isError = false;
                if (obj.featureType === "VELBAND" || obj.featureType === undefined) {
                    if (obj.Operational.threshold_value === "" || isNaN(obj.Operational.threshold_value)) {
                        errors[feature + "Operational"] = this.props.t("Please enter a valid value");
                        flag = false;
                        isError = true;
                    } else if (errors[feature + "Operational"]) {
                        flag = false;
                        isError = true;
                    }
                    if (obj.Caution.threshold_value === "" || isNaN(obj.Caution.threshold_value)) {
                        errors[feature + "Caution"] = this.props.t("Please enter a valid value");
                        flag = false;
                        isError = true;

                    } else if (errors[feature + "Caution"]) {
                        flag = false;
                        isError = true;

                    }
                    if (obj.Warning.threshold_value === "" || isNaN(obj.Warning.threshold_value)) {
                        errors[feature + "Warning"] = this.props.t("Please enter a valid value");
                        flag = false;
                        isError = true;

                    } else if (errors[feature + "Warning"]) {
                        flag = false;
                        isError = true;

                    }

                    if (obj.alertPreference === "" || obj.alertPreference === undefined) {
                        errors[feature + "alertPreference"] = this.props.t("Please enter a valid value");
                        flag = false;
                        isError = true;

                    }

                    if (errors[feature + "repeatFrequency"]) {
                        flag = false;
                        isError = true;

                    }

                }
                if (obj.featureType === "VELBAND") {

                    if (obj.displayLabel === "" || obj.displayLabel === undefined) {
                        errors[feature + "displayLabel"] = this.props.t("Please enter a valid value");
                        flag = false;
                        isError = true;

                    } else if (errors[feature + "displayLabel"]) {
                        flag = false;
                        isError = true;

                    }

                    if (errors[feature + "recommendation"]) {
                        flag = false;
                        isError = true;
                    }

                    if (obj.spectral_features.axis === "" || obj.spectral_features.axis === undefined) {
                        errors[feature + "axis"] = this.props.t("Please select a valid value");
                        flag = false;
                        isError = true;

                    }
                    if (obj.spectral_features.multiplier === undefined || (obj.spectral_features.multiplier === "" || isNaN(obj.spectral_features.multiplier))) {
                        errors[feature + "multiplier"] = this.props.t("Please enter a valid value");
                        flag = false;
                        isError = true;

                    }
                    if (obj.spectral_features.band === "" || obj.spectral_features.band === undefined) {
                        errors[feature + "band"] = this.props.t("Please enter a valid value");
                        flag = false;
                        isError = true;

                    }

                    if (obj.spectral_features.unit === "" || obj.spectral_features.unit === undefined) {
                        errors[feature + "unit"] = this.props.t("Please enter a valid value");
                        flag = false;
                        isError = true;

                    } else if (errors[feature + "unit"]) {
                        flag = false;
                        isError = true;

                    }

                } else if (obj.featureType === "PHASEDIFF") {
                    if (obj.displayLabel === "" || obj.displayLabel === undefined) {
                        errors[feature + "displayLabel"] = this.props.t("Please enter a valid value");
                        flag = false;
                        isError = true;

                    } else if (errors[feature + "displayLabel"]) {
                        flag = false;
                        isError = true;

                    }

                    // if (errors[feature + "recommendation"]) {
                    //     flag = false;
                    //     isError = true;
                    // }

                    if (obj.spectral_features.axis1 === "" || obj.spectral_features.axis1 === undefined) {
                        errors[feature + "axis1"] = this.props.t("Please select a valid value");
                        flag = false;
                        isError = true;

                    } else if (errors[feature + "axis1"]) {
                        flag = false;
                        isError = true;

                    }

                    if (obj.spectral_features.axis2 === "" || obj.spectral_features.axis2 === undefined) {
                        errors[feature + "axis2"] = this.props.t("Please select a valid value");
                        flag = false;
                        isError = true;

                    } else if (errors[feature + "axis2"]) {
                        flag = false;
                        isError = true;

                    }

                    if (obj.spectral_features.unit === "" || obj.spectral_features.unit === undefined) {
                        errors[feature + "unit"] = this.props.t("Please enter a valid value");
                        flag = false;
                        isError = true;

                    } else if (errors[feature + "unit"]) {
                        flag = false;
                        isError = true;

                    }

                }
                specs.conditions[feature] = obj;

                if (isError) {
                    obj.expand = true;
                }

            })

            if (specs.diagnostics) {
                specs.diagnostics.map((obj, index) => {
                    var isError = false;
                    if (errors["repeatFrequency" + index]) {
                        flag = false;
                        isError = true;

                    }
                    if (obj.displayLabel === "" || obj.displayLabel === undefined) {
                        errors["displayLabel" + index] = this.props.t("Please enter a valid value");
                        flag = false;
                        isError = true;
                    } else if (errors["displayLabel" + index]) {
                        flag = false;
                        isError = true;

                    }


                    obj.triggers.map((item, index1) => {
                        if (item.displayLabel === "" || item.displayLabel === undefined) {
                            errors["displayLabel" + index + index1] = this.props.t("Please enter a valid value");
                            flag = false;
                            isError = true;
                        }
                        if (item.feature1 === "" || item.feature1 === undefined) {
                            errors["feature1" + index + index1] = this.props.t("Please enter a valid value");
                            flag = false;
                            isError = true;
                        } else if (errors["feature1" + index + index1]) {
                            flag = false;
                            isError = true;

                        }

                        if (errors["feature2" + index + index1]) {
                            flag = false;
                            isError = true;

                        }

                        if (errors["recommendation" + index]) {
                            flag = false;
                            isError = true;
                        }

                        if (item.comparator === "" || item.comparator === undefined) {
                            errors["comparator" + index + index1] = this.props.t("Please enter a valid value");
                            flag = false;
                            isError = true;
                        }
                        if (item.operator === "" || item.operator === undefined) {
                            errors["operator" + index + index1] = this.props.t("Please enter a valid value");
                            flag = false;
                            isError = true;
                        }

                        if (item.threshold === "" || isNaN(item.threshold)) {
                            errors["threshold" + index + index1] = this.props.t("Please enter a valid value");
                            flag = false;
                            isError = true;

                        }
                    })
                    if (isError) {
                        obj.expand = true;
                    }


                })
            }

            this.setState({
                specs: specs,
                errorModel: errors
            })

        }
        return flag;


    }

    updateStateForDValues = (value, field, feature, trigger, errorMessage) => {
        let specs = this.state.specs;
        var errors = this.state.errorModel;
        let currentObject = {};
        currentObject = specs.diagnostics[feature];
        if (trigger === null) {
            currentObject[field] = value;
        } else {
            currentObject.triggers[trigger][field] = value;

        }
        if (trigger !== null) {
            if (currentObject.triggers[trigger]["feature1"] && currentObject.triggers[trigger]["feature1"] != "" && currentObject.triggers[trigger]["feature2"] && currentObject.triggers[trigger]["feature2"] != "" && currentObject.triggers[trigger]["operator"] && currentObject.triggers[trigger]["operator"] != "") {
                var feature1 = features.includes(currentObject.triggers[trigger]["feature1"]) ? currentObject.triggers[trigger]["feature1"] : specs.conditions[currentObject.triggers[trigger]["feature1"]].displayLabel;
                var feature2 = features.includes(currentObject.triggers[trigger]["feature2"]) ? currentObject.triggers[trigger]["feature2"] : specs.conditions[currentObject.triggers[trigger]["feature2"]].displayLabel;
                if (currentObject.triggers[trigger]["comparator"] && currentObject.triggers[trigger]["comparator"] != "" && currentObject.triggers[trigger]["threshold"] && currentObject.triggers[trigger]["threshold"] != "") {
                    currentObject.triggers[trigger].displayLabel = feature1 + " " + currentObject.triggers[trigger]["operator"] + " " + feature2 + " " + currentObject.triggers[trigger]["comparator"] + " " + currentObject.triggers[trigger]["threshold"];
                } else {
                    currentObject.triggers[trigger].displayLabel = feature1 + " " + currentObject.triggers[trigger]["operator"] + " " + feature2;
                }
            } else if (currentObject.triggers[trigger]["feature1"] && currentObject.triggers[trigger]["feature1"] != "") {
                var feature1 = features.includes(currentObject.triggers[trigger]["feature1"]) ? currentObject.triggers[trigger]["feature1"] : specs.conditions[currentObject.triggers[trigger]["feature1"]].displayLabel;
                if (currentObject.triggers[trigger]["comparator"] && currentObject.triggers[trigger]["comparator"] != "" && currentObject.triggers[trigger]["threshold"] && currentObject.triggers[trigger]["threshold"] != "") {
                    currentObject.triggers[trigger].displayLabel = feature1 + " " + currentObject.triggers[trigger]["comparator"] + " " + currentObject.triggers[trigger]["threshold"];
                } else {
                    currentObject.triggers[trigger].displayLabel = feature1;
                }
            } else {
                currentObject.triggers[trigger].displayLabel = "";
            }

        }



        if (errorMessage != "") {
            trigger === null ? errors[field + feature] = errorMessage : errors[field + feature + trigger] = errorMessage;
        } else {
            if (trigger === null) {
                if (errors[field + feature]) {
                    delete errors[field + feature];
                }
            } else {
                if (errors[field + feature + trigger]) {
                    delete errors[field + feature + trigger];
                }
            }

        }

        if (trigger !== null && (field === "feature1" || field === "feature2")) {
            if (currentObject.triggers[trigger]["feature1"] && currentObject.triggers[trigger]["feature1"] != "" && currentObject.triggers[trigger]["feature2"] && currentObject.triggers[trigger]["feature2"] != "") {
                var errorval = "";
                errorval = currentObject.triggers[trigger]["feature1"] === currentObject.triggers[trigger]["feature2"] ? "Feature1 and Feature2 must be different." : "";
                if (errorval != "") {
                    errors["feature2" + feature + trigger] = errorval;
                } else {
                    if (errors["feature2" + feature + trigger]) {
                        delete errors["feature2" + feature + trigger];
                    }
                }
            } else {
                if (errors["feature2" + feature + trigger]) {
                    delete errors["feature2" + feature + trigger];
                }
            }
        }

        specs.diagnostics[feature] = currentObject;

        this.setState({
            specs: specs,
            errorModel: errors,
            disableSaveButton: false
        })
    }

    updateStateForValues = (value, field, feature, errorMessage) => {
        let specs = this.state.specs;
        var errors = this.state.errorModel;
        let currentObject = {};
        currentObject = specs.conditions[feature];
        var keys = ["unit", "multiplier", "band", "axis", "axis1", "axis2"];

        if (keys.includes(field)) {
            currentObject["spectral_features"][field] = value;
        } else {
            currentObject[field] = value;
        }

        if (field === "axis1" || field === "axis2") {
            errorMessage = field === "axis1" && value === currentObject["spectral_features"].axis2 ? this.props.t("Axis1 and Axis2 must not be same.") : errorMessage;
            errorMessage = field === "axis2" && value === currentObject["spectral_features"].axis1 ? this.props.t("Axis1 and Axis2 must not be same.") : errorMessage;
        }
        if (currentObject["spectral_features"]) {

            if (currentObject["spectral_features"].axis1 !== currentObject["spectral_features"].axis2) {
                if (errors[feature + "axis1"]) {
                    delete errors[feature + "axis1"];
                }

                if (errors[feature + "axis2"]) {
                    delete errors[feature + "axis2"];
                }
            }
        }
        if (errorMessage != "") {
            errors[feature + field] = errorMessage;
        } else {
            if (errors[feature + field]) {
                delete errors[feature + field];
            }
        }
        specs.conditions[feature] = currentObject;

        this.setState({
            specs: specs,
            errorModel: errors,
            disableSaveButton: false
        })
    }


    updateStateForThreshold = (value, type, feature) => {
        let specs = this.state.specs;
        var errors = this.state.errorModel;
        let currentObject = {};
        let error = false;
        let errorMessage = "";
        if (type === 'Operational' || type === 'Caution' || type === 'Warning' || type === 'threshold_value') {
            // value = parseInt(value);
            currentObject = specs.conditions[feature];
            //  currentObject = currentObject.children.find((item) => (item.key === levelIndicator[1]));

            let operationValue = type === 'Operational' ? value : currentObject.Operational['threshold_value'];
            let cautionValue = type === 'Caution' ? value : currentObject.Caution['threshold_value'];
            let warningValue = type === 'Warning' ? value : currentObject.Warning['threshold_value'];
            operationValue = parseFloat(operationValue);
            cautionValue = parseFloat(cautionValue);
            warningValue = parseFloat(warningValue);
            if ((isNaN(value) && type === 'Operational')) { //|| operationValue === '' || isNaN(operationValue)
                type === 'Operational' ? currentObject.Operational['threshold_value'] = value : currentObject.Operational['threshold_value'] = currentObject.Operational['threshold_value'];
                error = true;
                errorMessage = this.props.t("Please enter a valid value");;
                errors[feature + 'Operational'] = errorMessage;

            } else if (operationValue >= cautionValue || operationValue >= warningValue) {
                type === 'Operational' ? currentObject.Operational['threshold_value'] = value : currentObject.Operational['threshold_value'] = currentObject.Operational['threshold_value'];
                error = true;
                errorMessage = this.props.t("Operational value can not be greater than or equal to Caution or Warning value");
                errors[feature + 'Operational'] = errorMessage;

            } else {
                // currentObject.children[0].children[0].value= value;
                type === 'Operational' ? currentObject.Operational['threshold_value'] = value : currentObject.Operational['threshold_value'] = currentObject.Operational['threshold_value'];
                error = false;
                errorMessage = null;
                if (errors[feature + "Operational"]) {
                    delete (errors[feature + "Operational"]);

                }

            }
            if ((isNaN(value) && type === 'Caution')) { // ||cautionValue === '' || isNaN(cautionValue)
                type === 'Caution' ? currentObject.Caution['threshold_value'] = value : currentObject.Caution['threshold_value'] = currentObject.Caution['threshold_value'];
                error = true;
                errorMessage = this.props.t("Please enter a valid value");;
                errors[feature + 'Caution'] = errorMessage;

            } else if (cautionValue <= operationValue || cautionValue >= warningValue) {
                type === 'Caution' ? currentObject.Caution['threshold_value'] = value : currentObject.Caution['threshold_value'] = currentObject.Caution['threshold_value'];
                error = true;
                errorMessage = this.props.t("Caution value can not be greater than or equal to Warning value or can not be lesser than or equal Operational value");
                errors[feature + 'Caution'] = errorMessage;

            } else {
                // currentObject.children[1].children[0].value= value;
                type === 'Caution' ? currentObject.Caution['threshold_value'] = value : currentObject.Caution['threshold_value'] = currentObject.Caution['threshold_value'];
                error = false;
                errorMessage = null;

                if (errors[feature + "Caution"]) {
                    delete (errors[feature + "Caution"]);

                }
            }
            if ((isNaN(value) && type === 'Warning')) { //|| warningValue === '' || isNaN(warningValue)
                type === 'Warning' ? currentObject.Warning['threshold_value'] = value : currentObject.Warning['threshold_value'] = currentObject.Warning['threshold_value'];
                error = true;
                errorMessage = this.props.t("Please enter a valid value");;
                errors[feature + 'Warning'] = errorMessage;

            } else if (warningValue <= cautionValue || warningValue <= operationValue) {
                type === 'Warning' ? currentObject.Warning['threshold_value'] = value : currentObject.Warning['threshold_value'] = currentObject.Warning['threshold_value'];
                error = true;
                errorMessage = this.props.t("Warning value can not be lesser than or equal Caution or Operational value");
                errors[feature + 'Warning'] = errorMessage;

            } else {
                // currentObject.children[2].children[0].value= value;
                type === 'Warning' ? currentObject.Warning['threshold_value'] = value : currentObject.Warning['threshold_value'] = currentObject.Warning['threshold_value'];
                error = false;
                errorMessage = null;
                if (errors[feature + "Warning"]) {
                    delete (errors[feature + "Warning"]);

                }

            }

            specs.conditions[feature] = currentObject;

        }
        this.setState({
            specs: specs,
            errorModel: errors,
            disableSaveButton: false
        })
    }

    addDiagnostics = (feature, type) => {
        let specs = this.state.specs;
        var diagnostics = specs.diagnostics;
        var dignostic = {
            "code": "NEW",
            "displayLabel": "",
            "recommendation": "",
            "triggers": [
                {
                    "code": "NEW",
                    "displayLabel": "",
                    "feature1": "",
                    "feature2": "",
                    "operator": "",
                    "comparator": "",
                    "threshold": "",
                    "isMandatory": false,
                    isNew: true
                }
            ],
            "alertBuffer": "",
            "repeatFrequency": "",
            "expand": false,
            isNew: true
        };
        switch (type) {
            case "feature":
                if (diagnostics) {
                    diagnostics.push(dignostic);

                } else {
                    diagnostics = [];
                    diagnostics.push(dignostic);

                }

                break;
            case "trigger":
                var trigger = {
                    "code": "NEW",
                    "displayLabel": "",
                    "feature1": "",
                    "feature2": "",
                    "operator": "",
                    "comparator": "",
                    "threshold": "",
                    "isMandatory": false,
                    isNew: true
                };
                var triggers = diagnostics[feature].triggers;
                if (triggers) {
                    triggers.push(trigger);

                } else {
                    triggers = [];
                    triggers.push(trigger);

                }
                diagnostics[feature].triggers = [...triggers];
                break;

        }
        this.state.specs.diagnostics = diagnostics;
        this.setState({
            specs: specs, disableSaveButton: false
        });
    }

    removeDiagnostics = (feature, trigger, type) => {
        let specs = this.state.specs;
        var diagnostics = specs.diagnostics;
        if (diagnostics) {
            switch (type) {
                case "feature":
                    var data = [];
                    diagnostics.map((item, index) => {
                        if (index !== feature) {
                            data.push(item);
                        }
                    })
                    diagnostics = [...data];
                    break;
                case "trigger":
                    var triggers = diagnostics[feature].triggers;
                    var data = [];
                    triggers.map((item, index) => {
                        if (index !== trigger) {
                            data.push(item);
                        }
                    })
                    diagnostics[feature].triggers = [...data];
                    break;
            }
        }
        this.state.specs.diagnostics = diagnostics;
        this.setState({
            specs: specs, disableSaveButton: false
        });
    }

    updateSpecForFaultConifg = (bearingTypeNumber) => {
        this.props.getFaultSpecsForMonitor(bearingTypeNumber);
    }
    addNewAdvancedFeature(featureType) {
        var velocityBandObject = {
            featureType: "",
            displayLabel: "",
            "spectral_features": {
                "axis": "",
                "multiplier": "",
                "band": "",
                "unit": ""
            },
            "recommendation": "",
            "Operational": {
                "threshold_value": "",

            },
            "Caution": {
                "threshold_value": "",
            },
            "Warning": {
                "threshold_value": "",
            },

            dashboardDial: true,
            alertPreference: "CAUTWARN",
            alertBuffer: "",
            repeatFrequency: "",
            expand: false,
            isNew: true
        }
        var phaseDiffObject = {
            featureType: "",
            displayLabel: "",
            dashboardDial: true,
            "spectral_features": {
                "axis1": "",
                "axis2": "",
                "unit": ""
            },
            isNew: true,
            expand: ""
        }
        var featureData = {};
        featureData = featureType === "VELBAND" ? velocityBandObject : phaseDiffObject;
        featureData.featureType = featureType;
        let specs = this.state.specs;
        var conditions = specs.conditions;
        var newAdvancedFeatures = this.state.newAdvancedFeatures + 1;
        conditions["NEW" + newAdvancedFeatures] = featureData;
        specs.conditions = conditions;
        this.setState({
            specs: specs,
            newAdvancedFeatures: newAdvancedFeatures,
            disableSaveButton: false
        })
    }

    removeBearingTypeFaults() {
        let specs = this.state.specs;
        bearingFeatures.map((item) => {
            if (specs.conditions[item]) {
                delete specs.conditions[item];
            }
        })
        this.setState({
            specs: specs, disableSaveButton: false
        });
    }
    updateStateForSpectralMonitoring(value, field, node, errorMessage) {
        if (value === "VARIABLE") {
            let specs = this.state.specs;
            specs[node]["rotating_speed_range"]["from"] = "";
            specs[node]["rotating_speed_range"]["to"] = "";
            var errors = this.state.errorModel;
            delete errors["rotating_speed"];

        }
        if (value === "FIXED") {
            let specs = this.state.specs;
            specs[node]["rotating_speed"] = "";
            var errors = this.state.errorModel;
            delete errors["from"];
            delete errors["to"];
        }
        if (field === "bearing_type" && value === 0) {
            this.removeBearingTypeFaults();
        }
        let specs = this.state.specs;
        if (field === "from" || field === "to") {
            specs[node]["rotating_speed_range"][field] = value;
        } else {
            specs[node][field] = value;
        }
        var errors = this.state.errorModel;
        if (errorMessage !== "") {
            errors[field] = errorMessage;

        } else {
            if (errors[field]) { delete errors[field] };
            if (field === "to" && errors["from"]) {
                delete errors["from"];
            }
            if (field === "from" && errors["to"]) {
                delete errors["to"];
            }

        }
        if (field === 'lines_of_resolution' || field === 'maximum_frequency') {
            var mindsp = 0;
            if (this.props.metadata && this.props.metadata.metadata && this.props.metadata.metadata.dataSendPeriodDetails) {
                const lor = specs.spectral_monitoring.lines_of_resolution;
                const fmax = specs.spectral_monitoring.maximum_frequency;
                if (fmax && fmax !== "" && fmax !== 0 && lor && lor !== 0 && lor !== "") {
                    var res = this.props.metadata.metadata.dataSendPeriodDetails.find(x => x.lor.toString() === lor.toString() && x.fmax.toString() === fmax.toString());
                    if (res) {
                        mindsp = res.dsp / 1000;
                    }
                }
                let lorInt = parseInt(lor)
                if ((fmax === "2500" && (lorInt === 100 || lorInt === 200)) || (fmax === '5000' && (lorInt === 100 || lorInt === 200 || lorInt === 400)) || (fmax === '10000' && (lorInt === 100 || lorInt === 200 || lorInt === 400 || lorInt === 800))) {
                    errors['lor'] = this.props.t("Please select valid LOR value.")
                } else {
                    if (errors["lor"]) { delete errors["lor"] }
                }
                if (field === 'maximum_frequency' && fmax <= 1300) {
                    specs["spectral_monitoring"]["g_range"] = 4;

                } else {
                    specs["spectral_monitoring"]["g_range"] = 8;

                }
            }
            if (specs.additional_attrs.dsp && specs.additional_attrs.dsp !== "" && (mindsp !== 2 && parseFloat(specs.additional_attrs.dsp) < mindsp)) {
                errors['dsp'] = this.props.t("Please select valid DSP value.")
            } else {
                if (errors["dsp"]) { delete errors["dsp"] }
            }
        }
        if (field === "g_range") {
            let specs = this.state.specs;
            specs[node][field] = value;
        }
        if (field === "rotating_speed" || field === "to" || field === "from") {
            let specs = this.state.specs;
            let val;
            if (field === "to" || field === "from") {
                let to = specs[node]["rotating_speed_range"]["to"]
                let from = specs[node]["rotating_speed_range"]["from"]
                if (parseFloat(from) <= parseFloat(to)) {
                    val = (parseFloat(to) + parseFloat(from)) / 2
                }
            } else {
                val = parseFloat(value);
            }

            if (val > 200) {
                specs["spectral_monitoring"]["lines_of_resolution"] = 1600;
            } else {
                specs["spectral_monitoring"]["lines_of_resolution"] = 3200;
            }
            if (val <= 400) {
                specs["spectral_monitoring"]["maximum_frequency"] = 325;
                specs["spectral_monitoring"]["g_range"] = 4;
            } else if (val > 400 && val <= 900) {
                specs["spectral_monitoring"]["maximum_frequency"] = 648;
                specs["spectral_monitoring"]["g_range"] = 4;
            } else if (val > 900 && val <= 1900) {
                specs["spectral_monitoring"]["maximum_frequency"] = 1300;
                specs["spectral_monitoring"]["g_range"] = 4;
            } else if (val > 1900 && val <= 3700) {
                specs["spectral_monitoring"]["maximum_frequency"] = 2500;
                specs["spectral_monitoring"]["g_range"] = 8;
            } else if (val > 3700 && val <= 7500) {
                specs["spectral_monitoring"]["maximum_frequency"] = 5000;
                specs["spectral_monitoring"]["g_range"] = 8;
            } else {
                specs["spectral_monitoring"]["maximum_frequency"] = 10000;
                specs["spectral_monitoring"]["g_range"] = 8;
            }
        }
        this.setState({
            specs: specs, errorModel: errors, disableSaveButton: false
        });
    }
    openThresholdPopup() {
        this.tableConfigurations();
        this.setState({ openThresholdNote: false, openThresholdsDialog: true })
    }
    openThresholdNotePopup() {
        // this.tableConfigurations();
        this.setState({ openThresholdNote: true })
    }
    // display specs widget view
    getSpecView = () => {
        return (
            <div>
                {this.state.specs && typeof this.state.specs === "object" ?
                    <MonitorSpec
                        jsonData={this.state.specs}
                        features={features}
                        metadata={this.props.metadata}
                        id={this.state.id}
                        removeBearingTypeFaults={() => this.removeBearingTypeFaults()}
                        dspList={this.state.dspList}
                        isComputeThreshold={this.state.isComputeThreshold}
                        lastThresholdAssistantDatetime={this.state.lastThresholdAssistantDatetime}
                        openThresholdPopup={() => this.openThresholdNotePopup()}
                        updateStateForDValues={(value, field, feature, trigger, errorMessage) => this.updateStateForDValues(value, field, feature, trigger, errorMessage)}
                        updateStateForValues={(value, field, feature, errorMessage) => (this.updateStateForValues(value, field, feature, errorMessage))}
                        updateStateForThreshold={(value, type, feature) => (this.updateStateForThreshold(value, type, feature))}
                        entityName={"monitors"}
                        addDiagnostics={(feature, type) => this.addDiagnostics(feature, type)}
                        removeDiagnostics={(feature, trigger, type) => this.removeDiagnostics(feature, trigger, type)}
                        errorModel={this.state.errorModel}
                        updateStateForSpectralMonitoring={(value, field, node, errorMessage) => (this.updateStateForSpectralMonitoring(value, field, node, errorMessage))}
                        addNewAdvancedFeature={(featureType) => (this.addNewAdvancedFeature(featureType))}
                        showErrorMessage={this.state.showErrorMessage}
                        updateSpecForFaultConifg={(bearingTypeNumber) => (this.updateSpecForFaultConifg(bearingTypeNumber))}
                        expanPanel={(key, val, panel) => (this.expanPanel(key, val, panel))}
                        monitorCodeName={this.state.monitorCodeName}
                        showError={(message, successful) => this.showError(message, successful)}
                        updateFaults={(action, faulCode, bearingTypeNumber) => this.updateFaults(action, faulCode, bearingTypeNumber)}
                        deleteAdvancedFeature={(feature) => this.deleteAdvancedFeature(feature)}
                    />
                    : null}
            </div>
        )
    }

    deleteAdvancedFeature = (feature) => {
        var specs = this.state.specs;
        var conditions = specs.conditions;
        for (let i = 0; i <= feature.length - 1; i++) {
            if (conditions[feature[i]]) {
                delete conditions[feature[i]];
            }
            this.state.specs.conditions = conditions;
            this.setState({
                specs: specs, disableSaveButton: false
            })
        }




    }


    showError = (message, successful) => {
        let message1 = { "responseMessage": { "message": [message] }, "successful": successful };
        this.props.getAlertMessage(message1);
    }

    expanPanel = (key, val, panel) => {
        let specs = this.state.specs;
        switch (panel) {
            case "basic":
                // var keys = Object.keys(specs.conditions[key]);
                specs.conditions[key].expand = val;
                this.setState({
                    specs: specs
                });
                break;
            case "diagnostic":
                specs.diagnostics[key].expand = val;

        }
        this.setState({ specs: specs });

    }


    // get monitor specs for selected monitor type
    getMonitorTypeSpecs = (id) => {
       
        if (this.state.id === "" && this.state.monitorTypeId && this.state.machineId && this.state.monitorTypeId != "" && this.state.machineId != "" && this.state.selectedTab === 0) {
            this.props.getDefaultSpecs(this.state.machineId, this.state.monitorTypeId);
        }
        if (this.state.id && this.state.id !== "" && this.state.monitorTypeId && this.state.machineId && this.state.monitorTypeId != "" && this.state.machineId != "" && this.state.selectedTab === 0) {
            this.props.getDefaultSpecs(this.state.machineId, this.state.monitorTypeId, this.state.id);
        }
    }

    // cast number to string for specs
    normalizeJsonObject = (object) => {
        if (object.value && typeof object.value === "string") {
            if (Number(object.value)) {
                object.value = Number(object.value);
            }
        }

        if (object.children !== null) {
            object.children.forEach((item) => {
                this.normalizeJsonObject(item);
            })
        }
    }

    // Handle changes to any user input field
    inputChangeHandler = (event, field) => {
        switch (field) {
            case "deviceIdentifier": this.setState({ deviceIdentifier: event.target.value, disableSaveButton: false });
                this.checkValidation("deviceIdentifier", event.target.value);
                let find = this.props.devicesData.tableData.find(e => e.deviceIdentifier === event.target.value)
                if (find && find.configBinwidthSupported === false) {
                    this.setState({ showErrorMessage: true });
                } else {
                    this.setState({ showErrorMessage: false });
                }
                break;
            case "specs": this.setState({ specs: event.target.value, disableSaveButton: false });
                this.checkValidation("specs", event.target.value);
                break;
            case "notes": this.setState({ notes: event.target.value, disableSaveButton: false });
                this.checkValidation("notes", event.target.value);
                break;
            case "machineId":
                this.setState({ machineId: event.target.value, displayPosition: "", disableSaveButton: false }, () => {
                    this.props.getMonitorsCountByMachineId(this.state.machineId);
                    this.getMonitorTypeSpecs(this.state.machineId);
                });
                this.checkValidation("machineId", event.target.value);
                break;
            case "orientationCode": this.setState({ orientationCode: (event.target.value == "" ? null : event.target.value), disableSaveButton: false });
                this.checkValidation("orientationCode", event.target.value);
                break;
            case "displayPosition": this.setState({ displayPosition: event.target.value, disableSaveButton: false });
                break;
            case "monitorTypeId":
                let obj = JSON.parse(event.target.value);
                // if (this.state.monitorCodeName == "VELMONWITHFAULTS" && obj.codeName !== "VELMONWITHFAULTS") {
                if (this.state.monitorCodeName !== '' && this.state.monitorCodeName !== obj.codeName) {
                    if (obj.codeName === 'VELMON') {
                        this.setState({
                            openDialog: true,
                            tempMonitorId: obj.id,
                            tempMonitorCodeName: obj.codeName,
                            tempMonitorType: event.target.value
                        })
                    } else {
                        this.setState({
                            openDialog: false,
                            monitorTypeId: obj.id,
                            monitorCodeName: obj.codeName,
                            monitorType: event.target.value,
                            disableSaveButton: false
                        }, () => {
                            this.getMonitorTypeSpecs(this.state.monitorTypeId);
                        })
                    }
                } else {
                    this.setState({ monitorTypeId: obj.id, monitorCodeName: obj.codeName, monitorType: event.target.value, disableSaveButton: false }, () => {
                        this.getMonitorTypeSpecs(this.state.monitorTypeId);
                    });
                }
                this.checkValidation("monitorTypeId", obj.id);
                break;
            case "label": this.setState({ label: event.target.value, disableSaveButton: false });
                this.checkValidation("label", event.target.value);
                break;
            default: console.log("default case");
        }
    }

    // Handle routing
    goToBackPage = () => {
        let navigateId = localStorage.getItem("navigateID");
        if (navigateId) {
            localStorage.removeItem("navigateID");
            this.props.history.push({ pathname: "/dashboard/MonitoringTable", state: true })
        } else {
            this.props.history.goBack();
        }
    }

    // update monitors entity
    updateMonitorsData = () => {
        let { id, deviceIdentifier, notes, specs, machineId, monitorTypeId, label, orientationCode } = this.state;
        let flag = this.validateNull();
        if (flag) {
            if (typeof specs !== "string") {
                specs = this.state.specs;
                let conditions = specs.conditions;
                var keys = Object.keys(conditions);
                keys.map((item) => {
                    delete specs.conditions[item]["expand"];
                    if (specs.conditions[item]["featureType"] !== "PHASEDIFF") {
                        // if ((specs.conditions[item]["alertBuffer"]) === "") {
                        delete specs.conditions[item]["alertBuffer"]
                        // }
                        // if ((specs.conditions[item]["repeatFrequency"]) === "") {
                        delete specs.conditions[item]["repeatFrequency"]
                        //}
                    }
                })
                specs.diagnostics.map((item, index) => {
                    delete specs.diagnostics[index]["expand"];
                    //if (specs.diagnostics[index]["alertBuffer"] === "") {
                    delete specs.diagnostics[index]["alertBuffer"]
                    //}
                    //if ((specs.diagnostics[index]["repeatFrequency"]) === "") {
                    delete specs.diagnostics[index]["repeatFrequency"]
                    //}
                })
                specs = JSON.stringify(specs);
            }
            var displayPosition = this.state.displayPosition ? parseInt(this.state.displayPosition) : null;

            if (id && id != null && id !== "") {
                this.props.updateMonitorsEntity({ id, deviceIdentifier, notes, specsJson: specs, machineId, monitorTypeId, label, orientationCode, displayPosition }, id);
            }
            else {
                this.props.updateMonitorsEntity({ deviceIdentifier, notes, specsJson: specs, machineId, monitorTypeId, label, orientationCode, displayPosition }, id);
            }
        } else {
            let message = { "responseMessage": { "message": ["Field validation error"] }, "successful": false };
            this.props.getAlertMessage(message);
        }
    }

    // create display position dropdown list
    getDisplayPosition = () => {
        let displayPositionList = [];
        displayPositionList.push(<option key={""} disabled={true} value={""}>{this.props.t("Select")}</option>);
        if (this.props.monitorCount && this.props.monitorCount.monitorCount && this.props.monitorCount.monitorCount > 0) {
            for (var i = 1; i <= this.props.monitorCount.monitorCount; i++) {
                displayPositionList.push(
                    <option key={i} value={i}>{i}</option>
                );
                if (i === this.props.monitorCount.monitorCount) {
                    if (this.state.id === null || this.state.id === "") {
                        displayPositionList.push(
                            <option key={this.props.monitorCount.monitorCount + 1} value={this.props.monitorCount.monitorCount + 1}>{this.props.t("Last")}</option>
                        );
                    }
                }
            }

        } else {
            if (this.state.id === null || this.state.id === "") {
                displayPositionList.push(
                    <option key={1} value={1}>{this.props.t("Last")}</option>
                );
            }
        }
        return displayPositionList;
    }


    // create machines dropdown list
    getMachinesName = () => {
        let machinesList = [];
        machinesList.push(<option key={""} disabled={true} value={""}>{this.props.t("Select")}</option>);
        if ((this.state.validationData.machineName.validations && !this.state.validationData.machineName.validations.required) || !(this.state.validationData.machineName.validations)) {
            machinesList.push(<option key={""} value={""}>{this.props.t("None")}</option>);
        }
        if (this.props.machinesData && this.props.machinesData.tableData && this.props.machinesData.tableData.length > 0) {
            this.props.machinesData.tableData.map((data) => {
                machinesList.push(
                    <option key={data.id} value={data.id}>{data.label}</option>
                );
            })
        }
        return machinesList;
    }

    // create orientation dropdown list
    getOrientationList = () => {
        let orientationList = [];
        orientationList.push(<option key={""} value={""} disabled={true}>{this.props.t("Select")}</option>);
        // orientationList.push(<option key={" "} value={" "}>{this.props.t("None")}</option>);
        if (this.props.metadata && this.props.metadata.metadata && this.props.metadata.metadata.orientations && this.props.metadata.metadata.orientations.length > 0) {
            this.props.metadata.metadata.orientations.map((data) => {
                orientationList.push(
                    <option key={data.code} value={data.code}>{this.props.t(data.displayLabel)}</option>
                );
            })
        }
        return orientationList;
    }

    // create devices dropdown list
    getDeviceMacAddress = () => {
        let deviceList = [];
        deviceList.push(<option key={""} disabled={true} value={""}>{this.props.t("Select")}</option>);
        if ((this.state.validationData.monitorDeviceIdentifier.validations && !this.state.validationData.monitorDeviceIdentifier.validations.required) || !(this.state.validationData.monitorDeviceIdentifier.validations)) {
            deviceList.push(<option key={""} value={""}>{this.props.t("None")}</option>);
        }
        if (this.props.devicesData && this.props.devicesData.tableData && this.props.devicesData.tableData.length > 0) {
            this.props.devicesData.tableData.map((data) => {
                deviceList.push(
                    <option key={data.deviceIdentifier} value={data.deviceIdentifier}>{data.deviceIdentifier}</option>
                );
            })
        }
        return deviceList;
    }

    // create monitor-types dropdown list
    getMonitorType = () => {
        let monitorTypeList = [];
        monitorTypeList.push(<option key={""} disabled={true} value={""}>{this.props.t("Select")}</option>);
        if ((this.state.validationData.monitorTypeName.validations && !this.state.validationData.monitorTypeName.validations.required) || !(this.state.validationData.monitorTypeName.validations)) {
            monitorTypeList.push(<option key={""} value={""}>{this.props.t("None")}</option>);
        }
        let found = false;
        let plantOrganization = "";

        if (this.state.plantId) {
            this.props.scopeSelector.subOrganizations.map((organization) => {
                if (organization.plants && organization.plants.length > 0) {
                    organization.plants.map((plant) => {
                        if (plant.id === this.state.plantId && !found) {
                            plantOrganization = organization.id;
                            found = true;
                        }
                    })
                }
            })
        }


        if (plantOrganization && this.props.staticData && this.props.staticData.staticData) {
            let plantOrganizationStaticData = this.props.staticData.staticData[plantOrganization]
            if (plantOrganizationStaticData && plantOrganizationStaticData.monitortypes) {
                plantOrganizationStaticData.monitortypes.map((data) => {
                    monitorTypeList.push(
                        <option key={data.id} value={JSON.stringify({ 'id': data.id, 'codeName': data.codeName })}>{this.props.t(data.name)}</option>
                    );
                })
            }
        }
        return monitorTypeList;
    }

    componentDidMount() {
        let abc = localStorage.getItem("navigateID")
        this.props.getFaultList();
        if (this.props.data) {
            let entityNames = Object.keys(this.props.data)
            entityNames.map((data) => {
                if (this.props.data[data] == null) {
                    this.props.data[data] = ""
                }
            })
            var errors = {};
            this.setState({
                id: this.props.data.id,
                label: this.props.data.label,
                monitorTypeId: this.props.data.monitorTypes.id,
                monitorCodeName: this.props.data.monitorTypes.code,
                monitorType: JSON.stringify({
                    'id': this.props.data.monitorTypes.id,
                    'codeName': this.props.data.monitorTypes.code
                })
            })
            if (this.props.data.lastThresholdAssistant && this.props.data.lastThresholdAssistant !== "") {
                this.setState({ lastThresholdAssistant: this.props.data.lastThresholdAssistant })
            }

            if (this.props.data.lastThresholdAssistantDatetime && this.props.data.lastThresholdAssistantDatetime !== "") {
                this.setState({ lastThresholdAssistantDatetime: this.props.data.lastThresholdAssistantDatetime })
            }

            if (this.props.data.specsJson && this.props.data.specsJson != "") {
                var jsonObject = JSON.parse(this.props.data.specsJson);
                if (!jsonObject.spectral_monitoring) {
                    jsonObject.spectral_monitoring = { "lines_of_resolution": "", "maximum_frequency": "", "minimum_frequency":"","g_range": "" }
                }
                if (jsonObject.additional_attrs) {
                    jsonObject.additional_attrs["dsp"] = jsonObject.additional_attrs["dsp"] && jsonObject.additional_attrs["dsp"] !== null ? jsonObject.additional_attrs["dsp"] : 1
                    jsonObject.additional_attrs["rotating_speed_type"] = jsonObject.additional_attrs["rotating_speed_type"] && jsonObject.additional_attrs["rotating_speed_type"] !== null ? jsonObject.additional_attrs["rotating_speed_type"] : "FIXED"
                    jsonObject.additional_attrs["rotating_speed_range"] = jsonObject.additional_attrs["rotating_speed_range"] && jsonObject.additional_attrs["rotating_speed_range"] !== null ? jsonObject.additional_attrs["rotating_speed_range"] : { from: "", to: "" }
                } else {
                    jsonObject.additional_attrs = {
                        "bearing_type": "",
                        "dsp": 1,
                        "rotating_speed_type": "FIXED",
                        "rotating_speed": "",
                        "rotating_speed_range": {
                            "from": "",
                            "to": ""
                        }
                    }
                }
                var conditions = jsonObject.conditions;
                if (conditions) {
                    var keys = Object.keys(conditions);
                    keys.map((item) => {
                        jsonObject.conditions[item]["expand"] = false;
                        jsonObject.conditions[item]["dashboardDial"] = jsonObject.conditions[item]["dashboardDial"] != undefined ? jsonObject.conditions[item]["dashboardDial"] : true;
                        jsonObject.conditions[item]["alertPreference"] = jsonObject.conditions[item]["alertPreference"] ? jsonObject.conditions[item]["alertPreference"] : "CAUTWARN";
                        jsonObject.conditions[item]["alertBuffer"] = "";
                        jsonObject.conditions[item]["repeatFrequency"] = "";
                        // jsonObject.conditions[item]["alertBuffer"] = jsonObject.conditions[item]["alertBuffer"] ? jsonObject.conditions[item]["alertBuffer"] : "";
                        // jsonObject.conditions[item]["repeatFrequency"] = jsonObject.conditions[item]["repeatFrequency"] ? jsonObject.conditions[item]["repeatFrequency"] : "";
                        jsonObject.conditions[item]["Operational"] = jsonObject.conditions[item]["Operational"] ? jsonObject.conditions[item]["Operational"] : { threshold_value: "" };
                        jsonObject.conditions[item]["Caution"] = jsonObject.conditions[item]["Caution"] ? jsonObject.conditions[item]["Caution"] : { threshold_value: "" };
                        jsonObject.conditions[item]["Warning"] = jsonObject.conditions[item]["Warning"] ? jsonObject.conditions[item]["Warning"] : { threshold_value: "" };
                        if (!features.includes(item)) {
                            jsonObject.conditions[item]["featureType"] = jsonObject.conditions[item]["featureType"] ? jsonObject.conditions[item]["featureType"] : "VELBAND"
                            jsonObject.conditions[item]["recommendation"] = jsonObject.conditions[item]["recommendation"] ? jsonObject.conditions[item]["recommendation"] : "";
                            jsonObject.conditions[item].spectral_features.unit = jsonObject.conditions[item]["spectral_features"].unit ? jsonObject.conditions[item]["spectral_features"].unit : "";
                            if (this.props.faultList && this.props.faultList.json) {
                                if (!jsonObject.conditions[item]["displayLabel"] || jsonObject.conditions[item]["displayLabel"] === "") {
                                    var fault = this.props.faultList.json.find(x => x.faultCode === item);
                                    jsonObject.conditions[item]["displayLabel"] = fault ? fault.faultName : item;
                                }

                            } else {
                                jsonObject.conditions[item]["displayLabel"] = item;
                            }
                        }
                    })
                } else {
                    jsonObject.conditions = {};
                }

                if (jsonObject.diagnostics && jsonObject.diagnostics.length > 0) {
                    var diagnostics = jsonObject.diagnostics;
                    var diagnosticsdata = [];
                    diagnostics.map((item) => {
                        item["expand"] = false;
                        item["dashboardDial"] = item["dashboardDial"] != undefined ? item["dashboardDial"] : true;
                        item["alertBuffer"] = "";
                        item["recommendation"] = item["recommendation"] && item["recommendation"] !== undefined ? item["recommendation"] : "";
                        item["repeatFrequency"] = "";
                        // item["alertBuffer"] = item["alertBuffer"] ? item["alertBuffer"] : "";
                        //  = item["repeatFrequency"] ? item["repeatFrequency"] : "";
                        diagnosticsdata.push(item);
                    })
                    jsonObject.diagnostics = diagnosticsdata;
                } else {
                    jsonObject.diagnostics = [];
                }

                this.setState({ specs: jsonObject, errorModel: errors }
                    /*,() => {
                        this.getMonitorTypeSpecs();
                    }*/
                )


            }
            if (this.props.data.orientation) {
                if (this.props.data.orientation.code) {
                    this.setState({ orientationCode: this.props.data.orientation.code, orientationError: false })
                }
                else {
                    this.setState({ orientationCode: "", orientationError: true })
                }
            }
            if (this.props.data.displayPosition) {
                this.setState({ displayPosition: this.props.data.displayPosition })
            }

            if (this.props.data.notes && this.props.data.notes != "") {
                if (this.props.data.notes.props) {
                    this.setState({ notes: this.props.data.notes.props.children })
                }
                else {
                    this.setState({ notes: this.props.data.notes })
                }
            }
            if (this.props.data.machines && this.props.data.machines.id) {
                this.setState({ machineId: this.props.data.machines.id }, () => {
                    this.props.getMonitorsCountByMachineId(this.state.machineId);
                })
            }
            if (this.props.data.devices && this.props.data.devices.deviceIdentifier) {
                if (this.props.data.devices.hasOwnProperty('configBinwidthSupported')) {
                    if (this.props.data.devices.configBinwidthSupported === false) {
                        this.setState({ deviceIdentifier: this.props.data.devices.deviceIdentifier, showErrorMessage: true });
                    } else {
                        this.setState({ deviceIdentifier: this.props.data.devices.deviceIdentifier, showErrorMessage: false });
                    }
                } else {
                    this.setState({ deviceIdentifier: this.props.data.devices.deviceIdentifier, showErrorMessage: false });
                }

            }
            if (this.props.data.monitorTypes && this.props.data.monitorTypes.id) {
                this.setState({ monitorTypeId: this.props.data.monitorTypes.id })
            }
        }
        if (this.props.selectedScopeData) {
            this.setState({
                plantId: this.props.selectedScopeData,
            })
        }

        if (this.props.validationData && this.props.validationData.config && this.props.validationData.config.Monitors) {
            this.setState({ validationData: this.props.validationData.config.Monitors });
        }
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevState.plantId != this.state.plantId && this.state.plantId && this.state.plantId != "") {
            this.props.getTableData("DevicesTable", this.state.plantId, true, false, this.state.deviceIdentifier);
            this.props.getTableData("MachinesTable", this.state.plantId, true);
            this.props.getTableData("Metadata", this.state.plantId, true);

        }
        if (prevProps.thresholds !== this.props.thresholds) {
            if (this.props.thresholds.thresholds) {
                this.setState({ lastThresholdAssistant: this.props.thresholds.thresholds, lastThresholdAssistantDatetime: new Date().getTime(), isComputeThreshold: false }, () => {
                    this.tableConfigurations();
                })
            } else {
                this.setState({ lastThresholdAssistant: "", lastThresholdAssistantDatetime: "", isComputeThreshold: false }, () => {
                    this.tableConfigurations();
                })
            }
        }
        if (prevProps.jsonData !== this.props.jsonData) {
            var errors = {};
            var jsonObject = this.props.jsonData.json;
            if (!jsonObject.spectral_monitoring) {
                jsonObject.spectral_monitoring = { "lines_of_resolution": "", "maximum_frequency": "","minimum_frequency":"", "g_range": "" }
            }
            if (jsonObject.additional_attrs) {
                jsonObject.additional_attrs["dsp"] = jsonObject.additional_attrs["dsp"] && jsonObject.additional_attrs["dsp"] !== null ? jsonObject.additional_attrs["dsp"] : 1
                jsonObject.additional_attrs["rotating_speed_type"] = jsonObject.additional_attrs["rotating_speed_type"] && jsonObject.additional_attrs["rotating_speed_type"] !== null ? jsonObject.additional_attrs["rotating_speed_type"] : "FIXED"
                jsonObject.additional_attrs["rotating_speed_range"] = jsonObject.additional_attrs["rotating_speed_range"] && jsonObject.additional_attrs["rotating_speed_range"] !== null ? jsonObject.additional_attrs["rotating_speed_range"] : { from: "", to: "" }
            } else {
                jsonObject.additional_attrs = {
                    "bearing_type": "",
                    "dsp": 1,
                    "rotating_speed_type": "FIXED",
                    "rotating_speed": "",
                    "rotating_speed_range": {
                        "from": "",
                        "to": ""
                    }
                }
            }
            var conditions = jsonObject.conditions;
            if (conditions) {
                var keys = Object.keys(conditions);
                keys.map((item) => {
                    jsonObject.conditions[item]["expand"] = false;
                    jsonObject.conditions[item]["dashboardDial"] = jsonObject.conditions[item]["dashboardDial"] != undefined ? jsonObject.conditions[item]["dashboardDial"] : true;
                    jsonObject.conditions[item]["alertPreference"] = jsonObject.conditions[item]["alertPreference"] ? jsonObject.conditions[item]["alertPreference"] : "CAUTWARN";
                    // jsonObject.conditions[item]["alertBuffer"] = jsonObject.conditions[item]["alertBuffer"] ? jsonObject.conditions[item]["alertBuffer"] : "";
                    // jsonObject.conditions[item]["repeatFrequency"] = jsonObject.conditions[item]["repeatFrequency"] ? jsonObject.conditions[item]["repeatFrequency"] : "";
                    jsonObject.conditions[item]["alertBuffer"] = "";
                    jsonObject.conditions[item]["repeatFrequency"] = "";
                    jsonObject.conditions[item]["Operational"] = jsonObject.conditions[item]["Operational"] ? jsonObject.conditions[item]["Operational"] : { threshold_value: "" };
                    jsonObject.conditions[item]["Caution"] = jsonObject.conditions[item]["Caution"] ? jsonObject.conditions[item]["Caution"] : { threshold_value: "" };
                    jsonObject.conditions[item]["Warning"] = jsonObject.conditions[item]["Warning"] ? jsonObject.conditions[item]["Warning"] : { threshold_value: "" };
                    if (!features.includes(item)) {
                        jsonObject.conditions[item].spectral_features.unit = jsonObject.conditions[item]["spectral_features"].unit ? jsonObject.conditions[item]["spectral_features"].unit : "";
                        jsonObject.conditions[item]["recommendation"] = jsonObject.conditions[item]["recommendation"] ? jsonObject.conditions[item]["recommendation"] : undefined;
                        jsonObject.conditions[item]["featureType"] = jsonObject.conditions[item]["featureType"] ? jsonObject.conditions[item]["featureType"] : "VELBAND"
                        if (this.props.faultList && this.props.faultList.json) {
                            var fault = this.props.faultList.json.find(x => x.faultCode === item);
                            jsonObject.conditions[item]["displayLabel"] = fault ? fault.faultName : item;

                        } else {
                            jsonObject.conditions[item]["displayLabel"] = item;
                        }
                    }
                })
            } else {
                jsonObject.conditions = {};
            }
            if (jsonObject.diagnostics && jsonObject.diagnostics.length > 0) {
                var diagnostics = jsonObject.diagnostics;
                var diagnosticsdata = [];
                diagnostics.map((item) => {
                    item["expand"] = false;
                    item["dashboardDial"] = item["dashboardDial"] != undefined ? item["dashboardDial"] : true;
                    item["recommendation"] = item["recommendation"] && item["recommendation"] !== undefined ? item["recommendation"] : undefined;
                    // item["alertBuffer"] = item["alertBuffer"] ? item["alertBuffer"] : "";
                    // item["repeatFrequency"] = item["repeatFrequency"] ? item["repeatFrequency"] : "";
                    item["alertBuffer"] = "";
                    item["repeatFrequency"] = "";
                    diagnosticsdata.push(item);
                })
                jsonObject.diagnostics = diagnosticsdata;
            } else {
                jsonObject.diagnostics = [];
            }

            this.setState({ specs: jsonObject, errorModel: errors }
                /*,() => {
                    this.getMonitorTypeSpecs();
                }*/
            )
            /* this.props.jsonData.json ? this.setState({
                 specs: jsonObject
             })
                 : this.setState({
                     selectedTab: 0
                 })*/
        }

        if (prevProps.metadata !== this.props.metadata) {
            if (this.props.metadata && this.props.metadata.metadata && this.props.metadata.metadata.dataSendPeriodDetails) {
                var distinctdsp = [...new Set(this.props.metadata.metadata.dataSendPeriodDetails.map(x => x.dsp / 1000))];
                this.setState({ dspList: distinctdsp });
            }
        }

        if (prevProps.bearingData !== this.props.bearingData) {
            var jsonObject = this.state.specs;
            var conditions = jsonObject.conditions;
            if (this.props.bearingData && this.props.bearingData.json) {
                var keys = Object.keys(this.props.bearingData.json.conditions);
                keys.map((item) => {
                    var object = this.props.bearingData.json.conditions[item];
                    object["expand"] = false;
                    object["dashboardDial"] = object["dashboardDial"] != undefined ? object["dashboardDial"] : true;
                    object["alertPreference"] = object["alertPreference"] && object["alertPreference"] !== null ? object["alertPreference"] : "CAUTWARN";
                    object["alertBuffer"] = object["alertBuffer"] && object["alertBuffer"] !== null ? object["alertBuffer"] : "";
                    object["repeatFrequency"] = object["repeatFrequency"] && object["repeatFrequency"] !== null ? object["repeatFrequency"] : "";
                    object["Operational"] = object["Operational"] && object["Operational"] != null ? object["Operational"] : { threshold_value: "" };
                    object["Caution"] = object["Caution"] && object["Caution"] != null ? object["Caution"] : { threshold_value: "" };
                    object["Warning"] = object["Warning"] && object["Warning"] != null ? object["Warning"] : { threshold_value: "" };
                    if (!features.includes(item)) {
                        object.spectral_features.unit = object["spectral_features"].unit ? object["spectral_features"].unit : "";
                        object["recommendation"] = object["recommendation"] ? object["recommendation"] : undefined;
                        object["featureType"] = object["featureType"] ? object["featureType"] : "VELBAND"
                        if (this.props.faultList && this.props.faultList.json) {
                            var fault = this.props.faultList.json.find(x => x.faultCode === item);
                            object["displayLabel"] = fault ? fault.faultName : item;

                        } else {
                            object["displayLabel"] = item;
                        }
                    }
                    jsonObject.conditions[item] = object;
                })
            }

            this.setState({ specs: jsonObject });
        }

    }

    computeThresholds = () => {
        this.props.getThresholds(this.state.id);
        this.setState({ lastThresholdAssistant: "", isComputeThreshold: true });
    }

    // disable save button based on validations
    disableSaveButton = () => {
        if (this.state.label === "" && (this.state.validationData.label.validations && this.state.validationData.label.validations.required)) {
            return true;
        }
        else if (this.state.deviceIdentifier === "" && (this.state.validationData.monitorDeviceIdentifier.validations && this.state.validationData.monitorDeviceIdentifier.validations.required)) {
            return true;
        }
        else if (this.state.notes === "" && (this.state.validationData.notes.validations && this.state.validationData.notes.validations.required)) {
            return true;
        }
        else if (this.state.specs === "" && (this.state.validationData.specs.validations && this.state.validationData.specs.validations.required)) {
            return true;
        }
        else if (this.state.machineId === "" && (this.state.validationData.machineName.validations && this.state.validationData.machineName.validations.required)) {
            return true;
        }
        else if (this.state.monitorTypeId === "" && (this.state.validationData.monitorTypeName.validations && this.state.validationData.monitorTypeName.validations.required)) {
            return true;
        }
        else if (this.state.orientationError) {
            return true;
        }

        else if (this.state.labelError || this.state.specsError || this.state.notesError || this.state.monitorTypeError || this.state.machineError || this.state.deviceIdentifierError || this.state.displayPositionError || this.state.orientationError) {
            return true;
        }

        else {
            return false;
        }
    }

    // set card header
    getCardHeader = () => {
        if (this.props.data && this.props.data.label && this.props.data.label != "") {
            return this.props.data.label;
        }
        else {
            return this.props.t("Create New")
        }
    }

    tableConfigurations = () => {
        let columns = [
            {
                "name": "featureKey",
                "label": "Feature",
                "options": {
                    "display": true,
                    "filter": false,
                    "sort": false,
                    "searchable": false
                }
            },
            {
                "name": "operational",
                "label": "Operational",
                "options": {
                    "display": true,
                    "filter": false,
                    "sort": false,
                    "searchable": false
                }
            },
            {
                "name": "caution",
                "label": "Caution",
                "options": {
                    "display": true,
                    "filter": false,
                    "sort": false,
                    "searchable": false
                }
            },
            {
                "name": "warning",
                "label": "Warning",
                "options": {
                    "display": true,
                    "filter": false,
                    "sort": false,
                    "searchable": false
                }
            }


        ];
        let data = [];

        data = this.state.lastThresholdAssistant && this.state.lastThresholdAssistant.thresholds ?
            this.state.lastThresholdAssistant.thresholds.map((item) => {
                return item;
            }) : null;
        this.setState({
            columns,
            data
        })

    }

    setComputedThresholds = () => {
        var specs = this.state.specs;
        if (this.state.lastThresholdAssistant && this.state.lastThresholdAssistant !== "") {
            this.state.lastThresholdAssistant.thresholds.map((threshold) => {
                var feature = featureCodes.includes(threshold.featureCode) ? threshold.featureKey : threshold.featureCode;
                if (specs.conditions[feature]) {
                    specs.conditions[feature].Operational.threshold_value = threshold.operational ? threshold.operational : specs.conditions[feature].Operational.threshold_value;
                    specs.conditions[feature].Caution.threshold_value = threshold.caution ? threshold.caution : specs.conditions[feature].Caution.threshold_value;
                    specs.conditions[feature].Warning.threshold_value = threshold.warning ? threshold.warning : specs.conditions[feature].Warning.threshold_value;
                    specs.conditions[feature].expand = true;
                }
            })
            this.setState({
                specs: specs,
                openThresholdsDialog: false,
                disableSaveButton: false,
                openConfirmDialog: false
            })
        }

    }
    handleOpenConfirmDialog = () => {
        this.setState({
            openConfirmDialog: true,
            disableSaveButton: true
        })
    }
    handleConfirmCancel = () => {
        this.setState({
            openConfirmDialog: false,
            disableSaveButton: false
        })

    }
    // check validations of the user input fields
    checkValidation = (field, value) => {
        switch (field) {
            case "deviceIdentifier":
                if (!this.state.deviceIdentifierError && value === "" && (this.state.validationData.deviceIdentifier && this.state.validationData.deviceIdentifier.validations && this.state.validationData.deviceIdentifier.validations.required)) {
                    this.setState({ deviceIdentifierError: true });
                } else if (this.state.deviceIdentifierError) {
                    if ((value === "" && (this.state.validationData.deviceIdentifier && this.state.validationData.deviceIdentifier.validations && this.state.validationData.deviceIdentifier.validations.required))) {
                        //skip
                    }
                    else {
                        this.setState({ deviceIdentifierError: false });
                    }
                };
                break;
            case "machineId":
                if (!this.state.machineError && value === "" && (this.state.validationData.machineName.validations && this.state.validationData.machineName.validations.required)) {
                    this.setState({ machineError: true });
                } else if (this.state.machineError) {
                    if ((value === "" && (this.state.validationData.machineName.validations && this.state.validationData.machineName.validations.required))) {
                        //skip
                    }
                    else {
                        this.setState({ machineError: false });
                    }
                };
                break;
            case "monitorTypeId":
                if (!this.state.monitorTypeError && value === "" && (this.state.validationData.monitorTypeName.validations && this.state.validationData.monitorTypeName.validations.required)) {
                    this.setState({ monitorTypeError: true });
                } else if (this.state.monitorTypeError) {
                    if ((value === "" && (this.state.validationData.monitorTypeName.validations && this.state.validationData.monitorTypeName.validations.required))) {
                        //skip
                    }
                    else {
                        this.setState({ monitorTypeError: false });
                    }
                };
                break;
            case "label":
                if (!this.state.labelError && value === "" && (this.state.validationData.label.validations && this.state.validationData.label.validations.required)) {
                    this.setState({ labelError: true });
                } else if (!this.state.labelError && (this.state.validationData.label.validations) && (value.length > this.state.validationData.label.validations.maxLength)) {
                    this.setState({ labelError: true });
                } else if (this.state.labelError) {
                    if ((value === "" && (this.state.validationData.label.validations && this.state.validationData.label.validations.required) || ((this.state.validationData.label.validations) && (value.length > this.state.validationData.label.validations.maxLength)))) {
                        //skip
                    }
                    else {
                        this.setState({ labelError: false });
                    }
                };
                break;
            case "notes":
                if (!this.state.notesError && value === "" && (this.state.validationData.notes.validations && this.state.validationData.notes.validations.required)) {
                    this.setState({ notesError: true });
                } else if (!this.state.notesError && (this.state.validationData.notes.validations) && (value.length > this.state.validationData.notes.validations.maxLength)) {
                    this.setState({ notesError: true });
                } else if (this.state.notesError) {
                    if ((value === "" && (this.state.validationData.notes.validations && this.state.validationData.notes.validations.required) || ((this.state.validationData.notes.validations) && (value.length > this.state.validationData.notes.validations.maxLength)))) {
                        //skip
                    }
                    else {
                        this.setState({ notesError: false });
                    }
                };
                break;
            case "specs":
                if (!this.state.specsError && value === "" && (this.state.validationData.specs.validations && this.state.validationData.specs.validations.required)) {
                    this.setState({ specsError: true });
                } else if (!this.state.specsError && (this.state.validationData.specs.validations) && (value.length > this.state.validationData.specs.validations.maxLength)) {
                    this.setState({ specsError: true });
                } else if (this.state.specsError) {
                    if ((value === "" && (this.state.validationData.specs.validations && this.state.validationData.specs.validations.required) || ((this.state.validationData.specs.validations) && (value.length > this.state.validationData.specs.validations.maxLength)))) {
                        //skip
                    }
                    else {
                        this.setState({ specsError: false });
                    }
                };
                break;
            case "orientationCode":
                if (!this.state.orientationError && value === "") {
                    this.setState({ orientationError: true, disableSaveButton: true, orientationErrorMessage: this.props.t("Please enter orientation data for this monitoring location.") });
                } else if (this.state.orientationError) {
                    if (value === "") {
                        //skip
                    }
                    else {
                        this.setState({ orientationError: false, orientationErrorMessage: "" });
                    }
                };
                break;
        }
    }

    render() {
        const { t } = this.props;
        const DialogTitle = withStyles(styles)((props) => {
            const { children, classes, onClose, ...other } = props;
            return (
                <MuiDialogTitle disableTypography className={classes.root} {...other}>
                    <Typography variant="h6">{children}</Typography>
                    {onClose ? (
                        <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
                            <CloseIcon />
                        </IconButton>
                    ) : null}
                </MuiDialogTitle>
            );
        });
        const options = {
            filter: false,
            search: false,
            sort: false,
            print: false,
            download: false,
            viewColumns: false,
            elevation: 0,
            responsive: "scroll",
            selectableRows: "none",
            rowsPerPage: 5,
            rowsPerPageOptions: [5, 10, 50],
            textLabels: {
                body: {
                    noMatch: this.props.t("No matching records")
                },
                pagination: {
                    next: this.props.t("Next Page"),
                    previous: this.props.t("Previous Page"),
                    rowsPerPage: this.props.t("Rows per Page"),
                    displayRows: this.props.t("of")
                },
                toolbar: {
                    search: this.props.t("Search"),
                    download: this.props.t("Download"),
                    print: this.props.t("Print"),
                    viewColumns: this.props.t("View Columns"),
                    filterTable: this.props.t("Filter Table"),
                    upload: this.props.t("Upload"),
                    // download:currentLanguage==="en" ? "Download":"アップロード",
                    showArchivedData: this.props.t("Show Archieved Data"),
                    showActiveData: this.props.t("Show Active Data")


                },
                filter: {
                    title: this.props.t("FILTERS"),
                    reset: this.props.t("Reset")
                },
                viewColumns: {
                    title: this.props.t("Show Columns")
                },
                selectedRows: {
                    text: this.props.t("Reset"),
                    delete: this.props.t("Delete")
                }

            }
        }

        return (
            <Fragment>
                <Card className={this.props.classes.card}>
                    <div className={this.props.classes.actionButtonContainer}>
                        <div className={this.props.classes.backButtonContainer}>
                            <KeyboardBackspace onClick={this.goToBackPage} classes={{ root: this.props.classes.arrowBack }} />
                            <div className={this.props.classes.entityTitle}>{this.getCardHeader()}</div>
                        </div>
                        <div style={{ display: 'flex', alignItems: 'center' }}>
                            <Button variant="contained"
                                className={this.props.classes.saveButton}
                                disabled={this.state.disableSaveButton || this.disableSaveButton()}
                                onClick={this.updateMonitorsData}>{t("Save")}</Button>
                        </div>
                    </div>
                    <CardContent className={this.props.classes.cardContent} classes={{ root: this.props.classes.cardContentRoot }}>
                        {this.state.validationData && this.state.validationData.label && this.state.validationData.label.page_visible ? (
                            <div className={this.props.classes.inputFieldDiv}>
                                <InputLabel className={this.props.classes.textFieldLabel}>
                                    {this.state.validationData.label.display_name}
                                    {(this.state.validationData.label.validations && this.state.validationData.label.validations.required) ? (<span className={this.props.classes.requiredClass}>*</span>) : null}
                                </InputLabel>
                                <TextField
                                    id="label"
                                    name="label"
                                    variant="outlined"
                                    className={this.props.classes.textField}
                                    helperText={(this.state.validationData.label.validations && this.state.validationData.label.validations.helpText) ? (this.state.validationData.label.validations.helpText) : null}
                                    onChange={(event) => this.inputChangeHandler(event, "label")}
                                    value={this.state.label}
                                    error={this.state.labelError}
                                    InputProps={{
                                        classes: {
                                            input: this.props.classes.outlinedInput
                                        }
                                    }}
                                />
                            </div>
                        )
                            : null
                        }
                        {this.state.validationData && this.state.validationData.monitorTypeName && this.state.validationData.monitorTypeName.page_visible ? (
                            <div className={this.props.classes.inputFieldDiv}>
                                <InputLabel className={this.props.classes.textFieldLabel}>
                                    {this.state.validationData.monitorTypeName.display_name}
                                    {(this.state.validationData.monitorTypeName.validations && this.state.validationData.monitorTypeName.validations.required) ? (<span className={this.props.classes.requiredClass}>*</span>) : null}
                                </InputLabel>
                                <Select
                                    native
                                    value={this.state.monitorType}
                                    onChange={(event) => this.inputChangeHandler(event, "monitorTypeId")}
                                    input={
                                        <OutlinedInput
                                            variant="outlined"
                                            name="monitorTypeId"
                                            id="language-native-simple"
                                            labelWidth={this.state.labelWidth}
                                            className={this.props.classes.textField}
                                            error={this.state.monitorTypeError}
                                            classes={{ input: this.props.classes.outlinedInput }}
                                        />
                                    }
                                >
                                    {this.getMonitorType()}
                                </Select>
                            </div>
                        )
                            : null
                        }
                        {this.state.validationData && this.state.validationData.monitorDeviceIdentifier && this.state.validationData.monitorDeviceIdentifier.page_visible ? (
                            <div className={this.props.classes.inputFieldDiv}>
                                <InputLabel className={this.props.classes.textFieldLabel}>
                                    {this.state.validationData.monitorDeviceIdentifier.display_name}
                                    {(this.state.validationData.monitorDeviceIdentifier.validations && this.state.validationData.monitorDeviceIdentifier.validations.required) ? (<span className={this.props.classes.requiredClass}>*</span>) : null}
                                </InputLabel>
                                <Select
                                    native
                                    value={this.state.deviceIdentifier}
                                    onChange={(event) => this.inputChangeHandler(event, "deviceIdentifier")}
                                    input={
                                        <OutlinedInput
                                            variant="outlined"
                                            name="macAddeviceMacAddressdress"
                                            id="language-native-simple"
                                            labelWidth={this.state.labelWidth}
                                            className={this.props.classes.textField}
                                            error={this.state.deviceIdentifierError}
                                            classes={{ input: this.props.classes.outlinedInput }}
                                        />
                                    }
                                >
                                    {this.getDeviceMacAddress()}
                                </Select>
                            </div>
                        )
                            : null
                        }
                        {this.state.validationData && this.state.validationData.machineName && this.state.validationData.machineName.page_visible ? (
                            <div className={this.props.classes.inputFieldDiv}>
                                <InputLabel className={this.props.classes.textFieldLabel}>
                                    {this.state.validationData.machineName.display_name}
                                    {(this.state.validationData.machineName.validations && this.state.validationData.machineName.validations.required) ? (<span className={this.props.classes.requiredClass}>*</span>) : null}
                                </InputLabel>
                                <Select
                                    native
                                    value={this.state.machineId}
                                    onChange={(event) => this.inputChangeHandler(event, "machineId")}
                                    input={
                                        <OutlinedInput
                                            variant="outlined"
                                            name="machineId"
                                            id="language-native-simple"
                                            labelWidth={this.state.labelWidth}
                                            className={this.props.classes.textField}
                                            error={this.state.machineError}
                                            classes={{ input: this.props.classes.outlinedInput }}
                                        />
                                    }
                                >
                                    {this.getMachinesName()}
                                </Select>
                            </div>
                        )
                            : null
                        }
                        {this.state.validationData ? (
                            <div>
                                <div className={this.props.classes.inputFieldDiv}>
                                    <InputLabel className={this.props.classes.textFieldLabel}>
                                        {t("Orientation")} <span className={this.props.classes.requiredClass}>*</span>
                                    </InputLabel>
                                    <Select
                                        native
                                        value={this.state.orientationCode}
                                        onChange={(event) => this.inputChangeHandler(event, "orientationCode")}
                                        input={
                                            <OutlinedInput
                                                variant="outlined"
                                                name="orientation"
                                                id="language-native-simple"
                                                labelWidth={this.state.labelWidth}
                                                className={this.props.classes.textField}
                                                error={this.state.orientationErrorMessage}
                                                helperText={this.state.orientationError ? this.state.orientationErrorMessage : ""}
                                                classes={{ input: this.props.classes.outlinedInput }}
                                            />
                                        }>
                                        {this.getOrientationList()}
                                    </Select>

                                </div>
                                <span className={this.props.classes.InputValidation}> {this.state.orientationErrorMessage}</span>
                            </div>
                        ) : null
                        }
                        <div className={this.props.classes.inputFieldDiv}>
                            <InputLabel className={this.props.classes.textFieldLabel}>
                                {t("Display Position")}
                            </InputLabel>
                            <Select
                                native
                                value={this.state.displayPosition}
                                onChange={(event) => this.inputChangeHandler(event, "displayPosition")}
                                input={
                                    <OutlinedInput
                                        variant="outlined"
                                        name="displayPosition"
                                        id="language-native-simple"
                                        labelWidth={this.state.labelWidth}
                                        className={this.props.classes.textField}
                                        error={this.state.displayPositionError}
                                        classes={{ input: this.props.classes.outlinedInput }}
                                    />
                                }
                            >
                                {this.getDisplayPosition()}
                            </Select>
                        </div>

                        {this.state.validationData && this.state.validationData.notes && this.state.validationData.notes.page_visible ? (
                            <div className={this.props.classes.textareaInputFieldDiv}>
                                <InputLabel className={this.props.classes.textareaFieldLabel}>
                                    {this.state.validationData.notes.display_name}
                                    {(this.state.validationData.notes.validations && this.state.validationData.notes.validations.required) ? (<span className={this.props.classes.requiredClass}>*</span>) : null}
                                </InputLabel>
                                <TextField
                                    id="note"
                                    multiline
                                    rows="6"
                                    value={this.state.notes}
                                    helperText={(this.state.validationData.notes.validations && this.state.validationData.notes.validations.helpText) ? (this.state.validationData.notes.validations.helpText) : null}
                                    variant="outlined"
                                    onChange={(event) => this.inputChangeHandler(event, "notes")}
                                    className={this.props.classes.textArea}
                                    error={this.state.notesError}
                                    InputProps={{
                                        classes: {
                                            multiline: this.props.classes.outlinedInput
                                        }
                                    }}
                                />
                            </div>
                        )
                            : null
                        }

                        <div style={{ clear: "both", minHeight: 200 }}>
                            <Paper>
                                <Tabs
                                    value={this.state.selectedTab}
                                    onChange={this.handleTabChange}
                                    indicatorColor="primary"
                                    textColor="primary"
                                    variant="fullWidth"
                                    TabIndicatorProps={{
                                        classes: {
                                            colorPrimary: this.props.classes.tabIndicator
                                        }
                                    }}
                                    style={{ marginTop: 10, marginBottom: 10 }}
                                >
                                    <Tab label={this.props.t("SPECS")} key={"SPECS"} classes={{ selected: this.props.classes.selectedTab }} />
                                    {/*  <Tab label={"YAML"} key={"YAML"} classes={{ selected: this.props.classes.selectedTab }} />*/}
                                </Tabs>
                            </Paper>
                            {this.state.selectedTab === 0 ? this.getSpecView() : this.getYamlView()}
                        </div>
                    </CardContent>
                </Card >
                <Dialog
                    open={this.state.openDialog}
                    onClose={this.handleCancel}
                    maxWidth={'md'}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <DialogContent className={this.props.classes.content}>
                        {t("If you change the 'Monitoring Location Type' then all advance features and diagnostics will be removed, do you want to continue?")}
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={(event) => this.handleOk()} color="primary" autoFocus>
                            {t("OK")}
                        </Button>
                        <Button onClick={(event) => this.handleCancel()} color="primary" autoFocus>
                            {t("Cancel")}
                        </Button>
                    </DialogActions>
                </Dialog>
                <Dialog
                    open={this.state.openConfirmDialog}
                    onClose={this.handleConfirmCancel}
                    maxWidth={'md'}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <DialogTitle >
                        <span style={{ color: "#ff5722" }}><WarningIcon></WarningIcon> Set Computed Thresholds</span>
                    </DialogTitle>
                    <DialogContent className={this.props.classes.content}>
                        {t("Continuing with the Computed Thresholds will result in deleting old thresholds and cannot be reverted.")}
                        <br /> <br />
                        {t("Do you want to continue?")}
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={(event) => this.setComputedThresholds()} color="primary" autoFocus>
                            {t("SET")}
                        </Button>
                        <Button onClick={(event) => this.handleConfirmCancel()} color="primary" autoFocus>
                            {t("CANCEL")}
                        </Button>
                    </DialogActions>
                </Dialog>

                <Dialog
                    open={this.state.openThresholdNote}
                    onClose={this.handleThresholdNoteCancel}
                    maxWidth={'md'}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <DialogTitle >
                        <span style={{ color: "#ff5722" }}><WarningIcon></WarningIcon> {t("Important Note")}</span>
                    </DialogTitle>
                    <DialogContent className={this.props.classes.content}>
                        • {t("Feature to be used under expert supervision only.")}
                        <br />
                        • {t("Applicable to machines with variable load, variable speed, and machines running above ISO vibration limits and considered  normal by experts.")}
                        <br />
                        • {t("Recommended to have 24 hours working of the equipment under all cycles of variable speed and load.")}
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={(event) => this.openThresholdPopup()} color="primary" autoFocus>
                            {t("Ok I understand.")}
                        </Button>
                    </DialogActions>
                </Dialog>


                <Dialog
                    open={this.state.openThresholdsDialog}
                    onClose={this.handleThresholdsClose}
                    // className={this.props.classes.dialogbox}
                    maxWidth={'lg'}
                    minWidth={'md'}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <DialogTitle id="customized-dialog-title" onClose={this.handleThresholdsClose}>
                        {t("Computed Thresholds")}
                    </DialogTitle>
                    <Divider></Divider>

                    <DialogContent className={this.props.classes.content} style={{ marginTop: "10px" }}>
                        <div>
                            {
                                this.state.lastThresholdAssistant !== "" && this.state.lastThresholdAssistant ?
                                    (
                                        <>
                                            <div className={"row col-sm-12 " + this.props.classes.periodDiv} style={{ margin: "0" }}>
                                                <div className="col-sm-5" style={{ padding: "0" }}><span style={{ fontWeight: "500", padding: "0" }}>Threshold Calibration Period: </span></div>
                                                <div className="col-sm-7" style={{ padding: "0" }}>
                                                    {this.state.lastThresholdAssistant.startTime && this.state.lastThresholdAssistant.endTime ?
                                                        (<>
                                                            <div className="col-sm-12" style={{ padding: "0" }}><span style={{ fontWeight: "500" }}>{t("From")}: </span>{moment(this.state.lastThresholdAssistant.startTime).local().toString()} </div>
                                                            <div className="col-sm-12" style={{ padding: "0" }}><span style={{ fontWeight: "500" }}>{t("To")}: </span>{moment(this.state.lastThresholdAssistant.endTime).local().toString()} </div>
                                                            {/* moment(this.state.lastThresholdAssistant.endTime).format().replace("T", " ").replace("+", " GMT+") */}
                                                        </>
                                                        )
                                                        : "-"}
                                                </div>
                                            </div>
                                            <MuiThemeProvider theme={this.getMuiTheme()}>
                                                {
                                                    this.state.data !== null ? (
                                                        <MUIDataTable
                                                            data={this.state.data}
                                                            columns={this.state.columns}
                                                            options={options}
                                                        />
                                                    ) : <Fragment></Fragment>
                                                }
                                            </MuiThemeProvider>
                                        </>
                                    ) : (
                                        this.state.isComputeThreshold ?
                                            (<div style={{ color: "black" }}>
                                                {t("Computing thresholds")}...
                                            </div>
                                            ) :
                                            (
                                                <div>
                                                    {t("No data to display, please click on the COMPUTE/RECOMPUTE THRESHOLDS button to compute thresholds.")}
                                                </div>
                                            )
                                    )
                            }

                        </div>
                    </DialogContent>
                    <DialogActions>
                        <Button disabled={this.state.isComputeThreshold} className={this.props.classes.computeButton} onClick={(event) => this.computeThresholds()} >
                            {t("COMPUTE/RECOMPUTE THRESHOLDS")}
                        </Button>
                        <Button disabled={this.state.orientationError || this.state.isComputeThreshold || this.state.lastThresholdAssistant === "" || this.state.lastThresholdAssistant === null || this.state.lastThresholdAssistant === undefined || this.state.lastThresholdAssistant === {}} className={this.props.classes.thresholdButton} onClick={(event) => this.handleOpenConfirmDialog()} color="primary">
                            {t("SET COMPUTED THRESHOLDS")}
                        </Button>
                    </DialogActions>
                </Dialog>
            </Fragment >
        );
    }
}

const mapStateToProps = (state) => {
    return {
        staticData: state.staticDataReducer,
        devicesData: state.deviceDataReducer,
        machinesData: state.machinesDataReducer,
        validationData: state.viewDefinitionReducer,
        jsonData: state.constructJsonReducer,
        metadata: state.metadataReducer,
        faultList: state.getFaultReducer,
        bearingData: state.bearingDataReducer,
        monitorCount: state.monitorCountReducer,
        thresholds: state.getThresholdsReducer
    }
}

const mapDispatchToProps = dispatch => {
    return ({
        getTableData: (selectedNavtab, currentScopeSelectionId, setToStore, showArchivedData, deviceIdentifier) => (dispatch(actionCreators.getTableData(selectedNavtab, currentScopeSelectionId, setToStore, showArchivedData, deviceIdentifier))),
        updateMonitorsEntity: (monitorsEntityData, id) => (dispatch(actionCreators.updateMonitorsEntity(monitorsEntityData, id))),
        updateSelectedTab: (selectedTab) => (dispatch(actionCreators.updateSelectedTab(selectedTab))),
        getJsonFromYaml: (yamlRequestObject) => (dispatch(actionCreators.getJsonFromYaml(yamlRequestObject))),
        getDefaultSpecs: (machineId, monitorTypeId, monitorId) => (dispatch(actionCreators.getDefaultSpecs(machineId, monitorTypeId, monitorId))),
        getYamlFromJson: (jsonRequestObject) => (dispatch(actionCreators.getYamlFromJson(jsonRequestObject))),
        addNewCondition: (object) => (dispatch(actionCreators.addNewCondition(object))),
        getMonitorSpecs: (monitorsEntityData) => (dispatch(actionCreators.getMonitorSpecs(monitorsEntityData))),
        getYamlFaults: (monitorsEntityData) => (dispatch(actionCreators.getYamlFaults(monitorsEntityData))),
        getFaultSpecs: (monitorsEntityData, cb) => (dispatch(actionCreators.getFaultSpecs(monitorsEntityData, cb))),
        getAlertMessage: (message) => (dispatch(actionCreators.getAlertMessage(message))),
        updateFaultSpecs: (monitorsEntityData, cb) => (dispatch(actionCreators.updateFaultSpecs(monitorsEntityData, cb))),
        getFaultList: () => (dispatch(actionCreators.getFaultList())),
        getFaultSpecsForMonitor: (bearingTypeNumber) => (dispatch(actionCreators.getFaultSpecsForMonitor(bearingTypeNumber))),
        getMonitorsCountByMachineId: (machineId) => (dispatch(actionCreators.getMonitorsCountByMachineId(machineId))),
        getThresholds: (monitorId) => (dispatch(actionCreators.getThresholds(monitorId)))

    })
}

export default withTranslation()(connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(MonitorsEntity)));

