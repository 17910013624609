import React, { Component } from 'react';
import Highcharts from 'highcharts';
import {
    Link,
    Dialog,
    Typography,
    Tooltip,
    DialogContent,
    Divider,
    DialogActions,
    Button,
    TableRow,
    TableCell,
    CircularProgress,
} from '@material-ui/core';
import { Close, ChevronRight, ExpandMore } from '@material-ui/icons';
import RefreshIcon from '@material-ui/icons/Refresh';
import { withStyles } from '@material-ui/core';
import { createMuiTheme, MuiThemeProvider } from '@material-ui/core/styles';
import MUIDataTable from "mui-datatables";
import Brightness1Icon from '@material-ui/icons/Brightness1';
import HelpIcon from '@material-ui/icons/Help';
import { withTranslation } from "react-i18next";
import IconButton from '@material-ui/core/IconButton';
import { config, IDAP_SSO_DOMAIN_NAME } from '../../../configuration';
import MonitorsWithStateCount from "../../MonitorsWithStateCount"
import moment from "moment";
import { getSynDisplayValue } from '../../../utils';
import { inRange, isEqual } from "lodash";

const SYN_PRIORITY_LABEL_MAPPING = {
    1: "Low Priority",
    2: "Medium Priority",
    3: "High Priority"
};

let relible = require("../../../assets/images/relibleButton.png");
let countMachine = 0, countPie = 0;
const clonedeep = require('lodash.clonedeep')
const styles = theme => ({
    loaderColor: {
        color: '#2abbe8'
    },
    dialogTitle: {
        display: 'flex',
        'justify-content': 'space-between',
        'align-items': 'center',
        padding: '10px 24px'
    },
    dialogContent: {
        padding: '0 24px'
    },

    loaderColor: {
        color: '#2abbe8'
    },

});
const statusColors = {
    1: "#64dd17",
    2: "#ffc107",
    3: "#ff5722"
}


const colors = {
    1: "#31ccec",
    2: "#64dd17",
    3: "#ffc107",
    4: "#ff5722",
    0: "#9e9e9e",
    5: "#9e9e9e",
    6: "#800080",
}

const chartOptions = {
    chart: {
        type: 'column',
        height: 150,
    },
    colors: ['#008000', '#FFFF00', '#FF0000', '#FFFFFF'],
    title: {
        text: ''
    },
    exporting: {
        enabled: false
    },
    credits: {
        enabled: false
    },
    tooltip: {
        headerFormat: '<b>{point.x}</b><br/>',
        pointFormat: '<span style="color:#393333;font-size:10px">{series.name}</span>: <b>{point.y}</b><br/>',
        shared: true,
        outside: true,
    },
    xAxis: {
        categories: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
        labels: {
            rotation: -70
        }
    },
    yAxis: {
        min: 1,
        max: 100,
        visible: false,
        className: 'highchartSummaryPercentage',
        title: {
            text: ''
        }
    },
    plotOptions: {
        column: {
            stacking: 'normal',
            borderColor: "#393333",
            dataLabels: {
                enabled: false
            }
        }
    },
    series: []
}

export class SummaryPanel extends Component {
    constructor(props) {
        super(props);
        this.state = {
            chartOptions: chartOptions,
            top: false,
            left: false,
            bottom: false,
            right: false,
            data: 0,
            title: "",
            columns: [],
            tabledata: null,
            noFlag: false,
            isPieFilter: false,
            filterData: [],
            filter: null,
            options: {rowsPerPage:20},
            openDialog: false,
            record: {},
            openFlag: {},
            odrEnable: true,
            fftEnable: true,
            monitorCountFlag: false,
            monitorCountData: [],
            searchText: null,
            series: [{
                name: this.props.t('Index'),
                data: [
                    {
                        name: this.props.t("Idle"),
                        y: 8,
                        color: '#31ccec',
                        bg: 'rgb(49, 204, 236,0.1)',
                        id: "idle"
                    },
                    {
                        name: this.props.t("Operational"),
                        y: 7,
                        color: '#64dd17',
                        bg: 'rgb(100, 221, 23,0.1)',
                        id: "operational"
                    },
                    {
                        name: this.props.t("Caution"),
                        y: 25,
                        color: '#ffc107',
                        bg: 'rgb(255, 193, 7,0.1)',
                        id: "caution"
                    },
                    {
                        name: this.props.t("Warning"),
                        y: 17,
                        color: '#ff5722',
                        bg: 'rgb(255, 87, 34,0.1)',
                        id: "warning"
                    },
                    {
                        name: this.props.t("Disconnected"),
                        y: 0,
                        color: '#9e9e9e',
                        bg: 'rgb(62, 62, 62,0.1)',
                        id: "disconnected"
                    },
                    {
                        name: this.props.t("System Fault"),
                        y: 0,
                        color: '#800080',
                        bg: '#fae1fa',
                        id: "faulty"
                    }
                ]
            }],
            optionsC: {
                filter: false,
                search: true,
                sort: true,
                print: false,
                download: false,
                viewColumns: false,
                elevation: 0,
                responsive: "scroll",
                selectableRows: "none",
                rowsPerPage: 20,
                rowsPerPageOptions: [20, 50, 100],
                expandableRows: true,// Try Adding This
                rowsExpanded: [],
                onRowClick: this.onRowClick,
                onSearchChange: this.onSearch,
                renderExpandableRow: this.rowExpand,
                onTableChange: this.handleTableChange.bind(this),
                textLabels: {
                    body: {
                        noMatch: this.props.t("No matching records"),
                        toolTip: this.props.t("Sort"),
                    },
                    pagination: {
                        next: this.props.t("Next Page"),
                        previous: this.props.t("Previous Page"),
                        rowsPerPage: this.props.t("Rows per Page"),
                        displayRows: this.props.t("of")
                    },
                    toolbar: {
                        search: this.props.t("Search"),
                        download: this.props.t("Download"),
                        print: this.props.t("Print"),
                        viewColumns: this.props.t("View Columns"),
                        filterTable: this.props.t("Filter Table"),
                        upload: this.props.t("Upload"),
                        // download:currentLanguage==="en" ? "Download":"アップロード",
                        showArchivedData: this.props.t("Show Archieved Data"),
                        showActiveData: this.props.t("Show Active Data")
    
    
                    },
                    filter: {
                        title: this.props.t("FILTERS"),
                        reset: this.props.t("Reset")
                    },
                    viewColumns: {
                        title: this.props.t("Show Columns")
                    },
                    selectedRows: {
                        text: this.props.t("Reset"),
                        delete: this.props.t("Delete")
                    }
    
                }
            },
            lang: ''

        }

        this.chart = React.createRef();
    }

    getMuiTheme = () => createMuiTheme({
        overrides: {
            MUIDataTableHeadCell: {

                fixedHeader: {
                    'z-index': 0
                },
                hintIconAlone: {
                    fontSize: "12px",
                    marginLeft: "1px !important"
                },
                hintIconWithSortIcon: {
                    fontSize: "12px",
                    marginLeft: "-5px !important"
                },
                toolButton: {
                    display: "-webkit-inline-box !important",
                    // marginLeft: '-14px !important',
                    width: "156px"
                },
                sortActive: {
                    width: "100px"
                }
            },
            MUIDataTableFilter: {
                root: {
                    'width': '382px !important'
                }
            },

            MUIDataTableSelectCell: {
                "icon": {
                    // Soft hide the button.
                    color: '#1665c0'
                },
                fixedHeaderYAxis: {
                    width: '15px'
                },
            },

            typography: {
                useNextVariants: true,
            },
            MUIDataTable: {
                responsiveScroll: {
                    // width: '990px',
                    height: 'calc(85vh - 150px)',
                    'max-height': 'calc(85vh - 150px) !important',
                    'z-index': '0'
                }
            },
            MuiTableCell: {
                root: {
                    "padding-right": "0px !important"
                },
                head: {
                    fontSize: 14,
                    fontWeight: 800,
                    letterSpacing: 0.25
                },
                body: {
                    fontSize: 14,
                    letterSpacing: 0.25,
                    width: 50,

                }
            }
        }
    })

    handleTableChange(action, tableState) {
        let _searchText = (this.state && this.state.searchText) || null;
        
        if (action === "search")
          _searchText = tableState.searchText;
        else if (action === "propsUpdate" && _searchText) 
          tableState.searchText = _searchText;
        else if (action === "onSearchClose") _searchText = null;

        if (this.state && _searchText !== (this.state.searchText || null))
          this.setState({ searchText: _searchText }, () => {
            this.optionsCall(this.state.searchText);
          });
    }

    highChartsRender(series) {
        let that = this;
        Highcharts.chart({
            chart: {
                type: 'pie',
                renderTo: 'piePanel',
                // width: '200px',
                height: '350px'

            },
            title: {
                verticalAlign: 'middle',
                floating: true,
                text: '',
                style: {
                    fontSize: '10px',
                }
            },
            credits: {
                "enabled": false
            },
            exporting: {
                "enabled": false
            },
            plotOptions: {
                pie: {
                    // dataLabels: {
                    //   format: '{point.name}: {point.percentage:.1f} %'
                    // },
                    dataLabels: {
                        enabled: false
                    },
                    showInLegend: false,
                    size: '200',
                    // innerSize: '45%'
                },
                series: {
                    point: {
                        events: {
                            click: function () {
                                that.onClickPieChart(this.id)
                            }
                        }
                    }
                }
            },
            series: series
        });
    }

    rowExpand = (rowData) => {
        let monitorMap = this.state.tabledata && this.state.tabledata.filter(monitor => monitor.id === rowData[0])
        let check = true, obs, obs_txt = '', rec, recommend_txt = '', diag, diagnostic_txt = '';
        if (monitorMap[0] && monitorMap[0].plantStatus && monitorMap[0].plantStatus === "COMPLETED") {
            check = monitorMap[0].hours >= 24;
        }
        if (monitorMap[0] && monitorMap[0].observation) {
            obs = monitorMap[0] && monitorMap[0].observation.split('.');
            if (obs.length !== 0) {
                for (var i = 0; i < obs.length; i++) {
                    obs_txt += obs[0] ? this.props.t((obs[i] + ".")) : this.props.t((obs[i]));
                }
            }
        }
        if (monitorMap[0] && monitorMap[0].diagnostic) {
            diag = monitorMap[0] && monitorMap[0].diagnostic.split('.');
            if (diag.length !== 0) {
                for (var i = 0; i < diag.length; i++) {
                    diagnostic_txt += diag[0] ? this.props.t((diag[i] + ".")) : this.props.t((diag[i]));
                }
            }
        }
        if (monitorMap[0] && monitorMap[0].recommendation) {
            rec = monitorMap[0] && monitorMap[0].recommendation.split('.');
            if (rec.length !== 0) {
                for (var i = 0; i < rec.length; i++) {
                    recommend_txt += rec[0] ? this.props.t((rec[i] + ".")) : this.props.t((rec[i]));
                }
            }
        }

        let monitorMapData = (
            <>
              {this.state.odrEnable && (
                <>
                  <TableRow>
                    <TableCell colSpan="2" style={{ fontSize: "14px", fontWeight: "bold", color: "rgba(0, 0, 0, 0.54)" }}>
                      {this.props.t("Observation")}
                    </TableCell>
                    <TableCell colSpan="2" style={{ fontSize: "13px", fontWeight: "bold", color: "rgba(0, 0, 0, 0.54)" }}>
                      {this.props.t("Diagnostic")}
                    </TableCell>
                    <TableCell colSpan="2" style={{ fontSize: "13px", fontWeight: "bold", color: "rgba(0, 0, 0, 0.54)" }}>
                      {this.props.t("Recommendation")}
                    </TableCell>
                    <TableCell colSpan="2"></TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell colSpan="2" style={{ wordBreak: "break-word" }}>
                      {monitorMap[0] && monitorMap[0].observation ? this.props.t(obs_txt) : "-"}
                    </TableCell>
                    <TableCell colSpan="2" style={{ wordBreak: "break-word" }}>
                      {monitorMap[0] && monitorMap[0].diagnostic ? this.props.t(diagnostic_txt) : "-"}
                    </TableCell>
                    <TableCell colSpan="2" style={{ wordBreak: "break-word" }}>
                      {monitorMap[0] && monitorMap[0].recommendation ? this.props.t(recommend_txt) : "-"}
                    </TableCell>
                    <TableCell colSpan="2"></TableCell>
                  </TableRow>
                </>
              )}

                {monitorMap[0] &&
                monitorMap[0].monitors.map((item, index) => {
                    const healthScoreText = inRange(item.healthScore, 1, 101) ? `${item.healthScore}%` : 'N.A';

                    return (
                    <>
                        <TableRow key={index}>
                        <TableCell></TableCell>
                        <TableCell style={{ display: "table-cell", width: "190px" }}>
                            {this.state.odrEnable === true &&
                            monitorMap[0] &&
                            monitorMap[0].plantStatus !== "INPROGRESS" &&
                            monitorMap[0].plantStatus !== "NEW" &&
                            monitorMap[0].plantStatus !== "ACTION PENDING" &&
                            check ? (
                                <IconButton
                                style={{ textAlign: "left", marginLeft: "-60px", marginTop: "-5px" }}
                                aria-label="expand row"
                                size="small"
                                onClick={() => this.expandMoreTable(item.monitorId)}
                                >
                                {this.state.openFlag[item.monitorId] ? <ExpandMore /> : <ChevronRight />}
                                </IconButton>
                            ) : (
                                <div
                                style={{ textAlign: "left", marginLeft: "-41px", padding: "10px", marginTop: "20px" }}
                                ></div>
                            )}
                            <div
                            onClick={() => this.loadMonitoringDashboard(item)}
                            style={{
                                paddingTop: "11px",
                                paddingLeft: "11px",
                                marginTop: "-45px",
                                marginLeft: "-9px",
                                color: "#1664c0",
                                cursor: "pointer",
                            }}
                            >
                            {item.monitorName}
                            </div>
                        </TableCell>

                        {this.state.odrEnable && (
                            <TableCell>
                            <div style={{ marginLeft: "30px" }}>
                                <span>{healthScoreText}</span>
                                <Tooltip title={this.props.t(item.superscriptMessage || "")} placement="right">
                                <sup style={{ marginLeft: "5px", color: "red" }}>{item.superscript || ""}</sup>
                                </Tooltip>
                            </div>
                            </TableCell>
                        )}

                        <TableCell>
                            <span style={{ marginLeft: "12px" }}>
                            {item.trend === 3
                                ? "Significant rise"
                                : item.trend === 2
                                ? "Minor rise"
                                : item.trend === 1
                                ? "No rise"
                                : "-"}
                            </span>
                        </TableCell>
                        <TableCell>
                            <Brightness1Icon
                            style={{ fontSize: "18", marginLeft: "30px", color: colors[item.status] }}
                            />
                        </TableCell>
                        <TableCell>
                            <span style={{ textAlign: "center" }}>
                            {item.healthTrend === 3
                                ? this.props.t("Deteriorating fast")
                                : item.healthTrend === 2
                                ? this.props.t("Deteriorating gradually")
                                : item.healthTrend === 1
                                ? this.props.t("Stable")
                                : "-"}
                            </span>
                        </TableCell>
                        <TableCell>
                            <span>{this.subscription(item)}</span>
                        </TableCell>
                        </TableRow>

                        {this.state.openFlag[item.monitorId] && (
                        <>
                            <TableRow>
                            <TableCell colSpan="2" style={{ fontSize: "14px", fontWeight: "bold", color: "rgba(0, 0, 0, 0.54)" }}>
                                {this.props.t("Observation")}
                            </TableCell>
                            <TableCell colSpan="2" style={{ fontSize: "13px", fontWeight: "bold", color: "rgba(0, 0, 0, 0.54)" }}>
                                {this.props.t("Diagnostic")}
                            </TableCell>
                            <TableCell colSpan="2" style={{ fontSize: "13px", fontWeight: "bold", color: "rgba(0, 0, 0, 0.54)" }}>
                                {this.props.t("Recommendation")}
                            </TableCell>
                            <TableCell colSpan="2"></TableCell>
                            </TableRow>
                            <TableRow style={{ borderBottom: "2px solid #b6b6b6" }}>
                            <TableCell colSpan="2" style={{ wordBreak: "break-word" }}>
                                {item.observation ? this.props.t(item.observation) : "-"}
                            </TableCell>
                            <TableCell colSpan="2" style={{ wordBreak: "break-word" }}>
                                {item.diagnostic ? this.props.t(item.diagnostic) : "-"}
                            </TableCell>
                            <TableCell colSpan="2" style={{ wordBreak: "break-word" }}>
                                {item.recommendation ? this.props.t(item.recommendation) : "-"}
                            </TableCell>
                            <TableCell colSpan="2"></TableCell>
                            </TableRow>
                        </>
                        )}
                    </>
                    );
                })}
            </>
        );
          
        return monitorMapData;
    }

    expandMoreTable = (id) => {
        this.setState({
            openFlag: {
                ...this.state.openFlag,
                [id]: !this.state.openFlag[id]
            }
        })
    }
    subscription = (item) => {
        var today = new Date().getTime();
        var status = (today > item.subscriptionStart && today < item.subscriptionEnd && ((item.subscriptionEnd - today) / (1000 * 3600 * 24)) > 30) ? 1 :
            ((today > item.subscriptionStart && today < item.subscriptionEnd && ((item.subscriptionEnd - today) / (1000 * 3600 * 24)) < 30 ? 2 : 3))
        var subStart = item.subscriptionStart ? new Date(item.subscriptionStart).getDate() + "/" + (new Date(item.subscriptionStart).getMonth() + 1) + "/" + new Date(item.subscriptionStart).getFullYear() : null;
        var subEnd = item.subscriptionEnd ? new Date(item.subscriptionEnd).getDate() + "/" + (new Date(item.subscriptionEnd).getMonth() + 1) + "/" + new Date(item.subscriptionEnd).getFullYear() : null;
        var subscriptionRemain = status === 1 || status === 2 ? Math.ceil((item.subscriptionEnd - today) / (1000 * 3600 * 24)) : 0;
        item.subscriptionStatus = <Tooltip title={subStart && subEnd ? this.props.t("Subscription Start") + ": " + subStart + ' &' + this.props.t("Subscription End") + ': ' + subEnd : "-"} aria-label="status"><span>{(subscriptionRemain===0?this.props.t(subscriptionRemain+" "+"Days") :subscriptionRemain+ " " + this.props.t("Days"))}<Brightness1Icon style={{ fontSize: "18", marginLeft: "2px", color: statusColors[status] }} /></span></Tooltip>
        return item.subscriptionStatus

    }
    capitalize = (str) => {
        return str.charAt(0).toUpperCase() + str.slice(1);
    }
    onClickLegendChart(name) {
        let title = this.capitalize(name)
        // this.setState({ monitorCountFlag: true, title })
        var data = [];
        switch (name) {
            case "idle":
                if (this.state.filter !== name) {
                    data = this.props.summaryData && this.props.summaryData.summaryData && this.props.summaryData.summaryData.machines ? this.props.summaryData.summaryData.machines.filter(item => item.status === 1) : [];
                } else {
                    data = this.props.summaryData && this.props.summaryData.summaryData && this.props.summaryData.summaryData.machines ? this.props.summaryData.summaryData.machines : [];
                }
                break;
            case "operational":
                if (this.state.filter !== name) {
                    data = this.props.summaryData && this.props.summaryData.summaryData && this.props.summaryData.summaryData.machines ? this.props.summaryData.summaryData.machines.filter(item => item.status === 2) : [];
                } else {
                    data = this.props.summaryData && this.props.summaryData.summaryData && this.props.summaryData.summaryData.machines ? this.props.summaryData.summaryData.machines : [];
                }
                break;
            case "caution":
                if (this.state.filter !== name) {
                    data = this.props.summaryData && this.props.summaryData.summaryData && this.props.summaryData.summaryData.machines ? this.props.summaryData.summaryData.machines.filter(item => item.status === 3) : [];
                } else {
                    data = this.props.summaryData && this.props.summaryData.summaryData && this.props.summaryData.summaryData.machines ? this.props.summaryData.summaryData.machines : [];
                }
                break;
            case "warning":
                if (this.state.filter !== name) {
                    data = this.props.summaryData && this.props.summaryData.summaryData && this.props.summaryData.summaryData.machines ? this.props.summaryData.summaryData.machines.filter(item => item.status === 4) : [];
                } else {
                    data = this.props.summaryData && this.props.summaryData.summaryData && this.props.summaryData.summaryData.machines ? this.props.summaryData.summaryData.machines : [];
                }
                break;
            case "disconnected":
                if (this.state.filter !== name) {
                    data = this.props.summaryData && this.props.summaryData.summaryData && this.props.summaryData.summaryData.machines ? this.props.summaryData.summaryData.machines.filter(item => (parseInt(item.status) === 0 || parseInt(item.status) === 5)) : [];
                } else {
                    data = this.props.summaryData && this.props.summaryData.summaryData && this.props.summaryData.summaryData.machines ? this.props.summaryData.summaryData.machines : [];
                }
                break;
            case "faulty":
                if (this.state.filter !== name) {
                    data = this.props.summaryData && this.props.summaryData.summaryData && this.props.summaryData.summaryData.machines ? this.props.summaryData.summaryData.machines.filter(item => item.status === 6) : [];
                } else {
                    data = this.props.summaryData && this.props.summaryData.summaryData && this.props.summaryData.summaryData.machines ? this.props.summaryData.summaryData.machines : [];
                }
                break;
        }
        this.setState({ monitorCountData: data })
    }
    onClickPieChart(name) {
        document.cookie = `idap-machineName-${config.url.env}=null` + "; domain=" + `${IDAP_SSO_DOMAIN_NAME}; secure`;
        this.setState({ searchText: "" }, () => {
            this.optionsCall(this.state.searchText);
        })
        var data = [];
        switch (name) {
            case "idle":
                if (this.state.filter !== name) {
                    data = this.props.summaryData && this.props.summaryData.summaryData && this.props.summaryData.summaryData.machines ? this.props.summaryData.summaryData.machines.filter(item => item.status === 1) : [];
                } else {
                    data = this.props.summaryData && this.props.summaryData.summaryData && this.props.summaryData.summaryData.machines ? this.props.summaryData.summaryData.machines : [];
                }
                break;
            case "operational":
                if (this.state.filter !== name) {
                    data = this.props.summaryData && this.props.summaryData.summaryData && this.props.summaryData.summaryData.machines ? this.props.summaryData.summaryData.machines.filter(item => item.status === 2) : [];
                } else {
                    data = this.props.summaryData && this.props.summaryData.summaryData && this.props.summaryData.summaryData.machines ? this.props.summaryData.summaryData.machines : [];
                }
                break;
            case "caution":
                if (this.state.filter !== name) {
                    data = this.props.summaryData && this.props.summaryData.summaryData && this.props.summaryData.summaryData.machines ? this.props.summaryData.summaryData.machines.filter(item => item.status === 3) : [];
                } else {
                    data = this.props.summaryData && this.props.summaryData.summaryData && this.props.summaryData.summaryData.machines ? this.props.summaryData.summaryData.machines : [];
                }
                break;
            case "warning":
                if (this.state.filter !== name) {
                    data = this.props.summaryData && this.props.summaryData.summaryData && this.props.summaryData.summaryData.machines ? this.props.summaryData.summaryData.machines.filter(item => item.status === 4) : [];
                } else {
                    data = this.props.summaryData && this.props.summaryData.summaryData && this.props.summaryData.summaryData.machines ? this.props.summaryData.summaryData.machines : [];
                }
                break;
            case "disconnected":
                if (this.state.filter !== name) {
                    data = this.props.summaryData && this.props.summaryData.summaryData && this.props.summaryData.summaryData.machines ? this.props.summaryData.summaryData.machines.filter(item => (parseInt(item.status) === 0) || parseInt(item.status) === 5) : [];
                } else {
                    data = this.props.summaryData && this.props.summaryData.summaryData && this.props.summaryData.summaryData.machines ? this.props.summaryData.summaryData.machines : [];
                }
                break;
            case "faulty":
                if (this.state.filter !== name) {
                    data = this.props.summaryData && this.props.summaryData.summaryData && this.props.summaryData.summaryData.machines ? this.props.summaryData.summaryData.machines.filter(item => item.status === 6) : [];
                } else {
                    data = this.props.summaryData && this.props.summaryData.summaryData && this.props.summaryData.summaryData.machines ? this.props.summaryData.summaryData.machines : [];
                }
                break;
        }
        this.setState({ filterData: data, isPieFilter: true, filter: this.state.filter === name ? null : name }, () => {
            this.tableConfigurations();
        })
    }
    componentDidMount() {
        countPie = 0;
        this.setState({ lang: this.props && this.props.props.i18n.language })
        if (this.props.searchText) {
            this.optionsCall(this.props.searchText)
            this.setState({ searchText: this.props.searchText })
        }
        let plantView = localStorage.getItem("PlantView")
        let plantViewArray = JSON.parse(plantView);

        if ((this.props && this.props.selectedScopeData === undefined) && (plantViewArray === null)) {
            this.setState({ noFlag: true })
        }
        let selectedOrg = this.props.props.userData && this.props.props.userData.selectedOrganization;
        if (selectedOrg == undefined) {
            let rootOrganization = this.props.props.userData.scopeSelector.subOrganizations.find((organization) => (
                organization.root
            ))
            selectedOrg = rootOrganization.id;
        }
        let odrFftFlag = this.props.props.userData && this.props.props.userData.scopeSelector.subOrganizations.find(ele => ele.id === selectedOrg);
        if (odrFftFlag !== undefined) {
            this.setState({
                odrEnable: odrFftFlag.odrEnable,
                fftEnable: odrFftFlag.fftWaveformEnable
            })
        }

    }
    getCookie(cname) {
        let name = cname + "=";
        let decodedCookie = decodeURIComponent(document.cookie);
        let ca = decodedCookie.split(';');
        for (let i = 0; i < ca.length; i++) {
            let c = ca[i];
            while (c.charAt(0) == ' ') {
                c = c.substring(1);
            }
            if (c.indexOf(name) == 0) {
                return c.substring(name.length, c.length);
            }
        }
        return "";
    }

    componentDidUpdate(prevProps, prevState) {
        let machineNameFromIU = this.getCookie(`idap-machineName-${config.url.env}`);

        if (machineNameFromIU && machineNameFromIU !== "null" && machineNameFromIU !== "" && countMachine < 3) {
            countMachine++;
            localStorage.setItem("dashboard", "summary");
            this.optionsCall(machineNameFromIU);
            this.setState({ searchText: machineNameFromIU })
        }
        if (prevProps.props.i18n.language !== prevState.lang) {
            this.setState({ lang: this.props.props.i18n.language }, () => {
                this.optionsCall(this.state.searchText)
            });

            this.tableConfigurations();
        }
        if (
          !isEqual(
            prevProps.summaryData.summaryData,
            this.props.summaryData.summaryData
          )
        ) {
          if (
            this.props.summaryData &&
            this.props.summaryData.summaryData &&
            this.props.summaryData.summaryData.machines
          ) {
            var summarydata = [...this.props.summaryData.summaryData.machines];
            this.setState(
              {
                filterData: summarydata,
                isPieFilter: false,
                filter: null,
                noFlag: false,
              },
              () => {
                this.tableConfigurations();
              }
            );
          }
        }
        if (!isEqual(prevProps.summaryData.summaryData, this.props.summaryData.summaryData) && countPie < 2) {
            countPie++;
            if (this.chart && this.chart.current && this.chart.current.container && this.chart.current.container.current) {
                const container = this.chart.current.container.current;
                container.style.height = "100%";
                container.style.width = "100%";
                this.chart.current.chart.reflow();
            }

            this.setState({ options: clonedeep(this.state.optionsC) })
            const idle = this.props.summaryData && this.props.summaryData.summaryData && this.props.summaryData.summaryData.machines ? this.props.summaryData.summaryData.machines.filter(item => item.status === 1) : [];
            const operational = this.props.summaryData && this.props.summaryData.summaryData && this.props.summaryData.summaryData.machines ? this.props.summaryData.summaryData.machines.filter(item => item.status === 2) : [];
            const caution = this.props.summaryData && this.props.summaryData.summaryData && this.props.summaryData.summaryData.machines ? this.props.summaryData.summaryData.machines.filter(item => item.status === 3) : [];
            const warning = this.props.summaryData && this.props.summaryData.summaryData && this.props.summaryData.summaryData.machines ? this.props.summaryData.summaryData.machines.filter(item => item.status === 4) : [];
            const disconnected = this.props.summaryData && this.props.summaryData.summaryData && this.props.summaryData.summaryData.machines ? this.props.summaryData.summaryData.machines.filter(item => parseInt(item.status) === 5) : [];
            const faulty = this.props.summaryData && this.props.summaryData.summaryData && this.props.summaryData.summaryData.machines ? this.props.summaryData.summaryData.machines.filter(item => parseInt(item.status) === 6) : [];

            let idleCount = 0, operationalCount = 0, cautionCount = 0, warnigCount = 0, disCount = 0, faultyCount = 0;
            idle && idle.map((item, key) => { idleCount = idleCount + item.monitors.length; return idleCount })
            operational && operational.map((item, key) => { operationalCount = operationalCount + item.monitors.length; return operationalCount })
            caution && caution.map((item, key) => { cautionCount = cautionCount + item.monitors.length; return cautionCount })
            warning && warning.map((item, key) => { warnigCount = warnigCount + item.monitors.length; return warnigCount })
            disconnected && disconnected.map((item, key) => { disCount = disCount + item.monitors.length; return disCount })
            faulty && faulty.map((item, key) => { faultyCount = faultyCount + item.monitors.length; return faultyCount })

            let series = [{
                name: this.props.t('Index'),
                allowPointSelect: true,
                data: [
                    {
                        name: this.props.t("Idle"),
                        y: idle ? idle.length : 0,
                        color: '#31ccec',
                        bg: 'rgb(49, 204, 236,0.1)',
                        id: "idle",
                    },
                    {
                        name: this.props.t("Operational"),
                        y: operational ? operational.length : 0,
                        color: '#64dd17',
                        bg: 'rgb(100, 221, 23,0.1)',
                        id: "operational"
                    },
                    {
                        name: this.props.t("Caution"),
                        y: caution ? caution.length : 0,
                        color: '#ffc107',
                        bg: 'rgb(255, 193, 7,0.1)',
                        id: "caution"
                    },
                    {
                        name: this.props.t("Warning"),
                        y: warning ? warning.length : 0,
                        color: '#ff5722',
                        bg: 'rgb(255, 87, 34,0.1)',
                        id: "warning"
                    },
                    {
                        name: this.props.t("Disconnected"),
                        y: disconnected ? disconnected.length : 0,
                        color: '#9e9e9e',
                        bg: 'rgb(62, 62, 62,0.1)',
                        id: "disconnected"
                    },
                    {
                        name: this.props.t("System Fault"),
                        y: faulty ? faulty.length : 0,
                        color: '#800080',
                        bg: '#fae1fa',
                        id: "faulty"
                    }
                ]
            }];

            const filteredSeries = [
                {
                    ...series[0],
                    data: series[0].data.filter(item => item.y !== 0)
                }
            ];

            this.setState({ series: series })
            this.highChartsRender(filteredSeries);
            
            if (this.props.summaryData && this.props.summaryData.summaryData && this.props.summaryData.summaryData.machines) {
                var summarydata = [...this.props.summaryData.summaryData.machines]
                //summarydata.sort(this.compare);
                this.setState({ filterData: summarydata, isPieFilter: false, filter: null, noFlag: false }, () => {
                    this.tableConfigurations()
                });
            }
            else {
                let plantView = localStorage.getItem("PlantView")
                let plantViewArray = JSON.parse(plantView);
                if (plantViewArray === null) {
                    this.setState({ noFlag: true })
                }
            }

            // if (this.props.summaryData && this.props.summaryData.summaryData && this.props.summaryData.summaryData.plantHealthHistory) {
            //     var plantHealthHistory = [...this.props.summaryData.summaryData.plantHealthHistory]
            //     const months = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
            //     let objArr = [];
            //     let currentDate = new Date();
            //     let monthNumber = currentDate.getMonth();
            //     var healthHistory = plantHealthHistory[plantHealthHistory.length - 1];
            //     var healthHistoryLastMonthIndex = monthNumber - 1;
            //     healthHistoryLastMonthIndex++;
            //     var chartCategories = [];
            //     var chartSeries = [
            //         { 
            //             name:this.props.t("health scores exceeding 85%"),
            //             showInLegend: false,
            //             data: []
            //         }, {
            //             name:this.props.t("health scores between 50 – 85%"),
            //             showInLegend: false,
            //             data: []
            //         }, {
            //             name:this.props.t("health scores less than 50%"),
            //             showInLegend: false,
            //             data: []
            //         },
            //         {
            //             name:this.props.t("health scores not available"),
            //             showInLegend: false,
            //             data: []
            //         }
            //     ];
            //     for (var index = 0; index < 12; index++) {
            //         if (healthHistoryLastMonthIndex > 11) {
            //             healthHistoryLastMonthIndex = 0;
            //         }
            //         let monthName = months[healthHistoryLastMonthIndex++];
            //         objArr.push(monthName);
            //         chartCategories.push(this.props.t(monthName) + "-21")
            //         chartSeries[0].data.push(0);
            //         chartSeries[1].data.push(0);
            //         chartSeries[2].data.push(0);
            //         chartSeries[3].data.push(100);

            //     }

            //     for (var index = 0; index < 12; index++) {
            //         if (plantHealthHistory) {
            //             const obj = plantHealthHistory.filter(x => x.month === months[index]);
            //             if (obj.length !== 0) {
            //                 let indexData = objArr.findIndex(x => x === obj[0].month);
            //                 chartCategories[indexData] = this.props.t(obj[0].month) + "-" + (obj[0].year).slice(-2);
            //                 chartSeries[0].data[indexData] = obj[0].greenPercent
            //                 chartSeries[1].data[indexData] = obj[0].yellowPercent
            //                 chartSeries[2].data[indexData] = obj[0].redPercent
            //                 chartSeries[3].data[indexData] = 0;
            //             }
            //         }
            //     }
            //     let options2 = clonedeep(chartOptions);
            //     options2.xAxis.categories = chartCategories;
            //     options2.series = chartSeries;
            //     this.setState({
            //         chartOptions: options2,
            //     })
            // }

            this.props.onSummaryDone();
        }
        if (prevProps.selectedScopeData !== this.props.selectedScopeData) {
            countPie = 0;
            document.cookie = `idap-machineName-${config.url.env}=null` + "; domain=" + `${IDAP_SSO_DOMAIN_NAME}; secure`;
            this.setState({ searchText: "" }, () => {
                this.optionsCall(this.state.searchText);
            })
        }

    }
    reliableBtnClick = () => {
        let plantId = localStorage.getItem("plantValue");
        if (plantId === null) {
            plantId = localStorage.getItem("selectedScopeData")
        }
        if (config.url.relible_url.indexOf('?') != -1) {
            window.open(`${config.url.relible_url}&plantId=${plantId}`)
        } else {
            window.open(`${config.url.relible_url}?plantId=${plantId}`)
        }
    }
    reoprtedDRS = (id) => {
        let plantId = localStorage.getItem("plantValue");
        if (plantId === null) {
            plantId = localStorage.getItem("selectedScopeData")
        }
        window.open(`${config.url.relible_url}reports-doc/${id}`)
    }
    compare = (a, b) => {
        var val1 = a.healthScore && a.healthScore !== 0 ? a.healthScore : 99999;
        var val2 = b.healthScore && b.healthScore !== 0 ? b.healthScore : 99999;
        if (val1 < val2) {
            return -1;
        }
        if (val1 > val2) {
            return 1;
        }
        return 0;
    }


    tableConfigurations = () => {

        let columns = [
            {
                "name": "id",
                "label": "id",
                "options": {
                    "display": false,
                    "filter": false,
                    "sort": false,
                    "searchable": false
                }
            },
            {
                "name": "label",
                "label": this.props.t("Asset"),
                "options": {
                    "display": true,
                    "filter": false,
                    "sort": true,
                    "searchable": true,
                    setCellProps: () => ({ style: { width: '200px', wordBreak: "break-word", display: "table-cell", padding: "15px" } }),


                }
            },
            {
                "name": "serviceRequired",
                "label": "serviceRequired",
                "options": {
                    "display": false,
                    "filter": false,
                    "sort": false,
                    "searchable": false,
                }
            },
            {
                "name": "healthScore",
                "label": this.props.t("Overall Health Score"),
                "options": {
                    hint: this.props.t("Machine health score is a representation of the machine health condition (Worst to Best - 0 to 100%)"),
                    "display": this.state.odrEnable === true ? true : false,
                    "filter": false,
                    "sort": true,
                    "searchable": false,
                    setCellProps: () => ({ style: { width: '200px' } }),
                    "customBodyRender": (value, tableMeta) => value && value >= 0 ? (
                        <div>
                            <span style={{ marginLeft: "30px" }}>
                                {value === 101 ? "NA" : value + "%"}
                                <Tooltip title={this.props.t(SYN_PRIORITY_LABEL_MAPPING[tableMeta.rowData[5]] || "")} placement='right'>
                                    <sup style={{ marginLeft: "5px", color: "red" }}>
                                        {(SYN_PRIORITY_LABEL_MAPPING[tableMeta.rowData[5]] || "").charAt(0)}
                                    </sup>
                                </Tooltip>
                            </span>
                            {/* {tableMeta.rowData[2] && (
                                <div style={{ cursor: "pointer" }} onClick={() => this.reliableBtnClick()}>
                                    <span style={{ position: "absolute", color: "#fff", fontSize: "11px", padding: "4px" }}>
                                        {this.props.t("Reliability Services")}
                                    </span>
                                    <img style={{ width: "130px", height: "23px" }} src={relible} />
                                </div>
                            )} */}
                            {tableMeta.rowData[14] && tableMeta.rowData[14] !== "" && (
                                <div style={{ cursor: "pointer" }} onClick={() => this.reoprtedDRS(tableMeta.rowData[15])}>
                                    <span style={{ background: "#468bd2", color: "#fff", fontSize: "11px", padding: "4px" }}>
                                        {tableMeta.rowData[14] && tableMeta.rowData[14] !== "COMPLETED" ? this.props.t("Reported on") + " " + tableMeta.rowData[13] : this.props.t("Completed on") + " " + tableMeta.rowData[16]}
                                    </span>
                                </div>
                            )}
                        </div>
                    ) : (
                        <div>
                            <span style={{ marginLeft: "30px" }}>{"NA"}</span>
                        </div>
                    )
                }
            },
            {
                "name": "trend",
                "label": this.props.t("Vibration Trend"),
                "options": {
                    "display": true,
                    "filter": false,
                    "sort": true,
                    "searchable": false,
                    setCellProps: () => ({ style: { width: '200px' } }),
                    "customBodyRender": (value) => <div style={{ marginLeft: value > 0 ? "13px" : "40px" }}><span >{value === 3 ? this.props.t("Significant rise") : value === 2 ? this.props.t("Minor rise") : value === 1 ? this.props.t("No rise") : value === null ? "-" : "-"}</span></div>
                }
            },
            {
                "name": "synPriority",
                "label": this.props.t("Syndication Priority"),
                "options": {
                    // "display": this.props.summaryData && this.props.summaryData.summaryData && this.props.summaryData.summaryData.showSynPriority,
                    "display": false,
                    "filter": false,
                    "sort": true,
                    "searchable": false,
                    setCellProps: () => ({ style: {
                        width: "180px", 
                        minWidth: "180px",
                        whiteSpace: "nowrap",
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                        textAlign: "center"
                } }),
                    "customBodyRender": (value) => <div>{getSynDisplayValue(value)}</div>,
                }
            },
            /* {
                 "name": "triggeredDiagnostics",
                 "label": "Triggered Diagnostics",
                 "options": {
                     "display": true,
                     "filter": false,
                     "sort": false,
                     "searchable": false
                 }
             }*/
            {
                "name": "status",
                "label": this.props.t("Instantaneous Status"),
                "options": {
                    hint: this.props.t("This is based on instantaneous machine vibration levels with respect to the existing thresholds"),
                    "display": true,
                    "filter": false,
                    "sort": true,
                    "searchable": false,
                    setCellProps: () => ({ style: { width: "200px" } }),
                    "customBodyRender": (value) => <Brightness1Icon style={{ fontSize: "18", marginLeft: "30px", color: colors[value] }} />
                }
            },


            {
                "name": "diagnosticAlert",
                "label": this.props.t("Diagnostic Alert"),
                "options": {
                    "display": false,
                    "filter": false,
                    "sort": false,
                    "searchable": false,
                    setCellProps: () => ({ style: { textAlign: "center" } }),
                    "customBodyRender": (value) => value === "Yes" ? <span>{value}<Tooltip title={"Click on the arrow “ > “ to view diagnostics"} aria-label="status"><HelpIcon style={{ fontSize: "13" }} /></Tooltip></span> : <span>{value}</span>
                }
            },
            {
                "name": "observation",
                "label": this.props.t("observation"),
                "options": {
                    "display": false,
                    "filter": false,
                    "sort": false,
                    "searchable": false
                }
            }, {
                "name": "diagnostic",
                "label": this.props.t("diagnostic"),
                "options": {
                    "display": false,
                    "filter": false,
                    "sort": false,
                    "searchable": false
                }
            },
            {
                "name": "recommendation",
                "label": this.props.t("recommendation"),
                "options": {
                    "display": false,
                    "filter": false,
                    "sort": false,
                    "searchable": false
                }
            },
            {
                "name": "healthTrend",
                "label": this.props.t("Health Trend"),
                "options": {
                    "display": true,
                    "filter": false,
                    "sort": true,
                    "searchable": false,
                    // setCellProps: () => ({ style: { width: "200px" } }),
                    "customBodyRender": (value) => <div style={{ width: "100px", marginLeft: value > 0 ? "0px" : "22px" }}><span style={{ textAlign: "center" }}>{value === 3 ? this.props.t("Deteriorating fast") : value === 2 ? this.props.t("Deteriorating gradually") : value === 1 ? this.props.t("Stable") : value === null ? "-" : "-"}</span></div>
                }
            },
            {
                "name": "subscriptionStatus",
                "label": this.props.t("Renewal Due in"),
                "options": {
                    "display": true,
                    "filter": false,
                    "sort": false,
                    "searchable": false,
                    //setCellProps: () => ({ style: { width: "200px" } }),
                    "customBodyRender": (value) => value && <div style={{ width: "100px"}}><span>{value}</span></div>

                }
            },
            {
                "name": "createdDate",
                "label": "createdDate",
                "options": {
                    "display": false,
                    "filter": false,
                    "sort": false,
                    "searchable": false
                }
            },
            {
                "name": "plantStatus",
                "label": "plantStatus",
                "options": {
                    "display": false,
                    "filter": false,
                    "sort": false,
                    "searchable": false
                }
            },
            {
                "name": "uniqueId",
                "label": "uniqueId",
                "options": {
                    "display": false,
                    "filter": false,
                    "sort": false,
                    "searchable": false
                }
            },
            {
                "name": "completionDate",
                "label": "completionDate",
                "options": {
                    "display": false,
                    "filter": false,
                    "sort": false,
                    "searchable": false
                }
            },
            {
                "name": "hours",
                "label": "hours",
                "options": {
                    "display": false,
                    "filter": false,
                    "sort": false,
                    "searchable": false
                }
            },
            {
                "name": "healthScoreIntelligenceSymbol",
                "label": "healthScoreIntelligenceSymbol",
                "options": {
                    "display": false,
                    "filter": false,
                    "sort": false,
                    "searchable": false
                }
            },
            {
                "name": "healthScoreIntelligenceHoverText",
                "label": "healthScoreIntelligenceHoverText",
                "options": {
                    "display": false,
                    "filter": false,
                    "sort": false,
                    "searchable": false
                }
            },
        ];

        /* if (this.props.summaryData && this.props.summaryData.summaryData && this.props.summaryData.summaryData.monitors && this.props.summaryData.summaryData.monitors.length <= 20) {
            columns.push({
                "name": "estimatedAlarmForecast",
                "label": "Estimated Alarm Forecast",
                "options": {
                    "display": true,
                    "filter": false,
                    "sort": false,
                    "searchable": false
                }
            })
        } */

        let tabledata = [];
        if (this.state.filterData) {
            var summarydata = [...this.state.filterData]
            tabledata = summarydata.map((item, index) => {
                item.id=item.id
                item.status = item.status === 5 ? 0 : item.status;
                item.diagnosticAlert = item.healthScore && item.healthScore > 0 ? "Yes" : "No";
                var today = new Date().getTime();
                var status = (today > item.subscriptionStart && today < item.subscriptionEnd && ((item.subscriptionEnd - today) / (1000 * 3600 * 24)) > 30) ? 1 :
                    ((today > item.subscriptionStart && today < item.subscriptionEnd && ((item.subscriptionEnd - today) / (1000 * 3600 * 24)) < 30 ? 2 : 3))
                var subStart = item.subscriptionStart ? new Date(item.subscriptionStart).getDate() + "/" + (new Date(item.subscriptionStart).getMonth() + 1) + "/" + new Date(item.subscriptionStart).getFullYear() : null;
                var subEnd = item.subscriptionEnd ? new Date(item.subscriptionEnd).getDate() + "/" + (new Date(item.subscriptionEnd).getMonth() + 1) + "/" + new Date(item.subscriptionEnd).getFullYear() : null;
                var subscriptionRemain = status === 1 || status === 2 ? Math.ceil((item.subscriptionEnd - today) / (1000 * 3600 * 24)) : 0;
                item.subscriptionRemain = subscriptionRemain;
                item.subscriptionStatus = <Tooltip title={subStart && subEnd ? this.props.t("Subscription Start") + ": " + subStart + ' &' + this.props.t("Subscription End") + ': ' + subEnd : "-"} aria-label="status"><span>{(subscriptionRemain===0?this.props.t("0 Days") :subscriptionRemain+ " " + this.props.t("Days"))}<Brightness1Icon style={{ fontSize: "18", marginLeft: "2px", color: statusColors[status] }} /></span></Tooltip>
                //item.trend = item.trend === 3 ? "Significant" : item.trend === 2 ? "Minor" : item.trend === 1 ? "None" : "-";
                item.healthScore = ((item.healthScore !== null) && (item.healthScore !== undefined) && (item.healthScore !== 0)) ? item.healthScore : 101;
                if ((item && item.serviceReqMachineDetails) && (item && item.serviceReqMachineDetails.length !== 0)) {
                    item.createdDate = moment(item.serviceReqMachineDetails[0].createdDate).format("DD MMM YY");
                    item.plantStatus = item.serviceReqMachineDetails[0].plantStatus;
                    item.uniqueId = item.serviceReqMachineDetails[0].uniqueId
                    if (item.serviceReqMachineDetails[0] && item.serviceReqMachineDetails[0].completionDate) {
                        let res = this.checkTime(item.serviceReqMachineDetails[0].completionDate);
                        item.hours = res;
                        item.completionDate = moment(item.serviceReqMachineDetails[0].completionDate).format("DD MMM YY")
                    }
                }
                
                item.healthScoreIntelligenceSymbol = item && item.superscript;
                item.healthScoreIntelligenceHoverText = item && item.superscriptMessage;
                return item;
            });

        }
        tabledata = tabledata.sort((a, b) => (a.healthScore > b.healthScore) ? 1 : -1).map((item, index) => {
            return item;
        })
        this.setState({
            columns,
            tabledata

        })
    }
    checkTime(item) {
        var currentTime = moment();
        var timeStore = moment(item);

        return currentTime.diff(timeStore, 'h');
    }
    optionsCall = (text) => {
        let optionsC = {
            filter: false,
            search: true,
            sort: true,
            print: false,
            download: false,
            viewColumns: false,
            elevation: 0,
            responsive: "scroll",
            selectableRows: "none",
            rowsPerPage: 20,
            rowsPerPageOptions: [20, 50, 100],
            expandableRows: true,// Try Adding This
            rowsExpanded: [],
            onRowClick: this.onRowClick,
            onSearchChange: this.onSearch,
            searchOpen: text && text !== undefined ? true : false,
            searchText: text && text !== undefined ? text : "",
            renderExpandableRow: this.rowExpand,
            onTableChange: this.handleTableChange.bind(this),
            textLabels: {
                body: {
                    noMatch: this.props.t("No matching records"),
                    toolTip: this.props.t("Sort"),
                },
                pagination: {
                    next: this.props.t("Next Page"),
                    previous: this.props.t("Previous Page"),
                    rowsPerPage: this.props.t("Rows per Page"),
                    displayRows: this.props.t("of")
                },
                toolbar: {
                    search: this.props.t("Search"),
                    download: this.props.t("Download"),
                    print: this.props.t("Print"),
                    viewColumns: this.props.t("View Columns"),
                    filterTable: this.props.t("Filter Table"),
                    upload: this.props.t("Upload"),
                    // download:currentLanguage==="en" ? "Download":"アップロード",
                    showArchivedData: this.props.t("Show Archieved Data"),
                    showActiveData: this.props.t("Show Active Data")


                },
                filter: {
                    title: this.props.t("FILTERS"),
                    reset: this.props.t("Reset")
                },
                viewColumns: {
                    title: this.props.t("Show Columns")
                },
                selectedRows: {
                    text: this.props.t("Reset"),
                    delete: this.props.t("Delete")
                }

            }
        }
        this.setState({ options: clonedeep(optionsC), optionsC })
    }
    openDialog = (data) => {
        var record = this.state.tabledata.find(x => x.monitorId === data);
        this.setState({ record: record });
        this.setState({
            openDialog: true
        });
    }
    closeDialog = () => {
        this.setState({
            openDialog: false
        });
    }
    loadMonitoringDashboard(monitor) {
        localStorage.setItem("dashboard", "monitoring")
        localStorage.setItem("deviceName", monitor.monitorName);
        localStorage.setItem("setMonitorName", monitor.monitorName);
        localStorage.setItem("deviceId", monitor.monitorId)
        localStorage.setItem("machineName", monitor.machineName)
        localStorage.setItem("setMachineName", monitor.machineName);
        localStorage.setItem("deviceIdentifier", monitor.deviceIdentifier)
        this.props.loadMonitoringDashboard(monitor);
    }

    handlePromptModal = () => {
        this.setState({ monitorCountFlag: false })
    }
    render() {
        const renderLink = () => {
            return <Link
                component="button"
                variant="body2"
                title={this.props.t("Refresh Data")}
                onClick={() => {
                    this.props.onRefreshSummary()
                }}>
                <RefreshIcon></RefreshIcon>
            </Link>
        }

        const { series } = this.state;
        const { t } = this.props;
        return (
            <div className="col-sm-12" style={{ paddingTop: "10px", backgroundColor: "white" }}>
                {this.state.monitorCountFlag && <MonitorsWithStateCount
                    props={this.state.monitorCountData}
                    openPromptModal={this.state.monitorCountFlag}
                    handlePromptModal={(action) => this.handlePromptModal(action)}
                    entityName={this.state.title}
                    loadMonitoringDashboard={(monitor) => this.loadMonitoringDashboard(monitor)}
                    t={this.props}
                />}
                {
                    ((this.state.tabledata === null) && (this.props.props.loaderState.load.value === false) && !(this.state.noFlag)) ? (
                        <div className="loadingForSummary">
                            <CircularProgress className="loaderColorForSummary" variant="indeterminate" />
                        </div>
                    ) : null
                }
                <div className="row">
                    <div className="col-sm-3" style={{ borderRight: "1px solid", borderColor: "grey" }}>
                        <div className="col-sm-12 row" style={{ marginTop: "10px" }}>
                            <div className="col-sm-11" title={this.props.summaryData && this.props.summaryData.summaryData ? this.props.summaryData.summaryData.plantName : ""} style={{
                                maxWidth: "99%", overflow: "auto", wordBreak: "break-word", alignContent: "center", fontSize: "18px", fontWeight: "500", color: "#1664c0"
                            }}>
                                <span>{t("Plant")}</span> :  {this.props.summaryData && this.props.summaryData.summaryData ? this.props.summaryData.summaryData.plantName : ""}
                            </div>
                            <div className="col-sm-1" style={{ padding: "0px" }}>
                                <div className="float-right">
                                    {renderLink()}
                                </div>
                            </div>
                        </div>
                        <div className="col-sm-12">
                            {/* <div style={{fontSize:"12px",fontWeight:700,textAlign:"center",top:"11%",zIndex:"99",left:"20%",position:"absolute"}}>{this.props.t("Threshold Based Machine Status")}</div> */}
                            <div id="piePanel" style={{ marginRight: 'auto', marginLeft: 'auto' }}></div>
                            <div style={{ position: "absolute", top: "286px", left: '57px' }}>
                                {this.state && this.state.series && this.state.series[0].data && this.state.series[0].data.map((item, key) =>
                                    <div key={key} className="legendStyle" onClick={() => this.onClickLegendChart(item.id)}><Brightness1Icon className="fontstyle" style={{ color: item.color, marginLeft: '30px' }} /> {this.props.t(item.name)}</div>
                                )}
                            </div>
                        </div>
                        {/* {
                            (this.props.summaryData && this.props.summaryData.summaryData && this.props.summaryData.summaryData.plantHealthHistory) ?
                                (<div className="col-sm-12" style={{marginTop:'4%'}} >
                                    <HighchartsReact
                                        ref={this.chart}
                                        highcharts={Highcharts}
                                        constructorType={"chart"}
                                        options={this.state.chartOptions}
                                    />
                                    <div style={{fontSize:"11px",fontWeight:600}}>
                                        <div><Brightness1Icon style={{ fontSize: "14", color: "#008000" }} /> {this.props.t("health scores exceeding 85%")}</div>
                                        <div><Brightness1Icon style={{ fontSize: "14", color: "#FFFF00" }} /> {this.props.t("health scores between 50 – 85%")}</div>
                                        <div><Brightness1Icon style={{ fontSize: "14", color: "#FF0000" }} /> {this.props.t("health scores less than 50%")}</div>
                                        <div><Brightness1Icon style={{ fontSize: "12", color: "#FFFFFF",border:"1px solid grey",borderRadius:"50%" }} /> {this.props.t("health scores not available")}</div>
                                    </div>
                                </div>) : null} */}

                    </div>
                    <div className="col-sm-9" style={{ paddingRight: "0px" }}>
                        <div className="row" style={{
                            textAlign: "center", marginTop: "10px", fontSize: "15px", fontWeight: "500", color: "#1664c0", width:"100%"
                        }}>
                             <div className="col-sm-2" style={{ borderRight: "1px solid", borderColor: "grey" }}>
                                {t("Total Areas")}: <div>{this.props.summaryData && this.props.summaryData.summaryData && this.props.summaryData.summaryData.count ? this.props.summaryData.summaryData.count.areaCount : 0}</div>
                            </div>
                            <div className="col-sm-3" style={{ borderRight: "1px solid", borderColor: "grey" }}>
                                {t("Total Equipment")}: <div>{this.props.summaryData && this.props.summaryData.summaryData && this.props.summaryData.summaryData.count ? this.props.summaryData.summaryData.count.machineGroupCount : 0}</div>
                            </div>
                            <div className="col-sm-3" style={{ borderRight: "1px solid", borderColor: "grey" }}>
                                {t("Total Assets")}: <div>{this.props.summaryData && this.props.summaryData.summaryData && this.props.summaryData.summaryData.count ? this.props.summaryData.summaryData.count.machineCount : 0}</div>
                            </div>
                            <div className="col-sm-4">
                                {t("Total Measurement Locations")}: <div>{this.props.summaryData && this.props.summaryData.summaryData && this.props.summaryData.summaryData.count ? this.props.summaryData.summaryData.count.monitorCount : 0}</div>
                            </div>
                        </div>
                        <div className="col-sm-12" style={{  paddingRight: "0px" }}>
                            <MuiThemeProvider theme={this.getMuiTheme()}>
                                {
                                    this.state.tabledata !== null && (
                                        <MUIDataTable
                                            style={{ minWidth: "100%" }}
                                            data={this.state.tabledata}
                                            columns={this.state.columns}
                                            options={this.state.options}
                                        />
                                    )
                                }
                            </MuiThemeProvider>
                            {this.state.noFlag && (this.state.tabledata === null || this.state.tabledata === undefined) && (<div style={{ marginTop: '10%', textAlign: "center", padding: "13px", boxShadow: "0 3px 5px rgb(0 0 0 / 15%)" }}>{this.props.t("Data is not available to display here, as you don't have assigned any plant.")}</div>)}
                        </div>
                        <Dialog
                            open={this.state.openDialog}
                            onClose={this.closeDialog}
                            maxWidth={'md'}
                            aria-labelledby="alert-dialog-title"
                            aria-describedby="alert-dialog-description"
                        >
                            <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', padding: '10px 24px' }}>
                                <Typography variant="h6">
                                    {t("Diagnostic & Recommendation")}
                                </Typography>
                                <Close onClick={(event) => this.closeDialog()} style={{ cursor: 'pointer' }} />
                            </div>
                            <Divider />

                            <DialogContent >
                                <div className="row" style={{ marginTop: "10px" }}>
                                    <div className="col-sm-12 row">
                                        <div className="col-sm-3" style={{ fontWeight: "500" }}>{t("Asset")}:</div>
                                        <div className="col-sm-9">{this.state.record.machineName}</div>
                                    </div>
                                    <div className="col-sm-12 row">
                                        <div className="col-sm-3" style={{ fontWeight: "500" }}>{t("Measurement Location")}:</div>
                                        <div className="col-sm-9">{this.state.record.monitorName}</div>
                                    </div>
                                    <div className="col-sm-12 row">
                                        <div className="col-sm-3" style={{ fontWeight: "500" }}>{t("Health Score")}:</div>
                                        <div className="col-sm-9">{this.state.record.healthScore + "%"}</div>
                                    </div>
                                    <div className="col-sm-12 row">
                                        <div className="col-sm-3" style={{ fontWeight: "500" }}>{t("Observation")}:</div>
                                        <div className="col-sm-9">{this.state.record.observation}</div>
                                    </div>
                                    <div className="col-sm-12 row">
                                        <div className="col-sm-3" style={{ fontWeight: "500" }}>{t("Diagnostic")}:</div>
                                        <div className="col-sm-9">{this.state.record.diagnostic}</div>
                                    </div>
                                    <div className="col-sm-12 row">
                                        <div className="col-sm-3" style={{ fontWeight: "500" }}>{t("Recommentation")}:</div>
                                        <div className="col-sm-9">{this.state.record.recommendation}</div>
                                    </div>
                                </div>
                            </DialogContent>
                            <DialogActions>

                                <Button onClick={(event) => this.closeDialog()} color="primary" autoFocus>
                                    {t("Ok")}
                                </Button>
                            </DialogActions>
                        </Dialog>
                    </div>
                </div>
            </div >
        )
    }
}

export default withTranslation()((withStyles(styles)(SummaryPanel)));