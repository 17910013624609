import * as actionTypes from '../actions/actionTypes';

export const summaryDataReducer = (state = {}, action) => {
    switch (action.type) {

        case actionTypes.SAVE_SUMMARY_DATA:
            return Object.assign({}, state, action.payload)

        default: return state
    }
}