import { combineReducers } from 'redux';

import { appReducer } from './appReducer';
import { userDataReducer } from './userDataReducer';
import { tableDataReducer } from './tableDataReducer';
import { alertMessageReducer } from './alertMessageReducer';
import { schedulesDataReducer } from './schedulesDataReducer';
import { jobsDataReducer } from './jobsDataReducer';
import { lossCodesReducer } from './lossCodesReducer';
import { staticDataReducer } from './staticDataReducer';
import { machineGroupReducer } from './machineGroupReducer';
import { deviceDataReducer } from './deviceDataReducer';
import { machinesDataReducer } from './machinesDataReducer';
import { userPlantsReducer } from './userPlantsReducer';
import { userTableDataReducer } from './userTableDataReducer';
import { jobListDataReducer } from './jobListDataReducer';
import { metadataReducer } from './metadataReducer';
import { monitorDataReducer } from './monitorDataReducer'
import { lossListDataReducer } from './lossListDataReducer';
import { loaderReducer } from './loaderReducer';
import { viewDefinitionReducer } from './viewDefinitionReducer';
import { timezoneReducer } from './timezoneReducer';
import { alertMediaReducer } from './alertMediaReducer';
import { appsNameReducer } from './appsNameReducer';
import { constructJsonReducer } from './constructJsonReducer';
import { currentScheduleReducer } from './currentScheduleReducer';
import { dashboardDeviceReducer } from './dashboardDeviceReducer';
import { reportFeatureReducer } from './reportFeatureReducer';
import { setWidgetLanguageReducer } from './setWidgetLanguageReducer';
import { alarmsDataReducer } from './alarmsDataReducer';
import { fftDataReducer } from './fftDataReducer';
import { equipmentDataReducer } from './equipmentDataReducer';
import { maintenanceDataReducer } from './maintenanceDataReducer';
import { featureDataReducer } from './featureDataReducer';
import { fftSelectedDataReducer } from './fftSelectedDataReducer';
import { saveAlarmDataReducer } from './saveAlarmDataReducer';
import { machineDataReducer } from './machineDataReducer';
import { productivityIndexDataReducer } from './productivityIndexDataReducer';
import { plantPartCountReducer } from './plantPartCountReducer';
import { timeSeriesDataReducer } from './timeSeriesDataReducer';
import { plantShiftReducer } from './plantShiftReducer';
import { oeeByPartNumberReducer } from './oeeByPartNumberReducer';
import { productivitySelectionReducer } from './productivitySelectionReducer';
import { partCountListReducer } from './partCountListReducer';
import { machineProductionHistoryReducer } from './machineProductionHistoryReducer';
import { oeeSelectionReducer } from './oeeSelectionReducer';
import { inputDialogReducer } from './inputDialogReducer';
import { downtimeAnalysisReducer } from './downtimeAnalysisReducer';
import { maintenanceMetricsReducer } from './maintenanceMetricsReducer';
import { lossDropDownListReducer } from './lossDropDownListReducer';
import { lossReportDataReducer } from './lossReportDataReducer';
import { lossDataReducer } from './lossDataReducer';
import { topPlantLossReducer } from './topPlantLossReducer';
import { topMachineLossReducer } from './topMachineLossReducer';
import { alarmAnalysisDataReducer } from './alarmAnalysisDataReducer';
import { alarmReportDataReducer } from './alarmReportDataReducer';
import { qualityDataReducer } from './qualityDataReducer';
import { cycletimeReportReducer } from './cycletimeReportReducer';
import { cycleTimeComparisonReducer } from './cycleTimeComparisonReducer';
import { qualityDataReducer1 } from './qualityDataReducer1';
import { qualityReportReducer } from './qualityReportReducer';
import { drawerReducer } from './drawerReducer';
import { fftUpdateReducer } from './fftUpdateReducer';
import { documentationDataReducer } from './documentationDataReducer'
import { reportAlarmDataReducer } from './reportAlarmDataReducer';
import { reportEquipmentDataReducer } from './reportEquipmentDataReducer';
import { alarmWatchlistReducer } from './alarmWatchlistReducer';
import { getFaultReducer } from './getFaultReducer';
import { bearingDataReducer } from './bearingDataReducer';
import { getMonitorReducer } from './getMonitorReducer';
import { dashboardTypeReducer } from './dashboardTypeReducer';
import { summaryDataReducer } from './summaryDataReducer';
import { deviceDataforDashboardReducer } from './deviceDataforDashboardReducer';
import { monitorCountReducer } from './monitorCountReducer';
import { machineCountReducer } from './machineCountReducer';
import { machineGroupCountReducer } from './machineGroupCountReducer';
import { getThresholdsReducer } from './getThresholdsReducer';
import { machineImageReducer } from './machineImageReducer';
import { diagnosticsAlarmsDataReducer } from './diagnosticsAlarmsDataReducer';
import {updatePasswordReducer} from "./updatePasswordReducer";
import {platformUptimeReducer} from "./platformUptimeReducer";
import { languageListReducer } from './languageListReducer';
import { summaryPlantHealthScoreReducer } from './summaryPlantHealthScoreReducer';
import externalFeaturesReducer from "./externalFeaturesReducer";
import applicationReducer from "./applicationReducer";
import trendHistoryReducer from "./trendHistoryReducer";

export const rootReducer = () => combineReducers({
    appReducer,
    userDataReducer,
    monitorCountReducer,
    machineCountReducer,
    diagnosticsAlarmsDataReducer,
    getThresholdsReducer,
    machineImageReducer,
    machineGroupCountReducer,
    tableDataReducer,
    getMonitorReducer,
    summaryDataReducer,
    summaryPlantHealthScoreReducer,
    alertMessageReducer,
    schedulesDataReducer,
    jobsDataReducer,
    bearingDataReducer,
    lossCodesReducer,
    staticDataReducer,
    dashboardTypeReducer,
    documentationDataReducer,
    machineGroupReducer,
    deviceDataReducer,
    machinesDataReducer,
    userPlantsReducer,
    userTableDataReducer,
    jobListDataReducer,
    metadataReducer,
    lossListDataReducer,
    loaderReducer,
    viewDefinitionReducer,
    timezoneReducer,
    alertMediaReducer,
    constructJsonReducer,
    currentScheduleReducer,
    dashboardDeviceReducer,
    reportFeatureReducer,
    setWidgetLanguageReducer,
    alarmsDataReducer,
    monitorDataReducer,
    fftDataReducer,
    equipmentDataReducer,
    maintenanceDataReducer,
    featureDataReducer,
    fftSelectedDataReducer,
    saveAlarmDataReducer,
    machineDataReducer,
    productivityIndexDataReducer,
    plantPartCountReducer,
    timeSeriesDataReducer,
    plantShiftReducer,
    getFaultReducer,
    oeeByPartNumberReducer,
    productivitySelectionReducer,
    partCountListReducer,
    machineProductionHistoryReducer,
    oeeSelectionReducer,
    inputDialogReducer,
    downtimeAnalysisReducer,
    maintenanceMetricsReducer,
    lossDropDownListReducer,
    lossReportDataReducer,
    lossDataReducer,
    topPlantLossReducer,
    topMachineLossReducer,
    alarmAnalysisDataReducer,
    alarmReportDataReducer,
    qualityDataReducer,
    cycletimeReportReducer,
    cycleTimeComparisonReducer,
    qualityDataReducer1,
    qualityReportReducer,
    drawerReducer,
    fftUpdateReducer,
    reportAlarmDataReducer,
    reportEquipmentDataReducer,
    alarmWatchlistReducer,
    deviceDataforDashboardReducer,
    appsNameReducer,
    updatePasswordReducer,
    platformUptimeReducer,
    languageListReducer,
    externalFeaturesReducer,
    applicationReducer,
    trendHistoryReducer
});