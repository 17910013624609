import React, { Component } from 'react'
import { withTranslation } from "react-i18next";

class InternetSpeedPanel extends Component {
    constructor(props) {
        super(props);
    }

    componentDidMount() {
        const { t } = this.props;
        let imageAddr = "/200KB.jpg";
        let downloadSize = 208896; //bytes    // 208896 504000  1090519

        let ele = document.getElementById("internet");
        ele.innerHTML = "Checking...";
        setTimeout(() => {
            let startTime, endTime, speedState, speedColor;
            let download = new Image();
            startTime = (new Date()).getTime();

            download.onload = function () {
                endTime = (new Date()).getTime();

                let duration = (endTime - startTime) / 1000;
                let bitsLoaded = downloadSize * 8;
                let speedBps = (bitsLoaded / duration).toFixed(2);
                let speedKbps = (speedBps / 1024).toFixed(2);
                let speedMbps = (speedKbps / 1024).toFixed(2);
                speedState = 'Medium Speed';
                speedColor = '#FF7F00';

                if (speedMbps <= 1) {
                    speedState = `${speedMbps} Mbps ${t("Very Slow")}`;
                    // speedState = 'Very Slow Speed (' + speedMbps + ' Mbps)';
                    speedColor = '#FF0000';
                }
                else if (speedMbps <= 3) {
                    speedState = `${speedMbps} Mbps ${t("Slow")}`;
                    // speedState = 'Slow Speed (' + speedMbps + ' Mbps)';
                    speedColor = '#C44000';
                }
                else if (speedMbps <= 8) {
                    speedState = `${speedMbps} Mbps ${t("Medium")}`;
                    // speedState = 'Medium Speed (' + speedMbps + ' Mbps)';
                    speedColor = '#FF7F00';
                }
                else if (speedMbps <= 14) {
                    speedState = `${speedMbps} Mbps ${t("Fast")}`;
                    // speedState = 'Fast Speed (' + speedMbps + ' Mbps)';
                    speedColor = '#006000';
                }
                else {
                    speedState = `${speedMbps} Mbps ${t("Very Fast")}`;
                    // speedState = 'Very Fast Speed (' + speedMbps + ' Mbps)';
                    speedColor = '#00A000';
                }
                ele.innerHTML = speedState;
                ele.style.color = speedColor;
            }

            download.onerror = function (err, msg) {
                console.log("Invalid image, or error downloading");
            }

            let cacheBuster = "?nnn=" + startTime;
            download.src = imageAddr + cacheBuster;
        }, 3000);

    }

    render() {
        const {t}=this.props;
        return (
            <div style={{ "padding": "10px 0 0 10px" }}>{t("Internet Speed")}: <span id="internet"></span></div>
        )
    }
}

export default withTranslation()(InternetSpeedPanel);