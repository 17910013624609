import React, { Component } from 'react'
import Button from '@material-ui/core/Button';
import Modal from 'react-responsive-modal';
import Picky from "react-picky";
import TimeKeeper from 'react-timekeeper';
import PickyDateTime from 'react-picky-date-time';
import moment from "moment";
import { connect } from 'react-redux';
import * as actionCreators from '../../../actions/actionCreators';
import AvailabilityGraph from "../TimeSeriesGraphs/AvailabilityGraph"
import { withTranslation } from "react-i18next";

const chartHeader = [
    {label:'>90%', bgColor : '#00FF00'},
    {label:'80 - 90%', bgColor : '#33ff00'},
    {label:'70 - 80%', bgColor : '#66ff00'},
    {label:'60 - 70%', bgColor : '#99ff00'},
    {label:'50 - 60%', bgColor : '#ccff00'},
    {label:'40 - 50%', bgColor : '#FFFF00'},
    {label:'30 - 40%', bgColor : '#FFCC00'},
    {label:'20 - 30%', bgColor : '#ff9900'},
    {label:'10 - 20%', bgColor : '#ff6600'},
    {label:'0.1 - 10%', bgColor : '#FF3300'},
    {label:'0%', bgColor : '#FF0000'},
    {label:'N/A', bgColor : '#f0f0f0'}

]

const dataChartHeader = [
    {label:'>90%', bgColor : '#7f7f7f'},
    {label:'70 - 90%', bgColor : '#9f9f9f'},
    {label:'50 - 70%', bgColor : '#afafaf'},
    {label:'<50%', bgColor : '#cfcfcf'},
    {label:'N/A', bgColor : '#f0f0f0'}

]

const styles = {
    customModal : {
        modalStyle: "modalStyle"
    }
  };

class VariousTimeSeriesGraphWiget extends Component {
    constructor(props){
        super(props);
        var today = new Date();
        var startTime , endTime;
        if(today.getMinutes() > 30){
            startTime = today.getHours() + ':' + '00' ;
            endTime = today.getHours() + ':' + '30' ;
        } else {
            startTime = today.getHours()-1 + ':' + '30' ;
            endTime = today.getHours() + ':' + '00' ;
        }

        this.state = {    
            Availability: '',
            AvailabilityticksList: '',
            Productivity: '',
            ProductivityticksList: '',
            Quality: '',
            QualityticksList: '',
            Data: '',
            DataticksList: '',
            formOpen: false,
            partNumber: Object.keys(this.props.partCountList).length !== 0 ? this.props.partCountList.list[0].partName : null,
            isCalendar : false,
            isCalendarNext : false,
            selectedDay: {year: today.getFullYear(), month: today.getMonth()+1, date: today.getDate()},
            selectedDayNext: {year: today.getFullYear(), month: today.getMonth()+1, date: today.getDate()},
            startTime: startTime,
            endTime: endTime,
            selectedMachine : '',
            selectedMachineId : this.props.machineList.length !== 0 ? this.props.machineList.machineData[0].machineId : null,
            rejectionName :  this.props.inputDialogData.inputData ? this.props.inputDialogData.inputData.rejectionReasonList[0] : null,
            reasonName:  this.props.inputDialogData.inputData ? this.props.inputDialogData.inputData.reworkReasonList[0] : null,
            numberParts: 1,
            selectedDeviceId: this.props.machineList.length !== 0 ? this.props.machineList.machineData[0].subRows[0].deviceId : null,
            count : 0,
            nextCount : 0
            
        }    
        this.state.count=0; 
        this.state.nextcount=0;    
    }  

   
    componentDidUpdate(prevProps,prevState){
        if(prevProps.timeSeriesData != this.props.timeSeriesData) {
            let gAvailabilityArr = Object.keys(this.props.timeSeriesData).length>0 ? this.props.timeSeriesData.timeSeriesData.availability : '';
            let gProdArr = Object.keys(this.props.timeSeriesData).length>0 ? this.props.timeSeriesData.timeSeriesData.productivity : '';
            let gQualityArr = Object.keys(this.props.timeSeriesData).length>0 ? this.props.timeSeriesData.timeSeriesData.quality : '';
            let gDataArr = Object.keys(this.props.timeSeriesData).length>0 ? this.props.timeSeriesData.timeSeriesData.data : '';
            this.drawGraph(gAvailabilityArr,'Availability')
            this.drawGraph(gProdArr,'Productivity')
            this.drawGraph(gQualityArr,'Quality') 
            this.drawGraph(gDataArr,'Data')        
        }
        if(prevProps.machineList !== this.props.machineList || prevState.selectedMachineId !== this.state.selectedMachineId ){
            let selectedMachine = this.props.machineList.machineData.filter((ele)=> ele.machineId == this.state.selectedMachineId)
            this.setState({ selectedMachine : selectedMachine})
        }

        if(prevProps.machineList !== this.props.machineList ){            
            this.setState({ selectedMachineId : this.props.machineList.machineData[0].machineId,
                            selectedDeviceId : this.props.machineList.machineData[0].subRows[0].deviceId,
            })
        }
        
        if(prevProps.inputDialogData != this.props.inputDialogData){
            this.setState({ reasonName : this.props.inputDialogData.inputData.reworkReasonList[0],
                            rejectionName : this.props.inputDialogData.inputData.rejectionReasonList[0]
             })
        }

        if(prevProps.partCountList != this.props.partCountList){
            this.setState({ partNumber : this.props.partCountList.list[0].partName })
        }
    }

    drawGraph = (arr,dataTitle) => {
        let gAvailabilityInfo = arr;
        let ticksListArr= dataTitle+'ticksList'
        if (gAvailabilityInfo.length == 0) {
            return;
        }
        let header = [],value = [],ticksList = [],data = [];;
        header.push('Type');

        for (let i = 0; i < gAvailabilityInfo.length; i++) {
            header.push('Value-' + i);
            header.push({ role: 'style' });
            header.push({ type: 'string', role: 'tooltip' });
        }

        header.push({role: 'annotation'});

        value.push(dataTitle);

        for (let i = 0; i < gAvailabilityInfo.length; i++) {
            let info = gAvailabilityInfo[i];
            let showPercent = parseFloat(info.totalPercentage).toFixed(1);
    
            value.push(1);

            if(dataTitle==="Data"){
                if (info.totalPercentage > 90)
                    value.push('color:' + dataChartHeader[0].bgColor);
                else if (info.totalPercentage > 70)
                    value.push('color:' + dataChartHeader[1].bgColor);
                else if (info.totalPercentage > 50)
                    value.push('color:' + dataChartHeader[2].bgColor);
                else if (info.totalPercentage > 0)
                    value.push('color:' + dataChartHeader[3].bgColor);
                else
                    value.push('color:' + dataChartHeader[4].bgColor);

               
            }
            else {
                if (info.totalPercentage > 90)
                    value.push('color:' + chartHeader[0].bgColor);
                else if (info.totalPercentage > 80)
                    value.push('color:' + chartHeader[1].bgColor);
                else if (info.totalPercentage > 70)
                    value.push('color:' + chartHeader[2].bgColor);
                else if (info.totalPercentage > 60)
                    value.push('color:' + chartHeader[3].bgColor);
                else if (info.totalPercentage > 50)
                    value.push('color:' + chartHeader[4].bgColor);
                else if (info.totalPercentage > 40)
                    value.push('color:' + chartHeader[5].bgColor);
                else if (info.totalPercentage > 30)
                    value.push('color:' + chartHeader[6].bgColor);
                else if (info.totalPercentage > 20)
                    value.push('color:' + chartHeader[7].bgColor);
                else if (info.totalPercentage > 10)
                    value.push('color:' + chartHeader[8].bgColor);
                else if (info.totalPercentage > 0)
                    value.push('color:' + chartHeader[9].bgColor);
                else if (info.totalPercentage == 0)
                    value.push('color:' + chartHeader[10].bgColor);
                else
                    value.push('color:' + chartHeader[11].bgColor);
            }

            info.shiftList = info.shiftList || [];
            if (info.shiftList.length > 0) {
                let tooltip = info.timeStamp + "    Total "+dataTitle+" : " + parseFloat(showPercent).toFixed(2) + "%\n";
    
                if (showPercent < 0) {
                    tooltip = info.timeStamp + "    Total "+dataTitle+" : N/A\n";
                }
    
                for (let k = 0; k < info.shiftList.length; k++) {
                    let shiftInfo = info.shiftList[k];
                    if (k == 0) {
                        if (showPercent >= 0) {
                            tooltip = tooltip + shiftInfo.name + " : " + parseFloat(shiftInfo.shiftPercentage).toFixed(2) + "%";
                        }
                        else {
                            tooltip = tooltip + shiftInfo.name + " : N/A";
                        }
                    }
                    else {
                        if (showPercent >= 0) {
                            tooltip = tooltip + ", " + shiftInfo.name + " : " + parseFloat(shiftInfo.shiftPercentage).toFixed(2) + "%";
                        }
                        else {
                            tooltip = tooltip + ", " + shiftInfo.name + " : N/A";
                        }
                    }
                }
    
                value.push(tooltip);
            }
            else {
                if (showPercent >= 0)
                    value.push(info.timeStamp + " -> " + showPercent + '%');
                else
                    value.push(info.timeStamp + " -> N/A");
            }
        }
    
        value.push('');
    
        if (gAvailabilityInfo.length > 0) {
            ticksList.push({v:0, f:gAvailabilityInfo[0].timeStamp});
            ticksList.push({v:gAvailabilityInfo.length, f:gAvailabilityInfo[gAvailabilityInfo.length - 1].toDate});
        }    
        
        data.push(header);
        data.push(value);

       return this.setState({ [dataTitle]: data , [ticksListArr]: ticksList })
    }

    onMachineSelectionChange = (e) =>{
        let eleValue = parseInt(e.target.value);
        this.setState({ selectedMachineId : eleValue })
    }

    onDeviceSelectionChange = (e) => {
        let eleValue = this.state.selectedMachine[0].subRows.filter( ele => ele.machineName === e.target.value)
        this.setState({ selectedDeviceId : eleValue[0].deviceId })
    }
    modalOpen = () => {
        this.setState({ formOpen: true });
    }

    modalClose = () => {
        this.setState({ formOpen: false })
     //   this.setState({ selectedMachineId : '', selectedDeviceId : '', partNumber : '', rejectionName : '',numberParts : '', reasonName : ''})
    } 
     
    onPartSelectionChange = (e) => {
        this.setState({  partNumber : e.target.value })
    }

    onRejectionChange = (e) => {
        this.setState({  rejectionName : e.target.value })
    }

    onReasonChange = (e) => {
        this.setState({ reasonName : e.target.value })
    }

    updateInput = (e) => {
        this.setState({numberParts : e.target.value})
    }    

    startTimeClicked = () => {
        this.setState({ isCalendar : true, isCalendarNext : false  })
    }
    
    endTimeClicked = () => {
        this.setState({  isCalendarNext: true, isCalendar : false  })
    }
    
    handleDayClick = (res) => {
        const { date, month, year } = res;
        this.setState({ startFlag: true, selectedDay: { year: year, month: month, date: date } });
    }
    
    handleDayClickNext = (res) => {
        const { date, month, year } = res;
        this.setState({ endFlag: true, selectedDayNext: { year: year, month: month, date: date } });
    }
    
    handleTimeChange = (newTime) => {
        let startTime, endTime;
       
        if(this.state.count){
            if(newTime.minute > 30){
                startTime = newTime.hour24 + ':' + '00' ;
                endTime = newTime.hour24 + ':' + '30' ;
            }else {
                startTime = newTime.hour24-1 + ':' + '30' ;
                endTime = newTime.hour24 + ':' + '00' ;
            }
            this.setState({ startTime: startTime, endTime: endTime });
        }else {
            this.setState({ startTime: newTime.hour24 + ':' + '00'});
        }
        if(this.state.count == 0)
            this.setState({ count : 1})
        else {
            this.setState({ count : 0})
            this.setState({ isCalendar: false, isCalendarNext: false, formOpen: true });   
        }
    }
    
    handleTimeChangeNext = (newTime) => {
        let startTime, endTime;
        if(this.state.nextcount){
            if(newTime.minute > 30){
                startTime = newTime.hour24 + ':' + '' ;
                endTime = newTime.hour24 + ':' + '30' ;
            }else {
                startTime = newTime.hour24-1 + ':' + '30' ;
                endTime = newTime.hour24 + ':' + '00' ;
            }
            this.setState({ startTime: startTime, endTime: endTime });
        }else {
            this.setState({ startTime: newTime.hour24 + ':' + '00'});
        }

        if(this.state.nextcount == 0)
            this.setState({ nextcount : 1})
        else {
            this.setState({ nextcount : 1})
            this.setState({ isCalendar: false, isCalendarNext: false, formOpen: true });
        }
    }
    
    userSelectionFromCal = (e) => {
        this.setState({ isCalendar: false, isCalendarNext: false, formOpen: true });   
    }

    handleFormSubmit = (formSubmitEvent) => {
        formSubmitEvent.preventDefault();
        const { selectedDay, selectedDayNext, startTime, endTime } = this.state;

        let startingDate = new Date(`${selectedDay.year} ${selectedDay.month} ${selectedDay.date}`)
        startingDate.setHours(startTime.split(':')[0],startTime.split(':')[1],0,0);

        let endingDate = new Date(`${selectedDayNext.year} ${selectedDayNext.month} ${selectedDayNext.date}`)
        endingDate.setHours(endTime.split(':')[0],endTime.split(':')[1],0,0);
       
        
        let from = moment(startingDate).utc().format("YYYY-MM-DDTHH:mm:00") + "Z";
        let to = moment(endingDate).utc().format("YYYY-MM-DDTHH:mm:00") + "Z";
       
        this.setState({ formOpen : false })
      
        this.props.sendInputQualityData(
                     this.state.selectedMachineId, 
                     this.state.selectedDeviceId, 
                     this.props.selectedPlantId,
                     from,to,
                     this.state.partNumber , 
                     this.state.rejectionName,
                     this.state.numberParts,                     
                     this.state.reasonName);
      //  this.setState({ selectedMachineId : '', selectedDeviceId : '', partNumber : '', rejectionName : '',numberParts : '', reasonName : ''})
        
       // this.props.sendInputQualityData(machineId, deviceId, plantId, from, to, partNumber, rejectionName, numberparts, reasonName)
    }
      
    
    render() {
        const {t}=this.props;
        const { selectedDay, selectedDayNext, clocktime, clocktimeNext, startTime, endTime } = this.state;
        return ( 
            <div className="multiple-graphs common-widget">
                <div className="chart-header">
                    {chartHeader.map((list, index) =>
                            <span className="list-wrp" key={index}>
                                <span className="list_element display-text" style={{backgroundColor : list.bgColor}}></span>
                                <span className="list_label">{list.label}</span>
                            </span>
                        )}
                </div>

                <AvailabilityGraph data={this.state.Availability} ticksList={this.state.AvailabilityticksList} />
                <AvailabilityGraph data={this.state.Productivity} ticksList={this.state.ProductivityticksList} />

                <div className="display-text" style={{ padding :"4px",width : "auto", verticalAlign : "top", float : "right", marginRight: "10px"}} onClick={(e) => this.modalOpen(e)}>
                    <a className="btn btn-primary" style={{color: "#333",backgroundColor: "#fff",borderColor: "#ccc", padding: "6px"}}>INPUT QUALITY DATA</a>
                </div>
                <div className="clearfix"></div>

                <AvailabilityGraph data={this.state.Quality} ticksList={this.state.QualityticksList} />
               
                <div className="chart-header">
                    {dataChartHeader.map((list, index) =>
                            <span className="list-wrp" key={index}>
                                <span className="list_element display-text" style={{backgroundColor : list.bgColor}}></span>
                                <span className="list_label">{list.label}</span>
                            </span>
                        )}
                </div>

                <AvailabilityGraph data={this.state.Data} ticksList={this.state.DataticksList} />

                <Modal open={this.state.formOpen} onClose={this.modalClose} center classNames={{modal: styles.customModal}}>
                    <div className="machineList-dialog-wrp">
                        <h2>{t("Add Quality Data")}</h2>
                        <form onSubmit={this.handleFormSubmit}>
                            <div className="qualityInput-dialog">
                                <div className="item-wrp">
                                    <label>{t("Asset")}</label>
                                    { this.props.machineList.length !== 0 ?
                                        <select className="list-selection form-control" onChange={(e) => this.onMachineSelectionChange(e)}>                                                                              
                                            { this.props.machineList.machineData.map( (list, index) =>
                                                <option value={ list.machineId } key={ index }>{list.machineName}</option>
                                            )}
                                        </select>
                                    : null }                                  
                                </div>
                                <div className="item-wrp">
                                    <label>{t("Device Name")}</label>
                                    { this.state.selectedMachine.length !== 0 ?
                                    <select className="list-selection form-control" onChange={(e) => this.onDeviceSelectionChange(e)}>                                       
                                        { this.state.selectedMachine[0].subRows.map( (list, index) =>
                                                <option value={ list.machineId } key={ index }>{list.machineName}</option>
                                        )}
                                   </select>
                                    : null }      
                                </div>                           
                                <div className="item-wrp">
                                    <label>{t("Start Time")}</label>
                                    <span style={{ background: '#aaa', color: '#eee', fontSize: '20px', padding: "4px 8px"}} className="list-selection" onClick={this.startTimeClicked} >
                                        {selectedDay.year}/{selectedDay.month}/{selectedDay.date} {startTime}
                                    </span>
                                    {   this.state.isCalendar ?
                                            <div className="timeDate startCal widgets-shadow" ref={prodSearchCalendar => { this.prodSearchCalendar = prodSearchCalendar }}>
                                                <PickyDateTime size="m" mode={0} defaultDate={`${selectedDay.month}/${selectedDay.date}/${selectedDay.year}`} locale="en-us" show={true} onDatePicked={res => this.handleDayClick(res)} />
                                                <TimeKeeper time={startTime} onChange={this.handleTimeChange} closeOnMinuteSelect={true} switchToMinuteOnHourSelect={true} />
                                                <div className="shiftSelector-action" style={{ background: "white", textAlign: "right", padding: "10px"}}>
                                                    <button className="btn btn-primary" style={{ padding: "4px 12px" }} onClick={(e) => this.userSelectionFromCal(e)}>Ok</button>
                                                </div>
                                            </div>
                                        : null
                                    }
                                </div>
                                <div className="item-wrp">
                                    <label>{t("End Time")}</label>
                                    <span style={{ background: '#aaa', color: '#eee', fontSize: '20px', padding: "4px 8px"}} className="list-selection" onClick={this.endTimeClicked} >
                                        {selectedDayNext.year}/{selectedDayNext.month}/{selectedDayNext.date} {endTime}
                                    </span>
                                    { this.state.isCalendarNext ?
                                        <div className="timeDate endCal widgets-shadow" ref={prodSearchCalendarNext => { this.prodSearchCalendarNext = prodSearchCalendarNext }}>
                                            <PickyDateTime size="m" mode={0} defaultDate={`${selectedDayNext.month}/${selectedDayNext.date}/${selectedDayNext.year}`} locale="en-us" show={true} onDatePicked={res => this.handleDayClickNext(res)} />
                                            <TimeKeeper time={endTime} onChange={this.handleTimeChangeNext} switchToMinuteOnHourSelect={true} />
                                            <div className="shiftSelector-action" style={{ background: "white", textAlign: "right", padding: "10px"}}>
                                                <button className="btn btn-primary" style={{ padding: "4px 12px" }} onClick={(e) => this.userSelectionFromCal(e)}>Ok</button>
                                            </div>
                                        </div>
                                        : null
                                    }
                                </div>
                                <div className="clearfix"></div>
                            <div className="item-wrp">
                                <label>{t("Part Number")}</label>
                                { Object.keys(this.props.partCountList).length !== 0 ?
                                    <select className="list-selection form-control" onChange={(e) => this.onPartSelectionChange(e)}>
                                        {this.props.partCountList.list.map((list, index) =>
                                            <option value={list.partName} key={index}>{list.partName}</option>
                                        )}
                                    </select>
                                : null }
                            </div>   
                            <div className="item-wrp">
                                <label>{t("Reason For Rejection")}</label>
                                { Object.keys(this.props.inputDialogData).length !== 0 ?
                                    <select className="list-selection form-control"  onChange={this.onRejectionChange}>
                                        {this.props.inputDialogData.inputData.rejectionReasonList.map((list, index) => 
                                            ( list !== null ) ? <option value={list} key={index}>{list}</option> : null
                                        )}
                                     </select>
                                : null }
                            </div>   
                            <div className="item-wrp">
                                <label>{t("Number of Parts Rejected for Rejection")}</label>
                                <input className="list-selection form-control" type="Number" value={this.state.numberParts} id="numberParts" onChange={this.updateInput}/>
                            </div>   
                            <div className="item-wrp">
                                <label>{t("Reason For Rework")}</label>                               
                                { Object.keys(this.props.inputDialogData).length !== 0 ?
                                    <select className="list-selection form-control"  onChange={this.onReasonChange}>
                                        {this.props.inputDialogData.inputData.reworkReasonList.map((list, index) =>
                                             ( list !== null ) ? <option value={list} key={index}>{list}</option> : null
                                        )}
                                     </select>
                                : null }
                            </div>   
                        </div>
                        <div className="dialog-btn-wrp">
                            <Button className="dialog-btn select-btn" type="submit" color="primary" autoFocus>{t("OK")}</Button>
                            <Button className="dialog-btn cancel-btn" onClick={() => this.modalClose()} color="primary" autoFocus>{t("Cancel")}</Button>
                        </div>
                        </form>
                    </div>
                </Modal>
            </div>
        )
    }
}

const mapDispatchToProps = dispatch => {
    return {
        sendInputQualityData: (machineId, deviceId, plantId, from, to, partNumber, rejectionName, numberparts, reasonName) => (dispatch((actionCreators.sendInputQualityData(machineId, deviceId, plantId, from, to, partNumber, rejectionName, numberparts, reasonName)))),        
    }
  }

const mapStateToProps = state => {
    return {
      partCountList : state.partCountListReducer,
      machineList : state.machineDataReducer,
      inputDialogData : state.inputDialogReducer
    }
  }
export default withTranslation()(connect(mapStateToProps, mapDispatchToProps)(VariousTimeSeriesGraphWiget));