import React, { Component, Fragment } from "react";
import { connect } from 'react-redux';
import {
    withStyles,
    Button,
    Typography,
    Dialog,
    DialogContent,
    DialogActions,
    TextField,
    Divider,
    IconButton
} from '@material-ui/core';
import Checkbox from '@material-ui/core/Checkbox'
import MUIDataTable from "mui-datatables";
import { createMuiTheme, MuiThemeProvider } from '@material-ui/core/styles';
import { Edit, Delete, Close, Archive, Launch } from '@material-ui/icons';
import { withTranslation } from "react-i18next";

import * as actionCreators from '../../actions/actionCreators';
import { linux_deployment_platform } from '../../configuration';
import LossCodesModal from './LossCodesModal';
import CsvDownloadComponent from '../CsvDownloadComponent';
import CsvUploadComponent from '../CsvUploadComponent';
import ConfirmationModal from '../ConfirmationModal';
let currentLanguage = localStorage.getItem("lang");

if (currentLanguage === "null" || currentLanguage === null) {
    currentLanguage = "en"
}

const styles = theme => ({
    addButton: {
        background: "#2abbe8",
        color: "#504a4a",
        fontSize: "12px",
        fontWeight: "500",
        "&:hover": {
            background: "#2abbe8a1"
        }
    },
    editIcon: {
        'font-size': 18,
        cursor: 'pointer',
        color: 'rgba(0, 200, 83, 0.5)',
        '&:hover': {
            color: 'rgba(0, 200, 83, 1.0)'
        }
    },
    createNewButton: {
        fontWeight: '400',
        marginLeft: 12
    },
    deleteIcon: {
        'font-size': 18,
        cursor: 'pointer',
        color: 'rgba(221, 44, 0, 0.5)',
        '&:hover': {
            color: 'rgba(221, 44, 0, 1.0)'
        }
    },
    archiveIcon: {
        'font-size': 20,
        cursor: 'pointer',
        color: 'rgba(221, 44, 0, 0.5)',
        '&:hover': {
            color: 'rgba(221, 44, 0, 1.0)'
        }
    },
    bulkEditDeleteIcon: {
        cursor: 'pointer',
        color: 'rgba(221, 44, 0, 0.5)',
        '&:hover': {
            color: 'rgba(221, 44, 0, 1.0)'
        }
    },
    flexDiv: {
        display: "flex",
        flexDirection: "row-reverse",
        marginRight: "20px"
    },
    buttonContainer: {
        padding: '5px 10px 10px 0px',
        width: '100%'
    },
    lossListModal: {
        'min-width': 525,
        'max-height': 589,
        'min-height': 310
    },
    dialogTitle: {
        display: 'flex',
        'justify-content': 'space-between',
        'align-items': 'center',
        padding: '10px 24px'
    },
    dialogContent: {
        padding: '0 24px'
    },

    elementContainer: {
        display: 'flex',
        'align-items': 'center',
        'justify-content': 'space-between'
    },
    inputLabel: {
        color: 'rgba(0, 0, 0, 0.4000000059604645)',
        'font-size': 14,
        'letter-spacing': 0.5,
        'font-weight': 500
    },
    textField: {
        width: 300,
        height: 50,
        '& input': {
            padding: 16
        },
        '& > p': {
            margin: "3px 5px 0",
        }
    },
    saveButton: {
        backgroundColor: '#2abbe8',
        '&:hover': {
            backgroundColor: '#2abbe8',
        }
    },
    dialogSaveButton: {
        width: 145,
        backgroundColor: '#42D784',
        '&:hover': {
            backgroundColor: '#42D784'
        }
    },
    requiredClass: {
        color: '#d32f2f'
    },
    hideHeaderForActiveData: {
        '& thead tr th:nth-child(1)': {
            width: 10
        },
        '& tbody tr td:nth-of-type(1)': {
            padding: '4px 12px',
        },
        '& thead tr th:nth-child(2)': {
            width: 10,
            padding: '4px 15px'
        },
        '& tbody tr td:nth-of-type(2)': {
            padding: '4px 12px',
        },
        '& thead tr th:nth-child(3)': {
            width: 10,
            padding: '4px 30px'
        },
        '& tbody tr td:nth-of-type(3)': {
            padding: '4px 12px',
        }
    },
    hideHeaderForArchivedData: {
        '& thead tr th:nth-child(1)': {
            width: 10,
            padding: '4px 12px',
            color: '#fff'
        },
        '& tbody tr td:nth-of-type(1)': {
            padding: '4px 12px'
        }
    },
    buttonLabel: {
        height: 24
    },
    iconBotton: {
        '&:hover': {
            color: '#3f51b5'
        }
    }
});

class LossCodesTable extends Component {

    state = {
        columns: [],
        data: null,
        name: "",
        id: "",
        notes: "",
        openDialog: false,
        plantId: "",
        plantName: "",
        diableSaveButton: false,
        notesError: false,
        lossListNameError: false,
        displayCreateButton: null,
        displayDelete: false,
        selectedRows: null,
        enableBulkEditingView: false,
        headerClass: "",
        openPromptModal: false,
        rowsToBeArchived: [],
        entity: "",
        defaultLossList: false,
        lang:currentLanguage
    }

    getMuiTheme = () => createMuiTheme({
        overrides: {
            MUIDataTableHeadCell: {
                fixedHeader: {
                    'z-index': 0
                }
            },
            typography: {
                useNextVariants: true,
            },
            MUIDataTable: {
                responsiveScroll: {
                    height: 'calc(85vh - 150px)',
                    'max-height': 'calc(85vh - 150px)'
                }
            },
            MuiTableCell: {
                root: {
                    "padding-right": "38px !important"
                },
                head: {
                    fontSize: 14,
                    letterSpacing: 0.25
                },
                body: {
                    fontSize: 14,
                    letterSpacing: 0.25
                }
            }
        }
    })

    // handle routing for entity view
    navigateToEntityView = () => {
        this.props.navigateToEntityView("", this.props.location.pathname.split("/")[2]);
    }

    // handle delete functionality of selected record
    onDelete = (rowId, multipleDeletion) => {
        let rowsToBeArchived = [];
        if (multipleDeletion) {
            this.state.selectedRows.forEach(item => {
                rowsToBeArchived.push(item.id);
            })
        }
        else {
            rowsToBeArchived.push(rowId);
        }

        this.setState({
            rowsToBeArchived,
            openPromptModal: true,
            entity: "Losses"
        })
    }

    // handle user action for deleting
    handlePromptModal = (action) => {
        if (action === "agree") {
            let selectedtab = this.props.userData.selectedTab;
            let selectedNavTab = this.props.location.pathname.split("/")[2];
            let currentScopeSelectionId = this.getCurrentScopeSelectionId(selectedtab);

            if (this.state.entity === "Losses" && this.state.rowsToBeArchived[0]) {
                if (this.state.rowsToBeArchived.length > 0) {
                    this.props.deleteRow(selectedNavTab, currentScopeSelectionId, this.state.rowsToBeArchived);
                }
            }
            else if (this.state.entity === "LossList") {
                let selectedtab = this.props.userData.selectedTab;
                let selectedNavTab = this.props.location.pathname.split("/")[2];
                let currentScopeSelectionId = this.getCurrentScopeSelectionId(selectedtab);
                if (this.state.rowsToBeArchived[0]) {
                    this.props.deleteLossList(selectedNavTab, currentScopeSelectionId, this.state.rowsToBeArchived[0]);
                }
                this.closeDialog();
            }
        }
        this.setState({
            openPromptModal: false,
            rowsToBeArchived: []
        })
    }

    // handle user action for deleting lossLists
    onDeleteLossList = (rowId) => {
        let rowsToBeArchived = [];
        rowsToBeArchived.push({ "id": rowId, "defaultLossList": this.state.defaultLossList });

        this.setState({
            rowsToBeArchived,
            openPromptModal: true,
            entity: "LossList"
        })
    }

    // handle edit functionality of selected record
    onEdit = (rowIndex) => {
        let object = this.props.tableData.tableData.find((item, index) => {
            return index === rowIndex
        })
        this.props.navigateToEntityView(object, "LossCodesEntity");
    }

    // handle routing for foreign keys in the table
    handleForeignKey = (rowIndex) => {
        let object = this.props.tableData.tableData.find(item => {
            return item.id === rowIndex
        });
        let selectedNavTab = this.props.location.pathname.split("/")[2];

        this.openDialog(true, object.lossLists, selectedNavTab);
    }

    // gets the id of current plant selected in scope selector
    getCurrentScopeSelectionId = (selectedTab) => {
        let selectedScopeData = "";
        if (this.props.location.pathname.split("/").length === 3) {
            let currentEntity = this.props.location.pathname.split("/")[2];

            if (currentEntity === "OrganizationsTable" || currentEntity === "PlantsTable" || currentEntity === "UsersTable") {
                selectedScopeData = this.props.userData.selectedOrganization;
            }
            else if (currentEntity === "MachineGroupsTable" || currentEntity === "MachinesTable" || currentEntity === "SchedulesTable" ||
                currentEntity === "JobsTable" || currentEntity === "LossCodesTable" || currentEntity === "MonitorsTable" || currentEntity === "DevicesTable" ||
                currentEntity === "AlertsTable") {
                selectedScopeData = this.props.userData.selectedPlant;
            }
        }
        if (selectedScopeData) {
            return selectedScopeData;
        }
    }

    componentDidMount() {
        let selectedNavTab = this.props.location.pathname.split("/")[2];
        let selectedtab = this.props.userData.selectedTab;
        let currentScopeSelectionId = this.getCurrentScopeSelectionId(selectedtab);
        if (currentScopeSelectionId) {
            this.props.getTableData(selectedNavTab, currentScopeSelectionId, false);
            this.displayCreateButton();
        }
    }

    // initializes table component based on the view-definitions received from the api call
    tableConfigurations = () => {
        let columns = [];
        let data = [];
        let currentColumnObject = {};

        if (this.props.tableData.tableData.length > 0 && Object.keys(this.props.config).length > 0) {

            if (this.props.userData.permission === 'configuration') {
                if (this.props.config.Losses.edit.table_visible) {
                    columns.push({
                        "name": "edit",
                        "label": this.props.config.Losses.edit.display_name,
                        "options": {
                            "display": true,
                            "filter": this.props.config.Losses.edit.filter_visible,
                            "sort": false,
                            "searchable": false
                        }
                    })
                }
                if (this.props.config.Losses.delete.table_visible) {
                    columns.push({
                        "name": "delete",
                        "label": this.props.config.Losses.delete.display_name,
                        "options": {
                            "display": true,
                            "filter": this.props.config.Losses.delete.filter_visible,
                            "sort": false,
                            "searchable": false
                        }
                    })
                }
            }

            for (let key in this.props.tableData.tableData[0]) {
                let currentConfigObject = this.props.config["Losses"][key];
                if (currentConfigObject !== undefined) {
                    currentColumnObject.name = currentConfigObject["key"];
                    currentColumnObject.label = currentConfigObject["display_name"];
                    currentColumnObject.options = {};

                    if (currentConfigObject["table_visible"]) {
                        currentColumnObject.options.display = true;
                        currentColumnObject.options.filter = currentConfigObject["filter_visible"];

                        if (currentConfigObject["ellipsis"]) {
                            currentColumnObject.options.customBodyRender = (value) => <div style={{ maxWidth: 150, overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}>{value}</div>
                            currentColumnObject.options.sort = false;
                            currentColumnObject.options.searchable = false;
                        }

                        if (currentConfigObject["table_hyperlink"]) {
                            currentColumnObject.options.customBodyRender = (value, tableMeta) => {
                                if (this.props.userData.permission === "configuration") {
                                    for (let loss of this.props.tableData.tableData) {
                                        if (loss && loss.lossLists) {
                                            if (loss.lossLists.name === value) {
                                                if (loss.lossLists.isActive) {
                                                    return (<a onClick={() => this.handleForeignKey(tableMeta.rowData[2], currentConfigObject["key"])}>{value}</a>)
                                                }
                                                else {
                                                    return (
                                                        <span style={{ color: 'rgba(0, 0, 0, 0.38)' }}>{value}</span>
                                                    )
                                                }
                                            }
                                        }
                                    }
                                }
                                else {
                                    return value;
                                }
                            }
                        }
                    }
                    else {
                        currentColumnObject.options.display = "excluded";
                        currentColumnObject.options.filter = false;
                        currentColumnObject.options.sort = false;
                        currentColumnObject.options.searchable = false;
                    }

                    columns.push(currentColumnObject)
                    currentColumnObject = {};
                }
            }
        }

        data = this.props.tableData.tableData.map((item, index) => {

            item.edit = <Edit classes={{ root: this.props.classes.editIcon }} onClick={() => this.onEdit(index)} />;
            item.delete = <Delete classes={{ root: this.props.classes.deleteIcon }} onClick={() => this.onDelete(item.id, false)} />;

            return item;
        });

        this.setState({
            columns,
            data
        })

    }

    // display create new button based on user permissions
    displayCreateButton = () => {
        let flag = false;
        let plantPermisssion = localStorage.getItem("plantPermisssion")
        if (this.props.userData.permission === 'configuration' || plantPermisssion === 'configuration') {
            flag = true;
        }
        else if (this.props.userData.permission === "dashboard" && plantPermisssion === 'configuration') {
            flag = false
        }
        flag === true ?
            this.setState({ displayCreateButton: true })
            :
            this.setState({ displayCreateButton: false })
    }

    componentDidUpdate(prevProps, prevState) {
        let selectedtab = this.props.userData.selectedTab;
        let selectedNavTab = this.props.location.pathname.split("/")[2];
        if ((prevProps.tableData !== this.props.tableData)) {
            let headerClass = "";
            if (this.props.userData.permission === "configuration") {
                headerClass = this.props.userData.permission === "configuration" ? this.props.classes.hideHeaderForActiveData : "";
            }
            this.setState({
                headerClass
            })
            if ((prevProps.selectedScopeData !== this.props.selectedScopeData)) {
                let currentScopeSelectionId = this.getCurrentScopeSelectionId(selectedtab);
                this.props.getTableData(selectedNavTab, currentScopeSelectionId);
                this.displayCreateButton();
            }
            else {
                this.tableConfigurations();
            }
        }
        else if ((prevProps.selectedScopeData !== this.props.selectedScopeData && prevProps.selectedTab === this.props.selectedTab)) {
            let currentScopeSelectionId = this.getCurrentScopeSelectionId(selectedtab);
            if (currentScopeSelectionId) {
                this.props.getTableData(selectedNavTab, currentScopeSelectionId);
                this.displayCreateButton();
            }
        }

    }

    // get selected plant name in the scope selector
    getPlantName = () => {
        if (this.props.selectedScopeData || this.props.selectedScopeData === "") {
            let tempdata = "";
            let found = false;
            this.props.scopeSelector.subOrganizations.map((organization) => {
                if (organization.plants && organization.plants.length > 0) {
                    organization.plants.map((plant) => {
                        if (plant.id === this.props.selectedScopeData && !found) {
                            tempdata = plant.name;
                            found = true;
                        }
                    })
                }
            })
            this.setState({
                plantId: this.props.selectedScopeData,
                plantName: tempdata,
            })
        }
    }

    // open lobList/lossList modal window upon click on foreign key
    openDialog = (edit, data) => {
        if (data !== undefined) {
            if (data !== "") {
                this.setState({
                    name: data.name,
                    notes: data.notes,
                    id: data.id,
                    openDialog: !this.state.openDialog,
                    diableSaveButton: false,
                    notesError: false,
                    lossListNameError: false,
                    displayDelete: edit,
                    defaultLossList: data.defaultLossList
                })
                if (data.plants && data.plants.id && data.plants.name) {
                    this.setState({
                        plantId: data.plants.id,
                        plantName: data.plants.name,
                    })
                }
            }
        }
        else {
            this.setState({
                name: "",
                notes: "",
                id: "",
                openDialog: !this.state.openDialog,
                diableSaveButton: false,
                notesError: false,
                lossListNameError: false,
            })
            this.getPlantName();
        }

        if (this.props.validationData && this.props.validationData.config && this.props.validationData.config.LossLists) {
            this.setState({ validationData: this.props.validationData.config.LossLists });
        }
    }

    // close lobList/lossList modal window
    closeDialog = () => {
        this.setState({
            openDialog: false,
            saveButton: false,
            displayDelete: false,
            defaultLossList: false
        })
    }

    // Handle changes to any user input field in jobList/lossList modal
    inputChangeHandler = (event, field) => {

        switch (field) {
            case "name": this.setState({ name: event.target.value, diableSaveButton: true });
                this.checkValidation("name", event.target.value);
                break;
            case "notes": this.setState({ notes: event.target.value, diableSaveButton: true });
                this.checkValidation("notes", event.target.value);
                break;
            case "defaultLossList": this.setState({
                defaultLossList: !this.state.defaultLossList,
                diableSaveButton: true
            });
            default: console.log("default case");
        }
    }

    // save or update jobList/lossList
    saveLossList = () => {
        let selectedtab = this.props.userData.selectedTab;
        let selectedNavTab = this.props.location.pathname.split("/")[2];
        let currentScopeSelectionId = this.getCurrentScopeSelectionId(selectedtab);
        const { name, notes, plantId, id, defaultLossList } = this.state
        if (id && id != null && id !== "") {
            this.props.updateLossListsEntity({ name, notes, plantId, id, defaultLossList }, id, selectedNavTab, currentScopeSelectionId);
        }
        else {
            this.props.updateLossListsEntity({ name, notes, plantId, defaultLossList }, id, selectedNavTab, currentScopeSelectionId);
        }
        this.closeDialog();
    }

    // disable save button based on validations
    disableSaveButton = () => {
        if (this.state.name === "" && (this.state.validationData.name.validations && this.state.validationData.name.validations.required)) {
            return true;
        } else if ((this.state.validationData.name.validations) && (this.state.name.length > this.state.validationData.name.validations.maxLength)) {
            return true;
        }
        else if (this.state.notes === "" && (this.state.validationData.notes.validations && this.state.validationData.notes.validations.required)) {
            return true;
        } else if ((this.state.validationData.notes.validations) && (this.state.notes.length > this.state.validationData.notes.validations.maxLength)) {
            return true;
        }
        else {
            return false;
        }
    }

    // check validations of the user input fields in jobList/lossList modal
    checkValidation = (field, value) => {
        switch (field) {
            case "name":
                if (!this.state.lossListNameError && value === "" && (this.state.validationData.name.validations && this.state.validationData.name.validations.required)) {
                    this.setState({ lossListNameError: true });
                } else if (!this.state.lossListNameError && (this.state.validationData.name.validations) && (value.length > this.state.validationData.name.validations.maxLength)) {
                    this.setState({ lossListNameError: true });
                } else if (this.state.lossListNameError) {
                    if ((value === "" && (this.state.validationData.name.validations && this.state.validationData.name.validations.required) || ((this.state.validationData.name.validations) && (value.length > this.state.validationData.name.validations.maxLength)))) {
                        //skip
                    }
                    else {
                        this.setState({ lossListNameError: false });
                    }
                };
                break;
            case "notes":
                if (!this.state.notesError && value === "" && (this.state.validationData.notes.validations && this.state.validationData.notes.validations.required)) {
                    this.setState({ notesError: true });
                } else if (!this.state.notesError && (this.state.validationData.notes.validations) && (value.length > this.state.validationData.notes.validations.maxLength)) {
                    this.setState({ notesError: true });
                } else if (this.state.notesError) {
                    if ((value === "" && (this.state.validationData.notes.validations && this.state.validationData.notes.validations.required) || ((this.state.validationData.notes.validations) && (value.length > this.state.validationData.notes.validations.maxLength)))) {
                        //skip
                    }
                    else {
                        this.setState({ notesError: false });
                    }
                };
                break;
        }
    }

    // purpose : create csv upload/download icons,user can create new Losses through csv by editing in csv file before uploading 
    //It uses  <CsvDownloadComponent/> and <CsvUploadComponent/>  which is imported from "../CsvUploadComponent" and "../CsvDownloadComponent"
    //This function also creates "Create NEW"  and "CREATE NEW LOSS LIST"button (for creating new Losses and Loss List through UI), which calls navigateToEntityView() and openDialog() function respectively when clicked
    getCustomToolbar = () => {
        if (this.state.displayCreateButton) {
            return (
                <div style={{ display: 'inline-block' }}>
                    {
                        this.props.userData.admin ?
                            (
                                <Fragment>
                                    <IconButton className={this.props.classes.iconBotton}>
                                        <CsvDownloadComponent
                                            parentOrganizationId={this.props.userData.parentOrganizationId}
                                            url={"downloadCSVForLosses"}
                                            selectedPlant={this.props.userData.selectedPlant}
                                        />
                                    </IconButton>
                                    <IconButton classes={{ label: this.props.classes.buttonLabel }} className={this.props.classes.iconBotton}>
                                        <CsvUploadComponent
                                            selectedNavTab={"LossCodesTable"}
                                            currentScopeSelectionId={this.props.userData.selectedPlant}
                                        />
                                    </IconButton>
                                </Fragment>
                            )
                            : null
                    }
                    <Button variant="outlined" className={this.props.classes.createNewButton} style={{ marginLeft: 12 }}
                        onClick={() => this.navigateToEntityView("", this.props.location.pathname.split("/")[2])}>
                        {this.props.t("Create New")}
                    </Button>
                    <div style={{ display: 'inline-block', marginLeft: 12 }}>
                        <Button variant="outlined" className={this.props.classes.createNewButton}
                            onClick={() => this.openDialog(false)}>
                            {this.props.t("Create New Loss List")}
                        </Button>
                    </div>
                </div>
            )
        }
        else {
            return null;
        }
    }

    // update selected records for bulk editing
    updateSelectedRecords = (updatedObject) => {

        for (let row of this.state.selectedRows) {
            if (updatedObject.changedDescription) {
                row.description = updatedObject.description;
            }
            if (updatedObject.changedLossList) {
                row.lossLists = updatedObject.lossList;
            }
            if (updatedObject.changedSubDescription) {
                row.subDescription = updatedObject.subDescription;
            }
            if (updatedObject.changedSpecs) {
                row.specs = updatedObject.specs;
            }
            if (updatedObject.changedLossCode) {
                row.lossCode = updatedObject.lossCode;
            }
            if (updatedObject.changedLossLabel) {
                row.lossLabel = updatedObject.lossLabel;
            }
            if (updatedObject.changedLossSubCode) {
                row.lossSubcode = updatedObject.lossSubCode;
            }
            if (updatedObject.changedLossSubLabel) {
                row.lossSublabel = updatedObject.lossSubLabel;
            }
        }

        let selectedNavTab = this.props.location.pathname.split("/")[2];
        let selectedtab = this.props.userData.selectedTab;
        let currentScopeSelectionId = this.getCurrentScopeSelectionId(selectedtab);

        this.props.updateAll(selectedNavTab, currentScopeSelectionId, this.state.selectedRows);
    }

    // enable bulk editing view
    enableBulkEditingView = () => {
        this.setState({
            enableBulkEditingView: true
        })
    }

    // disable bulk editing view
    disableBulkEditingView = () => {
        this.setState({
            enableBulkEditingView: false
        })
    }

    // create custom toolbar for table view
    getCustomToolbarSelect = () => {
        return (
            <div>
                <IconButton onClick={this.enableBulkEditingView}>
                    <Launch />
                </IconButton>
                <IconButton style={{ marginRight: 14 }} onClick={() => this.onDelete(undefined, true)}>
                    <Delete classes={{ root: this.props.classes.bulkEditDeleteIcon }} />
                </IconButton>
            </div>
        )
    }

    // handle multiple record selection
    selectedRows = (currentRowsSelected, allRowsSelected) => {
        let selectedRows = [];
        for (let data of allRowsSelected) {
            let selectedIndex = data.dataIndex;
            let selectedObject = this.state.data[selectedIndex];

            let newObject = {};
            newObject.description = selectedObject.description;
            newObject.id = selectedObject.id;
            newObject.lossCode = selectedObject.lossCode;
            newObject.lossLabel = selectedObject.lossLabel;
            newObject.lossLists = selectedObject.lossLists !== null ? selectedObject.lossLists.id : null;
            newObject.lossSubcode = selectedObject.lossSubcode;
            newObject.lossSublabel = selectedObject.lossSublabel;
            newObject.specs = selectedObject.specs;
            newObject.subDescription = selectedObject.subDescription;

            selectedRows.push(newObject)
        }

        this.setState({
            selectedRows
        })
    }

    // validate multiple record selection based on user permissions
    isRowSelectable = () => {
        let isSelectable = linux_deployment_platform ? "none" : false;
        if (this.state.data && this.state.data.length > 0) {
            if (this.props.userData.permission === "configuration") {
                isSelectable = this.state.data.length === 0 ? (linux_deployment_platform ? "none" : false) : "multiple";
            }
        }
        return isSelectable;
    }

    render() {
        const { t } = this.props;
        const options = {
            filter: true,
            sort: true,
            search: true,
            customToolbar: () => (this.getCustomToolbar()),
            print: false,
            elevation: 0,
            responsive: "scroll",
            download: false,
            filterType: 'dropdown',
            rowsPerPage: 20,
            rowsPerPageOptions: [20, 50, 100],
            selectableRows: this.isRowSelectable(),
            onRowsSelect: (currentRowsSelected, allRowsSelected) => this.selectedRows(currentRowsSelected, allRowsSelected),
            customToolbarSelect: () => (this.getCustomToolbarSelect()),
            textLabels: {
                body: {
                    noMatch: this.props.t("No matching records")
                },
                pagination: {
                    next: this.props.t("Next Page"),
                    previous: this.props.t("Previous Page"),
                    rowsPerPage: this.props.t("Rows per Page"),
                    displayRows: this.props.t("of")
                },
                toolbar: {
                    search: this.props.t("Search"),
                    download: this.props.t("Download"),
                    print: this.props.t("Print"),
                    viewColumns: this.props.t("View Columns"),
                    filterTable: this.props.t("Filter Table"),
                    upload: this.props.t("Upload"),
                    // download:currentLanguage==="en" ? "Download":"アップロード",
                    showArchivedData: this.props.t("Show Archieved Data"),
                    showActiveData: this.props.t("Show Active Data")


                },
                filter: {
                    title: this.props.t("FILTERS"),
                    reset: this.props.t("Reset")
                },
                viewColumns: {
                    title: this.props.t("Show Columns")
                },
                selectedRows: {
                    text: this.props.t("Reset"),
                    delete: this.props.t("Delete")
                }

            }
        }

        return (
            <Fragment>
                <ConfirmationModal
                    props={this.props}
                    openPromptModal={this.state.openPromptModal}
                    handlePromptModal={(action) => this.handlePromptModal(action)}
                    delete={(this.state.entity === "Losses") ? true : false}
                />
                <div id="tableContainer" className={this.state.headerClass}>
                    <MuiThemeProvider theme={this.getMuiTheme()}>
                        {
                            this.state.data !== null ? (
                                <MUIDataTable
                                    title={this.props.t("Losses")}
                                    data={this.state.data}
                                    columns={this.state.columns}
                                    options={options}
                                />
                            ) : <Fragment></Fragment>
                        }
                    </MuiThemeProvider>
                </div>
                {
                    this.state.enableBulkEditingView ?
                        <LossCodesModal
                            config={this.props.config.Losses}
                            selectedPlant={this.props.userData.selectedPlant}
                            updateSelectedRecords={(state) => (this.updateSelectedRecords(state))}
                            disableBulkEditingView={this.disableBulkEditingView}
                            enableBulkEditingView={this.state.enableBulkEditingView} />
                        : null
                }
                <div>
                    <Dialog
                        onClose={this.closeDialog}
                        aria-labelledby="customized-dialog-title"
                        open={this.state.openDialog}
                        classes={{ paperWidthSm: this.props.classes.lossListModal }}
                    >
                        <div className={this.props.classes.dialogTitle}>
                            <Typography variant="h6">{this.props.t("Loss List")}</Typography>
                            <div style={{ display: 'flex', alignItems: 'center' }}>
                                <div style={{ marginRight: 24 }}>
                                    <Checkbox
                                        checked={this.state.defaultLossList}
                                        onChange={(event) => (this.inputChangeHandler(event, "defaultLossList"))}
                                        color="secondary"
                                    />
                                    <span className={this.props.classes.inputLabel} style={{ marginLeft: 0 }}>{this.props.t("Set as plant default")}</span>
                                </div>
                                {(this.state.displayDelete) ? (
                                    <Archive classes={{ root: this.props.classes.archiveIcon }} onClick={() => this.onDeleteLossList(this.state.id)} />)
                                    : null
                                }
                                <span style={{ display: 'inline-block', marginLeft: 15 }}>
                                    <Close onClick={this.closeDialog} style={{ cursor: 'pointer' }} />
                                </span>
                            </div>
                        </div>
                        <Divider />
                        <DialogContent classes={{ root: this.props.classes.dialogContent }}>
                            {this.state.validationData && this.state.validationData.name && this.state.validationData.name.page_visible ? (
                                <div key={this.state.validationData.name.display_name} className={this.props.classes.elementContainer}>
                                    <div className={this.props.classes.inputLabel}>{this.state.validationData.name.display_name} {(this.state.validationData.name.validations && this.state.validationData.name.validations.required) ? (<span className={this.props.classes.requiredClass}>*</span>) : null} </div>
                                    <TextField
                                        required
                                        id="name"
                                        className={this.props.classes.textField}
                                        helperText={(this.state.validationData.name.validations && this.state.validationData.name.validations.helpText) ? (this.state.validationData.name.validations.helpText) : null}
                                        value={this.state.name}
                                        margin="normal"
                                        variant="outlined"
                                        onChange={(event) => this.inputChangeHandler(event, "name")}
                                        error={this.state.lossListNameError}
                                    />
                                </div>
                            )
                                : null
                            }
                            {/* <div key={"Plant Name"} className={this.props.classes.elementContainer}>
                                <div className={this.props.classes.inputLabel}>{"Plant Name"}</div>
                                <TextField
                                    required
                                    id="plantName"
                                    disabled
                                    className={this.props.classes.textField}
                                    value={this.state.plantName}
                                    margin="normal"
                                    variant="outlined"
                                />
                            </div> */}
                            {this.state.validationData && this.state.validationData.notes && this.state.validationData.notes.page_visible ? (
                                <div key={this.state.validationData.notes.display_name} className={this.props.classes.elementContainer}>
                                    <div className={this.props.classes.inputLabel}>{this.state.validationData.notes.display_name} {(this.state.validationData.notes.validations && this.state.validationData.notes.validations.required) ? (<span className={this.props.classes.requiredClass}>*</span>) : null}</div>
                                    <TextField
                                        required
                                        id="notes"
                                        multiline
                                        helperText={(this.state.validationData.notes.validations && this.state.validationData.notes.validations.helpText) ? (this.state.validationData.notes.validations.helpText) : null}
                                        rows="3"
                                        className={this.props.classes.textField}
                                        value={this.state.notes}
                                        margin="normal"
                                        variant="outlined"
                                        onChange={(event) => this.inputChangeHandler(event, "notes")}
                                        error={this.state.notesError}
                                    />
                                </div>
                            )
                                : null
                            }
                        </DialogContent>
                        <DialogActions classes={{ root: this.props.classes.dialogActions }}>
                            <Button
                                variant="contained"
                                color="primary"
                                disabled={!this.state.diableSaveButton || this.disableSaveButton()}
                                onClick={this.saveLossList}
                                className={this.props.classes.dialogSaveButton}
                            >
                                {t("Save Changes")}
                            </Button>
                        </DialogActions>
                    </Dialog>
                </div>
            </Fragment >
        );
    }
}

const mapStateToProps = state => {
    return {
        userData: state.userDataReducer,
        tableData: state.tableDataReducer,
        validationData: state.viewDefinitionReducer
    }
}

const mapDispatchToProps = dispatch => {
    return {
        getTableData: (selectedNavtab, currentScopeSelectionId, setToStore) => (dispatch(actionCreators.getTableData(selectedNavtab, currentScopeSelectionId, setToStore))),
        deleteRow: (selectedNavTab, currentScopeSelectionId, requestObject) => (dispatch(actionCreators.deleteRow(selectedNavTab, currentScopeSelectionId, requestObject))),
        updateLossListsEntity: (lossListEntityData, id, selectedNavTab, currentScopeSelectionId) => (dispatch(actionCreators.updateLossListsEntity(lossListEntityData, id, selectedNavTab, currentScopeSelectionId))),
        deleteLossList: (selectedNavTab, currentScopeSelectionId, id) => (dispatch(actionCreators.deleteJobAndLossList(selectedNavTab, currentScopeSelectionId, id))),
        updateAll: (selectedNavTab, currentScopeSelectionId, allRecords) => (dispatch(actionCreators.updateAll(selectedNavTab, currentScopeSelectionId, allRecords)))
    }
}

export default withTranslation()(connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(LossCodesTable)));
