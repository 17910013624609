import React, { Component } from 'react';
import { Paper, Tabs, Tab, withStyles } from '@material-ui/core';
import { connect } from 'react-redux';

import * as actionCreators from '../actions/actionCreators';

const styles = theme => ({
    paper: {
        borderRadius: 0,
        marginBottom: 1,
        boxShadow: '0 0 0 0',
        backgroundColor: '#f2f3f8',
        height: '10vh'
    },
    enabledTabs: {
        color: 'rgba(24, 32, 44, 0.8700000047683716)',
        letterSpacing: 0.1
    },
    selectedTab: {
        color: '#5ACBE5 !important'
    },
    tabIndicator: {
        backgroundColor: '#5ACBE5'
    }
});

class FunctionTabs extends Component {
    
    componentDidUpdate(prevProps) {
        if(prevProps.selectedScopeData === undefined && this.props.selectedScopeData && this.props.selectedTab !== 0 && this.props.selectedTab !== 4) {
            this.props.history.push(this.props.history.location.pathname);
            // this.props.isAdmin ? this.props.history.push("/dashboard/UsersTable") : this.props.history.push("/dashboard/MonitorsTable");
        }
    }

    handleChange = (event, value) => {
        if(this.props.selectedTab !== value){
            if(value === 3) { 
                this.props.history.push("/dashboard/UsersTable") 
            }else if(value === 0){
                if(this.props.selectedTab === 0){
                    this.props.history.push("/dashboard/MonitoringTable");
                }else{
                    this.props.history.push("/dashboard/Monitoring");
                }
            }else if(value=== 4){
            //  this.props.history.push("/dashboard/ProductionDashboard")
            }else{
                this.props.history.push("/dashboard/MonitorsTable");
            }
            this.props.updateSelectedTab(value);
            this.props.updateDrawer(true);
        }
    };

    getTabs = () => {
        const tabs = [];
        if (this.props.tabs) {
            for (let item of this.props.tabs) {
                switch (item.key) {
                    case 'Monitoring': tabs.push(<Tab label={item.value} key={item.key} classes={{ textColorPrimary: this.props.classes.enabledTabs,selected: this.props.classes.selectedTab }} />);
                        break;
                    case 'Reporting': tabs.push(<Tab label={item.value} key={item.key} disabled classes={{ selected: this.props.classes.selectedTab }} style={{ display: 'none' }}/>);
                        break;
                    case 'Configuration': tabs.push(<Tab label={item.value} key={item.key} classes={{ textColorPrimary: this.props.classes.enabledTabs, selected: this.props.classes.selectedTab }} />);
                        break;
                    case 'Administration': if (this.props.isAdmin) {
                        tabs.push(<Tab label={item.value} key={item.key} classes={{ textColorPrimary: this.props.classes.enabledTabs, selected: this.props.classes.selectedTab }} />);
                    } else {
                        tabs.push(<Tab label={item.value} key={item.key} disabled classes={{ selected: this.props.classes.selectedTab }} />);
                    }
                        break;
                    case 'Production': tabs.push(<Tab label={item.value} key={item.key} classes={{ textColorPrimary: this.props.classes.enabledTabs, selected: this.props.classes.selectedTab }} />);
                    break;
                    default: return null;
                }
            }
        }
        return tabs;
    }

    render() {
        return (
            <Paper position="static" color="default" className={this.props.classes.paper}>
                <Tabs
                    value={0}
                    onChange={this.handleChange}
                    indicatorColor="primary"
                    textColor="primary"
                    variant="fullWidth"
                    centered
                    TabIndicatorProps={{
                        classes: {
                            colorPrimary: this.props.classes.tabIndicator
                        }
                    }}
                >
                    {this.getTabs()}
                </Tabs>
            </Paper>
        );
    }
}

const mapDispatchToProps = dispatch => {
    return {
        updateSelectedTab: (selectedTab) => (dispatch(actionCreators.updateSelectedTab(selectedTab))),
        updateDrawer : (value)=> (dispatch(actionCreators.updateDrawer(value))),        
    }
}

export default connect(null, mapDispatchToProps)(withStyles(styles)(FunctionTabs));
