import React, { Component } from 'react'
import Chart from "react-google-charts";
import moment from "moment";
import { withTranslation } from "react-i18next";

class DowntimeAnalysisContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [],
      machines: [],
      options: {},
      maxValue: 0,
      ticksList: []
    }
  }

  componentDidMount() {
    this.drawHistogram(this.props.downtimeDataList.downtimeData);
  }

  componentDidUpdate(prevProps) {
    if(prevProps.downtimeDataList.downtimeData !== this.props.downtimeDataList.downtimeData){
      if (prevProps.downtimeOption !== this.props.downtimeOption && this.props.downtimeOption === "histogram") {
        this.drawHistogram(this.props.downtimeDataList.downtimeData);
      }
      if (prevProps.downtimeOption !== this.props.downtimeOption && this.props.downtimeOption === "pareto") {
        this.drawPareto(this.props.downtimeDataList.downtimeData);
      }
    }
    if (prevProps.downtimeOption !== this.props.downtimeOption && this.props.downtimeOption === "histogram") {
      this.drawHistogram(this.props.downtimeDataList.downtimeData);
    }
    if (prevProps.downtimeOption !== this.props.downtimeOption && this.props.downtimeOption === "pareto") {
      this.drawPareto(this.props.downtimeDataList.downtimeData);
    }
  }

  drawPareto = (rawData) => {
    let machines = Object.keys(rawData);
    let tempData = []
    machines.forEach(machine => {
      let total = 0;
      var sum = 0;
      for (let row = 0; row < rawData[machine].downtime.length; row++) {
        sum += rawData[machine].downtime[row].duration;
      }
      rawData[machine].downtime.sort(function (a, b) {
        return b.duration - a.duration;
      });
      let header = [[machine, "Value0", { type: "string", role: "tooltip" }, "Cumulative Frequency", { type: "string", role: "tooltip" }]];
      rawData[machine].downtime.forEach(ele => {
        total += ele.duration;
        header.push([moment(ele.timestamp).utc().local().format("YYYY-MM-DD HH:mm:ss"), ele.duration, `${moment(ele.timestamp).utc().local().format("YYYY-MM-DD HH:mm:ss")} : ${moment(ele.duration * 1000).format('HH:mm:ss')}`, total, `${moment(ele.timestamp).utc().local().format("YYYY-MM-DD HH:mm:ss")}\nCumulative Percent: ${((total / sum) * 100).toFixed(2)}%\nCumulative Frequency: ${total}`])
      });
      tempData.push({ [machine]: header });

    });
    this.setState(
      {
        data: tempData,
        machines: machines
      });
  }

  drawHistogram = (rawData) => {
    let machines = Object.keys(rawData);
    let tempData = [];
    let maxValue = 0;
    let ticksList = [];
    machines.forEach(machine => {
      let data1 = [[machine, "Duration"]];
      rawData[machine].downtime.forEach(element => {
        if (maxValue < element.duration)
          maxValue = element.duration;
        data1.push([moment(element.timestamp).utc().local().format("YYYY-MM-DD HH:mm:ss"), element.duration]);
      });
      tempData.push({ [machine]: data1 });

    });
    maxValue = parseInt(maxValue + 1);
    maxValue = parseInt(maxValue / 25 + 1) * 25;
    for (var k = 0; k <= 5; k++) {
      ticksList.push({ v: (maxValue / 5 * k), f: ((maxValue / 5 * k) + ' mins') });
    }
    this.setState(
      {
        data: tempData,
        machines: machines,
        maxValue: maxValue,
        ticksList: ticksList
      });
  }

  render() {
    const{t}=this.props;
    return (
      <div className="downtimeAnalysis-cntr display-text" style={{ height: "340px", overflowY: "scroll", marginLeft: "8px", width: "97%" }}>

        {this.state.machines.map((machine, index) => (
          <div>
            {this.props.downtimeOption === "histogram" ?
              <Chart
                chartType="Histogram"
                width="100%"
                height="300px"
                graph_id={`ComboChart+${index}`}
                data={this.state.data[index][machine]}
                options={{
                  title: "Histogram of " + machine,
                  height: 300,
                  legend: "none",
                  hAxis: {
                    ticks: this.state.ticksList
                  },
                  bar: { gap: 1, groupWidth: "95%" },
                  histogram: {
                    bucketSize: this.state.maxValue / 25,
                    maxNumBuckets: 25,
                    minValue: 0,
                    maxValue: this.state.maxValue
                  },
                  explorer: {
                    actions: ["dragToZoom", "rightClickToReset"],
                    maxZoomOut: 1,
                    maxZoomIn: 32,
                    keepInBounds: true
                  }
                }} />
              :
              null
            }
            {this.props.downtimeOption === "pareto" ?

              <Chart
                width="100%"
                height="300px"
                graph_id={`ComboChart+${index}`}
                chartType="ComboChart"
                loader={<div>{t("Loading Chart")}</div>}
                data={this.state.data[index][machine]}
                options={{
                  title: "Pareto of " + machine,
                  isStacked: true,
                  vAxis: { title: '' },
                  hAxis: { title: '' },
                  legend: 'none',
                  seriesType: 'bars',
                  series: [{},
                  { type: "line" }]
                }}
                rootProps={{ 'data-testid': '1' }}
              />
              :
              null
            }
          </div>
        ))}
      </div>
    )
  }
}

export default withTranslation()(DowntimeAnalysisContainer);