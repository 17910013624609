import { WebStorageStateStore, UserManager } from "oidc-client-ts";
import { inMemoryWebStorage } from "./oidcStateManager";

const userStore = new WebStorageStateStore({ store: inMemoryWebStorage });

const oidcConfig = {
  authority: process.env.REACT_APP_OIDC_AUTHORITY_URL,
  client_id: process.env.REACT_APP_OIDC_CLIENT_ID,
  redirect_uri: process.env.REACT_APP_OIDC_LOGIN_REDIRECT_URI,
  post_logout_redirect_uri: process.env.REACT_APP_OIDC_LOGOUT_REDIRECT_URI,
  automaticSilentRenew: false,
  monitorSession: true,
  accessTokenExpiringNotificationTimeInSeconds: 10,
  onSigninCallback: () => {
    window.history.replaceState({}, document.title, window.location.pathname);
  },
  userStore: userStore,
};

const userManager = new UserManager(oidcConfig);

export const authProvider = {
  userManager,
  oidcConfig,
};
