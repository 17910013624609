import * as actionTypes from "../actions/actionTypes";

export const appsNameReducer = (state = [], action) => {
  switch (action.type) {
    case actionTypes.SHOW_APP_LIST:
      state = action.payload;
      return state;
    default:
      return state;
  }
};
