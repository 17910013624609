import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import {
    Card,
    CardContent,
    TextField,
    Select,
    Switch,
    FormControlLabel,
    Checkbox,
    Button,
    Radio,
    RadioGroup,
    InputAdornment,
    Drawer,
    ExpansionPanel,
    ExpansionPanelSummary,
    Typography,
    ExpansionPanelDetails,
    IconButton,
    Grid,
    OutlinedInput,
    Divider,
    Chip,
    ListItemText,
    InputLabel,
    MenuItem,
    Tooltip
} from '@material-ui/core';
import { Visibility, VisibilityOff, ExpandMore, Close, Delete, KeyboardBackspace } from '@material-ui/icons';
import { connect } from 'react-redux';
import { withTranslation } from "react-i18next";


import * as actionCreators from '../../actions/actionCreators';
const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250,
        },
    },

};

const styles = theme => ({
    cardContent: {
        height: '91%',
        "overflow-y": "auto"
    },
    cardContentRoot: {
        '&:last-child': {
            paddingBottom: 16
        }
    },
    card: {
        width: '100%',
        borderRadius: 0,
        height: '80vh'
    },
    elementContainer: {
        display: 'flex',
        'justify-content': 'space-between',
        'align-items': 'baseline',
        padding: '11.5px 0'
    },
    textField: {
        width: '60%',
        marginRight: 20
    },
    textArea: {
        width: '80%',
        marginRight: 20
    },
    outlinedInput: {
        padding: 14
    },
    radioGroup: {
        'flex-wrap': 'nowrap',
        'flex-direction': 'row'
    },
    chips: {
        display: 'flex',
        flexWrap: 'wrap',
    },
    chip: {
        backgroundColor: "#ef6e37 !important",
        margin: 2,
    },
    selected: {
        backgroundColor: "#f97942e6 !important",
        color: "white",
    },
    inputFieldDiv: {
        display: "flex",
        justifyContent: "space-between",
        width: '50%',
        float: 'left',
        paddingTop: '1%',
        height: 80,
        'align-items': 'baseline'
    },
    textareaInputFieldDiv: {
        display: "flex",
        justifyContent: "space-between",
        width: '100%',
        float: 'left',
        paddingTop: '1%',
        height: 180,
        'align-items': 'baseline'
    },
    textFieldLabel: {
        color: 'rgba(0, 0, 0, 0.4000000059604645)',
        'letter-spacing': 0.5,
        'font-weight': 500,
        fontSize: "14px",
        lineHeight: 1.5,
        width: '30%',
        marginLeft: 20,
        //text-align': 'right'
    },
    textareaFieldLabel: {
        color: 'rgba(0, 0, 0, 0.4000000059604645)',
        'letter-spacing': 0.5,
        'font-weight': 500,
        fontSize: "14px",
        lineHeight: 1.5,
        width: '15%',
        marginLeft: 20,
        //'text-align': 'right'
    },
    plantsContainer: {
        width: '75%',
        display: 'flex',
        'flex-direction': 'column',
        'min-height': 52
    },
    actionButtonContainer: {
        display: 'flex',
        'justify-content': 'space-between',
        height: 50,
        'border-bottom': '1px solid rgba(207, 216, 220, 0.5)'
    },
    saveButton: {
        fontSize: "14px",
        fontWeight: "500",
        textAlign: "center",
        color: "#ffffff",
        backgroundColor: "#42D784",
        '&:hover': {
            backgroundColor: '#42d784'
        },
        '&:disabled': {
            backgroundColor: '#f6f6f6'
        },
        borderRadius: "3px",
        boxShadow: "0 0 1px 0 rgba(0, 0, 0, 0.05)",
        width: "110px",
        height: "36px",
        marginRight: "24px"
    },
    addPlantsButton: {
        color: '#3C4858'
    },
    visibilityIcon: {
        color: '#4c4c4c',
        cursor: 'pointer'
    },
    visibilityOffIcon: {
        color: '#afacac'
    },
    drawer: {
        'min-width': 508,
        // 'max-width': 730,
        backgroundColor: '#eff2f7',
        overflow: 'hidden'
    },
    drawerHeaderContainer: {
        flex: '0 0 60px',
        'border-bottom': '1px solid rgba(0, 0, 0, 0.3)',
        display: 'flex',
        'justify-content': 'space-between',
        'align-items': 'center',
        padding: '0 24px'
    },
    drawerTitle: {
        'font-size': 20,
        color: '#18202c',
        'letter-spacing': 0.5,
    },
    drawerCloseIcon: {
        cursor: 'pointer'
    },
    expensionPanelDetails: {
        'flex-direction': 'column'
    },
    expansionPanel: {
        'border-radius': 0,
        '&:first-child': {
            'border-radius': 0,
            'border-top': '1px solid rgba(0, 0, 0, 0.14)'
        },
        '&:last-child': {
            'border-radius': 0,
            'border-bottom': '1px solid rgba(0, 0, 0, 0.14)'
        },
        marginLeft: 24,
        marginRight: 24
    },
    AddPlantsButtonEnabled: {
        width: 145,
        backgroundColor: '#42D784',
        '&:hover': {
            backgroundColor: '#42D784'
        }
    },
    AddPlantsButtonContainer: {
        flex: '0 0 60px',
        'box-sizing': 'border-box',
        display: 'flex',
        'justify-content': 'flex-end',
        'align-items': 'center',
        padding: '0 24px'
    },
    flexButtonContainer: {
        display: 'flex',
        'justify-content': 'flex-end',
        paddingRight: 24,
        paddingTop: 15
    },
    backButtonContainer: {
        display: 'flex',
        'align-items': 'center',
        paddingLeft: 21
    },
    entityTitle: {
        color: 'rgba(0, 0, 0, 0.8)',
        'font-family': 'Roboto',
        'font-size': 21,
        'font-weight': '400',
        'letter-spacing': 0.5,
        marginLeft: 10
    },
    arrowBack: {
        cursor: 'pointer'
    },
    deleteIcon: {
        'font-size': 18,
        cursor: 'pointer'
    },
    containedHelperText: {
        margin: '3px 5px 0'
    },
    sup: {
        top: 0,
        color: 'red',
        fontSize: '100%'
    }
});
const alertTypeList = [{ "key": "DISCONNECTION", "value": "Disconnection" }, { "key": "BATTERY_LOW", "value": "Battery Low" }]
class UsersEntity extends Component {

    state = {
        "id": "",
        "Firstname": "",
        "Lastname": "",
        "Password": null,
        "Organizations": [],
        "Organization": {
            "id": "",
            "name": ""
        },
        "Admin": false,
        "isPasswordExpire": false,
        "Plants": [],
        "UserPlants": [],
        "AllPlants": [],
        "Phone": "",
        "Email": "",
        "Language": "",
        "Timezone": "(GMT+5:30) IST",
        "Alerts": ["none", "info", "warning", "critical"],
        "Alertmedia": "EMAIL",
        "Notes": "",
        labelWidth: 0,
        "displayPassword": false,
        "AddNewRecord": false,
        "AddPlants": false,
        "AddPlantsButtonEnabled": false,
        "validFirstname": null,
        "validLastname": null,
        "validPassword": null,
        "validOrganizationName": null,
        "validPhoneNumber": null,
        "validEmailAddress": null,
        "validLanguage": null,
        "validTimezone": null,
        "validAlertmedia": null,
        "validAdmin": null,
        "validNotes": null,
        "somethingChanged": null,
        "alertTypes": ["DIAGNOSTIC"],
        alertTypeError: false,
        "plantPermissions": {}
    }

    // show password field
    displayPassword = () => {
        this.setState({ displayPassword: true })
    }

    // hide password fiels
    hidePassword = () => {
        this.setState({ displayPassword: false })
    }

    // update users entity
    updateUser = () => {
        let object = {};

        object.firstName = this.state.Firstname;
        object.lastName = this.state.Lastname;
        object.password = this.state.Password;
        object.admin = this.state.Admin;
        object.phoneNumber = this.state.Phone;
        object.language = this.state.Language;
        object.organizationName = this.state.Organization.name;
        object.emailAddress = this.state.Email;
        object.alertMedia = this.state.Alertmedia;
        object.alertTypes = this.state.alertTypes ? this.state.alertTypes : [];
        object.notes = this.state.Notes;

        object.plants = this.state.UserPlants.map(e => {
            return { ...e, enableAlert: this.state.plantPermissions[`${e.id}`] }
        })
        object.timezone = this.state.Timezone;
        object.expirePassword = this.state.isPasswordExpire;

        if (!this.state.AddNewRecord) {
            object.id = this.state.id;
        }

        this.props.updateUser(object, this.state.AddNewRecord);
    }

    // Handle changes to any user input field
    onChangeHandler = (event, element) => {
        switch (element) {
            // case "Organization":
            //     this.validate("organizationName", event.target.value, this.props.config.Users["organizationName"].validations);
            //     let currentOrganization = this.state.Organizations.find((organization) => {
            //         return event.target.value === organization.name
            //     })

            //     this.props.getPlantsForUser(currentOrganization.id, undefined);

            //     this.setState({
            //         Organization: { ...currentOrganization },
            //         somethingChanged: true
            //     })
            //

            case "Firstname":
                this.validate("firstName", event.target.value, this.props.config.Users["firstName"].validations);
                this.setState({
                    Firstname: event.target.value,
                    somethingChanged: true
                })
                break;

            case "Lastname":
                this.validate("lastName", event.target.value, this.props.config.Users["lastName"].validations);
                this.setState({
                    Lastname: event.target.value,
                    somethingChanged: true
                })
                break;

            case "Admin":
                this.validate("admin", event.target.value, this.props.config.Users["admin"].validations);
                this.setState({
                    Admin: !this.state.Admin,
                    somethingChanged: true
                })
                break;
            case "isPasswordExpire":
                this.setState({
                    isPasswordExpire: !this.state.isPasswordExpire,
                    somethingChanged: true
                })
                break;

            case "Phone":
                this.validate("phoneNumber", event.target.value, this.props.config.Users["phoneNumber"].validations);
                this.setState({
                    Phone: event.target.value,
                    somethingChanged: true
                })
                break;

            case "Email":
                this.validate("emailAddress", event.target.value, this.props.config.Users["emailAddress"].validations);
                this.setState({
                    Email: event.target.value,
                    somethingChanged: true
                })
                break;

            /* case "Password":
                this.validate("password", event.target.value, this.props.config.Users["password"].validations);
                this.setState({
                    Password: event.target.value,
                    somethingChanged: true
                })
                break; */

            case "Language":
                this.validate("language", event.target.value, this.props.config.Users["language"].validations);
                this.setState({
                    Language: event.target.value,
                    somethingChanged: true
                })
                break;

            case "Timezone":
                this.validate("timezone", event.target.value, this.props.config.Users["timezone"].validations);
                this.setState({
                    Timezone: event.target.value,
                    somethingChanged: true
                })
                break;

            case "Alertmedia":
                this.validate("alertMedia", event.target.value, this.props.config.Users["alertMedia"].validations);
                this.setState({
                    Alertmedia: event.target.value,
                    somethingChanged: true
                })
                break;

            case "Notes":
                this.validate("notes", event.target.value, this.props.config.Users["notes"].validations);
                this.setState({
                    Notes: event.target.value,
                    somethingChanged: true
                })
                break;
            default: console.log("reached default case");
        }
    }

    // provide permissions for different plants
    handleUserPlantSelection = (event, element, plantId) => {
        let userPlants = [];

        switch (element) {
            case "Delete":
                userPlants = this.state.UserPlants.filter((plant) => (plant.id !== plantId));

                let allPlants = this.state.AllPlants.filter((item) => true);
                let found = false;

                allPlants.map((organization) => {
                    organization.plants.map((plant) => {
                        if (plant.id === plantId) {
                            plant.permission = null;
                            plant.selected = false;
                            found = true;
                            return
                        }
                    })
                    if (found) {
                        return
                    }
                })

                this.setState({
                    UserPlants: [...userPlants],
                    AllPlants: [...allPlants],
                    somethingChanged: true
                })
                break;

            case "Radio":
                userPlants = this.state.UserPlants.filter((item) => true);

                userPlants.map((plant) => {
                    if (plant.id === plantId) {
                        plant.permission = event.target.value;
                        plant.selected = true;
                    }
                })
                this.setState({
                    UserPlants: userPlants,
                    somethingChanged: true
                })
                break;
            default: console.log("reached default case");
        }
    }

    componentDidMount() {
        let organizations = [];
        const plantIdMap = {}
        this.props.subOrganizations.map((organization) => {
            organizations.push({
                id: organization.id,
                name: organization.name
            })
        })

        if (this.props.data !== "" && Object.keys(this.props.data).length > 0) {

            this.props.getPlantsForUser(this.props.data.organization.id, this.props.data.id);
            this.props.data.plantPermissions.forEach(element => {
                if (element) {
                    plantIdMap[`${element.plants.id}`] = element.enableAlert
                }
            });
             let filterdArr;
            if (this.props.data.alertTypes) {
                filterdArr = this.props.data.alertTypes.filter((item) => ((item !== 'DIAGNOSTIC') &&  ( item !=='THRESHOLD_VIOLATION')));
            } else {
                filterdArr = [];
            }
            this.setState(() => ({
                id: this.props.data.id,
                Firstname: this.props.data.firstName,
                Lastname: this.props.data.lastName,
                Organizations: [...organizations],
                Organization: { id: this.props.data.organization.id, name: this.props.data.organization.name },
                Admin: this.props.data.admin === "Yes" ? true : false,
                Phone: this.props.data.phoneNumber,
                Email: this.props.data.emailAddress,
                Language: this.props.data.language ? this.props.data.language : undefined,
                Timezone: this.props.data.timezone,
                "Alertmedia": this.props.data.alertMedia,
                alertTypes: filterdArr,
                Notes: this.props.data.notes,
                isPasswordExpire: this.props.data.passwordExpireOn ? true : false,
                plantPermissions: plantIdMap
            }))
        }
        else {
            this.setState({ Alertmedia: "EMAIL" });
            let currentOrganization = {};
            for (let organization of this.props.subOrganizations) {
                if (organization.id === this.props.selectedScopeData) {
                    currentOrganization.id = organization.id;
                    currentOrganization.name = organization.name;
                    break;
                }
            }

            if (currentOrganization.id) {
                this.props.getPlantsForUser(currentOrganization.id, undefined);
                this.setState(() => ({
                    Organizations: [...organizations],
                    Organization: { ...currentOrganization },
                    AddNewRecord: true,
                    validOrganizationName: true
                }))
            }
        }
    }

    componentDidUpdate(prevProps) {
        if (prevProps.userPlants !== this.props.userPlants) {
            let userPlants = [];
            let allPlants = [];
            const plantIdMap = { ...this.state.plantPermissions }

            this.props.userPlants.map((organization) => {
                if (organization.plants.length > 0) {
                    let newOrganization = {};
                    let newOrganizationPlants = [];
                    newOrganization.id = organization.id;
                    newOrganization.name = organization.name;

                    organization.plants.map((plant) => {
                        let newPlant = {};

                        newPlant.id = plant.id;
                        newPlant.name = plant.name;
                        newPlant.permission = plant.permissions;
                        newPlant.selected = plant.permissions !== null ? true : false;

                        newOrganizationPlants.push(newPlant);

                        if (plant.permissions !== null) {
                            newPlant = {};
                            newPlant.id = plant.id;
                            newPlant.name = plant.name;
                            newPlant.permission = plant.permissions;
                            newPlant.selected = true;

                            userPlants.push(newPlant)
                        } else if (plantIdMap[`${plant.id}`] == null)
                            plantIdMap[`${plant.id}`] = true
                    })
                    newOrganization.plants = [...newOrganizationPlants];
                    allPlants.push(newOrganization);
                }
            })

            this.setState({
                UserPlants: [...userPlants],
                AllPlants: [...allPlants],
                plantPermissions: plantIdMap
            })
        }
    }

    // handle plants available for a user
    handlePlantSelection = (event, element, organizationId, plantId, plantPermissionChanged = true) => {
        let allPlants = [];
        allPlants = this.state.AllPlants.filter((item) => true);

        switch (element) {
            case "Checkbox":
                allPlants.map((organization) => {
                    if (organization.id === organizationId) {
                        organization.plants.map((plant) => {
                            if (plant.id === plantId && plant.selected === false) {
                                plant.selected = event.target.checked;
                                plant.permission = "dashboard";
                            }
                            else if (plant.id === plantId && plant.selected === true) {
                                plant.selected = event.target.checked;
                                plant.permission = null;
                            }
                        })
                    }
                })

                this.setState({
                    AllPlants: [...allPlants],
                    AddPlantsButtonEnabled: true,
                    somethingChanged: true
                })
                break;

            case "Radio":
                allPlants.map((organization) => {
                    if (organization.id === organizationId) {
                        organization.plants.map((plant) => {
                            if (plant.id === plantId) {
                                if (plantPermissionChanged)
                                    plant.permission = event.target.value;
                                plant.selected = true;
                            }
                        })
                    }
                })
                this.setState({
                    AllPlants: [...allPlants],
                    AddPlantsButtonEnabled: true,
                    somethingChanged: true
                })
                break;
        }
    }

    // get current available plants with permissions for user
    getPlants = () => {
        let allPlants = this.state.AllPlants;
        allPlants.sort(function (plant1, plant2) {
            return plant1.name.toLowerCase().localeCompare(plant2.name.toLowerCase());
        });

        return (
            allPlants.map((organization) => {
                return (
                    <ExpansionPanel key={organization.id} classes={{ rounded: this.props.classes.expansionPanel }}>
                        <ExpansionPanelSummary expandIcon={<ExpandMore />}>
                            <Typography>{organization.name}</Typography>
                        </ExpansionPanelSummary>
                        <ExpansionPanelDetails classes={{ root: this.props.classes.expensionPanelDetails }}>
                            {organization.plants.map((plant) => {
                                return (
                                    <div key={plant.id} style={{ display: 'flex', justifyContent: 'space-between', boxSizing: 'border-box', paddingLeft: 13 }}>
                                        <FormControlLabel
                                            control={
                                                <Checkbox
                                                    checked={plant.selected}
                                                    onChange={(event) => this.handlePlantSelection(event, "Checkbox", organization.id, plant.id)}
                                                    value={`${plant.id}`}
                                                    color="primary"
                                                />
                                            }
                                            label={plant.name}
                                            title={plant.name}
                                            style={{
                                                display: 'flex',
                                                justifyContent: 'flex-start',
                                                marginRight: 11,
                                                //width: '100px',
                                                //textOverflow: 'ellipsis',
                                                //overflow: 'hidden'
                                                width: 260,
                                                'word-break': 'break-all'
                                            }}
                                        />
                                        <RadioGroup
                                            classes={{ root: this.props.classes.radioGroup }}
                                            aria-label="Plant Permission"
                                            name="Plant Permission"
                                            value={plant.id}
                                            onChange={(event) => this.handlePlantSelection(event, "Radio", organization.id, plant.id)}
                                        >
                                            <FormControlLabel style={{ marginLeft: 0, marginRight: 24, maxWidth: 100 }} value="dashboard" control={<Radio checked={plant.permission === "dashboard" ? true : false} />} label={this.props.t("Dashboard")} />
                                            <FormControlLabel style={{ marginLeft: 0, maxWidth: 100 }} value="configuration" control={<Radio checked={plant.permission === "configuration" ? true : false} />} label={this.props.t("Configuration + Dashboard")} />
                                        </RadioGroup>
                                        <Tooltip title={"Alert"}>
                                            <div style={{ marginLeft: 24 }}>
                                                <Switch
                                                    checked={this.state.plantPermissions[`${plant.id}`] ? true : false}
                                                    color="primary"
                                                    disableRipple={false}
                                                    onChange={(event, value) => { this.handleAlertSwitch(value, plant.id); this.handlePlantSelection(event, "Radio", organization.id, plant.id, false) }}
                                                    label={this.props.t("Alert")}
                                                />{this.props.t("Alert")}</div>
                                        </Tooltip>

                                    </div>
                                )
                            })
                            }
                        </ExpansionPanelDetails>
                    </ExpansionPanel>
                )
            })
        )
    }

    // handle routing
    cancelRecord = () => {
        this.props.history.goBack();
    }

    // open right side Drawer
    openDrawer = () => {
        this.setState({
            AddPlants: true
        })
    }

    // close right side Drawer
    closeDrawer = () => {
        this.setState({
            AddPlants: false
        })
    }

    // handle Add Plants button
    addPlants = () => {
        let userPlants = [];

        this.state.AllPlants.map((organization) => {
            organization.plants.map((plant) => {
                if (plant.selected) {
                    userPlants.push({ ...plant })
                }
            })
        })

        this.setState({
            UserPlants: [...userPlants],
            AddPlants: false
        })
    }

    // get helptext for user input fields
    getHelpText = fieldName => {
        let helpText = null;
        switch (fieldName) {

            case 'firstName':
                for (let key in this.props.config.Users) {
                    if (key === fieldName && this.props.config.Users[fieldName]["validations"] && this.props.config.Users[fieldName]["validations"]["helpText"]) {
                        helpText = this.props.config.Users[fieldName]["validations"]["helpText"];
                        break;
                    }
                }
                return helpText;

            case 'lastName':
                for (let key in this.props.config.Users) {
                    if (key === fieldName && this.props.config.Users[fieldName]["validations"] && this.props.config.Users[fieldName]["validations"]["helpText"]) {
                        helpText = this.props.config.Users[fieldName]["validations"]["helpText"];
                        break;
                    }
                }
                return helpText;

            /* case 'password':
                for (let key in this.props.config.Users) {
                    if (key === fieldName && this.props.config.Users[fieldName]["validations"] && this.props.config.Users[fieldName]["validations"]["helpText"]) {
                        helpText = this.props.config.Users[fieldName]["validations"]["helpText"];
                        break;
                    }
                }
                return helpText; */

            case 'organizationName':
                for (let key in this.props.config.Users) {
                    if (key === fieldName && this.props.config.Users[fieldName]["validations"] && this.props.config.Users[fieldName]["validations"]["helpText"]) {
                        helpText = this.props.config.Users[fieldName]["validations"]["helpText"];
                        break;
                    }
                }
                return helpText;

            case 'phoneNumber':
                for (let key in this.props.config.Users) {
                    if (key === fieldName && this.props.config.Users[fieldName]["validations"] && this.props.config.Users[fieldName]["validations"]["helpText"]) {
                        helpText = this.props.config.Users[fieldName]["validations"]["helpText"];
                        break;
                    }
                }
                return helpText;

            case 'emailAddress':
                for (let key in this.props.config.Users) {
                    if (key === fieldName && this.props.config.Users[fieldName]["validations"] && this.props.config.Users[fieldName]["validations"]["helpText"]) {
                        helpText = this.props.config.Users[fieldName]["validations"]["helpText"];
                        break;
                    }
                }
                return helpText;

            case 'language':
                for (let key in this.props.config.Users) {
                    if (key === fieldName && this.props.config.Users[fieldName]["validations"] && this.props.config.Users[fieldName]["validations"]["helpText"]) {
                        helpText = this.props.config.Users[fieldName]["validations"]["helpText"];
                        break;
                    }
                }
                return helpText;

            case 'timezone':
                for (let key in this.props.config.Users) {
                    if (key === fieldName && this.props.config.Users[fieldName]["validations"] && this.props.config.Users[fieldName]["validations"]["helpText"]) {
                        helpText = this.props.config.Users[fieldName]["validations"]["helpText"];
                        break;
                    }
                }
                return helpText;

            case 'alertMedia':
                for (let key in this.props.config.Users) {
                    if (key === fieldName && this.props.config.Users[fieldName]["validations"] && this.props.config.Users[fieldName]["validations"]["helpText"]) {
                        helpText = this.props.config.Users[fieldName]["validations"]["helpText"];
                        break;
                    }
                }
                return helpText;

            case 'notes':
                for (let key in this.props.config.Users) {
                    if (key === fieldName && this.props.config.Users[fieldName]["validations"] && this.props.config.Users[fieldName]["validations"]["helpText"]) {
                        helpText = this.props.config.Users[fieldName]["validations"]["helpText"];
                        break;
                    }
                }
                return helpText;

            default: return null;
        }
    }

    // validate whether a field is mandatory
    isRequiredField = fieldName => {
        let requiredNode = null;

        switch (fieldName) {

            case 'firstName':
                for (let key in this.props.config.Users) {
                    if (key === fieldName && this.props.config.Users[fieldName]["validations"] && this.props.config.Users[fieldName]["validations"]["required"]) {
                        requiredNode = <sup className={this.props.classes.sup}>{'*'}</sup>
                        break;
                    }
                }
                return requiredNode;

            case 'lastName':
                for (let key in this.props.config.Users) {
                    if (key === fieldName && this.props.config.Users[fieldName]["validations"] && this.props.config.Users[fieldName]["validations"]["required"]) {
                        requiredNode = <sup className={this.props.classes.sup}>{'*'}</sup>
                        break;
                    }
                }
                return requiredNode;

            /* case 'password':
                requiredNode = this.state.AddNewRecord ? <sup className={this.props.classes.sup}>{'*'}</sup> : null;
                return requiredNode; */

            case 'organizationName':
                for (let key in this.props.config.Users) {
                    if (key === fieldName && this.props.config.Users[fieldName]["validations"] && this.props.config.Users[fieldName]["validations"]["required"]) {
                        requiredNode = <sup className={this.props.classes.sup}>{'*'}</sup>
                        break;
                    }
                }
                return requiredNode;

            case 'phoneNumber':
                for (let key in this.props.config.Users) {
                    if (key === fieldName && this.props.config.Users[fieldName]["validations"] && this.props.config.Users[fieldName]["validations"]["required"]) {
                        requiredNode = <sup className={this.props.classes.sup}>{'*'}</sup>
                        break;
                    }
                }
                return requiredNode;

            case 'emailAddress':
                for (let key in this.props.config.Users) {
                    if (key === fieldName && this.props.config.Users[fieldName]["validations"] && this.props.config.Users[fieldName]["validations"]["required"]) {
                        requiredNode = <sup className={this.props.classes.sup}>{'*'}</sup>
                        break;
                    }
                }
                return requiredNode;

            case 'language':
                for (let key in this.props.config.Users) {
                    if (key === fieldName && this.props.config.Users[fieldName]["validations"] && this.props.config.Users[fieldName]["validations"]["required"]) {
                        requiredNode = <sup className={this.props.classes.sup}>{'*'}</sup>
                        break;
                    }
                }
                return requiredNode;

            case 'timezone':
                for (let key in this.props.config.Users) {
                    if (key === fieldName && this.props.config.Users[fieldName]["validations"] && this.props.config.Users[fieldName]["validations"]["required"]) {
                        requiredNode = <sup className={this.props.classes.sup}>{'*'}</sup>
                        break;
                    }
                }
                return requiredNode;

            case 'alertMedia':
                for (let key in this.props.config.Users) {
                    if (key === fieldName && this.props.config.Users[fieldName]["validations"] && this.props.config.Users[fieldName]["validations"]["required"]) {
                        requiredNode = <sup className={this.props.classes.sup}>{'*'}</sup>
                        break;
                    }
                }
                return requiredNode;

            case 'notes':
                for (let key in this.props.config.Users) {
                    if (key === fieldName && this.props.config.Users[fieldName]["validations"] && this.props.config.Users[fieldName]["validations"]["required"]) {
                        requiredNode = <sup className={this.props.classes.sup}>{'*'}</sup>
                        break;
                    }
                }
                return requiredNode;

            default: return null;
        }
    }

    // validate user input fields
    validate = (fieldName, value, rules) => {
        let valid = true;

        switch (fieldName) {
            case 'firstName':
                if (rules) {
                    if (rules["minLength"]) {
                        valid = value.length >= rules["minLength"] && valid;
                    }
                    if (rules["maxLength"]) {
                        valid = value.length <= rules["maxLength"] && valid;
                    }
                    if (rules["required"]) {
                        valid = value.trim() !== '' && valid;
                    }
                }
                this.setState({
                    validFirstname: valid
                })
                break;

            case 'lastName':
                if (rules) {
                    if (rules["minLength"]) {
                        valid = value.length >= rules["minLength"] && valid;
                    }
                    if (rules["maxLength"]) {
                        valid = value.length <= rules["maxLength"] && valid;
                    }
                    if (rules["required"]) {
                        valid = value.trim() !== '' && valid;
                    }
                }
                this.setState({
                    validLastname: valid
                })
                break;

            case 'password':
                if (rules) {
                    if (rules["minLength"]) {
                        valid = value.length >= rules["minLength"] && valid;
                    }
                    if (rules["maxLength"]) {
                        valid = value.length <= rules["maxLength"] && valid;
                    }
                    if (rules["required"]) {
                        valid = value.trim() !== '' && valid;
                    }
                }

                this.setState({
                    validPassword: valid
                })
                break;

            // case 'organizationName':
            //     if (rules) {
            //         if (rules["minLength"]) {
            //             valid = value.length >= rules["minLength"] && valid;
            //         }
            //         if (rules["maxLength"]) {
            //             valid = value.length <= rules["maxLength"] && valid;
            //         }
            //         if (rules["required"]) {
            //             valid = value.trim() !== '' && valid;
            //         }
            //         if (value !== "Select") {
            //             valid = true && valid;
            //         }
            //     }
            //     this.setState({
            //         validOrganizationName: valid
            //     })
            //     break;

            case 'phoneNumber':
                if (rules) {
                    if (rules["minLength"]) {
                        valid = value.length >= rules["minLength"] && valid;
                    }
                    if (rules["maxLength"]) {
                        valid = value.length <= rules["maxLength"] && valid;
                    }
                    if (rules["required"]) {
                        valid = value.trim() !== '' && valid;
                    }
                }
                this.setState({
                    validPhoneNumber: valid
                })
                break;

            case 'emailAddress':
                if (rules) {
                    if (rules["minLength"]) {
                        valid = value.length >= rules["minLength"] && valid;
                    }
                    if (rules["maxLength"]) {
                        valid = value.length <= rules["maxLength"] && valid;
                    }
                    if (rules["required"]) {
                        valid = value.trim() !== '' && valid;
                    }
                    // const regEx = RegExp(/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/);
                    const regEx = RegExp(/^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/);
                    valid = regEx.test(value) && valid;

                }
                this.setState({
                    validEmailAddress: valid
                })
                break;

            case 'language':
                if (rules) {
                    if (rules["minLength"]) {
                        valid = value.length >= rules["minLength"] && valid;
                    }
                    if (rules["maxLength"]) {
                        valid = value.length <= rules["maxLength"] && valid;
                    }
                    if (rules["required"]) {
                        valid = value.trim() !== '' && valid;
                    }
                    if (value !== "Select") {
                        valid = true && valid;
                    }
                }
                this.setState({
                    validLanguage: valid
                })
                break;

            case 'timezone':
                if (rules) {
                    if (rules["minLength"]) {
                        valid = value.length >= rules["minLength"] && valid;
                    }
                    if (rules["maxLength"]) {
                        valid = value.length <= rules["maxLength"] && valid;
                    }
                    if (rules["required"]) {
                        valid = value.trim() !== '' && valid;
                    }
                    if (value !== "Select") {
                        valid = true && valid;
                    }
                }
                this.setState({
                    validTimezone: valid
                })
                break;

            case 'alertMedia':
                if (rules) {
                    if (rules["minLength"]) {
                        valid = value.length >= rules["minLength"] && valid;
                    }
                    if (rules["maxLength"]) {
                        valid = value.length <= rules["maxLength"] && valid;
                    }
                    if (rules["required"]) {
                        valid = value.trim() !== '' && valid;
                    }
                    if (value !== "Select") {
                        valid = true && valid;
                    }
                }
                this.setState({
                    validAlertmedia: valid
                })
                break;

            case 'admin':
                if (rules) {
                    if (rules["minLength"]) {
                        valid = value.length >= rules["minLength"] && valid;
                    }
                    if (rules["maxLength"]) {
                        valid = value.length <= rules["maxLength"] && valid;
                    }
                    if (rules["required"]) {
                        valid = value.trim() !== '' && valid;
                    }
                }
                this.setState({
                    validAdmin: valid
                })
                break;

            case 'notes':
                if (rules) {
                    if (rules["minLength"]) {
                        valid = value.length >= rules["minLength"] && valid;
                    }
                    if (rules["maxLength"]) {
                        valid = value.length <= rules["maxLength"] && valid;
                    }
                    if (rules["required"]) {
                        valid = value.trim() !== '' && valid;
                    }
                }
                this.setState({
                    validNotes: valid
                })
                break;

            default: return null;
        }
    }

    handleChange = (event) => {
        this.setState({
            alertTypes: event.target.value,
            somethingChanged: true
        })
    };

    handleAlertSwitch = (switchValue, plantId) => {
        this.state.plantPermissions[`${plantId}`] = switchValue
        this.setState({ plantPermissions: this.state.plantPermissions, somethingChanged: true })
    }

    // disable save button based on validations
    disableSaveButton = () => {
        let disableSaveButton = true;
        if (this.state.AddNewRecord && this.state.somethingChanged) {
            if ((this.state.validOrganizationName && this.state.validEmailAddress)) {
                disableSaveButton = false;
                if (this.state.validFirstname === false || this.state.validLastname === false
                    || this.state.validPhoneNumber === false || this.state.validLanguage === false
                    || this.state.validTimezone === false || this.state.validAlertmedia === false
                    || this.state.validAdmin === false || this.state.validNotes === false) {
                    disableSaveButton = true;
                }
            }
        }
        else if (this.state.AddNewRecord === false && this.state.somethingChanged) {
            if (this.state.validOrganizationName === false || this.state.validEmailAddress === false
                || this.state.validFirstname === false || this.state.validLastname === false
                || this.state.validPhoneNumber === false || this.state.validLanguage === false
                || this.state.validTimezone === false || this.state.validAlertmedia === false
                || this.state.validAdmin === false || this.state.validNotes === false) {
                disableSaveButton = true;
            }
            else {
                disableSaveButton = false;
            }
        }
        return disableSaveButton;
    }

    render() {
        const { t } = this.props;
        return (
            <Card className={this.props.classes.card}>
                <Drawer
                    anchor="right"
                    open={this.state.AddPlants}
                    classes={{ paperAnchorRight: this.props.classes.drawer }}
                >
                    <div className={this.props.classes.drawerHeaderContainer}>
                        <div className={this.props.classes.drawerTitle}>
                            {t("List of plants")}
                        </div>
                        {/* <IconButton onClick={this.closeDrawer}> */}
                        <Close className={this.props.classes.drawerCloseIcon} onClick={this.closeDrawer} />
                        {/* </IconButton> */}
                    </div>
                    <div className={this.props.classes.AddPlantsButtonContainer}>
                        <Button
                            variant="contained"
                            color="primary"
                            disabled={!this.state.AddPlantsButtonEnabled}
                            className={this.props.classes.AddPlantsButtonEnabled}
                            onClick={this.addPlants}
                        >
                            {t("Update")}
                        </Button>
                    </div>
                    <div style={{ overflowY: 'auto' }}>
                        {this.getPlants()}
                    </div>
                </Drawer>
                <div className={this.props.classes.actionButtonContainer}>
                    <div className={this.props.classes.backButtonContainer}>
                        <KeyboardBackspace onClick={this.cancelRecord} classes={{ root: this.props.classes.arrowBack }} />
                        <div className={this.props.classes.entityTitle}>{this.state.AddNewRecord === false ? this.props.data.emailAddress : this.props.t("Create New")}</div>
                    </div>
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                        <Button variant="contained"
                            className={this.props.classes.saveButton}
                            onClick={this.updateUser}
                            disabled={this.disableSaveButton()} >
                            {t("Save")}
                        </Button>
                    </div>
                </div>
                <CardContent className={this.props.classes.cardContent} classes={{ root: this.props.classes.cardContentRoot }}>
                    {
                        this.props.config.Users.emailAddress.page_visible ?
                            (
                                <div key={"Email"} className={this.props.classes.inputFieldDiv}>
                                    <div className={this.props.classes.textFieldLabel}>{this.props.config.Users.emailAddress.display_name}{this.isRequiredField("emailAddress")}</div>
                                    <TextField
                                        disabled={this.props.data !== ""}
                                        className={this.props.classes.textField}
                                        variant="outlined"
                                        value={this.state.Email}
                                        type="email"
                                        onChange={(event) => this.onChangeHandler(event, "Email")}
                                        helperText={this.getHelpText("emailAddress")}
                                        error={this.state.validEmailAddress === false}
                                        FormHelperTextProps={{
                                            classes: {
                                                contained: this.props.classes.containedHelperText
                                            }
                                        }}
                                        InputProps={{
                                            classes: {
                                                input: this.props.classes.outlinedInput
                                            }
                                        }}
                                    />
                                </div>
                            )
                            : null
                    }
                    {/* {
                        this.props.config.Users.password.page_visible ?
                            (
                                <div key={"Password"} className={this.props.classes.inputFieldDiv}>
                                    <div className={this.props.classes.textFieldLabel}>{this.state.AddNewRecord ? this.props.config.Users.password.display_name : this.props.config.Users.changePassword.display_name}{this.isRequiredField("password")}</div>
                                    <TextField
                                        className={this.props.classes.textField}
                                        autoComplete="new-password"
                                        variant="outlined"
                                        type={this.state.displayPassword ? "text" : "password"}
                                        value={this.state.Password === null ? "" : this.state.Password}
                                        onChange={(event) => this.onChangeHandler(event, "Password")}
                                        helperText={this.getHelpText("password")}
                                        error={this.state.validPassword === false}
                                        FormHelperTextProps={{
                                            classes: {
                                                contained: this.props.classes.containedHelperText
                                            }
                                        }}
                                        InputProps={{
                                            classes: {
                                                input: this.props.classes.outlinedInput
                                            },
                                            endAdornment: (
                                                <InputAdornment position="end">
                                                    {!this.state.Password ?
                                                        <VisibilityOff className={this.props.classes.visibilityOffIcon} />
                                                        :
                                                        <Visibility
                                                            className={this.props.classes.visibilityIcon}
                                                            onMouseDown={this.displayPassword}
                                                            onMouseUp={this.hidePassword} />}
                                                </InputAdornment>
                                            ),
                                        }}
                                    />
                                </div>
                            )
                            :
                            null
                    } */}
                    {
                        this.props.config.Users.firstName.page_visible ?
                            (
                                <div key={"Firstname"} className={this.props.classes.inputFieldDiv}>
                                    <div className={this.props.classes.textFieldLabel}>{this.props.config.Users.firstName.display_name}{this.isRequiredField("firstName")}</div>
                                    <TextField
                                        className={this.props.classes.textField}
                                        variant="outlined"
                                        value={this.state.Firstname}
                                        onChange={(event) => this.onChangeHandler(event, "Firstname")}
                                        helperText={this.getHelpText("firstName")}
                                        error={this.state.validFirstname === false}
                                        FormHelperTextProps={{
                                            classes: {
                                                contained: this.props.classes.containedHelperText
                                            }
                                        }}
                                        InputProps={{
                                            classes: {
                                                input: this.props.classes.outlinedInput
                                            }
                                        }}
                                    />
                                </div>
                            )
                            : null
                    }
                    {
                        this.props.config.Users.lastName.page_visible ?
                            (
                                <div key={"Lastname"} className={this.props.classes.inputFieldDiv}>
                                    <div className={this.props.classes.textFieldLabel}>{this.props.config.Users.lastName.display_name}{this.isRequiredField("lastName")}</div>
                                    <TextField
                                        className={this.props.classes.textField}
                                        variant="outlined"
                                        value={this.state.Lastname}
                                        autoComplete="new-password"
                                        onChange={(event) => this.onChangeHandler(event, "Lastname")}
                                        helperText={this.getHelpText("lastName")}
                                        error={this.state.validLastname === false}
                                        FormHelperTextProps={{
                                            classes: {
                                                contained: this.props.classes.containedHelperText
                                            }
                                        }}
                                        InputProps={{
                                            classes: {
                                                input: this.props.classes.outlinedInput
                                            }
                                        }}
                                    />
                                </div>
                            )
                            : null
                    }
                    {
                        this.props.config.Users.organizationName.page_visible ?
                            (
                                <div key={"Organization"} className={this.props.classes.inputFieldDiv}>
                                    <div className={this.props.classes.textFieldLabel}>{this.props.config.Users.organizationName.display_name}{this.isRequiredField("organizationName")}</div>
                                    <Select
                                        input={
                                            <OutlinedInput
                                                labelWidth={0}
                                                variant="outlined"
                                                name="Organization"
                                                id="language-native-simple"
                                                className={this.props.classes.textField}
                                                error={this.state.validOrganizationName === false}
                                                classes={{ input: this.props.classes.outlinedInput }}
                                            />
                                        }
                                        native
                                        value={this.state.Organization.name}
                                        onChange={(event) => this.onChangeHandler(event, "Organization")}
                                        //error={this.state.validOrganizationName === false}
                                        disabled={true}
                                    >
                                        <option disabled={true} value="" key={""}>{this.props.t("Select")}</option>
                                        {
                                            this.state.Organizations.map((item) => (<option value={item.name} key={item.name}>{item.name}</option>))
                                        }
                                    </Select>
                                </div>
                            )
                            : null
                    }
                    {
                        this.props.config.Users.phoneNumber.page_visible ?
                            (
                                <div key={"Phone"} className={this.props.classes.inputFieldDiv}>
                                    <div className={this.props.classes.textFieldLabel}>{this.props.config.Users.phoneNumber.display_name}{this.isRequiredField("phoneNumber")}</div>
                                    <TextField
                                        className={this.props.classes.textField}
                                        variant="outlined"
                                        value={this.state.Phone}
                                        onChange={(event) => this.onChangeHandler(event, "Phone")}
                                        helperText={this.getHelpText("phoneNumber")}
                                        error={this.state.validPhoneNumber === false}
                                        FormHelperTextProps={{
                                            classes: {
                                                contained: this.props.classes.containedHelperText
                                            }
                                        }}
                                        InputProps={{
                                            classes: {
                                                input: this.props.classes.outlinedInput
                                            }
                                        }}
                                    />
                                </div>
                            )
                            : null
                    }
                    {
                        this.props.config.Users.language.page_visible ?
                            (
                                <div key={"Language"} className={this.props.classes.inputFieldDiv}>
                                    <div className={this.props.classes.textFieldLabel}>{this.props.config.Users.language.display_name}{this.isRequiredField("language")}</div>
                                    <Select
                                        input={
                                            <OutlinedInput
                                                labelWidth={0}
                                                variant="outlined"
                                                name="Language"
                                                id="language-native-simple"
                                                className={this.props.classes.textField}
                                                error={this.state.validLanguage === false}
                                                classes={{ input: this.props.classes.outlinedInput }}
                                            />
                                        }
                                        native
                                        value={this.state.Language}
                                        onChange={(event) => this.onChangeHandler(event, "Language")}
                                    //error={this.state.validLanguage === false}
                                    >
                                        <option disabled={true} key={""} value="">{this.props.t("Select")}</option>
                                        <option value="English">English</option>
                                        <option value="Japanese">日本語</option>
                                        {/* {
                                            this.props.languages.map((item) => (<option value={item} key={item}>{item}</option>))
                                        } */}
                                    </Select>
                                </div>
                            )
                            : null
                    }
                    {
                        this.props.config.Users.timezone.page_visible ?
                            (
                                <div key={"Timezone"} className={this.props.classes.inputFieldDiv}>
                                    <div className={this.props.classes.textFieldLabel}>{this.props.config.Users.timezone.display_name}{this.isRequiredField("timezone")}</div>
                                    <Select
                                        input={
                                            <OutlinedInput
                                                labelWidth={0}
                                                variant="outlined"
                                                name="Timezone"
                                                id="language-native-simple"
                                                className={this.props.classes.textField}
                                                error={this.state.validTimezone === false}
                                                classes={{ input: this.props.classes.outlinedInput }}
                                            />
                                        }
                                        native
                                        value={this.state.Timezone === null ? "" : this.state.Timezone}
                                        onChange={(event) => this.onChangeHandler(event, "Timezone")}
                                    //error={this.state.validTimezone === false}
                                    >
                                        <option value="" disabled={true} key={""}>{this.props.t("Select")}</option>
                                        {
                                            this.props.timezones ? this.props.timezones.map((item) => (<option value={item} key={item}>{item}</option>)) : this.props.history.replace("/dashboard")
                                        }
                                    </Select>
                                </div>
                            )
                            : null
                    }
                    {
                        this.props.config.Users.alertMedia.page_visible ?
                            (
                                <div key={"Alertmedia"} className={this.props.classes.inputFieldDiv}>
                                    <div className={this.props.classes.textFieldLabel}>{this.props.config.Users.alertMedia.display_name}{this.isRequiredField("alertMedia")}</div>
                                    <Select
                                        input={
                                            <OutlinedInput
                                                labelWidth={0}
                                                variant="outlined"
                                                name="Alertmedia"
                                                id="language-native-simple"
                                                className={this.props.classes.textField}
                                                error={this.state.validAlertmedia === false}
                                                classes={{ input: this.props.classes.outlinedInput }}
                                            />
                                        }
                                        native
                                        value={this.state.Alertmedia === null ? "" : this.state.Alertmedia}
                                        onChange={(event) => this.onChangeHandler(event, "Alertmedia")}
                                    //error={this.state.validAlertmedia === false}
                                    >
                                        <option value="" disabled={true} key={""}>{this.props.t("Select")}</option>
                                        {
                                            this.props.alertMediaTypes ? this.props.alertMediaTypes.map((item) => (<option value={item} key={item}>{this.props.t(item)}</option>)) : this.props.history.replace("/dashboard")
                                        }
                                    </Select>
                                </div>
                            )
                            : null
                    }
                    <div className={this.props.classes.inputFieldDiv} >
                        <InputLabel className={this.props.classes.textFieldLabel}>
                            {t("Alert Type")}
                        </InputLabel>
                        <Select
                            id="demo-mutiple-checkbox"
                            multiple
                            value={this.state.alertTypes}
                            onChange={this.handleChange}
                            style={{ minHeight: "50px", maxHeight: "70px" }}
                            input={
                                <OutlinedInput
                                    variant="outlined"
                                    name="entityType"
                                    id="language-native-simple"
                                    labelWidth={this.state.labelWidth}
                                    className={this.props.classes.textField}
                                    error={this.state.alertTypeError}
                                />
                            }
                            renderValue={(selected) => (
                                <div style={{ display: 'flex', flexWrap: 'wrap', maxHeight: "85px", overflowY: "auto" }}>
                                    {selected.map((value) => (
                                        <>
                                        {(value !== 'DIAGNOSTIC' && value !=='THRESHOLD_VIOLATION') && <Chip key={value} label={alertTypeList.find(x => x.key === value).value} style={{ marginBottom: "2px", marginRight: "2px" }} />}
                                        </>
                                    ))}
                                </div>
                            )}
                            MenuProps={MenuProps}
                        >
                            {alertTypeList.map((item) => (
                                <MenuItem key={item.key} value={item.key} classes={{ selected: this.props.classes.selected }}>
                                    <Checkbox checked={this.state.alertTypes.indexOf(item.key) > - 1} inputProps={{ 'aria-label': 'primary checkbox' }} />
                                    <ListItemText primary={item.value} />
                                </MenuItem>
                            ))}
                        </Select>
                    </div>
                    {
                        this.props.config.Users.admin.page_visible ?
                            (
                                <div key={"Admin"} className={this.props.classes.inputFieldDiv}>
                                    <div className={this.props.classes.textFieldLabel}>{this.props.config.Users.admin.display_name}{this.isRequiredField("admin")}</div>
                                    <div className={this.props.classes.textField}>
                                        <TextField
                                            //className={this.props.classes.textField}
                                            style={{ width: '80%' }}
                                            variant="outlined"
                                            value={this.state.Admin ? this.props.t("Administrator") : this.props.t("Non-Administrator")}
                                            disabled
                                            helperText={this.getHelpText("admin")}
                                            error={this.state.validAdmin === false}
                                            FormHelperTextProps={{
                                                classes: {
                                                    contained: this.props.classes.containedHelperText
                                                }
                                            }}
                                            InputProps={{
                                                classes: {
                                                    input: this.props.classes.outlinedInput
                                                }
                                            }}
                                        />
                                        <Switch
                                            className={this.props.classes.inputFields}
                                            variant="outlined"
                                            checked={this.state.Admin}
                                            value={this.state.Admin}
                                            color="secondary"
                                            onChange={(event) => this.onChangeHandler(event, "Admin")}
                                        />
                                    </div>
                                </div>
                            )
                            : null
                    }
                    <div key={"Admin"} className={this.props.classes.inputFieldDiv}>
                        <div className={this.props.classes.textFieldLabel}>{t("Password Reset Every 3 Month")}</div>
                        <div className={this.props.classes.textField}>
                            <TextField
                                //className={this.props.classes.textField}
                                style={{ width: '80%' }}
                                variant="outlined"
                                value={this.state.isPasswordExpire ? this.props.t("Enabled") : this.props.t("Disabled")}
                                disabled
                                FormHelperTextProps={{
                                    classes: {
                                        contained: this.props.classes.containedHelperText
                                    }
                                }}
                                InputProps={{
                                    classes: {
                                        input: this.props.classes.outlinedInput
                                    }
                                }}
                            />
                            <Switch
                                className={this.props.classes.inputFields}
                                variant="outlined"
                                checked={this.state.isPasswordExpire}
                                value={this.state.isPasswordExpire}
                                color="secondary"
                                onChange={(event) => this.onChangeHandler(event, "isPasswordExpire")}
                            />
                        </div>
                    </div>

                    {
                        this.props.config.Users.notes.page_visible ?
                            (
                                <div key={"Notes"} className={this.props.classes.textareaInputFieldDiv}>
                                    <div className={this.props.classes.textareaFieldLabel}>{this.props.config.Users.notes.display_name}{this.isRequiredField("notes")}</div>
                                    <TextField
                                        className={this.props.classes.textArea}
                                        variant="outlined"
                                        multiline
                                        rows="6"
                                        value={this.state.Notes}
                                        onChange={(event) => this.onChangeHandler(event, "Notes")}
                                        helperText={this.getHelpText("notes")}
                                        error={this.state.validNotes === false}
                                        FormHelperTextProps={{
                                            classes: {
                                                contained: this.props.classes.containedHelperText
                                            }
                                        }}
                                        InputProps={{
                                            classes: {
                                                multiline: this.props.classes.outlinedInput
                                            }
                                        }}
                                    />
                                </div>
                            )
                            : null
                    }
                    {
                        this.props.config.Users.plants.page_visible ?
                            <Grid container className={this.props.classes.gridClass}>
                                <Grid item sm={12}>
                                    <Divider />
                                </Grid>
                                <Grid item sm={10}>
                                    <div key={"Plants"} className={this.props.classes.elementContainer} style={{ alignItems: 'flex-start' }}>
                                        <div className={this.props.classes.textFieldLabel}>{this.props.config.Users.plants.display_name}</div>
                                        <div className={this.props.classes.plantsContainer}>
                                            {
                                                this.state.UserPlants.map((plant) => {
                                                    return (
                                                        <div key={plant.id} style={{ display: 'flex', justifyContent: 'space-between', boxSizing: 'border-box', paddingLeft: 13, alignItems: 'center', marginBottom: 16 }}>
                                                            {/* <FormControlLabel
                                                            control={
                                                                <Checkbox
                                                                    checked={plant.selected}
                                                                    onChange={(event) => this.handleUserPlantSelection(event, "Checkbox")}
                                                                    value={`${plant.id}`}
                                                                    color="primary"
                                                                />
                                                            }
                                                            label={plant.name}
                                                            style={{ display: 'flex', justifyContent: 'flex-start', marginRight: 11 }}
                                                        /> */}
                                                            <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                                                <IconButton onClick={(event) => this.handleUserPlantSelection(event, "Delete", plant.id)}>
                                                                    <Delete classes={{ root: this.props.classes.deleteIcon }} />
                                                                </IconButton>
                                                                <Typography variant="subtitle1" style={{ marginLeft: 25 }}>
                                                                    < label title={plant.name}
                                                                        style={{
                                                                            display: 'flex',
                                                                            justifyContent: 'flex-start',
                                                                            width: 250,
                                                                            wordBreak: 'break-all',
                                                                            //whiteSpace: 'nowrap',
                                                                            overflow: 'hidden',
                                                                            textOverflow: 'ellipsis', cursor: 'pointer', marginBottom: 0
                                                                        }}>
                                                                        {plant.name}
                                                                    </label>
                                                                </Typography>
                                                            </div>
                                                            <RadioGroup
                                                                classes={{ root: this.props.classes.radioGroup }}
                                                                aria-label="Plant Permission"
                                                                name="Plant Permission"
                                                                value={plant.id}
                                                                onChange={(event) => this.handleUserPlantSelection(event, "Radio", plant.id)}
                                                            >
                                                                <FormControlLabel style={{ marginBottom: 0 }} value="dashboard" control={<Radio checked={plant.permission === "dashboard" ? true : false} />} label="Dashboard" />
                                                                <FormControlLabel style={{ marginBottom: 0 }} value="configuration" control={<Radio checked={plant.permission === "configuration" ? true : false} />} label="Configuration + Dashboard" />
                                                            </RadioGroup>
                                                            <Tooltip title={"Alert"}>
                                                                <div ><Switch
                                                                    checked={this.state.plantPermissions[`${plant.id}`]}
                                                                    color="primary"
                                                                    disableRipple={false}
                                                                    onChange={(event, value) => { this.handleAlertSwitch(value, plant.id) }}
                                                                    label="Alert"
                                                                />{this.props.t("Alert")}</div>
                                                            </Tooltip>
                                                        </div>
                                                    )
                                                })
                                            }
                                        </div>
                                    </div>
                                </Grid>
                                <Grid item sm={2}>
                                    <div className={this.props.classes.flexButtonContainer}>
                                        <Button variant="outlined" className={this.props.classes.addPlantsButton} onClick={this.openDrawer}>
                                            {t("Add Plants")}
                                        </Button>
                                    </div>
                                </Grid>
                            </Grid>
                            : null
                    }
                </CardContent>
            </Card>
        )
    }
}

const mapStateToProps = state => {
    return {
        userPlants: state.userPlantsReducer.userPlants
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        updateUser: (updatedUserData, newRecord) => (dispatch(actionCreators.updateUser(updatedUserData, newRecord))),
        getPlantsForUser: (organizationId, userId) => (dispatch(actionCreators.getPlantsForUser(organizationId, userId))),
        updateSelectedTab: (selectedTab) => (dispatch(actionCreators.updateSelectedTab(selectedTab)))
    }
}

export default withTranslation()(connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(UsersEntity)));
