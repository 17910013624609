import * as actionTypes from '../actions/actionTypes';

export const topPlantLossReducer = (state={}, action) => {
    switch(action.type) {

        case actionTypes.SAVE_TOP_PLANT_LOSS:{ 
            return Object.assign({}, state, action.payload)
        }
        
        default: return state
    }
}