import React, { Component } from 'react'
import DateRangeSelector from './WidgetPanels/DateRangeSelector';
import ProductivityPanel from './WidgetPanels/ProductivityPanel';
import InternetSpeedPanel from './WidgetPanels/InternetSpeedPanel';
import VariousTimeSeriesGraphWiget from './WidgetPanels/VariousTimeSeriesGraphWiget';
import MachineList from './WidgetPanels/MachineList';
import MachineProductivityListingPanel from './WidgetPanels/MachineProductivityListingPanel'
import ReportPanel from './WidgetPanels/ReportPanel'
import moment from "moment";
import * as actionCreators from '../../actions/actionCreators';
import { connect } from 'react-redux';

class ProductionDashboard extends Component {
    constructor(props){
        super(props);
        this.state = {
            range:['today'],
            shiftNumber: 'Shift 1'
        }
    }

    componentDidMount(){
        let currentDateTime = new Date(),startDate;
        let range = [];
        startDate = new Date();
        startDate.setHours(0,0,0,0);
        currentDateTime.setHours(24,0,0,0);

        range.push('today',startDate.toISOString(),currentDateTime.toISOString());
        this.setState({range : range});
        let from = moment(range[1]).utc().format("YYYY-MM-DDTHH:mm:00") + "Z";
        let to = moment(range[2]).utc().format("YYYY-MM-DDTHH:mm:00") + "Z";
        this.props.getAccessToken(this.props.selectedPlantId,from,to);
        this.props.getPlantPartCount(this.props.selectedPlantId,from,to);
        this.props.getMachineData(this.props.selectedPlantId,from,to);
        this.props.getProductivityIndexData(this.props.selectedPlantId,from,to);
        this.props.getTimeSeriesData(this.props.selectedPlantId,from,to);
        this.props.getPlantShift(this.props.selectedPlantId)
        this.props.getOEEByPartNumber(this.props.selectedPlantId,from,to,1106)
        this.props.getProductivityByMachineNPlantId(this.props.selectedPlantId,1,from,to)
        this.props.getPartNumberList(this.props.selectedPlantId)
        this.props.getMachineProductionHistory(this.props.selectedPlantId,from,to,0);
        this.props.getOeeSelection(this.props.selectedPlantId,1,from,to);
        this.props.getInputDialog(this.props.selectedPlantId);
        this.props.getDowntimeAnalysis(this.props.selectedPlantId,from,to);
        this.props.getMaintenanceMetricsData(this.props.selectedPlantId,from,to);
        this.props.getLossDropDownList();
        this.props.getTopPlantLoss(this.props.selectedPlantId,from,to);
        this.props.getQualitytData(this.props.selectedPlantId,from,to);
        this.props.getAlarmAnalysisData(1,this.props.selectedPlantId,from,to);
        this.props.getQualitytData1(this.props.selectedPlantId,from,to,1);
        this.props.getQualityReport(this.props.selectedPlantId,from,to,1);
    }

    componentDidUpdate(prevProps,prevState){
      if(prevProps.plantShift !== this.props.plantShift){
              let rangeStartDate = new Date();
              let currentDateTime = new Date(),range = [];
              let currentHours = rangeStartDate.getHours();
              let shiftNumber = '';
              let totalShifts = this.props.plantShift.plantShift.length;
              for(let i=0;i<totalShifts;i++){
                let x = this.props.plantShift.plantShift[i].timeParameter.startTime.split(':')
                let y = this.props.plantShift.plantShift[i].timeParameter.endTime.split(':')

                let durationOfShift = y[0]-x[0];
                if(parseInt(x[0])+Math.abs(durationOfShift) !== parseInt(y[0])){
                  currentDateTime.setDate(currentDateTime.getDate()+1);
                  if(currentHours>=x[0] || currentHours<=y[0]){
                    rangeStartDate.setHours(x[0],0,0,0);
                    currentDateTime.setHours(y[0],0,0,0);
                    shiftNumber = this.props.plantShift.plantShift[i].shift;
                  }
                }
                else{
                  if(currentHours>=x[0] && currentHours<=y[0]){
                    rangeStartDate.setHours(x[0],0,0,0);
                    currentDateTime.setHours(y[0],0,0,0);
                    shiftNumber = this.props.plantShift.plantShift[i].shift;
                  }
                }
              }
              //range.push('shift',rangeStartDate.toISOString(),currentDateTime.toISOString());
              this.setState({shiftNumber: shiftNumber});
      }
      var prevSelectedPlantId = prevProps.selectedPlantId;
      var currentSelectedPlantId = this.props.selectedPlantId;
      let startDate = moment(this.state.range[1]).utc().format("YYYY-MM-DDTHH:mm:00") + "Z";
      let endDate = moment(this.state.range[2]).utc().format("YYYY-MM-DDTHH:mm:00") + "Z";
      // plant is changed
      if(prevSelectedPlantId !== currentSelectedPlantId ){
        this.props.getAccessToken(currentSelectedPlantId,startDate,endDate);
        this.props.getPlantPartCount(currentSelectedPlantId,startDate,endDate);
        this.props.getMachineData(currentSelectedPlantId,startDate,endDate);
        this.props.getProductivityIndexData(currentSelectedPlantId,startDate,endDate);
        this.props.getTimeSeriesData(currentSelectedPlantId,startDate,endDate);
        this.props.getPlantShift(currentSelectedPlantId)
        this.props.getOEEByPartNumber(currentSelectedPlantId,startDate,endDate,1106)
        this.props.getProductivityByMachineNPlantId(currentSelectedPlantId,1,startDate,endDate);
        this.props.getPartNumberList(currentSelectedPlantId)
        this.props.getMachineProductionHistory(this.props.selectedPlantId,startDate,endDate,0);
        this.props.getOeeSelection(this.props.selectedPlantId,1,startDate,endDate);
        this.props.getDowntimeAnalysis(this.props.selectedPlantId,startDate,endDate);
        this.props.getMaintenanceMetricsData(this.props.selectedPlantId,startDate,endDate);
        this.props.getInputDialog(this.props.selectedPlantId);
        this.props.getLossDropDownList();
        this.props.getTopPlantLoss(this.props.selectedPlantId,startDate,endDate);
        this.props.getQualitytData(this.props.selectedPlantId,startDate,endDate);
        this.props.getAlarmAnalysisData(1,this.props.selectedPlantId,startDate,endDate);
        this.props.getQualitytData1(this.props.selectedPlantId,startDate,endDate,1);
        this.props.getQualityReport(this.props.selectedPlantId,startDate,endDate,1);
      }
      // date range is chnaged
      if(prevState.range[0] != this.state.range[0]){
        this.props.getAccessToken(currentSelectedPlantId,startDate,endDate);
        this.props.getPlantPartCount(currentSelectedPlantId,startDate,endDate);
        this.props.getMachineData(currentSelectedPlantId,startDate,endDate);
        this.props.getProductivityIndexData(currentSelectedPlantId,startDate,endDate);
        this.props.getTimeSeriesData(currentSelectedPlantId,startDate,endDate);
        this.props.getPlantShift(currentSelectedPlantId)
        this.props.getOEEByPartNumber(currentSelectedPlantId,startDate,endDate,1106)
        this.props.getProductivityByMachineNPlantId(currentSelectedPlantId,1,startDate,endDate);
        this.props.getPartNumberList(currentSelectedPlantId)
        this.props.getMachineProductionHistory(currentSelectedPlantId,startDate,endDate,0);
        this.props.getOeeSelection(currentSelectedPlantId,1,startDate,endDate);
        this.props.getDowntimeAnalysis(currentSelectedPlantId,startDate,endDate);
        this.props.getMaintenanceMetricsData(currentSelectedPlantId,startDate,endDate);
        this.props.getInputDialog(currentSelectedPlantId);
        this.props.getLossDropDownList();
        this.props.getTopPlantLoss(currentSelectedPlantId,startDate,endDate);
        this.props.getQualitytData(currentSelectedPlantId,startDate,endDate);
        this.props.getAlarmAnalysisData(1,currentSelectedPlantId,startDate,endDate);
        this.props.getQualitytData1(currentSelectedPlantId,startDate,endDate,1);
        this.props.getQualityReport(currentSelectedPlantId,startDate,endDate,1);

        // const diffTime = Math.abs(this.state.range[0].getTime() - this.state.range[1].getTime());
        //             const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
        //             if( diffDays <= 1 ){
        //                console.log('24hour');
        //             }else if(diffDays >1 && diffDays <= 7){
        //                console.log('week');
        //             }else if(diffDays => 8 && diffDays <= 31){
        //            console.log('month');;
        //             }else{
        //            console.log('year');
        //             }
      }

      // setInterval(() => {
      //   this.props.getPlantPartCount(currentSelectedPlantId,startDate,endDate);
      // }, 30000);
    }

    rangeSelector = (e, data, selectDate,selectedTime)=>{
        let rangeStartDate;
        let currentDateTime = new Date(),range = [];

        switch(data){
            case 'today':
              rangeStartDate = new Date();
              rangeStartDate.setHours(0,0,0,0);
              currentDateTime.setHours(24,0,0,0,0);
              range.push(data,rangeStartDate.toISOString(),currentDateTime.toISOString());
            break;
            case 'shift':
              rangeStartDate = new Date();
              let currentHours = rangeStartDate.getHours();
              let shiftNumber = '';
              let totalShifts = this.props.plantShift.plantShift.length;

              for(let i=0;i<totalShifts;i++){
                let x = this.props.plantShift.plantShift[i].timeParameter.startTime.split(':')
                let y = this.props.plantShift.plantShift[i].timeParameter.endTime.split(':')

                let durationOfShift = y[0]-x[0];
                if(parseInt(x[0])+Math.abs(durationOfShift) !== parseInt(y[0])){
                  currentDateTime.setDate(currentDateTime.getDate()+1);
                  if(currentHours>=x[0] || currentHours<=y[0]){
                    rangeStartDate.setHours(x[0],0,0,0);
                    currentDateTime.setHours(y[0],0,0,0);
                    shiftNumber = this.props.plantShift.plantShift[i].shift;
                  }
                }
                else{
                  if(currentHours>=x[0] && currentHours<=y[0]){
                    rangeStartDate.setHours(x[0],0,0,0);
                    currentDateTime.setHours(y[0],0,0,0);
                    shiftNumber = this.props.plantShift.plantShift[i].shift;
                  }
                }
              }
              range.push(data,rangeStartDate.toISOString(),currentDateTime.toISOString());
              this.setState({shiftNumber: shiftNumber});
            break;
            case 'userShiftSelection':
                rangeStartDate = new Date();
              let userSelectedShift = e.target.dataset.value;

              let x = this.props.plantShift.plantShift[userSelectedShift].timeParameter.startTime.split(':')
              let y = this.props.plantShift.plantShift[userSelectedShift].timeParameter.endTime.split(':')

              let durationOfShift = y[0]-x[0];
              if(parseInt(x[0])+Math.abs(durationOfShift) !== parseInt(y[0])){
                currentDateTime.setDate(currentDateTime.getDate()+1);
              }
              rangeStartDate.setHours(x[0],0,0,0);
              currentDateTime.setHours(y[0],0,0,0);

              range.push(data,rangeStartDate.toISOString(),currentDateTime.toISOString());
            break;
            case 'thisMonth':
            rangeStartDate = new Date();
              rangeStartDate.setDate(1);
              rangeStartDate.setHours(0,0,0,0);
              currentDateTime.setDate(1);
              currentDateTime.setUTCMonth(currentDateTime.getUTCMonth()+1);
              currentDateTime.setHours(0,0,0,0)
              range.push(data,rangeStartDate.toISOString(),currentDateTime.toISOString());
            break;
            case '30Minutes':
              rangeStartDate = new Date( currentDateTime.getTime() - 30 * 1000 * 60 );
              rangeStartDate.setHours(rangeStartDate.getHours(),rangeStartDate.getMinutes(),rangeStartDate.getSeconds(),0);
              currentDateTime.setHours(currentDateTime.getHours(),currentDateTime.getMinutes(),currentDateTime.getSeconds(),0);
              range.push(data,rangeStartDate.toISOString(),currentDateTime.toISOString());
            break;
            case 'hour':
              rangeStartDate = new Date( currentDateTime.getTime() - 60 * 1000 * 60 );
              rangeStartDate.setHours(rangeStartDate.getHours(),rangeStartDate.getMinutes(),rangeStartDate.getSeconds(),0);
              currentDateTime.setHours(currentDateTime.getHours(),currentDateTime.getMinutes(),currentDateTime.getSeconds(),0);
              range.push(data,rangeStartDate.toISOString(),currentDateTime.toISOString());
            break;
            case '24hour':
              rangeStartDate = new Date( currentDateTime.getTime() - 24 * 60 * 1000 * 60 );
              rangeStartDate.setHours(rangeStartDate.getHours(),rangeStartDate.getMinutes(),rangeStartDate.getSeconds(),0);
              currentDateTime.setHours(currentDateTime.getHours(),currentDateTime.getMinutes(),currentDateTime.getSeconds(),0);
              range.push(data,rangeStartDate.toISOString(),currentDateTime.toISOString());
            break;
            case 'week':
              rangeStartDate = new Date( currentDateTime.getTime() - 7 * 24 * 60 * 1000 * 60 );
              rangeStartDate.setHours(rangeStartDate.getHours(),rangeStartDate.getMinutes(),rangeStartDate.getSeconds(),0);
              currentDateTime.setHours(currentDateTime.getHours(),currentDateTime.getMinutes(),currentDateTime.getSeconds(),0);
              range.push(data,rangeStartDate.toISOString(),currentDateTime.toISOString());
            break;
            case 'month':
            rangeStartDate = new Date();
              let month = currentDateTime.getUTCMonth();
              rangeStartDate.setUTCMonth(month-1);
              rangeStartDate.setHours(rangeStartDate.getHours(),rangeStartDate.getMinutes(),rangeStartDate.getSeconds(),0);
              currentDateTime.setHours(currentDateTime.getHours(),currentDateTime.getMinutes(),currentDateTime.getSeconds(),0);
              range.push(data,rangeStartDate.toISOString(),currentDateTime.toISOString());
            break;
            case 'year':
            rangeStartDate = new Date();
              let year = currentDateTime.getUTCFullYear();
              rangeStartDate.setUTCFullYear(year-1);
              rangeStartDate.setDate(new Date(rangeStartDate.getFullYear(), rangeStartDate.getMonth() + 1, 0).getDate());
              rangeStartDate.setHours(rangeStartDate.getHours(),rangeStartDate.getMinutes(),rangeStartDate.getSeconds(),0);
              currentDateTime.setDate(new Date(currentDateTime.getFullYear(), currentDateTime.getMonth() + 1, 0).getDate());
              currentDateTime.setHours(currentDateTime.getHours(),currentDateTime.getMinutes(),currentDateTime.getSeconds(),0);
              range.push(data,rangeStartDate.toISOString(),currentDateTime.toISOString());
            break;
            case 'all':
                    let rangeStartDate = new Date(2015, 7, 1, 0, 0, 0, 0);
                    currentDateTime.setHours(0,0,0,0);
                    currentDateTime.setDate(new Date(currentDateTime.getFullYear(), currentDateTime.getMonth() + 1, 0).getDate());
                    range.push(data,rangeStartDate.toISOString(),currentDateTime.toISOString());
            break;
            case 'userSelection':
                    const diffTime = Math.abs(selectedTime.getTime() - selectDate.getTime());
                    const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
                    if( diffDays <= 1 ){
                        range.push('24hour');
                    }else if(diffDays >1 && diffDays <= 7){
                        range.push('week');
                    }else if(diffDays => 8 && diffDays <= 31){
                    range.push('month');;
                    }else{
                    range.push('year');
                    }
                range.push(selectDate.toISOString(),selectedTime.toISOString());
            break;
          }
          this.setState({range : range});
    }

    render() {
      const {range,shiftNumber} = this.state
      const {widgetsData,timeSeriesData} = this.props
        return (
           <div id="prodDashboard">
               <DateRangeSelector plantShift={this.props.plantShift.plantShift} widgetsData={widgetsData} range={range} rangeSelect={this.rangeSelector} shiftNumber={shiftNumber}/>
               <div className="clearfix"></div>

               <div className="prodDashboard-wrp">
                    <ProductivityPanel/>

                    <div className="machineList-container widget-col">
                      <MachineList machineList={this.props.machineList.machineData} range={range} selectedPlantId={this.props.selectedPlantId}/>
                      <MachineProductivityListingPanel plantName={this.props.plantName} machineList={this.props.machineList.machineData} selectedPlantId={this.props.selectedPlantId} oeeByPartNo={this.props.oeeByPartNo.countOEE} range={range} qualityData={this.props.qualityData.qualityData}/>
                    </div>

                    <div className="widget-col dummy-col panel-three">
                      <VariousTimeSeriesGraphWiget timeSeriesData={timeSeriesData} selectedPlantId={this.props.selectedPlantId}/>
                      {/* <ReportPanel machineList={this.props.machineList.machineData} range={range} selectedPlantId={this.props.selectedPlantId}/> */}
                    </div>

               </div>
              <InternetSpeedPanel/>
           </div>
        )
    }
}

const mapStateToProps = state => {
  return {
    machineList : state.machineDataReducer,
    timeSeriesData : state.timeSeriesDataReducer,
    plantShift : state.plantShiftReducer,
    oeeByPartNo : state.oeeByPartNumberReducer,
    qualityData: state.qualityDataReducer,
    alarmsData: state.alarmAnalysisDataReducer
  }
}

const mapDispatchToProps = dispatch => {
  return {
      getAccessToken: (plantId , from, to) => (dispatch((actionCreators.getAccessToken(plantId , from, to)))),
      getPlantPartCount: (plantId , from, to) => (dispatch((actionCreators.getPlantPartCount(plantId , from, to)))),
      getMachineData: (plantId , from, to) => (dispatch((actionCreators.getMachineData(plantId,from, to)))),
      getProductivityIndexData: (plantId , from, to) => (dispatch((actionCreators.getProductivityIndexData(plantId,from, to)))),
      getTimeSeriesData: (plantId , from, to) => (dispatch((actionCreators.getTimeSeriesData(plantId , from, to)))),
      getPlantShift: (plantId) => (dispatch((actionCreators.getPlantShift(plantId)))),
      getOEEByPartNumber: (plantId , from, to, partNumber) => (dispatch((actionCreators.getOEEByPartNumber(plantId , from, to,partNumber)))),
      getProductivityByMachineNPlantId:(plantId ,machineId, from, to) => (dispatch((actionCreators.getProductivityByMachineNPlantId(plantId,machineId,from, to)))),
      getPartNumberList: (plantId) => (dispatch((actionCreators.getPartNumberList(plantId)))),
      getMachineProductionHistory: (plantId , from, to,pd) => (dispatch((actionCreators.getMachineProductionHistory(plantId , from, to, pd)))),
      getOeeSelection: (plantId ,machineId, from, to) => (dispatch((actionCreators.getOeeSelection(plantId ,machineId, from, to)))),
      getInputDialog: (plantId) => (dispatch((actionCreators.getInputDialog(plantId)))),
      getDowntimeAnalysis: (plantId , from, to) => (dispatch((actionCreators.getDowntimeAnalysis(plantId , from, to)))),
      getMaintenanceMetricsData : (plantId , from, to) => (dispatch((actionCreators.getMaintenanceMetricsData(plantId , from, to)))),
      getLossDropDownList: () => (dispatch((actionCreators.getLossDropDownList()))),
      getTopPlantLoss: (plantId , from, to) => (dispatch((actionCreators.getTopPlantLoss(plantId , from, to)))),
      getQualitytData: (plantId , from, to) => (dispatch((actionCreators.getQualitytData(plantId , from, to)))),
      getAlarmAnalysisData: (type, plantId, from, to) => (dispatch((actionCreators.getAlarmAnalysisData(type, plantId, from, to)))),
      getQualitytData1: (plantId , from, to, type) => (dispatch((actionCreators.getQualitytData1(plantId , from, to, type)))),
      getQualityReport: (plantId , from, to, type) => (dispatch((actionCreators.getQualityReport(plantId , from, to, type)))),

  }
}

export default connect(mapStateToProps,mapDispatchToProps )(ProductionDashboard);
