import React from 'react';
import { Button, Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions } from '@material-ui/core';
import { withTranslation } from "react-i18next";

const ConfirmationModal = (props) => {
    return (
        <Dialog
            open={props.openPromptModal}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            disableBackdropClick={true}
        >
            {/* {Changed for Common Archive functionality on 08.01.2021} */}
            <DialogTitle id="alert-dialog-title">{`${props.delete ? (props.entityName ? props.t("Delete")+" " + props.entityName +"?" : props.t("Delete Entity") +"?") : props.entityName === props.t("devices") ? props.t("Archive Device/s") : (props.entityName ? props.t("Archive") +" "+ props.entityName +"":props.t("Archive Entity"))}`}</DialogTitle>
            <DialogContent>                
                <DialogContentText id="alert-dialog-description" style={{ color: "red" }}>
                    {/* {`Continuing with the current action will result in ${props.delete ? "deleting the entity and its dependencies." : props.entityName === "devices" ? "de-registration and deactivation of the selected devices." : "deactivating the entity and its dependencies."}`} */}
                    {props.t("The selected entity/s will be hidden, and mapping with all other entities will be deleted. Do you want to continue?")}
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={() => props.handlePromptModal("disagree")} color="primary">
                    {props.t("Cancel")}
                </Button>
                <Button onClick={() => props.handlePromptModal("agree")} color="primary">
                    {/* {`${props.delete ? "Delete" : props.entityName === "devices" ? "De-register" : "Archive"}`} */}
                    {`${props.delete ? props.t("Delete") : props.t("Archive")}`}
                </Button>
            </DialogActions>
        </Dialog>
    )
}

export default withTranslation()(ConfirmationModal);