import React, { Component } from 'react';
import { connect } from 'react-redux';
import {
    withStyles,
    Dialog,
    DialogActions,
    Button,
    InputLabel,
    Select,
    OutlinedInput
} from '@material-ui/core';
import { withTranslation } from "react-i18next";

import * as actionCreators from '../../actions/actionCreators';

const styles = () => ({
    dialog: {
        padding: '12px 24px'
    },
    outlinedInput: {
        padding: 12
    },
    elementContainer: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        paddingTop: 24
    },
    textFeildLabel: {
        color: 'rgba(0, 0, 0, 0.4000000059604645)',
        'letter-spacing': 0.5,
        'font-weight': 500,
        fontSize: "14px",
        paddingLeft: "7px",
        lineHeight: 2.5
    },
    textField: {
        border: "1px solid #00000005",
        borderRadius: "6px",
        width: '315px',
        height: '45px',
    },
    textArea: {
        border: "1px solid #00000005",
        width: '315px',
    },
    multiline: {
        paddingTop: 7
    },
    saveButton: {
        width: 100,
        backgroundColor: '#42D784',
        '&:hover': {
            backgroundColor: '#42D784'
        }
    },
    dialogAction: {
        marginTop: 24
    }
})

class ScheduledReportsModal extends Component {

    state = {
        machineRole: "",
        machineType: "",
        machineGroup: "",
        schedule: "",
        jobList: "",
        lossList: "",
        defaultJob: "",
        specs: "",
        notes: "",
        changedMachineRole: false,
        changedMachineType: false,
        changedMachineGroup: false,
        changedSchedule: false,
        changedJobList: false,
        changedLossList: false,
        changedDefaultJob: false,
        changedNotes: false,
        changedSpecs: false
    }

    componentDidMount() {
        if (this.props.selectedPlant) {
            this.props.getTableData("SchedulesTable", this.props.selectedPlant, true);
            this.props.getTableData("JobListTable", this.props.selectedPlant, true);
            this.props.getTableData("LossListTable", this.props.selectedPlant, true);
            this.props.getTableData("MachineGroupsTable", this.props.selectedPlant, true);
            this.props.getTableData("JobsTable", this.props.selectedPlant, true);
        }
    }

    // handle updated user input values
    handleUpdate = () => {
        this.props.updateSelectedRecords(this.state);
        this.props.disableBulkEditingView();
    }

    // handle modal window close action
    handleClose = () => {
        this.props.disableBulkEditingView();
        this.setState({
            machineRole: "",
            machineType: "",
            machineGroup: "",
            schedule: "",
            jobList: "",
            lossList: "",
            defaultJob: "",
            specs: "",
            notes: "",
            changedMachineRole: false,
            changedMachineType: false,
            changedMachineGroup: false,
            changedSchedule: false,
            changedJobList: false,
            changedLossList: false,
            changedDefaultJob: false,
            changedNotes: false,
            changedSpecs: false
        })
    }

    // handle user input changes
    onChangeHandler = (event, item) => {
        switch (item) {
            case "MachineRole":
                this.setState({
                    changedMachineRole: true,
                    machineRole: event.target.value
                })
                break;
            case "MachineType":
                this.setState({
                    changedMachineType: true,
                    machineType: event.target.value
                })
                break;
            case "MachineGroup":
                this.setState({
                    changedMachineGroup: true,
                    machineGroup: event.target.value
                })
                break;
            case "DefaultJob":
                this.setState({
                    changedDefaultJob: true,
                    defaultJob: event.target.value
                })
                break;
            case "Schedule":
                this.setState({
                    changedSchedule: true,
                    schedule: event.target.value
                })
                break;
            case "JobList":
                this.setState({
                    changedJobList: true,
                    jobList: event.target.value
                })
                break;
            case "LossList":
                this.setState({
                    changedLossList: true,
                    lossList: event.target.value
                })
                break;
            case "Notes":
                this.setState({
                    changedNotes: true,
                    notes: event.target.value
                })
                break;
            case "Specs":
                this.setState({
                    changedSpecs: true,
                    specs: event.target.value
                })
                break;
            default: console.log("default case");
        }
    }

    // create schedules dropdown list
    getScheduleName = () => {
        let scheduleList = [];
        scheduleList.push(<option key={""} disabled={true} value={""}>{"Select"}</option>);
        if((this.props.config.schedulesName.validations && !this.props.config.schedulesName.validations.required) || !(this.props.config.schedulesName.validations))
        {
            scheduleList.push(<option key={""} value={" "}>{"None"}</option>);
        }
        if (this.props.schedulesData && this.props.schedulesData.tableData && this.props.schedulesData.tableData.length > 0) {
            this.props.schedulesData.tableData.map((data) => {
                scheduleList.push(
                    <option key={data.id} value={data.id}>{data.name}</option>
                );
            })
        }
        return scheduleList;
    }

    // create job-lists dropdown list
    getJobListName = () => {
        let jobList = [];
        jobList.push(<option key={""} disabled={true} value={""}>{"Select"}</option>);
        if((this.props.config.jobListsName.validations && !this.props.config.jobListsName.validations.required) || !(this.props.config.jobListsName.validations))
        {
            jobList.push(<option key={""} value={" "}>{"None"}</option>);
        }
        if (this.props.jobsData && this.props.jobsData.tableData && this.props.jobsData.tableData.length > 0) {
            this.props.jobsData.tableData.map((data) => {
                jobList.push(
                    <option key={data.id} value={data.id}>{data.name}</option>
                );
            })
        }
        return jobList;
    }

    // create loss-lists dropdown list
    getLossListName = () => {
        let lossList = [];
        lossList.push(<option key={""} disabled={true} value={""}>{"Select"}</option>);
        if((this.props.config.lossListsName.validations && !this.props.config.lossListsName.validations.required) || !(this.props.config.lossListsName.validations))
        {
            lossList.push(<option key={""} value={" "}>{"None"}</option>);
        }
        if (this.props.lossCodes && this.props.lossCodes.tableData && this.props.lossCodes.tableData.length > 0) {
            this.props.lossCodes.tableData.map((data) => {
                lossList.push(
                    <option key={data.id} value={data.id}>{data.name}</option>
                );
            })
        }
        return lossList;
    }

    // create machine-types dropdown list
    getMachineTypes = () => {
        let machineTypeList = [];
        machineTypeList.push(<option key={""} disabled={true} value={""}>{"Select"}</option>);
        if((this.props.config.machineTypeName.validations && !this.props.config.machineTypeName.validations.required) || !(this.props.config.machineTypeName.validations))
        {
        machineTypeList.push(<option key={""} value={" "}>{"None"}</option>);
        }
        if (this.props.parentOrganizationId && this.props.staticData && this.props.staticData.staticData) {
            let plantOrganizationStaticData = this.props.staticData.staticData[this.props.parentOrganizationId]
            if (plantOrganizationStaticData && plantOrganizationStaticData.machinetypes) {
                plantOrganizationStaticData.machinetypes.map((data) => {
                    machineTypeList.push(
                        <option key={data.id} value={data.id}>{data.name}</option>
                    );
                })
            }
        }
        return machineTypeList;
    }

    // create machine-groups dropdown list
    getMachineGroups = () => {
        let machineGroupList = [];
        machineGroupList.push(<option key={""} disabled={true} value={""}>{"Select"}</option>);
        if((this.props.config.machineGroupsName.validations && !this.props.config.machineGroupsName.validations.required) || !(this.props.config.machineGroupsName.validations))
        {
            machineGroupList.push(<option key={""} value={" "}>{"None"}</option>);
        }
        if (this.props.machineGroupData && this.props.machineGroupData.tableData && this.props.machineGroupData.tableData.length > 0) {
            this.props.machineGroupData.tableData.map((data) => {
                machineGroupList.push(
                    <option key={data.id} value={data.id}>{data.name}</option>
                );
            })
        }
        return machineGroupList;
    }

    // create jobs dropdown list
    getJobName = () => {
        let jobs = [];
        jobs.push(<option key={""} disabled={true} value={""}>{"Select"}</option>);
        if((this.props.config.jobsName.validations && !this.props.config.jobsName.validations.required) || !(this.props.config.jobsName.validations))
        {
            jobs.push(<option key={""} value={" "}>{"None"}</option>);
        }
        if (this.props.defaultJobsData && this.props.defaultJobsData.tableData && this.props.defaultJobsData.tableData.length > 0) {
            this.props.defaultJobsData.tableData.map((data) => {
                if (data.partRef) {
                    jobs.push(
                        <option key={data.id} value={data.id}>{data.partRef}</option>
                    )
                }
            })
        }
        return jobs;
    }

    render() {
        const {t}=this.props;
        return (
            <Dialog
                open={this.props.enableBulkEditingView}
                onClose={this.handleClose}
                aria-labelledby="form-dialog-title"
                maxWidth="sm"
                fullWidth={true}
                classes={{ paper: this.props.classes.dialog }}
                disableBackdropClick={true}
            >
                {
                    this.props.config.role.page_visible ?
                        (
                            <div className={this.props.classes.elementContainer}>
                                <InputLabel className={this.props.classes.textFeildLabel} style={{ alignSelf: 'flex-start' }}>
                                    {this.props.config.role.display_name}
                                </InputLabel>
                                <OutlinedInput
                                    value={this.state.machineRole}
                                    onChange={(event) => this.onChangeHandler(event, "MachineRole")}
                                    className={this.props.classes.textField}
                                    labelWidth={0}
                                />
                            </div>
                        )
                        : null
                }
                {
                    this.props.config.machineTypeName.page_visible ?
                        (
                            <div className={this.props.classes.elementContainer}>
                                <InputLabel className={this.props.classes.textFeildLabel}>
                                    {this.props.config.machineTypeName.display_name}
                                </InputLabel>
                                <Select
                                    native
                                    value={this.state.machineType}
                                    onChange={(event) => this.onChangeHandler(event, "MachineType")}
                                    input={
                                        <OutlinedInput
                                            variant="outlined"
                                            name="machineType"
                                            id="language-native-simple"
                                            labelWidth={0}
                                            className={this.props.classes.textField}
                                            classes={{ input: this.props.classes.outlinedInput }}
                                        />
                                    }
                                >
                                    {this.getMachineTypes()}
                                </Select>
                            </div>
                        )
                        : null
                }
                {
                    this.props.config.machineGroupsName.page_visible ?
                        (
                            <div className={this.props.classes.elementContainer}>
                                <InputLabel className={this.props.classes.textFeildLabel}>
                                    {this.props.config.machineGroupsName.display_name}
                                </InputLabel>
                                <Select
                                    native
                                    value={this.state.machineGroup}
                                    onChange={(event) => this.onChangeHandler(event, "MachineGroup")}
                                    input={
                                        <OutlinedInput
                                            variant="outlined"
                                            name="machineGroup"
                                            id="language-native-simple"
                                            labelWidth={0}
                                            className={this.props.classes.textField}
                                            classes={{ input: this.props.classes.outlinedInput }}
                                        />
                                    }
                                >
                                    {this.getMachineGroups()}
                                </Select>
                            </div>
                        )
                        : null
                }
                {
                    this.props.config.jobsName.page_visible ?
                        (
                            <div className={this.props.classes.elementContainer}>
                                <InputLabel className={this.props.classes.textFeildLabel}>
                                    {this.props.config.jobsName.display_name}
                                </InputLabel>
                                <Select
                                    native
                                    value={this.state.defaultJob}
                                    onChange={(event) => this.onChangeHandler(event, "DefaultJob")}
                                    input={
                                        <OutlinedInput
                                            variant="outlined"
                                            name="defaultJob"
                                            id="language-native-simple"
                                            labelWidth={0}
                                            className={this.props.classes.textField}
                                            classes={{ input: this.props.classes.outlinedInput }}
                                        />
                                    }
                                >
                                    {this.getJobName()}
                                </Select>
                            </div>
                        )
                        : null
                }
                {
                    this.props.config.schedulesName.page_visible ?
                        (
                            <div className={this.props.classes.elementContainer}>
                                <InputLabel className={this.props.classes.textFeildLabel}>
                                    {this.props.config.schedulesName.display_name}
                                </InputLabel>
                                <Select
                                    native
                                    value={this.state.schedule}
                                    onChange={(event) => this.onChangeHandler(event, "Schedule")}
                                    input={
                                        <OutlinedInput
                                            variant="outlined"
                                            name="jschedule"
                                            id="language-native-simple"
                                            labelWidth={0}
                                            className={this.props.classes.textField}
                                            classes={{ input: this.props.classes.outlinedInput }}
                                        />
                                    }
                                >
                                    {this.getScheduleName()}
                                </Select>
                            </div>
                        )
                        : null
                }
                {
                    this.props.config.jobListsName.page_visible ?
                        (
                            <div className={this.props.classes.elementContainer}>
                                <InputLabel className={this.props.classes.textFeildLabel}>
                                    {this.props.config.jobListsName.display_name}
                                </InputLabel>
                                <Select
                                    native
                                    value={this.state.jobList}
                                    onChange={(event) => this.onChangeHandler(event, "JobList")}
                                    input={
                                        <OutlinedInput
                                            variant="outlined"
                                            name="jobListId"
                                            id="language-native-simple"
                                            labelWidth={0}
                                            className={this.props.classes.textField}
                                            classes={{ input: this.props.classes.outlinedInput }}
                                        />
                                    }
                                >
                                    {this.getJobListName()}
                                </Select>
                            </div>
                        )
                        : null
                }
                {
                    this.props.config.lossListsName.page_visible ?
                        (
                            <div className={this.props.classes.elementContainer}>
                                <InputLabel className={this.props.classes.textFeildLabel}>
                                    {this.props.config.lossListsName.display_name}
                                </InputLabel>
                                <Select
                                    native
                                    value={this.state.lossList}
                                    onChange={(event) => this.onChangeHandler(event, "LossList")}
                                    input={
                                        <OutlinedInput
                                            variant="outlined"
                                            name="lossListId"
                                            id="language-native-simple"
                                            labelWidth={0}
                                            className={this.props.classes.textField}
                                            classes={{ input: this.props.classes.outlinedInput }}
                                        />
                                    }
                                >
                                    {this.getLossListName()}
                                </Select>
                            </div>
                        )
                        : null
                }
                {
                    this.props.config.notes.page_visible ?
                        (
                            <div className={this.props.classes.elementContainer}>
                                <InputLabel className={this.props.classes.textFeildLabel} style={{ alignSelf: 'flex-start' }}>
                                    {this.props.config.notes.display_name}
                                </InputLabel>
                                <OutlinedInput
                                    multiline
                                    rows="5"
                                    value={this.state.notes}
                                    onChange={(event) => this.onChangeHandler(event, "Notes")}
                                    className={this.props.classes.textArea}
                                    classes={{ multiline: this.props.classes.multiline }}
                                    labelWidth={0}
                                />
                            </div>
                        )
                        : null
                }
                {
                    this.props.config.specs.page_visible ?
                        (
                            <div className={this.props.classes.elementContainer}>
                                <InputLabel className={this.props.classes.textFeildLabel} style={{ alignSelf: 'flex-start' }}>
                                    {this.props.config.specs.display_name}
                                </InputLabel>
                                <OutlinedInput
                                    multiline
                                    rows="5"
                                    value={this.state.specs}
                                    onChange={(event) => this.onChangeHandler(event, "Specs")}
                                    className={this.props.classes.textArea}
                                    classes={{ multiline: this.props.classes.multiline }}
                                    labelWidth={0}
                                />
                            </div>
                        )
                        : null
                }
                <DialogActions classes={{ root: this.props.classes.dialogAction }}>
                    <Button onClick={this.handleClose} color="primary">
                        {t("Cancel")}
                    </Button>
                    <Button
                        variant="contained"
                        color="primary"
                        disabled={!(this.state.changedMachineRole || this.state.changedMachineType || this.state.changedMachineGroup || this.state.changedSchedule || this.state.changedJobList || this.state.changedLossList || this.state.changedDefaultJob || this.state.changedNotes || this.state.changedSpecs)}
                        className={this.props.classes.saveButton}
                        onClick={this.handleUpdate}
                    >
                        {t("Save")}
                        </Button>
                </DialogActions>
            </Dialog>
        )
    }
}

const mapStateToProps = state => {
    return {
        schedulesData: state.schedulesDataReducer,
        jobsData: state.jobListDataReducer,
        lossCodes: state.lossListDataReducer,
        machineGroupData: state.machineGroupReducer,
        defaultJobsData: state.jobsDataReducer
    }
}

const mapDispatchToProps = dispatch => {
    return {
        getTableData: (selectedNavtab, currentScopeSelectionId, setToStore) => (dispatch(actionCreators.getTableData(selectedNavtab, currentScopeSelectionId, setToStore))),
    }
}

export default withTranslation()(connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(ScheduledReportsModal)));