import React, { Component } from 'react';
import Highcharts from 'highcharts';
import { Progress } from 'reactstrap';
import { Link, Checkbox, Tooltip } from '@material-ui/core';
import RefreshIcon from '@material-ui/icons/Refresh';
import Drawer from '@material-ui/core/Drawer';
import PopoverTable from './AlarmsPopover/PopoverTable';
import { connect } from 'react-redux';
import moment from "moment";
import OpenInNewIcon from '@material-ui/icons/OpenInNew';
import AlarmWatchlistModal from './AlarmWatchlistModal/AlarmWatchlistModal'
import Brightness1Icon from '@material-ui/icons/Brightness1';
import { withTranslation } from "react-i18next";


const colors = {
  1: "#64dd17",
  2: "#ffc107",
  3: "#ff5722"
}

const allintervals = {
  "userSelection": [
    { "intervalUnit": "minute", "intervalValue": 1, "intervalName": "1 Minute" },
    { "intervalUnit": "minute", "intervalValue": 30, "intervalName": "30 Minute" },
    { "intervalUnit": "hour", "intervalValue": 6, "intervalName": "6 Hour" }
  ],
  "today": [
    { "intervalUnit": "minute", "intervalValue": 30, "intervalName": "30 Minute" },
    { "intervalUnit": "minute", "intervalValue": 1, "intervalName": "1 Minute" },
    { "intervalUnit": "hour", "intervalValue": 6, "intervalName": "6 Hour" }
  ],
  "thisMonth": [
    { "intervalUnit": "hour", "intervalValue": 6, "intervalName": "6 Hour" },
    { "intervalUnit": "day", "intervalValue": 1, "intervalName": "1 Day" },
    { "intervalUnit": "day", "intervalValue": 7, "intervalName": "7 Day" }

  ],
  "30Minutes": [
    { "intervalUnit": "seconds", "intervalValue": 1, "intervalName": "All" },
    { "intervalUnit": "seconds", "intervalValue": 10, "intervalName": "10 Seconds" },
    { "intervalUnit": "minute", "intervalValue": 1, "intervalName": "1 Minute" }
  ],
  "hour": [
    { "intervalUnit": "seconds", "intervalValue": 10, "intervalName": "10 Seconds" },
    { "intervalUnit": "minute", "intervalValue": 1, "intervalName": "1 Minute" },
    { "intervalUnit": "minute", "intervalValue": 30, "intervalName": "30 Minute" }
  ],
  "24hour": [
    { "intervalUnit": "minute", "intervalValue": 30, "intervalName": "30 Minute" },
    { "intervalUnit": "minute", "intervalValue": 1, "intervalName": "1 Minute" },
    { "intervalUnit": "hour", "intervalValue": 6, "intervalName": "6 Hour" }
  ],
  "week": [
    { "intervalUnit": "minute", "intervalValue": 30, "intervalName": "30 Minute" },
    { "intervalUnit": "hour", "intervalValue": 6, "intervalName": "6 Hour" },
    { "intervalUnit": "day", "intervalValue": 1, "intervalName": "1 Day" }
  ],
  "month": [
    { "intervalUnit": "hour", "intervalValue": 6, "intervalName": "6 Hour" },
    { "intervalUnit": "day", "intervalValue": 1, "intervalName": "1 Day" },
    { "intervalUnit": "day", "intervalValue": 7, "intervalName": "7 Day" }
  ],
  "year": [
    { "intervalUnit": "day", "intervalValue": 1, "intervalName": "1 Day" },
    { "intervalUnit": "day", "intervalValue": 7, "intervalName": "7 Day" },
    { "intervalUnit": "day", "intervalValue": 30, "intervalName": "1 Month" }
  ],
  "all": [
    { "intervalUnit": "day", "intervalValue": 7, "intervalName": "7 Day" },
    { "intervalUnit": "day", "intervalValue": 30, "intervalName": "1 Month" }
  ]
}

export class MaintenancePanel extends Component {
  constructor(props) {
    super(props);
    const deviveStateRow = this.props.widgetsData.widgetData.commonStateForDevice;
    this.state = {
      top: false,
      left: false,
      bottom: false,
      right: false,
      status: 3,
      tooltip: "",
      data: 0,
      colorStyle: "",
      series: [{
        name: this.props.t('Index'),
        data: [
          {
            name: deviveStateRow && deviveStateRow.deviceState[0].value,
            y: 0,
            color: '#31ccec',
            bg: 'rgb(49, 204, 236,0.1)',
            id: deviveStateRow && deviveStateRow.deviceState[0].name
          },
          {
            name: deviveStateRow && deviveStateRow.deviceState[1].value,
            y: 0,
            color: '#64dd17',
            bg: 'rgb(100, 221, 23,0.1)',
            id: deviveStateRow && deviveStateRow.deviceState[1].name
          },
          {
            name: deviveStateRow && deviveStateRow.deviceState[2].value,
            y: 0,
            color: '#ffc107',
            bg: 'rgb(255, 193, 7,0.1)',
            id: deviveStateRow && deviveStateRow.deviceState[2].name
          },
          {
            name: deviveStateRow && deviveStateRow.deviceState[3].value,
            y: 0,
            color: '#ff5722',
            bg: 'rgb(255, 87, 34,0.1)',
            id: deviveStateRow && deviveStateRow.deviceState[3].name
          },
          {
            name: deviveStateRow && deviveStateRow.deviceState[4].value,
            y: 0,
            color: '#9e9e9e',
            bg: 'rgb(62, 62, 62,0.1)',
            id: deviveStateRow && deviveStateRow.deviceState[4].name
          },
          {
            name: deviveStateRow && deviveStateRow.deviceState[5].value,
            y: 0,
            color: '#800080',
            bg: '#fae1fa',
            id: deviveStateRow && deviveStateRow.deviceState[5].name
          }
        ]
      }]
    }
    this.largestPercent = this.state.series[0].data[0];
    this.deviceAlarmData = [];
  }

  highChartsRender(series) {
    Highcharts.chart({
      chart: {
        type: 'pie',
        renderTo: 'piePanel',
        // width: '268',
        height: '250px'

      },
      title: {
        verticalAlign: 'middle',
        floating: true,
        text: '',
        style: {
          fontSize: '10px',
        }
      },
      credits: {
        "enabled": false
      },
      exporting: {
        "enabled": false
      },
      plotOptions: {
        pie: {
          dataLabels: {
            format: '{point.name}: {point.percentage:.1f} %'
          },
          size: '90%',
          innerSize: '45%'
        }
      },
      series: series
    });
  }

  createData = (id, name, time, level, details, machineName, actionNote) => {
    return { id, name, time, level, details, machineName, actionNote };
  }

  calLargestPercent(series) {
    // let count='';
    // series[0].data.forEach((ele) => {
    //   if(ele.y != 0) 
    //     count = count + 1;
    // })

    // if(count>0) {
    //   this.largestPercent = series[0].data.reduce(function (prev, current) {
    //     return (prev.y > current.y) ? prev : current
    //   })
    // }
    // else {
    //   this.largestPercent = series[0].data[0];
    // }
    this.largestPercent = series[0].data[1];
  }
  actionStatus=()=>{
    var today = new Date().getTime();
    var subscriptionStart = null;
    var subscriptionEnd = null;
    if (this.props.deviceData && this.props.deviceData.deviceData) {
      subscriptionStart = this.props.deviceData.deviceData.subscriptionStart;
      subscriptionEnd = this.props.deviceData.deviceData.subscriptionEnd;
    }
    var status = (today > subscriptionStart && today < subscriptionEnd && ((subscriptionEnd - today) / (1000 * 3600 * 24)) > 30) ? 1 :
      ((today > subscriptionStart && today < subscriptionEnd && ((subscriptionEnd - today) / (1000 * 3600 * 24)) < 30 ? 2 : 3))
    var subStart = subscriptionStart ? new Date(subscriptionStart).getDate() + "/" + (new Date(subscriptionStart).getMonth() + 1) + "/" + new Date(subscriptionStart).getFullYear() : null;
    var subEnd = subscriptionEnd ? new Date(subscriptionEnd).getDate() + "/" + (new Date(subscriptionEnd).getMonth() + 1) + "/" + new Date(subscriptionEnd).getFullYear() : null;
    var tooltip = subStart && subEnd ? this.props.t("Subscription Start") + ": " + subStart + ' & ' + this.props.t("Subscription End") + ": " + subEnd : "-"
    this.setState({
      status: status,
      tooltip: tooltip
    })
  }


  componentDidUpdate(prevProps) {
    const deviveStateRow = this.props.widgetsData.widgetData.commonStateForDevice;
    if (this.props.deviceData != prevProps.deviceData) {
     this.actionStatus();
      //Added for rssi quality on 28.12.2020
      var rssi = this.props.deviceData.deviceData.rssi;
      if (this.props.deviceData && this.props.deviceData.deviceData && this.props.deviceData.deviceData.rssi) {
        if (rssi < -60) {
          rssi = `${rssi} (${this.props.t('POOR')})`;
          this.setState({
            rssi: rssi,
            colorStyle: "red"
          })
        } else if (rssi <= -45 && rssi >= -60) {
          rssi = `${rssi} (${this.props.t('MEDIUM')})`;
          this.setState({
            rssi: rssi,
            colorStyle: "#ffc107"

          })
        } else {
          rssi = `${rssi} (${this.props.t('GOOD')})`;
          this.setState({
            rssi: rssi,
            colorStyle: "green"

          })
        }
      }


    }
    if (prevProps.maintenanceData != this.props.maintenanceData) {
      this.props.onMaintainanceDone();
      this.actionStatus();
      let series = [{
        name: this.props.t('Index'),
        data: [
          {
            name: deviveStateRow && deviveStateRow.deviceState[0].value,
            y: (this.props.maintenanceData['1'] != null) ? this.props.maintenanceData['1'] : 0,
            color: '#31ccec',
            bg: 'rgb(49, 204, 236,0.1)',
            id: deviveStateRow && deviveStateRow.deviceState[0].name
          },
          {
            name: deviveStateRow && deviveStateRow.deviceState[1].value,
            //name: "Normal",
            y: (this.props.maintenanceData['2'] != null) ? this.props.maintenanceData['2'] : 0,
            color: '#64dd17',
            bg: 'rgb(100, 221, 23,0.1)',
            id: deviveStateRow && deviveStateRow.deviceState[1].name
          },
          {
            name: deviveStateRow && deviveStateRow.deviceState[2].value,
            y: (this.props.maintenanceData['3'] != null) ? this.props.maintenanceData['3'] : 0,
            color: '#ffc107',
            bg: 'rgb(255, 193, 7,0.1)',
            id: deviveStateRow && deviveStateRow.deviceState[2].name
          },
          {
            name: deviveStateRow && deviveStateRow.deviceState[3].value,
            y: (this.props.maintenanceData['4'] != null) ? this.props.maintenanceData['4'] : 0,
            color: '#ff5722',
            bg: 'rgb(255, 87, 34,0.1)',
            id: deviveStateRow && deviveStateRow.deviceState[3].name
          },
          {
            name: deviveStateRow && deviveStateRow.deviceState[4].value,
            y: (this.props.maintenanceData['5'] != null) ? this.props.maintenanceData['5'] : 0,
            color: '#9e9e9e',
            bg: 'rgb(62, 62, 62,0.1)',
            id: deviveStateRow && deviveStateRow.deviceState[4].name
          },
          {
            name: deviveStateRow && deviveStateRow.deviceState[5].value,
            y: (this.props.maintenanceData['6'] != null) ? this.props.maintenanceData['6'] : 0,
            color: '#800080',
            bg: '#fae1fa',
            id: deviveStateRow && deviveStateRow.deviceState[5].name
          }
        ]
      }];
      this.setState({ series: series })
      this.calLargestPercent(series);
      if(!this.props.batteryFlag){
        // this.highChartsRender(series);    // Dashboard cleanup #4967
      }

      let from = moment(this.props.range[1]);
      let to = moment(this.props.range[2]);
      let daysBetween = to.diff(from, 'days') + 1;
      let monthDays = from.format('M') == 2 ? 28 : 30;
      if (daysBetween >= monthDays) {
        this.setState({
          displayAlarmWatchlistButton: true
        })
      } else {
        this.setState({
          displayAlarmWatchlistButton: false
        })
      }
    }
  }

  handleOpenModal = () => {
    this.setState({ showModal: true });
  }

  handleCloseModal = () => {
    this.setState({ showModal: false });
  }

  // Open alarm watchlist modal
  viewAlarmWatchlist = () => {
    this.setState({
      viewAlarmWatchlist: true
    })
  }

  // Close alarm watchlist modal
  closeAlarmWatchlistModal = () => {
    this.setState({
      viewAlarmWatchlist: false
    })
  }

  toggleDrawer = (side, open, alaramStatus) => {

    let arr = this.props.alarmsData && this.props.alarmsData.alarmsData ? this.props.alarmsData.alarmsData : [];

    arr.forEach(element => {
      element.time = new Date(element.time);
    })

    arr.sort(function (a, b) {
      return b.time.getTime() - a.time.getTime()
    });

    this.deviceAlarmData = [];
    let description = '';
    let time = '';
    arr.forEach(element => {
      description = element.description.replace(/{|"|}/g, '');
      let featureData = this.props.featureList.find(ele => ele.value === element.featureCode);
      if (featureData) {
        description = element.featureName + ": Value:" + element.value + ",Threshold:" + element.threshold;
      }
      time = moment(element.time).utc().local().format("YYYY-MM-DD HH:mm:ss");
      if (alaramStatus == 'totalAlarms') {
        this.deviceAlarmData.push(this.createData(element.id, this.props.deviceName, time, element.level, description, this.props.machineName, element.actionNote));
      }
    })
    this.setState({ [side]: open });
  }
  navigateConfigPage = (value) => {
    let plantView = localStorage.getItem("PlantView")
    let plantViewArray = JSON.parse(plantView);
    let selectedScopeData = localStorage.getItem("selectedScopeData");
    let permission;

    if (plantViewArray !== null) {
      permission = plantViewArray.filter(function (e) { return parseInt(e.id) === parseInt(selectedScopeData) });
    }
    if ((this.props.props.userData.permission === "configuration") || permission[0].permission === "configuration") {
      localStorage.setItem("navigateID", value)
      this.props.updateSelectedTab(value);
    }
  }

  render() {
    const { t } = this.props;
    let plantView = localStorage.getItem("PlantView")
    let plantViewArray = JSON.parse(plantView);
    let selectedScopeData = localStorage.getItem("selectedScopeData");
    let permission;

    if (plantViewArray !== null) {
      permission = plantViewArray.filter(function (e) { return parseInt(e.id) === parseInt(selectedScopeData) });
      permission = permission && permission[0] && permission[0].permission
    }
    const row = this.props.widgetsData.widgetData.maintainenceIndex;
    const { series } = this.state
    let progressItem = series[0].data;
    let x = this.largestPercent.color;
    const divStyle = {
      backgroundColor: '' + x + '',
    };
    const renderLink = () => {
      if (this.props.range[0] === 'today' || this.props.range[0] === 'thisMonth') {
        return <Link
          component="button"
          variant="body2"
          title={this.props.t("Refresh Data")}
          onClick={() => {
            this.props.onRefreshMaintainace()
          }}>
          <RefreshIcon></RefreshIcon>
        </Link>

      }
    }
    const rootOrganization = this.props.userData.scopeSelector.subOrganizations.find(ele=>ele.root)
    const isUserAdminPublisher = this.props.userData && this.props.userData.admin && rootOrganization && rootOrganization.organizationType === "publisher";

    return (
      <div className={this.props.batteryFlag ?"":"index-panel"}>
        <div className="widgets-shadow widgets-info" style={{ width: "100%" }}>
          <div className="widgets-heading widgets-heading-info">
            <h2 className="display-text" style={{ paddingRight: "0px" }}>{t("Device Details")} </h2>
          </div>
          {/* Added for Device Details on 18.12.2020 */}
          <div className="widgets-container">
            <div style={{ marginTop: "5px", padding: "5px", fontSize: "12px" }}>
              <div className="display-text" style={{ fontWeight: "500" }}>
                <span className="display-text">{t("Device Identifier")}</span>
              </div><br></br>
              <div className="display-text">
                <span className="display-text" style={{ color: "#1664c0", cursor: ((this.props.props.monitorData && this.props.props.monitorData.data && this.props.props.monitorData.data.devices && this.props.props.monitorData.data.devices.deviceIdentifier) && ((this.props.props.userData.permission === "configuration") || permission === "configuration")) ? "pointer" : null }} onClick={() => this.props.props.monitorData && this.props.props.monitorData.data && this.props.props.monitorData.data.devices && this.props.props.monitorData.data.devices.deviceIdentifier && this.navigateConfigPage(this.props.deviceId)}>{this.props.props.monitorData && this.props.props.monitorData.data && this.props.props.monitorData.data.devices && this.props.props.monitorData.data.devices.deviceIdentifier ? this.props.props.monitorData && this.props.props.monitorData.data && this.props.props.monitorData.data.devices && this.props.props.monitorData.data.devices.deviceIdentifier : "-"}</span>
              </div><br></br><br></br>
              <div className="display-text" style={{ fontWeight: "500" }}>
                <span className="display-text">{t("Device Type")}</span>
              </div><br></br>
              <div className="display-text">
                <span className="display-text">{this.props.deviceData && this.props.deviceData.deviceData && this.props.deviceData.deviceData.deviceTypes ? this.props.deviceData.deviceData.deviceTypes.name : "-"}</span>
              </div><br></br><br></br>
              <div className="display-text" style={{ fontWeight: "500" }}>
                <span className="display-text">{t("Firmware Version")}</span>
              </div><br></br>
              <div className="display-text">
                <span className="display-text">{this.props.deviceData && this.props.deviceData.deviceData && this.props.deviceData.deviceData.firmwareVersion ? this.props.deviceData.deviceData.firmwareVersion : "-"}</span>
              </div><br></br><br></br>
              <div className="display-text" style={{ fontWeight: "500" }}>
                <span className="display-text">{t("RSSI (dBm)")}</span>
              </div><br></br>
              <div className="display-text">
                <span className="display-text" style={{color:this.state.colorStyle,fontWeight:"bold"}}>{this.props.deviceData && this.props.deviceData.deviceData && this.props.deviceData.deviceData.rssi && this.state.rssi ? this.state.rssi : "-"}</span>
                <span className="display-text"> {this.props.deviceData && this.props.deviceData.deviceData && this.props.deviceData.deviceData.rssi && this.state.rssi ? "RSSI at " + new Date(this.props.deviceData.deviceData.rssiEpoch).toLocaleString() : ""}</span>
              </div>
              <br></br><br></br>
              {/* {
                this.props.deviceData && this.props.deviceData.deviceData && this.props.deviceData.deviceData.batteryLevel && (this.props.batteryFlag===false) ?
                  (
                    <>
                      <div className="display-text" style={{ fontWeight: "500" }}>
                        <span className="display-text">{t("Battery Level")}</span>
                      </div><br></br>
                      <div className="display-text">
                        <span className="display-text">{this.props.deviceData && this.props.deviceData.deviceData && this.props.deviceData.deviceData.batteryLevel ? this.props.deviceData.deviceData.batteryLevel + "%" : ""}</span>
                      </div>
                      <br></br><br></br>
                    </>
                  ) : ""
              } */}
              <div className="display-text" style={{ fontWeight: "500" }}>
                <span className="display-text">{t("Subscription Status")}</span>
              </div><br></br>
              <div className="display-text">
                <span className="display-text">
                  <Tooltip title={this.state.tooltip} aria-label="status"><Brightness1Icon style={{ fontSize: "18", color: colors[this.state.status] }} /></Tooltip>
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

const mapStateToProps = state => {
  return {
    alarmsData: state.alarmReportDataReducer,
    userData : state.userDataReducer
  }
}
export default withTranslation()(connect(mapStateToProps, null)(MaintenancePanel));

