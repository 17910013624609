import React, { Component } from 'react';
import * as actionCreators from '../../actions/actionCreators';
import { connect } from 'react-redux';
import { KeyboardBackspace } from '@material-ui/icons';
import {
    Card,
    CardContent,
    InputLabel,
    TextField,
    Select,
    withStyles,
    OutlinedInput,
    Button,
    Paper,
    Tabs,
    Tab,
    MenuItem,
    Chip,
    ListItemText,
    InputAdornment,
    Checkbox
} from '@material-ui/core';

import WidgetComponents from '../../components/WidgetComponents';
import { CloudUpload } from '@material-ui/icons';
import { withTranslation } from "react-i18next";

const mountingConditionList = ["At Ground", "On Rigid Concrete", "Above Ground Level", "On Vibro Pad", "On Steel Structure", "On Vibrating Body"]

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250,
        },
    },

};
const data = {
    manufacturer: "man_name",
    machine_model: "model_name",
    "serial_number": "xyz",
    rotating_speed: 3000,
    kw: "",
    mounting_condition: [],
}

const styles = theme => ({
    cardContent: {
        height: '91%',
        "overflow-y": "auto"
    },
    cardContentRoot: {
        '&:last-child': {
            paddingBottom: 16
        }
    },
    card: {
        width: '100%',
        borderRadius: 0,
        height: '80vh'
    },
    textField: {
        width: '60%',
        marginRight: 20
    },
    textArea: {
        width: '80%',
        marginRight: 20
    },
    yamlTextArea: {
        width: '100%',
        marginTop: '1%',
        backgroundColor: '#f2f3f8'
    },
    outlinedInput: {
        padding: 14
    },
    saveButton: {
        fontSize: "14px",
        fontWeight: "500",
        textAlign: "center",
        color: "#ffffff",
        backgroundColor: "#42D784",
        '&:hover': {
            backgroundColor: '#42d784'
        },
        '&:disabled': {
            backgroundColor: '#f6f6f6'
        },
        borderRadius: "3px",
        boxShadow: "0 0 1px 0 rgba(0, 0, 0, 0.05)",
        width: "110px",
        height: "36px",
        marginRight: "24px"
    },
    inputFieldDiv: {
        display: "flex",
        justifyContent: "space-between",
        width: '50%',
        float: 'left',
        paddingTop: '1%',
        height: 80,
        'align-items': 'baseline'
    },
    textareaInputFieldDiv: {
        display: "flex",
        justifyContent: "space-between",
        width: '100%',
        float: 'left',
        paddingTop: '1%',
        height: 180,
        'align-items': 'baseline'
    },
    textFieldLabel: {
        color: 'rgba(0, 0, 0, 0.4000000059604645)',
        'letter-spacing': 0.5,
        'font-weight': 500,
        fontSize: "14px",
        lineHeight: 2.5,
        width: '30%',
        marginLeft: 20,
        //text-align': 'right'
    },
    textareaFieldLabel: {
        color: 'rgba(0, 0, 0, 0.4000000059604645)',
        'letter-spacing': 0.5,
        'font-weight': 500,
        fontSize: "14px",
        lineHeight: 2.5,
        width: '15%',
        marginLeft: 20,
        //'text-align': 'right'
    },
    requiredClass: {
        color: 'red'
    },
    inputLogo: {
        display: "none"
    },
    cloudUploadIcon: {
        color: '#4c4c4c',
        cursor: 'pointer'
    },
    fileUploadDiv: {
        width: '58%',
        '&>div': {
            width: '100%'
        },
        // marginRight: 20
    },
    actionButtonContainer: {
        display: 'flex',
        'justify-content': 'space-between',
        height: 50,
        'border-bottom': '1px solid rgba(207, 216, 220, 0.5)'
    },
    backButtonContainer: {
        display: 'flex',
        'align-items': 'center',
        paddingLeft: 21
    },
    arrowBack: {
        cursor: 'pointer'
    },
    entityTitle: {
        color: 'rgba(0, 0, 0, 0.8)',
        'font-family': 'Roboto',
        'font-size': 21,
        'font-weight': '400',
        'letter-spacing': 0.5,
        marginLeft: 10
    },
    paper: {
        borderRadius: 0,
        marginBottom: 1,
        boxShadow: '0 0 0 0',
        backgroundColor: '#f2f3f8',
        height: '10vh',
        clear: "both"
    },
    enabledTabs: {
        color: 'rgba(24, 32, 44, 0.8700000047683716)',
        letterSpacing: 0.1
    },
    selectedTab: {
        color: '#5ACBE5 !important'
    },
    chips: {
        display: 'flex',
        flexWrap: 'wrap',
    },
    chip: {
        backgroundColor: "#ef6e37 !important",
        margin: 2,
    },
    selected: {
        backgroundColor: "#f97942e6 !important",
        color: "white",

    },
    tabIndicator: {
        backgroundColor: '#5ACBE5'
    }
})

class MachinesEntity extends Component {

    state = {
        id: "",
        label: "",
        machineGroupsId: "",
        disableSaveButton: true,
        machineTypeId: "",
        role: "",
        scheduleId: "",
        jobId: "",
        lossListId: "",
        jobListId: "",
        imageOfEquipment: "",
        specs: "",
        plantId: "",
        labelWidth: 0,
        displayPosition: "",
        displayPositionError: false,
        labelError: false,
        machineGroupError: false,
        machineTypeError: false,
        roleError: false,
        specsError: false,
        notesError: false,
        scheduleError: false,
        lossListError: false,
        jobListError: false,
        imageOfEquipmentError: false,
        jobError: false,
        selectedTab: 0,
        errorModel: {},
        file: "",
        imageError: this.props.t("Image file with Max size 512KB, optimum resolution 1000px * 1000px")
    }

    // handle widget/yaml view
    handleTabChange = (event, value) => {
        this.setState({
            selectedTab: value
        },
            () => {
                if (this.state.specs) {
                    this.getMachineTypeSpecs();
                }
            })
    };

    // update the state for widget view
    updateStateForValue = (value, incomingLevelIndicator, error, errorMessage) => {
        let specs = [...this.state.specs];
        let currentObject = {};
        let levelIndicator = [];
        levelIndicator = incomingLevelIndicator.map(level => {
            return level.split(":")[0];
        })

        levelIndicator.forEach((key) => {
            if (((Object.keys(currentObject)).length) === 0) {
                currentObject = specs.find((item) => (item.key === key))
            }
            else {
                currentObject = currentObject.children.find((item) => (item.key === key));
            }
        })
        currentObject["value"] = value;
        currentObject["error"] = error;
        currentObject["errorMessage"] = errorMessage;

        this.setState({
            specs,
            disableSaveButton: false
        })
    }

    // update the state for widget view
    updateStateForSpecs = (value, field) => {
        let specs = this.state.specsJson;
        specs[field] = value;
        let minValue = 0;
        let maxValue = 60000;
        let error = false;
        let errorMessage = "";
        if (field === "rotating_speed" || field === "kw") {
            let parameterDatatype = "double";
            if (isNaN(value) || value === "") {
                error = true;
                errorMessage = this.props.t("Enter valid number.");
            } else {
                if (parameterDatatype === "double") {
                    var valid = (value.match(/^-?\d*(\.\d+)?$/));
                    if (valid) {
                        if (parseFloat(value) >= minValue && parseFloat(value) <= maxValue) {
                            error = false;
                            errorMessage = "";
                        } else if (parseFloat(value) < minValue) {
                            error = true;
                            errorMessage = `${this.props.t("Enter a value greater than or equal to")} ${minValue}`;
                        } else {
                            error = true;
                            errorMessage = `${this.props.t("Enter a value less than or equal to")} ${maxValue}`;
                        }
                    }
                    else {
                        error = true;
                        errorMessage = this.props.t("Enter valid number.");
                    }
                }
            }

        }
        if (field === "manufacturer" || field === "serial_number" || field === "machine_model") {
            if (value.length > 75) {
                errorMessage = this.props.t("Length should not be more than 75 character.")
                error = true;
            }
        }


        var errors = this.state.errorModel;
        if (errorMessage !== "") {
            errors[field] = errorMessage;

        } else {
            if (errors[field]) { delete errors[field] };
        }
        this.setState({
            specsJson: specs,
            errorModel: errors,
            disableSaveButton: error
        })
    }
    // display specs widget view
    /* getSpecView = () => {
         return (
             <div>
                 {this.state.specs && typeof this.state.specs === "object" ?
                     <MachinesSpecs
                         jsonData={this.state.specs}
                         errorModel={this.state.errorModel}
                         updateStateForSpecs={(value, field, errorMessage) => (this.updateStateForSpecs(value, field, errorMessage))}
                     />
                     : null}
             </div>
         )
     }*/

    // display specs yaml view
    getYamlView = () => {
        return (
            <div>
                {
                    typeof this.state.specs === "string" ?
                        <TextField
                            id="specification"
                            multiline
                            rows="60"
                            disabled='true'
                            //helperText={(this.state.validationData.specs.validations && this.state.validationData.specs.validations.helpText) ? (this.state.validationData.specs.validations.helpText) : null}
                            value={this.state.specs}
                            variant="outlined"
                            onChange={(event) => this.inputChangeHandler(event, "specs")}
                            className={this.props.classes.yamlTextArea}
                            //error={this.state.specsError}
                            InputProps={{
                                classes: {
                                    multiline: this.props.classes.outlinedInput
                                }
                            }}
                        />
                        : null
                }
            </div>
        )
    }

    // get specs for selected machine-type
    getMachineTypeSpecs = (id) => {
        if (id) {
            let plantOrganizationId = "";
            let machineTypeObject = null;
            if (id) {
                if (this.state.plantId) {
                    this.props.scopeSelector.subOrganizations.map((organization) => {
                        if (organization.plants && organization.plants.length > 0) {
                            organization.plants.map((plant) => {
                                if (plant.id === this.state.plantId) {
                                    plantOrganizationId = organization.id;
                                }
                            })
                        }
                    })
                }
                machineTypeObject = this.props.staticData.staticData[plantOrganizationId].machinetypes.find(item => {
                    if (item.id === parseInt(id)) {
                        return item;
                    }
                })
            }
            if (this.state.selectedTab === 0) {
                if (machineTypeObject.specs.trim() !== "") {
                    this.props.getJsonFromYaml({ "entityType": "machines", "specs": machineTypeObject.specs });
                }
            }
            else if (this.state.selectedTab === 1) {
                this.setState({
                    specs: machineTypeObject.specs
                })
            }
        }
        else {
            if (this.state.selectedTab === 0) {
                if (typeof this.state.specs === "string" && this.state.specs.trim() !== "") {
                    this.props.getJsonFromYaml({ "entityType": "machines", "specs": this.state.specs });
                }
            }
            else if (this.state.selectedTab === 1) {
                if (typeof this.state.specs === "object") {
                    let specs = JSON.parse(JSON.stringify(this.state.specs));
                    specs.forEach((item) => {
                        this.normalizeJsonObject(item);
                    })
                    this.props.getYamlFromJson(specs);
                }
            }
        }
    }

    // cast number to string of specs json
    normalizeJsonObject = (object) => {
        if (object.value && typeof object.value === "string") {
            if (Number(object.value)) {
                object.value = Number(object.value);
            }
        }

        if (object.children !== null) {
            object.children.forEach((item) => {
                this.normalizeJsonObject(item);
            })
        }
    }

    // Handle changes to any user input field
    inputChangeHandler = (event, field) => {
        switch (field) {
            case "label": this.setState({ label: event.target.value, disableSaveButton: false });
                this.checkValidation("label", event.target.value);
                break;
            case "machineGroupsId":
                this.setState({ machineGroupsId: event.target.value, displayPosition: "", disableSaveButton: false }, () => {
                    this.props.getMachineCountByMachineGroupId(this.state.machineGroupsId);
                });
                this.checkValidation("machineGroupsId", event.target.value);
                break;
            case "machineTypeId":
                // this.getMachineTypeSpecs(event.target.value);
                this.setState({ machineTypeId: event.target.value, specsJson: JSON.parse(JSON.stringify(data)), errorModel: {}, disableSaveButton: false });
                this.checkValidation("machineTypeId", event.target.value);
                break;
            case "role": this.setState({ role: event.target.value, disableSaveButton: false });
                this.checkValidation("role", event.target.value);
                break;
            case "specs": this.setState({ specs: event.target.value, disableSaveButton: false });
                this.checkValidation("specs", event.target.value);
                break;
            case "notes": this.setState({ notes: event.target.value, disableSaveButton: false });
                this.checkValidation("notes", event.target.value);
                break;
            case "scheduleId": this.setState({ scheduleId: event.target.value, disableSaveButton: false });
                this.checkValidation("scheduleId", event.target.value);
                break;
            case "jobId": this.setState({ jobId: event.target.value, disableSaveButton: false });
                this.checkValidation("jobId", event.target.value);
                break;
            case "lossListId": this.setState({ lossListId: event.target.value, disableSaveButton: false });
                this.checkValidation("lossListId", event.target.value);
                break;
            case "jobListId": this.setState({ jobListId: event.target.value, disableSaveButton: false });
                this.checkValidation("jobListId", event.target.value);
                break;
            case "displayPosition": this.setState({ displayPosition: event.target.value, disableSaveButton: false });
                break;
            case "imageUpload":
                if (event.target.files.length > 0) {
                    var fileData = "";
                    var that = this;
                    var reader = new FileReader();
                    //Read the contents of Image File.
                    reader.readAsDataURL(event.target.files[0]);
                    reader.onloadend = function () {
                        that.setState({ imageOfEquipment: reader.result });
                    }
                    this.checkValidation("imageUpload", event.target.files[0]);
                    // this.setState({
                    //     file: URL.createObjectURL(event.target.files[0])
                    // });
                }
                break;
            case "imageRemove":
                this.setState({ imageOfEquipment: "" });
                this.fileInput.value = "";
                this.setState({ imageOfEquipmentError: false, disableSaveButton: false })
                break;
            default: console.log("default case");
        }
    }

    // Handle routing
    goToBackPage = () => {
        this.props.history.goBack();
    }

    // update machines entity
    updateMachinesData = () => {
        let { id, notes, specs, specsJson, scheduleId: schedulesId, jobId: jobsId, lossListId: lossListsId, jobListId: jobListsId, label, machineGroupsId, machineTypeId, role } = this.state
        let imageBase64 = this.state.imageOfEquipment;
        if (typeof specsJson !== "string") {
            specsJson = JSON.stringify(specsJson);
        }
        var displayPosition = this.state.displayPosition ? parseInt(this.state.displayPosition) : null;
        if (id && id != null && id !== "") {
            this.props.updateMachinesEntity({ id, notes, specsJson, specs, schedulesId, jobsId, lossListsId, jobListsId, label, machineGroupsId, machineTypeId, role, displayPosition, imageBase64 }, id);
        }
        else {
            this.props.updateMachinesEntity({ notes, specs, specsJson, schedulesId, jobsId, lossListsId, jobListsId, label, machineGroupsId, machineTypeId, role, displayPosition, imageBase64 }, id);
        }
    }

    // create loss-lists dropdown list
    getLossListName = () => {
        let lossList = [];
        lossList.push(<option key={""} disabled={true} value={""}>{this.props.t("Select")}</option>);
        if ((this.state.validationData.lossListsName.validations && !this.state.validationData.lossListsName.validations.required) || !(this.state.validationData.lossListsName.validations)) {
            lossList.push(<option key={""} value={""}>{this.props.t("None")}</option>);
        }
        if (this.props.lossCodes && this.props.lossCodes.tableData && this.props.lossCodes.tableData.length > 0) {
            this.props.lossCodes.tableData.map((data) => {
                lossList.push(
                    <option key={data.id} value={data.id}>{data.name}</option>
                );
            })
        }
        return lossList;
    }

    // create display position dropdown list
    getDisplayPosition = () => {
        let displayPositionList = [];
        displayPositionList.push(<option key={""} disabled={true} value={""}>{this.props.t("Select")}</option>);
        if (this.props.machineCount && this.props.machineCount.machineCount && this.props.machineCount.machineCount > 0) {
            for (var i = 1; i <= this.props.machineCount.machineCount; i++) {
                displayPositionList.push(
                    <option key={i} value={i}>{i}</option>
                );
                if (i === this.props.machineCount.machineCount) {
                    if (this.state.id === null || this.state.id === "") {
                        displayPositionList.push(
                            <option key={this.props.machineCount.machineCount + 1} value={this.props.machineCount.machineCount + 1}>{this.props.t("Last")}</option>
                        );
                    }
                }
            }

        } else {
            if (this.state.id === null || this.state.id === "") {
                displayPositionList.push(
                    <option key={1} value={1}>{this.props.t("Last")}</option>
                );
            }
        }
        return displayPositionList;
    }

    // create job-lists dropdown list
    getJobListName = () => {
        let jobList = [];
        jobList.push(<option key={""} disabled={true} value={""}>{this.props.t("Select")}</option>);
        if ((this.state.validationData.jobListsName.validations && !this.state.validationData.jobListsName.validations.required) || !(this.state.validationData.jobListsName.validations)) {
            jobList.push(<option key={""} value={""}>{this.props.t("None")}</option>);
        }
        if (this.props.jobListsData && this.props.jobListsData.tableData && this.props.jobListsData.tableData.length > 0) {
            this.props.jobListsData.tableData.map((data) => {
                jobList.push(
                    <option key={data.id} value={data.id}>{data.name}</option>
                );
            })
        }
        return jobList;
    }

    // create jobs dropdown list
    getJobName = () => {
        let jobs = [];
        jobs.push(<option key={""} disabled={true} value={""}>{this.props.t("Select")}</option>);
        if ((this.state.validationData.jobsName.validations && !this.state.validationData.jobsName.validations.required) || !(this.state.validationData.jobsName.validations)) {
            jobs.push(<option key={""} value={""}>{this.props.t("None")}</option>);
        }
        if (this.props.jobsData && this.props.jobsData.tableData && this.props.jobsData.tableData.length > 0) {
            this.props.jobsData.tableData.map((data) => {
                if (data.partRef) {
                    jobs.push(
                        <option key={data.id} value={data.id}>{data.partRef}</option>
                    )
                }
            })
        }
        return jobs;
    }

    // create schedules dropdown list
    getScheduleName = () => {
        let scheduleList = [];
        scheduleList.push(<option key={""} disabled={true} value={""}>{this.props.t("Select")}</option>);
        if ((this.state.validationData.schedulesName.validations && !this.state.validationData.schedulesName.validations.required) || !(this.state.validationData.schedulesName.validations)) {
            scheduleList.push(<option key={""} value={""}>{this.props.t("None")}</option>);
        }
        if (this.props.schedulesData && this.props.schedulesData.tableData && this.props.schedulesData.tableData.length > 0) {
            this.props.schedulesData.tableData.map((data) => {
                scheduleList.push(
                    <option key={data.id} value={data.id}>{data.name}</option>
                );
            })
        }
        return scheduleList;
    }

    // create machine-groups dropdown list
    getMachineGroups = () => {
        let machineGroupList = [];
        machineGroupList.push(<option key={""} disabled={true} value={""}>{this.props.t("Select")}</option>);
        if ((this.state.validationData.machineGroupsName.validations && !this.state.validationData.machineGroupsName.validations.required) || !(this.state.validationData.machineGroupsName.validations)) {
            machineGroupList.push(<option key={""} value={""}>{this.props.t("None")}</option>);
        }
        if (this.props.machineGroupData && this.props.machineGroupData.tableData && this.props.machineGroupData.tableData.length > 0) {
            this.props.machineGroupData.tableData.map((data) => {
                machineGroupList.push(
                    <option key={data.id} value={data.id}>{data.name}</option>
                );
            })
        }
        return machineGroupList;
    }

    // create machine-types dropdown list
    getMachineTypes = () => {
        let machineTypeList = [];
        machineTypeList.push(<option key={""} disabled={true} value={""}>{this.props.t("Select")}</option>);
        if ((this.state.validationData.machineTypeName.validations && !this.state.validationData.machineTypeName.validations.required) || !(this.state.validationData.machineTypeName.validations)) {
            machineTypeList.push(<option key={""} value={""}>{this.props.t("None")}</option>);
        }
        let found = false;
        let plantOrganization = "";
        //let tempPlantId = this.props.selectedScopeData;
        let tempPlantId = this.state.plantId;
        if (tempPlantId) {
            this.props.scopeSelector.subOrganizations.map((organization) => {
                if (organization.plants && organization.plants.length > 0) {
                    organization.plants.map((plant) => {
                        if (plant.id === tempPlantId && !found) {
                            plantOrganization = organization.id;
                            found = true;
                        }
                    })
                }
            })
        }
        if (plantOrganization && this.props.staticData && this.props.staticData.staticData) {
            let plantOrganizationStaticData = this.props.staticData.staticData[plantOrganization]
            if (plantOrganizationStaticData && plantOrganizationStaticData.machinetypes) {
                plantOrganizationStaticData.machinetypes.map((data) => {
                    machineTypeList.push(
                        <option key={data.id} value={data.id}>{this.props.t(data.name)}</option>
                    );
                })
            }
        }
        return machineTypeList;
    }

    componentDidMount() {
        if (this.props.data) {
            let entityNames = Object.keys(this.props.data)
            entityNames.map((data) => {
                if (this.props.data[data] == null) {
                    this.props.data[data] = ""
                }
            })
            this.setState({
                id: this.props.data.id,
                label: this.props.data.label,
                role: this.props.data.role,

            })
            this.props.getMachineImageById(this.props.data.id);

            if (this.props.data.specsJson && this.props.data.specsJson != "") {
                var specs = JSON.parse(this.props.data.specsJson);
                specs["kw"] = specs["kw"] ? specs["kw"] : "";
                specs["mounting_condition"] = specs["mounting_condition"] ? specs["mounting_condition"] : [];
                this.setState({ specsJson: specs })
            }

            /* if (this.props.data.specs && this.props.data.specs != "") {
                 if (this.props.data.specs.props) {
                     this.setState({ specs: this.props.data.specs.props.children },
                         () => {
                             this.getMachineTypeSpecs();
                         })
                 }
                 else {
                     this.setState({ specs: this.props.data.specs },
                         () => {
                             this.getMachineTypeSpecs();
                         })
                 }
             }*/
            if (this.props.data.notes && this.props.data.notes != "") {
                if (this.props.data.notes.props) {
                    this.setState({ notes: this.props.data.notes.props.children })
                }
                else {
                    this.setState({ notes: this.props.data.notes })
                }
            }
            if (this.props.data.plants && this.props.data.plants.id) {
                this.setState({ plantId: this.props.data.plants.id })
            }
            if (this.props.data.machineGroups && this.props.data.machineGroups.id) {
                this.setState({ machineGroupsId: this.props.data.machineGroups.id }, () => {
                    this.props.getMachineCountByMachineGroupId(this.state.machineGroupsId);
                })
            }
            if (this.props.data.machineType && this.props.data.machineType.id) {
                this.setState({ machineTypeId: this.props.data.machineType.id })
            }
            if (this.props.data.schedules && this.props.data.schedules.id) {
                this.setState({ scheduleId: this.props.data.schedules.id })
            }
            if (this.props.data.jobs && this.props.data.jobs.id) {
                this.setState({ jobId: this.props.data.jobs.id })
            }
            if (this.props.data.lossLists && this.props.data.lossLists.id) {
                this.setState({ lossListId: this.props.data.lossLists.id })
            }
            if (this.props.data.jobLists && this.props.data.jobLists.id) {
                this.setState({ jobListId: this.props.data.jobLists.id })
            }
            if (this.props.data.displayPosition) {
                this.setState({ displayPosition: this.props.data.displayPosition })
            }

        }
        if (this.props.selectedScopeData && this.props.selectedScopeData != "") {
            this.setState({ plantId: this.props.selectedScopeData })
        }

        if (this.props.validationData && this.props.validationData.config && this.props.validationData.config.Machines) {
            this.setState({ validationData: this.props.validationData.config.Machines });
        }
    }

    componentDidUpdate(prevProps, prevState) {
        if (this.state.plantId && this.state.plantId != "" && prevState.plantId != this.state.plantId) {
            this.props.getTableData("SchedulesTable", this.state.plantId, true);
            this.props.getTableData("JobListTable", this.state.plantId, true);
            this.props.getTableData("LossListTable", this.state.plantId, true);
            this.props.getTableData("MachineGroupsTable", this.state.plantId, true);
            this.props.getTableData("JobsTable", this.state.plantId, true);
        }

        if (prevProps.imageData !== this.props.imageData) {
            this.setState({ imageOfEquipment: this.props.imageData && this.props.imageData.data ? 'data:image/png;base64, ' + this.props.imageData.data : "" })
        }
        /* if (prevProps.jsonData !== this.props.jsonData) {
             this.props.jsonData.json ? this.setState({
                 specs: data
             })
                 : this.setState({
                     selectedTab: 0
                 })
         }*/
    }

    // disable save button based on validations
    disableSaveButton = () => {
        if (this.state.label === "" && (this.state.validationData.label.validations && this.state.validationData.label.validations.required)) {
            return true;
        }
        else if (this.state.role === "" && (this.state.validationData.role.validations && this.state.validationData.role.validations.required)) {
            return true;
        }
        else if (this.state.notes === "" && (this.state.validationData.notes.validations && this.state.validationData.notes.validations.required)) {
            return true;
        }
        else if (this.state.specs === "" && (this.state.validationData.specs.validations && this.state.validationData.specs.validations.required)) {
            return true;
        }
        else if (this.state.jobListId === "" && (this.state.validationData.jobListsName.validations && this.state.validationData.jobListsName.validations.required)) {
            return true;
        }
        else if (this.state.lossListId === "" && (this.state.validationData.lossListsName.validations && this.state.validationData.lossListsName.validations.required)) {
            return true;
        }
        else if (this.state.machineTypeId === "" && (this.state.validationData.machineTypeName.validations && this.state.validationData.machineTypeName.validations.required)) {
            return true;
        }
        else if (this.state.scheduleId === "" && (this.state.validationData.schedulesName.validations && this.state.validationData.schedulesName.validations.required)) {
            return true;
        }
        else if (this.state.jobId === "" && (this.state.validationData.jobsName.validations && this.state.validationData.jobsName.validations.required)) {
            return true;
        }
        else if ((this.state.machineGroupsId === "") && (this.state.validationData.machineGroupsName.validations && this.state.validationData.machineGroupsName.validations.required)) {
            return true;
        }
        else if (this.state.labelError || this.state.imageOfEquipmentError || this.state.machineGroupError || this.state.machineTypeError || this.state.roleError || this.state.specsError || this.state.notesError || this.state.scheduleError || this.state.lossListError || this.state.jobListError || this.state.disableSaveButton) {
            return true;
        }
        else {
            return false;
        }
    }

    // check validations of the user input fields
    checkValidation = (field, value) => {
        switch (field) {
            case "jobListId":
                if (!this.state.jobListError && value === "" && (this.state.validationData.jobListsName.validations && this.state.validationData.jobListsName.validations.required)) {
                    this.setState({ jobListError: true });
                } else if (this.state.jobListError) {
                    if ((value === "" && (this.state.validationData.jobListsName.validations && this.state.validationData.jobListsName.validations.required))) {
                        //skip
                    }
                    else {
                        this.setState({ jobListError: false });
                    }
                };
                break;
            case "lossListId":
                if (!this.state.lossListError && value === "" && (this.state.validationData.lossListsName.validations && this.state.validationData.lossListsName.validations.required)) {
                    this.setState({ lossListError: true });
                } else if (this.state.lossListError) {
                    if ((value === "" && (this.state.validationData.lossListsName.validations && this.state.validationData.lossListsName.validations.required))) {
                        //skip
                    }
                    else {
                        this.setState({ lossListError: false });
                    }
                };
                break;
            case "scheduleId":
                if (!this.state.scheduleError && value === "" && (this.state.validationData.schedulesName.validations && this.state.validationData.schedulesName.validations.required)) {
                    this.setState({ scheduleError: true });
                } else if (this.state.scheduleError) {
                    if ((value === "" && (this.state.validationData.schedulesName.validations && this.state.validationData.schedulesName.validations.required))) {
                        //skip
                    }
                    else {
                        this.setState({ scheduleError: false });
                    }
                };
                break;
            case "jobId":
                if (!this.state.jobError && value === "" && (this.state.validationData.jobsName.validations && this.state.validationData.jobsName.validations.required)) {
                    this.setState({ jobError: true });
                } else if (this.state.jobError) {
                    if ((value === "" && (this.state.validationData.jobsName.validations && this.state.validationData.jobsName.validations.required))) {
                        //skip
                    }
                    else {
                        this.setState({ jobError: false });
                    }
                };
                break;
            case "machineTypeId":
                if (!this.state.machineTypeError && value === "" && (this.state.validationData.machineTypeName.validations && this.state.validationData.machineTypeName.validations.required)) {
                    this.setState({ machineTypeError: true });
                } else if (this.state.machineTypeError) {
                    if ((value === "" && (this.state.validationData.machineTypeName.validations && this.state.validationData.machineTypeName.validations.required))) {
                        //skip
                    }
                    else {
                        this.setState({ machineTypeError: false });
                    }
                };
                break;
            case "machineGroupsId":
                if (!this.state.machineGroupError && value === "" && (this.state.validationData.machineGroupsName.validations && this.state.validationData.machineGroupsName.validations.required)) {
                    this.setState({ machineGroupError: true });
                } else if (this.state.machineGroupError) {
                    if ((value === "" && (this.state.validationData.machineGroupsName.validations && this.state.validationData.machineGroupsName.validations.required))) {
                        //skip
                    }
                    else {
                        this.setState({ machineGroupError: false });
                    }
                };
                break;
            case "label":
                if (!this.state.labelError && value === "" && (this.state.validationData.label.validations && this.state.validationData.label.validations.required)) {
                    this.setState({ labelError: true });
                } else if (!this.state.labelError && (this.state.validationData.label.validations) && (value.length > this.state.validationData.label.validations.maxLength)) {
                    this.setState({ labelError: true });
                } else if (this.state.labelError) {
                    if ((value === "" && (this.state.validationData.label.validations && this.state.validationData.label.validations.required) || ((this.state.validationData.label.validations) && (value.length > this.state.validationData.label.validations.maxLength)))) {
                        //skip
                    }
                    else {
                        this.setState({ labelError: false });
                    }
                };
                break;
            case "role":
                if (!this.state.roleError && value === "" && (this.state.validationData.role.validations && this.state.validationData.role.validations.required)) {
                    this.setState({ roleError: true });
                } else if (!this.state.roleError && (this.state.validationData.role.validations) && (value.length > this.state.validationData.role.validations.maxLength)) {
                    this.setState({ roleError: true });
                } else if (this.state.roleError) {
                    if ((value === "" && (this.state.validationData.role.validations && this.state.validationData.role.validations.required) || ((this.state.validationData.role.validations) && (value.length > this.state.validationData.role.validations.maxLength)))) {
                        //skip
                    }
                    else {
                        this.setState({ roleError: false });
                    }
                };
                break;
            case "notes":
                if (!this.state.notesError && value === "" && (this.state.validationData.notes.validations && this.state.validationData.notes.validations.required)) {
                    this.setState({ notesError: true });
                } else if (!this.state.notesError && (this.state.validationData.notes.validations) && (value.length > this.state.validationData.notes.validations.maxLength)) {
                    this.setState({ notesError: true });
                } else if (this.state.notesError) {
                    if ((value === "" && (this.state.validationData.notes.validations && this.state.validationData.notes.validations.required) || ((this.state.validationData.notes.validations) && (value.length > this.state.validationData.notes.validations.maxLength)))) {
                        //skip
                    }
                    else {
                        this.setState({ notesError: false });
                    }
                };
                break;
            case "specs":
                if (!this.state.specsError && value === "" && (this.state.validationData.specs.validations && this.state.validationData.specs.validations.required)) {
                    this.setState({ specsError: true });
                } else if (!this.state.specsError && (this.state.validationData.specs.validations) && (value.length > this.state.validationData.specs.validations.maxLength)) {
                    this.setState({ specsError: true });
                } else if (this.state.specsError) {
                    if ((value === "" && (this.state.validationData.specs.validations && this.state.validationData.specs.validations.required) || ((this.state.validationData.specs.validations) && (value.length > this.state.validationData.specs.validations.maxLength)))) {
                        //skip
                    }
                    else {
                        this.setState({ specsError: false });
                    }
                };
                break;
            case "imageUpload":
                var reader = new FileReader();
                var that = this;
                //Read the contents of Image File.
                reader.readAsDataURL(value);
                reader.onload = function (e) {
                    var image = new Image();
                    image.src = e.target.result;
                    image.onload = function () {
                        var height = this.height;
                        var width = this.width;
                        if (height > 1000 || width > 1000 || value.size > 512000) {
                            that.setState({ imageOfEquipmentError: true, disableSaveButton: true })
                        } else {
                            that.setState({ imageOfEquipmentError: false, disableSaveButton: false })
                        }
                    };
                }
                break;

        }
    }

    // set card header
    getCardHeader = () => {
        if (this.props.data && this.props.data.label && this.props.data.label != "") {
            return this.props.data.label;
        }
        else {
            return this.props.t("Create New")
        }
    }

    render() {
        const {t}=this.props;
        return (
            <Card className={this.props.classes.card}>
                <div className={this.props.classes.actionButtonContainer}>
                    <div className={this.props.classes.backButtonContainer}>
                        <KeyboardBackspace onClick={this.goToBackPage} classes={{ root: this.props.classes.arrowBack }} />
                        <div className={this.props.classes.entityTitle}>{this.getCardHeader()}</div>
                    </div>
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                        <Button variant="contained"
                            className={this.props.classes.saveButton}
                            disabled={this.state.disableSaveButton || this.disableSaveButton()}
                            onClick={this.updateMachinesData}>{t("Save")}</Button>
                    </div>
                </div>
                <CardContent className={this.props.classes.cardContent} classes={{ root: this.props.classes.cardContentRoot }}>
                    {this.state.validationData && this.state.validationData.label && this.state.validationData.label.page_visible ? (
                        <div className={this.props.classes.inputFieldDiv}>
                            <InputLabel className={this.props.classes.textFieldLabel}>
                                {this.state.validationData.label.display_name}
                                {(this.state.validationData.label.validations && this.state.validationData.label.validations.required) ? (<span className={this.props.classes.requiredClass}>*</span>) : null}
                            </InputLabel>
                            <TextField
                                id="label"
                                name="label"
                                variant="outlined"
                                helperText={(this.state.validationData.label.validations && this.state.validationData.label.validations.helpText) ? (this.state.validationData.label.validations.helpText) : null}
                                className={this.props.classes.textField}
                                onChange={(event) => this.inputChangeHandler(event, "label")}
                                value={this.state.label}
                                error={this.state.labelError}
                                InputProps={{
                                    classes: {
                                        input: this.props.classes.outlinedInput
                                    }
                                }}
                            />
                        </div>
                    )
                        : null
                    }
                    {this.state.validationData && this.state.validationData.machineGroupsName && this.state.validationData.machineGroupsName.page_visible ? (
                        <div className={this.props.classes.inputFieldDiv}>
                            <InputLabel className={this.props.classes.textFieldLabel}>
                                {this.state.validationData.machineGroupsName.display_name}
                                {(this.state.validationData.machineGroupsName.validations && this.state.validationData.machineGroupsName.validations.required) ? (<span className={this.props.classes.requiredClass}>*</span>) : null}
                            </InputLabel>
                            <Select
                                native
                                value={this.state.machineGroupsId}
                                onChange={(event) => this.inputChangeHandler(event, "machineGroupsId")}
                                input={
                                    <OutlinedInput
                                        variant="outlined"
                                        name="machineGroupsId"
                                        id="language-native-simple"
                                        labelWidth={this.state.labelWidth}
                                        className={this.props.classes.textField}
                                        error={this.state.machineGroupError}
                                        classes={{ input: this.props.classes.outlinedInput }}
                                    />
                                }>
                                {this.getMachineGroups()}
                            </Select>
                        </div>
                    )
                        : null
                    }
                    {this.state.validationData && this.state.validationData.machineTypeName && this.state.validationData.machineTypeName.page_visible ? (
                        <div className={this.props.classes.inputFieldDiv}>
                            <InputLabel className={this.props.classes.textFieldLabel}>
                                {this.state.validationData.machineTypeName.display_name}
                                {(this.state.validationData.machineTypeName.validations && this.state.validationData.machineTypeName.validations.required) ? (<span className={this.props.classes.requiredClass}>*</span>) : null}
                            </InputLabel>
                            <Select
                                native
                                value={this.state.machineTypeId}
                                onChange={(event) => this.inputChangeHandler(event, "machineTypeId")}
                                input={
                                    <OutlinedInput
                                        variant="outlined"
                                        name="machineTypeId"
                                        id="language-native-simple"
                                        labelWidth={this.state.labelWidth}
                                        className={this.props.classes.textField}
                                        error={this.state.machineTypeError}
                                        classes={{ input: this.props.classes.outlinedInput }}
                                    />
                                }
                            >
                                {this.getMachineTypes()}
                            </Select>
                        </div>
                    )
                        : null
                    }
                    {this.state.validationData && this.state.validationData.role && this.state.validationData.role.page_visible ? (
                        <div className={this.props.classes.inputFieldDiv}>
                            <InputLabel className={this.props.classes.textFieldLabel}>
                                {this.state.validationData.role.display_name}
                                {(this.state.validationData.role.validations && this.state.validationData.role.validations.required) ? (<span className={this.props.classes.requiredClass}>*</span>) : null}
                            </InputLabel>
                            <TextField
                                id="role"
                                name="role"
                                variant="outlined"
                                helperText={(this.state.validationData.role.validations && this.state.validationData.role.validations.helpText) ? (this.state.validationData.role.validations.helpText) : null}
                                className={this.props.classes.textField}
                                onChange={(event) => this.inputChangeHandler(event, "role")}
                                value={this.state.role}
                                error={this.state.roleError}
                                InputProps={{
                                    classes: {
                                        input: this.props.classes.outlinedInput
                                    }
                                }}
                            />
                        </div>
                    )
                        : null
                    }
                    {this.state.validationData && this.state.validationData.schedulesName && this.state.validationData.schedulesName.page_visible ? (
                        <div className={this.props.classes.inputFieldDiv}>
                            <InputLabel className={this.props.classes.textFieldLabel}>
                                {this.state.validationData.schedulesName.display_name}
                                {(this.state.validationData.schedulesName.validations && this.state.validationData.schedulesName.validations.required) ? (<span className={this.props.classes.requiredClass}>*</span>) : null}
                            </InputLabel>
                            <Select
                                native
                                value={this.state.scheduleId}
                                onChange={(event) => this.inputChangeHandler(event, "scheduleId")}
                                input={
                                    <OutlinedInput
                                        variant="outlined"
                                        name="scheduleId"
                                        id="language-native-simple"
                                        labelWidth={this.state.labelWidth}
                                        className={this.props.classes.textField}
                                        error={this.state.scheduleError}
                                        classes={{ input: this.props.classes.outlinedInput }}
                                    />
                                }
                            >
                                {this.getScheduleName()}
                            </Select>
                        </div>
                    )
                        : null
                    }
                    {this.state.validationData && this.state.validationData.lossListsName && this.state.validationData.lossListsName.page_visible ? (
                        <div className={this.props.classes.inputFieldDiv}>
                            <InputLabel className={this.props.classes.textFieldLabel}>
                                {this.state.validationData.lossListsName.display_name}
                                {(this.state.validationData.lossListsName.validations && this.state.validationData.lossListsName.validations.required) ? (<span className={this.props.classes.requiredClass}>*</span>) : null}
                            </InputLabel>
                            <Select
                                native
                                value={this.state.lossListId}
                                onChange={(event) => this.inputChangeHandler(event, "lossListId")}
                                input={
                                    <OutlinedInput
                                        variant="outlined"
                                        name="lossListId"
                                        id="language-native-simple"
                                        labelWidth={this.state.labelWidth}
                                        className={this.props.classes.textField}
                                        error={this.state.lossListError}
                                        classes={{ input: this.props.classes.outlinedInput }}
                                    />
                                }
                            >
                                {this.getLossListName()}
                            </Select>
                        </div>
                    )
                        : null
                    }
                    {this.state.validationData && this.state.validationData.jobsName && this.state.validationData.jobsName.page_visible ? (
                        <div className={this.props.classes.inputFieldDiv}>
                            <InputLabel className={this.props.classes.textFieldLabel}>
                                {this.state.validationData.jobsName.display_name}
                                {(this.state.validationData.jobsName.validations && this.state.validationData.jobsName.validations.required) ? (<span className={this.props.classes.requiredClass}>*</span>) : null}
                            </InputLabel>
                            <Select
                                native
                                value={this.state.jobId}
                                onChange={(event) => this.inputChangeHandler(event, "jobId")}
                                input={
                                    <OutlinedInput
                                        variant="outlined"
                                        name="jobId"
                                        id="language-native-simple"
                                        labelWidth={this.state.labelWidth}
                                        className={this.props.classes.textField}
                                        error={this.state.jobError}
                                        classes={{ input: this.props.classes.outlinedInput }}
                                    />
                                }
                            >
                                {this.getJobName()}
                            </Select>
                        </div>
                    )
                        : null
                    }
                    {this.state.validationData && this.state.validationData.jobListsName && this.state.validationData.jobListsName.page_visible ? (
                        <div className={this.props.classes.inputFieldDiv}>
                            <InputLabel className={this.props.classes.textFieldLabel}>
                                {this.state.validationData.jobListsName.display_name}
                                {(this.state.validationData.jobListsName.validations && this.state.validationData.jobListsName.validations.required) ? (<span className={this.props.classes.requiredClass}>*</span>) : null}
                            </InputLabel>
                            <Select
                                native
                                value={this.state.jobListId}
                                onChange={(event) => this.inputChangeHandler(event, "jobListId")}
                                input={
                                    <OutlinedInput
                                        variant="outlined"
                                        name="jobListId"
                                        id="language-native-simple"
                                        labelWidth={this.state.labelWidth}
                                        className={this.props.classes.textField}
                                        error={this.state.jobListError}
                                        classes={{ input: this.props.classes.outlinedInput }}
                                    />
                                }
                            >
                                {this.getJobListName()}
                            </Select>
                        </div>
                    )
                        : null
                    }
                    <div className="row col-sm-12" style={{ padding: "0px", margin: "0px", marginBottom: "10px", marginTop: "5px" }}>
                        <div className={this.props.classes.inputFieldDiv}>
                            <InputLabel className={this.props.classes.textFieldLabel}>
                                {t("Display Position")}
                        </InputLabel>
                            <Select
                                native
                                value={this.state.displayPosition}
                                onChange={(event) => this.inputChangeHandler(event, "displayPosition")}
                                input={
                                    <OutlinedInput
                                        variant="outlined"
                                        name="displayPosition"
                                        id="language-native-simple"
                                        labelWidth={this.state.labelWidth}
                                        className={this.props.classes.textField}
                                        error={this.state.displayPositionError}
                                        classes={{ input: this.props.classes.outlinedInput }}
                                    />
                                }
                            >
                                {this.getDisplayPosition()}
                            </Select>
                        </div>
                        {
                            <div className={this.props.classes.inputFieldDiv}>
                                <InputLabel className={this.props.classes.textFieldLabel}>
                                    {t("Image Of Equipment")}
                                    </InputLabel>
                                <label htmlFor="contained-button-file" className={this.props.classes.fileUploadDiv}>
                                    <TextField
                                        name="imageUpload"
                                        variant="outlined"
                                        id="imageUpload"
                                        helperText={this.state.imageError}
                                        placeholder="Choose File.."
                                        value={""}
                                        InputProps={{
                                            endAdornment: (
                                                <InputAdornment position="end">
                                                    <CloudUpload className={this.props.classes.cloudUploadIcon}></CloudUpload>

                                                </InputAdornment>

                                            ),
                                            classes: {
                                                input: this.props.classes.outlinedInput
                                            }
                                        }}
                                        error={this.state.imageOfEquipmentError}
                                    />

                                </label>
                                <div style={{ padding: "0", width: '1%', fontSize: '20px', fontWeight: "500", cursor: "pointer" }} title="Remove"><div hidden={this.state.imageOfEquipment === ""} onClick={(event) => this.inputChangeHandler(event, "imageRemove")}>x</div></div>

                                <input
                                    variant="outlined"
                                    accept="image/*"
                                    className={this.props.classes.inputLogo}
                                    id="contained-button-file"
                                    ref={ref => this.fileInput = ref}
                                    type="file"
                                    onChange={(event) => this.inputChangeHandler(event, "imageUpload")}
                                />
                            </div>
                        }
                    </div>
                    {
                        this.state.imageOfEquipment !== "" ?
                            <div className="row col-sm-12" style={{ padding: "0", margin: "0", marginTop: "10px !important" }}>
                                <div className="col-md-8 col-lg-9 col-sm-6" style={{ padding: "0", margin: "0" }}></div>
                                <div className="col-md-4 col-lg-3 col-sm-6" style={{ padding: "0", margin: "0", marginTop: "10px", alignContent: "center" }}>
                                    <img src={this.state.imageOfEquipment} width="80px" height="60px" />
                                </div>
                            </div>
                            : null
                    }
                    {this.state.specsJson ?

                        <>
                            {
                                <div className={this.props.classes.inputFieldDiv} key={"Manufacturer"}>
                                    <InputLabel className={this.props.classes.textFieldLabel} style={{ paddingTop: '8px' }}>{t("Manufacturer")}</InputLabel>
                                    <TextField
                                        id={"manufacturer"}
                                        name={"manufacturer"}
                                        variant="outlined"
                                        type="text"
                                        className={this.props.classes.textField}
                                        onChange={(event) => this.updateStateForSpecs(event.target.value, "manufacturer")}
                                        value={this.state.specsJson.manufacturer}
                                        error={this.state.errorModel["manufacturer"] ? true : false}
                                        helperText={this.state.errorModel["manufacturer"]}//change
                                        InputProps={{
                                            classes: {
                                                input: this.props.classes.outlinedInput
                                            }
                                        }}
                                    />

                                </div>

                            }

                            {
                                <div className={this.props.classes.inputFieldDiv} key={"machine_model"}>
                                    <InputLabel className={this.props.classes.textFieldLabel} style={{ paddingTop: '8px' }}>{t("Asset Model")}</InputLabel>
                                    <TextField
                                        id={"machine_model"}
                                        name={"machine_model"}
                                        variant="outlined"
                                        type="text"
                                        className={this.props.classes.textField}
                                        onChange={(event) => this.updateStateForSpecs(event.target.value, "machine_model")}
                                        value={this.state.specsJson.machine_model}
                                        error={this.state.errorModel["machine_model"] ? true : false}
                                        helperText={this.state.errorModel["machine_model"]}//change
                                        InputProps={{
                                            classes: {
                                                input: this.props.classes.outlinedInput
                                            }
                                        }}
                                    />

                                </div>

                            }

                            {
                                <div className={this.props.classes.inputFieldDiv} key={"serial_number"}>
                                    <InputLabel className={this.props.classes.textFieldLabel} style={{ paddingTop: '8px' }}>{t("Serial Number")}</InputLabel>
                                    <TextField
                                        id={"serial_number"}
                                        name={"serial_number"}
                                        variant="outlined"
                                        type="text"
                                        className={this.props.classes.textField}
                                        onChange={(event) => this.updateStateForSpecs(event.target.value, "serial_number")}
                                        value={this.state.specsJson.serial_number}
                                        error={this.state.errorModel["serial_number"] ? true : false}
                                        helperText={this.state.errorModel["serial_number"]}//change
                                        InputProps={{
                                            classes: {
                                                input: this.props.classes.outlinedInput
                                            }
                                        }}
                                    />

                                </div>

                            }
                            {
                                <div className={this.props.classes.inputFieldDiv} key={"rotating_speed"}>
                                    <InputLabel className={this.props.classes.textFieldLabel} style={{ paddingTop: '8px' }}>{t("Rotating Speed")}</InputLabel>
                                    <TextField
                                        id={"rotating_speed"}
                                        name={"rotating_speed"}
                                        variant="outlined"
                                        type="text"
                                        className={this.props.classes.textField}
                                        onChange={(event) => this.updateStateForSpecs(event.target.value, "rotating_speed")}
                                        value={this.state.specsJson.rotating_speed}
                                        error={this.state.errorModel["rotating_speed"] ? true : false}
                                        helperText={this.state.errorModel["rotating_speed"]}//change
                                        InputProps={{
                                            classes: {
                                                input: this.props.classes.outlinedInput
                                            }
                                        }}
                                    />

                                </div>

                            }
                            {
                                <div className={this.props.classes.inputFieldDiv} key={"kw"}>
                                    <InputLabel className={this.props.classes.textFieldLabel} style={{ paddingTop: '8px' }}>{t("KW")}</InputLabel>
                                    <TextField
                                        id={"kw"}
                                        name={"kw"}
                                        variant="outlined"
                                        type="text"
                                        className={this.props.classes.textField}
                                        onChange={(event) => this.updateStateForSpecs(event.target.value, "kw")}
                                        value={this.state.specsJson.kw}
                                        error={this.state.errorModel["kw"] ? true : false}
                                        helperText={this.state.errorModel["kw"]}//change
                                        InputProps={{
                                            classes: {
                                                input: this.props.classes.outlinedInput
                                            }
                                        }}
                                    />
                                </div>
                            }
                            {
                                <div className={this.props.classes.inputFieldDiv} style={{ marginBottom: "30px" }} >
                                    <InputLabel className={this.props.classes.textFieldLabel}>
                                        {t("Mounting Condition")}
                                     </InputLabel>
                                    <Select
                                        id="demo-mutiple-checkbox"
                                        multiple
                                        value={this.state.specsJson.mounting_condition}
                                        onChange={(event) => this.updateStateForSpecs(event.target.value, "mounting_condition")}
                                        style={{ maxHeight: "100px" }}
                                        input={
                                            <OutlinedInput
                                                variant="outlined"
                                                name="mounting_condition"
                                                id="language-native-simple"
                                                labelWidth={this.state.labelWidth}
                                                className={this.props.classes.textField}
                                                error={this.state.errorModel["mounting_condition"] ? true : false}
                                            />
                                        }
                                        renderValue={(selected) => (
                                            <div style={{ display: 'flex', flexWrap: 'wrap', maxHeight: "85px", overflowY: "auto" }}>
                                                {selected.map((value) => (
                                                    <Chip key={value} label={value} style={{ marginBottom: "2px", marginRight: "2px" }} />
                                                ))}
                                            </div>
                                        )}
                                        MenuProps={MenuProps}
                                    >
                                        {mountingConditionList.map((item) => (
                                            <MenuItem key={item} value={item} classes={{ selected: this.props.classes.selected }}>
                                                <Checkbox checked={this.state.specsJson.mounting_condition && (this.state.specsJson.mounting_condition.indexOf(item) > -1)} inputProps={{ 'aria-label': 'primary checkbox' }} />
                                                <ListItemText primary={item} />
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </div>
                            }

                        </>

                        : null}

                    {this.state.validationData && this.state.validationData.notes && this.state.validationData.notes.page_visible ? (
                        <div className={this.props.classes.textareaInputFieldDiv}>
                            <InputLabel className={this.props.classes.textareaFieldLabel}>
                                {this.state.validationData.notes.display_name}
                                {(this.state.validationData.notes.validations && this.state.validationData.notes.validations.required) ? (<span className={this.props.classes.requiredClass}>*</span>) : null}
                            </InputLabel>
                            <TextField
                                id="note"
                                multiline
                                rows="6"
                                helperText={(this.state.validationData.notes.validations && this.state.validationData.notes.validations.helpText) ? (this.state.validationData.notes.validations.helpText) : null}
                                value={this.state.notes}
                                variant="outlined"
                                onChange={(event) => this.inputChangeHandler(event, "notes")}
                                className={this.props.classes.textArea}
                                error={this.state.notesError}
                                InputProps={{
                                    classes: {
                                        multiline: this.props.classes.outlinedInput
                                    }
                                }}
                            />
                        </div>
                    )
                        : null
                    }
                    {
                        // <div style={{ clear: "both", minHeight: 200 }}>
                        //     <Paper>
                        //         <Tabs
                        //             value={this.state.selectedTab}
                        //             onChange={this.handleTabChange}
                        //             indicatorColor="primary"
                        //             textColor="primary"
                        //             variant="fullWidth"
                        //             TabIndicatorProps={{
                        //                 classes: {
                        //                     colorPrimary: this.props.classes.tabIndicator
                        //                 }
                        //             }}
                        //             style={{ marginTop: 10, marginBottom: 10 }}
                        //         >
                        //             <Tab label={"Machine Specifications"} key={"SPECS"} classes={{ selected: this.props.classes.selectedTab }} />
                        //             {/* <Tab label={"YAML"} key={"YAML"} classes={{ selected: this.props.classes.selectedTab }} /> */}
                        //         </Tabs>
                        //     </Paper>
                        //     {this.state.selectedTab === 0 ? this.getSpecView() : this.getYamlView()}
                        // </div>
                    }
                </CardContent>
            </Card >
        );
    }
}

const mapStateToProps = (state) => {
    return {
        schedulesData: state.schedulesDataReducer,
        jobsData: state.jobsDataReducer,
        jobListsData: state.jobListDataReducer,
        lossCodes: state.lossListDataReducer,
        staticData: state.staticDataReducer,
        machineGroupData: state.machineGroupReducer,
        validationData: state.viewDefinitionReducer,
        jsonData: state.constructJsonReducer,
        machineCount: state.machineCountReducer,
        imageData: state.machineImageReducer
    }
}

const mapDispatchToProps = dispatch => {
    return ({
        getMachineImageById: (id) => (dispatch(actionCreators.getMachineImageById(id))),
        getTableData: (selectedNavtab, currentScopeSelectionId, setToStore) => (dispatch(actionCreators.getTableData(selectedNavtab, currentScopeSelectionId, setToStore))),
        updateMachinesEntity: (machinesEntityData, id) => (dispatch(actionCreators.updateMachinesEntity(machinesEntityData, id))),
        updateSelectedTab: (selectedTab) => (dispatch(actionCreators.updateSelectedTab(selectedTab))),
        getJsonFromYaml: (yamlRequestObject) => (dispatch(actionCreators.getJsonFromYaml(yamlRequestObject))),
        getYamlFromJson: (jsonRequestObject) => (dispatch(actionCreators.getYamlFromJson(jsonRequestObject))),
        getMachineCountByMachineGroupId: (machineGroupId) => (dispatch(actionCreators.getMachineCountByMachineGroupId(machineGroupId)))
    })
}

export default withTranslation()(connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(MachinesEntity)));

