import React, { Component } from 'react';
import {
    withStyles,
    Dialog,
    DialogActions,
    Button,
    InputLabel,
    Select,
    OutlinedInput
} from '@material-ui/core';
import { withTranslation } from "react-i18next";

const styles = () => ({
    dialog: {
        padding: '12px 24px'
    },
    outlinedInput: {
        padding: 12
    },
    elementContainer: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        paddingTop: 24
    },
    textFeildLabel: {
        color: 'rgba(0, 0, 0, 0.4000000059604645)',
        'letter-spacing': 0.5,
        'font-weight': 500,
        fontSize: "14px",
        paddingLeft: "7px",
        lineHeight: 2.5
    },
    textField: {
        border: "1px solid #00000005",
        borderRadius: "6px",
        width: '315px',
        height: '45px',
    },
    textArea: {
        border: "1px solid #00000005",
        width: '315px',
    },
    multiline: {
        paddingTop: 7
    },
    saveButton: {
        width: 100,
        backgroundColor: '#42D784',
        '&:hover': {
            backgroundColor: '#42D784'
        }
    },
    dialogAction: {
        marginTop: 24
    }
})

class PlantsModal extends Component {

    state = {
        language: "",
        timezone: "",
        specs: "",
        notes: "",
        changedLanguage: false,
        changedTimezone: false,
        changedNotes: false,
        changedSpecs: false
    }

    // handle updated user input values
    handleUpdate = () => {
        this.props.updateSelectedRecords(this.state);
        this.props.disableBulkEditingView();
    }

    // handle modal window close action
    handleClose = () => {
        this.props.disableBulkEditingView();
        this.setState({
            language: "",
            timezone: "",
            specs: "",
            notes: "",
            changedLanguage: false,
            changedTimezone: false,
            changedNotes: false,
            changedSpecs: false
        })
    }

    // handle user input changes
    onChangeHandler = (event, item) => {
        switch (item) {
            case "Language":
                this.setState({
                    changedLanguage: true,
                    language: event.target.value
                })
                break;
            case "Timezone":
                this.setState({
                    changedTimezone: true,
                    timezone: event.target.value
                })
                break;
            case "Notes":
                this.setState({
                    changedNotes: true,
                    notes: event.target.value
                })
                break;
            case "Specs":
                this.setState({
                    changedSpecs: true,
                    specs: event.target.value
                })
                break;
            default: console.log("default case");
        }
    }

    render() {
        const {t}=this.props;
        return (
            <Dialog
                open={this.props.enableBulkEditingView}
                onClose={this.handleClose}
                aria-labelledby="form-dialog-title"
                maxWidth="sm"
                fullWidth={true}
                classes={{ paper: this.props.classes.dialog }}
                disableBackdropClick={true}
            >
                {
                    this.props.config.language.page_visible ?
                        (
                            <div className={this.props.classes.elementContainer}>
                                <InputLabel className={this.props.classes.textFeildLabel}>
                                    {this.props.config.language.display_name}
                                </InputLabel>
                                <Select
                                    native
                                    value={this.state.language}
                                    onChange={(event) => this.onChangeHandler(event, "Language")}
                                    input={
                                        <OutlinedInput
                                            variant="outlined"
                                            name="defaultLanguage"
                                            id="language-native-simple"
                                            className={this.props.classes.textField}
                                            labelWidth={0}
                                            classes={{ input: this.props.classes.outlinedInput }}
                                        />
                                    }
                                >
                                    <option disabled={true} key={""} value="">{"Select"}</option>
                                    <option value="English">English</option>
                                    {/* {
                                        this.props.languages.map((item) => (<option value={item} key={item}>{item}</option>))
                                    } */}
                                </Select>
                            </div>
                        )
                        : null
                }
                {
                    this.props.config.timezone.page_visible ?
                        (
                            <div className={this.props.classes.elementContainer}>
                                <InputLabel className={this.props.classes.textFeildLabel}>
                                    {this.props.config.timezone.display_name}
                                </InputLabel>
                                <Select
                                    native
                                    value={this.state.timezone}
                                    onChange={(event) => this.onChangeHandler(event, "Timezone")}
                                    input={
                                        <OutlinedInput
                                            variant="outlined"
                                            name="timezone"
                                            id="language-native-simple"
                                            className={this.props.classes.textField}
                                            labelWidth={0}
                                            classes={{ input: this.props.classes.outlinedInput }}
                                        />
                                    }
                                >
                                    <option disabled={true} key={""} value="">{"Select"}</option>
                                    {
                                        this.props.timezones ? this.props.timezones.map((item) => (<option value={item} key={item}>{item}</option>)) : this.props.history.replace("/dashboard")
                                    }
                                </Select>
                            </div>
                        )
                        : null
                }
                {
                    this.props.config.notes.page_visible ?
                        (
                            <div className={this.props.classes.elementContainer}>
                                <InputLabel className={this.props.classes.textFeildLabel} style={{ alignSelf: 'flex-start' }}>
                                    {this.props.config.notes.display_name}
                                </InputLabel>
                                <OutlinedInput
                                    multiline
                                    rows="5"
                                    value={this.state.notes}
                                    onChange={(event) => this.onChangeHandler(event, "Notes")}
                                    className={this.props.classes.textArea}
                                    classes={{ multiline: this.props.classes.multiline }}
                                    labelWidth={0}
                                />
                            </div>
                        )
                        : null
                }
                {
                    this.props.config.specs.page_visible ?
                        (
                            <div className={this.props.classes.elementContainer}>
                                <InputLabel className={this.props.classes.textFeildLabel} style={{ alignSelf: 'flex-start' }}>
                                    {this.props.config.specs.display_name}
                                </InputLabel>
                                <OutlinedInput
                                    multiline
                                    rows="5"
                                    value={this.state.specs}
                                    onChange={(event) => this.onChangeHandler(event, "Specs")}
                                    className={this.props.classes.textArea}
                                    classes={{ multiline: this.props.classes.multiline }}
                                    labelWidth={0}
                                />
                            </div>
                        )
                        : null
                }
                <DialogActions classes={{ root: this.props.classes.dialogAction }}>
                    <Button onClick={this.handleClose} color="primary">
                        {t("Cancel")}
                    </Button>
                    <Button
                        variant="contained"
                        color="primary"
                        disabled={!(this.state.changedLanguage || this.state.changedNotes || this.state.changedSpecs || this.state.changedTimezone)}
                        className={this.props.classes.saveButton}
                        onClick={this.handleUpdate}
                    >
                        {t("Save")}
                        </Button>
                </DialogActions>
            </Dialog>
        )
    }
}

export default withTranslation()((withStyles(styles)(PlantsModal)));