import * as actionTypes from '../actions/actionTypes';

export const productivitySelectionReducer = (state={}, action) => {
    switch(action.type) {

        case actionTypes.SAVE_PRODUCTIVITY_SELECTION:{ 
            return Object.assign({}, state, action.payload)
        }
        
        default: return state
    }
}